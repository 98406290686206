import { map } from 'lodash';
import { getFirebase } from 'react-redux-firebase';

export const checkWhitelists = async () => {
  const firebase = getFirebase();
  const db = firebase.firestore();
  const snapshot = await db.collection('whitelist').get();
  const emails = map(snapshot.docs, doc => doc.data().email);
  console.log('white list', emails.join(', '));

  const users = await db.collection('users').get();
  const usersEmails = map(users.docs, doc => doc.data().email);
  console.log('emails', usersEmails.join(', '));
};

export const getUser = async userId => {
  const firebase = getFirebase();
  const db = firebase.firestore();
  const snapshot = await db.collection(`users`).doc(`${userId}`).get();
  const userData = snapshot.data();
  return userData;
};
