import React from 'react';
// cr components
import VoteResultsFlows from 'components/NoteAndMap/Participant/VoteResultsFlows';

/**
 * Note And Map view for the 'voteResults' phase on the Participant side
 */
const NoteAndMapParticipantVoteResults = () => {
  return <VoteResultsFlows />;
};

export default NoteAndMapParticipantVoteResults;
