import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Switch, Route } from 'react-router-dom';
import { Card, CardContent } from '@material-ui/core';
import SignIn from './signin';
import LoginVerification from './verify';

import './index.css';

const queryString = require('query-string');

const LoginPage = () => {
  const authUser = useSelector(state => state.firebase.auth);
  const history = useHistory();
  const urlParams = queryString.parse(window.location.search);

  if (!authUser.isEmpty && !authUser.isAnonymous) {
    if (urlParams.redirectTo) {
      // redirect to url the user was originally trying to visit
      history.push(decodeURIComponent(urlParams.redirectTo));
    } else {
      history.push('/admin');
    }
    return null;
  }

  return (
    <Card
      className="login-container"
      style={{ boxShadow: '0px 8px 18px rgba(0, 0, 0, 0.12)', border: '1px solid #E3E3E3' }}
    >
      <CardContent>
        <Switch>
          <Route path="/auth/login-page/verify" component={LoginVerification} />
          <Route>
            <SignIn whitelist={true} />
          </Route>
        </Switch>
      </CardContent>
    </Card>
  );
};

export default LoginPage;
