import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';
import { find } from 'lodash';
// cr components
import SessionFeedbackSummary from 'views/Admin/SessionFeedbackDetail/SessionFeedbackSummary';
// selectors
import { getQuestion } from 'redux/selectors/assessmentSelectors';
// constants
import { SENTIMENT_CHOICES } from 'components/CustomFormComponents/Sentiment';

const SentimentQuestionResultsSummary = ({ questionId }) => {
  const [data, setData] = useState({});
  const { answerCounts } = useSelector(getQuestion(questionId));

  useDeepCompareEffect(() => {
    const filteredFeedback = {};

    const satisfiedCounts = find(answerCounts, { answer: SENTIMENT_CHOICES.SATISFIED });
    const dissatisfiedCounts = find(answerCounts, { answer: SENTIMENT_CHOICES.DISSATISFIED });
    const neutralCounts = find(answerCounts, { answer: SENTIMENT_CHOICES.NEUTRAL });

    filteredFeedback[SENTIMENT_CHOICES.SATISFIED] = [...Array(satisfiedCounts.count).keys()];
    filteredFeedback[SENTIMENT_CHOICES.SATISFIED].percentage = satisfiedCounts.percentage;
    filteredFeedback[SENTIMENT_CHOICES.NEUTRAL] = [...Array(neutralCounts.count).keys()];
    filteredFeedback[SENTIMENT_CHOICES.NEUTRAL].percentage = neutralCounts.percentage;
    filteredFeedback[SENTIMENT_CHOICES.DISSATISFIED] = [...Array(dissatisfiedCounts.count).keys()];
    filteredFeedback[SENTIMENT_CHOICES.DISSATISFIED].percentage = dissatisfiedCounts.percentage;

    setData(filteredFeedback);
  }, [answerCounts]);

  return (
    <div className="my-6">
      <SessionFeedbackSummary filteredFeedback={data} />
    </div>
  );
};

SentimentQuestionResultsSummary.propTypes = {
  questionId: PropTypes.string.isRequired,
};

export default React.memo(SentimentQuestionResultsSummary);
