import { createSelector } from 'reselect';
import { values, keyBy, filter } from 'lodash';

export const participantsSelector = state => state.participants;

export const getParticipants = createSelector([participantsSelector], participants =>
  values(participants),
);

export const getParticipantsMap = createSelector([getParticipants], participants =>
  participants.length ? keyBy(participants, 'id') : {},
);

export const getConnectedParticipants = createSelector(
  [getParticipants],
  participants => filter(participants, ['connected', true]) || [],
);

export const getConnectedParticipantsMap = createSelector(
  [getParticipants],
  participants => keyBy(filter(participants, ['connected', true]), 'id') || {},
);

export const getParticipantById = participantId =>
  createSelector([getParticipants], participants =>
    participants.find(participant => participant.id === participantId),
  );
