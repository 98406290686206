import React from 'react';
import AutoCompleteBase from 'components/AutoCompleteBase';

/**
 * AutoSuggestInput (not tooled for Formik)
 */
const AutoSuggestInput = ({
  strict = false,
  freeSolo, // prevent passing freeSolo prop to MuiAutocomplete (use strict instead)
  options,
  closeIcon, // prevent passing closeIcon prop to MuiAutocomplete
  disableClearable = true,
  ...props
}) => {
  if (options.length > 0 && typeof options[0] !== 'string') {
    throw new Error(
      'AutoSuggestInput options must be an array of strings, found objects. Maybe you wanted to use SearchableSelect?',
    );
  }

  return (
    <AutoCompleteBase
      freeSolo={!strict}
      options={options}
      disableClearable={disableClearable}
      {...props}
    />
  );
};

export default AutoSuggestInput;
