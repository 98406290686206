import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
// library components
import { Button, DialogActions } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  cancelButton: {
    width: 105,
    height: 40,
    marginRight: 'auto',
    background: '#9E9E9E',
    color: '#fff',
    fontSize: 14,
    '&:hover': {
      background: '#9E9E9E',
      opacity: 0.9,
    },
  },
  saveButton: {
    width: 205,
    height: 40,
    background: theme.palette.primary.main,
    color: '#fff',
    fontSize: 14,
    '&:hover': {
      background: theme.palette.primary.main,
      opacity: 0.9,
    },
  },
}));

const DialogAction = ({ handleClose, handleConfirm, confirmText, className }) => {
  const classes = useStyles();

  return (
    <DialogActions className={cx(className, 'p-0')}>
      {/* <Button
        onClick={handleClose}
        className={classes.cancelButton}
      >
        CANCEL
      </Button> */}
      <Button className={classes.saveButton} onClick={handleConfirm}>
        {confirmText || 'EXPORT'}
      </Button>
    </DialogActions>
  );
};

DialogAction.propTypes = {
  handleClose: PropTypes.func.isRequired, // close dialog
  handleConfirm: PropTypes.func.isRequired, // open dialog
  confirmText: PropTypes.string, // text for submit button
  className: PropTypes.string, // styles for buttons container
};

export default DialogAction;
