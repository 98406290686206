import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { filter, size } from 'lodash';
// library components
import { Box } from '@material-ui/core';
// cr components
import ActivityParticipantDetails from 'components/ActivityParticipantDetails';
import ActivityPromptAdmin from 'components/ActivityPromptAdmin';
import ActivityActionButton from 'components/CustomButtons/ActivityActionButton';
import SurveyQuestions from './SurveyQuestions';
// selectors
import { useSurveySelector, getParticipantsHaveAnswered } from 'redux/selectors/surveySelectors';
// actions
import { endSurvey } from 'redux/actions/surveyActions';
// config
import { SURVEY_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';
// utils
import { makeParticipantDetailsHeaderText } from 'utils/makeParticipantDetailsHeaderText';
import { getQuestions } from 'redux/selectors/surveySelectors';

/**
 * Survey view for the 'write' phase on the Facilitator side
 */
const SurveyAdminWrite = () => {
  const dispatch = useDispatch();
  const {
    id,
    prompt,
    participantData: { participants },
    config: { questions },
  } = useSurveySelector();

  const participantsHaveAnswered = useSelector(getParticipantsHaveAnswered());

  const headerText = makeParticipantDetailsHeaderText(
    filter(participantsHaveAnswered, pt => pt.completed).length,
    participants.length,
    'answered',
  );

  const onEndSurvey = () => {
    dispatch(endSurvey(id));
  };

  return (
    <Box className="d-flex flex-column justify-content-center">
      <ActivityPromptAdmin
        prompt={prompt}
        collection={CONFIG.collection}
        activityId={id}
        className="mb-1"
      />
      <Box mb={2}>
        <SurveyQuestions />
      </Box>
      <ActivityParticipantDetails
        headerText={headerText}
        hasCompletedLabel="Answered"
        hasntCompletedLabel="Hasn't Answered"
        completedCountMax={size(questions)}
        participantsCompletedStatus={participantsHaveAnswered}
      />
      <ActivityActionButton text="END SURVEY" onClick={onEndSurvey} className="mb-2" />
    </Box>
  );
};

export default SurveyAdminWrite;
