import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isEmpty, isLoaded, useFirestoreConnect } from 'react-redux-firebase';

import CardSortingAdminCategorize from 'components/CardSorting/Admin/CardSortingAdminCategorize';
import CardSortingAdminReview from 'components/CardSorting/Admin/CardSortingAdminReview';
import CardSortingAdminPrimaryCategorize from 'components/CardSorting/Admin/CardSortingAdminPrimaryCategorize';
import CardSortingAdminResults from 'components/CardSorting/Admin/CardSortingAdminResults';
import AdminActivityTemplate from 'components/AdminActivityTemplate';

import { useCardSortingSelector } from 'redux/selectors/cardSortingSelectors';

import { CARD_SORTING_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';

const views = {
  categorize: CardSortingAdminCategorize,
  review: CardSortingAdminReview,
  primaryCategorize: CardSortingAdminPrimaryCategorize,
  results: CardSortingAdminResults,
};

const AdminCardSorting = () => {
  const routeParams = useParams();
  const cardSorting = useCardSortingSelector();
  const session = useSelector(state => state.firestore.data.session);
  const meetingParticipants = useSelector(state => state.firestore.data.participants);

  // hooks up a firestore listener to Redux
  useFirestoreConnect(() => [
    {
      collection: 'cardSorting',
      doc: `${routeParams.activityId}`,
      storeAs: 'cardSorting',
    },
  ]);

  // ensure all of the data we need is loaded
  if (
    !isLoaded(session) ||
    isEmpty(session) ||
    !isLoaded(cardSorting) ||
    isEmpty(cardSorting) ||
    !isLoaded(meetingParticipants) ||
    routeParams.activityId !== cardSorting?.id
  ) {
    return null;
  }

  // get current view
  const CurrentView = views[cardSorting.state.activeView];

  return (
    <AdminActivityTemplate
      activityConfig={CONFIG}
      activityData={cardSorting}
      session={session}
      CurrentView={CurrentView}
    />
  );
};

export default AdminCardSorting;
