import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { has, map, sortBy } from 'lodash';
import { useSelector } from 'react-redux';
// library components
import { Box, Typography } from '@material-ui/core';
// cr components
import ActivityPromptAdmin from 'components/ActivityPromptAdmin';
import Categories from './Categories';
// selectors
import {
  useNoteAndCategorizeSelector,
  getResults,
} from 'redux/selectors/noteAndCategorizeSelectors';
// config
import { NOTE_AND_CATEGORIZE_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';

const useStyles = makeStyles(theme => ({
  promptBox: {
    marginBottom: theme.spacing(2),
  },
  voteBox: {
    '&:first-child': {
      color: '#236ec3',
    },
  },
  voteText: {
    color: '#236ec3',
    marginRight: theme.spacing(1),
    fontWeight: 'bold',
    lineHeight: 'normal',
  },
  title: {
    textAlign: 'center',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    fontWeight: 300,
    fontSize: 16,
    opacity: 0.9,
  },
}));

/**
 * Note & Categorize view for the 'results' phase on the Facilitator side
 */
const NoteAndCategorizeAdminResults = () => {
  const classes = useStyles();
  const {
    id,
    prompt,
    state: { activeView },
    config: { categories, votesPerCategory },
  } = useNoteAndCategorizeSelector();
  const results = useSelector(getResults());
  let sortField = 'value';

  const sortedCategories = sortBy(
    map(categories, category => {
      sortField = has(category, 'order') ? 'order' : 'value';
      return category;
    }),
    sortField,
  );

  return (
    <Box className="d-flex flex-column justify-content-center">
      <ActivityPromptAdmin
        prompt={prompt}
        collection={CONFIG.collection}
        activityId={id}
        className={classes.promptBox}
      />
      <Categories categories={sortedCategories} output={results} view={activeView} />
      {votesPerCategory === '0' && (
        <Typography className={classes.title}>Reviewing all categories</Typography>
      )}
    </Box>
  );
};

export default NoteAndCategorizeAdminResults;
