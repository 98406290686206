import React from 'react';
import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
// library components
import { grey } from '@material-ui/core/colors';
import { makeStyles, Box, Typography } from '@material-ui/core';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import ThumbDownAltIcon from '@material-ui/icons/ThumbDownAlt';
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';

const useStyles = makeStyles(theme => ({
  line: {
    width: '100%',
    border: `1px solid ${grey[100]}`,
  },
  text: {
    fontSize: 14,
    opacity: 0.87,
  },
  participantText: {
    width: '18%',
    marginRight: theme.spacing(1),
  },
  noteText: {
    width: '46%',
    marginRight: theme.spacing(1),
  },
  timeText: {
    width: '10%',
    marginRight: theme.spacing(1),
  },
  testerText: {
    width: '19%',
    textAlign: 'end',
  },
  cardLike: {
    marginRight: theme.spacing(4),
    color: theme.palette.success.main,
  },
  cardUnlike: {
    marginRight: theme.spacing(4),
    color: theme.palette.error.main,
  },
  cardQuote: {
    marginRight: theme.spacing(4),
    color: theme.palette.indigo.main,
  },
  withoutIcon: {
    width: 20,
    marginRight: theme.spacing(4),
  },
}));

/**
 * Renders list with note, action for note, the participant name, tester name and time
 */
const Insights = ({ action, note, participant, time, tester }) => {
  const classes = useStyles();

  const renderAction = () => {
    switch (action) {
      case 'like':
        return <ThumbUpAltIcon className={classes.cardLike} fontSize="small" />;
      case 'unlike':
        return <ThumbDownAltIcon className={classes.cardUnlike} fontSize="small" />;
      case 'quote':
        return <FormatQuoteIcon className={classes.cardQuote} fontSize="small" />;
      default:
        return <div className={classes.withoutIcon} />;
    }
  };

  return (
    <>
      <Box className="w-100 mt-2 mb-2" display="flex" alignItems="center">
        {renderAction()}
        <Typography className={cx(classes.text, classes.participantText)}>{participant}</Typography>
        <Typography className={cx(classes.text, classes.noteText)}>{note}</Typography>
        <Typography className={cx(classes.text, classes.timeText)}>
          {moment(time).format('hh:mm A')}
        </Typography>
        <Typography className={cx(classes.text, classes.testerText)}>{tester}</Typography>
      </Box>
      <div className={classes.line} />
    </>
  );
};

Insights.propTypes = {
  action: PropTypes.string, // the partcipant select note as 'like', 'dislike' or 'quote'
  note: PropTypes.string.isRequired, // note value
  participant: PropTypes.string.isRequired, // the participant name, who created note
  time: PropTypes.string.isRequired, //time when the note is created or the participant chooses the action for the note
  tester: PropTypes.string.isRequired, // the tester name
};

export default Insights;
