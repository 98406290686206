import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { filter } from 'lodash';
// library components
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// cr components
import ActivityParticipantDetails from 'components/ActivityParticipantDetails';
import ActivityPromptAdmin from 'components/ActivityPromptAdmin';
import ActivityActionButton from 'components/CustomButtons/ActivityActionButton';
// selectors
import {
  useUserJourneyMomentsSelector,
  getParticipantsHaveSelectedKeyMoment,
} from 'redux/selectors/userJourneyMomentsSelectors';
// actions
import { updateActivityView } from 'redux/actions/userJourneyMomentsActions';
// config
import { USER_JOURNEY_MOMENTS_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';
// utils
import { makeParticipantDetailsHeaderText } from 'utils/makeParticipantDetailsHeaderText';

const useStyles = makeStyles(theme => ({
  promptAdmin: {
    marginBottom: theme.spacing(5),
  },
}));

/**
 * User Journey Moments view for the 'keyMoment' phase on the Facilitator side
 */
const UserJourneyMomentsAdminKeyMoment = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userJourneyMoments = useUserJourneyMomentsSelector();
  const { id, prompt } = userJourneyMoments;

  const participantsHaveSelected = useSelector(getParticipantsHaveSelectedKeyMoment());
  const participantsHaveSelectedCount = filter(participantsHaveSelected, {
    completed: true,
  }).length;
  const participantsHaveSelectedCountText = makeParticipantDetailsHeaderText(
    participantsHaveSelectedCount,
    participantsHaveSelected.length,
    'selected a key moment',
  );

  const onUpdateActivityView = () => {
    dispatch(updateActivityView('vote', id));
  };

  return (
    <Box className="d-flex flex-column justify-content-center">
      <ActivityPromptAdmin
        prompt={prompt}
        collection={CONFIG.collection}
        activityId={id}
        className={classes.promptAdmin}
      />
      <ActivityParticipantDetails
        headerText={participantsHaveSelectedCountText}
        hasCompletedLabel="Selected"
        hasntCompletedLabel="Hasn’t Selected"
        participantsCompletedStatus={participantsHaveSelected}
      />
      <ActivityActionButton
        text="Start Voting"
        onClick={onUpdateActivityView}
        className="mb-2 mt-1"
        delayReady
      />
    </Box>
  );
};

export default UserJourneyMomentsAdminKeyMoment;
