import * as Yup from 'yup';
import { VARIABLE_QUESTION_TYPES } from '../constants';

export const VARIABLE_QUESTIONS_PARTICIPANT_FIELDS_SCHEMA_MAP = {
  [VARIABLE_QUESTION_TYPES.SHORT_ANSWER]: Yup.string().required('Answer cannot be empty'),
  [VARIABLE_QUESTION_TYPES.LINEAR_SCALE]: Yup.string().required('You must make a selection'),
  [VARIABLE_QUESTION_TYPES.SINGLE_CHOICE]: Yup.string().required('You must make a selection'),
  [VARIABLE_QUESTION_TYPES.MULTIPLE_CHOICE]: Yup.array()
    .min(1)
    .required('You must make at least 1 selection'),
  [VARIABLE_QUESTION_TYPES.SENTIMENT]: Yup.string().required('You must make a selection'),
};
