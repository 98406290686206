import React from 'react';
import { useSelector } from 'react-redux';
import { useFirestore } from 'react-redux-firebase';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { map, includes } from 'lodash';
import cx from 'classnames';
// library components
import { Typography } from '@material-ui/core';
// CR components
import Topic from 'components/25:10/Admin/Topic';
import EditableTextWrapper from '../../EditableTextWrapper';
import ActivityParticipantDetails from 'components/ActivityParticipantDetails';
import ActivityActionButton from 'components/CustomButtons/ActivityActionButton';
// selectors
import {
  getIdeas,
  getIdeasToParticipantsRatio,
  getTopic,
  session2510Selector,
} from 'redux/selectors/25:10/root2510Selectors';
// utils
import { assignIdeas } from 'utils/25:10/voteHelper';
import { makeParticipantDetailsHeaderText } from 'utils/makeParticipantDetailsHeaderText';

const NEXT_ROUTE = 'vote';
const useStyles = makeStyles(theme => ({
  ratio: {
    fontSize: '50px',
    color: '#093B75',
    fontWeight: '300',
    marginBottom: '20px',
    marginRight: theme.spacing(2),
  },
  idea: {
    fontSize: '34px',
    fontWeight: '300',
    color: '#333333',
    marginBottom: '50px',
  },
  // button: {
  //   backgroundColor: '#02A05C',
  //   color: 'white',
  //   '&:hover': {
  //     backgroundColor: darken('#02A05C', 0.3),
  //   },

  // },
}));

const Admin2510Idea = ({ activityId }) => {
  const classes = useStyles();
  const db = useFirestore();
  const { participants } = useSelector(session2510Selector);
  const ideas = useSelector(getIdeas);
  const topic = useSelector(getTopic);
  const { numVotes, numParticipants } = useSelector(getIdeasToParticipantsRatio);

  const headerText = makeParticipantDetailsHeaderText(numVotes, numParticipants, 'added ideas');

  const handleSubmit = () => {
    const assignments = assignIdeas({ ideas, voteAssignments: {} });

    if (Object.values(assignments).filter(val => val.length === 6).length !== 5) {
      assignIdeas({ ideas, voteAssignments: {} });
    }

    const nextState = {
      activeView: NEXT_ROUTE,
      currentRound: 1,
      voteAssignments: assignments,
      gameFinished: false,
    };
    db.doc(`25:10/${activityId}`).set(nextState, { merge: true });
  };

  return (
    <div className="text-center">
      <EditableTextWrapper
        initialValue={topic}
        fieldType="textarea"
        fieldName="topic"
        firestoreCollection="25:10"
        documentId={activityId}
      >
        <Topic topic={topic} />
      </EditableTextWrapper>
      <div className="d-flex align-items-baseline justify-content-center">
        <ActivityParticipantDetails
          headerText={headerText}
          hasCompletedLabel="Added Idea"
          hasntCompletedLabel="Hasn't Added Idea"
          participantsCompletedStatus={map(participants, pId => {
            const ideaIds = map(ideas, idea => idea.id);
            return {
              id: pId,
              completed: includes(ideaIds, pId),
              //completedCount not needed - will only ever be 1.
            };
          })}
        />
      </div>
      <ActivityActionButton
        text="Start Voting"
        onClick={handleSubmit}
        className={cx('mb-2')}
        disabled={ideas.length < 6}
      />
      <Typography>
        <b>Note: </b>You need 6 ideas to be submitted before you can start voting
      </Typography>
    </div>
  );
};

export default Admin2510Idea;
