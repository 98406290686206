import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { map, remove, head, split, last, isEmpty, forEach } from 'lodash';
import { useFirestore } from 'react-redux-firebase';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
// library components
import { makeStyles, Box, Typography, Button } from '@material-ui/core';
import { Formik, Form } from 'formik';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
// cr components
import AddActivityButton from 'components/AddActivityButton';
import ActivityItem from './ActivityItem';
import ButtonSpinner from 'components/ButtonSpinner/ButtonSpinner';
// actions
import { enqueueSuccessSnackbar, enqueueErrorSnackbar } from 'redux/actions/notifierActions';
import { setSelectedFacilitatorRoute } from 'redux/actions/facilitatorSessionActions';

const useStyles = makeStyles(theme => ({
  addActivityButton: {
    width: '100%',
    height: 40,
    background: theme.palette.primary.main,
    color: '#fff !important',
    fontSize: 14,
    fontWeight: '500 !important',
    textTransform: 'uppercase !important',
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24)',
    '&:hover': {
      background: theme.palette.primary.main,
    },
  },
  activitiesContainer: {
    marginTop: 30,
  },
  activitiesTitle: {
    marginBottom: theme.spacing(2),
    fontSize: 12,
    fontWeight: 300,
    color: '#5e5e5e',
  },
  cancelButton: {
    width: 105,
    height: 40,
    color: '#fff',
    background: '#9E9E9E',
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24)',
    '&:hover': {
      background: '#9E9E9E',
    },
  },
  saveButton: {
    width: 105,
    height: 40,
    color: '#fff',
    background: theme.palette.primary.main,
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24)',
    '&:hover': {
      background: theme.palette.primary.main,
    },
  },
  spinner: {
    marginLeft: '0 !important',
    color: '#fff',
  },
}));

/**
 * Edit (re-order activities, delete activity, edit activity settings) activities to the current session.
 */
const EditActivityForm = ({ isUserAccountLimit, session, closeDialog }) => {
  const classes = useStyles();
  const db = useFirestore();
  const dispatch = useDispatch();
  const history = useHistory();

  const [activities, setActivities] = useState(session.activities);
  const [deletedActivities, setDeletedActivities] = useState([]);

  useEffect(() => {
    setActivities(session.activities);
  }, [session.activities]);

  // re-order activity
  const reorderActivity = (activitiesList, startIndex, endIndex) => {
    const result = [...activitiesList];
    const removedActivity = remove(result, (activity, idx) => idx === startIndex);
    result.splice(endIndex, 0, head(removedActivity));
    return result;
  };

  // user finish drag
  const onDragEnd = result => {
    const { destination, source } = result;
    if (!destination) {
      return;
    }
    if (destination.index === source.index) {
      return;
    }
    const newActivities = reorderActivity(activities, source.index, destination.index);
    setActivities(newActivities);
  };

  // delete activity from editing list
  const onDeleteActivity = (activityId, activityType) => {
    const result = [...activities];
    remove(result, activity => activity.id === activityId);
    setActivities(result);
    setDeletedActivities(prev => [
      ...prev,
      {
        id: activityId,
        activity: activityType,
      },
    ]);
  };

  const renderSessionActivities = () =>
    map(activities, (activity, idx) => (
      <ActivityItem
        key={activity.id}
        activityName={activity.name}
        activityId={activity.id}
        activityType={activity.activity}
        idx={idx}
        onDeleteActivity={onDeleteActivity}
      />
    ));

  // delete activity documant and change routes
  const deleteActivity = async sessionId => {
    const path = last(split(history.location.pathname, '/'));
    if (!isEmpty(deletedActivities)) {
      for (const { activity, id } of deletedActivities) {
        await db
          .collection(`${activity}`)
          .doc(`${id}`)
          .delete()
          .then(() => {
            if (id === path) {
              dispatch(setSelectedFacilitatorRoute('slides', sessionId));
              history.push(`/admin/sessions/${session.id}/slides`);
            }
          });
      }
    }
  };

  return (
    <Formik
      initialValues={activities}
      enableReinitialize
      onSubmit={async (values, { setSubmitting }) => {
        let isRouteChange = false;

        forEach(deletedActivities, ({ activity, id }) => {
          isRouteChange = `${activity}/${id}` === session.activeRoute || isRouteChange;
        });

        const data = {
          activities: [...values],
          activeRoute: isRouteChange ? 'slides' : session.activeRoute,
        };

        db.doc(`sessions/${session.id}`)
          .update({
            ...data,
          })
          .then(async () => {
            await deleteActivity(session.id);
            setSubmitting(false);
            closeDialog();
            dispatch(enqueueSuccessSnackbar('Saved'));
          })
          .catch(err => {
            setSubmitting(false);
            closeDialog();
            dispatch(enqueueErrorSnackbar('Error saving activities'));
          });
      }}
    >
      {({ isSubmitting, submitForm }) => (
        <Form>
          <Box>
            <AddActivityButton
              className={classes.addActivityButton}
              isUserAccountLimit={isUserAccountLimit}
              withIcon={false}
            />
            <Box className={classes.activitiesContainer}>
              <Typography className={classes.activitiesTitle}>Current Activities</Typography>
            </Box>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="re-order-activities" type="activities">
                {(provided, snapshot) => (
                  <div ref={provided.innerRef} {...provided.droppableProps} className="w-100">
                    {renderSessionActivities()}
                    <div>{provided.placeholder}</div>
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Box>
          <div className={cx('form-dialog-buttons', 'justify-content-between')}>
            <Button className={classes.cancelButton} disabled={isSubmitting} onClick={closeDialog}>
              CANCEL
            </Button>
            <Button className={classes.saveButton} disabled={isSubmitting} onClick={submitForm}>
              {isSubmitting ? <ButtonSpinner className={classes.spinner} /> : 'SAVE'}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

EditActivityForm.propTypes = {
  isUserAccountLimit: PropTypes.bool, // the account limit depending on the user's role
  session: PropTypes.object.isRequired, // the current session data
  closeDialog: PropTypes.func.isRequired, // function close Form dialog
};

export default EditActivityForm;
