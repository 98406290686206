import React from 'react';
import { useSelector } from 'react-redux';
import { take } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
// CR components
import Results2510List from 'components/25:10/Shared/Results2510List';
import Results2510Header from 'components/25:10/Shared/Results2510Header';
// selectors
import { getResults, getTopic } from 'redux/selectors/25:10/root2510Selectors';

const useStyles = makeStyles({
  topic: {
    marginBottom: '49px',
  },
  top: {
    marginBottom: '52px',
  },
  votes: {
    marginBottom: '51px',
    width: '100%',
    maxWidth: '1200px',
  },
});

const Participant2510Results = () => {
  const classes = useStyles();
  const topic = useSelector(getTopic);
  const ideas = useSelector(getResults);

  if (!ideas || !ideas.length) {
    return null;
  }

  const topIdeas = take(ideas, 10);

  return (
    <>
      <Results2510Header topic={topic} classes={{ topic: classes.topic, top: classes.top }} />
      <Results2510List classes={classes.votes} ideas={topIdeas} />
    </>
  );
};

export default Participant2510Results;
