/*global gapi*/
/*global google*/

import './googlePicker.scss';

import { useState, useEffect } from 'react';

const useGooglePicker = (accessToken, callback) => {
  const [picker, setPicker] = useState(false);

  useEffect(() => {
    if (accessToken) {
      if (!window.onApiLoad) {
        window.onApiLoad = onApiLoad;
        const script = document.createElement('script');
        script.src = `https://apis.google.com/js/api.js?onload=onApiLoad`;
        script.async = true;
        document.body.appendChild(script);
      } else {
        onApiLoad();
      }
    }
  }, [accessToken]); // eslint-disable-line react-hooks/exhaustive-deps

  const onApiLoad = () => {
    gapi.load('picker', onPickerApiLoad);
  };

  const onPickerApiLoad = () => {
    const picker = new google.picker.PickerBuilder()
      .addView(google.picker.ViewId.PRESENTATIONS)
      .setOAuthToken(accessToken.access_token)
      .setDeveloperKey(process.env.REACT_APP_FIREBASE_API_KEY)
      .setCallback(data => {
        if (data.action === google.picker.Action.PICKED) {
          callback(data.docs[0].id);
        }
      })
      .build();
    setPicker(picker);
  };

  return picker;
};

export default useGooglePicker;
