import { enqueueErrorSnackbar } from 'redux/actions/notifierActions';
import { includes, get } from 'lodash';
import { createOutput } from 'utils/heatMapping';

import { HEAT_MAPPING_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';

/**
 * Update the active view for the activity
 * @param {String} view
 * @param {String} heatMappingId
 */
export const updateActivityView =
  (view, heatMappingId) =>
  (dispatch, getState, { getFirebase }) => {
    if (!includes(CONFIG.views, view)) {
      console.error(`View '${view}' does not exist in the activity config`);
      return;
    }

    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${heatMappingId}`)
      .set(
        {
          state: {
            activeView: view,
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error changing activity view'));
      });
  };

/**
 * Update the active view for the activity
 * @param {String} heatMappingId
 */
export const togglePointsVisibility =
  heatMappingId =>
  (dispatch, getState, { getFirebase }) => {
    const isPointsHidden = getState().firestore.data.heatMapping?.state?.isPointsHidden ?? false;
    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${heatMappingId}`)
      .set(
        {
          state: {
            isPointsHidden: !isPointsHidden,
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error changing points flag in DB'));
      });
  };

/**
 * Update the active view for the activity
 * @param {String} heatMappingId
 */
export const toggleAdminImageReorder =
  heatMappingId =>
  (dispatch, getState, { getFirebase }) => {
    const isReordering = getState().firestore.data.heatMapping?.state?.isReordering ?? false;
    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${heatMappingId}`)
      .set(
        {
          state: {
            isReordering: !isReordering,
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error changing reordering flag in DB'));
      });
  };

/**
 * Update completed upload for a admin view
 *
 * @param {String} heatMappingId
 * @param {String} sketchId
 */
export const updateSketchCompletedHeatMapping =
  (heatMappingId, sketchId, completed) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${heatMappingId}`)
      .set(
        {
          participantData: {
            sketches: {
              [sketchId]: {
                completed: completed,
              },
            },
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error update completed heat-mapping sketch'));
      });
  };

/**
 * Update current sketch
 *
 * @param {String} heatMappingId
 * @param {String} sketchId
 */
export const updateCurrentSketch =
  (heatMappingId, sketchId) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${heatMappingId}`)
      .set(
        {
          state: {
            currentSketch: sketchId,
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error update current sketch'));
      });
  };

/**
 * Create image
 *
 * @param {String} heatMappingId
 * @param {String} imageId
 * @param {String} participantId
 * @param {Number} order
 */
export const createImage =
  (heatMappingId, id, participantId, url, order) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${heatMappingId}`)
      .set(
        {
          participantData: {
            images: {
              [id]: { id, participantId, url, order },
            },
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error create image'));
      });
  };

/**
 * Update images in participantData
 *
 * @param {String} heatMappingId
 * @param {Object} imageData
 */
export const updateImages =
  (heatMappingId, imageData) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${heatMappingId}`)
      .update({
        'participantData.images': imageData,
      })
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error update images'));
      });
  };

/**
 * Create sketch
 *
 * @param {String} heatMappingId
 * @param {String} participantId
 * @param {Array} images
 */
export const createSketch =
  (heatMappingId, sketchId, participantId, images) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${heatMappingId}`)
      .set(
        {
          participantData: {
            sketches: {
              [`${sketchId}`]: {
                id: sketchId,
                participantId: participantId,
                images: images,
                completed: false,
                comments: {},
              },
            },
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error create sketch'));
      });
  };

/**
 * Update voted participants for sketch
 *
 * @param {String} heatMappingId
 * @param {String} sketchId
 * @param {String} participantId
 * @param {Boolean} voted
 */
export const updateParticipantSketchVotedFor =
  (heatMappingId, sketchId, voted, participantId) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;
    const value = voted
      ? db.FieldValue.arrayUnion(participantId)
      : db.FieldValue.arrayRemove(participantId);

    db()
      .doc(`${CONFIG.collection}/${heatMappingId}`)
      .set(
        {
          participantData: {
            sketches: {
              [sketchId]: {
                votedFor: value,
              },
            },
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error update sketch voted participants'));
      });
  };

/**
 * Update participant comment for sketch
 *
 * @param {String} heatMappingId
 * @param {String} sketchId
 * @param {String} participantId
 * @param {String} comment
 */
export const updateParticipantSketchComment =
  (heatMappingId, sketchId, participantId, comment) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${heatMappingId}`)
      .set(
        {
          participantData: {
            sketches: {
              [sketchId]: {
                comments: {
                  [participantId]: comment,
                },
              },
            },
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error update sketch participant comment'));
      });
  };

/**
 * Update sketch images
 *
 * @param {String} heatMappingId
 * @param {String} sketchId
 * @param {Array} images
 */
export const updateSketchImages =
  (heatMappingId, sketchId, images) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${heatMappingId}`)
      .set(
        {
          participantData: {
            sketches: {
              [`${sketchId}`]: {
                images: images,
              },
            },
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error update sketch images'));
      });
  };

/**
 * Remove image from sketch
 *
 * @param {String} heatMappingId
 * @param {String} sketchId
 * @param {String} imageId
 */
export const removeSketchImage =
  (heatMappingId, sketchId, imageId) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${heatMappingId}`)
      .set(
        {
          participantData: {
            sketches: {
              [`${sketchId}`]: {
                images: db.FieldValue.arrayRemove(imageId),
              },
            },
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error remove sketch images'));
      });
  };

/**
 * Add points for image
 *
 * @param {String} heatMappingId
 * @param {String} imageId
 * @param {String} participantId
 * @param {Object} points
 */
export const addImagePoints =
  (heatMappingId, imageId, participantId, points) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;
    const path = `participantData.images.${imageId}.points.${participantId}`;

    db()
      .doc(`${CONFIG.collection}/${heatMappingId}`)
      .update({
        [path]: points,
      })
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error update image points for participant'));
      });
  };

/**
 * Update points for image
 *
 * @param {String} heatMappingId
 * @param {String} imageId
 * @param {String} participantId
 * @param {Object} points
 */
export const updateImagePoints =
  (heatMappingId, imageId, points) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;
    const path = `participantData.images.${imageId}.points`;

    db()
      .doc(`${CONFIG.collection}/${heatMappingId}`)
      .update({
        [path]: points,
      })
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error update image points'));
      });
  };

/**
 * Update sketch comments
 *
 * @param {String} heatMappingId
 * @param {String} sketchId
 * @param {String} bigIdeas
 * @param {String} concerns
 */
export const updateSketchComments =
  (heatMappingId, sketchId, bigIdeas, concerns) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${heatMappingId}`)
      .set(
        {
          participantData: {
            sketches: {
              [`${sketchId}`]: {
                adminComments: {
                  bigIdeas,
                  concerns,
                },
              },
            },
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error update sketch comments'));
      });
  };

/**
 * Update super vote participant id
 *
 * @param {String} heatMappingId
 * @param {String} participantId
 */
export const updateSuperVoteParticipant =
  (heatMappingId, participantId) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${heatMappingId}`)
      .set(
        {
          state: {
            superVoteParticipantId: participantId,
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error update super vote heat-mapping participant'));
      });
  };

/**
 * Add super vote points
 *
 * @param {String} heatMappingId
 * @param {String} sketchId
 * @param {String} superVotePoints
 */
export const addSuperVotePoints =
  (heatMappingId, sketchId, superVotePoints) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${heatMappingId}`)
      .set(
        {
          participantData: {
            sketches: {
              [sketchId]: {
                superVotePoints: superVotePoints,
              },
            },
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error update sketch super vote points'));
      });
  };

/**
 * Update sketch review image
 *
 * @param {String} heatMappingId
 * @param {String} sketchId
 * @param {String} imageId
 */
export const updateSketchReviewImage =
  (heatMappingId, sketchId, imageId) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${heatMappingId}`)
      .set(
        {
          participantData: {
            sketches: {
              [`${sketchId}`]: {
                reviewImageId: imageId,
              },
            },
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error update sketch review image'));
      });
  };

/**
 * Remove sketch
 *
 * @param {String} heatMappingId
 * @param {String} sketchId
 * @param {Function} successCb
 */
export const removeSketch =
  (heatMappingId, sketchId, successCb) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;
    const path = `participantData.sketches.${sketchId}`;

    db()
      .doc(`${CONFIG.collection}/${heatMappingId}`)
      .update({
        [path]: db.FieldValue.delete(),
      })
      .then(() => successCb)
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error remove sketch'));
      });
  };

/**
 * Create the activity output and update the view to 'results'
 * @param {String} heatMappingId
 */
export const revealResults =
  heatMappingId =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;
    const state = getState();
    const { sketches, images } = state.firestore.data.heatMapping.participantData;
    const output = createOutput(sketches, images);

    db()
      .doc(`${CONFIG.collection}/${heatMappingId}`)
      .set(
        {
          output,
        },
        { merge: true },
      )
      .then(() => {
        dispatch(updateActivityView('results', heatMappingId));
      })
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error generating results'));
      });
  };
