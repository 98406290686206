import firebase from 'firebase/app';
import 'firebase/storage';
import imageCompression from 'browser-image-compression';

/**
 * Custom class for integrating firebase storage uploads
 * with CK Editor
 */
class CkEditorUploadAdatper {
  constructor(loader, sessionId) {
    this.loader = loader;
    this.sessionId = sessionId;
    this.canUpload = true;
    this.isUploading = false;
    this.uploadTask = {
      cancel: () => {},
    };
  }

  upload() {
    const loader = this.loader;
    const sessionId = this.sessionId;

    return loader.file.then(
      file =>
        new Promise((resolve, reject) => {
          (async file => {
            const options = {
              maxSizeMB: 1,
              maxWidthOrHeight: 720,
            };

            try {
              const compressedFile = await imageCompression(file, options);
              const storageRef = firebase
                .storage()
                .ref(`sessions/${sessionId}/images/${compressedFile.name}`);

              if (this.canUpload) {
                this.isUploading = true;
                this.uploadTask = await storageRef.put(compressedFile);
                const url = await storageRef.getDownloadURL();
                this.isUploading = false;
                this.canUpload = true;
                resolve({ default: url });
              } else {
                reject('upload cancelled');
              }
            } catch (err) {
              reject(err);
            }
          })(file);
        }),
    );
  }

  // Aborts the upload process.
  abort() {
    if (!this.isUploading) {
      this.canUpload = false;
    } else {
      this.uploadTask.cancel();
    }
  }
}

export default function CustomUploadAdatperPlugin(editor) {
  const sessionId = editor.config.get('sessionId');

  if (!sessionId) throw 'no sessionId passed to upload adapter';

  editor.plugins.get('FileRepository').createUploadAdapter = loader => {
    return new CkEditorUploadAdatper(loader, sessionId);
  };
}
