import React from 'react';
import PropTypes from 'prop-types';
import { useFirestore } from 'react-redux-firebase';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
// library components
import { Formik, Form } from 'formik';
import { Button } from '@material-ui/core';
// cr components
import ButtonSpinner from 'components/ButtonSpinner/ButtonSpinner';
import TextInput from 'components/CustomFormComponents/TextInput';
// actions
import { enqueueErrorSnackbar } from 'redux/actions/notifierActions';
// utils
import { getFacilitatorSessionType } from 'utils/getFacilitatorSessionType';

/**
 * Form used by facilitators to update the sessio title/topic
 */
const RenameSessionForm = ({ closeDialog, sessionId }) => {
  const db = useFirestore();
  const dispatch = useDispatch();
  const sessionType = getFacilitatorSessionType();
  const session = useSelector(state => state.firestore.data[sessionType][sessionId]);

  const formSchema = Yup.object().shape({
    topic: Yup.string().required('Required'),
  });

  return (
    <Formik
      initialValues={{
        topic: session.topic,
      }}
      validationSchema={formSchema}
      onSubmit={async values => {
        return db
          .doc(`sessions/${session.id}`)
          .update({
            topic: values.topic,
          })
          .then(() => {
            closeDialog();
          })
          .catch(err => {
            dispatch(enqueueErrorSnackbar('Error updating meeting name'));
            console.log('Error updating meeting name: ', err);
          });
      }}
    >
      {({ isSubmitting, submitForm, resetForm }) => (
        <Form style={{ paddingBottom: 20 }}>
          <TextInput name="topic" type="text" label="Title/Topic" className="mb-4" required />

          <div className="form-dialog-buttons">
            <Button
              variant="outlined"
              color="primary"
              disabled={isSubmitting}
              onClick={() => {
                closeDialog();
                resetForm();
              }}
            >
              CANCEL
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={submitForm}
            >
              SAVE
              {isSubmitting && <ButtonSpinner />}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

RenameSessionForm.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  sessionId: PropTypes.string.isRequired, // id of the meeting/session being
};

export default RenameSessionForm;
