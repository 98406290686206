import React from 'react';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
// cr components
import ActivityTemplate from 'components/ActivityTemplate/ActivityTemplate';
import LightningDemoParticipantUpload from 'components/LightningDemo/Participant/LightningDemoParticipantUpload';
import LightningDemoParticipantReview from 'components/LightningDemo/Participant/LightningDemoParticipantReview';
import LightningDemoParticipantResults from 'components/LightningDemo/Participant/LightningDemoParticipantResults';
// selectors
import { useLightningDemoSelector } from 'redux/selectors/lightningDemoSelectors';

//add new view for all action
const views = {
  upload: LightningDemoParticipantUpload,
  review: LightningDemoParticipantReview,
  results: LightningDemoParticipantResults,
};

const LightningDemoParticipant = ({
  match: {
    params: { activityId },
  },
}) => {
  const lightningDemo = useLightningDemoSelector();

  // hooks up a firestore listener to Redux
  useFirestoreConnect(() => [
    {
      collection: 'lightningDemo',
      doc: `${activityId}`,
      storeAs: 'lightningDemo',
    },
  ]);

  // ensure all of the data we need is loaded
  if (!isLoaded(lightningDemo) || activityId !== lightningDemo?.id) {
    return null;
  }

  const Component = views[lightningDemo.state.activeView];

  return (
    <ActivityTemplate type="Lightning Demo" name={lightningDemo.name}>
      <Component />
    </ActivityTemplate>
  );
};

export default LightningDemoParticipant;
