import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { includes } from 'lodash';

// 3rd part components
import { Typography } from '@material-ui/core';

// cr components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import PageContent from 'components/PageContent/PageContent';
import PageContentHeader from 'components/PageContent/PageContentHeader';
import PageContentBody from 'components/PageContent/PageContentBody';
import FreeSubscriptionForm from 'components/Forms/FreeSubscriptionForm';

const FreeSubscriptionInvite = () => {
  const history = useHistory();
  const authUserEmail = useSelector(state => state.firebase.auth.email);
  const allowedEmails = [
    'john@voltagecontrol.com',
    'douglas@voltagecontrol.com',
    'reggie.ofarrell@gmail.com',
  ];

  if (!includes(allowedEmails, authUserEmail)) {
    history.push('/admin');
  }

  return (
    <GridContainer direction="row" justify="center" alignItems="center">
      <GridItem xs={12}>
        <PageContent>
          <PageContentHeader>
            <Typography variant="h5" className="page-content-title">
              Grant Free Subscriptions
            </Typography>
          </PageContentHeader>
          <PageContentBody>
            <FreeSubscriptionForm />
          </PageContentBody>
        </PageContent>
      </GridItem>
    </GridContainer>
  );
};

export default FreeSubscriptionInvite;
