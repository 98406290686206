import { enqueueErrorSnackbar } from 'redux/actions/notifierActions';
import { includes } from 'lodash';

import { generateRandomId } from 'utils/generateRandomId';
import { createCategorizeOutput, createResults, createOutput } from 'utils/noteAndCategorize';

import { NOTE_AND_CATEGORIZE_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';

/**
 * Create a note
 *
 * @param {String} note
 * @param {String} participantId
 * @param {String} noteAndCategorizeId
 * @param {Number} order
 */
export const createNote =
  (note, participantId, noteAndCategorizeId, order, successCb) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;
    const newNoteId = generateRandomId();

    db()
      .doc(`${CONFIG.collection}/${noteAndCategorizeId}`)
      .set(
        {
          participantData: {
            notes: {
              [`${newNoteId}`]: {
                participantId,
                noteId: newNoteId,
                note,
                selected: false,
                categoryId: '',
                votedFor: [],
                order,
              },
            },
          },
        },
        { merge: true },
      )
      .then(() => {
        successCb();
      })
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error creating note'));
      });
  };

/**
 * Edit a note that has already been submitted
 * @param {String} note
 * @param {String} noteId
 * @param {String} noteAndCategorizeId
 * @param {Function} successCb
 */
export const editNote =
  (note, noteId, noteAndCategorizeId, successCb) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${noteAndCategorizeId}`)
      .set(
        {
          participantData: {
            notes: {
              [`${noteId}`]: {
                note,
              },
            },
          },
        },
        { merge: true },
      )
      .then(() => {
        successCb();
      })
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error editing note'));
      });
  };

/**
 * Remove a note
 *
 * @param {String} noteAndCategorizeId
 * @param {String} noteId
 */
export const removeNote =
  (noteAndCategorizeId, noteId) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;
    const path = `participantData.notes.${noteId}`;

    db()
      .doc(`${CONFIG.collection}/${noteAndCategorizeId}`)
      .update({
        [path]: db.FieldValue.delete(),
      })
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error removing note'));
      });
  };

/**
 * Update a note
 *
 * @param {Object} note
 * @param {String} noteId
 * @param {String} noteAndCategorizeId
 */
export const updateNote =
  (note, noteId, noteAndCategorizeId) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${noteAndCategorizeId}`)
      .set(
        {
          participantData: {
            notes: {
              [`${noteId}`]: note,
            },
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error update note'));
      });
  };

/**
 * Update category status
 *
 * @param {String} noteAndCategorizeId
 * @param {String} categoryId
 */
export const updateCategoryStatus =
  (noteAndCategorizeId, categoryId) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${noteAndCategorizeId}`)
      .set(
        {
          state: {
            isCategorized: db.FieldValue.arrayUnion(categoryId),
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error update category status'));
      });
  };

/**
 * Update current category
 *
 * @param {String} noteAndCategorizeId
 * @param {String} categoryId
 */
export const updateCurrentCategory =
  (noteAndCategorizeId, categoryId = '') =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${noteAndCategorizeId}`)
      .set(
        {
          participantData: {
            currentCategoryId: categoryId,
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error update current category'));
      });
  };

/**
 * Update previous category
 *
 * @param {String} noteAndCategorizeId
 * @param {String} categoryId
 */
export const updatePreviousCategory =
  (noteAndCategorizeId, categoryId = '') =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${noteAndCategorizeId}`)
      .set(
        {
          participantData: {
            previousCategoryId: categoryId,
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error update previous category'));
      });
  };

/**
 * Update a participant's round robin status
 *
 * @param {String} noteAndCategorizeId
 * @param {String} participantId
 */
export const updateParticipantRoundRobinStatus =
  (noteAndCategorizeId, participantId, removeParticipant) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    const participantsCompletedRoundRobin = removeParticipant
      ? db.FieldValue.arrayRemove(participantId)
      : db.FieldValue.arrayUnion(participantId);

    db()
      .doc(`${CONFIG.collection}/${noteAndCategorizeId}`)
      .set(
        {
          participantData: {
            participantsCompletedRoundRobin: participantsCompletedRoundRobin,
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar("Error update participant's round robin status"));
      });
  };

/**
 * Update current participant
 *
 * @param {String} noteAndCategorizeId
 * @param {Object} participant
 */
export const updateCurrentParticipant =
  (noteAndCategorizeId, participant) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${noteAndCategorizeId}`)
      .set(
        {
          participantData: {
            participantRoundRobin: participant,
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error update current participant'));
      });
  };

/**
 * Add participant votes note
 * @param {String} noteAndCategorizeId
 * @param {String} noteId
 * @param {String} participantId
 */
export const addParticipantVote =
  (noteAndCategorizeId, noteId, participantId) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${noteAndCategorizeId}`)
      .set(
        {
          participantData: {
            notes: {
              [noteId]: {
                votedFor: db.FieldValue.arrayUnion(participantId),
              },
            },
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error add participant votes'));
      });
  };

/**
 * Remove participant votes note
 * @param {String} noteAndCategorizeId
 * @param {String} noteId
 * @param {String} participantId
 */
export const removeParticipantVote =
  (noteAndCategorizeId, noteId, participantId) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${noteAndCategorizeId}`)
      .set(
        {
          participantData: {
            notes: {
              [noteId]: {
                votedFor: db.FieldValue.arrayRemove(participantId),
              },
            },
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error remove participant votes'));
      });
  };

/**
 * Swap a participant vote for another
 * @param {String} noteIdToVoteFor
 * @param {String} noteIdToUnvote
 * @param {String} noteAndCategorizeId
 * @param {String} participantId
 */
export const swapParticipantVote =
  (noteAndCategorizeId, participantId, noteIdToVoteFor, noteIdToUnvote) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${noteAndCategorizeId}`)
      .set(
        {
          participantData: {
            notes: {
              [noteIdToVoteFor]: {
                votedFor: db.FieldValue.arrayUnion(participantId),
              },
              [noteIdToUnvote]: {
                votedFor: db.FieldValue.arrayRemove(participantId),
              },
            },
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error changing vote'));
      });
  };

/**
 * Update the active view for the activity
 * @param {String} view
 * @param {String} noteAndCategorizeId
 */
export const updateActivityView =
  (view, noteAndCategorizeId) =>
  (dispatch, getState, { getFirebase }) => {
    if (!includes(CONFIG.views, view)) {
      console.error(`View '${view}' does not exist in the activity config`);
      return;
    }

    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${noteAndCategorizeId}`)
      .set(
        {
          state: {
            activeView: view,
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error changing activity view'));
      });
  };

/**
 * Create the activity output and update the view to 'results'
 * @param {String} noteAndCategorizeId
 * @param {String} nextView
 * @param {Boolean} afterVote
 */
export const revealResults =
  (noteAndCategorizeId, nextView, afterVote) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;
    const state = getState();
    const { categories } = state.firestore.data[CONFIG.collection].config;
    const { notes } = state.firestore.data[CONFIG.collection].participantData;
    let results = state.firestore.data[CONFIG.collection].state?.results || {};
    const output = createOutput(categories, notes);

    if (afterVote) {
      results = createResults(results, notes);
    } else {
      results = createCategorizeOutput(notes);
    }

    db()
      .doc(`${CONFIG.collection}/${noteAndCategorizeId}`)
      .set(
        {
          state: {
            results: results,
          },
          output,
        },
        { merge: true },
      )
      .then(() => {
        dispatch(updateActivityView(nextView, noteAndCategorizeId));
      })
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error generating results'));
      });
  };

/**
 * Update Serial Round Robin status
 *
 * @param {String} noteAndCategorizeId
 * @param {String} categoryId
 * @param {String} activeView
 */
export const updateSerialRoundRobinStatus =
  (noteAndCategorizeId, categoryId, activeView) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${noteAndCategorizeId}`)
      .set(
        {
          state: {
            serialRoundRobin: categoryId,
            activeView,
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error update Serial Round Robin status'));
      });
  };

/**
 * Update combine notes
 * @param {String} noteAndCategorizeId
 * @param {String} noteId
 * @param {String} combineNoteId
 * @param {Boolean} combine
 */
export const updateCombineNotes =
  (noteAndCategorizeId, noteId, combineNoteId, combine) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;
    const combineNotesId = combine
      ? db.FieldValue.arrayUnion(combineNoteId)
      : db.FieldValue.arrayRemove(combineNoteId);

    db()
      .doc(`${CONFIG.collection}/${noteAndCategorizeId}`)
      .set(
        {
          participantData: {
            notes: {
              [`${noteId}`]: {
                combineNotesId,
              },
              [`${combineNoteId}`]: {
                combineParentNote: combine ? noteId : '',
                isCombine: combine,
              },
            },
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error combine notes'));
      });
  };
