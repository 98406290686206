import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { startCase, join, split, map, mapValues, isString } from 'lodash';
// library components
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { CSVLink } from 'react-csv';
// TODO:...import { parse as parseJson } from 'json2csv'
import { makeStyles, Button, IconButton } from '@material-ui/core';
import ArchiveIcon from '@material-ui/icons/Archive';

const useClasses = makeStyles(theme => ({
  downloadButton: {
    paddingLeft: 0,
    marginRight: 'auto',
    color: theme.palette.primary.main,
    fontSize: 15,
    fontWeight: 400,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  csvLink: {
    color: theme.palette.primary.main,
  },
}));

const CSVReportDownloadButton = ({
  csvReport,
  headers,
  topic,
  activity,
  activityName,
  filename,
  buttonText,
  className,
  withoutLabel,
}) => {
  const classes = useClasses();
  const smallScreen = useMediaQuery('(max-width: 960px)');

  const sessionTopicStartCase = startCase(topic);
  const sessionTopic = join(split(sessionTopicStartCase, ' '), '-');

  const nameStartCase = startCase(activityName);
  const name = join(split(nameStartCase, ' '), '-');

  const escapedCSVReport = map(csvReport, row =>
    mapValues(row, value => (isString(value) ? value.replace(/"/g, '""') : value)),
  );
  // TODO: Does json2csv - do this properly? Note: CSVDownload can take a string, Maybe we just have both link and zip use strings for consistency.
  // TODO: Move this up to a shared place???
  // TODO: CSVLink can work with string just link our downloader, soo... maybe that's the preferred shared format?

  return !smallScreen && !withoutLabel ? (
    <Button
      className={cx(classes.downloadButton, className)}
      startIcon={<ArchiveIcon color="primary" />}
    >
      <CSVLink
        data={csvReport}
        headers={headers}
        filename={`${sessionTopic}-${activity}-${name}-${filename}`}
        className={classes.csvLink}
        target="_blank"
      >
        {buttonText}
      </CSVLink>
    </Button>
  ) : (
    <IconButton className={className}>
      <CSVLink
        data={escapedCSVReport}
        headers={headers}
        filename={`${sessionTopic}-${activity}-${name}-${filename}`}
        className="btn btn-primary"
        target="_blank"
        style={{ lineHeight: '18px' }}
      >
        <ArchiveIcon color="primary" />
      </CSVLink>
    </IconButton>
  );
};

CSVReportDownloadButton.propTypes = {
  csvReport: PropTypes.array.isRequired, // represents the CSV data. This data can be array of arrays, array of literal objects or string.
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      // specifying headers helps to define an order of the CSV fields.
      label: PropTypes.string, // label to display at the top of the CSV
      key: PropTypes.string, // key to the data
    }),
  ).isRequired,
  topic: PropTypes.string.isRequired, // meeting topic
  activity: PropTypes.string.isRequired, // activity type
  activityName: PropTypes.string.isRequired, // activity name
  filename: PropTypes.string.isRequired, // end of file name (Result.csv)
  buttonText: PropTypes.string.isRequired, // button text
  className: PropTypes.string, // styles for button
  withoutLabel: PropTypes.bool, // the button label exists?
};

export default CSVReportDownloadButton;
