import React from 'react';
import { useSelector } from 'react-redux';
import { getResults, getTopic } from 'redux/selectors/25:10/root2510Selectors';
import { makeStyles } from '@material-ui/core/styles';

import Results2510Header from 'components/25:10/Shared/Results2510Header';

const useStyles = makeStyles({
  topic: {
    marginBottom: '49px',
  },
  topVoted: {
    marginBottom: '52px',
  },
  votes: {
    marginBottom: '79px',
  },
  button: {
    color: 'white',
  },
  startNewSession: {
    backgroundColor: '#02A05C',
  },
  exitSession: {
    backgroundColor: '#093B75',
  },
});
const Admin2510Results = () => {
  const classes = useStyles();

  const topic = useSelector(getTopic);
  const ideas = useSelector(getResults);

  if (!ideas || !ideas.length) {
    return null;
  }

  return (
    <Results2510Header topic={topic} classes={{ topic: classes.topic, top: classes.topVoted }} />
  );
};

export default Admin2510Results;
