import React from 'react';
import { find, sortBy, map, orderBy } from 'lodash';
import { useSelector } from 'react-redux';
// library components
import { Box } from '@material-ui/core';
// cr components
import StoryFlow from 'components/StoryFlow/Participant/StoryFlow';
// selectors
import { useStoryFlowSelector } from 'redux/selectors/storyFlowSelectors';
// utils
import { getCountSelectedFlows } from 'utils/storyFlow';

/**
 * Story Flow view for the 'results' phase on the Participant side
 */
const StoryFlowParticipantResults = () => {
  const {
    participantData: { participantsSuperVoteFlow, flows, participants, participantsSelectedFlow },
    state: { activeView },
  } = useStoryFlowSelector();
  // get super vote flow
  const superVoteFlow = find(
    participantsSuperVoteFlow,
    participant => !!participant.superVoteFlowId,
  );
  const votedFlow = find(flows, flow => flow.participantId === superVoteFlow?.superVoteFlowId);

  // renders all participants flows with 'superVote' results
  const renderParticipantsFlows = () => (
    <StoryFlow
      selectedFlowId={superVoteFlow?.superVoteFlowId}
      createdFlowParticipantId={votedFlow?.participantId}
      participantFlow={votedFlow?.values}
      votesCount={0}
      disabled={true}
      context={activeView}
      isFinished={true}
    />
  );

  return votedFlow ? <Box className="w-100">{renderParticipantsFlows()}</Box> : <></>;
};

export default StoryFlowParticipantResults;
