import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { filter } from 'lodash';
// library components
import { Box } from '@material-ui/core';
// cr components
import ActivityParticipantDetails from 'components/ActivityParticipantDetails';
import ActivityPromptAdmin from 'components/ActivityPromptAdmin';
import ActivityActionButton from 'components/CustomButtons/ActivityActionButton';
// selectors
import {
  useNoteAndVoteSelector,
  getParticipantsHaveVoted,
} from 'redux/selectors/noteAndVoteSelectors';
// actions
import { revealResults } from 'redux/actions/noteAndVoteActions';
// config
import { NOTE_AND_VOTE_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';
// utils
import { makeParticipantDetailsHeaderText } from 'utils/makeParticipantDetailsHeaderText';

/**
 * Note & Vote view for the 'vote' phase on the Facilitator side
 */
const NoteAndVoteAdminVote = () => {
  const dispatch = useDispatch();
  const {
    id,
    prompt,
    participantData: { participants },
    config,
  } = useNoteAndVoteSelector();
  const participantsHaveVotedData = useSelector(getParticipantsHaveVoted());
  const participantsVotedCount = filter(participantsHaveVotedData, { completed: true }).length;

  const onRevealResults = () => {
    dispatch(revealResults(id));
  };

  const participantsUploadedCountText = makeParticipantDetailsHeaderText(
    participantsVotedCount,
    participants.length,
    'voted',
  );

  return (
    <Box className="d-flex flex-column justify-content-center">
      <ActivityPromptAdmin
        prompt={prompt}
        collection={CONFIG.collection}
        activityId={id}
        className="mb-5"
      />
      <ActivityParticipantDetails
        headerText={participantsUploadedCountText}
        hasCompletedLabel="Voted"
        hasntCompletedLabel="Hasn't Voted"
        completedCountMax={config.voteLimit}
        participantsCompletedStatus={participantsHaveVotedData}
      />
      <ActivityActionButton
        text="Review Results"
        onClick={onRevealResults}
        className="mb-2 mt-1"
        delayReady
      />
    </Box>
  );
};

export default NoteAndVoteAdminVote;
