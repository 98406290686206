import React, { useRef } from 'react';
import { map } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
// materail ui components
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
// cr components
import GrayBox from 'components/GrayBox/GrayBox';
// selectors
import { useBrainwritingSelector } from 'redux/selectors/brainwritingSelectors';
import { getParticipantsMap } from 'redux/selectors/participantsSelectors';

const useStyles = makeStyles(theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: '500',
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 20,
  },
}));

const BrainwritingAdminResults = () => {
  const printRef = useRef();
  const classes = useStyles();
  const { ideas, topic } = useBrainwritingSelector();
  const meetingParticipants = useSelector(getParticipantsMap);

  const renderIdeasForPrint = () =>
    map(ideas, (idea, idx) => (
      <div className="mb-4" key={`idea_print_${idx}`}>
        <Typography className="mb-1">
          {meetingParticipants[idea.id] ? meetingParticipants[idea.id].name : 'Participant Unknown'}
        </Typography>
        <GrayBox className="text-left">
          <Typography className="white-space-pre-wrap">{idea.text}</Typography>
        </GrayBox>
      </div>
    ));

  return (
    <div ref={printRef}>
      <Box display="none" displayPrint="block" mb={1}>
        <Typography className={`${classes.title} mb-1 text-center`}>Prompt</Typography>
      </Box>
      <GrayBox className="mb-4">
        <Typography className={classes.topic}>{topic}</Typography>
      </GrayBox>
      <Box display="none" displayPrint="block">
        <Divider className="mb-4" />
      </Box>
      <Box display="none" displayPrint="block">
        <Typography className={`${classes.title} mb-4 text-center`}>Results</Typography>
      </Box>
      <Box displayPrint="block" display="none">
        {renderIdeasForPrint()}
      </Box>
    </div>
  );
};

export default BrainwritingAdminResults;
