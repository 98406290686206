import React from 'react';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
// cr components
import ActivityTemplate from 'components/ActivityTemplate/ActivityTemplate';
import ParticipantActivityContent from 'components/ParticipantActivityContent';
// import NarrowingParticipantCategorize from 'components/Narrowing/Participant/NarrowingParticipantCategorize';
// import NarrowingParticipantReview from 'components/Narrowing/Participant/NarrowingParticipantReview';
import NarrowingParticipantPrimaryCategorize from 'components/Narrowing/Participant/NarrowingParticipantPrimaryCategorize';
import NarrowingParticipantResults from 'components/Narrowing/Participant/NarrowingParticipantResults';
// selectors
import { useNarrowingSelector } from 'redux/selectors/narrowingSelectors';
// constants
import { NARROWING_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';

//add new view for all action
const views = {
  // categorize: NarrowingParticipantCategorize,
  // review: NarrowingParticipantReview,
  primaryCategorize: NarrowingParticipantPrimaryCategorize,
  results: NarrowingParticipantResults,
};

const NarrowingParticipant = ({
  match: {
    params: { activityId },
  },
}) => {
  const narrowing = useNarrowingSelector();
  console.log('activityId', activityId);

  // hooks up a firestore listener to Redux
  useFirestoreConnect(() => [
    {
      collection: 'narrowing',
      doc: `${activityId}`,
      storeAs: 'narrowing',
    },
  ]);

  // ensure all of the data we need is loaded
  if (!isLoaded(narrowing) || activityId !== narrowing?.id) {
    return null;
  }

  const Component = views[narrowing.state.activeView];

  const renderViewLabel = () => {
    switch (narrowing.state.activeView) {
      case 'results':
        if (narrowing.state.isActivityFinished) {
          return `Final Results`;
        } else {
          return `Review round ${narrowing.state.round} cards`;
        }
      default:
        return CONFIG.participantViewLabels[narrowing.state.activeView];
    }
  };

  return (
    <ActivityTemplate type="Narrowing" name={narrowing.name}>
      <ParticipantActivityContent
        activityData={narrowing}
        viewLabel={renderViewLabel()}
        activityConfig={CONFIG}
      >
        <Component />
      </ParticipantActivityContent>
    </ActivityTemplate>
  );
};

export default NarrowingParticipant;
