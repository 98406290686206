import { map, compact, orderBy, values, cloneDeep, includes } from 'lodash';

/**
 * Creates the output array for a Survey actvity
 * @param {Object} questions
 * @param {Object} answers
 * @returns {Array<{text: String, votes: Number}>}
 */
export const createOutput = (questions, answers) => {
  return map(parseSurveyQuestions(questions), question => ({
    text: question.value,
    answers: compact(map(answers, participantAnswers => participantAnswers[question.id])),
  }));
};

/**
 * Parses answer options out of stored question.  If answers are found after the
 * question...
 *
 * {option 1 | option 2 | option 3}
 *
 * ...we will parse those out into an array under an 'options' key and remove the
 * options from the question string
 *
 * @param {Array} questions
 * @returns {Array}
 */
export const parseSurveyQuestions = questions => {
  let theQuestions = cloneDeep(questions);

  if (!Array.isArray(theQuestions)) {
    theQuestions = orderBy(values(theQuestions), 'order', 'asc');
  }

  theQuestions.forEach((question, idx) => {
    const hasOptions = includes(question.value, '{') && includes(question.value, '}');

    if (hasOptions) {
      const parsedQuestion = question.value.split('{')[0].trim();
      const parsedOptions = question.value.split('{')[1].split('}')[0].split('|');

      theQuestions[idx].value = parsedQuestion;
      theQuestions[idx].options = parsedOptions;
    }
  });

  return theQuestions;
};

/**
 * Creates the array for Report Header
 *
 */
export const createSurveyReportHeader = () => {
  return [
    { label: 'Question', key: 'question' },
    { label: 'Answers', key: 'answers' },
  ];
};

export const createSurveyReportData = output => {
  // render data for csv report
  return map(output, question => {
    return {
      question: question.text,
      answers: question.answers || 0,
    };
  });
};

export const createSurveyReport = activityData => {
  const headers = createSurveyReportHeader();
  const data = createSurveyReportData(activityData.output);
  return { headers, data };
};
