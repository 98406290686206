import { createSelector } from 'reselect';
import { getParticipants } from '../participantsSelectors';
import { createRoomMapFromBreakouts } from 'utils/Breakouts/breakoutsHelper';

export const breakoutsSessionSelector = state => state.firestore.data.breakouts || {};

export const getRoomMap = createSelector([breakoutsSessionSelector], breakouts =>
  createRoomMapFromBreakouts(breakouts),
);

export const getBreakoutsTopic = createSelector(
  [breakoutsSessionSelector],
  breakouts => breakouts.topic || {},
);

export const getBreakoutsRooms = createSelector(
  [breakoutsSessionSelector],
  breakouts => breakouts.rooms || [],
);

export const getBreakoutsParticipant = participantId =>
  createSelector([getParticipants], participants =>
    participants.find(participant => participant.id === participantId),
  );

export const getParticipantRoomAssignments = createSelector(
  [breakoutsSessionSelector],
  breakouts => breakouts.participantRoomAssignments || [],
);

export const getParticipantRoom = participantId =>
  createSelector([getParticipantRoomAssignments, getBreakoutsRooms], (roomAssignments, rooms) => {
    const roomAssignment = roomAssignments.find(
      roomAssignment => roomAssignment.participantId === participantId,
    );

    if (!roomAssignment) {
      return null;
    }

    return rooms.find(room => room.id === roomAssignment.roomId);
  });

export const getBreakoutsParticipantsForRoom = roomId =>
  createSelector(
    [getParticipantRoomAssignments, getParticipants],
    (roomAssignments, participants) => {
      const participantAssignments = roomAssignments.filter(
        roomAssignment => roomAssignment.roomId === roomId,
      );

      return participants.filter(participant =>
        participantAssignments.find(assignment => participant.id === assignment.participantId),
      );
    },
  );
