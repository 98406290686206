import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFirestore } from 'react-redux-firebase';
import cx from 'classnames';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { findIndex, forEach } from 'lodash';
import { useSnackbar } from 'notistack';
import { ACTIVITIES } from 'constants/activities';

import { Formik, Form } from 'formik';
import { IconButton, makeStyles } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

import TextArea from 'components/CustomFormComponents/TextArea';
import TextInput from 'components/CustomFormComponents/TextInput';

const useStyles = makeStyles(theme => ({
  iconButtonRoot: {
    position: 'absolute',
    right: '-5px',
    top: '-5px',
    boxShadow: theme.shadows[1],
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: '#f7f7f7',
    },
  },
  actionButtonRoot: {
    backgroundColor: 'white',
    boxShadow: theme.shadows[1],
    '&:hover': {
      backgroundColor: '#f7f7f7',
    },
  },
}));

/**
 * A component for wrapping text that is editable in the database which when hovered
 * will display an edit button and when the edit button is clicked the text will
 * be swapped out for an inline edit form
 *
 * Can only edit database fields that are top level to a document or one level deep in an object
 */
const EditableTextWrapper = ({
  initialValue,
  fieldType,
  fieldName,
  children,
  isActivityName,
  firestoreCollection,
  documentId,
  isNestedIn,
}) => {
  const db = useFirestore();
  const [isEditing, setIsEditing] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const classes = useStyles();
  const session = useSelector(state => state.firestore.data.session);
  const { enqueueSnackbar } = useSnackbar();

  const onMouseEnterHandler = e => {
    setIsHovering(true);
  };

  const onMouseLeaveHandler = e => {
    setIsHovering(false);
  };

  if (!isEditing) {
    return (
      <div
        className="position-relative"
        onMouseEnter={onMouseEnterHandler}
        onMouseLeave={onMouseLeaveHandler}
      >
        {children}
        <IconButton
          onClick={() => {
            setIsEditing(true);
          }}
          size="small"
          classes={{ root: classes.iconButtonRoot }}
          className={cx({ 'd-none': !isHovering })}
        >
          <EditIcon fontSize="inherit" />
        </IconButton>
      </div>
    );
  }

  return (
    <Formik
      initialValues={{ [fieldName]: initialValue }}
      validationSchema={Yup.object().shape({
        [fieldName]: Yup.string().required('Required'),
      })}
      onSubmit={values => {
        // bail early if nothing changed
        if (values[fieldName] === initialValue) {
          setIsEditing(false);
          setIsHovering(false);
          return;
        }

        const docRef = db.doc(`${firestoreCollection}/${documentId}`);
        const batch = db.batch();

        let update;

        // format the update properly if our value lives in an object
        // inside the firestore document
        if (isNestedIn) {
          update = {
            [isNestedIn]: {
              [fieldName]: values[fieldName],
            },
          };
        } else {
          update = { [fieldName]: values[fieldName] };
        }

        batch.set(docRef, update, { merge: true });

        // also update the activity name in the session document if we are
        // editing an activity name
        if (isActivityName) {
          let sessionActivities = [...session.activities];
          const sessionActivityIdx = findIndex(sessionActivities, ['id', documentId]);
          let niceName = '';
          forEach(ACTIVITIES, (item, key) => {
            if (key === firestoreCollection || item?.collection === firestoreCollection) {
              niceName = item.niceName;
            }
          });

          sessionActivities[sessionActivityIdx] = {
            ...sessionActivities[sessionActivityIdx],
            name: `${niceName}: ${values[fieldName]}`,
          };

          batch.set(
            db.doc(`sessions/${session.id}`),
            {
              activities: sessionActivities,
            },
            { merge: true },
          );
        }

        batch
          .commit()
          .then(() => {
            setIsEditing(false);
            setIsHovering(false);
          })
          .catch(err => {
            console.log(err);
            setIsEditing(false);
            setIsHovering(false);
            enqueueSnackbar('Error saving text', { variant: 'error' });
          });
      }}
    >
      {({ isSubmitting, submitForm, resetForm }) => (
        <Form style={{ paddingBottom: 20 }} className="w-100">
          {fieldType === 'text' && <TextInput name={fieldName} type="text" label="" required />}

          {fieldType === 'textarea' && <TextArea name={fieldName} label="" required />}

          <div className="position-relative">
            <div style={{ position: 'absolute', top: '-28px', right: 8 }}>
              {!isSubmitting && (
                <>
                  <IconButton
                    onClick={submitForm}
                    size="small"
                    style={{ color: 'green' }}
                    classes={{ root: classes.actionButtonRoot }}
                    className="mr-1"
                  >
                    <CheckIcon fontSize="inherit" color="inherit" />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      resetForm();
                      setIsEditing(false);
                      setIsHovering(false);
                    }}
                    size="small"
                    classes={{ root: classes.actionButtonRoot }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                </>
              )}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

EditableTextWrapper.defaultProps = {
  isActivityName: false,
};

EditableTextWrapper.propTypes = {
  initialValue: PropTypes.string.isRequired, // current value of the field to be edited
  fieldName: PropTypes.string.isRequired, // key in the firestore document to update
  fieldType: PropTypes.oneOf(['text', 'textarea']).isRequired, // type of form field to show
  children: PropTypes.node.isRequired, // component to show when the field is not being edited
  isActivityName: PropTypes.bool, // the field being edited is an activity name
  isNestedIn: PropTypes.string, // key of nested object in Firestore if applicable (we can only update 1 level deep)
  documentId: PropTypes.string.isRequired, // firestore document id
  firestoreCollection: PropTypes.string.isRequired, // firestore collection name
};

export default EditableTextWrapper;
