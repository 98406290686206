/**
 * Generate a MeetRoom id for a breakout room
 *
 * @param {string} sessionId
 * @param {string} breakoutRoomId
 */
export const createBreakoutMeetRoomId = (sessionId, breakoutRoomId) => {
  return `breakout-${sessionId}-${breakoutRoomId}`;
};

/**
 * Generate a MeetRoom id for the main session room
 *
 * @param {string} sessionId
 */
export const createMainMeetRoomId = sessionId => {
  return `main-${sessionId}`;
};

/**
 * Generate a MeetRoom id for a sidebar room
 *
 * @param {string} sessionId
 * @param {string} sidebarId
 */
export const createSidebarMeetRoomId = (sessionId, sidebarId) => {
  return `sidebar-${sessionId}-${sidebarId}`;
};

/**
 * Generate a MeetRoomid for the hallway for a given session
 *
 * @param {string} sessionId
 */
export const createHallwayMeetRoomId = sessionId => {
  return `hallway-${sessionId}`;
};
