import { useShallowEqualSelector } from '../utils';
import { map, includes, find, size, each, reduce, cloneDeep } from 'lodash';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { getParticipants } from './participantsSelectors';
import { reportData } from 'utils/noteAndMap';

const noteAndMapSelector = state => state.firestore.data.noteAndMap || {};

export const useNoteAndMapSelector = () => {
  return useShallowEqualSelector(noteAndMapSelector);
};

/**
 * Get data on which participants have created or not created a
 * moments yet, formatted for the ActivityParticipantDetail component
 *
 * @returns {Array.<{id: String, completed: Boolean}>}
 */
export const getParticipantsHaveCreated = () =>
  createSelector([noteAndMapSelector], ({ participantData: { flows, participants } }) => {
    const finishedFlowSize = 6;
    return map(participants, participantId => {
      const participantFlow = find(
        flows,
        flow => flow.participantId === participantId && !includes(flow.types, ''),
      );
      const participantFlowSize = size(participantFlow?.types);
      return {
        id: participantId,
        completed: participantFlowSize > 0,
        completedCount: participantFlowSize,
      };
    });
  });

/**
 * Get data on which participant voted momment
 *
 */
export const getParticipantVotes = participantId =>
  createSelector([noteAndMapSelector], ({ participantData: { flows } }) => {
    let votedFlows = {};
    each(flows, flow => {
      each(flow.types, (types, idx) => {
        if (includes(types.votedFor, participantId)) {
          votedFlows[flow.participantId] = {
            ...votedFlows?.[flow.participantId],
            [idx]: types.value,
          };
        }
      });
    });
    return votedFlows;
  });

/**
 * Get participant flow types
 *
 */
export const getParticipantFlow = participantId =>
  createSelector([noteAndMapSelector], ({ participantData: { flows } }) => {
    return flows?.[participantId];
  });

/**
 * Get participant flow types
 *
 */
export const getParticipantsFlows = () =>
  createSelector([noteAndMapSelector], ({ participantData }) => {
    return participantData?.flows;
  });

/**
 * Get data on which participants have completed vote,
 * formatted for the ActivityParticipantDetail component
 *
 * @returns {Array.<{id: String, completed: Boolean}>}
 */
export const getParticipantsCompletedVote = () =>
  createSelector([noteAndMapSelector], ({ participantData: { participants } }) => {
    return map(participants, participantId => {
      const participantVotes = useSelector(getParticipantVotes(participantId));
      const participantVotedTypesCount = reduce(
        participantVotes,
        (acc, moment) => {
          each(moment, value => {
            if (value) {
              acc += 1;
            }
          });
          return acc;
        },
        0,
      );
      return {
        id: participantId,
        completed: participantVotedTypesCount > 0,
        completedCount: participantVotedTypesCount,
      };
    });
  });

/**
 * Get super voted types data
 *
 */
export const getSuperVotes = () =>
  createSelector([noteAndMapSelector], ({ participantData }) => {
    return participantData?.superVoteTypes || [];
  });

/**
 *
 * Get types from all flows
 * @returns {Array}
 */
export const getAllTypes = () =>
  createSelector([noteAndMapSelector, getParticipants], ({ participantData }, participants) => {
    return reportData(participantData, participants);
  });

/**
 * Get participant flow votes results
 *
 */
export const getParticipantsFlowsVotesResults = () =>
  createSelector([noteAndMapSelector], ({ participantData }) => {
    const participantsFlow = cloneDeep(participantData.flows);
    each(participantsFlow, flow => {
      each(flow?.types, (type, idx) => {
        if (idx === 'actor') {
          return;
        }
        if (!type.votedFor?.length || !type.value || type.parentMomentId) {
          delete participantsFlow[flow.participantId].types[idx];
        }
      });
    });
    return participantsFlow;
  });

export const getParticipant = participantId =>
  createSelector([getParticipants], participants =>
    participants.find(participant => participant.id === participantId),
  );
