import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { map } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
// 3rd party components
import { Card, CardContent, Typography, LinearProgress } from '@material-ui/core';
// selectors
import { getQuestion } from 'redux/selectors/assessmentSelectors';
// styles
import { useAssessmentStyles } from '../useAssessmentStyles';

const useProgressStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#e8e8e8',
    height: 30,
    borderRadius: 4,
    [theme.breakpoints.down('xs')]: {
      height: 24,
    },
  },
}));

const SingleChoiceQuestionResultsSummary = ({ questionId, withCard }) => {
  const classes = useAssessmentStyles();
  const progressClasses = useProgressStyles();
  const { answerCounts } = useSelector(getQuestion(questionId));

  const renderContent = () => (
    <div className="flex-1">
      {map(answerCounts, (answer, idx) => (
        <div
          key={`answer-${idx}`}
          className={cx('row align-items-center', {
            'mb-2': answerCounts.length !== idx + 1,
          })}
        >
          <div className="col-5 col-sm-3 justify-content-end d-flex align-items-center pl-2 pr-0">
            <Typography
              className="mr-1 text-right"
              color={answer.count === 0 ? 'textSecondary' : idx === 0 ? 'secondary' : 'primary'}
            >
              {answer.answer}
            </Typography>
            <Typography className="" style={{ color: '#9e9e9e' }}>
              {answer.count}
            </Typography>
          </div>
          <div className="col-7 col-sm-9 pr-2 pl-1">
            <LinearProgress
              variant="determinate"
              color={idx === 0 ? 'secondary' : 'primary'}
              value={answer.percentage}
              classes={progressClasses}
            />
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <>
      {withCard ? (
        <div style={{ maxWidth: 600 }} className="mx-auto">
          <Card className={cx(classes.card)}>
            <CardContent className="p-2 d-flex">{renderContent()}</CardContent>
          </Card>
        </div>
      ) : (
        renderContent()
      )}
    </>
  );
};

SingleChoiceQuestionResultsSummary.propTypes = {
  questionId: PropTypes.string.isRequired,
  withCard: PropTypes.bool,
};

export default SingleChoiceQuestionResultsSummary;
