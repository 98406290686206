import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { find } from 'lodash';
// cr components
import Gift from 'components/Giveaway/Participant/Gift';
import ParticipantForm from 'components/Giveaway/Participant/ParticipantForm';
// library components
import { makeStyles } from '@material-ui/styles';
import { Typography, Box } from '@material-ui/core';
// selectors
import {
  useGiveawaySelector,
  getParticipantsCompletedWrite,
} from 'redux/selectors/giveawaySelectors';
// actions
import { addParticipantData, updateParticipant } from 'redux/actions/giveawayActions';

const useStyles = makeStyles({
  text: {
    fontSize: 30,
    fontWeight: 300,
    lineHeight: '40px',
    textAlign: 'center',
  },
});

/**
 * Giveaway view for the 'write' phase on the Participant side
 */
const GiveawayParticipantWrite = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const authUserFirebase = useSelector(state => state.firebase.auth);
  const participantId = authUserFirebase.uid;
  const submittedParticipants = useSelector(getParticipantsCompletedWrite());
  const isParticipantSubmitted = !!find(
    submittedParticipants,
    participant => participant.id === participantId && participant.completed,
  );
  const {
    id,
    config: { itemName, itemDescription, ofGiveaways, imageUrl },
  } = useGiveawaySelector();

  // set winner text
  const winnersText = `${ofGiveaways} ${ofGiveaways > 1 ? 'chances to win!' : 'chance to win!'}`;

  // get session
  const session = useSelector(state => state.firestore.data.session);
  const sessionParticipants = useSelector(state => state.firestore.data.participants);
  const authUser = sessionParticipants?.[participantId];

  const localStorageEmail = localStorage.getItem('authUserEmail');
  const userEmail = localStorageEmail || authUser?.email || authUserFirebase.email;

  // submit participant data
  const handleSubmitParticipantForm = useCallback(
    values => {
      localStorage.setItem('authUserEmail', values.participantEmail);
      dispatch(
        addParticipantData(id, participantId, values.participantName, values.participantEmail),
      );
      dispatch(
        updateParticipant(
          session.id,
          participantId,
          values.participantName,
          values.participantEmail,
        ),
      );
    },
    [dispatch, id, participantId],
  );

  return (
    <Box>
      <Typography className={classes.text}>{winnersText}</Typography>
      <Gift image={imageUrl} itemName={itemName} itemDescription={itemDescription} />
      <ParticipantForm
        participantDefaultName={authUser?.name}
        participantDefaultEmail={userEmail}
        participantId={participantId}
        handleSubmit={handleSubmitParticipantForm}
        isParticipantSubmitted={isParticipantSubmitted}
      />
    </Box>
  );
};

export default GiveawayParticipantWrite;
