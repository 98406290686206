import React from 'react';
import { map } from 'lodash';
import { VARIABLE_QUESTION_TYPES, VARIABLE_QUESTION_TYPES_TITLES } from '../constants';

import LinearScale, { LINEAR_SCALE_SCHEMA, LINEAR_SCALE_INITIAL_VALUES } from './LinearScale';

import SingleChoice, { SINGLE_CHOICE_SCHEMA, SINGLE_CHOICE_INITIAL_VALUES } from './SingleChoice';

import MultipleChoice, {
  MULTIPLE_CHOICE_SCHEMA,
  MULTIPLE_CHOICE_INITIAL_VALUES,
} from './MultipleChoice';

export const VARIABLE_QUESTION_TYPES_SELECT_OPTIONS = map(
  VARIABLE_QUESTION_TYPES_TITLES,
  (val, key) => ({ name: val, value: key }),
);

// export const VARIABLE_QUESTIONS_SUBMIT_FORMATTERS = {
//   // [VARIABLE_QUESTION_TYPES.LINEAR_SCALE]: formatLinearScaleSubmit,
//   // [VARIABLE_QUESTION_TYPES.SINGLE_CHOICE]: formatSingleChoiceSubmit,
//   // [VARIABLE_QUESTION_TYPES.MULTIPLE_CHOICE]: formatSingleChoiceSubmit,
// }

export const VARIABLE_QUESTIONS_FIELDS_MAP = {
  [VARIABLE_QUESTION_TYPES.SHORT_ANSWER]: null,
  [VARIABLE_QUESTION_TYPES.LINEAR_SCALE]: <LinearScale />,
  [VARIABLE_QUESTION_TYPES.SINGLE_CHOICE]: <SingleChoice />,
  [VARIABLE_QUESTION_TYPES.MULTIPLE_CHOICE]: <MultipleChoice />,
  [VARIABLE_QUESTION_TYPES.SENTIMENT]: null,
};

export const VARIABLE_QUESTIONS_FIELDS_SCHEMA_MAP = {
  [VARIABLE_QUESTION_TYPES.SHORT_ANSWER]: {},
  [VARIABLE_QUESTION_TYPES.LINEAR_SCALE]: LINEAR_SCALE_SCHEMA,
  [VARIABLE_QUESTION_TYPES.SINGLE_CHOICE]: SINGLE_CHOICE_SCHEMA,
  [VARIABLE_QUESTION_TYPES.MULTIPLE_CHOICE]: MULTIPLE_CHOICE_SCHEMA,
  [VARIABLE_QUESTION_TYPES.SENTIMENT]: {},
};

export const VARIABLE_QUESTIONS_INITIAL_VALUES_MAP = {
  [VARIABLE_QUESTION_TYPES.SHORT_ANSWER]: {},
  [VARIABLE_QUESTION_TYPES.LINEAR_SCALE]: LINEAR_SCALE_INITIAL_VALUES,
  [VARIABLE_QUESTION_TYPES.SINGLE_CHOICE]: SINGLE_CHOICE_INITIAL_VALUES,
  [VARIABLE_QUESTION_TYPES.MULTIPLE_CHOICE]: MULTIPLE_CHOICE_INITIAL_VALUES,
  [VARIABLE_QUESTION_TYPES.SENTIMENT]: {},
};
