import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { map, size } from 'lodash';
import { makeStyles } from '@material-ui/styles';
import cx from 'classnames';
// library components
import LaunchIcon from '@material-ui/icons/Launch';
import FileIcon from '@material-ui/icons/Description';
import UserIcon from '@material-ui/icons/AccountCircle';
import {
  Typography,
  Divider,
  MenuItem,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Box,
} from '@material-ui/core';
// cr components
import FormDialog from 'components/Dialogs/FormDialog';
import EllipsisMenu from 'components/EllipsisMenu';
import SlidesSessionAdmin from 'components/SlidesSession/Admin/SlidesSessionAdmin';
import AdminSendToParticipantsActivity from 'components/AdminActivityTemplate/AdminSendToParticipantsActivity';
// actions
import { createSession } from 'redux/actions/slidesSessionActions';
import { authenticateSlides, getSlidesAuthTokens } from 'redux/actions/googleSlidesAuthActions';
import { exportGoogleSlidesFile, exportPdf } from 'redux/actions/googleSlidesExportActions';
// selectors
import { useExportsSelector } from 'redux/selectors/googleSlidesExportSelectors';
import { useSessionsSelector } from 'redux/selectors/slidesSessionSelectors';
import { useAuthSelector } from 'redux/selectors/googleSlidesAuthSelectors';
// hooks
import useGooglePicker from 'hooks/useGooglePicker';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
// constants
import { ACTIVITIES } from 'constants/activities';
// utils
import { renderHtmlStringSimple } from 'utils/renderHtmlString';
import { getFacilitatorVideoInstructions } from 'utils/getActivityConfig';

const useStyles = makeStyles(theme => ({
  title: {
    color: '#093B75',
    fontSize: 18,
    lineHeight: '30px',
  },
  button: {
    minWidth: 295,
  },
  videoBox: {
    position: 'relative',
    width: '100%',
    height: 0,
    paddingBottom: '62.5%',
    marginTop: theme.spacing(3),
  },
  video: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
}));

const Slides = ({ session, isViewingShared }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [instructionsDialogOpen, setInstructionsDialogOpen] = useState(false);
  const { exporting, exportsLoading, exportsIds } = useExportsSelector();
  const { creatingSession } = useSessionsSelector();
  const { accessToken, tokensLoading } = useAuthSelector();
  const user = useSelector(state => state.firebase.profile);
  const inputRef = useRef(null);
  const slidesSessionIds = session.slidesSessionIds;
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [chosenFileId, setChosenFileId] = useState(null);

  const activeParticipantRoute = session?.activeRoute;
  const { selectedFacilitatorRoute } = useSelector(state => state.facilitatorSession);

  useEffect(() => {
    dispatch(getSlidesAuthTokens());
  }, [dispatch, enqueueSnackbar, user]);

  const toggleInstructionsDialog = () => {
    setInstructionsDialogOpen(!instructionsDialogOpen);
  };

  const picker = useGooglePicker(accessToken, fileId => {
    if (user.googleSlidesExportIds?.length && user.googleSlidesExportIds.includes(fileId)) {
      setChosenFileId(fileId);
      toggleDialog();
    } else {
      dispatch(exportGoogleSlidesFile(fileId, accessToken, enqueueSnackbar));
    }
  });

  const exportAndCreateSlides = () => {
    dispatch(exportGoogleSlidesFile(chosenFileId, accessToken, enqueueSnackbar));
  };

  const createSlidesFromExistingExport = () => {
    dispatch(createSession(chosenFileId, session.id, enqueueSnackbar));
  };

  const onAuthenticate = () => {
    dispatch(authenticateSlides(enqueueSnackbar));
  };

  const onExport = () => {
    if (picker) {
      picker.setVisible(true);
    } else {
      enqueueSnackbar('picker not loaded', { variant: 'error' });
    }
  };

  const toggleDialog = () => {
    setIsDialogOpen(!isDialogOpen);
  };

  const [videoInstruction, setVideoInstruction] = useState(
    ACTIVITIES.slides?.facilitatorInstructionsVideo,
  );

  const activityHasInstructionsVideo = !!size(videoInstruction);

  /**
   * if the activity config doesn't contain video instructions,
   * then the video is loaded from the firebase storage. path - 'facilitatorInctructions/slides'
   */
  const getVideoInstructions = useCallback(async () => {
    const result = await getFacilitatorVideoInstructions('slides');
    if (size(result)) {
      setVideoInstruction(result);
    }
  }, []);

  useEffect(() => {
    if (!activityHasInstructionsVideo) {
      getVideoInstructions();
    }
  }, [getVideoInstructions, activityHasInstructionsVideo]);

  const getEllipsisMenuItems = () => {
    const menuItems = [{ function: toggleInstructionsDialog, name: 'Instructions' }];

    return map(menuItems, (item, idx) => (
      <MenuItem key={`menu-item-${idx}`} onClick={item.function}>
        {item.name}
      </MenuItem>
    ));
  };

  return (
    <div className="d-flex flex-column flex-1">
      <div>
        <div className="d-flex justify-content-between w-100">
          <Typography className={classes.title}>Slides</Typography>
          <EllipsisMenu menuItems={getEllipsisMenuItems()} />
        </div>
        <Divider className="mb-1" />
      </div>
      <div className="d-flex flex-column justify-content-center align-items-center flex-1">
        {slidesSessionIds.length > 0 && (
          <SlidesSessionAdmin
            slidesSessionId={slidesSessionIds[0]}
            session={session}
            isViewingShared={isViewingShared}
          />
        )}
        {!slidesSessionIds.length && !isViewingShared && (
          <div className="d-flex flex-column">
            {exporting || creatingSession || (!accessToken && tokensLoading) ? (
              <div className="d-flex flex-column justify-content-center align-items-center mb-2">
                <Typography
                  style={{ fontSize: 18, fontWeight: '500', textAlign: 'center' }}
                  className="mb-2"
                >
                  {(exporting || creatingSession) && (
                    <>
                      <span className="d-block">Processing</span>
                      <span className="d-block">This may take a minute or two...</span>
                    </>
                  )}
                  {tokensLoading && 'Connecting to Google Drive...'}
                </Typography>
                <CircularProgress size={20} />
              </div>
            ) : (
              <>
                {accessToken ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onExport}
                    className={cx(classes.button, 'mb-2')}
                  >
                    <LaunchIcon className="mr-1" />
                    Import From Google Slides
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onAuthenticate}
                    className={cx(classes.button, 'mb-2')}
                  >
                    <UserIcon className="mr-1" />
                    Authenticate Google Slides
                  </Button>
                )}
              </>
            )}
          </div>
        )}
        {!slidesSessionIds.length && !isViewingShared && !exporting && !creatingSession && (
          <div className="d-flex flex-column">
            <Button
              variant="contained"
              color="primary"
              onClick={() => inputRef.current.click()}
              className={classes.button}
            >
              <FileIcon className="mr-1" />
              Upload Presentation PDF
            </Button>
            <input
              type="file"
              style={{ display: 'none' }}
              ref={inputRef}
              accept=".pdf"
              onChange={event => {
                dispatch(exportPdf(event.target.files[0], enqueueSnackbar));
              }}
            />
          </div>
        )}
        {!slidesSessionIds.length && isViewingShared && (
          <div className="d-flex flex-column justify-content-center align-items-center">
            <Typography
              style={{ fontSize: 18, fontWeight: '500', textAlign: 'center' }}
              className="mb-2"
            >
              Slides must be set up by the main facilitator for this meeting
            </Typography>
          </div>
        )}
        <Dialog
          open={isDialogOpen}
          onClose={toggleDialog}
          onExited={() => {
            setChosenFileId(null);
          }}
          aria-labelledby="form-dialog-title"
          fullWidth
          maxWidth="sm"
          className="form-dialog"
        >
          {/* Export already exists dialog */}
          <DialogContent>
            <div className="mt-2 mb-4">
              <Typography style={{ fontSize: 18, textAlign: 'center' }}>
                You previously exported this presentation from Google Drive. Would you like to use
                the existing export or export a new copy?
              </Typography>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center mb-2">
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  createSlidesFromExistingExport();
                  toggleDialog();
                }}
                className="mb-2"
              >
                Use Existing
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  exportAndCreateSlides();
                  toggleDialog();
                }}
              >
                Export New
              </Button>
            </div>
          </DialogContent>
        </Dialog>
        {/* Instructions Dialog */}
        <FormDialog
          isOpen={instructionsDialogOpen}
          handleClose={toggleInstructionsDialog}
          isTitleHasCloseIcon={true}
          title="Feedback Instructions"
        >
          <Box className="mb-3">
            <Typography
              dangerouslySetInnerHTML={renderHtmlStringSimple(
                ACTIVITIES.slides.facilitatorInstructions,
              )}
            />
            {activityHasInstructionsVideo && (
              <Box className={classes.videoBox}>
                <iframe
                  title="facilitator-instructions"
                  className={classes.video}
                  src={videoInstruction}
                  frameBorder="0"
                  webkitallowfullscreen="true"
                  mozallowfullscreen="true"
                  allowFullScreen
                />
              </Box>
            )}
          </Box>
        </FormDialog>
      </div>
      {activeParticipantRoute !== selectedFacilitatorRoute && (
        <AdminSendToParticipantsActivity
          className="mt-auto"
          session={session}
          activeFacilitatorRoute={selectedFacilitatorRoute}
        />
      )}
    </div>
  );
};

Slides.propTypes = {
  session: PropTypes.object.isRequired,
  isViewingShared: PropTypes.bool, // is viewing as co-facilitator
};

export default Slides;
