import { makeStyles } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { map } from 'lodash';
// library components
import { Box } from '@material-ui/core';
// cr components
import ButtonSpinner from 'components/ButtonSpinner/ButtonSpinner';

const useStyles = makeStyles(theme => ({
  dotHeatMapping: {
    width: 6,
    height: 6,
    borderRadius: '50%',
    backgroundColor: theme.palette.indigo.main,
    border: '1px solid white',
  },
  buttonSpinnerBox: {
    position: 'absolute',
    top: '45%',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  buttonSpinner: {
    color: theme.palette.primary.main,
  },
  image: {
    maxWidth: ({ isSmallScreen }) => (isSmallScreen ? '100%' : 303),
    maxHeight: 250,
    objectFit: 'contain',
  },
  imageBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: ({ isSmallScreen }) => (isSmallScreen ? '100%' : 303),
    maxWidth: ({ isSmallScreen }) => (isSmallScreen ? '100%' : 303),
    height: 250,
    minHeight: 150,
    background: 'rgba(183, 194, 200, 0.3)',
    marginLeft: 'auto',
    marginRight: 'auto',
    objectFit: 'contain',
  },
}));

const HeatMappingDots = ({ image, heatMappingPoints, heatMappingImageId, idx, isPointsHidden }) => {
  const isSmallScreen = useMediaQuery('(max-width: 767px)');
  const imageClasses = useStyles({ isSmallScreen });

  const heatMappingMaxWidth = 715;
  const heatMappingMaxHeight = 540;

  const [imageWidth, setImageWidth] = useState();
  const [imageHeight, setImageHeight] = useState();

  let heatMappingCoeff = heatMappingMaxWidth / imageWidth;
  let heatMappingCoeffHeight = heatMappingMaxHeight / imageHeight;

  const load = !imageWidth || !imageHeight;

  const imageLoad = () => {
    const heatMappingImage = document.getElementById(heatMappingImageId);
    setImageWidth(heatMappingImage?.clientWidth);
    setImageHeight(heatMappingImage?.clientHeight);
  };

  return (
    <Box key={`image-${idx}`} className={imageClasses.imageBox}>
      <Box position="relative">
        <img
          id={heatMappingImageId}
          src={image}
          className={imageClasses.image}
          alt="heat-mapping"
          onLoad={imageLoad}
        />
        {load ? (
          <Box className={imageClasses.buttonSpinnerBox}>
            <ButtonSpinner className={imageClasses.buttonSpinner} />
          </Box>
        ) : (
          map(heatMappingPoints, imagePoints => {
            if (image.indexOf(imagePoints.id) !== -1) {
              return map(imagePoints.points, points =>
                map(points, (point, index) => {
                  return (
                    <div
                      key={`points-${index}`}
                      className={imageClasses.dotHeatMapping}
                      style={{
                        position: 'absolute',
                        top: point.top / heatMappingCoeffHeight,
                        left: point.left / heatMappingCoeff,
                        display: isPointsHidden ? 'none' : 'block',
                      }}
                    />
                  );
                }),
              );
            }
          })
        )}
      </Box>
    </Box>
  );
};

HeatMappingDots.propTypes = {
  image: PropTypes.string.isRequired, // image url
  heatMappingPoints: PropTypes.object, // image heat-mapping points
  heatMappingImageId: PropTypes.string.isRequired, // image id
  idx: PropTypes.number, // image order in sketch
};

export default HeatMappingDots;
