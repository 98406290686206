import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { includes, keys } from 'lodash';
// library components
import { Box, Typography, Button } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AddCircleOutlinedIcon from '@material-ui/icons/AddCircleOutlined';
// cr components
import InfoDialog from 'components/Dialogs/InfoDialog';
// actions
import { addParticipant } from 'redux/actions/activityActions';
import { createGroup } from 'redux/actions/howMightWeActions';
// utils
import { renderHtmlString } from 'utils/renderHtmlString';
import { localStorageGetObject } from 'utils/localStorageUtils';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
  },
  activeView: {
    fontSize: 26,
    fontWeight: '400',
    letterSpacing: '0.15px',
    lineHeight: '40px',
    marginBottom: ({ smallScreen, activeView }) => (smallScreen && activeView === 'group' ? 0 : 30),
    textAlign: 'center',
  },
  prompt: {
    paddingRight: ({ helpEnabled, smallScreen }) => helpEnabled && !smallScreen && 10,
    marginRight: ({ helpEnabled }) => helpEnabled && 'auto',
    marginLeft: ({ helpEnabled }) => helpEnabled && 'auto',
    marginBottom: ({ smallScreen }) => (smallScreen ? 15 : 0),
    fontWeight: '200',
    textAlign: 'center',
  },
  helpDialogContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: ({ smallScreen }) => smallScreen && 'column',
    justifyContent: ({ helpEnabled, smallScreen }) =>
      helpEnabled && !smallScreen ? 'start' : 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  buttonBox: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(4),
  },
  addGroupButton: {
    width: 99,
    marginBottom: ({ smallScreen, activeView }) => (smallScreen && activeView === 'group' ? 30 : 0),
  },
  infoButton: {
    marginBottom: ({ smallScreen }) => (smallScreen ? 20 : 0),
  },
}));

/**
 * Template for displaying the content area of an activity to participants
 */
const ParticipantActivityContent = ({
  children,
  activityData,
  activityConfig,
  viewLabel,
  promptOverride,
}) => {
  const impersonate = localStorageGetObject('impersonate');
  const participantId = useSelector(state => state.firebase.auth.uid);
  const dispatch = useDispatch();
  const smallScreen = useMediaQuery('(max-width: 750px)');

  const { participantHelp } = activityConfig;

  const {
    id,
    participantData: { participants, groups },
    prompt,
    state: { activeView },
  } = activityData;

  const participantHelpData = participantHelp?.[activeView];
  const helpEnabled = !!participantHelpData;
  const classes = useStyles({ helpEnabled, smallScreen, activeView });
  const participantsCount = participants.length;

  // add participant to activity
  useEffect(() => {
    if (impersonate) return;

    if (!includes(participants, participantId)) {
      dispatch(addParticipant(activityConfig.collection, participantId, id));
    }
  }, [participantsCount, impersonate]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openDialog = () => {
    setIsModalOpen(true);
  };

  const closeDialog = () => {
    setIsModalOpen(false);
  };

  const handleAddNewGroup = () => {
    dispatch(createGroup(id, participantId, keys(groups).length + 1));
  };

  const NewGroupButton = () =>
    activeView === 'group' ? (
      <Button
        className={classes.addGroupButton}
        color="primary"
        size="small"
        startIcon={<AddCircleOutlinedIcon />}
        onClick={handleAddNewGroup}
      >
        New Group
      </Button>
    ) : null;

  return (
    <>
      <Box className={classes.container}>
        <Box className={classes.helpDialogContainer}>
          {helpEnabled && (
            <>
              <Button
                color="primary"
                size="small"
                startIcon={<HelpIcon fontSize="small" color="primary" />}
                onClick={openDialog}
                className={classes.infoButton}
              >
                Activity Help
              </Button>
              <InfoDialog
                handleClose={closeDialog}
                isOpen={isModalOpen}
                title={participantHelpData.helpTitle}
              >
                {participantHelpData.helpText}
              </InfoDialog>
            </>
          )}
          <Typography
            className={classes.prompt}
            dangerouslySetInnerHTML={renderHtmlString(promptOverride ?? prompt)}
          />
          {activeView === 'group' && !smallScreen ? (
            <NewGroupButton />
          ) : (
            <div style={{ width: helpEnabled ? '99px' : 0 }}></div>
          )}
        </Box>
        <Typography className={classes.activeView}>{viewLabel}</Typography>
        {smallScreen && <NewGroupButton />}
      </Box>
      {children}
    </>
  );
};

ParticipantActivityContent.defaultProps = {
  promptOverride: null,
};

ParticipantActivityContent.propTypes = {
  children: PropTypes.node.isRequired,
  activityData: PropTypes.shape({
    id: PropTypes.string.isRequired,
    participantData: PropTypes.shape({
      participants: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
    prompt: PropTypes.string,
  }),
  activityConfig: PropTypes.shape({
    collection: PropTypes.string.isRequired,
  }).isRequired,
  viewLabel: PropTypes.string.isRequired,
  promptOverride: PropTypes.string,
};

export default ParticipantActivityContent;
