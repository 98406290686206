import React from 'react';
import PropTypes from 'prop-types';
// library components
import { DialogTitle, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  dialogHeader: {
    position: 'relative',
    maxWidth: 760,
    width: 760,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerRoot: {
    padding: '24px 8px',
  },
  cancelButton: {
    position: 'absolute',
    right: theme.spacing(1),
  },
  title: {
    fontSize: 16,
    fontWeight: 500,
  },
}));

const DialogHeader = ({ handleClose, title }) => {
  const classes = useStyles();

  return (
    <DialogTitle
      disableTypography
      className={classes.dialogHeader}
      classes={{ root: classes.headerRoot }}
    >
      <Typography className={classes.title}>{title}</Typography>
      <IconButton className={classes.cancelButton} aria-label="close" onClick={handleClose}>
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  );
};

DialogHeader.propTypes = {
  handleClose: PropTypes.func.isRequired, // on close dialog
  title: PropTypes.string, // the title of dialog
};

export default DialogHeader;
