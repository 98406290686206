import { includes, values, map, orderBy, filter, forEach, keys, size } from 'lodash';

/**
 * Shuffle ideas when changing brainwriting rounds.
 * Idea owner can't get their own idea and other contributors
 * can't get an idea they've already contributed to.
 *
 * @param object  ideas   object of ideas keyed by their id
 * @param object  rounds  object containing the data for each round
 *                        keyed by the round number
 *
 * @returns object  an object keyed by participant ids with their idea assignments
 *                  for the next round
 */
export default function shuffleIdeas(ideas, rounds) {
  let newWritingAssignments = {};
  const participantIds = keys(ideas);
  const roundCount = size(rounds);
  const previousAssignments = {};
  console.log('**** SHUFFLE **** => ', roundCount);

  const rotate = (array, times) => {
    while (times--) {
      let temp = array.pop();
      array.unshift(temp);
    }
  };

  /**
   * Build up the previousAssignments object where they keys
   * are participantIds and the values are an array of idea IDs
   * that participant has been previously assigned
   */
  forEach(participantIds, participantId => {
    previousAssignments[participantId] = [];

    forEach(rounds, round => {
      if (round.assignments[participantId]) {
        previousAssignments[participantId].push(round.assignments[participantId].idea);
      }
    });
  });

  //console.log(ideas)
  //console.log(previousAssignments)

  /**
   * Checks if a given ideaId has already been assigned this round
   *
   * @param object newWritingAssignments
   * @param string ideaId
   */
  const ideaAlreadyAssigned = (newWritingAssignments, ideaId) => {
    const assignments = values(newWritingAssignments);
    return filter(assignments, assignment => assignment.idea === ideaId).length;
  };

  /**
   * Sort ideas based on the time they were originally created, ascending.
   * Since the ideas id's are actually the id of the participant that
   * created them, we can use this to sort the participants based on
   * their order of inital submission in the activity
   */
  const sortedIdeas = orderBy(
    map(ideas, idea => ({
      ...idea,
      seconds: idea.time.seconds,
      nanoseconds: idea.time.nanoseconds,
    })),
    ['seconds', 'nanoseconds'],
  );

  const sortedParticipantIds = map(sortedIdeas, p => p.id);

  // NOTE: rotate mutates this array....
  rotate(sortedIdeas, roundCount);

  forEach(sortedParticipantIds, participantId => {
    const availableIdeas = filter(
      sortedIdeas,
      idea =>
        idea.id !== participantId && // exclude original writer
        !includes(previousAssignments[participantId], idea.id) && // exclude ideas participant was previously assigned
        !ideaAlreadyAssigned(newWritingAssignments, idea.id), // exclude ideas already assigned this round
    );

    //const participantIdeaText = ideas[participantId].text;
    //console.log(`availableIdeas for ${participantIdeaText}`, map(availableIdeas, idea => idea.text))

    if (availableIdeas.length) {
      newWritingAssignments[participantId] = {
        idea: availableIdeas[0].id,
        isSubmitReady: false,
      };
    }
  });

  // console.log('shuffleIdeas results', {
  //   activeParticipants: keys(ideas),
  //   nextAssignments: newWritingAssignments,
  //   canShuffle: keys(newWritingAssignments).length === keys(ideas).length
  // });

  return newWritingAssignments;
}
