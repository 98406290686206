import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { find, map } from 'lodash';
// library components
import { Typography, Box } from '@material-ui/core';
// cr components
import LightningDemoReview from 'components/LightningDemo/Participant/LightningDemoReview';
// selectors
import {
  useLightningDemoSelector,
  getParticipantsCompletedReview,
} from 'redux/selectors/lightningDemoSelectors';

const useStyles = makeStyles({
  title: {
    marginLeft: 'auto',
    paddingLeft: '10%',
    fontSize: 16,
    fontWeight: 200,
    textAlign: 'center',
  },
  completeTitle: {
    margin: 'auto',
    fontSize: 16,
    fontWeight: 200,
    textAlign: 'center',
  },
  newDemoButton: {
    marginLeft: 'auto',
    fontSize: 15,
    fontWeight: 400,
  },
});

/**
 * Lightning Demo view for the 'review' phase on the Participant side
 */
const LightningDemoParticipantReview = () => {
  const classes = useStyles();
  const lightningDemo = useLightningDemoSelector();
  const {
    id,
    participantData: { demos },
    state: { currentDemoId, activeView, participantsCompletedReview },
  } = lightningDemo;

  const participantsHaveReviewed = useSelector(getParticipantsCompletedReview());
  // get upload demos participants id
  const participantsUploadDemo = map(participantsHaveReviewed, participant => participant.id);

  // find participant demo
  const currentDemo = find(demos, demo => demo.demoId === currentDemoId);

  return (
    <>
      <Box className="w-100 mb-2" display="flex" alignItems="center">
        <Typography className={classes.completeTitle}>
          {`Reviewing demos from participant ${participantsCompletedReview?.length} of ${participantsUploadDemo.length}`}
        </Typography>
      </Box>
      <LightningDemoReview lightningDemoId={id} activeView={activeView} currentDemo={currentDemo} />
    </>
  );
};

export default LightningDemoParticipantReview;
