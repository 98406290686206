import React from 'react';
import { useSelector } from 'react-redux';
import { map } from 'lodash';
import { makeStyles } from '@material-ui/styles';
import cx from 'classnames';
// cr components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Idea from './Idea';
import NewIdea from './NewIdea';
// selectors
import { useHowMightWeSelector, getParticipantIdeas } from 'redux/selectors/howMightWeSelectors';

const useStyles = makeStyles({
  grid: {
    width: 'calc(100% + 10px)',
  },
  ideas: {
    padding: `0 5px !important`,
  },
});

/**
 * HMW view for the 'create' phase on the Participant side
 */
const HowMightWeParticipantCreate = () => {
  const classes = useStyles();
  const participantId = useSelector(state => state.firebase.auth.uid);
  const participantIdeas = useSelector(getParticipantIdeas(participantId));
  const {
    state: { activeView },
  } = useHowMightWeSelector();

  const ideasCount = participantIdeas.length;
  // Renders participant ideas
  const renderIdeas = () =>
    map(participantIdeas, (idea, idx) => (
      <GridItem
        key={idea.ideaId}
        className={cx('d-flex flex-column flex-1', classes.ideas)}
        xs={12}
        sm={6}
        md={3}
        lg={3}
        xl={3}
      >
        <Idea idea={idea} draggableId={idx} context={activeView} />
      </GridItem>
    ));

  return (
    <>
      <GridContainer direction="row" spacing={1} className={classes.grid}>
        <GridItem
          className={cx('d-flex flex-column flex-1', classes.ideas)}
          xs={12}
          sm={6}
          md={3}
          lg={3}
          xl={3}
        >
          <NewIdea order={ideasCount} />
        </GridItem>
        {renderIdeas()}
      </GridContainer>
    </>
  );
};

export default HowMightWeParticipantCreate;
