import React from 'react';
// cr components
import ImagesBox from './ImagesBox';
// selectors
import { useHeatMappingSelector } from 'redux/selectors/heatMappingSelectors';

/**
 * Heat-Mapping view for the 'results' phase on the Participant side
 */
const HeatMappingParticipantResults = () => {
  const {
    participantData: { superVoteCompleted },
    state: { activeView },
  } = useHeatMappingSelector();

  return <ImagesBox context={activeView} isFinishedVote={superVoteCompleted} />;
};

export default HeatMappingParticipantResults;
