import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isEmpty, isLoaded, useFirestoreConnect } from 'react-redux-firebase';

import AdminActivityTemplate from 'components/AdminActivityTemplate';
import HeatMappingAdminUpload from 'components/HeatMapping/Admin/HeatMappingAdminUpload';
import HeatMappingAdminHeatMapping from 'components/HeatMapping/Admin/HeatMappingAdminHeatMapping';
import HeatMappingAdminReview from 'components/HeatMapping/Admin/HeatMappingAdminReview';
import HeatMappingAdminStrawPoll from 'components/HeatMapping/Admin/HeatMappingAdminStrawPoll';
import HeatMappingAdminStrawPollResults from 'components/HeatMapping/Admin/HeatMappingAdminStrawPollResults';
import HeatMappingAdminSuperVote from 'components/HeatMapping/Admin/HeatMappingAdminSuperVote';
import HeatMappingAdminResults from 'components/HeatMapping/Admin/HeatMappingAdminResults';

import { useHeatMappingSelector } from 'redux/selectors/heatMappingSelectors';

import { HEAT_MAPPING_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';

const views = {
  upload: HeatMappingAdminUpload,
  heatMapping: HeatMappingAdminHeatMapping,
  review: HeatMappingAdminReview,
  strawPoll: HeatMappingAdminStrawPoll,
  strawPollResults: HeatMappingAdminStrawPollResults,
  superVote: HeatMappingAdminSuperVote,
  results: HeatMappingAdminResults,
};

const AdminHeatMapping = () => {
  const routeParams = useParams();
  const heatMapping = useHeatMappingSelector();
  const session = useSelector(state => state.firestore.data.session);
  const meetingParticipants = useSelector(state => state.firestore.data.participants);

  // hooks up a firestore listener to Redux
  useFirestoreConnect(() => [
    {
      collection: 'heatMapping',
      doc: `${routeParams.activityId}`,
      storeAs: 'heatMapping',
    },
  ]);

  // ensure all of the data we need is loaded
  if (
    !isLoaded(session) ||
    isEmpty(session) ||
    !isLoaded(heatMapping) ||
    isEmpty(heatMapping) ||
    !isLoaded(meetingParticipants) ||
    routeParams.activityId !== heatMapping?.id
  ) {
    return null;
  }

  const CurrentView = views[heatMapping.state.activeView];

  return (
    <AdminActivityTemplate
      activityConfig={CONFIG}
      activityData={heatMapping}
      session={session}
      CurrentView={CurrentView}
    />
  );
};

export default AdminHeatMapping;
