import { createSelector } from 'reselect';
import { each, find, sortBy, map, size, filter } from 'lodash';
import { useShallowEqualSelector } from '../utils';
import { getNarrowingReportData } from 'utils/narrowing';

const narrowingSelector = state => state.firestore.data.narrowing || {};

export const useNarrowingSelector = () => {
  return useShallowEqualSelector(narrowingSelector);
};

/**
 * Get data on which cards is categorized
 *
 */
export const getParticipantCategorizedCards = participantId =>
  createSelector([useNarrowingSelector], ({ participantData: { cards, round1 } }) => {
    const participantCategories = round1?.[participantId];
    const participantCards = {};
    each(participantCategories, (category, index) => {
      each(category, (card, idx) => {
        participantCards[idx] = {
          ...cards[idx],
          order: card.order,
          categoryId: index,
        };
      });
    });
    return participantCards;
  });

/**
 * Get data on which participants have completed categorize,
 * formatted for the ActivityParticipantDetail component
 *
 * @returns {Array.<{id: String, completed: Boolean}>}
 */
export const getParticipantsCompletedCategorize = maxCountCards =>
  createSelector([useNarrowingSelector], ({ participantData: { participants, round1 } }) => {
    return map(participants, participant => {
      const participantCategories = round1?.[participant];
      const participantCards = {};
      each(participantCategories, (category, index) => {
        each(category, (card, idx) => {
          participantCards[idx] = {
            ...card,
            categoryId: index,
          };
        });
      });
      return {
        id: participant,
        completed: size(participantCards) > 0,
        completedCount: size(participantCards),
      };
    });
  });

/**
 * Get data on which cards is categorized
 *
 */
export const getCategorizedCards = () =>
  createSelector([useNarrowingSelector], ({ participantData }) => {
    const categoriesCards = {};
    const allCards = [];
    each(participantData?.round1, (categories, participantId) => {
      each(categories, (cards, categoryId) => {
        each(cards, card => {
          allCards.push({
            ...card,
            categoryId: categoryId,
            participantId: participantId,
          });
        });
      });
    });

    each(participantData?.round1, (categories, participantId) => {
      each(categories, (cards, categoryId) => {
        each(cards, (card, cardId) => {
          categoriesCards[categoryId] = {
            ...categoriesCards?.[categoryId],
            [cardId]: {
              ...participantData.cards?.[cardId],
              categoryId: categoryId,
              participantId: participantId,
              isDuplicate: !!find(
                allCards,
                dupCard => dupCard.cardId === cardId && dupCard.categoryId !== categoryId,
              ),
            },
          };
        });
      });
    });
    return categoriesCards;
  });

/**
 * Get cards from primary category
 *
 */
export const getPrimaryCategorizedCards = round =>
  createSelector([useNarrowingSelector], ({ participantData, config }) => {
    let primaryCards = {};

    if (round === 'round0') {
      // each(participantData?.[round], (categories, participantId) => {
      //   each(categories, (cards, categoryId) => {
      //     if (categoryId === config.primaryCategory)
      //     each(cards, (card, cardId) => {
      //       primaryCards[cardId] = {
      //         ...participantData.cards?.[cardId],
      //         participantId: participantId,
      //       }
      //     })
      //   })
      // });

      primaryCards = participantData.cards;
    } else {
      each(participantData?.[round], (cards, participantId) => {
        each(cards, (card, cardId) => {
          if (!card.isNotInclude) {
            primaryCards[cardId] = {
              ...participantData.cards?.[cardId],
              participantId: participantId,
            };
          }
        });
      });
    }
    return sortBy(primaryCards, ['votesCount', 'order', 'cardId']);
  });

/**
 * Get cards for primary category
 *
 */
export const getParticipantRoundCards = (round, participantId) =>
  createSelector([useNarrowingSelector], ({ participantData }) => {
    const primaryCards = {};
    each(participantData?.[round], (cards, index) => {
      if (participantId === index) {
        each(cards, (card, cardId) => {
          if (!card.isNotInclude) {
            primaryCards[cardId] = {
              ...participantData.cards?.[cardId],
              order: card.order,
              participantId: index,
            };
          }
        });
      }
    });
    return primaryCards;
  });

/**
 * Get data on which participants have completed categorize,
 * formatted for the ActivityParticipantDetail component
 *
 * @returns {Array.<{id: String, completed: Boolean}>}
 */
export const getParticipantsCompletedRound = round =>
  createSelector([useNarrowingSelector], ({ participantData, state: { cardsCount } }) => {
    return map(participantData?.participants, participant => {
      const roundCards = participantData?.[round];
      const participantCards = roundCards?.[participant];
      return {
        id: participant,
        completed: size(participantCards) > 0,
        completedCount: size(participantCards),
      };
    });
  });

/**
 * Get cards for primary category on the review phase
 *
 */
export const getReviewRoundCards = round =>
  createSelector([useNarrowingSelector], ({ participantData }) => {
    const allRoundCards = [];

    each(participantData?.[round], cards => {
      each(cards, (card, cardId) => {
        allRoundCards.push(cardId);
      });
    });

    const primaryCards = {};
    each(participantData?.[round], (cards, index) => {
      each(cards, (card, cardId) => {
        primaryCards[cardId] = {
          ...participantData.cards?.[cardId],
          isNotIncludeNextRound: card.isNotInclude || false,
          participantId: index,
          votesCount: size(filter(allRoundCards, card => card === cardId)),
        };
      });
    });
    return sortBy(primaryCards, ['votesCount', 'order', 'cardId']);
  });

/**
 * Get all data cards for download csv report
 *
 */
export const getCardsReport = () =>
  createSelector([useNarrowingSelector], ({ config, participantData, state }) => {
    return getNarrowingReportData(config, participantData, state);
  });

/**
 * Get data on which cards is categorized
 *
 */
export const getAllParticipantsCards = () =>
  createSelector([useNarrowingSelector], ({ participantData }) => {
    const allCards = [];
    each(participantData?.round1, (categories, participantId) => {
      each(categories, (cards, categoryId) => {
        each(cards, card => {
          allCards.push({
            ...card,
            categoryId: categoryId,
            participantId: participantId,
          });
        });
      });
    });
    return allCards;
  });
