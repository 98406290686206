import React, { useRef, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useField, useFormikContext } from 'formik';
import { includes } from 'lodash';
// library components
import { Box, IconButton, Typography } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { useDropzone } from 'react-dropzone';
// utils
import { maybeConvertFromHeic } from 'utils/maybeConvertFromHeic';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: 210,
    padding: 20,
    background: '#E1E1E1',
    borderRadius: 2,
    border: '1px solid #D6D6D6',
  },
  image: {
    height: 170,
  },
  uploadContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: 170,
    border: '1px dashed #C3C3C3',
    cursor: 'pointer',
  },
  uploadContainerDrag: {
    border: `2px dashed #CFCFCF !important`,
    background: 'rgba(0,0,0,.1)',
  },
  icon: {
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
    fontSize: 30,
  },
  text: {
    color: theme.palette.primary.main,
    fontSize: 11,
  },
  removeButton: {
    position: 'absolute',
    top: -5,
    right: -5,
    padding: 4,
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    background: '#f0f0f0',
    '&:hover': {
      background: '#f0f0f0',
    },
  },
  cancelIcon: {
    fontSize: 13,
  },
}));

const UploadImage = ({ name, label, disabled }) => {
  const [field, meta] = useField(name);
  const { setFieldValue, submitCount } = useFormikContext();
  const { error, touched } = meta;
  const hasError = error && (touched || submitCount);
  const classes = useStyles();
  const inputRef = useRef();
  const imageRef = useRef();
  const [url, setUrl] = useState(field?.value);

  const getImageUrl = async () => {
    const canvas = document.createElement('canvas');

    const scaleX = imageRef.current.naturalWidth / imageRef.current.width;
    const scaleY = imageRef.current.naturalHeight / imageRef.current.height;

    canvas.width = imageRef.current.naturalWidth;
    canvas.height = imageRef.current.naturalHeight;

    const context = canvas.getContext('2d');
    context.imageSmoothingQuality = 'high';

    context.drawImage(
      imageRef.current,
      0,
      0,
      imageRef.current.width * scaleX,
      imageRef.current.height * scaleY,
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        blob => {
          blob.name = name;
          resolve(blob);
        },
        'image/png',
        1,
      );
    });
  };

  // Participant upload new image. Read image file and return first file
  const onDrop = useCallback(async acceptedFiles => {
    if (includes(acceptedFiles[0].type, 'image')) {
      if (acceptedFiles && acceptedFiles.length > 0) {
        let imageBlob = await maybeConvertFromHeic(acceptedFiles[0]);

        if (!(imageBlob instanceof Blob)) {
          return null;
        }

        const reader = new FileReader();
        reader.addEventListener('load', () => {
          setUrl(reader.result);
        });
        reader.readAsDataURL(imageBlob);
      }
    }
  }, []);

  const setImageUrl = async () => {
    if (field?.value) {
      return;
    }
    const imageUrl = await getImageUrl(url);
    setFieldValue(name, imageUrl);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <>
      <Box className={classes.container}>
        {url ? (
          <Box position="relative">
            <img ref={imageRef} src={url} className={classes.image} onLoad={setImageUrl} />
            {!disabled && (
              <IconButton
                className={classes.removeButton}
                onClick={() => {
                  setUrl('');
                  setFieldValue(name, '');
                }}
              >
                <ClearIcon className={classes.cancelIcon} />
              </IconButton>
            )}
          </Box>
        ) : (
          <div className="w-100" {...getRootProps()}>
            <input
              {...getInputProps()}
              accept="image/*"
              className={classes.input}
              id="contained-button-file"
              type="file"
              ref={inputRef}
              disabled={disabled}
            />
            <label htmlFor="contained-button-file">
              <Box
                className={cx(classes.uploadContainer, isDragActive && classes.uploadContainerDrag)}
              >
                <AddCircleIcon className={classes.icon} />
                <Typography className={classes.text}>{label}</Typography>
              </Box>
            </label>
          </div>
        )}
      </Box>
      {hasError && (
        <Box component="span" color="error.main" className="d-block" style={{ marginTop: '-16px' }}>
          {error}
        </Box>
      )}
    </>
  );
};

UploadImage.defaultProps = {
  label: 'Add Image',
};

UploadImage.propTypes = {
  // The image URL
  value: PropTypes.string,
  // The name of config field.
  name: PropTypes.string,
  // The title of field.
  label: PropTypes.string,
  // is the field disabled
  disabled: PropTypes.bool,
};

export default UploadImage;
