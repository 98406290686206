import { keys, map, filter, some, every } from 'lodash';

export const getJobRolesMarkedAsLead = jobs => {
  if (!keys(jobs).length) return [];

  const leadJobs = filter(jobs, { isLead: true });

  return leadJobs.length ? map(leadJobs, job => job.jobRole) : [];
};

export const jobIsFilled = job => {
  if (!keys(job.assignees).length) return false;
  return some(
    job.assignees,
    assignee =>
      assignee.status === 'accepted' || assignee.status === 'signed' || assignee.status === 'paid',
  );
};

/**
 * Gets and array of job roles marked as "Lead" that have been accepted or signed
 * @param {object} jobs
 * @returns {array} array role names marked as Lead that have been accepted
 */
export const getSignedLeadRoles = jobs => {
  if (!keys(jobs).length) return [];

  const filledLeadJobs = filter(jobs, job => {
    return (
      job.isLead &&
      some(job.assignees, assignee => assignee.status === 'signed' || assignee.status === 'paid')
    );
  });

  return filledLeadJobs.length ? map(filledLeadJobs, job => job.jobRole) : [];
};
