import React from 'react';
import PropTypes from 'prop-types';

import { CircularProgress } from '@material-ui/core';

const Spinner = ({ size, color, ...rest }) => (
  <CircularProgress color={color} size={size} {...rest} />
);

Spinner.defaultProps = {
  size: 35,
  color: 'primary',
};

Spinner.propTypes = {
  color: PropTypes.oneOf(['inherit', 'primary', 'secondary']),
  size: PropTypes.number,
};

export default Spinner;
