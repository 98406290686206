/**
 * Accurately rounds to the specified number of decimal places
 *
 * usage: roundAccurately(1.005, 2)) // 1.01
 * @link https://medium.com/swlh/how-to-round-to-a-certain-number-of-decimal-places-in-javascript-ed74c471c1b8
 * @link https://gist.github.com/djD-REK/068cba3d430cf7abfddfd32a5d7903c3
 *
 * @param {number} number
 * @param {number} decimalPlaces
 * @returns {number}
 */
export const roundAccurately = (number, decimalPlaces) =>
  Number(Math.round(Number(number) + 'e' + Number(decimalPlaces)) + 'e-' + Number(decimalPlaces));

/**
 * Converts a number to a number representing a percentage. Will round the passed number
 * to two decimal places before converting
 *
 * usage: numberToPercentage(.356) // 36
 *
 * @param {number} number
 * @returns {number} number representing a percentage
 */
export const numberToPercent = number => roundAccurately(number, 2) * 100;
