import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isEmpty, isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { each } from 'lodash';

import AdminActivityTemplate from 'components/AdminActivityTemplate';
import LightningDemoAdminUpload from 'components/LightningDemo/Admin/LightningDemoAdminUpload';
import LightningDemoAdminReview from 'components/LightningDemo/Admin/LightningDemoAdminReview';
import LightningDemoAdminResults from 'components/LightningDemo/Admin/LightningDemoAdminResults';
// selectors
import { getParticipantsMap } from 'redux/selectors/participantsSelectors';
import { useLightningDemoSelector } from 'redux/selectors/lightningDemoSelectors';
// constants
import { LIGHTNING_DEMO_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';
// actions
import { updateParicipants, removeParicipants } from 'redux/actions/lightningDemoActions';

const views = {
  upload: LightningDemoAdminUpload,
  review: LightningDemoAdminReview,
  results: LightningDemoAdminResults,
};

const AdminLightningDemo = () => {
  const routeParams = useParams();
  const dispatch = useDispatch();
  const lightningDemo = useLightningDemoSelector();
  const session = useSelector(state => state.firestore.data.session);
  const meetingParticipants = useSelector(state => state.firestore.data.participants);
  const connectedParticipants = useSelector(getParticipantsMap);

  // hooks up a firestore listener to Redux
  useFirestoreConnect(() => [
    {
      collection: 'lightningDemo',
      doc: `${routeParams.activityId}`,
      storeAs: 'lightningDemo',
    },
  ]);

  // ensure all of the data we need is loaded
  if (
    !isLoaded(session) ||
    isEmpty(session) ||
    !isLoaded(lightningDemo) ||
    isEmpty(lightningDemo) ||
    !isLoaded(meetingParticipants) ||
    routeParams.activityId !== lightningDemo?.id
  ) {
    return null;
  }

  const CurrentView = views[lightningDemo.state.activeView];

  each(connectedParticipants, participant => {
    if (participant?.id && participant.connected) {
      dispatch(updateParicipants(lightningDemo.id, participant.id));
    } else if (!participant.connected) {
      dispatch(removeParicipants(lightningDemo.id, participant.id));
    }
  });

  return (
    <AdminActivityTemplate
      activityConfig={CONFIG}
      activityData={lightningDemo}
      session={session}
      CurrentView={CurrentView}
    />
  );
};

export default AdminLightningDemo;
