import React from 'react';
import { useSelector } from 'react-redux';
import { map } from 'lodash';
import makeStyles from '@material-ui/core/styles/makeStyles';
import cx from 'classnames';
// library components
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  Box,
  Card,
  CardContent,
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Divider,
} from '@material-ui/core';
// cr components
import AdminActivityAuxView from 'components/AdminActivityAuxView';
import CSVReportDownloadButton from 'components/CSVReportDownloadButton';
// selectors
import { useSurveySelector } from 'redux/selectors/surveySelectors';
import { createSurveyReportData, createSurveyReportHeader } from 'utils/survey';

const useStyles = makeStyles(theme => ({
  card: {
    boxShadow: '0px 8px 18px rgba(0, 0, 0, 0.12)',
    marginBottom: theme.spacing(3),
  },
  cardContent: {
    padding: '18px !important',
  },
  answerTxt: {
    fontSize: 14,
    overflowX: 'hidden',
    whiteSpace: 'pre-line',
    overflowWrap: 'break-word',
  },
  expansionPanel: {
    '&::before': {
      opacity: '1 !important',
    },
  },
}));

/**
 * Renders survey results in the Alt view
 */
const SurveyReview = () => {
  const classes = useStyles();
  const { output, name, state } = useSurveySelector();

  const session = useSelector(state => state.firestore.data.session);

  if (!state?.activeView) return null;

  // if (!state?.activeView || state.activeView !== 'results') {
  //   return null;
  // }

  // render headers for csv report
  const headers = createSurveyReportHeader();

  // render data for csv report
  const csvReport = createSurveyReportData(output);

  const renderResults = () =>
    map(output, (question, idx) => (
      <ExpansionPanel
        className={classes.expansionPanel}
        defaultExpanded={idx === 0}
        elevation={0}
        variant="elevation"
        key={`question-${idx}`}
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} style={{ padding: 0 }}>
          <Typography style={{ fontWeight: '300', margin: '8px 0' }}>{question.text}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{ padding: 0, flexWrap: 'wrap' }}>
          {map(question.answers, (answer, answerIdx) => (
            <Card
              variant="outlined"
              elevation={0}
              className={cx('w-100', classes.card)}
              key={`answer-${answerIdx}`}
            >
              <CardContent className={classes.cardContent}>
                <Typography className={classes.answerTxt}>{answer}</Typography>
              </CardContent>
            </Card>
          ))}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    ));

  const renderPrintResults = () =>
    map(output, (question, idx) => (
      <Box key={`question-${idx}`} className="mb-4">
        <Typography style={{ fontSize: 18 }} className="mb-1">
          {question.text}
        </Typography>
        <Divider className="mb-2" />
        {map(question.answers, (answer, answerIdx) => (
          <Box key={`answer-${answerIdx}`} className="mb-2">
            <Typography>{answer}</Typography>
          </Box>
        ))}
      </Box>
    ));

  return (
    <AdminActivityAuxView
      titleText="Assessment Results"
      allowPrint={state.activeView === 'results'}
      activityName={name}
      hasDownloadButton={state.activeView === 'results'}
      renderDownloadButton={() => (
        <CSVReportDownloadButton
          headers={headers}
          csvReport={csvReport}
          buttonText="Download Results"
          filename="Results.csv"
          topic={session.topic}
          activity="Survey"
          activityName={name}
          withoutLabel={true}
        />
      )}
    >
      <Box displayPrint="none" style={{ marginTop: '-24px' }}>
        {output.length ? (
          renderResults()
        ) : (
          <Typography className="mt-8 mb-4 text-center">No responses yet</Typography>
        )}
      </Box>
      <Box displayPrint="block" display="none">
        {renderPrintResults()}
      </Box>
    </AdminActivityAuxView>
  );
};

export default SurveyReview;
