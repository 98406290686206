import React from 'react';
import { sortBy, map } from 'lodash';
import { useSelector } from 'react-redux';
// cr components
import Flow from 'components/NoteAndMap/Participant/Flow';
// selectors
import { useNoteAndMapSelector, getParticipantsFlows } from 'redux/selectors/noteAndMapSelectors';

/**
 * Note And Map view for the 'review' phase on the Participant side
 */
const NoteAndMapParticipantReview = () => {
  const {
    state: { activeView },
  } = useNoteAndMapSelector();
  const participantsFlows = useSelector(getParticipantsFlows());
  const sortedFlows = sortBy(participantsFlows, 'participantId');

  const renderFlow = (flow, idx, flowParticipantId) => (
    <Flow
      key={`moment-${idx}`}
      participantFlow={flow}
      context={activeView}
      createdFlowParticipantId={flowParticipantId}
      disabled={true}
    />
  );

  return map(sortedFlows, (flow, idx) => renderFlow(flow.types, idx, flow.participantId));
};

export default NoteAndMapParticipantReview;
