import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty, useFirestore } from 'react-redux-firebase';
import { map, keyBy, filter, keys, sortBy, values, union, includes } from 'lodash';

import { Button, Typography, Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import ButtonSpinner from '../ButtonSpinner';

import { enqueueSnackbar } from '../../redux/actions/notifierActions';
import { joinMeetingRoom } from '../../redux/actions/meetingRoomActions';

import { getParticipantCanControlView } from '../../redux/selectors/participantSessionSelectors';
import { getParticipantsMap } from '../../redux/selectors/participantsSelectors';

import { createSidebarMeetRoomId } from '../../utils/createMeetRoomIds';

const StartSidebarMeeting = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const db = useFirestore();
  const dispatch = useDispatch();
  const session = useSelector(state => state.firestore.data.session);
  const authUser = useSelector(state => state.firebase.auth);
  const sidebarMeetings = useSelector(state => state.firestore.data.sidebarMeetings);
  const participantsCanControlView = useSelector(getParticipantCanControlView);
  const isCoFacilitator = authUser.uid !== session?.facilitatorId;
  const participants = useSelector(getParticipantsMap);

  const unavailableUsers = union(...map(sidebarMeetings, sb => [...sb.userIds, sb.createdBy]), [
    ...map(
      filter(participants, part => !part.connected),
      disconnected => disconnected.id,
    ),
  ]);

  const coFacilitators = useSelector(state => {
    if (!state.firestore.data.coFacilitators) return {};
    return keyBy(
      map(state.firestore.data.coFacilitators, coFac => ({
        name: coFac.displayName,
        id: coFac.uid,
      })),
      'id',
    );
  });

  const facilitator = {
    [session?.facilitatorId]: {
      id: session?.facilitatorId,
      name: session?.facilitatorName || 'Facilitator',
    },
  };

  let userPool;

  if (isCoFacilitator) {
    userPool = { ...participants, ...facilitator };
  } else {
    userPool = { ...participants, ...coFacilitators };
  }

  const startSidebar = async () => {
    setIsSubmitting(true);
    const userIds = [...keys(selectedUsers)];

    if (!userIds.length) {
      setError('No users selected');
      setIsSubmitting(false);
      return;
    }

    const newSidebar = db.collection('sidebarMeetings').doc();
    const newSidebarRef = await newSidebar.get();

    const accepted = {};

    userIds.forEach(userId => {
      accepted[userId] = true;
    });

    const data = {
      sessionId: session.id,
      id: newSidebarRef.id,
      createdAt: db.FieldValue.serverTimestamp(),
      userIds,
      createdBy: authUser.uid,
      accepted,
    };

    db.doc(`sidebarMeetings/${newSidebarRef.id}`)
      .set(data)
      .then(() => {
        setIsOpen(false);
        setSelectedUsers({});
        setIsSubmitting(false);
        dispatch(
          joinMeetingRoom(
            {
              roomId: createSidebarMeetRoomId(session.id, newSidebarRef.id),
              roomName: 'Sidebar',
            },
            'Joining Sidebar Room',
          ),
        );
      })
      .catch(err => {
        dispatch(
          enqueueSnackbar({
            message: 'Error creating sidebar meeting',
            options: { variant: 'error' },
          }),
        );
        console.log(err);
      });
  };

  const toggleUserButton = id => {
    if (error) setError('');
    if (!keys(selectedUsers).length) {
      setSelectedUsers({ [id]: { ...userPool[id] } });
    }

    if (id in selectedUsers) {
      setSelectedUsers({
        ...keyBy(
          filter(selectedUsers, u => id !== u.id),
          'id',
        ),
      });
    } else {
      setSelectedUsers({ ...selectedUsers, [id]: { ...userPool[id] } });
    }
  };

  const renderButton = user => (
    <Button
      key={user.id}
      className="mr-1 mb-1"
      size="small"
      color="primary"
      variant={user.id in selectedUsers ? 'contained' : 'outlined'}
      onClick={() => {
        toggleUserButton(user.id);
      }}
      disabled={includes(unavailableUsers, user.id)}
    >
      {user.name}
    </Button>
  );

  const renderSortedButtons = users => {
    const sortedUsers = sortBy(values(users), [p => p?.name?.toLowerCase()]);
    return map(sortedUsers, user => renderButton(user));
  };

  if (isEmpty(participants) && isEmpty(coFacilitators)) return null;

  return (
    <div className="d-inline-block">
      <Button
        variant="contained"
        size="small"
        className="mr-2"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        Start Sidebar
      </Button>
      <Dialog
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        className="form-dialog"
      >
        <DialogTitle className="pb-0 text-center">Start Sidebar</DialogTitle>
        <DialogContent className="text-center">
          <Typography className="mb-6">
            Choose from the participants {!isCoFacilitator && 'and/or co-facilitators'} below to
            start a sidebar conversation.
            {!participantsCanControlView &&
              " You can't sidebar with participants when their view is set to Welcome or Feedback"}
          </Typography>
          <div className="mb-8">
            {isCoFacilitator && (
              <div className="mb-3">
                <Typography className="fw-500 mb-1">Facilitator</Typography>
                {renderSortedButtons(facilitator)}
              </div>
            )}
            {!isEmpty(coFacilitators) && !isCoFacilitator && (
              <div className="mb-3">
                <Typography className="fw-500 mb-1">Co-Facilitators</Typography>
                {renderSortedButtons(coFacilitators)}
              </div>
            )}
            {!isEmpty(participants) && participantsCanControlView && (
              <div className="mb-3 position-relative">
                <Typography className="fw-500 mb-1">Participants</Typography>
                {renderSortedButtons(participants)}
              </div>
            )}
          </div>
          <div className="d-flex flex-column align-items-center justify-content-center mb-4">
            {error.length > 0 && (
              <Typography className="text-danger fw-500 mb-3" style={{ fontSize: 18 }}>
                {error}
              </Typography>
            )}
            <Button
              variant="contained"
              color="primary"
              className="mb-2"
              disabled={isSubmitting}
              onClick={startSidebar}
            >
              Start Sidebar
              {isSubmitting && <ButtonSpinner />}
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                setIsOpen(false);
                setSelectedUsers({});
              }}
            >
              Cancel
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

StartSidebarMeeting.propTypes = {};

export default StartSidebarMeeting;
