import React from 'react';
import { useDispatch } from 'react-redux';
import { useConfirm } from 'material-ui-confirm';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

//staffing components
import SelectMenu from 'components/Staffing/SelectMenu/SelectMenu';

//actions & selectors
import { openCreateContractorDialog, deleteContractorUser } from 'redux/actions/staffingActions';
import FlagIcon from '@material-ui/icons/Flag';
import DescriptionIcon from '@material-ui/icons/Description';
import FlightIcon from '@material-ui/icons/Flight';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';
import TagIcon from '@material-ui/icons/LocalOffer';
import TagChip from 'components/TagChip';

const useStyles = makeStyles(({ theme }) => ({
  rowView: {
    display: 'flex',
    marginBottom: 10,
  },
  noteIcon: {
    width: 20,
    color: 'rgba(158, 158, 158, 1)',
  },
  tagIcon: {
    width: 20,
    color: 'rgba(158, 158, 158, 1)',
  },
  flagIcon: {
    width: 20,
    color: 'rgba(226, 53, 30, 1)',
  },
  flightIcon: {
    width: 20,
    color: 'rgba(158, 158, 158, 1)',
  },
  noteText: {
    fontSize: 14,
    fontWeight: 300,
    color: 'rgba(0, 0, 0, 0.87)',
    marginLeft: 5,
  },
}));

const AboutContractorCard = ({ contractor }) => {
  const {
    displayPhone,
    displayEmail,
    vcEmail,
    displayName,
    type,
    staffType,
    address,
    city,
    state,
    postalCode,
    country,
    emergencyContactName,
    emergencyContactPhone,
    emergencyContactEmail,
    contractEndDate,
    tags,
    notes,
    languages,
    flagComments,
    hasVCZoomLicense,
    birthday,
    flightPreferences,
  } = contractor;

  const classes = useStyles();
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const history = useHistory();

  const deleteContractorProfile = () => {
    confirm({
      description: `You are about to delete ${displayName}'s profile. This action is permanent. Are you sure?.`,
    }).then(() => {
      dispatch(
        deleteContractorUser(contractor.id, () => {
          history.push('/admin/staffing/contractors');
        }),
      );
    });
  };

  // MENU CONTROL OPTIONS
  const menuOptions = [
    {
      title: 'Edit Profile',
      onItemClick: () => {
        dispatch(openCreateContractorDialog(true, contractor.id));
      },
    },
    {
      title: 'Delete',
      onItemClick: deleteContractorProfile,
    },
  ];

  const formattedContractEndDate =
    contractEndDate && moment(contractEndDate).isAfter(moment())
      ? moment(contractEndDate).format('MMMM D, YYYY')
      : null;

  return (
    <div
      className="d-flex justify-content-between flex-wrap shadow rounded p-3 border position-relative w-100"
      style={{ minWidth: '300px', minHeight: '220px' }}
    >
      <div className="flex-grow-1">
        <h6 className="text-uppercase text-secondary">About Staff</h6>
        <h4 className="font-weight-bold mb-0">{displayName}</h4>
        <p className="text-dark">
          {staffType}, {type}
        </p>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <p className="text-dark mb-0">
              <a href={`mailto:${displayEmail}`}>{displayEmail}</a>
              {vcEmail && (
                <>
                  {' | '}
                  <a href={`mailto:${vcEmail}`}>{vcEmail}</a>
                </>
              )}
              {displayPhone && ` | ${displayPhone}`}
            </p>
            <p className="text-dark mb-0">
              {[address, city, state, postalCode, country].filter(Boolean).join(', ')}
            </p>
            {languages && languages.length > 0 && (
              <p className="text-dark mb-0">Languages: {languages.join(', ')}</p>
            )}
            <p className="text-dark mb-0">Birthday: {birthday}</p>
            <p className="text-dark mb-0">
              Emergency Contact:{' '}
              {[emergencyContactName, emergencyContactPhone, emergencyContactEmail]
                .filter(Boolean)
                .join(', ')}
            </p>
            <p className="text-dark mb-0">
              {hasVCZoomLicense ? (
                'Has VC Zoom License'
              ) : (
                <span style={{ color: 'red' }}>No VC Zoom License</span>
              )}
            </p>
            <p className="text-dark mb-0">
              {formattedContractEndDate
                ? `Contract ends: ${formattedContractEndDate}`
                : 'No active contract'}
            </p>
            {flagComments && (
              <div className={classes.rowView}>
                <FlagIcon className={classes.flagIcon} />
                <Typography className={classes.noteText}>{flagComments || 'None'}</Typography>
              </div>
            )}
          </Grid>
          <Grid item xs={6}>
            <div className={classes.rowView}>
              <DescriptionIcon className={classes.noteIcon} />
              <Typography className={classes.noteText}>{notes || 'None'}</Typography>
            </div>
            {flightPreferences && (
              <div className={classes.rowView}>
                <FlightIcon className={classes.flightIcon} />
                <Typography className={classes.noteText}>{flightPreferences || 'None'}</Typography>
              </div>
            )}

            {tags && tags.length > 0 && (
              <div className={classes.rowView}>
                <TagIcon className={classes.tagIcon} />
                <div>
                  {tags.map((tag, idx) => (
                    <TagChip key={idx} label={tag} />
                  ))}
                </div>
              </div>
            )}
          </Grid>
        </Grid>
      </div>

      <SelectMenu options={menuOptions} />
    </div>
  );
};

export default AboutContractorCard;
