import { useShallowEqualSelector } from '../utils';
import { createSelector } from 'reselect';
import { filter, keys } from 'lodash';
import shuffleIdeas from 'utils/brainwriting/shuffleIdeas';

const brainwritingSelector = state => state.firestore.data.brainwriting;

export const useBrainwritingSelector = () => {
  return useShallowEqualSelector(brainwritingSelector);
};

export const getSubmissionRatioThisRound = createSelector(
  [brainwritingSelector],
  ({ participants, rounds, currentRound }) => {
    const assignmentsThisRound = rounds[currentRound].assignments;
    const submitCount = filter(assignmentsThisRound, assignment => assignment.submitted).length;

    return {
      submitCount,
      participantsCount: participants?.length || 0,
    };
  },
);

export const getIsShuffleReady = createSelector(
  [brainwritingSelector],
  ({ rounds, currentRound }) => {
    if (!keys(rounds[currentRound].assignments).length) return false;

    return (
      filter(rounds[currentRound].assignments, assignment => {
        return assignment.submitted === true;
      }).length >= 2
    );
  },
);

export const getRoundSubmissionsComplete = createSelector(
  [brainwritingSelector],
  ({ rounds, currentRound }) => {
    if (!keys(rounds[currentRound].assignments).length) return false;

    const assignmentsCount = keys(rounds[currentRound].assignments).length;

    return (
      filter(rounds[currentRound].assignments, assignment => {
        return assignment.submitted;
      }).length === assignmentsCount
    );
  },
);

export const getCanShuffle = createSelector([brainwritingSelector], ({ ideas, rounds }) => {
  const activeParticipants = keys(ideas);
  const nextAssignments = shuffleIdeas(ideas, rounds);
  const activeParticipantsCount = activeParticipants.length;
  const nextAssignmentsCount = keys(nextAssignments).length;

  return nextAssignmentsCount === activeParticipantsCount;
});
