import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
// library components
import { makeStyles, Divider, Box } from '@material-ui/core';
// cr components
import Activity from './Activity';
import Slider from './Slider';

const useStyles = makeStyles(theme => ({
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: 35,
  },
}));

/**
 * Renders all activities in meeting in their status (active, completed, not started)
 */
const ParticipantMeetingProgress = ({ session }) => {
  const classes = useStyles();

  const { activities, activeRoute } = session;

  let nextActivityIndex;
  let currentActivityIndex;
  const sessionActivities = map(activities, (activity, idx) => {
    //TODO: This logic is pretty crappy. We aren't using status right now.
    // If we start using it again we need to fix it...
    if (activity.route === activeRoute) {
      currentActivityIndex = idx;
      return { ...activity, status: 'active' };
    }
    if (currentActivityIndex && !nextActivityIndex) {
      nextActivityIndex = idx;
      return { ...activity, status: 'next' };
    }
    if (!nextActivityIndex && !currentActivityIndex) {
      return { ...activity, status: 'completed' };
    }
    return { ...activity, status: 'later' };
  });

  // return activities
  const renderActivities = () =>
    map(sessionActivities, (activity, idx) => (
      <Activity
        key={`activity-${idx}`}
        slideNumber={idx}
        activityName={activity.name}
        activityStatus={activity.status}
        activityType={activity.activity}
        activityId={activity.id}
      />
    ));

  return (
    <Box className="w-100">
      <Box className="d-flex flex-wrap px-3">
        <Slider slides={renderActivities()} />
      </Box>
      <Divider className={classes.divider} />
    </Box>
  );
};

ParticipantMeetingProgress.propTypes = {
  session: PropTypes.shape({
    activeRoute: PropTypes.string, // the participant route
    activities: PropTypes.arrayOf(PropTypes.object), // meeting actvities
  }),
};

export default ParticipantMeetingProgress;
