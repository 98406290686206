import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isLoaded, isEmpty } from 'react-redux-firebase';
import { useFirestore } from 'react-redux-firebase';
import * as Sentry from '@sentry/react';
import mixpanel from 'mixpanel-browser';
import moment from 'moment';

import Spinner from '../Spinner';

const AuthIsLoaded = ({ children }) => {
  const authUser = useSelector(state => state.firebase.auth);
  const user = useSelector(state => state.firebase.profile);
  const [canConnectToFirestore, setCanConnectToFirestore] = useState(false);
  const db = useFirestore();

  const isConnectionErrorPage = window.location.pathname.split('/')[2] === 'connection-error';

  /**
   * Check if the user can connect to Firestore before loading the app.
   * Some corporate VPNs or firewalls can have issues with this.
   */
  useEffect(() => {
    if (!isConnectionErrorPage) {
      db.doc('connectionCheck/check-me')
        .get()
        .then(() => {
          setCanConnectToFirestore(true);
        })
        .catch(err => {
          const previousRoute = window.location.href;
          window.location.href = `/auth/connection-error?previousRoute=${previousRoute}`;
          console.error(err);
        });
    }
  }, [isConnectionErrorPage]);

  if (
    (!isLoaded(authUser) || !isLoaded(user) || !canConnectToFirestore) &&
    !isConnectionErrorPage
  ) {
    return (
      <div className="w-100 vh-100 d-flex justify-content-center align-items-center">
        <Spinner />
      </div>
    );
  }

  console.log('auth is loaded, render...');

  if (!isEmpty(authUser)) {
    Sentry.configureScope(function (scope) {
      scope.setUser({
        email: authUser.isAnonymous ? 'anonymous' : authUser.email,
        id: authUser.uid,
      });
    });

    if (process.env.REACT_APP_HOSTING_ENV === 'prod') {
      if (!authUser.isAnonymous && !user.isInMixpanel) {
        mixpanel.people.set(
          {
            $email: authUser.email,
            USER_ID: authUser.uid,
            'Sign Up Date': moment.unix(user?.timestamp?.seconds).toISOString(),
          },
          function () {
            db.doc(`users/${authUser.uid}`)
              .update({
                isInMixpanel: true,
              })
              .catch(err => {
                console.log(err);
              });
          },
        );
      }

      if (!authUser.isAnonymous) {
        mixpanel.identify(authUser.uid);
      }
    }
  }

  return children;
};

export default AuthIsLoaded;
