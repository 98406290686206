import { makeStyles } from '@material-ui/core/styles';

export const useAssessmentStyles = makeStyles(theme => ({
  card: {
    border: '1px solid #D6D6D6',
  },
  questionText: {
    fontSize: 30,
    fontWeight: '300',
    [theme.breakpoints.down('xs')]: {
      fontSize: 24,
    },
  },
  dot: {
    display: 'block',
    content: "' '",
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: theme.palette.indigo.main,
  },
}));
