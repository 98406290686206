import { map, each, filter, sortBy, size, join } from 'lodash';
import moment from 'moment';

/**
 * Creates the output array for a Sprint Scorecard actvity
 * @param {Object} participantAnswers
 * @param {Object} sprintQuestions
 * @param {Object} prototypeQuestions
 * @returns {Array<{question: String, answers: Array<String>}>}
 */
export const createOutput = (allAnswers, sprintQuestions, prototypeQuestions) => {
  const sortedSprintQuestions = sortBy(sprintQuestions, 'order');
  const sortedPrototypeQuestions = sortBy(prototypeQuestions, 'order');
  const questions = [...sortedSprintQuestions, ...sortedPrototypeQuestions];
  const participantAnswers = map(questions, question => ({
    question: question.value,
    id: question.id,
    answers: [],
  }));
  each(allAnswers, answer =>
    each(answer, participants =>
      each(participantAnswers, participantAnswer => {
        const questionId = participantAnswer.id;
        if (participants?.[questionId]) {
          participantAnswer.answers.push(participants?.[questionId]);
        }
      }),
    ),
  );
  const percentage = (list, key) => {
    const size = list.length;
    const count = filter(list, item => {
      return item === key;
    }).length;
    return (count / size) * 100 || 0;
  };
  return map(participantAnswers, answer => ({
    question: answer.question,
    yes: percentage(answer.answers, 'Yes'),
    no: percentage(answer.answers, 'No'),
    unclear: percentage(answer.answers, 'Unclear'),
    na: percentage(answer.answers, 'N/A'),
  }));
};

export const getSortedNotes = notes =>
  sortBy(notes, note => {
    switch (note.action?.action) {
      case 'like':
        return 0;
      case 'unlike':
        return 1;
      case 'quote':
        return 2;
      default:
        return 3;
    }
  });

/**
 * Render action type
 * @param {String} action
 * @returns {String}
 */
export const renderActionType = action => {
  switch (action) {
    case 'like':
      return 'Positive';
    case 'unlike':
      return 'Negative';
    case 'quote':
      return 'Quotes';
    default:
      return '';
  }
};

/**
 * Creates the array for Report Header
 *
 */
export const createScorecardReportHeader = () => [
  { label: 'Type', key: 'type' },
  { label: 'Participant', key: 'participant' },
  { label: 'Question', key: 'question' },
  { label: 'Score', key: 'score' },
  { label: 'Comment', key: 'comment' },
  { label: 'Insight', key: 'insight' },
  { label: 'Time', key: 'time' },
  { label: 'Tester', key: 'tester' },
];

/**
 * Creates the data (auestions/answers) for Report
 *
 */
export const createScorecardReportQuestions = (config, answers, comments) => {
  const { sprintQuestions, prototypeQuestions } = config;
  // sort sprint questions by value and id
  const sortedSprintQuestions = sortBy(sprintQuestions, ['order', 'value']);
  // sort prototype questions by value and id
  const sortedPrototypeQuestions = sortBy(prototypeQuestions, ['order', 'value']);
  const questions = [...sortedSprintQuestions, ...sortedPrototypeQuestions];
  const participantAnswers = [];
  each(answers, (tester, testerId) =>
    each(tester, (participants, participantId) =>
      each(questions, participantAnswer => {
        const questionId = participantAnswer.id;
        if (participants?.[questionId]) {
          participantAnswers.push({
            participantId: participantId,
            testerId: testerId,
            question: participantAnswer.value,
            answer: participants?.[questionId],
            comment: comments?.[testerId]?.[participantId]?.[questionId],
          });
        }
      }),
    ),
  );
  return participantAnswers;
};

/**
 * Creates the data for Report
 *
 */
export const createScorecardReportData = (notes, participantsData, config, answers, comments) => {
  const participantAnswers = createScorecardReportQuestions(config, answers, comments);
  // sort notes by action
  const sortedNotes = getSortedNotes(notes);
  const insights = map(sortedNotes, note => ({
    type: renderActionType(note.action?.action),
    participant: participantsData[note.participantId]?.name,
    insight: note.note,
    time: note.action
      ? moment(note.action.time).format('hh:mm A')
      : moment(note.timeCreated).format('hh:mm A'),
    tester: config.testers[note.testerId]?.value,
  }));
  const questions = map(participantAnswers, answer => ({
    type: 'Score',
    participant: participantsData[answer.participantId]?.name,
    question: answer.question,
    score: answer.answer,
    tester: config.testers[answer.testerId]?.value,
    comment: answer.comment,
  }));
  return [...questions, ...insights];
};

export const createScorecardReport = (activityData, participantMap) => {
  const participantData = activityData?.participantData;
  const config = activityData?.config;
  const participantsNotes = [];
  each(participantData?.notes, note => {
    if (size(note.participantsActions)) {
      each(note.participantsActions, action =>
        participantsNotes.push({ ...note, action, timeCreated: action.time }),
      );
    }
    participantsNotes.push(note);
  });

  const headers = createScorecardReportHeader();
  const data = createScorecardReportData(
    participantsNotes,
    participantMap,
    config,
    participantData?.answers,
    participantData?.comments,
  );

  return { headers, data };
};
