import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';
import { map, sortBy } from 'lodash';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
// library components
import { Box, FormLabel, TextareaAutosize } from '@material-ui/core';
// cr components
import NewField from './NewField';
import AddButton from './AddButton';
import AutoField from '../AutoField';
// utils
import { generateRandomId } from '../../../utils/generateRandomId';

const useStyles = makeStyles(theme => ({
  disabledFieldWrapper: {
    position: 'relative',
    '&::before': {
      position: 'absolute',
      display: 'block',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      content: '""',
      backgroundColor: 'white',
      opacity: '0.5',
    },
  },
  root: {
    minHeight: 40,
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    borderRadius: 4,
    padding: theme.spacing(1),
    backgroundColor: '#EEEEEE',
    display: 'inline-flex',
    alignItems: 'flex-start',
    alignSelf: 'flex-start',
  },
  textArea: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    background: 'transparent',
    resize: 'none',
    border: '0 none',
    outline: 'none',
    padding: 0,
    display: 'inline-block',
  },
  fakeInput: {
    width: '1px', // zoom properly (iOS)
    height: 0, // hide cursor (font-size: 0 will zoom to quarks level) (iOS)
    opacity: 0, // make input transparent :]
    marginLeft: '-8px',
  },
}));

/**
 * Renders fields for creating custom parameters that live in an object together.
 */
const TextOptionsCluster = ({ value, name, label, inputProps, disabled, canAddNew, child }) => {
  const [field, meta] = useField(name);
  const values = { ...value, ...field.value };
  const { setFieldValue, submitCount } = useFormikContext();
  const sortedValues = sortBy(values, ['order', 'value']);
  const { error, touched } = meta;
  const hasError = error && (touched || submitCount);
  const classes = useStyles();
  const input = useRef(null);
  const fakeInput = useRef(null);
  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState('');

  /**
   * Renders fields.
   */
  const createFields = () => {
    if (!sortedValues.length) return;

    return map(sortedValues, field => {
      const { id, value, order } = field;

      return (
        <NewField
          key={`new-filed-${id}`}
          fieldId={id}
          fieldValue={value}
          fieldOrder={order}
          inputProps={inputProps}
          handleDeleteField={handleDeleteField}
          handleChangeFieldValue={handleChangeFieldValue}
          setFocus={setFocus}
          disabled={disabled}
        />
      );
    });
  };

  /**
   * Add new field.
   */
  const handleAddField = fieldValue => {
    const fieldId = generateRandomId();
    const lastOrder = sortedValues.length ? sortedValues[sortedValues.length - 1].order : 0;

    values[fieldId] = {
      value: fieldValue,
      id: fieldId,
      order: lastOrder + 1,
    };
    setFieldValue(name, values);
  };

  /**
   * set focus on field (need from iOS).
   */
  const setFocus = (event, field) => {
    event.preventDefault();
    // focus so that subsequent async focus will work
    fakeInput.current.focus();
    if (field === input.current) {
      setIsEditing(true);
    }
    requestAnimationFrame(() => {
      // Shift focus to target field
      field.focus();
    });
  };

  /**
   * Edit field value.
   */
  const handleChangeFieldValue = (fieldId, fieldValue, fieldOrder) => {
    values[fieldId] = {
      value: fieldValue,
      id: fieldId,
      order: fieldOrder,
    };
    setFieldValue(name, values);
  };

  /**
   * Delete field.
   */
  const handleDeleteField = fieldId => {
    delete values[fieldId];
    setFieldValue(name, values);
  };

  const blurHandler = () => {
    if (text) {
      handleAddField(text);
    }
    setIsEditing(false);
    setText('');
  };

  const changeTextHandler = event => {
    setText(event.target.value);
  };

  return (
    <>
      <Box {...field} className={cx({})}>
        <FormLabel component="legend" style={{ marginBottom: 10 }}>
          {label}
        </FormLabel>
        <Box
          className={cx({
            [classes.disabledFieldWrapper]: disabled,
          })}
        >
          <Box display="flex" flexWrap="wrap">
            {createFields()}
            <Box className={cx(classes.root, { 'd-none': !isEditing })}>
              <TextareaAutosize
                className={cx(classes.textArea, {
                  'd-none': !isEditing,
                })}
                ref={input}
                value={text}
                onChange={changeTextHandler}
                onBlur={blurHandler}
                type="text"
                id="target-input"
                {...inputProps}
              />
            </Box>
            {/* invisible dummy input to receive the focus first */}
            <input
              className={classes.fakeInput}
              type="text"
              id="fake-input"
              placeholder="I'm fake"
              ref={fakeInput}
            />
            {!disabled && canAddNew && (
              <AddButton handleClick={event => setFocus(event, input.current)} />
            )}
          </Box>
        </Box>
      </Box>
      {hasError ? (
        <Box component="span" color="error.main" className="d-block" style={{ marginTop: '-16px' }}>
          {error}
        </Box>
      ) : null}
      {!!child && (
        <AutoField
          id={`${child.key}`}
          field={{ ...child.formField, parentFieldValue: field.value }}
          disabled={disabled}
        />
      )}
    </>
  );
};

TextOptionsCluster.defaultProps = {
  canAddNew: true,
};

TextOptionsCluster.propTypes = {
  // The object contains custom parameter name, id and order.
  values: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.string,
    order: PropTypes.number,
  }),
  // The name of config field.
  name: PropTypes.string,
  // The title of field.
  label: PropTypes.string,
  // The props for input component.
  inputProps: PropTypes.object,
  // is the field disabled
  disabled: PropTypes.bool,
  // can new items be added
  canAddNew: PropTypes.bool,
};

export default TextOptionsCluster;
