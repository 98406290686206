import React from 'react';
import * as Yup from 'yup';
// cr components
import QuestionWithChoices from './QuestionWithChoices';

export const MULTIPLE_CHOICE_SCHEMA = {
  choices: Yup.array()
    .of(Yup.string().min(1).required('an answer choice cannot be empty'))
    .min(2, 'You must add at least 2 choices'),
};

export const MULTIPLE_CHOICE_INITIAL_VALUES = {
  choices: [''],
};

/**
 * ShortAnswer has no additional fields, it is the default
 */
const MultipleChoice = () => <QuestionWithChoices fieldName="choices" />;

export default MultipleChoice;
