import { enqueueErrorSnackbar } from 'redux/actions/notifierActions';

export const updateIsBroadcasting = (sessionId, isBroadcasting) => {
  return (dispatch, getState, { getFirebase }) => {
    const firestore = getFirebase().firestore;
    const db = firestore();

    db.doc(`sessions/${sessionId}`).set(
      {
        facilitatorIsBroadcasting: Boolean(isBroadcasting),
      },
      { merge: true },
    );
  };
};

export const updateRecapDeckImages =
  (sessionId, activityId, url, isRemoveImage, succesCb) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    const images = isRemoveImage ? db.FieldValue.arrayRemove(url) : db.FieldValue.arrayUnion(url);

    db()
      .doc(`sessions/${sessionId}`)
      .set(
        {
          recapDeck: {
            activities: {
              [`${activityId}`]: {
                supplementalImages: images,
              },
            },
          },
        },
        { merge: true },
      )
      .then(() => {
        succesCb();
      })
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error update recap deck images'));
      });
  };

export const updateRecapDeckNotes =
  (sessionId, type, value) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`sessions/${sessionId}`)
      .set(
        {
          recapDeck: {
            [`${type}`]: value,
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error update recap deck notes'));
      });
  };
