import React from 'react';
import AutoCompleteBase from 'components/AutoCompleteBase';

const SearchableSelect = ({
  freeSolo,
  forcePopupIcon,
  options,
  getOptionLabel,
  getOptionSelected,
  ...props
}) => {
  if (options.length > 0 && typeof options[0] === 'string') {
    throw new Error('SearchableSelect options must be an array of objects, found strings');
  }

  if (
    options.length > 0 &&
    (!options[0].hasOwnProperty('name') || !options[0].hasOwnProperty('value'))
  ) {
    throw new Error(
      'SearchableSelect options must be an array of objects with "name" and "value" properties',
    );
  }

  const defaultGetOptionLabel = option => option.name;
  const defaultGetOptionSelected = (option, value) => option.value === value.value;

  return (
    <AutoCompleteBase
      options={options}
      forcePopupIcon={true}
      clearOnBlur
      getOptionLabel={getOptionLabel || defaultGetOptionLabel}
      getOptionSelected={getOptionSelected || defaultGetOptionSelected}
      {...props}
    />
  );
};

export default SearchableSelect;
