import React, { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import AddActivityForm from '../Forms/AddActivityForm';
import FormDialog from '../Dialogs/FormDialog';

// actions
import { openPremiumSubscriptionDialog } from 'redux/actions/facilitatorSessionActions';

const useStyles = makeStyles(theme => {
  return {
    root: {
      fontWeight: 400,
      color: theme.palette.primary.main,
      textTransform: 'none',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  };
});

const AddActivityButton = ({ isUserAccountLimit, className, withIcon }) => {
  const classes = useStyles();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const dispatch = useDispatch();

  const toggleDialog = () => {
    isUserAccountLimit
      ? setIsDialogOpen(!isDialogOpen)
      : dispatch(openPremiumSubscriptionDialog(true));
  };

  return (
    <Fragment>
      <div>
        <Button
          className={className}
          classes={classes}
          size="small"
          onClick={() => {
            toggleDialog();
          }}
        >
          <div className="d-flex align-items-center">
            {withIcon && <AddCircleIcon className="mr-1" style={{ fontSize: 22 }} />}
            <Box
              component="span"
              style={{ fontSize: 14, position: 'relative', top: 1, left: '-3px' }}
            >
              Add Activity
            </Box>
          </div>
        </Button>
      </div>

      <FormDialog handleClose={toggleDialog} isOpen={isDialogOpen} title="Add Activity">
        <AddActivityForm closeDialog={toggleDialog} />
      </FormDialog>
    </Fragment>
  );
};

AddActivityButton.propTypes = {
  isUserAccountLimit: PropTypes.bool, // the account limit depending on the user's role
};

export default AddActivityButton;
