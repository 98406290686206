import { compact, concat, uniq } from 'lodash';

/**
 * Strips prepositions and pronouns from a string
 * @param {string[]} strings
 * @param {string[]} blacklist
 * @returns {Array}
 */
export const stripWordsFromArray = (strings, blacklist) => {
  let theWords = compact(strings);

  if (theWords.length) {
    theWords = theWords.filter(word => !blacklist.includes(word.trim().toLowerCase()));
  }

  return theWords;
};

export const prepositions = [
  // prepositions: https://en.wikipedia.org/wiki/List_of_English_prepositions
  'a',
  'aboard',
  'about',
  'above',
  'abreast',
  'absent',
  'across',
  'after',
  'against',
  'along',
  'aloft',
  'alongside',
  'amid',
  'amidst',
  'mid',
  'midst',
  'among',
  'amongst',
  'anti',
  'apropos',
  'around',
  'round',
  'as',
  'aslant',
  'astride',
  'at',
  'atop',
  'ontop',
  'bar',
  'barring',
  'before',
  'behind',
  'below',
  'beneath',
  'beside',
  'besides',
  'between',
  'beyond',
  'but',
  'by',
  'chez',
  'circa',
  'come',
  'concerning',
  'contra',
  'counting',
  'despite',
  'down',
  'during',
  'effective',
  'ere',
  'except',
  'excepting',
  'excluding',
  'failing',
  'following',
  'for',
  'from',
  'in',
  'including',
  'inside',
  'into',
  'less',
  'like',
  'minus',
  'modulo',
  'near',
  'nearer',
  'nearest',
  'next',
  'notwithstanding',
  'of',
  'off',
  'offshore',
  'on',
  'onto',
  'opposite',
  'out',
  'outside',
  'over',
  'pace',
  'past',
  'pending',
  'per',
  'plus',
  'post',
  'pre',
  'pro',
  'qua',
  're',
  'regarding',
  'respecting',
  'sans',
  'save',
  'saving',
  'since',
  'sub',
  'than',
  'through',
  'throughout',
  'till',
  'times',
  'to',
  'touching',
  'toward',
  'towards',
  'under',
  'underneath',
  'unlike',
  'until',
  'up',
  'upon',
  'versus',
  'vs',
  'via',
  'vice',
  'wanting',
  'with',
  'within',
  'without',
  'w/o',
];

export const pronouns = [
  // pronouns: https://www.thefreedictionary.com/List-of-pronouns.htm
  'all',
  'another',
  'any',
  'anybody',
  'anyone',
  'anything',
  'as',
  'aught',
  'both',
  'each',
  'each other',
  'either',
  'enough',
  'everybody',
  'everyone',
  'everything',
  'few',
  'he',
  'her',
  'hers',
  'herself',
  'him',
  'himself',
  'his',
  'I',
  'idem',
  'it',
  'its',
  'itself',
  'many',
  'me',
  'mine',
  'most',
  'my',
  'myself',
  'naught',
  'neither',
  'no one',
  'nobody',
  'none',
  'nothing',
  'nought',
  'one',
  'one another',
  'other',
  'others',
  'ought',
  'our',
  'ours',
  'ourself',
  'ourselves',
  'several',
  'she',
  'some',
  'somebody',
  'someone',
  'something',
  'somewhat',
  'such',
  'suchlike',
  'that',
  'thee',
  'their',
  'theirs',
  'theirself',
  'theirselves',
  'them',
  'themself',
  'themselves',
  'there',
  'these',
  'they',
  'thine',
  'this',
  'those',
  'thou',
  'thy',
  'thyself',
  'us',
  'we',
  'what',
  'whatever',
  'whatnot',
  'whatsoever',
  'whence',
  'where',
  'whereby',
  'wherefrom',
  'wherein',
  'whereinto',
  'whereof',
  'whereon',
  'wherever',
  'wheresoever',
  'whereto',
  'whereunto',
  'wherewith',
  'wherewithal',
  'whether',
  'which',
  'whichever',
  'whichsoever',
  'who',
  'whoever',
  'whom',
  'whomever',
  'whomso',
  'whomsoever',
  'whose',
  'whosever',
  'whosesoever',
  'whoso',
  'whosoever',
  'ye',
  'yon',
  'yonder',
  'you',
  'your',
  'yours',
  'yourself',
  'yourselves',
];

export const stateOfBeingVerbs = [
  // state of being verbs: https://www.wordnik.com/lists/state-of-being-verbs
  'been',
  'being',
  'be',
  'are',
  'were',
  'is',
  'am',
  'was',
];

export const otherUselessWords = [
  // other
  'n/a',
  'and',
  'the',
  'i',
  'much',
  'more',
  'while',
  'have',
  'want',
];

// https://github.com/Alir3z4/stop-words/blob/bd8cc1434faeb3449735ed570a4a392ab5d35291/english.txt
export const otherStopWords = [
  'a',
  'about',
  'above',
  'after',
  'again',
  'against',
  'all',
  'am',
  'an',
  'and',
  'any',
  'are',
  "aren't",
  'as',
  'at',
  'be',
  'because',
  'been',
  'before',
  'being',
  'below',
  'between',
  'both',
  'but',
  'by',
  "can't",
  'cannot',
  'could',
  "couldn't",
  'did',
  "didn't",
  'do',
  'does',
  "doesn't",
  'doing',
  "don't",
  'down',
  'during',
  'each',
  'few',
  'for',
  'from',
  'further',
  'had',
  "hadn't",
  'has',
  "hasn't",
  'have',
  "haven't",
  'having',
  'he',
  "he'd",
  "he'll",
  "he's",
  'her',
  'here',
  "here's",
  'hers',
  'herself',
  'him',
  'himself',
  'his',
  'how',
  "how's",
  'i',
  "i'd",
  "i'll",
  "i'm",
  "i've",
  'if',
  'in',
  'into',
  'is',
  "isn't",
  'it',
  "it's",
  'its',
  'itself',
  "let's",
  'me',
  'more',
  'most',
  "mustn't",
  'my',
  'myself',
  'no',
  'nor',
  'not',
  'of',
  'off',
  'on',
  'once',
  'only',
  'or',
  'other',
  'ought',
  'our',
  'ours',
  'ourselves',
  'out',
  'over',
  'own',
  'same',
  "shan't",
  'she',
  "she'd",
  "she'll",
  "she's",
  'should',
  "shouldn't",
  'so',
  'some',
  'such',
  'than',
  'that',
  "that's",
  'the',
  'their',
  'theirs',
  'them',
  'themselves',
  'then',
  'there',
  "there's",
  'these',
  'they',
  "they'd",
  "they'll",
  "they're",
  "they've",
  'this',
  'those',
  'through',
  'to',
  'too',
  'under',
  'until',
  'up',
  'very',
  'was',
  "wasn't",
  'we',
  "we'd",
  "we'll",
  "we're",
  "we've",
  'were',
  "weren't",
  'what',
  "what's",
  'when',
  "when's",
  'where',
  "where's",
  'which',
  'while',
  'who',
  "who's",
  'whom',
  'why',
  "why's",
  'with',
  "won't",
  'would',
  "wouldn't",
  'you',
  "you'd",
  "you'll",
  "you're",
  "you've",
  'your',
  'yours',
  'yourself',
  'yourselves',
];

export const wordExclusions = uniq(
  concat(prepositions, pronouns, stateOfBeingVerbs, otherStopWords, otherUselessWords),
);
