import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { has, map, sortBy, forEach, size } from 'lodash';
// library components
import { Box } from '@material-ui/core';
// cr components
import ActivityPromptAdmin from 'components/ActivityPromptAdmin';
import ActivityActionButton from 'components/CustomButtons/ActivityActionButton';
import Categories from './Categories';
// selectors
import {
  useNoteAndCategorizeSelector,
  getResults,
} from 'redux/selectors/noteAndCategorizeSelectors';
// actions
import {
  updateActivityView,
  revealResults,
  updateSerialRoundRobinStatus,
  updateParticipantRoundRobinStatus,
} from 'redux/actions/noteAndCategorizeAction';
// config
import { NOTE_AND_CATEGORIZE_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';

const useStyles = makeStyles(theme => ({
  promptAdmin: {
    marginBottom: theme.spacing(1),
  },
}));

/**
 * Note & Categorize view for the 'review' phase on the Facilitator side
 */
const NoteAndCategorizeAdminReview = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const noteAndCategorize = useNoteAndCategorizeSelector();
  const {
    id,
    prompt,
    config: { categories, roundRobinShare, format, votesPerCategory },
    state: { activeView, isCategorized, serialRoundRobin },
    participantData: { participantsCompletedRoundRobin },
  } = noteAndCategorize;
  const results = useSelector(getResults());

  let sortField = 'value';
  let nextView = 'results';
  let finishButtonText = 'review final results';

  const sortedCategories = sortBy(
    map(categories, category => {
      sortField = has(category, 'order') ? 'order' : 'value';
      return category;
    }),
    sortField,
  );

  if (votesPerCategory !== '0') {
    nextView = 'vote';
    finishButtonText = 'Start Voting';
  }

  if (
    roundRobinShare === 'on' &&
    format === 'Serial' &&
    votesPerCategory === '0' &&
    size(isCategorized) < size(categories)
  ) {
    // if roundRobin on and serial format -> go to next category
    nextView = 'categorize';
    finishButtonText = 'Next Category';
  }

  const onRevealResults = () => {
    if (roundRobinShare === 'on' && format === 'Serial') {
      dispatch(updateSerialRoundRobinStatus(id, serialRoundRobin, nextView));
      forEach(participantsCompletedRoundRobin, participantId => {
        dispatch(updateParticipantRoundRobinStatus(id, participantId, true));
      });
    } else if (nextView === 'results') {
      dispatch(revealResults(id, 'results', false));
    } else {
      dispatch(updateActivityView(nextView, id));
    }
  };

  return (
    <Box className="d-flex flex-column justify-content-center">
      <ActivityPromptAdmin
        prompt={prompt}
        collection={CONFIG.collection}
        activityId={id}
        className={classes.promptAdmin}
      />
      <Categories categories={sortedCategories} view={activeView} output={results} />
      <ActivityActionButton
        text={finishButtonText}
        onClick={onRevealResults}
        className="mb-2 mt-6"
        delayReady
      />
    </Box>
  );
};

export default NoteAndCategorizeAdminReview;
