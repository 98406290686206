import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, Redirect, useHistory } from 'react-router-dom';

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';

import { getZoomToken } from 'redux/actions/zoomAuthActions';

const queryString = require('query-string');

const ZoomAuth = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const zoom_access_token = useSelector(state => state.firebase.profile.zoom_access_token);
  const userIsLoaded = useSelector(state => state.firebase.profile.isLoaded);
  const userIsAuthed = useSelector(state => !state.firebase.auth.isEmpty);
  const params = queryString.parse(location.search);

  useEffect(() => {
    // pop up opened from dashboard
    if (!params.code && params.is_popup && userIsAuthed && !zoom_access_token) {
      window.location.href = `${process.env.REACT_APP_API_URL}/zoom-oauth2`;
      return;
    }

    // redirected popup from Zoom
    if (params.code && userIsAuthed && !zoom_access_token) {
      dispatch(getZoomToken(params.code, '&is_profile_connect=1'));
    }

    // zoom install
    if (params.code && !userIsAuthed && !zoom_access_token) {
      dispatch(getZoomToken(params.code, '', true));
      return;
    }

    // direct visit to /zoom-signup
    if (!params.code && !userIsAuthed && !zoom_access_token) {
      window.location.href = `${process.env.REACT_APP_API_URL}/zoom-oauth2`;
    }
  }, [dispatch, params.code, userIsLoaded, userIsAuthed, zoom_access_token]);

  if (!params.code && userIsLoaded && zoom_access_token) {
    return <Redirect to="/admin/dashboard" />;
  }

  const isAuthSuccess = params.code && userIsLoaded && zoom_access_token;

  if (isAuthSuccess) {
    history.push(`/zoom-signup?code=${params.code}&isAuthSuccess=1`);
  }

  return (
    <div>
      <GridContainer direction="row" justify="center" alignItems="center">
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Typography variant="h5" component="h2">
            {isAuthSuccess ? 'Success!' : 'Authenticating...'}
          </Typography>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default ZoomAuth;
