import { cloneDeep, map, orderBy, forEach, has, filter, find, join, values, sortBy } from 'lodash';

/**
 * Creates the output object for a Note & Categorize activity after categorize
 * @param {Object} notes
 * @returns {Object}
 */
export const createCategorizeOutput = notes => {
  const output = {};

  const sortedNotes = sortBy(notes, ['order', 'id']);

  // create output by category
  forEach(sortedNotes, (note, noteId) => {
    if (note.categoryId) {
      if (!has(output, note.categoryId)) {
        output[note.categoryId] = {};
      }

      output[note.categoryId][noteId] = {
        noteId: note.noteId,
        text: note.note,
        participantId: note.participantId,
        combineNotesId: note.combineNotesId || [],
        isCombine: note.isCombine || false,
        combineParentNote: note.combineParentNote || '',
      };
    }
  });

  return output;
};

/**
 * Creates the output object for a Note & Categorize activity after vote
 * @param {Object} output
 * @param {Object} notes
 * @returns {Array<{text: String, winner: Boolean, votes: Number}>}
 */
export const createResults = (output, notes) => {
  // create initial output array
  const currentOutput = cloneDeep(output);

  forEach(currentOutput, (currentNotes, categoryId) => {
    const maxVotes = { noteIds: [], votes: 0 };

    forEach(currentNotes, (note, noteId) => {
      const votes = notes[note.noteId].votedFor?.length || 0;
      // find notes with the most votes
      if (votes > maxVotes.votes) {
        maxVotes.noteIds = [noteId];
        maxVotes.votes = votes;
      } else if (votes === maxVotes.votes) {
        maxVotes.noteIds.push(noteId);
      }

      currentOutput[categoryId][noteId].votes = votes;
      currentOutput[categoryId][noteId].winner = false;
    });

    forEach(maxVotes.noteIds, noteId => {
      if (maxVotes.votes !== 0) {
        currentOutput[categoryId][noteId].winner = true;
      }
    });

    // sort array
    currentOutput[categoryId] = orderBy(
      map(currentOutput[categoryId], note => note),
      ['votes', 'text'],
      ['desc', 'asc'],
    );
  });

  return currentOutput;
};

/**
 * Creates the output object for a Note & Categorize activity
 * @param {Object} categories
 * @param {Object} notes
 * @returns {Array<{name: String, notes: Array}>}
 */
export const createOutput = (categories, notes) => {
  const sortedCategories = sortBy(categories, 'order');
  const output = map(sortedCategories, category => {
    const categoryNotes = filter(notes, { categoryId: category.id });
    return {
      name: category.value,
      notes: map(categoryNotes, note => ({
        text: note.note,
        votes: note.votedFor ? note.votedFor.length : 0,
      })),
    };
  });
  return output;
};

/**
 * Creates the array for Report Header
 *
 */
export const createNoteAndCategorizeReportHeader = () => [
  { label: 'Votes', key: 'votes' },
  { label: 'Note', key: 'note' },
  { label: 'Category', key: 'category' },
];

/**
 * Creates the data for Report
 *
 */
export const createNoteAndCategorizeReportData = (participantNotes, config) => {
  const sortedNotes = orderBy(values(participantNotes), note => note.votedFor?.length, ['desc']);
  return map(sortedNotes, note => {
    const category = find(config.categories, category => category.id === note.categoryId);
    return {
      votes: note.votedFor?.length || 0,
      note: note.note,
      category: note.categoryId ? category.value : '',
    };
  });
};

export const createNoteAndCategorizeReport = activityData => {
  const headers = createNoteAndCategorizeReportHeader();
  const data = createNoteAndCategorizeReportData(
    activityData?.participantData?.notes,
    activityData.config,
  );
  return { headers, data };
};
