import React from 'react';
import { useSelector } from 'react-redux';
import { isEmpty, isLoaded } from 'react-redux-firebase';
import { useParams } from 'react-router-dom';
// cr components
import AssessmentAdminAuxFinalResults from 'components/Assessment/Admin/AssessmentAdminAuxFinalResults';
import AssessmentAdminQuestionResult from 'components/Assessment/Admin/AssessmentAdminQuestionResult';
// selectors
import { useAssessmentSelector } from 'redux/selectors/assessmentSelectors';

/**
 * Entry point for Assessment auxilary view on the Admin side.
 * Loads the Firestore object for the activity, ensures
 * the data is loaded before rendering the game, and determines
 * which view to show
 */
const AssessmentAdminAux = () => {
  const routeParams = useParams();
  const assessment = useAssessmentSelector();
  const session = useSelector(state => state.firestore.data.session);

  // ensure all of the data we need is loaded
  if (
    !isLoaded(session) ||
    isEmpty(session) ||
    !isLoaded(assessment) ||
    isEmpty(assessment) ||
    /**
     * Ensure the loaded Assessment data actually matches the id in the URL.
     * Since we use only store one instance of any activiy in Redux at a time,
     * if you switch to a different Assessment in the same meeting/session it is
     * possible to momentarily have data for the wrong instance of the activity while
     * useFirestoreConnect loads the new data
     */
    routeParams.activityId !== assessment?.id
  ) {
    return null;
  }

  if (
    assessment.state.activeView === 'results' ||
    (assessment.state.activeView === 'write' && !assessment.config.reviewResults)
  ) {
    return <AssessmentAdminAuxFinalResults />;
  } else {
    return <AssessmentAdminQuestionResult />;
  }
};

export default AssessmentAdminAux;
