import React from 'react';
import { makeStyles, Typography, Box } from '@material-ui/core';
import cx from 'classnames';

import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles({
  root: {
    alignItems: 'space-around',
  },
  placement: {
    backgroundColor: 'transparent',
    color: props => (props.hasMaxScore ? '#02A05C' : '#093B75'),
    borderRadius: 0,
    fontWeight: '500',
    fontSize: '24px',
  },
  idea: props => ({
    fontWeight: props.hasMaxScore ? '300' : 'normal',
    color: props.hasMaxScore ? 'rgba(0,0,0, 1)' : 'rgba(0,0,0, .6)',
    fontSize: '18px',
    maxWidth: '640px',
    letterSpacing: '0.15px',
    lineHeight: '22px',
    padding: '0 20px',
  }),
  score: {
    fontSize: '15px',
    fontWeight: 400,
    backgroundColor: props => (props.hasMaxScore ? '#02A05C' : '#093B75'),
    color: 'white',
    padding: '14px 5px',
    marginRight: '-18px',
    minWidth: '45px',
    textAlign: 'center',
    height: 50,
    verticalAlign: 'middle',
    display: 'inline-block',
    cursor: 'default',
  },
});

const Results2510ListItem = ({ idea, hasMaxScore, place }) => {
  const classes = useStyles({ hasMaxScore });
  return (
    <>
      <ListItem className={cx(classes.root, 'pl-0')}>
        <ListItemAvatar style={{ minWidth: 40 }}>
          <Avatar className={classes.placement}>#{place}</Avatar>
        </ListItemAvatar>
        <ListItemText className={classes.idea} primary={idea.idea} />
        <ListItemSecondaryAction>
          <Box edge="end" aria-label="score" className={classes.score}>
            <Typography>{idea.score}</Typography>
          </Box>
        </ListItemSecondaryAction>
      </ListItem>
      <Divider />
    </>
  );
};

export default Results2510ListItem;
