import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { filter, size, round, isEmpty, find, each, sortBy } from 'lodash';
// library components
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// cr components
import ActivityParticipantDetails from 'components/ActivityParticipantDetails';
import ActivityPromptAdmin from 'components/ActivityPromptAdmin';
import ActivityActionButton from 'components/CustomButtons/ActivityActionButton';
import CategoriesAdmin from 'components/Categories/CategoriesAdmin';
import ActiveCardSetAdmin from './ActiveCardSetAdmin';
import SelectOptionForm from './SelectOptionForm';
import FormDialog from 'components/Dialogs/FormDialog';
// selectors
import {
  useNarrowingSelector,
  getPrimaryCategorizedCards,
  getParticipantsCompletedRound,
} from 'redux/selectors/narrowingSelectors';
import { getActiveSet } from 'redux/selectors/cardSetsSelectors';
// actions
import {
  updateRound,
  updateActivityView,
  updateCardsCount,
  createCard,
  deleteCards,
} from 'redux/actions/narrowingActions';
// config
import { NARROWING_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';
// utils
import { makeParticipantDetailsHeaderText } from 'utils/makeParticipantDetailsHeaderText';

const useStyles = makeStyles(theme => ({
  promptAdmin: {
    marginBottom: theme.spacing(1),
  },
}));

/**
 * Card Sorting view for the 'primaryCategorize' phase on the Facilitator side
 */
const NarrowingAdminPrimaryCategorize = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const narrowing = useNarrowingSelector();
  const {
    id,
    prompt,
    config: { categories, primaryCategory, cardSet, initialCardsCount },
    // participantData: { cards },
    state: { cardsCount },
    state,
  } = narrowing;

  const currentRound = state.round;

  const [isModalOpen, setIsModalOpen] = useState(!cardsCount && currentRound > 1);

  const sortedCategories = filter(categories, { id: primaryCategory });

  const cards = useSelector(getPrimaryCategorizedCards(`round${currentRound - 1}`));

  const [activeCardSet, setActiveCardSet] = useState(cards);

  const isSetChanged = !isEmpty(cards) && !find(cards, card => card.cardSetId === cardSet);

  const participantsHaveCategorizedData = useSelector(
    getParticipantsCompletedRound(`round${currentRound}`),
  );

  // get cards for active set
  // const getCards = useCallback(async() => {
  //   const set = await getActiveSet(cardSet);
  //   each(set.cards, (card) => {
  //     dispatch(createCard(id, cardSet, card.order, card.icon || '', card.text || '', card.imageUrl || ''));
  //   });
  //   setActiveCardSet(sortBy(set.cards, ['order', 'cardId']));
  // }, [cardSet]);

  // useEffect(() => {
  //   if (!cardsCount && currentRound === 1) {
  //     dispatch(updateCardsCount(id, initialCardsCount))
  //   }
  // }, [cardsCount, currentRound]);

  useEffect(() => {
    // if (isEmpty(cards)) {
    //   getCards();
    // }
    if (isSetChanged) {
      dispatch(
        deleteCards(id, () => {
          setActiveCardSet({});
        }),
      );
    }
  }, [isSetChanged]);

  // sorting categorized items
  const participantsCategorizedCount = filter(participantsHaveCategorizedData, {
    completed: true,
  }).length;

  const participantsCategorizedCountText = makeParticipantDetailsHeaderText(
    participantsCategorizedCount,
    participantsHaveCategorizedData.length,
    `sorted round ${currentRound} cards`,
  );

  const onUpdateActivityView = () => {
    dispatch(updateActivityView(id, 'results'));
  };

  // Close modal form
  const closeDialog = () => {
    setIsModalOpen(false);
  };

  const closeSelectOptionFormAndResetRound = () => {
    dispatch(updateRound(id, currentRound - 1));
    dispatch(updateActivityView(id, 'results'));
    closeDialog();
  };

  const submitOptions = count => {
    dispatch(updateCardsCount(id, count));
    closeDialog();
  };

  const options = Array.from({ length: size(cards) }, (_, idx) => idx + 1);

  return (
    <>
      <Box className="d-flex flex-column justify-content-center">
        <ActivityPromptAdmin
          prompt={prompt}
          collection={CONFIG.collection}
          activityId={id}
          className={classes.promptAdmin}
        />
        <ActiveCardSetAdmin cardSetId={cardSet} />
        {/* <CategoriesAdmin
          categories={sortedCategories}
          primaryCategoryId={primaryCategory}
        /> */}
        <ActivityParticipantDetails
          headerText={participantsCategorizedCountText}
          hasCompletedLabel={`Sorted Round ${currentRound} Cards`}
          hasntCompletedLabel={`Hasn't Sorted Round ${currentRound} Cards`}
          completedCountMax={cardsCount}
          participantsCompletedStatus={participantsHaveCategorizedData}
        />
        <ActivityActionButton
          className="mt-2"
          text="Review Results"
          onClick={onUpdateActivityView}
          delayReady
        />
      </Box>
      <FormDialog
        handleClose={closeSelectOptionFormAndResetRound}
        isOpen={isModalOpen}
        isTitleHasCloseIcon
        centerTitle
        title="Select Option"
      >
        <SelectOptionForm
          options={options}
          submitOptions={submitOptions}
          defaultValue={round(size(cards) / 2)}
          closeDialog={closeSelectOptionFormAndResetRound}
        />
      </FormDialog>
    </>
  );
};

export default NarrowingAdminPrimaryCategorize;
