import React from 'react';
import PropTypes from 'prop-types';

import Divider from '@material-ui/core/Divider';

const PageContentHeader = ({ children, className }) => {
  return (
    <>
      <div className={`page-content-header ${className}`}>{children}</div>
      <Divider style={{ marginBottom: 30 }} />
    </>
  );
};

PageContentHeader.defaultProps = {
  className: '',
};

PageContentHeader.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default PageContentHeader;
