import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty, isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { useParams } from 'react-router-dom';
// cr components
import NoteAndVoteAdminWrite from 'components/NoteAndVote/Admin/NoteAndVoteAdminWrite';
import NoteAndVoteAdminShare from 'components/NoteAndVote/Admin/NoteAndVoteAdminShare';
import NoteAndVoteAdminReview from 'components/NoteAndVote/Admin/NoteAndVoteAdminReview';
import NoteAndVoteAdminVote from 'components/NoteAndVote/Admin/NoteAndVoteAdminVote';
import NoteAndVoteAdminResults from 'components/NoteAndVote/Admin/NoteAndVoteAdminResults';
import AdminActivityTemplate from 'components/AdminActivityTemplate';
// selectors
import { useNoteAndVoteSelector } from 'redux/selectors/noteAndVoteSelectors';
// config
import { NOTE_AND_VOTE_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';

const views = {
  write: NoteAndVoteAdminWrite,
  share: NoteAndVoteAdminShare,
  review: NoteAndVoteAdminReview,
  vote: NoteAndVoteAdminVote,
  results: NoteAndVoteAdminResults,
};

/**
 * Entry point for Note & Vote on the Admin side.
 * Loads the Firestore object for the activity, ensures
 * the data is loaded before rendering the game, and determines
 * which view to show
 */
const AdminNoteAndVote = () => {
  const routeParams = useParams();
  const noteAndVote = useNoteAndVoteSelector();
  const session = useSelector(state => state.firestore.data.session);
  const meetingParticipants = useSelector(state => state.firestore.data.participants);

  // hooks up a firestore listener to Redux
  useFirestoreConnect(() => [
    {
      collection: 'noteAndVote',
      doc: `${routeParams.activityId}`,
      storeAs: 'noteAndVote',
    },
  ]);

  // ensure all of the data we need is loaded
  if (
    !isLoaded(session) ||
    isEmpty(session) ||
    !isLoaded(noteAndVote) ||
    isEmpty(noteAndVote) ||
    !isLoaded(meetingParticipants) ||
    /**
     * Ensure the loaded Note & Vote data actually matches the id in the URL.
     * Since we use only store one instance of any activiy in Redux at a time,
     * if you switch to a different Note & Vote in the same meeting/session it is
     * possible to momentarily have data for the wrong instance of the activity while
     * useFirestoreConnect loads the new data
     */
    routeParams.activityId !== noteAndVote?.id
  ) {
    return null;
  }

  const CurrentView = views[noteAndVote.state.activeView];

  return (
    <AdminActivityTemplate
      activityConfig={CONFIG}
      activityData={noteAndVote}
      session={session}
      CurrentView={CurrentView}
    />
  );
};

export default AdminNoteAndVote;
