import React from 'react';
import { Checkbox, FormControlLabel, makeStyles, Box } from '@material-ui/core';
import { useField, useFormikContext } from 'formik';
import cx from 'classnames';

const useStyles = makeStyles({
  settingsCheckbox: {
    position: 'relative',
    top: -5,
    marginBottom: 20,
  },
});

const CustomCheckbox = ({
  name,
  required,
  label,
  disabled,
  formFieldDisabled,
  isSettingField,
  // defaultValue,
  parentFieldValue,
  ...rest
}) => {
  const [field, meta] = useField({ name, type: 'checkbox' });
  const classes = useStyles();
  const { error, touched } = meta;
  const { submitCount } = useFormikContext();
  const hasError = (error && touched) || (submitCount && error) ? true : false;

  // console.log('error: ', error);
  // console.log('hasError: ', hasError);

  return (
    <>
      <FormControlLabel
        className={cx({
          [`${classes.settingsCheckbox}`]: isSettingField,
          'MuiFormLabel-root': !hasError,
          'text-danger': hasError,
        })}
        control={
          <Checkbox
            {...field}
            checked={field.value !== undefined ? field.value : false}
            color="primary"
            {...rest}
          />
        }
        label={label}
        disabled={disabled || eval(formFieldDisabled)}
      />
      {hasError ? (
        <Box component="span" color="error.main">
          {error}
        </Box>
      ) : null}
    </>
  );
};

export default CustomCheckbox;
