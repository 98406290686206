import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';
import { map, sortBy, cloneDeep, chain, findIndex } from 'lodash';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
// library components
import { Box, FormLabel } from '@material-ui/core';
// cr components
import AddButton from './AddButton';
import FormDialog from 'components/Dialogs/FormDialog';
import Question from './Question';
import QuestionForm from './QuestionForm';
// utils
import { generateRandomId } from '../../../utils/generateRandomId';

const useStyles = makeStyles(theme => ({
  disabledFieldWrapper: {
    position: 'relative',
    '&::before': {
      position: 'absolute',
      display: 'block',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      content: '""',
      backgroundColor: 'white',
      opacity: '0.3',
      'z-index': '2',
    },
  },
  root: {
    minHeight: 40,
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    borderRadius: 4,
    padding: theme.spacing(1),
    backgroundColor: '#EEEEEE',
    display: 'inline-flex',
    alignItems: 'flex-start',
    alignSelf: 'flex-start',
  },
}));

const VariableQuestions = ({ name, label, disabled, canAddNew }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [field, meta, helpers] = useField(name);
  const { submitCount } = useFormikContext();
  const sortedValues = sortBy(field.value, ['order']);
  const sortedQuestionIds = sortedValues.map(v => v.id);
  const { error, touched } = meta;
  const hasError = error && (touched || submitCount);
  const classes = useStyles();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const toggleDialog = () => setIsDialogOpen(!isDialogOpen);

  const renderQuestions = () => {
    if (!sortedValues.length) return;

    return (
      <SortableContext items={sortedQuestionIds} strategy={verticalListSortingStrategy}>
        {map(sortedValues, question => (
          <Question
            key={`question-${question.id}`}
            question={question}
            onDeleteQuestion={handleDeleteQuestion}
            onEditQuestion={handleEditQuestion}
          />
        ))}
      </SortableContext>
    );
  };

  /**
   * Add new field.
   */
  const handleAddQuestion = fieldValue => {
    const theValues = cloneDeep(field.value);
    const fieldId = generateRandomId();
    const lastOrder = sortedValues.length ? sortedValues[sortedValues.length - 1].order : 0;

    theValues[fieldId] = {
      data: fieldValue,
      id: fieldId,
      order: lastOrder + 1,
    };
    helpers.setValue(theValues);
  };

  /**
   * Edit field value.
   */
  const handleEditQuestion = (questionId, values) => {
    const theValues = cloneDeep(field.value);

    theValues[questionId] = {
      ...theValues[questionId],
      data: values,
    };

    helpers.setValue(theValues);
  };

  /**
   * Delete field.
   */
  const handleDeleteQuestion = questionId => {
    const theValues = cloneDeep(field.value);
    delete theValues[questionId];

    helpers.setValue(
      chain(theValues)
        .values()
        .sortBy(['order'])
        .map((q, idx) => ({ ...q, order: idx + 1 }))
        .keyBy('id')
        .value(),
    );
  };

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = findIndex(sortedValues, { id: active.id });
      const newIndex = findIndex(sortedValues, { id: over.id });
      const newArray = arrayMove(sortedValues, oldIndex, newIndex);

      // reformat to object
      const valuesObj = chain(newArray)
        .map((q, idx) => ({ ...q, order: idx + 1 }))
        .keyBy('id')
        .value();

      helpers.setValue(valuesObj);
    }
  }

  return (
    <>
      <Box
        {...field}
        className={cx({
          [`${classes.disabledFieldWrapper}`]: disabled,
        })}
      >
        <FormLabel component="legend" style={{ marginBottom: 10 }}>
          {label}
        </FormLabel>
        <Box
          className={cx({
            [classes.disabledFieldWrapper]: disabled,
          })}
        >
          <Box display="flex" flexWrap="wrap">
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
            >
              {renderQuestions()}
            </DndContext>

            {!disabled && canAddNew && <AddButton handleClick={toggleDialog} />}
          </Box>
        </Box>
      </Box>
      {hasError ? (
        <Box component="span" color="error.main" className="d-block" style={{ marginTop: '-16px' }}>
          {error}
        </Box>
      ) : null}
      <FormDialog handleClose={toggleDialog} isOpen={isDialogOpen} title="Add Question">
        <QuestionForm isNew closeDialog={toggleDialog} onAddQuestion={handleAddQuestion} />
      </FormDialog>
    </>
  );
};

VariableQuestions.defaultProps = {
  canAddNew: true,
};

VariableQuestions.propTypes = {
  // The name of config field.
  name: PropTypes.string.isRequired,
  // The title of field.
  label: PropTypes.string.isRequired,
  // is the field disabled
  disabled: PropTypes.bool,
  // can new items be added
  canAddNew: PropTypes.bool,
};

export default VariableQuestions;
