export const TIME_ZONES = [
  'Etc/GMT+12',
  'Pacific/Midway',
  'Pacific/Honolulu',
  'US/Alaska',
  'America/Los_Angeles',
  'America/Tijuana',
  'US/Arizona',
  'America/Chihuahua',
  'US/Mountain',
  'America/Managua',
  'US/Central',
  'America/Mexico_City',
  'Canada/Saskatchewan',
  'America/Bogota',
  'US/Eastern',
  'US/East-Indiana',
  'Canada/Atlantic',
  'America/Caracas',
  'America/Manaus',
  'America/Santiago',
  'Canada/Newfoundland',
  'America/Sao_Paulo',
  'America/Argentina/Buenos_Aires',
  'America/Godthab',
  'America/Montevideo',
  'America/Noronha',
  'Atlantic/Cape_Verde',
  'Atlantic/Azores',
  'Africa/Casablanca',
  'Etc/Greenwich',
  'Europe/Amsterdam',
  'Europe/Belgrade',
  'Europe/Brussels',
  'Europe/Sarajevo',
  'Africa/Lagos',
  'Asia/Amman',
  'Europe/Athens',
  'Asia/Beirut',
  'Africa/Cairo',
  'Africa/Harare',
  'Europe/Helsinki',
  'Asia/Jerusalem',
  'Europe/Minsk',
  'Africa/Windhoek',
  'Asia/Kuwait',
  'Europe/Moscow',
  'Africa/Nairobi',
  'Asia/Tbilisi',
  'Asia/Tehran',
  'Asia/Muscat',
  'Asia/Baku',
  'Asia/Yerevan',
  'Asia/Kabul',
  'Asia/Yekaterinburg',
  'Asia/Karachi',
  'Asia/Calcutta',
  'Asia/Calcutta',
  'Asia/Katmandu',
  'Asia/Almaty',
  'Asia/Dhaka',
  'Asia/Rangoon',
  'Asia/Bangkok',
  'Asia/Krasnoyarsk',
  'Asia/Hong_Kong',
  'Asia/Kuala_Lumpur',
  'Asia/Irkutsk',
  'Australia/Perth',
  'Asia/Taipei',
  'Asia/Tokyo',
  'Asia/Seoul',
  'Asia/Yakutsk',
  'Australia/Adelaide',
  'Australia/Darwin',
  'Australia/Brisbane',
  'Australia/Canberra',
  'Australia/Hobart',
  'Pacific/Guam',
  'Asia/Vladivostok',
  'Asia/Magadan',
  'Pacific/Auckland',
  'Pacific/Fiji',
  'Pacific/Tongatapu',
];
