import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
// library components
import { Formik, Form } from 'formik';
import { Box, Button } from '@material-ui/core';
// cr components
import VariableQuestionsParticipantAutoField from 'components/CustomFormComponents/VariableQuestions/QuestionTypes/Participant/VariableQuestionsParticipantAutoField';
// actions
import { updateParticipantAnswer } from 'redux/actions/assessmentActions';
// selectors
import {
  useAssessmentSelector,
  getParticipantAnswers,
  getSortedQuestions,
} from 'redux/selectors/assessmentSelectors';
// constants
import { VARIABLE_QUESTIONS_PARTICIPANT_FIELDS_SCHEMA_MAP } from 'components/CustomFormComponents/VariableQuestions/QuestionTypes/Participant/constants';

const AssessmentParticipantForm = ({ questionIdx }) => {
  const dispatch = useDispatch();
  const participantId = useSelector(state => state.firebase.auth.uid);
  const { id } = useAssessmentSelector();
  const participantAnswers = useSelector(getParticipantAnswers(participantId));
  const questions = useSelector(getSortedQuestions());

  // don't render if questionIdx isn't set
  if (questionIdx === null) return null;

  const currentQuestion = questions[questionIdx];

  const getInitialValues = () => {
    const theInitialValues = {};

    if (!questions.length) return theInitialValues;

    theInitialValues[currentQuestion.id] = participantAnswers[currentQuestion.id] || '';

    return theInitialValues;
  };

  const getSchema = () =>
    Yup.object().shape({
      [`${currentQuestion.id}`]:
        VARIABLE_QUESTIONS_PARTICIPANT_FIELDS_SCHEMA_MAP[currentQuestion.data.questionType],
    });

  return (
    <Formik
      initialValues={getInitialValues()}
      validationSchema={getSchema()}
      enableReinitialize
      key={currentQuestion.id}
      onSubmit={async (values, { resetForm, setSubmitting }) => {
        dispatch(
          updateParticipantAnswer(
            id,
            participantId,
            currentQuestion.id,
            questionIdx,
            values[currentQuestion.id],
          ),
        );
      }}
    >
      {({ submitForm }) => (
        <Form className="w-100" style={{ maxWidth: 600 }}>
          <Box mb={4}>
            <VariableQuestionsParticipantAutoField
              questionType={currentQuestion.data.questionType}
              name={currentQuestion.id}
              choices={currentQuestion.data.choices || []}
              required
            />
          </Box>
          <Box display="flex" justifyContent="center">
            <Button variant="contained" color="primary" onClick={submitForm}>
              Done
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

AssessmentParticipantForm.defaultProps = {
  questionIdx: null,
};

AssessmentParticipantForm.propTypes = {
  questionIdx: PropTypes.number.isRequired,
};

export default AssessmentParticipantForm;
