import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { join, map, filter, find, split, sortBy } from 'lodash';
// library components
import { Formik, Form } from 'formik';
import { Box, Typography, makeStyles } from '@material-ui/core';
// cr components
import SelectDeck from './SelectDeck';
import TextArea from 'components/CustomFormComponents/TextArea';
// actions
import { updateRecapDeckNotes } from 'redux/actions/sessionActions';

const useStyles = makeStyles({
  label: {
    fontSize: 12,
    fontWeight: 300,
    lineHeight: '30px',
    color: '#5E5E5E',
  },
  textArea: {
    paddingLeft: 12,
    fontSize: 14,
    lineHeight: '24px',
    color: '#5C5C5C',
  },
});

/**
 * Renders notes for recap deck tab
 */
const NotesContent = ({ sessionId, initialValues, notes, testers }) => {
  const { activities } = useSelector(state => state.firestore.data.session);
  const dispath = useDispatch();
  const classes = useStyles();
  const sessionHasScorecard = find(activities, { activity: 'scorecard' });

  // get insights and quptes notes
  const insightsNotes = [];
  const quotesNotes = filter(notes, note => {
    const isQuotes = find(
      note.participantsActions,
      participants => participants.action === 'quote',
    );
    if (isQuotes) {
      return note;
    } else {
      insightsNotes.push(note);
    }
  });

  // render options for insights
  const insightsOptions = sortBy(
    map(insightsNotes, insight => {
      const testerName = find(testers, tester => tester.id === insight.testerId).value;
      return {
        name: testerName ? `"${insight.note}" - ${testerName}` : insight.note,
        id: insight.noteId,
      };
    }),
    'id',
  );

  // render options for quotes
  const quotesOptions = sortBy(
    map(quotesNotes, quote => {
      const testerName = find(testers, tester => tester.id === quote.testerId).value;
      return {
        name: testerName ? `"${quote.note}" - ${testerName}` : quote.note,
        id: quote.noteId,
      };
    }),
    'id',
  );

  // save insights, quotes, next steps on Blur Form
  const handleUpdateForm = event => {
    const fieldName = event.target.name;
    const value = event.target.value;
    if (fieldName) {
      const newValues = split(value, '\n');
      dispath(updateRecapDeckNotes(sessionId, fieldName, newValues, false));
    }
  };

  // on change insights select
  const handleChangeInsights = event => {
    const insightId = event.target.value;
    const insight = find(insightsOptions, insight => insight.id === insightId);
    if (insight?.name) {
      const initialValue = initialValues?.['userInsights'] || [];
      dispath(
        updateRecapDeckNotes(sessionId, 'userInsights', [...initialValue, insight.name], false),
      );
    }
  };

  // on change quotes select
  const handleChangeQuotes = event => {
    const quoteId = event.target.value;
    const quote = find(quotesOptions, quote => quote.id === quoteId);
    if (quote?.name) {
      const initialValue = initialValues?.['testerQuotes'] || [];
      dispath(
        updateRecapDeckNotes(sessionId, 'testerQuotes', [...initialValue, quote.name], false),
      );
    }
  };

  return (
    <Formik
      initialValues={{
        testerQuotes: join(initialValues?.testerQuotes, '\n') || '',
        userInsights: join(initialValues?.userInsights, '\n') || '',
        nextSteps: join(initialValues?.nextSteps, '\n') || '',
      }}
      enableReinitialize
    >
      {() => (
        <Form onBlur={handleUpdateForm}>
          <Box className="w-100">
            <Typography className={classes.label}>Add Quotes (one per line)</Typography>
            {sessionHasScorecard && (
              <SelectDeck
                title=""
                placeholder="Select quote to add it"
                options={quotesOptions}
                handleChange={handleChangeQuotes}
              />
            )}
            <TextArea
              name="testerQuotes"
              placeholder="Add Quotes"
              multiline
              fullWidth
              rows={4}
              variant="filled"
              additionalStyles={{
                input: classes.textArea,
              }}
            />
            <Typography className={classes.label}>Add Insights (one per line)</Typography>
            {sessionHasScorecard && (
              <SelectDeck
                title=""
                placeholder="Select insight to add it"
                options={insightsOptions}
                handleChange={handleChangeInsights}
              />
            )}
            <TextArea
              name="userInsights"
              placeholder="Add Insights"
              multiline
              fullWidth
              rows={4}
              variant="filled"
              additionalStyles={{
                input: classes.textArea,
              }}
            />
            <Typography className={classes.label}>Add Next Steps (one per line)</Typography>
            <TextArea
              name="nextSteps"
              placeholder="Add Next Steps"
              multiline
              fullWidth
              rows={4}
              variant="filled"
              additionalStyles={{
                input: classes.textArea,
              }}
            />
          </Box>
        </Form>
      )}
    </Formik>
  );
};

NotesContent.propTypes = {
  sessionId: PropTypes.string.isRequired, // session id,,
  initialValues: PropTypes.shape({
    testerQuotes: PropTypes.arrayOf(PropTypes.string),
    userInsights: PropTypes.arrayOf(PropTypes.string),
    nextSteps: PropTypes.arrayOf(PropTypes.string),
  }),
  notes: PropTypes.shape({
    note: PropTypes.string, // note value
    noteId: PropTypes.string, // note id
  }),
};

export default NotesContent;
