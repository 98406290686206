import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/functions';
import { store } from '../index.js';
import { find, includes } from 'lodash';
import * as Sentry from '@sentry/react';
// actions
import { enqueueSuccessSnackbar, enqueueErrorSnackbar } from 'redux/actions/notifierActions';
// constants
import { ACTIVITIES } from 'constants/activities';

/**
 * Resets an activity to it's default state according to it's config
 * @param {String} activityType the firestore collection for the activity
 * @param {String} activityId
 */
export const resetActivity = (activityType, activityId, showNotifications) => {
  const dispatch = store.dispatch;
  const db = firebase.firestore();
  const functions = firebase.functions();
  const activityCollection = ACTIVITIES[activityType]?.collection
    ? ACTIVITIES[activityType].collection
    : activityType;
  const activityRef = db.doc(`/${activityCollection}/${activityId}`);

  const getDefaultValue = key => {
    return find(ACTIVITIES[activityType].config.fields, { key: key }).defaultValue;
  };

  const resetBrainwriting = () => ({
    activeView: 'write',
    currentRound: 1,
    ideas: {},
    isTransitioning: false,
    participants: [],
    rounds: {
      1: {
        assignments: {},
        finished: false,
      },
    },
  });

  const resetBreakouts = () => ({
    participantRoomAssignments: [],
  });

  const reset2510 = () => ({
    activeView: 'idea',
    currentRound: 1,
    gameFinished: false,
    ideas: [],
    numParticipants: 0,
    voteAssignments: {},
    participants: [],
  });

  const resetLegacyActivity = () => {
    const resetFunctions = {
      brainwriting: resetBrainwriting,
      breakouts: resetBreakouts,
      '25:10': reset2510,
    };

    return resetFunctions[activityType]();
  };

  return db
    .runTransaction(function (transaction) {
      return transaction.get(activityRef).then(function (activityDoc) {
        if (!activityDoc.exists) {
          throw 'Document does not exist!';
        }

        let activityData = activityDoc.data();
        let update;

        if (includes(['25:10', 'breakouts', 'brainwriting'], activityType)) {
          update = resetLegacyActivity();
        } else {
          update = {
            state: getDefaultValue('state'),
            participantData: getDefaultValue('participantData'),
            output: [],
            resetCount: activityData.resetCount ? activityData.resetCount + 1 : 1,
          };
        }

        transaction.update(activityRef, update);
      });
    })
    .then(() => {
      showNotifications && dispatch(enqueueSuccessSnackbar('Activity reset'));

      if (ACTIVITIES[activityType].config?.usesStorage) {
        console.log('clearing activity storage...');

        functions
          .httpsCallable('activities-clearStorage')({ activityType, activityId })
          .catch(err => {
            Sentry.captureException(err);
            console.error('activities-clearStorage error', err);
          });
      }
    })
    .catch(function (error) {
      dispatch(enqueueErrorSnackbar('Error resetting activity'));
      Sentry.captureException(error);
      console.log('Activity reset failed', error);
    });
};
