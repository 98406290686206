import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';

import { Tab, Tabs } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  tab: {
    minWidth: 80,
    fontSize: 12,
    margin: '0 10px',
  },
  root: {
    height: 40,
    minHeight: 'initial',
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      width: '100%',
      backgroundColor: '#093B75',
    },
  },
}));

const TabToggler = ({ labels, activeTab, onChangeTab }) => {
  const classes = useStyles();

  return (
    <>
      <Tabs
        value={activeTab}
        indicatorColor="primary"
        textColor="primary"
        onChange={(event, value) => {
          onChangeTab(value);
        }}
        TabIndicatorProps={{ children: <span /> }}
        classes={{
          root: classes.root,
          indicator: classes.indicator,
        }}
      >
        {map(labels, label => (
          <Tab disableRipple className={classes.tab} label={label} key={label} />
        ))}
      </Tabs>
    </>
  );
};

TabToggler.propTypes = {
  labels: PropTypes.array.isRequired,
  activeTab: PropTypes.number.isRequired,
  onChangeTab: PropTypes.func.isRequired,
  hiddenTabs: PropTypes.object.isRequired,
};

export default TabToggler;
