import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { map, head } from 'lodash';
import { Formik, Form } from 'formik';
// library components
import { Button, Select, InputLabel, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  selectTitle: {
    marginBottom: theme.spacing(1),
  },
  select: {
    width: '100%',
    marginBottom: theme.spacing(5),
  },
}));

const formSchema = Yup.object().shape({
  participant: Yup.string().required('Required'),
});

/**
 * Renders form for selecting Super Vote participant
 */
const SelectSuperVoteParticipantForm = ({
  closeDialog,
  participants,
  participantsData,
  submitSuperVoteParticipantId,
}) => {
  const classes = useStyles();

  const [participantId, setParticipantId] = useState(head(participants));

  // Render participants name for select
  const renderOptions = () =>
    map(participants, (participant, idx) => (
      <option key={`paricipant-${idx}`} value={participant}>
        {participantsData[participant].name}
      </option>
    ));

  // Change participant id for super vote
  const handleChangeParticipantId = event => {
    setParticipantId(event.target.value);
  };

  return (
    <Formik
      initialValues={{
        participant: participantId,
      }}
      validationSchema={formSchema}
      onSubmit={() => {
        submitSuperVoteParticipantId(participantId);
      }}
    >
      {({ isSubmitting, submitForm }) => (
        <Form className="pb-3">
          <InputLabel shrink htmlFor="select-decider" className={classes.selectTitle}>
            Select Decider
          </InputLabel>
          <Select
            native
            value={participantId}
            className={classes.select}
            onChange={handleChangeParticipantId}
          >
            {renderOptions()}
          </Select>
          <div className="form-dialog-buttons">
            {!!closeDialog && (
              <Button
                variant="outlined"
                color="primary"
                disabled={isSubmitting}
                onClick={closeDialog}
              >
                CANCEL
              </Button>
            )}
            <Button variant="contained" color="primary" onClick={submitForm}>
              START
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

SelectSuperVoteParticipantForm.propTypes = {
  closeDialog: PropTypes.func, // close modal form
  participants: PropTypes.arrayOf(PropTypes.string).isRequired, // all participants id
  participantsData: PropTypes.shape({
    name: PropTypes.string, // participant name
  }),
  submitSuperVoteParticipantId: PropTypes.func.isRequired, // submit selected participant id
};

export default SelectSuperVoteParticipantForm;
