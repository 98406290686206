import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
// library components
import { Typography, Divider } from '@material-ui/core';
// CR components
import EditableTextWrapper from 'components/EditableTextWrapper';
import GrayBox from 'components/GrayBox';
// utils
import { renderHtmlString } from 'utils/renderHtmlString';

const useStyles = makeStyles(theme => ({
  box: {
    minHeight: 50,
    padding: '10px 15px',
    marginBottom: theme.spacing(4),
  },
}));

/**
 * Display the activity prompt in a grey box
 */
const ActivityPromptAdmin = ({ prompt, collection, activityId, className, additionalInfo }) => {
  const classes = useStyles();

  return (
    <GrayBox className={cx(classes.box, className)}>
      <EditableTextWrapper
        initialValue={prompt}
        fieldType="textarea"
        fieldName="prompt"
        firestoreCollection={collection}
        documentId={activityId}
      >
        <Typography style={{ fontSize: 18 }} dangerouslySetInnerHTML={renderHtmlString(prompt)} />
        {additionalInfo && (
          <>
            <Divider className="my-1" />
            <Typography
              style={{ fontSize: 18 }}
              dangerouslySetInnerHTML={renderHtmlString(additionalInfo)}
            />
          </>
        )}
      </EditableTextWrapper>
    </GrayBox>
  );
};

ActivityPromptAdmin.defaultProps = {
  className: '',
};

ActivityPromptAdmin.propTypes = {
  prompt: PropTypes.string.isRequired,
  collection: PropTypes.string.isRequired,
  activityId: PropTypes.string.isRequired,
  className: PropTypes.string,
  additionalInfo: PropTypes.string,
};

export default ActivityPromptAdmin;
