import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';

import makeStyles from '@material-ui/core/styles/makeStyles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';

const useStyles = makeStyles({
  gridContainer: {
    height: '100%',
    width: '100%',
    margin: 0,
    marginBottom: 20,
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },
  gridItem: {
    maxWidth: '100%',
    flexBasis: '100%',
    marginBottom: 3,
  },
  card: {
    boxShadow: '0px 8px 18px rgba(0, 0, 0, 0.12)',
  },
  cardContent: {
    padding: '18px !important',
  },
  answerTxt: {
    fontSize: 14,
    overflowX: 'hidden',
    whiteSpace: 'pre-line',
    overflowWrap: 'break-word',
  },
});

const FeedbackAnswersCollapse = ({ title, answers }) => {
  const classes = useStyles();

  const renderAnswers = () => {
    return map(answers, (answer, idx) => {
      if (answer) {
        return (
          <GridItem key={idx} xs={12} sm={12} md={6} lg={6} className={classes.gridItem}>
            <Card variant="outlined" elevation={0} className={classes.card}>
              <CardContent className={classes.cardContent}>
                <Typography className={classes.answerTxt}>{answer}</Typography>
              </CardContent>
            </Card>
          </GridItem>
        );
      }
    });
  };

  return (
    <div>
      <ExpansionPanel elevation={0} variant="elevation">
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          // aria-controls={`panel-${panelNumber}-content`}
          // id="panel1a-header"
          style={{ padding: 0 }}
        >
          <Typography style={{ fontWeight: '300' }}>{title}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{ padding: 0, flexWrap: 'wrap' }}>
          <GridContainer className={classes.gridContainer}>{renderAnswers()}</GridContainer>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
};

FeedbackAnswersCollapse.propTypes = {
  title: PropTypes.string.isRequired,
  answers: PropTypes.array.isRequired,
};

export default FeedbackAnswersCollapse;
