import React, { useState, useEffect } from 'react';
import { useFirebase } from 'react-redux-firebase';
import { useDispatch, useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';
import cx from 'classnames';
import { muralApiClient } from 'utils/muralApiClient';
// cr components
import AdminActivityAuxView from 'components/AdminActivityAuxView';
import Spinner from 'components/Spinner';
// 3rd party components
import { Canvas } from '@muraldevkit/mural-integrations-mural-canvas';
import { Typography } from '@material-ui/core';
// selectors
import { useMuralSelector } from 'redux/selectors/muralSelectors';
// action
import { enqueueErrorSnackbar } from 'redux/actions/notifierActions';

import './mural-admin-view.scss';

const MuralAdminView = () => {
  const dispatch = useDispatch();
  const firebase = useFirebase();
  const mural = useMuralSelector();
  const user = useSelector(state => state.firebase.profile);
  const muralUrl = mural?.config?.muralUrl;
  const workspaceId = mural?.config?.muralWorkspaceId;
  const muralData = mural?.state?.muralData;
  const muralId = muralData?.id;
  const muralState = mural?.state?.muralState;
  const activityId = mural?.id;
  const name = mural?.name;
  const isMuralAuthorized = user.isMuralAuthorized;
  const [canvasReady, setCanvasReady] = useState(false);
  const [muralUrlState, setMuralUrlState] = useState(muralUrl);
  const [muralEvt, setMuralEvt] = useState({
    evt: null,
    message: null,
  });
  const [loadCanvas, setLoadCanvas] = useState(true);

  useEffect(() => {
    if (
      (isMuralAuthorized && activityId && !muralId) ||
      (isMuralAuthorized && activityId && muralId && muralUrl !== muralUrlState) // retrigger if the user changes the mural url
    ) {
      firebase
        .functions()
        .httpsCallable('mural-setMuralActivityData')({ muralUrl, workspaceId, activityData: mural })
        .then(() => {
          setMuralUrlState(muralUrl);
          setLoadCanvas(true);
        })
        .catch(err => {
          Sentry.captureException(err);
          console.error(err);

          let message = err.message;

          if (message.includes('MURAL_NOT_FOUND')) {
            setLoadCanvas(false);
            dispatch(enqueueErrorSnackbar('Mural board not found'));
            return;
          }

          if (message.includes(' - ')) {
            message = message.split(' - ')[1];
          }

          message = `Mural returned the following error: ${message}`;
          dispatch(enqueueErrorSnackbar(message, { autoHideDuration: 10000 }));
        });
    }
  }, [muralUrl, muralId, activityId, isMuralAuthorized]);

  if (!user.isMuralAuthorized) return null;

  if (!muralId) {
    return (
      <div className="d-flex justify-content-center">
        <Spinner />
      </div>
    );
  }

  const handleMessage = evt => {
    console.log(evt);

    if (evt.data?.type === 'mural.mural_unavailable') {
      handleMuralUnavailable(evt);
    }
  };

  const handleMuralUnavailable = evt => {
    const message = 'Mural board not found';
    setMuralEvt({ evt, message });
    dispatch(enqueueErrorSnackbar('Mural board not found'));
  };

  const handleAccessDenied = () => {
    dispatch(enqueueErrorSnackbar('Mural access denied'));
  };

  const handleCanvasReady = () => setCanvasReady(true);

  const handleError = () => {
    dispatch(enqueueErrorSnackbar('Mural canvas error'));
  };

  return (
    <AdminActivityAuxView titleText="Mural" activityName={name}>
      {!muralId ? (
        <div className="d-flex justify-content-center">
          <Spinner />
        </div>
      ) : loadCanvas ? (
        <div>
          {!canvasReady && (
            <div className="d-flex justify-content-center mt-12">
              <div className="d-flex flex-column align-items-center justify-content-center">
                <Typography className="mb-4" style={{ fontSize: 18 }}>
                  Fetching Mural Canvas
                </Typography>
                <Spinner />
              </div>
            </div>
          )}
          <div
            className={cx({
              invisible: !canvasReady,
            })}
          >
            <Canvas
              apiClient={muralApiClient}
              authUrl={`${window.origin}/admin/mural-session-activation`}
              muralId={muralId}
              state={muralState}
              onVisitorAccessDenied={handleAccessDenied}
              onMuralUnavailable={handleMuralUnavailable}
              onGuestAccessDenied={handleAccessDenied}
              onMemberAccessDenied={handleAccessDenied}
              onError={handleError}
              onMessage={handleMessage}
              onReady={handleCanvasReady}
            />
          </div>
        </div>
      ) : (
        <div>
          {muralEvt.message ? (
            <div className="text-center">
              <Typography>Mural returned an error: {muralEvt.message}</Typography>
            </div>
          ) : (
            <div className="text-center">
              <Typography>There was a problem loading the Mural board.</Typography>
            </div>
          )}
        </div>
      )}
    </AdminActivityAuxView>
  );
};

export default MuralAdminView;
