import { isLoaded } from 'react-redux-firebase';
import { createSelector } from 'reselect';
import { orderBy } from 'lodash';

const configSelector = (state, type) => state.firestore.data.configs?.[type];

export const selectConfigSelectOptions = () => {
  return createSelector([configSelector], config => {
    if (!isLoaded(config)) return { selectOptions: [], isLoaded: false };

    return {
      selectOptions: orderBy(config.data.selectOptions, ['name'], ['asc']),
      isLoaded: true,
    };
  });
};
