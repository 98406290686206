import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { delay } from 'lodash';
// library components
import { Button } from '@material-ui/core';
// cr components
import ButtonSpinner from 'components/ButtonSpinner';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: ({ color }) => {
      switch (color) {
        case 'green':
          return theme.palette.success.main;
        case 'primary':
          return theme.palette.primary.main;
        case 'secondary':
          return theme.palette.secondary.main;
        case 'indigo':
          return theme.palette.indigo.main;
        default:
          return theme.palette.primary.main;
      }
    },
    '&:hover': {
      backgroundColor: ({ color }) => {
        switch (color) {
          case 'green':
            return theme.palette.success.dark;
          case 'primary':
            return theme.palette.primary.dark;
          case 'secondary':
            return theme.palette.secondary.dark;
          case 'indigo':
            return theme.palette.indigo.dark;
          default:
            return theme.palette.primary.dark;
        }
      },
    },
    color: 'white',
  },
  disabled: {
    color: 'white !important',
    opacity: '0.4 !important',
    backgroundColor: ({ color }) => {
      switch (color) {
        case 'green':
          return `${theme.palette.success.main} !important`;
        case 'secondary':
          return `${theme.palette.secondary.main} !important`;
        case 'indigo':
          return `${theme.palette.indigo.main} !important`;
        case 'primary':
          return `${theme.palette.primary.main} !important`;
        default:
          return `${theme.palette.primary.main} !important`;
      }
    },
  },
}));

const ActivityActionButton = ({
  className,
  color,
  onClick,
  text,
  disabled,
  showSpinner,
  delayReady,
  delayReadyDuration,
}) => {
  const classes = useStyles({ color });
  const [isReady, setIsReady] = useState(!delayReady);
  const { selectedFacilitatorRoute } = useSelector(state => state.facilitatorSession);
  const selectedParticipantRoute = useSelector(state => state.firestore.data.session.activeRoute);

  useEffect(() => {
    if (delayReady && !isReady) {
      delay(() => {
        setIsReady(true);
      }, delayReadyDuration);
    }
  }, [delayReady, isReady, delayReadyDuration]);

  const isDisabled =
    disabled || (disabled !== false && selectedFacilitatorRoute !== selectedParticipantRoute);

  return (
    <Button
      disabled={!isReady || isDisabled}
      variant="contained"
      onClick={onClick}
      classes={classes}
      className={cx('align-self-center', className)}
    >
      {!showSpinner && text}
      {showSpinner && <ButtonSpinner className="ml-0" />}
    </Button>
  );
};

ActivityActionButton.defaultProps = {
  className: '',
  delayReadyDuration: 1000,
};

ActivityActionButton.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(['primary', 'green', 'indigo', 'secondary']),
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  showSpinner: PropTypes.bool,
  delayReady: PropTypes.bool,
  // the number of miliseconds to delay enabling the button on render
  delayReadyDuration: PropTypes.number,
};

export default ActivityActionButton;
