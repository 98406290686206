import React from 'react';
// cr components
import Results from './Results';
// selectors
import { useHowMightWeSelector } from 'redux/selectors/howMightWeSelectors';

/**
 * HMW view for the 'results' phase on the Participant side
 */
const HowMightWeParticipantResults = () => {
  const {
    state: { activeView },
    name,
  } = useHowMightWeSelector();

  return <Results context={activeView} hmwName={name} />;
};

export default HowMightWeParticipantResults;
