import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
// library components
import { Paper, Box, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
// cr components
import ButtonSpinner from 'components/ButtonSpinner/ButtonSpinner';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 190,
    height: 140,
    padding: 10,
    border: '1px solid #D6D6D6',
    borderRadius: 2,
    background: '#E1E1E1',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    marginBottom: 16,
  },
  buttonSpinnerBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 200,
    paddingRight: 25,
  },
  buttonSpinner: {
    color: theme.palette.primary.main,
  },
  input: {
    display: 'none',
  },
  image: {
    maxWidth: 170,
    maxHeight: 120,
  },
  iconButton: {
    position: 'absolute',
    right: -8,
    top: -8,
    width: 26,
    height: 26,
    background: '#fff',
    color: '#9E9E9E',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    '&:hover': {
      background: '#fff',
    },
  },
  icon: {
    fontSize: 16,
  },
  imageContainer: {
    position: 'relative',
  },
}));

/**
 * Renders add image view
 */
const Image = ({ isLoading, fileUrl, onDeleteImage }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.container} variant="outlined">
      {isLoading || !fileUrl ? (
        <Box className={classes.buttonSpinnerBox}>
          <ButtonSpinner className={classes.buttonSpinner} />
        </Box>
      ) : (
        <Box className={classes.imageContainer}>
          <IconButton className={classes.iconButton} onClick={() => onDeleteImage(fileUrl)}>
            <CloseIcon className={classes.icon} />
          </IconButton>
          <img className={classes.image} src={fileUrl} alt="recap-deck" />
        </Box>
      )}
    </Paper>
  );
};

Image.propTypes = {
  isLoading: PropTypes.bool, // loading new image
  fileUrl: PropTypes.string, // image url
  onDeleteImage: PropTypes.func, // on remove image from firestore and storage
};

export default Image;
