import React from 'react';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
// cr components
import ActivityTemplate from 'components/ActivityTemplate/ActivityTemplate';
import ScorecardParticipantInterview from 'components/Scorecard/Participant/ScorecardParticipantInterview';
import ScorecardParticipantResults from 'components/Scorecard/Participant/ScorecardParticipantResults';
import ParticipantActivityContent from 'components/ParticipantActivityContent';
// selectors
import { useScorecardSelector } from 'redux/selectors/scorecardSelectors';
// constants
import { SCORECARD_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';

//add new view for all action
const views = {
  interview: ScorecardParticipantInterview,
  results: ScorecardParticipantResults,
};

const ScorecardParticipant = ({
  match: {
    params: { activityId },
  },
}) => {
  const scorecard = useScorecardSelector();

  // hooks up a firestore listener to Redux
  useFirestoreConnect(() => [
    {
      collection: 'scorecard',
      doc: `${activityId}`,
      storeAs: 'scorecard',
    },
  ]);

  // ensure all of the data we need is loaded
  if (!isLoaded(scorecard) || activityId !== scorecard?.id) {
    return null;
  }

  // get current view
  const Component = views[scorecard.state.activeView];

  return (
    <ActivityTemplate type={CONFIG.niceName} name={scorecard.name}>
      {scorecard.state.activeView === 'interview' ? (
        <ParticipantActivityContent
          activityData={scorecard}
          viewLabel={CONFIG.participantViewLabels[scorecard.state.activeView]}
          activityConfig={CONFIG}
        >
          <Component />
        </ParticipantActivityContent>
      ) : (
        <Component />
      )}
    </ActivityTemplate>
  );
};

export default ScorecardParticipant;
