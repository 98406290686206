import React from 'react';
import { useFirestore } from 'react-redux-firebase';
import { includes } from 'lodash';
import { useSnackbar } from 'notistack';
import axios from 'axios';

import { Formik, Form } from 'formik';
import { Button } from '@material-ui/core';

import ButtonSpinner from 'components/ButtonSpinner/ButtonSpinner';
import TextArea from 'components/CustomFormComponents/TextArea';
import * as Yup from 'yup';

const formSchema = Yup.object().shape({
  emails: Yup.string().required('Required'),
});

const WhitelistInviteForm = () => {
  const db = useFirestore();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Formik
      initialValues={{
        emails: '',
      }}
      validationSchema={formSchema}
      onSubmit={async (values, { resetForm, setSubmitting }) => {
        const emails = values.emails.split(',');
        const emailValidate = Yup.string().email();
        const invalidEmails = [];
        const firestoreErrors = [];
        const sendToZapier = [];
        const validatedEmails = [];

        emails.forEach(email => {
          const trimmedEmail = email.trim();

          if (emailValidate.isValidSync(trimmedEmail)) {
            validatedEmails.push(trimmedEmail);
          } else {
            invalidEmails.push(trimmedEmail);
          }
        });

        const whitelistQuery = await db.collection('whitelist').get();
        const existingWhitelist = whitelistQuery.docs.map(doc => doc.data().email);

        const sentToFirebase = validatedEmails.map(email => {
          if (includes(existingWhitelist, email)) {
            return Promise.resolve();
          }

          return db
            .collection('whitelist')
            .add({ email })
            .then(() => {
              sendToZapier.push(email);
            })
            .catch(e => {
              firestoreErrors.push(`error adding ${email} to the whitelist`);
              console.log(e);
            });
        });

        Promise.allSettled(sentToFirebase).then(() => {
          if (invalidEmails.length) {
            invalidEmails.forEach(email => {
              enqueueSnackbar(`${email} is invalid`, { variant: 'error' });
            });
          }

          if (firestoreErrors.length) {
            firestoreErrors.forEach(error => {
              enqueueSnackbar(error, { variant: 'error' });
            });
          }

          const zapierEmailList = encodeURIComponent(sendToZapier.join(','));

          axios
            .get(`https://hooks.zapier.com/hooks/catch/2821161/oiwdkfc?emails=${zapierEmailList}`)
            .then(() => {
              setSubmitting(false);
              resetForm();
            })
            .catch(err => {
              setSubmitting(false);
              enqueueSnackbar(`Zapier error: ${err}`, { variant: 'error' });
            });
        });
      }}
    >
      {({ isSubmitting, submitForm, values, resetForm }) => (
        <Form style={{ paddingBottom: 20 }}>
          <TextArea
            name="emails"
            type="text"
            label="Email Addresses"
            className="mb-4"
            placeholder="Past a comma seperated list of email addresses here..."
            required
          />

          <div className="">
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={submitForm}
              className="mr-0 mr-md-2"
            >
              SUBMIT
              {isSubmitting && <ButtonSpinner />}
            </Button>
            <Button variant="outlined" color="primary" disabled={isSubmitting} onClick={resetForm}>
              CLEAR
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default WhitelistInviteForm;
