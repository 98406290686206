import linkifyHtml from 'linkifyjs/html';
import nl2br from './nl2br';
import renderlinkFormat from './renderlinkFormat';

export const renderHtmlString = (string, options = {}) => {
  return { __html: linkifyHtml(nl2br(string), { ...options }) };
};

export const renderHtmlStringSimple = string => {
  return { __html: string };
};

export const linkifyHtmlString = string =>
  linkifyHtml(nl2br(string), {
    format: function (type, value) {
      return renderlinkFormat(
        type,
        value,
        '<svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeInherit" style="width: 14px; position: relative; top: 2px" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation" fill="#0066cc"><path d="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"></path></svg>',
      );
    },
  });

export default renderHtmlString;
