import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { map } from 'lodash';
// library components
import { Box, Divider, Typography } from '@material-ui/core';
// cr components
import ActivityPromptAdmin from 'components/ActivityPromptAdmin';
import DottedProgress from 'components/DottedProgress';
// selectors
import { usePollingSelector } from 'redux/selectors/pollingSelectors';
// config
import { POLLING_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';

/**
 * Polling view for the 'results' phase on the Facilitator side
 */
const PollingAdminVote = () => {
  const theme = useTheme();
  const { id, prompt, output } = usePollingSelector();

  const renderResults = () =>
    map(output, (result, idx) => {
      const last = idx === output.length - 1;

      return (
        <Box key={`result-${idx}`}>
          <Box className="d-flex justify-content-between align-items-center py-1">
            <Typography className="font-weight-light">{result.text}</Typography>
            <DottedProgress
              activeColor={theme.palette.indigo.main}
              totalCount={result.votes}
              activeCount={result.votes}
              className="mb-0"
            />
          </Box>
          {!last && <Divider />}
        </Box>
      );
    });

  return (
    <Box className="d-flex flex-column justify-content-center">
      <ActivityPromptAdmin prompt={prompt} collection={CONFIG.collection} activityId={id} />
      {/* <Box>
        {renderResults()}
      </Box> */}
    </Box>
  );
};

export default PollingAdminVote;
