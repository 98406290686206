import React from 'react';
import PropTypes from 'prop-types';
import { values } from 'lodash';
// cr components
import ShortAnswerQuestionResultsSummary from './ShortAnswerQuestionResultsSummary';
import ShortAnswerQuestionResultsDetail from './ShortAnswerQuestionResultsDetail';
import SingleChoiceQuestionResultsSummary from './SingleChoiceQuestionResultsSummary';
import MultipleChoiceQuestionResultsSummary from './MultipleChoiceQuestionResultsSummary';
import LinearScaleQuestionResultsSummary from './LinearScaleQuestionResultsSummary';
import SentimentQuestionResultsSummary from './SentimentQuestionResultsSummary';
import AnswerDetailTable from './AnswerDetailTable';
// selectors
import { useAssessmentSelector } from 'redux/selectors/assessmentSelectors';

import { VARIABLE_QUESTION_TYPES } from 'components/CustomFormComponents/VariableQuestions/QuestionTypes/constants';

const AssessmentQuestionResults = ({ questionType, questionId }) => {
  const {
    state: { questionResultView },
  } = useAssessmentSelector();

  switch (questionType) {
    case VARIABLE_QUESTION_TYPES.SHORT_ANSWER:
      return (
        <>
          {questionResultView === 'summary' && (
            <ShortAnswerQuestionResultsSummary questionId={questionId} />
          )}
          {questionResultView === 'detail' && (
            <ShortAnswerQuestionResultsDetail questionId={questionId} />
          )}
        </>
      );
    case VARIABLE_QUESTION_TYPES.SINGLE_CHOICE:
      // return <SingleChoiceQuestionResultsSummary questionId={questionId} withCard />;
      return (
        <>
          {questionResultView === 'summary' && (
            <SingleChoiceQuestionResultsSummary questionId={questionId} />
          )}
          {questionResultView === 'detail' && <AnswerDetailTable questionId={questionId} />}
        </>
      );
    case VARIABLE_QUESTION_TYPES.MULTIPLE_CHOICE:
      // return <MultipleChoiceQuestionResultsSummary questionId={questionId} withCard />;

      return (
        <>
          {questionResultView === 'summary' && (
            <MultipleChoiceQuestionResultsSummary questionId={questionId} />
          )}
          {questionResultView === 'detail' && <AnswerDetailTable questionId={questionId} />}
        </>
      );
    case VARIABLE_QUESTION_TYPES.LINEAR_SCALE:
      return (
        <>
          {questionResultView === 'summary' && (
            <LinearScaleQuestionResultsSummary questionId={questionId} />
          )}
          {questionResultView === 'detail' && <AnswerDetailTable questionId={questionId} />}
        </>
      );
    case VARIABLE_QUESTION_TYPES.SENTIMENT:
      return (
        <>
          {questionResultView === 'summary' && (
            <SentimentQuestionResultsSummary questionId={questionId} />
          )}
          {questionResultView === 'detail' && <AnswerDetailTable questionId={questionId} />}
        </>
      );
    default:
      return null;
  }
};

AssessmentQuestionResults.propTypes = {
  questionType: PropTypes.oneOf([...values(VARIABLE_QUESTION_TYPES)]),
  questionId: PropTypes.string.isRequired,
};

export default AssessmentQuestionResults;
