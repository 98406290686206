import React from 'react';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
// cr components
import ActivityTemplate from 'components/ActivityTemplate/ActivityTemplate';
import SurveyParticipantWrite from 'components/Survey/Participant/SurveyParticipantWrite';
// import SurveyParticipantResults from 'components/Survey/Participant/SurveyParticipantResults';
import ParticipantActivityContent from 'components/ParticipantActivityContent';
// selectors
import { useSurveySelector } from 'redux/selectors/surveySelectors';
// constants
import { SURVEY_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';

const views = {
  write: SurveyParticipantWrite,
  results: SurveyParticipantWrite,
};

/**
 * Entry point for Survey on the Participant side.
 * Loads the Firestore object for the activity, ensures
 * the data is loaded before rendering the game, and determines
 * which view to show
 */
const SurveyParticipant = ({
  match: {
    params: { activityId },
  },
}) => {
  const survey = useSurveySelector();

  // hooks up a firestore listener to Redux
  useFirestoreConnect(() => [
    {
      collection: 'survey',
      doc: `${activityId}`,
      storeAs: 'survey',
    },
  ]);

  // ensure all of the data we need is loaded
  if (!isLoaded(survey) || activityId !== survey?.id) {
    return null;
  }

  const Component = views[survey.state.activeView];

  return (
    <ActivityTemplate type="Survey" name={survey.name}>
      <ParticipantActivityContent
        activityData={survey}
        viewLabel={CONFIG.participantViewLabels[survey.state.activeView]}
        activityConfig={CONFIG}
      >
        <Component context={survey.state.activeView} />
      </ParticipantActivityContent>
    </ActivityTemplate>
  );
};

export default SurveyParticipant;
