import React from 'react';
import { useSelector } from 'react-redux';
import { includes, isEmpty, filter, sortBy, size, get } from 'lodash';
// cr components
import HeatMapping from 'components/HeatMapping/Participant/HeatMapping';
import HeatmappingImageUpload from '../Participant/HeatMappingParticipantUpload';
import ImagesBox from 'components/HeatMapping/Participant/ImagesBox';
import AdminActivityAuxView from 'components/AdminActivityAuxView';
import CSVReportDownloadButton from 'components/CSVReportDownloadButton';
// selectors
import { useHeatMappingSelector } from 'redux/selectors/heatMappingSelectors';
// utils
import { createHeatMappingReportHeader, createHeatMappingReportData } from 'utils/heatMapping';
import ToggleHeatmapDots from './Components/ToggleHeatmapDots';
import ToggleImageReorder from './Components/ToggleReorderImages';

/**
 * Renders heat-mapping (sketches and dots), straw poll and super vote results views on the Facilitator side
 */
const SketchesView = () => {
  const heatMapping = useHeatMappingSelector();
  const session = useSelector(state => state.firestore.data.session);
  const isPointsHidden = heatMapping?.state?.isPointsHidden || false;
  const isReordering = heatMapping?.state?.isReordering || false;

  if (
    isEmpty(heatMapping) ||
    !includes(['review', 'strawPollResults', 'results'], heatMapping?.state?.activeView || [])
  ) {
    return null;
  }

  const {
    prompt,
    id,
    participantData: { sketches, images, participants },
    state: { currentSketch, activeView },
    name,
  } = heatMapping;

  const participant = sketches[currentSketch].participantId;
  const noHeatMappingSketches = sortBy(filter(sketches, { completed: false }, ['participantId']));
  const sketchesLength = size(sketches);

  const sketchNumber = `${sketchesLength - noHeatMappingSketches.length + 1}`;

  // render headers for csv report
  const headers = createHeatMappingReportHeader();

  // render data for csv report
  const csvReport = createHeatMappingReportData(sketches, images);

  const renderTitle = () => {
    switch (activeView) {
      case 'review':
        return `${prompt}: Sketch #${sketchNumber}`;
      case 'strawPollResults':
        return `${prompt}: Straw Poll Results`;
      case 'results':
        return `${prompt}: Voting Results`;
      default:
        return '';
    }
  };

  return (
    <AdminActivityAuxView
      titleText={renderTitle()}
      allowPrint={activeView === 'results'}
      activityName={name}
      hasDownloadButton={activeView === 'results'}
      hasTabs
      renderActionsLeft={() => activeView === 'review' && <ToggleImageReorder id={id} />}
      renderActionsRight={() => <ToggleHeatmapDots id={id} />}
      renderDownloadButton={() => (
        <CSVReportDownloadButton
          headers={headers}
          csvReport={csvReport}
          buttonText="Download Sketches"
          filename="Sketches.csv"
          topic={session.topic}
          activity="HeatMapping"
          activityName={name}
          withoutLabel={true}
        />
      )}
    >
      {activeView === 'review' && !isReordering && (
        <HeatMapping
          sketches={sketches}
          currentSketch={currentSketch}
          id={id}
          images={images}
          context={`${activeView}Admin`}
          participants={participants}
          disable={true}
          isPointsHidden={isPointsHidden}
        />
      )}
      {activeView === 'review' && isReordering && (
        <HeatmappingImageUpload isAdminReordering={isReordering} participant={participant} />
      )}
      {activeView === 'strawPollResults' && <ImagesBox context={`${activeView}Admin`} />}
      {activeView === 'results' && (
        <ImagesBox context={`${activeView}Admin`} superVoteDisabled={true} />
      )}
    </AdminActivityAuxView>
  );
};

export default SketchesView;
