import React from 'react';
import { useField, useFormikContext } from 'formik';
import { map, chain } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
// library components
import { Box, Typography } from '@material-ui/core';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
// css
import './linear-scale.scss';

const useButtonGroupStyles = makeStyles({
  root: {
    display: 'inline-flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignSelf: 'center',
  },
});

const LinearScale = ({ name, choices }) => {
  const [field, meta, helpers] = useField(name);
  const buttonGroupStyles = useButtonGroupStyles();
  const { submitCount } = useFormikContext();
  const { error, touched } = meta;
  const hasError = (error && touched) || (submitCount && error) ? true : false;

  const handleChange = (event, value) => {
    helpers.setValue(value);
  };

  const theChoices = chain(choices)
    .map((choice, key) => ({ value: key, label: choice }))
    .sortBy('value')
    .value();

  return (
    <Box display="flex">
      <Box className="linear-scale-field">
        <Typography className="fw-300 mb-1">Select your answer</Typography>
        <ToggleButtonGroup
          value={meta.value}
          exclusive
          classes={buttonGroupStyles}
          onChange={handleChange}
          aria-label="vote"
        >
          {map(theChoices, (item, idx) => (
            <Option key={`${idx}_${item}`} value={item.value} label={item.label} />
          ))}
        </ToggleButtonGroup>
        <Box display="flex" justifyContent="center">
          <svg
            className="arrow-svg"
            height="16"
            viewBox="0 0 582 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.292893 7.29289C-0.0976311 7.68342 -0.097631 8.31658 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538407 7.04738 0.538407 6.65685 0.928932L0.292893 7.29289ZM581.707 8.70706C582.098 8.31653 582.098 7.68337 581.707 7.29284L575.343 0.928882C574.953 0.538358 574.319 0.538358 573.929 0.928882C573.538 1.31941 573.538 1.95257 573.929 2.3431L579.586 7.99995L573.929 13.6568C573.538 14.0473 573.538 14.6805 573.929 15.071C574.319 15.4615 574.953 15.4615 575.343 15.071L581.707 8.70706ZM1 9L581 8.99995L581 6.99995L1 7L1 9Z"
              fill="#D2D2D2"
            />
          </svg>
        </Box>
        {hasError ? (
          <Box component="span" color="error.main">
            {error}
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

const useOptionStyle = makeStyles(theme => ({
  root: {
    backgroundColor: 'white',
    color: 'black',
  },
  selected: {
    backgroundColor: `${theme.palette.secondary.lightest} !important`,
    color: 'black !important',
  },
  label: {
    flexDirection: 'column',
  },
}));

const Option = props => {
  const classes = useOptionStyle();

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <ToggleButton
        {...props}
        classes={classes}
        value={props.value}
        className={cx('score-option', {
          'score-active': props.selected,
        })}
        disableRipple
      >
        <Typography className="option-vote">{props.value}</Typography>
      </ToggleButton>
      <Typography className="option-text">{props.label}</Typography>
    </Box>
  );
};

export default LinearScale;
