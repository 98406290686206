import React from 'react';
import PropTypes from 'prop-types';
import { sortBy } from 'lodash';
// library components
import { Box, makeStyles } from '@material-ui/core';
// cr components
import OptionCloud from 'components/OptionCloud';
// selectors
import { usePollingSelector } from 'redux/selectors/pollingSelectors';

const useStyles = makeStyles({
  optionContainer: {
    width: '100%',
  },
});

/**
 * Render an OptionCloud for the polling options in relation
 * to the current view/context
 */
const PollingOptions = () => {
  const classes = useStyles();
  const {
    config: { notes },
  } = usePollingSelector();

  const sortedNotes = sortBy(notes, ['order', 'value']);

  /**
   * leaving the following functions here and commented out as an example of
   * how to use the OptionCloud component if it's not read-only
   */

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mt={2}>
      <OptionCloud
        readonly
        options={sortedNotes}
        allowNew={false}
        className={classes.optionContainer}
      />
    </Box>
  );
};

PollingOptions.propTypes = {
  context: PropTypes.oneOf(['vote']),
};

export default PollingOptions;
