import React, { Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import CreateRoundedIcon from '@material-ui/icons/CreateRounded';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import EditCardSetsDialog from 'components/Dialogs/EditCardSets';

const useStyles = makeStyles(theme => {
  return {
    root: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  };
});

const EditCardSetsButton = ({ handleConfirm, disabled }) => {
  const classes = useStyles();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const toggleDialog = () => {
    setIsDialogOpen(isOpen => !isOpen);
  };

  return (
    <Fragment>
      <div>
        <Button
          classes={classes}
          color="primary"
          style={{ textTransform: 'none' }}
          size="small"
          onClick={() => {
            toggleDialog();
          }}
          disabled={disabled}
        >
          <div className="d-flex align-items-center">
            <CreateRoundedIcon className="mr-1" style={{ fontSize: 22 }} />
            <Box
              component="span"
              color={disabled ? 'textSecondary' : 'primary.main'}
              style={{ fontSize: 14, fontWeight: 400, position: 'relative', top: 1, left: '-3px' }}
            >
              Edit Card Sets
            </Box>
          </div>
        </Button>
      </div>
      <EditCardSetsDialog
        handleClose={toggleDialog}
        isOpen={isDialogOpen}
        title="Edit Card Sets"
        handleConfirm={handleConfirm}
      />
    </Fragment>
  );
};

export default EditCardSetsButton;
