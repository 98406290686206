import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';

const ButtonSpinner = ({ size, className }) => {
  const spinnerSize = {
    default: 24,
    small: 14,
  };

  const styles = {
    default: {
      marginLeft: 20,
    },
    small: {
      marginLeft: 8,
    },
  };

  return (
    <CircularProgress
      color="inherit"
      size={spinnerSize[size]}
      style={styles[size]}
      className={className}
    />
  );
};

ButtonSpinner.defaultProps = {
  size: 'default',
  className: '',
};

ButtonSpinner.propTypes = {
  size: PropTypes.string,
  className: PropTypes.string,
};

export default ButtonSpinner;
