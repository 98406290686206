import React from 'react';
import { includes, map, sortBy, isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
// library components
import { Box, Typography, makeStyles } from '@material-ui/core';
// cr components
import Flow from 'components/NoteAndMap/Participant/Flow';
import AdminActivityAuxView from 'components/AdminActivityAuxView';
import VoteResultsFlows from 'components/NoteAndMap/Participant/VoteResultsFlows';
import CSVReportDownloadButton from 'components/CSVReportDownloadButton';
// selectors
import {
  useNoteAndMapSelector,
  getParticipantsFlows,
  getSuperVotes,
  getAllTypes,
} from 'redux/selectors/noteAndMapSelectors';
// utils
import { createNoteAndMapReportHeader, createNoteAndMapReportData } from 'utils/noteAndMap';

const useStyles = makeStyles({
  additionalText: {
    paddingBottom: 30,
    fontSize: 14,
    color: '#a8a8a8',
    textAlign: 'center',
  },
});

/**
 *  Renders Note & Maps for 'review' phases on the Facilitator side
 */
const NoteAndMapAdminViews = () => {
  const classes = useStyles();
  const noteAndMap = useNoteAndMapSelector();
  const participantsFlows = useSelector(getParticipantsFlows());
  const allTypes = useSelector(getAllTypes());
  const superVoteTypes = useSelector(getSuperVotes());

  const session = useSelector(state => state.firestore.data.session);

  if (
    isEmpty(noteAndMap) ||
    !noteAndMap?.state?.activeView ||
    !includes(['review', 'voteResults', 'results'], noteAndMap.state.activeView)
  ) {
    return null;
  }

  const {
    state: { activeView },
    name,
  } = noteAndMap;

  const sortedFlows = sortBy(participantsFlows, 'participantId');

  // render headers for csv report
  const headers = createNoteAndMapReportHeader();

  // render data for csv report
  const csvReport = createNoteAndMapReportData(allTypes);

  const renderFlows = isPrint =>
    map(sortedFlows, (flow, idx) => (
      <Flow
        key={`participant-flow-${idx}`}
        participantFlow={flow?.types}
        disabled={true}
        context={activeView}
        superVoteTypes={superVoteTypes}
        createdFlowParticipantId={flow.participantId}
        isPrint={isPrint}
      />
    ));

  const renderTitle = activeView => {
    switch (activeView) {
      case 'review':
        return `Sharing Results`;
      case 'voteResults':
        return `Reviewing Results`;
      case 'results':
        return `Final Results`;
      default:
        return '';
    }
  };

  return (
    <AdminActivityAuxView
      titleText={renderTitle(activeView)}
      activityName={name}
      printTitleLine2={activeView === 'results' ? 'Voting Results' : 'Review Results'}
      allowPrint={activeView === 'results'}
      hasDownloadButton={activeView === 'results'}
      renderDownloadButton={() => (
        <CSVReportDownloadButton
          headers={headers}
          csvReport={csvReport}
          buttonText="Download Map"
          filename="Results.csv"
          topic={session.topic}
          activity="NoteAndMap"
          activityName={name}
          withoutLabel={true}
        />
      )}
    >
      <Box displayPrint="none">
        {activeView === 'voteResults' && (
          <Typography className={classes.additionalText}>
            Click a moment to remove, click - to add back.
          </Typography>
        )}
        {includes(['review', 'results'], activeView) && renderFlows()}
        {activeView === 'voteResults' && (
          <VoteResultsFlows context={activeView === 'voteResults' ? 'adminReview' : ''} />
        )}
      </Box>
      <Box displayPrint="flex" display="none" flexDirection="column" flexWrap="wrap">
        {activeView === 'results' && renderFlows(true)}
      </Box>
    </AdminActivityAuxView>
  );
};

export default NoteAndMapAdminViews;
