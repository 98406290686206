import React from 'react';
import PropTypes from 'prop-types';
import TextInput from './TextInput';
import TextArea from './TextArea';
import RadioButtonGroup from './RadioButtonGroup';
import CustomSelect from './CustomSelect';
import TextOptionsCluster from './TextOptionsCluster';
import CustomCheckbox from './CustomCheckbox';
import PollingOptions from './PollingOptions';
import CardSetSelect from './CardSetSelect';
import UploadImage from './UploadImage';
import MuralWorkspaceSelect from './MuralWorkspaceSelect';
import VariableQuestions from './VariableQuestions';

const AutoField = ({ field, disabled, ...rest }) => {
  switch (field.type) {
    case 'text':
    case 'number':
      return <TextInput {...field} disabled={disabled} {...rest} />;
    case 'textarea':
      return <TextArea {...field} disabled={disabled} {...rest} />;
    case 'radioButtonGroup':
      return <RadioButtonGroup {...field} disabled={disabled} {...rest} />;
    case 'select':
      return <CustomSelect {...field} disabled={disabled} {...rest} />;
    case 'checkbox':
      return <CustomCheckbox {...field} disabled={disabled} {...rest} />;
    case 'textOptionsCluster':
      return <TextOptionsCluster {...field} disabled={disabled} {...rest} />;
    case 'poolingOptions':
      return <PollingOptions {...field} disabled={disabled} {...rest} />;
    case 'cardSet':
      return <CardSetSelect field={field} disabled={disabled} {...rest} />;
    case 'uploadImage':
      return <UploadImage {...field} disabled={disabled} {...rest} />;
    case 'muralWorkspace':
      return <MuralWorkspaceSelect field={field} disabled={disabled} {...rest} />;
    case 'variableQuestions':
      return <VariableQuestions {...field} disabled={disabled} {...rest} />;
    default:
      return <div />;
  }
};

AutoField.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    required: PropTypes.bool,
    isFieldDisabled: PropTypes.bool,
  }).isRequired,
  disabled: PropTypes.bool,
};

export default AutoField;
