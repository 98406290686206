import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { useField, useFormikContext } from 'formik';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@voltage-control/control-room-ck-editor';
import cx from 'classnames';

import { InputLabel, Box } from '@material-ui/core';

import CustomUploadAdatperPlugin from '../../utils/CkEditorUploadAdapter';

import './textEditor.scss';

const useLabelStyles = makeStyles({
  root: {
    marginBottom: 10,
    color: 'black',
  },
});

const TextEditor = ({ name, required, label, className, sessionId, toolbar, disabled }) => {
  const [field, meta, helpers] = useField(name);
  const { error, touched } = meta;
  const { submitCount } = useFormikContext();

  const hasError = (error && touched) || (submitCount && error) ? true : false;

  return (
    <div className={cx('text-area', className)}>
      <InputLabel
        variant="standard"
        disableAnimation
        shrink={false}
        classes={useLabelStyles()}
        error={hasError}
        required={required}
        htmlFor={name}
      >
        {label}
      </InputLabel>
      <CKEditor
        id={name}
        editor={ClassicEditor}
        data={field.value}
        disabled={disabled}
        config={{
          sessionId,
          extraPlugins: [CustomUploadAdatperPlugin],
          toolbar,
          image: {
            styles: ['alignLeft', 'full', 'alignRight'],
            toolbar: [
              'imageTextAlternative',
              'imageStyle:alignLeft',
              'imageStyle:full',
              'imageStyle:alignRight',
            ],
          },
          mediaEmbed: {
            previewsInData: true,
            extraProviders: [
              {
                name: 'Loom',
                url: /^loom\.com\/share\/(\w+)/,
                html: match =>
                  `<div style="position: relative; padding-bottom: 56.25%; height: 0;">
                    <iframe src="https://www.loom.com/embed/${match[1]}" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe>
                  </div>`,
              },
            ],
          },
          link: { addTargetToExternalLinks: true },
          // more info on config: https://ckeditor.com/docs/ckeditor5/latest/api/module_core_editor_editorconfig-EditorConfig.html
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          helpers.setValue(data);
        }}
        onFocus={(event, editor) => {
          editor.model.change(writer => {
            writer.setSelection(writer.createPositionAt(editor.model.document.getRoot(), 'end'));
          });
        }}
      />
      {hasError ? (
        <Box component="span" color="error.main">
          {error}
        </Box>
      ) : null}
    </div>
  );
};

TextEditor.defaultProps = {
  toolbar: [
    'heading',
    '|',
    'alignment',
    'fontColor',
    'fontBackgroundColor',
    'fontSize',
    'link',
    'imageUpload',
    'mediaEmbed',
    'bulletedList',
    'numberedList',
    'bold',
    'underline',
    'italic',
    '|',
    'code',
    '|',
    'horizontalLine',
    'undo',
    'redo',
  ],
};

TextEditor.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  label: PropTypes.string,
  className: PropTypes.string,
  // Used for uploading images to firebase storage
  sessionId: PropTypes.string,
  toolbar: PropTypes.array,
  disabled: PropTypes.bool,
};

export default TextEditor;
