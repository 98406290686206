import React, { useRef, useState } from 'react';
import cx from 'classnames';
import MomentUtils from '@date-io/moment';
import moment from 'moment';

import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/styles';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { IconButton, Button, Typography } from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';

import ButtonSpinner from 'components/ButtonSpinner';

import { useStaffingSelector } from 'redux/selectors/staffing/staffingSelectors';
import { addChecklistToProject } from 'redux/actions/staffingActions';
import { useDispatch, useSelector } from 'react-redux';
import { selectProject } from 'redux/selectors/staffing/getProject';

import { filter, map, size, orderBy } from 'lodash';
import { generateRandomId } from 'utils/generateRandomId';
import { linkifyHtmlString } from 'utils/renderHtmlString';

const useStyles = makeStyles(theme => ({
  main: {},
  checklistItem: {
    // height: 40,
    paddingLeft: 10,
    paddingRight: 0,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#EEEEEE',
    marginBottom: 10,
  },
  checklistItemRed: {
    backgroundColor: '#F4E3E1',
  },
  inputContainer: {
    flexGrow: 1,
    display: 'flex',
  },
  input: {
    fontSize: 15,
    fontWeight: '400',
    height: '100%',
    flexGrow: 1,
    outline: 'none',
    border: 'none',
    background: 'transparent',
  },

  checklistTitleContainer: {
    display: 'flex',
    flexGrow: 1,
  },
  checklistTitle: {
    height: '100%',
    fontSize: 15,
    fontWeight: '400',
    marginBottom: 0,
    padding: `${theme.spacing(1)}px 0`,
  },
  checklistTitleDone: {
    color: '#898989',
    textDecoration: 'line-through',
  },

  statusContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  statusText: {
    fontSize: 14,
    fontWeight: '400',
    marginBottom: 0,
    marginRight: 10,
  },
  statusTextGreen: {
    color: '#02A05C',
  },
  statusTextRed: {
    color: '#E2351E',
  },
  statusTextGray: {
    color: '#5E5E5E',
  },
  statusTextLightGray: {
    color: '#898989',
  },

  calendarIconRed: {
    fontSize: 20,
    color: '#E2351E',
  },
  calendarIconGray: {
    fontSize: 20,
    color: '#5E5E5E',
  },

  addNewChecklistBtn: {
    textTransform: 'capitalize',
    width: '100%',
    height: 40,
    background: '#ffffff',
    border: '1px solid #BFBFBF',
    color: '#898989',
    fontWeight: '400',
    fontSize: 15,
    '&:hover': {
      background: '#ffffff',
      opacity: 0.9,
    },
    '&:disabled': {
      background: '#9E9E9E',
      color: '#fff',
    },
  },

  closeButtonContainer: {
    marginTop: 40,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  closeButton: {
    width: 104,
    height: 40,
    borderRadius: 4,
    background: theme.palette.primary.main,
    color: '#fff',
    fontSize: 14,
    '&:hover': {
      background: theme.palette.primary.main,
      opacity: 0.9,
    },
    '&:disabled': {
      background: '#9E9E9E',
      color: '#fff',
    },
  },
}));

const StaffingChecklistForm = props => {
  const { closeDialog } = props;

  const classes = useStyles();

  const dispatch = useDispatch();

  const { staffingChecklistProjectId, staffingChecklistCategory } = useStaffingSelector();
  const getProject = selectProject();
  const project = useSelector(state => getProject(state, staffingChecklistProjectId));

  const checklistItems = orderBy(
    filter(Object.values(project?.checklists || {}), m => m.category === staffingChecklistCategory),
    m => m.order,
  );

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [itemToEditIdx, setItemToEditIdx] = useState(null);

  const handleAddNewChecklistItem = () => {
    const newItem = {
      id: generateRandomId(),
      category: staffingChecklistCategory,
      completed: false,
      dueDate: moment().add(7, 'days').valueOf(), //add '7' days to the current date - STAFFING_TO_DO -> Confirm if this is oky,
      title: '',
      order: size(checklistItems),
    };

    setIsSubmitting(true);
    dispatch(
      addChecklistToProject(staffingChecklistProjectId, newItem.id, newItem, () => {
        setIsSubmitting(false);
      }),
    );
  };

  const handleUpdateChecklistItem = (newItem, idx) => {
    dispatch(addChecklistToProject(staffingChecklistProjectId, newItem.id, newItem, () => {}));
  };

  return (
    <div className={classes.main}>
      {map(checklistItems, (item, idx) => {
        return (
          <ChecklistItem
            key={item.id}
            idx={idx}
            item={item}
            onItemChange={newItem => handleUpdateChecklistItem(newItem, idx)}
            itemToEditIdx={itemToEditIdx}
            setItemToEditIdx={setItemToEditIdx}
          />
        );
      })}
      <Button className={classes.addNewChecklistBtn} onClick={handleAddNewChecklistItem}>
        Add new checklist item
      </Button>

      <div className={classes.closeButtonContainer}>
        <Button className={classes.closeButton} disabled={isSubmitting} onClick={closeDialog}>
          CLOSE
          {isSubmitting && <ButtonSpinner />}
        </Button>
      </div>
    </div>
  );
};

const ChecklistItem = props => {
  const { idx, item, onItemChange, itemToEditIdx, setItemToEditIdx } = props;

  const classes = useStyles();

  const [itemTitle, setItemTitle] = useState(item.title);
  const [isOpen, setIsOpen] = useState(false);

  const inputRef = useRef();

  // Check if Due Date has passed
  const isDueDateGone = () => {
    return moment(new Date()).isAfter(new Date(item.dueDate));
  };

  // Render status
  const renderStatus = () => {
    if (item.completed) {
      return (
        <Typography className={cx(classes.statusText, classes.statusTextGreen)}>Done</Typography>
      );
    }

    if (isDueDateGone()) {
      return (
        <div className={classes.statusContainer}>
          <Typography className={cx(classes.statusText, classes.statusTextRed)}>
            Due {moment(new Date()).diff(new Date(item.dueDate), 'days')} day ago
          </Typography>

          <IconButton onClick={() => setIsOpen(true)}>
            <EventIcon className={classes.calendarIconRed} />
          </IconButton>
        </div>
      );
    }

    return (
      <div className={classes.statusContainer}>
        <Typography className={cx(classes.statusText, classes.statusTextGray)}>
          Due on {moment(item.dueDate).format('MMMM Do')}
        </Typography>

        <IconButton onClick={() => setIsOpen(true)}>
          <EventIcon className={classes.calendarIconGray} />
        </IconButton>
      </div>
    );
  };

  // Get Checklist Container Classes
  const getChecklistItemClasses = () => {
    if (isDueDateGone() && !item.completed) {
      return classes.checklistItemRed;
    }

    return '';
  };

  // Handle Title click to update Checklist Item Click
  const handleTitleClick = () => {
    setItemToEditIdx(idx);
    setTimeout(() => {
      inputRef.current && inputRef.current.focus();
    }, 0);
  };

  // Handle Save Title Update
  const handleSaveUpdatedTitle = () => {
    onItemChange({ ...item, title: itemTitle });
    setItemToEditIdx(null);
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <div className={cx(classes.checklistItem, getChecklistItemClasses())}>
        <Checkbox
          checked={item.completed}
          onChange={e => {
            onItemChange({ ...item, completed: e.target.checked });
          }}
          color="primary"
          inputProps={{ 'aria-label': 'secondary checkbox' }}
        />
        <div className={classes.inputContainer}>
          {itemToEditIdx === idx ? (
            <input
              ref={inputRef}
              type="text"
              placeholder="Item Name"
              className={classes.input}
              value={itemTitle}
              onChange={e => setItemTitle(e.target.value)}
              onBlur={handleSaveUpdatedTitle}
            />
          ) : (
            <div
              className={classes.checklistTitleContainer}
              onClick={e => {
                e.stopPropagation();
                handleTitleClick();
              }}
            >
              <Typography
                className={cx(
                  classes.checklistTitle,
                  item.completed ? classes.checklistTitleDone : '',
                )}
              >
                {item.order + 1}.
              </Typography>

              <Typography
                className={cx(
                  classes.checklistTitle,
                  item.completed ? classes.checklistTitleDone : '',
                )}
                dangerouslySetInnerHTML={{ __html: linkifyHtmlString(item.title) }}
              />
            </div>
          )}
        </div>
        {renderStatus()}

        <KeyboardDatePicker
          open={isOpen}
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
          value={new Date(item.dueDate)}
          onChange={date => {
            onItemChange({ ...item, dueDate: date.valueOf() });
          }}
          TextFieldComponent={() => null}
        />
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default StaffingChecklistForm;
