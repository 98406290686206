import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { darken } from '@material-ui/core/styles';

import { Typography, Divider, MenuItem, Tooltip } from '@material-ui/core';

import BreakoutsTemplateAdmin from './Layout/BreakoutsTemplateAdmin';
import BreakoutsFormAdmin from './BreakoutsFormAdmin';
import ActivityMenu from '../../ActivityMenu/ActivityMenu';
import EditableTextWrapper from '../../EditableTextWrapper';
import MoveParticipants from '../../MoveParticipants';

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%',
  },
  center: {
    margin: '0 auto',
    textAlign: 'center',
  },
  topic: {
    marginBottom: '17px',
  },
  description: {
    marginBottom: props => (props.hasStarted ? '0px' : '75px'),
  },
  session: {
    marginBottom: '32px',
  },
  room: {
    marginBottom: '56px',
  },
  divider: {
    marginBottom: '32px',
  },
  formSubtopic: {
    fontSize: '18px',
    fontWeight: '500',
    marginBottom: 32,
    display: 'block',
  },
  addRoom: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: darken(theme.palette.primary.main, 0.2),
    },
  },
  addRoomDisabled: {
    color: '#fff',
    opacity: 0.6,
  },
  startBreakout: {
    backgroundColor: '#093B75',
    color: 'white',

    '&:hover': {
      backgroundColor: darken('#093B75', 0.2),
    },
  },
  participantsText: {
    fontWeight: 'bold',
  },
  participantsList: {},
}));
const BreakoutsAdmin = ({ hasStarted, sessionId, breakoutsSession }) => {
  const [moveDialogOpen, setMoveDialogOpen] = useState(false);
  const classes = useStyles(hasStarted);
  const routeParams = useParams();
  const participantsChooseRooms = breakoutsSession?.participantsChooseRooms;

  const toggleMoveDilog = () => {
    setMoveDialogOpen(!moveDialogOpen);
  };

  const renderMenuItems = () => {
    const items = [];

    if (breakoutsSession?.breakoutStarted) {
      items.push(
        <Tooltip
          title="You cannot move participants when they are allowed to choose their own rooms"
          placement="left-start"
          disableHoverListener={!participantsChooseRooms}
          disableFocusListener={!participantsChooseRooms}
          disableTouchListener={!participantsChooseRooms}
          arrow
        >
          <span>
            <MenuItem onClick={toggleMoveDilog} disabled={participantsChooseRooms}>
              Move Participants
            </MenuItem>
          </span>
        </Tooltip>,
        <Divider />,
      );
    }

    return items;
  };

  return (
    <>
      <BreakoutsTemplateAdmin>
        <div className="d-flex justify-content-between align-items-center">
          <EditableTextWrapper
            initialValue={breakoutsSession.topic?.name}
            fieldType="text"
            fieldName="name"
            firestoreCollection="breakouts"
            documentId={breakoutsSession.id}
            isActivityName
            isNestedIn="topic"
          >
            <Typography variant="h6" className="mb-1">
              Breakouts - {breakoutsSession.topic?.name}
            </Typography>
          </EditableTextWrapper>
          <ActivityMenu
            activityId={breakoutsSession.id}
            additionalMenuItems={renderMenuItems()}
            isLegacyActivity
          />
        </div>
        <Divider className="mb-2" />
        <BreakoutsFormAdmin
          sessionId={sessionId}
          hasStarted={hasStarted}
          classes={classes}
          breakoutId={routeParams.breakoutId}
        />
      </BreakoutsTemplateAdmin>
      <MoveParticipants open={moveDialogOpen} closeDialog={toggleMoveDilog} />
    </>
  );
};

export default BreakoutsAdmin;
