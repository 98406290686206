import { getFirebase } from 'react-redux-firebase';

/**
 * Fetches data for a given activity
 *
 * @param {string} collection - firestore collection
 * @param {string} activityId - firestore document id
 * @returns {object} the firestore document
 */
export const getActivityData = async (collection, activityId) => {
  const firebase = getFirebase();
  const db = firebase.firestore();
  const snapshot = await db.collection(`${collection}`).doc(`${activityId}`).get();
  const activityData = snapshot.data();

  return activityData;
};
