import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { map, filter } from 'lodash';
import clsx from 'clsx';
// library components
import { makeStyles, Paper, Typography, MenuItem, Icon, Box } from '@material-ui/core';
// cr components
import EllipsisMenu from 'components/EllipsisMenu';
import NewCardText from './NewCardText';

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: 170,
    height: 200,
    marginBottom: 10,
    border: '1px solid #D6D6D6',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
  },
  text: {
    fontSize: 18,
    fontWeight: 500,
    color: 'rgba(0, 0, 0, 0.87)',
  },
  ellipsisMenu: {
    position: 'absolute',
    top: 0,
    right: 5,
    zIndex: 10,
  },
  image: {
    width: '100%',
    height: '100%',
    borderRadius: 3,
  },
  imageWithText: {},
  imageText: {
    position: 'absolute',
    color: '#FFFFFF',
    fontSize: '12px',
    lineHeight: '30px',
    textAlign: 'center',
    bottom: 0, //set the text for Image to bottom
    left: 0,
    right: 0,
    background: 'rgba(10, 10, 10, 0.8)',
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
  },
}));

const Card = ({
  text,
  icon,
  image,
  cardId,
  handleDeleteCard,
  handleEditCard,
  handleCreateCardText,
}) => {
  const classes = useStyles();
  const [isEditText, setIsEditText] = useState(false);

  const menuList = [
    {
      function: () => {
        handleEditCard(cardId, 'icon', 'addIcon');
      },
      name: 'Add Icon',
      type: 'addIcon',
    },
    {
      function: () => {
        setIsEditText(true);
      },
      name: 'Add Text',
      type: 'addText',
    },
    {
      function: () => {
        setIsEditText(true);
      },
      name: 'Edit Text',
      type: 'editText',
    },
    {
      function: () => {
        handleEditCard(cardId, 'icon');
      },
      name: 'Edit Icon',
      type: 'editIcon',
    },
    {
      function: () => {
        handleEditCard(cardId, 'image');
      },
      name: 'Edit Image',
      type: 'editImage',
    },
    {
      function: () => {
        handleEditCard(cardId, 'text', 'deleteIcon');
      },
      name: 'Delete Icon',
      type: 'deleteIcon',
    },
    {
      function: () => {
        handleDeleteCard(cardId);
      },
      name: 'Delete',
      type: 'delete',
    },
  ];

  const menuItems = filter(menuList, item => {
    if (text && !icon && !image) {
      if (item.type === 'addIcon' || item.type === 'delete' || item.type === 'editText') {
        return item;
      }
    }
    //Add the Add Text to Image menu when no text exists
    if (image && !text) {
      if (item.type === 'editImage' || item.type === 'delete' || item.type === 'addText') {
        return item;
      }
    }
    //Add the Edit Text to Image Menu
    if (image && text) {
      if (item.type === 'editImage' || item.type === 'delete' || item.type === 'editText') {
        return item;
      }
    }
    if (icon && !text) {
      if (item.type === 'addText' || item.type === 'editIcon' || item.type === 'delete') {
        return item;
      }
    }
    if (icon && text) {
      if (
        item.type === 'editIcon' ||
        item.type === 'deleteIcon' ||
        item.type === 'delete' ||
        item.type === 'editText'
      ) {
        return item;
      }
    }
  });

  const renderMenuItems = () =>
    map(menuItems, item => <MenuItem onClick={item.function}>{item.name}</MenuItem>);

  return (
    <Paper className={classes.container} elevation={0}>
      <EllipsisMenu className={classes.ellipsisMenu} menuItems={renderMenuItems()} />
      {isEditText ? (
        <NewCardText
          createCardText={handleCreateCardText}
          handleCloseCreateText={() => {
            setIsEditText(false);
          }}
          cardId={cardId}
          context="edit"
          initialValue={text}
        />
      ) : (
        !!icon && <Icon style={{ fontSize: 40 }}>{icon}</Icon>
      )}
      {/* If it's Image & Text, combine the 'text' class and the 'imageText' class */}
      {!isEditText && (
        <Typography className={image ? clsx(classes.text, classes.imageText) : classes.text}>
          {text}
        </Typography>
      )}
      {/* Don't Display the Image while editing Text */}
      {
        image && !isEditText && (
          <img
            className={text ? clsx(classes.image, classes.imageWithText) : classes.image}
            src={image}
            alt="card"
          />
        ) //If Text Exits, add the 'imageWithText' class
      }
    </Paper>
  );
};

Card.propTypes = {
  text: PropTypes.string, // card text
  icon: PropTypes.string, // card icon
  image: PropTypes.string, // card image
  cardId: PropTypes.string.isRequired, //card ID
  handleDeleteCard: PropTypes.func, // remove card from set
  handleEditCard: PropTypes.func, // edit card
  handleCreateCardText: PropTypes.func, // create card text
};

export default Card;
