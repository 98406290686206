import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { filter } from 'lodash';
// library components
import { Box } from '@material-ui/core';
// cr components
import ActivityParticipantDetails from 'components/ActivityParticipantDetails';
import ActivityPromptAdmin from 'components/ActivityPromptAdmin';
import ActivityActionButton from 'components/CustomButtons/ActivityActionButton';
// selectors
import {
  useNoteAndMapSelector,
  getParticipantsCompletedVote,
} from 'redux/selectors/noteAndMapSelectors';
// actions
import { updateActivityView } from 'redux/actions/noteAndMapActions';
// config
import { NOTE_AND_MAP_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';
// utils
import { makeParticipantDetailsHeaderText } from 'utils/makeParticipantDetailsHeaderText';

/**
 * Note & Map view for the 'vote' phase on the Facilitator side
 */
const NoteAndMapAdminVote = () => {
  const dispatch = useDispatch();
  const { id, prompt } = useNoteAndMapSelector();
  const participantsHaveShared = useSelector(getParticipantsCompletedVote());
  const participantsHaveSharedCount = filter(participantsHaveShared, { completed: true }).length;

  const participantsHaveSharedCountText = makeParticipantDetailsHeaderText(
    participantsHaveSharedCount,
    participantsHaveShared.length,
    'voted',
  );

  return (
    <Box className="d-flex flex-column justify-content-center">
      <ActivityPromptAdmin
        prompt={prompt}
        collection={CONFIG.collection}
        activityId={id}
        className="mb-5"
      />
      <ActivityParticipantDetails
        headerText={participantsHaveSharedCountText}
        hasCompletedLabel="Voted"
        hasntCompletedLabel="Hasn’t Voted"
        participantsCompletedStatus={participantsHaveShared}
      />
      <ActivityActionButton
        text="Start Review"
        onClick={() => {
          dispatch(updateActivityView('voteResults', id));
        }}
        className="mb-2 mt-1"
      />
    </Box>
  );
};

export default NoteAndMapAdminVote;
