import React from 'react';
import { isEmpty, isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { breakoutsSessionSelector } from 'redux/selectors/Breakouts/rootBreakoutsSelector';

import BreakoutsAdmin from 'components/Breakouts/Admin/BreakoutsAdmin';

const BreakoutsDetailAdmin = ({ sessionId }) => {
  const routeParams = useParams();

  useFirestoreConnect(() => [
    {
      collection: 'breakouts',
      doc: `${routeParams.breakoutId}`,
      storeAs: 'breakouts',
    },
    {
      collection: 'participants',
      doc: `${sessionId}`,
      storeAs: 'participants',
    },
  ]);

  const breakoutsSession = useSelector(breakoutsSessionSelector);
  const session = useSelector(state => state.firestore.data.session);

  if (
    !isLoaded(session) ||
    isEmpty(session) ||
    !isLoaded(breakoutsSession) ||
    isEmpty(breakoutsSession) ||
    routeParams.breakoutId !== breakoutsSession?.id
  ) {
    return null;
  }

  const hasBreakoutStarted = breakoutsSession && !!breakoutsSession.breakoutStarted;
  return (
    <BreakoutsAdmin
      hasStarted={hasBreakoutStarted}
      breakoutsSession={breakoutsSession}
      sessionId={sessionId}
    />
  );
};

export default BreakoutsDetailAdmin;
