import React from 'react';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
// cr components
import ActivityTemplate from 'components/ActivityTemplate/ActivityTemplate';
import LiveScribingParticipantWrite from 'components/LiveScribing/Participant/LiveScribingParticipantWrite';
// selectors
import { useLiveScribingSelector } from 'redux/selectors/liveScribingSelectors';

//add new view for all action
const views = {
  write: LiveScribingParticipantWrite,
};

const LiveScribingParticipant = ({
  match: {
    params: { activityId },
  },
}) => {
  const liveScribing = useLiveScribingSelector();

  // hooks up a firestore listener to Redux
  useFirestoreConnect(() => [
    {
      collection: 'liveScribing',
      doc: `${activityId}`,
      storeAs: 'liveScribing',
    },
  ]);

  // ensure all of the data we need is loaded
  if (!isLoaded(liveScribing) || activityId !== liveScribing?.id) {
    return null;
  }

  const Component = views[liveScribing.state.activeView];

  return (
    <ActivityTemplate type="Live Scribing" name={liveScribing.name}>
      <Component />
    </ActivityTemplate>
  );
};

export default LiveScribingParticipant;
