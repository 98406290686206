import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isEmpty, isLoaded, useFirestoreConnect } from 'react-redux-firebase';

import AdminActivityTemplate from 'components/AdminActivityTemplate';
import LiveScribingAdminWrite from 'components/LiveScribing/Admin/LiveScribingAdminWrite';
// selectors
import { useLiveScribingSelector } from 'redux/selectors/liveScribingSelectors';
// constants
import { LIVE_SCRIBING_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';

const views = {
  write: LiveScribingAdminWrite,
};

const AdminLiveScribing = () => {
  const routeParams = useParams();
  const liveScribing = useLiveScribingSelector();
  const session = useSelector(state => state.firestore.data.session);

  // hooks up a firestore listener to Redux
  useFirestoreConnect(() => [
    {
      collection: 'liveScribing',
      doc: `${routeParams.activityId}`,
      storeAs: 'liveScribing',
    },
  ]);

  // ensure all of the data we need is loaded
  if (
    !isLoaded(session) ||
    isEmpty(session) ||
    !isLoaded(liveScribing) ||
    isEmpty(liveScribing) ||
    routeParams.activityId !== liveScribing?.id
  ) {
    return null;
  }

  const CurrentView = views[liveScribing.state.activeView];

  return (
    <AdminActivityTemplate
      activityConfig={CONFIG}
      activityData={liveScribing}
      session={session}
      CurrentView={CurrentView}
    />
  );
};

export default AdminLiveScribing;
