import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { difference, filter, head, sortBy, remove, map } from 'lodash';
// library components
import { Box } from '@material-ui/core';
// cr components
import ActivityParticipantDetails from 'components/ActivityParticipantDetails';
import ActivityActionButton from 'components/CustomButtons/ActivityActionButton';
// selectors
import {
  useLightningDemoSelector,
  getParticipantsCompletedReview,
  getParticipantDemos,
} from 'redux/selectors/lightningDemoSelectors';
// actions
import {
  revealResults,
  updateCompletedDemo,
  addCurrentParticipantId,
  updateParticipantsCompletedReview,
  addCurrentDemoId,
} from 'redux/actions/lightningDemoActions';

/**
 * Lightning Demo view for the 'review' phase on the Facilitator side
 */
const LightningDemoAdminReview = () => {
  const dispatch = useDispatch();
  const lightningDemo = useLightningDemoSelector();
  const {
    id,
    participantData: { demos },
    state: { currentParticipantId, currentDemoId, participantsCompletedReview },
  } = lightningDemo;
  const participantsHaveReviewed = useSelector(getParticipantsCompletedReview());
  const participantsHaveReviewedCount = filter(participantsHaveReviewed, {
    completed: true,
  }).length;

  const participantsReviewedCountText = `Viewing demo for participant ${participantsHaveReviewedCount} of ${participantsHaveReviewed.length}`;

  const participantDemos = useSelector(getParticipantDemos(currentParticipantId));
  // participants demos which are not reviewed
  let notCompletedParticipantDemos = filter(
    participantDemos,
    participant => !participant.isCompleted,
  );

  // get upload demos participants id
  const participantsUploadDemo = map(participantsHaveReviewed, participant => participant.id);

  // get not reviewed participants
  const participantsNotCompleted = difference(participantsUploadDemo, participantsCompletedReview);
  remove(participantsNotCompleted, currentParticipantId);

  let finishButtonText = 'Next Demo';
  if (!participantsNotCompleted?.length && notCompletedParticipantDemos.length <= 1) {
    finishButtonText = 'Review Results';
  }

  const onUpdateActivityView = () => {
    if (participantsNotCompleted?.length || notCompletedParticipantDemos.length > 1) {
      // if participant has not reviewed demos
      if (notCompletedParticipantDemos.length > 1) {
        notCompletedParticipantDemos = filter(
          notCompletedParticipantDemos,
          demo => demo.demoId !== currentDemoId,
        );
        const nextDemoId = head(notCompletedParticipantDemos);
        dispatch(addCurrentDemoId(id, nextDemoId.demoId));
        dispatch(updateCompletedDemo(id, nextDemoId.demoId));
      } else {
        // update next participant id
        const nextParticipant = head(participantsNotCompleted);
        const participantDemos = filter(demos, demo => demo.participantId === nextParticipant);
        const nextParticipantDemo = head(sortBy(participantDemos, 'order'));
        dispatch(addCurrentParticipantId(id, nextParticipant));
        dispatch(updateParticipantsCompletedReview(id, nextParticipant));
        dispatch(addCurrentDemoId(id, nextParticipantDemo ? nextParticipantDemo.demoId : ''));
        if (currentDemoId) {
          dispatch(updateCompletedDemo(id, currentDemoId));
        }
      }
    } else {
      dispatch(revealResults(id));
    }
  };

  return (
    <Box className="d-flex flex-column justify-content-center mt-8 mb-5">
      <ActivityParticipantDetails
        headerText={participantsReviewedCountText}
        hasCompletedLabel="Reviewed"
        hasntCompletedLabel="Hasn't Reviewed"
        participantsCompletedStatus={participantsHaveReviewed}
      />
      <ActivityActionButton
        key={currentDemoId}
        text={finishButtonText}
        className="mb-2"
        onClick={onUpdateActivityView}
        delayReady
      />
    </Box>
  );
};

export default LightningDemoAdminReview;
