import {
  SLIDES_AUTHED,
  LOGOUT,
  AUTH_TOKENS_LOADING,
  AUTH_TOKENS_ERROR,
} from '../types/googleSlidesAuthTypes';

const INITIAL_STATE = {
  tokensLoading: false,
  accessToken: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SLIDES_AUTHED:
      return {
        ...state,
        accessToken: action.payload,
        tokensLoading: false,
      };
    case LOGOUT:
      localStorage.clear();
      return {
        ...state,
        accessToken: null,
      };
    case AUTH_TOKENS_LOADING:
      return {
        ...state,
        tokensLoading: true,
      };
    case AUTH_TOKENS_ERROR:
      return {
        ...state,
        tokensLoading: false,
      };
    default:
      return state;
  }
};
