import {
  GOOGLE_SLIDES_EXPORTS_LOADING,
  GOOGLE_SLIDES_EXPORTS_SUCCESS,
  GOOGLE_SLIDES_EXPORTS_ERROR,
  GOOGLE_SLIDES_EXPORTING,
  GOOGLE_SLIDES_EXPORT_SUCCESS,
  GOOGLE_SLIDES_EXPORT_ERROR,
  GOOGLE_SLIDES_EXPORT_DELETED,
} from '../types/googleSlidesExportTypes';

import { SLIDES_SESSION_SUCCESS } from '../types/slidesSessionTypes';

import { includes } from 'lodash';

/**
 * Loads all the GoogleSlidesExports objects currently in Firestore
 * @param fileIds   an array of google slides file ids stored in the user profile
 * @param enqueueSnackbar   notistack helper
 */
export const loadGoogleSlidesExports =
  (enqueueSnackbar, fileIds) =>
  (dispatch, getState, { slidesApi }) => {
    dispatch({ type: GOOGLE_SLIDES_EXPORTS_LOADING });
    return slidesApi
      .getGoogleSlidesExports(fileIds)
      .then(exports => {
        dispatch({ type: GOOGLE_SLIDES_EXPORTS_SUCCESS, payload: exports });
      })
      .catch(err => {
        dispatch({ type: GOOGLE_SLIDES_EXPORTS_ERROR });
        enqueueSnackbar(err.message, { variant: 'error' });
        console.error(err);
      });
  };

/**
 * Exports a Google Slides file in Google Drive to static images and uploads them Firebase Storage / Firestore
 * @param fileId    The id of the Google Slides file in Google Drive
 * @param enqueueSnackbar   notistack helper
 */
export const exportGoogleSlidesFile =
  (fileId, accessToken, enqueueSnackbar) =>
  (dispatch, getState, { slidesApi, getFirebase }) => {
    dispatch({ type: GOOGLE_SLIDES_EXPORTING });

    const slidesExportError = err => {
      dispatch({ type: GOOGLE_SLIDES_EXPORT_ERROR });
      enqueueSnackbar(err.message, { variant: 'error' });
      console.error(err);
    };

    return slidesApi
      .exportGoogleSlidesFile(fileId, accessToken)
      .then(async googleSlidesExport => {
        const db = getFirebase().firestore;
        const session = getState().firestore.data.session;
        const user = getState().firebase.profile;
        const userId = user.uid;

        return slidesApi
          .createRemoteSlidesSessionForExport(googleSlidesExport.id, googleSlidesExport.name)
          .then(async slidesSession => {
            const batch = db().batch();

            batch.update(db().doc(`users/${userId}`), {
              googleSlidesExportIds: db.FieldValue.arrayUnion(googleSlidesExport.id),
            });

            batch.update(db().doc(`sessions/${session.id}`), {
              slidesSessionIds: db.FieldValue.arrayUnion(slidesSession.id),
            });

            return batch.commit().then(() => {
              dispatch({ type: SLIDES_SESSION_SUCCESS, payload: slidesSession });
              dispatch({ type: GOOGLE_SLIDES_EXPORT_SUCCESS, payload: googleSlidesExport });
              enqueueSnackbar('Google Slides Exported!', { variant: 'success' });
            });
          });
      })
      .catch(err => {
        slidesExportError(err);
      });
  };

/**
 * Exports a Pdf to static images and uploads them Firebase Storage / Firestore
 * @param file    File object from Html Input
 * @param enqueueSnackbar   notistack helper
 */
export const exportPdf =
  (file, enqueueSnackbar) =>
  (dispatch, getState, { slidesApi, getFirebase }) => {
    dispatch({ type: GOOGLE_SLIDES_EXPORTING });

    const slidesExportError = err => {
      dispatch({ type: GOOGLE_SLIDES_EXPORT_ERROR });
      enqueueSnackbar(err.message, { variant: 'error' });
      console.error(err);
    };

    return slidesApi
      .exportPdf(file)
      .then(async googleSlidesExport => {
        const db = getFirebase().firestore;
        const session = getState().firestore.data.session;
        const user = getState().firebase.profile;
        const userId = user.uid;

        return slidesApi
          .createRemoteSlidesSessionForExport(googleSlidesExport.id, session.topic)
          .then(async slidesSession => {
            const batch = db().batch();

            batch.update(db().doc(`users/${userId}`), {
              googleSlidesExportIds: db.FieldValue.arrayUnion(googleSlidesExport.id),
            });

            batch.update(db().doc(`sessions/${session.id}`), {
              slidesSessionIds: db.FieldValue.arrayUnion(slidesSession.id),
            });

            return batch.commit().then(() => {
              dispatch({ type: SLIDES_SESSION_SUCCESS, payload: slidesSession });
              dispatch({ type: GOOGLE_SLIDES_EXPORT_SUCCESS, payload: googleSlidesExport });
              enqueueSnackbar('Google Slides Exported!', { variant: 'success' });
            });
          });
      })
      .catch(err => {
        slidesExportError(err);
      });
  };

/**
 * Deletes a GoogleSlidesExport object in Firestore
 * @param exportId The id of the GoogleSlidesExport object to delete
 * @param setDeleting   Sets the 'deleting' state of the corresponding SessionItem View
 * @param enqueueSnackbar   notistack helper
 */
export const deleteGoogleSlidesExport =
  (exportId, setDeleting, enqueueSnackbar) =>
  (dispatch, getState, { slidesApi, getFirebase }) => {
    return slidesApi
      .deleteGoogleSlidesExport(exportId)
      .then(async () => {
        const user = getState().firebase.profile;
        const db = getFirebase().firestore;

        await db()
          .doc(`users/${user.uid}`)
          .update({
            googleSlidesExportIds: db.FieldValue.arrayRemove(exportId),
          });

        dispatch({ type: GOOGLE_SLIDES_EXPORT_DELETED, payload: exportId });
        enqueueSnackbar('Export Deleted', { variant: 'success' });
      })
      .catch(err => {
        if (setDeleting) {
          setDeleting(false);
        }
        enqueueSnackbar(err.message, { variant: 'error' });
        console.error(err);
      });
  };
