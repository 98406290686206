import { enqueueErrorSnackbar } from 'redux/actions/notifierActions';
import { includes } from 'lodash';
import { createOutput } from 'utils/userJourneyMoments';

import { USER_JOURNEY_MOMENTS_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';

/**
 * Update the active view for the activity
 * @param {String} view
 * @param {String} userJourneyMomentsId
 */
export const updateActivityView =
  (view, userJourneyMomentsId) =>
  (dispatch, getState, { getFirebase }) => {
    if (!includes(CONFIG.views, view)) {
      console.error(`View '${view}' does not exist in the activity config`);
      return;
    }

    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${userJourneyMomentsId}`)
      .set(
        {
          state: {
            activeView: view,
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error changing activity view'));
      });
  };

/**
 * Create participant journey field
 *
 * @param {String} userJourneyMomentsId
 * @param {String} participantId
 * @param {String} fieldName
 * @param {String} value
 */
export const createParticipantJourney =
  (userJourneyMomentsId, participantId, fieldName, value) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${userJourneyMomentsId}`)
      .set(
        {
          participantData: {
            journeys: {
              [participantId]: {
                participantId: participantId,
                values: {
                  [fieldName]: value,
                },
              },
            },
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error create participant journey field'));
      });
  };

/**
 * Update key moment for a journey
 *
 * @param {String} userJourneyMomentsId
 * @param {String} participantId
 * @param {String} keyMoment
 */
export const updateKeyMoment =
  (userJourneyMomentsId, participantId, keyMoment) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${userJourneyMomentsId}`)
      .set(
        {
          participantData: {
            journeys: {
              [participantId]: {
                participantId: participantId,
                keyMoment: keyMoment,
              },
            },
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error update key moment for a journey'));
      });
  };

/**
 * Update participant actor vote
 *
 * @param {String} userJourneyMomentsId
 * @param {String} journeyId
 * @param {String} participantId
 * @param {String} type
 * @param {Bool} isVoted
 */
export const updateParticipantActorVote =
  (userJourneyMomentsId, journeyId, participantId, type, isVoted) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${userJourneyMomentsId}`)
      .set(
        {
          participantData: {
            journeys: {
              [journeyId]: {
                votedParticipants: {
                  [participantId]: {
                    actor: {
                      type: type,
                      isVoted: isVoted,
                    },
                  },
                },
              },
            },
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error update participant actor vote'));
      });
  };

/**
 * Update participant moment vote
 *
 * @param {String} userJourneyMomentsId
 * @param {String} journeyId
 * @param {String} participantId
 * @param {String} type
 * @param {Bool} isVoted
 */
export const updateParticipantMomentVote =
  (userJourneyMomentsId, journeyId, participantId, type, isVoted) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${userJourneyMomentsId}`)
      .set(
        {
          participantData: {
            journeys: {
              [journeyId]: {
                votedParticipants: {
                  [participantId]: {
                    moment: {
                      type: type,
                      isVoted: isVoted,
                    },
                  },
                },
              },
            },
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error update participant moment vote'));
      });
  };

/**
 * Create the activity output and update the view to 'results'
 * @param {String} userJourneyMomentsId
 */
export const revealResults =
  userJourneyMomentsId =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;
    const state = getState();
    const { journeys, superVoteTypes } = state.firestore.data.userJourneyMoments.participantData;
    const output = createOutput(journeys, superVoteTypes);

    db()
      .doc(`${CONFIG.collection}/${userJourneyMomentsId}`)
      .set(
        {
          output,
        },
        { merge: true },
      )
      .then(() => {
        dispatch(updateActivityView('results', userJourneyMomentsId));
      })
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error generating results'));
      });
  };

/**
 * Update super vote participant id
 *
 * @param {String} userJourneyMomentsId
 * @param {String} participantId
 */
export const updateSuperVoteParticipant =
  (userJourneyMomentsId, participantId) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${userJourneyMomentsId}`)
      .set(
        {
          state: {
            superVoteParticipantId: participantId,
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error update super vote User Journey Moments participant'));
      });
  };

/**
 * Update participant super vote actor/moment/outcome
 *
 * @param {String} userJourneyMomentsId
 * @param {String} type
 * @param {String} journeyId
 * @param {Boolean} isVoted
 */
export const updateSuperVote =
  (userJourneyMomentsId, journeyId, type, isVoted) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;
    const superVoteTypes = isVoted
      ? db.FieldValue.arrayUnion({ id: journeyId, type })
      : db.FieldValue.arrayRemove({ id: journeyId, type });
    db()
      .doc(`${CONFIG.collection}/${userJourneyMomentsId}`)
      .set(
        {
          participantData: {
            superVoteTypes,
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error update participant vote'));
      });
  };
