import { createSelector } from 'reselect';
import { useShallowEqualSelector } from '../utils';
import { map, includes, find, size, each } from 'lodash';

const userJourneyMomentsSelector = state => state.firestore.data.userJourneyMoments || {};

export const useUserJourneyMomentsSelector = () => {
  return useShallowEqualSelector(userJourneyMomentsSelector);
};

/**
 * Get data on which participants have created or not created a
 * moments yet, formatted for the ActivityParticipantDetail component
 *
 * @returns {Array.<{id: String, completed: Boolean}>}
 */
export const getParticipantsHaveCreated = () =>
  createSelector(
    [userJourneyMomentsSelector],
    ({ participantData: { participants, journeys } }) => {
      const maxParticipantJourneyFields = 6;
      return map(participants, participantId => {
        const participantJourney = find(
          journeys,
          journey => journey.participantId === participantId && !includes(journey.values, ''),
        );
        return {
          id: participantId,
          completed: size(participantJourney?.values) > 0,
          completedCount: size(participantJourney?.values),
          //TOD0: Send the totals - but how do we diplays them... maxParticipantJourneyFields
        };
      });
    },
  );

/**
 * Get data on which participants have selected or not selected a key
 * moment yet, formatted for the ActivityParticipantDetail component
 *
 * @returns {Array.<{id: String, completed: Boolean}>}
 */
export const getParticipantsHaveSelectedKeyMoment = () =>
  createSelector([userJourneyMomentsSelector], ({ participantData: { participants, journeys } }) =>
    map(participants, participantId => {
      const participantJourney = find(
        journeys,
        journey => journey.participantId === participantId && journey.keyMoment,
      );
      return {
        id: participantId,
        completed: !!participantJourney,
        //completedCount is not needed, max is 1.
      };
    }),
  );

/**
 * Get data on which participants have vote or not voted,
 * formatted for the ActivityParticipantDetail component
 *
 * @returns {Array.<{id: String, completed: Boolean}>}
 */
export const getParticipantsHaveVoted = () =>
  createSelector([userJourneyMomentsSelector], ({ participantData: { participants, journeys } }) =>
    map(participants, participantId => {
      let momentVotes = false;
      let actorVotes = false;
      each(journeys, journey => {
        const votedJourney = journey.votedParticipants?.[participantId];
        if (votedJourney?.actor?.isVoted && !actorVotes) {
          actorVotes = votedJourney.actor.isVoted;
        }
        if (votedJourney?.moment?.isVoted && !momentVotes) {
          momentVotes = votedJourney.moment.isVoted;
        }
      });
      return {
        id: participantId,
        completed: momentVotes && actorVotes,
        //completedCount is not needed - they have done the thing or not.
      };
    }),
  );

/**
 * Get journeys where exist participant actor votes
 *
 * @param {String} participantId
 */
export const getParticipantActorVotes = participantId =>
  createSelector([userJourneyMomentsSelector], ({ participantData: { journeys } }) =>
    map(journeys, journey => {
      return {
        journeyId: journey.participantId,
        votes: journey.votedParticipants?.[participantId]?.actor,
      };
    }),
  );

/**
 * Get journeys where exist participant moment votes
 *
 * @param {String} participantId
 */
export const getParticipantMomentVotes = participantId =>
  createSelector([userJourneyMomentsSelector], ({ participantData: { journeys } }) =>
    map(journeys, journey => {
      return {
        journeyId: journey.participantId,
        votes: journey.votedParticipants?.[participantId]?.moment,
      };
    }),
  );

/**
 * Get super voted types data
 *
 */
export const getSuperVotes = () =>
  createSelector([userJourneyMomentsSelector], ({ participantData }) => {
    return participantData?.superVoteTypes || [];
  });
