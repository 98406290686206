import React from 'react';
import { useSelector } from 'react-redux';
import { map } from 'lodash';
// library components
import { Box } from '@material-ui/core';
// cr components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import NoteCard from 'components/Polling/Participant/NoteCard';
import AdminActivityAuxView from 'components/AdminActivityAuxView';
import CSVReportDownloadButton from 'components/CSVReportDownloadButton';
import { createPollingReportHeader, createPollingReportData } from 'utils/polling';
// selectors
import { usePollingSelector } from 'redux/selectors/pollingSelectors';

/**
 * Renders options for the 'results' phases on the Facilitator side
 */
const PollingReview = () => {
  const polling = usePollingSelector();
  const { output, name, state } = polling;

  const session = useSelector(state => state.firestore.data.session);

  if (!state?.activeView || state.activeView !== 'results') {
    return null;
  }

  // render headers for csv report
  const headers = createPollingReportHeader();

  // render data for csv report
  const csvReport = createPollingReportData(output);

  const renderResults = () =>
    map(output, (note, idx) => (
      <GridItem key={`results-${idx}`} xs={12} sm={6} md={4} lg={4} xl={4}>
        <NoteCard
          note={{
            ...note,
            value: note.text,
          }}
          context="results"
        />
      </GridItem>
    ));

  const renderPrintResults = () =>
    map(output, (note, idx) => (
      <GridItem key={`results-${idx}`} xs={4} className="p-0">
        <NoteCard
          note={{
            ...note,
            value: note.text,
          }}
          context="results"
        />
      </GridItem>
    ));

  return (
    <AdminActivityAuxView
      titleText="Review Results"
      allowPrint={state.activeView === 'results'}
      activityName={name}
      hasDownloadButton={state.activeView === 'results'}
      renderDownloadButton={() => (
        <CSVReportDownloadButton
          headers={headers}
          csvReport={csvReport}
          buttonText="Download Results"
          filename="Results.csv"
          topic={session.topic}
          activity="Polling"
          activityName={name}
          withoutLabel={true}
        />
      )}
    >
      <Box displayPrint="none">
        <GridContainer direction="row">{renderResults()}</GridContainer>
      </Box>
      <Box displayPrint="flex" display="none" justifyContent="start" flexWrap="wrap">
        <GridContainer direction="row">{renderPrintResults()}</GridContainer>
      </Box>
    </AdminActivityAuxView>
  );
};

export default PollingReview;
