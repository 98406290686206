import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { map, forEach } from 'lodash';
import { Box, Typography, makeStyles, Divider } from '@material-ui/core';
// selectors
import { getParticipantsMap } from 'redux/selectors/participantsSelectors';

const useStyles = makeStyles({
  question: {
    marginTop: 20,
    marginBottom: 10,
    fontSize: 15,
    fontWeight: 500,
    lineHeight: '20px',
  },
  comment: {
    fontSize: 15,
    fontWeight: 300,
    lineHeight: '20px',
  },
  commentBox: {
    marginBottom: 15,
  },
  divider: {
    width: 'calc(100% + 48px)',
    margin: '0 -24px',
  },
});

/**
 * Renders the question, comments on the question, the participant name who left the comment, the name of the tester
 */
const CommentsModal = ({ question, comments }) => {
  const classes = useStyles();
  const participantsData = useSelector(getParticipantsMap);

  const renderComments = () =>
    map(comments, tester => {
      const results = [];
      forEach(tester, (participant, participantId) => {
        const participantName = participantsData?.[participantId]
          ? participantsData?.[participantId].name
          : 'Uknownw';
        const answer = participant.answer ? participant.answer : 'No answer';
        const testerName = participant.testerName;
        const answerWithParticipant = `${answer}, ${participantName}, TESTER: ${testerName}`;
        results.push(
          <Box className={classes.commentBox} key={`comments-${participantId}`}>
            <Typography className={classes.comment}>{participant.comment}</Typography>
            <Typography className={classes.comment}>{answerWithParticipant}</Typography>
          </Box>,
        );
      });
      return results;
    });

  return (
    <Box className="pb-3">
      <Divider className={classes.divider} />
      <Typography className={classes.question}>{question}</Typography>
      {renderComments()}
    </Box>
  );
};

CommentsModal.propTypes = {
  question: PropTypes.string, // the question where comment was left
  comments: PropTypes.shape({
    testerName: PropTypes.string, // the tester name where comment was left
    comment: PropTypes.string, // the participant comment
    answer: PropTypes.string, // participant answer on question
  }),
};

export default CommentsModal;
