import React from 'react';
import { sortBy, map } from 'lodash';
import { useSelector } from 'react-redux';
// library components
import { makeStyles, Box } from '@material-ui/core';
import { DragDropContext } from 'react-beautiful-dnd';
// cr components
import Category from './Category';
// selectors
import { useCardSortingSelector, getCategorizedCards } from 'redux/selectors/cardSortingSelectors';

const useStyles = makeStyles({
  categoriesContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: 'calc(100% + 20px)',
  },
});

const CardSortingParticipantReview = () => {
  const classes = useStyles();
  const {
    config: { categories, primaryCategory },
    state: { activeView },
  } = useCardSortingSelector();

  const cards = useSelector(getCategorizedCards());

  const sortedCategories = sortBy(categories, ['order', 'value']);

  // render categories
  const renderCategories = () =>
    map(sortedCategories, (category, idx) => {
      const categoryCards = cards?.[category.id];
      const sortedCards = sortBy(categoryCards, ['order', 'cardId']);
      return (
        <Category
          key={`${category.value}-${idx}`}
          id={category.id}
          droppableId={`${idx}`}
          categoryName={category.value}
          cards={sortedCards}
          context={activeView}
          isPrimaryCategory={primaryCategory === category.id}
        />
      );
    });

  return (
    <DragDropContext>
      <Box className={classes.categoriesContainer}>{renderCategories()}</Box>
    </DragDropContext>
  );
};

export default CardSortingParticipantReview;
