import React from 'react';
// library components
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// cr components
import ActivityPromptAdmin from 'components/ActivityPromptAdmin';
// selectors
import { useHeatMappingSelector } from 'redux/selectors/heatMappingSelectors';
// config
import { HEAT_MAPPING_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';

const useStyles = makeStyles(theme => ({
  promptAdmin: {
    marginBottom: theme.spacing(1),
  },
}));

/**
 * Heat-Mapping view for the 'results' phase on the Facilitator side
 */
const HeatMappingAdminResults = () => {
  const classes = useStyles();
  const heatMapping = useHeatMappingSelector();
  const { id, prompt } = heatMapping;

  return (
    <Box className="d-flex flex-column justify-content-center">
      <ActivityPromptAdmin
        prompt={prompt}
        collection={CONFIG.collection}
        activityId={id}
        className={classes.promptAdmin}
      />
    </Box>
  );
};

export default HeatMappingAdminResults;
