import React from 'react';
// library components
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// cr components
import ActivityPromptAdmin from 'components/ActivityPromptAdmin';
// selectors
import { useNoteAndMapSelector } from 'redux/selectors/noteAndMapSelectors';
// config
import { NOTE_AND_MAP_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';

const useStyles = makeStyles(theme => ({
  promptAdmin: {
    marginBottom: theme.spacing(1),
  },
}));

/**
 * NoteAndMap view for the 'results' phase on the Facilitator side
 */
const NoteAndMapAdminResults = () => {
  const classes = useStyles();
  const noteAndMap = useNoteAndMapSelector();
  const { id, prompt } = noteAndMap;

  return (
    <Box className="d-flex flex-column justify-content-center">
      <ActivityPromptAdmin
        prompt={prompt}
        collection={CONFIG.collection}
        activityId={id}
        className={classes.promptAdmin}
      />
    </Box>
  );
};

export default NoteAndMapAdminResults;
