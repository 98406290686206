import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { map, sortBy, size, find, split, head, filter, concat, isEmpty } from 'lodash';
// library components
import { grey } from '@material-ui/core/colors';
import {
  Typography,
  Box,
  makeStyles,
  Tabs,
  Tab,
  ClickAwayListener,
  Popper,
  Paper,
  MenuList,
  MenuItem,
  Grow,
  Divider,
} from '@material-ui/core';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import ThumbDownAltIcon from '@material-ui/icons/ThumbDownAlt';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
// cr components
import QuestionResults from 'components/Scorecard/Participant/QuestionResults';
import Insights from 'components/Scorecard/Participant/Insights';
// selectors
import { getParticipantsMap } from 'redux/selectors/participantsSelectors';
import {
  getAnswersSelector,
  getParticipantsNotes,
  getCommentsSelector,
} from 'redux/selectors/scorecardSelectors';
// utils
import { getSortedNotes } from 'utils/scorecard';

const useStyles = makeStyles({
  tab: {
    minWidth: 80,
    minHeight: 50,
    fontSize: 12,
  },
  testerTab: {
    minWidth: 50,
    minHeight: 50,
    fontSize: 12,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  questionTitle: {
    fontSize: 11,
  },
  icons: {
    marginRight: 9,
    fontSize: 13,
    opacity: 0.55,
  },
  tabIcons: {
    marginRight: 8,
    marginBottom: `0px !important`,
    fontSize: 14,
  },
  dropDownIcon: {
    order: 3,
  },
  filterMenu: {
    background: '#fff',
    borderRadius: 3,
    boxShadow: '0px 3px 14px rgba(0, 0, 0, 0.35)',
  },
  popper: {
    zIndex: 10,
  },
  title: {
    textAlign: 'center',
    fontWeight: 300,
    fontSize: 30,
    lineHeight: '40px',
    opacity: 0.87,
  },
});

/**
 * Scorecard view for the 'results' phase
 */
const ResultsView = ({ sprintQuestions, prototypeQuestions, testers, context }) => {
  const classes = useStyles();
  const anchorRef = useRef(null);
  const [openFilterMenu, setOpenFilterMenu] = useState(false);
  const [notesFilter, setNotesFilter] = useState('all-notes');

  // sort sprint questions by value and id
  const sortedSprintQuestions = sortBy(sprintQuestions, ['order', 'value']);
  // sort prototype questions by value and id
  const sortedPrototypeQuestions = sortBy(prototypeQuestions, ['order', 'value']);

  // get participants data
  const participantsData = useSelector(getParticipantsMap);
  const participantQuestions = useSelector(getAnswersSelector());
  const notes = useSelector(getParticipantsNotes());
  const participantComments = useSelector(getCommentsSelector());
  // sort notes by action
  const sortedNotes = getSortedNotes(notes);

  // filter notes
  const filterNotes =
    notesFilter && notesFilter !== 'all-notes'
      ? filter(sortedNotes, note => note.action?.action === notesFilter)
      : sortedNotes;

  // active tab 'insights' or 'graph'
  const [activeTab, setActiveTab] = useState(0);
  // active tester tab
  const [activeTesterTab, setActiveTesterTab] = useState(0);
  const [activeTester, setActiveTester] = useState();

  // get tester notes
  const sortedNotesWithTester = filter(filterNotes, note => note.testerId === activeTester);

  // change tab 'insights' or 'graph'
  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
  };

  // change tester or 'all notes' tab
  const handleChangeTesterTab = (event, newValue) => {
    setActiveTester(event.currentTarget.id);
    setActiveTesterTab(newValue);
  };

  // open filter menu
  const handleToggleFilterMenu = () => {
    setOpenFilterMenu(prevOpen => !prevOpen);
  };

  // close filter menu and change filter parameter
  const handleCloseFilterMenu = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    if (event.target.id) {
      setNotesFilter(event.target.id);
    }

    setOpenFilterMenu(false);
  };

  const prevOpen = useRef(openFilterMenu);
  useEffect(() => {
    if (prevOpen.current === true && openFilterMenu === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = openFilterMenu;
  }, [openFilterMenu]);

  // render tab label
  const renderLabel = () => {
    switch (notesFilter) {
      case 'like':
        return 'Positive';
      case 'unlike':
        return 'Negative';
      case 'quote':
        return 'Quotes';
      default:
        return 'All notes';
    }
  };

  // render tab icon
  const renderIcon = () => {
    switch (notesFilter) {
      case 'like':
        return <ThumbUpAltIcon className={classes.tabIcons} />;
      case 'unlike':
        return <ThumbDownAltIcon className={classes.tabIcons} />;
      case 'quote':
        return <FormatQuoteIcon className={classes.tabIcons} />;
      default:
        return <ChatBubbleIcon className={classes.tabIcons} />;
    }
  };

  // renders questions
  const renderQuestions = (questions, showLegend) =>
    map(questions, (question, idx) => {
      const questionAnswers = find(
        participantQuestions,
        participantQuestion => participantQuestion.id === question.id,
      );
      const questionComments = find(
        participantComments,
        participantComment =>
          participantComment.id === question.id && !isEmpty(participantComment.comments),
      );
      return (
        <QuestionResults
          key={`question-${idx}`}
          number={idx + 1}
          question={question.value}
          questionId={question.id}
          questionsCount={size(questions)}
          participantAnswers={questionAnswers.answers}
          showLegend={showLegend}
          context={context}
          questionComments={questionComments}
        />
      );
    });

  // renders participants notes, action for tester
  const renderInsights = notes => {
    return map(notes, (note, idx) => {
      const testerData = find(testers, tester => tester.id === note.testerId);
      return (
        <Insights
          key={`insight-note-${idx}`}
          note={note.note}
          action={note.action?.action}
          participant={participantsData[note.participantId].name}
          tester={testerData.value}
          time={note.action ? note.action.time : note.timeCreated}
        />
      );
    });
  };

  return (
    <Box className="w-100" display="flex" justifyContent="flex-start" flexDirection="column">
      {context === 'results' && (
        <>
          <Typography className={classes.title}>Results</Typography>
        </>
      )}
      <Box
        className="w-100"
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        flexWrap="wrap"
      >
        <Tabs
          className="mb-3 mr-auto"
          value={activeTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChangeTab}
        >
          <Tab disableRipple className={classes.tab} label="GRAPH" />
          <Tab disableRipple className={classes.tab} label="INSIGHTS" />
        </Tabs>
        {!!activeTab && (
          <Tabs
            className="mb-3"
            value={activeTesterTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChangeTesterTab}
          >
            <Tab
              ref={anchorRef}
              aria-controls={openFilterMenu ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              onClick={handleToggleFilterMenu}
              disableRipple
              classes={{ wrapper: classes.wrapper }}
              className={classes.testerTab}
              icon={
                <>
                  {renderIcon()}
                  {openFilterMenu ? (
                    <ArrowDropDownIcon className={classes.dropDownIcon} />
                  ) : (
                    <ArrowDropUpIcon className={classes.dropDownIcon} />
                  )}
                </>
              }
              label={renderLabel()}
            />
            {map(testers, (tester, idx) => {
              const testerNotes = filter(filterNotes, note => note.testerId === tester.id);
              if (size(testerNotes)) {
                return (
                  <Tab
                    key={`tester-${idx}`}
                    id={idx}
                    disableRipple
                    className={classes.testerTab}
                    label={head(split(tester.value, ' ', 1))}
                  />
                );
              }
            })}
          </Tabs>
        )}
        <Popper
          className={classes.popper}
          open={openFilterMenu}
          anchorEl={anchorRef.current}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper className={classes.filterMenu}>
                <ClickAwayListener onClickAway={handleCloseFilterMenu}>
                  <MenuList id="filter-menu-list-grow">
                    <MenuItem
                      id="all-notes"
                      selected={notesFilter === 'all-notes'}
                      onClick={handleCloseFilterMenu}
                    >
                      <ChatBubbleIcon className={classes.icons} />
                      All Notes
                    </MenuItem>
                    <MenuItem
                      id="like"
                      selected={notesFilter === 'like'}
                      onClick={handleCloseFilterMenu}
                    >
                      <ThumbUpAltIcon className={classes.icons} />
                      Positive
                    </MenuItem>
                    <MenuItem
                      id="unlike"
                      selected={notesFilter === 'unlike'}
                      onClick={handleCloseFilterMenu}
                    >
                      <ThumbDownAltIcon className={classes.icons} />
                      Negative
                    </MenuItem>
                    <MenuItem
                      id="quote"
                      selected={notesFilter === 'quote'}
                      onClick={handleCloseFilterMenu}
                    >
                      <FormatQuoteIcon className={classes.icons} />
                      Quotes
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Box>
      {activeTab ? (
        activeTesterTab ? (
          renderInsights(sortedNotesWithTester)
        ) : (
          renderInsights(filterNotes)
        )
      ) : (
        <>
          <Box display="flex" flexDirection="column" className="w-100">
            <Typography className={classes.questionTitle} variant="overline" color="textSecondary">
              Questions
            </Typography>
            <Divider className="mt-2" />
            {renderQuestions(concat(sortedSprintQuestions, sortedPrototypeQuestions), true)}
          </Box>
        </>
      )}
    </Box>
  );
};

ResultsView.propTypes = {
  sprintQuestions: PropTypes.shape({
    // sprint questions
    id: PropTypes.string,
    value: PropTypes.string,
  }),
  prototypeQuestions: PropTypes.shape({
    // prototype questions
    id: PropTypes.string,
    value: PropTypes.string,
  }),
  testers: PropTypes.shape({
    // testers
    id: PropTypes.string,
    value: PropTypes.string,
  }),
  scorecardName: PropTypes.string, // name of scorecard activity
  context: PropTypes.string, // the current activity active view
};

export default ResultsView;
