import React from 'react';
import { useSelector } from 'react-redux';
import { map } from 'lodash';
import { makeStyles } from '@material-ui/styles';
// cr components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import NoteCard from './NoteCard';
import NewNote from './NewNote';
// selectors
import { getParticipantNotes } from 'redux/selectors/noteAndVoteSelectors';

const useStyles = makeStyles({
  card: {
    padding: '0 10px !important',
  },
  grid: {
    width: 'calc(100% + 20px)',
  },
});

/**
 * Note & Vote view for the 'write' phase on the Participant side
 */
const NoteAndVoteParticipantWrite = () => {
  const classes = useStyles();
  const participantId = useSelector(state => state.firebase.auth.uid);
  const participantNotes = useSelector(getParticipantNotes(participantId));

  const renderParticipantNotes = () =>
    map(participantNotes, note => (
      <GridItem key={note.noteId} className={classes.card} xs={12} sm={6} md={3} lg={3} xl={3}>
        <NoteCard note={note} context="write" />
      </GridItem>
    ));

  return (
    <GridContainer direction="row" className={classes.grid}>
      <GridItem className={classes.card} xs={12} sm={6} md={3} lg={3} xl={3}>
        <NewNote />
      </GridItem>
      {renderParticipantNotes()}
    </GridContainer>
  );
};

export default NoteAndVoteParticipantWrite;
