import React from 'react';
import { useDispatch } from 'react-redux';
// library components
import { Box } from '@material-ui/core';
// cr components
import ActivityParticipantDetails from 'components/ActivityParticipantDetails';
import ActivityPromptAdmin from 'components/ActivityPromptAdmin';
import ActivityActionButton from 'components/CustomButtons/ActivityActionButton';
// actions
import { updateActivityView } from 'redux/actions/noteAndVoteActions';
// selectors
import { useNoteAndVoteSelector } from 'redux/selectors/noteAndVoteSelectors';
// config
import { NOTE_AND_VOTE_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';

/**
 * Note & Vote view for the 'review' phase on the Facilitator side
 */
const NoteAndVoteAdminReview = () => {
  const dispatch = useDispatch();
  const { id, prompt } = useNoteAndVoteSelector();

  const onNextActivity = () => {
    dispatch(updateActivityView('vote', id));
  };

  return (
    <Box className="d-flex flex-column justify-content-center">
      <ActivityPromptAdmin
        prompt={prompt}
        collection={CONFIG.collection}
        activityId={id}
        className="mb-5"
      />
      <ActivityParticipantDetails headerText="Reviewing notes & grouping duplicates." noDetails />
      <ActivityActionButton
        text="Start Voting"
        onClick={onNextActivity}
        className="mb-2 mt-10"
        delayReady
      />
    </Box>
  );
};

export default NoteAndVoteAdminReview;
