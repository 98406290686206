import React from 'react';
import { useSelector } from 'react-redux';

// material ui
import { Typography, makeStyles } from '@material-ui/core';

// cr components
import GrayBox from 'components/GrayBox/GrayBox';

// selectors
import { useBrainwritingSelector } from 'redux/selectors/brainwritingSelectors';

// urils
import { renderHtmlStringSimple } from 'utils/renderHtmlString';

const useStyles = makeStyles(theme => ({
  results: {
    color: theme.palette.primary.main,
    fontSize: 20,
  },
  grayBox: {
    width: 'fit-content',
    marginBottom: theme.spacing(4),
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

const BrainwritingParticipantResults = () => {
  const classes = useStyles();
  const { ideas, topic } = useBrainwritingSelector();
  const participantId = useSelector(state => state.firebase.auth.uid);

  const renderResultText = () => {
    if (ideas[participantId]) {
      return ideas[participantId].text;
    } else {
      return "Looks like you didn't contribute anything.";
    }
  };

  return (
    <div className="w-100">
      <GrayBox className={classes.grayBox}>
        <Typography className="text-center">{topic}</Typography>
      </GrayBox>
      <Typography className={`${classes.results} text-center mb-4`}>Results</Typography>
      <Typography
        className="font-italic mb-4 white-space-pre-wrap"
        dangerouslySetInnerHTML={renderHtmlStringSimple(renderResultText())}
      />
    </div>
  );
};

export default BrainwritingParticipantResults;
