import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { includes, size, join, chunk, truncate } from 'lodash';

// selectors
import { makeGetSession } from 'redux/selectors/getSession';

// core components
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';

import FeedbackIcon from 'components/FeedbackIcon/FeedbackIcon';
import SessionCardMenu from './SessionCardMenu';

// scss
import './sessionCard.scss';

const textColor = {
  satisfied: 'success.main',
  neutral: 'warning.main',
  dissatisfied: 'error.main',
  empty: '#8997B1',
};

const renderCount = (sentiment, count) => {
  let color = '#898989';

  if (count > 0) {
    color = textColor[sentiment];
  }

  return (
    <Box component="span" color={color} fontWeight="400" fontSize="20px" marginTop="10px">
      {count}
    </Box>
  );
};

const renderActivitiesList = activitiesList => {
  let activities = size(activitiesList)
    ? join(activitiesList, ', ')
    : 'Add activities to view them here';
  const countActivities = size(activitiesList);

  if (countActivities > 5) {
    const chunkActivities = chunk(activitiesList, 5);
    const firstPart = join(chunkActivities[0], ', ');
    const secondPart = size(chunkActivities[1]);
    activities = `${firstPart}, + ${secondPart} more`;
  }

  return activities;
};

const truncateMeetingName = topic => {
  const newName = truncate(topic, {
    length: 70,
    separator: ' ',
  });
  return newName;
};

const titleStyles = makeStyles({
  h6: {
    width: '100%',
    fontSize: 18,
    fontWeight: 400,
    lineHeight: '30px',
    color: 'rgba(0, 0, 0, 0.87)',
    overflowWrap: 'break-word',
    whiteSpace: 'pre-line',
    textAlign: 'center',
  },
  h5: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '21px',
    color: 'rgba(0, 0, 0, 0.87)',
    textAlign: 'center',
  },
});

const cardActionStyles = makeStyles({
  focusHighlight: {
    background: 'transparent',
  },
});

const cardContentStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    paddingTop: 20,
    paddingBottom: props => (props.isViewingShared ? '42px !important' : '0 !important'),
  },
});

const SessionCard = ({ id, isViewingShared, sessionsType }) => {
  const getSession = makeGetSession();
  const history = useHistory();

  const {
    sessionDateWithYear,
    percentScored,
    topic,
    feedbackCount,
    filteredFeedback,
    facilitatorName,
    activitiesList,
    activities,
    recapDeck,
  } = useSelector(state => getSession(state, id, sessionsType));

  const handleClick = () => {
    history.push(`/admin/${sessionsType}/${id}`);
  };

  return (
    <Card className="session-card">
      <CardContent classes={cardContentStyles({ isViewingShared })}>
        <div className="card-header">
          <div className="card-header-status" onClick={handleClick}>
            <Typography variant="h6" classes={titleStyles()}>
              {truncateMeetingName(topic)}
            </Typography>
            <Typography variant="h5" classes={titleStyles()}>
              {sessionDateWithYear}
            </Typography>
          </div>
          <SessionCardMenu
            sessionId={id}
            isViewingShared={isViewingShared}
            activities={activities}
            recapDeck={recapDeck}
            sessionTopic={topic}
          />
        </div>
        <CardActionArea
          disableRipple
          classes={cardActionStyles({ feedbackCount: feedbackCount })}
          onClick={handleClick}
          className="card-action-area"
        >
          <Box className="w-100">
            <Typography
              color="textSecondary"
              align="center"
              style={{ marginBottom: 8, fontWeight: '300', fontSize: 14 }}
            >
              {renderActivitiesList(activitiesList)}
            </Typography>
          </Box>
          <GridContainer direction="row" justify="center" alignItems="center">
            <GridItem className="card-sentiment">
              <FeedbackIcon type="satisfied" active={!!size(filteredFeedback.satisfied)} />
              {renderCount('satisfied', filteredFeedback.satisfied.length || 0, percentScored)}
            </GridItem>
            <GridItem className="card-sentiment">
              <FeedbackIcon type="neutral" active={!!size(filteredFeedback.neutral)} />
              {renderCount('neutral', filteredFeedback.neutral.length || 0, percentScored)}
            </GridItem>
            <GridItem className="card-sentiment">
              <FeedbackIcon type="dissatisfied" active={!!size(filteredFeedback.dissatisfied)} />
              {renderCount(
                'dissatisfied',
                filteredFeedback.dissatisfied.length || 0,
                percentScored,
              )}
            </GridItem>
          </GridContainer>
          {isViewingShared && facilitatorName && (
            <Box
              className="w-100 d-flex align-items-center"
              style={{ position: 'absolute', bottom: '-32px', left: '-4px', color: 'grey' }}
            >
              <SupervisedUserCircleIcon style={{ marginRight: 4 }} />
              {facilitatorName}
            </Box>
          )}
        </CardActionArea>
      </CardContent>
    </Card>
  );
};

SessionCard.propTypes = {
  id: PropTypes.string.isRequired,
  isViewingShared: PropTypes.bool, // viewing as co-facilitator
  sessionsType: PropTypes.oneOf(['sessions', 'shared-sessions', 'past-sessions']),
};

export default SessionCard;
