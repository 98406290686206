import React from 'react';
import cx from 'classnames';
// 3rd party components
import { Typography } from '@material-ui/core';
// styles
import { useAssessmentStyles } from '../Shared/useAssessmentStyles';

const AssessmentParticipantAllAnswered = ({ questionCount }) => {
  const commonClasses = useAssessmentStyles();

  return (
    <Typography className={cx('text-center', commonClasses.questionText)}>
      You answered all {questionCount} questions.
      <br />
      Good Job!
    </Typography>
  );
};

export default AssessmentParticipantAllAnswered;
