import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { muralApiClient } from 'utils/muralApiClient';
import * as Sentry from '@sentry/react';
// cr components
import Spinner from 'components/Spinner';
// library components
import { Canvas } from '@muraldevkit/mural-integrations-mural-canvas';
import { Typography, Box } from '@material-ui/core';
import cx from 'classnames';
// action
import { enqueueErrorSnackbar } from 'redux/actions/notifierActions';
// utils
import { parseMuralURL } from 'utils/muralApiClient';

import './mural-canvas.scss';

/**
 * Mural canvas for participants
 */
const MuralCanvas = ({ muralURL, isFacilitator }) => {
  const { state, id } = parseMuralURL(muralURL);
  const dispatch = useDispatch();
  const [canvasReady, setCanvasReady] = useState(false);
  const [loadCanvas, setLoadCanvas] = useState(true);

  const handleMessage = evt => {
    console.log(evt);

    if (evt.data?.type === 'mural.mural_unavailable') {
      handleMuralUnavailable(evt);
    }
  };

  const handleMuralUnavailable = evt => {
    const message = 'Mural board not found';
    setLoadCanvas(false);
    dispatch(enqueueErrorSnackbar('Mural board not found'));
  };

  const handleAccessDenied = () => {
    dispatch(enqueueErrorSnackbar('Mural access denied'));
  };

  const handleError = () => {
    dispatch(enqueueErrorSnackbar('Mural error'));
  };

  const handleCanvasReady = () => setCanvasReady(true);

  const canvasProps = {
    apiClient: muralApiClient,
    muralId: id,
    state,
    onVisitorAccessDenied: handleAccessDenied,
    onMuralUnavailable: handleMuralUnavailable,
    onGuestAccessDenied: handleAccessDenied,
    onMemberAccessDenied: handleAccessDenied,
    onError: handleError,
    onMessage: handleMessage,
    onReady: handleCanvasReady,
  };

  if (isFacilitator) {
    canvasProps.authUrl = `${window.origin}/admin/mural-session-activation`;
  }

  return (
    <Box width="100%">
      <div>
        {loadCanvas ? (
          <>
            {!canvasReady && (
              <div className="d-flex justify-content-center mt-12">
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <Typography className="mb-4" style={{ fontSize: 18 }}>
                    Fetching Mural Canvas
                  </Typography>
                  <Spinner />
                </div>
              </div>
            )}
            <div
              className={cx({
                invisible: !canvasReady,
              })}
            >
              <Canvas {...canvasProps} />
            </div>
          </>
        ) : (
          <div className="text-center mt-12 mural-error">
            <Typography>There was a problem loading the Mural board.</Typography>
          </div>
        )}
      </div>
    </Box>
  );
};

export default MuralCanvas;
