import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
// library components
import { makeStyles } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// cr components
import GridItem from 'components/Grid/GridItem';

const useStyles = makeStyles(theme => ({
  gridItem: {
    position: 'relative',
    width: 'fit-content',
    padding: '0 15px !important',
  },
  image: {
    width: ({ isSmallScreen }) => (isSmallScreen ? '100%' : 290),
    marginBottom: 20,
  },
  imageContainer: {
    position: 'absolute',
    top: 0,
    width: ({ isSmallScreen }) => (isSmallScreen ? '100%' : 290),
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
  },
  imageSelected: {
    outline: `3px solid ${theme.palette.primary.main}`,
  },
  circleIcon: {
    position: 'absolute',
    // left: 137,
    zIndex: 10,
    width: 50,
    height: 50,
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
  },
  checkIcon: {
    position: 'absolute',
    top: 7,
    left: 8,
    color: '#fff',
    fontSize: 35,
  },
}));

/**
 * Renders search image
 */
const SearchImages = ({ src, handleSelectedImageUrl, isSelected }) => {
  const isSmallScreen = useMediaQuery('(max-width: 767px)');
  const classes = useStyles({ isSmallScreen });
  const imageRef = useRef();
  const imageHeight = imageRef.current?.clientHeight;
  const imageWidth = imageRef.current?.clientWidth;

  return (
    <GridItem className={classes.gridItem}>
      <img
        ref={imageRef}
        className={cx(classes.image, isSelected && classes.imageSelected)}
        src={src}
        alt="search-lightning-demo"
        onClick={() => handleSelectedImageUrl(src)}
        crossOrigin="anonymous"
      />
      {isSelected && (
        <div
          className={classes.circleIcon}
          style={isSelected ? { top: imageHeight / 2 - 25, left: imageWidth / 2 - 8 } : {}}
        >
          <CheckIcon className={classes.checkIcon} />
        </div>
      )}
      <div
        className={cx(classes.imageContainer)}
        style={isSelected ? { height: imageHeight, width: imageWidth } : {}}
        onClick={() => handleSelectedImageUrl(src)}
      />
    </GridItem>
  );
};

SearchImages.propTypes = {
  src: PropTypes.string.isRequired, // image url
  handleSelectedImageUrl: PropTypes.func.isRequired, // handle select image
  isSelected: PropTypes.bool, // image is selected - true
};

export default SearchImages;
