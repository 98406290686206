import React from 'react';
import { useSelector } from 'react-redux';
import { size, each, isEmpty } from 'lodash';
// cr components
import Gift from 'components/Giveaway/Participant/Gift';
// library components
import { makeStyles } from '@material-ui/styles';
import { Typography, Box } from '@material-ui/core';
// selectors
import {
  useGiveawaySelector,
  getWinnerParticipant,
  getWinnerParticipants,
} from 'redux/selectors/giveawaySelectors';

const useStyles = makeStyles({
  itemText: {
    fontSize: 30,
    fontWeight: 300,
    textAlign: 'center',
  },
  text: {
    fontSize: 16,
    fontWeight: 300,
    textAlign: 'center',
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 'calc(100% + 40px)',
    height: '100%',
    margin: '0 -20px',
    overflow: 'hidden',
  },
});

/**
 * Giveaway view for the 'results' phase on the Participant side
 */
const GiveawayParticipantResults = () => {
  const classes = useStyles();

  const {
    config: { itemName, itemDescription, imageUrl },
    participantData: { giveawayParticipants },
  } = useGiveawaySelector();

  // get all winner participants
  const winnerParticipants = useSelector(getWinnerParticipants());

  // set winner text
  let winnersText = '';
  each(winnerParticipants, (participant, idx) => {
    if (idx + 1 === size(winnerParticipants)) {
      winnersText = `${winnersText} ${participant.participantName}`;
    } else {
      winnersText = `${winnersText} ${participant.participantName},`;
    }
  });

  let text =
    size(giveawayParticipants) === 1 ? '1 Winner!' : `${size(giveawayParticipants)} Winners!`;
  if (isEmpty(giveawayParticipants)) {
    text = 'Winners not selected';
  }

  return (
    <Box className={classes.box}>
      <Typography className={classes.text}>{text}</Typography>
      <Typography className={classes.itemText}>{winnersText}</Typography>
      <Gift
        image={imageUrl}
        isWinner={true}
        itemName={itemName}
        itemDescription={itemDescription}
      />
    </Box>
  );
};

export default GiveawayParticipantResults;
