import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { map, orderBy, size, filter, find, chunk } from 'lodash';
import firebase from 'firebase/app';
import 'firebase/storage';
import imageCompression from 'browser-image-compression';
// library components
import { makeStyles } from '@material-ui/core/styles';
// cr components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import NewCard from './Cards/NewCard';
import Card from './Cards/Card';
import Slider from './Dialogs/Slider';
import UploadImageDialog from 'components/Dialogs/EditCardSets/Dialogs/UploadImageDialog';
import UploadIconDialog from 'components/Dialogs/EditCardSets/Dialogs/UploadIconDialog';
// selectors
import { getSetCards } from 'redux/selectors/cardSetsSelectors';
// actions
import {
  createCard,
  deleteCard,
  editCardImage,
  editCardIcon,
  editCardText,
} from 'redux/actions/cardSetsActions';
// utils
import { generateRandomId } from 'utils/generateRandomId';

const useStyles = makeStyles(() => ({
  gridContainer: {
    width: 'calc(100% + 20px)',
    margin: '0 -5px',
  },
  gridItem: {
    padding: '0 5px !important',
  },
  sliderButton: {
    position: 'absolute',
    bottom: '-65px',
    left: 0,
  },
}));

const Cards = ({ setId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [cards, setCards] = useState();
  const [editCardId, setEditCardId] = useState('');
  const [isOpenUploadForm, setIsOpenUploadForm] = useState(false);
  const [isOpenUploadIconForm, setIsOpenUploadIconForm] = useState(false);

  const getCards = useCallback(async () => {
    const cardsOfSet = await getSetCards(setId);
    setCards(cardsOfSet);
  }, [setId]);

  useEffect(() => {
    getCards();
  }, [getCards]);

  // create card text. open text area for editing text
  const createCardText = (text, cardId) => {
    if (cardId) {
      dispatch(editCardText(setId, cardId, text));
      setCards(prev => ({
        ...prev,
        [cardId]: {
          ...prev[cardId],
          text: text,
        },
      }));
    } else {
      const id = generateRandomId();
      const order = size(cards) + 1 || 0;
      dispatch(createCard(setId, id, text, '', '', order));
      setCards(prev => ({
        ...prev,
        [id]: {
          cardId: id,
          text: text,
          order: order,
          imageUrl: '',
          icon: '',
        },
      }));
    }
  };

  // delete card from firestore. if card has image, delete image from storage
  const handleDeleteCard = cardId => {
    const deletedCard = find(cards, card => card.cardId === cardId);
    dispatch(
      deleteCard(setId, cardId, () => {
        if (deletedCard.imageUrl) {
          const deleteRef = firebase.storage().refFromURL(deletedCard.imageUrl);
          deleteRef.delete().catch(err => {
            console.log('Error delete image from firebase', err);
          });
        }
        setCards(prev => filter(prev, card => card.cardId !== cardId));
      }),
    );
  };

  // edit image/icon in card
  const handleEditCard = (cardId, type, action) => {
    setEditCardId(cardId);
    if (type === 'image') {
      handleUploadImage();
    }
    if (type === 'icon') {
      handleUploadIcon();
    }
    if (type === 'text') {
      if (action === 'addIcon') {
        handleUploadIcon();
      }
      if (action === 'deleteIcon') {
        handleDeleteIcon(cardId);
      }
    }
  };

  // open upload image dialog
  const handleUploadImage = () => {
    setIsOpenUploadForm(true);
  };

  // close upload image dialog
  const handleCloseUploadForm = () => {
    setIsOpenUploadForm(false);
  };

  // upload image to storage and create new card with image or change image if card exist
  const uploadCardImage = async file => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
    };

    try {
      const imageId = generateRandomId();
      const compressedFile = await imageCompression(file, options);
      const storageRef = firebase.storage().ref(`sets/${setId}/${imageId}`);

      await storageRef.put(compressedFile);
      const url = await storageRef.getDownloadURL();

      if (editCardId) {
        const editedCard = find(cards, card => card.cardId === editCardId);
        const deleteRef = firebase.storage().refFromURL(editedCard.imageUrl);
        deleteRef
          .delete()
          .then(() => {
            dispatch(editCardImage(setId, editCardId, url));
            setCards(prev => ({
              ...prev,
              [editCardId]: {
                ...prev[editCardId],
                imageUrl: url,
              },
            }));
          })
          .catch(err => {
            console.log('Error delete image from firebase', err);
          });
      } else {
        const id = generateRandomId();
        const order = size(cards) + 1 || 0;
        dispatch(createCard(setId, id, '', url, '', order));
        setCards(prev => ({
          ...prev,
          [id]: {
            cardId: id,
            text: '',
            order: order,
            imageUrl: url,
            icon: '',
          },
        }));
      }
    } catch (err) {
      console.log('Error upload image to firebase', err);
    }
  };

  // open upload icon dialog
  const handleUploadIcon = () => {
    setIsOpenUploadIconForm(true);
  };

  // close upload icon dialog
  const handleCloseUploadIcon = () => {
    setIsOpenUploadIconForm(false);
  };

  // upload icon
  const uploadIcon = icon => {
    if (editCardId) {
      dispatch(editCardIcon(setId, editCardId, icon));
      setCards(prev => ({
        ...prev,
        [editCardId]: {
          ...prev[editCardId],
          icon: icon,
        },
      }));
      setEditCardId('');
    } else {
      const id = generateRandomId();
      const order = size(cards) + 1 || 0;
      dispatch(createCard(setId, id, '', '', icon, order));
      setCards(prev => ({
        ...prev,
        [id]: {
          cardId: id,
          text: '',
          order: order,
          imageUrl: '',
          icon: icon,
        },
      }));
    }
  };

  // delete icon from card
  const handleDeleteIcon = cardId => {
    dispatch(editCardIcon(setId, cardId, ''));
    setCards(prev => ({
      ...prev,
      [cardId]: {
        ...prev[cardId],
        icon: '',
      },
    }));
    setEditCardId('');
  };

  const renderCards = pageCards =>
    map(pageCards, (card, idx) => {
      if (card === 'newCard') {
        return (
          <GridItem key={`card-${idx}`} className={classes.gridItem}>
            <NewCard
              createCardText={createCardText}
              handleUploadImage={handleUploadImage}
              handleUploadIcon={handleUploadIcon}
            />
          </GridItem>
        );
      } else {
        return (
          <GridItem key={`card-${card.cardId}`} className={classes.gridItem}>
            <Card
              text={card.text}
              handleDeleteCard={handleDeleteCard}
              handleEditCard={handleEditCard}
              handleCreateCardText={createCardText}
              cardId={card.cardId}
              icon={card.icon}
              image={card.imageUrl}
            />
          </GridItem>
        );
      }
    });

  const renderSlides = () => {
    const allCards = cards ? ['newCard', ...orderBy(cards, 'order', 'desc')] : ['newCard'];
    const pages = chunk(allCards, 8);
    return map(pages, (pageCards, idx) => (
      <GridContainer key={`page-${idx}`} className={classes.gridContainer}>
        {renderCards(pageCards)}
      </GridContainer>
    ));
  };

  return (
    <>
      <Slider
        slides={renderSlides()}
        buttonStyles={classes.sliderButton}
        additionalText={`${size(cards) || 0} cards`}
        isShowPages={true}
      />
      <UploadImageDialog
        isOpen={isOpenUploadForm}
        handleClose={handleCloseUploadForm}
        uploadCardImage={uploadCardImage}
      />
      <UploadIconDialog
        isOpen={isOpenUploadIconForm}
        handleClose={handleCloseUploadIcon}
        handleUploadIcon={uploadIcon}
      />
    </>
  );
};

Cards.propTypes = {
  setId: PropTypes.string, // current set id
};

export default Cards;
