import React, { useState, useLayoutEffect, useEffect } from 'react';
import PropTypes from 'prop-types';
import { chunk, floor, size } from 'lodash';
import cx from 'classnames';
// library components
import { makeStyles, IconButton } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import './slide.scss';

const useStyles = makeStyles({
  sliderContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  button: {
    position: 'relative',
    background: '#EEEEEE',
    color: '#898989',
    borderRadius: 5,
    width: 35,
    height: 35,
    zIndex: 10,
    '&:hover': {
      background: '#EEEEEE',
    },
    '&:disabled': {
      background: '#fff',
      color: '#CFCFCF',
      border: '1px solid #CFCFCF',
      pointerEvents: 'auto',
    },
  },
  icon: {
    fontSize: 17,
  },
  leftIcon: {
    position: 'absolute',
    left: 11,
  },
  slideContainer: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    marginLeft: 2,
  },
  nextSlide: {
    width: '100%',
    display: 'flex',
    position: 'absolute',
    left: '98%',
    transform: 'translate(-10%,0)',
  },
  gradient: {
    position: 'absolute',
    left: '100%',
    transform: 'translate(-100%,0)',
    width: 80,
    height: 40,
    background: 'linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 81.82%)',
  },
  slide: {
    display: 'contents',
  },
});

// on resize window change width and cound activities in slide
function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

/**
 * Gets the active activity and brings the progress tile in to view
 */
const getProgressIndex = (activeSlideNumber, activeSlides) => {
  let newIndex = 0;
  for (var i = 0; i < activeSlides.length; i++) {
    newIndex = newIndex + activeSlides[i].length;
    if (activeSlideNumber < newIndex) {
      return i;
    }
  }
};

/**
 * Renders the slider for images with different sizes and styles.
 */
const Slider = ({ slides }) => {
  const classes = useStyles();
  const [index, setIndex] = useState(0);
  const [activeSlides, setActiveSlides] = useState([slides]);

  const [ref, setRef] = useState();
  const [slidesRef, setSlidesRef] = useState();
  const countSlides = size(activeSlides);

  const [width] = useWindowSize();

  useEffect(
    () => {
      const activityWidth = 130;
      const slidesCount = floor(ref / activityWidth);
      setActiveSlides(chunk(slides, slidesCount));
      if (index === 0 && slidesRef < ref) {
        setActiveSlides([slides]);
      }
      const activeSlideElement = slides.find(slide => slide.props.activityStatus === 'active');
      const activeSlideNumber = activeSlideElement?.props?.slideNumber || 0;
      if (activeSlides.length > 1) {
        const progressIndex = getProgressIndex(activeSlideNumber, activeSlides, setIndex);
        setIndex(progressIndex);
      }
    },
    [width, slides, ref, countSlides],
    index,
  );

  // on next slide
  const slideRight = () => {
    setIndex(index + 1);
  };

  // on previous slide
  const slideLeft = () => {
    setIndex(index - 1);
  };

  return (
    <div className="w-100 d-flex flex-column align-items-center justify-content-between">
      <div className={cx(classes.sliderContainer, countSlides === 1 && 'justify-content-center')}>
        {countSlides > 1 && (
          <IconButton
            className={classes.button}
            id="slider-button"
            onClick={slideLeft}
            disabled={index === 0}
          >
            <ArrowBackIosIcon id="slider-button" className={cx(classes.icon, classes.leftIcon)} />
          </IconButton>
        )}
        <div
          className={cx(classes.slideContainer, countSlides === 1 && 'justify-content-center')}
          ref={newRef => setRef(newRef?.clientWidth)}
        >
          <div
            ref={newRef => setSlidesRef(newRef?.clientWidth)}
            className={cx('d-flex position-relative', index !== 0 && 'slide')}
          >
            <div className={classes.slide}>{countSlides > 0 && activeSlides?.[index]}</div>
            <div className={classes.slide}>{countSlides > 1 && activeSlides?.[index + 1]}</div>
            <div className={classes.slide}>{countSlides > 2 && activeSlides?.[index + 2]}</div>
            <div className={classes.slide}>{countSlides > 3 && activeSlides?.[index + 3]}</div>
          </div>
          {countSlides > 1 && <div className={classes.gradient} />}
        </div>
        {countSlides > 1 && (
          <IconButton
            className={classes.button}
            onClick={slideRight}
            disabled={index === countSlides - 1}
            id="slider-button"
          >
            <ArrowForwardIosIcon id="slider-button" className={classes.icon} />
          </IconButton>
        )}
      </div>
    </div>
  );
};

Slider.defaultProps = {
  slides: [],
};

Slider.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.node), // array of html element
};

export default Slider;
