import React from 'react';
import cx from 'classnames';

import { makeStyles } from '@material-ui/styles';
import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { useStaffingSelector } from 'redux/selectors/staffing/staffingSelectors';
import { useDispatch } from 'react-redux';
import { openDealInsightsDialog } from 'redux/actions/staffingActions';

const useStyles = makeStyles(theme => ({
  paperWidthSm: {
    maxWidth: 700,
    width: 700,
  },
  contentRoot: {
    padding: theme.spacing(5),
  },
  dialogHeader: {
    position: 'relative',
    maxWidth: 700,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dialogHeaderRoot: {
    padding: '24px 8px',
  },
  dialogHeaderTitle: {
    fontSize: 16,
    fontWeight: 500,
  },
  cancelButton: {
    position: 'absolute',
    right: theme.spacing(1),
  },

  statsContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  statsView: {
    flexGrow: 1,
    padding: '0px 15px',
    borderRight: '1px solid #D7D7D7',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 20,
  },
  statsViewLast: {
    flexGrow: 1,
    padding: '0px 15px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 20,
  },
  statsLabel: {
    textTransform: 'uppercase',
    fontSize: 11,
    fontWeight: '400',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  statsTitle: {
    fontSize: 24,
    fontWeight: '400',
    color: 'rgba(0, 0, 0, 0.87)',
  },

  barViewHeader: {
    height: 22,
    borderRadius: 11,
    display: 'flex',
    marginTop: '30px',
  },
  barViewHeaderText: {
    fontSize: 11,
    fontWeight: '400',
    color: 'rgba(0, 0, 0, 0.6)',
  },

  barView: {
    height: 22,
    borderRadius: 11,
    backgroundColor: '#E6E6E6',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
    marginBottom: '50px',
    display: 'flex',
  },
  barPieceBlank: {
    height: '100%',
    background: '#C8C8C8',
  },
  barPieceOrange: {
    height: '100%',
    background: '#E59A05',
    marginRight: 1,
  },
  barPieceGreen: {
    height: '100%',
    background: '#02A05C',
    marginRight: 1,
  },

  statusContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  statusView: {
    flexGrow: 1,
    marginBottom: 40,
  },
  statusPill: {
    height: 22,
    borderRadius: 99,
    padding: '0px 10px',
    fontSize: 12,
    fontWeight: '500',
    color: '#ffffff',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  statusPillOrange: {
    background: '#E59A05',
  },
  statusPillGreen: {
    background: '#02A05C',
  },
  statusTitle: {
    fontSize: 18,
    fontWeight: '400',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  statusSubTitle: {
    fontSize: 12,
    fontWeight: '400',
    color: 'rgba(0, 0, 0, 0.6)',
  },
}));

const DealInsightsDialog = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { isOpenDealInsightsDialog, dealInsightId } = useStaffingSelector();

  const closeDialog = () => {
    dispatch(openDealInsightsDialog(false));
  };

  return (
    <div>
      <Dialog
        open={isOpenDealInsightsDialog}
        onClose={closeDialog}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        classes={{
          paperWidthSm: classes.paperWidthSm,
        }}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle
          disableTypography
          className={classes.dialogHeader}
          classes={{ root: classes.dialogHeaderRoot }}
        >
          <Typography className={classes.dialogHeaderTitle}>Q4 Deal Insights</Typography>
          <IconButton className={classes.cancelButton} aria-label="close" onClick={closeDialog}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers className={classes.contentRoot}>
          <div className={classes.statsContainer}>
            <div className={classes.statsView}>
              <Typography className={classes.statsLabel}>Total Time</Typography>
              <Typography className={classes.statsTitle}>26.5 Days</Typography>
            </div>

            <div className={classes.statsView}>
              <Typography className={classes.statsLabel}>Deal Opened</Typography>
              <Typography className={classes.statsTitle}>09/28/22</Typography>
            </div>

            <div className={classes.statsView}>
              <Typography className={classes.statsLabel}>Projects Started</Typography>
              <Typography className={classes.statsTitle}>10/15/22</Typography>
            </div>

            <div className={classes.statsViewLast}>
              <Typography className={classes.statsLabel}>Projects Ended</Typography>
              <Typography className={classes.statsTitle}>11/15/22</Typography>
            </div>
          </div>

          <div className={classes.barViewHeader}>
            <div style={{ width: '15%' }}>
              <Typography className={classes.barViewHeaderText}>09/28</Typography>
            </div>
            <div style={{ width: '15%' }}>
              <Typography className={classes.barViewHeaderText}>09/28</Typography>
            </div>
            <div style={{ width: '20%' }}>
              <Typography className={classes.barViewHeaderText}>09/28</Typography>
            </div>
            <div style={{ width: '20%' }}>
              <Typography className={classes.barViewHeaderText}>09/28</Typography>
            </div>
            <div style={{ width: '10%' }}></div>
            <div style={{ width: '20%' }}>
              <Typography className={classes.barViewHeaderText}>09/28</Typography>
            </div>
          </div>
          <div className={classes.barView}>
            <div style={{ width: '15%' }} className={classes.barPieceOrange} />
            <div style={{ width: '15%' }} className={classes.barPieceOrange} />
            <div style={{ width: '20%' }} className={classes.barPieceOrange} />
            <div style={{ width: '20%' }} className={classes.barPieceGreen} />
            <div style={{ width: '10%' }} className={classes.barPieceBlank} />
            <div style={{ width: '20%' }} className={classes.barPieceGreen} />
          </div>

          <div className={classes.statusContainer}>
            <div className={classes.statusView}>
              <div className={cx(classes.statusPill, classes.statusPillOrange)}>Pending</div>
              <div>
                <Typography className={classes.statusTitle}>3 Days</Typography>
                <Typography className={classes.statusSubTitle}>12 hrs</Typography>
              </div>
            </div>

            <div className={classes.statusView}>
              <div className={cx(classes.statusPill, classes.statusPillOrange)}>Contract Sent</div>
              <div>
                <Typography className={classes.statusTitle}>3 Days</Typography>
                <Typography className={classes.statusSubTitle}>12 hrs</Typography>
              </div>
            </div>

            <div className={classes.statusView}>
              <div className={cx(classes.statusPill, classes.statusPillOrange)}>
                Ready to Invoice
              </div>
              <div>
                <Typography className={classes.statusTitle}>3 Days</Typography>
                <Typography className={classes.statusSubTitle}>12 hrs</Typography>
              </div>
            </div>

            <div className={classes.statusView}>
              <div className={cx(classes.statusPill, classes.statusPillOrange)}>
                Deposit Invoiced
              </div>
              <div>
                <Typography className={classes.statusTitle}>3 Days</Typography>
                <Typography className={classes.statusSubTitle}>12 hrs</Typography>
              </div>
            </div>

            <div className={classes.statusView}>
              <div className={cx(classes.statusPill, classes.statusPillGreen)}>In Progress</div>
              <div>
                <Typography className={classes.statusTitle}>3 Days</Typography>
                <Typography className={classes.statusSubTitle}>12 hrs</Typography>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DealInsightsDialog;
