import { isLoaded } from 'react-redux-firebase';
import { createSelector } from 'reselect';
import { map, orderBy, compact, sum, inRange, concat } from 'lodash';
import moment from 'moment';
import { formatPrice } from 'utils/formatPrice';
import { DEAL_STATUS_STATES } from 'constants/staffing';

const dealsSelector = state => state.firestore.data.deals;
const dealSelector = (state, id) => state.firestore.data.deals?.[id];
const companiesSelector = state => state.firestore.data.companies;
const projectsSelector = (state, id) => state.firestore.data.projects;

const staffingSelector = state => state.staffing;

/**
 * Selector for retrieving all Deals
 */
export const selectDeals = () => {
  return createSelector([dealsSelector, staffingSelector], (deals, staffing) => {
    if (!isLoaded(deals)) return { isLoaded: false };

    const filteredDeals = compact(
      map(deals, deal => {
        return filterOutDeals(deal, staffing);
      }),
    );

    return {
      deals: map(orderBy(filteredDeals, ['dealName'], ['asc']), deal => {
        return {
          ...deal,
        };
      }),
      isLoaded: true,
    };
  });
};

/**
 * Selector for retrieving the Deal Information from the Redux Store
 */
export const selectDeal = () => {
  return createSelector(
    [dealSelector, companiesSelector, projectsSelector],
    (deal, companies, projects) => {
      if (!deal || !companies) return null;

      const company = companies[deal.companyId];

      const { contractStartDate, contractEndDate, amount, statusUpdatedAt } = deal;

      const formattedStartDate = moment(contractStartDate).format('MM/DD/YYYY');
      const formattedEndDate = moment(contractEndDate).format('MM/DD/YYYY');

      const formattedAmount = `${formatPrice(amount)}`;

      let dealProjects = compact(deal.projects || []).map(projectId => {
        const project = projects ? projects[projectId] : null;
        return project;
      });

      // remove any falsey values
      dealProjects = compact(dealProjects);

      // sort dealProjects so that completed projects are at the end
      if (dealProjects.length) {
        const completedDealProjects = [];

        dealProjects = dealProjects.filter(project => {
          if (project.status === 'closed') {
            completedDealProjects.push(project);
            return false;
          }

          return true;
        });

        dealProjects = concat(dealProjects, completedDealProjects);
      }

      const projectsValuesSum = sum(map(dealProjects, project => project?.contractValue || 0));
      const amountLeft = amount - projectsValuesSum;
      const formattedAmountLeft = `${formatPrice(amountLeft)}`;

      const timeSpentInCurrentStatus = moment(statusUpdatedAt).fromNow();

      return {
        ...deal,
        companyName: company?.companyName || '',
        company,
        formattedStartDate,
        formattedEndDate,
        formattedAmount,
        formattedAmountLeft,
        amountLeft,
        projectsValuesSum,
        projects: compact(dealProjects),
        timeSpentInCurrentStatus,
      };
    },
  );
};

/**
 * Selector for retrieving all Companies
 */
export const selectCompanies = () => {
  return createSelector([companiesSelector], companies => {
    if (!isLoaded(companies)) return { companies: [], isLoaded: false };

    return {
      companies: map(orderBy(companies, ['companyName'], ['asc']), company => {
        return {
          name: company.companyName,
          value: company.id,
        };
      }),
      isLoaded: true,
    };
  });
};

/**
 *
 * @param {*} staffing
 * @param {*} deal
 * @returns
 */
export const filterOutDeals = (deal, staffing) => {
  //Check if Filter is set,
  //And if set, and the property don't match with the Filter, skip the deal

  const { dealsCompany, dealsPrice, dealsStatus } = staffing;

  if (!deal) {
    return null;
  }

  // Only show closed deals when filtering on closed.....
  // This removes closed from teh all view..
  if (dealsStatus !== DEAL_STATUS_STATES.CLOSED && deal.status === DEAL_STATUS_STATES.CLOSED) {
    return null;
  }

  if (dealsCompany && deal.companyId !== dealsCompany) {
    return null;
  }

  if (dealsStatus && deal.status !== dealsStatus) {
    return null;
  }

  if (dealsPrice && !inRange(Number(deal.amount), Number(dealsPrice[0]), Number(dealsPrice[1]))) {
    return null;
  }

  return deal;
};
