import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useReactToPrint } from 'react-to-print';
import { includes, find, filter } from 'lodash';
import { useSnackbar } from 'notistack';
import { useFirestore } from 'react-redux-firebase';
// library components
import { Typography, Divider, Box, MenuItem } from '@material-ui/core';
import { useConfirm } from 'material-ui-confirm';
// cr components
import CrLogo from 'components/CrLogo/CrLogo';
import ActivitySettingsForm from 'components/Forms/ActivitySettingsForm';
import SendToLiveScribingForm from 'components/Forms/SendToLiveScribingForm';
import AddActivityForm from 'components/Forms/AddActivityForm';
import FormDialog from 'components/Dialogs/FormDialog';
import ActivityMenu from 'components/ActivityMenu/ActivityMenu';
import EditableTextWrapper from 'components/EditableTextWrapper';
import SmallLabel from 'components/SmallLabel';
import AdminSendToParticipantsActivity from 'components/AdminActivityTemplate/AdminSendToParticipantsActivity';
import RecapDeckDialog from 'components/Dialogs/RecapDeck';
// config
import { ACTIVITY_BEHAVIORS as BEHAVIORS } from 'constants/activityBehaviors';
// utils
import { deleteActivity } from 'utils/deleteActivity';
import { resetActivity } from 'utils/resetActivity';
import { copyActivityOutput } from 'utils/copyActivityOutput';
import { sendResultsToLiveScribing } from 'utils/sendResultsToLiveScribing';
// selectors
import { useFacilitatorSessionSelector } from 'redux/selectors/facilitatorSessionSelectors';

const useActivityNameStyles = makeStyles(theme => ({
  root: {
    fontSize: 18,
    color: theme.palette.primary.main,
  },
}));

/**
 * Renders the template that contains an activity on the Facilitator side.
 * Provides all of the activity header items including the actions menu
 */
const AdminActivityTemplate = ({
  activityConfig,
  activityData,
  additionalMenuItems,
  CurrentView,
  children,
  session,
}) => {
  const [recapDeckDialogOpen, setRecapDeckDialogOpen] = useState(false);
  const [settingsDialogOpen, setSettingsDialogOpen] = useState(false);
  const [sendToLivescribeDialogOpen, setSendToLivescribeDialogOpen] = useState(false);
  const [isAddActivityDialogOpen, setIsAddActivityDialogOpen] = useState(false);
  const [sendToLivescribeOuput, setSendToLivescribeOuput] = useState('');
  const { collection, niceName } = activityConfig;
  const { name, id } = activityData;
  const activityNameClasses = useActivityNameStyles();
  const printRef = useRef();
  const confirm = useConfirm();
  const sessionActivity = find(session.activities, { id: id });
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const activeParticipantRoute = session.activeRoute;
  const { selectedFacilitatorRoute } = useSelector(state => state.facilitatorSession);
  const { uid } = useSelector(state => state.firebase.auth);
  const { isInternalUser } = useFacilitatorSessionSelector();
  const hasRecapDeckDialog =
    isInternalUser && includes(activityConfig.behaviors, BEHAVIORS.recapDeck);

  const liveScribeActivities = filter(session.activities, sessionActivity => {
    return sessionActivity.activity === 'liveScribing';
  });

  const onPrint = useReactToPrint({
    content: () => printRef.current,
  });

  const toggleSettingsDialog = () => {
    setSettingsDialogOpen(!settingsDialogOpen);
  };

  const toggleRecapDeckDialog = () => {
    setRecapDeckDialogOpen(prev => !prev);
  };

  const toggleSendToLivescribeDialog = () => {
    setSendToLivescribeDialogOpen(!sendToLivescribeDialogOpen);
  };

  const toggleAddActivityDialog = () => {
    setIsAddActivityDialogOpen(prev => !prev);
  };

  const onDelete = () => {
    confirm({ description: 'This action is permanent!' }).then(() => {
      deleteActivity(session.id, sessionActivity.activity, id, history, enqueueSnackbar);
    });
  };

  const onReset = () => {
    confirm({
      description:
        "You are about to remove all participant data from this activity and reset it to it's default state.  Are you sure?",
    }).then(() => {
      resetActivity(sessionActivity.activity, id, true);
    });
  };

  const onCopyOutput = () => {
    copyActivityOutput(sessionActivity.activity, id);
  };

  const onSendOutputToLivescribe = async () => {
    const output = await copyActivityOutput(sessionActivity.activity, id, true);

    if (!liveScribeActivities.length) {
      setSendToLivescribeOuput(output);
      toggleAddActivityDialog();
    }

    if (liveScribeActivities.length === 1) {
      const liveScribeActivity = liveScribeActivities[0];

      sendResultsToLiveScribing(liveScribeActivity, uid, output);
    }

    if (liveScribeActivities.length > 1) {
      setSendToLivescribeOuput(output);
      toggleSendToLivescribeDialog();
    }
  };

  const renderMenuItems = () => {
    const items = [...additionalMenuItems];

    if (includes(activityConfig.behaviors, BEHAVIORS.settings)) {
      items.push(<MenuItem onClick={toggleSettingsDialog}>Settings</MenuItem>);
    }

    if (
      activityData.state.activeView === 'results' &&
      includes(activityConfig.behaviors, BEHAVIORS.print)
    ) {
      items.push(<MenuItem onClick={onPrint}>Print</MenuItem>);
    }

    if (includes(activityConfig.behaviors, BEHAVIORS.delete)) {
      items.push(<MenuItem onClick={onDelete}>Delete</MenuItem>);
    }

    if (includes(activityConfig.behaviors, BEHAVIORS.reset)) {
      items.push(<MenuItem onClick={onReset}>Reset</MenuItem>);
    }

    if (
      activityData.state.activeView === 'results' &&
      includes(activityConfig.behaviors, BEHAVIORS.copyOutput)
    ) {
      items.push(<MenuItem onClick={onCopyOutput}>Copy Results</MenuItem>);

      // must have the copyOuput AND sendOutputToLivescribe behviours
      if (
        activityData.state.activeView === 'results' &&
        includes(activityConfig.behaviors, BEHAVIORS.sendOutputToLivescribe)
      ) {
        items.push(
          <MenuItem onClick={onSendOutputToLivescribe}>Send Results to Live Scribing</MenuItem>,
        );
      }
    }

    if (hasRecapDeckDialog && sessionActivity) {
      items.push(<MenuItem onClick={toggleRecapDeckDialog}>Add Recap Images</MenuItem>);
    }

    return items;
  };

  return (
    <>
      <div className="admin-activity-template">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <SmallLabel text={niceName} className="mb-0" />
            <EditableTextWrapper
              initialValue={name}
              fieldType="text"
              fieldName="name"
              firestoreCollection={collection}
              documentId={id}
              isActivityName
            >
              <Typography classes={activityNameClasses} className="mb-1">
                {name}
              </Typography>
            </EditableTextWrapper>
          </div>
          <ActivityMenu activityId={id} additionalMenuItems={renderMenuItems()} />
        </div>
        <Divider className="mb-2" />
        <div ref={printRef}>
          <Box display="none" displayPrint="block" mb={4} className="text-center">
            <div className="mb-2">
              <CrLogo />
            </div>
            <Typography variant="h5" className="mb-1 text-center">
              {session.topic}: {niceName} - {name}
            </Typography>
          </Box>
          <CurrentView />
        </div>
        {activeParticipantRoute !== selectedFacilitatorRoute && (
          <AdminSendToParticipantsActivity
            session={session}
            activeFacilitatorRoute={selectedFacilitatorRoute}
          />
        )}
      </div>
      <FormDialog
        handleClose={toggleSettingsDialog}
        isOpen={settingsDialogOpen}
        title={`${niceName} Settings`}
      >
        <ActivitySettingsForm
          activityConfig={activityConfig}
          activityData={activityData}
          closeDialog={toggleSettingsDialog}
        />
      </FormDialog>
      {activityData.state.activeView === 'results' &&
        includes(activityConfig.behaviors, BEHAVIORS.copyOutput) &&
        includes(activityConfig.behaviors, BEHAVIORS.sendOutputToLivescribe) && (
          <FormDialog
            handleClose={toggleSendToLivescribeDialog}
            isOpen={sendToLivescribeDialogOpen}
            title="Send Results to Live Scribing"
          >
            <Typography className="mb-3">
              Select which Live Scribing activity in this meeting you'd like the results sent to.
            </Typography>
            <SendToLiveScribingForm
              sessionId={session.id}
              liveScribeActivities={liveScribeActivities}
              activityOutput={sendToLivescribeOuput}
              closeDialog={toggleSendToLivescribeDialog}
            />
          </FormDialog>
        )}
      {activityData.state.activeView === 'results' &&
        includes(activityConfig.behaviors, BEHAVIORS.copyOutput) &&
        includes(activityConfig.behaviors, BEHAVIORS.sendOutputToLivescribe) && (
          <FormDialog
            handleClose={toggleAddActivityDialog}
            isOpen={isAddActivityDialogOpen}
            title="Add Activity"
          >
            <AddActivityForm
              isAddOneActivity={true}
              activityTemplateActivity="liveScribing"
              activityStateInitalValues={{
                state: {
                  liveScribingText: sendToLivescribeOuput,
                },
              }}
              closeDialog={toggleAddActivityDialog}
            />
          </FormDialog>
        )}
      {hasRecapDeckDialog && sessionActivity && (
        <RecapDeckDialog
          isOpen={recapDeckDialogOpen}
          handleClose={toggleRecapDeckDialog}
          sessionId={session.id}
          activities={session.activities}
          recapDeck={session?.recapDeck}
          onlyImages
          sessionActivity={sessionActivity}
        />
      )}
    </>
  );
};

AdminActivityTemplate.defaultProps = {
  additionalMenuItems: [],
};

AdminActivityTemplate.propTypes = {
  activityConfig: PropTypes.object.isRequired,
  activityData: PropTypes.object.isRequired,
  additionalMenuItems: PropTypes.array,
  CurrentView: PropTypes.func.isRequired,
  session: PropTypes.object.isRequired,
  children: PropTypes.node,
};

export default AdminActivityTemplate;
