import React from 'react';
import PropTypes from 'prop-types';
import { useFirebase } from 'react-redux-firebase';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
// library components
import { Formik, Form } from 'formik';
import { Button, Typography } from '@material-ui/core';
// cr components
import ButtonSpinner from 'components/ButtonSpinner/ButtonSpinner';
// actions
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from 'redux/actions/notifierActions';
import CustomSelect from 'components/CustomFormComponents/CustomSelect';
import * as Sentry from '@sentry/react';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

/**
 * Form for re-sending project cohort messages
 */
const SendProjectCohortMessageForm = ({ closeDialog, projectId, messageOptions }) => {
  const dispatch = useDispatch();
  const authUser = useSelector(state => state.firebase.auth);
  const firebase = useFirebase();

  const formSchema = Yup.object().shape({
    messageId: Yup.string().required('Required'),
  });

  return (
    <Formik
      initialValues={{
        displayName: authUser.displayName,
      }}
      validationSchema={formSchema}
      onSubmit={async (values, { resetForm }) => {
        return firebase
          .functions()
          .httpsCallable('projects-onCall_sendCohortMessage')({
            projectId,
            messageId: values.messageId,
          })
          .then(res => {
            closeDialog();
            resetForm();
            dispatch(
              enqueueSuccessSnackbar(
                'Sending cohort message. If something goes wrong an error will be sent to Slack.',
              ),
            );
          })
          .catch(err => {
            dispatch(enqueueErrorSnackbar('Error scheduling cohort message send'));
            Sentry.captureException(err);
            console.error(err);
          });
      }}
    >
      {({ isSubmitting, submitForm, resetForm }) => (
        <Form style={{ paddingBottom: 20 }}>
          <GridContainer>
            <GridItem>
              <Typography color="textSecondary" className="mb-4">
                Cohrt messages are automatically sent every hour according the project data and
                messaging config. Generally, you'll only use this form to re-send cohort messages
                that should have already been sent to Circle. If you don't see any message options
                below, try re-syncing them to the project via the drop down menu at the top right of
                the project details. If you still don't see any message options after re-syncing the
                messaging config it's most likely that there are no messages in the config for this
                project's Service/Methodology combo. If a message is sent via this form before it
                would normally be sent via the message scheduler, it will not be sent again via the
                scheduler.
              </Typography>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12}>
              <CustomSelect
                name="messageId"
                label="Message"
                selectOptions={messageOptions}
                className="mb-4"
                required
                placeholder="Select a message"
                disabled={!messageOptions.length}
              />
            </GridItem>
          </GridContainer>
          <div className="form-dialog-buttons">
            <Button
              variant="outlined"
              color="primary"
              disabled={isSubmitting}
              onClick={() => {
                closeDialog();
                resetForm();
              }}
            >
              CANCEL
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={submitForm}
            >
              SEND
              {isSubmitting && <ButtonSpinner />}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

SendProjectCohortMessageForm.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  messageOptions: PropTypes.array.isRequired,
};

export default SendProjectCohortMessageForm;
