import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { isEmpty, find, each, keys } from 'lodash';

// import NarrowingAdminCategorize from 'components/Narrowing/Admin/NarrowingAdminCategorize';
// import NarrowingAdminReview from 'components/Narrowing/Admin/NarrowingAdminReview';
import NarrowingAdminPrimaryCategorize from 'components/Narrowing/Admin/NarrowingAdminPrimaryCategorize';
import NarrowingAdminResults from 'components/Narrowing/Admin/NarrowingAdminResults';
import AdminActivityTemplate from 'components/AdminActivityTemplate';

import { useNarrowingSelector } from 'redux/selectors/narrowingSelectors';
import { getActiveSet } from 'redux/selectors/cardSetsSelectors';

import { createCard, deleteCards, updateCardsCount } from 'redux/actions/narrowingActions';

import { NARROWING_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';

const views = {
  // categorize: NarrowingAdminCategorize,
  // review: NarrowingAdminReview,
  primaryCategorize: NarrowingAdminPrimaryCategorize,
  results: NarrowingAdminResults,
};

const AdminNarrowing = () => {
  const { activityId } = useParams();
  const dispatch = useDispatch();
  const narrowing = useNarrowingSelector();
  const cardSet = narrowing?.config?.cardSet;
  const initialCardsCount = narrowing?.config?.initialCardsCount;
  const cards = narrowing?.participantData?.cards;
  const round = narrowing?.state?.round;
  const narrowingId = narrowing?.id;
  const cardsCount = narrowing?.state?.cardsCount;
  const session = useSelector(state => state.firestore.data.session);
  const meetingParticipants = useSelector(state => state.firestore.data.participants);
  const cardDeckCount = keys(cards).length;

  // hooks up a firestore listener to Redux
  useFirestoreConnect(() => [
    {
      collection: 'narrowing',
      doc: `${activityId}`,
      storeAs: 'narrowing',
    },
  ]);

  // set up cards for the selected cardSet
  const getCards = useCallback(async () => {
    const set = await getActiveSet(cardSet);
    each(set.cards, card => {
      dispatch(
        createCard(
          activityId,
          cardSet,
          card.order,
          card.icon || '',
          card.text || '',
          card.imageUrl || '',
        ),
      );
    });
  }, [cardSet, activityId]);

  /**
   * Check if we have a card deck.  If not,
   * call getCards to set it up
   */
  useEffect(() => {
    if (cardDeckCount === 0 && cardSet && round === 1 && activityId === narrowingId) {
      getCards();
    }
  }, [getCards, cardDeckCount, cardSet, activityId, narrowingId]);

  useEffect(() => {
    if (!cardsCount && round === 1) {
      dispatch(updateCardsCount(activityId, initialCardsCount));
    }
  }, [cardsCount, round]);

  // ensure all of the data we need is loaded
  if (
    !isLoaded(session) ||
    isEmpty(session) ||
    !isLoaded(narrowing) ||
    isEmpty(narrowing) ||
    !isLoaded(meetingParticipants) ||
    activityId !== narrowing?.id ||
    !cardDeckCount ||
    (!cardsCount && round === 1)
  ) {
    return null;
  }

  // get current view
  const CurrentView = views[narrowing.state.activeView];

  return (
    <AdminActivityTemplate
      activityConfig={CONFIG}
      activityData={narrowing}
      session={session}
      CurrentView={CurrentView}
    />
  );
};

export default AdminNarrowing;
