import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as Sentry from '@sentry/react';
import { getFirebase } from 'react-redux-firebase';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';

import LoginForm from '../form';
import { getLoginEmail, clearLoginEmail } from '../../../utils/login';

import './index.css';

/**
 * Performs the Email Link Verification with Firebase. If we don't have an email stored in localStorage, then we need
 * to collect it here
 */
const LoginVerification = () => {
  const [loading, setLoading] = useState(false);
  const [showConfirmEmail, setShowConfirmEmail] = useState(false);
  const firebase = getFirebase();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const auth = useSelector(state => state.firebase.auth);

  // Attempt to Auto-verify the user when this component loads
  useEffect(() => {
    const email = getLoginEmail();
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      if (email) {
        signInWithEmail(email);
      } else {
        setShowConfirmEmail(true);
      }
    } else {
      history.push('/auth/login-page');
    }
  }, []);

  // Listen to Auth state and redirect once ready
  useEffect(() => {
    if (auth.isLoaded && !auth.isEmpty && !auth.isAnonymous) {
      history.push('/admin');
    }
  }, [auth.isLoaded, auth.isEmpty]);

  const signInWithEmail = email => {
    firebase
      .auth()
      .signInWithEmailLink(email, window.location.href)
      .then(() => clearLoginEmail())
      .catch(e => {
        setLoading(false);
        console.error(e);
        Sentry.captureException(e);

        let message = e.message;

        if (e.code === 'auth/network-request-failed') {
          message +=
            ' If your internet connection is currently active, your network administrator may be blocking requests to googleapis.com/identitytoolkit';
        }

        enqueueSnackbar(message, { variant: 'error', autoHideDuration: 10000 });

        history.push('/auth/login-page/');
      });
  };

  return (
    <div>
      <div className="login-header">
        <Typography variant="h6" component="h2">
          {!showConfirmEmail ? 'Please wait...' : 'Confirm email'}
        </Typography>
      </div>
      {!showConfirmEmail ? (
        <div className="login-verify-spinner">
          <CircularProgress size={45} />
        </div>
      ) : (
        <LoginForm
          loading={loading}
          loginButtonLabel="Confirm Email"
          helperText="Confirm your email to complete sign in."
          onSubmit={values => {
            setLoading(true);
            signInWithEmail(values.email);
          }}
        />
      )}
    </div>
  );
};

export default LoginVerification;
