import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
// library components
import { Box, TextField, FormControl, Typography } from '@material-ui/core';
// actions
import { updateSketchComments } from 'redux/actions/heatMappingActions';

const useStyles = makeStyles(theme => ({
  textField: {
    margin: `${theme.spacing(2)}px 0`,
  },
  textFieldContainer: {
    flex: '1 1 470px',
    margin: `0 ${theme.spacing(2)}px`,
  },
  formAreaInput: {
    padding: 15,
    borderRadius: 0,
    fontSize: 14,
    color: '#000000',
  },
}));

/**
 * Renders Heat-Mapping comments view
 */
const HeatMappingComments = ({ id, context, currentSketch, bigIdeas, concerns }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [bigIdeasComments, setBigIdeasComments] = useState(bigIdeas);
  const [concernsComments, setConcernsComments] = useState(concerns);
  const [startTime, setStartTime] = useState('');

  useEffect(() => {
    setBigIdeasComments(bigIdeas ? bigIdeas : '');
    setConcernsComments(concerns ? concerns : '');
  }, [bigIdeas, concerns, currentSketch]);

  const handleChangeSketchComment = event => {
    if (event.target.id === 'bigIdeas') {
      setBigIdeasComments(event.target.value);
    } else {
      setConcernsComments(event.target.value);
    }
    if (!startTime) {
      const time = moment().add(2, 'seconds').format();
      setStartTime(time);
    }
  };

  const handleSaveSketchComment = () => {
    setStartTime('');
    dispatch(updateSketchComments(id, currentSketch, bigIdeasComments, concernsComments));
  };

  if (moment(startTime).unix() <= moment().unix()) {
    handleSaveSketchComment();
  }

  return (
    <Box className="px-2" display="flex" justifyContent="space-around" flexWrap="wrap">
      <FormControl className={classes.textFieldContainer}>
        <Typography variant="body2" color="textSecondary">
          Big Ideas
        </Typography>
        <TextField
          id="bigIdeas"
          className={classes.textField}
          placeholder="Add big ideas"
          multiline
          variant="filled"
          rows={3}
          value={bigIdeasComments}
          onChange={handleChangeSketchComment}
          onBlur={handleSaveSketchComment}
          InputProps={{
            className: classes.formAreaInput,
          }}
          disabled={context === 'review'}
        />
      </FormControl>
      <FormControl className={classes.textFieldContainer}>
        <Typography variant="body2" color="textSecondary">
          Concerns
        </Typography>
        <TextField
          id="concerns"
          className={classes.textField}
          placeholder="Add concerns"
          multiline
          variant="filled"
          rows={3}
          value={concernsComments}
          onChange={handleChangeSketchComment}
          onBlur={handleSaveSketchComment}
          InputProps={{
            className: classes.formAreaInput,
          }}
          disabled={context === 'review'}
        />
      </FormControl>
    </Box>
  );
};

HeatMappingComments.propTypes = {
  currentSketch: PropTypes.string, // current sketch id
  id: PropTypes.string,
  sketchComments: PropTypes.object, // all facilitator comments for current sketch
  context: PropTypes.string, // state phase
};

export default HeatMappingComments;
