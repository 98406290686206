import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import cx from 'classnames';
// library components
import { Button, Box, Typography } from '@material-ui/core';
// cr components
import FormDialog from 'components/Dialogs/FormDialog';
import Rotate90DegreesCcwIcon from '@material-ui/icons/Rotate90DegreesCcw';

const useStyles = makeStyles(theme => ({
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: 3,
  },
  image: {
    width: 600,
    height: 600,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  },
  imageToRotate: {
    margin: 'auto',
    objectFit: 'contain',
    height: '100%',
    width: '100%',
    maxHeight: '45vh',
  },
  rotateButton: {
    height: 40,
    minWidth: 40,
    marginBottom: '0 !important',
    marginRight: 'auto',
    borderRadius: '50%',
    background: theme.palette.primary.main,
    color: '#fff',
    boxShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
    fontSize: 14,
    '&:hover': {
      background: theme.palette.primary.main,
      opacity: 0.9,
    },
  },
  formDialog: {
    marginTop: '4vh !important',
    justifyContent: 'space-between !important',
    width: '100%',
  },
  'ReactCrop__drag-handle': {
    width: 50,
    height: 50,
  },
}));

const useCropStyle = makeStyles(() => ({
  handle: {
    '&::after': {
      position: 'absolute',
      content: '',
      display: 'block',
      width: 20,
      height: 20,
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
      border: '1px solid rgba(255, 255, 255, 0.7)',
      boxSizing: 'border-box',
      outline: '1px solid transparent',
    },
  },
}));

/**
 * Renders modal form for crop participant image
 */
const CropImageForm = ({
  closeDialog,
  isOpen,
  fileUrl,
  uploadNewImage,
  id,
  cropParam,
  /**
   * Setting the defualt pixelRatio to 4 because the original image is
   * getting severely downsized during the crop. This helps the
   * final quality in larger views substantially. Peviously this
   * was being set to window.devicePixelRatio which didn't make
   * much sense
   */
  pixelRatio = 4,
}) => {
  const classes = useStyles();
  const cropStyle = useCropStyle();
  const [crop, setCrop] = useState(
    cropParam
      ? cropParam
      : {
          unit: '%',
          width: 60,
          height: 60,
        },
  );

  //Keeps Track of when the user is done cropping and starts Rotating the Image...
  const [startsRotating, setStartsRotating] = useState(false);
  const [rotateImgUrl, setRotateImgUrl] = useState(''); //Holds the Image URL that 's updated when the Image is Rotated

  useEffect(() => {
    if (fileUrl) {
      document.body.style.overflow = 'hidden';
    }
    setCrop(cropParam);
  }, [fileUrl]);

  //When the CropForm is opened, sets the 'startRotating' to false (back to Crop Section)
  useEffect(() => {
    setStartsRotating(false);
  }, [isOpen]);

  const imgRef = useRef(null);

  // Passes the image DOM element
  const onImageLoaded = image => {
    imgRef.current = image;
  };

  /**
   * Get croped image in blob format
   *
   * @param {HTMLImageElement} image - Image File Object
   * @param {Object} crop - crop Object
   * @param {String} fileName - Name of the returned file in Promise
   */
  const getCroppedImg = async (image, crop, fileName) => {
    const canvas = document.createElement('canvas');

    // Leaving these here for future debugging...
    // console.log('crop', crop);
    // console.log('image naturalWidth', image.naturalWidth);
    // console.log('image width', image.width);
    // console.log('image naturalHeight', image.naturalHeight);
    // console.log('image height', image.height);

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = (crop.width ? crop.width : image.width) * pixelRatio;
    canvas.height = (crop.height ? crop.height : image.height) * pixelRatio;
    const ctx = canvas.getContext('2d');

    // Leaving these here for future debugging...
    // console.log('scaleX', scaleX);
    // console.log('scaleY', scaleY);
    // console.log('canvas.width', canvas.width);
    // console.log('canvas.height', canvas.height);

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);

    ctx.drawImage(
      image,
      (crop.width ? crop.x : 0) * scaleX,
      (crop.height ? crop.y : 0) * scaleY,
      (crop.width ? crop.width : image.width) * scaleX,
      (crop.height ? crop.height : image.height) * scaleY,
      0,
      0,
      crop.width ? crop.width : image.width,
      crop.height ? crop.height : image.height,
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        blob.name = fileName;
        resolve(URL.createObjectURL(blob)); //creates a URL to display the Cropped Image in the Rotate Section
      }, 'image/jpeg');
    });
  };

  const handleRotateUploadedImage = () => {
    if (!startsRotating) {
      return;
    }

    const TO_RADIANS = Math.PI / 180;
    const DEGREES_TO_ROTATE = 90;

    //Create an Image Object
    var img = new Image();

    //when the Image is loaded, rotate the Image...
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      //Sets the Canvas Dimensions to the Image Dimensions
      canvas.height = img.naturalWidth;
      canvas.width = img.naturalHeight;

      ctx.translate(canvas.width, 0);

      // Rotate the Canvas by converting from Degrees to Radians
      ctx.rotate(DEGREES_TO_ROTATE * TO_RADIANS);

      //Place the Image on the Rotated Canvas
      ctx.drawImage(img, 0, 0);

      canvas.toBlob(blob => {
        setRotateImgUrl(URL.createObjectURL(blob)); //sets the Image Source to the Rotated Image
      });
    };
    img.src = rotateImgUrl; //Set the Image Source to the FileUrl
  };

  // Set new crop if he changed
  const onCropChange = newCrop => {
    setCrop(newCrop);
  };

  //When in Crop Section, get the Cropped Image, and go to Rotate Section
  //When in Rotate Section, get the Blob from the innerFileURL, and save the Image
  const onSubmit = async () => {
    if (!startsRotating) {
      const croppedImg = await getCroppedImg(imgRef.current, crop);
      setRotateImgUrl(croppedImg);
      setStartsRotating(true);
    } else {
      let blob = await fetch(rotateImgUrl).then(r => r.blob());
      uploadNewImage(blob, id);
      document.body.style.overflow = 'visible';
      closeDialog();
    }
  };

  return (
    <FormDialog handleClose={closeDialog} isOpen={isOpen} title={'Upload Image'}>
      <Box className={classes.formContainer}>
        {!startsRotating ? (
          <ReactCrop
            src={fileUrl}
            crop={crop}
            onImageLoaded={onImageLoaded}
            onChange={onCropChange}
            imageStyle={{
              objectFit: 'contain',
              maxHeight: '45vh',
              backgroundColor: 'rgba(183, 194, 200, 0.3)',
            }}
            crossorigin="anonymous"
          />
        ) : (
          <img src={rotateImgUrl} alt="" className={classes.imageToRotate} />
        )}

        {!startsRotating && (
          <div>
            <Typography className="mt-2 mb-0">
              If desired, use your mouse to select an area to crop
            </Typography>
          </div>
        )}

        <div className={cx(classes.formDialog, 'form-dialog-buttons')}>
          <Button variant="outlined" color="primary" onClick={closeDialog}>
            CANCEL
          </Button>

          {/* Only Display the Rotate Button when User is done with Cropping, and starts Rotating */}
          {startsRotating && (
            <Box>
              <Button onClick={handleRotateUploadedImage} className={classes.rotateButton}>
                <Rotate90DegreesCcwIcon />
              </Button>
              <Typography style={{ marginTop: -16, marginLeft: -4 }} className="mb-2 mb-sm-0">
                Rotate
              </Typography>
            </Box>
          )}

          <Button variant="contained" color="primary" onClick={onSubmit}>
            {!startsRotating ? 'NEXT' : 'SAVE'}
          </Button>
        </div>
      </Box>
    </FormDialog>
  );
};

CropImageForm.propTypes = {
  closeDialog: PropTypes.func, // close dialog form
  isOpen: PropTypes.bool,
  fileUrl: PropTypes.string, // image url
  uploadNewImage: PropTypes.func, // upload new participant image
  id: PropTypes.string, // parent id
};

export default CropImageForm;
