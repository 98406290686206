import React from 'react';
import { useSelector } from 'react-redux';
// library components
import { Box } from '@material-ui/core';
// cr components
import Results2510List from 'components/25:10/Shared/Results2510List';
import AdminActivityAuxView from 'components/AdminActivityAuxView';
import CSVReportDownloadButton from 'components/CSVReportDownloadButton';
// selectors
import { getResults, getName, getActiveView } from 'redux/selectors/25:10/root2510Selectors';
// utils
import { create2510ReportHeader, create2510ReportData } from 'utils/25:10/report';

/**
 * Renders options for the 'results' phases on the Facilitator side
 */
const Admin2510Review = () => {
  const name = useSelector(getName);
  const ideas = useSelector(getResults);
  const activeView = useSelector(getActiveView);

  const session = useSelector(state => state.firestore.data.session);

  if (activeView !== 'results') {
    return null;
  }

  // render headers for csv report
  const headers = create2510ReportHeader();

  // render data for csv report
  const csvReport = create2510ReportData(ideas);

  return (
    <AdminActivityAuxView
      titleText="Top Voted Ideas"
      allowPrint={activeView === 'results'}
      activityName={name}
      hasDownloadButton={activeView === 'results'}
      renderDownloadButton={() => (
        <CSVReportDownloadButton
          headers={headers}
          csvReport={csvReport}
          buttonText="Download Results"
          filename="Results.csv"
          topic={session.topic}
          activity="2510"
          activityName={name}
          withoutLabel={true}
        />
      )}
    >
      <Box displayPrint="none">
        <Results2510List ideas={ideas} />
      </Box>
      <Box displayPrint="flex" display="none" justifyContent="start" flexWrap="wrap">
        <Results2510List ideas={ideas} classes="w-100" />
      </Box>
    </AdminActivityAuxView>
  );
};

export default Admin2510Review;
