import { useShallowEqualSelector } from '../utils';

export const useSessionsSelector = () => {
  return useShallowEqualSelector(
    ({
      slidesSessions: {
        sessionsLoading,
        creatingSession,
        sessionIds,
        watchingSlide,
        watchingSessionName,
      },
    }) => {
      return {
        sessionsLoading,
        sessionIds,
        creatingSession,
        watchingSlide,
        watchingSessionName,
      };
    },
  );
};

export const useSessionDetailsSelector = sessionId => {
  return useShallowEqualSelector(({ slidesSessions: { sessionMap } }) => {
    return sessionMap[sessionId];
  });
};
