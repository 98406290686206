import React from 'react';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
// cr components
import ActivityTemplate from 'components/ActivityTemplate/ActivityTemplate';
import ParticipantActivityContent from 'components/ParticipantActivityContent';
import MuralParticipantMural from 'components/Mural/Participant/MuralParticipantMural';
// selectors
import { useMuralSelector } from 'redux/selectors/muralSelectors';
// constants
import { MURAL_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';

//add new view for all action
const views = {
  mural: MuralParticipantMural,
};

const MuralParticipant = ({
  match: {
    params: { activityId },
  },
}) => {
  const mural = useMuralSelector();

  // hooks up a firestore listener to Redux
  useFirestoreConnect(() => [
    {
      collection: 'mural',
      doc: `${activityId}`,
      storeAs: 'mural',
    },
  ]);

  // ensure all of the data we need is loaded
  if (!isLoaded(mural) || activityId !== mural?.id) {
    return null;
  }

  const Component = views[mural.state.activeView];

  return (
    <ActivityTemplate type="Mural" name={mural.name}>
      <ParticipantActivityContent activityData={mural} activityConfig={CONFIG} viewLabel="">
        <Component />
      </ParticipantActivityContent>
    </ActivityTemplate>
  );
};

export default MuralParticipant;
