import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const PageContentBody = ({ children, contentCenter }) => {
  const styles = cx('page-content-body', {
    center: contentCenter,
  });

  return <div className={styles}>{children}</div>;
};

PageContentBody.propTypes = {
  children: PropTypes.node.isRequired,
  contentCenter: PropTypes.bool,
};

export default PageContentBody;
