import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { filter } from 'lodash';
// library components
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// cr components
import ActivityParticipantDetails from 'components/ActivityParticipantDetails';
import ActivityPromptAdmin from 'components/ActivityPromptAdmin';
import ActivityActionButton from 'components/CustomButtons/ActivityActionButton';
// selectors
import {
  useStoryFlowSelector,
  getParticipantsCompletedSelect,
} from 'redux/selectors/storyFlowSelectors';
// actions
import { updateActivityView } from 'redux/actions/storyFlowActions';
// config
import { STORY_FLOW_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';
// utils
import { makeParticipantDetailsHeaderText } from 'utils/makeParticipantDetailsHeaderText';

const useStyles = makeStyles(theme => ({
  promptAdmin: {
    marginBottom: theme.spacing(5),
  },
}));

/**
 * Story Flow view for the 'select' phase on the Facilitator side
 */
const StoryFlowAdminSelect = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const storyFlow = useStoryFlowSelector();
  const { id, prompt } = storyFlow;

  const participantsHaveSelected = useSelector(getParticipantsCompletedSelect());
  const participantsHaveSelectedCount = filter(participantsHaveSelected, {
    completed: true,
  }).length;

  const participantsHaveSelectedCountText = makeParticipantDetailsHeaderText(
    participantsHaveSelectedCount,
    participantsHaveSelected.length,
    'voted',
  );

  const onUpdateActivityView = () => {
    dispatch(updateActivityView('selectResults', id));
  };

  return (
    <Box className="d-flex flex-column justify-content-center">
      <ActivityPromptAdmin
        prompt={prompt}
        collection={CONFIG.collection}
        activityId={id}
        className={classes.promptAdmin}
      />
      <ActivityParticipantDetails
        headerText={participantsHaveSelectedCountText}
        hasCompletedLabel="Voted"
        hasntCompletedLabel="Hasn’t Voted"
        participantsCompletedStatus={participantsHaveSelected}
      />
      <ActivityActionButton
        text="Review Results"
        className="mb-2 mt-1"
        onClick={onUpdateActivityView}
        delayReady
      />
    </Box>
  );
};

export default StoryFlowAdminSelect;
