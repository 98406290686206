import { map, orderBy, filter, each, size, join, forEach } from 'lodash';

/**
 * Creates the output array for a Narrowing actvity
 * @param {Object} ideas
 * @returns {Array<{icon: String, text: Number, imageUrl: String, votes: Number}>}
 */
export const createOutput = data => {
  // filter to ideas that were shared
  let output = filter(data, { finalRound: true });

  // create initial output array
  output = map(output, card => ({
    icon: card.icon,
    text: card.text,
    imageUrl: card.imageUrl,
    votes: card.votesCount,
  }));

  // sort array and return
  return orderBy(output, ['votes', 'text'], ['desc', 'asc']);
};

/**
 * Creates the array for Report Header
 *
 */
export const createNarrowingReportHeader = () => {
  return [
    { label: 'Card', key: 'card' },
    { label: 'Category', key: 'category' },
    { label: 'Final Round', key: 'finalRound' },
    { label: 'Icon', key: 'icon' },
    { label: 'Image URL', key: 'imageUrl' },
    { label: 'Text', key: 'text' },
    { label: 'Votes', key: 'votes' },
  ];
};

/**
 * Creates the data for Report
 *
 */
export const createNarrowingReportData = cardsReportData => {
  return map(
    orderBy(cardsReportData, ['votesCount', 'category'], ['desc', 'asc']),
    ({ icon, text, imageUrl, category, finalRound, votesCount }, idx) => {
      return {
        card: idx + 1,
        icon,
        text,
        imageUrl,
        category,
        finalRound,
        votes: votesCount,
      };
    },
  );
};
export const getNarrowingReportData = (config, participantData, state) => {
  const categories = config?.categories;
  const cards = participantData?.cards;
  const round1 = participantData?.round1;
  const round = state?.round;
  const report = map(cards, (card, cardId) => {
    let categoryId = '';
    let isFinalRound = false;
    each(round1, participants => {
      each(participants, (categories, caregoryId) => {
        if (categories?.[cardId]) {
          categoryId = caregoryId;
        }
      });
    });

    each(participantData[`round${round}`], participants => {
      each(participants, (cards, index) => {
        if (!cards.isNotInclude) {
          isFinalRound = isFinalRound || cardId === index;
        }
      });
    });

    let currentRound = 2;
    const allRoundCards = [];
    while (currentRound <= round) {
      each(participantData?.[`round${currentRound}`], cards => {
        each(cards, (card, cardId) => {
          if (!card.isNotInclude) {
            allRoundCards.push(cardId);
          }
        });
      });
      currentRound += 1;
    }

    return {
      icon: card.icon,
      text: card.text,
      imageUrl: card.imageUrl,
      category: categoryId ? categories[categoryId].value : '',
      finalRound: isFinalRound,
      votesCount: size(filter(allRoundCards, card => card === cardId)),
    };
  });
  return report;
};

export const createNarrowingReport = activityData => {
  const headers = createNarrowingReportHeader();
  const data = createNarrowingReportData(
    getNarrowingReportData(activityData.config, activityData.participantData, activityData.state),
  );
  return { headers, data };
};
