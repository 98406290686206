import React, { useState } from 'react';
import { sortBy, map, find, filter, size, head, split } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
// library components
import { makeStyles, Box, Typography } from '@material-ui/core';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
// cr components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Category from './Category';
import Card from './Card';
// actions
import { updateCardCategory, deleteCardFromCategory } from 'redux/actions/cardSortingActions';
// selectors
import {
  useCardSortingSelector,
  getParticipantCategorizedCards,
} from 'redux/selectors/cardSortingSelectors';

const useStyles = makeStyles(theme => ({
  categoriesContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: 'calc(100% + 20px)',
  },
  gridContainer: {
    width: 'calc(100% + 10px)',
    margin: '0 -5px',
  },
  gridItem: {
    padding: `0 5px !important`,
  },
  successColor: {
    color: theme.palette.success.main,
  },
  categorizedCard: {
    width: 95,
    height: 115,
    border: '1px dashed #CFCFCF',
    borderRadius: 4,
    background: '#F9F9F9',
    marginBottom: 10,
  },
  categorizedCardDrag: {
    background: '#E8E8E8',
  },
}));

/**
 * Card Sorting view for the 'round 1' phase on the Participant side
 */
const CardSortingParticipantCategorize = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    id,
    config: { categories, primaryCategory },
    state: { activeView },
    participantData: { cards },
  } = useCardSortingSelector();

  const participantId = useSelector(state => state.firebase.auth.uid);
  // get participant cards from categories
  const participantCards = useSelector(getParticipantCategorizedCards(participantId));

  const [dragCardId, setDragCardId] = useState('');

  // sort categories and cards set by 'order'
  const sortedCategories = sortBy(categories, ['order', 'value']);
  const activeCardSet = sortBy(cards, ['order', 'cardId']);

  const isCategorizeCompleted = size(participantCards) === size(activeCardSet);

  // render categories
  const renderCategories = () =>
    map(sortedCategories, (category, idx) => {
      const categoryCards = filter(participantCards, { categoryId: category.id });
      const sortedCards = sortBy(categoryCards, ['order', 'cardId']);
      return (
        <Category
          key={`${category.value}-${idx}`}
          id={category.id}
          droppableId={`${idx}`}
          categoryName={category.value}
          cards={sortedCards}
          context={activeView}
          isPrimaryCategory={primaryCategory === category.id}
        />
      );
    });

  // render cards
  const renderCards = isDraggingOver =>
    map(activeCardSet, (card, idx) => {
      const cardCategorized = find(
        participantCards,
        categorizedCard => categorizedCard.cardId === card.cardId,
      );
      if (isCategorizeCompleted) {
        return;
      }
      return (
        <GridItem className={classes.gridItem} key={`card-${idx}`}>
          {cardCategorized ? (
            <Box
              key={`card-${idx}`}
              className={cx(
                classes.categorizedCard,
                dragCardId === card.cardId && isDraggingOver && classes.categorizedCardDrag,
              )}
            />
          ) : (
            <Card card={card} draggableId={idx} context={activeView} />
          )}
          {dragCardId === card.cardId && !cardCategorized && (
            <Box
              className={cx(
                classes.categorizedCard,
                dragCardId === card.cardId && isDraggingOver && classes.categorizedCardDrag,
              )}
            />
          )}
        </GridItem>
      );
    });

  const onDragEnd = result => {
    setDragCardId('');
    const { source, destination, draggableId } = result;

    if (!destination) {
      return;
    }
    const cardId = head(split(draggableId, '-'));

    const startIndex = source.droppableId;
    const finishIndex = destination.droppableId;
    const finishCategoryId = sortedCategories[finishIndex] ? sortedCategories[finishIndex].id : '';
    const draggableCard = find(activeCardSet, card => card.cardId === cardId);

    if (startIndex === finishIndex) {
      return;
    }

    if (!finishCategoryId) {
      const deletedCard = find(participantCards, card => card.cardId === cardId);
      dispatch(deleteCardFromCategory(id, participantId, deletedCard.categoryId, cardId));
      return;
    }

    if (sortedCategories[startIndex]) {
      const deletedCard = find(participantCards, card => card.cardId === cardId);
      dispatch(deleteCardFromCategory(id, participantId, deletedCard.categoryId, cardId));
    }

    const categoryCards = filter(participantCards, { categoryId: finishCategoryId });

    // add card to category
    dispatch(
      updateCardCategory(
        id,
        participantId,
        finishCategoryId,
        draggableCard.cardId,
        categoryCards?.length || 0,
      ),
    );
  };

  const onDragStart = start => {
    const cardId = head(split(start.draggableId, '-'));
    setDragCardId(cardId);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
      <Box className={classes.categoriesContainer}>{renderCategories()}</Box>
      <Droppable droppableId={`${sortedCategories.length}`} type="cards">
        {(provided, snapshot) => (
          <div ref={provided.innerRef} {...provided.droppableProps} className="w-100">
            <GridContainer className={classes.gridContainer} direction="row" spacing={3}>
              {renderCards(snapshot.isDraggingOver)}
            </GridContainer>
            <div style={{ display: 'none' }}>{provided.placeholder}</div>
          </div>
        )}
      </Droppable>
      <>
        {isCategorizeCompleted && <CheckCircleIcon className={classes.successColor} />}
        <Typography
          className={cx(isCategorizeCompleted && classes.successColor, 'mb-2')}
          color="textSecondary"
          variant="body2"
        >
          {`You’ve organized ${size(participantCards)} of ${size(activeCardSet)} cards`}
        </Typography>
      </>
    </DragDropContext>
  );
};

export default CardSortingParticipantCategorize;
