import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import { useTheme } from '@material-ui/core/styles';
import { includes, head } from 'lodash';
import cx from 'classnames';
// library components
import { Card, CardContent, CardActions, Typography } from '@material-ui/core';
// cr components
import Spinner from 'components/Spinner';
import DottedProgress from 'components/DottedProgress';
// actions
import { voteForNote } from 'redux/actions/pollingActions';
// selectors
import { usePollingSelector, getParticipantVotes } from 'redux/selectors/pollingSelectors';

const useStyles = makeStyles(theme => ({
  card: {
    width: '100%',
    height: 190,
    border: `1px solid ${grey[300]}`,
    padding: theme.spacing(2),
    marginBottom: 30,
    cursor: ({ context }) => (context === 'vote' ? 'pointer' : 'default'),
  },
  cardVoted: {
    padding: 14,
    border: `3px solid ${theme.palette.indigo.main}`,
    backgroundColor: theme.palette.indigo.lightest,
  },
  cardTxt: {
    color: ({ disabled }) => (disabled ? theme.palette.text.disabled : theme.palette.text.primary),
  },
  shareBtn: {
    color: theme.palette.success.main,
    '&[disabled]': {
      color: theme.palette.text.disabled,
      opacity: '50%',
    },
  },
  voteBtn: {
    color: theme.palette.indigo.main,
    '&[disabled]': {
      color: theme.palette.text.disabled,
      opacity: '50%',
    },
  },
  editBtn: {
    color: theme.palette.primary.main,
  },
  winnerText: {
    color: theme.palette.indigo.main,
  },
  buttonSpinner: {
    position: 'relative',
    top: 35,
    left: 125,
  },
}));

const NoteCard = ({ note, context }) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const theme = useTheme();
  const dispatch = useDispatch();
  const participantId = useSelector(state => state.firebase.auth.uid);
  const {
    id,
    config: { voteLimit },
  } = usePollingSelector();
  const participantVotes = useSelector(getParticipantVotes(participantId));
  const votedFor = includes(participantVotes, note.id);
  const disabled = context === 'vote' && !votedFor && voteLimit === participantVotes.length;
  const classes = useStyles({ disabled, context });

  const onVote = () => {
    if (context === 'vote' && voteLimit === 1 && participantVotes.length) {
      setIsUpdating(true);
      dispatch(
        voteForNote(false, head(participantVotes), participantId, id, () => {
          setIsUpdating(true);
        }),
      );
      dispatch(
        voteForNote(true, note.id, participantId, id, () => {
          setIsUpdating(false);
        }),
      );
    }
    if (context !== 'vote' || (disabled && !votedFor)) {
      return;
    } else {
      setIsUpdating(true);
      dispatch(
        voteForNote(!votedFor, note.id, participantId, id, () => {
          setIsUpdating(false);
        }),
      );
    }
  };

  return (
    <Card
      className={cx('d-flex flex-column justify-content-between', classes.card, {
        [classes.cardVoted]:
          (context === 'vote' && votedFor) || (context === 'results' && note.votes > 0),
      })}
      elevation={12}
      onClick={onVote}
    >
      <CardContent className="w-100 h-100 p-0">
        <Typography className={classes.cardTxt}>{note.value}</Typography>
        {isUpdating && <Spinner size={24} className={classes.buttonSpinner} />}
      </CardContent>
      <CardActions className="px-0 pb-0">
        {context === 'results' && (
          <>
            <DottedProgress
              activeColor={theme.palette.indigo.main}
              totalCount={note.votes}
              activeCount={note.votes}
              className="mb-0"
            />
          </>
        )}
      </CardActions>
    </Card>
  );
};

NoteCard.propTypes = {
  note: PropTypes.shape({
    value: PropTypes.string.isRequired,
    id: PropTypes.string,
    winner: PropTypes.bool, // only included in results
    votes: PropTypes.number, // only included in results
  }),
  context: PropTypes.oneOf(['write', 'vote', 'results']),
};

export default NoteCard;
