import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'react-redux-firebase';
// librrary components
import { Typography, ButtonBase } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
// cr components
import AdminBroadcastForm from './AdminBroadcastForm';
// utils
import { linkifyHtmlString } from 'utils/renderHtmlString';

const useStyles = makeStyles(theme => ({
  buttonLink: {
    color: '#093B75',
  },
  highlightsWrapper: {
    border: '1px solid #C9C9C9',
    padding: '1rem',
    minHeight: '230px',
    marginBottom: '1rem',
  },
}));

const SessionAdminBroadcast = () => {
  const [isEditing, setIsEditing] = useState(false);
  const session = useSelector(state => state.firestore.data.session);
  const classes = useStyles();

  if (isEmpty(session)) return null;

  return (
    <>
      {isEditing || !session.highlight ? (
        <AdminBroadcastForm
          initialValues={{ highlightMsg: session.highlight || '' }}
          setIsEditing={setIsEditing}
          sessionId={session.id}
        />
      ) : (
        <>
          <Typography>Highlights</Typography>
          <div className={classes.highlightsWrapper}>
            <div className="d-flex justify-content-between">
              <div
                className="ck-content w-100"
                style={{ fontSize: 18 }}
                dangerouslySetInnerHTML={{ __html: linkifyHtmlString(session.highlight) }}
              />
            </div>
          </div>
          <ButtonBase
            onClick={() => {
              setIsEditing(true);
            }}
            size="small"
            className={`${classes.buttonLink} align-self-start`}
          >
            Edit
          </ButtonBase>
        </>
      )}
    </>
  );
};

export default SessionAdminBroadcast;
