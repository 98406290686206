import React from 'react';
import PropTypes from 'prop-types';
// library components
import { Box } from '@material-ui/core';
// cr components
import LightningDemo from 'components/LightningDemo/Participant/LightningDemo';

/**
 * Renders lightning demo for review phase
 */
const LightningDemoReview = ({ activeView, currentDemo, lightningDemoId }) => {
  if (!currentDemo) {
    return <></>;
  }

  return (
    <Box
      className="w-100 pt-1"
      display="flex"
      alignItems="center"
      justifyContent="flex-start"
      flexWrap="wrap"
    >
      {currentDemo.imageId && (
        <LightningDemo
          lightningDemoId={lightningDemoId}
          context={activeView}
          demoId={currentDemo.demoId}
          participantId={currentDemo.participantId}
          imageId={currentDemo.imageId}
          bigIdeas={currentDemo.bigIdeas}
          source={currentDemo.source}
          demoUrl={currentDemo.url}
          isFinished={true}
        />
      )}
    </Box>
  );
};

LightningDemoReview.propTypes = {
  activeView: PropTypes.string, // active phase of state,
  currentDemo: PropTypes.shape({
    demoId: PropTypes.string.isRequired, // the id of the lightning demo
    participantId: PropTypes.string.isRequired, // the id of participant
    imageId: PropTypes.string.isRequired, // the id of image
    bigIdeas: PropTypes.string.isRequired, // the title (big ideas) of the lightning demo
    source: PropTypes.string.isRequired, // the source title of the lightning demo
    url: PropTypes.string.isRequired, // the url of the lightning demo
  }),
  lightningDemoId: PropTypes.string.isRequired, // the id of the acticity
};

export default LightningDemoReview;
