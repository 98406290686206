import { createSelector } from 'reselect';
import { map, includes, find, filter, size, reduce } from 'lodash';
import { useShallowEqualSelector } from '../utils';

const heatMappingSelector = state => state.firestore.data.heatMapping || {};

export const useHeatMappingSelector = () => {
  return useShallowEqualSelector(heatMappingSelector);
};

/**
 * Get data on which participants have completed upload,
 * formatted for the ActivityParticipantDetail component
 *
 * @returns {Array.<{id: String, completed: Boolean}>}
 */
export const getParticipantsCompletedUpload = () =>
  createSelector([useHeatMappingSelector], ({ participantData: { images, participants } }) => {
    return map(participants, participantId => {
      const participantImages = filter(images, { participantId: participantId });
      return {
        id: participantId,
        completed: !!participantImages.length,
        completedCount: participantImages.length,
      };
    });
  });

/**
 * Get data on which participants have completed heat-mapping,
 * formatted for the ActivityParticipantDetail component
 *
 * @returns {Array.<{id: String, completed: Boolean}>}
 */
export const getParticipantsCompletedHeatMapping = sketchId =>
  createSelector(
    [useHeatMappingSelector],
    ({ participantData: { images, sketches, participants } }) => {
      const sketchImages = sketches[sketchId]?.images;
      return map(participants, participantId => {
        const participantHeatMappingPoints = filter(sketchImages, imageId =>
          size(images[imageId]?.points?.[participantId]),
        );
        return {
          id: participantId,
          completed: !!participantHeatMappingPoints.length,
          completedCount: participantHeatMappingPoints.length,
        };
      });
    },
  );

/**
 * Get data on which participants have reviwed sketch,
 * formatted for the ActivityParticipantDetail component
 *
 * @returns {Array.<{id: String, completed: Boolean}>}
 */
export const getParticipantsCompletedReview = () =>
  createSelector(
    [useHeatMappingSelector],
    ({ participantData: { sketches, participants }, state: { currentSketch } }) => {
      return map(participants, participantId => {
        const participantSketch = find(sketches, sketch => sketch.participantId === participantId);
        return {
          id: participantId,
          completed: participantSketch
            ? !!participantSketch.completed || participantSketch.id === currentSketch
            : false,
        };
      });
    },
  );

/**
 * Get data on which participants have completed vote,
 * formatted for the ActivityParticipantDetail component
 *
 * @returns {Array.<{id: String, completed: Boolean}>}
 */
export const getParticipantsCompletedVote = () =>
  createSelector([useHeatMappingSelector], ({ participantData: { sketches, participants } }) => {
    return map(participants, participantId => ({
      id: participantId,
      completed: !!find(
        sketches,
        sketch => includes(sketch.votedFor, participantId) && sketch.comments?.[participantId],
      ),
    }));
  });

/**
 * Get images for participant
 *
 * @param {String} participantId
 * @returns {Array.<String>}
 */
export const getParticipantImages = participantId =>
  createSelector([useHeatMappingSelector], ({ participantData: { images } }) => {
    const participantImages = filter(images, ['participantId', participantId]);
    return map(participantImages, image => image.id);
  });

/**
 * Get sketches the participant
 *
 * @param {String} participantId
 * @returns {Array.<String>}
 */
export const getParticipantSketches = participantId =>
  createSelector([useHeatMappingSelector], ({ participantData: { sketches } }) => {
    return find(sketches, { participantId: participantId });
  });

/**
 * Get data on which participants have completed super vote,
 * formatted for the ActivityParticipantDetail component
 *
 * @returns {Array.<{id: String, completed: Boolean}>}
 */
export const getParticipantsCompletedSuperVote = () =>
  createSelector(
    [useHeatMappingSelector],
    ({ participantData: { sketches, participants }, state: { superVoteParticipantId } }) => {
      const maxSuperVotePoints = 3;
      return map(participants, participantId => {
        if (participantId !== superVoteParticipantId) {
          return {
            id: participantId,
            completed: false,
          };
        }
        const participantPoints = reduce(
          sketches,
          (acc, sketch) => {
            acc += sketch.superVotePoints ? sketch.superVotePoints : 0;
            return acc;
          },
          0,
        );
        return {
          id: participantId,
          completed: participantPoints > 0,
          completedCount: participantPoints,
        };
      });
    },
  );
