import {
  SLIDES_AUTHED,
  LOGOUT,
  AUTH_TOKENS_LOADING,
  AUTH_TOKENS_ERROR,
} from '../types/googleSlidesAuthTypes';

import filter from 'lodash/filter';

/**
 * Loads all the GoogleSlidesExports objects currently in Firestore
 * @param enqueueSnackbar   notistack helper
 */
export const authenticateSlides =
  enqueueSnackbar =>
  (dispatch, getState, { slidesApi }) => {
    const hasGoogleProvider = filter(
      getState().firebase.auth.providerData,
      provider => provider.providerId === 'google.com',
    ).length;

    dispatch({ type: AUTH_TOKENS_LOADING });
    return slidesApi
      .getAuthUrl(document.location.href)
      .then(url => {
        document.location.href = url;
      })
      .catch(err => {
        dispatch({ type: AUTH_TOKENS_ERROR });
        enqueueSnackbar(err.message, { variant: 'error' });
        console.error(err);
      });
  };

export const getSlidesAuthTokens =
  () =>
  (dispatch, getState, { slidesApi }) => {
    dispatch({ type: AUTH_TOKENS_LOADING });
    return slidesApi
      .getDecryptedSlidesAuthTokens()
      .then(tokens => {
        if (tokens) {
          return dispatch({ type: SLIDES_AUTHED, payload: tokens });
        }
        dispatch({ type: AUTH_TOKENS_ERROR });
        console.log('no auth tokens for user');
      })
      .catch(err => {
        dispatch({ type: AUTH_TOKENS_ERROR });
        console.error(err);
      });
  };

export const logout =
  enqueueSnackbar =>
  (dispatch, getState, { slidesApi }) => {
    return slidesApi
      .signOut()
      .then(accessToken => {
        dispatch({ type: LOGOUT });
      })
      .catch(err => {
        enqueueSnackbar(err.message, { variant: 'error' });
        console.error(err);
      });
  };
