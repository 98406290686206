import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { size, find } from 'lodash';
import cx from 'classnames';
// library components
import { grey } from '@material-ui/core/colors';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Typography } from '@material-ui/core';
// cr components
import Flow from 'components/NoteAndMap/Participant/Flow';
// selectors
import { useNoteAndMapSelector, getParticipantFlow } from 'redux/selectors/noteAndMapSelectors';
// actions
import { createMoment, updateMomentValue } from 'redux/actions/noteAndMapActions';

const useStyles = makeStyles(theme => ({
  successColor: {
    color: theme.palette.success.main,
  },
  text: {
    fontSize: 14,
    color: grey[500],
  },
}));

/**
 * Note And Map view for the 'create' phase on the Participant side
 */
const NoteAndMapParticipantCreate = () => {
  const flowFieldsCount = 6;
  const dispatch = useDispatch();
  const classes = useStyles();
  const participantId = useSelector(state => state.firebase.auth.uid);

  const {
    id,
    state: { activeView },
  } = useNoteAndMapSelector();
  const flow = useSelector(getParticipantFlow(participantId));
  const isFlowComplete =
    size(flow?.types) === flowFieldsCount && !find(flow?.types, ({ value }) => !value);

  // change flow field value
  const handleChangeFlowField = (fieldName, value) => {
    dispatch(updateMomentValue(id, participantId, fieldName, value));
  };

  // create new flow field
  const handleCreateFlowField = (fieldName, value) => {
    dispatch(createMoment(id, participantId, fieldName, value));
  };

  return (
    <>
      <Flow
        createdFlowParticipantId={participantId}
        participantFlow={flow?.types}
        handleCreateFlowField={handleCreateFlowField}
        handleChangeFlowField={handleChangeFlowField}
        context={activeView}
      />
      {isFlowComplete ? (
        <>
          <CheckCircleIcon className={classes.successColor} />
          <Typography className={cx(classes.successColor, 'mb-3')} variant="body2">
            You’ve submitted the steps
          </Typography>
        </>
      ) : (
        <Typography className={classes.text}>Add all the steps</Typography>
      )}
    </>
  );
};

export default NoteAndMapParticipantCreate;
