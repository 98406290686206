import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { size } from 'lodash';

import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';

import SelectMenu from 'components/Staffing/SelectMenu/SelectMenu';

import { useDispatch, useSelector } from 'react-redux';
import { selectDeal } from 'redux/selectors/staffing/getDeals';
import {
  deleteDeal,
  openAddProjectToDealDialog,
  openCreateDealDialog,
  openDealInsightsDialog,
} from 'redux/actions/staffingActions';

import './dealCard.scss';
import { useHistory } from 'react-router-dom';

import { getDealStatusStyles } from 'utils/staffing/status';
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from 'redux/actions/notifierActions';
import { useConfirm } from 'material-ui-confirm';
import { DEAL_STATUS_STATES } from 'constants/staffing';

const DealCard = props => {
  const { id } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const confirm = useConfirm();

  const getDeal = selectDeal();
  const deal = useSelector(state => getDeal(state, id));

  const menuOptions = [
    {
      title: 'Edit',
      onItemClick: () => {
        dispatch(openCreateDealDialog(true, id));
      },
    },
    {
      title: 'View Report',
      onItemClick: () => {
        dispatch(openDealInsightsDialog(true));
      },
    },
  ];

  if (deal?.status !== DEAL_STATUS_STATES.CLOSED) {
    menuOptions.push({
      title: 'Add Project',
      onItemClick: () => {
        dispatch(openAddProjectToDealDialog(true, id));
      },
    });
  }

  menuOptions.push({
    title: 'Delete Deal',
    onItemClick: () => {
      confirm({
        description: 'The Deal, SOW, and Services Agreement will all be deleted. Are you sure?',
      }).then(() => {
        if (deal.projects.length) {
          dispatch(
            enqueueErrorSnackbar(
              'A deal cannot be deleted while it has associated projects. Delete the projects first and try again.',
            ),
          );
        } else {
          dispatch(
            deleteDeal(deal.id, () => {
              history.push('/admin/staffing/deals');
              dispatch(enqueueSuccessSnackbar('Deal deleted'));
            }),
          );
        }
      });
    },
  });

  const handleDetailNavigate = () => {
    history.push(`/admin/staffing/deals/${id}`);
  };

  if (!deal) {
    return null;
  }

  return (
    <Card className={cx('deal-card')}>
      <div className="deal-card-header">
        <div onClick={handleDetailNavigate}>
          <Typography className="deal-card-name">{deal.dealName}</Typography>
          <Typography className="deal-card-contact-email">{deal.companyName}</Typography>

          <Typography className="deal-card-contact-email">{deal.contactEmail}</Typography>
        </div>

        <SelectMenu
          options={menuOptions}
          menuContainerClassName="deal-card-menu"
          menuBtnClassName="deal-card-menu-button"
        />
      </div>

      <div className="deal-card-separator" />

      <div className="deal-card-body">
        <Typography className="deal-card-body-description mb-1">
          {size(deal.projects)} projects | {deal.formattedAmount} Value
        </Typography>

        <div className="d-flex align-items-center">
          <div
            className={cx(
              'mr-1',
              'deal-card-body-status',
              getDealStatusStyles(deal.status).containerClassname,
            )}
          >
            <Typography className="deal-card-body-status-text">
              {getDealStatusStyles(deal.status).title}
            </Typography>
          </div>

          <Typography
            className={cx(
              'deal-card-body-day-text',
              getDealStatusStyles(deal.status).textClassname,
            )}
          >
            {deal.timeSpentInCurrentStatus}
          </Typography>
        </div>
      </div>
    </Card>
  );
};

DealCard.propTypes = {
  id: PropTypes.string.isRequired,
};

export default DealCard;
