import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import cx from 'classnames';

const useStyles = makeStyles(theme => ({
  main: {},
  formControl: {
    width: '100%',
    margin: theme.spacing(1),
    flexGrow: 1,
  },
  inputLabel: {
    top: 4,
  },
  inputLabelShrink: {
    top: '-8px',
    left: '-12px',
    paddingLeft: 0,
  },
  select: {
    backgroundColor: '#EEEEEE',
    paddingLeft: 10,
  },
  root: {
    backgroundColor: '#EEEEEE',
  },
}));

const PickerSelect = ({
  classNames,
  label,
  options,
  onChange,
  defaultValue = '',
  formControlClassName,
}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState('');

  const handleChange = value => {
    setValue(value);
    onChange(value);
  };

  useEffect(() => {
    handleChange(defaultValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  return (
    <div className={cx(classes.main, classNames)}>
      <FormControl variant="standard" className={cx(classes.formControl, formControlClassName)}>
        <InputLabel
          id="demo-simple-select-filled-label"
          className={classes.inputLabel}
          classes={{ shrink: classes.inputLabelShrink }}
          variant="filled"
        >
          {label}
        </InputLabel>
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={value}
          onChange={event => {
            handleChange(event.target.value);
          }}
          classes={{ select: classes.select, root: classes.root }}
        >
          <MenuItem value="">
            <em>All</em>
          </MenuItem>
          {options?.map(({ name, value }, idx) => (
            <MenuItem key={idx.toString()} value={value}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

PickerSelect.propTypes = {
  classNames: PropTypes.string,
  defaultValue: PropTypes.string,
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default PickerSelect;
