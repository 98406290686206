import React from 'react';
import { useSelector } from 'react-redux';
import { isEmpty, isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { useParams } from 'react-router-dom';
// cr components
import SurveyAdminWrite from 'components/Survey/Admin/SurveyAdminWrite';
import SurveyAdminResults from 'components/Survey/Admin/SurveyAdminResults';
import AdminActivityTemplate from 'components/AdminActivityTemplate';
// selectors
import { useSurveySelector } from 'redux/selectors/surveySelectors';
// config
import { SURVEY_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';

const views = {
  write: SurveyAdminWrite,
  results: SurveyAdminResults,
};

/**
 * Entry point for Survey on the Admin side.
 * Loads the Firestore object for the activity, ensures
 * the data is loaded before rendering the game, and determines
 * which view to show
 */
const SurveyAdmin = () => {
  const routeParams = useParams();
  const survey = useSurveySelector();
  const session = useSelector(state => state.firestore.data.session);
  const meetingParticipants = useSelector(state => state.firestore.data.participants);

  // hooks up a firestore listener to Redux
  useFirestoreConnect(() => [
    {
      collection: 'survey',
      doc: `${routeParams.activityId}`,
      storeAs: 'survey',
    },
  ]);

  // ensure all of the data we need is loaded
  if (
    !isLoaded(session) ||
    isEmpty(session) ||
    !isLoaded(survey) ||
    isEmpty(survey) ||
    !isLoaded(meetingParticipants) ||
    /**
     * Ensure the loaded Survey data actually matches the id in the URL.
     * Since we use only store one instance of any activiy in Redux at a time,
     * if you switch to a different Survey in the same meeting/session it is
     * possible to momentarily have data for the wrong instance of the activity while
     * useFirestoreConnect loads the new data
     */
    routeParams.activityId !== survey?.id
  ) {
    return null;
  }

  const CurrentView = views[survey.state.activeView];

  return (
    <AdminActivityTemplate
      activityConfig={CONFIG}
      activityData={survey}
      session={session}
      CurrentView={CurrentView}
    />
  );
};

export default SurveyAdmin;
