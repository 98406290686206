import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isEmpty, isLoaded, useFirestoreConnect } from 'react-redux-firebase';

import AdminActivityTemplate from 'components/AdminActivityTemplate';
import StoryFlowAdminCreate from 'components/StoryFlow/Admin/StoryFlowAdminCreate';
import StoryFlowAdminSelect from 'components/StoryFlow/Admin/StoryFlowAdminSelect';
import StoryFlowAdminSelectResults from 'components/StoryFlow/Admin/StoryFlowAdminSelectResults';
import StoryFlowAdminSuperVote from 'components/StoryFlow/Admin/StoryFlowAdminSuperVote';
import StoryFlowAdminResults from 'components/StoryFlow/Admin/StoryFlowAdminResults';

import { useStoryFlowSelector } from 'redux/selectors/storyFlowSelectors';

import { STORY_FLOW_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';

const views = {
  create: StoryFlowAdminCreate,
  select: StoryFlowAdminSelect,
  selectResults: StoryFlowAdminSelectResults,
  superVote: StoryFlowAdminSuperVote,
  results: StoryFlowAdminResults,
};

const StoryFlowAdmin = () => {
  const routeParams = useParams();
  const storyFlow = useStoryFlowSelector();
  const session = useSelector(state => state.firestore.data.session);
  const meetingParticipants = useSelector(state => state.firestore.data.participants);

  // hooks up a firestore listener to Redux
  useFirestoreConnect(() => [
    {
      collection: 'storyFlow',
      doc: `${routeParams.activityId}`,
      storeAs: 'storyFlow',
    },
  ]);

  // ensure all of the data we need is loaded
  if (
    !isLoaded(session) ||
    isEmpty(session) ||
    !isLoaded(storyFlow) ||
    isEmpty(storyFlow) ||
    !isLoaded(meetingParticipants) ||
    routeParams.activityId !== storyFlow?.id
  ) {
    return null;
  }

  const CurrentView = views[storyFlow.state.activeView];

  return (
    <AdminActivityTemplate
      activityConfig={CONFIG}
      activityData={storyFlow}
      session={session}
      CurrentView={CurrentView}
    />
  );
};

export default StoryFlowAdmin;
