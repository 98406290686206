import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useField, useFormikContext } from 'formik';

import Card from '@material-ui/core/Card';
import InputLabel from '@material-ui/core/InputLabel';
import FormLabel from '@material-ui/core/FormLabel';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Box from '@material-ui/core/Box';

import FeedbackIcon from 'components/FeedbackIcon/FeedbackIcon';

import { ReactComponent as SentimentNeutralFilled } from 'assets/img/sentiment_neutral_filled.svg';
import { ReactComponent as SentimentSatisfiedFilled } from 'assets/img/sentiment_satisfied_filled.svg';
import { ReactComponent as SentimentDissatisfiedFilled } from 'assets/img/sentiment_dissatisfied_filled.svg';

import './sentiment.scss';

export const SENTIMENT_CHOICES = {
  SATISFIED: 'satisfied',
  NEUTRAL: 'neutral',
  DISSATISFIED: 'dissatisfied',
};

const SentimentOption = props => {
  const optionStyle = makeStyles({
    root: {
      backgroundColor: '#F0F0F0',
    },
    selected: {
      backgroundColor: '#FFF !important',
    },
  });

  const filledIcon = () => {
    switch (props.value) {
      case 'satisfied':
        return <SentimentSatisfiedFilled />;
      case 'neutral':
        return <SentimentNeutralFilled />;
      case 'dissatisfied':
        return <SentimentDissatisfiedFilled />;
    }
  };

  return (
    <ToggleButton
      {...props}
      classes={optionStyle()}
      value={props.value}
      className="sentiment-option"
      disableRipple
    >
      {props.selected ? filledIcon() : <FeedbackIcon type={props.value} active width={60} />}
    </ToggleButton>
  );
};

const useCardStyles = makeStyles({
  root: {
    marginTop: 10,
  },
});

const useToggleButtonGroupStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },
}));

const Sentiment = ({ name, label, isSaveSentiment, handleSaveSentiment, required }) => {
  const [field, meta, helpers] = useField(name);
  const cardStyles = useCardStyles();
  const { submitCount } = useFormikContext();
  const [isTouched, setIsTouched] = useState(false);

  const hasError = !!((submitCount && !meta.value) || (isTouched && !meta.value));

  const handleChange = (event, value) => {
    helpers.setValue(value);
    setIsTouched(true);
    if (isSaveSentiment && handleSaveSentiment) {
      handleSaveSentiment(value);
    }
  };

  return (
    <Box className="sentiment-field">
      <InputLabel
        style={{ marginBottom: 5 }}
        error={hasError}
        htmlFor="sentiment-field"
        required={required}
      >
        {label}
      </InputLabel>
      <Card variant="outlined" elevation={3} id="sentiment-field" classes={cardStyles}>
        <ToggleButtonGroup
          value={field.value}
          exclusive
          onChange={handleChange}
          aria-label="sentiment"
          classes={useToggleButtonGroupStyles()}
        >
          <SentimentOption value="satisfied" />
          <SentimentOption value="neutral" />
          <SentimentOption value="dissatisfied" />
        </ToggleButtonGroup>
      </Card>
      {hasError ? (
        <Box component="span" color="error.main" style={{ paddingTop: 5, display: 'inline-block' }}>
          Required
        </Box>
      ) : null}
    </Box>
  );
};

Sentiment.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isSaveSentiment: PropTypes.bool,
  handleSaveSentiment: PropTypes.func,
  required: PropTypes.bool,
};

export default Sentiment;
