import React, { useEffect } from 'react';
import { useFirestore, isEmpty } from 'react-redux-firebase';
import { Form, Formik } from 'formik';
import { includes } from 'lodash';
import { useSelector } from 'react-redux';
// library components
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
// CR components
import TextArea from 'components/CustomFormComponents/TextArea';
// selectors
import { getIdeaById } from 'redux/selectors/25:10/root2510Selectors';

const Participant2510IdeaForm = ({ activityId, activity, classes }) => {
  const db = useFirestore();
  const participantId = useSelector(state => state.firebase.auth.uid);
  const activityRef = db.doc(`25:10/${activityId}`);
  const idea = useSelector(getIdeaById(participantId));
  const topic = !!activity ? activity.topic : '';

  useEffect(() => {
    if (!includes(activity.participants, participantId)) {
      activityRef
        .set(
          {
            participants: db.FieldValue.arrayUnion(participantId),
          },
          { merge: true },
        )
        .then(() => {});
    }
  }, []);

  const handleSubmit = (values, { setSubmitting }) => {
    const nextState = {
      id: participantId,
      ...values,
    };

    db.doc(`25:10/${activityId}`)
      .set(
        {
          ideas: db.FieldValue.arrayUnion(nextState),
        },
        { merge: true },
      )
      .then(() => {
        setSubmitting(false);
      });
  };

  const renderButton = (submitForm, { idea }) => {
    return (
      <Button variant="contained" onClick={submitForm} className={classes.button} disabled={!idea}>
        Submit Idea
      </Button>
    );
  };

  const renderSubmittedText = () => {
    return (
      <div className={classes.submittedText}>
        <h4>Idea Submitted!</h4>
        <div>Voting starts soon</div>
      </div>
    );
  };

  return (
    <Formik
      initialValues={{
        idea: '',
      }}
      onSubmit={handleSubmit}
    >
      {({ submitForm, values }) => (
        <Form className="w-100" style={{ maxWidth: 600 }}>
          <Paper className={classes.topic} elevation={0}>
            <Typography>{topic}</Typography>
          </Paper>
          <TextArea
            name="idea"
            className={classes.textarea}
            placeholder="Insert your idea here:"
            multiline
            fullWidth
            rows={4}
            variant="filled"
            disabled={!isEmpty(idea)}
          />
          <div className={classes.action}>
            {!isEmpty(idea) ? renderSubmittedText() : renderButton(submitForm, values)}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Participant2510IdeaForm;
