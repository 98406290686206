import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
// library components
import { Box, Button, FormLabel, makeStyles } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
// cr components
import TextOptionsCluster from './TextOptionsCluster';
import PollingBulk from './PollingBulk';
// selectors

const useStyles = makeStyles(theme => ({
  button: {
    marginRight: theme.spacing(1),
    textTransform: 'none',
    fontWeight: 400,
    fontSize: 14,
    color: '#898989',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  activeMode: {
    color: theme.palette.primary.main,
  },
  optionContainer: {
    width: '100%',
  },
}));

/**
 * Render an OptionCloud for the polling options in relation
 * to the current view/context
 */
const PollingOptions = ({ field, disabled, label, ...rest }) => {
  const classes = useStyles();
  const [activeMode, setActiveMode] = useState('single');

  const handleChangeMode = value => {
    setActiveMode(value);
  };

  return (
    <Box mb={2}>
      <FormLabel component="legend" style={{ marginBottom: 10 }}>
        {label}
      </FormLabel>
      <Box>
        <Button
          endIcon={<AddCircleIcon />}
          className={cx(classes.button, activeMode === 'single' && classes.activeMode)}
          onClick={() => handleChangeMode('single')}
        >
          Single Add
        </Button>
        <Button
          endIcon={<FilterNoneIcon />}
          className={cx(classes.button, activeMode === 'bulk' && classes.activeMode)}
          onClick={() => handleChangeMode('bulk')}
        >
          Bulk Add
        </Button>
      </Box>
      {activeMode === 'bulk' ? (
        <PollingBulk {...field} disabled={disabled} {...rest} />
      ) : (
        <TextOptionsCluster {...field} disabled={disabled} {...rest} />
      )}
    </Box>
  );
};

PollingOptions.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    required: PropTypes.bool,
    isFieldDisabled: PropTypes.bool,
  }).isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
};

export default PollingOptions;
