import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { map, join, split, sortBy, each, find, remove, last } from 'lodash';
import { useField, useFormikContext } from 'formik';
// library components
import { Box, TextField, makeStyles } from '@material-ui/core';
// utils
import { generateRandomId } from 'utils/generateRandomId';

const useStyles = makeStyles(theme => ({
  formControl: {
    fontSize: 15,
  },
  input: {
    fontSize: 15,
    lineHeight: '24px',
  },
}));

/**
 * Render a Bulk mode for the polling
 */
const PollingBulk = ({ value, name, disabled }) => {
  const [field, meta] = useField(name);
  const notes = { ...value, ...field.value };
  const { setFieldValue, submitCount } = useFormikContext();
  const { error, touched } = meta;
  const hasError = error && (touched || submitCount);

  const classes = useStyles();
  const sortedNotes = sortBy(notes, 'order');

  const pollNotes = map(sortedNotes, note => note.value);
  const noteValue = join(pollNotes, '\n');

  const [bulkValue, setBulkValue] = useState(noteValue + '\n');

  const handleChange = event => {
    setBulkValue(event.target.value);
  };

  const createNote = (note, order) => {
    const fieldId = generateRandomId();

    notes[fieldId] = {
      value: note,
      id: fieldId,
      order,
    };

    setFieldValue(name, notes);
  };

  const editNote = (note, noteId) => {
    notes[noteId] = {
      ...notes[noteId],
      value: note,
    };
    setFieldValue(name, notes);
  };

  const deleteNote = noteId => {
    delete notes[noteId];
    setFieldValue(name, notes);
  };

  const handleSaveNotes = () => {
    if (disabled) return;

    const newNotes = split(bulkValue, '\n');
    remove(newNotes, note => note === '');
    each(newNotes, (note, idx) => {
      const changedNote = find(sortedNotes, note => note.order === idx);
      if (!changedNote) {
        createNote(note, idx);
      } else {
        editNote(note, changedNote.id);
      }
    });
    if (newNotes.length < sortedNotes.length) {
      while (sortedNotes.length - newNotes.length) {
        const removeNote = last(sortedNotes);
        deleteNote(removeNote.id);
        remove(sortedNotes, note => note.id === removeNote.id);
      }
    }
  };

  return (
    <>
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mt={2}>
        <TextField
          id="polling-bulk"
          multiline
          rows={10}
          value={bulkValue}
          label="Paste options, seperated by line break"
          placeholder="Paste options, seperated by line break"
          onChange={handleChange}
          onBlur={handleSaveNotes}
          variant="filled"
          fullWidth
          InputProps={{ className: classes.input }}
          InputLabelProps={{ className: classes.formControl }}
        />
      </Box>
      {hasError ? (
        <Box component="span" color="error.main" className="d-block" style={{ marginTop: '-16px' }}>
          {error}
        </Box>
      ) : null}
    </>
  );
};

PollingBulk.propTypes = {
  // The object contains custom parameter name, id and order.
  values: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.string,
    order: PropTypes.number,
  }),
  // The name of config field.
  name: PropTypes.string,
  // is the field disabled
  disabled: PropTypes.bool,
};

export default PollingBulk;
