import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { each, filter, head, sortBy } from 'lodash';
// library components
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// cr components
import ActivityParticipantDetails from 'components/ActivityParticipantDetails';
import ActivityPromptAdmin from 'components/ActivityPromptAdmin';
import ActivityActionButton from 'components/CustomButtons/ActivityActionButton';
// selectors
import {
  useHeatMappingSelector,
  getParticipantsCompletedUpload,
} from 'redux/selectors/heatMappingSelectors';
// actions
import {
  updateActivityView,
  updateCurrentSketch,
  removeSketch,
} from 'redux/actions/heatMappingActions';
// config
import { HEAT_MAPPING_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';
// utils
import { makeParticipantDetailsHeaderText } from 'utils/makeParticipantDetailsHeaderText';

const useStyles = makeStyles(theme => ({
  promptAdmin: {
    marginBottom: theme.spacing(5),
  },
}));

/**
 * Heat-Mapping view for the 'upload' phase on the Facilitator side
 */
const HeatMappingAdminUpload = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const heatMapping = useHeatMappingSelector();
  const {
    id,
    prompt,
    participantData: { sketches },
  } = heatMapping;
  const participantsHaveUploaded = useSelector(getParticipantsCompletedUpload());
  const participantsHaveUploadCount = filter(participantsHaveUploaded, { completed: true }).length;

  const participantsUploadedCountText = makeParticipantDetailsHeaderText(
    participantsHaveUploadCount,
    participantsHaveUploaded.length,
    'added a sketch',
  );

  const onUpdateActivityView = () => {
    each(sketches, sketch => {
      if (!sketch.images?.length) {
        dispatch(
          removeSketch(id, sketch.id, () => {
            delete sketches[sketch.id];
          }),
        );
      }
    });
    const noHeatMappingSketches = sortBy(filter(sketches, { completed: false }, ['participantId']));
    if (noHeatMappingSketches?.length) {
      const currentSketchId = head(noHeatMappingSketches).id;
      dispatch(updateCurrentSketch(id, currentSketchId));
    }
    dispatch(updateActivityView('heatMapping', id));
  };

  return (
    <Box className="d-flex flex-column justify-content-center">
      <ActivityPromptAdmin
        prompt={prompt}
        collection={CONFIG.collection}
        activityId={id}
        className={classes.promptAdmin}
      />
      <ActivityParticipantDetails
        headerText={participantsUploadedCountText}
        hasCompletedLabel="Added Sketch"
        hasntCompletedLabel="Hasn't Added Sketch"
        participantsCompletedStatus={participantsHaveUploaded}
      />
      <ActivityActionButton
        text="Start Voting"
        className="mb-2 mt-1"
        onClick={onUpdateActivityView}
      />
    </Box>
  );
};

export default HeatMappingAdminUpload;
