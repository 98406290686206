import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { truncate } from 'lodash';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
// library components
import { makeStyles, Box, Typography, IconButton } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import DragHandleIcon from '@material-ui/icons/DragHandle';
// cr components
import FormDialog from 'components/Dialogs/FormDialog';
import QuestionForm from './QuestionForm';
// constants
import { VARIABLE_QUESTION_TYPES_TITLES } from './QuestionTypes/constants';

const useStyles = makeStyles(theme => ({
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 40,
    marginBottom: theme.spacing(1),
    background: '#EEEEEE',
    color: '#898989',
    fontSize: 16,
    width: '100%',
    borderBottom: 'black',
  },
  activityName: {
    marginLeft: theme.spacing(1),
    color: '#000000',
    fontSize: 15,
  },
  iconButton: {
    padding: 5,
  },
  close: {
    marginLeft: 'auto',
  },
  drag: {
    cursor: 'grab',
  },
}));

const Question = ({ question, onDeleteQuestion, onEditQuestion, disabled }) => {
  const [questionDialogOpen, setQuestionDialogOpen] = useState(false);
  const classes = useStyles();
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: question.id,
  });

  // truncate activity name
  const truncatedTitle = truncate(question.data.question, {
    length: 20,
    omission: '...',
  });

  // function open/close question for activity
  const toggleQuestionDialog = () => {
    setQuestionDialogOpen(!questionDialogOpen);
  };

  const handleDeleteQuestion = () => {
    onDeleteQuestion(question.id);
  };

  const handleEditQuestion = (id, data) => {
    onEditQuestion(id, data);
  };

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <>
      <Box className={classes.itemContainer} style={style}>
        <Box display="flex" alignItems="center">
          <IconButton
            className={cx(classes.iconButton, classes.drag)}
            size="small"
            ref={setNodeRef}
            {...attributes}
            {...listeners}
          >
            <DragHandleIcon fontSize="small" />
          </IconButton>
          <Typography className={classes.activityName}>
            {question.order}: {VARIABLE_QUESTION_TYPES_TITLES[question.data.questionType]}:{' '}
            {truncatedTitle}
          </Typography>
        </Box>
        <Box>
          <IconButton
            className={cx(classes.iconButton, classes.close)}
            size="small"
            onClick={toggleQuestionDialog}
            fontSize="small"
          >
            <EditIcon fontSize="small" />
          </IconButton>
          <IconButton
            className={cx(classes.iconButton, classes.close)}
            size="small"
            onClick={handleDeleteQuestion}
          >
            <CancelIcon fontSize="small" />
          </IconButton>
        </Box>
      </Box>
      <FormDialog
        handleClose={toggleQuestionDialog}
        isOpen={questionDialogOpen}
        title="Edit Question"
      >
        <QuestionForm
          isNew={false}
          questionId={question.id}
          initialValues={question.data}
          closeDialog={toggleQuestionDialog}
          onEditQuestion={handleEditQuestion}
        />
      </FormDialog>
    </>
  );
};

Question.propTypes = {
  question: PropTypes.object.isRequired,
  onDeleteQuestion: PropTypes.func,
  onEditQuestion: PropTypes.func,
};

export default Question;
