import { UPDATE_PARTICIPANTS } from 'redux/types/participantsTypes';
import { each, cloneDeep, isEqual, values } from 'lodash';

export const updateParticipants = () => {
  return (dispatch, getState, { getFirebase }) => {
    const currentState = getState();

    if (
      !currentState.firestore.data.participants ||
      !values(currentState.firestore.data.participants).length
    )
      return;

    let participants = cloneDeep(currentState.firestore.data.participants);
    const firestore = getFirebase().firestore;
    const currentTime = firestore.Timestamp.now().seconds;

    each(participants, (participant, key) => {
      // console.log('participant heartbreat', participant.heartbeat);
      // console.log('heartbeat difference', `${participant.id} - ${currentTime - participant.heartbeat?.seconds}`);
      participants[key].connected =
        Boolean(participant?.heartbeat) && currentTime - participant.heartbeat?.seconds < 60;
    });

    // console.log('updating particpants');

    dispatch({
      type: UPDATE_PARTICIPANTS,
      payload: participants,
    });
  };
};

export const clearParticipants = () => dispatch => {
  dispatch({
    type: UPDATE_PARTICIPANTS,
    payload: {},
  });
};
