import { createSelector } from 'reselect';
import { values, filter, includes, sortBy, reverse } from 'lodash';

const sidebarMeetingsSelector = state => state.firestore.data.sidebarMeetings;

export const getCurrentSidebarMeetingForUser = userId =>
  createSelector([sidebarMeetingsSelector], sidebarMeetings => {
    const sidebars = values(sidebarMeetings);
    const mySidebarMeetings = filter(sidebars, sb => {
      return includes(sb?.userIds, userId) || sb?.createdBy === userId;
    });

    if (!mySidebarMeetings.length) return null;

    if (mySidebarMeetings.length > 1) {
      return reverse(sortBy(mySidebarMeetings, sb => sb.createdAt.seconds))[0];
    }

    return mySidebarMeetings[0];
  });
