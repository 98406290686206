import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isEmpty, isLoaded, useFirestoreConnect } from 'react-redux-firebase';

import AdminActivityTemplate from 'components/AdminActivityTemplate';
import ScorecardAdminInterview from 'components/Scorecard/Admin/ScorecardAdminInterview';
import ScorecardAdminResults from 'components/Scorecard/Admin/ScorecardAdminResults';

import { useScorecardSelector } from 'redux/selectors/scorecardSelectors';

import { SCORECARD_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';

const views = {
  interview: ScorecardAdminInterview,
  results: ScorecardAdminResults,
};

const AdminScorecard = () => {
  const routeParams = useParams();
  const scorecard = useScorecardSelector();
  const session = useSelector(state => state.firestore.data.session);
  const meetingParticipants = useSelector(state => state.firestore.data.participants);

  // hooks up a firestore listener to Redux
  useFirestoreConnect(() => [
    {
      collection: 'scorecard',
      doc: `${routeParams.activityId}`,
      storeAs: 'scorecard',
    },
  ]);

  // ensure all of the data we need is loaded
  if (
    !isLoaded(session) ||
    isEmpty(session) ||
    !isLoaded(scorecard) ||
    isEmpty(scorecard) ||
    !isLoaded(meetingParticipants) ||
    routeParams.activityId !== scorecard?.id
  ) {
    return null;
  }

  const CurrentView = views[scorecard.state.activeView];

  return (
    <AdminActivityTemplate
      activityConfig={CONFIG}
      activityData={scorecard}
      session={session}
      CurrentView={CurrentView}
    />
  );
};

export default AdminScorecard;
