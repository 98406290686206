import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    fontWeight: '300',
    fontSize: 30,
    textAlign: props => (props.centered ? 'center' : 'left'),
    // maxWidth: '75%',
    // margin: props => props.centered ? '0 auto' : '',
  },
});

const PageContentSectionHeader = props => {
  const { title, centered, ...rest } = props;

  return (
    <Typography variant="h5" classes={useStyles(props)} {...rest}>
      {title}
    </Typography>
  );
};

PageContentSectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  centered: PropTypes.bool,
};

export default PageContentSectionHeader;
