import React from 'react';
import PropTypes from 'prop-types';
import { map, find, filter, sortBy, size } from 'lodash';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Droppable } from 'react-beautiful-dnd';
import cx from 'classnames';
// library components
import { Box, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
// cr components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import NoteCard from './NoteCard';
import DottedProgress from 'components/DottedProgress';
// selectors
import { useNoteAndCategorizeSelector } from 'redux/selectors/noteAndCategorizeSelectors';

const useCategoryStyles = makeStyles(theme => ({
  categoryContainer: {
    height: '100%',
    flex: '1 1 240px',
    marginRight: 10,
    marginLeft: 10,
    marginBottom: 20,
  },
  container: {
    width: '100%',
    height: '100%',
    minHeight: 180,
    padding: '0 15px',
    border: ({ isPrevCategory }) => (isPrevCategory ? '1px dashed #DCDCDC' : '1px dashed #C0CFDC'),
    borderRadius: 4,
    backgroundColor: ({ isPrevCategory }) => (isPrevCategory ? '#F3F3F3' : '#EDF5F8'),
  },
  grid: {
    width: '100%',
    margin: 0,
  },
  gridItem: {
    display: ({ context }) => context === 'write' && 'flex',
    alignItems: ({ context }) => context === 'write' && 'flex-end',
    padding: `0 5px !important`,
    marginBottom: 10,
  },
  title: {
    marginBottom: theme.spacing(1),
    color: ({ isPrevCategory }) => (isPrevCategory ? '#828282' : '#093B75'),
    fontSize: '16px',
    lineHeight: '40px',
  },
  note: {
    minHeight: 50,
    height: '100%',
    width: 145,
  },
  noteLarge: {
    height: 110,
    width: 240,
    padding: 15,
  },
  successVoteColor: {
    color: theme.palette.indigo.main,
  },
}));

/**
 * Category view on the Participant side.
 */
const Category = ({
  droppableId,
  id,
  value,
  notes,
  context,
  handleParticipantVote,
  participantVoteNotes,
  isFinished,
  votesPerCategory,
  votesCount,
  countVotedNotesForCategory,
  noteSize,
  results,
  className,
  noteClassName,
  isPrevCategory,
  handleOpenDuplicateCards,
  combineNote,
  isOpenCombineList,
  isDragStart,
  isDraggableCategory,
}) => {
  const categoryClasses = useCategoryStyles({ isPrevCategory, context });
  const theme = useTheme();
  const {
    config: { ideasPerCategory, format, roundRobinShare },
  } = useNoteAndCategorizeSelector();

  const renderNote = (note, idx, isSelected, countVotes) => (
    <NoteCard
      key={note.noteId}
      className={cx(
        noteSize === 'small' ? categoryClasses.note : categoryClasses.noteLarge,
        noteClassName,
      )}
      note={note}
      draggableId={`${idx}`}
      isCategorize={true}
      handleParticipantVote={handleParticipantVote}
      isSelected={isSelected}
      countVotes={countVotes}
      context={context}
      isFinished={isFinished}
      votesPerCategory={votesPerCategory}
      handleOpenDuplicateCards={handleOpenDuplicateCards}
      isDragStart={isDragStart}
      isPrevCategory={isPrevCategory && context === 'write'}
      isShowVoteResults={
        isPrevCategory && format === 'Serial' && roundRobinShare === 'on' && !!size(note.votedFor)
      }
      isDraggableCategory={isDraggableCategory}
    />
  );

  /**
   * Create participant notes for current category.
   */
  const renderParticipantNotes = () => {
    const filterNotes =
      context === 'results'
        ? filter(notes, note => !note.isCombine)
        : sortBy(
            filter(notes, note => !note.isCombine),
            ['order', 'noteId'],
          );
    return map(filterNotes, (note, idx) => {
      if (note.categoryId === id) {
        let isSelected = !!find(participantVoteNotes, { noteId: note.noteId });
        if (context === 'results') {
          const outputNote = find(results, result => result.noteId === note.noteId);
          isSelected = outputNote?.winner;
        }
        const countVotes = note.votedFor?.length;
        return (
          <GridItem key={`grid-item-${idx}`} className={categoryClasses.gridItem}>
            {renderNote(note, idx, isSelected, countVotes)}
            {isOpenCombineList &&
              combineNote?.noteId === note.noteId &&
              map(combineNote.combineNotesId, (noteId, index) => {
                const duplicateNote = find(notes, duplicateNote => duplicateNote.noteId === noteId);
                return renderNote(duplicateNote, idx + index, isSelected, countVotes);
              })}
          </GridItem>
        );
      }
    });
  };

  const categoryNotes = filter(notes, { categoryId: id });
  const isCategoryFull = categoryNotes.length >= ideasPerCategory;

  const getCategoryDragStyle = isDraggingOver => ({
    background:
      isDraggingOver && !isPrevCategory && !isCategoryFull && context === 'write'
        ? '#D7E6EF'
        : isPrevCategory
          ? '#F3F3F3'
          : '#EDF5F8',
  });

  return (
    <Box className={cx(categoryClasses.categoryContainer, className)}>
      <Droppable
        isDropDisabled={context === 'vote' || isPrevCategory}
        droppableId={droppableId}
        type="notes"
        isCombineEnabled={context === 'review'}
        direction="horizontal"
      >
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={getCategoryDragStyle(snapshot.isDraggingOver)}
            className={categoryClasses.container}
          >
            <Typography className={categoryClasses.title} align="center">
              {value}
            </Typography>
            <GridContainer direction="row" className={categoryClasses.grid} spacing={1}>
              {renderParticipantNotes()}
            </GridContainer>
            <div style={{ display: 'none' }}>{provided.placeholder}</div>
          </div>
        )}
      </Droppable>
      {context === 'vote' && (
        <Box
          className="mt-1"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          {countVotedNotesForCategory === votesCount && !!votesCount && (
            <CheckCircleIcon className={cx(categoryClasses.successVoteColor, 'mt-1')} />
          )}
          <DottedProgress
            className={cx(countVotedNotesForCategory < votesCount && 'mt-4', 'mt-1 ml-1')}
            activeColor={theme.palette.indigo.main}
            totalCount={votesCount}
            activeCount={countVotedNotesForCategory}
          />
          <Typography
            className={cx(
              countVotedNotesForCategory === votesCount && categoryClasses.successVoteColor,
            )}
            color="textSecondary"
            variant="body2"
          >
            {countVotedNotesForCategory === votesCount && votesCount
              ? `All votes used.`
              : votesCount
                ? `${countVotedNotesForCategory}/${votesCount} votes used.`
                : ''}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

Category.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  droppableId: PropTypes.string,
  context: PropTypes.string,
  isFinished: PropTypes.bool,
  handleParticipantVote: PropTypes.func,
  votesPerCategory: PropTypes.string,
  votesCount: PropTypes.number,
  countVotedNotesForCategory: PropTypes.number,
  noteSize: PropTypes.oneOf(['large', 'small']), // size of note card. The "small" size - card size by text
  handleOpenDuplicateCards: PropTypes.func, // open duplicate card list
  isDraggableCategory: PropTypes.bool, // note drags in current category
};

export default Category;
