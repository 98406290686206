import React from 'react';
import { useFirestore } from 'react-redux-firebase';
import { Form, Formik } from 'formik';
import { useSelector } from 'react-redux';
import { grey } from '@material-ui/core/colors';
import { makeStyles, darken } from '@material-ui/core/styles';
// library components
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
// cr components
import Score from 'components/25:10/Participant/Participant2510ScoreField';

const useStyles = makeStyles(theme => ({
  topic: {
    backgroundColor: grey[100],
    textAlign: 'center',
    padding: '30px',
    marginBottom: '20px',
  },
  action: {
    margin: '40px 0',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  button: {
    backgroundColor: '#093B75',
    color: 'white',

    '&:hover': {
      backgroundColor: darken('#093B75', 0.2),
    },
  },
  submittedText: {
    color: '#333333',
    fontSize: '20px',
  },
  round: {
    marginBottom: '30px',
    textAlign: 'center',
    fontSize: '30px',
    fontWeight: '300',
  },
  successColor: {
    color: theme.palette.success.main,
  },
}));

const Participant2510VoteForm = ({
  activityId,
  idea,
  votes,
  currentRound,
  participantsVote,
  topic,
}) => {
  const classes = useStyles();
  const db = useFirestore();
  const participantId = useSelector(state => state.firebase.auth.uid);

  const handleSubmit = (values, { setSubmitting }) => {
    const sessionRef = db.doc(`25:10/${activityId}`);
    db.runTransaction(t => {
      return t.get(sessionRef).then(sessionDoc => {
        const sessionData = sessionDoc.data();
        const { vote } = values;

        const roundAssignments = sessionData.voteAssignments[currentRound].map(assignment => {
          if (assignment.participantId === participantId) {
            return {
              ...assignment,
              vote: vote ? vote : 0,
            };
          }
          return assignment;
        });

        t.set(
          sessionRef,
          {
            voteAssignments: {
              ...sessionData.voteAssignments,
              [currentRound]: roundAssignments,
            },
          },
          { merge: true },
        );
      });
    }).then(() => {
      setSubmitting(false);
    });
  };

  const renderSubmittedText = participantsVote => {
    return (
      !!participantsVote && (
        <>
          <CheckCircleIcon className={classes.successColor} />
          <Typography className={classes.successColor} variant="body2">
            Vote! Your facilitator will advance the round.
          </Typography>
        </>
      )
    );
  };

  return (
    <>
      <Paper elevation={0}>
        <Typography>{topic}</Typography>
      </Paper>
      <Typography className={classes.round}>Round {currentRound}</Typography>
      <Paper className={classes.topic} elevation={0}>
        <Typography>{idea.idea}</Typography>
      </Paper>
      {
        <Formik
          initialValues={{
            vote: !!participantsVote ? participantsVote : 0,
          }}
          enableReinitialize
          onSubmit={handleSubmit}
        >
          {({ submitForm, values }) => (
            <Form className="w-100">
              <Score
                name="vote"
                values={votes}
                selectedVote={values.vote}
                hasVoted={!!participantsVote}
                handleSelectScore={submitForm}
              />
              <div className={classes.action}>{renderSubmittedText(values.vote)}</div>
            </Form>
          )}
        </Formik>
      }
    </>
  );
};

export default Participant2510VoteForm;
