import { useShallowEqualSelector } from '../utils';
import { createSelector } from 'reselect';
import {
  orderBy,
  values,
  keys,
  difference,
  filter,
  map,
  compact,
  size,
  includes,
  cloneDeep,
} from 'lodash';
import { parseSurveyQuestions } from 'utils/survey';

const surveySelector = state => state.firestore.data.survey || {};

export const useSurveySelector = () => {
  return useShallowEqualSelector(surveySelector);
};

/**
 * Get questions as an array in the proper order
 *
 * @returns {array}
 */
export const getQuestions = () =>
  createSelector([surveySelector], ({ config: { questions } }) => {
    const theQuestions = orderBy(values(questions), 'order', 'asc');
    return parseSurveyQuestions(theQuestions);
  });

/**
 * Find out if the participant has answered all of the
 *
 * @returns {array}
 */
export const getParticipantAnsweredAllQuestions = participantId =>
  createSelector([surveySelector], ({ participantData: { answers }, config: { questions } }) => {
    return _participantAnsweredAllQuestions(answers[participantId], questions);
  });

/**
 * Get an array of objects that have the question and an array of answers
 *
 * @returns {array}
 */
export const getQuestionsWithAnswers = () =>
  createSelector([surveySelector], ({ participantData: { answers }, config: { questions } }) => {
    return map(orderBy(questions, 'order', 'asc'), question => ({
      question: question.value,
      answers: compact(map(answers, participantAnswers => participantAnswers[question.id])),
    }));
  });

/**
 * Get all answers for a participant
 *
 * @param {String} participantId
 */
export const getParticipantAnswers = participantId =>
  createSelector([surveySelector], ({ participantData: { answers } }) => {
    return answers[participantId] || {};
  });

/**
 * Get data on which participants have submitted their votes,
 * formatted for the ActivityParticipantDetail component
 *
 * @returns {Array.<{id: String, completed: Boolean}>}
 */
export const getParticipantsHaveAnswered = () =>
  createSelector(
    [surveySelector],
    ({ participantData: { answers, participants }, config: { questions } }) => {
      return map(participants, pId => {
        const participantAnswerCount = size(
          filter(answers[pId], answer => answer === '').length === 0,
        );

        return {
          id: pId,
          completed: participantAnswerCount > 0,
          completedCount: participantAnswerCount,
        };
      });
    },
  );

const _participantAnsweredAllQuestions = (participantAnswers, questions) => {
  if (!participantAnswers) return false;

  const questionIds = keys(questions);
  const questionsIdsAnswered = keys(participantAnswers);
  const allTouched = difference(questionIds, questionsIdsAnswered).length === 0;

  if (!allTouched) return false;

  const theParticipantAnswers = values(participantAnswers);

  return filter(theParticipantAnswers, answer => answer === '').length === 0;
};
