import React, { useState } from 'react';
import FormDialog from './FormDialog';
import { useDeepCompareEffect } from 'react-use';
import { useEffect } from 'react';
import { PROJECT_ATTENDEE_MESSAGE_DESTINATIONS } from 'constants/staffing';
import SendProjectCohortMessageForm from 'components/Forms/SendProjectCohortMesageForm';

const SendCohortMessageDialog = ({ handleClose, isOpen, projectAttendeeMessages, projectId }) => {
  const [messageOptions, setMessageOptions] = useState([]);

  useEffect(() => {
    if (!isOpen) {
      setMessageOptions([]);
    }
  }, [isOpen]);

  useDeepCompareEffect(() => {
    if (!projectAttendeeMessages.length || !isOpen) return;

    const cohortMessageDestinations = [PROJECT_ATTENDEE_MESSAGE_DESTINATIONS.CircleCohort];

    setMessageOptions(
      projectAttendeeMessages
        .filter(msg => cohortMessageDestinations.includes(msg.destination))
        .map(msg => ({
          value: msg.id,
          name: msg.name,
        })),
    );
  }, [projectAttendeeMessages, isOpen]);

  return (
    <FormDialog
      isOpen={isOpen}
      handleClose={handleClose}
      title="Re-Send Cohort Message"
      isTitleHasCloseIcon
    >
      <SendProjectCohortMessageForm
        closeDialog={handleClose}
        messageOptions={messageOptions}
        projectId={projectId}
      />
    </FormDialog>
  );
};

export default SendCohortMessageDialog;
