import React from 'react';
import { Typography, Box } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import GrayBox from 'components/GrayBox';
import ActivityActionButton from 'components/CustomButtons/ActivityActionButton';
import { updateEditLock } from 'redux/actions/liveScribingActions';
import { useLiveScribingSelector } from 'redux/selectors/liveScribingSelectors';

/**
 * Live Scribing view for the 'write' phase on the Facilitator side
 */
const LiveScribingAdminWrite = () => {
  const userId = useSelector(state => state.firebase.auth.uid);
  const {
    id,
    state: { editUserId },
  } = useLiveScribingSelector();
  const editLockedByOtherUser = Boolean(editUserId !== '' && editUserId !== userId);
  const currentUserEditing = editUserId === userId;
  const dispatch = useDispatch();

  const onEdit = () => {
    dispatch(updateEditLock(id, userId));
  };

  const onDoneEditing = () => {
    dispatch(updateEditLock(id, ''));
  };

  return (
    <>
      <GrayBox className="mb-8">
        <Typography>
          {editLockedByOtherUser
            ? 'Editing currently locked by co-facilitator'
            : currentUserEditing
              ? 'Editing'
              : 'Click EDIT to begin editing below...'}
        </Typography>
      </GrayBox>
      {!currentUserEditing && (
        <Box display="flex" justifyContent="center">
          <ActivityActionButton text="Edit" onClick={onEdit} disabled={editLockedByOtherUser} />
        </Box>
      )}
      {currentUserEditing && (
        <Box display="flex" justifyContent="center">
          <ActivityActionButton text="Done Editing" onClick={onDoneEditing} />
        </Box>
      )}
    </>
  );
};

export default LiveScribingAdminWrite;
