import React from 'react';
import MuralCanvas from 'components/MuralCanvas';
// selectors
import { useMuralSelector } from 'redux/selectors/muralSelectors';

/**
 * Mural view for the 'mural' phase on the Participant side
 */
const MuralParticipantMural = () => {
  const {
    config: { muralUrl },
  } = useMuralSelector();

  return <MuralCanvas muralURL={muralUrl} />;
};

export default MuralParticipantMural;
