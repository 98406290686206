import React, { useState } from 'react';
import { sortBy, map, find, size, remove, includes } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
// library components
import { DragDropContext } from 'react-beautiful-dnd';
// cr components
import Flow from 'components/NoteAndMap/Participant/Flow';
// selectors
import {
  useNoteAndMapSelector,
  getParticipantsFlowsVotesResults,
  getAllTypes,
} from 'redux/selectors/noteAndMapSelectors';
// actions
import {
  updateCombineMoments,
  updateCombineMomentsForOneFlow,
  removeMoment,
} from 'redux/actions/noteAndMapActions';

/**
 * Note And Map view for the 'voteResults' phase
 */
const VoteResultsFlows = ({ context }) => {
  const dispatch = useDispatch();
  const {
    id,
    participantData: { flows },
    state: { activeView },
  } = useNoteAndMapSelector();

  // get all data for moments
  const allTypes = useSelector(getAllTypes());
  // get participants flows with active moments
  const participantsFlows = useSelector(getParticipantsFlowsVotesResults());
  const sortedFlows = sortBy(participantsFlows, 'participantId');

  // user start drag moment
  const [isDragStart, setIsDragStart] = useState(false);
  // combine moments with cards in stack
  const [combineMoment, setCombineMoment] = useState({});

  // remove cards from stack
  const removeCardFromStack = (
    flow,
    combineFlow,
    momentType,
    combineMomentType,
    momentId,
    combineMomentId,
  ) => {
    const newCombineMoment = {
      ...combineMoment,
      combineMomentsId: remove(combineMoment.combineMomentsId, combineMomentId),
    };
    setCombineMoment(newCombineMoment);
    if (flow === combineFlow) {
      dispatch(
        updateCombineMomentsForOneFlow(
          id,
          flow,
          momentType,
          combineMomentType,
          momentId,
          combineMomentId,
          false,
        ),
      );
      return;
    }
    dispatch(
      updateCombineMoments(
        id,
        flow,
        combineFlow,
        momentType,
        combineMomentType,
        momentId,
        combineMomentId,
        false,
      ),
    );
  };

  // on open combine list
  const handleOpenCombineList = (type, flowId) => {
    if (size(combineMoment)) {
      setCombineMoment({});
    } else {
      const moment = flows[flowId].types[type];
      setCombineMoment(moment);
    }
  };

  // User start drag moment.
  const onDragStart = () => {
    setIsDragStart(true);
  };

  // User finished drag moment. Combine moments/remove moments from stack
  const onDragEnd = result => {
    setIsDragStart(false);

    // if user add card to stack
    if (result.combine || result.destination) {
      const combineMomentId = result.draggableId;
      const momentId = result.combine ? result.combine.draggableId : result.destination.droppableId;

      if (combineMomentId === momentId) {
        return;
      }

      const combineMomentType = find(allTypes, type => type.id === combineMomentId);
      const momentType = find(allTypes, type => type.id === momentId);

      const combineFlow = combineMomentType?.flowId;
      const flow = momentType?.flowId;

      if (!flow) {
        return;
      }
      // combine cards in one flow
      if (flow === combineFlow) {
        dispatch(
          updateCombineMomentsForOneFlow(
            id,
            flow,
            momentType?.type,
            combineMomentType?.type,
            momentId,
            combineMomentId,
            true,
          ),
        );
        return;
      }

      if (includes(combineMoment.combineMomentsId, combineMomentId)) {
        const prevMomentType = find(allTypes, type => type.id === combineMoment.id);

        removeCardFromStack(
          flow,
          combineFlow,
          prevMomentType?.type,
          combineMomentType?.type,
          combineMoment.id,
          combineMomentId,
        );
      }

      if (combineMomentType?.combineMomentsId?.length) {
        return;
      }

      dispatch(
        updateCombineMoments(
          id,
          flow,
          combineFlow,
          momentType?.type,
          combineMomentType?.type,
          momentId,
          combineMomentId,
          true,
        ),
      );
    } else {
      const combineMomentId = result.draggableId;
      let momentId = result.source.droppableId;

      if (combineMomentId === momentId && !size(combineMoment)) {
        return;
      }

      if (combineMomentId === momentId) {
        momentId = combineMoment.id;
      }

      const combineMomentType = find(allTypes, type => type.id === combineMomentId);
      let momentType = find(allTypes, type => type.id === momentId);

      const combineFlow = combineMomentType?.flowId;
      const flow = momentType?.flowId;

      if (!flow) {
        return;
      }

      removeCardFromStack(
        flow,
        combineFlow,
        momentType?.type,
        combineMomentType?.type,
        momentId,
        combineMomentId,
      );
    }
  };

  // remove moment
  const handleRemoveMoment = (flowId, type, isRemoved, event) => {
    if (event.target.id === 'combine-list') {
      // don't remove moment if click on combine list open
      return;
    }
    const moment = flows?.[flowId]?.types?.[type];
    if (moment?.parentMomentId) {
      // don't remove moment if moment in combine list
      return;
    }
    dispatch(removeMoment(id, flowId, type, isRemoved));
  };

  const renderFlow = (flow, idx, flowParticipantId) => {
    return (
      <Flow
        key={`moment-${idx}`}
        participantFlow={flow}
        context={activeView}
        disabled={true}
        createdFlowParticipantId={flowParticipantId}
        isDragStart={isDragStart}
        handleOpenCombineList={handleOpenCombineList}
        combineMoment={combineMoment}
        hasCombineList={true}
        isRemoveMoment={context === 'adminReview'}
        handleVote={handleRemoveMoment}
      />
    );
  };

  return (
    <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
      {map(sortedFlows, (flow, idx) => renderFlow(flow.types, idx, flow.participantId))}
    </DragDropContext>
  );
};

export default VoteResultsFlows;
