import { POLLING_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';
import { enqueueErrorSnackbar } from 'redux/actions/notifierActions';
import { includes } from 'lodash';
import { generateRandomId } from 'utils/generateRandomId';
import { createOutput } from 'utils/polling';

/**
 * Create a note
 *
 * @param {String} note
 * @param {String} participantId
 * @param {String} pollingId
 */
export const createNote =
  (value, pollingId, order, successCb) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;
    const newNoteId = generateRandomId();

    db()
      .doc(`${CONFIG.collection}/${pollingId}`)
      .set(
        {
          config: {
            notes: {
              [`${newNoteId}`]: {
                id: newNoteId,
                value,
                order,
              },
            },
          },
          participantData: {
            votes: {
              [`${newNoteId}`]: [],
            },
          },
        },
        { merge: true },
      )
      .then(() => {
        if (successCb) {
          successCb();
        }
      })
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error creating note'));
      });
  };

/**
 * Edit a note that has already been submitted
 * @param {String} value
 * @param {String} noteId
 * @param {String} pollingId
 * @param {Function} successCb
 */
export const editNote =
  (value, noteId, pollingId, successCb, errorCb) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${pollingId}`)
      .set(
        {
          config: {
            notes: {
              [`${noteId}`]: {
                value,
              },
            },
          },
        },
        { merge: true },
      )
      .then(() => {
        if (successCb) {
          successCb();
        }
      })
      .catch(err => {
        console.error(err);
        errorCb();
        dispatch(enqueueErrorSnackbar('Error editing note'));
      });
  };

/**
 * Delete a note
 * @param {String} noteId
 * @param {String} pollingId
 * @param {Function} successCb
 */
export const deleteNote =
  (noteId, pollingId, successCb) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${pollingId}`)
      .update({
        [`config.notes.${noteId}`]: db.FieldValue.delete(),
        [`participantData.votes.${noteId}`]: db.FieldValue.delete(),
      })
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error removing note'));
      });
  };

/**
 * Update the active view for the activity
 * @param {String} view
 * @param {String} pollingId
 */
export const updateActivityView =
  (view, pollingId) =>
  (dispatch, getState, { getFirebase }) => {
    if (!includes(CONFIG.views, view)) {
      console.error(`View '${view}' does not exist in the activity config`);
      return;
    }

    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${pollingId}`)
      .set(
        {
          state: {
            activeView: view,
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error changing to Submit Ideas view'));
      });
  };

/**
 * Record a vote for a note
 * @param {Boolean} voted
 * @param {String} noteId
 * @param {String} participantId
 * @param {String} pollingId
 * @param {Function} successCb
 */
export const voteForNote =
  (voted, noteId, participantId, pollingId, successCb) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;
    const value = voted
      ? db.FieldValue.arrayUnion(participantId)
      : db.FieldValue.arrayRemove(participantId);

    db()
      .doc(`${CONFIG.collection}/${pollingId}`)
      .set(
        {
          participantData: {
            votes: {
              [`${noteId}`]: value,
            },
          },
        },
        { merge: true },
      )
      .then(() => {
        successCb();
      })
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error adding vote'));
      });
  };

/**
 * Create the activity output and update the view to 'results'
 * @param {String} pollingId
 */
export const revealResults =
  (notes, pollingId) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;
    const output = createOutput(notes);

    db()
      .doc(`${CONFIG.collection}/${pollingId}`)
      .set(
        {
          output,
        },
        { merge: true },
      )
      .then(() => {
        dispatch(updateActivityView('results', pollingId));
      })
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error generating results'));
      });
  };
