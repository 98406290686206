import { enqueueErrorSnackbar } from 'redux/actions/notifierActions';
import { includes } from 'lodash';
import { generateRandomId } from 'utils/generateRandomId';
import { createOutput } from 'utils/howMightWe';

import { HOW_MIGHT_WE_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';

/**
 * Update the active view for the activity
 * @param {String} view
 * @param {String} howMightWeId
 */
export const updateActivityView =
  (view, howMightWeId) =>
  (dispatch, getState, { getFirebase }) => {
    if (!includes(CONFIG.views, view)) {
      console.error(`View '${view}' does not exist in the activity config`);
      return;
    }

    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${howMightWeId}`)
      .set(
        {
          state: {
            activeView: view,
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error changing activity view'));
      });
  };

/**
 * Create a idea
 *
 * @param {String} idea
 * @param {String} participantId
 * @param {String} howMightWeId
 * @param {String} groupId
 * @param {String} order
 * @param {Function} successCb
 */
export const createIdea =
  (idea, participantId, howMightWeId, groupId, order, successCb) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;
    const newIdeaId = generateRandomId();

    db()
      .doc(`${CONFIG.collection}/${howMightWeId}`)
      .set(
        {
          participantData: {
            ideas: {
              [`${newIdeaId}`]: {
                participantId,
                ideaId: newIdeaId,
                idea,
                groupId: groupId,
                isShared: false,
                order: order,
                parentIdeaId: '',
              },
            },
          },
        },
        { merge: true },
      )
      .then(() => {
        successCb();
      })
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error creating idea'));
      });
  };

/**
 * Edit a idea that has already been submitted
 * @param {String} idea
 * @param {String} ideaId
 * @param {String} howMightWeId
 * @param {Function} successCb
 */
export const editIdea =
  (idea, ideaId, howMightWeId, successCb) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${howMightWeId}`)
      .set(
        {
          participantData: {
            ideas: {
              [`${ideaId}`]: {
                idea,
              },
            },
          },
        },
        { merge: true },
      )
      .then(() => {
        successCb();
      })
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error editing idea'));
      });
  };

/**
 * Update shared participant idea
 * @param {String} howMightWeId
 * @param {String} ideaId
 * @param {Boolean} isShared
 */
export const updateSharedIdea =
  (howMightWeId, ideaId, isShared) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${howMightWeId}`)
      .set(
        {
          participantData: {
            ideas: {
              [ideaId]: {
                isShared: isShared,
              },
            },
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error update idea'));
      });
  };

/**
 * Create a group
 *
 * @param {String} idea
 * @param {String} participantId
 * @param {String} howMightWeId
 */
export const createGroup =
  (howMightWeId, participantId, order) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;
    const newGroupId = generateRandomId();

    db()
      .doc(`${CONFIG.collection}/${howMightWeId}`)
      .set(
        {
          participantData: {
            groups: {
              [`${newGroupId}`]: {
                participantId,
                id: newGroupId,
                value: 'Untitled',
                order: order,
              },
            },
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error creating group'));
      });
  };

/**
 * Delete a group
 *
 * @param {String} idea
 * @param {String} participantId
 * @param {String} howMightWeId
 */
export const deleteGroup =
  (howMightWeId, groupId) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;
    const path = `participantData.groups.${groupId}`;

    db()
      .doc(`${CONFIG.collection}/${howMightWeId}`)
      .update({
        [path]: db.FieldValue.delete(),
      })
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error deleting group'));
      });
  };

/**
 * Edit a group name
 *
 * @param {String} groupId
 * @param {String} value
 * @param {String} howMightWeId
 * @param {String} participantId
 */
export const editGroupName =
  (howMightWeId, groupId, value, participantId) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${howMightWeId}`)
      .set(
        {
          participantData: {
            groups: {
              [groupId]: {
                value: value,
                editName: {
                  [participantId]: {
                    id: participantId,
                    value: value,
                  },
                },
              },
            },
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error editing group name'));
      });
  };

/**
 * Update id group idea
 *
 * @param {String} groupId
 * @param {String} ideaId
 * @param {String} howMightWeId
 */
export const updateIdGroupIdea =
  (howMightWeId, ideaId, groupId) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${howMightWeId}`)
      .set(
        {
          participantData: {
            ideas: {
              [ideaId]: {
                groupId: groupId,
              },
            },
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error update id group idea'));
      });
  };

/**
 * Add participant id in voted participant for idea
 *
 * @param {String} participantId
 * @param {String} ideaId
 * @param {String} howMightWeId
 */
export const addParticipantVote =
  (howMightWeId, ideaId, participantId) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${howMightWeId}`)
      .set(
        {
          participantData: {
            ideas: {
              [ideaId]: {
                votedParticipants: db.FieldValue.arrayUnion(participantId),
              },
            },
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error adding participant id in voted participant for idea'));
      });
  };

/**
 * Remove participant id from voted participant for idea
 *
 * @param {String} participantId
 * @param {String} ideaId
 * @param {String} howMightWeId
 */
export const removeParticipantVote =
  (howMightWeId, ideaId, participantId) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${howMightWeId}`)
      .set(
        {
          participantData: {
            ideas: {
              [ideaId]: {
                votedParticipants: db.FieldValue.arrayRemove(participantId),
              },
            },
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(
          enqueueErrorSnackbar('Error removing participant id from voted participant for idea'),
        );
      });
  };

/**
 * Update id group idea
 *
 * @param {String} groupId
 * @param {String} ideaId
 * @param {String} howMightWeId
 */
export const updateCombineIdeas =
  (howMightWeId, ideaId, combineIdeaId, isCombine) =>
  (dispatch, getState, { getFirebase }) => {
    if (ideaId === combineIdeaId && isCombine) return;

    const db = getFirebase().firestore;
    const combineIdeasId = isCombine
      ? db.FieldValue.arrayUnion(combineIdeaId)
      : db.FieldValue.arrayRemove(combineIdeaId);

    db()
      .doc(`${CONFIG.collection}/${howMightWeId}`)
      .set(
        {
          participantData: {
            ideas: {
              [`${ideaId}`]: {
                combineIdeasId,
              },
              [`${combineIdeaId}`]: {
                parentIdeaId: isCombine ? ideaId : '',
                isCombine,
              },
            },
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error update combine ideas'));
      });
  };

/**
 * Create the activity output and update the view to 'results'
 * @param {String} howMightWeId
 */
export const revealResults =
  howMightWeId =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;
    const state = getState();
    const { ideas } = state.firestore.data.howMightWe.participantData;
    const output = createOutput(ideas);

    db()
      .doc(`${CONFIG.collection}/${howMightWeId}`)
      .set(
        {
          output,
        },
        { merge: true },
      )
      .then(() => {
        dispatch(updateActivityView('results', howMightWeId));
      })
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error generating results'));
      });
  };
