import { NOTE_AND_VOTE_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';
import { enqueueErrorSnackbar } from 'redux/actions/notifierActions';
import { includes } from 'lodash';
import { generateRandomId } from 'utils/generateRandomId';
import { createOutput } from 'utils/noteAndVote';

/**
 * Create a note
 *
 * @param {String} note
 * @param {String} participantId
 * @param {String} noteAndVoteId
 * @param {Number} noteOrder
 */
export const createNote =
  (note, participantId, noteAndVoteId, noteOrder, successCb) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;
    const newNoteId = generateRandomId();

    db()
      .doc(`${CONFIG.collection}/${noteAndVoteId}`)
      .set(
        {
          participantData: {
            notes: {
              [`${newNoteId}`]: {
                participantId,
                noteId: newNoteId,
                note,
                selected: false,
                votedFor: [],
                order: noteOrder,
              },
            },
          },
        },
        { merge: true },
      )
      .then(() => {
        successCb();
      })
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error creating note'));
      });
  };

/**
 * Edit a note that has already been submitted
 * @param {String} note
 * @param {String} noteId
 * @param {String} noteAndVoteId
 * @param {Function} successCb
 */
export const editNote =
  (note, noteId, noteAndVoteId, successCb) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${noteAndVoteId}`)
      .set(
        {
          participantData: {
            notes: {
              [`${noteId}`]: {
                note,
              },
            },
          },
        },
        { merge: true },
      )
      .then(() => {
        successCb();
      })
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error editing note'));
      });
  };

/**
 * Update the active view for the activity
 * @param {String} view
 * @param {String} noteAndVoteId
 */
export const updateActivityView =
  (view, noteAndVoteId) =>
  (dispatch, getState, { getFirebase }) => {
    if (!includes(CONFIG.views, view)) {
      console.error(`View '${view}' does not exist in the activity config`);
      return;
    }

    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${noteAndVoteId}`)
      .set(
        {
          state: {
            activeView: view,
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error changing to Submit Ideas view'));
      });
  };

/**
 * Mark a note as shared
 * @param {Boolean} shared
 * @param {String} noteId
 * @param {String} noteAndVoteId
 * @param {Function} successCb
 */
export const shareNote =
  (shared, noteId, noteAndVoteId, successCb) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${noteAndVoteId}`)
      .set(
        {
          participantData: {
            notes: {
              [`${noteId}`]: { shared },
            },
          },
        },
        { merge: true },
      )
      .then(() => {
        successCb();
      })
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error sharing note'));
      });
  };

/**
 * Swap a shared note for another
 * @param {String} noteIdToShare
 * @param {String} noteIdToUnshare
 * @param {String} noteAndVoteId
 * @param {Function} successCb
 */
export const swapSharedNote =
  (noteIdToShare, noteIdToUnshare, noteAndVoteId, successCb) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${noteAndVoteId}`)
      .set(
        {
          participantData: {
            notes: {
              [`${noteIdToShare}`]: { shared: true },
              [`${noteIdToUnshare}`]: { shared: false },
            },
          },
        },
        { merge: true },
      )
      .then(() => {
        successCb();
      })
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error changing shared note'));
      });
  };

/**
 * Record a vote for a note
 * @param {Boolean} voted
 * @param {String} noteId
 * @param {String} participantId
 * @param {String} noteAndVoteId
 * @param {Function} successCb
 */
export const voteForNote =
  (voted, noteId, participantId, noteAndVoteId, successCb) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;
    const value = voted
      ? db.FieldValue.arrayUnion(participantId)
      : db.FieldValue.arrayRemove(participantId);

    db()
      .doc(`${CONFIG.collection}/${noteAndVoteId}`)
      .set(
        {
          participantData: {
            notes: {
              [`${noteId}`]: { votedFor: value },
            },
          },
        },
        { merge: true },
      )
      .then(() => {
        successCb();
      })
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error voting'));
      });
  };

/**
 * Create the activity output and update the view to 'results'
 * @param {String} noteAndVoteId
 */
export const revealResults =
  noteAndVoteId =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;
    const state = getState();
    const { notes } = state.firestore.data.noteAndVote.participantData;
    const output = createOutput(notes);

    db()
      .doc(`${CONFIG.collection}/${noteAndVoteId}`)
      .set(
        {
          output,
        },
        { merge: true },
      )
      .then(() => {
        dispatch(updateActivityView('results', noteAndVoteId));
      })
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error generating results'));
      });
  };

/**
 * Update combine notes
 * @param {String} noteAndVoteId
 * @param {String} noteId
 * @param {String} combineNoteId
 * @param {Boolean} combine
 */
export const updateCombineNotes =
  (noteAndVoteId, noteId, combineNoteId, combine) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;
    const combineNotesId = combine
      ? db.FieldValue.arrayUnion(combineNoteId)
      : db.FieldValue.arrayRemove(combineNoteId);

    db()
      .doc(`${CONFIG.collection}/${noteAndVoteId}`)
      .set(
        {
          participantData: {
            notes: {
              [`${noteId}`]: {
                combineNotesId,
              },
              [`${combineNoteId}`]: {
                combine,
              },
            },
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error combine notes'));
      });
  };
