import React from 'react';
import PropTypes from 'prop-types';
import { useFirestore, useFirebase } from 'react-redux-firebase';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
// library components
import { Formik, Form } from 'formik';
import { Button } from '@material-ui/core';
// cr components
import ButtonSpinner from 'components/ButtonSpinner/ButtonSpinner';
import TextInput from 'components/CustomFormComponents/TextInput';
import ParticipantNameForm from 'components/ParticipantNamePrompt/ParticipantNameForm';
// actions
import { enqueueErrorSnackbar } from 'redux/actions/notifierActions';

/**
 * Form used by particicpants to change their display name
 */
const ParticipantRenameForm = ({ closeDialog }) => {
  const db = useFirestore();
  const dispatch = useDispatch();
  const authUser = useSelector(state => state.firebase.auth);
  const session = useSelector(state => state.firestore.data.session);
  const firebase = useFirebase();

  const formSchema = Yup.object().shape({
    displayName: Yup.string().required('Required'),
  });

  return (
    <Formik
      initialValues={{
        displayName: authUser.displayName,
      }}
      validationSchema={formSchema}
      onSubmit={async (values, { resetForm, setSubmitting }) => {
        const currentUser = firebase.auth().currentUser;
        const batch = db.batch();

        batch.set(
          db.doc(`participants/${session.id}`),
          {
            [currentUser.uid]: {
              name: values.displayName,
            },
          },
          { merge: true },
        );

        return batch
          .commit()
          .then(async () => {
            await firebase.updateAuth(
              {
                displayName: values.displayName,
              },
              !currentUser.isAnonymous,
            );
            closeDialog();
          })
          .catch(err => {
            dispatch(enqueueErrorSnackbar('Error updating display name'));
            console.log('Error updating particicpant diplay name: ', err);
          });
      }}
    >
      {({ isSubmitting, submitForm, resetForm }) => (
        <Form style={{ paddingBottom: 20 }}>
          <TextInput
            name="displayName"
            type="text"
            label="Display Name"
            className="mb-4"
            placeholder="Enter your first and last name"
            required
          />

          <div className="form-dialog-buttons">
            <Button
              variant="outlined"
              color="primary"
              disabled={isSubmitting}
              onClick={() => {
                closeDialog();
                resetForm();
              }}
            >
              CANCEL
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={submitForm}
            >
              SAVE
              {isSubmitting && <ButtonSpinner />}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

ParticipantNameForm.propTypes = {
  closeDialog: PropTypes.func.isRequired,
};

export default ParticipantRenameForm;
