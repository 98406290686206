import { NARROWING_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';
import { enqueueErrorSnackbar } from 'redux/actions/notifierActions';
import { includes } from 'lodash';
import { generateRandomId } from 'utils/generateRandomId';
import { createOutput } from 'utils/narrowing';

/**
 * Update the active view for the activity
 * @param {String} narrowingId
 * @param {String} view
 */
export const updateActivityView =
  (narrowingId, view) =>
  (dispatch, getState, { getFirebase }) => {
    if (!includes(CONFIG.views, view)) {
      console.error(`View '${view}' does not exist in the activity config`);
      return;
    }

    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${narrowingId}`)
      .set(
        {
          state: {
            activeView: view,
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error changing view'));
      });
  };

/**
 * Create card
 *
 * @param {String} narrowingId
 * @param {String} cardSetId
 * @param {String} order
 * @param {String} icon
 * @param {String} text
 * @param {String} imageUrl
 */
export const createCard =
  (narrowingId, cardSetId, order, icon, text, imageUrl) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    const cardId = generateRandomId();

    db()
      .doc(`${CONFIG.collection}/${narrowingId}`)
      .set(
        {
          participantData: {
            cards: {
              [cardId]: {
                order,
                icon,
                text,
                imageUrl,
                cardId,
                cardSetId,
              },
            },
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error create cards'));
      });
  };

/**
 * Delete cards
 *
 * @param {String} narrowingId
 * @param {Function} successCb
 */
export const deleteCards =
  (narrowingId, successCb) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    const path = `participantData.cards`;

    db()
      .doc(`${CONFIG.collection}/${narrowingId}`)
      .update({
        [path]: db.FieldValue.delete(),
      })
      .then(() => {
        if (successCb) successCb();
      })
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error delete cards'));
      });
  };

/**
 * Update category in cards
 *
 * @param {String} narrowingId
 * @param {String} participantId
 * @param {String} categoryId
 * @param {String} cardId
 * @param {String} order
 */
export const updateCardCategory =
  (narrowingId, participantId, categoryId, cardId, order) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${narrowingId}`)
      .set(
        {
          participantData: {
            round1: {
              [participantId]: {
                [categoryId]: {
                  [cardId]: {
                    order,
                    cardId,
                  },
                },
              },
            },
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error update group in cards'));
      });
  };

/**
 * Delete card from category
 *
 * @param {String} narrowingId
 * @param {String} participantId
 * @param {String} categoryId
 * @param {String} cardId
 */
export const deleteCardFromCategory =
  (narrowingId, participantId, categoryId, cardId) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    const path = `participantData.round1.${participantId}.${categoryId}.${cardId}`;

    db()
      .doc(`${CONFIG.collection}/${narrowingId}`)
      .update({
        [path]: db.FieldValue.delete(),
      })
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error delete card from group'));
      });
  };

/**
 * Update the activity round
 * @param {String} narrowingId
 * @param {Number} round
 */
export const updateRound =
  (narrowingId, round) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${narrowingId}`)
      .set(
        {
          state: {
            round: round,
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error changing round'));
      });
  };

/**
 * Update the activity count cards
 * @param {String} narrowingId
 * @param {Number} cardsCount
 */
export const updateCardsCount =
  (narrowingId, cardsCount) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${narrowingId}`)
      .set(
        {
          state: {
            cardsCount: cardsCount,
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error changing cards count'));
      });
  };

/**
 * Update cards in round
 *
 * @param {String} narrowingId
 * @param {String} participantId
 * @param {String} round
 * @param {String} cardId
 * @param {String} order
 */
export const updateCardRound =
  (narrowingId, participantId, round, cardId, order) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${narrowingId}`)
      .set(
        {
          participantData: {
            [round]: {
              [participantId]: {
                [cardId]: {
                  order,
                  cardId,
                },
              },
            },
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error update cards in round'));
      });
  };

/**
 * Delete card from round
 *
 * @param {String} narrowingId
 * @param {String} participantId
 * @param {String} round
 * @param {String} cardId
 */
export const deleteCardFromRound =
  (narrowingId, participantId, round, cardId) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    const path = `participantData.${round}.${participantId}.${cardId}`;

    db()
      .doc(`${CONFIG.collection}/${narrowingId}`)
      .update({
        [path]: db.FieldValue.delete(),
      })
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error delete card from round'));
      });
  };

/**
 * Update the activity finished
 * @param {String} narrowingId
 * @param {Boolean} isFinished
 * @param {Array} reportData
 */
export const updateFinishedActivity =
  (narrowingId, isFinished, reportData) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    const output = createOutput(reportData);

    db()
      .doc(`${CONFIG.collection}/${narrowingId}`)
      .set(
        {
          output,
          state: {
            isActivityFinished: isFinished,
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error finished activity'));
      });
  };

/**
 * Delete card from next round
 *
 * @param {String} narrowingId
 * @param {String} round
 * @param {String} participantId
 * @param {String} cardId
 * @param {Boolean} isNotIncludeNextRound
 */
export const updateCardNextRound =
  (narrowingId, round, participantId, cardId, isNotIncludeNextRound) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${narrowingId}`)
      .set(
        {
          participantData: {
            [round]: {
              [participantId]: {
                [cardId]: {
                  isNotInclude: isNotIncludeNextRound,
                },
              },
            },
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error update cards in round'));
      });
  };
