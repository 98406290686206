import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { filter } from 'lodash';
// library components
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// cr components
import ActivityParticipantDetails from 'components/ActivityParticipantDetails';
import ActivityPromptAdmin from 'components/ActivityPromptAdmin';
import ActivityActionButton from 'components/CustomButtons/ActivityActionButton';
// selectors
import {
  useHeatMappingSelector,
  getParticipantsCompletedVote,
} from 'redux/selectors/heatMappingSelectors';
// actions
import { updateActivityView } from 'redux/actions/heatMappingActions';
// config
import { HEAT_MAPPING_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';
// utils
import { makeParticipantDetailsHeaderText } from 'utils/makeParticipantDetailsHeaderText';

const useStyles = makeStyles(theme => ({
  promptAdmin: {
    marginBottom: theme.spacing(5),
  },
}));

/**
 * Heat-Mapping view for the 'strawPoll' phase on the Facilitator side
 */
const HeatMappingAdminStrawPoll = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const heatMapping = useHeatMappingSelector();
  const { id, prompt } = heatMapping;
  const participantsHaveVoted = useSelector(getParticipantsCompletedVote());
  const participantsHaveVotedCount = filter(participantsHaveVoted, { completed: true }).length;

  const participantsHaveVotedCountText = makeParticipantDetailsHeaderText(
    participantsHaveVotedCount,
    participantsHaveVoted.length,
    'voted',
  );

  const onUpdateActivityView = () => {
    dispatch(updateActivityView('strawPollResults', id));
  };

  return (
    <Box className="d-flex flex-column justify-content-center">
      <ActivityPromptAdmin
        prompt={prompt}
        collection={CONFIG.collection}
        activityId={id}
        className={classes.promptAdmin}
      />
      <ActivityParticipantDetails
        headerText={participantsHaveVotedCountText}
        hasCompletedLabel="Voted"
        hasntCompletedLabel="Hasn't Voted"
        participantsCompletedStatus={participantsHaveVoted}
      />
      <ActivityActionButton
        text="Review results"
        className="mb-2 mt-1"
        onClick={onUpdateActivityView}
        delayReady
      />
    </Box>
  );
};

export default HeatMappingAdminStrawPoll;
