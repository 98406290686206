import { map, orderBy, filter, join, forEach } from 'lodash';

/**
 * Creates the output array for a Lightning Demo actvity
 * @param {Object} demos
 * @returns {Array<{image: String, bigIdeas: String, source: String, url: String}>}
 */
export const createOutput = demos => {
  const filteredDemos = filter(demos, demo => !!demo.imageId);

  // create initial output array
  const output = map(filteredDemos, demo => ({
    image: demo.imageUrl,
    bigIdea: demo.bigIdeas,
    source: demo.source,
    url: demo.url,
  }));

  // sort array and return
  return orderBy(output, ['bigIdeas'], ['desc', 'asc']);
};

/**
 * Creates the array for Report Header
 *
 */
export const createLightningDemoReportHeader = () => [
  { label: 'Big Idea', key: 'bigIdea' },
  { label: 'Source', key: 'source' },
  { label: 'URL', key: 'url' },
  { label: 'Image URL', key: 'imageUrl' },
];

/**
 * Creates the data for Report
 *
 */
export const createLightningDemoReportData = output => {
  return map(output, demo => {
    return {
      bigIdea: demo.bigIdea,
      source: demo.source,
      url: demo.url,
      imageUrl: demo.image,
    };
  });
};

export const createLightningDemoReport = activityData => {
  const headers = createLightningDemoReportHeader();
  const output = createOutput(activityData?.participantData?.demos);
  const data = createLightningDemoReportData(output);
  return { headers, data };
};
