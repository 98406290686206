import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import PageContent from 'components/PageContent/PageContent';
import PageContentHeader from 'components/PageContent/PageContentHeader';
import PageContentBody from 'components/PageContent/PageContentBody';
import CrLogo from 'components/CrLogo/CrLogo';
import ParticipantMeetingProgress from 'components/ParticipantMeetingProgress';

import '../../breakoutStyles.scss';

const BreakoutsParticipantTemplate = ({ children, sessionName, actionBtn }) => {
  const session = useSelector(state => state.firestore.data.session);
  return (
    <GridContainer
      direction="row"
      justify="center"
      alignItems="center"
      className={`session-${sessionName}`}
    >
      <GridItem xs={12} sm={12} md={12} lg={12} xl={8}>
        <PageContent>
          <PageContentHeader className="with-logo">
            <div className="left-col">
              <CrLogo withLink session={session} />
            </div>
            <Typography variant="h5" style={{ fontWeight: 'bold' }} className="page-content-title">
              {sessionName}
            </Typography>
            <div className="right-col">{actionBtn}</div>
          </PageContentHeader>
          <ParticipantMeetingProgress session={session} />
          <PageContentBody contentCenter>{children}</PageContentBody>
        </PageContent>
      </GridItem>
    </GridContainer>
  );
};

BreakoutsParticipantTemplate.propTypes = {
  children: PropTypes.node,
  sessionName: PropTypes.string,
  actionBtn: PropTypes.node,
};

export default BreakoutsParticipantTemplate;
