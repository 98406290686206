import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { find } from 'lodash';
// library components
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// cr components
import ActivityParticipantDetails from 'components/ActivityParticipantDetails';
import ActivityPromptAdmin from 'components/ActivityPromptAdmin';
import ActivityActionButton from 'components/CustomButtons/ActivityActionButton';
import FormDialog from 'components/Dialogs/FormDialog';
import SelectSuperVoteParticipantForm from 'components/Forms/SelectSuperVoteParticipantForm';
// selectors
import {
  useHeatMappingSelector,
  getParticipantsCompletedSuperVote,
} from 'redux/selectors/heatMappingSelectors';
import { getParticipantsMap } from 'redux/selectors/participantsSelectors';
// actions
import { revealResults, updateSuperVoteParticipant } from 'redux/actions/heatMappingActions';
// config
import { HEAT_MAPPING_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';

const useStyles = makeStyles(theme => ({
  promptAdmin: {
    marginBottom: theme.spacing(5),
  },
  selectTitle: {
    marginBottom: theme.spacing(1),
  },
  select: {
    width: '100%',
    marginBottom: theme.spacing(5),
  },
}));

/**
 * Heat-Mapping view for the 'superVote' phase on the Facilitator side
 */
const HeatMappingAdminSuperVote = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const participantsData = useSelector(getParticipantsMap);
  const heatMapping = useHeatMappingSelector();
  const {
    id,
    prompt,
    participantData: { participants },
    state: { superVoteParticipantId },
  } = heatMapping;
  const participantsHaveSuperVoteComplete = useSelector(getParticipantsCompletedSuperVote());
  const isSuperVoteComplete = find(
    participantsHaveSuperVoteComplete,
    votes => votes.id === superVoteParticipantId && votes.completed,
  );

  const [isModalOpen, setIsModalOpen] = useState(!superVoteParticipantId);

  const participantsSuperVoteText = isSuperVoteComplete
    ? 'Decider has placed super votes'
    : 'Decider is placing super votes';

  const onUpdateActivityView = () => {
    dispatch(revealResults(id));
  };

  // Close modal form
  const closeDialog = () => {
    setIsModalOpen(false);
  };

  // Add super vote participant id
  const submitSuperVoteParticipantId = participantId => {
    dispatch(updateSuperVoteParticipant(id, participantId));
    closeDialog();
  };

  return (
    <Box className="d-flex flex-column justify-content-center">
      <ActivityPromptAdmin
        prompt={prompt}
        collection={CONFIG.collection}
        activityId={id}
        className={classes.promptAdmin}
      />
      <ActivityParticipantDetails
        headerText={participantsSuperVoteText}
        hasCompletedLabel="Voted"
        hasntCompletedLabel="Hasn't Voted"
        completedCountMax={3} // There are a total of 3 supervotes, so that's the max they can add. This should probably be a constant somewhere.
        participantsCompletedStatus={participantsHaveSuperVoteComplete}
      />
      <ActivityActionButton
        text="Review Final Results"
        className="mb-2 mt-1"
        onClick={onUpdateActivityView}
        delayReady
      />
      <FormDialog
        handleClose={() => setIsModalOpen(true)}
        isOpen={isModalOpen}
        title={'Start Super Vote'}
      >
        <SelectSuperVoteParticipantForm
          participantsData={participantsData}
          participants={participants}
          submitSuperVoteParticipantId={submitSuperVoteParticipantId}
        />
      </FormDialog>
    </Box>
  );
};

export default HeatMappingAdminSuperVote;
