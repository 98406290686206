import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { each } from 'lodash';
import { orderBy } from 'lodash';
// CR components
import BasicTable from 'components/BasicTable';
// 3rd party components
import { Box } from '@material-ui/core';
// selectors
import { getQuestion } from 'redux/selectors/assessmentSelectors';

const AnswerDetailTable = ({ questionId }) => {
  const { answerCounts } = useSelector(getQuestion(questionId));
  const tableHeaders = ['Answer', 'Participants'];
  const tableRows = [];

  each(orderBy(answerCounts, ['answer'], ['asc']), answer => {
    const answerText = answer.answerLabel ? `${answer.answerLabel}` : `${answer.answer}`;

    tableRows.push([answerText, answer.participantNames.join(', ')]);
  });

  return (
    <Box>
      <BasicTable headers={tableHeaders} rows={tableRows} />
    </Box>
  );
};

AnswerDetailTable.propTypes = {
  questionId: PropTypes.string.isRequired,
};

export default React.memo(AnswerDetailTable);
