import React from 'react';
import { useField } from 'formik';
import { darken, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
// library components
import { Card, Box, Typography } from '@material-ui/core';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
// css
import './score.scss';

const buttonColors = {
  1: { color: '#E2351E', text: 'No Way.' },
  2: { color: '#F89725', text: 'Maybe?' },
  3: { color: '#AEC139', text: 'Interesting!' },
  4: { color: '#00B086', text: 'Cool!' },
  5: { color: '#0096C6', text: 'Must do it!' },
};

const ScoreOption = ({ isSelected, hasVoted, ...props }) => {
  const optionStyle = makeStyles({
    root: props => ({
      backgroundColor: buttonColors[props.value].color,
      color: 'white',
      '&:hover': {
        backgroundColor: darken(buttonColors[props.value].color, 0.2),
      },
    }),
    selected: props => ({
      backgroundColor: `${buttonColors[props.value].color} !important`,
      color: 'white !important',
      transform: 'scale(1.2)',
      boxShadow: '0px 18px 26px rgba(0, 0, 0, 0.5)',
      '@media screen and (max-width: 767px)': {
        transform: 'scale(1.1)',
      },
    }),
    label: {
      flexDirection: 'column',
    },
  });

  return (
    <ToggleButton
      {...props}
      classes={optionStyle({ isSelected, hasVoted, ...props })}
      value={props.value}
      className={clsx('score-option', {
        'score-active': props.selected,
      })}
      disableRipple
    >
      <Typography className="option-vote">{props.value}</Typography>
      <Typography className="option-text">{buttonColors[props.value].text}</Typography>
    </ToggleButton>
  );
};

const useCardStyles = makeStyles({
  root: {
    marginTop: 10,
    overflow: 'visible',
  },
});

const useButtonGroupStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
});

const Participant2510ScoreField = props => {
  const [field, meta, helpers] = useField(props);
  const cardStyles = useCardStyles();
  const buttonGroupStyles = useButtonGroupStyles();

  const handleChange = (event, value) => {
    if (props.disabled) {
      return;
    }
    helpers.setValue(value);
    props.handleSelectScore();
  };

  return (
    <Box className="score-field">
      <Card elevation={0} id="score-field" classes={cardStyles}>
        <ToggleButtonGroup
          value={meta.value}
          exclusive
          classes={buttonGroupStyles}
          onChange={handleChange}
          aria-label="vote"
        >
          {props.values.map((vote, idx) => (
            <ScoreOption
              key={`${idx}_${vote}`}
              value={vote}
              isSelected={!!field.value}
              hasVoted={props.hasVoted}
            />
          ))}
        </ToggleButtonGroup>
      </Card>
    </Box>
  );
};

export default Participant2510ScoreField;
