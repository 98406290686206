import * as yup from 'yup';

/**
 * We are doing this because the ACTIVITIES and ACTIVITY_SCHEMA used to be setup here and exported
 * but they were moved to a separate package.  This way we don't have to find everywhere
 * in the app that they were imported and change the import.
 */
export { ACTIVITIES } from '@voltage-control/control-room-activities-config';
export { ACTIVITY_SCHEMA } from '@voltage-control/control-room-activities-config';

/**
 * Default fields that all activities are
 * initialized with
 */
export const ACTIVITY_DEFAULT_FIELDS = [
  {
    key: 'createdBy',
    defaultValue: '',
  },
  {
    key: 'createdAt',
    defaultValue: '',
  },
  {
    key: 'id',
    defaultValue: '',
  },
  {
    key: 'description',
    defaultValue: '',
  },
  {
    key: 'name',
    defaultValue: '',
    validator: yup.string(),
    isSetupField: true,
    formField: {
      name: 'name',
      type: 'text',
      label: 'Name',
      required: true,
    },
  },
  {
    key: 'sessionId',
    defaultValue: '',
  },
  {
    key: 'output',
    type: 'array',
    defaultValue: [],
  },
];
