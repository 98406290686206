import React from 'react';
import { Autocomplete as MuiAutocomplete } from '@material-ui/lab';
import { makeStyles, TextField } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    position: 'relative',
  },
  input: {
    backgroundColor: '#EEEEEE',
  },
  inputRoot: {
    marginTop: 0,
    paddingLeft: 10,
    backgroundColor: '#EEEEEE',
  },
  endAdornment: {
    zIndex: 3,
  },
});

const useInputLabelStyles = makeStyles({
  root: {
    paddingLeft: 10,
    zIndex: 2,
  },
  focused: {
    paddingLeft: 0,
  },
});

const useFormControlStyles = makeStyles({
  root: {
    marginBottom: 0,
  },
});

/**
 * Control Room styled MUI Autocomplete with animated floating label.
 * This compontent is meant to be used as a base component for other autocomplete components
 * that we will extend from it.
 */
const AutoCompleteBase = ({
  renderInput, // prevent passing renderInput prop to MuiAutocomplete
  classes,
  label,
  fullWidth, // prevent passing fullWidth prop to MuiAutocomplete
  forcePopupIcon = false,
  hasError,
  ...props
}) => {
  const thestyles = useStyles();
  const inputLabelClasses = useInputLabelStyles();
  const formControlClasses = useFormControlStyles();

  return (
    <MuiAutocomplete
      forcePopupIcon={forcePopupIcon}
      classes={thestyles}
      fullWidth
      renderInput={params => (
        <TextField
          {...params}
          InputLabelProps={{ classes: inputLabelClasses }}
          classes={formControlClasses}
          label={label}
          margin="none"
          error={hasError}
        />
      )}
      {...props}
    />
  );
};

export default AutoCompleteBase;
