import React from 'react';
import { useField } from 'formik';
// 3rd party components
import { Typography } from '@material-ui/core';
// cr components
import SentimentField from 'components/CustomFormComponents/Sentiment';

const Sentiment = ({ name }) => {
  const [field] = useField(name);

  return (
    <div className="d-flex flex-column align-items-center w-100">
      <Typography className="mb-1 fw-300">Select your answer</Typography>
      <SentimentField name={name} label="" className="w-100" />
    </div>
  );
};

export default Sentiment;
