import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { map, find, remove, reduce, each, includes, filter } from 'lodash';
import { makeStyles } from '@material-ui/styles';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
// cr components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import NoteCard from './NoteCard';
import { grey } from '@material-ui/core/colors';
// actions
import { updateCombineNotes } from 'redux/actions/noteAndVoteActions';

const useStyles = makeStyles({
  grid: {
    width: `calc(100% + 20px)`,
    marginBottom: 0,
  },
  container: {
    padding: '0 10px 20px !important',
  },
  disableCard: {
    color: grey[500],
  },
});

const getStyle = (style, snapshot) => {
  if (!snapshot.isDragging) return {};
  if (!snapshot.isDropAnimating) {
    return style;
  }

  return {
    ...style,
    transitionDuration: `0.001s`,
  };
};

/**
 * Note & Vote view for the 'review' phase
 */
const NotesReview = ({ notes, noteAndVoteId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const topNotes = filter(notes, note => !note.combine);

  const [combineNote, setCombineNote] = useState({});
  const [isOpenCombineList, setIsOpenCombineList] = useState(false);
  const [isDragStart, setIsDragStart] = useState(false);

  const handleOpenDuplicateCards = noteId => {
    const currentCombineNote = find(notes, note => note.noteId === noteId);
    if (combineNote.noteId === noteId) {
      setIsOpenCombineList(false);
      setCombineNote({});
    } else {
      setIsOpenCombineList(true);
      setCombineNote(currentCombineNote);
    }
  };

  const renderNote = (isDraggingOver, note, idx, className, isStackCard) =>
    note && (
      <Draggable key={note.noteId} draggableId={note.noteId} index={idx}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getStyle(provided.draggableProps.style, snapshot)}
          >
            <NoteCard
              note={note}
              context="review"
              handleOpenDuplicateCards={handleOpenDuplicateCards}
              numberCombineNotes={reduce(
                note.combineNotesId,
                (acc, noteId) => {
                  const duplicateNote = find(
                    notes,
                    duplicateNote => duplicateNote.noteId === noteId,
                  );
                  acc += duplicateNote?.combineNotesId?.length
                    ? duplicateNote.combineNotesId.length
                    : 1;
                  return acc;
                },
                0,
              )}
              className={className}
              isStackCard={isStackCard}
              isDraggingOver={isDraggingOver}
            />
          </div>
        )}
      </Draggable>
    );

  const renderReviewNotes = () =>
    map(topNotes, (note, idx) => (
      <GridItem
        key={`review-${idx}`}
        className={classes.container}
        xs={12}
        sm={6}
        md={3}
        lg={3}
        xl={3}
      >
        <Droppable droppableId={note.noteId} isCombineEnabled type="notes">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className="w-100"
              style={
                (isDragStart ? { height: 190 } : {},
                snapshot.draggingFromThisWith
                  ? { height: '100%', border: '1px dashed #CFCFCF', borderRadius: 4 }
                  : {})
              }
            >
              {renderNote(snapshot.isDraggingOver, note, idx)}
              {isOpenCombineList &&
                combineNote?.noteId === note.noteId &&
                map(combineNote.combineNotesId, (noteId, index) => {
                  const duplicateNote = find(
                    notes,
                    duplicateNote => duplicateNote.noteId === noteId,
                  );
                  return renderNote(
                    snapshot.isDraggingOver,
                    duplicateNote,
                    idx + index,
                    classes.disableCard,
                    true,
                  );
                })}
              <div style={{ display: 'none' }}>{provided.placeholder}</div>
            </div>
          )}
        </Droppable>
      </GridItem>
    ));

  const onDragEnd = result => {
    setIsDragStart(false);
    if (result.combine || result.destination) {
      const combineNoteId = result.draggableId;
      const noteId = result.combine ? result.combine.draggableId : result.destination.droppableId;
      if (combineNoteId === noteId) {
        return;
      }
      const duplicateCard = find(notes, note => note.noteId === combineNoteId);

      if (duplicateCard.combine) {
        const combineCard = find(notes, note => includes(note.combineNotesId, combineNoteId));
        dispatch(updateCombineNotes(noteAndVoteId, combineCard.noteId, combineNoteId, false));
        const newCombineNote = {
          ...combineNote,
          combineNotesId: remove(combineNote.combineNotesId, combineNoteId),
        };
        setCombineNote(newCombineNote);
      }

      if (duplicateCard?.combineNotesId?.length) {
        each(duplicateCard.combineNotesId, duplicateNoteId => {
          dispatch(updateCombineNotes(noteAndVoteId, combineNoteId, duplicateNoteId, false));
          dispatch(updateCombineNotes(noteAndVoteId, noteId, duplicateNoteId, true));
        });
      }

      dispatch(updateCombineNotes(noteAndVoteId, noteId, combineNoteId, true));
    } else {
      const combineNoteId = result.draggableId;
      const noteId = result.source.droppableId;
      if (combineNoteId === noteId) {
        return;
      }
      const newCombineNote = {
        ...combineNote,
        combineNotesId: remove(combineNote.combineNotesId, combineNoteId),
      };
      setCombineNote(newCombineNote);
      dispatch(updateCombineNotes(noteAndVoteId, noteId, combineNoteId, false));
    }
  };

  const onDragStart = () => {
    setIsDragStart(true);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
      <GridContainer direction="row" className={classes.grid}>
        {renderReviewNotes()}
      </GridContainer>
    </DragDropContext>
  );
};

export default NotesReview;
