import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { filter } from 'lodash';
// library components
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// cr components
import ActivityParticipantDetails from 'components/ActivityParticipantDetails';
import ActivityPromptAdmin from 'components/ActivityPromptAdmin';
import ActivityActionButton from 'components/CustomButtons/ActivityActionButton';
// selectors
import {
  useHowMightWeSelector,
  getParticipantsCompletedCreate,
} from 'redux/selectors/howMightWeSelectors';
// actions
import { updateActivityView } from 'redux/actions/howMightWeActions';
// config
import { HOW_MIGHT_WE_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';
// utils
import { makeParticipantDetailsHeaderText } from 'utils/makeParticipantDetailsHeaderText';

const useStyles = makeStyles(theme => ({
  promptAdmin: {
    marginBottom: theme.spacing(5),
  },
}));

/**
 * HMW view for the 'create' phase on the Facilitator side
 */
const HowMightWeAdminCreate = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const howMightWe = useHowMightWeSelector();
  const { id, prompt } = howMightWe;
  const participantsHaveCreated = useSelector(getParticipantsCompletedCreate());
  const participantsHaveCreatedCount = filter(participantsHaveCreated, { completed: true }).length;

  const participantsCreatedCountText = makeParticipantDetailsHeaderText(
    participantsHaveCreatedCount,
    participantsHaveCreated.length,
    'added HMWs',
  );

  const onUpdateActivityView = () => {
    dispatch(updateActivityView('share', id));
  };

  return (
    <Box className="d-flex flex-column justify-content-center">
      <ActivityPromptAdmin
        prompt={prompt}
        collection={CONFIG.collection}
        activityId={id}
        className={classes.promptAdmin}
      />
      <ActivityParticipantDetails
        headerText={participantsCreatedCountText}
        hasCompletedLabel="Added HMWs"
        hasntCompletedLabel="Hasn’t Added HMWs"
        // Don't set a completedCountMax - they can add as many as they want.
        participantsCompletedStatus={participantsHaveCreated}
      />
      <ActivityActionButton
        text="Start Sharing"
        className="mb-2 mt-1"
        onClick={onUpdateActivityView}
      />
    </Box>
  );
};

export default HowMightWeAdminCreate;
