import React from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';
// cr components
import Gift from 'components/Giveaway/Participant/Gift';
// library components
import { makeStyles } from '@material-ui/styles';
import { Typography, Box } from '@material-ui/core';
// selectors
import { useGiveawaySelector, getWinnerParticipant } from 'redux/selectors/giveawaySelectors';

const useStyles = makeStyles({
  text: {
    fontSize: 16,
    fontWeight: 300,
    textAlign: 'center',
  },
  itemText: {
    fontSize: 30,
    fontWeight: 300,
    textAlign: 'center',
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 'calc(100% + 40px)',
    height: '100%',
    margin: '0 -20px',
    overflow: 'hidden',
  },
});

/**
 * Giveaway view for the 'pickWinner' phase on the Participant side
 */
const GiveawayParticipantPickWinner = () => {
  const classes = useStyles();

  const {
    config: { itemName, itemDescription, ofGiveaways, imageUrl },
    state: { numberWinner, notSelectingWinner },
  } = useGiveawaySelector();

  // get winner participant for current round
  const winnerParticipant = useSelector(getWinnerParticipant(numberWinner));

  // are all winners selected?
  const isFinishActivity = numberWinner === ofGiveaways;

  // set winner text
  let winnersText = winnerParticipant
    ? `Winner ${ofGiveaways > 1 ? `${numberWinner} of ${ofGiveaways}` : ''}`
    : `Selecting Winner ${ofGiveaways > 1 ? `${numberWinner} of ${ofGiveaways}` : ''}`;
  if (notSelectingWinner) {
    winnersText = 'No winner';
  }

  // if all winners selected, then display text with all winners
  if (isFinishActivity && ofGiveaways === 1 && winnerParticipant) {
    winnersText = `1 Winner!`;
  }

  let winnerParticipantName = `${winnerParticipant?.participantName}!`;
  if (winnerParticipant && isFinishActivity && ofGiveaways === 1) {
    winnerParticipantName = `${winnerParticipant.participantName}`;
  }

  return (
    <Box className={classes.box}>
      <Typography className={cx(!winnerParticipant && classes.itemText, classes.text)}>
        {winnersText}
      </Typography>
      {winnerParticipant && (
        <Typography className={classes.itemText}>{winnerParticipantName}</Typography>
      )}
      <Gift
        image={imageUrl}
        isSelecting={!winnerParticipant && !notSelectingWinner}
        isWinner={!!winnerParticipant && !notSelectingWinner}
        itemName={itemName}
        itemDescription={itemDescription}
      />
    </Box>
  );
};

export default GiveawayParticipantPickWinner;
