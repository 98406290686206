import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { Tooltip, Box } from '@material-ui/core';

import AutoField from './AutoField';

const useGroupStyles = makeStyles({
  groupContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

/**
 * Renders the fields in a group.
 */
const FieldsGroup = ({ group, disabledFields, tooltipTitleFields }) => {
  const groupStyles = useGroupStyles();
  const fields = map(group, (field, idx) => {
    const disabled = disabledFields ? disabledFields[field.key]?.disabled : false;
    const tooltipTitle = tooltipTitleFields ? tooltipTitleFields[field.key]?.tooltipTitle : '';
    return (
      // if tooltipTitle exist for current field, than render field with tooltip
      !!tooltipTitle ? (
        <Tooltip
          key={`${field.key}${idx}`}
          disableFocusListener={!disabled}
          disableHoverListener={!disabled}
          disableTouchListener={!disabled}
          placement="top"
          arrow
          title={tooltipTitle}
        >
          <Box>
            <AutoField
              id={`${field.key}${idx}`}
              field={{ ...field.formField, child: field.child }}
              disabled={disabled}
            />
          </Box>
        </Tooltip>
      ) : (
        <AutoField
          id={`${field.key}${idx}`}
          key={`${field.key}${idx}`}
          field={{ ...field.formField, child: field.child }}
          disabled={disabled}
        />
      )
    );
  });
  return <div className={groupStyles.groupContainer}>{fields}</div>;
};

FieldsGroup.propTypes = {
  group: PropTypes.array, // The collection contains values of group.
  disabledFields: PropTypes.shape({
    fieldName: PropTypes.string, // name of disabled field
    disabled: PropTypes.bool, // field disabled or not
  }),
  tooltipTitleFields: PropTypes.shape({
    fieldName: PropTypes.string, // name of field
    tooltipTitle: PropTypes.bool, // field tooltipTitle
  }),
};

export default FieldsGroup;
