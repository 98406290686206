import { random, map, sortBy, filter, find, join, forEach } from 'lodash';

/**
 * Select random winner for GiveawayActivity
 * @param {Object} submittedParticipants
 * @returns {winnerParticipant: String}
 */
export const getRandomWinner = submittedParticipants => {
  const participantsId = map(submittedParticipants, participant => participant.id);
  const winnerParticipantNumber = random(0, participantsId.length - 1);
  const winnerParticipant = participantsId[winnerParticipantNumber];
  return winnerParticipant;
};

/**
 * Creates the output array for a GiveAway actvity
 * @param {Object} results
 * @returns {Array<{winner: Boolean, round: Number, name: String, email: String}>}
 */
export const createOutput = results => {
  const output = filter(results, { winner: true });
  return map(sortBy(output, 'round'), ({ winner, round, participantName, participantEmail }) => {
    return {
      winner: winner,
      round: round,
      name: participantName,
      email: participantEmail,
    };
  });
};

/**
 * Creates the array for Report Header
 *
 */
export const createGiveawayReportHeader = () => [
  { label: 'Winner', key: 'winner' },
  { label: 'Round', key: 'round' },
  { label: 'Name', key: 'name' },
  { label: 'Email', key: 'email' },
];

export const createGiveawayReportData = results => {
  return map(sortBy(results, 'round'), ({ winner, round, participantName, participantEmail }) => ({
    winner: winner,
    round: round,
    name: participantName,
    email: participantEmail,
  }));
};

export const createGiveawayReport = activityData => {
  const headers = createGiveawayReportHeader();
  const data = createGiveawayReportData(
    formatGiveawayReport(
      activityData?.participantData?.giveawayParticipants,
      activityData?.state?.winnerParticipants,
    ),
  );
  return { headers, data };
};

export const formatGiveawayReport = (giveawayParticipants, winnerParticipants) => {
  return map(giveawayParticipants, participant => {
    const winnerParticipant = find(
      winnerParticipants,
      data => data.participantId === participant.participantId,
    );
    if (winnerParticipant) {
      return {
        ...participant,
        round: winnerParticipant.order,
        winner: true,
      };
    }
    return {
      ...participant,
      winner: false,
    };
  });
};
