import React from 'react';
import PropTypes from 'prop-types';
import { each, filter, map } from 'lodash';
import { useField } from 'formik';
// CR components
import CustomSelect from './CustomSelect';
// constants
import { MEETING_TEMPLATES } from 'constants/meetingTemplates';

const MeetingTemplatesSelect = ({ onChange, isInternalUser, ...otherProps }) => {
  const [field, meta, helpers] = useField('meetingTemplate');
  const [otherField, otherMeta, otherHelpers] = useField('templateActivities');

  each(MEETING_TEMPLATES, (meetingTemplate, key) => {
    if (!isInternalUser && meetingTemplate.internalUserOnly) {
      delete MEETING_TEMPLATES[key];
    }
  });

  const meetingTemplateOptions = map(MEETING_TEMPLATES, (meetingTemplate, key) => ({
    name: meetingTemplate.title,
    value: key,
  })).concat({ name: <i className="text-secondary">None</i>, value: null });

  const getMeetingTemplateActivities = template => {
    if (!template) return {};

    const meetingTemplate = MEETING_TEMPLATES[template];
    const values = {};

    const includedActivities = filter(
      meetingTemplate.includedActivities,
      activity => !activity.isInternal || (activity.isInternal && isInternalUser),
    );

    each(includedActivities, (activity, idx) => {
      const activityType = activity.derivative ?? activity.collection;

      values[`${idx}-${activityType}`] = {
        order: idx,
        id: `${idx}-${activityType}`,
        value: activity.niceName,
      };
    });

    return values;
  };

  const handleChange = e => {
    helpers.setValue(e.target.value);
    otherHelpers.setValue(getMeetingTemplateActivities(e.target.value));
  };

  return (
    <CustomSelect
      {...otherProps}
      customOnChange={handleChange}
      selectOptions={meetingTemplateOptions}
    />
  );
};

MeetingTemplatesSelect.propTypes = {
  onChange: PropTypes.func, // the default onChange from Formik, not used
};

export default MeetingTemplatesSelect;
