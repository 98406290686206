import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';

const useStyles = makeStyles(theme => ({
  dot: {
    display: 'block',
    content: ' ',
    width: 12,
    height: 12,
    marginRight: theme.spacing(1),
    borderRadius: '50%',
  },
  activeDot: {
    background: ({ activeColor }) => activeColor,
  },
  inactiveDot: {
    background: ({ inactiveColor, activeColor }) => inactiveColor || activeColor,
    opacity: ({ inactiveColor }) => (inactiveColor ? '100%' : '30%'),
  },
  container: {
    marginBottom: theme.spacing(1),
    display: 'flex',
  },
}));

const DottedProgress = ({ activeColor, inactiveColor, totalCount, activeCount, className }) => {
  const renderDots = () => {
    const dots = [];

    for (let count = 1; count <= totalCount; count++) {
      if (count <= activeCount) {
        dots.push(
          <span key={`${count}-dottedProgress`} className={cx(classes.dot, classes.activeDot)} />,
        );
      } else {
        dots.push(
          <span key={`${count}-dottedProgress`} className={cx(classes.dot, classes.inactiveDot)} />,
        );
      }
    }

    return dots;
  };

  const classes = useStyles({ activeColor, inactiveColor });

  return <div className={cx('dotted-progress', classes.container, className)}>{renderDots()}</div>;
};

DottedProgress.defaultProps = {
  className: '',
};

DottedProgress.propTypes = {
  activeColor: PropTypes.string.isRequired,
  inactiveColor: PropTypes.string,
  totalCount: PropTypes.number.isRequired,
  activeCount: PropTypes.number.isRequired,
  className: PropTypes.string,
};

export default DottedProgress;
