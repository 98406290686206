import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty, isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { each, filter, find } from 'lodash';

// material ui components
import { Typography, Divider, Box, MenuItem } from '@material-ui/core';
import { ContactsOutlined } from '@material-ui/icons';

// cr components
import Admin2510Idea from 'components/25:10/Admin/Admin2510Idea';
import Admin2510Vote from 'components/25:10/Admin/Admin2510Vote';
import Admin2510Results from 'components/25:10/Admin/Admin2510Results';
import ActivityMenu from 'components/ActivityMenu/ActivityMenu';
import CrLogo from 'components/CrLogo/CrLogo';
import EditableTextWrapper from 'components/EditableTextWrapper';
import AdminSendToParticipantsActivity from 'components/AdminActivityTemplate/AdminSendToParticipantsActivity';
import FormDialog from 'components/Dialogs/FormDialog';
import SettingsForm2510 from 'components/Forms/SettingsForm2510';
import SendToLiveScribingForm from 'components/Forms/SendToLiveScribingForm';
import AddActivityForm from 'components/Forms/AddActivityForm';
import RecapDeckDialog from 'components/Dialogs/RecapDeck';

// selectors
import { session2510Selector } from 'redux/selectors/25:10/root2510Selectors';

//utils
import { copyActivityOutput } from 'utils/copyActivityOutput';
import { sendResultsToLiveScribing } from 'utils/sendResultsToLiveScribing';

const views = {
  idea: Admin2510Idea,
  vote: Admin2510Vote,
  results: Admin2510Results,
};

const Admin2510Detail = () => {
  const [recapDeckDialogOpen, setRecapDeckDialogOpen] = useState(false);
  const [isAddActivityDialogOpen, setIsAddActivityDialogOpen] = useState(false);
  const [settingsDialogOpen, setSettingsDialogOpen] = useState(false);
  const routeParams = useParams();
  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  useFirestoreConnect(() => [
    {
      collection: '25:10',
      doc: `${routeParams.activityId}`,
      storeAs: '25:10',
    },
  ]);

  const session2510 = useSelector(session2510Selector);
  const session = useSelector(state => state.firestore.data.session);
  const { selectedFacilitatorRoute } = useSelector(state => state.facilitatorSession);

  const liveScribeActivities = filter(session.activities, sessionActivity => {
    return sessionActivity.activity === 'liveScribing';
  });

  const { uid } = useSelector(state => state.firebase.auth);
  const [sendToLivescribeDialogOpen, setSendToLivescribeDialogOpen] = useState(false);
  const [sendToLivescribeOuput, setSendToLivescribeOuput] = useState('');
  const sessionActivity = find(session.activities, { id: routeParams.activityId });

  const copyOutput = activityData => {
    let outputString = `<h2>${activityData.name}</h2>`;
    each(activityData.output, item => {
      outputString += `${item.idea} (${item.score})<br/>`;
    });
    return outputString;
  };

  const handleCopy = () => {
    copyActivityOutput('25:10', session2510.id, false, copyOutput);
  };

  if (
    !isLoaded(session) ||
    isEmpty(session) ||
    !isLoaded(session2510) ||
    isEmpty(session2510) ||
    routeParams.activityId !== session2510?.id ||
    !isLoaded(selectedFacilitatorRoute)
  ) {
    return null;
  }

  const toggleRecapDeckDialog = () => {
    setRecapDeckDialogOpen(!recapDeckDialogOpen);
  };

  const toggleSettingsDialog = () => {
    setSettingsDialogOpen(!settingsDialogOpen);
  };

  const toggleSendToLivescribeDialog = () => {
    setSendToLivescribeDialogOpen(!sendToLivescribeDialogOpen);
  };

  const toggleAddActivityDialog = () => {
    setIsAddActivityDialogOpen(prev => !prev);
  };

  const handleSendToLivescribe = async () => {
    const output = await copyActivityOutput('25:10', session2510.id, true, copyOutput);

    if (!liveScribeActivities.length) {
      setSendToLivescribeOuput(output);
      toggleAddActivityDialog();
    }

    if (liveScribeActivities.length === 1) {
      const liveScribeActivity = liveScribeActivities[0];

      sendResultsToLiveScribing(liveScribeActivity, uid, output);
    }

    if (liveScribeActivities.length > 1) {
      setSendToLivescribeOuput(output);
      toggleSendToLivescribeDialog();
    }
  };

  const activeParticipantRoute = session?.activeRoute;

  const renderMenuItems = () => {
    const items = [];

    if (session2510.activeView === 'results') {
      items.push(<MenuItem onClick={handlePrint}>Print</MenuItem>);

      items.push(<MenuItem onClick={handleCopy}>Copy Results</MenuItem>);

      items.push(
        <MenuItem onClick={handleSendToLivescribe}>Send Results to Live Scribing</MenuItem>,
      );
    }

    items.push(<MenuItem onClick={toggleSettingsDialog}>Settings</MenuItem>);

    items.push(<MenuItem onClick={toggleRecapDeckDialog}>Add Recap Images</MenuItem>);

    return items;
  };

  const route = session2510 && session2510.activeView ? session2510.activeView : 'start';

  const Component = views[route];

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <EditableTextWrapper
          initialValue={session2510.name}
          fieldType="text"
          fieldName="name"
          firestoreCollection="25:10"
          documentId={routeParams.activityId}
          isActivityName
        >
          <Typography variant="h6" className="mb-1">
            25:10 - {session2510.name}
          </Typography>
        </EditableTextWrapper>
        <ActivityMenu
          activityId={session2510.id}
          additionalMenuItems={renderMenuItems()}
          isLegacyActivity
        />
      </div>
      <Divider className="mb-2" />
      <div ref={printRef}>
        <Box display="none" displayPrint="block" mb={4} className="text-center">
          <div className="mb-2">
            <CrLogo />
          </div>
          <Typography variant="h5" className="mb-1 text-center">
            {session.topic}: 25:10 - {session2510.name}
          </Typography>
        </Box>
        <Component session={session} activityId={routeParams.activityId} />
      </div>
      {activeParticipantRoute !== selectedFacilitatorRoute && (
        <AdminSendToParticipantsActivity
          session={session}
          activeFacilitatorRoute={selectedFacilitatorRoute}
        />
      )}
      {/* Settings Form */}
      <FormDialog title="Settings" handleClose={toggleSettingsDialog} isOpen={settingsDialogOpen}>
        <SettingsForm2510
          closeDialog={toggleSettingsDialog}
          activityId={routeParams.activityId}
          topic={session2510?.topic}
        />
      </FormDialog>
      <RecapDeckDialog
        isOpen={recapDeckDialogOpen}
        handleClose={toggleRecapDeckDialog}
        sessionId={session.id}
        activities={session.activities}
        recapDeck={session.recapDeck}
        onlyImages
        sessionActivity={sessionActivity}
      />
      {session2510.activeView === 'results' && (
        <FormDialog
          handleClose={toggleSendToLivescribeDialog}
          isOpen={sendToLivescribeDialogOpen}
          title="Send Results to Live Scribing"
        >
          <Typography className="mb-3">
            Select which Live Scribing activity in this meeting you'd like the results sent to.
          </Typography>
          <SendToLiveScribingForm
            sessionId={session.id}
            liveScribeActivities={liveScribeActivities}
            activityOutput={sendToLivescribeOuput}
            closeDialog={toggleSendToLivescribeDialog}
          />
        </FormDialog>
      )}
      {session2510.activeView === 'results' && (
        <FormDialog
          handleClose={toggleAddActivityDialog}
          isOpen={isAddActivityDialogOpen}
          title="Add Activity"
        >
          <AddActivityForm
            isAddOneActivity={true}
            activityTemplateActivity="liveScribing"
            activityStateInitalValues={{
              state: {
                liveScribingText: sendToLivescribeOuput,
              },
            }}
            closeDialog={toggleAddActivityDialog}
          />
        </FormDialog>
      )}
    </div>
  );
};

export default Admin2510Detail;
