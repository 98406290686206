import React from 'react';
import PropTypes from 'prop-types';
import { useFirebase } from 'react-redux-firebase';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { map } from 'lodash';
import * as Yup from 'yup';
// library components
import { Formik, Form } from 'formik';
import Button from '@material-ui/core/Button';
import ButtonSpinner from 'components/ButtonSpinner/ButtonSpinner';
// cr components
import TextInput from 'components/CustomFormComponents/TextInput';
import MaterialDateTime from 'components/CustomFormComponents/MaterialDateTime';
// actions
import { enqueueErrorSnackbar } from 'redux/actions/notifierActions';
// utils
import { resetActivity } from 'utils/resetActivity';
import { getFacilitatorSessionType } from 'utils/getFacilitatorSessionType';

const formSchema = Yup.object().shape({
  topic: Yup.string().required('Required'),
  startTime: Yup.string().required('Required'),
});

/**
 * Clones an existing session
 */
const CloneSessionForm = ({ closeDialog, baseSessionId }) => {
  const sessionType = getFacilitatorSessionType();
  const sessions = useSelector(state => state.firestore.data[sessionType]);
  const baseSession = sessions[baseSessionId];
  const functions = useFirebase().functions();
  const history = useHistory();
  const dispatch = useDispatch();

  console.log('sessionType', sessionType);

  return (
    <Formik
      initialValues={{
        topic: `${baseSession.topic} - Clone`,
        startTime: moment().format(),
      }}
      validationSchema={formSchema}
      onSubmit={async (values, { resetForm }) => {
        /**
         * Tech Debt:
         * When cloning a session, the cloud storage bucket associated with
         * the session is not duplicated.  Therefore, if any images were uploaded
         * to the Welcome message, those image links will be broken if the session that
         * is being cloned from is deleted.
         */
        return functions
          .httpsCallable('sessions-clone')({ baseSessionId, ...values })
          .then(async res => {
            const newSession = res.data;

            await Promise.allSettled(
              map(newSession.activities, newActivity => {
                return resetActivity(newActivity.activity, newActivity.id);
              }),
            );

            closeDialog();
            resetForm();
            history.push(`/admin/${sessionType}`);
          })
          .catch(err => {
            console.error('Error cloning meeting:', err);
            dispatch(enqueueErrorSnackbar(err.message));
            closeDialog();
            resetForm();
          });
      }}
    >
      {({ isSubmitting, submitForm }) => (
        <Form style={{ paddingBottom: 20 }}>
          <TextInput
            name="topic"
            type="text"
            label="Title/Topic"
            placeholder="My Awesome Meeting Topic"
            required
          />

          <MaterialDateTime name="startTime" label="Date/Time" />

          <div className="form-dialog-buttons">
            <Button
              variant="outlined"
              color="primary"
              disabled={isSubmitting}
              onClick={closeDialog}
            >
              CANCEL
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={submitForm}
            >
              CLONE MEETING
              {isSubmitting && <ButtonSpinner />}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

CloneSessionForm.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  baseSessionId: PropTypes.string.isRequired, // id of the session being cloned
};

export default CloneSessionForm;
