import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// library components
import { Button, Typography } from '@material-ui/core';
// cr components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import CrLogo from 'components/CrLogo';

const pageStyles = makeStyles({
  container: {
    marginTop: '10%',
    minHeight: '100vh',
    left: '50%',
    width: '100%',
    padding: '0 15px',
    zIndex: 3,
    position: 'relative',
    maxWidth: 880,
    transform: 'translate(-50%,0)',
    textAlign: 'center',
  },
  textField: {
    background: 'white',
  },
});

const ErrorFallback = () => {
  const classes = pageStyles();

  return (
    <div className={classes.container}>
      <GridContainer>
        <GridItem xs={12}>
          <div className="mb-4">
            <CrLogo width={160} />
          </div>
          <Typography variant="h2" className="font-weight-bold text-dark mb-2">
            Oops!
          </Typography>
          <Typography variant="h4" className="text-dark mb-4">
            Looks like our software is out of control!
          </Typography>
          {/* <div className="mb-4 mx-auto" style={{maxWidth: 400}}>
            <InputLabel
              variant="standard"
              disableAnimation
              shrink={false}
              htmlFor="userNotes"
              className="mb-1 text-left"
            >
              Notes
            </InputLabel>
            <TextField
              id="userNotes"
              name="userNotes"
              variant="outlined"
              placeholder="Please give us some context about what you were doing before you got here."
              fullWidth
              multiline
              rows={6}
              className={classes.textField}
            />
          </div> */}
          <Button
            variant="contained"
            color="primary"
            href={`${window.location.protocol}//${window.location.host}`}
          >
            Return to Safety
          </Button>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default ErrorFallback;
