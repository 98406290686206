import { useShallowEqualSelector } from '../utils';
import { createSelector } from 'reselect';
import { filter, uniq, union, map, includes, find, orderBy, size } from 'lodash';

const noteAndVoteSelector = state => state.firestore.data.noteAndVote || {};

export const useNoteAndVoteSelector = () => {
  return useShallowEqualSelector(noteAndVoteSelector);
};

/**
 * Get all notes for a participant
 *
 * @param {String} participantId
 */
export const getParticipantNotes = participantId =>
  createSelector([noteAndVoteSelector], ({ participantData: { notes } }) => {
    return orderBy(filter(notes, { participantId: participantId }), ['order'], ['desc']);
  });

/**
 * Get all notes a participant has voted for
 *
 * @param {String} participantId
 */
export const getParticipantVotedNotes = participantId =>
  createSelector([noteAndVoteSelector], ({ participantData: { notes } }) => {
    return filter(notes, note => includes(note.votedFor, participantId));
  });

/**
 * Get all note ids that were voted for by a participant
 *
 * @param {String} participantId
 */
export const getParticipantVotes = participantId =>
  createSelector([getParticipantVotedNotes(participantId)], notes => {
    return map(notes, 'noteId');
  });

/**
 * Get count of shared notes for participant
 *
 * @param {String} participantId
 */
export const getParticipantSharedNotesCount = participantId =>
  createSelector([getParticipantNotes(participantId)], participantNotes => {
    return filter(participantNotes, { shared: true }).length;
  });

/**
 * Get data on which participants have written or not written a
 * note yet, formatted for the ActivityParticipantDetail component
 *
 * @returns {Array.<{id: String, completed: Boolean}>}
 */
export const getParticipantsHaveWritten = () =>
  createSelector([noteAndVoteSelector], ({ participantData: { notes, participants } }) => {
    return map(participants, pId => ({
      id: pId,
      completed: !!find(notes, note => note.participantId === pId),
      completedCount: size(find(notes, note => note.participantId === pId)),
    }));
  });

/**
 * Get data on which participants have submitted their shared ideas,
 * formatted for the ActivityParticipantDetail component
 *
 * @returns {Array.<{id: String, completed: Boolean}>}
 */
export const getParticipantsSubmittedShares = () =>
  createSelector([noteAndVoteSelector], ({ participantData: { participants, notes } }) => {
    return map(participants, pId => ({
      id: pId,
      completed: !!find(notes, note => note.shared && note.participantId === pId),
      completedCount: size(find(notes, note => note.shared && note.participantId === pId)),
    }));
  });

/**
 * Get data on which participants have submitted thier votes,
 * formatted for the ActivityParticipantDetail component
 *
 * @returns {Array.<{id: String, completed: Boolean}>}
 */
export const getParticipantsHaveVoted = () =>
  createSelector([noteAndVoteSelector], ({ participantData: { notes, participants } }) => {
    return map(participants, pId => {
      const notesVotedFor = size(
        filter(notes, note => {
          return note.votedFor?.length && includes(note.votedFor, pId);
        }),
      );

      return {
        id: pId,
        completed: notesVotedFor > 0,
        completedCount: notesVotedFor,
      };
    });
  });

/**
 * Get all notes that were submitted during the share phase
 *
 * @returns {Array}
 */
export const getSubmittedNotes = () =>
  createSelector([noteAndVoteSelector], ({ participantData }) => {
    if (participantData) {
      return orderBy(
        filter(participantData.notes, note => note.shared),
        ['order', 'noteId'],
        ['desc'],
      );
    }
  });

/**
 * Get all notes that were not combined during the review phase
 *
 * @returns {Array}
 */
export const getNotCombinedNotes = () =>
  createSelector([noteAndVoteSelector], ({ participantData }) => {
    if (participantData) {
      return orderBy(
        filter(participantData.notes, note => note.shared && !note.combine),
        ['order', 'noteId'],
        ['desc'],
      );
    }
  });
