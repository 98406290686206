import React from 'react';
import { useFirestore } from 'react-redux-firebase';
import mixpanel from 'mixpanel-browser';

import { Formik, Form } from 'formik';
import { Typography, ButtonBase } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ButtonSpinner from 'components/ButtonSpinner/ButtonSpinner';
import TextEditor from 'components/CustomFormComponents/TextEditor';
import * as Yup from 'yup';

const useStyles = makeStyles(theme => ({
  buttonLink: {
    color: '#093B75',
  },
  cancelLink: {
    color: '#898989',
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));

const formSchema = Yup.object().shape({
  highlightMsg: Yup.string().required('Required'),
});

const AdminBroadcastForm = ({ initialValues, setIsEditing, sessionId }) => {
  const db = useFirestore();
  const classes = useStyles();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={formSchema}
      onSubmit={values => {
        db.doc(`sessions/${sessionId}`)
          .update({
            highlight: values.highlightMsg,
          })
          .then(() => {
            if (process.env.REACT_APP_HOSTING_ENV === 'prod') {
              mixpanel.track('Highlight Submitted');
            }
            setIsEditing(false);
          })
          .catch(err => {
            console.log('firestore write error', err);
          });
      }}
    >
      {({ isSubmitting, submitForm, values, resetForm }) => (
        <Form style={{ paddingBottom: 20 }}>
          <Typography>Highlights</Typography>
          <div style={{ overflow: 'auto' }}>
            <TextEditor name="highlightMsg" className="mb-2" sessionId={sessionId} />
          </div>

          <div className={classes.buttonWrapper}>
            <ButtonBase
              className={classes.cancelLink}
              disabled={isSubmitting}
              onClick={() => {
                resetForm();
                setIsEditing(false);
              }}
            >
              Cancel
            </ButtonBase>
            <ButtonBase className={classes.buttonLink} disabled={isSubmitting} onClick={submitForm}>
              Save
              {isSubmitting && <ButtonSpinner size="small" />}
            </ButtonBase>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AdminBroadcastForm;
