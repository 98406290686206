import React from 'react';
import { useSelector } from 'react-redux';
import { isEmpty, isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { useParams } from 'react-router-dom';
// cr components
import NoteAndMapAdminCreate from 'components/NoteAndMap/Admin/NoteAndMapAdminCreate';
import NoteAndMapAdminReview from 'components/NoteAndMap/Admin/NoteAndMapAdminReview';
import NoteAndMapAdminVote from 'components/NoteAndMap/Admin/NoteAndMapAdminVote';
import NoteAndMapAdminVoteReview from 'components/NoteAndMap/Admin/NoteAndMapAdminVoteReview';
import NoteAndMapAdminSuperVote from 'components/NoteAndMap/Admin/NoteAndMapAdminSuperVote';
import NoteAndMapAdminResults from 'components/NoteAndMap/Admin/NoteAndMapAdminResults';
import AdminActivityTemplate from 'components/AdminActivityTemplate';

// selectors
import { useNoteAndMapSelector } from 'redux/selectors/noteAndMapSelectors';
// config
import { NOTE_AND_MAP_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';

const views = {
  create: NoteAndMapAdminCreate,
  review: NoteAndMapAdminReview,
  vote: NoteAndMapAdminVote,
  voteResults: NoteAndMapAdminVoteReview,
  superVote: NoteAndMapAdminSuperVote,
  results: NoteAndMapAdminResults,
};

/**
 * Entry point for Note & Map on the Admin side.
 * Loads the Firestore object for the activity, ensures
 * the data is loaded before rendering the game, and determines
 * which view to show
 */
const AdminNoteAndMap = () => {
  const routeParams = useParams();
  const noteAndMap = useNoteAndMapSelector();
  const session = useSelector(state => state.firestore.data.session);
  const meetingParticipants = useSelector(state => state.firestore.data.participants);

  // hooks up a firestore listener to Redux
  useFirestoreConnect(() => [
    {
      collection: 'noteAndMap',
      doc: `${routeParams.activityId}`,
      storeAs: 'noteAndMap',
    },
  ]);

  // ensure all of the data we need is loaded
  if (
    !isLoaded(session) ||
    isEmpty(session) ||
    !isLoaded(noteAndMap) ||
    isEmpty(noteAndMap) ||
    !isLoaded(meetingParticipants) ||
    /**
     * Ensure the loaded Note & Map data actually matches the id in the URL.
     */
    routeParams.activityId !== noteAndMap?.id
  ) {
    return null;
  }

  const CurrentView = views[noteAndMap.state.activeView];

  return (
    <AdminActivityTemplate
      activityConfig={CONFIG}
      activityData={noteAndMap}
      session={session}
      CurrentView={CurrentView}
    />
  );
};

export default AdminNoteAndMap;
