import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
// library components
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Form } from 'formik';
// cr components
import TextArea from 'components/CustomFormComponents/TextArea';
// actions
import { updateSetName } from 'redux/actions/cardSetsActions';

const useStyles = makeStyles(() => ({
  textArea: {
    width: 315,
    marginRight: 'auto',
  },
  textareaLabel: {
    fontSize: 12,
    fontWeight: 300,
    color: '#5E5E5E',
  },
  input: {
    maxWidth: 315,
  },
}));

const EditSet = ({ setId, name }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleUpdateName = event => {
    const setName = event.target.value;
    dispatch(updateSetName(setId, setName));
  };

  return (
    <Formik
      initialValues={{
        cards: name,
      }}
    >
      {() => (
        <Form onBlur={handleUpdateName} className={classes.textArea}>
          <TextArea
            value={name}
            label="Edit Set Title"
            name="cards"
            type="text"
            className={classes.textArea}
            additionalStyles={{
              label: classes.textareaLabel,
              input: classes.input,
            }}
            multiline
            rows={1}
            variant="filled"
            onBlur={handleUpdateName}
          />
        </Form>
      )}
    </Formik>
  );
};

EditSet.propTypes = {
  setId: PropTypes.string.isRequired, // set Id
  name: PropTypes.string, // set name
};

export default EditSet;
