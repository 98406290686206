import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
// library components
import { makeStyles, Box, Typography } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';

const useStyles = makeStyles(theme => ({
  lockBox: {
    position: 'absolute',
    left: ({ liveScibingWidth }) => liveScibingWidth / 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: 350,
    height: 125,
    boxShadow: '0px 4px 11px rgba(0, 0, 0, 0.15)',
    borderRadius: 4,
    backgroundColor: '#fff',
    color: '#666666',
    zIndex: 10,
  },
  lockIcon: {
    marginBottom: theme.spacing(2),
    fontSize: 35,
  },
  lockText: {
    fontSize: 16,
    fontWeight: 300,
  },
}));

const Lock = ({ className }) => {
  const classes = useStyles();

  return (
    <Box className={cx(classes.lockBox, className)}>
      <LockIcon className={classes.lockIcon} />
      <Typography className={classes.lockText}>
        Currently being edited in another tab/window
      </Typography>
    </Box>
  );
};

Lock.propTypes = {
  className: PropTypes.string,
};

export default Lock;
