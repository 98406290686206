import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import makeStyles from '@material-ui/core/styles/makeStyles';
import cx from 'classnames';
import { find } from 'lodash';
import { getCorrectString } from 'utils/getCorrectString';
// library components
import { Formik, Form } from 'formik';
import { Button, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
// cr components
import TextArea from 'components/CustomFormComponents/TextArea';
import Spinner from 'components/Spinner';
// selectors
import { useHowMightWeSelector } from 'redux/selectors/howMightWeSelectors';
// actions
import { createIdea, editIdea } from 'redux/actions/howMightWeActions';

const useStyles = makeStyles(theme => ({
  saveIcon: {
    fontSize: 20,
  },
  saveBtn: {
    color: theme.palette.success.pale,
    position: 'relative',
    left: `-${theme.spacing(1)}px`,
  },
  saveBtnText: {
    fontSize: 12,
    textTransform: 'initial',
  },
  cancelBtn: {
    position: 'relative',
  },
  cancelBtnText: {
    fontSize: 12,
    color: '#ABABAB',
    textTransform: 'initial',
  },
  textArea: {
    fontSize: 14,
    lineHeight: '20px',
    paddingLeft: 0,
    paddingTop: 0,
  },
  formActions: {
    padding: 0,
    marginTop: '-1rem',
  },
}));

/**
 * Renders form for creating new idea or edit idea on the Participant side.
 */
const IdeaForm = ({ idea, type, cancelEdit, order }) => {
  const user = useSelector(state => state.firebase.auth);
  const {
    id,
    participantData: { groups },
  } = useHowMightWeSelector();
  const dispatch = useDispatch();
  const classes = useStyles({ type });
  const initialGroup = find(groups, group => group.order === 0);

  /**
   * Autofocus the field when the form is rendered so that
   * the user knows that they can type since the form field
   * is essentially invisible
   */
  useEffect(() => {
    focusField();
  }, []);

  const focusField = () => {
    document.getElementById('idea').focus();
  };

  const formSchema = Yup.object().shape({
    idea: Yup.string(),
  });

  const initialValues = { idea: '' };

  if (idea) {
    initialValues.idea = idea.idea;
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={formSchema}
      onSubmit={(values, { resetForm, setSubmitting }) => {
        if (!values.idea) {
          setSubmitting(false);
          cancelEdit();
          return;
        }
        const ideaText = getCorrectString(values.idea);

        if (type === 'create') {
          dispatch(
            createIdea(ideaText, user.uid, id, initialGroup.id, order, () => {
              setSubmitting(false);
              resetForm();
              focusField();
            }),
          );
        }

        if (type === 'edit') {
          dispatch(
            editIdea(ideaText, idea.ideaId, id, () => {
              setSubmitting(false);
              resetForm();
              cancelEdit();
            }),
          );
        }
      }}
    >
      {({ isSubmitting, submitForm, resetForm }) => (
        <Form className={cx('w-100 position-relative ', classes.form)}>
          <TextArea
            name="idea"
            rows={4}
            required
            transparent
            className="flex-1 d-flex flex-column"
            additionalStyles={{
              input: cx(classes.textArea, 'flex-1 d-flex flex-column'),
            }}
            inputProps={{ maxLength: 140 }}
          />

          <div className={cx('d-flex justify-content-between w-100', classes.formActions)}>
            {isSubmitting ? (
              <Spinner size={24} />
            ) : (
              <Button className={classes.saveBtn} onClick={submitForm} size="small">
                <CheckCircleIcon color="inherit" className="mr-1" />
                <Typography color="inherit" className={classes.saveBtnText}>
                  Save
                </Typography>
              </Button>
            )}
            <Button
              onClick={() => {
                resetForm();
                submitForm();
              }}
              size="small"
              className={classes.cancelBtn}
            >
              <Typography className={classes.cancelBtnText}>Cancel</Typography>
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

IdeaForm.propTypes = {
  idea: PropTypes.shape({
    idea: PropTypes.string.isRequired, // idea text value
    ideaId: PropTypes.string.isRequired, // idea id
  }),
  cancelEdit: PropTypes.func.isRequired, // partcipant cancel edit idea
  type: PropTypes.oneOf(['create', 'edit']), // create - create new idea, edit - edit idea value
  order: PropTypes.number, // serial number of idea
};

export default IdeaForm;
