import { enqueueErrorSnackbar } from 'redux/actions/notifierActions';
import { includes } from 'lodash';
import { createOutput } from 'utils/giveaway';

import { GIVEAWAY_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';

/**
 * Update the active view for the activity
 * @param {String} view
 * @param {String} giveawayId
 */
export const updateActivityView =
  (view, giveawayId) =>
  (dispatch, getState, { getFirebase }) => {
    if (!includes(CONFIG.views, view)) {
      console.error(`View '${view}' does not exist in the activity config`);
      return;
    }

    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${giveawayId}`)
      .set(
        {
          state: {
            activeView: view,
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error changing activity view'));
      });
  };

/**
 * Add additional participant data
 *
 * @param {String} giveawayId
 * @param {String} participantId
 * @param {String} participantName
 * @param {String} participantEmail
 */

export const addParticipantData =
  (giveawayId, participantId, participantName, participantEmail) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${giveawayId}`)
      .set(
        {
          participantData: {
            giveawayParticipants: {
              [participantId]: {
                participantId: participantId,
                participantName: participantName,
                participantEmail: participantEmail,
              },
            },
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error add participant data'));
      });
  };

/**
 * Add winner participant data
 *
 * @param {String} giveawayId
 * @param {String} participantId
 * @param {Number} order
 */

export const addWinnerParticipant =
  (giveawayId, participantId, order) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${giveawayId}`)
      .set(
        {
          state: {
            winnerParticipants: db.FieldValue.arrayUnion({
              participantId: participantId,
              order: order,
            }),
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error add winner'));
      });
  };

/**
 * Add number winner
 *
 * @param {String} giveawayId
 * @param {Number} numberWinner
 */

export const updateNumberWinner =
  (giveawayId, numberWinner) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${giveawayId}`)
      .set(
        {
          state: {
            numberWinner,
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error update number winner'));
      });
  };

/**
 * Add reveal results
 *
 * @param {String} giveawayId
 * @param {Object} results
 */

export const updateRevealResults =
  (giveawayId, results) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;
    const output = createOutput(results);
    db()
      .doc(`${CONFIG.collection}/${giveawayId}`)
      .set(
        {
          output,
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error update results'));
      });
  };

/**
 * Update participant name and email in participants document
 *
 * @param {String} sessionId
 * @param {String} participantId
 * @param {String} participantName
 * @param {String} participantEmail
 */

export const updateParticipant =
  (sessionId, participantId, participantName, participantEmail) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`participants/${sessionId}`)
      .set(
        {
          [participantId]: {
            name: participantName,
            email: participantEmail,
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error update participant name and email'));
      });
  };

/**
 * Add not selecting winner
 *
 * @param {String} giveawayId
 * @param {Boolean} notSelectingWinner
 */

export const addNotSelectingWinner =
  (giveawayId, notSelectingWinner) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${giveawayId}`)
      .set(
        {
          state: {
            notSelectingWinner,
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error add winner'));
      });
  };
