import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useFirestoreConnect, isEmpty, isLoaded, useFirestore } from 'react-redux-firebase';
import { Switch, Route, Redirect, useHistory, useLocation, useParams } from 'react-router-dom';
import copy from 'copy-to-clipboard';
import { useTheme } from '@material-ui/core/styles';
import mixpanel from 'mixpanel-browser';
import cx from 'classnames';
import { isMobile } from 'react-device-detect';
import { MOBILE_DETECT_MESSAGE } from 'constants/general';
import { includes, find, findIndex, union, isEqual, filter, map } from 'lodash';
import { useParticipants } from 'hooks/useParticipants';
import { grey } from '@material-ui/core/colors';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  Card,
  CardContent,
  Typography,
  Tooltip,
  Button,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import LaunchIcon from '@material-ui/icons/Launch';
import PeopleIcon from '@material-ui/icons/People';
import DesktopMacIcon from '@material-ui/icons/DesktopMac';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import VideocamIcon from '@material-ui/icons/Videocam';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ContactlessIcon from '@material-ui/icons/Contactless';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import PageContent from 'components/PageContent/PageContent';
import PageContentBody from 'components/PageContent/PageContentBody';
import SessionControlNavbar from 'components/Navbars/SessionControlNavbar';
import SessionFeedbackDetail from 'views/Admin/SessionFeedbackDetail/SessionFeedbackDetail';
import Slides from 'views/Admin/Slides/Slides';
import Welcome from 'views/Admin/Welcome/Welcome';
import Admin2510Detail from 'views/Admin/25:10/Admin2510Detail';
import AdminBrainwriting from 'views/Admin/Brainwriting/AdminBrainwriting';
import GiveawayAdmin from 'views/Admin/Giveaway/GiveawayAdmin';
import AssessmentAdmin from 'views/Admin/Assessment/AssessmentAdmin';
import AssessmentAdminAux from 'views/Admin/Assessment/AssessmentAdminAux';
import SurveyAdmin from 'views/Admin/Survey/SurveyAdmin';
import CardSortingAdmin from 'views/Admin/CardSorting/CardSortingAdmin';
import NarrowingAdmin from 'views/Admin/Narrowing/NarrowingAdmin';
import NoteAndVoteAdmin from 'views/Admin/NoteAndVote/NoteAndVoteAdmin';
import NoteAndCategorizeAdmin from 'views/Admin/NoteAndCategorize/NoteAndCategorizeAdmin';
import NoteAndMapAdmin from 'views/Admin/NoteAndMap/NoteAndMapAdmin';
import UserJourneyMomentsAdmin from 'views/Admin/UserJourneyMoments/UserJourneyMomentsAdmin';
import PollingAdmin from 'views/Admin/Polling/PollingAdmin';
import BreakoutsDetailAdmin from 'views/Admin/Breakouts/BreakoutsDetailAdmin';
import HeatMappingAdmin from 'views/Admin/HeatMapping/HeatMappingAdmin';
import HowMightWeAdmin from 'views/Admin/HowMightWe/HowMightWeAdmin';
import MuralAdmin from 'views/Admin/Mural/MuralAdmin';
import MuralAdminView from 'components/Mural/Admin/MuralAdminView';
import StoryFlowAdmin from 'views/Admin/StoryFlow/StoryFlowAdmin';
import ScorecardAdmin from 'views/Admin/Scorecard/ScorecardAdmin';
import LightningDemoAdmin from 'views/Admin/LightningDemo/LightningDemoAdmin';
import ParticipantViewSelect from '../../../components/ParticipantViewSelect/ParticipantViewSelect';
import FacilitatorViewSelect from '../../../components/FacilitatorViewSelect/FacilitatorViewSelect';
import AdminTimer from '../../../components/AdminTimer/AdminTimer';
import AdminBroadcast from '../../../components/AdminBroadcast';
import EditActivityButton from 'components/EditActivityButton';
import Spinner from '../../../components/Spinner';
import ActivitiesControlButtons from 'components/ActivitiesControlButtons';
import { MeetRoom, MODE, MeetBroadcast } from '@voltage-control/control-room-meet-api';
import AlertDialog from '../../../components/AlertDialog';
import FormDialog from '../../../components/Dialogs/FormDialog';
import AddCoFacilitatorForm from '../../../components/Forms/AddCoFacilitatorForm';
import SessionParticipantsDialog from '../../../components/Dialogs/SessionParticipants';
import SketchesView from 'components/HeatMapping/Admin/SketchesView';
import GroupsView from 'components/HowMightWe/Admin/GroupsView';
import JourneyVoteReview from 'components/UserJourneyMoments/Admin/JourneyVoteReview';
import StoryFlowViews from 'components/StoryFlow/Admin/StoryFlowViews';
import LightningDemoViews from 'components/LightningDemo/Admin/LightningDemoViews';
import ScorecardResults from 'components/Scorecard/Admin/ScorecardResults';
import NoteAndCategorizeResults from 'components/NoteAndCategorize/Admin/NoteAndCategorizeResults';
import NoteAndVoteReview from 'components/NoteAndVote/Admin/NoteAndVoteReview';
import LiveScribingAdmin from 'views/Admin/LiveScribing/LiveScribingAdmin';
import LiveScribingWrite from 'components/LiveScribing/Admin/LiveScribingWrite';
import NoteAndMapAdminViews from 'components/NoteAndMap/Admin/NoteAndMapAdminViews';
import CardSortingAdminViews from 'components/CardSorting/Admin/CardSortingAdminViews';
import NarrowingAdminViews from 'components/Narrowing/Admin/NarrowingAdminViews';
import PollingReview from 'components/Polling/Admin/PollingReview';
import SurveyReview from 'components/Survey/Admin/SurveyReview';
import Admin2510Review from 'components/25:10/Admin/Admin2510Review';
import BrainwritingAdminReview from 'components/Brainwriting/Admin/BrainwritingAdminReview';
import AssessmentAdminQuestinonResult from 'components/Assessment/Admin/AssessmentAdminQuestionResult';
// actions
import { openPremiumSubscriptionDialog } from 'redux/actions/facilitatorSessionActions';
import { updateShowVideo } from '../../../redux/actions/facilitatorSessionActions';
import {
  updateMeetingRoom,
  joinMeetingRoom,
  joinMainRoom,
  initMeetingRoom,
  destroySidebarMeeting,
  leaveSidebarMeeting,
} from '../../../redux/actions/meetingRoomActions';
import { updateBreakout } from '../../../redux/actions/breakoutsActions';
import {
  enqueueSuccessSnackbar,
  enqueueErrorSnackbar,
  enqueueSnackbar,
} from '../../../redux/actions/notifierActions';
import { updateIsBroadcasting } from '../../../redux/actions/sessionActions';
// selectors
import { useFacilitatorSessionSelector } from '../../../redux/selectors/facilitatorSessionSelectors';
import { useMeetingRoomSelector } from '../../../redux/selectors/meetingRoomSelectors';
import { getCurrentActivityType } from '../../../redux/selectors/participantSessionSelectors';
import { getCurrentSidebarMeetingForUser } from '../../../redux/selectors/sidebarMeetingsSelectors';
import {
  getConnectedParticipants,
  getParticipantsMap,
} from '../../../redux/selectors/participantsSelectors';
import { makeGetSession } from 'redux/selectors/getSession';
import { facilitatorSubscriptionSelector } from 'redux/selectors/facilitatorSessionSelectors';
// styles
import './sessionDetail.scss';
// utils
import { localStorageSetObject } from '../../../utils/localStorageUtils';
import {
  createBreakoutMeetRoomId,
  createMainMeetRoomId,
  createSidebarMeetRoomId,
} from '../../../utils/createMeetRoomIds';
import { assignParticipantsToRooms } from 'utils/Breakouts/roomAssignmentHelper';
import { getStoredFacilitatorRouteKey } from 'utils/localStorageUtils';
import { getSessionData } from 'utils/getSessionData';

const useButtonStyles = makeStyles({
  root: {
    padding: 4,
    textTransform: 'none',
    fontWeight: 400,
    fontSize: 14,
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
});

const useCopyParticipantStyles = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    height: 35,
    width: 145,
    padding: 4,
    textTransform: 'none',
    fontWeight: 400,
    fontSize: 14,
    backgroundColor: theme.palette.success.main,
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.25)',
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.success.main,
    },
  },
}));

const SessionDetail = ({ isViewingShared, sessionsType }) => {
  const buttonClasses = useButtonStyles();
  const copyLinkClasses = useCopyParticipantStyles();
  const smallScreen = useMediaQuery('(max-width: 760px)');
  const theme = useTheme();
  const dispatch = useDispatch();
  const [participantListDialogOpen, setParticipantListDialogOpen] = useState(false);
  const [meetRoomPool, setMeetRoomPool] = useState([]);
  const [isBroadcasting, setIsBroadcasting] = useState(false);
  const [isConnectingToBroadcast, setIsConnectingToBroadcast] = useState(false);
  const [isCoFacilitatorFormOpen, setIsCoFacilitatorFormOpen] = useState(false);
  const db = useFirestore();
  const location = useLocation();
  const history = useHistory();
  const { sessionId } = useParams();
  const authUser = useSelector(state => state.firebase.auth);
  const getSession = makeGetSession();
  const sidebarMeetings = useSelector(state => state.firestore.data.sidebarMeetings);
  const { showVideo, selectedFacilitatorRoute } = useFacilitatorSessionSelector();
  const {
    roomStatus,
    roomId,
    videoMuted,
    audioMuted,
    roomName,
    facilitatorIsBroadcasting,
    roomIsSidebar,
    jitsiDevices,
    roomIsMain,
    roomIsBreakout,
    previousRoom,
  } = useMeetingRoomSelector();
  const currentSidebar = useSelector(getCurrentSidebarMeetingForUser(authUser.uid));
  const currentActivityType = useSelector(getCurrentActivityType);
  const session = useSelector(state => getSession(state, sessionId, sessionsType));
  const participantsListener = useSelector(state => state.firestore.data.participants);
  const participants = useSelector(getParticipantsMap);
  const connectedParticipants = useSelector(getConnectedParticipants);
  const connectedParticipantsCount = connectedParticipants.length;
  const participantBreakout = useSelector(state => state.firestore.data.participantBreakout);
  const participantBreakoutIsLoaded = isLoaded(participantBreakout);
  const participantBreakoutIsEmpty = isEmpty(participantBreakout);
  const participantBreakoutHasRooms = participantBreakout?.rooms?.length;
  const assignedBreakoutParticipantsCount = participantBreakout?.participantRoomAssignments?.length;
  const sessionIsLoaded = isLoaded(session);
  const sessionIsEmpty = isEmpty(session);
  const avEnabled = session?.avEnabled;
  const sidebarMeetingsIsLoaded = isLoaded(sidebarMeetings);
  const { isSubscriber } = useSelector(facilitatorSubscriptionSelector());

  // because firestore where-in query will break if you don't pass it a non-empty array...
  const coFacilitators = session?.coFacilitators?.length ? session.coFacilitators : ['empty'];

  const toggleParticipantList = () => {
    setParticipantListDialogOpen(!participantListDialogOpen);
  };

  let pariticpantBreakoutActivity;

  if (currentActivityType === 'breakouts') {
    pariticpantBreakoutActivity = find(session.activities, ['route', session.activeRoute]);
  }

  useFirestoreConnect(() => [
    {
      collection: 'sessions',
      doc: `${sessionId}`,
      storeAs: 'session',
    },
    {
      collection: 'participants',
      doc: `${sessionId}`,
      storeAs: 'participants',
    },
    {
      collection: 'breakouts',
      doc: `${pariticpantBreakoutActivity?.id}`,
      storeAs: 'participantBreakout',
    },
    {
      collection: 'sidebarMeetings',
      where: ['sessionId', '==', `${sessionId}`],
    },
    {
      collection: 'users',
      where: [['uid', 'in', coFacilitators]],
      storeAs: 'coFacilitators',
    },
  ]);

  useParticipants();

  /**
   * set audio/video defaults for facilitators
   */
  useEffect(() => {
    dispatch(updateMeetingRoom({ videoMuted: false, audioMuted: false }));
  }, []);

  /**
   * automatically route to the main meeting room if breakouts is not the current activity
   * and they don't have an active sidebar.
   * When Breakouts is the activity, the auto-routing is handled in...
   *
   * /views/Admin/Breakouts/BreakoutDetailAdmin.js
   *
   * ...if the participant is currently in a breakout room
   */
  useEffect(() => {
    if (sessionIsLoaded && sessionIsEmpty) return;
    if (!sessionIsLoaded || !sidebarMeetingsIsLoaded) return;

    // this user is co-facilitator in a sidebar started by the
    // facilitator and the sidebar has ended
    if (avEnabled && roomIsSidebar && !currentSidebar) {
      if (previousRoom.roomId) {
        dispatch(
          joinMeetingRoom(
            {
              roomId: previousRoom.roomId,
              roomName: previousRoom.roomName,
            },
            `Joining room: ${previousRoom.roomName}`,
          ),
        );
      } else {
        dispatch(leaveSidebarMeeting(roomId, authUser.uid));
      }
      return;
    }

    if (
      (!roomId && avEnabled && !currentSidebar) ||
      (avEnabled &&
        // !currentActivityType === 'breakouts' &&
        !roomIsBreakout &&
        roomId !== createMainMeetRoomId(sessionId) &&
        roomStatus !== 'routing' &&
        !currentSidebar)
    ) {
      console.log('auto routing to main room...');
      dispatch(joinMainRoom(session.id, { roomStatus: 'loading' }));
      return;
    }

    if (!avEnabled) {
      dispatch(initMeetingRoom());
    }
  }, [
    roomId,
    avEnabled,
    roomStatus,
    roomIsSidebar,
    currentSidebar,
    sessionIsLoaded,
    sessionIsEmpty,
    sidebarMeetingsIsLoaded,
  ]);

  /**
   * Set current room pool based on if the current participant activity is breakouts
   */
  useEffect(() => {
    if (sessionIsLoaded && sessionIsEmpty) return;

    if (participantBreakoutHasRooms && sessionIsLoaded) {
      let nextMeetRoomPool = [];

      nextMeetRoomPool = participantBreakout.rooms.map(room => ({
        ...room,
        id: createBreakoutMeetRoomId(session.id, room.id),
      }));

      nextMeetRoomPool = union(
        [
          {
            id: createMainMeetRoomId(sessionId),
            title: 'Main',
          },
        ],
        nextMeetRoomPool,
      );

      setMeetRoomPool(nextMeetRoomPool);
    }
  }, [participantBreakoutHasRooms, sessionIsLoaded, sessionIsEmpty]);

  /**
   * Auto-assign participants to breakout rooms if the current participant activity type is breakouts
   */
  useEffect(() => {
    if (sessionIsLoaded && sessionIsEmpty) return;

    if (!participantBreakoutIsLoaded || participantBreakoutIsEmpty) {
      return;
    }

    const { rooms, breakoutStarted, participantsChooseRooms, participantRoomAssignments } =
      participantBreakout;

    if (
      currentActivityType !== 'breakouts' ||
      !breakoutStarted ||
      participantsChooseRooms ||
      !connectedParticipantsCount
    ) {
      return;
    }

    const update = assignParticipantsToRooms({
      participants: connectedParticipants,
      rooms,
      assignments: participantRoomAssignments || [],
    });

    if (!isEqual(participantRoomAssignments, update) && update.length) {
      console.log('assigning users to breakouts...');
      dispatch(updateBreakout(participantBreakout.id, { participantRoomAssignments: update }));
    }
  }, [
    connectedParticipantsCount,
    currentActivityType,
    participantBreakoutIsLoaded,
    participantBreakoutIsEmpty,
    sessionIsLoaded,
    sessionIsEmpty,
    assignedBreakoutParticipantsCount,
  ]);

  // route user to their sidebar room if it exists
  useEffect(() => {
    if (sessionIsLoaded && sessionIsEmpty) return;

    if (!sessionIsLoaded || !sidebarMeetingsIsLoaded) return;

    if (currentSidebar) {
      dispatch(
        joinMeetingRoom(
          {
            roomId: createSidebarMeetRoomId(sessionId, currentSidebar.id),
            roomName: 'Sidebar',
          },
          'Joining Sidebar room',
        ),
      );
    }
  }, [
    currentSidebar,
    sessionIsLoaded,
    sessionIsEmpty,
    sidebarMeetingsIsLoaded,
    sessionId,
    currentActivityType,
  ]);

  /**
   * mixpanel
   * reset meet room to default on unload
   */
  useEffect(() => {
    if (sessionIsLoaded && sessionIsEmpty) return;

    if (process.env.REACT_APP_HOSTING_ENV === 'prod') {
      mixpanel.track('Facilitator Viewed Meeting');
    }

    // reset meeting room data on unload
    return () => {
      console.log('session unloading', session?.id);
      dispatch(initMeetingRoom());
    };
  }, [sessionIsLoaded, sessionIsEmpty]);

  /**
   * add facilitator data to session if doesn't exist
   */
  useEffect(() => {
    if (sessionIsLoaded && sessionIsEmpty) return;

    // add facilitator name to session on load if it doesn't exist
    if (
      authUser.uid === session?.facilitatorId &&
      (!session?.facilitatorName || session?.facilitatorName !== authUser.displayName) &&
      authUser.displayName
    ) {
      console.log('sessionId: ', sessionId);
      db.doc(`/sessions/${sessionId}`).update({ facilitatorName: authUser.displayName });
    }
  }, [sessionIsLoaded, sessionIsEmpty, sessionId, authUser.displayName, authUser.uid, session]);

  useEffect(() => {
    /**
     * wrapping everything in an anonymous function here
     * so we can use async/await
     */
    (async () => {
      if (sessionIsLoaded && sessionIsEmpty) {
        const sessionCheck = await getSessionData(sessionId);

        if (sessionCheck?.facilitatorId === authUser.uid) {
          if (sessionsType === 'past-sessions') {
            /**
             * if the current url contains /past-sessions, check if the
             * session document exists and if it does, try to redirect to
             * /sessions/{sessionId}
             */
            if (sessionCheck?.id) {
              history.replace(`/admin/sessions/${sessionId}`);
              return;
            }
          } else if (sessionsType === 'sessions') {
            /**
             * if the current url contains /sessions, check if the
             * session document exists and if it does, try to redirect to
             * /past-sessions/{sessionId}
             */
            if (sessionCheck?.id) {
              history.replace(`/admin/past-sessions/${sessionId}`);
              return;
            }
          }
        } else if (
          /**
           * If the user is a co-facilitator on the session, but they
           * are accidentally at a /sessions or /past-sessions route,
           * redirect them to /shared-sessions/{sessionId}
           */
          sessionCheck?.facilitatorId !== authUser.uid &&
          sessionCheck?.coFacilitators.includes(authUser.uid) &&
          ['sessions', 'past-sessions'].includes(sessionsType)
        ) {
          if (sessionCheck?.id) {
            history.replace(`/admin/shared-sessions/${sessionId}`);
            return;
          }
        }

        history.replace(`/admin/${sessionsType}`);
        return;
      }

      if (sessionIsLoaded && !sessionIsEmpty && sessionId) {
        const selectedRoute =
          localStorage.getItem(getStoredFacilitatorRouteKey(sessionId)) || 'welcome';

        const redirectRoute = `/admin/${sessionsType}/${sessionId}/${selectedRoute}`;

        if (location.pathname !== redirectRoute) {
          history.replace(redirectRoute);
        }
      }
    })();
  }, [sessionIsLoaded, sessionIsEmpty, sessionId, sessionsType]);

  const notPremiumActivityMaxSize = 3;
  // const isSubscriber = userRole === 'subscriber';
  // determines the account limit depending on the user's role
  const isUserAccountLimit =
    isSubscriber || (!isSubscriber && session?.activities?.length < notPremiumActivityMaxSize);

  const toggleCoFacilitatorForm = () => {
    isSubscriber
      ? setIsCoFacilitatorFormOpen(!isCoFacilitatorFormOpen)
      : dispatch(openPremiumSubscriptionDialog(true));
  };

  const copyParticipantLink = () => {
    copy(session.participantLink, {
      onCopy: () => {
        dispatch(enqueueSuccessSnackbar('Participant Link Copied'));
      },
      format: 'text/plain',
    });
  };

  const getCurrentMeetRoomPoolIdx = () => findIndex(meetRoomPool, ['id', roomId]);

  if (!sessionIsLoaded || sessionIsEmpty) return null;
  if (!isLoaded(participantsListener)) return null;

  const numActiveParticipantsCount =
    session.numActiveParticipants > connectedParticipantsCount
      ? connectedParticipantsCount
      : session.numActiveParticipants;

  return (
    <>
      {isMobile && avEnabled && !localStorage.getItem('mobileAvMessage') && (
        <AlertDialog
          onDismiss={() => {
            localStorage.setItem('mobileAvMessage', true);
          }}
        >
          {MOBILE_DETECT_MESSAGE}
        </AlertDialog>
      )}
      <PageContent className="session-detail">
        <SessionControlNavbar session={session} isViewingShared={isViewingShared} />
        {currentActivityType === 'welcome' && avEnabled && (
          <div className="w-100 bg-warning p-1">
            <span>
              <b>Notice:</b>
            </span>{' '}
            Participants are not included in Audio/Video when participant view is set to Welcome
          </div>
        )}
        <div
          className="session-detail-control"
          style={{
            justifyContent: 'space-evenly',
            flexDirection: smallScreen ? 'column' : 'row',
            alignItems: smallScreen && 'center',
          }}
        >
          <div style={{ maxWidth: 350, minWidth: 270 }}>
            <FacilitatorViewSelect
              session={session}
              sessionsType={sessionsType}
              isUserAccountLimit={isUserAccountLimit}
            />
            <div style={{ marginLeft: 2 }} className="d-flex justify-content-between">
              <EditActivityButton isUserAccountLimit={isUserAccountLimit} session={session} />
              {!isViewingShared && (
                <Tooltip title="add co-facilitator" arrow enterDelay={300}>
                  <Button
                    onClick={toggleCoFacilitatorForm}
                    className="mr-1 flex-shrink-0"
                    classes={buttonClasses}
                    startIcon={<GroupAddIcon fontSize="inherit" color="primary" />}
                    color="primary"
                  >
                    Add Co-facilitator
                  </Button>
                </Tooltip>
              )}
            </div>
          </div>
          <div className="mx-1 position-relative">
            <AdminTimer session={session} />
            <ActivitiesControlButtons activities={session.activities} sessionsType={sessionsType} />
          </div>
          <div style={{ maxWidth: 350, minWidth: 270 }}>
            <ParticipantViewSelect session={session} />
            <div className="d-flex align-items-center ml-1">
              <Tooltip title="copy participant link" arrow enterDelay={300}>
                <Button
                  onClick={copyParticipantLink}
                  size="small"
                  className="mr-auto"
                  classes={copyLinkClasses}
                  startIcon={<LaunchIcon fontSize="inherit" />}
                  color="primary"
                >
                  Participant Link
                </Button>
              </Tooltip>
              <div className="d-flex">
                <Tooltip title="conected participants" arrow enterDelay={300}>
                  <Button
                    onClick={toggleParticipantList}
                    size="small"
                    className="mr-2"
                    classes={buttonClasses}
                    style={{ color: theme.palette.primary.main }}
                    startIcon={<PeopleIcon color="primary" />}
                  >
                    {connectedParticipants.length}
                  </Button>
                </Tooltip>
              </div>
              <div className="d-flex">
                <Tooltip title="active participants" arrow enterDelay={300}>
                  <Button
                    onClick={toggleParticipantList}
                    size="small"
                    className="mr-1"
                    classes={buttonClasses}
                    style={{ color: theme.palette.primary.main }}
                    startIcon={<DesktopMacIcon colore="primary" />}
                  >
                    {numActiveParticipantsCount}
                  </Button>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      </PageContent>
      <PageContentBody>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6} lg={6} xl={6} style={{ marginBottom: 30 }}>
            <Card style={{ minHeight: 360 }} className="d-flex flex-column">
              <CardContent className="d-flex flex-column flex-1" style={{ padding: '24px 30px' }}>
                <Switch>
                  <Route
                    path={`/admin/${sessionsType}/${sessionId}/feedback`}
                    render={routerProps => (
                      <SessionFeedbackDetail {...routerProps} session={session} />
                    )}
                  />
                  <Route
                    path={`/admin/${sessionsType}/${sessionId}/slides`}
                    render={routerProps => (
                      <Slides
                        {...routerProps}
                        session={session}
                        isViewingShared={isViewingShared}
                      />
                    )}
                  />
                  <Route
                    path={`/admin/${sessionsType}/${sessionId}/25:10/:activityId`}
                    render={routerProps => (
                      <Admin2510Detail {...routerProps} sessionId={sessionId} />
                    )}
                  />
                  <Route
                    path={`/admin/${sessionsType}/${sessionId}/breakouts/:breakoutId`}
                    render={routerProps => (
                      <BreakoutsDetailAdmin {...routerProps} sessionId={sessionId} />
                    )}
                  />
                  <Route
                    path={`/admin/${sessionsType}/${sessionId}/brainwriting/:activityId`}
                    render={routerProps => (
                      <AdminBrainwriting {...routerProps} sessionId={sessionId} />
                    )}
                  />
                  <Route
                    path={`/admin/${sessionsType}/${sessionId}/noteAndVote/:activityId`}
                    render={routerProps => (
                      <NoteAndVoteAdmin {...routerProps} sessionId={sessionId} />
                    )}
                  />
                  <Route
                    path={`/admin/${sessionsType}/${sessionId}/polling/:activityId`}
                    render={routerProps => <PollingAdmin {...routerProps} sessionId={sessionId} />}
                  />
                  <Route
                    path={`/admin/${sessionsType}/${sessionId}/welcome`}
                    render={routerProps => <Welcome {...routerProps} sessionId={sessionId} />}
                  />
                  <Route
                    path={`/admin/${sessionsType}/${sessionId}/noteAndCategorize/:activityId`}
                    render={routerProps => (
                      <NoteAndCategorizeAdmin {...routerProps} sessionId={sessionId} />
                    )}
                  />
                  <Route
                    path={`/admin/${sessionsType}/${sessionId}/noteAndMap/:activityId`}
                    render={routerProps => (
                      <NoteAndMapAdmin {...routerProps} sessionId={sessionId} />
                    )}
                  />
                  <Route
                    path={`/admin/${sessionsType}/${sessionId}/heatMapping/:activityId`}
                    render={routerProps => (
                      <HeatMappingAdmin {...routerProps} sessionId={sessionId} />
                    )}
                  />
                  <Route
                    path={`/admin/${sessionsType}/${sessionId}/noteAndCategorizeDerivatives/:activityId`}
                    render={routerProps => (
                      <NoteAndCategorizeAdmin {...routerProps} sessionId={sessionId} />
                    )}
                  />
                  <Route
                    path={`/admin/${sessionsType}/${sessionId}/howMightWe/:activityId`}
                    render={routerProps => (
                      <HowMightWeAdmin {...routerProps} sessionId={sessionId} />
                    )}
                  />
                  <Route
                    path={`/admin/${sessionsType}/${sessionId}/userJourneyMoments/:activityId`}
                    render={routerProps => (
                      <UserJourneyMomentsAdmin {...routerProps} sessionId={sessionId} />
                    )}
                  />
                  <Route
                    path={`/admin/${sessionsType}/${sessionId}/lightningDemo/:activityId`}
                    render={routerProps => (
                      <LightningDemoAdmin {...routerProps} sessionId={sessionId} />
                    )}
                  />
                  <Route
                    path={`/admin/${sessionsType}/${sessionId}/storyFlow/:activityId`}
                    render={routerProps => (
                      <StoryFlowAdmin {...routerProps} sessionId={sessionId} />
                    )}
                  />
                  <Route
                    path={`/admin/${sessionsType}/${sessionId}/scorecard/:activityId`}
                    render={routerProps => (
                      <ScorecardAdmin {...routerProps} sessionId={sessionId} />
                    )}
                  />
                  <Route
                    path={`/admin/${sessionsType}/${sessionId}/liveScribing/:activityId`}
                    render={routerProps => (
                      <LiveScribingAdmin {...routerProps} sessionId={sessionId} />
                    )}
                  />
                  <Route
                    path={`/admin/${sessionsType}/${sessionId}/cardSorting/:activityId`}
                    render={routerProps => (
                      <CardSortingAdmin {...routerProps} sessionId={sessionId} />
                    )}
                  />
                  <Route
                    path={`/admin/${sessionsType}/${sessionId}/narrowing/:activityId`}
                    render={routerProps => (
                      <NarrowingAdmin {...routerProps} sessionId={sessionId} />
                    )}
                  />
                  <Route
                    path={`/admin/${sessionsType}/${sessionId}/giveaway/:activityId`}
                    render={routerProps => <GiveawayAdmin {...routerProps} sessionId={sessionId} />}
                  />
                  {/* Assessment V1 */}
                  <Route
                    path={`/admin/${sessionsType}/${sessionId}/survey/:activityId`}
                    render={routerProps => <SurveyAdmin {...routerProps} sessionId={sessionId} />}
                  />
                  {/* Assessment V2 */}
                  <Route
                    path={`/admin/${sessionsType}/${sessionId}/assessment/:activityId`}
                    render={routerProps => (
                      <AssessmentAdmin {...routerProps} sessionId={sessionId} />
                    )}
                  />
                  <Route
                    path={`/admin/${sessionsType}/${sessionId}/mural/:activityId`}
                    render={routerProps => <MuralAdmin {...routerProps} sessionId={sessionId} />}
                  />
                  <Redirect
                    from={`/admin/${sessionsType}/${sessionId}`}
                    to={`/admin/${sessionsType}/${sessionId}/welcome`}
                  />
                </Switch>
              </CardContent>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={6} lg={6} xl={6} style={{ marginBottom: 30 }}>
            {/* Meeting Room */}
            {avEnabled && !isMobile && (
              <Card
                className={cx('mb-3 rounded', {
                  visible: showVideo,
                  invisible: !showVideo,
                  'position-fixed': !showVideo,
                })}
                style={{ height: 448 }}
              >
                {roomStatus !== 'loaded' && (
                  <div className="d-flex flex-column justify-content-center align-items-center h-100">
                    <Typography style={{ fontSize: 20 }} className="mb-1">
                      {roomStatus === 'routing' ? 'Routing' : 'Meeting Room Loading'}
                    </Typography>
                    <Spinner />
                  </div>
                )}
                {roomId && authUser.displayName && (
                  <>
                    <div
                      style={{ height: 48 }}
                      className="d-flex align-items-center justify-content-between px-1"
                    >
                      <div style={{ overflow: 'hidden' }}>
                        <Typography className="text-nowrap">
                          <span className="fw-500 text-nowrap">Room:</span> {roomName}
                        </Typography>
                      </div>
                      <div className="text-nowrap">
                        {!roomIsMain && !roomIsSidebar && (
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            className="text-nowrap mr-1"
                            onClick={() => {
                              dispatch(joinMainRoom(session.id, {}));
                            }}
                          >
                            Back to Main Room
                          </Button>
                        )}
                        {roomIsSidebar && currentSidebar?.createdBy === authUser.uid && (
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            className="text-nowrap mr-1"
                            onClick={() => {
                              dispatch(destroySidebarMeeting(roomId));
                            }}
                          >
                            End Sidebar
                          </Button>
                        )}
                        {roomIsSidebar && currentSidebar?.createdBy !== authUser.uid && (
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            className="text-nowrap mr-1"
                            onClick={() => {
                              dispatch(leaveSidebarMeeting(roomId, authUser.uid, true));
                            }}
                          >
                            Leave Sidebar
                          </Button>
                        )}

                        {/* Sweep Room Buttons */}
                        {meetRoomPool.length > 1 && !roomIsSidebar && (
                          <>
                            <Tooltip title="go to previous room" arrow>
                              <IconButton
                                onClick={() => {
                                  const currentMeetRoomPoolIdx = getCurrentMeetRoomPoolIdx();
                                  const nextRoomIdx =
                                    currentMeetRoomPoolIdx > 0
                                      ? currentMeetRoomPoolIdx - 1
                                      : meetRoomPool.length - 1;

                                  const nextRoom = meetRoomPool[nextRoomIdx];

                                  dispatch(
                                    joinMeetingRoom(
                                      {
                                        roomId: nextRoom.id,
                                        roomName: nextRoom.title,
                                      },
                                      `Joining room: ${nextRoom.title}`,
                                    ),
                                  );
                                }}
                                size="small"
                              >
                                <ChevronLeftIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="go to next room" arrow>
                              <IconButton
                                size="small"
                                onClick={() => {
                                  const currentMeetRoomPoolIdx = getCurrentMeetRoomPoolIdx();
                                  const nextRoomIdx =
                                    currentMeetRoomPoolIdx === meetRoomPool.length - 1
                                      ? 0
                                      : currentMeetRoomPoolIdx + 1;

                                  const nextRoom = meetRoomPool[nextRoomIdx];

                                  dispatch(
                                    joinMeetingRoom(
                                      {
                                        roomId: nextRoom.id,
                                        roomName: nextRoom.title,
                                      },
                                      `Joining room: ${nextRoom.title}`,
                                    ),
                                  );
                                }}
                              >
                                <ChevronRightIcon />
                              </IconButton>
                            </Tooltip>
                          </>
                        )}
                        {/* END Sweep Room buttons */}

                        {/* Broadcast */}
                        {meetRoomPool.length > 1 && (
                          <>
                            {isConnectingToBroadcast ? (
                              <div
                                style={{
                                  position: 'relative',
                                  top: 4,
                                  display: 'inline-block',
                                  width: 14,
                                }}
                                className="mx-1"
                              >
                                <Spinner size={16} />
                              </div>
                            ) : (
                              <Tooltip title="Broadcast audio to all rooms" arrow>
                                <IconButton
                                  onClick={() => {
                                    if (isBroadcasting) {
                                      dispatch(updateIsBroadcasting(sessionId, false));
                                      setIsBroadcasting(false);
                                    } else {
                                      setIsConnectingToBroadcast(true);
                                      dispatch(updateIsBroadcasting(sessionId, true));
                                    }
                                  }}
                                  size="small"
                                  style={{
                                    color: isBroadcasting
                                      ? theme.palette.primary.main
                                      : theme.palette.grey[500],
                                  }}
                                  // className="mr-1"
                                >
                                  <ContactlessIcon />
                                </IconButton>
                              </Tooltip>
                            )}
                            {(isConnectingToBroadcast || isBroadcasting) && (
                              <MeetBroadcast
                                useInternal
                                roomIds={map(meetRoomPool, room => room.id.toLowerCase())}
                                devices={jitsiDevices}
                                userName={`Audio Broadcast - ${authUser.displayName.replace(
                                  /\W+/g,
                                  ' ',
                                )}`}
                                onConnectionFailed={err => {
                                  dispatch(
                                    enqueueErrorSnackbar(`Broadcast connection error: ${err}`),
                                  );
                                  dispatch(updateIsBroadcasting(sessionId, false));
                                  setIsBroadcasting(false);
                                  setIsConnectingToBroadcast(false);
                                }}
                                onRoomsJoined={() => {
                                  dispatch(updateMeetingRoom({ facilitatorIsBroadcasting: true }));
                                  dispatch(enqueueSuccessSnackbar('Broadcasting'));
                                  setIsBroadcasting(true);
                                  setIsConnectingToBroadcast(false);
                                }}
                                onDisconnected={() => {
                                  dispatch(enqueueSuccessSnackbar('Broadcast disconnected'));
                                  dispatch(
                                    updateMeetingRoom({
                                      facilitatorIsBroadcasting: false,
                                      audioMuted: false,
                                    }),
                                  );
                                }}
                              />
                            )}
                          </>
                        )}
                        {/* End Broadcast */}
                      </div>
                    </div>
                    <div
                      style={{ height: 400 }}
                      className={cx({
                        visible: roomStatus === 'loaded' && showVideo,
                        invisible: roomStatus !== 'loaded' || !showVideo,
                      })}
                    >
                      <MeetRoom
                        useInternal
                        mode={MODE.FACILITATOR}
                        roomId={roomId}
                        mute={facilitatorIsBroadcasting || audioMuted}
                        startWithVideoMuted={videoMuted}
                        // startWithAudioMuted={audioMuted}
                        devices={jitsiDevices}
                        onDevicesChanged={devices => {
                          if (!isEqual(devices, jitsiDevices)) {
                            localStorageSetObject('jitsiDeviceSettings', devices);
                            dispatch(updateMeetingRoom({ jitsiDevices: devices }));
                          }
                        }}
                        audioMuteStatusChanged={status => {
                          if (status !== audioMuted) {
                            dispatch(updateMeetingRoom({ audioMuted: status }));
                          }
                        }}
                        videoMuteStatusChanged={status => {
                          if (status !== videoMuted) {
                            dispatch(updateMeetingRoom({ videoMuted: status }));
                          }
                        }}
                        onLoad={() => {
                          dispatch(updateMeetingRoom({ roomStatus: 'loaded' }));
                        }}
                        userName={authUser.displayName.replace(/\W+/g, ' ')}
                        subject={roomName}
                      />
                    </div>
                  </>
                )}
              </Card>
            )}
            <Card className="h-100" style={{ minHeight: 360 }}>
              <CardContent style={{ padding: '20px 30px' }}>
                <AdminBroadcast session={session} />
              </CardContent>
            </Card>
          </GridItem>

          {/* Auxilary activity views */}
          <Switch>
            <Route
              path={`/admin/${sessionsType}/${sessionId}/noteAndVote/:activityId`}
              render={routerProps => <NoteAndVoteReview {...routerProps} sessionId={sessionId} />}
            />
            <Route
              path={`/admin/${sessionsType}/${sessionId}/noteAndCategorize/:activityId`}
              render={routerProps => (
                <NoteAndCategorizeResults {...routerProps} sessionId={sessionId} />
              )}
            />
            <Route
              path={`/admin/${sessionsType}/${sessionId}/heatMapping/:activityId`}
              render={routerProps => <SketchesView {...routerProps} sessionId={sessionId} />}
            />
            <Route
              path={`/admin/${sessionsType}/${sessionId}/howMightWe/:activityId`}
              render={routerProps => <GroupsView {...routerProps} sessionId={sessionId} />}
            />
            <Route
              path={`/admin/${sessionsType}/${sessionId}/userJourneyMoments/:activityId`}
              render={routerProps => <JourneyVoteReview {...routerProps} sessionId={sessionId} />}
            />
            <Route
              path={`/admin/${sessionsType}/${sessionId}/lightningDemo/:activityId`}
              render={routerProps => <LightningDemoViews {...routerProps} sessionId={sessionId} />}
            />
            <Route
              path={`/admin/${sessionsType}/${sessionId}/storyFlow/:activityId`}
              render={routerProps => <StoryFlowViews {...routerProps} sessionId={sessionId} />}
            />
            <Route
              path={`/admin/${sessionsType}/${sessionId}/scorecard/:activityId`}
              render={routerProps => <ScorecardResults {...routerProps} sessionId={sessionId} />}
            />
            <Route
              path={`/admin/${sessionsType}/${sessionId}/liveScribing/:activityId`}
              render={routerProps => <LiveScribingWrite {...routerProps} sessionId={sessionId} />}
            />
            <Route
              path={`/admin/${sessionsType}/${sessionId}/noteAndMap/:activityId`}
              render={routerProps => (
                <NoteAndMapAdminViews {...routerProps} sessionId={sessionId} />
              )}
            />
            <Route
              path={`/admin/${sessionsType}/${sessionId}/cardSorting/:activityId`}
              render={routerProps => (
                <CardSortingAdminViews {...routerProps} sessionId={sessionId} />
              )}
            />
            <Route
              path={`/admin/${sessionsType}/${sessionId}/narrowing/:activityId`}
              render={routerProps => <NarrowingAdminViews {...routerProps} sessionId={sessionId} />}
            />
            <Route
              path={`/admin/${sessionsType}/${sessionId}/polling/:activityId`}
              render={routerProps => <PollingReview {...routerProps} sessionId={sessionId} />}
            />
            <Route
              path={`/admin/${sessionsType}/${sessionId}/survey/:activityId`}
              render={routerProps => <SurveyReview {...routerProps} sessionId={sessionId} />}
            />
            {/* Question Drill down from Results view */}
            <Route
              path={`/admin/${sessionsType}/${sessionId}/assessment/:activityId/q/:questionId`}
              render={routerProps => (
                <AssessmentAdminQuestinonResult {...routerProps} sessionId={sessionId} />
              )}
            />
            <Route
              path={`/admin/${sessionsType}/${sessionId}/assessment/:activityId`}
              render={routerProps => <AssessmentAdminAux {...routerProps} sessionId={sessionId} />}
            />
            <Route
              path={`/admin/${sessionsType}/${sessionId}/25:10/:activityId`}
              render={routerProps => <Admin2510Review {...routerProps} sessionId={sessionId} />}
            />
            <Route
              path={`/admin/${sessionsType}/${sessionId}/brainwriting/:activityId`}
              render={routerProps => (
                <BrainwritingAdminReview {...routerProps} sessionId={sessionId} />
              )}
            />
            <Route
              path={`/admin/${sessionsType}/${sessionId}/mural/:activityId`}
              render={routerProps => <MuralAdminView {...routerProps} sessionId={sessionId} />}
            />
          </Switch>
        </GridContainer>
      </PageContentBody>
      <FormDialog
        handleClose={toggleCoFacilitatorForm}
        isOpen={isCoFacilitatorFormOpen}
        title="Add Co-Facilitator"
      >
        <AddCoFacilitatorForm closeDialog={toggleCoFacilitatorForm} />
      </FormDialog>
      <SessionParticipantsDialog
        open={participantListDialogOpen}
        closeDialog={toggleParticipantList}
      />
    </>
  );
};

SessionDetail.propTypes = {
  isViewingShared: PropTypes.bool, // viewing as co-facilitator
  sessionType: PropTypes.oneOf(['sessions', 'shared-sessions', 'past-sessions']),
};

export default SessionDetail;
