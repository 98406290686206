import React from 'react';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { includes } from 'lodash';
import { useSelector } from 'react-redux';

const queryString = require('query-string');

/**
 * A wrapper for <Route> that redirects to the login
 * screen if you're not yet authenticated or if auth is not
 * yet loaded
 */
export default function PrivateRoute({ children, ...rest }) {
  const auth = useSelector(state => state.firebase.auth);
  const history = useHistory();
  const location = useLocation();
  const urlParams = queryString.parse(location.search);

  if (auth.isLoaded && (auth.isEmpty || auth.isAnonymous)) {
    let path = '/auth/login-page';

    if (location.pathname) {
      path += `?redirectTo=${encodeURIComponent(location.pathname)}${encodeURIComponent(location.search)}`;
    }

    if (urlParams.loginEmail) {
      let prefix = includes(path, '?') ? '&' : '?';
      path += `${prefix}loginEmail=${encodeURIComponent(urlParams.loginEmail)}`;
    }

    history.push(path);
    return null;
  }

  return <Route {...rest} render={({ location }) => children} />;
}
