import React from 'react';
import { sortBy, map } from 'lodash';
import { useSelector } from 'react-redux';

// cr components
import Flow from 'components/NoteAndMap/Participant/Flow';
// selectors
import {
  useNoteAndMapSelector,
  getSuperVotes,
  getParticipantsFlows,
} from 'redux/selectors/noteAndMapSelectors';

/**
 * Note And Map view for the 'results' phase on the Participant side
 */
const NoteAndMapParticipantResults = () => {
  const {
    state: { activeView },
  } = useNoteAndMapSelector();

  const participantsFlows = useSelector(getParticipantsFlows());
  const superVoteTypes = useSelector(getSuperVotes());
  const sortedFlows = sortBy(participantsFlows, 'participantId');

  const renderFlow = (flow, idx, flowParticipantId) => (
    <Flow
      key={`moment-${idx}`}
      participantFlow={flow}
      context={activeView}
      disabled={true}
      superVoteTypes={superVoteTypes}
      createdFlowParticipantId={flowParticipantId}
    />
  );

  return <>{map(sortedFlows, (flow, idx) => renderFlow(flow.types, idx, flow.participantId))}</>;
};

export default NoteAndMapParticipantResults;
