import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
// library components
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
// cr components
import EditActivityForm from 'components/Forms/EditActivityForm/EditActivityForm';
import FormDialog from 'components/Dialogs/FormDialog';

const useStyles = makeStyles(theme => ({
  root: {
    fontWeight: 400,
    fontSize: 14,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

/**
 * Renders a button that opens a dialog for editing activities in a session.
 * The user can delete the activity, change the activity settings, or re-order the activity.
 */
const EditActivityButton = ({ isUserAccountLimit, session }) => {
  const classes = useStyles();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const toggleDialog = () => {
    setIsDialogOpen(!isDialogOpen);
  };

  return (
    <Box className="flex-shrink-0">
      <Button
        classes={classes}
        color="primary"
        style={{ textTransform: 'none' }}
        size="small"
        onClick={() => {
          toggleDialog();
        }}
        startIcon={<EditIcon />}
      >
        Edit Activities
      </Button>
      <FormDialog handleClose={toggleDialog} isOpen={isDialogOpen} title="Edit Activities">
        <EditActivityForm
          session={session}
          isUserAccountLimit={isUserAccountLimit}
          closeDialog={toggleDialog}
        />
      </FormDialog>
    </Box>
  );
};

EditActivityButton.propTypes = {
  isUserAccountLimit: PropTypes.bool, // the account limit depending on the user's role
  session: PropTypes.shape({
    id: PropTypes.string,
    activeRoute: PropTypes.string,
    activities: PropTypes.array,
  }).isRequired,
};

export default EditActivityButton;
