import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { map, find } from 'lodash';
import cx from 'classnames';
// library components
import { Box, Typography, Radio, IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// cr components
import AdminActivityAuxView from 'components/AdminActivityAuxView';
import AssessmentQuestionResults from '../Shared/QuestionResults';
// selectors
import { useAssessmentSelector, getSortedQuestions } from 'redux/selectors/assessmentSelectors';
// actions
import { updateQuestionResultView } from 'redux/actions/assessmentActions';
// constants
import { VARIABLE_QUESTION_TYPES } from 'components/CustomFormComponents/VariableQuestions/QuestionTypes/constants';
// styles
import { useAssessmentStyles } from '../Shared/useAssessmentStyles';

/**
 * Renders facilitator view of a single question results
 */
const AssessmentAdminQuestinonResult = () => {
  const urlParams = useParams();
  const history = useHistory();
  const commonClasses = useAssessmentStyles();
  const dispatch = useDispatch();
  const {
    id,
    config: { reviewResults },
    state: { currentQuestionIdx, activeView, questionResultView },
  } = useAssessmentSelector();
  const questions = useSelector(getSortedQuestions());

  /**
   * If there is a question id in the URL, grab that specific question.
   * This is only used for drilling down into a single question
   * from the final results view
   */
  const currentQuestion = urlParams.questionId
    ? find(questions, { id: urlParams.questionId })
    : questions[currentQuestionIdx];

  const currentQuestionType = currentQuestion.data.questionType;

  useEffect(() => {
    if (!['detail', 'summary'].includes(questionResultView)) {
      dispatch(updateQuestionResultView(id, 'summary'));
    }
  }, [currentQuestionType, questionResultView, reviewResults]);

  if (activeView === 'start') return null;

  const setQuestionResultView = view => {
    dispatch(updateQuestionResultView(id, view));
  };

  const renderTitleText = () => {
    if (activeView === 'results' || (activeView === 'write' && !reviewResults)) {
      return 'Results';
    } else {
      return `Question #${currentQuestionIdx + 1} of ${questions.length}`;
    }
  };

  const renderActionsLeft = () => {
    if (urlParams.questionId) {
      return (
        <IconButton onClick={history.goBack}>
          <ArrowBackIcon />
        </IconButton>
      );
    }
  };

  const renderActionsRight = () => {
    const choices = [
      { label: 'Summary', value: 'summary' },
      { label: 'Details', value: 'detail' },
    ];
    return (
      <ViewTypeToggle
        choices={choices}
        toggleValue={questionResultView}
        setToggleValue={setQuestionResultView}
      />
    );
  };

  return (
    <AdminActivityAuxView
      titleText={renderTitleText()}
      renderActionsLeft={renderActionsLeft}
      renderActionsRight={renderActionsRight}
    >
      <>
        <Box mb={3}>
          <Typography className={cx('text-center', commonClasses.questionText)}>
            {currentQuestion.data.question}
          </Typography>
        </Box>
        <AssessmentQuestionResults
          questionType={currentQuestion.data.questionType}
          questionId={currentQuestion.id}
        />
      </>
    </AdminActivityAuxView>
  );
};

const ViewTypeToggle = ({ choices, setToggleValue, toggleValue }) => {
  const handleChange = event => {
    setToggleValue(event.target.value);
  };

  return (
    <Box display="flex">
      {map(choices, (choice, idx) => (
        <Box key={`toggle-choice-${idx}`} display="flex" alignItems="center">
          <Radio
            color="primary"
            checked={toggleValue === choice.value}
            onChange={handleChange}
            value={choice.value}
            name={`toggle-choice-${idx}`}
            inputProps={{ 'aria-label': choice.label }}
          />
          <Typography>{choice.label}</Typography>
        </Box>
      ))}
    </Box>
  );
};

export default AssessmentAdminQuestinonResult;
