import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Switch, Route } from 'react-router-dom';
import { sessionRoutes } from 'routes.js';
import {
  useFirestoreConnect,
  isLoaded,
  isEmpty,
  useFirestore,
  useFirebase,
} from 'react-redux-firebase';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import mixpanel from 'mixpanel-browser';
import { isMobile, isBrowser } from 'react-device-detect';
import { MOBILE_DETECT_MESSAGE } from 'constants/general';
import { isEqual, includes } from 'lodash';

import PageVisibility from 'react-page-visibility';
import { Card, CardContent, Typography, Tabs, Tab, Button, Box } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import OverlaySpinner from '../components/OverlaySpinner';
import ParticipantSidebar from '../components/ParticipantSidebar/ParticipantSidebar';
import ParticipantNameForm from '../components/ParticipantNamePrompt/ParticipantNameForm';
import CrLogo from '../components/CrLogo/CrLogo';
import DelayedRender from '../components/DelayedRender/DelayedRender';
import Spinner from '../components/Spinner';

import ParticipantDetail from '../components/ParticipantNamePrompt/ParticipantDetail';
import Slides from '../views/Participant/Slides/Slides';
import { MeetRoom, MODE } from '@voltage-control/control-room-meet-api';
import AlertDialog from '../components/AlertDialog';

import {
  useParticipantSessionSelector,
  getParticipantCanControlView,
  getCurrentActivityType,
  getShowVideoOnboarding,
} from '../redux/selectors/participantSessionSelectors';
import { useMeetingRoomSelector } from '../redux/selectors/meetingRoomSelectors';
import { getParticipantRoom } from '../redux/selectors/Breakouts/rootBreakoutsSelector';
import { getCurrentSidebarMeetingForUser } from '../redux/selectors/sidebarMeetingsSelectors';

import {
  updateMeetingRoom,
  joinMainRoom,
  initMeetingRoom,
  joinMeetingRoom,
  leaveSidebarMeeting,
} from '../redux/actions/meetingRoomActions';
import { updateParticipantSessionView } from '../redux/actions/participantSessionActions';

import { createMainMeetRoomId, createSidebarMeetRoomId } from '../utils/createMeetRoomIds';
import { localStorageSetObject, localStorageGetObject } from '../utils/localStorageUtils';
import { impersonateUser } from 'utils/impersonateUser';

import styles from 'assets/jss/material-dashboard-pro-react/layouts/adminStyle.js';
import './participant.scss';
import firebase from 'firebase';

const queryString = require('query-string');

const useStyles = makeStyles(styles);

const useCusotmStyles = makeStyles(theme => ({
  sessionContentWrapper: {
    position: 'relative',
    marginRight: ({ participantCanControlView }) => (participantCanControlView ? 200 : 0),
    marginTop: 16,
    [theme.breakpoints.down('sm')]: {
      marginRight: '0px !important',
    },
  },
  returnToFacilitatorContainer: {
    top: 0,
    display: 'flex',
    justifyContent: 'center',
    marginBottom: `-${theme.spacing(2)}px`,
  },
}));

const useTabsStyles = makeStyles({
  indicator: {
    backgroundColor: 'transparent',
  },
});

const useTabStyles = makeStyles(theme => ({
  root: {
    background: 'white',
  },
  selected: {
    background: theme.palette.primary.main,
    color: 'white',
    boxShadow: theme.shadows[16],
  },
}));

export default function Session({
  match: {
    params: { sessionId },
  },
}) {
  /**
   * Have we alread verified that this user's display is not 'anonymous-user' and logged them
   * out if so so that they have to re-enter their name?
   */
  const [anonymousParticipantVerified, setAnonymousParticipantVerified] = useState(false);

  const location = useLocation();
  const authUser = useSelector(state => state.firebase.auth);
  const breakouts = useSelector(state => state.firestore.data.breakouts);
  const session = useSelector(state => state.firestore.data.session);
  const activeRoute = session?.activeRoute;
  const participantUpdating = useSelector(state => state.participantSession.updating);
  const showAppOverlaySpinner = useSelector(
    state => state.participantSession.showAppOverlaySpinner,
  );
  const classes = useStyles();
  const history = useHistory();
  const { selectedView } = useParticipantSessionSelector();
  const participantIsCounted = localStorage.getItem(
    `session_${sessionId}_participant_${authUser.uid}`,
  );
  const participantId = authUser.uid;
  const participantName = authUser.displayName;
  const db = useFirestore();
  const showPrompt = isEmpty(authUser) || (!isEmpty(authUser) && !authUser.displayName);
  const participantCanControlView = useSelector(getParticipantCanControlView);
  const currentActivityType = useSelector(getCurrentActivityType);
  const {
    roomStatus,
    roomId,
    videoMuted,
    audioMuted,
    roomName,
    roomIsMain,
    jitsiDevices,
    facilitatorIsBroadcasting,
  } = useMeetingRoomSelector();
  const currentSidebar = useSelector(getCurrentSidebarMeetingForUser(authUser.uid));
  const dispatch = useDispatch();
  const avEnabled = session?.avEnabled;
  const showVideoOnboarding = useSelector(getShowVideoOnboarding);
  const paticipantRoom = useSelector(getParticipantRoom(authUser.uid));
  const [activeTab, setActiveTab] = useState(selectedView);
  const tabClasses = useTabStyles();
  const tabsClasses = useTabsStyles();
  const lastActiveRoute = useRef('');
  const customClasses = useCusotmStyles({ participantCanControlView });
  const sessionFacilitatorIsBroadcasting = session?.facilitatorIsBroadcasting || false;
  const sessionIsLoaded = isLoaded(session);
  const sessionIsEmpty = isEmpty(session);
  const urlParams = queryString.parse(location.search);
  const meetingTitle = urlParams?.title;
  const impersonate = localStorageGetObject('impersonate');

  const isFacilitator =
    authUser.uid &&
    session?.facilitatorId &&
    (session?.facilitatorId.includes(authUser.uid) ||
      (session?.coFacilitators && session?.coFacilitators.includes(authUser.uid)));

  useFirestoreConnect(() => [
    {
      collection: 'sessions',
      doc: `${sessionId}`,
      storeAs: 'session',
    },
  ]);

  /**
   * If the user is anonymously signed in under the name 'anonymous-user',
   * they previously visited a route that bypassed the participant name
   * form.  Log them out so they will have to provider their name.
   */
  useEffect(() => {
    if (!anonymousParticipantVerified) {
      if (participantId && participantName === 'anonymous-user') {
        firebase.logout();
      }

      setAnonymousParticipantVerified(true);
    }
  }, [participantId, participantName, anonymousParticipantVerified]);

  /**
   * Update facilitatorIsBroadcasting in meet room if the flag on the session document changes
   */
  useEffect(() => {
    dispatch(updateMeetingRoom({ facilitatorIsBroadcasting: sessionFacilitatorIsBroadcasting }));
  }, [sessionFacilitatorIsBroadcasting]);

  /**
   * automatically route to the main meeting room if breakouts is not the current activity,
   * the participant can control the view, and they don't have an active sidebar.  When
   * Breakouts is the activity, the auto-routing is handled in...
   *
   * /src/components/Breakouts/Participant/BreakoutParticipantRoom
   * If the participant is currently in a breakout room or...
   *
   * /src/views/Participant/Breakouts/BreakoutsDetailPariticpant.js
   * If the participants can choose their rooms and the participant is not in a room
   */
  useEffect(() => {
    if (!anonymousParticipantVerified) return;

    if (
      sessionId &&
      (!roomId || roomId !== createMainMeetRoomId(sessionId)) &&
      currentActivityType !== 'breakouts' &&
      participantCanControlView &&
      !roomIsMain &&
      !currentSidebar &&
      avEnabled
    ) {
      console.log('auto routing to main room...');
      dispatch(joinMainRoom(sessionId, { roomStatus: 'loading' }));
    }

    if (isLoaded(session) && !avEnabled) {
      dispatch(initMeetingRoom());
    }
  }, [
    sessionId,
    avEnabled,
    roomId,
    currentActivityType,
    participantCanControlView,
    currentSidebar,
    anonymousParticipantVerified,
  ]);

  // clear meeting room if participant can't toggle to it
  useEffect(() => {
    if (!participantCanControlView && sessionId) {
      dispatch(initMeetingRoom());
    }
  }, [participantCanControlView, sessionId]);

  // route user to their sidebar room if it exists
  useEffect(() => {
    if (!anonymousParticipantVerified) return;

    if (currentSidebar) {
      dispatch(
        joinMeetingRoom(
          {
            roomId: createSidebarMeetRoomId(sessionId, currentSidebar.id),
            roomName: 'Sidebar',
          },
          'Joining Sidebar room',
        ),
      );
    }
  }, [currentSidebar, anonymousParticipantVerified]);

  /**
   * Programatic session view control changes
   */
  // useEffect(() => {
  //   if (activeRoute && lastActiveRoute.current && activeRoute !== lastActiveRoute.current) {
  //     if (currentActivityType !== 'slides') {
  //       dispatch(updateParticipantSessionView('activity'));
  //     } else {
  //       dispatch(updateParticipantSessionView('slides'));
  //     }

  //     lastActiveRoute.current = activeRoute;
  //     return;
  //   }

  //   if (activeRoute && !lastActiveRoute.current && activeRoute !== lastActiveRoute.current) {
  //     lastActiveRoute.current = activeRoute;
  //   }

  //   if (avEnabled && showVideoOnboarding) {
  //     dispatch(updateParticipantSessionView('video'));
  //     return;
  //   }

  //   if (!avEnabled && selectedView !== 'slides' && currentActivityType && currentActivityType !== 'slides') {
  //     dispatch(updateParticipantSessionView('activity'));
  //     return;
  //   }

  //   if (currentActivityType === 'slides' && selectedView === 'activity') {
  //     console.log("currentActivityType === 'slides' && selectedView === 'activity'");
  //     dispatch(updateParticipantSessionView('slides'));
  //     return;
  //   }

  //   if (currentActivityType === 'breakouts' && breakouts?.participantsChooseRooms && !paticipantRoom) {
  //     dispatch(updateParticipantSessionView('activity'));
  //     return;
  //   }

  //   if (!participantCanControlView && currentActivityType !== 'slides') {
  //     dispatch(updateParticipantSessionView('activity'));
  //     return;
  //   } else if (!participantCanControlView && currentActivityType === 'slides') {
  //     dispatch(updateParticipantSessionView('slides'));
  //     return;
  //   }

  //   // dispatch(updateParticipantSessionView('activity'));

  // }, [currentActivityType, breakouts, showVideoOnboarding, avEnabled, activeRoute]);

  // useEffect(() => {
  //   setActiveTab(selectedView);
  // }, [selectedView])

  // send beacon on window close to remove participant from session tracking
  useEffect(() => {
    if (impersonate || !anonymousParticipantVerified) return;

    const handleBeforeUnload = event => {
      event.preventDefault();
      navigator.sendBeacon(
        `${process.env.REACT_APP_API_URL}/particicpants-sessionWindowClosed`,
        // 'http://localhost:5001/lightsaber-90578/us-central1/particicpants-sessionWindowClosed',
        JSON.stringify({ sessionId, participantId }),
      );

      return undefined; // keeps the warning dialog from showing
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [impersonate, anonymousParticipantVerified]);

  /**
   * Count participant if not already counted and add the participant
   * info to the participants Firestore document for this session
   */
  useEffect(() => {
    if (impersonate || !anonymousParticipantVerified) return;

    if (authUser.uid && !participantUpdating && sessionIsLoaded) {
      if (process.env.REACT_APP_HOSTING_ENV === 'prod') {
        mixpanel.track('Participant Viewed Meeting');
      }

      const batch = db.batch();

      if (!participantIsCounted) {
        console.log('counting participant');

        batch.set(
          db.collection('sessions').doc(sessionId),
          {
            numParticipants: db.FieldValue.increment(1),
          },
          { merge: true },
        );
      }

      batch.set(
        db.doc(`participants/${sessionId}`),
        {
          [`${authUser.uid}`]: {
            active: true,
            id: authUser.uid,
            name: authUser.displayName,
            heartbeat: db.FieldValue.serverTimestamp(),
          },
        },
        { merge: true },
      );

      batch
        .commit()
        .then(() => {
          if (!participantIsCounted) {
            localStorage.setItem(`session_${sessionId}_participant_${authUser.uid}`, true);
          }
        })
        .catch(err => {
          console.log('batch write error', err);
        });
    }
  }, [participantUpdating, sessionIsLoaded, impersonate, anonymousParticipantVerified]);

  useEffect(() => {
    if (!anonymousParticipantVerified) return;

    if (sessionIsLoaded && !sessionIsEmpty && session.activeRoute) {
      if (
        session.activeRoute === 'slides' ||
        (session.activeRoute === 'feedback' &&
          session.slidesSessionIds?.[0] &&
          localStorage.getItem(`submittedFeedbackFor_${sessionId}`))
      ) {
        const slidesSessionId = session.slidesSessionIds?.length
          ? session.slidesSessionIds[0]
          : undefined;
        history.push(`/session/${sessionId}/slides/${slidesSessionId}`);
      } else {
        history.push(`/session/${sessionId}/${session.activeRoute}${location.search}`);
      }
    } else if (sessionIsLoaded && (sessionIsEmpty || !session.activeRoute)) {
      console.log('bad session', session);
      history.push('/auth/404');
    }
  }, [session, sessionIsLoaded, sessionIsEmpty, sessionId, anonymousParticipantVerified]);

  const handleVisibilityChange = (isVisible, visibilityState) => {
    if (impersonate || !anonymousParticipantVerified) return;

    if (isVisible) {
      updateParticipantIsActive(true);
    } else if (visibilityState === 'hidden') {
      updateParticipantIsActive(false);
    }
  };

  const returnToFacilitator = () => {
    impersonateUser.returnToFacilitator();
  };

  const goToFacilitatorView = () => {
    window.location.href = `/admin/sessions/${session.id}`;
  };

  const updateParticipantIsActive = active => {
    if (impersonate || !anonymousParticipantVerified) return;

    if (isEmpty(authUser) || impersonate) return;

    db.doc(`participants/${sessionId}`)
      .update({
        [`${authUser.uid}.active`]: active,
      })
      .catch(err => {
        console.log('updateParticipantIsActiveerror', err);
      });
  };

  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }

      return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
    });
  };

  // const handleTabChange = (event, tabValue) => {
  //   dispatch(updateParticipantSessionView(tabValue));
  // }

  if (isLoaded(session) && isEmpty(session)) {
    history.push('/auth/404');
  }

  if (
    isEmpty(session) ||
    !isLoaded(authUser) ||
    !session?.activeRoute ||
    participantUpdating ||
    !anonymousParticipantVerified
  ) {
    return (
      <div className="position-fixed h-100 w-100 d-flex justify-content-center align-items-center">
        <DelayedRender delay={500}>
          <Spinner />
        </DelayedRender>
      </div>
    );
  }

  return (
    <PageVisibility onChange={handleVisibilityChange}>
      <Fragment>
        <Helmet>
          <title>Control Room | Participant</title>
          <link
            id="favicon"
            rel="icon"
            type="image/x-icon"
            href={`${window.location.origin}/favicon-green.ico`}
          />
        </Helmet>
        {showAppOverlaySpinner && <OverlaySpinner fullscreen />}
        <div className={cx(classes.wrapper)}>
          <div
            className={cx(classes.content, customClasses.sessionContentWrapper, 'mt-0 pt-0', {
              'mr-0': showPrompt,
            })}
          >
            <div className={cx(classes.container)}>
              {!showPrompt && ( // user has "logged in"
                <ParticipantDetail>
                  {impersonate && (
                    <Box className={customClasses.returnToFacilitatorContainer}>
                      <Button
                        style={{ zIndex: '2' }}
                        variant="contained"
                        color="secondary"
                        size="small"
                        onClick={returnToFacilitator}
                      >
                        <ChevronLeftIcon style={{ marginLeft: '-8px' }} />
                        Return to Facilitator View
                      </Button>
                    </Box>
                  )}
                  {!impersonate && isFacilitator && (
                    <Box className={customClasses.returnToFacilitatorContainer}>
                      <Button
                        style={{ zIndex: '2' }}
                        variant="contained"
                        color="secondary"
                        size="small"
                        onClick={goToFacilitatorView}
                      >
                        <ChevronLeftIcon style={{ marginLeft: '-8px' }} />
                        Facilitator View
                      </Button>
                    </Box>
                  )}
                  {isMobile && avEnabled && !localStorage.getItem('mobileAvMessage') && (
                    <AlertDialog
                      onDismiss={() => {
                        localStorage.setItem('mobileAvMessage', true);
                      }}
                    >
                      {MOBILE_DETECT_MESSAGE}
                    </AlertDialog>
                  )}
                  {showVideoOnboarding && avEnabled && !isMobile && (
                    <AlertDialog
                      onDismiss={() => {
                        localStorage.setItem('hasSeenVideoOnboarding', true);
                      }}
                      title="Welcome to Control Room Audio/Video"
                    >
                      You can unmute your mic and turn on your video below.
                      <br />
                      <br />
                      You can toggle your view between Slides, Video, and Activity using the tabs
                      above.
                    </AlertDialog>
                  )}
                  {/* <div className="d-flex justify-content-between mx-auto" style={{maxWidth: 1200}}> */}
                  {/* <CrLogo width={100} className="mt-1" withLink /> */}
                  {/* {participantCanControlView &&
                      <div className="d-flex justify-content-between">
                        {currentSidebar &&
                          <Button
                            size="small"
                            variant="outlined"
                            color="primary"
                            className="align-self-center mr-2"
                            onClick={() => {
                              dispatch(leaveSidebarMeeting(roomId, authUser.uid, true));
                            }}
                          >
                            Leave Sidebar
                          </Button>
                        }
                        <Tabs
                          value={activeTab}
                          onChange={handleTabChange}
                          indicatorColor="primary"
                          textColor="inherit"
                          className="shadow bg-white"
                          classes={tabsClasses}
                        >
                          <Tab
                            label="Slides"
                            disabled={!participantCanControlView}
                            value="slides"
                            className="border"
                            classes={tabClasses}
                          />
                          {avEnabled &&
                            <Tab
                              label="Video"
                              disabled={!participantCanControlView}
                              value="video"
                              className="border"
                              classes={tabClasses}
                            />
                          }
                          {currentActivityType !== 'slides' &&
                            <Tab
                              label="Activity"
                              disabled={currentActivityType === 'slides'}
                              value="activity"
                              className="border"
                              classes={tabClasses}
                            />

                          }
                        </Tabs>
                      </div>
                    } */}
                  {/* </div> */}
                  <div
                    className={cx({
                      'd-none': selectedView !== 'activity' && participantCanControlView,
                    })}
                  >
                    <Switch>{getRoutes(sessionRoutes)}</Switch>
                  </div>
                  <div
                    className={cx({
                      'd-none': selectedView !== 'slides' || !participantCanControlView,
                    })}
                  >
                    <Slides />
                  </div>

                  {/* Meeting Room */}
                  {participantCanControlView && avEnabled && (
                    <div
                      className={cx('video-container bg-white', {
                        invisible: selectedView !== 'video' || !participantCanControlView,
                        visible: selectedView === 'video' && participantCanControlView,
                      })}
                    >
                      {isMobile && (
                        <div className="h-100 d-flex justify-content-center align-items-center p-3">
                          <Typography style={{ fontSize: 20 }} className="mb-1">
                            Meeting audio and video require a desktop browser. Please join the
                            meeting from a computer.
                          </Typography>
                        </div>
                      )}
                      {isBrowser && (
                        <>
                          <>
                            {roomStatus !== 'loaded' && (
                              <div className="d-flex flex-column justify-content-center align-items-center h-100">
                                <Typography style={{ fontSize: 20 }} className="mb-1">
                                  Meeting Room Loading
                                </Typography>
                                <Spinner />
                              </div>
                            )}
                          </>
                          <div
                            className={cx('h-100 w-100', {
                              visible:
                                roomStatus === 'loaded' &&
                                selectedView === 'video' &&
                                participantCanControlView,
                              invisible:
                                roomStatus !== 'loaded' ||
                                selectedView !== 'video' ||
                                !participantCanControlView,
                            })}
                          >
                            <MeetRoom
                              useInternal
                              mode={MODE.PARTICIPANT}
                              chatEnabled
                              roomId={roomId}
                              mute={facilitatorIsBroadcasting || audioMuted}
                              startWithVideoMuted={videoMuted}
                              devices={jitsiDevices}
                              onDevicesChanged={devices => {
                                if (!isEqual(devices, jitsiDevices)) {
                                  localStorageSetObject('jitsiDeviceSettings', devices);
                                  dispatch(updateMeetingRoom({ jitsiDevices: devices }));
                                }
                              }}
                              audioMuteStatusChanged={status => {
                                if (status !== audioMuted) {
                                  dispatch(updateMeetingRoom({ audioMuted: status }));
                                }
                              }}
                              videoMuteStatusChanged={status => {
                                if (status !== videoMuted) {
                                  dispatch(updateMeetingRoom({ videoMuted: status }));
                                }
                              }}
                              onLoad={() => {
                                dispatch(updateMeetingRoom({ roomStatus: 'loaded' }));
                              }}
                              userName={authUser.displayName.replace(/\W+/g, ' ')}
                              subject={roomName}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </ParticipantDetail>
              )}
              {showPrompt && ( // user hasn't "logged in " yet
                <Card style={{ maxWidth: 375 }} className="mx-auto mt-12">
                  <CardContent>
                    <div className="d-flex flex-column align-items-center justify-content-center mb-4 mt-2">
                      <div>
                        <CrLogo />
                      </div>
                      {meetingTitle && (
                        <div className="mt-2">
                          <Typography variant="h6" className="font-weight-normal font-italic">
                            {decodeURIComponent(meetingTitle)}
                          </Typography>
                        </div>
                      )}
                    </div>
                    <ParticipantNameForm sessionId={sessionId} />
                  </CardContent>
                </Card>
              )}
            </div>
          </div>
          {!showPrompt && <ParticipantSidebar session={session} />}
        </div>
      </Fragment>
    </PageVisibility>
  );
}
