import React, { useEffect, useRef } from 'react';
import { useFirestore } from 'react-redux-firebase';
import { Form, Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { includes, keys } from 'lodash';
import cx from 'classnames';
// library components
import { CircularProgress, Typography, useTheme } from '@material-ui/core';
// cr components
import TextArea from 'components/CustomFormComponents/TextArea';
import TextEditor from 'components/CustomFormComponents/TextEditor';
import CustomButton from 'components/CustomButton/CustomButton';
import ButtonSpinner from 'components/ButtonSpinner';
// selectors
import { useBrainwritingSelector } from 'redux/selectors/brainwritingSelectors';
import { localStorageSetObject, localStorageGetObject } from '../../../utils/localStorageUtils';
import GrayBox from 'components/GrayBox/GrayBox';
// actions
import { enqueueErrorSnackbar } from 'redux/actions/notifierActions';

const BrainwritingParticipantWriteForm = () => {
  const db = useFirestore();
  const formRef = useRef();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { id, currentRound, ideas, rounds, participants, isTransitioning } =
    useBrainwritingSelector();
  const localStorageKey = `brainWriting_${id}_currentSubmission`;
  const participantsCount = participants.length;
  const participantId = useSelector(state => state.firebase.auth.uid);
  const activityRef = db.doc(`brainwriting/${id}`);
  const isFirstSubmission = currentRound === 1 || !includes(keys(ideas), participantId);
  const alreadySubmittedThisRound = rounds[currentRound].assignments[participantId]?.submitted;
  const session = useSelector(state => state.firestore.data.session);

  // add participant to activity
  useEffect(() => {
    if (!includes(participants, participantId)) {
      activityRef
        .set(
          {
            participants: db.FieldValue.arrayUnion(participantId),
          },
          { merge: true },
        )
        .catch(err => {
          console.log(err);
        });
    }
  }, [participantsCount]);

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    const currentSubmission = values.text;
    setSubmitting(true);
    const previousSubmission = localStorageGetObject(localStorageKey);

    localStorageSetObject(localStorageKey, { ...previousSubmission, currentSubmission });
    if (isFirstSubmission) {
      activityRef
        .set(
          {
            ideas: {
              [participantId]: {
                id: participantId,
                contributors: [],
                text: `─ Round ${currentRound} ─\n${currentSubmission}`,
                time: db.FieldValue.serverTimestamp(),
              },
            },
            rounds: {
              [currentRound]: {
                assignments: {
                  [participantId]: {
                    idea: participantId,
                    submitted: true,
                  },
                },
              },
            },
          },
          { merge: true },
        )
        .then(() => {
          setSubmitting(false);
          resetForm();
        })
        .catch(err => {
          console.log(err);
          dispatch(enqueueErrorSnackbar('Error submitting'));
          setSubmitting(false);
        });
    } else {
      const ideaId = rounds[currentRound].assignments[participantId].idea;
      const newText = `${ideas[ideaId].text}\n─ Round ${currentRound} ─\n${values.text}`;

      let update = {
        ...ideas[ideaId],
        text: newText,
        contributors: db.FieldValue.arrayUnion(participantId),
      };

      activityRef
        .set(
          {
            ideas: {
              [ideaId]: { ...update },
            },
            rounds: {
              [currentRound]: {
                assignments: {
                  [participantId]: {
                    submitted: true,
                  },
                },
              },
            },
          },
          { merge: true },
        )
        .then(() => {
          setSubmitting(false);
          resetForm();
        })
        .catch(err => {
          console.log(err);
          dispatch(enqueueErrorSnackbar('Error submitting'));
          setSubmitting(false);
        });
    }
  };

  const renderLabel = () => {
    if (alreadySubmittedThisRound) return '';

    if (isFirstSubmission) {
      return 'Based on the prompt above, type in your thoughts/ideas below. When done hit the submit button.';
    }

    return 'Read the thoughts/ideas shared from others in the area above. Type in your contributions in the text box below. Remember to “yes, and” what you read above. When you are done hit the submit button.';
  };

  return (
    <Formik
      initialValues={{
        text: '',
      }}
      onSubmit={handleSubmit}
      validationSchema={Yup.object().shape({
        text: Yup.string(),
      })}
      innerRef={formRef}
    >
      {({ values, submitForm, isSubmitting }) => (
        <>
          <div
            className={cx('justify-content-center', 'flex-column', 'align-items-center', {
              'd-none': !isTransitioning,
              'd-flex': isTransitioning,
            })}
          >
            <GrayBox className="m-2">
              <Typography style={{ fontSize: 18 }} className="mb-2" align="center">
                Shuffling Participants
              </Typography>
              <CircularProgress size={35} />
            </GrayBox>
          </div>
          <Form className={cx('w-100', { 'd-none': isTransitioning })} style={{ minHeight: 400 }}>
            {!alreadySubmittedThisRound && (
              <TextEditor
                name="text"
                label={renderLabel()}
                sessionId={session.id}
                toolbar={[
                  '|',
                  'alignment',
                  'fontColor',
                  'fontBackgroundColor',
                  'fontSize',
                  'imageUpload',
                  'bulletedList',
                  'numberedList',
                  'bold',
                  'underline',
                  'italic',
                  'undo',
                  'redo',
                ]}
              />
            )}
            <div className="my-4 text-center">
              {!isSubmitting && alreadySubmittedThisRound ? (
                <Typography style={{ color: theme.palette.success.main }}>
                  Waiting for next round to start
                </Typography>
              ) : (
                <CustomButton onClick={submitForm} disabled={!values.text || isSubmitting}>
                  Submit
                  {isSubmitting && <ButtonSpinner />}
                </CustomButton>
              )}
            </div>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default BrainwritingParticipantWriteForm;
