import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/functions';

/**
 * Update users email
 *
 * @param string    userId            id of the current user
 * @param string    email             the desired email they'd like to change
 */
export async function changeEmail(userId, email) {
  const functions = firebase.functions();

  return functions.httpsCallable('users-changeEmail')({
    userId,
    email,
  });
}
