import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { each, map, reduce, includes, filter, sortBy, reverse, size, every, orderBy } from 'lodash';
import firebase from 'firebase/app';
import 'firebase/storage';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// library components
import { Box, Typography } from '@material-ui/core';
// cr components
import ImagePoll from './ImagePoll';
// selectors
import { useHeatMappingSelector } from 'redux/selectors/heatMappingSelectors';

const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: theme.spacing(3),
    fontSize: '20px',
    fontWeight: '200',
  },
  sketchBox: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: ({ isSmallScreen }) => (isSmallScreen ? 'center' : 'flex-start'),
    alignSelf: ({ isSmallScreen }) => (isSmallScreen ? 'center' : 'start'),
  },
}));

/**
 * Renders all sketches box for voting
 */
const ImagesBox = ({
  handleVote,
  isVoting,
  context,
  isFinishedVote,
  superVoteDisabled,
  selectedSketchCreatedBy,
  handleChangeParticipantComment,
  handleFinishParticipantComment,
}) => {
  const isSmallScreen = useMediaQuery('(max-width: 767px)');
  const classes = useStyles({ isSmallScreen });
  const participantId = useSelector(state => state.firebase.auth.uid);
  const {
    id,
    participantData: { sketches, images },
  } = useHeatMappingSelector();
  const [sketchImageUrls, setSketchImageUrls] = useState([]);
  const [selectedDot, setSelectedDot] = useState('');
  const sortedSketchImages = sortBy(sketchImageUrls, ['participantId', 'order']);

  const sketchesImages = reduce(
    sortedSketchImages,
    (acc, sketch) => {
      acc[sketch.sketchId] = acc[sketch.sketchId]
        ? [...acc[sketch.sketchId], sketch.url]
        : [sketch.url];
      return acc;
    },
    {},
  );

  const sketchWithImagesUrl = [];

  each(sketches, sketch => {
    const newSketch = {
      id: sketch.id,
      participantId: sketch.participantId,
      images: sketchesImages[sketch.id],
      votedFor: sketch.votedFor,
      superVotePoints: sketch.superVotePoints,
    };
    sketchWithImagesUrl.push(newSketch);
  });

  const sortedSketches = sortBy(sketchWithImagesUrl, 'participantId');

  const sketchesWithSuperVote = filter(sortedSketches, sketch => sketch.superVotePoints);
  const sketchesWithoutSuperVote = filter(sortedSketches, sketch => !sketch.superVotePoints);

  const getSketchImageUrls = sketch => {
    each(sketch.images, image => {
      const storageRef = firebase
        .storage()
        .ref(`heatMapping/${id}/${sketch.participantId}/${image}`);
      storageRef
        .getDownloadURL()
        .then(url => {
          const newImage = {
            sketchId: sketch.id,
            participant: sketch.participantId,
            order: images[image].order,
            url: url,
          };
          setSketchImageUrls(oldArray => [...oldArray, newImage]);
        })
        .catch(err => console.log('Error get image url', err));
    });
  };

  useEffect(() => {
    each(sketches, sketch => {
      getSketchImageUrls(sketch);
    });
  }, []);

  // Renders all sketches for voting
  const renderSketches = (participantSketches, startFrom) =>
    map(participantSketches, (sketch, idx) => {
      const allComments = sketches[sketch.id].comments;
      const comments = {};
      each(sketches[sketch.id].votedFor, votedParticipant => {
        if (allComments?.[votedParticipant]) {
          comments[votedParticipant] = allComments[votedParticipant];
        } else {
          comments[votedParticipant] = '';
        }
      });
      const superVotePoints = sketches[sketch.id].superVotePoints;
      const pollPoints = filter(
        sketches,
        currentSketch =>
          currentSketch.id === sketch.id && includes(currentSketch.votedFor, participantId),
      );

      const heatMappingPoints = reduce(
        sketches[sketch.id].images,
        (acc, image) => {
          acc[image] = {
            id: image,
            points: reduce(images[image].points, (acc, points) => [...acc, points], []),
          };
          return acc;
        },
        {},
      );

      const isSelected =
        context === 'strawPoll'
          ? sketch.participantId === selectedSketchCreatedBy
          : every(participantSketches, s => size(sketch.votedFor) >= size(s.votedFor));

      return (
        <ImagePoll
          key={`sketch-${startFrom ? startFrom + idx : idx}`}
          createdBy={sketch.participantId}
          sketch={sketch.images}
          idx={startFrom ? startFrom + idx : idx}
          handleVote={handleVote}
          isVoting={isVoting}
          isFinishedVote={superVoteDisabled || isFinishedVote}
          context={context === 'resultsAdmin' ? 'results' : context}
          comments={context === 'strawPoll' ? {} : comments}
          selectedDot={selectedDot}
          setSelectedDot={setSelectedDot}
          participantComment={comments[participantId]}
          superVotePoints={context === 'strawPoll' ? size(pollPoints) : superVotePoints}
          heatMappingPoints={heatMappingPoints}
          isSelected={isSelected}
          handleChangeParticipantComment={handleChangeParticipantComment}
          handleFinishParticipantComment={handleFinishParticipantComment}
        />
      );
    });

  return (
    <>
      {context === 'results' ? (
        <Box className={isSmallScreen && 'w-100'}>
          <Box className={classes.sketchBox}>
            {renderSketches(reverse(sortBy(sketchesWithSuperVote, 'superVotePoints')))}
          </Box>
          <Box className={classes.sketchBox}>
            {renderSketches(sketchesWithoutSuperVote, size(sketchesWithSuperVote))}
          </Box>
        </Box>
      ) : (
        <Box className={classes.sketchBox}>{renderSketches(sortedSketches)}</Box>
      )}
    </>
  );
};

ImagesBox.propTypes = {
  handleVote: PropTypes.func, // function for voting
  isVoting: PropTypes.bool, // participant select sketch for voting
  context: PropTypes.oneOf([
    'strawPoll',
    'strawPollResults',
    'strawPollResultsAdmin',
    'superVote',
    'results',
    'resultsAdmin',
  ]), // state phase
  isFinishedVote: PropTypes.bool, // paricipant finished voting
  selectedSketchCreatedBy: PropTypes.string, // participant created а voted sketch
  handleChangeParticipantComment: PropTypes.func, // function for changing participant comment
  handleFinishParticipantComment: PropTypes.func, // function for saving participant comment
};

export default ImagesBox;
