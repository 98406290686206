/**
 * Formats a number into Currency
 * @param {number|string} price
 * @returns
 */
export const formatPrice = price => {
  //If the type of Price is a string, convert to Number
  const number = typeof price === 'string' ? Number(price) : price;

  return `${new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'usd',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(number)}`;
};
