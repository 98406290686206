import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/functions';
import { store } from '../index.js';
import { showAppOverlaySpinner } from 'redux/actions/facilitatorSessionActions';
import { showAppOverlaySpinner as showParticipantAppOverlaySpinner } from 'redux/actions/participantSessionActions';
import { localStorageSetObject, localStorageGetObject } from './localStorageUtils';
import * as Sentry from '@sentry/react';
import { enqueueErrorSnackbar } from 'redux/actions/notifierActions.js';

export const impersonateUser = {
  /**
   * Gets a token for a facilitator to impersonate a particiant as well as a token to return
   * them to their facilitator account, stores them in local storage, logs in as the participant,
   * and redirects the facilitator to the participant view.
   *
   * @param {Object} session - session object from Redux/Firestore
   * @param {string} participantId - id of the  participant to impersonate
   */
  impersonate: async (session, participantId) => {
    const functions = firebase.functions();
    const redirectURL = `${window.location.origin}/session/${session.id}/${session.activeRoute}`;

    try {
      store.dispatch(showAppOverlaySpinner(true));
      const response = await functions.httpsCallable('users-impersonate')({
        participantId,
        meetingId: session.id,
      });

      if (response.data) {
        const tokens = response.data;

        localStorageSetObject('impersonate', {
          returnToken: tokens.returnToken,
          returnURL: window.location.href,
        });

        await firebase.auth().signInWithCustomToken(tokens.impersonateToken);
        window.location.replace(`${redirectURL}`);
      }
    } catch (error) {
      localStorage.removeItem('impersonate');
      store.dispatch(showAppOverlaySpinner(false));
      Sentry.captureException(error);
      console.error(error);
      store.dispatch(enqueueErrorSnackbar('Error trying to impersonate user'));
    }
  },
  /**
   * Returns a facilitator that is impersonating a participant user to their
   * facilitator account at the correct meeting
   */
  returnToFacilitator: async () => {
    store.dispatch(showParticipantAppOverlaySpinner(true));
    const impersonate = localStorageGetObject('impersonate');

    try {
      if (impersonate) {
        await firebase.auth().signInWithCustomToken(impersonate.returnToken);
        localStorage.removeItem('impersonate');
        window.location.replace(impersonate.returnURL);
      } else {
        throw new Error('Error returning to facilitator (tokens not found)');
      }
    } catch (error) {
      store.dispatch(showParticipantAppOverlaySpinner(false));
      console.error(error);
      Sentry.captureException(error);
      store.dispatch(enqueueErrorSnackbar('Error returning to facilitator'));
    }
  },
};
