import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { isLoaded, isEmpty } from 'react-redux-firebase';
// library components
import { Typography, Box } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';
// cr components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import PageContent from 'components/PageContent/PageContent';
import PageContentHeader from 'components/PageContent/PageContentHeader';
import PageContentBody from 'components/PageContent/PageContentBody';
import CrLogo from 'components/CrLogo';
import ParticipantMeetingProgress from 'components/ParticipantMeetingProgress';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
  },
  iconBox: {
    width: ({ smallScreen }) => (smallScreen ? 34 : 100),
    height: 34,
    display: 'flex',
    alignItems: 'center',
    marginTop: ({ smallScreen }) => (smallScreen ? 6 : 0),
  },
}));

/**
 * Generic view template for display meeting activities to a participant
 */
const ActivityTemplate = ({ type, name, children, contentCenter, titleOverride }) => {
  const smallScreen = useMediaQuery('(max-width: 767px)');
  const classes = useStyles({ smallScreen });
  const session = useSelector(state => state.firestore.data.session);
  const title = `${session.topic}: ${titleOverride ? titleOverride : name ? name : type}`;
  if (!isLoaded(session) || isEmpty(session)) {
    return null;
  }

  return (
    <GridContainer direction="row" justify="center" alignItems="center">
      <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
        <PageContent>
          <PageContentHeader className={classes.container} dividerStyles={{ marginBottom: 24 }}>
            <Box className={classes.iconBox}>
              <CrLogo width={100} withLink session={session} onlyLogo={smallScreen} />
            </Box>
            <Typography
              variant="h5"
              style={{
                fontWeight: 'bold',
                textTransform: 'uppercase',
                position: 'absolute',
                left: '50%',
                transform: 'translate(-50%,0)',
              }}
              className="page-content-title"
            >
              {title}
            </Typography>
          </PageContentHeader>
          <ParticipantMeetingProgress session={session} />
          <PageContentBody contentCenter={contentCenter}>{children}</PageContentBody>
        </PageContent>
      </GridItem>
    </GridContainer>
  );
};

ActivityTemplate.defaultProps = {
  contentCenter: true,
};

ActivityTemplate.propTypes = {
  type: PropTypes.string.isRequired,
  // the type of activity (Brainwriting, 25:10, etc) to be displayed
  name: PropTypes.string,
  // the name of the activity as set by the facilitator
  contentCenter: PropTypes.bool,
  titleOverride: PropTypes.string,
};

export default ActivityTemplate;
