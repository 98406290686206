import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Typography, Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useConfirm } from 'material-ui-confirm';
import { find, map, size } from 'lodash';

import IconButton from '@material-ui/core/IconButton';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

// cr components
import FormDialog from 'components/Dialogs/FormDialog';

// utils
import { deleteActivity } from '../../utils/deleteActivity';
import { resetActivity } from '../../utils/resetActivity';
import { renderHtmlStringSimple } from 'utils/renderHtmlString';
import { getFacilitatorVideoInstructions } from 'utils/getActivityConfig';

import { ACTIVITIES } from 'constants/activities';

const useStyles = makeStyles(theme => ({
  buttonRoot: {
    marginBottom: theme.spacing(1),
  },
  actionsContainer: {
    alignSelf: 'start',
    position: 'relative',
    top: `-${theme.spacing(1) / 2}px`,
    right: `-${theme.spacing(1) / 2}px`,
  },
  videoBox: {
    position: 'relative',
    width: '100%',
    height: 0,
    paddingBottom: '62.5%',
    marginTop: theme.spacing(3),
  },
  video: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
}));

/**
 * Renders the actions menu at the top right of the activity view for Facilitators
 */
export default function ActivityMenu({ activityId, additionalMenuItems, isLegacyActivity }) {
  const [open, setOpen] = useState(false);
  const [instructionsDialogOpen, setInstructionsDialogOpen] = useState(false);
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const anchorRef = useRef(null);
  const session = useSelector(state => state.firestore.data.session);
  const sessionActivity = find(session.activities, { id: activityId });
  const activityHasInstructions =
    ACTIVITIES[sessionActivity?.activity]?.facilitatorInstructions?.length > 0;

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const [videoInstruction, setVideoInstruction] = useState(
    ACTIVITIES[sessionActivity?.activity]?.facilitatorInstructionsVideo,
  );

  const activityHasInstructionsVideo = !!size(videoInstruction);

  /**
   * if the activity config doesn't contain video instructions,
   * then the video is loaded from the firebase storage. path - 'facilitatorInctructions/${activity}'
   */
  const getVideoInstructions = useCallback(async () => {
    const result = await getFacilitatorVideoInstructions(sessionActivity?.activity);
    if (size(result)) {
      setVideoInstruction(result);
    }
  }, [sessionActivity?.activity]);

  useEffect(() => {
    if (!activityHasInstructionsVideo) {
      getVideoInstructions();
    }
  }, [getVideoInstructions, activityHasInstructionsVideo]);

  /**
   * this is still here to support legacy activities. once they are all refactored,
   * all of the activity menu items will be passed in from AdminActivityTemplate
   */
  const onDeleteActivity = () => {
    confirm({ description: 'This action is permanent!' }).then(() => {
      deleteActivity(session.id, sessionActivity.activity, activityId, history, enqueueSnackbar);
    });
  };

  /**
   * this is still here to support legacy activities. once they are all refactored,
   * all of the activity menu items will be passed in from AdminActivityTemplate
   */
  const onResetActivity = () => {
    confirm({
      description:
        "You are about to remove all participant data from this activity and reset it to it's default state.  Are you sure?",
    }).then(() => {
      resetActivity(sessionActivity.activity, activityId, true);
    });
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const toggleInstructionsDialog = () => {
    setInstructionsDialogOpen(!instructionsDialogOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleListKeyDown = event => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  const renderAdditionalItems = () => {
    if (additionalMenuItems.length) {
      return additionalMenuItems.map((item, idx) => <div key={`item_${idx}`}>{item}</div>);
    }
  };

  const renderLegacyActivityMenuItems = () => {
    const items = [
      { function: onDeleteActivity, name: 'Delete' },
      { function: onResetActivity, name: 'Reset' },
    ];

    return map(items, (item, idx) => (
      <MenuItem key={`legacy-menu-item-${idx}`} onClick={item.function}>
        {item.name}
      </MenuItem>
    ));
  };

  return (
    <div className={classes.actionsContainer}>
      <IconButton
        size="small"
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        className={classes.buttonRoot}
      >
        <MoreHorizIcon />
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        placement="bottom-end"
        style={{ zIndex: 1 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper elevation={5} style={{ minWidth: 200 }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  // autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                  onClick={handleClose}
                >
                  {renderAdditionalItems()}

                  {isLegacyActivity && renderLegacyActivityMenuItems()}

                  {activityHasInstructions && (
                    <MenuItem onClick={toggleInstructionsDialog}>Instructions</MenuItem>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      {activityHasInstructions && (
        <FormDialog
          isOpen={instructionsDialogOpen}
          handleClose={toggleInstructionsDialog}
          isTitleHasCloseIcon={true}
          title={`${ACTIVITIES[sessionActivity.activity].niceName} Instructions`}
        >
          <Box className="mb-3">
            <Typography
              dangerouslySetInnerHTML={renderHtmlStringSimple(
                ACTIVITIES[sessionActivity.activity].facilitatorInstructions,
              )}
            />
            {activityHasInstructionsVideo && (
              <Box className={classes.videoBox}>
                <iframe
                  title="facilitator-instructions"
                  className={classes.video}
                  src={videoInstruction}
                  frameBorder="0"
                  webkitallowfullscreen="true"
                  mozallowfullscreen="true"
                  allowFullScreen
                />
              </Box>
            )}
          </Box>
        </FormDialog>
      )}
    </div>
  );
}

ActivityMenu.defaultProps = {
  additionalMenuItems: [],
};

ActivityMenu.propTypes = {
  activityId: PropTypes.string.isRequired,
  additionalMenuItems: PropTypes.arrayOf(PropTypes.node), // should be MenuItem components
  isLegacyActivity: PropTypes.bool,
};
