import { createSelector } from 'reselect';
import { map, includes, filter, find, each, size } from 'lodash';
import { useShallowEqualSelector } from '../utils';

const lightningDemoSelector = state => state.firestore.data.lightningDemo || {};

export const useLightningDemoSelector = () => {
  return useShallowEqualSelector(lightningDemoSelector);
};

/**
 * Get data on which participants have completed upload,
 * formatted for the ActivityParticipantDetail component
 *
 * @returns {Array.<{id: String, completed: Boolean}>}
 */
export const getParticipantsCompletedUpload = () =>
  createSelector([useLightningDemoSelector], ({ participantData: { demos, participants } }) => {
    return map(participants, participantId => ({
      id: participantId,
      completed: !!find(
        demos,
        demo =>
          demo.participantId === participantId && demo.imageId && demo.source && demo.bigIdeas,
      ),
      completedCount: size(
        find(
          demos,
          demo =>
            demo.participantId === participantId && demo.imageId && demo.source && demo.bigIdeas,
        ),
      ),
    }));
  });

/**
 * Get demos for participant
 *
 * @param {String} participantId
 */
export const getParticipantDemos = participantId =>
  createSelector([useLightningDemoSelector], ({ participantData: { demos } }) => {
    return filter(demos, demo => demo.participantId === participantId);
  });

/**
 * Get data on which participants have completed review,
 * formatted for the ActivityParticipantDetail component
 *
 * @returns {Array.<{id: String, completed: Boolean}>}
 */
export const getParticipantsCompletedReview = () =>
  createSelector(
    [useLightningDemoSelector],
    ({ participantData: { participants, demos }, state: { participantsCompletedReview } }) => {
      const participantsUploadDemos = [];
      each(participants, participant => {
        const participantDemo = find(demos, demo => demo.participantId === participant);
        if (participantDemo) {
          participantsUploadDemos.push(participant);
        }
      });
      return map(participantsUploadDemos, participantId => ({
        id: participantId,
        completed: includes(participantsCompletedReview, participantId),
      }));
    },
  );
