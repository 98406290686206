import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isLoaded } from 'react-redux-firebase';
import { updateParticipants, clearParticipants } from 'redux/actions/participantsActions';
import { keys } from 'lodash';

/**
 * Starts and interval that checks the current participants for connectedness
 * based on their current heartbeat timestamp and updates the redux store
 *
 * ONLY USE THIS EFFECT ONCE PER SESSION VIEW (participant or facilitator)
 */
export function useParticipants() {
  const dispatch = useDispatch();
  const [initialLoad, setInitialLoad] = useState(true);
  const participants = useSelector(state => state.firestore.data.participants) || {};
  const participantsLoaded = isLoaded(participants);
  const participantsCount = keys(participants).length;
  const lastParticipantsCount = useRef(0);

  useEffect(() => {
    // console.log('participantsCountRef', lastParticipantsCount.current);
    // console.log('participantsCount', participantsCount);
    const countChanged = participantsCount !== lastParticipantsCount.current;

    if (countChanged) {
      lastParticipantsCount.current = participantsCount;
    }

    if (participantsLoaded && (initialLoad || countChanged)) {
      // console.log('initial load', initialLoad);
      // console.log('count changed', countChanged);
      dispatch(updateParticipants());
      setInitialLoad(false);
    }
  }, [participantsLoaded, initialLoad, participantsCount]);

  useEffect(() => {
    let interval;

    if (participantsLoaded && !initialLoad) {
      // console.log('set Interval', participantsLoaded);
      interval = setInterval(() => {
        dispatch(updateParticipants());
      }, 10000);
    }

    return () => {
      clearInterval(interval);
      dispatch(clearParticipants());
    };
  }, [participantsLoaded, initialLoad]);
}
