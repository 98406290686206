import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import cx from 'classnames';
import { concat, findIndex, size, split, startCase } from 'lodash';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useFirestore } from 'react-redux-firebase';
import mixpanel from 'mixpanel-browser';
//library components
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { makeStyles, IconButton, Box, Tooltip, useMediaQuery } from '@material-ui/core';
// actions
import { setSelectedFacilitatorRoute } from 'redux/actions/facilitatorSessionActions';

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    position: 'absolute',
    bottom: 3,
    left: -15,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 115,
    height: 30,
  },
  buttonContainerMobile: {
    bottom: 35,
  },
  button: {
    color: theme.palette.primary.main,
  },
  disabledButton: {
    color: '#ADADAD',
  },
  icon: {
    fontSize: 22,
  },
}));

/**
 * Renders Previous/Next buttons to page through activities
 */
const ActivitiesControlButtons = ({ activities, sessionsType }) => {
  const smallScreen = useMediaQuery('(max-width: 760px)');
  const classes = useStyles();
  const route = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const db = useFirestore();

  const sessionId = route.params.sessionId;

  // get active facilitator route
  const { selectedFacilitatorRoute } = useSelector(state => state.facilitatorSession);
  // default routes: Welcome, Slides, Feedback
  const defaultRoutes = [
    {
      route: 'welcome',
    },
    {
      route: 'slides',
    },
  ];
  const feedbackRoute = [
    {
      route: 'feedback',
    },
  ];
  // get all routes
  const allRoutes = concat(defaultRoutes, activities, feedbackRoute);
  // index of active facilitator route
  const activeRouteIndex = findIndex(
    allRoutes,
    activity => activity.route === selectedFacilitatorRoute,
  );

  // set active on the participant side
  const setParticipantActivity = newRoute => {
    db.collection('sessions')
      .doc(`${sessionId}`)
      .update({
        activeRoute: newRoute,
      })
      .then(() => {
        if (process.env.REACT_APP_HOSTING_ENV === 'prod') {
          let activityName = startCase(split(newRoute, '/')[0]);

          mixpanel.track('Set Activity', { activity: activityName });
        }
      });
  };

  // set active route and change session history on the facilitator side
  const setActiveRoute = index => {
    const newRoute = allRoutes[index].route;
    dispatch(setSelectedFacilitatorRoute(newRoute, sessionId));
    setParticipantActivity(newRoute);
    const redirectRoute = `/admin/${sessionsType}/${sessionId}/${newRoute}`;
    history.push(redirectRoute);
  };

  // on next activity
  const handleNextActivity = () => {
    setActiveRoute(activeRouteIndex + 1);
  };

  // on previous activity
  const handlePrevActivity = () => {
    setActiveRoute(activeRouteIndex - 1);
  };

  return (
    <Box className={cx(classes.buttonContainer, smallScreen && classes.buttonContainerMobile)}>
      <Tooltip title="previous activity" arrow enterDelay={300}>
        <Box>
          <IconButton
            classes={{
              root: classes.button,
              disabled: classes.disabledButton,
            }}
            size="small"
            onClick={handlePrevActivity}
            disabled={activeRouteIndex === -1 || !activeRouteIndex}
          >
            <ArrowBackIosIcon className={classes.icon} />
          </IconButton>
        </Box>
      </Tooltip>
      <Tooltip title="next activity" arrow enterDelay={300}>
        <Box>
          <IconButton
            classes={{
              root: classes.button,
              disabled: classes.disabledButton,
            }}
            size="small"
            onClick={handleNextActivity}
            disabled={activeRouteIndex === -1 || activeRouteIndex === size(allRoutes) - 1}
          >
            <ArrowForwardIosIcon className={classes.icon} />
          </IconButton>
        </Box>
      </Tooltip>
    </Box>
  );
};

ActivitiesControlButtons.propTypes = {
  activities: PropTypes.arrayOf(
    PropTypes.shape({
      // session activities, not includes Welcome, Slides, Feedback
      routes: PropTypes.string,
    }),
  ),
  sessionsType: PropTypes.oneOf(['sessions', 'shared-sessions', 'past-sessions']),
};

export default ActivitiesControlButtons;
