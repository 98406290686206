import { useShallowEqualSelector } from '../utils';
import { createSelector } from 'reselect';
import {
  orderBy,
  values,
  keys,
  difference,
  filter,
  map,
  compact,
  size,
  chain,
  flatten,
} from 'lodash';
import {
  VARIABLE_QUESTION_TYPES_TITLES,
  VARIABLE_QUESTION_TYPES,
} from 'components/CustomFormComponents/VariableQuestions/QuestionTypes/constants';
import { SENTIMENT_CHOICES } from 'components/CustomFormComponents/Sentiment';
import { participantsSelector } from './participantsSelectors';

const assessmentSelector = state => state.firestore.data.assessment || {};

export const useAssessmentSelector = () => {
  return useShallowEqualSelector(assessmentSelector);
};

export const getQuestionTypes = () =>
  createSelector([assessmentSelector], ({ config: { questions } }) => {
    const questionTypes = chain(questions)
      .map(q => q.data.questionType)
      // .uniq()
      .value();

    return questionTypes;
  });

export const getSortedQuestions = () =>
  createSelector([assessmentSelector], ({ config: { questions } }) => {
    const theQuestions = chain(questions)
      .values()
      .sortBy('order')
      .map(q => ({
        ...q,
        data: {
          ...q.data,
          niceName: VARIABLE_QUESTION_TYPES_TITLES[q.data.questionType],
        },
      }))
      .value();

    return theQuestions;
  });

export const getFacilitatorPromptSubText = () =>
  createSelector(
    [assessmentSelector, getSortedQuestions()],
    (
      { config: { reviewResults }, state: { currentQuestionIdx, activeView } },
      orderedQuestions,
    ) => {
      let displayText = '';

      if (reviewResults && !activeView === 'start') {
        const currentQuestion = orderedQuestions[currentQuestionIdx];
        displayText = `Question #${currentQuestion.order} (${currentQuestion.data.niceName}): ${currentQuestion.data.question}`;
      } else {
        displayText = `<span class="d-block text-center pl-2">Questions: </span>`;
        displayText += `<ol class="pl-4">`;
        orderedQuestions.forEach((q, idx) => {
          displayText += `<li class="text-left">${q.data.question}</li>`;
        });
        displayText += `</ol>`;
      }

      return displayText;
    },
  );

/**
 * Find out if the participant has answered all of the
 *
 * @returns {array}
 */
export const getParticipantAnsweredAllQuestions = participantId =>
  createSelector(
    [assessmentSelector],
    ({ participantData: { answers }, config: { questions } }) => {
      // return _participantAnsweredAllQuestions(answers[participantId], questions);

      const participantAnswers = answers[participantId];

      if (!participantAnswers) return false;

      const questionIds = keys(questions);
      const questionsIdsAnswered = keys(participantAnswers);
      const allTouched = difference(questionIds, questionsIdsAnswered).length === 0;

      if (!allTouched) return false;

      const theParticipantAnswers = values(participantAnswers);

      return filter(theParticipantAnswers, answer => answer === '').length === 0;
    },
  );

/**
 * Get an array of objects that have the question and an array of answers
 *
 * @returns {array}
 */
export const getQuestionsWithAnswers = () =>
  createSelector(
    [assessmentSelector],
    ({ participantData: { answers }, config: { questions } }) => {
      return map(orderBy(questions, 'order', 'asc'), question => ({
        ...question,
        answers: compact(map(answers, participantAnswers => participantAnswers[question.id])),
      }));
    },
  );

/**
 * Get a question by id with the answers
 *
 * @returns {object}
 */
export const getQuestion = questionId =>
  createSelector(
    [assessmentSelector, participantsSelector],
    ({ participantData: { answers }, config: { questions } }, participants) => {
      let answerCounts = {};
      const question = questions[questionId];

      const answersArr = chain(answers)
        .map(participantAnswers => participantAnswers[questionId])
        .flatten()
        .compact()
        .value();

      const answersWithParticipantsArr = map(answers, (participantAnswers, participantId) => {
        return {
          answer: participantAnswers[questionId],
          participantName: participants[participantId].name,
          participantId: participantId,
        };
      });

      answersArr.forEach(answer => {
        if (!(answer in answerCounts)) {
          answerCounts[answer] = 0;
        }
        answerCounts[answer]++;
      });

      answerCounts = map(answerCounts, (count, answer) => {
        const participantNames = answersWithParticipantsArr
          .filter(participantAnswer => {
            if (Array.isArray(participantAnswer.answer)) {
              return participantAnswer.answer.includes(answer);
            }
            return participantAnswer.answer === answer;
          })
          .map(participantAnswer => participantAnswer.participantName);

        return {
          participantNames,
          answer,
          count,
          percentage: Math.round((count / answersArr.length) * 100),
          // if choices are an object...
          answerLabel:
            typeof question.data.choices === 'object' &&
            !Array.isArray(question.data.choices) &&
            question.data.choices[answer],
        };
      });

      // if there were pre-defined choices that nobody picked, add them to the list/collection
      if (question.data.choices) {
        if (typeof question.data.choices === 'object') {
          // choices is array
          if (Array.isArray(question.data.choices)) {
            question.data.choices.forEach(choice => {
              if (!answersArr.includes(choice)) {
                answerCounts.push({
                  answer: choice,
                  count: 0,
                  percentage: 0,
                  participantNames: [],
                });
              }
            });
          } else {
            /**
             * choices is plain object where the keys are choice values
             * and the values are choice labels
             */
            for (const val in question.data.choices) {
              if (!answersArr.includes(val)) {
                answerCounts.push({
                  answerLabel: question.data.choices[val],
                  answer: val,
                  count: 0,
                  percentage: 0,
                  participantNames: [],
                });
              }
            }
          }
        }
      } else if (question.data.questionType === VARIABLE_QUESTION_TYPES.SENTIMENT) {
        values(SENTIMENT_CHOICES).forEach(choice => {
          if (!answersArr.includes(choice)) {
            answerCounts.push({
              answer: choice,
              count: 0,
              percentage: 0,
              participantNames: [],
            });
          }
        });
      }

      answerCounts = orderBy(answerCounts, ['count', 'answer'], ['desc', 'asc']);

      return {
        ...question,
        answers: answersArr,
        answerCounts,
      };
    },
  );

/**
 * Get all answers for a participant
 *
 * @param {String} participantId
 */
export const getParticipantAnswers = participantId =>
  createSelector([assessmentSelector], ({ participantData: { answers } }) => {
    return answers[participantId] || {};
  });

/**
 * Get data on which participants have submitted their answers,
 * formatted for the ActivityParticipantDetail component
 *
 * @returns {Array.<{id: String, completed: Boolean}>}
 */
export const getParticipantsHaveAnswered = (reviewResults, questionId) =>
  createSelector([assessmentSelector], ({ participantData: { answers, participants } }) => {
    if (reviewResults && questionId) {
      return map(participants, pId => {
        const answer = answers[pId]?.[questionId];
        const hasAnswered = Boolean(answer || answer === 0 || answer === '0');

        return {
          id: pId,
          completed: hasAnswered,
        };
      });
    }

    return map(participants, pId => {
      const participantAnswerCount = size(
        filter(answers[pId], answer => Boolean(answer || answer === 0 || answer === '0')),
      );

      return {
        id: pId,
        completed: participantAnswerCount > 0,
        completedCount: participantAnswerCount,
      };
    });
  });
