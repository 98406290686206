import React from 'react';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
// cr components
import ActivityTemplate from 'components/ActivityTemplate/ActivityTemplate';
import NoteAndVoteParticipantWrite from 'components/NoteAndVote/Participant/NoteAndVoteParticipantWrite';
import NoteAndVoteParticipantShare from 'components/NoteAndVote/Participant/NoteAndVoteParticipantShare';
import NoteAndVoteParticipantReview from 'components/NoteAndVote/Participant/NoteAndVoteParticipantReview';
import NoteAndVoteParticipantVote from 'components/NoteAndVote/Participant/NoteAndVoteParticipantVote';
import NoteAndVoteParticipantResults from 'components/NoteAndVote/Participant/NoteAndVoteParticipantResults';
import ParticipantActivityContent from 'components/ParticipantActivityContent';
// selectors
import { useNoteAndVoteSelector } from 'redux/selectors/noteAndVoteSelectors';
// constants
import { NOTE_AND_VOTE_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';

const views = {
  write: NoteAndVoteParticipantWrite,
  share: NoteAndVoteParticipantShare,
  review: NoteAndVoteParticipantReview,
  vote: NoteAndVoteParticipantVote,
  results: NoteAndVoteParticipantResults,
};

/**
 * Entry point for Note & Vote on the Participant side.
 * Loads the Firestore object for the activity, ensures
 * the data is loaded before rendering the game, and determines
 * which view to show
 */
const NoteAndVoteParticipant = ({
  match: {
    params: { activityId },
  },
}) => {
  const noteAndVote = useNoteAndVoteSelector();

  // hooks up a firestore listener to Redux
  useFirestoreConnect(() => [
    {
      collection: 'noteAndVote',
      doc: `${activityId}`,
      storeAs: 'noteAndVote',
    },
  ]);

  // ensure all of the data we need is loaded
  if (!isLoaded(noteAndVote) || activityId !== noteAndVote?.id) {
    return null;
  }

  const renderViewLabel = activeView => {
    switch (activeView) {
      case 'share':
        return `Share ${noteAndVote.config.shareLimit} ideas`;
      case 'results':
        return `Voting results from ${noteAndVote.participantData.participants?.length} participants`;
      default:
        return CONFIG.participantViewLabels[activeView];
    }
  };

  const Component = views[noteAndVote.state.activeView];

  return (
    <ActivityTemplate type="Note & Vote" name={noteAndVote.name}>
      <ParticipantActivityContent
        activityData={noteAndVote}
        viewLabel={renderViewLabel(noteAndVote.state.activeView)}
        activityConfig={CONFIG}
      >
        <Component />
      </ParticipantActivityContent>
    </ActivityTemplate>
  );
};

export default NoteAndVoteParticipant;
