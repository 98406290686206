import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import cx from 'classnames';

import IconButton from '@material-ui/core/IconButton';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

const useStyles = makeStyles(theme => ({
  buttonRoot: {
    marginBottom: theme.spacing(1),
  },
  actionsContainer: {
    alignSelf: 'start',
    position: 'relative',
    top: `-${theme.spacing(1) / 2}px`,
    right: `-${theme.spacing(1) / 2}px`,
  },
}));

/**
 * Renders an elipsis icon button that opens a menu
 */
export default function ElipsisMenu({ menuItems, className }) {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const anchorRef = useRef(null);

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleListKeyDown = event => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  const renderItems = () => {
    if (menuItems.length) {
      return menuItems.map((item, idx) => <div key={`item_${idx}`}>{item}</div>);
    }
  };

  return (
    <div className={cx(classes.actionsContainer, className)}>
      <IconButton
        size="small"
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        className={classes.buttonRoot}
      >
        <MoreHorizIcon />
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom-end"
        style={{ zIndex: 1 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper elevation={5} style={{ minWidth: 115 }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  // autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                  onClick={handleClose}
                >
                  {renderItems()}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}

ElipsisMenu.defaultProps = {
  menuItems: [],
};

ElipsisMenu.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.node), // should be MenuItem components
};
