import React from 'react';
// cr components
import NoteAndCategorizeReview from './NoteAndCategorizeReview';

/**
 * Note & Categorize view for the 'review' phase on the Participant side.
 */
const NoteAndCategorizeParticipantReview = () => {
  return <NoteAndCategorizeReview />;
};

export default NoteAndCategorizeParticipantReview;
