import React from 'react';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { keys, filter } from 'lodash';
// cr components
import ActivityTemplate from 'components/ActivityTemplate/ActivityTemplate';
import ParticipantActivityContent from 'components/ParticipantActivityContent';
import HowMightWeParticipantCreate from 'components/HowMightWe/Participant/HowMightWeParticipantCreate';
import HowMightWeParticipantShare from 'components/HowMightWe/Participant/HowMightWeParticipantShare';
import HowMightWeParticipantGroup from 'components/HowMightWe/Participant/HowMightWeParticipantGroup';
import HowMightWeParticipantVote from 'components/HowMightWe/Participant/HowMightWeParticipantVote';
import HowMightWeParticipantResults from 'components/HowMightWe/Participant/HowMightWeParticipantResults';
// selectors
import { useHowMightWeSelector } from 'redux/selectors/howMightWeSelectors';
// config
import { HOW_MIGHT_WE_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';

//add new view for all action
const views = {
  create: HowMightWeParticipantCreate,
  share: HowMightWeParticipantShare,
  group: HowMightWeParticipantGroup,
  vote: HowMightWeParticipantVote,
  results: HowMightWeParticipantResults,
};

const HowMightWeParticipant = ({
  match: {
    params: { activityId },
  },
}) => {
  const howMightWe = useHowMightWeSelector();

  // hooks up a firestore listener to Redux
  useFirestoreConnect(() => [
    {
      collection: 'howMightWe',
      doc: `${activityId}`,
      storeAs: 'howMightWe',
    },
  ]);

  // ensure all of the data we need is loaded
  if (!isLoaded(howMightWe) || activityId !== howMightWe?.id) {
    return null;
  }

  const renderViewLabel = activeView => {
    switch (activeView) {
      case 'create':
        return `Please create at least ${howMightWe.config.share} How Might We (HMW) cards`;
      case 'share':
        return `Select up to ${howMightWe.config.share} HMWs to share`;
      case 'group':
        return CONFIG.participantViewLabels.group;
      case 'vote':
        const countIdeas = keys(
          filter(howMightWe.participantData.ideas, idea => !idea.isDisabled),
        ).length;
        const totalCountVotes =
          countIdeas < howMightWe.config.votes ? countIdeas : howMightWe.config.votes;
        return `Select up to ${totalCountVotes} HMWs`;
      case 'results':
        return CONFIG.participantViewLabels.results;
      default:
        return '';
    }
  };

  const Component = views[howMightWe.state.activeView];

  return (
    <ActivityTemplate type="HMW" name={howMightWe.name}>
      <ParticipantActivityContent
        activityData={howMightWe}
        viewLabel={renderViewLabel(howMightWe.state.activeView)}
        activityConfig={CONFIG}
      >
        <Component />
      </ParticipantActivityContent>
    </ActivityTemplate>
  );
};

export default HowMightWeParticipant;
