import React from 'react';
import PropTypes from 'prop-types';
import { useFirebase } from 'react-redux-firebase';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
// library components
import { Formik, Form } from 'formik';
import { Button, Typography } from '@material-ui/core';
// cr components
import ButtonSpinner from 'components/ButtonSpinner/ButtonSpinner';
// actions
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from 'redux/actions/notifierActions';
import CustomSelect from 'components/CustomFormComponents/CustomSelect';
import * as Sentry from '@sentry/react';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

/**
 * Form for resending project attendee messages
 */
const SendProjectAttendeeMessageForm = ({
  closeDialog,
  projectId,
  attendeeOptions,
  messageOptions,
}) => {
  const dispatch = useDispatch();
  const authUser = useSelector(state => state.firebase.auth);
  const firebase = useFirebase();

  const formSchema = Yup.object().shape({
    messageId: Yup.string().required('Required'),
    attendeeId: Yup.string().required('Required'),
  });

  return (
    <Formik
      initialValues={{
        displayName: authUser.displayName,
      }}
      validationSchema={formSchema}
      onSubmit={async (values, { resetForm }) => {
        return firebase
          .functions()
          .httpsCallable('projects-onCall_sendAttendeeMessage')({
            projectId,
            attendeeId: values.attendeeId,
            messageId: values.messageId,
          })
          .then(res => {
            closeDialog();
            resetForm();
            dispatch(
              enqueueSuccessSnackbar(
                'Sending message. If something goes wrong an error will be sent to Slack.',
              ),
            );
          })
          .catch(err => {
            dispatch(enqueueErrorSnackbar('Error scheduling message send'));
            Sentry.captureException(err);
            console.error(err);
          });
      }}
    >
      {({ isSubmitting, submitForm, resetForm }) => (
        <Form style={{ paddingBottom: 20 }}>
          <GridContainer>
            <GridItem>
              <Typography color="textSecondary" className="mb-4">
                Attendee messages are automatically sent every hour according the project data and
                messaging config. Generally, you'll only use this form to re-send messages that
                should have already been recieved. If you don't see any attendees or message options
                below, try re-syncing them to the project via the drop down menu at the top right of
                the project details. If you still don't see any message options after re-syncing the
                messaging config it's most likely that there are no messages in the config for this
                project's Service/Methodology combo. When new attendees are added after they would
                have recieved scheduled messages, they will recieve all of the messages they missed
                at the top of the next hour. You should not have to manually send them. If a message
                is sent via this form before it would normally be sent via the message scheduler, it
                will not be sent again via the scheduler.
              </Typography>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12}>
              <CustomSelect
                name="attendeeId"
                label="Attendee"
                selectOptions={attendeeOptions}
                className="mb-4"
                required
                placeholder="Select an Attendee"
                disabled={!attendeeOptions.length}
              />
            </GridItem>
            <GridItem xs={12}>
              <CustomSelect
                name="messageId"
                label="Message"
                selectOptions={messageOptions}
                className="mb-4"
                required
                placeholder="Select a message"
                disabled={!messageOptions.length}
              />
            </GridItem>
          </GridContainer>
          <div className="form-dialog-buttons">
            <Button
              variant="outlined"
              color="primary"
              disabled={isSubmitting}
              onClick={() => {
                closeDialog();
                resetForm();
              }}
            >
              CANCEL
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={submitForm}
            >
              SEND
              {isSubmitting && <ButtonSpinner />}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

SendProjectAttendeeMessageForm.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  attendeeOptions: PropTypes.array.isRequired,
  messageOptions: PropTypes.array.isRequired,
};

export default SendProjectAttendeeMessageForm;
