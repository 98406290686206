import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import materialIcons from './materialIcons.json';
import { map, chunk, includes, delay, debounce, filter } from 'lodash';
// library components
import { Dialog, Box, Input } from '@material-ui/core';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
// cr components
import DialogHeader from '../DialogHeader';
import DialogAction from '../DialogAction';
import IconCard from './IconCard';
import Slider from './Slider';
import ButtonSpinner from 'components/ButtonSpinner/ButtonSpinner';

const useStyles = makeStyles(theme => ({
  paperWidthSm: {
    maxWidth: 970,
  },
  dialogTitle: {
    maxWidth: 970,
    width: 970,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttonSpinnerBox: {
    position: 'absolute',
    top: '45%',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  buttonSpinner: {
    color: theme.palette.primary.main,
  },
  iconsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  inputStyles: {
    background: 'rgba(0, 0, 0, 0.04)',
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    marginTop: '0 !important',
    paddingLeft: 10,
    paddingRight: 5,
  },
  input: {
    maxWidth: 315,
    height: 40,
  },
  slider: {
    height: 515,
  },
}));

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(5),
    paddingTop: theme.spacing(2),
  },
}))(MuiDialogContent);

const UploadIconDialog = ({ handleClose, isOpen, handleUploadIcon }) => {
  const classes = useStyles();

  const [selectedIcon, setSelectedIcon] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [isSearch, setIsSearch] = useState(false);
  const [timer, setTimer] = useState();
  const [searchIcons, setSearchIcons] = useState(materialIcons.icons);
  const [isLoading, setIsLoading] = useState(false);

  // close dialog
  const handleCloseUploadIcon = () => {
    handleClose();
    setSelectedIcon('');
    setSearchValue('');
    setSearchIcons(materialIcons.icons);
  };

  // Save selected icon
  const handleConfirm = async () => {
    if (!selectedIcon) {
      return;
    }
    handleUploadIcon(selectedIcon);
    handleCloseUploadIcon();
  };

  // select icon
  const handleSelectIcon = iconName => {
    setSelectedIcon(prev => (prev === iconName ? '' : iconName));
  };

  // update search values
  const update = debounce(() => {
    setIsSearch(true);
    setIsLoading(true);
  }, 1000);

  // set search values
  const handleSearch = useCallback(() => {
    setSelectedIcon('');
    if (!searchValue) {
      setSearchIcons(materialIcons.icons);
    } else {
      setSearchIcons(filter(materialIcons.icons, icon => includes(icon, searchValue)));
    }
    setIsSearch(false);
    setIsLoading(false);
  }, [searchValue]);

  // change search value
  const handleChangeSearch = event => {
    setSearchValue(event.target.value);
    if (timer) {
      clearTimeout(timer);
    }
    const timerId = delay(update, 100);
    setTimer(timerId);
  };

  useEffect(() => {
    if (isSearch) {
      handleSearch();
    }
  }, [isSearch, handleSearch]);

  // render all icons
  const renderIcons = icons =>
    map(icons, (icon, idx) => (
      <IconCard
        key={`icon-${icon}-${idx}`}
        icon={icon}
        handleSelectIcon={handleSelectIcon}
        isSelected={selectedIcon === icon}
      />
    ));

  // render slides
  const renderSlides = () => {
    const pages = chunk(searchIcons, 28);
    return map(pages, (page, idx) => (
      <Box key={`page-${idx}`} className={classes.iconsContainer}>
        {renderIcons(page)}
      </Box>
    ));
  };

  return (
    <Box>
      <Dialog
        open={isOpen}
        classes={{
          paperWidthSm: classes.paperWidthSm,
        }}
      >
        <DialogHeader title="Edit Card Sets: Add Icon" handleClose={handleCloseUploadIcon} />
        <DialogContent classes={classes.root} dividers>
          <Input
            value={searchValue}
            type="text"
            placeholder="Search icons"
            classes={{
              root: classes.inputStyles,
            }}
            fullWidth
            multiline
            rows={1}
            id="search-input"
            className={classes.input}
            onChange={handleChangeSearch}
          />
          {isLoading ? (
            <Box className={classes.buttonSpinnerBox}>
              <ButtonSpinner className={classes.buttonSpinner} />
            </Box>
          ) : (
            <Slider slides={renderSlides()} className={classes.slider} isShowPages={false} />
          )}
          <DialogAction
            handleClose={handleCloseUploadIcon}
            handleConfirm={handleConfirm}
            confirmText="Add"
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

UploadIconDialog.propTypes = {
  handleClose: PropTypes.func.isRequired, // close search icon dialog
  isOpen: PropTypes.bool.isRequired, // open search icon dialog
  handleUploadIcon: PropTypes.func.isRequired, // upload selected icon
};

export default UploadIconDialog;
