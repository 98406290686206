import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  cloneDeep,
  map,
  pull,
  each,
  delay,
  keys,
  difference,
  includes,
  filter,
  values,
} from 'lodash';
import { useTheme, makeStyles } from '@material-ui/core/styles';
// library components
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  Button,
  Card,
  Typography,
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Tooltip,
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
// cr components
import ButtonSpinner from '../ButtonSpinner';
// redux selectors
import { getRoomMap } from '../../redux/selectors/Breakouts/rootBreakoutsSelector';
import {
  getParticipantsMap,
  getConnectedParticipants,
} from 'redux/selectors/participantsSelectors';
// redux actions
import { updateBreakout } from '../../redux/actions/breakoutsActions';
// utils
import {
  assignParticipantsToRooms,
  splitBreakouts,
  mergeBreakouts,
} from 'utils/Breakouts/roomAssignmentHelper';
import { createRoomMapFromBreakouts } from 'utils/Breakouts/breakoutsHelper';

const useDeleteIconStyle = makeStyles(theme => ({
  root: {
    color: theme.palette.grey[500],
    '&:hover': {
      color: theme.palette.error.main,
      cursor: 'pointer',
    },
  },
}));

const MoveParticipants = ({ open, closeDialog }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [nextRoomMap, setNextRoomMap] = useState({});
  const [breakoutSnapshot, setBreakoutSnapshot] = useState({});
  const theme = useTheme();
  const dispatch = useDispatch();
  const participants = useSelector(getParticipantsMap);
  const connectedParticipants = useSelector(getConnectedParticipants);
  const breakout = useSelector(state => state.firestore.data.breakouts);
  const roomMap = useSelector(getRoomMap);
  const participantsChooseRooms = breakout?.participantsChooseRooms;
  const muralEnabled = breakout?.muralEnabled;
  const participantCount = breakout?.participantRoomAssignments?.length || 0;
  const deleteIconClasses = useDeleteIconStyle();

  /**
   * If new users join the session while this breakout is active, they will get automatically
   * added to the room with the least users.  Add them to our temporary state here so we can
   * relocate them if desired.
   */
  useEffect(() => {
    const participantIds = keys(participants);
    const assignedParticipantIds = map(
      breakoutSnapshot.participantRoomAssignments,
      assignment => assignment.participantId,
    );
    const unassignedParticipants = difference(participantIds, assignedParticipantIds);

    if (unassignedParticipants.length && breakoutSnapshot.participantRoomAssignments) {
      unassignedParticipants.forEach(participantId => {
        const room = getParticipantRoom(breakout.participantRoomAssignments, participantId);

        if (!room) return;

        const update = cloneDeep(breakoutSnapshot);

        update.participantRoomAssignments.push({
          participantId: participantId,
          roomId: room.id,
        });

        setBreakoutSnapshot(update);
        setNextRoomMap(createRoomMapFromBreakouts(update));
      });
    }
  }, [participantCount]);

  const onDialogOpen = () => {
    setNextRoomMap(roomMap);
    setBreakoutSnapshot(cloneDeep(breakout));
  };

  /**
   * Get the room for a given participantId
   * @param {array} roomAssignments - array of room objects
   * @param {string} participantId
   * @returns {object} - room object
   */
  const getParticipantRoom = (roomAssignments, participantId) => {
    const roomAssignment = roomAssignments.find(
      roomAssignment => roomAssignment.participantId === participantId,
    );

    if (!roomAssignment) {
      return null;
    }

    return breakout.rooms.find(room => room.id === roomAssignment.roomId);
  };

  const canSplit = () => {
    const extraParticipants = breakoutSnapshot?.participantRoomAssignments?.length % 2;
    return (
      breakoutSnapshot?.participantRoomAssignments?.length >
        breakoutSnapshot?.rooms?.length * 2 + extraParticipants && !participantsChooseRooms
    );
  };

  const canMerge = () => breakoutSnapshot?.rooms?.length > 1 && !participantsChooseRooms;

  const onShuffle = () => {
    const participantRoomAssignments = assignParticipantsToRooms({
      participants: values(participants),
      rooms: breakoutSnapshot.rooms,
      assignments: [],
    });

    const update = {
      ...breakoutSnapshot,
      participantRoomAssignments,
    };

    setBreakoutSnapshot(update);
    setNextRoomMap(createRoomMapFromBreakouts(update));
  };

  const onSplit = () => {
    const update = {
      ...breakoutSnapshot,
      ...splitBreakouts(breakoutSnapshot, nextRoomMap),
    };

    setBreakoutSnapshot(update);
    setNextRoomMap({
      ...nextRoomMap,
      ...createRoomMapFromBreakouts(update),
    });
  };

  const onMerge = () => {
    const { rooms, participantRoomAssignments } = mergeBreakouts(breakoutSnapshot, nextRoomMap);

    const update = {
      ...breakoutSnapshot,
      rooms,
      participantRoomAssignments,
    };

    setBreakoutSnapshot(update);
    setNextRoomMap(createRoomMapFromBreakouts(update));
  };

  const onClearDisconnected = () => {
    const connectedParticipantIds = map(connectedParticipants, cp => cp.id);
    const breakoutsSnapshotCopy = cloneDeep(breakoutSnapshot);

    breakoutsSnapshotCopy.participantRoomAssignments = filter(
      breakoutsSnapshotCopy.participantRoomAssignments,
      rmAss => {
        return includes(connectedParticipantIds, rmAss.participantId);
      },
    );

    setBreakoutSnapshot(breakoutsSnapshotCopy);
    setNextRoomMap(createRoomMapFromBreakouts(breakoutsSnapshotCopy));
  };

  const onRemove = participantId => {
    const breakoutsSnapshotCopy = cloneDeep(breakoutSnapshot);

    breakoutsSnapshotCopy.participantRoomAssignments = filter(
      breakoutsSnapshotCopy.participantRoomAssignments,
      rmAss => {
        return rmAss.participantId !== participantId;
      },
    );

    setBreakoutSnapshot(breakoutsSnapshotCopy);
    setNextRoomMap(createRoomMapFromBreakouts(breakoutsSnapshotCopy));
  };

  const getItemStyle = (isDragging, draggableStyle, participant) => ({
    userSelect: 'none',
    padding: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    border: '1px solid gray',
    borderRadius: 20,
    background: isDragging ? theme.palette.primary.main : 'white',
    color: isDragging ? 'white' : participant.connected ? 'initial' : theme.palette.error.main,
    opacity: participant.connected ? '1' : '.33',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? theme.palette.grey[50] : 'white',
  });

  const onDragEnd = result => {
    const tempState = cloneDeep(nextRoomMap);
    const sourceRoom = result.source.droppableId;
    const destRoom = result.destination.droppableId;
    const destIdx = result.destination.index;
    const participantId = result.draggableId;
    const nextSourceParticipants = pull(tempState[sourceRoom].participants, participantId);

    tempState[destRoom].participants.splice(destIdx, 0, participantId);

    const nextState = {
      ...tempState,
      [sourceRoom]: {
        ...tempState[sourceRoom],
        participants: nextSourceParticipants,
      },
    };

    setNextRoomMap(nextState);
  };

  const onCommit = () => {
    setIsSubmitting(true);

    const update = roomMapToFirestore(nextRoomMap);

    dispatch(
      updateBreakout(breakout.id, update, () => {
        closeDialog();

        // delay this to keep the rooms from clearing before
        // the modal closes...
        delay(function () {
          resetState();
          setIsSubmitting(false);
        }, 500);
      }),
    );
  };

  const resetState = () => {
    setBreakoutSnapshot({});
    setNextRoomMap({});
  };

  const onCancel = () => {
    closeDialog();

    // delay this to keep the rooms from clearing before
    // the modal closes...
    delay(function () {
      resetState();
    }, 500);
  };

  /**
   * Convert a roomMap object into a firestore update
   */
  const roomMapToFirestore = roomMap => {
    let participantRoomAssignments = [];

    each(roomMap, (room, roomKey) => {
      each(room.participants, participant => {
        participantRoomAssignments.push({
          participantId: participant,
          roomId: roomKey,
        });
      });
    });

    let rooms = [];

    each(nextRoomMap, room => {
      rooms.push({
        id: room.id,
        description: room.description,
        title: room.title,
        muralURL: room.muralURL ?? null,
        muralState: room.muralState ?? null,
        originalMuralURL: room.originalMuralURL ?? null,
      });
    });

    return { rooms, participantRoomAssignments };
  };

  /**
   * Convert an object with 'rooms' and 'participantRoomAssignments' into
   * a roomMap
   * @param {object} obj
   * @param {array} obj.rooms
   * @param {array} obj.participantRoomAssignments
   * @returns {object}
   */
  const firestoreToRoomMap = obj => {
    return createRoomMapFromBreakouts(obj);
  };

  return (
    <div>
      <Dialog
        open={open}
        maxWidth={breakoutSnapshot?.rooms?.length > 2 ? 'lg' : 'md'}
        fullWidth
        onEnter={onDialogOpen}
      >
        <div className="d-flex justify-content-between border">
          <div style={{ maxWidth: '50%' }}>
            <DialogTitle>Move Participants</DialogTitle>
            <Typography className="ml-3 mb-3" style={{ marginTop: '-18px' }}>
              Drag participants between rooms below
              {!muralEnabled ? ', or use one of the actions to the right. ' : '. '}
              {muralEnabled &&
                'Shuffling, merging, and splitting of rooms are disabled when Mural for Breakouts is enabled. '}
              Participants not currently connected are in light red. Changes will not be made until
              you click the Move button below.
            </Typography>
          </div>

          {/* Action Buttons */}
          {!muralEnabled && (
            <div className="mb-3 mt-3 mr-3">
              <Button
                className="mr-1"
                variant="outlined"
                color="primary"
                size="small"
                onClick={onShuffle}
              >
                Shuffle
              </Button>
              <Tooltip
                title="There are not enough participants to split"
                placement="bottom"
                arrow
                disableHoverListener={canSplit()}
                disableFocusListener={canSplit()}
                disableTouchListener={canSplit()}
              >
                <span>
                  <Button
                    className="mr-1"
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={onSplit}
                    disabled={!canSplit()}
                  >
                    Split
                  </Button>
                </span>
              </Tooltip>
              <Tooltip
                title="There are not enough rooms to merge"
                placement="bottom"
                disableHoverListener={canMerge()}
                arrow
              >
                <span>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={onMerge}
                    disabled={!canMerge()}
                  >
                    Merge
                  </Button>
                </span>
              </Tooltip>
              {/* <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={onClearDisconnected}
              >Clear Disconnected
              </Button> */}
            </div>
          )}
          {/* END Action Buttons */}
        </div>
        <DialogContent className="move-participants pt-4" style={{ background: '#fdfdfd' }}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Grid container spacing={1} className="mb-4">
              {/* Render rooms and participants */}
              {map(nextRoomMap, (room, key) => (
                <Grid item sm={6} md={breakoutSnapshot?.rooms?.length > 2 ? 4 : 6} key={key}>
                  <Droppable droppableId={key} direction="horizontal" className="flex-row">
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        className="w-100 h-100"
                        {...provided.droppableProps}
                      >
                        <Card
                          className="border p-3 h-100"
                          style={getListStyle(snapshot.isDraggingOver)}
                        >
                          <Typography className="text-large mb-2">{room.title}</Typography>
                          <div className="d-flex flex-wrap">
                            {map(room.participants, (roomParticipantId, idx) => (
                              <Draggable
                                key={roomParticipantId}
                                draggableId={roomParticipantId}
                                index={idx}
                              >
                                {(provided, snapshot) => (
                                  <span
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style,
                                      participants[roomParticipantId],
                                    )}
                                    className="shadow-sm participant-pill"
                                  >
                                    {participants[roomParticipantId].name}
                                    {!participants[roomParticipantId].connected && (
                                      <CancelIcon
                                        className="ml-1"
                                        style={{ fontSize: 14 }}
                                        classes={deleteIconClasses}
                                        onClick={() => {
                                          onRemove(roomParticipantId);
                                        }}
                                      />
                                    )}
                                  </span>
                                )}
                              </Draggable>
                            ))}
                          </div>
                        </Card>
                      </div>
                    )}
                  </Droppable>
                </Grid>
              ))}
            </Grid>
          </DragDropContext>
        </DialogContent>
        <DialogActions
          className="d-flex justify-content-center align-items-center p-3 shadow border"
          disableSpacing
        >
          <Button
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            onClick={onCommit}
            className="mr-2"
          >
            Move
            {isSubmitting && <ButtonSpinner />}
          </Button>
          <Button variant="outlined" color="primary" disabled={isSubmitting} onClick={onCancel}>
            CANCEL
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

MoveParticipants.propTypes = {
  closeDialog: PropTypes.func,
  open: PropTypes.bool,
};

export default MoveParticipants;
