import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import cx from 'classnames';
import { map, size } from 'lodash';
// library components
import { Box, Card, CardContent, Typography } from '@material-ui/core';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
// cr components
import NoteForm from './NoteForm';
import EditCardButton from 'components/EditCardButton/EditCardButton';

const useStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
    flex: '1',
    width: '100%',
    minWidth: 160,
    maxWidth: 300,
    minHeight: 140,
    border: `1px solid ${grey[300]}`,
    boxShadow: '0px 8px 18px rgba(0, 0, 0, 0.12)',
  },
  cardContent: {
    width: '100%',
    padding: ({ isCategorize }) => (isCategorize ? 0 : 20),
    paddingBottom: '16px !important',
    position: 'relative',
  },
  cardTxt: {
    lineHeight: '20px',
    color: ({ disabled }) => (disabled ? theme.palette.text.disabled : theme.palette.text.primary),
    whiteSpace: 'pre-line',
    overflowX: 'hidden',
    overflowWrap: 'break-word',
  },
  cardVote: {
    position: 'relative',
    minWidth: 100,
    height: 90,
    flexDirection: 'column',
    padding: 14,
  },
  cardContentVote: {
    padding: 0,
    paddingBottom: `0 !important`,
  },
  participantSelectNote: {
    background: '#E5F6FE',
    border: '3px solid #246FC4',
    padding: 12,
    boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.2)',
  },
  dot: {
    display: 'block',
    width: 7,
    height: 7,
    marginLeft: theme.spacing(1),
    borderRadius: '50%',
    background: theme.palette.indigo.main,
  },
  voteDot: {
    display: 'block',
    width: 10,
    height: 10,
    marginRight: theme.spacing(1),
    borderRadius: '50%',
    background: theme.palette.indigo.main,
  },
  textResult: {
    fontWeight: 500,
  },
  combineBox: {
    position: 'absolute',
    top: -5,
    right: -5,
    display: 'flex',
    cursor: ({ context }) => context === 'review' && 'pointer',
  },
  filterIcon: {
    color: '#5E5E5E',
    fontSize: 14,
  },
  combineTitle: {
    marginLeft: theme.spacing(1),
    marginRight: 4,
    fontSize: 12,
  },
  cardDraggingOver: {
    backgroundColor: '#F4F4F4',
  },
}));

const getDraggableStyle = (style, snapshot) => {
  if (!snapshot.isDragging) return {};
  if (!snapshot.isDropAnimating) {
    return style;
  }

  return {
    ...style,
    transitionDuration: `0.001s`,
  };
};

/**
 * Renders view for note on the Participant side.
 */
const NoteCard = ({
  note,
  className,
  context,
  draggableId,
  handleParticipantVote,
  isSelected,
  isCategorize,
  isFinished,
  countVotes,
  onEditNote,
  handleOpenDuplicateCards,
  isDragStart,
  isPrevCategory,
  isShowVoteResults,
  isDraggableCategory,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const classes = useStyles({ isCategorize, context });

  const onCancelEditing = () => {
    setIsEditing(false);
  };

  const onEdit = () => {
    if (context === 'write') {
      setIsEditing(true);
    }
  };

  const onVote = () => {
    if (context === 'vote') {
      handleParticipantVote(note.noteId, isSelected);
    }
  };

  return (
    <Droppable
      key={note.noteId}
      droppableId={note.noteId}
      isCombineEnabled={context === 'review'}
      type="notes"
      isDropDisabled={isPrevCategory}
    >
      {(provided, dropSnapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.droppableProps}
          className="align-self-start"
          style={
            (isDragStart ? { height: 75, width: 145 } : {},
            dropSnapshot.draggingFromThisWith && context === 'review'
              ? { height: 75, width: 145, border: '1px dashed #CFCFCF', borderRadius: 4 }
              : {})
          }
        >
          <Draggable
            isDragDisabled={
              (context !== 'write' && context !== 'review') || isFinished || isPrevCategory
            }
            key={note.noteId}
            draggableId={note.noteId}
            index={+draggableId}
          >
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={getDraggableStyle(provided.draggableProps.style, snapshot)}
              >
                {isShowVoteResults && (
                  <Box className="d-flex mb-1">
                    {map(note.votedFor, vote => (
                      <Box className={classes.voteDot} />
                    ))}
                  </Box>
                )}
                <Card
                  className={cx(
                    classes.card,
                    className,
                    {
                      [classes.cardVote]:
                        context === 'vote' ||
                        context === 'results' ||
                        isCategorize ||
                        isShowVoteResults,
                    },
                    {
                      [classes.participantSelectNote]:
                        ((context === 'vote' || context === 'results') && isSelected) ||
                        isShowVoteResults,
                    },
                    dropSnapshot.isDraggingOver &&
                      isDraggableCategory &&
                      context === 'review' &&
                      classes.cardDraggingOver,
                  )}
                  onClick={onVote}
                >
                  <CardContent
                    className={cx(classes.cardContent, {
                      [classes.cardContentVote]:
                        context === 'vote' || context === 'results' || isCategorize,
                    })}
                    onClick={onEdit}
                  >
                    {isEditing && !isCategorize ? (
                      <NoteForm
                        cancelEdit={onCancelEditing}
                        type="edit"
                        note={note}
                        onEditNote={onEditNote}
                      />
                    ) : (
                      <>
                        <Typography className={classes.cardTxt} variant="body2">
                          {note.note || note.text}
                        </Typography>
                        <EditCardButton
                          isVisible={context === 'write' && !isCategorize}
                          onEdit={onEdit}
                        />
                      </>
                    )}
                    {!!size(note.combineNotesId) && (
                      <Box
                        className={classes.combineBox}
                        onClick={() => {
                          if (context === 'review') {
                            handleOpenDuplicateCards(note.noteId);
                          }
                        }}
                      >
                        <Typography className={classes.combineTitle}>
                          {size(note.combineNotesId) + 1}
                        </Typography>
                        <FilterNoneIcon className={classes.filterIcon} />
                      </Box>
                    )}
                  </CardContent>
                  {context === 'results' && !!countVotes && (
                    <Box className="mt-1" display="flex" alignItems="center">
                      <Typography className={classes.textResult} color="primary" variant="caption">
                        {countVotes}
                      </Typography>
                      <Box className={classes.dot} />
                    </Box>
                  )}
                </Card>
              </div>
            )}
          </Draggable>
          <div style={{ display: 'none' }}>{provided.placeholder}</div>
        </div>
      )}
    </Droppable>
  );
};

NoteCard.propTypes = {
  note: PropTypes.object.isRequired,
  draggableId: PropTypes.string.isRequired,
  context: PropTypes.oneOf(['write', 'vote', 'review', 'roundRobin', 'results']),
  handleParticipantVote: PropTypes.func,
  isSelected: PropTypes.bool,
  isCategorize: PropTypes.bool,
  isFinished: PropTypes.bool,
  countVotes: PropTypes.number,
  onEditNote: PropTypes.func,
  isShowVoteResults: PropTypes.bool, // show vote results
  isDraggableCategory: PropTypes.bool, // note drags in current category
};

export default NoteCard;
