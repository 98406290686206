import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import { ReactComponent as Neutral } from 'assets/img/sentiment_neutral.svg';
import { ReactComponent as Satisfied } from 'assets/img/sentiment_satisfied.svg';
import { ReactComponent as Dissatisfied } from 'assets/img/sentiment_dissatisfied.svg';

const renderSvg = (type, classes, width) => {
  switch (type) {
    case 'neutral':
      return <Neutral className={classes} />;
    case 'satisfied':
      return <Satisfied className={classes} />;
    case 'dissatisfied':
      return <Dissatisfied className={classes} />;
    default:
      return null;
  }
};

const useStyles = makeStyles(theme => {
  return {
    inactive: {
      fill: '#898989',
    },
    active: {
      fill: props => {
        switch (props.type) {
          case 'satisfied':
            return theme.palette.success.main;
          case 'neutral':
            return theme.palette.warning.main;
          case 'dissatisfied':
            return theme.palette.error.main;
          default:
            return 'purple';
        }
      },
    },
    default: {
      width: props => props.width,
    },
  };
});

const FeedbackIcon = props => {
  const { active, type, width } = props;
  const classes = useStyles(props);

  const iconClasses = cx({
    [classes.active]: active,
    [classes.inactive]: !active,
    [classes.default]: true,
  });

  return renderSvg(type, iconClasses, width);
};

FeedbackIcon.defaultProps = {
  width: 44,
};

FeedbackIcon.propTypes = {
  active: PropTypes.bool,
  type: PropTypes.oneOf(['neutral', 'satisfied', 'dissatisfied']).isRequired,
  width: PropTypes.number,
};

export default FeedbackIcon;
