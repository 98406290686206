import { combineReducers } from 'redux';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';
import slidesSessionReducer from './slidesSessionReducer';
import googleSlidesExportsReducer from './googleSlidesExportsReducer';
import googleSlidesAuthReducer from './googleSlidesAuthReducer';
import participantsReducer from './participantsReducer';
import participantSessionReducer from './participantSessionReducer';
import facilitatorSessionReducer from './facilitatorSessionReducer';
import meetingRoomReducer from './meetingRoomReducer';
import notifierReducer from './notifierReducer';
// import { idTokenReducer } from './idTokenReducer';
import staffingReducer from './staffingReducer';

const reducers = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  slidesSessions: slidesSessionReducer,
  googleSlidesAuth: googleSlidesAuthReducer,
  googleSlidesExports: googleSlidesExportsReducer,
  participants: participantsReducer,
  participantSession: participantSessionReducer,
  facilitatorSession: facilitatorSessionReducer,
  meetingRoom: meetingRoomReducer,
  notifications: notifierReducer,
  staffing: staffingReducer,
  // idToken: idTokenReducer
});

export default reducers;
