import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { map } from 'lodash';
import { isEmpty } from 'react-redux-firebase';

import { AppBar, Toolbar, Typography, Tooltip, Button } from '@material-ui/core';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';

import AvToggle from './AvToggle';
import StartSidebarMeeting from 'components/StartSidebarMeeting';
import FormDialog from 'components/Dialogs/FormDialog';
import RenameSessionForm from 'components/Forms/RenameSessionForm';
import RemoveCoFacilitatorsForm from 'components/Forms/RemoveCoFacilitatorsForm';
import SessionCardMenu from 'components/SessionCard/SessionCardMenu';

import { useMeetingRoomSelector } from '../../redux/selectors/meetingRoomSelectors';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    // flexGrow: 1,
    // textAlign: 'center',
    // maxWidth: '60%',
    // margin: '0 auto'
    // color: 'white'
  },
  navbarCenter: {
    flexGrow: 1,
    textAlign: 'center',
    maxWidth: '60%',
    margin: '15px auto',
  },
  button: {
    fontSize: 15,
    fontWeight: 400,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  sharedIcon: {
    fontSize: '25px !important',
  },
  sessionMenu: {
    left: -5,
    minWidth: 30,
    padding: 0,
  },
}));

export default function SessionControlNavbar({ session, isViewingShared }) {
  const [renameFormOpen, setRenameFormOpen] = useState(false);
  const [removeCoFacilitatorsFormOpen, setRemoveCoFacilitatorsFormOpen] = useState(false);
  const classes = useStyles();
  const coFacilitators = useSelector(state => state.firestore.data.coFacilitators);
  const { roomIsSidebar } = useMeetingRoomSelector();

  const renderCoFacilitatorNames = () => {
    if (!isEmpty(coFacilitators)) {
      const names = map(coFacilitators, coFac => coFac.displayName);
      return names.join(' ');
    }

    return '';
  };

  const toggleRenameForm = () => {
    setRenameFormOpen(!renameFormOpen);
  };

  const toggleRemoveCoFacilitatorsForm = () => {
    setRemoveCoFacilitatorsFormOpen(prev => !prev);
  };

  if (!session) return null;

  return (
    <div className={classes.root}>
      <AppBar position="static" color="transparent" elevation={1}>
        <Toolbar>
          <SessionCardMenu
            sessionId={session.id}
            isViewingShared={isViewingShared}
            activities={session.activities}
            sessionTopic={session.topic}
            className={classes.sessionMenu}
            recapDeck={session?.recapDeck}
          />
          {(isViewingShared ||
            (!!session?.coFacilitators && session.coFacilitators.length !== 0)) && (
            <div className="d-flex align-items-center position-absolute" style={{ left: 55 }}>
              {isViewingShared ? (
                <div className="d-flex align-items-center">
                  <SupervisedUserCircleIcon className="mr-1" />
                  Co-Facilitator
                </div>
              ) : (
                <Tooltip title={renderCoFacilitatorNames()} arrow>
                  <div className="d-flex align-items-center">
                    <Button
                      className={classes.button}
                      onClick={toggleRemoveCoFacilitatorsForm}
                      disableRipple
                      startIcon={<SupervisedUserCircleIcon className={classes.sharedIcon} />}
                    >
                      Shared
                    </Button>
                  </div>
                </Tooltip>
              )}
            </div>
          )}
          <div className={classes.navbarCenter}>
            <Button onClick={toggleRenameForm} disableRipple>
              <Typography variant="h6" className={classes.title}>
                {session.topic}
              </Typography>
            </Button>
          </div>
          <div style={{ position: 'absolute', right: 0 }}>
            {!roomIsSidebar && session.avEnabled && <StartSidebarMeeting />}
            {/* <AvToggle /> */}
          </div>
        </Toolbar>
      </AppBar>
      <FormDialog isOpen={renameFormOpen} handleClose={toggleRenameForm} title="Rename Meeting">
        <RenameSessionForm closeDialog={toggleRenameForm} sessionId={session.id} />
      </FormDialog>
      <FormDialog
        isOpen={removeCoFacilitatorsFormOpen}
        handleClose={toggleRemoveCoFacilitatorsForm}
        title="Edit Co-Facilitators"
      >
        <RemoveCoFacilitatorsForm
          closeDialog={toggleRemoveCoFacilitatorsForm}
          sessionId={session.id}
          coFacilitators={coFacilitators}
        />
      </FormDialog>
    </div>
  );
}
