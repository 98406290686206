import moment from 'moment';
import { generateRandomId } from '../generateRandomId';
import { keyBy, cloneDeep, each } from 'lodash';

const DEFAULT_ROOMS_NUM = 1;
/**
 * If there are no rooms in firestore initialize one room by default.
 */
export const getInitialRooms = rooms => {
  if (rooms && rooms.length) {
    return rooms;
  }

  return Array(DEFAULT_ROOMS_NUM)
    .fill({})
    .map((room, idx) => {
      const roomNumber = idx + 1;
      const id = generateRandomId(); // adding index just to ensure that values are not the same.
      return {
        id,
        title: `Breakout ${roomNumber}`,
        description: '',
      };
    });
};

export const startSession = (breakoutId, { topic, rooms, participantRoomAssignments }) => {
  return {
    id: breakoutId,
    breakoutStarted: true,
    topic,
    rooms,
    participantRoomAssignments,
  };
};

export function updateFields(field, value, values, { topic, rooms }) {
  // console.log('field', field);
  // console.log('value', value);

  if (field === 'topic' || field === 'description') {
    const fieldName = field === 'topic' ? 'name' : field;
    return {
      topic: {
        ...topic,
        [field]: value,
      },
    };
  }

  if (field === 'participantsChooseRooms') {
    return { [field]: value === 'true' };
  }

  if (field === 'muralEnabled') {
    return { [field]: value === 'true' };
  }

  if (field === 'maxRoomSize') {
    return { [field]: +value };
  }

  if (field === 'muralWorkspace') {
    return { [field]: value };
  }

  if (field === 'muralURL') {
    return { [field]: value };
  }

  const fieldName = field.match(/(title|description)/)[1];
  const roomIdx = field.split('.')[1];

  // console.log('rooms', rooms);
  // console.log('roomFieldName', fieldName);
  // console.log('roomIdx', roomIdx);

  // TODO:  now that we are creating the breakout with 1 room from the beginning,
  //        much of this code is probably unnecessary. We should be able to just update
  //        the room rather than checking if it exists, is empty, etc

  if (!rooms[roomIdx]) {
    rooms.splice(roomIdx, 0, {});
  }

  const nextRoomsState = rooms.length
    ? rooms.map((room, idx) => {
        if (idx == roomIdx) {
          const update = {
            ...room,
            [fieldName]: value,
          };

          if (!update.id) {
            update.id = moment().valueOf();
          }

          return update;
        }

        return room;
      })
    : [
        {
          id: moment().valueOf(),
          [fieldName]: value,
        },
      ];

  // console.log('nextRoomState', nextRoomsState);

  return {
    rooms: nextRoomsState,
  };
}

export const createRoomMapFromBreakouts = breakout => {
  const breakoutsCopy = cloneDeep(breakout);

  // if (!breakoutsCopy?.participantRoomAssignments?.length) return null;

  const breakoutRooms = keyBy(breakoutsCopy.rooms, 'id');

  if (breakoutsCopy?.participantRoomAssignments?.length) {
    breakoutsCopy.participantRoomAssignments.forEach(rmAss => {
      if (!breakoutRooms[rmAss.roomId].participants) {
        breakoutRooms[rmAss.roomId].participants = [];
      }
      breakoutRooms[rmAss.roomId].participants.push(rmAss.participantId);
    });
  }

  each(breakoutRooms, (room, key) => {
    if (!room.participants) {
      breakoutRooms[key].participants = [];
    }
  });

  return breakoutRooms;
};
