import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import firebase from 'firebase/app';
import 'firebase/storage';
import imageCompression from 'browser-image-compression';
import { map, last, split, head, findIndex, size, remove, find } from 'lodash';
// library components
import { Box, Typography, makeStyles } from '@material-ui/core';
// cr components
import SelectDeck from './SelectDeck';
import Slider from 'components/Slider';
import NewImage from './NewImage';
import Image from './Image';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
// utils
import { generateRandomId } from 'utils/generateRandomId';
// actions
import { updateRecapDeckImages } from 'redux/actions/sessionActions';

const useStyles = makeStyles(theme => ({
  button: {
    position: 'relative',
    background: '#EEEEEE',
    color: '#898989',
    borderRadius: 5,
    minWidth: '41px !important',
    minHeight: '41px !important',
    zIndex: 10,
    fontSize: 17,
    '&:hover': {
      background: '#EEEEEE',
    },
    '&:disabled': {
      background: '#fff',
      color: '#CFCFCF',
      border: '1px solid #CFCFCF',
      pointerEvents: 'auto',
    },
  },
  textSlider: {
    display: 'none',
  },
  leftIcon: {
    position: 'absolute',
    left: 13,
  },
  imagesContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  gridContainer: {
    width: 'calc(100% + 20px)',
    margin: '0 -10px',
  },
  gridItem: {
    padding: `0 10px !important`,
  },
  activityTitle: {
    color: '#5C5C5C',
    fontSize: 14,
  },
}));

const ImagesContent = ({
  activities,
  sessionId,
  recapDeckActivities,
  sessionActivity,
  onlyImages,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const activityNames = map(activities, activity => {
    const activityName = last(split(activity.name, ': '));
    return {
      id: activity.id,
      name: activityName,
    };
  });

  const [selectedActivity, setSelectedActivity] = useState(
    onlyImages
      ? find(activities, activity => activity.id === sessionActivity.id)?.id
      : head(activityNames).id,
  );
  const [imageUrls, setImageUrls] = useState(
    recapDeckActivities?.[selectedActivity]?.supplementalImages,
  );

  useEffect(() => {
    setImageUrls(recapDeckActivities?.[selectedActivity]?.supplementalImages || []);
  }, [selectedActivity, recapDeckActivities]);

  const sliderStartIndex = findIndex(activityNames, activity => activity.id === selectedActivity);

  const handleChangeActivity = event => {
    setSelectedActivity(event.target.value);
  };

  // Upload new image to firebase
  const uploadNewImage = async file => {
    const options = {
      maxWidthOrHeight: 1920,
    };

    setImageUrls(prev => [...prev, '']);

    try {
      const imageId = generateRandomId();
      const compressedFile = await imageCompression(file, options);
      const storageRef = firebase
        .storage()
        .ref(`recapDeck/${sessionId}/${selectedActivity}/${imageId}`);

      await storageRef.put(compressedFile);
      const url = await storageRef.getDownloadURL();

      dispatch(
        updateRecapDeckImages(sessionId, selectedActivity, url, false, () => {
          const newUrl = [...imageUrls];
          remove(newUrl, url => url === '');
          setImageUrls([...newUrl, url]);
        }),
      );
    } catch (err) {
      const newUrl = [...imageUrls];
      remove(newUrl, url => url === '');
      setImageUrls([...newUrl]);
      console.log('Error upload image to firebase', err);
    }
  };

  // Delete image from firestore
  const handleDeleteImage = src => {
    const deleteRef = firebase.storage().refFromURL(src);

    dispatch(
      updateRecapDeckImages(sessionId, selectedActivity, src, true, () => {
        deleteRef
          .delete()
          .then(() => {
            const newUrl = [...imageUrls];
            remove(newUrl, url => url === src);
            setImageUrls(newUrl);
          })
          .catch(err => {
            console.log('Error delete image from recap deck', err);
          });
      }),
    );
  };

  const renderImages = () => {
    if (size(imageUrls)) {
      return map(imageUrls, (imageUrl, idx) => (
        <GridItem key={`recap-image-${idx}`} className={classes.gridItem}>
          <Image fileUrl={imageUrl} onDeleteImage={handleDeleteImage} />
        </GridItem>
      ));
    }
  };

  const slideLeft = () => {
    const nextIndex = sliderStartIndex - 1;
    if (nextIndex >= 0) {
      setSelectedActivity(activityNames[nextIndex].id);
    }
  };

  const slideRight = () => {
    const nextIndex = sliderStartIndex + 1;
    if (nextIndex <= size(activityNames)) {
      setSelectedActivity(activityNames[nextIndex].id);
    }
  };

  const renderSlides = () =>
    map(activityNames, activity => <Typography>{activity.name}</Typography>);

  const renderAllImages = () => (
    <GridContainer direction="row" className={classes.gridContainer}>
      {renderImages()}
      <GridItem className={classes.gridItem}>
        <NewImage uploadNewImage={uploadNewImage} />
      </GridItem>
    </GridContainer>
  );

  return onlyImages ? (
    <Box className="mt-5">{renderAllImages()}</Box>
  ) : (
    <Box className="w-100">
      <SelectDeck
        title="Add activity Image to deck"
        options={activityNames}
        selectedValue={selectedActivity}
        handleChange={handleChangeActivity}
      />
      <Slider
        buttonClassName={classes.button}
        slides={renderSlides()}
        textClassName={classes.textSlider}
        leftIconClassName={classes.leftIcon}
        startFrom={sliderStartIndex}
        isUserControl={true}
        onRightButton={slideRight}
        onLeftButton={slideLeft}
      />
      {renderAllImages()}
    </Box>
  );
};

ImagesContent.propTypes = {
  activities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired, // activity id
      name: PropTypes.string.isRequired, // activity name
    }),
  ),
  sessionId: PropTypes.string.isRequired, // session id,
  recapDeckActivities: PropTypes.object, // recap deck images for all activities
  sessionActivity: PropTypes.shape({
    id: PropTypes.string.isRequired, // current activity id
    name: PropTypes.string.isRequired, // current activity name
  }),
  onlyImages: PropTypes.bool, // true - recap deck only for images
};

export default ImagesContent;
