import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { map, remove, find, last, reduce, size, sortBy, filter, isEmpty } from 'lodash';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  horizontalListSortingStrategy,
} from '@dnd-kit/sortable';
import firebase from 'firebase/app';
import 'firebase/storage';
import imageCompression from 'browser-image-compression';
import { makeStyles } from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import cx from 'classnames';
// library components
import { Box, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
// cr components
import Image from './Image';
import NewImage from './NewImage';
// selectors
import { useHeatMappingSelector, getParticipantImages } from 'redux/selectors/heatMappingSelectors';
// actions
import {
  createImage,
  createSketch,
  updateImages,
  updateSketchImages,
  removeSketchImage,
} from 'redux/actions/heatMappingActions';
// utils
import { generateRandomId } from 'utils/generateRandomId';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

const useStyles = makeStyles(theme => ({
  successColor: {
    color: theme.palette.success.main,
  },
  imageBox: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: ({ isSmallScreen }) => (isSmallScreen ? 'center' : 'none'),
    alignSelf: ({ isSmallScreen }) => (isSmallScreen ? 'center' : 'start'),
  },
}));

/**
 * Heat-Mapping view for the 'upload' phase on the Participant side
 */
const HeatMappingParticipantUpload = ({ isAdminReordering, participant = '' }) => {
  const isSmallScreen = useMediaQuery('(max-width: 767px)');
  const dispatch = useDispatch();
  const classes = useStyles({ isSmallScreen });
  let participantId = useSelector(state => state.firebase.auth.uid);
  participantId = isAdminReordering ? participant : participantId;
  const participantImages = useSelector(getParticipantImages(participantId));
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const {
    id,
    participantData: { images, sketches },
  } = useHeatMappingSelector();

  const sortedParticipantImages = sortBy(
    filter(images, image => image.participantId === participantId),
    'order',
  );

  const sortedParticipantImageUrls = sortBy(
    filter(images, image => image.participantId === participantId),
    'order',
  ).map(i => i.url);
  const [items, setItems] = useState(sortedParticipantImageUrls);
  const [imageUrls, setImageUrls] = useState([]);
  const [isLoadingNewImage, setIsLoadingNewImage] = useState(false);
  const [hasReordered, setHasReordered] = useState(false);
  const currentSketch = find(sketches, sketch => sketch.participantId === participantId);
  const sketchId = currentSketch ? currentSketch.id : generateRandomId();

  useEffect(() => {
    // prevent stale images from being re-loaded if the activity is reset
    if (!Object.keys(images).length) return;

    // Get the url of images for participant
    const getImageUrl = imageRefs => {
      const urls = map(imageRefs, imageRef => imageRef.getDownloadURL());
      Promise.all(urls)
        .then(url => {
          setImageUrls(url);
        })
        .catch(err => {
          console.log('Error get images url', err);
        });
    };

    // Get the references of all images for participant
    const getAllParticipantImages = () => {
      const storageRef = firebase.storage().ref(`heatMapping/${id}/${participantId}`);

      storageRef
        .listAll()
        .then(result => {
          getImageUrl(result.items);
        })
        .catch(err => {
          console.log('Error get images ref', err);
        });
    };
    getAllParticipantImages();
  }, []);

  // push reordered items to the db
  useEffect(() => {
    if (!isEmpty(images)) {
      const myObject = { ...images };
      const myImages = [];
      if (items.length > 0) {
        items.map((imageUrl, idx) => {
          const imageItem = Object.values(myObject).find(i => i.url === imageUrl);
          const updatedImage = {
            ...imageItem,
            order: idx,
          };
          myObject[updatedImage.id] = updatedImage;
          myImages.push(updatedImage.id);
          return updatedImage;
        });
        dispatch(updateImages(id, myObject));
        if (!isAdminReordering) {
          dispatch(updateSketchImages(id, sketchId, myImages.reverse()));
        }
      }
      setHasReordered(false);
    }
  }, [hasReordered]);

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      setItems(items => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);

        return arrayMove(items, oldIndex, newIndex);
      });
      setHasReordered(true);
    }
  }

  // Upload new image to firebase
  const uploadNewImage = async file => {
    setIsLoadingNewImage(true);
    const options = {
      maxWidthOrHeight: 1920,
    };

    if (!currentSketch) {
      dispatch(createSketch(id, sketchId, participantId, participantImages));
    }

    try {
      const imageId = generateRandomId();
      const compressedFile = await imageCompression(file, options);
      const storageRef = firebase.storage().ref(`heatMapping/${id}/${participantId}/${imageId}`);

      await storageRef.put(compressedFile);
      const url = await storageRef.getDownloadURL();
      setImageUrls([...imageUrls, url]);
      setItems([...imageUrls, url]);
      const order = size(sortedParticipantImages) ? last(sortedParticipantImages).order + 1 : 0;
      dispatch(createImage(id, imageId, participantId, url, order));
      const updatedSketchImages = currentSketch ? [...currentSketch.images, imageId] : [imageId];
      dispatch(updateSketchImages(id, sketchId, updatedSketchImages));

      setIsLoadingNewImage(false);
    } catch (err) {
      console.log('Error upload image to firebase', err);
      setIsLoadingNewImage(false);
    }
  };

  // Delete image from firestore
  const handleDeleteImage = src => {
    const deleteRef = firebase.storage().refFromURL(src);
    const path = deleteRef.location.path_.split('/');
    const imageId = last(path);
    const newImages = reduce(
      images,
      (acc, image) => {
        if (image.id !== imageId) {
          acc[image.id] = image;
        }
        return acc;
      },
      {},
    );

    deleteRef
      .delete()
      .then(() => {
        dispatch(updateImages(id, newImages));

        const newImageUrls = [...imageUrls];
        remove(newImageUrls, url => url === src);
        setImageUrls([...newImageUrls]);
        setItems([...newImageUrls]);

        dispatch(removeSketchImage(id, sketchId, imageId));
      })
      .catch(err => {
        console.log('Error delete image to firebase', err);
      });
  };

  const SortableItem = props => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
      id: props.id,
    });

    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
    };

    return (
      <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
        <Image
          src={props.id}
          name={`Image ${props.position + 1}`}
          handleDeleteImage={handleDeleteImage}
        />
      </div>
    );
  };

  return (
    <>
      <Box className={cx(classes.imageBox)}>
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
          <SortableContext items={items} strategy={horizontalListSortingStrategy}>
            <Box className={cx(classes.imageBox)}>
              {items.map((id, idx) => (
                <SortableItem key={id} id={id} position={idx} />
              ))}
            </Box>
          </SortableContext>
        </DndContext>
        {!isAdminReordering && (
          <NewImage
            participantId={participantId}
            heatMappingId={id}
            uploadNewImage={uploadNewImage}
            isLoading={isLoadingNewImage}
          />
        )}
      </Box>
      {!!imageUrls.length && !isAdminReordering && (
        <>
          <CheckCircleIcon className={classes.successColor} />
          <Typography className={cx(classes.successColor, 'mb-3')} variant="body2">
            You’ve uploaded at least 1 sketch
          </Typography>
        </>
      )}
    </>
  );
};
export default HeatMappingParticipantUpload;
