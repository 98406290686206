import React from 'react';
import { makeStyles, TextField } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import EventIcon from '@material-ui/icons/Event';

// styles
const useStyles = makeStyles(theme => ({
  datePicker: {
    '& .MuiInputBase-root': {
      height: '32px',
    },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 0,
    },
    '& .MuiOutlinedInput-input': {
      padding: '7px 10px',
      paddingRight: 0,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
      borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: 0,
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderBottom: '1px solid rgba(0, 0, 0, 0.87)',
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderBottom: '2px solid #093c75',
        borderRadius: 0,
      },
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(10px, 9px) scale(1)',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink, & .MuiInputLabel-outlined.MuiInputLabel-focused':
      {
        transform: 'translate(0px, -14px) scale(0.75)',
      },
    '& .MuiInputAdornment-positionEnd': {
      marginRight: '0',
      marginLeft: '0',
    },
  },
  datePickerInput: {
    backgroundColor: '#EEEEEE',
  },
}));

const StaffingDatePicker = ({
  onChange,
  label,
  value = null,
  format = 'MM/DD/YYYY',
  readOnly = true,
}) => {
  const classes = useStyles();

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDatePicker
        className={`${classes.datePicker}`}
        style={{ margin: 8, width: '100%' }}
        InputProps={{
          className: classes.datePickerInput,
          readOnly: readOnly,
        }}
        autoOk
        TextFieldComponent={TextField}
        disableToolbar
        variant="inline"
        inputVariant="outlined"
        format={format}
        value={value}
        onChange={onChange}
        KeyboardButtonProps={{
          'aria-label': 'change available date',
        }}
        keyboardIcon={<EventIcon color="primary" fontSize="small" />}
        label={label}
        clearable={(!readOnly).toString()}
        InputLabelProps={{
          shrink: !!value, // Only shrink when a date is selected
        }}
      />
    </MuiPickersUtilsProvider>
  );
};

export default StaffingDatePicker;
