import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { map, sortBy, find } from 'lodash';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import cx from 'classnames';
// library components
import { Box, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
// cr components
import JourneyVote from 'components/UserJourneyMoments/Participant/JourneyVote';
import DottedProgress from 'components/DottedProgress';
// selectors
import {
  useUserJourneyMomentsSelector,
  getParticipantActorVotes,
  getParticipantMomentVotes,
} from 'redux/selectors/userJourneyMomentsSelectors';
// actions
import {
  updateParticipantActorVote,
  updateParticipantMomentVote,
} from 'redux/actions/userJourneyMomentsActions';

const useStyles = makeStyles(theme => ({
  successColor: {
    color: theme.palette.success.main,
  },
}));

/**
 * User Journey Moments view for the 'vote' phase on the Participant side
 */
const UserJourneyMomentsParticipantVote = () => {
  const totalVotesCount = 1;
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const participantId = useSelector(state => state.firebase.auth.uid);
  // get participant all votes for actors
  const participantActorVotes = useSelector(getParticipantActorVotes(participantId));
  // get participant all votes for moments
  const participantMomentVotes = useSelector(getParticipantMomentVotes(participantId));
  // get participant current vote for actor
  const participantActorVote = find(
    participantActorVotes,
    participantVotes => participantVotes.votes?.isVoted,
  );
  // get participant current vote for moment
  const participantMomentVote = find(
    participantMomentVotes,
    participantVotes => participantVotes.votes?.isVoted,
  );

  const {
    id,
    participantData: { journeys },
    state: { activeView },
  } = useUserJourneyMomentsSelector();
  // sorted participants journey by participant id
  const sortedJourneys = sortBy(journeys, 'participantId');

  // participant actor vote
  const [actorVote, setActorVote] = useState(!!participantActorVote);
  // participant moment vote
  const [momentVote, setMomentVote] = useState(!!participantMomentVote);
  // participant selected moment and actor
  const isVoteComplete = actorVote && momentVote;

  // participant select journey field for vote
  const handleVote = (journeyId, type, isVoted) => {
    if (type === 'actor') {
      if (participantActorVote?.journeyId) {
        dispatch(
          updateParticipantActorVote(
            id,
            participantActorVote.journeyId,
            participantId,
            type,
            false,
          ),
        );
      }
      setActorVote(isVoted);
      dispatch(updateParticipantActorVote(id, journeyId, participantId, type, isVoted));
    } else {
      if (participantMomentVote?.journeyId) {
        dispatch(
          updateParticipantMomentVote(
            id,
            participantMomentVote.journeyId,
            participantId,
            type,
            false,
          ),
        );
      }
      setMomentVote(isVoted);
      dispatch(updateParticipantMomentVote(id, journeyId, participantId, type, isVoted));
    }
  };

  // renders participan vote
  const rendersJourneyVote = () =>
    map(sortedJourneys, (journey, idx) => {
      // is actor vote for this journey
      const isActorVote = participantActorVote?.journeyId === journey.participantId;
      // is moment vote for this journey
      const isMomentVote = participantMomentVote?.journeyId === journey.participantId;
      if (journey.keyMoment && journey.values?.actor) {
        return (
          <JourneyVote
            key={`journey-${idx}`}
            journeyId={journey.participantId}
            keyMoment={journey.keyMoment}
            keyMomentTitle={journey.values ? journey.values[journey.keyMoment] : ''}
            actor={journey.values?.actor}
            type="actor"
            context={activeView}
            handleVote={handleVote}
            isActorVote={isActorVote}
            actorVote={isActorVote ? participantActorVote?.votes : {}}
            isMomentVote={isMomentVote}
            momentVote={isMomentVote ? participantMomentVote?.votes : {}}
          />
        );
      }
    });

  return (
    <>
      <Box
        className="w-100 mb-5"
        display="flex"
        alignItems="center"
        justifyContent="start"
        flexWrap="wrap"
      >
        {rendersJourneyVote()}
      </Box>
      {isVoteComplete ? (
        <>
          <CheckCircleIcon className={classes.successColor} />
          <Typography className={cx(classes.successColor, 'mb-3')} variant="body2">
            You’ve voted on Actor and Key Moment
          </Typography>
        </>
      ) : (
        <>
          <Box display="flex" alignItems="center" flexWrap="wrap" justifyContent="center">
            <DottedProgress
              activeColor={theme.palette.indigo.main}
              activeCount={actorVote ? 1 : 0}
              totalCount={totalVotesCount}
            />
            <DottedProgress
              activeColor={theme.palette.secondary.light}
              activeCount={momentVote ? 1 : 0}
              totalCount={totalVotesCount}
            />
          </Box>
          <Typography className="mb-3" variant="body2" color="textSecondary">
            Vote on actor and key moment
          </Typography>
        </>
      )}
    </>
  );
};

export default UserJourneyMomentsParticipantVote;
