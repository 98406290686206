import { createSelector } from 'reselect';
import { useShallowEqualSelector } from '../utils';
import { map, find, filter } from 'lodash';
import { getFirebase } from 'react-redux-firebase';
import { formatGiveawayReport } from 'utils/giveaway';

const giveawaySelector = state => state.firestore.data.giveaway || {};

export const useGiveawaySelector = () => {
  return useShallowEqualSelector(giveawaySelector);
};

/**
 * Get data on which participants have completed write data,
 * formatted for the ActivityParticipantDetail component
 *
 * @returns {Array.<{id: String, completed: Boolean}>}
 */
export const getParticipantsCompletedWrite = () =>
  createSelector(
    [useGiveawaySelector],
    ({ participantData: { giveawayParticipants, participants } }) => {
      return map(participants, participantId => {
        const participantData = find(
          giveawayParticipants,
          participant => participant.participantId === participantId,
        );
        return {
          id: participantId,
          completed: !!participantData,
        };
      });
    },
  );

/**
 * Get winner participant data.
 *
 */
export const getWinnerParticipant = numberWinner =>
  createSelector(
    [useGiveawaySelector],
    ({ participantData: { giveawayParticipants }, state: { winnerParticipants } }) => {
      const winnerParticipant = find(
        winnerParticipants,
        participant => participant.order === numberWinner,
      );
      const winnerParticipantId = winnerParticipant?.participantId;
      const winnerParticipantData = find(
        giveawayParticipants,
        participant => participant.participantId === winnerParticipantId,
      );
      return winnerParticipantData;
    },
  );

/**
 * Get winner participants data.
 *
 */
export const getWinnerParticipants = () =>
  createSelector(
    [useGiveawaySelector],
    ({ participantData: { giveawayParticipants }, state: { winnerParticipants } }) => {
      return map(winnerParticipants, participant => {
        const participantData = find(
          giveawayParticipants,
          data => data.participantId === participant.participantId,
        );
        return {
          ...participantData,
        };
      });
    },
  );

/**
 * Get active participants for giveaway.
 *
 */
export const getActiveParticipants = () =>
  createSelector(
    [useGiveawaySelector],
    ({ participantData: { giveawayParticipants }, state: { winnerParticipants } }) => {
      const activeParticipants = filter(giveawayParticipants, participant => {
        const winnerParticipant = find(
          winnerParticipants,
          data => data.participantId === participant.participantId,
        );
        if (!winnerParticipant) {
          return participant;
        }
      });
      return map(activeParticipants, participant => ({
        id: participant.participantId,
      }));
    },
  );

/**
 * Get giveaway results.
 *
 */
export const getGiveawayResults = () =>
  createSelector(
    [useGiveawaySelector],
    ({ participantData: { giveawayParticipants }, state: { winnerParticipants } }) => {
      return formatGiveawayReport(giveawayParticipants, winnerParticipants);
    },
  );

/**
 * Get participants data for all giveaway activies in current session.
 *
 */
export const getGiveawayActivities = async giveawayActivities => {
  const firebase = getFirebase();
  const db = firebase.firestore();
  const snapshot = await db.collection('giveaway').get();
  const giveaway = map(snapshot.docs, doc => doc.data());
  const giveawayActivitiesData = filter(giveaway, data => {
    const activity = find(giveawayActivities, activity => activity === data.id);
    if (activity) {
      return data;
    }
  });
  return map(giveawayActivitiesData, data => ({
    ...data?.participantData?.giveawayParticipants,
  }));
};

/**
 * Get participants data in current session.
 *
 */
export const getParticipantSessionData = async sessionId => {
  const firebase = getFirebase();
  const db = firebase.firestore();
  const snapshot = await db.collection('participants').doc(`${sessionId}`).get();
  const session = snapshot.data();
  return session;
};
