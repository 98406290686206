import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { loadStripe } from '@stripe/stripe-js';
import { each } from 'lodash';
import { useFirebase, useFirestore } from 'react-redux-firebase';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import * as Sentry from '@sentry/react';
import cx from 'classnames';
// actions
import { enqueueErrorSnackbar } from 'redux/actions/notifierActions';
// library components
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  IconButton,
  Typography,
  Card,
  Box,
  CardContent,
  Divider,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Logo from 'assets/img/cr-logo-gold.svg';
// cr components
import ButtonSpinner from 'components/ButtonSpinner/ButtonSpinner';
// selectors
import { facilitatorSubscriptionSelector } from 'redux/selectors/facilitatorSessionSelectors';

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    marginLeft: 'auto',
    fontWeight: 500,
  },
  closeIcon: {
    position: 'relative',
    right: `-${theme.spacing(2)}px`,
    top: `-${theme.spacing(1)}px`,
    marginLeft: 'auto',
  },
  dialogContent: {
    padding: `${theme.spacing(5)}px ${theme.spacing(8)}px`,
  },
  dialogContentTitle: {
    display: 'flex',
    flexDirection: 'column',
    padding: `0 ${theme.spacing(3)}px`,
    marginBottom: theme.spacing(3),
    fontWeight: 300,
    opacity: 0.87,
  },
  controlRoomTitle: {
    color: theme.palette.gold.lightest,
    fontWeight: 500,
  },
  controlRoomSubtitle: {
    fontSize: 17,
    fontWeight: 300,
    color: '#2B3648',
  },
  price: {
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(1),
    color: theme.palette.gold.lightest,
    fontSize: 18,
    fontWeight: 500,
  },
  priceSubtitle: {
    fontWeight: 300,
  },
  buttonSubscribe: {
    height: 45,
    width: '80%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: theme.spacing(7),
    background: theme.palette.gold.main,
    color: '#FFF',
    '&:hover': {
      background: theme.palette.gold.main,
    },
  },
  buttonDisabled: {
    backgroundColor: theme.palette.gold.main,
    opacity: 0.5,
  },
  buttonSpinnerBox: {
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  buttonSpinner: {
    color: theme.palette.gold.main,
  },
}));

const PremiumSubscriptionDialog = ({ handleClose, isOpen }) => {
  const classes = useStyles();
  const db = useFirestore();
  const dispatch = useDispatch();
  const functions = useFirebase().functions();

  const profile = useSelector(state => state.firebase.profile);

  const [priceText, setPriceText] = useState('');
  const [priceId, setPriceId] = useState('');
  const [userSubscriptionData, setUserSubscriptionData] = useState();
  const [loading, setLoading] = useState(false);
  const currentUserId = profile.uid;
  const { isSubscriber, hasStripeSubscription, hasFreeSubscription } = useSelector(
    facilitatorSubscriptionSelector(),
  );

  useEffect(() => {
    let unmounted = false;

    // Data listeners
    const startDataListeners = async () => {
      // Get products
      await db
        .collection('stripePricingPlans')
        .where('active', '==', true)
        .where('role', '==', 'subscriber')
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(async doc => {
            const priceSnap = await doc.ref.collection('prices').orderBy('unit_amount').get();

            // Set price in local state...
            each(priceSnap.docs, doc => {
              const priceId = doc.id;
              const priceData = doc.data();
              const content = `${new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'usd',
              }).format((priceData.unit_amount / 100).toFixed(2))} / ${priceData.interval}`;
              if (!unmounted) {
                setPriceText(content);
                setPriceId(priceId);
              }
            });
          });
        });

      // Get all subscriptions for the customer
      db.collection('users')
        .doc(currentUserId)
        .collection('subscriptions')
        .where('status', 'in', ['trialing', 'active'])
        .onSnapshot(async snapshot => {
          if (snapshot.empty) {
            return;
          }

          // In this implementation we only expect one Subscription to exist
          const subscription = snapshot.docs[0].data();
          const priceData = (await subscription.price.get()).data();
          if (!unmounted) {
            setUserSubscriptionData(priceData);
          }
        });
    };

    if (currentUserId) {
      startDataListeners();
    }
    return () => {
      unmounted = true;
    };
  }, [currentUserId]);

  const subscribe = async event => {
    event.preventDefault();
    setLoading(true);
    const docRef = await db
      .collection('users')
      .doc(profile.uid)
      .collection('checkout_sessions')
      .add({
        price: priceId,
        allow_promotion_codes: true,
        success_url: window.location.origin,
        cancel_url: window.location.origin,
        subscription_data: {
          payment_behavior: 'allow_incomplete',
        },
      });
    // Wait for the CheckoutSession to get attached by the extension
    docRef.onSnapshot(async snap => {
      const { error, sessionId } = snap.data();
      if (error) {
        // Show an error to customer and then inspect function logs.
        console.log(`An error occured: ${error.message}`);
        dispatch(enqueueErrorSnackbar('Error opening checkout'));
        Sentry.captureException(error);
      }
      if (sessionId) {
        // If a session create, redirect to Checkout
        // Init Stripe
        const stripe = await loadStripe(process.env.REACT_APP_STRIPE_API_KEY);
        stripe.redirectToCheckout({ sessionId });
      }
    });
  };

  const handleOpenBillingPortal = async () => {
    if (userSubscriptionData) {
      setLoading(true);
      const functionRef = functions.httpsCallable(
        'ext-firestore-stripe-subscriptions-createPortalLink',
      );
      const { data } = await functionRef({
        customer: currentUserId,
        returnUrl: window.location.origin,
      });
      window.location.assign(data.url);
    }
  };

  return (
    <div>
      <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle disableTypography className="p-0">
          <div className="d-flex justify-content-center pt-2 pl-2 pr-2">
            <Typography align="center" className={classes.dialogTitle}>
              Control Room Premium
            </Typography>
            <IconButton aria-label="close" onClick={handleClose} className={classes.closeIcon}>
              <CloseIcon />
            </IconButton>
          </div>
          <Divider />
        </DialogTitle>
        <form className="d-flex flex-column" onSubmit={subscribe}>
          <DialogContent className={classes.dialogContent}>
            {!isSubscriber && (
              <Typography className={classes.dialogContentTitle} align="center">
                A free account comes with 5 meetings and a maximum of 3 activities per meeting. To
                get full access, subscribe to Control Room Premium.
              </Typography>
            )}
            {hasFreeSubscription && (
              <Typography className={classes.dialogContentTitle} align="center">
                You have a complimentary subscirption 🎉
              </Typography>
            )}
            <Card>
              <Box display="flex" className="p-4">
                <img src={Logo} width="48" alt="premium-logo" />
                <Box display="flex" flexDirection="column" alignItems="flex-start" className="pl-2">
                  <Typography variant="h5" className={classes.controlRoomTitle} align="center">
                    Control Room Premium
                  </Typography>
                  <Typography className={classes.controlRoomSubtitle} align="center">
                    Unlimited meetings & activities, plus...
                  </Typography>
                </Box>
              </Box>
              <Divider />
              <CardContent>
                <ul>
                  <li>Meeting Templates</li>
                  <li>Add Co-Facilitators</li>
                  <li>Note & Categorize</li>
                  <li>Note & Map</li>
                  <li>Heat Mapping</li>
                  <li>Sprint Scorecard</li>
                  <li>Card Sorting</li>
                </ul>
                {priceText ? (
                  <Typography className={classes.price}>
                    {priceText}
                    <span className={classes.priceSubtitle}> (billed monthly) </span>
                  </Typography>
                ) : (
                  <Box className={classes.buttonSpinnerBox}>
                    <ButtonSpinner className={classes.buttonSpinner} />
                  </Box>
                )}
              </CardContent>
            </Card>
          </DialogContent>
          {priceId && !hasFreeSubscription ? (
            <Button
              type="submit"
              className={classes.buttonSubscribe}
              classes={{
                disabled: classes.buttonDisabled,
              }}
              onClick={handleOpenBillingPortal}
              disabled={loading}
            >
              {loading ? (
                <Box display="flex" justifyContent="center" className="pr-3">
                  <ButtonSpinner />
                </Box>
              ) : hasStripeSubscription ? (
                'MANAGE SUBSCRIPTION'
              ) : (
                'SUBSCRIBE'
              )}
            </Button>
          ) : (
            <>
              {!hasFreeSubscription && (
                <Box
                  display="flex"
                  justifyContent="center"
                  className={cx('mb-7', classes.buttonSpinnerBox)}
                >
                  <ButtonSpinner className={classes.buttonSpinner} />
                </Box>
              )}
            </>
          )}
        </form>
      </Dialog>
    </div>
  );
};

PremiumSubscriptionDialog.propTypes = {
  handleClose: PropTypes.func.isRequired, // function on close premuim subscription form
  isOpen: PropTypes.bool.isRequired,
};

export default PremiumSubscriptionDialog;
