import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { map } from 'lodash';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
// 3rd party components
import { Card, CardContent, Typography, Divider } from '@material-ui/core';
// selectors
import { getQuestion } from 'redux/selectors/assessmentSelectors';
// styles
import { useAssessmentStyles } from '../useAssessmentStyles';

const useStyles = makeStyles(theme => ({
  count: {
    color: theme.palette.indigo.main,
    fontSize: 12,
  },
  topAnswer: {
    color: theme.palette.indigo.main,
  },
  answer: {
    color: '#898989',
  },
}));

const MultipleChoiceQuestionResultsSummary = ({ questionId, withCard }) => {
  const commonClasses = useAssessmentStyles();
  const { answerCounts } = useSelector(getQuestion(questionId));
  const classes = useStyles({ answerCounts });

  const renderContent = () => (
    <div className="flex-1">
      {map(answerCounts, (answer, idx) => (
        <div key={`answer-${idx}`}>
          <div className="d-flex align-items-center">
            <Typography className={cx('mr-1', classes.count)}>{answer.count}</Typography>
            <div className={cx('mr-2', commonClasses.dot)} />
            <Typography
              className={cx('mr-1', {
                [`${classes.answer}`]: idx !== 0 || answer.count === 0,
                [`${classes.topAnswer}`]: idx === 0 && answer.count !== 0,
              })}
              style={{ fontSize: 15 }}
            >
              {answer.answer}
            </Typography>
          </div>
          {answerCounts.length !== idx + 1 && <Divider className="my-1" />}
        </div>
      ))}
    </div>
  );

  return (
    <>
      {withCard ? (
        <div style={{ maxWidth: 600 }} className={cx('mx-auto')}>
          <Card className={cx(commonClasses.card)}>
            <CardContent className="p-2 d-flex">{renderContent()}</CardContent>
          </Card>
        </div>
      ) : (
        renderContent()
      )}
    </>
  );
};

MultipleChoiceQuestionResultsSummary.propTypes = {
  questionId: PropTypes.string.isRequired,
  withCard: PropTypes.bool,
};

export default MultipleChoiceQuestionResultsSummary;
