import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Dialog, DialogContent, DialogTitle, Typography, Box } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { generateQR } from 'utils/generateQR';

const useStyles = makeStyles(theme => ({
  closeIcon: {
    position: 'relative',
    right: `-${theme.spacing(2)}px`,
    top: `-${theme.spacing(1)}px`,
  },
}));

/**
 * Renders a Dialog with a QR Code in it
 */
export default function QRDialog({ handleClose, isOpen, title, qrContent }) {
  const classes = useStyles();
  const [dataUrl, setDataUrl] = useState('');

  useEffect(() => {
    if (isOpen) {
      generateQR(qrContent)
        .then(qrCode => setDataUrl(qrCode))
        .catch(err => console.error(err));
    }
  }, [isOpen]);

  return (
    <div>
      <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle disableTypography className="pb-0">
          <div className="d-flex justify-content-between">
            <Typography variant="h6">{title}</Typography>
            <IconButton aria-label="close" onClick={handleClose} className={classes.closeIcon}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent className="pb-4">
          <Box justifyContent="center" display="flex">
            {dataUrl && <img src={dataUrl} height="300" width="300" />}
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

QRDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  title: PropTypes.string.isRequired,
  qrContent: PropTypes.string.isRequired,
};
