import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { map, size } from 'lodash';
import { makeStyles } from '@material-ui/styles';

import { Typography, Divider, MenuItem, Box } from '@material-ui/core';

import FormDialog from 'components/Dialogs/FormDialog';
import EllipsisMenu from 'components/EllipsisMenu';

import SessionWelcomeMessageAdmin from '../../../components/SessionWelcomeMessage/Admin/SessionWelcomeMessageAdmin';
import AdminSendToParticipantsActivity from 'components/AdminActivityTemplate/AdminSendToParticipantsActivity';

import { ACTIVITIES } from 'constants/activities';
// utils
import { renderHtmlStringSimple } from 'utils/renderHtmlString';
import { getFacilitatorVideoInstructions } from 'utils/getActivityConfig';

const useStyles = makeStyles(theme => ({
  title: {
    color: '#093B75',
    fontSize: 18,
    lineHeight: '30px',
  },
  videoBox: {
    position: 'relative',
    width: '100%',
    height: 0,
    paddingBottom: '62.5%',
    marginTop: theme.spacing(3),
  },
  video: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
}));

const Welcome = ({ sessionId }) => {
  const classes = useStyles();
  const [instructionsDialogOpen, setInstructionsDialogOpen] = useState(false);

  const session = useSelector(state => state.firestore.data.session);
  const { selectedFacilitatorRoute } = useSelector(state => state.facilitatorSession);

  const activeParticipantRoute = session?.activeRoute;

  const toggleInstructionsDialog = () => {
    setInstructionsDialogOpen(!instructionsDialogOpen);
  };

  const [videoInstruction, setVideoInstruction] = useState(
    ACTIVITIES.welcome?.facilitatorInstructionsVideo,
  );

  const activityHasInstructionsVideo = !!size(videoInstruction);

  /**
   * if the activity config doesn't contain video instructions,
   * then the video is loaded from the firebase storage. path - 'facilitatorInctructions/welcome'
   */
  const getVideoInstructions = useCallback(async () => {
    const result = await getFacilitatorVideoInstructions('welcome');
    if (result) {
      setVideoInstruction(result);
    }
  }, []);

  useEffect(() => {
    if (!activityHasInstructionsVideo) {
      getVideoInstructions();
    }
  }, [getVideoInstructions, activityHasInstructionsVideo]);

  const getEllipsisMenuItems = () => {
    const menuItems = [{ function: toggleInstructionsDialog, name: 'Instructions' }];

    return map(menuItems, (item, idx) => (
      <MenuItem key={`menu-item-${idx}`} onClick={item.function}>
        {item.name}
      </MenuItem>
    ));
  };

  return (
    <>
      <div className="d-flex flex-column justify-content-between">
        <div className="d-flex justify-content-between w-100">
          <Typography className={classes.title}>Welcome</Typography>
          <EllipsisMenu menuItems={getEllipsisMenuItems()} />
        </div>
        <Typography
          className="mb-1"
          color="textSecondary"
          style={{ fontSize: 12, lineHeight: '18px' }}
        >
          * Make sure to open the participant link in another browser window or tab to preview how
          this will appear for your participants
        </Typography>
      </div>
      <Divider className="mb-2" />
      <SessionWelcomeMessageAdmin sessionId={sessionId} />
      {activeParticipantRoute !== selectedFacilitatorRoute && (
        <AdminSendToParticipantsActivity
          className="mt-auto"
          session={session}
          activeFacilitatorRoute={selectedFacilitatorRoute}
        />
      )}

      {/* Instructions Dialog */}
      <FormDialog
        isOpen={instructionsDialogOpen}
        handleClose={toggleInstructionsDialog}
        isTitleHasCloseIcon={true}
        title="Welcome Instructions"
      >
        <Box className="mb-3">
          <Typography
            dangerouslySetInnerHTML={renderHtmlStringSimple(
              ACTIVITIES.welcome.facilitatorInstructions,
            )}
          />
          {activityHasInstructionsVideo && (
            <Box className={classes.videoBox}>
              <iframe
                title="facilitator-instructions"
                className={classes.video}
                src={videoInstruction}
                frameBorder="0"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allowFullScreen
              />
            </Box>
          )}
        </Box>
      </FormDialog>
    </>
  );
};

Welcome.propTypes = {
  sessionId: PropTypes.string.isRequired,
};

export default Welcome;
