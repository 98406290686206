import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { filter, delay, find } from 'lodash';
// library components
import { Box } from '@material-ui/core';
// cr components
import ActivityParticipantDetails from 'components/ActivityParticipantDetails';
import ActivityActionButton from 'components/CustomButtons/ActivityActionButton';
import ItemDescription from 'components/Giveaway/Admin/ItemDescription';
// selectors
import {
  useGiveawaySelector,
  getParticipantsCompletedWrite,
} from 'redux/selectors/giveawaySelectors';
// actions
import {
  updateActivityView,
  addWinnerParticipant,
  updateNumberWinner,
  addNotSelectingWinner,
  updateRevealResults,
} from 'redux/actions/giveawayActions';
// utils
import { makeParticipantDetailsHeaderText } from 'utils/makeParticipantDetailsHeaderText';
import { getRandomWinner } from 'utils/giveaway';

/**
 * Giveaway view for the 'write' phase on the Facilitator side
 */
const GiveawayAdminWrite = () => {
  const dispatch = useDispatch();
  const giveaway = useGiveawaySelector();
  const {
    id,
    config: { itemName, itemDescription, ofGiveaways },
    participantData: { giveawayParticipants },
  } = giveaway;
  const participantsHaveCreated = useSelector(getParticipantsCompletedWrite());
  const submittedParticipants = filter(participantsHaveCreated, { completed: true });
  const participantsHaveCreatedCount = submittedParticipants.length;

  const participantsCreatedCountText = makeParticipantDetailsHeaderText(
    participantsHaveCreatedCount,
    participantsHaveCreated.length,
    'registered',
  );

  // selects a participant from all partcipants who submitted form
  const getWinner = () => {
    const winnerParticipant = getRandomWinner(submittedParticipants);
    if (winnerParticipant) {
      dispatch(addWinnerParticipant(id, winnerParticipant, 1));
      if (ofGiveaways === 1) {
        const winnerParticipantData = find(
          giveawayParticipants,
          participant => participant.participantId === winnerParticipant,
        );
        const results = [
          {
            winner: true,
            round: 1,
            participantName: winnerParticipantData.participantName,
            participantEmail: winnerParticipantData.participantEmail,
          },
        ];
        dispatch(updateActivityView('results', id));
        dispatch(updateRevealResults(id, results));
      }
    } else {
      dispatch(addNotSelectingWinner(id, true));
    }
  };

  // select winner and update active view
  const onUpdateActivityView = () => {
    delay(getWinner, 5000);
    dispatch(updateNumberWinner(id, 1));
    dispatch(updateActivityView('pickWinner', id));
  };

  return (
    <Box className="d-flex flex-column justify-content-center">
      <ItemDescription itemName={itemName} itemDescription={itemDescription} />
      <ActivityParticipantDetails
        headerText={participantsCreatedCountText}
        hasCompletedLabel="Registered"
        hasntCompletedLabel="Hasn’t Registereed"
        participantsCompletedStatus={participantsHaveCreated}
      />
      <ActivityActionButton
        text="Pick Winner #1"
        className="mb-2 mt-1"
        onClick={onUpdateActivityView}
      />
    </Box>
  );
};

export default GiveawayAdminWrite;
