import React, { useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useDropzone } from 'react-dropzone';
import cx from 'classnames';
import { includes } from 'lodash';
// library components
import { Paper, Box, Typography } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CropImageForm from 'components/CropImageForm';
// cr components
import ButtonSpinner from 'components/ButtonSpinner/ButtonSpinner';
// utils
import { maybeConvertFromHeic } from 'utils/maybeConvertFromHeic';

const useStyles = makeStyles(theme => ({
  container: {
    width: 190,
    height: 140,
    padding: 10,
    border: '1px solid #D6D6D6',
    borderRadius: 2,
    background: '#E1E1E1',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    marginBottom: 16,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  addButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    border: '1px dashed #C3C3C3',
  },
  addButtonIcon: {
    fontSize: 30,
    color: theme.palette.primary.main,
  },
  title: {
    width: '100%',
    marginTop: 5,
    fontSize: 11,
  },
  dragActive: {
    border: `2px dashed #C0CFDC !important`,
    background: 'rgba(0,0,0,.1)',
  },
  buttonSpinnerBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 200,
    paddingRight: theme.spacing(5),
  },
  buttonSpinner: {
    color: theme.palette.primary.main,
  },
  input: {
    display: 'none',
  },
}));

/**
 * Renders add image view
 */
const NewImage = ({ uploadNewImage, isLoading }) => {
  const inputRef = useRef();
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [fileUrl, setFileUrl] = useState();

  const closeDialog = () => {
    inputRef.current.value = '';
    setIsOpen(false);
  };

  // Participant upload new image. Read image file and return first file
  const onDrop = useCallback(async acceptedFiles => {
    if (includes(acceptedFiles[0].type, 'image')) {
      if (acceptedFiles && acceptedFiles.length > 0) {
        let imageBlob = await maybeConvertFromHeic(acceptedFiles[0]);

        if (!(imageBlob instanceof Blob)) {
          return setIsOpen(false);
        }

        const reader = new FileReader();
        reader.addEventListener('load', () => setFileUrl(reader.result));
        reader.readAsDataURL(imageBlob);
      }
      return setIsOpen(true);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <>
      <CropImageForm
        closeDialog={closeDialog}
        isOpen={isOpen}
        fileUrl={fileUrl}
        uploadNewImage={uploadNewImage}
        cropParam={{
          aspect: 16 / 9,
          width: 100,
        }}
      />
      <div {...getRootProps()}>
        <input
          {...getInputProps()}
          accept="image/*"
          className={classes.input}
          id="contained-button-file"
          type="file"
          ref={inputRef}
        />
        <label htmlFor="contained-button-file">
          <Paper
            className={cx(classes.container, isDragActive && classes.dragActive)}
            variant="outlined"
          >
            {isLoading ? (
              <Box className={classes.buttonSpinnerBox}>
                <ButtonSpinner className={classes.buttonSpinner} />
              </Box>
            ) : (
              <Box className={classes.addButtonContainer}>
                <AddCircleIcon fontSize="large" className={classes.addButtonIcon} />
                <Typography className={classes.title} align="center" color="primary">
                  Add Image
                </Typography>
              </Box>
            )}
          </Paper>
        </label>
      </div>
    </>
  );
};

NewImage.propTypes = {
  uploadNewImage: PropTypes.func, // upload new participant image
  isLoading: PropTypes.bool, // loading new image
};

export default NewImage;
