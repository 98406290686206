import React from 'react';
import PropTypes from 'prop-types';
import { split, startCase } from 'lodash';
import { useFirestore } from 'react-redux-firebase';
import mixpanel from 'mixpanel-browser';
import { makeStyles } from '@material-ui/core/styles';
// library components
import { Typography, Divider, Box, Button } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';

const useStyles = makeStyles(theme => ({
  errorIcon: {
    marginRight: 4,
    fontSize: 24,
    color: theme.palette.warning.main,
  },
  errorText: {
    fontSize: 14,
    lineHeight: '24px',
    color: '#333333',
  },
  divider: {
    position: 'relative',
    left: -theme.spacing(4),
    width: `calc(100% + ${theme.spacing(8)}px)`,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  sendButton: {
    paddingRight: 0,
    marginLeft: 'auto',
    color: theme.palette.primary.main,
    fontSize: 15,
    fontWeight: 400,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
}));

const AdminSendToParticipantsActivity = ({ session, activeFacilitatorRoute, className }) => {
  const classes = useStyles();
  const db = useFirestore();

  const setParticipantActivity = () => {
    db.collection('sessions')
      .doc(`${session.id}`)
      .update({
        activeRoute: activeFacilitatorRoute,
      })
      .then(() => {
        if (process.env.REACT_APP_HOSTING_ENV === 'prod') {
          let activityName = startCase(split(activeFacilitatorRoute, '/')[0]);

          mixpanel.track('Set Activity', { activity: activityName });
        }
      });
  };

  return (
    <Box className={className}>
      <Divider className={classes.divider} />
      <Box className="d-flex align-items-center">
        <ErrorIcon className={classes.errorIcon} />
        <Typography className={classes.errorText}>
          Participants are not viewing this activity
        </Typography>
        <Button className={classes.sendButton} onClick={setParticipantActivity}>
          Send to Participants
        </Button>
      </Box>
    </Box>
  );
};

AdminSendToParticipantsActivity.propTypes = {
  session: PropTypes.object.isRequired,
  activeFacilitatorRoute: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default AdminSendToParticipantsActivity;
