import React from 'react';
import { map } from 'lodash';
// cr components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import NoteCard from './NoteCard';
// selectors
import { usePollingSelector } from 'redux/selectors/pollingSelectors';

/**
 * Polling view for the 'results' phase on the Participant side
 */
const PollingParticipantResults = () => {
  const { output } = usePollingSelector();

  const renderResults = () =>
    map(output, (note, idx) => (
      <GridItem key={`results-${idx}`} xs={12} sm={6} md={4} lg={4} xl={4}>
        <NoteCard
          note={{
            ...note,
            value: note.text,
          }}
          context="results"
        />
      </GridItem>
    ));

  return (
    <>
      <GridContainer direction="row" className="mb-4">
        {renderResults()}
      </GridContainer>
    </>
  );
};

export default PollingParticipantResults;
