import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// library components
import { Box } from '@material-ui/core';
// cr components
import ActivityPromptAdmin from 'components/ActivityPromptAdmin';
import ActivityParticipantDetails from 'components/ActivityParticipantDetails';
// selectors
import { useHowMightWeSelector } from 'redux/selectors/howMightWeSelectors';
// config
import { HOW_MIGHT_WE_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';

const useStyles = makeStyles(theme => ({
  promptAdmin: {
    marginBottom: theme.spacing(1),
  },
}));

/**
 * HMW view for the 'results' phase on the Facilitator side
 */
const HowMightWeAdminResults = () => {
  const classes = useStyles();
  const howMightWe = useHowMightWeSelector();
  const { id, prompt } = howMightWe;

  return (
    <Box className="d-flex flex-column justify-content-center">
      <ActivityPromptAdmin
        prompt={prompt}
        collection={CONFIG.collection}
        activityId={id}
        className={classes.promptAdmin}
      />
      <ActivityParticipantDetails headerText="Reviewing voting results." noDetails />
    </Box>
  );
};

export default HowMightWeAdminResults;
