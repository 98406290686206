import React, { useEffect, useRef, useMemo } from 'react';
import { Chart } from 'react-google-charts';
import { useSelector } from 'react-redux';
import { isEmpty } from 'react-redux-firebase';
import { forEach, groupBy } from 'lodash';

//selectors
import { selectSchedules } from 'redux/selectors/staffing/getSchedules';

const SchedulesChart = () => {
  const chartRef = useRef(null);
  const getSchedules = selectSchedules();
  const { schedules, selectedScheduleStartDate, selectedScheduleEndDate, selectedDateRange } =
    useSelector(state => getSchedules(state));

  const chartHeight = useMemo(() => {
    const groupedSchedules = groupBy(schedules, schedule => schedule[0]); // Group by contractor

    const processedSchedules = Object.entries(groupedSchedules).flatMap(
      ([contractor, contractorSchedules]) => {
        const sortedSchedules = contractorSchedules.sort((a, b) => new Date(a[2]) - new Date(b[2]));
        const processedContractorSchedules = [];

        sortedSchedules.forEach((schedule, index) => {
          if (
            index === 0 ||
            new Date(schedule[2]) >=
              new Date(processedContractorSchedules[processedContractorSchedules.length - 1][3])
          ) {
            processedContractorSchedules.push(schedule);
          }
        });

        return processedContractorSchedules;
      },
    );

    // Calculate height based on number of rows in processedSchedules
    const rowHeight = 51; // Approximate height of each row
    const headerHeight = 50; // Approximate height of the header
    const minHeight = 200; // Minimum height of the chart
    return Math.max(minHeight, processedSchedules.length * rowHeight + headerHeight);
  }, [schedules]);

  const isOneDaySchedule = useMemo(() => {
    if (schedules.length === 0) return false;
    const firstDay = new Date(schedules[0][2]).setHours(0, 0, 0, 0);
    return schedules.every(schedule => new Date(schedule[2]).setHours(0, 0, 0, 0) === firstDay);
  }, [schedules]);

  const chartEvents = [
    {
      eventName: 'ready',
      callback: ({ chartWrapper }) => {
        const chart = chartWrapper.getChart();
        const container = chart.container;
        const labels = container.getElementsByTagName('text');
        forEach(labels, function (label) {
          // Using lodash forEach
          label.setAttribute('font-weight', 'normal');

          // Check if this label represents the selected date
          forEach(selectedDateRange, rangeDate => {
            // Using lodash forEach
            //console.log('checking date in range', rangeDate.format('M/D'));
            if (label.textContent === rangeDate.format('M/D')) {
              label.setAttribute('font-weight', 'bold');
              label.textContent = `[${label.textContent}]`;
            }
          });
        });
      },
    },
  ];

  if (isEmpty(schedules))
    return (
      <div className="py-5 d-flex align-items-center justify-content-center">
        <p>
          No schedules were found for {selectedScheduleStartDate.format('MMMM D, YYYY')}{' '}
          {selectedScheduleEndDate ? `to ${selectedScheduleEndDate.format('MMMM D, YYYY')}` : ''}.
        </p>
      </div>
    );

  return (
    <div ref={chartRef} style={{ height: '100%', width: '100%', position: 'relative' }}>
      <Chart
        width={'100%'}
        height={'100%'}
        chartType="Timeline"
        loader={<div>Loading Schedules</div>}
        data={[
          [
            { type: 'string', id: 'Contractor' },
            { type: 'string', id: 'Project' },
            { type: 'date', id: 'Start' },
            { type: 'date', id: 'End' },
          ],
          ...schedules, // Use original schedules for chart data
        ]}
        options={{
          timeline: {
            colorByRowLabel: true,
          },
          height: chartHeight, // Use the height calculated from processedSchedules
          avoidOverlappingGridLines: false,
          hAxis: isOneDaySchedule
            ? {
                format: 'h a',
                textStyle: {
                  fontSize: 11,
                  bold: false,
                },
                gridlines: {
                  count: 24,
                  units: {
                    hours: { format: ['h a'] },
                  },
                },
                minorGridlines: {
                  count: 4,
                },
              }
            : {
                format: 'M/d',
                textStyle: {
                  fontSize: 11,
                  bold: false,
                },
                gridlines: {
                  count: -1,
                  units: {
                    days: { format: ['M/d'] },
                  },
                },
                minorGridlines: {
                  count: 0,
                },
              },
        }}
        chartEvents={chartEvents}
      />
    </div>
  );
};

export default SchedulesChart;
