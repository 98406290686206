import React from 'react';
// library components
import { makeStyles } from '@material-ui/core/styles';
// cr components
import ActivityPromptAdmin from 'components/ActivityPromptAdmin';
// selectors
import { useUserJourneyMomentsSelector } from 'redux/selectors/userJourneyMomentsSelectors';
// config
import { USER_JOURNEY_MOMENTS_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';

const useStyles = makeStyles(theme => ({
  promptAdmin: {
    marginBottom: theme.spacing(5),
  },
}));

/**
 * User Journey Moments view for the 'results' phase on the Facilitator side
 */
const UserJourneyMomentsAdminResults = () => {
  const classes = useStyles();
  const userJourneyMoments = useUserJourneyMomentsSelector();
  const { id, prompt } = userJourneyMoments;

  return (
    <ActivityPromptAdmin
      prompt={prompt}
      collection={CONFIG.collection}
      activityId={id}
      className={classes.promptAdmin}
    />
  );
};

export default UserJourneyMomentsAdminResults;
