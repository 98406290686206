import React, { useState, useEffect } from 'react';
import ReactCursorPosition from 'react-cursor-position';
import PropTypes from 'prop-types';
import { isEmpty, map, keys, cloneDeep, round } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import cx from 'classnames';
// library components
import Box from '@material-ui/core/Box';
// cr components
import DraggablePoint from './DraggablePoint';
import ButtonSpinner from 'components/ButtonSpinner/ButtonSpinner';

const pointRadius = 10;

const useStyles = makeStyles(theme => ({
  box: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  img: {
    width: '100%',
  },
  buttonSpinnerBox: {
    position: 'absolute',
    top: '45%',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  buttonSpinner: {
    color: theme.palette.primary.main,
  },
}));

/**
 * Renders component, wich on click to image will add point
 */
const ClickerImage = ({
  img,
  imageId,
  className,
  getPoints,
  imagePoints,
  disable,
  classNameImage,
  imageElementId,
  isPointsHidden = false,
}) => {
  const classes = useStyles();
  const [pos, setPos] = useState(null);
  const [points, setPoint] = useState({ ...imagePoints });
  const [currentDragId, setCurrentDragId] = useState();

  const heatMappingMaxWidth = 715;
  const heatMappingMaxHeight = 536;
  const heatMappingImage = document.getElementById(imageElementId);
  const [heatMappingCoeff, setHeatMappingCoeff] = useState(
    heatMappingMaxWidth /
      (heatMappingImage?.clientWidth === 0 ? heatMappingMaxWidth : heatMappingImage?.clientWidth),
  );
  const [heatMappingCoeffHeight, setHeatMappingCoeffHeight] = useState(
    heatMappingMaxHeight /
      (heatMappingImage?.clientHeight === 0
        ? heatMappingMaxHeight
        : heatMappingImage?.clientHeight),
  );

  useEffect(() => {
    const handleResize = () => {
      setHeatMappingCoeff(
        heatMappingMaxWidth / document.getElementById(imageElementId)?.clientWidth,
      );
      setHeatMappingCoeffHeight(
        heatMappingMaxHeight / document.getElementById(imageElementId)?.clientHeight,
      );
    };
    setHeatMappingCoeff(
      heatMappingMaxWidth /
        (document.getElementById(imageElementId)?.clientWidth === 0
          ? heatMappingMaxHeight
          : document.getElementById(imageElementId)?.clientWidth),
    );
    setHeatMappingCoeffHeight(
      heatMappingMaxHeight /
        (document.getElementById(imageElementId)?.clientHeight === 0
          ? heatMappingMaxHeight
          : document.getElementById(imageElementId)?.clientHeight),
    );
    window.addEventListener('resize', handleResize);
  });

  const [isLoading, setIsLoading] = useState(
    heatMappingImage?.clientWidth === 0 || heatMappingImage?.clientHeight === 0,
  );

  useEffect(() => {
    setPoint({ ...imagePoints });
    setIsLoading(true);
  }, [imageId]);

  // Add cursor position
  const addPos = currentPosition => {
    if (!isEmpty(currentPosition) && !isEmpty(currentPosition.position)) {
      setPos(currentPosition.position);
    }
  };

  // Handle add or delete new point
  const addPoint = () => {
    if (!isEmpty(pos) && !disable && !isLoading) {
      const top = round((pos.y - pointRadius) * heatMappingCoeffHeight);
      const left = round((pos.x - pointRadius) * heatMappingCoeff);
      const newPoint = {
        ...points,
        [`point-${top}-${left}`]: {
          top,
          left,
          coeffWidth: heatMappingCoeff,
          coeffHeight: heatMappingCoeffHeight,
        },
      };
      setPoint(newPoint);
      getPoints(imageId, newPoint);
    }
  };

  // Get draggable element id
  const getElementId = elementId => {
    setCurrentDragId(elementId);
  };

  // If d&d end, remove element
  const onDragEnd = () => {
    if (!currentDragId) {
      return;
    }

    const currentPoints = cloneDeep(points);
    delete currentPoints[currentDragId];
    setPoint(currentPoints);
    getPoints(imageId, currentPoints);
  };

  const filterPoints = map(points, point => point);

  const onImageLoad = () => {
    setHeatMappingCoeff(
      heatMappingMaxWidth /
        (document.getElementById(imageElementId)?.clientWidth === 0
          ? heatMappingMaxHeight
          : document.getElementById(imageElementId)?.clientWidth),
    );
    setHeatMappingCoeffHeight(
      heatMappingMaxHeight /
        (document.getElementById(imageElementId)?.clientHeight === 0
          ? heatMappingMaxHeight
          : document.getElementById(imageElementId)?.clientHeight),
    );
    setIsLoading(false);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <ReactCursorPosition onPositionChanged={addPos}>
        <Box className={classes.box} onClick={addPoint}>
          <img
            id={imageElementId}
            className={cx(classes.img, classNameImage)}
            alt="sketch"
            src={img}
            onLoad={onImageLoad}
          />
          <Droppable droppableId={`${keys(imagePoints).length}`} type="points">
            {(provided, snapshot) => (
              <div
                id="points"
                style={isPointsHidden ? { display: 'none' } : {}}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {isLoading ? (
                  <Box className={classes.buttonSpinnerBox}>
                    <ButtonSpinner className={classes.buttonSpinner} />
                  </Box>
                ) : (
                  !isEmpty(points) &&
                  map(filterPoints, (point, idx) => (
                    <DraggablePoint
                      key={`point-${point.top}-${point.left}`}
                      id={`point-${point.top}-${point.left}`}
                      point={point}
                      disable={disable}
                      className={className}
                      getElementId={getElementId}
                      coeff={heatMappingCoeff ? heatMappingCoeff : 1}
                      coeffHeight={heatMappingCoeffHeight ? heatMappingCoeffHeight : 1}
                      idx={idx}
                    />
                  ))
                )}
                <div style={{ display: 'none' }}>{provided.placeholder}</div>
              </div>
            )}
          </Droppable>
        </Box>
      </ReactCursorPosition>
    </DragDropContext>
  );
};

ClickerImage.propTypes = {
  img: PropTypes.string.isRequired, // image url
  imageId: PropTypes.string,
  className: PropTypes.string, // class name style for point
  getPoints: PropTypes.func, // function for getting participant points
  imagePoints: PropTypes.object, // participant image points
  disable: PropTypes.bool,
  imageElementId: PropTypes.string, // the html element id
  classNameImage: PropTypes.string, // class name style for image
};

export default ClickerImage;
