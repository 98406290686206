import React from 'react';
import { useSelector } from 'react-redux';

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Typography from '@material-ui/core/Typography';

//cr components
import PageContent from 'components/PageContent/PageContent';
import PageContentHeader from 'components/PageContent/PageContentHeader';
import PageContentBody from 'components/PageContent/PageContentBody';
import SchedulesChart from 'components/Staffing/Schedules/SchedulesChart';
import SchedulesFilter from 'components/Staffing/Schedules/SchedulesFilter';

//actions & selectors
import { selectSchedules } from 'redux/selectors/staffing/getSchedules';

const ProjectsList = () => {
  const getSchedules = selectSchedules();
  const { isLoaded } = useSelector(state => getSchedules(state));

  if (!isLoaded) {
    return null;
  }

  return (
    <div>
      <GridContainer direction="row" justify="center" alignItems="center">
        <GridItem xs={12}>
          <PageContent>
            <PageContentHeader>
              <Typography variant="h5" className="page-content-title">
                Schedules
              </Typography>
            </PageContentHeader>
            <PageContentBody>
              <SchedulesFilter />
              <SchedulesChart />
            </PageContentBody>
          </PageContent>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default ProjectsList;
