import React from 'react';
import PropTypes from 'prop-types';

import useDelayedRender from '../../hooks/useDelayedRender';

const DelayedRender = ({ delay, children }) => useDelayedRender(delay)(() => children);

DelayedRender.defaultProps = {
  delay: 1000,
};

DelayedRender.propTypes = {
  delay: PropTypes.number,
  children: PropTypes.node,
};

export default DelayedRender;
