import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFirebase } from 'react-redux-firebase';
import { MURAL_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';
import * as Sentry from '@sentry/react';
// library components
import { Box, Button, Typography } from '@material-ui/core';
// cr components
import ButtonSpinner from 'components/ButtonSpinner';
import ActivityPromptAdmin from 'components/ActivityPromptAdmin';
// selectors
import { useMuralSelector } from 'redux/selectors/muralSelectors';
// action
import { enqueueErrorSnackbar } from 'redux/actions/notifierActions';

/**
 * Mural view for the 'write' phase on the Facilitator side
 */
const MuralAdminMural = () => {
  const firebase = useFirebase();
  const dispatch = useDispatch();
  const user = useSelector(state => state.firebase.profile);
  const [showButtonSpinner, setShowButtonSpinner] = useState(false);
  const {
    id,
    prompt,
    config: { muralUrl },
  } = useMuralSelector();

  const getAuthUrl = () => {
    setShowButtonSpinner(true);

    firebase
      .functions()
      .httpsCallable('mural-getAuthUrl')({ redirectUrl: window.location.href })
      .then(res => {
        window.location.replace(res.data);
      })
      .catch(err => {
        Sentry.captureException(err);
        console.error(err);

        let message = err.message;

        if (message.includes(' - ')) {
          message = message.split(' - ')[1];
        }

        message = `Mural returned the following error: ${message}`;

        dispatch(enqueueErrorSnackbar(message, { autoHideDuration: 10000 }));
      });
  };

  return (
    <Box className="d-flex flex-column justify-content-center">
      <ActivityPromptAdmin
        prompt={prompt}
        collection={CONFIG.collection}
        activityId={id}
        className="mb-5"
      />
      {!user.isMuralAuthorized && (
        <Box display="flex" justifyContent="center" flexDirection="column">
          <Typography className="mb-3 text-center mx-auto" style={{ maxWidth: 500 }}>
            You must authorize your Mural account for use with Control Room before using the Mural
            for Control Room activity.
          </Typography>
          <Button
            disabled={showButtonSpinner}
            variant="contained"
            color="primary"
            className="align-self-center"
            onClick={getAuthUrl}
          >
            {!showButtonSpinner && 'Authorize Mural Account'}
            {showButtonSpinner && <ButtonSpinner className="ml-0" />}
          </Button>
        </Box>
      )}
      {user.isMuralAuthorized && (
        <Typography className="text-center dont-break-out" color="textSecondary">
          <a
            href={muralUrl}
            target="_blank"
            rel="noopner noreferrer"
            className="text-reset pointer-underline-hover"
          >
            {muralUrl}
          </a>
        </Typography>
      )}
    </Box>
  );
};

export default MuralAdminMural;
