import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFirestore } from 'react-redux-firebase';
import moment from 'moment';
import 'moment-duration-format';
import mixpanel from 'mixpanel-browser';

import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';

const RemainingTime = ({ remainingTime }) => {
  const renderedTimer =
    remainingTime > 60
      ? moment.duration(remainingTime, 'seconds').format('m:ss')
      : moment()
          .utcOffset(0)
          .set({ hour: 0, minute: 0, second: remainingTime, millisecond: 0 })
          .format('m:ss');

  return (
    <div className="timer">
      <div className="value" style={{ fontSize: 18 }}>
        {renderedTimer}
      </div>
    </div>
  );
};

const AdminTimer = ({ session }) => {
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const smallScreen = useMediaQuery('(max-width: 760px)');
  const [durationSeconds, setDurationSeconds] = useState(0);
  const timerValue = session.timerValue || 0;
  const timerRunning = session.timerRunning || false;
  const timerStartedAt = session.timerStartedAt || 0;
  const db = useFirestore();
  const [anchorEl, setAnchorEl] = useState(null);
  const popoverOpen = Boolean(anchorEl);
  const popoverId = popoverOpen ? 'simple-popover' : undefined;

  useEffect(() => {
    let duration =
      timerRunning && timerValue
        ? timerValue - (db.Timestamp.now().seconds - timerStartedAt)
        : timerValue;

    if (duration < 0) {
      duration = 0;
    }

    setDurationSeconds(duration);
  }, [timerValue, timerStartedAt, timerRunning]);

  const getTimeFromState = () => {
    return +minutes * 60 + +seconds;
  };

  const setSessionTimer = async (timerMinutes = 0, timerSeconds = 0) => {
    const actualSeconds = +timerMinutes * 60 + +timerSeconds;

    await db.doc(`sessions/${session.id}`).update({
      timerValue: actualSeconds,
      timerRunning,
      timerStartedAt: timerRunning ? db.Timestamp.now().seconds : timerStartedAt,
    });

    if (process.env.REACT_APP_HOSTING_ENV === 'prod') {
      mixpanel.track('Set Timer', {
        duration: moment.duration(actualSeconds, 'seconds').format('m:ss'),
      });
    }
  };

  const startSessionTimer = async () => {
    const fbTime = db.Timestamp.now().seconds;
    const systemTime = moment().unix();

    console.log('startSessionTimer', {
      fbTime,
      systemTime,
    });

    await db.doc(`sessions/${session.id}`).update({
      timerRunning: true,
      timerStartedAt: fbTime,
    });
  };

  const cancelSessionTimer = async () => {
    await db.doc(`sessions/${session.id}`).update({
      timerRunning: false,
      timerValue: getTimeFromState(),
      timerStartedAt: 0,
    });
  };

  const timerEnded = () => {
    setTimeout(() => {
      db.doc(`sessions/${session.id}`).update({
        timerValue: 0,
        timerStartedAt: 0,
        timerRunning: false,
      });
    }, 1000);
  };

  const onComplete = () => {
    timerEnded();
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={`d-flex flex-column ${smallScreen && 'my-4'}`}>
      <ButtonBase onClick={handleClick} style={{ borderRadius: '50%' }}>
        <CountdownCircleTimer
          key={timerStartedAt + durationSeconds}
          isPlaying={timerRunning}
          duration={durationSeconds}
          colors={[['#093B75', 0.0001], ['#02A05C', 0.89], ['#02A05C', 0.01], ['#E2351E']]}
          onComplete={onComplete}
          size={80}
          strokeWidth={5}
          strokeLinecap="square"
        >
          <RemainingTime />
        </CountdownCircleTimer>
      </ButtonBase>
      {!timerRunning && (
        <Button
          onClick={startSessionTimer}
          className="align-self-center"
          color="primary"
          style={{ zIndex: 1 }}
        >
          Start
        </Button>
      )}
      {timerRunning && (
        <Button
          onClick={cancelSessionTimer}
          className="align-self-center"
          color="primary"
          style={{ zIndex: 1 }}
        >
          Stop
        </Button>
      )}

      <Popover
        id={popoverId}
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0,0,0,.5)',
          },
        }}
      >
        <div className="pt-3 pl-3 pr-3 pb-1">
          <div className="d-flex align-items-end mb-2">
            <TextField
              autoFocus
              name="name"
              type="number"
              label="Minutes"
              value={minutes}
              onChange={event => setMinutes(event.target.value)}
              InputProps={{
                style: { maxWidth: 44 },
              }}
              inputProps={{
                min: '0',
                onFocus: event => event.target.select(),
              }}
            />
            <Typography className="mx-3">:</Typography>
            <TextField
              name="seconds"
              type="number"
              label="Seconds"
              inputProps={{ max: '60', min: '0' }}
              value={seconds}
              onChange={event => setSeconds(event.target.value)}
              InputProps={{
                style: { maxWidth: 44 },
              }}
            />
          </div>
          <div className="d-flex justify-content-center">
            <Button
              color="primary"
              onClick={() => {
                setSessionTimer(minutes, seconds);
                setAnchorEl(null);
              }}
            >
              Set
            </Button>
            {!timerRunning && (
              <Button
                color="primary"
                onClick={() => {
                  setSessionTimer(minutes, seconds);
                  startSessionTimer();
                  setAnchorEl(null);
                }}
              >
                Start
              </Button>
            )}
          </div>
        </div>
      </Popover>
    </div>
  );
};

AdminTimer.propTypes = {
  session: PropTypes.object.isRequired,
};

export default AdminTimer;
