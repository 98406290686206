import React from 'react';
import { map, filter } from 'lodash';
import { useSelector } from 'react-redux';
// library components
import { makeStyles } from '@material-ui/core';
// cr components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import CardResult from './CardResult';
// selectors
import {
  useNarrowingSelector,
  getReviewRoundCards,
  getCardsReport,
} from 'redux/selectors/narrowingSelectors';

const useStyles = makeStyles(theme => ({
  categoriesContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: 'calc(100% + 20px)',
  },
  gridContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: 'calc(100% + 10px)',
    margin: '0 -5px',
  },
  gridItem: {
    padding: `0 5px !important`,
  },
  successColor: {
    color: theme.palette.success.main,
  },
  categorizedCard: {
    width: 95,
    height: 115,
    border: '1px dashed #CFCFCF',
    borderRadius: 4,
    background: '#F9F9F9',
    marginBottom: 10,
  },
}));

/**
 * Card Sorting view for the 'results' (next rounds) phase on the Participant side
 */
const NarrowingParticipantResults = () => {
  const classes = useStyles();
  const {
    state: { activeView, round, isActivityFinished },
  } = useNarrowingSelector();

  const currentRound = `round${round}`;

  // get participant cards from primary category
  const cards = useSelector(getReviewRoundCards(currentRound));

  const finishedCards = useSelector(getCardsReport());

  // render cards
  const renderCards = () => {
    const resultCatds = isActivityFinished ? filter(finishedCards, { finalRound: true }) : cards;
    return map(resultCatds, (card, idx) => {
      return (
        <GridItem className={classes.gridItem} key={`card-${idx}`}>
          <CardResult card={card} context={activeView} />
        </GridItem>
      );
    });
  };

  return (
    <GridContainer className={classes.gridContainer} direction="row" spacing={3}>
      {renderCards()}
    </GridContainer>
  );
};

export default NarrowingParticipantResults;
