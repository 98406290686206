import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';

// material ui core components
import Button from '@material-ui/core/Button';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

//staffing components
import ContractorJobs from 'components/Staffing/Contractors/ContractorJobs/ContractorJobs';
import AboutContractorCard from 'components/Staffing/Contractors/AboutContractorCard/AboutContractorCard';
import CreateContractorDialog from 'components/Dialogs/Staffing/CreateContractorDialog';
import AddContractorToJobDialog from 'components/Dialogs/Staffing/AddContractorToJobDialog';

//actions & selectors
import { selectContractor } from 'redux/selectors/staffing/getContractor';
import { openAddContractorToJobDialog } from 'redux/actions/staffingActions';

const ContractorDetail = () => {
  const dispatch = useDispatch();
  const { contractorId } = useParams();

  const getContractor = selectContractor();
  const contractor = useSelector(state => getContractor(state, contractorId));

  if (!contractor) {
    return null;
  }
  const { displayName, contractorJobs } = contractor;

  return (
    <div className="w-100 shadow mt-2 mb-2 bg-white rounded">
      <div className="d-flex justify-content-between align-items-center border-bottom px-2 py-1">
        <Button
          variant="text"
          className="d-flex align-items-center"
          color="primary"
          component={Link}
          to="/admin/staffing/contractors"
        >
          <ArrowBackIosIcon fontSize="small" />
          <p className="m-0 text-uppercase">Back to staffing</p>
        </Button>
        <h4 className="font-weight-bold ">{displayName}</h4>
        <Button
          variant="contained"
          color="primary"
          className="text-uppercase px-2"
          size="small"
          onClick={() => {
            dispatch(openAddContractorToJobDialog(true, contractorId));
          }}
        >
          <p className="mb-0 text-uppercase">Add to job</p>
        </Button>
      </div>
      <div className="w-100 p-3">
        <AboutContractorCard contractor={contractor} />
      </div>

      <ContractorJobs jobs={contractorJobs} employee={contractor.staffType === 'Employee'} />
      <CreateContractorDialog />
      <AddContractorToJobDialog />
    </div>
  );
};

ContractorDetail.propTypes = {
  contractorId: PropTypes.string,
};

export default ContractorDetail;
