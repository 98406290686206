import {
  PARTICIPANT_SESSION_VIEW,
  PARTICIPANT_UPDATING,
  PARTICIPANT_UPDATED,
  PARTICIPANT_SLIDES_VIEW,
  SHOW_PARTICIPANT_APP_OVERLAY_SPINNER,
} from '../types/participantSessionTypes';

const initialState = {
  selectedView: 'activity',
  updating: false,
  slidesSessions: {},
  showAppOverlaySpinner: false,
};

export default function participantSessionReducer(state = initialState, action) {
  switch (action.type) {
    case PARTICIPANT_SESSION_VIEW:
      return { ...state, selectedView: action.payload };
    case PARTICIPANT_UPDATING:
    case PARTICIPANT_UPDATED:
      return { ...state, ...action.payload };
    case PARTICIPANT_SLIDES_VIEW:
      return {
        ...state,
        slidesSessions: {
          ...state.slidesSessions,
          [action.payload.slidesId]: {
            currentSlide: action.payload.slideIdx,
          },
        },
      };
    case SHOW_PARTICIPANT_APP_OVERLAY_SPINNER:
      return { ...state, showAppOverlaySpinner: action.payload };

    default:
      return state;
  }
}
