import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

// core material components
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import MoreVert from '@material-ui/icons/MoreVert';
import { Box, makeStyles } from '@material-ui/core';
import Spinner from 'components/Spinner';

// makeStyles
const useStyles = makeStyles({
  main: {
    position: 'absolute',
    right: '0px',
    top: '0px',
  },
});

const SelectMenu = ({ options, menuContainerClassName, menuBtnClassName, isVerticalIcon }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={cx(classes.main, menuContainerClassName)}>
      <IconButton className={menuBtnClassName} onClick={handleClick}>
        {isVerticalIcon ? <MoreVert /> : <MoreHoriz />}
      </IconButton>

      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {options.map((option, idx) => (
          <MenuItem
            disabled={option.disabled}
            key={idx.toString()}
            onClick={() => {
              option.onItemClick();
              setAnchorEl(null);
            }}
          >
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              {option.title}
              {option.loading && (
                <Box ml={1} style={{ opacity: '0.5' }}>
                  <Spinner size={14} color="inherit" />
                </Box>
              )}
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

SelectMenu.propTypes = {
  options: PropTypes.array.isRequired,
  menuContainerClassName: PropTypes.string,
  menuBtnClassName: PropTypes.string,
};

export default SelectMenu;
