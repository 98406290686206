import React from 'react';
import { sortBy, find } from 'lodash';
import { useSelector } from 'react-redux';
// library components
import { Box, makeStyles } from '@material-ui/core';
// cr components
import JourneyVote from 'components/UserJourneyMoments/Participant/JourneyVote';
// selectors
import {
  useUserJourneyMomentsSelector,
  getSuperVotes,
} from 'redux/selectors/userJourneyMomentsSelectors';

const useStyles = makeStyles(theme => ({
  cardSuperVoted: {
    background: '#F0FEE5',
    border: `3px solid ${theme.palette.success.main}`,
  },
}));

/**
 * User Journey Moments view for the 'results' phase on the Participant side
 */
const UserJourneyMomentsParticipantResults = () => {
  const classes = useStyles();
  const {
    participantData: { journeys },
    state: { activeView },
  } = useUserJourneyMomentsSelector();
  // sorted participants journey by participant id
  const sortedJourneys = sortBy(journeys, 'participantId');

  const superVoteTypes = useSelector(getSuperVotes());

  const isActorSelect = find(superVoteTypes, voteType => voteType.type === 'actor');
  const isMomentSelect = find(superVoteTypes, voteType => voteType.type !== 'actor');

  const actor = find(sortedJourneys, journey => journey.participantId === isActorSelect?.id);
  const moment = find(sortedJourneys, journey => journey.participantId === isMomentSelect?.id);
  const superVote = {
    ['participantId']: actor?.participantId,
    ['actor']: actor?.values.actor,
    ['moment']: moment?.values[isMomentSelect.type],
  };

  return (
    <>
      <Box
        className="w-100 mb-8"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexWrap="wrap"
      >
        <JourneyVote
          journeyId={superVote.participantId}
          keyMoment={isMomentSelect?.type}
          keyMomentTitle={superVote.moment}
          actor={superVote.actor}
          type="actor"
          context={activeView}
          isFinished={true}
          momentVotesCount={isMomentSelect ? 1 : 0}
          actorVotesCount={isActorSelect ? 1 : 0}
          superVoteTypes={superVoteTypes}
          superVotedClassName={classes.cardSuperVoted}
        />
      </Box>
    </>
  );
};

export default UserJourneyMomentsParticipantResults;
