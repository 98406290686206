import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { filter, keys } from 'lodash';
import { makeStyles } from '@material-ui/styles';
import cx from 'classnames';
// library components
import { Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
// cr components
import Grouping from './Grouping';
import DottedProgress from 'components/DottedProgress';
// selectors
import {
  useHowMightWeSelector,
  getVotedParticipantIdeas,
} from 'redux/selectors/howMightWeSelectors';
// actions
import { addParticipantVote, removeParticipantVote } from 'redux/actions/howMightWeActions';

const useStyles = makeStyles(theme => ({
  successColorText: {
    color: theme.palette.neutral.main,
  },
  checkIcon: {
    marginTop: theme.spacing(3),
  },
}));

/**
 * HMW view for the 'vote' phase on the Participant side
 */
const HowMightWeParticipantVote = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const {
    id,
    config: { votes },
    participantData: { groups, ideas },
    state: { activeView },
  } = useHowMightWeSelector();
  const participantId = useSelector(state => state.firebase.auth.uid);
  const participantVotesIdeasId = useSelector(getVotedParticipantIdeas(participantId));

  const [votesIdeasId, setVotesIdeasId] = useState(participantVotesIdeasId);

  const votesCount = votesIdeasId.length;

  // get number of ideas for vote
  const countIdeas = keys(filter(ideas, idea => !idea.isDisabled)).length;
  const totalCountVotes = countIdeas < votes ? countIdeas : votes;

  // Set participant vote
  const handleVote = (ideaId, isIdeaSelected) => {
    if (isIdeaSelected) {
      dispatch(addParticipantVote(id, ideaId, participantId));
      setVotesIdeasId([...votesIdeasId, ideaId]);
    } else {
      dispatch(removeParticipantVote(id, ideaId, participantId));
      setVotesIdeasId([...filter(votesIdeasId, id => id !== ideaId)]);
    }
  };

  return (
    <>
      <Grouping
        howMightWeId={id}
        groups={groups}
        context={activeView}
        settingsDisabled={true}
        handleVote={handleVote}
        isComplete={votesCount >= votes}
      />
      {votesCount >= totalCountVotes && (
        <CheckCircleIcon className={cx(classes.successColorText, classes.checkIcon)} />
      )}
      <DottedProgress
        className={cx(votesCount < totalCountVotes && 'mt-7', 'mt-1 ml-1')}
        activeColor="#E59A05"
        totalCount={totalCountVotes}
        activeCount={votesCount}
      />
      <Typography
        color="textSecondary"
        className={cx(votesCount === totalCountVotes && classes.successColorText)}
        variant="body2"
      >
        {votesCount === totalCountVotes
          ? ' You have used all your votes '
          : `${votesCount} of ${totalCountVotes} votes used.`}
      </Typography>
    </>
  );
};

export default HowMightWeParticipantVote;
