import React from 'react';
import { useFirestore, useFirebase } from 'react-redux-firebase';
import { useSelector, useDispatch } from 'react-redux';

import Button from '@material-ui/core/Button';
import { FieldArray } from 'formik';

import BreakoutsRoomAdmin from './BreakoutsRoomAdmin';
import AdminSendToParticipantsActivity from 'components/AdminActivityTemplate/AdminSendToParticipantsActivity';

import { enqueueErrorSnackbar } from 'redux/actions/notifierActions';
import { showAppOverlaySpinner } from 'redux/actions/facilitatorSessionActions';

import { generateRandomId } from '../../../utils/generateRandomId';
import { parseMuralURL } from 'utils/muralApiClient';

const BreakoutRoomsAdmin = ({
  hasStarted,
  rooms,
  handleChange,
  classes,
  breakoutId,
  muralEnabled,
  name,
  muralURL,
  muralWorkspace,
}) => {
  const db = useFirestore();
  const dispatch = useDispatch();
  const firebase = useFirebase();
  const { selectedFacilitatorRoute } = useSelector(state => state.facilitatorSession);
  const session = useSelector(state => state.firestore.data.session);

  const activeParticipantRoute = session.activeRoute;

  const addRoom = async () => {
    dispatch(showAppOverlaySpinner(true));
    let newMural;

    let newRoom = {
      id: generateRandomId(),
      title: `Breakout ${rooms.length + 1}`,
      description: '',
    };

    try {
      if (muralEnabled) {
        const cloneRequest = await firebase.functions().httpsCallable('mural-clone')({
          meetingTitle: session.topic,
          activityName: name,
          muralURL: muralURL,
          numberOfClones: 1,
          workspaceId: muralWorkspace,
          startingTitleCount: rooms.length + 1,
        });

        newMural = cloneRequest.data[0];

        const parsedMuralURL = parseMuralURL(newMural.visitorsSettings.link);

        newRoom = {
          ...newRoom,
          muralURL: newMural.visitorsSettings.link,
          muralState: parsedMuralURL.state,
          originalMuralURL: muralURL,
        };
      }

      const update = {
        rooms: [...rooms, newRoom],
      };

      db.doc(`breakouts/${breakoutId}`).set(update, { merge: true });
    } catch (error) {
      console.error(error);
      let message = error.message;

      if (message.includes('-')) {
        message = message.split(' - ')[1];
      }

      message = `Mural returned the following error: ${message}`;

      dispatch(enqueueErrorSnackbar(message, { autoHideDuration: 10000 }));
    } finally {
      dispatch(showAppOverlaySpinner(false));
    }
  };

  const renderRooms = rooms => {
    if (!rooms || !rooms.length) {
      return null;
    }

    return rooms.map((room, idx) => (
      <BreakoutsRoomAdmin
        key={`rooms.${idx}.id`}
        room={room}
        hasStarted={hasStarted}
        roomNumber={idx + 1}
        index={idx}
        classes={classes}
        muralEnabled={muralEnabled}
      />
    ));
  };

  return (
    <FieldArray name="rooms" onChange={handleChange}>
      {arrayHelpers => (
        <div>
          {renderRooms(rooms)}
          <div className="d-flex justify-content-center mb-3">
            <Button
              variant="outlined"
              classes={{
                disabled: classes.addRoomDisabled,
              }}
              className={classes.addRoom}
              onClick={addRoom}
            >
              Add Room
            </Button>
          </div>
          {activeParticipantRoute !== selectedFacilitatorRoute && (
            <AdminSendToParticipantsActivity
              session={session}
              activeFacilitatorRoute={selectedFacilitatorRoute}
            />
          )}
        </div>
      )}
    </FieldArray>
  );
};

export default BreakoutRoomsAdmin;
