import React, { Fragment, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useFirestore } from 'react-redux-firebase';
import cx from 'classnames';
// library components
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Tooltip, MenuItem, FormControl, Select, Input } from '@material-ui/core';
// cr components
import AddActivityForm from '../Forms/AddActivityForm';
import FormDialog from '../Dialogs/FormDialog';
// actions
import { setSelectedFacilitatorRoute } from 'redux/actions/facilitatorSessionActions';
import { openPremiumSubscriptionDialog } from 'redux/actions/facilitatorSessionActions';
// utils
import { getStoredFacilitatorRouteKey } from 'utils/localStorageUtils';
// constants
import { ACTIVITIES } from 'constants/activities';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: ({ mediumScreen, tabletScreen }) =>
      mediumScreen && !tabletScreen ? '23vw' : tabletScreen ? '100%' : '30vw',
    maxWidth: 300,
    minWidth: 160,
  },
  formSmallScreen: {
    width: `300px !important`,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const useSelectStyles = makeStyles({
  select: {
    paddingLeft: 12,
    paddingRight: 16,
    fontWeight: 400,
    color: 'rgba(0, 0, 0, 0.6)',
  },
});

const useInputStyles = makeStyles({
  root: {
    background: 'rgba(0, 0, 0, 0.04)',
    width: '100%',
  },
});

export default function SessionViewSelect({ session, sessionsType, isUserAccountLimit }) {
  const storedRouteKey = getStoredFacilitatorRouteKey(session.id);
  const dispatch = useDispatch();
  const { selectedFacilitatorRoute } = useSelector(state => state.facilitatorSession);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const selectClasses = useSelectStyles();
  const smallScreen = useMediaQuery('(max-width: 760px)');
  const mediumScreen = useMediaQuery('(max-width: 1100px)');
  const tabletScreen = useMediaQuery('(max-width: 959px)');
  const classes = useStyles({ mediumScreen, tabletScreen });
  const route = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    const storedRoute = localStorage.getItem(storedRouteKey);

    if (storedRoute && selectedFacilitatorRoute !== storedRoute) {
      dispatch(setSelectedFacilitatorRoute(storedRoute, session.id));
    } else if (!storedRoute) {
      dispatch(setSelectedFacilitatorRoute('welcome', session.id));
    }
  }, [selectedFacilitatorRoute, storedRouteKey, session.id]);

  const renderOptions = () => {
    const avilableRoutes = [
      {
        name: 'Welcome',
        value: 'welcome',
        tooltip: ACTIVITIES.welcome.tooltip,
      },
      {
        name: 'Slides',
        value: `slides`,
        tooltip: ACTIVITIES.slides.tooltip,
      },
    ];

    if (session.activities?.length) {
      session.activities.forEach(activity => {
        avilableRoutes.push({
          name: activity.name,
          value: activity.route,
          tooltip: ACTIVITIES[activity.activity]?.tooltip,
        });
      });
    }

    avilableRoutes.push({
      name: 'Feedback',
      value: `feedback`,
      tooltip: ACTIVITIES.feedback.tooltip,
    });

    avilableRoutes.push({
      name: 'Add new...',
      value: 'addNew',
    });

    return avilableRoutes.map((option, idx) => (
      <MenuItem
        value={option.value}
        key={idx}
        className={cx({
          'border-top': option.value === 'addNew',
        })}
      >
        {option.tooltip ? (
          <Tooltip
            title={option.tooltip}
            arrow
            placement="left"
            enterDelay={500}
            enterNextDelay={500}
          >
            <span className="w-100">{option.name}</span>
          </Tooltip>
        ) : (
          option.name
        )}
      </MenuItem>
    ));
  };

  const toggleDialog = () => {
    // if user is subscriber or limit for basic account not exceeded
    isUserAccountLimit
      ? setIsDialogOpen(!isDialogOpen)
      : dispatch(openPremiumSubscriptionDialog(true));
  };

  const handleChange = e => {
    const value = e.target.value;

    if (value === 'addNew') {
      toggleDialog();
    } else {
      dispatch(setSelectedFacilitatorRoute(e.target.value, session.id));
      const redirectRoute = `/admin/${sessionsType}/${route.params.sessionId}/${e.target.value}`;
      history.push(redirectRoute);
    }
  };

  return (
    <Fragment>
      <FormControl
        variant="outlined"
        className={cx(classes.formControl, smallScreen && classes.formSmallScreen)}
      >
        <span style={{ fontWeight: '500', paddingBottom: 8 }}>Facilitator View:</span>
        <Select
          id="facilitator-view-select"
          value={selectedFacilitatorRoute}
          onChange={handleChange}
          classes={selectClasses}
          input={<Input disableUnderline classes={useInputStyles()} />}
        >
          {renderOptions()}
        </Select>
      </FormControl>
      <FormDialog handleClose={toggleDialog} isOpen={isDialogOpen} title="Add Activity">
        <AddActivityForm closeDialog={toggleDialog} />
      </FormDialog>
    </Fragment>
  );
}
