import React from 'react';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
// cr components
import ActivityTemplate from 'components/ActivityTemplate/ActivityTemplate';
import ParticipantActivityContent from 'components/ParticipantActivityContent';
import UserJourneyMomentsParticipantCreate from 'components/UserJourneyMoments/Participant/UserJourneyMomentsParticipantCreate';
import UserJourneyMomentsParticipantKeyMoment from 'components/UserJourneyMoments/Participant/UserJourneyMomentsParticipantKeyMoment';
import UserJourneyMomentsParticipantVote from 'components/UserJourneyMoments/Participant/UserJourneyMomentsParticipantVote';
import UserJourneyMomentsParticipantResults from 'components/UserJourneyMoments/Participant/UserJourneyMomentsParticipantResults';
import UserJourneyMomentsParticipantReview from 'components/UserJourneyMoments/Participant/UserJourneyMomentsParticipantReview';
import UserJourneyMomentsParticipantSuperVote from 'components/UserJourneyMoments/Participant/UserJourneyMomentsParticipantSuperVote';
// selectors
import { useUserJourneyMomentsSelector } from 'redux/selectors/userJourneyMomentsSelectors';
// constants
import { USER_JOURNEY_MOMENTS_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';

//add new view for all action
const views = {
  create: UserJourneyMomentsParticipantCreate,
  keyMoment: UserJourneyMomentsParticipantKeyMoment,
  vote: UserJourneyMomentsParticipantVote,
  review: UserJourneyMomentsParticipantReview,
  superVote: UserJourneyMomentsParticipantSuperVote,
  results: UserJourneyMomentsParticipantResults,
};

const UserJourneyMomentsParticipant = ({
  match: {
    params: { activityId },
  },
}) => {
  const userJourneyMoments = useUserJourneyMomentsSelector();

  // hooks up a firestore listener to Redux
  useFirestoreConnect(() => [
    {
      collection: 'userJourneyMoments',
      doc: `${activityId}`,
      storeAs: 'userJourneyMoments',
    },
  ]);

  // ensure all of the data we need is loaded
  if (!isLoaded(userJourneyMoments) || activityId !== userJourneyMoments?.id) {
    return null;
  }

  const Component = views[userJourneyMoments.state.activeView];

  return (
    <ActivityTemplate type="User Journey Moments" name={userJourneyMoments.name}>
      <ParticipantActivityContent
        activityData={userJourneyMoments}
        viewLabel={CONFIG.participantViewLabels[userJourneyMoments.state.activeView]}
        activityConfig={CONFIG}
      >
        <Component />
      </ParticipantActivityContent>
    </ActivityTemplate>
  );
};

export default UserJourneyMomentsParticipant;
