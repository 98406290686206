import { createMuiTheme } from '@material-ui/core/styles';

const controlRoomTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#093c75',
      lightest: '#DCE5EF',
    },
    secondary: {
      main: '#02A05C',
      lightest: '#F0FEE5',
    },
    success: {
      main: '#02A05C',
      lightest: '#F2FCE9',
      pale: '#02A084',
    },
    warning: {
      main: '#E59A05',
    },
    error: {
      main: '#E2351E',
    },
    satisfied: {
      main: '#02A05C',
    },
    neutral: {
      main: '#E59A05',
      lightest: '#FCF8E5',
    },
    dissatisfied: {
      main: '#E2351E',
    },
    indigo: {
      main: '#246FC4',
      lightest: '#E5F6FE',
    },
    gold: {
      main: '#CEB070',
      lightest: '#C69F4B',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        fontSize: 14,
        letterSpacing: '1.25px',
      },
      contained: {
        minWidth: 200,
        minHeight: 45,
        textTransform: 'uppercase',
      },
      outlined: {
        minWidth: 200,
        minHeight: 45,
        textTransform: 'uppercase',
      },
      sizeSmall: {
        minHeight: 'initial',
        minWidth: 'initial',
        textTransform: 'initial',
      },
    },
  },
});

console.log('theme', controlRoomTheme);

export default controlRoomTheme;
