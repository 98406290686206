import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
// library components
import { Box, Typography } from '@material-ui/core';
// cr components
import HeatMapping from 'components/HeatMapping/Participant/HeatMapping';
// selectors
import { useHeatMappingSelector } from 'redux/selectors/heatMappingSelectors';

const useStyles = makeStyles(theme => ({
  commentsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  button: {
    margin: theme.spacing(2),
  },
}));

/**
 * Heat-Mapping view for the 'heatMapping' phase on the Participant side
 */
const HeatMappingParticipantHeatMapping = () => {
  const classes = useStyles();
  const participantId = useSelector(state => state.firebase.auth.uid);

  const {
    id,
    participantData: { sketches, images },
    state: { currentSketch, activeView },
  } = useHeatMappingSelector();

  return (
    <>
      <HeatMapping
        id={id}
        currentSketch={currentSketch}
        participantId={participantId}
        sketches={sketches}
        images={images}
        disable={false}
        context={activeView}
      />
      <Box className={classes.commentsContainer}>
        <Typography color="textSecondary" variant="body2" align="center">
          Click anywhere on the image to place a dot. Drag and drop to move dots. <br />
          Drag dots off the image to remove them. Click the left and right arrows to move between
          multiple images.
        </Typography>
      </Box>
    </>
  );
};

export default HeatMappingParticipantHeatMapping;
