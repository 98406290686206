import apiClient from 'utils/apiClient';

export const getZoomToken =
  (authCode, extraParams, loginOnSuccess) => async (dispatch, getState) => {
    const idToken = getState().idToken;
    const api = idToken ? apiClient(idToken) : apiClient();

    console.log('getZoomToken');
    let authURL = `/zoom-signup?code=${authCode}`;

    if (extraParams) {
      authURL += extraParams;
    }

    try {
      await api.get(authURL).catch(err => {
        console.log(err);
      });

      if (loginOnSuccess) {
        window.location.href = `${process.env.REACT_APP_URL}/auth/login-page`;
      }
    } catch (error) {
      console.log(error);
    }
  };

export const getZoomMeetings = () => (dispatch, getState) => {
  const idToken = getState().idToken;
  console.log('getZoomMeetings');

  apiClient(idToken)
    .get('/zoom/meetings')
    .then(res => {
      console.log('zoomMeetings', res);
    })
    .catch(err => {
      console.log(err);
    });
};
