import React from 'react';
import cx from 'classnames';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';

import styles from 'assets/jss/material-dashboard-pro-react/views/errorPageStyles.js';

const useStyles = makeStyles({
  ...styles(),
  title: {
    fontSize: '4rem',
    fontWeight: 'bold',
  },
  link: {
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  bodyText: {
    marginTop: 32,
    marginBottom: 32,
    fontSize: 18,
  },
});

/**
 * Shown when a user isn't an admin or a contractor.
 */
export default function UserError() {
  const classes = useStyles();

  return (
    <div className={cx(classes.contentCenter, 'text-dark')} style={{ maxWidth: 1200 }}>
      <GridContainer>
        <GridItem xs={12}>
          <h1 className={classes.title}>User Account Issue</h1>
          <h2 className={classes.subTitle}>There is an issue with your user account.</h2>
          <p className={classes.bodyText}>
            Pease contact Jamie at{' '}
            <a href="mailto:op@voltagecontrol.com" className={classes.link}>
              ops@voltagecontrol.com
            </a>{' '}
            for support.
          </p>
        </GridItem>
      </GridContainer>
    </div>
  );
}
