import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
// library components
import { DialogTitle, IconButton, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles({
  dialogHeader: {
    maxWidth: 970,
    width: 970,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  hiddenBox: {
    width: 48,
    height: 48,
    visibility: 'hidden',
  },
  headerRoot: {
    padding: '8px 24px',
  },
});

const DialogHeader = ({ handleClose, title }) => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <DialogTitle
      disableTypography
      className={classes.dialogHeader}
      classes={{ root: classes.headerRoot }}
    >
      <Box className={classes.hiddenBox} />
      <Typography variant="h6" style={{ textTransform: 'initial' }}>
        {title}
      </Typography>
      <IconButton
        aria-label="close"
        className="position-relative align-self-start"
        onClick={handleClose}
        style={{
          right: `-${theme.spacing(2)}px`,
        }}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  );
};

DialogHeader.propTypes = {
  handleClose: PropTypes.func.isRequired, // on close dialog
  title: PropTypes.string, // the title of dialog
};

export default DialogHeader;
