import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
// library components
import { Button } from '@material-ui/core';
// actions
import { togglePointsVisibility } from 'redux/actions/heatMappingActions';
// selectors
import { useHeatMappingSelector } from 'redux/selectors/heatMappingSelectors';

const useStyles = makeStyles(theme => ({
  toggleContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  toggle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  toggleIndicatorOn: {
    width: '16px',
    height: '16px',
    borderRadius: '100%',
    background: '#093B75',
    marginRight: '7px',
    marginTop: '4px',
  },
  toggleIndicatorOff: {
    width: '16px',
    height: '16px',
    borderRadius: '100%',
    background: '#E3E3E3',
    marginRight: '7px',
    marginTop: '4px',
  },
  toggleVerbiageOn: {
    fontWeight: 'normal',
    fontSize: '14px',
    color: '#093B75',
    marginBottom: 0,
    textTransform: 'initial !important',
  },
  toggleVerbiageOff: {
    fontWeight: 'normal',
    fontSize: '14px',
    color: '#9C9C9C',
    marginBottom: 0,
    textTransform: 'initial !important',
  },
}));

export default function ToggleHeatmapDots({ id }) {
  const heatMapping = useHeatMappingSelector();
  const dispatch = useDispatch();
  const isPointsHidden = heatMapping?.state?.isPointsHidden || false;
  const classes = useStyles();

  // Manage visibiility of heat map points
  const togglePoints = () => {
    dispatch(togglePointsVisibility(id));
  };

  return (
    <div className={classes.toggleContainer}>
      <Button onClick={togglePoints}>
        <div className={classes.toggle}>
          <div
            className={isPointsHidden ? classes.toggleIndicatorOff : classes.toggleIndicatorOn}
          />
          <p className={isPointsHidden ? classes.toggleVerbiageOff : classes.toggleVerbiageOn}>
            Heat dots {isPointsHidden ? 'off' : 'on'}
          </p>
        </div>
      </Button>
    </div>
  );
}
