import React, { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { each, map, cloneDeep, reduce, filter, sortBy, find, size, head, findIndex } from 'lodash';
import firebase from 'firebase/app';
import 'firebase/storage';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import cx from 'classnames';
// cr components
import Slider from 'components/Slider';
import ClickerImage from 'components/ClickerImage';
import HeatMappingComments from './HeatMappingComments';
// library components
import { Box, Typography } from '@material-ui/core';
// actions
import {
  createImage,
  addImagePoints,
  updateImagePoints,
  updateSketchReviewImage,
} from 'redux/actions/heatMappingActions';

const useStyles = makeStyles(theme => ({
  slider: {
    width: '100%',
    marginBottom: theme.spacing(1),
    border: '1px dashed #C0CFDC',
    borderRadius: 5,
    backgroundColor: '#EDF5F8',
  },
  imageBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: ({ heatMappingMaxWidth, isSmallScreen }) =>
      isSmallScreen ? '100%' : heatMappingMaxWidth,
    height: ({ heatMappingMaxHeight }) => heatMappingMaxHeight,
    objectFit: 'contain',
    backgroundColor: 'rgba(183, 194, 200, 0.3)',
  },
  image: {
    minHeight: 235,
    width: '100%',
    maxWidth: ({ heatMappingMaxWidth }) => heatMappingMaxWidth,
    maxHeight: ({ heatMappingMaxHeight }) => heatMappingMaxHeight,
    objectFit: 'contain',
  },
  title: {
    marginBottom: theme.spacing(4),
    fontSize: '20px',
    fontWeight: '200',
  },
  sliderImages: {
    width: '100%',
  },
  sliderText: {
    paddingTop: theme.spacing(2),
    color: 'rgba(86, 86, 86, 0.5)',
    order: 3,
    fontSize: 15,
  },
}));

/**
 * Renders Heat-Mapping view (images and dots)
 */
const HeatMapping = ({
  participantId,
  currentSketch,
  id,
  sketches,
  images,
  disable,
  context,
  participants,
  isPointsHidden = false,
}) => {
  const heatMappingMaxWidth = 715;
  const heatMappingMaxHeight = 536;
  const isSmallScreen = useMediaQuery('(max-width: 767px)');
  const classes = useStyles({ heatMappingMaxWidth, heatMappingMaxHeight, isSmallScreen });
  const dispatch = useDispatch();
  const sketchImages = sortBy(
    filter(images, image => image.participantId === sketches[currentSketch].participantId),
    'order',
  );

  const noHeatMappingSketches = sortBy(filter(sketches, { completed: false }, ['participantId']));
  const sketchesLength = size(sketches);

  const sketchNumber = `${sketchesLength - noHeatMappingSketches.length + 1}`;

  const reviewImageId = head(sketchImages)?.id;

  // Get all heat mapping dots and save them in firebase
  const getPoints = (imageId, points) => {
    if (!size(points)) {
      const imagePoints = cloneDeep(images[imageId].points);
      delete imagePoints[participantId];
      dispatch(updateImagePoints(id, imageId, imagePoints));
    } else {
      dispatch(addImagePoints(id, imageId, participantId, points));
    }
  };

  // Get image url from frirebase storage for sketches
  const getSketchImageUrls = useCallback(
    ({ participantId, id: imageId, order }) => {
      const storageRef = firebase.storage().ref(`heatMapping/${id}/${participantId}/${imageId}`);

      storageRef
        .getDownloadURL()
        .then(url => {
          dispatch(createImage(id, imageId, participantId, url, order));
        })
        .catch(err => console.log('Error get image url', err));
    },
    [dispatch, id],
  );

  useEffect(() => {
    each(sketchImages, image => {
      if (image && !image.url) {
        getSketchImageUrls(image);
      }
    });
  }, [currentSketch, getSketchImageUrls, sketchImages]);

  // Render images for heat mapping
  const renderHeatMappingImages = () =>
    map(sketchImages, (sketch, idx) => {
      const imageId = sketch.id;
      const points =
        context === 'heatMapping'
          ? images[imageId]?.points?.[participantId]
          : reduce(
              participants,
              (acc, participant) => ({ ...acc, ...images[imageId].points?.[participant] }),
              {},
            );
      return (
        <ClickerImage
          key={`image-${idx}`}
          imageId={imageId}
          className={classes.imageBox}
          getPoints={getPoints}
          img={sketch.url}
          imagePoints={points}
          disable={disable}
          isPointsHidden={isPointsHidden}
          classNameImage={classes.image}
          imageElementId={`points-image-${idx}`}
        />
      );
    });

  // Render image for review on participant side
  const renderReviewImage = imageId => {
    const imageUrl = find(images, image => image.id === imageId);
    if (imageUrl) {
      const points = reduce(
        participants,
        (acc, participant) => ({ ...acc, ...images[imageId]?.points?.[participant] }),
        {},
      );
      return [
        <ClickerImage
          imageId={imageId}
          className={classes.imageBox}
          getPoints={getPoints}
          img={imageUrl.url}
          imagePoints={points}
          disable={disable}
          isPointsHidden={isPointsHidden}
          classNameImage={classes.image}
          imageElementId={`points-image-0`}
        />,
      ];
    }
  };

  const onSliderControlButton = imageId => {
    dispatch(updateSketchReviewImage(id, currentSketch, imageId));
  };

  return (
    <>
      {context === 'review' && (
        <Typography className={classes.title} align="center">
          {`Sketch #${sketchNumber}`}
        </Typography>
      )}
      {sketchImages.length && (
        <Box className={classes.slider}>
          {context === 'reviewAdmin' || context === 'heatMapping' ? (
            <Slider
              slides={renderHeatMappingImages()}
              className={classes.sliderImages}
              onRightButton={onSliderControlButton}
              onLeftButton={onSliderControlButton}
              isUserControl={context === 'reviewAdmin'}
              startFrom={
                sketchImages.length
                  ? findIndex(sketchImages, image => image.id === reviewImageId)
                  : 0
              }
              textClassName={classes.sliderText}
            />
          ) : (
            <Slider
              slides={renderReviewImage(reviewImageId)}
              className={classes.sliderImages}
              withoutControlButtons={true}
              textClassName={classes.sliderText}
            />
          )}
          {context === 'review' && (
            <Typography align="center" className={cx(classes.sliderText, 'pt-0 pb-3')}>
              {`Image ${findIndex(sketchImages, image => image.id === reviewImageId) + 1} of ${
                sketchImages.length
              }`}
            </Typography>
          )}
          {(context === 'reviewAdmin' || context === 'review') && (
            <HeatMappingComments
              id={id}
              currentSketch={currentSketch}
              context={context}
              bigIdeas={sketches[currentSketch]?.adminComments?.bigIdeas}
              concerns={sketches[currentSketch]?.adminComments?.concerns}
            />
          )}
        </Box>
      )}
    </>
  );
};

HeatMapping.propTypes = {
  participantId: PropTypes.string,
  currentSketch: PropTypes.string, // current sketch id
  id: PropTypes.string,
  sketches: PropTypes.object, // all sketches for heat-mapping
  images: PropTypes.object, // all images of sketches
  disable: PropTypes.bool, // participant can't move, add, delete heat-mapping points
  context: PropTypes.string, // state phase
  participants: PropTypes.arrayOf(PropTypes.string), // all participants
};

export default HeatMapping;
