import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(theme => {
  return {
    root: {
      width: 80,
      height: 40,
      backgroundColor: '#DCE5EF',
      marginBottom: theme.spacing(2),
    },
  };
});

const useButtonStyles = makeStyles({
  buttonTitle: {
    position: 'relative',
    top: 1,
    left: '-3px',
    color: '#093B75',
    fontSize: 15,
  },
  iconContainer: {
    width: 17,
    height: 17,
    marginLeft: 5,
    borderRadius: '50%',
    color: 'white',
  },
  icon: {
    position: 'relative',
    bottom: '1px',
    fontSize: 15,
  },
});

/**
 * Renders the button for creating new custom parameters.
 */
const AddButton = ({ handleClick }) => {
  const classes = useStyles();
  const buttonClasses = useButtonStyles();
  return (
    <Button
      classes={classes}
      color="primary"
      size="medium"
      style={{ textTransform: 'none' }}
      onClick={() => {}}
      onMouseDown={handleClick}
    >
      <div className="d-flex align-items-center">
        <Box component="span" color="text.primary" className={buttonClasses.buttonTitle}>
          Add
        </Box>
        <Box bgcolor="primary.main" className={buttonClasses.iconContainer}>
          <AddIcon className={buttonClasses.icon} />
        </Box>
      </div>
    </Button>
  );
};

AddButton.propTypes = {
  // Function for creating new field.
  handleClick: PropTypes.func.isRequired,
};

export default AddButton;
