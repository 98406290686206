import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { map, keys, orderBy, each, filter, includes, find, remove } from 'lodash';
import { DragDropContext } from 'react-beautiful-dnd';
import { useConfirm } from 'material-ui-confirm';
// library components
import { Box, Button } from '@material-ui/core';
import AddCircleOutlinedIcon from '@material-ui/icons/AddCircleOutlined';
// cr components
import Group from './Group';
// selectors
import { getSharedIdeas } from 'redux/selectors/howMightWeSelectors';
import { getParticipantsMap } from 'redux/selectors/participantsSelectors';
// actions
import {
  createGroup,
  deleteGroup,
  editGroupName,
  updateIdGroupIdea,
  updateCombineIdeas,
} from 'redux/actions/howMightWeActions';

const useStyles = makeStyles({
  addGroupButton: {
    marginLeft: 'auto',
  },
  titleGroup: {
    marginLeft: 'auto',
    paddingLeft: '10%',
  },
  uncategorizedGroup: {
    flex: 1,
  },
  groupContainer: {
    gap: '20px',
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    position: 'relative',
  },
});

const Grouping = ({
  howMightWeId,
  groups,
  context,
  settingsDisabled,
  handleVote,
  isFinished,
  isComplete,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const participantId = useSelector(state => state.firebase.auth.uid);
  const participantsData = useSelector(getParticipantsMap);
  const countGroups = keys(groups).length;
  const confirm = useConfirm();

  const [combineIdea, setCombineIdea] = useState({});
  const [isOpenCombineList, setIsOpenCombineList] = useState(false);
  const [isDragStart, setIsDragStart] = useState(false);

  // get all shared ideas
  let ideas = useSelector(getSharedIdeas(context));
  // get are not disabled ideas
  if (context === 'vote') {
    ideas = filter(ideas, idea => !idea.isDisabled);
  }

  // sorts groups by creation
  const sortedGroups = orderBy(groups, ['order'], ['desc']);

  // function on add new group
  const handleAddNewGroup = () => {
    dispatch(createGroup(howMightWeId, participantId, countGroups + 1));
  };

  // function on delete group
  const onDeleteGroup = groupId => {
    confirm({ description: 'This action is permanent!' })
      .then(() => {
        dispatch(deleteGroup(howMightWeId, groupId));
      })
      .catch(() => console.log('group not deleted'));
  };

  // function on edit current group name
  const onEditGroupName = (groupId, value) => {
    dispatch(editGroupName(howMightWeId, groupId, value, participantId));
  };

  // open drop down duplicate cards
  const handleOpenDuplicateCards = ideaId => {
    const currentCombineIdea = find(ideas, idea => idea.ideaId === ideaId);
    if (combineIdea.ideaId === ideaId) {
      setIsOpenCombineList(false);
      setCombineIdea({});
    } else {
      setIsOpenCombineList(true);
      setCombineIdea(currentCombineIdea);
    }
  };

  // add duplicate card to stack
  const addDuplicateCardToStack = (combineIdeaId, draggableId, draggableIdea) => {
    if (combineIdeaId === draggableId) {
      return;
    }

    if (draggableIdea.combineIdeasId?.length) {
      each(draggableIdea.combineIdeasId, duplicateIdeaId => {
        dispatch(updateCombineIdeas(howMightWeId, draggableId, duplicateIdeaId, false));
        dispatch(updateCombineIdeas(howMightWeId, combineIdeaId, duplicateIdeaId, true));
      });
    }

    if (isOpenCombineList && combineIdea.combineIdeasId?.length > 1) {
      const newCombineIdea = {
        ...combineIdea,
        combineIdeasId: [...combineIdea.combineIdeasId, draggableId],
      };
      setCombineIdea(newCombineIdea);
    }

    dispatch(updateCombineIdeas(howMightWeId, combineIdeaId, draggableId, true));
  };

  // function on drag start
  const onDragStart = () => {
    setIsDragStart(true);
  };

  // function on drag end
  const onDragEnd = result => {
    setIsDragStart(false);
    const { source, destination, draggableId, combine } = result;

    const draggableIdea = find(ideas, idea => idea.ideaId === draggableId);

    const startIndex = source.droppableId;
    const finishIndex = destination?.droppableId;

    if (find(ideas, idea => idea.ideaId === finishIndex)) {
      if (draggableIdea.isCombine) {
        const combineIdea = find(ideas, idea => includes(idea.combineIdeasId, draggableId));
        dispatch(updateCombineIdeas(howMightWeId, combineIdea.ideaId, draggableId, false));
        const newCombineIdea = {
          ...combineIdea,
          combineIdeasId: remove(combineIdea.combineIdeasId, draggableId),
        };
        setCombineIdea(newCombineIdea);

        dispatch(updateCombineIdeas(howMightWeId, finishIndex, draggableId, true));
      } else {
        addDuplicateCardToStack(finishIndex, draggableId, draggableIdea);
      }
      return;
    }

    const finishGroupId = sortedGroups[finishIndex] ? sortedGroups[finishIndex].id : '';

    // remove duplicate card from stack
    if (draggableIdea.isCombine) {
      if (!finishIndex) {
        return;
      }
      const combineIdea = find(ideas, idea => includes(idea.combineIdeasId, draggableId));
      dispatch(updateCombineIdeas(howMightWeId, combineIdea.ideaId, draggableId, false));
      const newCombineIdea = {
        ...combineIdea,
        combineIdeasId: remove(combineIdea.combineIdeasId, draggableId),
      };
      setCombineIdea(newCombineIdea);

      // add card to other stack
      if (combine) {
        const combineIdeaId = combine.draggableId;
        dispatch(updateCombineIdeas(howMightWeId, combineIdeaId, draggableId, true));
      }

      if (startIndex !== finishIndex) {
        dispatch(updateIdGroupIdea(howMightWeId, draggableId, finishGroupId));
      }
      return;
    }

    // add duplicate card to stack
    if (combine) {
      const combineIdeaId = combine.draggableId;
      addDuplicateCardToStack(combineIdeaId, draggableId, draggableIdea);
      return;
    }

    if (!destination) {
      return;
    }
    // change group
    if (startIndex !== finishIndex) {
      dispatch(updateIdGroupIdea(howMightWeId, draggableId, finishGroupId));
    }
  };

  // renders all groups
  const rederGroups = () =>
    map(sortedGroups, (group, idx) => {
      const participantsEditName = map(
        group.editName,
        participant =>
          `Participant ${participantsData[participant.id]?.name} changed group name on "${
            participant.value
          }". `,
      );
      const tooltipText = participantsData[group.participantId]
        ? `Participant ${participantsData[group.participantId].name} 
        created group.`
        : '' + each(participantsEditName, text => text);
      return (
        <Group
          key={`group-${idx}`}
          howMightWeId={howMightWeId}
          ideas={ideas}
          groupId={group.id}
          droppableId={`${idx}`}
          context={context}
          title={group.value}
          onDeleteGroup={onDeleteGroup}
          onEditGroupName={onEditGroupName}
          settingsDisabled={settingsDisabled}
          handleVote={handleVote}
          participantId={participantId}
          isFinished={isFinished}
          tooltipText={tooltipText}
          isComplete={isComplete}
          countGroups={countGroups}
          handleOpenDuplicateCards={handleOpenDuplicateCards}
          isOpenCombineList={isOpenCombineList}
          combineIdea={combineIdea}
          isDragStart={isDragStart}
        />
      );
    });

  return (
    <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
      <Box display="flex" className="w-100" flexDirection="column">
        <Box
          display="flex"
          className="w-100 pb-2"
          alignItems="center"
          justifyContent="center"
          flexWrap="wrap"
        >
          {context === 'groupAdmin' && (
            <Button
              className={classes.addGroupButton}
              color="primary"
              size="small"
              startIcon={<AddCircleOutlinedIcon />}
              onClick={handleAddNewGroup}
            >
              New Group
            </Button>
          )}
        </Box>
        <Box className={classes.groupContainer}>{rederGroups()}</Box>
      </Box>
    </DragDropContext>
  );
};

Grouping.propTypes = {
  howMightWeId: PropTypes.string, // id of howMightWe
  groups: PropTypes.object, // all groups
  context: PropTypes.string, // active view of state
  settingsDisabled: PropTypes.bool, // true - group settings isn't enable
  handleVote: PropTypes.func, // function for set participant vote
  isFinished: PropTypes.bool, // the participant finished with active phase - true
  isComplete: PropTypes.bool, // the participant has fulfilled the conditions - true
};

export default Grouping;
