import React from 'react';
import { useSelector } from 'react-redux';
import { useFirestore } from 'react-redux-firebase';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { darken } from '@material-ui/core/styles';
import { map, keyBy, slice } from 'lodash';
// library components
import { Typography } from '@material-ui/core';
// CR components
import Topic from 'components/25:10/Admin/Topic';
import ActivityParticipantDetails from 'components/ActivityParticipantDetails';
import ActivityActionButton from 'components/CustomButtons/ActivityActionButton';
// selectors
import {
  getCurrentRound,
  getSessionVote,
  session2510Selector,
  getResults,
} from 'redux/selectors/25:10/root2510Selectors';
// utils
import { makeParticipantDetailsHeaderText } from 'utils/makeParticipantDetailsHeaderText';

const useStyles = makeStyles({
  topic: {
    marginBottom: '36px',
  },
  share: {
    marginBottom: '128px',
  },
  round: {
    fontSize: '36px',
    color: '#093B75',
    fontWeight: '300',
    marginBottom: '20px',
  },
  votes: {
    fontSize: '34px',
    fontWeight: '300',
    color: props => (props.numParticipants === props.numVotes ? '#02A05C' : '#D11B17'),
    marginBottom: '50px',
  },
  button: {
    marginBottom: '34px',
    backgroundColor: props => (props.remainingVotes ? '#093B75' : '#02A05C'),
    color: 'white',

    '&:hover': {
      backgroundColor: props =>
        props.remainingVotes ? darken('#093B75', 0.3) : darken('#02A05C', 0.3),
    },
  },
  remaining: {
    fontWeight: '300',
    fontSize: '20px',
    color: '#D11B17',
  },
  viewResults: {
    backgroundColor: props => (props.remainingVotes ? '#093B75' : '#02A05C'),
    color: 'white',

    '&:hover': {
      backgroundColor: props =>
        props.remainingVotes ? darken('#093B75', 0.3) : darken('#02A05C', 0.3),
    },
  },
});

const Admin2510Vote = ({ session, activityId }) => {
  const db = useFirestore();
  const { numVotes, remainingVotes, numParticipants } = useSelector(getSessionVote);
  const currentRound = useSelector(getCurrentRound);
  const classes = useStyles({ numParticipants, numVotes, remainingVotes });
  const { voteAssignments, ideas } = useSelector(session2510Selector);
  const ideasResults = useSelector(getResults);

  if (!numParticipants) {
    return null;
  }

  const participantsWithIdeas = map(ideas, 'id');

  const headerText = makeParticipantDetailsHeaderText(
    numVotes,
    participantsWithIdeas.length,
    'voted',
  );

  const handleClick = () => {
    const nextState = { currentRound: currentRound + 1 };
    db.doc(`25:10/${activityId}`).set(nextState, { merge: true });
  };

  const handleViewResults = () => {
    const output = map(ideasResults, idea => ({ idea: idea.idea, score: idea.score }));

    db.doc(`25:10/${activityId}`).set(
      {
        activeView: 'results',
        output: slice(output, 0, 10),
        gameFinished: true,
      },
      { merge: true },
    );
  };

  const renderButton = () => {
    return (
      <ActivityActionButton
        text="Next Round"
        onClick={handleClick}
        className="mb-4"
        color={remainingVotes ? 'primary' : 'green'}
        delayReady
        key={`next-btn-${currentRound}`}
      />
    );
  };

  const getParticipantsCompletedStatus = () => {
    return map(participantsWithIdeas, pId => {
      if (voteAssignments?.[currentRound]) {
        const participantVotes = keyBy(voteAssignments[currentRound], 'participantId');
        return {
          id: pId,
          completed: participantVotes[pId]?.vote > 0,
          //completedCount not needed - will only ever be 1.
        };
      }

      return {
        id: pId,
        completed: false,
      };
    });
  };

  const renderViewResults = () => {
    return (
      <ActivityActionButton
        text="View Results"
        onClick={handleViewResults}
        className="mb-4"
        color={remainingVotes ? 'primary' : 'green'}
        delayReady
      />
    );
  };

  return (
    <div className="text-center">
      <Topic topic={session.topic} classes={classes.topic} />
      <Typography className={classes.round}>Round {currentRound}</Typography>
      <ActivityParticipantDetails
        headerText={headerText}
        hasCompletedLabel="Voted"
        hasntCompletedLabel="Hasn't Voted"
        participantsCompletedStatus={getParticipantsCompletedStatus()}
      />
      {currentRound !== 5 ? renderButton() : renderViewResults()}
      {!remainingVotes ? null : (
        <Typography className={classes.remaining}>{remainingVotes} votes remaining</Typography>
      )}
    </div>
  );
};

export default Admin2510Vote;
