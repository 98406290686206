import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { map } from 'lodash';
// library components
import { makeStyles, IconButton, Typography } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles({
  icon: {
    fontSize: 18,
  },
  page: {
    padding: '0 5px',
    fontSize: 15,
    fontWeight: 500,
    color: grey[800],
    textAlign: 'center',
    cursor: 'pointer',
  },
  activePage: {
    color: grey[400],
  },
  additionalText: {
    padding: 10,
    color: grey[400],
    fontSize: 15,
    fontWeight: 500,
  },
});

/**
 * Renders the slider for images with different sizes and styles.
 */
export default function Slider({ slides, className, buttonStyles, additionalText, isShowPages }) {
  const isSmallScreen = useMediaQuery('(max-width: 767px)');
  const classes = useStyles({ isSmallScreen });
  const [index, setIndex] = useState(0);

  useEffect(() => {
    setIndex(0);
  }, [slides]);

  const slideRight = () => {
    setIndex(index + 1);
  };

  const slideLeft = () => {
    setIndex(index - 1);
  };

  const slidePage = index => {
    setIndex(index);
  };

  const renderPages = () =>
    map(slides, (slide, idx) => (
      <Typography
        key={`slides-${idx}`}
        className={cx(classes.page, index === idx && classes.activePage)}
        onClick={() => slidePage(idx)}
      >
        {idx + 1}
      </Typography>
    ));

  return (
    <div className={cx('d-flex flex-column justify-content-between py-2', className)}>
      <div
        className={
          'd-flex flex-column flex-nowrap align-items-center justify-content-between py-1 h-100'
        }
      >
        {slides.length > 0 && slides[index]}
        {slides.length > 1 && (
          <div className={cx('d-flex pt-3 pb-1', buttonStyles)}>
            <IconButton
              className="mr-1"
              id="slider-button"
              onClick={slideLeft}
              disabled={index === 0}
            >
              <ArrowBackIosIcon className={classes.icon} id="slider-button" />
            </IconButton>
            {isShowPages && (
              <div className="d-flex flex-wrap align-items-center justify-content-center">
                {renderPages()}
              </div>
            )}
            <IconButton
              className="ml-1"
              onClick={slideRight}
              disabled={index === slides.length - 1}
              id="slider-button"
            >
              <ArrowForwardIosIcon className={classes.icon} id="slider-button" />
            </IconButton>
            {additionalText && (
              <Typography className={classes.additionalText}>{additionalText}</Typography>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

Slider.defaultProps = {
  slides: [],
  className: '',
};

Slider.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.node), // array of html element
  className: PropTypes.string, // class name style for slider container
  buttonStyles: PropTypes.string, // styles for slider buttons control
  additionalText: PropTypes.string, // additional slider text
};
