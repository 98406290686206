import React from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
// cr components
import HeatMapping from './HeatMapping';
// selectors
import { useHeatMappingSelector } from 'redux/selectors/heatMappingSelectors';

/**
 * Heat-Mapping view for the 'review' phase on the Participant side
 */
const HeatMappingParticipantReview = () => {
  const {
    id,
    participantData: { sketches, images, participants },
    state: { currentSketch, activeView, isPointsHidden },
  } = useHeatMappingSelector();

  return (
    <HeatMapping
      sketches={sketches}
      currentSketch={currentSketch}
      id={id}
      isPointsHidden={isPointsHidden}
      images={images}
      participants={participants}
      disable={true}
      context={activeView}
    />
  );
};

export default HeatMappingParticipantReview;
