import {
  FACILITATOR_SHOW_VIDEO,
  SET_SELECTED_FACILITATOR_ROUTE,
  IS_OPEN_PREMIUM_SUBSCRIPTION_DIALOG,
  IS_OPEN_FACILITATOR_ACCOUNT_SETTINGS_DIALOG,
  IS_OPEN_CREATE_MEETING_DIALOG,
  IS_OPEN_MURAL_AUTH_DIALOG,
  SHOW_APP_OVERLAY_SPINNER,
  IS_OPEN_INFO_DIALOG,
  SET_INFO_DIALOG_CONTENT,
  IS_MURAL_AUTHORIZED,
  CLOSE_INFO_DIALOG,
} from '../types/facilitatorSessionTypes';

const initialState = {
  showVideo: true,
  selectedFacilitatorRoute: '',
  isOpenPremiumDialog: false,
  isOpenFacilitatorAccountSettingsDialog: false,
  showAppOverlaySpinner: false,
  isOpenCreateSessionDialog: false,
  isOpenMuralAuthDialog: false,
  isOpenInfoDialog: false,
  infoDialogContent: { title: '', content: '' },
};

export default function facilitatorSessionReducer(state = initialState, action) {
  switch (action.type) {
    case FACILITATOR_SHOW_VIDEO:
      return { ...state, showVideo: action.payload };
    case SET_SELECTED_FACILITATOR_ROUTE:
      return { ...state, selectedFacilitatorRoute: action.payload };
    case IS_OPEN_PREMIUM_SUBSCRIPTION_DIALOG:
      return { ...state, isOpenPremiumDialog: action.payload };
    case IS_OPEN_FACILITATOR_ACCOUNT_SETTINGS_DIALOG:
      return { ...state, isOpenFacilitatorAccountSettingsDialog: action.payload };
    case IS_OPEN_CREATE_MEETING_DIALOG:
      return { ...state, isOpenCreateSessionDialog: action.payload };
    case SHOW_APP_OVERLAY_SPINNER:
      return { ...state, showAppOverlaySpinner: action.payload };
    case IS_OPEN_INFO_DIALOG:
      return { ...state, isOpenInfoDialog: action.payload };
    case IS_OPEN_MURAL_AUTH_DIALOG:
      return { ...state, isOpenMuralAuthDialog: action.payload };
    case SET_INFO_DIALOG_CONTENT:
      return { ...state, infoDialogContent: { ...action.payload } };
    case CLOSE_INFO_DIALOG:
      return {
        ...state,
        infoDialogContent: initialState.infoDialogContent,
        isOpenInfoDialog: false,
      };
    default:
      return state;
  }
}
