import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
// library components
import { Typography } from '@material-ui/core';
// cr components
import GrayBox from 'components/GrayBox';
// selectors
import { getActiveSet } from 'redux/selectors/cardSetsSelectors';

const useStyles = makeStyles(theme => ({
  box: {
    marginBottom: theme.spacing(4),
    padding: '10px 15px',
  },
  header: {
    fontSize: 18,
  },
}));

/**
 * Renders the card set box
 */
const ActiveCardSetAdmin = ({ cardSetId }) => {
  const classes = useStyles();
  const [cardSetName, setCardSetName] = useState('');

  const getSetName = useCallback(async () => {
    const set = await getActiveSet(cardSetId);
    setCardSetName(set?.name);
  }, [cardSetId]);

  useEffect(() => {
    getSetName();
  }, [getSetName]);

  if (!cardSetName) {
    return <></>;
  }

  return (
    <GrayBox className={classes.box}>
      <Typography className={classes.header}>{cardSetName}</Typography>
    </GrayBox>
  );
};

ActiveCardSetAdmin.propTypes = {
  cardSetId: PropTypes.string.isRequired,
};

export default ActiveCardSetAdmin;
