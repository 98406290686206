import React from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { grey } from '@material-ui/core/colors';
import cx from 'classnames';
import { useFirestoreConnect, isLoaded } from 'react-redux-firebase';

import Spinner from '../components/Spinner';

const useStyles = makeStyles({
  noSlides: {
    border: '2px dashed',
    backgroundColor: grey[100],
    textAlign: 'center',
    padding: '0 15px',
    width: '100%',
    borderRadius: 4,
    height: 0,
    paddingTop: '28.125%',
    paddingBottom: '28.125%',
  },
});

const Slides = () => {
  const { slidesSessionId } = useParams();
  const classes = useStyles();
  const slidesSession = useSelector(state => state.firestore.data.slidesSession);

  useFirestoreConnect(() => [
    {
      collection: 'remoteSlidesSessions',
      doc: `${slidesSessionId}`,
      storeAs: 'slidesSession',
    },
  ]);

  if (!isLoaded(slidesSession)) {
    return (
      <div className="vw-100 vh-100 d-flex justify-content-center align-items-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div
      className="w-100 d-flex justify-content-start align-items-start"
      style={{ marginBottom: 72 }}
    >
      {!slidesSession || slidesSessionId === 'undefined' ? (
        <div className="w-100" style={{ maxWidth: 1200 }}>
          <div
            className={cx(
              classes.noSlides,
              'd-flex',
              'justify-content-center',
              'align-items-center',
            )}
          >
            <p style={{ fontSize: 20 }}>No slides for this session id</p>
          </div>
        </div>
      ) : (
        <img
          src={slidesSession.imageUrls[slidesSession.currentSlide]}
          className="border"
          style={{ width: 1400, height: 'auto' }}
        />
      )}
    </div>
  );
};

export default Slides;
