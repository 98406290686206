import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
  topic: {
    backgroundColor: grey[100],
    textAlign: 'center',
    padding: '30px',
  },
  top: {
    textAlign: 'center',
    fontSize: '30px',
    fontWeight: '300',
  },
});
const Results2510Header = ({ topic, ...props }) => {
  const classes = useStyles();
  return (
    <>
      <Paper className={clsx(classes.topic, props.classes.topic)} elevation={0}>
        {topic}
      </Paper>
      <Typography className={clsx(classes.top, props.classes.top)}>Top Voted Ideas</Typography>
    </>
  );
};

export default Results2510Header;
