import React, { Fragment, useEffect } from 'react';
import { useFirestoreConnect, isLoaded, isEmpty, useFirebase } from 'react-redux-firebase';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import * as Sentry from '@sentry/react';
import cx from 'classnames';
// librar components
import { Helmet } from 'react-helmet';
// cr components
import Feedback from 'views/Participant/Feedback/Feedback';
import OverlaySpinner from '../components/OverlaySpinner';
import DelayedRender from '../components/DelayedRender/DelayedRender';
import Spinner from '../components/Spinner';
// actions
import { enqueueErrorSnackbar } from 'redux/actions/notifierActions';
// styles
import styles from 'assets/jss/material-dashboard-pro-react/layouts/adminStyle.js';
import './participant.scss';

const useStyles = makeStyles(styles);

const useCusotmStyles = makeStyles(theme => ({
  sessionContentWrapper: {
    position: 'relative',
    marginRight: 0,
    marginTop: 16,
    [theme.breakpoints.down('sm')]: {
      marginRight: '0px !important',
    },
  },
}));

/**
 * Renders a feedback form for a session outside of the normal
 * session layout so it can be visited independently and also
 * allows for completely anonyous feedback
 */
export default function ParticipantFeedback() {
  const { sessionId } = useParams();
  const authUser = useSelector(state => state.firebase.auth);
  const session = useSelector(state => state.firestore.data.session);
  const showAppOverlaySpinner = useSelector(
    state => state.participantSession.showAppOverlaySpinner,
  );
  const classes = useStyles();
  const history = useHistory();
  const participantId = authUser.uid;
  const participantName = authUser.displayName;
  const firebase = useFirebase();
  const dispatch = useDispatch();
  const customClasses = useCusotmStyles();
  const sessionIsLoaded = isLoaded(session);
  const sessionIsEmpty = isEmpty(session);

  useFirestoreConnect(() => [
    {
      collection: 'sessions',
      doc: `${sessionId}`,
      storeAs: 'session',
    },
  ]);

  /**
   * If the user isn't already logged in, log them in anonymously and give
   * them a name of 'anonymous-user' so we have something consistent to check
   * against elsewhere in the app if need be (like in the Participant layout)
   */
  useEffect(() => {
    if (!participantId && sessionIsLoaded && !sessionIsEmpty) {
      const loginAnonymousUser = async () => {
        try {
          await firebase.auth().signInAnonymously();
          await firebase.updateAuth({ displayName: 'anonymous-user' });
        } catch (e) {
          console.error(e);
          Sentry.captureException(e);

          let message = e.message;

          if (e.code === 'auth/network-request-failed') {
            message +=
              ' If your internet connection is currently active, your network administrator may be blocking requests to googleapis.com/identitytoolkit';
          }

          dispatch(enqueueErrorSnackbar(message, { autoHideDuration: 10000 }));
        }
      };

      loginAnonymousUser();
    }
  }, [participantId, participantName, sessionIsLoaded, sessionIsEmpty]);

  if (isLoaded(session) && isEmpty(session)) {
    history.push('/auth/404');
  }

  if (isEmpty(session) || !isLoaded(authUser) || !participantId) {
    return (
      <div className="position-fixed h-100 w-100 d-flex justify-content-center align-items-center">
        <DelayedRender delay={500}>
          <Spinner />
        </DelayedRender>
      </div>
    );
  }

  return (
    <Fragment>
      <Helmet>
        <title>Control Room | Feedback</title>
        <link
          id="favicon"
          rel="icon"
          type="image/x-icon"
          href={`${window.location.origin}/favicon-green.ico`}
        />
      </Helmet>
      {showAppOverlaySpinner && <OverlaySpinner fullscreen />}
      <div className={cx(classes.wrapper)}>
        <div className={cx(classes.content, customClasses.sessionContentWrapper, 'mt-0 pt-0')}>
          <div className={cx(classes.container)}>
            <Feedback />
          </div>
        </div>
      </div>
    </Fragment>
  );
}
