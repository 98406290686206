import React from 'react';
import { useSelector } from 'react-redux';
import { map } from 'lodash';
// library components
import { Box, Typography, Radio } from '@material-ui/core';
// cr components
import AdminActivityAuxView from 'components/AdminActivityAuxView';
import CSVReportDownloadButton from 'components/CSVReportDownloadButton';
import AssessmentFinalResults from '../Shared/AssessmentFinalResults';
// selectors
import { useAssessmentSelector } from 'redux/selectors/assessmentSelectors';
// utils
import { createAssessmentReportData, createAssessmentReportHeader } from 'utils/assessment';

/**
 * Renders the Auxilary final results view on the
 * facilitator side
 */
const AssessmentAdminAuxFinalResults = () => {
  const {
    name,
    id,
    output,
    config: { questions },
    state: { activeView },
  } = useAssessmentSelector();
  const session = useSelector(state => state.firestore.data.session);

  // render headers for csv report
  const headers = createAssessmentReportHeader(questions);

  // render data for csv report
  const csvReport = createAssessmentReportData(output);

  return (
    <AdminActivityAuxView
      titleText="Assessment Results"
      // allowPrint
      activityName={name}
      hasDownloadButton={activeView === 'results'}
      renderDownloadButton={() => (
        <CSVReportDownloadButton
          headers={headers}
          csvReport={csvReport}
          buttonText="Download Insights"
          filename="Results.csv"
          topic={session.topic}
          activity="Assessment V2"
          activityName={name}
        />
      )}
    >
      <AssessmentFinalResults />
    </AdminActivityAuxView>
  );
};

export default AssessmentAdminAuxFinalResults;
