import React, { useState, useRef } from 'react';
import { Form, Formik } from 'formik';
import { debounce, cloneDeep, filter } from 'lodash';
import { useFirestore } from 'react-redux-firebase';
import { useSelector, useDispatch } from 'react-redux';
import * as Sentry from '@sentry/react';
import { useFirebase } from 'react-redux-firebase';
// library components
import { Divider, Box, Typography, Button } from '@material-ui/core';
// CR components
import ButtonSpinner from 'components/ButtonSpinner';
import BreakoutTopicAdmin from './BreakoutTopicAdmin';
import BreakoutRoomsAdmin from './BreakoutRoomsAdmin';
import CustomCheckbox from '../../CustomFormComponents/CustomCheckbox';
import TextInput from '../../CustomFormComponents/TextInput';
// selectors
import {
  getBreakoutsRooms,
  getBreakoutsTopic,
  breakoutsSessionSelector,
} from 'redux/selectors/Breakouts/rootBreakoutsSelector';
// actions
import { updateBreakout } from '../../../redux/actions/breakoutsActions';
import { enqueueErrorSnackbar } from 'redux/actions/notifierActions';
// utils
import { updateFields } from 'utils/Breakouts/breakoutsHelper';
import { showAppOverlaySpinner } from 'redux/actions/facilitatorSessionActions';
import CustomSelect from 'components/CustomFormComponents/CustomSelect';
import MuralWorkspaceSelect from 'components/CustomFormComponents/MuralWorkspaceSelect';
import { parseMuralURL } from 'utils/muralApiClient';
import { useFacilitatorSessionSelector } from 'redux/selectors/facilitatorSessionSelectors';

const BreakoutsFormAdmin = ({ hasStarted, sessionId, breakoutId, classes }) => {
  const [showButtonSpinner, setShowButtonSpinner] = useState(false);
  const firebase = useFirebase();
  const dispatch = useDispatch();
  const db = useFirestore();
  const session = useSelector(state => state.firestore.data.session);
  const topic = useSelector(getBreakoutsTopic);
  const rooms = useSelector(getBreakoutsRooms);
  const { participantsChooseRooms, maxRoomSize, muralURL, muralWorkspace, muralEnabled } =
    useSelector(breakoutsSessionSelector);
  const user = useSelector(state => state.firebase.profile);
  const { muralWorkspaceOptions } = useFacilitatorSessionSelector();
  const isMuralAuthorized = user.isMuralAuthorized;

  const getAuthUrl = () => {
    setShowButtonSpinner(true);

    firebase
      .functions()
      .httpsCallable('mural-getAuthUrl')({ redirectUrl: window.location.href })
      .then(res => {
        window.location.replace(res.data);
      })
      .catch(err => {
        dispatch(enqueueErrorSnackbar('Error getting Mural auth url'));
        Sentry.captureException(err);
        console.error(err);
      });
  };

  const handleChange = (e, values) => {
    const { name, value } = e.target;
    const fields = updateFields(name, value, values, { topic, rooms });
    const update = { ...fields };

    if (name === 'participantsChooseRooms' && value === 'true') {
      update.participantRoomAssignments = [];
    }

    dispatch(
      updateBreakout(breakoutId, update, async () => {
        if (!update.muralURL) {
          console.log('no updated mural url');
          return;
        }

        let newMurals = [];
        let newRoomData = [];

        const roomsWithoutMurals = filter(rooms, room => room.originalMuralURL !== update.muralURL);

        if (roomsWithoutMurals.length) {
          dispatch(showAppOverlaySpinner(true));

          try {
            const cloneRequest = await firebase.functions().httpsCallable('mural-clone')({
              meetingTitle: session.topic,
              activityName: topic.name,
              muralURL: update.muralURL,
              numberOfClones: roomsWithoutMurals.length,
              workspaceId: values.muralWorkspace,
            });

            newMurals = cloneRequest.data;

            newMurals.forEach((newMural, idx) => {
              const parsedMuralURL = parseMuralURL(newMural.visitorsSettings.link);

              newRoomData.push({
                ...roomsWithoutMurals[idx],
                muralURL: newMural.visitorsSettings.link,
                muralState: parsedMuralURL.state,
                originalMuralURL: update.muralURL,
              });
            });

            dispatch(
              updateBreakout(breakoutId, { rooms: newRoomData }, () => {
                dispatch(showAppOverlaySpinner(false));
              }),
            );
          } catch (error) {
            console.error(error);
            let message = error.message;

            if (message.includes('-')) {
              message = message.split(' - ')[1];
            }

            message = `Mural returned the following error: ${message}`;

            dispatch(enqueueErrorSnackbar(message, { autoHideDuration: 10000 }));
            dispatch(showAppOverlaySpinner(false));
          }
        }
      }),
    );
  };

  const debounceHandleChange = debounce(handleChange, 1000);

  return (
    <Formik
      initialValues={{
        topic: topic.name || '',
        description: topic.description || '',
        rooms: rooms,
        participantsChooseRooms: participantsChooseRooms || false,
        maxRoomSize: maxRoomSize || '',
        muralURL: muralURL || '',
        muralWorkspace: muralWorkspace || '',
        muralEnabled: muralEnabled || false,
      }}
      enableReinitialize
    >
      {({ values }) => (
        <Form className={classes.form} onChange={e => debounceHandleChange(e, values)}>
          <span className={classes.formSubtopic}>Options</span>
          <div className="mb-3">
            <CustomCheckbox
              name="participantsChooseRooms"
              label="allow particiapnts to choose their breakout room"
              type="checkbox"
              // disabled={hasStarted}
            />
          </div>
          {participantsChooseRooms && (
            <div className="mb-3">
              <TextInput
                name="maxRoomSize"
                type="number"
                inputProps={{ min: '0' }}
                label="Max room size"
                placeholder="leave empty for unlimited"
                // inputProps={{disabled: hasStarted}}
              />
            </div>
          )}
          <Divider className={classes.divider} />
          <BreakoutTopicAdmin hasStarted={hasStarted} classes={classes} topic={topic.name} />
          <div className="mb-3">
            <CustomCheckbox
              name="muralEnabled"
              label="enable Mural for Breakouts"
              type="checkbox"
              // disabled={hasStarted}
            />
          </div>
          {muralEnabled && (
            <>
              {user.isMuralAuthorized && (
                // <CustomSelect
                //   name="muralWorkspace"
                //   label="Mural Workspace"
                //   selectOptions={muralWorkspaceOptions}
                //   // we have to do this convoluted shit below because the Material UI select component doesn't
                //   // trigger the change event on the form and the change event is how the form is being submitted
                //   // in this case
                //   afterChange={(value) => { handleChange({target: {name: "muralWorkspace", value}}, values) }}
                // />
                <MuralWorkspaceSelect
                  name="muralWorkspace"
                  label="Mural Workspace"
                  afterChange={value => {
                    handleChange({ target: { name: 'muralWorkspace', value } }, values);
                  }}
                />
              )}
              <TextInput
                name="muralURL"
                type="text"
                inputProps={{ min: '0' }}
                label="Mural URL"
                helperText="The mural or template will be cloned once for each breakout room"
                disabled={!isMuralAuthorized || !values.muralWorkspace}
              />
              {!user.isMuralAuthorized && (
                <Box display="flex" flexDirection="column" alignItems="flex-start">
                  <Typography className="mb-1" color="textSecondary">
                    You must authorize your Mural account before using Mural for Breakouts
                  </Typography>
                  <Button
                    disabled={showButtonSpinner}
                    variant="contained"
                    color="primary"
                    className="mb-0"
                    onClick={getAuthUrl}
                    size="small"
                    style={{ minWidth: 164 }}
                  >
                    {!showButtonSpinner && 'Authorize Mural Account'}
                    {showButtonSpinner && <ButtonSpinner className="ml-0" />}
                  </Button>
                </Box>
              )}
            </>
          )}
          <Divider className={`${classes.divider} mt-4`} />
          <span className={classes.formSubtopic}>Rooms</span>
          <BreakoutRoomsAdmin
            hasStarted={hasStarted}
            rooms={values.rooms}
            classes={classes}
            breakoutId={breakoutId}
            muralEnabled={muralEnabled}
            muralURL={muralURL}
            muralWorkspace={muralWorkspace}
            name={topic.name}
          />
          {/* <div className={classes.center}>
            {!hasStarted && (
              <Button variant="outlined" className={classes.startBreakout} onClick={submitForm}>
                Start Breakout
                {submitting &&
                  <ButtonSpinner />
                }
              </Button>
            )}
          </div> */}
        </Form>
      )}
    </Formik>
  );
};

export default BreakoutsFormAdmin;
