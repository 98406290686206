import React from 'react';
import PropTypes from 'prop-types';
import { map, orderBy } from 'lodash';
// library components
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
// cr components
import NewSetButton from './Buttons/NewSetButton';

const useStyles = makeStyles(theme => ({
  cardSets: {
    minWidth: 150,
    borderRight: '1px solid #E3E3E3',
  },
  title: {
    marginBottom: 5,
    fontWeight: 300,
    fontSize: 12,
    color: theme.palette.grey[700],
  },
  setContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const useSetStyle = makeStyles(theme => ({
  setItem: {
    fontSize: 15,
    width: 120,
  },
  itemContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20,
    color: ({ isEdit }) => (isEdit ? theme.palette.primary.main : theme.palette.grey[700]),
    cursor: 'pointer',
  },
}));

// Render list of sets
const SetItem = ({ name, isEdit, setId, handleEditSet }) => {
  const classes = useSetStyle({ isEdit });
  return (
    <Box className={classes.itemContainer} onClick={() => handleEditSet(setId)}>
      <Typography className={classes.setItem}>{name || 'New Set'}</Typography>
      {isEdit && <ChevronRightIcon />}
    </Box>
  );
};

// Render list of sets and create 'New Set' button
const CardSets = ({ cardSets, handleCreateSet, handleEditSet }) => {
  const classes = useStyles();

  const renderSets = () =>
    map(orderBy(cardSets, 'order', 'desc'), ({ name, isEdit, setId }, idx) => (
      <SetItem
        key={`set-${idx}`}
        name={name}
        isEdit={isEdit}
        setId={setId}
        handleEditSet={handleEditSet}
      />
    ));

  return (
    <Box className={classes.cardSets}>
      <Typography className={classes.title}>Card Sets</Typography>
      <NewSetButton handleClick={handleCreateSet} />
      <Box className={classes.setContainer}>{renderSets()}</Box>
    </Box>
  );
};

CardSets.propTypes = {
  name: PropTypes.string, // set name
  isEdit: PropTypes.bool, // is set name edited?
  setId: PropTypes.string, // set ID
  handleEditSet: PropTypes.func, // edit set name
};

export default CardSets;
