import React from 'react';
import { useSelector } from 'react-redux';
import { includes, map, filter, has, sortBy, values, size, orderBy } from 'lodash';
import { makeStyles } from '@material-ui/styles';
import { DragDropContext } from 'react-beautiful-dnd';
// library components
import { Box } from '@material-ui/core';
// cr components
import Category from 'components/NoteAndCategorize/Participant/Category';
import AdminActivityAuxView from 'components/AdminActivityAuxView';
import CSVReportDownloadButton from 'components/CSVReportDownloadButton';
import NoteAndCategorizeReview from 'components/NoteAndCategorize/Participant/NoteAndCategorizeReview';
// selectors
import {
  useNoteAndCategorizeSelector,
  getResults,
} from 'redux/selectors/noteAndCategorizeSelectors';
// utils
import {
  createNoteAndCategorizeReportHeader,
  createNoteAndCategorizeReportData,
} from 'utils/noteAndCategorize';

const useStyles = makeStyles(theme => ({
  title: {
    paddingBottom: theme.spacing(3),
    fontWeight: 300,
    fontSize: 16,
    opacity: 0.9,
  },
  container: {
    width: '100%',
    padding: 30,
    margin: theme.spacing(2),
    marginBottom: 0,
  },
  category: {
    height: 'auto',
  },
  note: {
    width: 120,
  },
  noteResults: {
    width: 120,
    minHeight: 75,
  },
}));

/**
 * Renders categories and notes for the 'roundRobin', 'vote' and 'results' phases on the Facilitator side
 */
const NoteAndCategorizeResults = () => {
  const classes = useStyles();
  const noteAndCategorize = useNoteAndCategorizeSelector();
  const { config, participantData, state, name } = noteAndCategorize;
  const results = useSelector(getResults());

  const session = useSelector(state => state.firestore.data.session);

  if (
    !state?.activeView ||
    !includes(['roundRobin', 'review', 'vote', 'results'], state.activeView)
  ) {
    return null;
  }

  const participantNotes =
    state.activeView === 'roundRobin'
      ? filter(participantData.notes, {
          participantId: participantData?.participantRoundRobin
            ? participantData.participantRoundRobin.id
            : '',
        })
      : orderBy(
          values(participantData.notes),
          [note => size(note.votedFor), 'order', 'noteId'],
          ['desc', 'asc', 'asc'],
        );

  let sortField = 'value';
  const sortedCategories = sortBy(
    map(config.categories, category => {
      sortField = has(category, 'order') ? 'order' : 'value';
      return category;
    }),
    sortField,
  );

  // create all categories
  const renderCategories = categories => {
    return map(categories, (category, idx) => (
      <Category
        key={`${category.value}-${idx}`}
        id={category.id}
        droppableId={`${idx}`}
        value={category.value}
        notes={participantNotes}
        context={state.activeView === 'vote' ? 'roundRobin' : state.activeView}
        noteSize="small"
        votesPerCategory={config.votesPerCategory}
        results={results?.[category.id]}
        className={classes.category}
        noteClassName={state.activeView === 'results' ? classes.noteResults : classes.note}
      />
    ));
  };

  const renderTitle = () => {
    switch (state.activeView) {
      case 'roundRobin':
        const { participantRoundRobin } = noteAndCategorize.participantData;
        const participantName = participantRoundRobin ? participantRoundRobin.name : '';
        return `Reviewing notes from ${participantName}`;
      case 'review':
        return 'Review notes. Drag duplicate notes onto best version.';
      case 'vote':
        return 'Reviewing Categories';
      case 'results':
        return (config.roundRobinShare === 'off' && config.votesPerCategory === '0') ||
          config.votesPerCategory === '0'
          ? 'Reviewing Categories'
          : 'Voting Results';
      default:
        return '';
    }
  };

  // render headers for csv report
  const headers = createNoteAndCategorizeReportHeader();

  // render data for csv report
  const csvReport = createNoteAndCategorizeReportData(participantData.notes, config);

  const currentCategories =
    config.roundRobinShare === 'on' &&
    config.format === 'Serial' &&
    size(state.isCategorized) <= size(sortedCategories) &&
    state.activeView !== 'results'
      ? filter(sortedCategories, category => category.id === state.serialRoundRobin)
      : sortedCategories;

  return (
    <>
      <AdminActivityAuxView
        titleText={renderTitle()}
        activityName={name}
        allowPrint={state.activeView === 'results'}
        hasDownloadButton={state.activeView === 'results'}
        renderDownloadButton={() => (
          <CSVReportDownloadButton
            headers={headers}
            csvReport={csvReport}
            buttonText="Download Notes"
            filename="Results.csv"
            topic={session.topic}
            activity="NoteAndCategorize"
            activityName={name}
            withoutLabel={true}
          />
        )}
      >
        {state.activeView === 'review' ? (
          <NoteAndCategorizeReview />
        ) : (
          <DragDropContext>
            <Box className="w-100" display="flex" flexWrap="wrap">
              {renderCategories(currentCategories)}
            </Box>
          </DragDropContext>
        )}
      </AdminActivityAuxView>
    </>
  );
};

export default NoteAndCategorizeResults;
