import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { delay, find } from 'lodash';
// library components
import { Box, Typography, makeStyles } from '@material-ui/core';
// cr components
import ActivityActionButton from 'components/CustomButtons/ActivityActionButton';
import ItemDescription from 'components/Giveaway/Admin/ItemDescription';
import GiveawayDownloadEntires from 'components/Giveaway/Admin/GiveawayDownloadEntires';
// selectors
import {
  useGiveawaySelector,
  getWinnerParticipant,
  getActiveParticipants,
  getGiveawayResults,
} from 'redux/selectors/giveawaySelectors';
// actions
import {
  addWinnerParticipant,
  updateNumberWinner,
  updateRevealResults,
  addNotSelectingWinner,
  updateActivityView,
} from 'redux/actions/giveawayActions';
// utils
import { getRandomWinner } from 'utils/giveaway';

const useStyles = makeStyles({
  text: {
    marginBottom: 50,
    fontSize: 18,
    fontWeight: 300,
    textAlign: 'center',
  },
});

/**
 * Giveaway view for the 'pickWinner' phase on the Facilitator side
 */
const GiveawayAdminPickWinner = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const giveaway = useGiveawaySelector();
  const {
    id,
    name,
    config: { itemName, itemDescription, ofGiveaways },
    state: { numberWinner, notSelectingWinner },
    participantData: { giveawayParticipants },
  } = giveaway;

  const session = useSelector(state => state.firestore.data.session);

  const winnerParticipant = useSelector(getWinnerParticipant(numberWinner));
  // get the list who has not yet won
  const activeParticipants = useSelector(getActiveParticipants());

  // are all winners selected?
  const isFinishActivity = numberWinner === ofGiveaways;

  // set button text
  let activityButtonText = isFinishActivity ? 'Finish' : `Pick Winner #${numberWinner + 1}`;
  if (ofGiveaways === 1) {
    activityButtonText = 'Download Entries';
  }

  // set winner text
  let winnersText = winnerParticipant
    ? `Winner ${ofGiveaways > 1 ? `${numberWinner} of ${ofGiveaways}` : ''}: ${winnerParticipant.participantName}!`
    : `Selecting Winner ${ofGiveaways > 1 ? `${numberWinner} of ${ofGiveaways}` : ''}`;

  if (notSelectingWinner) {
    winnersText = 'No winner';
  }

  // if all winners selected, then display text with all winners
  if (isFinishActivity && ofGiveaways === 1 && winnerParticipant) {
    winnersText = `Winner: ${winnerParticipant.participantName}!`;
  }

  const results = useSelector(getGiveawayResults());

  // selects a participant from the list who has not yet won
  const getWinner = () => {
    const winnerParticipant = getRandomWinner(activeParticipants);
    if (winnerParticipant) {
      dispatch(addWinnerParticipant(id, winnerParticipant, numberWinner + 1));
    }
    if (numberWinner + 1 >= ofGiveaways) {
      const winnerParticipantData = find(
        giveawayParticipants,
        participant => participant.participantId === winnerParticipant,
      );
      if (winnerParticipantData) {
        results.push({
          winner: true,
          round: numberWinner + 1,
          participantName: winnerParticipantData.participantName,
          participantEmail: winnerParticipantData.participantEmail,
        });
      } else {
        dispatch(addNotSelectingWinner(id, true));
      }
      dispatch(updateRevealResults(id, results));
    }
  };

  // select next winner
  const onSelectNextWinner = () => {
    if (!isFinishActivity) {
      delay(getWinner, 5000);
      dispatch(updateNumberWinner(id, numberWinner + 1));
      dispatch(addNotSelectingWinner(id, false));
    } else {
      dispatch(updateActivityView('results', id));
    }
  };

  return (
    <Box className="d-flex flex-column justify-content-center">
      <ItemDescription itemName={itemName} itemDescription={itemDescription} />
      <Typography className={classes.text}>{winnersText}</Typography>
      {isFinishActivity && ofGiveaways === 1 ? (
        <GiveawayDownloadEntires
          topic={session.topic}
          activity="Giveaway"
          activityName={name}
          filename="Results.csv"
          buttonText={activityButtonText}
        />
      ) : (
        <ActivityActionButton
          text={activityButtonText}
          className="mb-2 mt-1"
          onClick={onSelectNextWinner}
        />
      )}
    </Box>
  );
};

export default GiveawayAdminPickWinner;
