import React from 'react';
// cr components
import ActivityPromptAdmin from 'components/ActivityPromptAdmin';
// config
import { STORY_FLOW_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';
// selectors
import { useStoryFlowSelector } from 'redux/selectors/storyFlowSelectors';

/**
 * Story Flow view for the 'results' phase on the Facilitator side
 */
const StoryFlowAdminResults = () => {
  const storyFlow = useStoryFlowSelector();
  const { id, prompt } = storyFlow;

  return <ActivityPromptAdmin prompt={prompt} collection={CONFIG.collection} activityId={id} />;
};

export default StoryFlowAdminResults;
