import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { filter } from 'lodash';
// library components
import { Box } from '@material-ui/core';
// cr components
import ActivityParticipantDetails from 'components/ActivityParticipantDetails';
import ActivityPromptAdmin from 'components/ActivityPromptAdmin';
import ActivityActionButton from 'components/CustomButtons/ActivityActionButton';
// selectors
import {
  useNoteAndMapSelector,
  getParticipantsHaveCreated,
} from 'redux/selectors/noteAndMapSelectors';
// actions
import { updateActivityView } from 'redux/actions/noteAndMapActions';
// config
import { NOTE_AND_MAP_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';
// utils
import { makeParticipantDetailsHeaderText } from 'utils/makeParticipantDetailsHeaderText';

/**
 * Note & Map view for the 'review' phase on the Facilitator side
 */
const NoteAndMapAdminReview = () => {
  const dispatch = useDispatch();
  const {
    id,
    prompt,
    participantData: { participants },
  } = useNoteAndMapSelector();
  const participantsCompletedReview = useSelector(getParticipantsHaveCreated());
  const participantsHaveSharedCount = filter(participantsCompletedReview, {
    completed: true,
  }).length;

  const participantsHaveSharedCountText = makeParticipantDetailsHeaderText(
    participantsHaveSharedCount,
    participants.length,
    'Participants sharing',
    true,
  );
  return (
    <Box className="d-flex flex-column justify-content-center">
      <ActivityPromptAdmin
        prompt={prompt}
        collection={CONFIG.collection}
        activityId={id}
        className="mb-5"
      />
      <ActivityParticipantDetails
        headerText={participantsHaveSharedCountText}
        hasCompletedLabel="Reviewed"
        hasntCompletedLabel="Hasn’t Reviewed"
        participantsCompletedStatus={participantsCompletedReview}
      />
      <ActivityActionButton
        text="Start Voting"
        onClick={() => {
          dispatch(updateActivityView('vote', id));
        }}
        className="mb-2 mt-1"
      />
    </Box>
  );
};

export default NoteAndMapAdminReview;
