import React from 'react';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
// cr components
import ActivityTemplate from 'components/ActivityTemplate/ActivityTemplate';
import NoteAndCategorizeParticipantWrite from 'components/NoteAndCategorize/Participant/NoteAndCategorizeParticipantWrite';
import NoteAndCategorizeParticipantVote from 'components/NoteAndCategorize/Participant/NoteAndCategorizeParticipantVote';
import NoteAndCategorizeParticipantResults from 'components/NoteAndCategorize/Participant/NoteAndCategorizeParticipantResults';
import NoteAndCategorizeParticipantRoundRobin from 'components/NoteAndCategorize/Participant/NoteAndCategorizeParticipantRoundRobin';
import NoteAndCategorizeParticipantReview from 'components/NoteAndCategorize/Participant/NoteAndCategorizeParticipantReview';
import ParticipantActivityContent from 'components/ParticipantActivityContent';
// selectors
import { useNoteAndCategorizeSelector } from 'redux/selectors/noteAndCategorizeSelectors';
// constants
import { NOTE_AND_CATEGORIZE_DERIVATIVE_CONFIG_LIST } from '@voltage-control/control-room-activities-config';

//add new view for all action
const views = {
  categorize: NoteAndCategorizeParticipantWrite,
  vote: NoteAndCategorizeParticipantVote,
  review: NoteAndCategorizeParticipantReview,
  roundRobin: NoteAndCategorizeParticipantRoundRobin,
  results: NoteAndCategorizeParticipantResults,
};

const NoteAndCategorizeParticipant = ({
  match: {
    params: { activityId },
  },
}) => {
  const noteAndCategorize = useNoteAndCategorizeSelector();

  // hooks up a firestore listener to Redux
  useFirestoreConnect(() => [
    {
      collection: 'noteAndCategorize',
      doc: `${activityId}`,
      storeAs: 'noteAndCategorize',
    },
  ]);

  // ensure all of the data we need is loaded
  if (!isLoaded(noteAndCategorize) || activityId !== noteAndCategorize?.id) {
    return null;
  }

  const maxVotesRerCategory = noteAndCategorize.config.votesPerCategory;

  // get current view
  const Component = views[noteAndCategorize.state.activeView];
  // get current derived noteAndCategorize
  const config =
    NOTE_AND_CATEGORIZE_DERIVATIVE_CONFIG_LIST[noteAndCategorize.config?.derivative || 'general'];

  const renderViewLabel = activeView => {
    switch (activeView) {
      case 'categorize':
        return config.participantViewLabels.categorize;
      case 'vote':
        return `Cast Votes (${maxVotesRerCategory} max per category)`;
      case 'roundRobin':
        const { participantRoundRobin } = noteAndCategorize.participantData;
        const participantName = participantRoundRobin ? participantRoundRobin.name : '';
        return `Reviewing notes from ${participantName}`;
      case 'review':
        return config.participantViewLabels.review;
      case 'results':
        const { roundRobinShare, votesPerCategory } = noteAndCategorize.config;
        return (roundRobinShare === 'off' && votesPerCategory === '0') || votesPerCategory === '0'
          ? 'Reviewing Categories'
          : config.participantViewLabels.results;
      default:
        return '';
    }
  };

  return (
    <ActivityTemplate type={config.niceName} name={noteAndCategorize.name}>
      <ParticipantActivityContent
        activityData={noteAndCategorize}
        viewLabel={renderViewLabel(noteAndCategorize.state.activeView)}
        activityConfig={config}
      >
        <Component />
      </ParticipantActivityContent>
    </ActivityTemplate>
  );
};

export default NoteAndCategorizeParticipant;
