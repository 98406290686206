import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import './formDialog.scss';

const useStyles = makeStyles(theme => ({
  closeIcon: {
    position: 'relative',
    right: `-${theme.spacing(2)}px`,
    top: `-${theme.spacing(1)}px`,
  },
}));

export default function FormDialog({
  handleClose,
  isOpen,
  children,
  title,
  classes,
  titleClasses,
  contentClasses,
  isTitleHasCloseIcon,
  centerTitle, //added a prop that controls if the Title is centered
}) {
  const iconClasses = useStyles();
  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="sm"
        classes={classes}
        className="form-dialog"
      >
        {isTitleHasCloseIcon ? (
          <DialogTitle disableTypography className="pb-0">
            <div className="d-flex justify-content-between">
              {/* When 'centerTitle' is set, make the Title take the entire remaining space, and center the Text  */}
              <Typography variant="h6" className={centerTitle ? 'flex-grow-1 text-center' : ''}>
                {title}
              </Typography>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                className={iconClasses.closeIcon}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </DialogTitle>
        ) : (
          <DialogTitle classes={titleClasses}>{title}</DialogTitle>
        )}
        <DialogContent classes={contentClasses}>{children}</DialogContent>
      </Dialog>
    </div>
  );
}

FormDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};
