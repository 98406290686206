import { map, join, forEach, values } from 'lodash';

/**
 * Creates the array for Report Header
 *
 */
export const create2510ReportHeader = () => {
  return [
    { label: 'Idea', key: 'idea' },
    { label: 'Score', key: 'score' },
  ];
};

/**
 * Creates the data for Report
 *
 */
export const create2510ReportData = ideas => {
  return map(ideas, idea => {
    return {
      score: idea.score,
      idea: idea.idea,
    };
  });
};

export const create2510Report = activityData => {
  const headers = create2510ReportHeader();
  const data = create2510ReportData(
    ideasWithScore(activityData?.ideas, activityData?.voteAssignments),
  );
  return { headers, data };
};

export const ideasWithScore = (ideas, voteAssignments) => {
  const voteAssignmentsValues = values(voteAssignments);
  return ideas.map(idea => {
    const votes = [];
    voteAssignmentsValues.forEach(round => {
      const assignment = round.find(assignment => assignment.ideaId === idea.id);
      votes.push(assignment?.vote || 0);
    });

    // check if there is an idea which has missing votes.
    const missingVotes = votes.filter(vote => !vote);
    const presentVotes = votes.filter(Boolean);

    // if has missing votes, take max vote for current idea and fill missing spots.
    if (missingVotes.length) {
      const maxVote = Math.max.apply(null, votes);
      missingVotes.forEach(() => {
        presentVotes.push(maxVote);
      });
    }

    const score = presentVotes.reduce((sum, curr) => sum + curr, 0);
    return { ...idea, score };
  });
};
