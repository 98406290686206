import React from 'react';
// library components
import Confetti from 'react-confetti';
import { makeStyles, Box } from '@material-ui/core';

const useStyles = makeStyles({
  confettiBox: {
    position: 'absolute',
    top: 35,
    left: -40,
    zIndex: 1,
  },
});

/**
 * Renders Confetti
 */
const WinnerConfetti = () => {
  const classes = useStyles();
  return (
    <Box className={classes.confettiBox}>
      <Confetti width={520} height={430} opacity={0.5} gravity={0.06} />
    </Box>
  );
};

export default WinnerConfetti;
