import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import { useFirestore } from 'react-redux-firebase';
import { reject } from 'lodash';
import { usePopupState } from 'material-ui-popup-state/hooks';
// cr components
import TextInput from '../../CustomFormComponents/TextInput';
import TextArea from '../../CustomFormComponents/TextArea';
// external components
import { IconButton, Tooltip, makeStyles, Button } from '@material-ui/core';
import LaunchIcon from '@material-ui/icons/Launch';
import DeleteIcon from '@material-ui/icons/Delete';
// selectors
import { useMeetingRoomSelector } from '../../../redux/selectors/meetingRoomSelectors';
import {
  getBreakoutsParticipantsForRoom,
  breakoutsSessionSelector,
} from 'redux/selectors/Breakouts/rootBreakoutsSelector';
// actions
// import {
//   joinMeetingRoom,
//   joinMainRoom
// } from '../../../redux/actions/meetingRoomActions';
// cr utils
import { createBreakoutMeetRoomId } from '../../../utils/createMeetRoomIds';

const useButtonsStyles = makeStyles(theme => ({
  deleteButton: {
    padding: 8,
    marginLeft: -12,
    marginRight: theme.spacing(1),
  },
  deleteIcon: {
    color: theme.palette.primary.main,
  },
}));

const BreakoutsRoomAdmin = ({ room, hasStarted, roomNumber = 1, index, classes, muralEnabled }) => {
  const buttonClasses = useButtonsStyles();
  const participants = useSelector(getBreakoutsParticipantsForRoom(room.id));
  const breakoutsSession = useSelector(breakoutsSessionSelector);
  const session = useSelector(state => state.firestore.data.session);
  const db = useFirestore();
  const dispatch = useDispatch();
  const { roomId } = useMeetingRoomSelector();
  const actionsMenuState = usePopupState({
    variant: 'popover',
    popupId: `${room.id}-actions-menu`,
  });
  const meetingRoomId = createBreakoutMeetRoomId(session.id, room.id);

  const deleteRoom = () => {
    const breakoutDocRef = db.doc(`breakouts/${breakoutsSession.id}`);

    return db
      .runTransaction(function (transaction) {
        return transaction.get(breakoutDocRef).then(function (breakoutDoc) {
          const breakoutData = breakoutDoc.data();
          const newRoomAssignments = reject(breakoutData.participantRoomAssignments, {
            roomId: room.id,
          });

          transaction.set(
            breakoutDocRef,
            {
              rooms: db.FieldValue.arrayRemove(room),
              participantRoomAssignments: newRoomAssignments,
            },
            { merge: true },
          );
        });
      })
      .catch(function (error) {
        console.log('Transaction failed: ', error);
      });
  };

  const renderParticipants = participants => {
    if (!hasStarted) {
      return null;
    }

    return (
      <div>
        <span className={classes.participantsText}>Participants: </span>
        {participants.map((participant, idx) => {
          const lastParticipant = idx === participants.length - 1;
          const comma = lastParticipant ? '' : ', ';
          return (
            <span
              key={participant.id}
              className={clsx(classes.participantsList, {
                'participant-inactive': !participant.connected,
              })}
            >
              {participant.name}
              {comma}
            </span>
          );
        })}
      </div>
    );
  };
  return (
    <div className={classes.room}>
      <TextInput
        name={`rooms.${index}.title`}
        placeholder={`Breakout ${roomNumber}`}
        className="mb-2"
      />
      <TextArea
        name={`rooms.${index}.description`}
        placeholder="Enter breakout instructions here..."
      />
      {muralEnabled && room.muralURL && (
        <div className="dont-break-out mb-1">
          <a
            href={room.muralURL}
            className="d-flex align-items-center"
            target="_blank"
            rel="noopener noreferrer"
          >
            Mural Board <LaunchIcon fontSize="inherit" style={{ marginLeft: 2 }} />
          </a>
        </div>
      )}
      <div className="mb-1">{renderParticipants(participants)}</div>
      {breakoutsSession?.rooms.length > 1 && (
        <Tooltip title="Delete Room" arrow placement="bottom">
          <IconButton className={buttonClasses.deleteButton} onClick={deleteRoom}>
            <DeleteIcon className={buttonClasses.deleteIcon} />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
};

export default BreakoutsRoomAdmin;
