import React from 'react';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
// cr components
import ActivityTemplate from 'components/ActivityTemplate/ActivityTemplate';
import ParticipantActivityContent from 'components/ParticipantActivityContent';
import GiveawayParticipantWrite from 'components/Giveaway/Participant/GiveawayParticipantWrite';
import GiveawayParticipantPickWinner from 'components/Giveaway/Participant/GiveawayParticipantPickWinner';
import GiveawayParticipantResults from 'components/Giveaway/Participant/GiveawayParticipantResults';
// selectors
import { useGiveawaySelector } from 'redux/selectors/giveawaySelectors';
// constants
import { GIVEAWAY_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';

//add new view for all action
const views = {
  write: GiveawayParticipantWrite,
  pickWinner: GiveawayParticipantPickWinner,
  results: GiveawayParticipantResults,
};

const GiveawayParticipant = ({
  match: {
    params: { activityId },
  },
}) => {
  const giveaway = useGiveawaySelector();

  // hooks up a firestore listener to Redux
  useFirestoreConnect(() => [
    {
      collection: 'giveaway',
      doc: `${activityId}`,
      storeAs: 'giveaway',
    },
  ]);

  // ensure all of the data we need is loaded
  if (!isLoaded(giveaway) || activityId !== giveaway?.id) {
    return null;
  }

  const Component = views[giveaway.state.activeView];

  return (
    <ActivityTemplate type="Giveaway" name={giveaway.name}>
      <ParticipantActivityContent activityData={giveaway} activityConfig={CONFIG} viewLabel="">
        <Component />
      </ParticipantActivityContent>
    </ActivityTemplate>
  );
};

export default GiveawayParticipant;
