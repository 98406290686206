export const SLIDES_SESSIONS_LOADING = 'SLIDES_SESSIONS_LOADING';
export const SLIDES_SESSIONS_SUCCESS = 'SLIDES_SESSIONS_SUCCESS';
// export const SLIDES_SESSION_SUCCESS = "SLIDES_SESSION_SUCCESS";
export const SLIDES_SESSIONS_ERROR = 'SLIDES_SESSIONS_ERROR';

export const SLIDES_SESSION_CREATING = 'SLIDES_SESSION_CREATING';
export const SLIDES_SESSION_SUCCESS = 'SLIDES_SESSION_SUCCESS';
export const SLIDES_SESSION_ERROR = 'SLIDES_SESSION_ERROR';
export const SLIDES_SESSION_DELETED = 'SLIDES_SESSION_DELETED';

export const SLIDES_SESSION_WATCH_UPDATED = 'SLIDES_SESSION_WATCH_UPDATED';
