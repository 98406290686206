import { useShallowEqualSelector } from '../utils';

export const useAuthSelector = () => {
  return useShallowEqualSelector(({ googleSlidesAuth: { accessToken, tokensLoading } }) => {
    return {
      accessToken,
      tokensLoading,
    };
  });
};
