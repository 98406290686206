import React from 'react';
import GridContainer from '../../../Grid/GridContainer';
import GridItem from '../../../Grid/GridItem';
import PageContent from '../../../PageContent/PageContent';
import PageContentBody from '../../../PageContent/PageContentBody';

import '../../breakoutStyles.scss';

const BreakoutsTemplateAdmin = ({ children }) => {
  return (
    <GridContainer
      direction="row"
      justify="center"
      alignItems="center"
      className="session-breakouts"
    >
      <GridItem sm={12} md={12} lg={12} xl={12}>
        {children}
      </GridItem>
    </GridContainer>
  );
};

export default BreakoutsTemplateAdmin;
