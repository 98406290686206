import React from 'react';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';

// cr components
import ActivityTemplate from '../../../components/ActivityTemplate/ActivityTemplate';
import BrainwritingParticipantWrite from '../../../components/Brainwriting/Participant/BrainwritingParticipantWrite';
import BrainwritingParticipantResults from '../../../components/Brainwriting/Participant/BrainwritingParticipantResults';

// selectors
import { useBrainwritingSelector } from '../../../redux/selectors/brainwritingSelectors';

import './participantBrainwritting.scss';

const views = {
  write: BrainwritingParticipantWrite,
  results: BrainwritingParticipantResults,
};

const ParticipantBrainwriting = ({
  match: {
    params: { activityId },
  },
}) => {
  const brainwriting = useBrainwritingSelector();

  useFirestoreConnect(() => [
    {
      collection: 'brainwriting',
      doc: `${activityId}`,
      storeAs: 'brainwriting',
    },
  ]);

  if (!isLoaded(brainwriting) || activityId !== brainwriting?.id) {
    return null;
  }

  const Component = views[brainwriting.activeView];

  return (
    <ActivityTemplate type="Brain Writing" name={brainwriting.name}>
      <Component />
    </ActivityTemplate>
  );
};

export default ParticipantBrainwriting;
