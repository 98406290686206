import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isEmpty, isLoaded, useFirestoreConnect } from 'react-redux-firebase';

import AdminActivityTemplate from 'components/AdminActivityTemplate';
import UserJourneyMomentsAdminCreate from 'components/UserJourneyMoments/Admin/UserJourneyMomentsAdminCreate';
import UserJourneyMomentsAdminKeyMoment from 'components/UserJourneyMoments/Admin/UserJourneyMomentsAdminKeyMoment';
import UserJourneyMomentsAdminVote from 'components/UserJourneyMoments/Admin/UserJourneyMomentsAdminVote';
import UserJourneyMomentsAdminResults from 'components/UserJourneyMoments/Admin/UserJourneyMomentsAdminResults';
import UserJourneyMomentsAdminSuperVote from 'components/UserJourneyMoments/Admin/UserJourneyMomentsAdminSuperVote';
import UserJourneyMomentsAdminReview from 'components/UserJourneyMoments/Admin/UserJourneyMomentsAdminReview';

import { useUserJourneyMomentsSelector } from 'redux/selectors/userJourneyMomentsSelectors';

import { USER_JOURNEY_MOMENTS_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';

const views = {
  create: UserJourneyMomentsAdminCreate,
  keyMoment: UserJourneyMomentsAdminKeyMoment,
  vote: UserJourneyMomentsAdminVote,
  review: UserJourneyMomentsAdminReview,
  superVote: UserJourneyMomentsAdminSuperVote,
  results: UserJourneyMomentsAdminResults,
};

const UserJourneyMomentsAdmin = () => {
  const routeParams = useParams();
  const userJourneyMoments = useUserJourneyMomentsSelector();
  const session = useSelector(state => state.firestore.data.session);
  const meetingParticipants = useSelector(state => state.firestore.data.participants);

  // hooks up a firestore listener to Redux
  useFirestoreConnect(() => [
    {
      collection: 'userJourneyMoments',
      doc: `${routeParams.activityId}`,
      storeAs: 'userJourneyMoments',
    },
  ]);

  // ensure all of the data we need is loaded
  if (
    !isLoaded(session) ||
    isEmpty(session) ||
    !isLoaded(userJourneyMoments) ||
    isEmpty(userJourneyMoments) ||
    !isLoaded(meetingParticipants) ||
    routeParams.activityId !== userJourneyMoments?.id
  ) {
    return null;
  }

  const CurrentView = views[userJourneyMoments.state.activeView];

  return (
    <AdminActivityTemplate
      activityConfig={CONFIG}
      activityData={userJourneyMoments}
      session={session}
      CurrentView={CurrentView}
    />
  );
};

export default UserJourneyMomentsAdmin;
