import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { each } from 'lodash';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { orderBy } from 'lodash';
// 3rd party components
import ChartistGraph from 'react-chartist';
import { Typography, Box } from '@material-ui/core';
// selectors
import { getQuestion } from 'redux/selectors/assessmentSelectors';
import { numberToPercent } from 'utils/math';

const useChartStyles = makeStyles(theme => ({
  stroke: {
    stroke: 'white',
    strokeWidth: 2,
  },
  'color-1': {
    fill: theme.palette.error.main,
  },
  'color-2': {
    fill: theme.palette.warning.main,
  },
  'color-3': {
    fill: theme.palette.primary.main,
  },
  'color-4': {
    fill: theme.palette.satisfied.main,
  },
  'color-5': {
    fill: 'purple',
  },
  'ct-chart': { maxWidth: 220 },
}));

const useStyles = makeStyles(theme => ({
  dot: {
    display: 'block',
    content: "' '",
    width: 14,
    height: 14,
    borderRadius: '50%',
  },
  label: {
    marginRight: theme.spacing(1) * 0.5,
  },
  'color-1': {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },
  'color-2': {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.main,
  },
  'color-3': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
  },
  'color-4': {
    backgroundColor: theme.palette.satisfied.main,
    color: theme.palette.satisfied.main,
  },
  'color-5': {
    backgroundColor: 'purple',
    fill: 'purple',
  },
}));

const LinearScaleQuestionResultsSummary = ({ questionId, stacked }) => {
  const { answerCounts, answers } = useSelector(getQuestion(questionId));
  const chartClasses = useChartStyles({ answerCounts });
  const classes = useStyles();
  const hasAnswers = answerCounts.filter(answer => answer.count > 0).length > 0;

  const chartOptions = {
    showLabel: false,
  };

  const chartData = {
    series: [],
  };

  each(orderBy(answerCounts, ['answer'], ['asc']), (answer, value) => {
    const name = answer.answerLabel
      ? `${answer.answer}: ${answer.answerLabel}`
      : `${answer.answer}:`;

    chartData.series.push({
      value: answer.count,
      name,
      meta: answer.answer,
      className: cx(chartClasses[`color-${answer.answer}`], chartClasses.stroke),
    });
  });

  return (
    <Box
      display="flex"
      flexDirection={stacked ? 'column' : 'row'}
      justifyContent="center"
      className={cx('linear-scale-result', {
        stacked: stacked,
      })}
    >
      <Box style={{ width: '100%', maxWidth: '220px' }}>
        {hasAnswers ? (
          <Box display="flex" justifyContent="center" mb={2}>
            <ChartistGraph
              data={chartData}
              options={chartOptions}
              type="Pie"
              className={chartClasses['ct-chart']}
            />
          </Box>
        ) : (
          <Box display="flex" justifyContent="center" mb={2}>
            <Box
              style={{
                content: ' ',
                backgroundColor: '#e8e8e8',
                width: 146,
                height: 146,
                borderRadius: '50%',
              }}
            />
          </Box>
        )}
      </Box>

      <Box
        display="flex"
        justifyContent="center"
        alignItems={stacked ? 'center' : 'flex-start'}
        flexDirection={stacked ? 'row' : 'column'}
        whiteSpace="nowrap"
      >
        {chartData.series.map((dataPoint, idx) => (
          <Box display="flex" alignItems="center" mr={2} key={`dataPoint-${idx}`}>
            <div className={cx(classes.dot, classes[`color-${dataPoint.meta}`], 'mr-1')} />
            <Typography
              className={cx(classes[`color-${dataPoint.meta}`], classes.label, 'fw-500 bg-white')}
            >
              {dataPoint.name}
            </Typography>
            <Typography style={{ color: '#666666', fontWeight: '200' }}>
              {dataPoint.value === 0
                ? '0'
                : numberToPercent(Number(dataPoint.value) / answers.length)}
              %
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

LinearScaleQuestionResultsSummary.propTypes = {
  questionId: PropTypes.string.isRequired,
  stacked: PropTypes.bool,
};

export default React.memo(LinearScaleQuestionResultsSummary);
