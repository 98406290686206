import React from 'react';
import { PropTypes } from 'prop-types';
// 3rd party components
import { Box, Typography } from '@material-ui/core';
// cr components
import AssessmentQuestionResults from '../Shared/QuestionResults';

/**
 * Render participant view of single question result
 */
const ParticipantQuestionResult = ({ currentQuestion }) => {
  return (
    <Box className="w-100">
      <Typography className="fw-300 mb-3 text-center">Reviewing Results</Typography>
      <AssessmentQuestionResults
        questionId={currentQuestion.id}
        questionType={currentQuestion.data.questionType}
      />
    </Box>
  );
};

ParticipantQuestionResult.propTypes = {
  currentQuestion: PropTypes.object.isRequired,
};

export default ParticipantQuestionResult;
