import React from 'react';
import cx from 'classnames';
import { useField } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
// 3rd party components
import { Card, CardContent, Typography } from '@material-ui/core';
// cr components
import RadioButtonGroup from 'components/CustomFormComponents/RadioButtonGroup';

const useStyles = makeStyles({
  card: {
    border: '1px solid #D6D6D6',
  },
});

const SingleChoice = ({ name, choices }) => {
  const [field] = useField(name);
  const classes = useStyles();

  return (
    <div className="d-flex flex-column align-items-center w-100">
      <Typography className="mb-1 fw-300">Select your answer</Typography>
      <Card className={cx('mb-2 w-100', classes.card)}>
        <CardContent>
          <RadioButtonGroup {...field} label="" values={choices} stacked />
        </CardContent>
      </Card>
    </div>
  );
};

export default SingleChoice;
