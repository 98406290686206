import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'react-redux-firebase';
import { map, filter } from 'lodash';

import { makeStyles } from '@material-ui/styles';

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Typography from '@material-ui/core/Typography';
import PageContent from 'components/PageContent/PageContent';
import PageContentHeader from 'components/PageContent/PageContentHeader';
import PageContentBody from 'components/PageContent/PageContentBody';
import Button from 'components/CustomButtons/Button.js';

//staffing components
import TabToggler from 'components/Staffing/TabToggler';
import ContractorCard from 'components/Staffing/Contractors/ContractorCard/index.js';
import CreateContractorDialog from 'components/Dialogs/Staffing/CreateContractorDialog';
import AddContractorToJobDialog from 'components/Dialogs/Staffing/AddContractorToJobDialog';
import ContractorFilter from 'components/Staffing/Contractors/ContractorFilter/ContractorFilter';

//actions & selectors
import { openCreateContractorDialog } from 'redux/actions/staffingActions';
import { selectContractors } from 'redux/selectors/staffing/getContractor';

const useStyles = makeStyles({
  pageHeader: {
    paddingBottom: '0px !important',
  },
  gridItem: {
    padding: '0 10px !important',
  },
  createContractorButton: {
    height: 36,
  },
  tabWrapper: {
    marginLeft: 15,
  },
  filterWrapper: {
    marginLeft: 5,
    marginRight: 15,
  },
});

const ContractorsList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const getContractors = selectContractors();
  const { contractors, isLoaded } = useSelector(state => getContractors(state));

  //Active Tab - Active or InActive
  const [activeTab, setActiveTab] = useState(0);

  const openCreateContractor = () => {
    dispatch(openCreateContractorDialog(true));
  };

  if (!isLoaded) {
    return null;
  }

  const displayedContractors = filter(contractors, {
    isActive: activeTab === 0,
  }); //Filter the Contractors by Active/Inactive.

  return (
    <div>
      <GridContainer direction="row" justify="center" alignItems="center">
        <GridItem xs={12}>
          <PageContent>
            <PageContentHeader className={classes.pageHeader}>
              <Typography variant="h5" className="page-content-title">
                Staff
              </Typography>
              <div className="right-col">
                <Button
                  color="primary"
                  onClick={openCreateContractor}
                  className={classes.createContractorButton}
                  size="sm"
                >
                  New Staff
                </Button>
              </div>

              <div className={classes.tabWrapper}>
                <TabToggler
                  labels={['Active', 'Inactive']}
                  activeTab={activeTab}
                  onChangeTab={setActiveTab}
                />
              </div>
            </PageContentHeader>
            <PageContentBody>
              <div className={classes.filterWrapper}>
                <ContractorFilter />
              </div>

              <GridContainer className="w-100 m-0">
                {isEmpty(displayedContractors) ? (
                  <Typography style={{ fontSize: 18 }} className="w-100 text-center mt-4">
                    No {activeTab === 0 ? 'Active' : 'Inactive'} Staff
                  </Typography>
                ) : null}

                {map(displayedContractors, (contractor, idx) => {
                  return (
                    <GridItem
                      xs={12}
                      sm={6}
                      md={6}
                      lg={4}
                      key={`contractor-card-${idx}`}
                      style={{ marginBottom: 30 }}
                      className={classes.gridItem}
                    >
                      <ContractorCard id={contractor.id} />
                    </GridItem>
                  );
                })}
              </GridContainer>
            </PageContentBody>
          </PageContent>
        </GridItem>
      </GridContainer>

      <CreateContractorDialog />
      <AddContractorToJobDialog />
    </div>
  );
};

export default ContractorsList;
