import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

// library components
import { Typography, makeStyles, IconButton } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const useStyles = makeStyles({
  header: {
    color: '#093B75',
    fontSize: 15,
    paddingBottom: 20,
  },
  slide: {
    width: '80%',
    filter: ' drop-shadow(0px 3px 15px rgba(39, 58, 124, 0.2))',
  },
  button: {
    position: 'relative',
    background: '#093B75',
    color: 'white',
    borderRadius: '5px',
    minWidth: '40px',
    minHeight: '80px',
    zIndex: 10,
    '&:hover': {
      background: '#093B75',
    },
    '&:disabled': {
      background: 'rgba(9, 59, 117, 0.2)',
      pointerEvents: 'auto',
    },
  },
  buttonLeft: {
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
  },
  buttonRight: {
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0,
  },
  smallSliderRight: {
    position: 'relative',
    right: ({ isSmallScreen }) => (isSmallScreen ? 0 : 30),
    zIndex: 10,
  },
  smallSliderLeft: {
    position: 'relative',
    left: ({ isSmallScreen }) => (isSmallScreen ? 0 : 30),
    zIndex: 10,
  },
});

/**
 * Renders the slider for images with different sizes and styles.
 */
export default function Slider({
  slides,
  className,
  size,
  isUserControl,
  onLeftButton,
  onRightButton,
  withoutControlButtons,
  startFrom,
  textClassName,
  buttonClassName,
  leftIconClassName,
}) {
  const isSmallScreen = useMediaQuery('(max-width: 767px)');
  const classes = useStyles({ isSmallScreen });
  const [index, setIndex] = useState(startFrom || 0);

  useEffect(() => {
    setIndex(startFrom || 0);
  }, [startFrom]);

  const slideRight = () => {
    setIndex(index + 1);
    if (isUserControl) {
      onRightButton(slides[index + 1].props.imageId);
    }
  };

  const slideLeft = () => {
    setIndex(index - 1);
    if (isUserControl) {
      onLeftButton(slides[index - 1].props.imageId);
    }
  };

  return (
    <div
      className={cx(
        'd-flex flex-column align-items-center justify-content-between py-2',
        className,
      )}
    >
      {slides.length > 1 && (
        <Typography className={cx(classes.header, textClassName)}>
          {`Image ${index + 1} of ${slides.length}`}
        </Typography>
      )}
      <div
        className={cx(
          'd-flex flex-nowrap align-items-center py-1 h-100',
          size !== 'small' && 'w-100',
          slides.length <= 1 || withoutControlButtons
            ? 'justify-content-center'
            : 'justify-content-between',
        )}
      >
        {slides.length > 1 && !withoutControlButtons && (
          <IconButton
            className={cx(
              classes.button,
              classes.buttonLeft,
              size === 'small' && classes.smallSliderLeft,
              buttonClassName,
            )}
            id="slider-button"
            onClick={slideLeft}
            disabled={index === 0}
          >
            <ArrowBackIosIcon fontSize="inherit" id="slider-button" className={leftIconClassName} />
          </IconButton>
        )}
        {slides.length > 0 && slides[index]}
        {slides.length > 1 && !withoutControlButtons && (
          <IconButton
            className={cx(
              classes.button,
              classes.buttonRight,
              size === 'small' && classes.smallSliderRight,
              buttonClassName,
            )}
            onClick={slideRight}
            disabled={index === slides.length - 1}
            id="slider-button"
          >
            <ArrowForwardIosIcon fontSize="inherit" id="slider-button" />
          </IconButton>
        )}
      </div>
    </div>
  );
}

Slider.defaultProps = {
  slides: [],
  className: '',
  isUserControl: false,
  startFrom: 0,
  withoutControlButtons: false,
};

Slider.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.node), // array of html element
  className: PropTypes.string, // class name style for slider container
  size: PropTypes.string, // size of slider container (small or default)
  isUserControl: PropTypes.bool, // // custom functions for left/right slider button enable
  onLeftButton: PropTypes.func, // custom function for left slider button
  onRightButton: PropTypes.func, // custom function for right slider button
  withoutControlButtons: PropTypes.bool, // slider without left/right buttons
  startFrom: PropTypes.number, // the index of the image from which the display will start
  textClassName: PropTypes.string, // class name style for slider text
};
