import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import cx from 'classnames';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';

import styles from 'assets/jss/material-dashboard-pro-react/views/errorPageStyles.js';
import { whiteColor } from 'assets/jss/material-dashboard-pro-react';
import { generateQR } from 'utils/generateQR';

const queryString = require('query-string');

const useStyles = makeStyles({
  ...styles(),
  title: {
    fontSize: '4rem',
    fontWeight: 'bold',
  },
  link: {
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  bodyText: {
    marginTop: 32,
    marginBottom: 32,
    fontSize: 18,
  },
});

/**
 * Shown when a problem connecting to Firestore is detected on app load
 * (VPN / Firewall issues)
 */
export default function ConnectionError() {
  const [qrDataUrl, setQrDataUrl] = useState('');
  const classes = useStyles();
  const location = useLocation();
  const urlParams = queryString.parse(location.search);
  const previousRoute = urlParams.previousRoute;
  const previousRouteParts = previousRoute.split('/');
  const isParticiantView = previousRouteParts[3] === 'session';

  useEffect(() => {
    if (isParticiantView) {
      generateQR(previousRoute)
        .then(qrCode => setQrDataUrl(qrCode))
        .catch(err => console.error(err));
    }
  }, [isParticiantView]);

  return (
    <div className={cx(classes.contentCenter, 'text-dark')} style={{ maxWidth: 1200 }}>
      <GridContainer>
        <GridItem xs={12}>
          <h1 className={classes.title}>Connection Issue</h1>
          <h2 className={classes.subTitle}>
            Please contact your network administrator for support.
          </h2>
          <p className={classes.bodyText}>
            If this problem persists please contact{' '}
            <a href="mailto:product@voltagecontrol.com" className={classes.link}>
              product@voltagecontrol.com
            </a>
          </p>
          {isParticiantView && qrDataUrl && (
            <div>
              <p className={classes.bodyText}>
                To join the meeting on your mobile or tablet, scan the QR Code below
              </p>
              <img src={qrDataUrl} height="300" width="300" />
            </div>
          )}
        </GridItem>
      </GridContainer>
    </div>
  );
}
