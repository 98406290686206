import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import cx from 'classnames';
// library components
import { Card, CardContent, Typography } from '@material-ui/core';
// cr components
import SmallLabel from 'components/SmallLabel';
import IdeaForm from './IdeaForm';

const useStyles = makeStyles(theme => ({
  idea: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    minHeight: 160,
    marginBottom: theme.spacing(2),
    border: `1px dashed ${grey[400]}`,
  },
}));

/**
 * Renders view for creating new idea on the Participant side.
 */
const NewIdea = ({ order, isFinished }) => {
  const classes = useStyles();
  const [isEditing, setIsEditing] = useState(false);

  const onCancelEditing = () => {
    setIsEditing(false);
  };

  return (
    <Card className={cx(classes.idea)} variant="outlined" elevation={0}>
      <CardContent
        className="w-100 pl-2 p-1"
        onClick={() => {
          setIsEditing(true);
        }}
      >
        <Typography color="textSecondary" variant="body2">
          HMW
        </Typography>
        {isEditing && !isFinished ? (
          <IdeaForm cancelEdit={onCancelEditing} type="create" order={order} />
        ) : (
          <SmallLabel
            text="Create new (max 140 characters)"
            uppercase={false}
            fontSize={14}
            className="flex-1"
          />
        )}
      </CardContent>
    </Card>
  );
};

NewIdea.propTypes = {
  order: PropTypes.number, // serial number of idea
  isFinished: PropTypes.bool, // participant finished with active phase
};

export default NewIdea;
