import { UPDATE_MEETING_ROOM } from '../types/meetingRoomTypes';
import { localStorageGetObject } from '../../utils/localStorageUtils';

const initialState = {
  roomStatus: 'loading',
  roomId: '',
  roomName: '',
  videoMuted: true,
  audioMuted: true,
  previousRoom: {},
  //jitsiDevices: localStorageGetObject('jitsiDeviceSettings') || {},
  jitsiDevices: {},
  facilitatorIsBroadcasting: false,
};

export default function meetingRoomReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_MEETING_ROOM:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
