import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
// library components
import { Typography, Icon, Paper, Box } from '@material-ui/core';
import BlockIcon from '@material-ui/icons/Block';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: 170,
    height: 200,
    marginBottom: 10,
    border: '1px solid #D6D6D6',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
  },
  duplicateCard: {
    border: '1px solid #DA988F',
    backgroundColor: '#FFF2F2',
  },
  text: {
    fontSize: 18,
    fontWeight: 500,
    color: 'rgba(0, 0, 0, 0.87)',
    textAlign: 'center',
  },
  image: {
    width: '100%',
    height: '100%',
    borderRadius: 3,
  },
  imageWithText: {},
  imageText: {
    position: 'absolute',
    color: '#FFFFFF',
    fontSize: '12px',
    lineHeight: '30px',
    textAlign: 'center',
    bottom: 0, //set the text for Image to bottom
    left: 0,
    right: 0,
    background: 'rgba(10, 10, 10, 0.8)',
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
  },
  icon: {
    fontSize: 40,
  },
  blockIcon: {
    position: 'absolute',
    top: 0,
    right: 0,
    margin: 4,
    fontSize: 16,
    color: theme.palette.dissatisfied.main,
  },
  clearIcon: {
    color: 'rgba(54, 54, 54, 0.6)',
  },
  notIncludeCard: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#F6F6F6',
    opacity: 0.6,
  },
}));

/**
 * Renders result card on the Participant side.
 */
const CardResult = ({ card, context }) => {
  const classes = useStyles();

  return (
    <Paper
      className={cx(
        classes.container,
        card.isDuplicate && classes.duplicateCard,
        context === 'resultsAdmin' && classes.adminCard,
      )}
      elevation={0}
    >
      {card.isNotIncludeNextRound && (
        <ClearIcon className={cx(classes.blockIcon, classes.clearIcon)} />
      )}
      {card.isDuplicate && <BlockIcon className={classes.blockIcon} />}
      {card.icon && <Icon className={classes.icon}>{card.icon}</Icon>}
      {/* If the Image URL exists, combine the 'text' class and the 'imageText' class */}
      <Typography className={card.imageUrl ? cx(classes.text, classes.imageText) : classes.text}>
        {card.text}
      </Typography>
      {card.imageUrl && (
        //If Text exists for Image, combine the 'imageWithText' class with the 'image' class
        <img
          className={card.text ? cx(classes.image, classes.imageWithText) : classes.image}
          src={card.imageUrl}
          alt="card"
        />
      )}
      {card.isNotIncludeNextRound && <Box className={classes.notIncludeCard} />}
    </Paper>
  );
};

CardResult.propTypes = {
  card: PropTypes.shape({
    icon: PropTypes.string,
    imageUrl: PropTypes.string,
    text: PropTypes.string,
  }),
  context: PropTypes.oneOf(['results']),
};

export default CardResult;
