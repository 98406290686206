import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { each, find, reduce } from 'lodash';
import cx from 'classnames';
// cr components
import ImagesBox from './ImagesBox';
import DottedProgress from 'components/DottedProgress';
// library components
import { Typography, Box } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
// selectors
import { useHeatMappingSelector } from 'redux/selectors/heatMappingSelectors';
import { getParticipantsMap } from 'redux/selectors/participantsSelectors';
// actions
import { addSuperVotePoints } from 'redux/actions/heatMappingActions';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  successColor: {
    color: theme.palette.success.main,
  },
}));

/**
 * Heat-Mapping view for the 'superVote' phase on the Participant side
 */
const HeatMappingParticipantSuperVote = () => {
  const maxSuperVotesCount = 3;
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const {
    id,
    participantData: { sketches, superVoteCompleted },
    state: { superVoteParticipantId, activeView },
  } = useHeatMappingSelector();
  const participantId = useSelector(state => state.firebase.auth.uid);
  const participantsData = useSelector(getParticipantsMap);

  const superVote = {};
  each(sketches, sketch => {
    superVote[sketch.id] = {
      id: sketch.id,
      pointsCount: sketch.superVotePoints,
    };
  });
  const superVotesPointsCount = reduce(
    superVote,
    (acc, points) => {
      acc += points.pointsCount ? points.pointsCount : 0;
      return acc;
    },
    0,
  );

  const [superVotePoints, setSuperVotePoints] = useState(superVote ? superVote : {});
  const [isVoteComplete, setIsVoteComplete] = useState(superVotesPointsCount >= maxSuperVotesCount);

  const handleVote = (createdBy, pointsCount) => {
    const sketch = find(sketches, sketch => sketch.participantId === createdBy);
    const newSuperVotePoints = {
      ...superVotePoints,
      [sketch.id]: {
        id: sketch.id,
        pointsCount: pointsCount,
      },
    };
    setSuperVotePoints(newSuperVotePoints);
    dispatch(addSuperVotePoints(id, sketch.id, pointsCount));
    const superVotesCount = reduce(
      newSuperVotePoints,
      (acc, points) => {
        acc += points.pointsCount ? points.pointsCount : 0;
        return acc;
      },
      0,
    );
    setIsVoteComplete(superVotesCount >= maxSuperVotesCount);
  };

  return superVoteParticipantId !== participantId ? (
    <>
      <ImagesBox context={activeView} comments={sketches} superVoteDisabled={true} />
      <Typography className="pb-3 pt-2" color="textSecondary" variant="body2">
        {participantsData[superVoteParticipantId]
          ? participantsData[superVoteParticipantId].name
          : 'Unknown'}{' '}
        is deciding.
      </Typography>
    </>
  ) : (
    <>
      <ImagesBox
        context={activeView}
        comments={sketches}
        handleVote={handleVote}
        isVoting={isVoteComplete}
        isFinishedVote={superVoteCompleted}
      />
      <Box className={classes.container}>
        {isVoteComplete && <CheckCircleIcon className={classes.successColor} />}
        <DottedProgress
          className="mt-1 ml-1"
          activeColor={theme.palette.success.main}
          activeCount={superVotesPointsCount}
          totalCount={maxSuperVotesCount}
        />
        {isVoteComplete ? (
          <Typography className={cx(classes.successColor, 'mb-3')} variant="body2" align="center">
            You’ve used all {maxSuperVotesCount} of your super votes.
            <br />
            You can remove a vote by click on one of the green dots.
          </Typography>
        ) : (
          <Typography className="mb-3" color="textSecondary" variant="body2" align="center">
            Tap a sketch to place your {maxSuperVotesCount} super votes.
            <br />
            Once you place a vote, you can click on the green dot to remove it.
            <br />
            You are allowed to vote on thes same sketch more than one.
          </Typography>
        )}
      </Box>
    </>
  );
};

export default HeatMappingParticipantSuperVote;
