import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// library components
import { Box, Typography } from '@material-ui/core';
// cr components
import GrayBox from 'components/GrayBox';

const useStyles = makeStyles(theme => ({
  box: {
    paddingBottom: theme.spacing(11),
    paddingTop: theme.spacing(11),
    marginBottom: theme.spacing(3),
  },
  boxTitle: {
    fontSize: 18,
  },
}));

/**
 * Assessment view for the 'results' phase on the Facilitator side
 */
const AssessmentAdminResults = () => {
  const classes = useStyles();

  return (
    <Box className="d-flex flex-column justify-content-center">
      <GrayBox className={classes.box}>
        <Typography className={classes.boxTitle}>Viewing Results</Typography>
      </GrayBox>
    </Box>
  );
};

export default AssessmentAdminResults;
