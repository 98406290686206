import React from 'react';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
// cr components
import ActivityTemplate from 'components/ActivityTemplate/ActivityTemplate';
import NoteAndMapParticipantCreate from 'components/NoteAndMap/Participant/NoteAndMapParticipantCreate';
import NoteAndMapParticipantReview from 'components/NoteAndMap/Participant/NoteAndMapParticipantReview';
import NoteAndMapParticipantVote from 'components/NoteAndMap/Participant/NoteAndMapParticipantVote';
import NoteAndMapParticipantVoteResults from 'components/NoteAndMap/Participant/NoteAndMapParticipantVoteResults';
import NoteAndMapParticipantSuperVote from 'components/NoteAndMap/Participant/NoteAndMapParticipantSuperVote';
import NoteAndMapParticipantResults from 'components/NoteAndMap/Participant/NoteAndMapParticipantResults';
import ParticipantActivityContent from 'components/ParticipantActivityContent';
// selectors
import { useNoteAndMapSelector } from 'redux/selectors/noteAndMapSelectors';
// constants
import { NOTE_AND_MAP_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';

const views = {
  create: NoteAndMapParticipantCreate,
  review: NoteAndMapParticipantReview,
  vote: NoteAndMapParticipantVote,
  voteResults: NoteAndMapParticipantVoteResults,
  superVote: NoteAndMapParticipantSuperVote,
  results: NoteAndMapParticipantResults,
};

/**
 * Entry point for Note & Map on the Participant side.
 * Loads the Firestore object for the activity, ensures
 * the data is loaded before rendering the game, and determines
 * which view to show
 */
const NoteAndMapParticipant = ({
  match: {
    params: { activityId },
  },
}) => {
  const noteAndMap = useNoteAndMapSelector();

  // hooks up a firestore listener to Redux
  useFirestoreConnect(() => [
    {
      collection: 'noteAndMap',
      doc: `${activityId}`,
      storeAs: 'noteAndMap',
    },
  ]);

  // ensure all of the data we need is loaded
  if (!isLoaded(noteAndMap) || activityId !== noteAndMap?.id) {
    return null;
  }

  const Component = views[noteAndMap.state.activeView];

  return (
    <ActivityTemplate type="Note & Map" name={noteAndMap.name}>
      <ParticipantActivityContent
        activityData={noteAndMap}
        viewLabel={CONFIG.participantViewLabels[noteAndMap.state.activeView]}
        activityConfig={CONFIG}
      >
        <Component />
      </ParticipantActivityContent>
    </ActivityTemplate>
  );
};

export default NoteAndMapParticipant;
