import React from 'react';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
// cr components
import ActivityTemplate from 'components/ActivityTemplate/ActivityTemplate';
import ParticipantActivityContent from 'components/ParticipantActivityContent';
import StoryFlowParticipantCreate from 'components/StoryFlow/Participant/StoryFlowParticipantCreate';
import StoryFlowParticipantSelect from 'components/StoryFlow/Participant/StoryFlowParticipantSelect';
import StoryFlowParticipantSelectResults from 'components/StoryFlow/Participant/StoryFlowParticipantSelectResults';
import StoryFlowParticipantSuperVote from 'components/StoryFlow/Participant/StoryFlowParticipantSuperVote';
import StoryFlowParticipantResults from 'components/StoryFlow/Participant/StoryFlowParticipantResults';
// selectors
import { useStoryFlowSelector } from 'redux/selectors/storyFlowSelectors';
// constants
import { STORY_FLOW_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';

//add new view for all action
const views = {
  create: StoryFlowParticipantCreate,
  select: StoryFlowParticipantSelect,
  selectResults: StoryFlowParticipantSelectResults,
  superVote: StoryFlowParticipantSuperVote,
  results: StoryFlowParticipantResults,
};

const StoryFlowParticipant = ({
  match: {
    params: { activityId },
  },
}) => {
  const storyFlow = useStoryFlowSelector();

  // hooks up a firestore listener to Redux
  useFirestoreConnect(() => [
    {
      collection: 'storyFlow',
      doc: `${activityId}`,
      storeAs: 'storyFlow',
    },
  ]);

  // ensure all of the data we need is loaded
  if (!isLoaded(storyFlow) || activityId !== storyFlow?.id) {
    return null;
  }

  const Component = views[storyFlow.state.activeView];

  return (
    <ActivityTemplate type="Story Flow" name={storyFlow.name}>
      <ParticipantActivityContent
        activityData={storyFlow}
        viewLabel={CONFIG.participantViewLabels[storyFlow.state.activeView]}
        activityConfig={CONFIG}
      >
        <Component />
      </ParticipantActivityContent>
    </ActivityTemplate>
  );
};

export default StoryFlowParticipant;
