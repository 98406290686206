import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Box, Typography, IconButton, Tooltip } from '@material-ui/core';
import RestoreIcon from '@material-ui/icons/Restore';

/**
 * Shows a count up timer
 */
class CountUpTimer extends Component {
  state = {
    timerOn: true,
    timerStart: 0,
    timerTime: 0,
  };

  static propTypes = {
    timerStart: PropTypes.instanceOf(Date),
    fontSize: PropTypes.number,
    onResetTimer: PropTypes.func,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.timerStart !== nextProps.timerStart) {
      return {
        timerStart: nextProps.startTime.seconds * 1000,
      };
    }

    // Return null to indicate no change to state.
    return null;
  }

  componentDidMount() {
    this.startTimer();
  }

  startTimer = () => {
    this.setState({
      timerOn: true,
      timerTime: this.state.timerTime,
      timerStart: Date.now() - this.state.timerTime,
    });
    this.timer = setInterval(() => {
      this.setState({
        timerTime: Date.now() - this.state.timerStart,
      });
    }, 10);
  };

  stopTimer = () => {
    this.setState({ timerOn: false });
    clearInterval(this.timer);
  };

  resetTimer = () => {
    this.setState({
      timerStart: 0,
      timerTime: 0,
    });
  };

  formatTimePartial = partial => {
    if (partial[0] === '0') {
      return partial.substring(1);
    }

    return partial;
  };

  render() {
    const { timerTime } = this.state;
    const { fontSize, onResetTimer } = this.props;
    let seconds = ('0' + (Math.floor(timerTime / 1000) % 60)).slice(-2);
    let minutes = ('0' + (Math.floor(timerTime / 60000) % 60)).slice(-2);
    let hours = ('0' + Math.floor(timerTime / 3600000)).slice(-2);

    return (
      <Box className="CountUpTimer d-flex align-items-center">
        <Typography style={{ fontSize }}>
          {hours !== '00' && <span>{hours}:</span>}
          {this.formatTimePartial(minutes)}:{seconds}
        </Typography>
        {onResetTimer && (
          <Tooltip arrow title="reset the timer for this slide">
            <IconButton className="ml-1" fontSize="inherit" size="small" onClick={onResetTimer}>
              <RestoreIcon />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    );
  }
}

CountUpTimer.defaulProps = {
  fontSize: 18,
};

export default CountUpTimer;
