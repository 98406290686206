import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Route, Switch, useLocation } from 'react-router-dom';
import { useFirestoreConnect, isLoaded } from 'react-redux-firebase';

import { Typography } from '@material-ui/core';

import SessionsList from 'views/Admin/Sessions/SessionsList';
import SessionDetail from 'views/Admin/SessionDetail/SessionDetail';
import FormDialog from '../../../components/Dialogs/FormDialog';
import UpdateUserDisplayNameForm from '../../../components/Forms/UpdateUserDisplayNameForm';

const PAGE_SIZE = 10000;

export default function Sessions() {
  const user = useSelector(state => state.firebase.profile);
  const displayName = user?.displayName;
  const [isFormDialogOpen, setIsFormDialogOpen] = useState(false);
  const location = useLocation();
  const sessionsType = location.pathname.split('/')[2];
  const isViewingShared = sessionsType === 'shared-sessions';
  const sessions = useSelector(state => state.firestore.data[sessionsType]);
  const [limit, setLimit] = useState(PAGE_SIZE - 1); // limit
  const [hasMoreSessions, setHasMoreSessions] = useState(true);

  const queryMap = {
    sessions: [
      ['facilitatorId', '==', `${user.uid}`],
      ['startTime', '>', moment().subtract(1, 'day').endOf('day').format()],
    ],
    'shared-sessions': ['coFacilitators', 'array-contains', `${user.uid}`],
    'past-sessions': [
      ['facilitatorId', '==', `${user.uid}`],
      ['startTime', '<', moment().subtract(1, 'day').endOf('day').format()],
    ],
  };

  useFirestoreConnect(() => {
    if (!isViewingShared) {
      return [
        {
          collection: 'sessions',
          where: [queryMap[sessionsType]],
          orderBy: ['startTime'],
          limit: limit + 1,
          startAt: limit + 1,
          storeAs: sessionsType,
        },
      ];
    } else {
      return [
        {
          collection: 'sessions',
          where: [queryMap[sessionsType]],
          storeAs: sessionsType,
        },
      ];
    }
  });

  // load next pages
  const handleLoadSessions = () => {
    setLimit(prev => prev + PAGE_SIZE);
  };

  useEffect(() => {
    if (!displayName) setIsFormDialogOpen(true);
  }, [displayName]);

  useEffect(() => {
    // check if sessions exist
    const sessionCount = sessions && Object.keys(sessions).length;
    if (!sessionCount) {
      setHasMoreSessions(false);
    } else {
      setHasMoreSessions(sessionCount % PAGE_SIZE === 0);
    }
  }, [sessions]);

  const toggleFormDialog = () => {
    setIsFormDialogOpen(!isFormDialogOpen);
  };

  if (!isLoaded(sessions)) return null;

  return (
    <>
      <Switch>
        <Route
          exact
          path="/admin/sessions"
          render={routeProps => (
            <SessionsList
              {...routeProps}
              sessionsType={sessionsType}
              handleLoadSessions={handleLoadSessions}
              hasMoreSessions={hasMoreSessions}
            />
          )}
        />
        <Route
          exact
          path="/admin/past-sessions"
          render={routeProps => (
            <SessionsList
              {...routeProps}
              sessionsType={sessionsType}
              handleLoadSessions={handleLoadSessions}
              hasMoreSessions={hasMoreSessions}
            />
          )}
        />
        <Route
          exact
          path={`/admin/shared-sessions`}
          render={routeProps => (
            <SessionsList
              {...routeProps}
              isViewingShared={isViewingShared}
              sessionsType={sessionsType}
              handleLoadSessions={handleLoadSessions}
              hasMoreSessions={hasMoreSessions}
            />
          )}
        />
        <Route
          path={`/admin/sessions/:sessionId`}
          render={routeProps => <SessionDetail {...routeProps} sessionsType={sessionsType} />}
        />
        <Route
          path={`/admin/shared-sessions/:sessionId`}
          render={routeProps => (
            <SessionDetail
              {...routeProps}
              isViewingShared={isViewingShared}
              sessionsType={sessionsType}
            />
          )}
        />
        <Route
          path={`/admin/past-sessions/:sessionId`}
          render={routeProps => <SessionDetail {...routeProps} sessionsType={sessionsType} />}
        />
      </Switch>
      <FormDialog
        handleClose={toggleFormDialog}
        isOpen={isFormDialogOpen}
        title="Update Display Name"
      >
        <Typography className="mb-4" style={{ fontSize: 18 }}>
          Welcome to Control Room! Please set your display name to start using the app.
        </Typography>
        <UpdateUserDisplayNameForm closeDialog={toggleFormDialog} />
      </FormDialog>
    </>
  );
}
