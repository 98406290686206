import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import {
  join,
  split,
  startCase,
  forEach,
  size,
  filter,
  map,
  isString,
  each,
  includes,
} from 'lodash';
//import { SystemUpdate } from '@material-ui/icons';
import { getFirebase } from 'react-redux-firebase';
import { ACTIVITIES } from 'constants/activities';
// utils
import { getActivityData } from './getActivityData';
import { create2510Report } from 'utils/25:10/report';
import { createBrainWritingReport } from 'utils/brainwriting/report';
import { createCardSortingReport } from 'utils/cardSorting';
import { createGiveawayReport } from 'utils/giveaway';
import { createHeatMappingReport } from 'utils/heatMapping';
import { createHMWReport } from 'utils/howMightWe';
import { createLightningDemoReport } from 'utils/lightningDemo';
import { createNarrowingReport } from 'utils/narrowing';
import { createNoteAndCategorizeReport } from 'utils/noteAndCategorize';
import { createNoteAndMapReport } from 'utils/noteAndMap';
import { createNoteAndVoteReport } from 'utils/noteAndVote';
import { createPollingReport } from 'utils/polling';
import { createScorecardReport } from 'utils/scorecard';
import { createStoryFlowReport } from 'utils/storyFlow';
import { createSurveyReport } from 'utils/survey';
import { createUserJourneyReport } from 'utils/userJourneyMoments';

const createReport = {
  '25:10': create2510Report,
  brainwriting: createBrainWritingReport,
  cartSorting: createCardSortingReport,
  giveaway: createGiveawayReport,
  heatMapping: createHeatMappingReport,
  howMightWe: createHMWReport,
  lightningDemo: createLightningDemoReport,
  narrowing: createNarrowingReport,
  noteAndCategorize: createNoteAndCategorizeReport,
  noteAndMap: createNoteAndMapReport,
  noteAndVote: createNoteAndVoteReport,
  polling: createPollingReport,
  scorecard: createScorecardReport,
  storyFlow: createStoryFlowReport,
  survey: createSurveyReport,
  userJourneyMoments: createUserJourneyReport,
};

const getParticipantSessionData = async sessionId => {
  const firebase = getFirebase();
  const db = firebase.firestore();
  const snapshot = await db.collection('participants').doc(`${sessionId}`).get();
  const session = snapshot.data();
  return session;
};

// generate activity name
const cleanValueForFileName = value => {
  const valueStartCase = startCase(value.replace('&', 'And'));
  return join(split(valueStartCase, ' '), '-');
};

// generate file name
const generateFileName = (topic, activityType, name, count) => {
  let fileName = `${topic}-${activityType}-${name}`;
  if (count > 1) {
    fileName += `-${count}`;
  }
  fileName += '-Results.csv';
  return fileName;
};

// this is a function to use in the csv creation loop.
// It escapes " in any of hte values...
// We can do other things here if needed.
const cleanDataForCSV = value => (isString(value) ? value.replace(/"/g, '""') : value);

// creates csv reports
const generateCSVReports = async (activities, sessionId) => {
  const reports = {};

  // some exports need the participantMap. Let's create it once and pass to all all
  const participantMap = await getParticipantSessionData(sessionId);

  for (const { activity, id } of activities) {
    //console.log(activity)

    // load activity definition from the constants file
    // error out if we can't find it.
    const activityDef = ACTIVITIES[activity];

    if (!activityDef) {
      console.error('unknown activity ' + activity);
      continue;
    }

    // load the report generator function for the activity.
    // error if we can't find one.
    const reportGenerator = createReport[activity];

    if (!reportGenerator) {
      console.error(activity + ' does not have an export function.');
      continue;
    }

    // Setup activityData - the main input to all downloads...
    const activityData = await getActivityData(activityDef.collection, id);

    // Run the generator function to create the download.
    const activityReport = reportGenerator(activityData, participantMap);

    // Cleanup the activity Type and the name.
    const activityType = cleanValueForFileName(activityDef.niceName);
    const name = cleanValueForFileName(activityData.name);

    /// Pull headers and data from the report object
    const headers = activityReport.headers;
    const headerLabels = map(headers, header => header.label);
    const headerKeys = map(headers, header => header.key);
    const data = activityReport.data;

    // Create CSV String to add to the file.
    let csvContent = join(headerLabels, ',') + '\r\n';
    //TODO: assemble the csv...  (use json2)
    //TODO: could this code be shared with download link???s
    each(data, dataRow => {
      let row = '';
      each(headerKeys, key => {
        row += '"' + cleanDataForCSV(dataRow[key]) + '"';
        row += ',';
      });
      // prune empty rows.
      if (size(row) > 0) {
        // remove the trailing slash.
        csvContent += row.slice(0, -1) + '\r\n';
      }
    });
    reports[id] = { csvContent, name, activityType };
  }
  return reports;
};

/**
 * Create and downloads a zip file with all activity reports in the current meeting
 * @param {Object} activities
 * @param {String} sessionTopic
 * @param {String} sessionId
 */
export const exportMeetingReports = async (activities, sessionTopic, sessionId) => {
  const sessionTopicStartCase = startCase(sessionTopic);
  const topic = join(split(sessionTopicStartCase, ' '), '-');

  const zip = new JSZip();

  const reports = await generateCSVReports(activities, sessionId);
  const reportsList = {};

  forEach(reports, report => {
    const type = report.activityType;
    const prevValues = reportsList[type] ? reportsList[type] : [];
    //generate filename...
    reportsList[type] = [...prevValues, report.name];
    const count = size(filter(reportsList?.[type], item => item === report.name));
    const fileName = generateFileName(topic, type, report.name, count);
    zip.file(fileName, report.csvContent);
  });

  zip.generateAsync({ type: 'blob' }).then(function (content) {
    saveAs(content, `${topic}-Export.zip`);
  });
};
