import React from 'react';
import PropTypes from 'prop-types';
// library components
import { makeStyles } from '@material-ui/core';
import { Formik, Form } from 'formik';
// cr components
import TextArea from 'components/CustomFormComponents/TextArea';

const useStyles = makeStyles({
  textArea: {
    maxWidth: 520,
    borderRadius: 3,
    padding: 10,
  },
});

/**
 * Renders text area for adding comment
 */
const QuestionComment = ({
  comment,
  questionId,
  handleParticipantAddComment,
  handleParticipantRemoveComment,
}) => {
  const classes = useStyles();

  const handleAddComment = event => {
    if (event.target.value) {
      const comment = event.target.value;
      handleParticipantAddComment(questionId, comment);
    } else {
      handleParticipantRemoveComment(questionId);
    }
  };

  return (
    <Formik
      initialValues={{
        questionComment: comment,
      }}
      enableReinitialize
    >
      {() => (
        <Form className="w-100 h-100 mt-1" onBlur={handleAddComment}>
          <TextArea
            name="questionComment"
            rows={3}
            required
            additionalStyles={{
              input: classes.textArea,
            }}
          />
        </Form>
      )}
    </Formik>
  );
};

QuestionComment.propTypes = {
  comment: PropTypes.string, // question comment
  questionId: PropTypes.string.isRequired, // question id
  handleParticipantAddComment: PropTypes.func, // function for adding text comment
  handleParticipantRemoveComment: PropTypes.func, // function for removing participant comment, if it is empty
};

export default QuestionComment;
