import React from 'react';
import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';
import { map } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { Radio, RadioGroup, FormControlLabel, FormLabel, Box } from '@material-ui/core';
import cx from 'classnames';

import AutoField from './AutoField';

const useFormLabelStyles = makeStyles({
  root: {
    marginBottom: 4,
  },
});

const useGroupStyles = makeStyles({
  mediumContainer: {
    width: 250,
  },
  largeContainer: {
    width: '100%',
  },
  radioButtonGroup: {
    marginBottom: 10,
  },
  label: {
    color: '#000000',
  },
});

/**
 * Renders the radio button group. Allow the user to select one option from a set.
 */
const RadioButtonGroup = ({
  name,
  label,
  defaultValue,
  values,
  size,
  disabled,
  child,
  stacked,
}) => {
  const [field, meta] = useField(name);
  const { setFieldValue, submitCount } = useFormikContext();
  const formLabelStyles = useFormLabelStyles();
  const radioButtonClasses = useGroupStyles();
  const { error, touched } = meta;
  const hasError = (error && touched) || (submitCount && error) ? true : false;

  const controlLabels = map(values, value => {
    let optionValue, label;

    // check if value is an object with a label key
    if (value.label) {
      label = value.label;
      optionValue = value.value;
    } else {
      label = value;
      optionValue = value;
    }

    return (
      <FormControlLabel
        key={`item_${optionValue}`}
        id={`item_${optionValue}`}
        className={radioButtonClasses.label}
        value={optionValue}
        control={<Radio color="primary" />}
        label={label}
        disabled={disabled}
      />
    );
  });

  const handleChange = event => {
    setFieldValue(name, event.target.value);
  };

  const containerClass =
    size === 'medium' ? radioButtonClasses.mediumContainer : radioButtonClasses.largeContainer;

  return (
    <div className={cx(radioButtonClasses.radioButtonGroup, containerClass)}>
      <FormLabel component="legend" classes={formLabelStyles}>
        {label}
      </FormLabel>
      <RadioGroup
        {...field}
        aria-label={label}
        name={name}
        value={!!field.value || field.value === '' ? field.value : defaultValue}
        onChange={handleChange}
        row={!stacked}
      >
        {controlLabels}
      </RadioGroup>
      {!!child && (
        <AutoField
          id={`${child.key}`}
          field={{
            ...child.formField,
            parentFieldValue: !!field.value || field.value === '' ? field.value : defaultValue,
          }}
          disabled={disabled}
        />
      )}
      {hasError ? (
        <Box component="span" color="error.main">
          {error}
        </Box>
      ) : null}
    </div>
  );
};

RadioButtonGroup.propTypes = {
  // The name of config field.
  name: PropTypes.string.isRequired,
  // The title of field.
  label: PropTypes.string.isRequired,
  // The default input element value.
  defaultValue: PropTypes.string,
  // Contains radio button values.
  values: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ]),
  ).isRequired,
  // The parameter that specifies the width of the group.
  size: PropTypes.string,
  // The field is disabled
  disabled: PropTypes.bool,
  // Render options vertically
  stacked: PropTypes.bool,
};

export default RadioButtonGroup;
