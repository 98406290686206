export const FACILITATOR_SHOW_VIDEO = 'FACILITATOR_SHOW_VIDEO';
export const SET_SELECTED_FACILITATOR_ROUTE = 'SET_SELECTED_FACILITATOR_ROUTE';
export const IS_OPEN_PREMIUM_SUBSCRIPTION_DIALOG = 'IS_OPEN_PREMIUM_SUBSCRIPTION_DIALOG';
export const IS_OPEN_FACILITATOR_ACCOUNT_SETTINGS_DIALOG =
  'IS_OPEN_FACILITATOR_ACCOUNT_SETTINGS_DIALOG';
export const IS_OPEN_CREATE_MEETING_DIALOG = 'IS_OPEN_CREATE_MEETING_DIALOG';
export const IS_OPEN_MURAL_AUTH_DIALOG = 'IS_OPEN_MURAL_AUTH_DIALOG';
export const SHOW_APP_OVERLAY_SPINNER = 'SHOW_APP_OVERLAY_SPINNER';
export const IS_OPEN_INFO_DIALOG = 'IS_OPEN_INFO_DIALOG';
export const SET_INFO_DIALOG_CONTENT = 'SET_INFO_DIALOG_CONTENT';
export const CLOSE_INFO_DIALOG = 'CLOSE_INFO_DIALOG';
