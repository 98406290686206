import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import makeStyles from '@material-ui/core/styles/makeStyles';
// library components
import { Formik, Form, Field } from 'formik';
import { TextareaAutosize, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
// cr components
import Spinner from 'components/Spinner';
import DelayedRender from 'components/DelayedRender';

const useStyles = makeStyles(theme => ({
  cancelBtn: {
    position: 'relative',
  },
  cancelBtnText: {
    fontSize: 12,
    color: '#ABABAB',
    textTransform: 'initial',
  },
  textArea: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    background: 'transparent',
    resize: 'none',
    border: '0 none',
    outline: 'none',
    padding: 0,
    display: 'inline-block',
  },
}));

/**
 * Renders the single field form inside of each Option inside
 * of an OptionCloud
 */
const OptionForm = ({ option, type, cancelEdit, onCreate, onEdit, cancelCreate }) => {
  const classes = useStyles({ type });

  /**
   * Autofocus the field when the form is rendered so that
   * the user knows that they can type since the form field
   * is essentially invisible
   */
  useEffect(() => {
    focusValueField();
  }, []);

  const focusValueField = () => {
    document.getElementById('value').focus();
  };

  const formSchema = Yup.object().shape({
    value: Yup.string(),
  });

  const initialValues = { value: '' };

  if (option) {
    initialValues.value = option.value;
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={formSchema}
      onSubmit={(values, { resetForm, setSubmitting }) => {
        if (type === 'create') {
          if (!values.value) {
            setSubmitting(false);
            cancelCreate();
            return;
          }

          const value = values.value;

          /**
           * reset the form before submission so the ui doesn't get
           */
          resetForm();

          onCreate(
            value,
            () => {
              setSubmitting(false);
              // refocus the form so the facilitator can add another option
              focusValueField();
            },
            () => {
              setSubmitting(false);
            },
          );
        }

        if (type === 'edit') {
          // cancel if nothing has changed
          if (values.value === option.value) {
            cancelEdit();
            return;
          }

          onEdit(
            { id: option.id, value: values.value },
            () => {
              setSubmitting(false);
              cancelEdit();
            },
            () => {
              setSubmitting(false);
            },
          );
        }
      }}
    >
      {({ isSubmitting, submitForm }) => (
        <>
          <Form className="w-100 position-relative d-flex align-items-center" onBlur={submitForm}>
            <Field name="value">
              {({ field }) => (
                <label className="input-sizer">
                  <TextareaAutosize
                    onKeyDown={e => {
                      if (e.key === 'Enter' || e.key === 'NumpadEnter' || e.key === 'Escape') {
                        e.preventDefault();
                        submitForm();
                      }
                    }}
                    id="value"
                    className={classes.textArea}
                    {...field}
                    disabled={isSubmitting}
                    readOnly={isSubmitting}
                  />
                </label>
              )}
            </Field>
            {isSubmitting && (
              <DelayedRender>
                <Spinner size={18} />
              </DelayedRender>
            )}
          </Form>
          {type === 'create' && (
            <IconButton size="small" className={classes.cancelBtn}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          )}
        </>
      )}
    </Formik>
  );
};

OptionForm.propTypes = {
  option: PropTypes.shape({
    value: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }),
  cancelEdit: PropTypes.func,
  type: PropTypes.oneOf(['create', 'edit']),
  onCreate: PropTypes.func,
  onEdit: PropTypes.func,
  cancelCreate: PropTypes.func,
};

export default OptionForm;
