import {
  SLIDES_SESSIONS_LOADING,
  SLIDES_SESSIONS_SUCCESS,
  SLIDES_SESSIONS_ERROR,
  SLIDES_SESSION_CREATING,
  SLIDES_SESSION_SUCCESS,
  SLIDES_SESSION_ERROR,
  SLIDES_SESSION_DELETED,
  SLIDES_SESSION_WATCH_UPDATED,
} from '../types/slidesSessionTypes';
import remove from 'lodash/remove';

/**
 * Loads all the RemoteSlidesSession objects currently in Firestore
 * @param sessionIds   an array of remoteSlidesSession id's
 * @param enqueueSnackbar   notistack helper
 */
export const loadSessions =
  (enqueueSnackbar, sessionIds) =>
  (dispatch, getState, { slidesApi }) => {
    dispatch({ type: SLIDES_SESSIONS_LOADING });
    return slidesApi
      .getRemoteSlidesSessionsByIds(sessionIds)
      .then(sessions => {
        dispatch({ type: SLIDES_SESSIONS_SUCCESS, payload: sessions });
      })
      .catch(err => {
        dispatch({ type: SLIDES_SESSIONS_ERROR });
        enqueueSnackbar(err.message, { variant: 'error' });
        console.error(err);
      });
  };

/**
 * Loads all the RemoteSlidesSession object currently in Firestore for a given id
 * @param sessionId   a remoteSlidesSession id
 * @param enqueueSnackbar   notistack helper
 */
export const loadSession =
  (enqueueSnackbar, sessionId) =>
  (dispatch, getState, { slidesApi }) => {
    dispatch({ type: SLIDES_SESSIONS_LOADING });
    return slidesApi
      .getRemoteSlidesSession(sessionId)
      .then(slidesSession => {
        dispatch({ type: SLIDES_SESSION_SUCCESS, payload: slidesSession });
      })
      .catch(err => {
        dispatch({ type: SLIDES_SESSIONS_ERROR });
        enqueueSnackbar(err.message, { variant: 'error' });
        console.error(err);
      });
  };

/**
 * Adds a new RemoteSlidesSession object in Firestore
 * @param fileId    The id of the Google Slides file in Google Drive
 * @param enqueueSnackbar   notistack helper
 */
export const createSession =
  (fileId, sessionId, enqueueSnackbar) =>
  async (dispatch, getState, { slidesApi, getFirebase }) => {
    console.log('createSession');
    const firestore = getFirebase().firestore;
    const db = getFirebase().firestore();

    dispatch({ type: SLIDES_SESSION_CREATING });

    const fileExportDoc = await db.doc(`googleSlidesExports/${fileId}`).get();
    let fileExport = null;

    if (fileExportDoc.exists) {
      fileExport = fileExportDoc.data();
    } else {
      dispatch({ type: SLIDES_SESSION_ERROR });
      enqueueSnackbar("The export you're trying to reuse does not exist", { variant: 'error' });
      return;
    }

    return slidesApi
      .createRemoteSlidesSessionForExport(fileId, fileExport.name)
      .then(async slidesSession => {
        await db.doc(`sessions/${sessionId}`).update({
          slidesSessionIds: firestore.FieldValue.arrayUnion(slidesSession.id),
        });

        dispatch({ type: SLIDES_SESSION_SUCCESS, payload: slidesSession });
        enqueueSnackbar('Slides Session Created!', { variant: 'success' });
      })
      .catch(err => {
        dispatch({ type: SLIDES_SESSION_ERROR });
        enqueueSnackbar(err.message, { variant: 'error' });
        console.error(err);
      });
  };

/**
 * Deletes a RemoteSlidesSession object in Firestore
 * @param sessionId The id of the RemoteSlidesSession object to delete
 * @param setDeleting   Sets the 'deleting' state of the corresponding SessionItem View
 * @param enqueueSnackbar   notistack helper
 */
export const deleteSession =
  (slidesSessionId, setDeleting, enqueueSnackbar) =>
  (dispatch, getState, { slidesApi, getFirebase }) => {
    return slidesApi
      .deleteRemoteSlidesSession(slidesSessionId)
      .then(async () => {
        const firestore = getFirebase().firestore;
        const db = getFirebase().firestore();
        const session = getState().firestore.data.session;
        // const slidesSessionIds = [...session.slidesSessionIds];

        // remove(slidesSessionIds, id => id === slidesSessionId);

        // const activeSlidesSession = session.activeSlidesSession === slidesSessionId
        //   ? !slidesSessionIds.length ? null : slidesSessionIds[0]
        //   : session.activeSlidesSession

        await db.doc(`sessions/${session.id}`).update({
          slidesSessionIds: firestore.FieldValue.arrayRemove(slidesSessionId),
          // activeSlidesSession
        });

        dispatch({ type: SLIDES_SESSION_DELETED, payload: slidesSessionId });
        enqueueSnackbar('Slides Session Deleted', { variant: 'success' });
      })
      .catch(err => {
        if (setDeleting) {
          setDeleting(false);
        }
        enqueueSnackbar(err.message, { variant: 'error' });
        console.error(err);
      });
  };

/**
 * Sets the current slide number of a RemoteSlidesSession
 * @param sessionId The id of the RemoteSlidesSession object to update
 * @param slideNumber   The new slide number
 * @param enqueueSnackbar   Notistack helper
 */
export const setSessionSlideNumber =
  (sessionId, slideNumber, enqueueSnackbar) =>
  (dispatch, getState, { slidesApi }) => {
    return slidesApi
      .setRemoteSlidesSessionSlideNumber(sessionId, slideNumber)
      .then(session => {
        dispatch({ type: SLIDES_SESSION_SUCCESS, payload: session });
      })
      .catch(err => {
        enqueueSnackbar(err.message, { variant: 'error' });
        console.error(err);
      });
  };

/**
 * Resets the current slide timer
 * @param sessionId The id of the RemoteSlidesSession object to update
 * @param enqueueSnackbar   Notistack helper
 */
export const resetSlideTimer =
  (sessionId, enqueueSnackbar) =>
  (dispatch, getState, { slidesApi }) => {
    return slidesApi
      .resetSlideTimer(sessionId)
      .then(session => {
        dispatch({ type: SLIDES_SESSION_SUCCESS, payload: session });
      })
      .catch(err => {
        enqueueSnackbar(err.message, { variant: 'error' });
        console.error(err);
      });
  };

/**
 * Sets the flag that allows participants to control their own slides view
 *
 * @param {string} sessionId The id of the RemoteSlidesSession object to update
 * @param {bool} participantsControlSlides can participants control their own slides view?
 * @param {function} enqueueSnackbar   Notistack helper
 */
export const setParticipantsControlSlides =
  (slidesSessionId, participantsControlSlides, enqueueSnackbar) =>
  (dispatch, getState, { slidesApi }) => {
    return slidesApi
      .setParticipantsControlSlides(slidesSessionId, participantsControlSlides)
      .then(session => {
        dispatch({ type: SLIDES_SESSION_SUCCESS, payload: session });
      })
      .catch(err => {
        enqueueSnackbar(err.message, { variant: 'error' });
        console.error(err);
      });
  };

/**
 * Watches for real time updates of a RemoteSlidesSession
 * @param sessionId The id of the RemoteSlidesSession to watch
 * @param cancelCallback    Callback that receives the 'cancel' method from Firestore
 */
export const watchSession =
  (sessionId, cancelCallback) =>
  (dispatch, getState, { slidesApi }) => {
    const cancel = slidesApi.watchRemoteSlidesSession(
      sessionId,
      (slideUrl, currentSlide, sessionName) => {
        if (slideUrl) {
          //KBM - You could use currentSlide info here to bolt on the ability to capture User input between slides
          dispatch({ type: SLIDES_SESSION_WATCH_UPDATED, payload: { slideUrl, sessionName } });
        } else {
          window.close();
        }
      },
    );
    cancelCallback(cancel);
  };
