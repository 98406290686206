import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';
// library components
import { Box, Typography } from '@material-ui/core';
// cr components
import AssessmentParticipantForm from './AssessmentParticipantForm';
import ParticipantQuestionResult from './ParticipantQuestionResult';
import AssessmentParticipantAllAnswered from './AssessmentParticipantAllAnswered';
// selectors
import {
  useAssessmentSelector,
  getParticipantAnswers,
  getSortedQuestions,
  getParticipantAnsweredAllQuestions,
} from 'redux/selectors/assessmentSelectors';
// styles
import { useAssessmentStyles } from '../Shared/useAssessmentStyles';

/**
 * Assessment view for the 'write' phase on the Participant side
 */
const AssessmentParticipantWrite = () => {
  const commonClasses = useAssessmentStyles();
  const participantId = useSelector(state => state.firebase.auth.uid);
  const {
    id,
    config: { reviewResults },
    state: { currentQuestionIdx },
    participantData: { questionIdxs },
  } = useAssessmentSelector();
  const [questionIdx, setQuestionIdx] = useState(null);
  const participantAnswers = useSelector(getParticipantAnswers(participantId));
  const questions = useSelector(getSortedQuestions());
  const allAnswered = useSelector(getParticipantAnsweredAllQuestions(participantId));
  const currentQuestion = questions[questionIdx];

  useEffect(() => {
    if (reviewResults) {
      setQuestionIdx(currentQuestionIdx);
    } else {
      setQuestionIdx(questionIdxs[participantId] || 0);
    }
  }, [reviewResults, currentQuestionIdx, questionIdxs[participantId]]);

  if (!reviewResults && allAnswered) {
    return <AssessmentParticipantAllAnswered questionCount={questions.length} />;
  }

  if (!currentQuestion) return null;

  return (
    <div className="position-relative d-flex flex-column align-items-center w-100">
      <Box className="mb-2 mb-sm-5" mt={{ xs: -5, sm: -3 }}>
        <Typography className={commonClasses.questionText}>
          {`Question #${questionIdx + 1} of ${questions.length}: `}
          {currentQuestion.data.question}
        </Typography>
      </Box>

      {participantAnswers[currentQuestion.id] && reviewResults && (
        <ParticipantQuestionResult currentQuestion={currentQuestion} />
      )}

      <div
        className={cx('w-100 justify-content-center', {
          'd-none': participantAnswers[currentQuestion.id],
          'd-flex': !participantAnswers[currentQuestion.id],
        })}
      >
        <AssessmentParticipantForm questionIdx={questionIdx} />
      </div>
    </div>
  );
};

export default AssessmentParticipantWrite;
