import React from 'react';
import TextInput from 'components/CustomFormComponents/TextInput';
import * as Yup from 'yup';

const requiredString = Yup.string().required('Required');
const notRequiredString = Yup.string();

export const LINEAR_SCALE_SCHEMA = {
  choices: Yup.object().shape({
    1: requiredString,
    2: notRequiredString,
    3: notRequiredString,
    4: notRequiredString,
    5: requiredString,
  }),
};

export const LINEAR_SCALE_INITIAL_VALUES = {
  choices: {
    1: 'Not great',
    2: 'So so',
    3: 'OK',
    4: 'Good',
    5: 'Fantastic',
  },
};

const LinearScale = () => (
  <div className="row">
    <div className="col-sm-6">
      <TextInput label="Minimum - 1" name="choices.1" required />
    </div>
    <div className="col-sm-6">
      <TextInput label="Less - 2" name="choices.2" />
    </div>
    <div className="col-sm-6">
      <TextInput label="Neutral - 3" name="choices.3" />
    </div>
    <div className="col-sm-6">
      <TextInput label="More - 4" name="choices.4" />
    </div>
    <div className="col-sm-6">
      <TextInput label="Most - 5" name="choices.5" required />
    </div>
  </div>
);

export default LinearScale;
