import React from 'react';
import Results2510ListItem from './Results2510ListItem';
import List from '@material-ui/core/List';

const Results2510List = ({ classes, ideas }) => {
  return (
    <List className={classes}>
      {ideas.map((idea, idx) => {
        const place = idx + 1;
        return (
          <Results2510ListItem
            key={idx}
            idea={idea}
            place={place}
            hasMaxScore={idea.score === 25}
          />
        );
      })}
    </List>
  );
};

export default Results2510List;
