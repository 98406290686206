export const VARIABLE_QUESTION_TYPES = {
  SHORT_ANSWER: 'shortAnswer',
  LINEAR_SCALE: 'linearScale',
  SINGLE_CHOICE: 'singleChoice',
  MULTIPLE_CHOICE: 'multipleChoice',
  SENTIMENT: 'sentiment',
};

export const VARIABLE_QUESTION_TYPES_TITLES = {
  [`${VARIABLE_QUESTION_TYPES.SHORT_ANSWER}`]: 'Short Answer',
  [`${VARIABLE_QUESTION_TYPES.LINEAR_SCALE}`]: 'Linear Scale',
  [`${VARIABLE_QUESTION_TYPES.SINGLE_CHOICE}`]: 'Single Choice',
  [`${VARIABLE_QUESTION_TYPES.MULTIPLE_CHOICE}`]: 'Multiple Choice',
  [`${VARIABLE_QUESTION_TYPES.SENTIMENT}`]: 'Sentiment',
};
