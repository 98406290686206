import React from 'react';
import { useSelector } from 'react-redux';
import { map } from 'lodash';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
// library components
import { Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
// cr components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import NoteCard from './NoteCard';
import DottedProgress from 'components/DottedProgress';
// selectors
import {
  getParticipantNotes,
  getParticipantSharedNotesCount,
  useNoteAndVoteSelector,
} from 'redux/selectors/noteAndVoteSelectors';

const useStyles = makeStyles(theme => ({
  container: {
    padding: '0 10px 20px !important',
  },
  grid: {
    width: 'calc(100% + 20px)',
    marginBottom: theme.spacing(4),
  },
  successColor: {
    color: theme.palette.indigo.main,
  },
}));

/**
 * Note & Vote view for the 'share' phase on the Participant side
 */
const NoteAndVoteParticipantShare = () => {
  const participantId = useSelector(state => state.firebase.auth.uid);
  const participantNotes = useSelector(getParticipantNotes(participantId));
  const theme = useTheme();
  const classes = useStyles();
  const {
    config: { shareLimit },
  } = useNoteAndVoteSelector();
  const sharedNotesCount = useSelector(getParticipantSharedNotesCount(participantId));

  const renderParticipantNotes = () =>
    map(participantNotes, note => (
      <GridItem key={note.noteId} className={classes.container} xs={12} sm={6} md={3} lg={3} xl={3}>
        <NoteCard note={note} context="share" />
      </GridItem>
    ));

  return (
    <>
      <GridContainer direction="row" className={classes.grid}>
        {renderParticipantNotes()}
      </GridContainer>
      {sharedNotesCount === shareLimit && (
        <CheckCircleIcon className={cx(classes.successColor, 'mb-1')} />
      )}
      {participantNotes.length > 0 && (
        <DottedProgress
          className="ml-1"
          activeColor={theme.palette.indigo.main}
          totalCount={shareLimit}
          activeCount={sharedNotesCount}
        />
      )}
      <Typography
        className={cx(sharedNotesCount === shareLimit && classes.successColor, 'mb-3')}
        color="textSecondary"
        variant="body2"
      >
        {sharedNotesCount === shareLimit
          ? `You're done! Your facilitator will begin the review shortly.`
          : participantNotes.length > 0
            ? `You've shared ${sharedNotesCount} of ${shareLimit} ideas`
            : `Please wait. Everyone is sharing notes. Once they're done, you'll be able to vote.`}
      </Typography>
    </>
  );
};

export default NoteAndVoteParticipantShare;
