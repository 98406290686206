import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTheme, makeStyles } from '@material-ui/styles';
import { map, isEmpty, filter, keyBy, keys } from 'lodash';
// library components
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Box,
  Tooltip,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
// redux selectors
import { getParticipantsMap } from 'redux/selectors/participantsSelectors';
// utils
import { impersonateUser } from 'utils/impersonateUser';

const useStyles = makeStyles({
  dialogContent: {
    minWidth: 500,
    minHeight: 100,
  },
  dialogContentLargeBox: {
    minWidth: 500,
    minHeight: 200,
  },
});

/**
 * A dialog that shows participants that are passed to it at either full
 * or partial opacity depending on if they have completed an action
 * in an activity
 */
const ParticipantsHaveCompletedDialog = ({
  closeDialog,
  hasCompletedLabel,
  hasntCompletedLabel,
  completedCountMax,
  open,
  participantsCompletedStatus,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const sessionParticipants = useSelector(getParticipantsMap);
  const session = useSelector(state => state.firestore.data.session);

  const getParticipantStyle = completed => ({
    background: 'white',
    border: '1px solid gray',
    borderRadius: 20,
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    opacity: completed ? '1' : '.33',
    padding: theme.spacing(1),
    cursor: 'pointer',
  });

  const handleParticipantClick = id => {
    impersonateUser.impersonate(session, id);
  };

  const participantsCompleted = isEmpty(sessionParticipants)
    ? []
    : keyBy(filter(participantsCompletedStatus, { completed: true }), 'id');

  const renderParticipantsCompleted = () => {
    return map(participantsCompleted, (p, idx) => (
      <Tooltip title="Impersonate User" arrow enterDelay={250} key={`pc-${idx}`}>
        <span
          className="shadow-sm"
          key={idx}
          style={getParticipantStyle(p.completed)}
          onClick={() => handleParticipantClick(p.id)}
        >
          {sessionParticipants[p.id]?.name} {p.completedCount ? `(${p.completedCount})` : ''}
        </span>
      </Tooltip>
    ));
  };

  const participantsNotCompleted = isEmpty(sessionParticipants)
    ? []
    : keyBy(filter(participantsCompletedStatus, { completed: false }), 'id');

  const renderParticipantsNotCompleted = () => {
    return map(participantsNotCompleted, (p, idx) => (
      <Tooltip title="Impersonate User" arrow enterDelay={250} key={`pnc-${idx}`}>
        <span
          className="shadow-sm"
          key={idx}
          style={getParticipantStyle(p.completed)}
          onClick={() => handleParticipantClick(p.id)}
        >
          {sessionParticipants[p.id]?.name}
        </span>
      </Tooltip>
    ));
  };

  return (
    <div>
      <Dialog open={open} maxWidth="md">
        <DialogTitle disableTypography className="d-flex justify-content-between pb-0">
          <Box className="mb-2">
            <Typography variant="h6" style={{ textTransform: 'initial' }}>
              Meeting Participants
            </Typography>
            <Typography style={{ textTransform: 'initial' }}>
              Click on a participant name to remotely assist them
            </Typography>
          </Box>
          <IconButton
            aria-label="close"
            className="position-relative align-self-start"
            onClick={closeDialog}
            style={{
              top: `-${theme.spacing(1)}px`,
              right: `-${theme.spacing(2)}px`,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="p-3" dividers>
          <Box className={classes.dialogContent}>
            <Typography className="mb-1 font-weight-bold">{hasntCompletedLabel}</Typography>
            <div className="d-flex flex-wrap mb-2">
              {keys(participantsNotCompleted).length ? renderParticipantsNotCompleted() : 'None'}
            </div>
          </Box>
          <Box className={classes.dialogContent}>
            <Typography className="mb-1 font-weight-bold">
              {hasCompletedLabel} {completedCountMax ? `(${completedCountMax} max allowed)` : ''}
            </Typography>
            <div className="d-flex flex-wrap mb-2">
              {keys(participantsCompleted).length ? (
                renderParticipantsCompleted()
              ) : (
                <span className="text-secondary text-italic">None</span>
              )}
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

ParticipantsHaveCompletedDialog.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  hasCompletedLabel: PropTypes.string.isRequired,
  hasntCompletedLabel: PropTypes.string.isRequired,
  // The max they can do.
  completedCountMax: PropTypes.number,
  participantsCompletedStatus: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      // participant id
      completed: PropTypes.bool,
      // participant has completed task that this modal is reporting on
      completedCount: PropTypes.number,
    }),
  ).isRequired,
};

export default ParticipantsHaveCompletedDialog;
