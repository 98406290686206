import { useShallowEqualSelector } from '../utils';

export const useExportsSelector = () => {
  return useShallowEqualSelector(
    ({ googleSlidesExports: { exporting, exportsLoading, exportsIds } }) => {
      return {
        exporting,
        exportsIds,
        exportsLoading,
      };
    },
  );
};

export const useExportsDetailsSelector = exportId => {
  return useShallowEqualSelector(({ googleSlidesExports: { exportsMap } }) => {
    return exportsMap[exportId];
  });
};
