import { ASSESSMENT_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';
import { enqueueErrorSnackbar } from 'redux/actions/notifierActions';
import { updateActivityView } from 'redux/actions/activityActions';
import { createOutput } from 'utils/assessment';

export const goToNextQuestion =
  assessmentId =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${assessmentId}`)
      .set(
        {
          state: {
            currentQuestionIdx: db.FieldValue.increment(1),
          },
        },
        { merge: true },
      )
      .catch(err => {
        dispatch(enqueueErrorSnackbar('Error changing current question'));
        console.error(err);
      });
  };

export const goToPrevQuestion =
  assessmentId =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${assessmentId}`)
      .set(
        {
          state: {
            currentQuestionIdx: db.FieldValue.increment(-1),
          },
        },
        { merge: true },
      )
      .catch(err => {
        dispatch(enqueueErrorSnackbar('Error changing current question'));
        console.error(err);
      });
  };

/**
 * Add participant answer
 *
 * @param {string} assessmentId
 * @param {string} participantId
 * @param {string} questionId
 * @param {string} value
 */
export const updateParticipantAnswer =
  (assessmentId, participantId, questionId, questionIdx, value, successCb) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;
    // const { questionIdx } = getState().firestore.data.assessment.participantData;

    db()
      .doc(`${CONFIG.collection}/${assessmentId}`)
      .set(
        {
          participantData: {
            answers: {
              [participantId]: {
                [questionId]: value,
              },
            },
            questionIdxs: {
              [participantId]: db.FieldValue.increment(1),
            },
          },
        },
        { merge: true },
      )
      .then(() => {
        // dispatch(updateOutput());
        if (successCb) successCb();
      })
      .catch(err => {
        dispatch(enqueueErrorSnackbar('Error updating answers'));
        console.error(err);
      });
  };

/**
 * Add participant answer
 *
 * @param {string} assessmentId
 * @param {string} value
 */
export const updateQuestionResultView =
  (assessmentId, value) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${assessmentId}`)
      .set(
        {
          state: {
            questionResultView: value,
          },
        },
        { merge: true },
      )
      .catch(err => {
        dispatch(enqueueErrorSnackbar('Error toggling view'));
        console.error(err);
      });
  };

/**
 * Create the activity output and update the view to 'results'
 * @param {String} assessmentId
 */
export const endAssessment =
  assessmentId =>
  async (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;
    const { id: sessionId } = getState().firestore.data.session;
    const {
      config: { questions },
      participantData: { answers },
    } = getState().firestore.data[CONFIG.collection];

    const participants = (await db().doc(`participants/${sessionId}`).get()).data();

    const output = createOutput(questions, answers, participants);

    db()
      .doc(`${CONFIG.collection}/${assessmentId}`)
      .set(
        {
          output,
        },
        { merge: true },
      )
      .then(() => {
        dispatch(updateActivityView(CONFIG, assessmentId, 'results'));
      })
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error generating results'));
      });
  };

/**
 * Sets the activity to the 'write' phase
 * @param {String} assessmentId
 */
export const startAssessment =
  assessmentId =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${assessmentId}`)
      .set(
        {
          state: { activeView: 'write' },
        },
        { merge: true },
      );
  };
