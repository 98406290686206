import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray, useFormikContext, useField } from 'formik';
// 3rd party components
import { Box, FormLabel, InputAdornment } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
// cr components
import TextInput from 'components/CustomFormComponents/TextInput';
import AddButton from '../../AddButton';

const QuestionWithChoices = ({ fieldName }) => {
  const { values, submitCount } = useFormikContext();
  const [field, meta] = useField(fieldName);
  const { error, touched } = meta;

  const hasError = (error && touched) || (submitCount && error) ? true : false;

  if (!values[fieldName]) return null;

  return (
    <Box>
      <FormLabel component="legend" error={hasError} required className="mb-1">
        <span className="text-capitalize">{fieldName}</span>
      </FormLabel>
      <FieldArray name={fieldName}>
        {({ insert, remove, push }) => (
          <Box>
            {values[fieldName].length > 0 &&
              values[fieldName].map((option, index) => (
                <Box key={index}>
                  <TextInput
                    name={`${fieldName}.${index}`}
                    endAdornment={
                      <InputAdornment>
                        <CancelIcon
                          htmlColor="#666666"
                          onClick={() => remove(index)}
                          fontSize="small"
                          className="hover-pointer"
                        />
                      </InputAdornment>
                    }
                  />
                </Box>
              ))}
            <AddButton handleClick={() => push('')} text="Add choice" />
          </Box>
        )}
      </FieldArray>
      {hasError && !Array.isArray(error) ? (
        <Box component="span" color="error.main" fontWeight={400}>
          {error}
        </Box>
      ) : null}
    </Box>
  );
};

QuestionWithChoices.propTypes = {
  fieldName: PropTypes.string.isRequired,
};

export default QuestionWithChoices;
