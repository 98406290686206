import { UPDATE_MEETING_ROOM } from '../types/meetingRoomTypes';
import { isMobile } from 'react-device-detect';
import { enqueueSnackbar, enqueueErrorSnackbar } from '../actions/notifierActions';
import { createMainMeetRoomId } from '../../utils/createMeetRoomIds';
import { includes } from 'lodash';

/**
 * Updates the status of the meeting room
 * @param {string} status one of ['loading', 'loaded']
 */
export const updateMeetingRoom = roomConfig => dispatch => {
  if (isMobile) return;

  dispatch({
    type: UPDATE_MEETING_ROOM,
    payload: roomConfig,
  });
};

/**
 * Join a meeting room
 *
 * @param {object} room - {roomId, roomName}
 * @param {string} message
 */
export const joinMeetingRoom = (room, message) => (dispatch, getState) => {
  if (isMobile || getState().meetingRoom.roomId === room.roomId) return;

  const currentState = getState();
  const currentUser = currentState.firebase.auth;
  const currentRoom = currentState.meetingRoom;
  const currentSession = currentState.firestore.data.session;
  const userIsParticipant = _currentUserIsParticipant(currentSession, currentUser);

  if (message) dispatch(enqueueSnackbar({ message }));

  if (room.roomId) {
    room.previousRoom = {
      roomId: currentRoom.roomId,
      roomName: currentRoom.roomName,
    };

    dispatch({
      type: UPDATE_MEETING_ROOM,
      payload: {
        ...room,
        roomStatus: 'routing',
        // automatically mute participant audio when changing rooms
        audioMuted: userIsParticipant ? true : currentRoom.audioMuted,
      },
    });
  }
};

/**
 * Join the main meeting room for the session
 *
 * @param {string} sessionId
 * @param {object} options
 * @param {string} options.message - an optional message to show while switching rooms
 * @param {string} options.roomStatus - an optional status to set while switching rooms
 */
export const joinMainRoom =
  (sessionId, { message, roomStatus }) =>
  (dispatch, getState) => {
    if (isMobile) return;
    const currentState = getState();
    const currentUser = currentState.firebase.auth;
    const currentRoom = currentState.meetingRoom;
    const currentSession = currentState.firestore.data.session;
    const userIsParticipant = _currentUserIsParticipant(currentSession, currentUser);

    dispatch(enqueueSnackbar({ message: message || 'Joining main room' }));

    dispatch({
      type: UPDATE_MEETING_ROOM,
      payload: {
        roomId: createMainMeetRoomId(sessionId),
        roomStatus: roomStatus || 'routing',
        roomName: 'Main',
        // automatically mute participant audio when changing rooms
        audioMuted: userIsParticipant ? true : currentRoom.audioMuted,
        previousRoom: {
          roomId: currentRoom.roomId,
          roomName: currentRoom.roomName,
        },
      },
    });
  };

/**
 * Initialize the meeting room
 */
export const initMeetingRoom = () => dispatch => {
  if (isMobile) return;

  dispatch({
    type: UPDATE_MEETING_ROOM,
    payload: {
      roomId: '',
      roomStatus: 'loading',
      roomName: '',
    },
  });
};

/**
 * End a sidebar meeting
 *
 * @param {string} roomId - locally constructed roomId
 */
export const destroySidebarMeeting = roomId => {
  return (dispatch, getState, { getFirebase }) => {
    const previousRoom = getState().meetingRoom.previousRoom;
    const db = getFirebase().firestore();
    const sidebarId = roomId.split('-')[2];
    const sessionId = roomId.split('-')[1];

    // set status to routing before destroying the room
    // so we don't trigger auto-routing to main room in SessionDetail.js
    dispatch({
      type: UPDATE_MEETING_ROOM,
      payload: { roomStatus: 'routing' },
    });

    db.doc(`sidebarMeetings/${sidebarId}`)
      .delete()
      .then(() => {
        if (previousRoom.roomId) {
          dispatch(
            joinMeetingRoom(
              {
                roomId: previousRoom.roomId,
                roomName: previousRoom.roomName,
              },
              `Joining ${previousRoom.roomName}`,
            ),
          );
        } else {
          dispatch(joinMainRoom(sessionId, {}));
        }
      })
      .catch(err => {
        enqueueErrorSnackbar('Error ending sidebar');
        console.log(err);
      });
  };
};

/**
 * Leave a sidebar room
 *
 * @param {string} roomId - locally constructed room id
 * @param {string} userId - userId of user leaving the room
 * @param {boolean} udpateDb - whether to update the user's status for the sidebar in firestore
 */
export const leaveSidebarMeeting = (roomId, userId, updateDb) => {
  return (dispatch, getState, { getFirebase }) => {
    const previousRoom = getState().meetingRoom.previousRoom;
    const firestore = getFirebase().firestore;
    const db = getFirebase().firestore();
    const sidebarId = roomId.split('-')[2];

    if (updateDb) {
      db.doc(`sidebarMeetings/${sidebarId}`)
        .set(
          {
            userIds: firestore.FieldValue.arrayRemove(userId),
            accepted: {
              [userId]: firestore.FieldValue.delete(),
            },
          },
          { merge: true },
        )
        .then(() => {
          if (previousRoom.roomId) {
            dispatch(
              joinMeetingRoom(
                {
                  roomId: previousRoom.roomId,
                  roomName: previousRoom.roomName,
                },
                `Joining ${previousRoom.roomName}`,
              ),
            );
          } else {
            dispatch(initMeetingRoom());
          }
        });
    } else {
      if (previousRoom.roomId) {
        dispatch(
          joinMeetingRoom(
            {
              roomId: previousRoom.roomId,
              roomName: previousRoom.roomName,
            },
            `Joining ${previousRoom.roomName}`,
          ),
        );
      } else {
        dispatch(initMeetingRoom());
      }
    }
  };
};

const _currentUserIsParticipant = (session, user) => {
  return user.uid !== session?.facilitatorId && !includes(session?.coFacilitators, user.uid);
};
