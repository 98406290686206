import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useFirebase } from 'react-redux-firebase';
import { makeStyles } from '@material-ui/core/styles';
// library components
import { IconButton, Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

// action
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from 'redux/actions/notifierActions';
import { useDropzone } from 'react-dropzone';
import { uploadFile } from 'utils/uploadFile';
import { showAppOverlaySpinner } from 'redux/actions/facilitatorSessionActions';
import { sleep } from 'utils/sleep';

const useStyles = makeStyles(theme => ({
  closeIcon: {
    position: 'relative',
    right: `-${theme.spacing(2)}px`,
    top: `-${theme.spacing(1)}px`,
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: 210,
    padding: 20,
    background: '#E1E1E1',
    borderRadius: 2,
    border: '1px solid #D6D6D6',
  },
  image: {
    height: 170,
  },
  uploadContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: 170,
    border: '1px dashed #C3C3C3',
    cursor: 'pointer',
  },
  uploadContainerDrag: {
    border: `2px dashed #CFCFCF !important`,
    background: 'rgba(0,0,0,.1)',
  },
  icon: {
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
    fontSize: 30,
  },
  text: {
    color: theme.palette.primary.main,
    fontSize: 11,
  },
  removeButton: {
    position: 'absolute',
    top: -5,
    right: -5,
    padding: 4,
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    background: '#f0f0f0',
    '&:hover': {
      background: '#f0f0f0',
    },
  },
  cancelIcon: {
    fontSize: 13,
  },
}));

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '50px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#D6D6D6',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: 'black',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

/**
 * Renders a Dialog to upload signed deal contracts
 */
export default function UploadSignedContractDialog({
  handleClose,
  isOpen,
  closeDialog,
  dealId,
  actionType,
}) {
  const classes = useStyles();
  const firebase = useFirebase();
  const dispatch = useDispatch();

  const onDrop = async (acceptedFiles, fileRejections, event) => {
    dispatch(showAppOverlaySpinner(true));

    try {
      await uploadFile(acceptedFiles[0], `signed-contracts`, { dealId });
      await firebase.functions().httpsCallable('deals-onCall_contractUploadCb')({
        dealId,
        storagePath: `signed-contracts/${acceptedFiles[0].name}`,
        action: actionType,
      });
      dispatch(enqueueSuccessSnackbar());
      closeDialog();
    } catch (error) {
      dispatch(enqueueErrorSnackbar('An error occurred while uploading the contract.'));
    } finally {
      dispatch(showAppOverlaySpinner(false));
    }
  };

  const { getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    accept: ['application/pdf'],
    maxFiles: 1,
    noClick: true,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragAccept, isDragReject],
  );

  return (
    <div>
      <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle disableTypography className="pb-0">
          <div className="d-flex justify-content-between">
            <Typography variant="h6">
              {actionType === 'replace' ? 'Replace Signed Contract' : 'Upload Signed Contract'}
            </Typography>
            <IconButton aria-label="close" onClick={handleClose} className={classes.closeIcon}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent className="pb-4">
          <div>
            {actionType === 'replace' && (
              <Typography className="mb-3">
                <b>Note:</b> A signed contract was previously uploaded. If you drop a new file here
                the old one will be moved to the trash in google drive
              </Typography>
            )}

            <div {...getRootProps({ style })}>
              <input {...getInputProps()} />
              {isDragReject ? (
                <Typography color="error">Only PDFs are accepted</Typography>
              ) : (
                <Typography color="textSecondary">Drag 'n' drop PDF here</Typography>
              )}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

UploadSignedContractDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};
