import { useShallowEqualSelector } from '../utils';
import { createSelector } from 'reselect';
import { includes } from 'lodash';
import { isMobile } from 'react-device-detect';

const sessionSelector = state => state.firestore.data.session;

export const useParticipantSessionSelector = () => {
  return useShallowEqualSelector(({ participantSession: { selectedView } }) => {
    return {
      selectedView,
    };
  });
};

export const getCurrentActivityType = createSelector([sessionSelector], session =>
  session?.activeRoute ? session.activeRoute.split('/')[0] : null,
);

export const getParticipantCanControlView = createSelector([sessionSelector], session => {
  if (session?.activeRoute) {
    return !includes(['welcome', 'feedback'], session.activeRoute);
  }

  return false;
});

export const getShowVideoOnboarding = createSelector(
  [getParticipantCanControlView],
  participantCanControlView =>
    !localStorage.getItem('hasSeenVideoOnboarding') && participantCanControlView && !isMobile,
);
