import { enqueueErrorSnackbar } from 'redux/actions/notifierActions';
import { includes } from 'lodash';
import { generateRandomId } from 'utils/generateRandomId';
import moment from 'moment';
import { createOutput } from 'utils/scorecard';

import { SCORECARD_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';

/**
 * Update the active view for the activity
 * @param {String} view
 * @param {String} scorecardId
 */
export const updateActivityView =
  (view, scorecardId) =>
  (dispatch, getState, { getFirebase }) => {
    if (!includes(CONFIG.views, view)) {
      console.error(`View '${view}' does not exist in the activity config`);
      return;
    }

    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${scorecardId}`)
      .set(
        {
          state: {
            activeView: view,
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error changing activity view'));
      });
  };

/**
 * Update current tester
 *
 * @param {String} scorecardId
 * @param {String} testerId
 */
export const updateCurrentTester =
  (scorecardId, testerId) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${scorecardId}`)
      .set(
        {
          state: {
            currentTester: testerId,
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error update current tester'));
      });
  };

/**
 * Add participant answer
 *
 * @param {String} scorecardId
 * @param {String} testerId
 * @param {String} participantId
 * @param {String} questionId
 * @param {String} answer
 */
export const addParticipantAnswer =
  (scorecardId, testerId, participantId, questionId, answer) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${scorecardId}`)
      .set(
        {
          participantData: {
            answers: {
              [testerId]: {
                [participantId]: {
                  [questionId]: answer,
                },
              },
            },
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error adding participant answer'));
      });
  };

/**
 * Remove participant answer
 *
 * @param {String} scorecardId
 * @param {String} testerId
 * @param {String} participantId
 * @param {String} questionId
 */
export const removeParticipantAnswer =
  (scorecardId, testerId, participantId, questionId) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;
    const path = `participantData.answers.${testerId}.${participantId}.${questionId}`;

    db()
      .doc(`${CONFIG.collection}/${scorecardId}`)
      .update({
        [path]: db.FieldValue.delete(),
      })
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error removing participant answer'));
      });
  };

/**
 * Create a note
 *
 * @param {String} scorecardId
 * @param {String} participantId
 * @param {String} testerId
 * @param {String} note
 * @param {String} order
 * @param {Function} successCb
 */
export const createNote =
  (scorecardId, participantId, testerId, note, order, successCb) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;
    const newNoteId = generateRandomId();

    db()
      .doc(`${CONFIG.collection}/${scorecardId}`)
      .set(
        {
          participantData: {
            notes: {
              [`${newNoteId}`]: {
                participantId,
                testerId,
                noteId: newNoteId,
                note,
                order: order,
                timeCreated: moment().format(),
              },
            },
          },
        },
        { merge: true },
      )
      .then(() => {
        successCb();
      })
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error creating note'));
      });
  };

/**
 * Edit a note that has already been submitted
 * @param {String} note
 * @param {String} noteId
 * @param {String} scorecardId
 * @param {Function} successCb
 */
export const editNote =
  (note, noteId, scorecardId, successCb) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${scorecardId}`)
      .set(
        {
          participantData: {
            notes: {
              [`${noteId}`]: {
                note,
              },
            },
          },
        },
        { merge: true },
      )
      .then(() => {
        successCb();
      })
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error editing note'));
      });
  };

/**
 * Add participant note action
 *
 * @param {String} scorecardId
 * @param {String} participantId
 * @param {String} noteId
 * @param {String} action
 */
export const addParticipantNoteAction =
  (scorecardId, participantId, noteId, action) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${scorecardId}`)
      .set(
        {
          participantData: {
            notes: {
              [noteId]: {
                participantsActions: {
                  [participantId]: {
                    action,
                    time: moment().format(),
                  },
                },
              },
            },
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error adding participant note action'));
      });
  };

/**
 * Remove participant note action
 *
 * @param {String} scorecardId
 * @param {String} participantId
 * @param {String} noteId
 */
export const removeParticipantNoteAction =
  (scorecardId, participantId, noteId) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;
    const path = `participantData.notes.${noteId}.participantsActions.${participantId}`;

    db()
      .doc(`${CONFIG.collection}/${scorecardId}`)
      .update({
        [path]: db.FieldValue.delete(),
      })
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error removing participant note action'));
      });
  };

/**
 * Add tester id and order in testersComplete
 *
 * @param {String} scorecardId
 * @param {String} testerId
 * @param {String} order
 */
export const updateTestersComplete =
  (scorecardId, testerId, order) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${scorecardId}`)
      .set(
        {
          state: {
            testersCompleted: {
              [testerId]: {
                id: testerId,
                order,
              },
            },
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error update tester complete'));
      });
  };

/**
 * Create the activity output and update the view to 'results'
 * @param {String} scorecardId
 */
export const revealResults =
  scorecardId =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;
    const state = getState();
    const { answers } = state.firestore.data.scorecard.participantData;
    const { sprintQuestions, prototypeQuestions } = state.firestore.data.scorecard.config;
    const output = createOutput(answers, sprintQuestions, prototypeQuestions);

    db()
      .doc(`${CONFIG.collection}/${scorecardId}`)
      .set(
        {
          output,
        },
        { merge: true },
      )
      .then(() => {
        dispatch(updateActivityView('results', scorecardId));
      })
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error generating results'));
      });
  };

/**
 * Add participant comment
 *
 * @param {String} scorecardId
 * @param {String} testerId
 * @param {String} participantId
 * @param {String} questionId
 * @param {String} comment
 */
export const addParticipantComment =
  (scorecardId, testerId, participantId, questionId, comment) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${scorecardId}`)
      .set(
        {
          participantData: {
            comments: {
              [testerId]: {
                [participantId]: {
                  [questionId]: comment,
                },
              },
            },
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error adding participant comment'));
      });
  };

/**
 * Remove participant comment
 *
 * @param {String} scorecardId
 * @param {String} testerId
 * @param {String} participantId
 * @param {String} questionId
 */
export const removeParticipantComment =
  (scorecardId, testerId, participantId, questionId) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;
    const path = `participantData.comments.${testerId}.${participantId}.${questionId}`;

    db()
      .doc(`${CONFIG.collection}/${scorecardId}`)
      .update({
        [path]: db.FieldValue.delete(),
      })
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error removing participant comment'));
      });
  };
