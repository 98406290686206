import React from 'react';
import { Chip } from '@material-ui/core';

/**
 * TagChip
 * @param {object} props
 * @param {string} props.label
 * @param {'small'|'medium'=} props.size
 */
export default function TagChip({ label, size = 'small' }) {
  return <Chip size={size} label={label} style={{ margin: 3, borderRadius: 3 }} />;
}
