import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore, compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import RemoteSlidesApi from '@voltage-control/remote-slides-api';
import reducers from 'redux/reducers';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/functions';
import 'firebase/storage';
import { ReactReduxFirebaseProvider, getFirebase } from 'react-redux-firebase';
import { createFirestoreInstance } from 'redux-firestore';
import { ThemeProvider } from '@material-ui/styles';
import { SnackbarProvider } from 'notistack';
import { ConfirmProvider } from 'material-ui-confirm';
import mixpanel from 'mixpanel-browser';

import AuthIsLoaded from 'components/AuthIsLoaded/AuthIsLoaded.js';
import App from 'App.js';
import controlRoomTheme from 'theme';

import 'assets/scss/material-dashboard-pro-react.scss?v=1.8.0';
import 'assets/scss/control-room.scss';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://2823fa9955b34bdfae12856ec59282b2@o385495.ingest.sentry.io/5228658',
    environment: process.env.REACT_APP_HOSTING_ENV,
    release: process.env.REACT_APP_BUILD_NUMBER,
    ignoreErrors: ['ResizeObserver loop limit exceeded'], // https://forum.sentry.io/t/resizeobserver-loop-limit-exceeded/8402/2
  });

  console.log('build number', process.env.REACT_APP_BUILD_NUMBER);
}

if (process.env.REACT_APP_HOSTING_ENV === 'prod') {
  mixpanel.init('ec644fb16d44cfdb1ec946ed2f971cd7');
}

const fbConfig = {
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
};

// Initialize firebase instance
firebase.initializeApp(fbConfig);

const db = firebase.firestore();

if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_USE_EMULATORS == '1') {
  console.log('Using Local Emulators');

  firebase.functions().useEmulator('localhost', 5001);
  firebase.auth().useEmulator('http://localhost:9099');
  firebase.storage().useEmulator('localhost', 9199);

  // Use the Firestore Emulator
  db.settings({
    host: 'localhost:8080',
    ssl: false,
  });
}

const slidesApi = new RemoteSlidesApi(
  firebase.firestore,
  firebase.functions,
  firebase.auth,
  firebase.storage,
);

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true,
        traceLimit: 25,
      })
    : compose;

const enhancer = composeEnhancers(
  applyMiddleware(thunk.withExtraArgument({ slidesApi, getFirebase })),
);

export const store = createStore(reducers, enhancer);

// react-redux-firebase config
const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true,
  enableClaims: true,
  allowMultipleListeners: true,
};

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
};

ReactDOM.render(
  <Provider store={store}>
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      autoHideDuration={3000}
    >
      <ReactReduxFirebaseProvider {...rrfProps}>
        <ThemeProvider theme={controlRoomTheme}>
          <ConfirmProvider>
            <AuthIsLoaded>
              <App />
            </AuthIsLoaded>
          </ConfirmProvider>
        </ThemeProvider>
      </ReactReduxFirebaseProvider>
    </SnackbarProvider>
  </Provider>,
  document.getElementById('root'),
);
