import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFirestore } from 'react-redux-firebase';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import moment from 'moment';
import 'moment-duration-format';

import { CountdownCircleTimer } from 'react-countdown-circle-timer';

import './participantTimer.scss';

const RemainingTime = ({ remainingTime }) => {
  const renderedTimer =
    remainingTime > 60
      ? moment.duration(remainingTime, 'seconds').format('m:ss')
      : moment()
          .utcOffset(0)
          .set({ hour: 0, minute: 0, second: remainingTime, millisecond: 0 })
          .format('m:ss');

  return (
    <div className="timer">
      <div className="value">{renderedTimer}</div>
    </div>
  );
};

const ParticipantTimer = ({ session }) => {
  const [durationSeconds, setDurationSeconds] = useState(0);
  const timerValue = session.timerValue || 0;
  const timerRunning = session.timerRunning || false;
  const timerStartedAt = session.timerStartedAt || 0;
  const db = useFirestore();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    let duration =
      timerRunning && timerValue
        ? timerValue - (db.Timestamp.now().seconds - timerStartedAt)
        : timerValue;

    if (duration < 0) {
      duration = 0;
    }

    // console.log('');
    // console.log('timerStartedAt', timerStartedAt);
    // console.log('timerValue', timerValue);

    // console.log('durationSeconds', duration);
    // console.log('system time', moment().unix());
    // console.log('firebase time', db.Timestamp.now().seconds);

    setDurationSeconds(duration);
  }, [timerValue, timerStartedAt, timerRunning]);

  return (
    <div className="participant-timer d-flex justify-content-center">
      <CountdownCircleTimer
        key={timerStartedAt + durationSeconds}
        isPlaying={timerRunning}
        duration={durationSeconds}
        colors={[['#d9d9d9', 0.0001], ['#02A05C', 0.89], ['#02A05C', 0.01], ['#E2351E']]}
        size={smallScreen ? 50 : 130}
        strokeWidth={smallScreen ? 3 : 8}
        // strokeLinecap="square"
      >
        <RemainingTime />
      </CountdownCircleTimer>
    </div>
  );
};

ParticipantTimer.propTypes = {
  session: PropTypes.object.isRequired,
};

export default ParticipantTimer;
