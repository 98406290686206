import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(theme => {
  return {
    root: {
      // width: 80,
      height: 40,
      paddingLeft: 12,
      paddingRight: 12,
      backgroundColor: '#DCE5EF',
      marginBottom: theme.spacing(2),
    },
  };
});

const useButtonStyles = makeStyles({
  buttonTitle: {
    position: 'relative',
    top: 1,
    left: '-3px',
    color: '#093B75',
    fontSize: 15,
    fontWeight: 400,
    flexShrink: 0,
  },
  iconContainer: {
    width: 17,
    height: 17,
    marginLeft: 5,
    borderRadius: '50%',
    color: 'white',
  },
  icon: {
    position: 'relative',
    bottom: '1px',
    fontSize: 15,
  },
});

/**
 * Renders the button for creating new custom parameters.
 */
const AddButton = ({ handleClick, text }) => {
  const classes = useStyles();
  const buttonClasses = useButtonStyles();
  return (
    <Button
      classes={classes}
      color="primary"
      style={{ textTransform: 'none' }}
      onClick={handleClick}
      type="button" // or else formik will try to submit the whole form when this is clicked
    >
      <div className="d-flex align-items-center">
        <Box component="span" color="text.primary" className={`${buttonClasses.buttonTitle}`}>
          {text}
        </Box>
        <Box bgcolor="primary.main" className={buttonClasses.iconContainer}>
          <AddIcon className={buttonClasses.icon} fontSize="small" />
        </Box>
      </div>
    </Button>
  );
};

AddButton.defaultProps = {
  text: 'Add',
};

AddButton.propTypes = {
  handleClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};

export default AddButton;
