import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty, isLoaded, useFirestoreConnect, useFirestore } from 'react-redux-firebase';

import ActivityTemplate from '../../../components/ActivityTemplate/ActivityTemplate';
import Participant2510Idea from 'components/25:10/Participant/Participant2510Idea';
import Participant2510Vote from 'components/25:10/Participant/Participant2510Vote';
import Participant2510Results from 'components/25:10/Participant/Participant2510Results';

import { session2510Selector } from 'redux/selectors/25:10/root2510Selectors';

const views = {
  idea: Participant2510Idea,
  vote: Participant2510Vote,
  results: Participant2510Results,
};

const Participant2510 = ({
  match: {
    params: { activityId },
  },
}) => {
  useFirestoreConnect(() => [
    {
      collection: '25:10',
      doc: `${activityId}`,
      storeAs: '25:10',
    },
  ]);

  const db = useFirestore();
  const participantId = useSelector(state => state.firebase.auth.uid);
  const session2510 = useSelector(session2510Selector);
  const id = session2510?.id;
  const participants = session2510?.participants;
  const participantsCount = participants?.length ?? 0;
  const activityLoaded = isLoaded(session2510);
  const activityEmpty = isEmpty(session2510);
  const activityRef = db.doc(`25:10/${activityId}`);

  /**
   * Add the participant to the array of activity participants
   */
  useEffect(() => {
    if (!activityLoaded || activityEmpty || activityId !== id) {
      return;
    }

    if (!participants.includes(participantId)) {
      activityRef
        .set(
          {
            participants: db.FieldValue.arrayUnion(participantId),
          },
          { merge: true },
        )
        .catch(err => {
          console.error(err);
        });
    }
  }, [participantsCount, activityLoaded, activityEmpty, participantId, activityId, id]);

  if (!isLoaded(session2510) || isEmpty(session2510) || activityId !== session2510?.id) {
    return null;
  }

  const Component = views[session2510.activeView];

  return (
    <ActivityTemplate type="25:10" name={session2510.name}>
      <Component activityId={activityId} />
    </ActivityTemplate>
  );
};

export default Participant2510;
