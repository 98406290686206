import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { map } from 'lodash';
import { Formik, Form } from 'formik';
// library components
import { Button, Select, InputLabel, Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  selectTitle: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(1),
    marginLeft: 'auto',
    marginRight: 'auto',
    fontSize: 16,
  },
  border: {
    borderColor: '#E1E1E1',
    borderTop: '1px solid',
    position: 'absolute',
    left: 0,
    right: 0,
  },
  select: {
    width: 255,
    margin: 'auto',
    padding: `4px ${theme.spacing(2)}px`,
    background: '#EEEEEE',
    color: '#5E5E5E',
    marginBottom: '50px',
  },
  cancelButton: {
    width: 105,
    height: 40,
    background: theme.palette.grey[600],
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.grey[500],
    },
  },
  button: {
    width: 105,
    height: 40,
    background: theme.palette.primary.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

const formSchema = Yup.object().shape({
  countCards: Yup.number().required('Required'),
});

/**
 * Renders form for selecting option
 */
const SelectOptionForm = ({ closeDialog, options, submitOptions, defaultValue }) => {
  const classes = useStyles();

  const [countCards, setCountCards] = useState(defaultValue);

  // Render participants name for select
  const renderOptions = () =>
    map(options, (option, idx) => (
      <option key={`option-${idx}`} value={option}>
        {`${option} cards`}
      </option>
    ));

  // Change count cards
  const handleChangeCountCards = event => {
    setCountCards(Number(event.target.value));
  };

  return (
    <Formik
      initialValues={{
        countCards: countCards,
      }}
      validationSchema={formSchema}
      onSubmit={() => {
        submitOptions(countCards);
      }}
    >
      {({ isSubmitting, submitForm }) => (
        <>
          <div className={classes.border}></div>
          <Form className="pb-3 d-flex flex-column align-items-center justify-content-center">
            <Box className="w-100 d-flex flex-column">
              <InputLabel shrink htmlFor="select-count-cards" className={classes.selectTitle}>
                Next round down-selects cards to:
              </InputLabel>
              <Select
                native
                value={countCards}
                className={classes.select}
                onChange={handleChangeCountCards}
              >
                {renderOptions()}
              </Select>

              <div className="form-dialog-buttons w-100 justify-content-between">
                {!!closeDialog && (
                  <Button
                    classes={{ root: classes.cancelButton }}
                    disabled={isSubmitting}
                    onClick={closeDialog}
                  >
                    CANCEL
                  </Button>
                )}
                <Button onClick={submitForm} classes={{ root: classes.button }}>
                  SAVE
                </Button>
              </div>
            </Box>
          </Form>
        </>
      )}
    </Formik>
  );
};

SelectOptionForm.propTypes = {
  closeDialog: PropTypes.func, // close modal form
  submitOptions: PropTypes.func.isRequired,
  defaultValue: PropTypes.number,
  options: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default SelectOptionForm;
