import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './index.scss';

const OverlayWithChildren = ({ children, fullscreen }) => {
  return (
    <>
      <div className={cx('overlay-with-children', { 'full-overlay': fullscreen })} />
      <div className={cx('overlay-with-children-children', { 'full-overlay': fullscreen })}>
        {children}
      </div>
    </>
  );
};

OverlayWithChildren.propTypes = {
  children: PropTypes.node.isRequired,
  fullScreen: PropTypes.bool, // overlay will take over whole screen
};

export default OverlayWithChildren;
