import React from 'react';
import { useSelector } from 'react-redux';
import { size, each } from 'lodash';
// library components
import { Box, Typography, makeStyles } from '@material-ui/core';
// cr components
import ItemDescription from 'components/Giveaway/Admin/ItemDescription';
import GiveawayDownloadEntires from 'components/Giveaway/Admin/GiveawayDownloadEntires';
// selectors
import { useGiveawaySelector, getWinnerParticipants } from 'redux/selectors/giveawaySelectors';
// actions

const useStyles = makeStyles({
  text: {
    marginBottom: 50,
    fontSize: 18,
    fontWeight: 300,
    textAlign: 'center',
  },
});

/**
 * Giveaway view for the 'pickWinner' phase on the Facilitator side
 */
const GiveawayAdminResults = () => {
  const classes = useStyles();
  const giveaway = useGiveawaySelector();
  const {
    name,
    config: { itemName, itemDescription },
  } = giveaway;

  const session = useSelector(state => state.firestore.data.session);
  const winnerParticipants = useSelector(getWinnerParticipants());

  // set button text
  const activityButtonText = 'Download Entries';

  // set winner text
  let winnersText = size(winnerParticipants) > 1 ? `Winners:` : `Winner:`;
  each(winnerParticipants, (participant, idx) => {
    if (idx + 1 === size(winnerParticipants)) {
      winnersText = `${winnersText} ${participant.participantName}`;
    } else {
      winnersText = `${winnersText} ${participant.participantName},`;
    }
  });

  return (
    <Box className="d-flex flex-column justify-content-center">
      <ItemDescription itemName={itemName} itemDescription={itemDescription} />
      <Typography className={classes.text}>{winnersText}</Typography>
      <GiveawayDownloadEntires
        topic={session.topic}
        activity="Giveaway"
        activityName={name}
        filename="Results.csv"
        buttonText={activityButtonText}
      />
    </Box>
  );
};

export default GiveawayAdminResults;
