import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { size } from 'lodash';
// library components
import { Box } from '@material-ui/core';
// cr components
import ActivityParticipantDetails from 'components/ActivityParticipantDetails';
import ActivityPromptAdmin from 'components/ActivityPromptAdmin';
import ActivityActionButton from 'components/CustomButtons/ActivityActionButton';
import FormDialog from 'components/Dialogs/FormDialog';
import SelectSuperVoteParticipantForm from 'components/Forms/SelectSuperVoteParticipantForm';
// selectors
import {
  useNoteAndMapSelector,
  getParticipantsHaveCreated,
  getSuperVotes,
} from 'redux/selectors/noteAndMapSelectors';
import { getParticipantsMap } from 'redux/selectors/participantsSelectors';
// actions
import { revealResults, updateSuperVoteParticipant } from 'redux/actions/noteAndMapActions';
// config
import { NOTE_AND_MAP_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';
// utils

/**
 * Note & Map view for the 'vote' phase on the Facilitator side
 */
const NoteAndMapAdminSuperVote = () => {
  const dispatch = useDispatch();
  const participantsData = useSelector(getParticipantsMap);
  const noteAndMap = useNoteAndMapSelector();
  const {
    id,
    prompt,
    participantData: { participants },
    state: { superVoteParticipantId },
  } = noteAndMap;
  const superVoteTypes = useSelector(getSuperVotes());
  const maxSuperVote = 2;
  const isSuperVoteComplete = size(superVoteTypes) === maxSuperVote;

  const [isModalOpen, setIsModalOpen] = useState(!superVoteParticipantId);
  const participantsHaveShared = useSelector(getParticipantsHaveCreated());

  const participantsSuperVoteText = isSuperVoteComplete
    ? 'Decider finished voting'
    : 'Decider is voting';

  // Close modal form
  const closeDialog = () => {
    setIsModalOpen(false);
  };

  // Add super vote participant id
  const submitSuperVoteParticipantId = participantId => {
    dispatch(updateSuperVoteParticipant(id, participantId));
    closeDialog();
  };
  return (
    <Box className="d-flex flex-column justify-content-center">
      <ActivityPromptAdmin
        prompt={prompt}
        collection={CONFIG.collection}
        activityId={id}
        className="mb-5"
      />
      <ActivityParticipantDetails
        headerText={participantsSuperVoteText}
        hasCompletedLabel="Voted"
        hasntCompletedLabel="Hasn’t Voted"
        participantsCompletedStatus={participantsHaveShared}
      />
      <ActivityActionButton
        text="Review Results"
        onClick={() => {
          dispatch(revealResults(id));
        }}
        className="mb-2 mt-1"
      />
      <FormDialog
        handleClose={() => setIsModalOpen(true)}
        isOpen={isModalOpen}
        title={'Start Super Vote'}
      >
        <SelectSuperVoteParticipantForm
          participantsData={participantsData}
          participants={participants}
          submitSuperVoteParticipantId={submitSuperVoteParticipantId}
        />
      </FormDialog>
    </Box>
  );
};

export default NoteAndMapAdminSuperVote;
