import { filter, map, find } from 'lodash';
import { getFirebase } from 'react-redux-firebase';

export const getCardSets = async () => {
  const firebase = getFirebase();
  const db = firebase.firestore();
  const snapshot = await db.collection('cardSets').get();
  const cardSets = map(snapshot.docs, doc => doc.data());
  return cardSets;
};

/**
 * Get cards sets name
 *
 * @returns {Array.<{name: String, isEdit: Boolean, setId: String, order: Number}>}
 */
export const getFacilitatorCardSets = async facilitatorId => {
  const cardSets = await getCardSets();
  const facilitatorSets = filter(cardSets, { facilitatorId: facilitatorId });
  return map(facilitatorSets, set => ({
    name: set.name,
    isEdit: set.isEdit,
    setId: set.setId,
    order: set.order,
  }));
};

export const getFacilitatorSet = async facilitatorId => {
  const cardSets = await getCardSets();
  return find(cardSets, set => set.facilitatorId === facilitatorId && set.isEdit);
};

export const getSetCards = async setId => {
  const cardSets = await getCardSets();
  const set = find(cardSets, set => set.setId === setId);
  return set?.cards;
};

export const getActiveSet = async setId => {
  const cardSets = await getCardSets();
  return find(cardSets, set => set.setId === setId);
};
