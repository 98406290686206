import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { find, includes } from 'lodash';
import cx from 'classnames';
// library components
import { Box, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
// cr components
import ImagesBox from './ImagesBox';
// selectors
import { useHeatMappingSelector } from 'redux/selectors/heatMappingSelectors';
// actions
import {
  updateParticipantSketchComment,
  updateParticipantSketchVotedFor,
} from 'redux/actions/heatMappingActions';

const useStyles = makeStyles(theme => ({
  commentsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  button: {
    width: 340,
  },
  successColor: {
    color: theme.palette.success.main,
  },
}));

/**
 * Heat-Mapping view for the 'strawPoll' phase on the Participant side
 */
const HeatMappingParticipantSrawPoll = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const participantId = useSelector(state => state.firebase.auth.uid);
  const {
    id,
    participantData: { sketches },
    state,
  } = useHeatMappingSelector();

  const selectedSketch = find(sketches, sketch => includes(sketch.votedFor, participantId));
  const [isVoting, setIsVoting] = useState(!!selectedSketch);
  const [selectedSketchId, setSelectedSketchId] = useState(selectedSketch?.participantId);
  const [comment, setComment] = useState(selectedSketch?.comments?.[participantId]);
  const [isFinishComment, setIsFinishComment] = useState(!!comment && selectedSketchId);

  useEffect(() => {
    const currentSketch = find(sketches, sketch => sketch.participantId === selectedSketchId);
    setComment(currentSketch?.comments?.[participantId]);
  }, [selectedSketchId, participantId, sketches]);

  // Update view if the participant voted for sketch
  const handleVote = (createdBy, isSelected) => {
    const currentSketch = find(sketches, sketch => sketch.participantId === createdBy);
    const prevSketch = find(sketches, sketch => sketch.participantId === selectedSketchId);
    if (isSelected) {
      if (selectedSketchId) {
        dispatch(updateParticipantSketchVotedFor(id, prevSketch.id, false, participantId));
      }
      setSelectedSketchId(createdBy);
      setComment(
        currentSketch?.comments?.[participantId] ? currentSketch?.comments?.[participantId] : '',
      );
      setIsVoting(true);
      dispatch(updateParticipantSketchVotedFor(id, currentSketch.id, true, participantId));
    } else {
      setSelectedSketchId();
      setIsVoting(false);
      dispatch(updateParticipantSketchVotedFor(id, currentSketch.id, false, participantId));
    }
    setIsFinishComment(createdBy && currentSketch?.comments?.[participantId]);
  };

  // Get comment value
  const handleChange = event => {
    setComment(event.target.value);
    const currentSketch = find(sketches, sketch => sketch.participantId === selectedSketchId);
    dispatch(
      updateParticipantSketchComment(id, currentSketch.id, participantId, event.target.value),
    );
    if (!event.target.value) {
      setIsFinishComment(false);
    }
  };

  const handleFinishComment = event => {
    if (event.target.value) {
      setIsFinishComment(true);
    }
  };

  return (
    <>
      <ImagesBox
        isVoting={isVoting}
        handleVote={handleVote}
        context={state.activeView}
        selectedSketchCreatedBy={selectedSketchId}
        handleChangeParticipantComment={handleChange}
        handleFinishParticipantComment={handleFinishComment}
      />
      {selectedSketchId ? (
        <Box className={classes.commentsContainer}>
          {isFinishComment ? (
            <>
              <CheckCircleIcon className={classes.successColor} />
              <Typography className={cx(classes.successColor, 'mb-3')} variant="body2">
                You’ve voted and left a comment.
              </Typography>
            </>
          ) : (
            <>
              <CheckCircleIcon className={classes.successColor} />
              <Typography className={cx(classes.successColor, 'mb-3')} variant="body2">
                You’ve voted, now leave a comment.
              </Typography>
            </>
          )}
        </Box>
      ) : (
        <Typography color="textSecondary" variant="body2">
          Tap one sketch to leave your vote.
        </Typography>
      )}
    </>
  );
};

export default HeatMappingParticipantSrawPoll;
