/**
 * Reusable actions that work across all activities that
 * follow the default schema
 */
import { enqueueErrorSnackbar } from 'redux/actions/notifierActions';
import { includes } from 'lodash';

/**
 * Add add a prticipant to the activity
 * @param {String} participantId
 * @param {String} noteAndVoteId
 */
export const addParticipant =
  (collection, participantId, activityId) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`${collection}/${activityId}`)
      .set(
        {
          participantData: {
            participants: db.FieldValue.arrayUnion(participantId),
          },
        },
        { merge: true },
      );
  };

/**
 * Update the active view for the activity
 * @param {object} CONFIG the activity config
 * @param {String} view the view being changed to
 * @param {String} activityId
 */
export const updateActivityView =
  (CONFIG, activityId, view) =>
  (dispatch, getState, { getFirebase }) => {
    if (!includes(CONFIG.views, view)) {
      console.error(`View '${view}' does not exist in the activity config`);
      return;
    }

    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${activityId}`)
      .set(
        {
          state: {
            activeView: view,
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar(`Error changing to ${view} view`));
      });
  };
