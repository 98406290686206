import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { filter, has, map, size, sortBy, head, forEach } from 'lodash';
// library components
import { Box } from '@material-ui/core';
// cr components
import ActivityParticipantDetails from 'components/ActivityParticipantDetails';
import ActivityPromptAdmin from 'components/ActivityPromptAdmin';
import ActivityActionButton from 'components/CustomButtons/ActivityActionButton';
import Categories from './Categories';
// selectors
import {
  useNoteAndCategorizeSelector,
  getParticipantsCompletedVotes,
  getResults,
} from 'redux/selectors/noteAndCategorizeSelectors';
// actions
import {
  revealResults,
  updateSerialRoundRobinStatus,
  updateParticipantRoundRobinStatus,
} from 'redux/actions/noteAndCategorizeAction';
// config
import { NOTE_AND_CATEGORIZE_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';
// utils
import { makeParticipantDetailsHeaderText } from 'utils/makeParticipantDetailsHeaderText';

const useStyles = makeStyles(theme => ({
  promptAdmin: {
    marginBottom: theme.spacing(1),
  },
}));

/**
 * Note & Categorize view for the 'vote' phase on the Facilitator side
 */
const NoteAndCategorizeAdminVote = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const noteAndCategorize = useNoteAndCategorizeSelector();
  const {
    id,
    prompt,
    state: { activeView, serialRoundRobin, isCategorized },
    config: { categories, format, roundRobinShare, votesPerCategory },
    participantData: { participantsCompletedRoundRobin },
  } = noteAndCategorize;
  const results = useSelector(getResults());
  let sortField = 'value';

  const actionButtonText =
    roundRobinShare === 'on' && format === 'Serial' && size(isCategorized) < size(categories)
      ? 'Next Category'
      : 'Review Final Results';

  const sortedCategories = sortBy(
    map(categories, category => {
      sortField = has(category, 'order') ? 'order' : 'value';
      return category;
    }),
    sortField,
  );

  const participantsCompletedVotesData = useSelector(getParticipantsCompletedVotes());

  const participantsVotedCount = filter(participantsCompletedVotesData, { completed: true }).length;
  const participantDetailsHeaderText = makeParticipantDetailsHeaderText(
    participantsVotedCount,
    participantsCompletedVotesData.length,
    'voted',
  );

  const onRevealResults = () => {
    // create result and switch view
    if (roundRobinShare === 'on' && format === 'Serial' && size(isCategorized) < size(categories)) {
      // if roundRobin on and serial format
      // find next category id
      const nextCategory = head(
        filter(sortedCategories, category => category.id !== serialRoundRobin),
      );
      dispatch(updateSerialRoundRobinStatus(id, nextCategory.id, 'categorize'));
      // remove all participants from completedRoundRobin for next category
      forEach(participantsCompletedRoundRobin, participantId => {
        dispatch(updateParticipantRoundRobinStatus(id, participantId, true));
      });
    } else {
      dispatch(revealResults(id, 'results', true));
    }
  };

  return (
    <Box className="d-flex flex-column justify-content-center">
      <ActivityPromptAdmin
        prompt={prompt}
        collection={CONFIG.collection}
        activityId={id}
        className={classes.promptAdmin}
      />
      <Categories categories={sortedCategories} output={results} view={activeView} />
      <ActivityParticipantDetails
        headerText={participantDetailsHeaderText}
        hasCompletedLabel="Voted"
        hasntCompletedLabel="Hasn't Voted"
        completedCountMax={
          roundRobinShare === 'on' && format === 'Serial'
            ? votesPerCategory
            : votesPerCategory * size(categories)
        }
        participantsCompletedStatus={participantsCompletedVotesData}
      />
      <ActivityActionButton
        text={actionButtonText}
        onClick={onRevealResults}
        className="mb-2"
        delayReady
      />
    </Box>
  );
};

export default NoteAndCategorizeAdminVote;
