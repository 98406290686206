import React from 'react';
import { useSelector } from 'react-redux';
// cr components
import NotesReview from 'components/NoteAndVote/Participant/NotesReview';
// selectors
import { getSubmittedNotes, useNoteAndVoteSelector } from 'redux/selectors/noteAndVoteSelectors';

/**
 * Note & Vote view for the 'review' phase on the Participant side
 */
const NoteAndVoteParticipantReview = () => {
  const { id } = useNoteAndVoteSelector();
  const submittedNotes = useSelector(getSubmittedNotes());

  return <NotesReview notes={submittedNotes} noteAndVoteId={id} />;
};

export default NoteAndVoteParticipantReview;
