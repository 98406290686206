import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

// @material-ui/core components
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';

// material-ui icons
import Menu from '@material-ui/icons/Menu';

// core components
import AdminNavbarLinks from './AdminNavbarLinks';
import Button from 'components/CustomButtons/Button.js';
import CrLogo from 'components/CrLogo/CrLogo';

import styles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarStyle.js';

const useStyles = makeStyles(styles);

export default function AdminNavbar(props) {
  const classes = useStyles();
  const { color, rtlActive } = props;
  const appBarClasses = cx({
    [' ' + classes[color]]: color,
  });

  // const sidebarMinimize =
  //   classes.sidebarMinimize +
  //   " " +
  //   cx({
  //     [classes.sidebarMinimizeRTL]: rtlActive
  //   });

  return (
    <AppBar
      className={classes.appBar + appBarClasses}
      style={{ background: 'white' }}
      elevation={1}
    >
      <Toolbar className={classes.container}>
        <div className={classes.flex}>
          <CrLogo />
        </div>
        <Hidden smDown implementation="css">
          <AdminNavbarLinks rtlActive={rtlActive} />
        </Hidden>
        <Hidden mdUp implementation="css">
          <Button
            className={classes.appResponsive}
            color="transparent"
            justIcon
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <Menu />
          </Button>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

AdminNavbar.propTypes = {
  color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger']),
  rtlActive: PropTypes.bool,
  brandText: PropTypes.string,
  miniActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  sidebarMinimize: PropTypes.func,
};
