import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/functions';
import { store } from '../index.js';
import * as Sentry from '@sentry/react';
// actions
import { enqueueSuccessSnackbar, enqueueErrorSnackbar } from 'redux/actions/notifierActions';
// constants
import { ACTIVITIES } from 'constants/activities';

/**
 * Copies the results output from an activity into a Live Scribing activity
 *
 * @param {String} liveScibingActivity the Live Scribing activity being copied to as stored
 *                                      in the activites array of the meeting
 * @param {String} userId id of the user taking the action
 * @param {String} output the results output from the activity being copied from
 */
export const sendResultsToLiveScribing = async (liveScribingActivity, userId, output) => {
  const dispatch = store.dispatch;
  const db = firebase.firestore();

  let liveScribingText = '';

  const liveScribingRef = db.collection('liveScribing').doc(`${liveScribingActivity.id}`);
  const liveScribingDoc = await liveScribingRef.get();

  if (!liveScribingDoc.exists) {
    console.error('No such LiveScribing document!');
  } else {
    const data = liveScribingDoc.data();
    liveScribingText = data.state?.liveScribingText || '';
  }

  return db
    .doc(`${ACTIVITIES['liveScribing'].collection}/${liveScribingActivity.id}`)
    .set(
      {
        state: {
          liveScribingText: `${liveScribingText}${output}`,
          editUserId: userId,
        },
      },
      { merge: true },
    )
    .then(() => {
      dispatch(enqueueSuccessSnackbar(`Results copied to ${liveScribingActivity.name}`));
    })
    .catch(err => {
      Sentry.captureException(err);
      console.error(err);
      dispatch(enqueueErrorSnackbar(`Error copying results to ${liveScribingActivity.name}`));
    });
};
