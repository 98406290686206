import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { Typography, Tooltip } from '@material-ui/core';
import { includes } from 'lodash';

import './projectStatusView.scss';
import { PROJECT_ERROR_STATUS_STATES } from 'constants/staffing';
import { getProjectStatusStyles } from 'utils/staffing/status';

const ProjectStatusErrorWrapper = props => {
  if (props.statusError && includes(Object.values(PROJECT_ERROR_STATUS_STATES), props.status)) {
    return (
      <Tooltip arrow title={props.statusError}>
        {props.children}
      </Tooltip>
    );
  }
  return <React.Fragment>{props.children}</React.Fragment>;
};

const ProjectStatusView = props => {
  const { status, statusError } = props;

  return (
    <div className="d-flex align-items-center flex-wrap project-status-pill">
      <ProjectStatusErrorWrapper statusError={statusError} status={status}>
        <div
          className={cx(
            'mr-1',
            'project-card-body-status',
            getProjectStatusStyles(status).containerClassname,
          )}
        >
          <Typography className={cx('project-card-body-status-text white-space-no-wrap')}>
            {getProjectStatusStyles(status).title}
          </Typography>
        </div>
      </ProjectStatusErrorWrapper>
    </div>
  );
};

ProjectStatusView.propTypes = {
  status: PropTypes.string,
};

export default ProjectStatusView;
