import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import { useTheme } from '@material-ui/core/styles';
import { includes, head, find } from 'lodash';
import cx from 'classnames';
// library components
import { Card, CardContent, CardActions, Typography, Box } from '@material-ui/core';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
// cr components
import NoteForm from './NoteForm';
import DottedProgress from 'components/DottedProgress';
// actions
import { shareNote, voteForNote, swapSharedNote } from 'redux/actions/noteAndVoteActions';
// selectors
import {
  useNoteAndVoteSelector,
  getParticipantSharedNotesCount,
  getParticipantVotes,
} from 'redux/selectors/noteAndVoteSelectors';
import EditCardButton from 'components/EditCardButton/EditCardButton';

const useStyles = makeStyles(theme => ({
  card: {
    width: '100%',
    flex: '1',
    padding: '20px 20px 0',
    border: `1px solid ${grey[300]}`,
    boxShadow: '0px 8px 18px rgba(0, 0, 0, 0.12)',
    cursor: ({ context }) =>
      context === 'vote' || context === 'share' || context === 'review' ? 'pointer' : 'default',
    backgroundColor: ({ isDraggingOver }) => isDraggingOver && '#F4F4F4',
  },
  cardVoted: {
    padding: '18px 18px 0',
    border: `3px solid ${theme.palette.indigo.main}`,
    backgroundColor: theme.palette.indigo.lightest,
  },
  cardTxt: {
    width: '100%',
    color: theme.palette.text.primary,
    whiteSpace: 'pre-line',
    overflowX: 'hidden',
    overflowWrap: 'break-word',
  },
  editBtn: {
    color: theme.palette.primary.main,
  },
  winnerText: {
    color: theme.palette.indigo.main,
  },
  combineBox: {
    position: 'relative',
    top: -10,
    left: 10,
    display: 'flex',
    marginLeft: 'auto',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  combineTitle: {
    marginLeft: theme.spacing(1),
    marginRight: 4,
    fontSize: 12,
  },
  filterIcon: {
    color: '#5E5E5E',
    fontSize: 14,
  },
  actionContainer: {
    position: 'relative',
    padding: '10px 0',
  },
}));

const NoteCard = ({
  note,
  context,
  handleOpenDuplicateCards,
  numberCombineNotes,
  className,
  isStackCard,
  isDraggingOver,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const theme = useTheme();
  const dispatch = useDispatch();
  const participantId = useSelector(state => state.firebase.auth.uid);
  const {
    id,
    config: { shareLimit, voteLimit },
    participantData: { notes },
  } = useNoteAndVoteSelector();
  const participantSharedNotesCount = useSelector(getParticipantSharedNotesCount(participantId));
  const participantVotes = useSelector(getParticipantVotes(participantId));
  const votedFor = includes(participantVotes, note.noteId);
  const disabled =
    (context === 'share' && !note.shared && shareLimit === participantSharedNotesCount) ||
    (context === 'vote' && !votedFor && voteLimit === participantVotes.length);
  const classes = useStyles({ disabled, context, isDraggingOver });

  const onCancelEditing = () => {
    setIsEditing(false);
  };

  const onEdit = () => {
    if (context === 'write') {
      setIsEditing(true);
    }
  };

  const onShare = () => {
    const sharedNote = find(notes, note => note.shared && participantId === note.participantId);

    if (shareLimit === 1 && !note.shared && sharedNote) {
      setIsUpdating(true);

      dispatch(
        swapSharedNote(note.noteId, sharedNote.noteId, id, () => {
          setIsUpdating(true);
        }),
      );

      return;
    }

    if (disabled && !note.shared) {
      return;
    } else {
      setIsUpdating(true);
      dispatch(
        shareNote(!note.shared, note.noteId, id, () => {
          setIsUpdating(false);
        }),
      );
    }
  };

  const onVote = () => {
    if (voteLimit === 1 && !votedFor) {
      setIsUpdating(true);
      dispatch(
        voteForNote(false, head(participantVotes), participantId, id, () => {
          setIsUpdating(true);
        }),
      );
      dispatch(
        voteForNote(true, note.noteId, participantId, id, () => {
          setIsUpdating(false);
        }),
      );
    }
    if (disabled && !votedFor) {
      return;
    } else {
      setIsUpdating(true);
      dispatch(
        voteForNote(!votedFor, note.noteId, participantId, id, () => {
          setIsUpdating(false);
        }),
      );
    }
  };

  const onOpenDuplicateCards = () => {
    if (context === 'review') {
      handleOpenDuplicateCards(note.noteId);
    }
  };

  const handleClick = () => {
    switch (context) {
      case 'share':
        return onShare();
      case 'vote':
        return onVote();
    }
  };

  return (
    <Card
      className={cx('d-flex flex-column justify-content-between', classes.card, {
        [classes.cardVoted]:
          (context === 'share' && note.shared) ||
          (context === 'vote' && votedFor) ||
          (context === 'results' && note.votes > 0),
      })}
      onClick={handleClick}
    >
      <CardContent className="w-100 d-flex flex-1 flex-column p-0" onClick={onEdit}>
        {isEditing ? (
          <NoteForm cancelEdit={onCancelEditing} type="edit" note={note} />
        ) : (
          <Typography className={cx(classes.cardTxt, className)}>
            {note.note || note.text}
          </Typography>
        )}
        {(!!numberCombineNotes || isStackCard) && (
          <Box className={classes.combineBox} onClick={onOpenDuplicateCards}>
            <Typography className={cx(classes.combineTitle, className)}>
              {numberCombineNotes ? numberCombineNotes + 1 : ''}
            </Typography>
            <FilterNoneIcon className={cx(classes.filterIcon, className)} />
          </Box>
        )}
      </CardContent>
      {!isEditing && (
        <CardActions className={classes.actionContainer}>
          <EditCardButton isVisible={context === 'write'} onEdit={onEdit} />
          {context === 'results' && (
            <>
              <DottedProgress
                activeColor={theme.palette.indigo.main}
                totalCount={note.votes}
                activeCount={note.votes}
                className="mb-0"
              />
            </>
          )}
        </CardActions>
      )}
    </Card>
  );
};

NoteCard.propTypes = {
  note: PropTypes.object.isRequired,
  context: PropTypes.oneOf(['write', 'share', 'review', 'vote', 'results']),
  handleOpenDuplicateCards: PropTypes.func, // function for open and close card stack
  numberCombineNotes: PropTypes.number, // number of stack cards
  className: PropTypes.string, // styles for disable card
  isStackCard: PropTypes.bool, // true - card from stack, false - top card
  isDraggingOver: PropTypes.bool, // dragged card over current card
};

export default NoteCard;
