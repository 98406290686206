import React from 'react';
import { makeStyles } from '@material-ui/core';
// library components
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  title: {
    marginTop: theme.spacing(10),
    fontSize: 18,
    fontWeight: 300,
  },
}));

/**
 * Lightning Demo view for the 'results' phase on the Facilitator side
 */
const LightningDemoAdminResults = () => {
  const classes = useStyles();
  return (
    <Typography className={classes.title} align="center">
      Lightning Demo Completed
    </Typography>
  );
};

export default LightningDemoAdminResults;
