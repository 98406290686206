import { createSelector } from 'reselect';
import moment from 'moment';
import { generateParticipantURL } from 'utils/generateParticipantURL';
import { filter, maxBy, map, size, reduce, split, head, includes, uniq } from 'lodash';

const sessionSelector = (state, id, sessionsType) => state.firestore.data[sessionsType]?.[id];

const participantsSelector = state => state.participants;

/**
 * Since this selector is being used for multiple components in the
 * same view, it won't memoize correctly unless we create a new
 * copy of the selector each time it is called.
 *
 * https://redux.js.org/recipes/computing-derived-data#sharing-selectors-across-multiple-components
 */
export const makeGetSession = () => {
  return createSelector([sessionSelector, participantsSelector], (session, participants) => {
    if (!session) return null;

    const {
      startTime,
      numParticipants,
      feedback,
      slidesSessionIds,
      activeRoute,
      activeFacilitatorRoute,
      recapDeck,
      id,
    } = session;

    const sessionDate = moment(startTime).format('M/D');

    const sessionDateWithYear = moment(startTime).format('M/D/YY');

    const notEmptyFeedback = filter(feedback, 'sentiment');

    const percentScored =
      numParticipants > 0 ? Math.floor((size(notEmptyFeedback) / numParticipants) * 100) : 0;

    // const feedbackCount = !!feedback ? feedback.length : 0;
    const feedbackCount = !!notEmptyFeedback ? size(notEmptyFeedback) : 0;

    const filteredFeedback = {
      satisfied: filter(notEmptyFeedback, fb => fb.sentiment === 'satisfied'),
      neutral: filter(notEmptyFeedback, fb => fb.sentiment === 'neutral'),
      dissatisfied: filter(notEmptyFeedback, fb => fb.sentiment === 'dissatisfied'),
    };

    if (!!notEmptyFeedback) {
      filteredFeedback.satisfied.percentage = Math.floor(
        (filteredFeedback.satisfied.length / size(notEmptyFeedback)) * 100,
      );
      filteredFeedback.neutral.percentage = Math.floor(
        (filteredFeedback.neutral.length / size(notEmptyFeedback)) * 100,
      );
      filteredFeedback.dissatisfied.percentage = Math.floor(
        (filteredFeedback.dissatisfied.length / size(notEmptyFeedback)) * 100,
      );
    }

    const filteredAnswersByQuestion = {
      liked: map(notEmptyFeedback, fb => fb.liked),
      didntLike: map(notEmptyFeedback, fb => fb.didntLike),
      opportunity: map(notEmptyFeedback, fb => fb.opportunity),
      outcome: map(notEmptyFeedback, fb => fb.outcome),
    };

    const sessionSentiment = maxBy(
      [
        { type: 'satisfied', count: filteredFeedback['satisfied'].length },
        { type: 'neutral', count: filteredFeedback['neutral'].length },
        { type: 'dissatisfied', count: filteredFeedback['dissatisfied'].length },
      ],
      'count',
    ).type;

    const totalSentimentCount = reduce(filteredFeedback, (t, n) => t + n.length, 0);

    const numActiveParticipants = filter(participants, { active: true }).length;

    const activitiesList = uniq(
      map(session.activities, activity => {
        if (includes(activity.name, '25:10')) {
          return '25:10';
        }
        return head(split(activity.name, ':'));
      }),
    );

    return {
      ...session,
      sessionDate,
      percentScored,
      feedbackCount,
      filteredFeedback,
      totalSentimentCount,
      sentiment: totalSentimentCount ? sessionSentiment : 'empty',
      topic: session.topic,
      agenda: session.agenda,
      sessionDateWithYear,
      feedback,
      filteredAnswersByQuestion,
      slidesSessionIds: slidesSessionIds || [],
      activeRoute: activeRoute || 'slides',
      activeFacilitatorRoute: activeFacilitatorRoute || 'slides',
      broadcast: session.broadcast || [],
      highlight: session.highlight || '',
      numParticipants: session.numParticipants || 0,
      numActiveParticipants: numActiveParticipants || 0,
      activitiesList: activitiesList,
      activities: session.activities,
      recapDeck,
      participantLink: generateParticipantURL(id, session.topic),
    };
  });
};
