import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';

import {
  Button,
  IconButton,
  CircularProgress,
  ButtonBase,
  FormControl,
  Switch,
  FormControlLabel,
  Typography,
} from '@material-ui/core';
import NextIcon from '@material-ui/icons/SkipNext';
import PreviousIcon from '@material-ui/icons/SkipPrevious';
import DeleteIcon from '@material-ui/icons/Delete';

import SlideSelect from './SlideSelect';
import CountUpTimer from 'components/CountUpTimer';
import GrayBox from 'components/GrayBox';

import {
  deleteSession,
  setSessionSlideNumber,
  setParticipantsControlSlides,
  resetSlideTimer,
} from 'redux/actions/slidesSessionActions';

import './slidesSessionAdmin.scss';

const SlidesSessionAdmin = ({ slidesSessionId, isViewingShared }) => {
  const [deleting, setDeleting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const slidesSession = useSelector(state => state.firestore.data.slidesSession);
  const participantsControlSlides = slidesSession?.participantsControlSlides ?? false;

  useFirestoreConnect(() => [
    {
      collection: 'remoteSlidesSessions',
      doc: `${slidesSessionId}`,
      storeAs: 'slidesSession',
    },
  ]);

  const onDelete = () => {
    setDeleting(true);
    dispatch(deleteSession(slidesSessionId, setDeleting, enqueueSnackbar));
  };

  const onNext = () => {
    dispatch(
      setSessionSlideNumber(slidesSessionId, slidesSession.currentSlide + 1, enqueueSnackbar),
    );
  };

  const onPrevious = () => {
    dispatch(
      setSessionSlideNumber(slidesSessionId, slidesSession.currentSlide - 1, enqueueSnackbar),
    );
  };

  const onHandleChangeSlidesControl = e => {
    dispatch(setParticipantsControlSlides(slidesSessionId, e.target.checked));
  };

  const onResetTimer = () => {
    dispatch(resetSlideTimer(slidesSessionId, enqueueSnackbar));
  };

  if (!slidesSession) return null;

  return (
    <div style={{ maxWidth: '100%' }}>
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex ">
          <div className="">
            <IconButton onClick={onPrevious}>
              <PreviousIcon />
            </IconButton>
          </div>
          <div className="mx-2 d-flex align-items-center">
            {slidesSession.currentSlide + 1} / {slidesSession.imageUrls.length}
          </div>
          <div className="d-flex align-items-center">
            <IconButton onClick={onNext}>
              <NextIcon />
            </IconButton>
          </div>
        </div>
        <div className="d-flex">
          <SlideSelect slidesSession={slidesSession} slidesSessionId={slidesSessionId} />
          {deleting ? (
            <CircularProgress size={25} />
          ) : (
            !isViewingShared && (
              <Fragment>
                <Button
                  variant="text"
                  color="primary"
                  onClick={onDelete}
                  className="align-self-center"
                >
                  <DeleteIcon className="mr-1" /> Delete
                </Button>
              </Fragment>
            )
          )}
        </div>
      </div>
      <div className="mb-2 ml-2">
        <FormControl>
          <FormControlLabel
            control={
              <Switch
                checked={participantsControlSlides}
                onChange={onHandleChangeSlidesControl}
                name="participantsControlSlides"
                color="primary"
              />
            }
            label="allow participants to control their slides view"
          />
        </FormControl>
      </div>
      <div className="mb-1">
        <a
          href={`/slides/${slidesSessionId}`}
          target="_blank"
          rel="noopener noreferrer"
          title="watch slides in new tab"
        >
          <img
            src={slidesSession.imageUrls[slidesSession.currentSlide]}
            className="img-fluid border"
          />
        </a>
      </div>
      <div className="d-flex justify-content-end align-items-center">
        <Typography className="fw-500">Time since last slide change:&nbsp;</Typography>
        <CountUpTimer
          startTime={slidesSession.lastSlideChange || new Date()}
          onResetTimer={onResetTimer}
        />
      </div>
      <div className="d-flex justify-content-between mt-3" style={{ maxWidth: '100%' }}>
        <div style={{ maxWidth: 200 }} className="mr-1">
          {slidesSession.currentSlide > 0 && <span className="d-block">Prev:</span>}
          <ButtonBase>
            <img
              src={slidesSession.imageUrls[slidesSession.currentSlide - 1]}
              className="img-fluid hover-pointer border"
              onClick={onPrevious}
            />
          </ButtonBase>
        </div>
        <div style={{ maxWidth: 200 }}>
          {slidesSession.currentSlide < slidesSession.imageUrls.length - 1 && (
            <span className="d-block">Next:</span>
          )}
          <ButtonBase>
            <img
              src={slidesSession.imageUrls[slidesSession.currentSlide + 1]}
              className="img-fluid hover-pointer border"
              onClick={onNext}
            />
          </ButtonBase>
        </div>
      </div>
    </div>
  );
};

SlidesSessionAdmin.propTypes = {
  slidesSessionId: PropTypes.string.isRequired,
  isViewingShared: PropTypes.bool, // is viewing as co-facilitator
};

export default SlidesSessionAdmin;
