import React from 'react';
import PropTypes from 'prop-types';
// library components
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CopyIcon from '@material-ui/icons/FileCopyOutlined';

const useStyles = makeStyles(theme => ({
  newSetButton: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.main,
    marginBottom: 28,
    textTransform: 'none',
    fontWeight: 400,
    '&:hover': {
      background: 'unset',
    },
  },
  icon: {
    fontSize: 16,
    marginBottom: 5,
  },
  buttonText: {
    fontSize: 15,
    marginLeft: 5,
  },
}));

const NewSetButton = ({ handleClick }) => {
  const classes = useStyles();
  return (
    <Button
      aria-label="newSet"
      className={classes.newSetButton}
      onClick={handleClick}
      startIcon={<CopyIcon className={classes.icon} />}
    >
      <span className={classes.buttonText}>New Set</span>
    </Button>
  );
};

NewSetButton.propTypes = {
  handleClick: PropTypes.func.isRequired, // the function processes the "New Set" button and create new set
};

export default NewSetButton;
