import axios from 'axios';

const apiClient = (idToken, contentType = 'application/json') => {
  let options = {
    baseURL: process.env.REACT_APP_API_URL,
    headers: {},
  };

  if (contentType !== 'multipart/form-data') {
    options.headers['Content-Type'] = contentType;
  }

  console.log('idToken', idToken);

  if (idToken) {
    options.headers.Authorization = `Bearer ${idToken}`;
  }

  return axios.create(options);
};

export default apiClient;
