import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { filter, size } from 'lodash';
// library components
import { Box, Typography } from '@material-ui/core';
// cr components
import ActivityParticipantDetails from 'components/ActivityParticipantDetails';
import ActivityPromptAdmin from 'components/ActivityPromptAdmin';
import ActivityActionButton from 'components/CustomButtons/ActivityActionButton';
// selectors
import {
  useAssessmentSelector,
  getFacilitatorPromptSubText,
  getSortedQuestions,
  getParticipantsHaveAnswered,
} from 'redux/selectors/assessmentSelectors';
// actions
import { endAssessment, goToNextQuestion, goToPrevQuestion } from 'redux/actions/assessmentActions';
// config
import { ASSESSMENT_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';
// utils
import { makeParticipantDetailsHeaderText } from 'utils/makeParticipantDetailsHeaderText';
import GrayBox from 'components/GrayBox';

/**
 * Assessment view for the 'write' phase on the Facilitator side
 */
const AssessmentAdminWrite = () => {
  const dispatch = useDispatch();
  const {
    id,
    config: { reviewResults },
    participantData: { participants },
    state: { currentQuestionIdx },
    prompt,
  } = useAssessmentSelector();
  const orderedQuestions = useSelector(getSortedQuestions());
  const promptText = useSelector(getFacilitatorPromptSubText());
  const participantsHaveAnswered = useSelector(
    getParticipantsHaveAnswered(reviewResults, orderedQuestions[currentQuestionIdx].id),
  );

  const headerText = makeParticipantDetailsHeaderText(
    filter(participantsHaveAnswered, pt => pt.completed).length,
    participants.length,
    'answered',
  );

  const onPrevQuestion = () => dispatch(goToPrevQuestion(id));

  const onNextQuestion = () => dispatch(goToNextQuestion(id));

  const onEndActivity = () => dispatch(endAssessment(id));

  return (
    <Box className="d-flex flex-column justify-content-center">
      <ActivityPromptAdmin
        prompt={prompt}
        collection={CONFIG.collection}
        activityId={id}
        className="mb-1"
      />
      <GrayBox className="mb-5 p-2">
        <Typography
          className="d-flex flex-column align-items-start justify-content-start"
          dangerouslySetInnerHTML={{ __html: promptText }}
        />
      </GrayBox>
      <ActivityParticipantDetails
        headerText={headerText}
        hasCompletedLabel="Answered"
        hasntCompletedLabel="Haven't Answered"
        completedCountMax={size(orderedQuestions)}
        participantsCompletedStatus={participantsHaveAnswered}
      />
      <Box display="flex" justifyContent="center" flexWrap="wrap">
        {reviewResults ? (
          <>
            <ActivityActionButton
              text="PREV QUESTION"
              onClick={onPrevQuestion}
              className="mx-1 mb-2"
              disabled={Number(currentQuestionIdx) === 0}
            />
            {Number(currentQuestionIdx !== orderedQuestions.length - 1) ? (
              <ActivityActionButton
                text="NEXT QUESTION"
                onClick={onNextQuestion}
                className="mx-1 mb-2"
              />
            ) : (
              <ActivityActionButton
                text="FINALIZE RESULTS"
                onClick={onEndActivity}
                className="mx-1 mb-2"
              />
            )}
          </>
        ) : (
          <ActivityActionButton text="FINALIZE RESULTS" onClick={onEndActivity} className="mb-2" />
        )}
      </Box>
    </Box>
  );
};

export default AssessmentAdminWrite;
