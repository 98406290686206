import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFirestore } from 'react-redux-firebase';
import { useSelector, useDispatch } from 'react-redux';
import { map, filter, each, includes, find, union, keys, isEmpty, orderBy } from 'lodash';
import { useHistory } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import cx from 'classnames';
import * as Yup from 'yup';
// 3rd party components
import { Formik, Form, useFormikContext } from 'formik';
import { Button, Typography } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// CR components
import ButtonSpinner from 'components/ButtonSpinner/ButtonSpinner';
import TextInput from 'components/CustomFormComponents/TextInput';
import CustomSelect from 'components/CustomFormComponents/CustomSelect';
import AutoField from 'components/CustomFormComponents/AutoField';
import FieldsGroup from 'components/CustomFormComponents/FieldsGroup';
// actions
import { setSelectedFacilitatorRoute } from 'redux/actions/facilitatorSessionActions';
import { enqueueSuccessSnackbar, enqueueErrorSnackbar } from 'redux/actions/notifierActions';
import { openPremiumSubscriptionDialog } from 'redux/actions/facilitatorSessionActions';
// selectors
import { openMuralAuthDialog } from 'redux/actions/facilitatorSessionActions';
import {
  facilitatorSubscriptionSelector,
  useFacilitatorSessionSelector,
} from 'redux/selectors/facilitatorSessionSelectors';
// utils
import { generateRandomId } from '../../utils/generateRandomId';
import { getFacilitatorSessionType } from 'utils/getFacilitatorSessionType';
import { uploadImage } from 'utils/uploadImage';
// constants
import { ACTIVITIES, ACTIVITY_SCHEMA, ACTIVITY_DEFAULT_FIELDS } from 'constants/activities';

const activityConfigs = {};

each(ACTIVITIES, activityDef => {
  if (activityDef.config) {
    if (activityDef?.derivative) {
      // if activity name differ from collection name
      activityConfigs[activityDef.derivative] = activityDef.config;
    } else {
      // if activity name matches collection name
      activityConfigs[activityDef.config.collection] = activityDef.config;
    }
  }
});

each(activityConfigs, (activity, key) => {
  if (key === 'brainwriting') return; // LEGACY - Do not follow this pattern

  activityConfigs[key].fields = union(ACTIVITY_DEFAULT_FIELDS, activityConfigs[key].fields);
});

const renderDefaultValuesForLegacy = (activityType, valueType) => {
  switch (activityType) {
    case '25:10':
      return valueType === 'name' ? '25:10' : 'What would you do if you were 10 times bolder?';
    case 'breakouts':
      return 'Breakouts';
    default:
      return '';
  }
};

/**
 * Add an activity to the current session
 */
const AddActivityForm = ({
  closeDialog,
  isActivityTemplate,
  isAddOneActivity,
  isLastActivityInTemplate,
  activityTemplateActivity,
  activityTemplateInitalValues,
  activityStateInitalValues,
  sessionId,
  activityIdx,
  setActivityIdx,
  resetCreateSessionDialogState,
}) => {
  const defaultYupFields = {
    name: Yup.string().max(70, 'Must be less than 70 characters').required('Required'),
    activity: Yup.string().required('Required'),
  };

  const defaultInitialValues = {
    activity: isActivityTemplate ? activityTemplateActivity : '',
    name: '',
  };

  const [selectedActivity, setSelectedActivity] = useState(
    isActivityTemplate || isAddOneActivity ? activityTemplateActivity : '',
  );
  const [formState, setFormState] = useState({
    yupFields: defaultYupFields,
    initialValues: defaultInitialValues,
    activityFields: [],
  });
  // const [yupFields, setYupFields] = useState(defaultYupFields);
  // const [initialValues, setInitialValues] = useState(defaultInitialValues);
  // const [activityFields, setActivityFields] = useState([]);
  const dispatch = useDispatch();
  const db = useFirestore();
  let session = useSelector(state => state.firestore.data.session) || {};
  const user = useSelector(state => state.firebase.profile);
  const { isSubscriber, isInternalUser } = useSelector(facilitatorSubscriptionSelector());
  const { isOpenCreateSessionDialog } = useFacilitatorSessionSelector();
  const history = useHistory();
  const smallScreen = useMediaQuery('(max-width: 500px)');

  const theSessionId = sessionId ?? session.id;

  const cancelActivityTemplate = () => {
    db.doc(`/sessions/${theSessionId}`).delete();
  };

  /**
   * Validate the supplied activity schema against the standard
   * activity schema
   */
  const validateActivityConfig = selectedActivity => {
    const schemaCheck = Yup.object().shape(ACTIVITY_SCHEMA);

    if (activityConfigs[selectedActivity]?.fields) {
      const activitySchema = {};

      each(activityConfigs[selectedActivity].fields, field => {
        if (field.key) {
          activitySchema[field.key] = field.defaultValue;
        }
      });

      try {
        schemaCheck.validateSync(activitySchema, { strict: true });
      } catch (error) {
        throw `Activity Config Error: ${selectedActivity} - ${error.message}. If the activity config has this field, did you forget to set a default value?`;
      }
    }
  };

  /**
   * When the activity selected to be added changes, dynamically
   * add the validators and default values for that activity as setup
   * in the activity's config object
   */
  useEffect(() => {
    let filteredFields;
    let validators = {};
    let theInitialValues = {};
    let defaultValueFields;

    const isPremiumActivity = ACTIVITIES[selectedActivity]?.isPremium;

    if (isPremiumActivity && !isSubscriber) {
      dispatch(openPremiumSubscriptionDialog(true));
      closeDialog();
      return;
    }

    if (
      !isOpenCreateSessionDialog &&
      !user.isMuralAuthorized &&
      ACTIVITIES[selectedActivity]?.collection === 'mural'
    ) {
      dispatch(openMuralAuthDialog(true));
      closeDialog();
      return;
    }

    // console.log('selectedActivity', selectedActivity);
    // console.log('activityConfigs', activityConfigs);
    // console.log('fields', activityConfigs[selectedActivity]?.fields);

    if (selectedActivity) {
      defaultValueFields = filter(activityConfigs[selectedActivity]?.fields, field => {
        return field.isDefaultValueField;
      });
    }

    // is not a legacy activity
    if (selectedActivity && !ACTIVITIES[selectedActivity].isLegacy) {
      // validate the supplied activity config for the selected activity
      if (selectedActivity !== 'brainwriting') {
        // skip brainwriting for now
        validateActivityConfig(selectedActivity);
      }

      // get the top level setup fields from the activity config object
      filteredFields = filter(activityConfigs[selectedActivity].fields, field => {
        return field.isSetupField;
      });

      // get the setup fields nested in the 'config' key in the activity config object
      if (filter(activityConfigs[selectedActivity]?.fields, { key: 'config' }).length) {
        const config = find(activityConfigs[selectedActivity].fields, { key: 'config' });

        each(config.fields, field => {
          if (field.isSetupField) {
            filteredFields.push(field);
          }
        });
      }

      each(filteredFields, field => {
        validators[field.key] = field.validator;
      });

      each(filteredFields, field => {
        theInitialValues[field.key] = field.defaultValue;
        const defaultValueField = find(defaultValueFields, value => value.key === field.key);
        if (defaultValueField) {
          theInitialValues[field.key] = defaultValueField.defaultValue;
        }
      });

      theInitialValues.activity = selectedActivity;
      theInitialValues.collection = ACTIVITIES[selectedActivity]?.collection;

      setFormState({
        ...formState,
        yupFields: {
          ...validators,
          ...defaultYupFields,
        },
        initialValues: {
          ...defaultInitialValues,
          ...theInitialValues,
          ...activityTemplateInitalValues,
        },
        activityFields: setupFields(selectedActivity),
      });

      // setYupFields({
      //   ...validators,
      //   ...defaultYupFields
      // });

      // setInitialValues({
      //   ...defaultInitialValues,
      //   ...theInitialValues
      // });

      // // setup the fields AFTER the validation and default values are in place
      // setupFields(selectedActivity);
    } else if (selectedActivity && ACTIVITIES[selectedActivity].isLegacy) {
      // set defaults for legacy activities
      // setYupFields(defaultYupFields);
      // setInitialValues(defaultInitialValues);
      // setupLegacyFields(selectedActivity);

      theInitialValues.activity = selectedActivity;
      theInitialValues.collection = ACTIVITIES[selectedActivity]?.collection;

      if (includes(['25:10', 'breakouts'], selectedActivity)) {
        theInitialValues.name = renderDefaultValuesForLegacy(selectedActivity, 'name');
        theInitialValues.topic = renderDefaultValuesForLegacy(selectedActivity);
      }

      filteredFields = filter(activityConfigs[selectedActivity]?.fields, field => {
        return field.isSetupField;
      });

      if (!isEmpty(filteredFields)) {
        each(filteredFields, field => {
          theInitialValues[field.key] = field.defaultValue;
          const defaultValueField = find(defaultValueFields, value => value.key === field.key);
          if (defaultValueField) {
            theInitialValues[field.key] = defaultValueField.defaultValue;
          }
        });
      }

      setFormState({
        ...formState,
        yupFields: defaultYupFields,
        initialValues: {
          ...defaultInitialValues,
          ...theInitialValues,
          ...activityTemplateInitalValues,
        },
        activityFields: setupLegacyFields(selectedActivity),
      });
    }
  }, [selectedActivity, isSubscriber]);

  /**
   * Return select options based on the ACTIVITIES
   */
  const getSelectOptions = () => {
    const optionalActivities = filter(ACTIVITIES, { type: 'optional' });
    const orderedActivities = orderBy(optionalActivities, 'niceName');
    const allowedActivities = filter(
      orderedActivities,
      activity => !activity.isInternal || (activity.isInternal && isInternalUser),
    );

    return map(allowedActivities, activity => ({
      name: `${activity.niceName} ${activity.isPremium ? '(Premium)' : ''}`,
      value: activity?.derivative ? activity.derivative : activity.collection,
      tooltip: activity.tooltip,
    }));
  };

  // setup the schema
  const formSchema = Yup.object().shape(formState.yupFields);

  /**
   * Format the form submit object based on the activity config
   * object and any values from the from fields
   */
  const formatActivitySubmit = async (values, activityId) => {
    const activity = values.activity;
    const topic = values.topic ?? '';
    const submit = {};

    if (includes(['25:10', 'breakouts'], activity)) {
      return formatLegacyActivitySubmit(values.name, topic, activity);
    }

    const configFields = find(activityConfigs[activity].fields, { key: 'config' });

    /**
     * Add any default config values if set in the defaultValue object
     * of the 'config' field.  These will be overridden below if there is a default
     * value set on the field itself. This is really meant for adding data to the config
     * that will be stored in Firestore that you don't want visible OR configurable
     * by the facilitator
     */
    if (keys(configFields?.defaultValue).length) {
      submit.config = configFields.defaultValue;
    }

    for (const field of activityConfigs[activity].fields) {
      if (field.key === 'config' && field.fields.length) {
        for (const configField of field.fields) {
          if (!submit.config) {
            submit.config = {};
          }
          if (configField.formField?.type === 'uploadImage') {
            const collection = values.collection ? values.collection : values.activity;
            const url = await uploadImage(values[configField.key], collection, activityId);
            submit.config[configField.key] = url || '';
          } else {
            submit.config[configField.key] = values[configField.key] ?? configField.defaultValue;
          }
        }
      } else {
        submit[field.key] = values[field.key] ?? field.defaultValue;
      }
    }

    const activityValueSubmit = isEmpty(activityStateInitalValues)
      ? submit
      : {
          ...submit,
          state: {
            ...submit.state,
            ...activityStateInitalValues.state,
          },
        };

    return activityValueSubmit;
  };

  /**
   * Format the form submit object for legacy activities that have
   * no config object
   */
  const formatLegacyActivitySubmit = (name, topic, activity) => {
    switch (activity) {
      case 'breakouts':
        return {
          topic: { name },
          rooms: [
            {
              description: '',
              title: 'Breakout 1',
              id: generateRandomId(),
              // participantRoomAssignments: [],
            },
          ],
          breakoutStarted: true,
          muralEnabled: false,
          muralURL: '',
          muralWorkspace: '',
        };
      case '25:10':
        return {
          name,
          topic,
          activeView: 'idea',
          ideas: [],
          participants: [],
        };
      default:
        return { name };
    }
  };

  /**
   * Setup form fields for legacy activities
   */
  const setupLegacyFields = activity => {
    const fields = [];

    fields.push(
      <div className={isAddOneActivity ? 'mb-4' : 'my-4'} key="legacy-name">
        <TextInput name="name" type="text" label="Name" required />
      </div>,
    );

    if (activity === '25:10') {
      fields.push(
        <div className="my-4" key="legacy-topic">
          <TextInput name="topic" type="text" label="Topic" required />
        </div>,
      );

      fields.push(
        <Typography className="mt-2" key="25:10note">
          <b>Note:</b> This activity requires at least 6 participants
        </Typography>,
      );
    }

    if (activity === '25:10') {
    }

    return fields;
    // setActivityFields(fields);
  };

  /**
   * Setup form fields based on activity config object
   */
  const setupFields = activity => {
    if (!activity) return;

    if (ACTIVITIES[activity].type === 'default') return;

    const filteredFields = filter(activityConfigs[activity].fields, field => {
      return field.isSetupField;
    });

    if (filter(activityConfigs[activity]?.fields, { key: 'config' }).length) {
      const config = find(activityConfigs[activity].fields, { key: 'config' });

      each(config.fields, field => {
        if (field.isSetupField) {
          filteredFields.push(field);
        }
      });
    } else if (selectedActivity !== 'brainwriting') {
      // LEGACY - Do not follow this pattern
      throw `Activity Config Error: ${selectedActivity}.fields has no config object`;
    }

    const groups = {};
    const fields = map(filteredFields, (field, idx) => {
      if (field.parent) {
        return;
      }
      if (field.groupName) {
        groups[field.groupName] = groups[field.groupName] || {};
        groups[field.groupName].values = groups[field.groupName].values || [];
        groups[field.groupName].values.push(field);

        if (field.child) {
          const child = find(filteredFields, childFields => childFields.key === field.child);
          each(groups[field.groupName].values, (parentFields, idx) => {
            if (parentFields.key === child?.parent) {
              groups[field.groupName].values[idx].child = child;
            }
          });
        }

        if (!groups[field.groupName].indexGroup) {
          groups[field.groupName].indexGroup = idx;
        }
        return;
      }
      if (field.child) {
        const child = find(filteredFields, childFields => childFields.key === field.child);
        field.formField.child = child;
      }
      return <AutoField key={`${field.formField.type}-${idx}`} field={field.formField} />;
    });

    if (keys(groups).length !== 0) {
      each(groups, (group, idx) => {
        fields[group.indexGroup] = <FieldsGroup key={`fields-group-${idx}`} group={group.values} />;
      });
    }

    return fields;
    // setActivityFields(fields);
  };

  /**
   * Listen for changes to the 'activity' field to trigger
   * dynamically re-populating the form setup data
   */
  const ValuesListener = ({ selectedActivity, setSelectedActivity }) => {
    const { values, setErrors } = useFormikContext();
    const activityValue = values.activity;

    useEffect(() => {
      if (activityValue !== selectedActivity) {
        setSelectedActivity(activityValue);

        setErrors({
          ...formState.initialValues,
        });
      }
    }, [activityValue, selectedActivity]);

    return null;
  };

  return (
    <Formik
      initialValues={formState.initialValues}
      validationSchema={formSchema}
      enableReinitialize
      validateOnChange={false}
      onSubmit={async (values, { resetForm, setSubmitting }) => {
        const collection = values.collection ? values.collection : values.activity;
        const batch = db.batch();
        const newActivity = db.collection(collection).doc();
        const newActivityRef = await newActivity.get();
        const sessionType = getFacilitatorSessionType();

        batch.set(db.doc(`${collection}/${newActivityRef.id}`), {
          ...(await formatActivitySubmit(values, newActivityRef.id)),
          id: newActivityRef.id,
          createdAt: db.FieldValue.serverTimestamp(),
          createdBy: user.uid,
          sessionId: theSessionId,
        });

        batch.update(db.doc(`sessions/${theSessionId}`), {
          activities: db.FieldValue.arrayUnion({
            activity: values.activity,
            name: `${ACTIVITIES[values.activity].niceName}: ${values.name}`,
            id: newActivityRef.id,
            route: `${collection}/${newActivityRef.id}`,
          }),
        });

        batch
          .commit()
          .then(() => {
            if (process.env.REACT_APP_HOSTING_ENV === 'prod') {
              mixpanel.track('Add Activity', { activity: values.activity });
            }

            if (isActivityTemplate) {
              if (isLastActivityInTemplate) {
                closeDialog();
              }

              // delay the rest 100ms so the modal doesn't flicker...
              setTimeout(function () {
                resetForm();
                setSubmitting(false);

                if (!isLastActivityInTemplate) {
                  setActivityIdx(activityIdx + 1);
                } else {
                  resetCreateSessionDialogState();
                  history.push('/admin/sessions');
                }
              }, 100);
            } else {
              dispatch(
                setSelectedFacilitatorRoute(`${collection}/${newActivityRef.id}`, theSessionId),
              );
              setSubmitting(false);
              resetForm();
              closeDialog();
              history.push(
                `/admin/${sessionType}/${theSessionId}/${collection}/${newActivityRef.id}`,
              );
              dispatch(enqueueSuccessSnackbar('Activity added'));
            }
          })
          .catch(err => {
            dispatch(enqueueErrorSnackbar('Error adding activity'));
          });
      }}
    >
      {({ isSubmitting, submitForm }) => (
        <Form style={{ paddingBottom: 20 }}>
          {!activityTemplateActivity && (
            <CustomSelect
              name="activity"
              label="Activity"
              type="select"
              selectOptions={getSelectOptions()}
              placeholder="Select activity..."
              required
            />
          )}

          {formState.activityFields}

          <ValuesListener
            selectedActivity={selectedActivity}
            setSelectedActivity={setSelectedActivity}
          />

          <div
            className={cx('form-dialog-buttons', {
              'justify-content-between': !isActivityTemplate,
              'justify-content-end': isActivityTemplate,
              'flex-column-reverse': smallScreen,
            })}
          >
            <Button
              variant="outlined"
              color="primary"
              disabled={isSubmitting}
              onClick={() => {
                if (isActivityTemplate) {
                  cancelActivityTemplate();
                  resetCreateSessionDialogState();
                }

                closeDialog();
              }}
            >
              CANCEL
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={submitForm}
            >
              {isActivityTemplate ? (isLastActivityInTemplate ? 'DONE' : 'NEXT') : 'ADD ACTIVITY'}
              {isSubmitting && <ButtonSpinner />}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

AddActivityForm.defaultProps = {
  activityTemplateInitalValues: {},
};

AddActivityForm.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  /**
   * is this being rendered inside the Create Meeting dialog
   * in order to collect settings for the included activities
   */
  isActivityTemplate: PropTypes.bool,
  /**
   * is this the last activity to collect settings from as part
   * of a meeting template
   */
  isLastActivityInTemplate: PropTypes.bool,
  /**
   * The activity type (noteAndVote, polling, etc) if we are setting
   * settings as part of a meeting template
   */
  activityTemplateActivity: PropTypes.string,
  /**
   * Any default values being passed if we are setting
   * settings as part of a meeting template
   */
  activityTemplateInitalValues: PropTypes.object,
  /**
   * A passed in sessionId if we are setting
   * settings as part of a meeting template
   */
  sessionId: PropTypes.string,
  /**
   * The index of the activity in the includedActivities array of
   * CreateSessionDialog if we are setting settings as part of a meeting template
   */
  activityIdx: PropTypes.number,
  /**
   * setter function to advance the activityIdx
   */
  setActivityIdx: PropTypes.func,
  /**
   * Fully resets the state of the CreateMeeting
   */
  resetCreateSessionDialogState: PropTypes.func,
};

export default AddActivityForm;
