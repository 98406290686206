import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cx from 'classnames';
import { makeStyles } from '@material-ui/styles';
// library components
import { Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
// cr components
import Journey from 'components/UserJourneyMoments/Participant/Journey';
// selectors
import { useUserJourneyMomentsSelector } from 'redux/selectors/userJourneyMomentsSelectors';
// actions
import { updateKeyMoment } from 'redux/actions/userJourneyMomentsActions';

const useStyles = makeStyles(theme => ({
  successColor: {
    color: theme.palette.success.main,
  },
}));

/**
 * User Journey Moments view for the 'keyMoment' phase on the Participant side
 */
const UserJourneyMomentsParticipantKeyMoment = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const participantId = useSelector(state => state.firebase.auth.uid);

  const {
    id,
    participantData: { journeys },
    state: { activeView },
  } = useUserJourneyMomentsSelector();
  const participantJourney = journeys[participantId];
  const [keyMoment, setKeyMoment] = useState(participantJourney?.keyMoment);
  const [isKeyMomentSelected, setIsKeyMomentSelected] = useState(!!keyMoment);

  // select key moment
  const handleSelectJourneyField = (type, isSelected) => {
    if (isSelected) {
      dispatch(updateKeyMoment(id, participantId, type));
      setKeyMoment(type);
    } else {
      dispatch(updateKeyMoment(id, participantId, ''));
      setKeyMoment('');
    }
    setIsKeyMomentSelected(isSelected);
  };

  return (
    <>
      <Journey
        handleSelectJourneyField={handleSelectJourneyField}
        participantJourney={participantJourney}
        activeView={activeView}
        keyMoment={keyMoment}
      />
      {isKeyMomentSelected ? (
        <>
          <CheckCircleIcon className={classes.successColor} />
          <Typography className={cx(classes.successColor, 'mb-2')} variant="body2">
            You’ve selected the key moment
          </Typography>
        </>
      ) : (
        <Typography className="mb-2" variant="body2" color="textSecondary">
          Select the key moment that represents the biggest pain point
        </Typography>
      )}
    </>
  );
};

export default UserJourneyMomentsParticipantKeyMoment;
