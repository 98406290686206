import {
  map,
  orderBy,
  max,
  filter,
  includes,
  findIndex,
  join,
  concat,
  forEach,
  sortBy,
} from 'lodash';

/**
 * Creates the output array for a How Might We actvity
 * @param {Object} ideas
 * @returns {Array<{text: String, votes: Number, winner: Boolean}>}
 */
export const createOutput = ideas => {
  // filter to ideas that were shared
  let output = filter(ideas, idea => idea.isShared && !idea.isCombine);

  // create initial output array
  output = map(output, idea => ({
    text: idea.idea,
    votes: idea.votedParticipants?.length || 0,
    winner: false,
  }));

  // create array of votes
  const votes = map(output, 'votes');

  // determine hights vote count
  const highVote = max(votes);

  // tag notes with the highest votes as 'winner'
  forEach(output, (idea, idx) => {
    if (idea.votes === highVote) {
      output[idx].winner = true;
    }
  });

  // sort array and return
  return orderBy(output, ['votes', 'text'], ['desc', 'asc']);
};

/**
 * Creates the array for Report Header
 *
 */
export const createHMWReportHeader = () => {
  return [
    { label: 'Votes', key: 'votes' },
    { label: 'HMW', key: 'hmw' },
    { label: 'Duplicate', key: 'duplicate' },
  ];
};

/**
 * Creates the data for Report
 *
 */
export const createHMWReportData = (archivedIdeas, votedIdeas) => {
  const notSharedIdeas = filter(archivedIdeas, { isShared: false });
  const sharedIdeas = filter(archivedIdeas, { isShared: true });
  const allHWMIdeas = concat(sharedIdeas, votedIdeas);
  const sortedAllHWMIdeas = orderBy(
    allHWMIdeas,
    [idea => (idea.votedParticipants ? idea.votedParticipants.length : 0), 'idea'],
    ['desc'],
  );
  const sharedIdeasReport = map(sortedAllHWMIdeas, idea => {
    const duplicateNumber = findIndex(sortedAllHWMIdeas, sortedIdea =>
      includes(sortedIdea.combineIdeasId, idea.ideaId),
    );
    return {
      votes: idea.votedParticipants ? idea.votedParticipants.length : 0,
      hmw: idea.idea,
      duplicate: idea.isCombine ? `${duplicateNumber + 1}` : '',
    };
  });
  // render data for csv report for not shared ideas
  const notSharedIdeasReport = map(notSharedIdeas, idea => ({
    votes: '',
    hmw: idea.idea,
    duplicate: '',
  }));
  return [...sharedIdeasReport, ...notSharedIdeasReport];
};

export const createHMWReport = activityData => {
  const participantData = activityData?.participantData;
  const archivedIdeas = sortBy(
    filter(
      participantData?.ideas,
      idea => !idea.votedParticipants || idea.votedParticipants?.length === 0,
    ),
    ['order', 'idea'],
  );
  const votedIdeas = orderBy(
    filter(
      participantData?.ideas,
      idea => idea.votedParticipants && idea.votedParticipants?.length !== 0,
    ),
    [idea => idea.votedParticipants?.length, 'order'],
    ['desc', 'desc'],
  );

  const headers = createHMWReportHeader();
  const data = createHMWReportData(archivedIdeas, votedIdeas);
  return { headers, data };
};
