import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { filter, has, head, map, sortBy, each, find } from 'lodash';
// library components
import { Box } from '@material-ui/core';
// cr components
import ActivityParticipantDetails from 'components/ActivityParticipantDetails';
import ActivityPromptAdmin from 'components/ActivityPromptAdmin';
import ActivityActionButton from 'components/CustomButtons/ActivityActionButton';
import Categories from './Categories';
// selectors
import {
  useNoteAndCategorizeSelector,
  getParticipantsCompletedRoundRobin,
  getResults,
} from 'redux/selectors/noteAndCategorizeSelectors';
import { getParticipantsMap } from 'redux/selectors/participantsSelectors';
// actions
import {
  updateParticipantRoundRobinStatus,
  updateCurrentParticipant,
  updateActivityView,
} from 'redux/actions/noteAndCategorizeAction';
// config
import { NOTE_AND_CATEGORIZE_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';

const useStyles = makeStyles(theme => ({
  promptAdmin: {
    marginBottom: theme.spacing(1),
  },
}));

/**
 * Note & Categorize view for the 'roundRobin' phase on the Facilitator side
 */
const NoteAndCategorizeAdminRoundRobin = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const noteAndCategorize = useNoteAndCategorizeSelector();
  const {
    id,
    prompt,
    state: { activeView },
    config: { categories },
    participantData: { participantRoundRobin, notes },
  } = noteAndCategorize;
  const results = useSelector(getResults());

  let currentParticipantId = null;
  let isLastParticipant = false;
  let sortField = 'value';
  const nextView = 'review';
  const finishButtonText = 'Remove Duplicates';

  const sortedCategories = sortBy(
    map(categories, category => {
      sortField = has(category, 'order') ? 'order' : 'value';
      return category;
    }),
    sortField,
  );

  const participantsCompletedRoundRobinData = useSelector(getParticipantsCompletedRoundRobin());
  const participantsData = useSelector(getParticipantsMap);

  const noRoundRobinParticipants = filter(
    participantsCompletedRoundRobinData,
    participant =>
      !participant.completed &&
      find(notes, note => note.participantId === participant.id && note.categoryId !== ''),
  );
  const noVotedParticipantsLength = noRoundRobinParticipants.length;

  let participantDetailsHeaderText = 'Reviewing notes from ';

  if (noVotedParticipantsLength <= 1) {
    isLastParticipant = true;
  }
  if (noVotedParticipantsLength) {
    currentParticipantId = head(noRoundRobinParticipants).id;
    const participantName = participantsData[currentParticipantId]?.name || 'Unknown';
    participantDetailsHeaderText += `${participantName}`;
    // pass current participant data for participant view
    dispatch(updateCurrentParticipant(id, { id: currentParticipantId, name: participantName }));
    each(participantsCompletedRoundRobinData, participantData => {
      if (participantData.id === participantRoundRobin?.id) {
        participantData.completed = true;
      }
    });
  }

  const onRevealResults = () => {
    if (currentParticipantId) {
      dispatch(updateParticipantRoundRobinStatus(id, currentParticipantId));
    }
    if (isLastParticipant) {
      dispatch(updateActivityView(nextView, id));
    }
  };

  return (
    <Box className="d-flex flex-column justify-content-center">
      <ActivityPromptAdmin
        prompt={prompt}
        collection={CONFIG.collection}
        activityId={id}
        className={classes.promptAdmin}
      />
      <Categories
        categories={sortedCategories}
        view={activeView}
        currentParticipantId={currentParticipantId}
        output={results}
      />
      <ActivityParticipantDetails
        headerText={participantDetailsHeaderText}
        hasCompletedLabel="Reviewed"
        hasntCompletedLabel="Hasn't Reviewed"
        participantsCompletedStatus={participantsCompletedRoundRobinData}
      />
      <ActivityActionButton
        text={isLastParticipant ? finishButtonText : 'next participant'}
        onClick={onRevealResults}
        className="mb-2"
        delayReady
        key={currentParticipantId}
      />
    </Box>
  );
};

export default NoteAndCategorizeAdminRoundRobin;
