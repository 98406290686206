import firebase from 'firebase/app';
import 'firebase/storage';
import imageCompression from 'browser-image-compression';
// utils
import { generateRandomId } from './generateRandomId';

// upload image to storage
export const uploadImage = async (file, collection, collectionId) => {
  if (!file) {
    return;
  }
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
  };

  try {
    const imageId = generateRandomId();
    const compressedFile = await imageCompression(file, options);
    const storageRef = firebase.storage().ref(`${collection}/${collectionId}/${imageId}`);

    await storageRef.put(compressedFile);
    const url = await storageRef.getDownloadURL();

    return url;
  } catch (err) {
    console.log('Error upload image to firebase', err);
  }
};

// Delete image from firebase storage
export const deleteImage = async (src, successCb) => {
  const deleteRef = firebase.storage().refFromURL(src);

  deleteRef
    .delete()
    .then(() => {
      if (successCb) {
        successCb();
      }
      return true;
    })
    .catch(err => {
      console.log('Error delete image from firebase storage', err);
      return false;
    });
};
