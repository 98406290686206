import React from 'react';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
// cr components
import ActivityTemplate from 'components/ActivityTemplate/ActivityTemplate';
import ParticipantActivityContent from 'components/ParticipantActivityContent';
import HeatMappingParticipantUpload from 'components/HeatMapping/Participant/HeatMappingParticipantUpload';
import HeatMappingParticipantHeatMapping from 'components/HeatMapping/Participant/HeatMappingParticipantHeatMapping';
import HeatMappingParticipantReview from 'components/HeatMapping/Participant/HeatMappingParticipantReview';
import HeatMappingParticipantStrawPoll from 'components/HeatMapping/Participant/HeatMappingParticipantStrawPoll';
import HeatMappingParticipantStrawPollResults from 'components/HeatMapping/Participant/HeatMappingParticipantStrawPollResults';
import HeatMappingParticipantSuperVote from 'components/HeatMapping/Participant/HeatMappingParticipantSuperVote';
import HeatMappingParticipantResults from 'components/HeatMapping/Participant/HeatMappingParticipantResults';
// selectors
import { useHeatMappingSelector } from 'redux/selectors/heatMappingSelectors';
// constants
import { HEAT_MAPPING_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';

//add new view for all action
const views = {
  upload: HeatMappingParticipantUpload,
  heatMapping: HeatMappingParticipantHeatMapping,
  review: HeatMappingParticipantReview,
  strawPoll: HeatMappingParticipantStrawPoll,
  strawPollResults: HeatMappingParticipantStrawPollResults,
  superVote: HeatMappingParticipantSuperVote,
  results: HeatMappingParticipantResults,
};

const HeatMappingParticipant = ({
  match: {
    params: { activityId },
  },
}) => {
  const heatMapping = useHeatMappingSelector();

  // hooks up a firestore listener to Redux
  useFirestoreConnect(() => [
    {
      collection: 'heatMapping',
      doc: `${activityId}`,
      storeAs: 'heatMapping',
    },
  ]);

  // ensure all of the data we need is loaded
  if (!isLoaded(heatMapping) || activityId !== heatMapping?.id) {
    return null;
  }

  let resetCount = heatMapping.resetCount ?? 0;
  const Component = views[heatMapping.state.activeView];

  return (
    <ActivityTemplate type="Heat-Mapping" name={heatMapping.name} key={resetCount}>
      <ParticipantActivityContent
        activityData={heatMapping}
        viewLabel={CONFIG.participantViewLabels[heatMapping.state.activeView]}
        activityConfig={CONFIG}
      >
        <Component />
      </ParticipantActivityContent>
    </ActivityTemplate>
  );
};

export default HeatMappingParticipant;
