import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { map, find, debounce, delay } from 'lodash';
import { Formik, Form } from 'formik';
import cx from 'classnames';
import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroller';
// library components
import { Button, Box, Divider, FilledInput, InputLabel, makeStyles } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// cr components
import ButtonSpinner from 'components/ButtonSpinner/ButtonSpinner';
import GridContainer from 'components/Grid/GridContainer';
import SearchImages from 'components/LightningDemo/Participant/SearchImages';

const useStyles = makeStyles(theme => ({
  cancelButton: {
    background: '#9E9E9E',
    color: '#fff',
  },
  button: {
    height: 40,
    width: 105,
    textTransform: 'uppercase',
  },
  inputRoot: {
    borderRadius: 0,
  },
  input: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  label: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    fontSize: 12,
    fontWeight: 300,
  },
  buttonSpinnerBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 40,
    paddingRight: theme.spacing(5),
  },
  buttonSpinner: {
    color: theme.palette.primary.main,
  },
  scrollingContainer: {
    height: ({ isSmallScreen }) => (isSmallScreen ? 'calc(100vh - 330px)' : '50vh'),
    width: ({ isSmallScreen }) => (isSmallScreen ? '100%' : 'calc(100% + 20px)'),
    padding: 3,
    paddingRight: 4,
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  imagesContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  gridContainer: {
    width: 'fit-content',
  },
}));

/**
 * Renders form for search images
 */
const ImageSearchForm = ({ closeDialog, handleSelectSearchImage }) => {
  const isSmallScreen = useMediaQuery('(max-width: 767px)');
  const classes = useStyles({ isSmallScreen });
  const [isLoading, setIsLoading] = useState(false);
  const [searchImages, setSearchImages] = useState([]);
  const [query, setQuery] = useState('');
  const [timer, setTimer] = useState();
  const [isSearch, setIsSearch] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState('');
  const [currentPage, setCurrentPage] = useState(0);

  /**
   * Fetch available image
   */
  const fetchImage = useCallback(
    async image => {
      if (!find(searchImages, ({ link }) => link === image.link)) {
        try {
          const response = await fetch(image.link);
          const blob = response.ok && (await response.blob());
          if (blob && !find(searchImages, ({ size }) => size === blob.size)) {
            setSearchImages(prevSrc => [
              ...prevSrc,
              { link: image.link, src: URL.createObjectURL(blob), size: blob.size },
            ]);
          }
        } catch (error) {
          console.log('fetch image error: ', error);
        }
      }
    },
    [searchImages],
  );

  const handleSearchImage = useCallback(async () => {
    const searchRequest = {
      google: 'https://www.googleapis.com/customsearch/v1?',
      apiKey: `key=${process.env.REACT_APP_GOOGLE_SEARCH_API_KEY}`,
      cx: `&cx=${process.env.REACT_APP_GOOGLE_CX}&num=10&start=${
        currentPage * 10 + 1
      }&searchType=image&q=`,
      query: query,
    };
    try {
      const response = await axios.get(
        searchRequest.google +
          searchRequest.apiKey +
          searchRequest.cx +
          encodeURIComponent(searchRequest.query),
      );
      setIsSearch(false);
      await Promise.all(map(response.data.items, fetchImage));
      setIsLoading(false);
      setCurrentPage(currentPage + 1);
    } catch (error) {
      console.log(error);
    }
  }, [currentPage, fetchImage, query]);

  useEffect(() => {
    if (isSearch) {
      handleSearchImage();
    }
  }, [isSearch, handleSearchImage]);

  const handleSelectedImageUrl = url => {
    if (selectedImageUrl === url) {
      setSelectedImageUrl('');
    } else {
      setSelectedImageUrl(url);
    }
  };
  const renderImage = (src, idx, isSelected) => (
    <SearchImages
      key={`search-image-${idx}`}
      src={src}
      handleSelectedImageUrl={handleSelectedImageUrl}
      isSelected={isSelected}
    />
  );

  const renderEvenImages = () =>
    map(searchImages, ({ src }, idx) => {
      if (!(idx % 2)) {
        return renderImage(src, idx, src === selectedImageUrl);
      }
    });

  const renderOddImages = () =>
    map(searchImages, ({ src }, idx) => {
      if (idx % 2) {
        return renderImage(src, idx, src === selectedImageUrl);
      }
    });

  const renderAllImages = () =>
    map(searchImages, ({ src }, idx) => {
      return renderImage(src, idx, src === selectedImageUrl);
    });

  const update = debounce(() => {
    setSearchImages([]);
    setIsLoading(true);
    setIsSearch(true);
  }, 1000);

  const handleChangeQuery = useCallback(
    event => {
      setQuery(event.target.value);
      if (event.target.value && event.target.value?.length > 3) {
        if (timer) {
          clearTimeout(timer);
        }
        const timerId = delay(update, 100);
        setTimer(timerId);
      } else {
        setSearchImages([]);
        setCurrentPage(0);
      }
    },
    [timer, update],
  );

  return (
    <Formik
      initialValues={{
        searchImage: query,
      }}
      onSubmit={() => {
        if (!!selectedImageUrl) {
          closeDialog();
          handleSelectSearchImage(selectedImageUrl);
        } else {
          return;
        }
      }}
    >
      {({ isSubmitting, submitForm }) => (
        <Form className="pb-3">
          <Divider />
          <InputLabel className={classes.label}>Search on Google Images</InputLabel>
          <FilledInput
            name="searchImage"
            value={query}
            className="mb-3"
            classes={{ root: classes.inputRoot, input: classes.input }}
            id="image-search"
            variant="filled"
            fullWidth
            onChange={handleChangeQuery}
          />
          <Box className={classes.scrollingContainer}>
            {isLoading ? (
              <Box className={classes.buttonSpinnerBox}>
                <ButtonSpinner className={classes.buttonSpinner} />
              </Box>
            ) : (
              <InfiniteScroll
                initialLoad={false}
                pageStart={0}
                loadMore={handleSearchImage}
                hasMore={currentPage !== 0}
                loader={
                  <Box key="loader" className={classes.buttonSpinnerBox}>
                    <ButtonSpinner className={classes.buttonSpinner} />
                  </Box>
                }
                useWindow={false}
              >
                {isSmallScreen ? (
                  <Box key="images-box" className={classes.imagesContainer}>
                    <GridContainer direction="column" className={classes.gridContainer}>
                      {renderAllImages()}
                    </GridContainer>
                  </Box>
                ) : (
                  <Box key="images-box" className={classes.imagesContainer}>
                    <GridContainer direction="column" className={classes.gridContainer}>
                      {renderEvenImages()}
                    </GridContainer>
                    <GridContainer direction="column" className={classes.gridContainer}>
                      {renderOddImages()}
                    </GridContainer>
                  </Box>
                )}
              </InfiniteScroll>
            )}
          </Box>
          <Box display="flex" justifyContent="space-between" className="mt-3">
            {!!closeDialog && (
              <Button
                classes={{ root: classes.cancelButton, sizeSmall: classes.button }}
                size="small"
                variant="contained"
                disabled={isSubmitting}
                onClick={closeDialog}
              >
                CANCEL
              </Button>
            )}
            <Button
              size="small"
              classes={{ sizeSmall: classes.button }}
              variant="contained"
              color="primary"
              onClick={submitForm}
            >
              Select
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

ImageSearchForm.propTypes = {
  closeDialog: PropTypes.func, // close modal form
  handleSelectSearchImage: PropTypes.func, // submit modal form
};

export default ImageSearchForm;
