import { createSelector } from 'reselect';
import { map, includes, reduce, size, find } from 'lodash';
import { useShallowEqualSelector } from '../utils';

const storyFlowSelector = state => state.firestore.data.storyFlow || {};

export const useStoryFlowSelector = () => {
  return useShallowEqualSelector(storyFlowSelector);
};

/**
 * Get data on which participants have completed upload,
 * formatted for the ActivityParticipantDetail component
 *
 * @returns {Array.<{id: String, completed: Boolean}>}
 */
export const getParticipantsCompletedCreate = () =>
  createSelector([useStoryFlowSelector], ({ participantData: { flows, participants } }) => {
    return map(participants, participantId => {
      const participantFlow = find(
        flows,
        flow => flow.participantId === participantId && !includes(flow.values, ''),
      );
      const participantFlowSize = size(participantFlow?.values);
      return {
        id: participantId,
        completed: participantFlowSize > 0,
        completedCount: participantFlowSize,
      };
    });
  });

/**
 * Get data on which participants have completed select,
 * formatted for the ActivityParticipantDetail component
 *
 * @returns {Array.<{id: String, completed: Boolean}>}
 */
export const getParticipantsCompletedSelect = () =>
  createSelector(
    [useStoryFlowSelector],
    ({ participantData: { participants, participantsSelectedFlow } }) => {
      return map(participants, participantId => ({
        id: participantId,
        completed: !!participantsSelectedFlow?.[participantId],
        // completedCount not needed as max is 1
      }));
    },
  );

/**
 * Get data on which participants have completed super vote,
 * formatted for the ActivityParticipantDetail component
 *
 * @returns {Array.<{id: String, completed: Boolean}>}
 */
export const getParticipantsCompletedSuperVote = () =>
  createSelector(
    [useStoryFlowSelector],
    ({ participantData: { participants, participantsSuperVoteFlow } }) => {
      return map(participants, participantId => ({
        id: participantId,
        completed: participantsSuperVoteFlow?.[participantId]
          ? !!participantsSuperVoteFlow[participantId]
          : false,
        //completedCount not needed as max size is 1.
      }));
    },
  );

export const getCountSelectedFlows = () =>
  createSelector(
    [useStoryFlowSelector],
    ({ participantData: { participants, participantsSelectedFlow } }) => {
      return reduce(
        participants,
        (acc, participant) => {
          if (participantsSelectedFlow?.[participant]) {
            const selectedFlowId = participantsSelectedFlow[participant].selectedFlowId;
            const selectedFlowForParticipant = acc[selectedFlowId] ? acc[selectedFlowId] : [];
            acc[selectedFlowId] = [...selectedFlowForParticipant, participant];
          }
          return acc;
        },
        {},
      );
    },
  );
