import React from 'react';
import PropTypes from 'prop-types';
import { map, fill } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { Droppable } from 'react-beautiful-dnd';
// library components
import { Box, Typography } from '@material-ui/core';
// cr components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from './Card';

const useStyles = makeStyles(theme => ({
  categoryContainer: {
    height: '100%',
    flex: '1 1 240px',
    margin: '0 10px 20px',
  },
  container: {
    width: '100%',
    height: '100%',
    minHeight: 180,
    padding: 20,
    // paddingTop: theme.spacing(1),
    border: ({ isPrimaryCategory }) =>
      isPrimaryCategory ? '1px dashed #CFCFCF' : '1px dashed #CFCFCF',
    borderRadius: 4,
    color: ({ isPrimaryCategory }) => (isPrimaryCategory ? '#008437' : '#093B75'),
  },
  title: {
    marginBottom: theme.spacing(1),
    color: ({ isPrimaryCategory }) => (isPrimaryCategory ? '#008437' : '#093B75'),
    fontSize: 15,
  },
  gridContainer: {
    width: 'calc(100% + 10px)',
    margin: '0 -5px',
    justifyContent: 'center',
  },
  gridItem: {
    padding: `0 5px !important`,
  },
  placeholderCard: {
    width: 95,
    height: 115,
    border: '1px dashed #CFCFCF',
    borderRadius: 4,
    background: '#F9F9F9',
    marginBottom: 10,
  },
}));

/**
 * Category view on the Participant side.
 */
const Category = ({
  cards,
  cardsCount,
  droppableId,
  context,
  isCategoryFull,
  isPrimaryCategory,
  handleRemoveCard,
}) => {
  const classes = useStyles({ isPrimaryCategory });

  const getCategoryDragStyle = isDraggingOver => {
    if (isDraggingOver && !isCategoryFull && isPrimaryCategory) {
      return {
        background: '#EFEFEF',
      };
    } else if (isDraggingOver && !isCategoryFull) {
      return {
        background: '#EFEFEF',
      };
    }
  };

  const renderCards = () =>
    map(cards, (card, idx) => {
      return (
        <GridItem className={classes.gridItem} key={`${card?.cardId}-${card?.participantId}`}>
          <Card
            card={card}
            draggableId={idx}
            context={context}
            handleRemoveCard={handleRemoveCard}
          />
        </GridItem>
      );
    });

  const renderPlaceholderCards = () => {
    //Don't render the Placeholder Cards when Cards Count isn't set
    if (!cardsCount) {
      return null;
    }

    const placeholderCards = fill(Array(cardsCount - cards.length), ''); //create an array of remaining cards
    return map(placeholderCards, (card, index) => {
      return (
        <GridItem className={classes.gridItem} key={index}>
          <Box className={classes.placeholderCard} />
        </GridItem>
      );
    });
  };

  return (
    <Box className={classes.categoryContainer}>
      <Droppable isDropDisabled={context === 'review'} droppableId={droppableId} type="cards">
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={getCategoryDragStyle(snapshot.isDraggingOver)}
            className={classes.container}
          >
            {/* <Typography className={classes.title} align="center">
              {isPrimaryCategory
                ? categoryName + ' (Primary)'
                : categoryName
              }
            </Typography> */}
            <GridContainer direction="row" className={classes.gridContainer}>
              {renderCards()}
              {renderPlaceholderCards()}
            </GridContainer>
            <div style={{ display: 'none' }}>{provided.placeholder}</div>
          </div>
        )}
      </Droppable>
    </Box>
  );
};

Category.propTypes = {
  categoryName: PropTypes.string.isRequired, // name of category
  cardsCount: PropTypes.number.isRequired,
};

export default Category;
