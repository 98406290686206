import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import cx from 'classnames';
// library components
import { Typography, Box, Tooltip } from '@material-ui/core';

const LightTooltip = withStyles(theme => ({
  tooltip: {
    maxWidth: 240,
    padding: theme.spacing(1.5),
    backgroundColor: theme.palette.common.white,
    color: '#5E5E5E',
    boxShadow: theme.shadows[1],
    size: 14,
    cursor: 'pointer',
  },
  arrow: {
    color: theme.palette.common.white,
  },
}))(Tooltip);

const useDotsStyles = makeStyles(theme => ({
  dot: {
    width: theme.spacing(1.6),
    height: theme.spacing(1.6),
    marginLeft: theme.spacing(1),
    borderRadius: '50%',
    background: theme.palette.indigo.main,
    cursor: 'pointer',
  },
  completePollDot: {
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    marginLeft: theme.spacing(1),
    borderRadius: '50%',
    background: theme.palette.indigo.main,
  },
  dotSelect: {
    boxSizing: 'content-box',
    marginLeft: theme.spacing(0.8),
    background: theme.palette.info.light,
    border: `2px solid ${theme.palette.indigo.main}`,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
}));

/**
 * Renders participants votes dots
 */

const ParticipantsVotes = ({ comment, context, idx, isSelect, setSelectedDot }) => {
  const dotsClasses = useDotsStyles();

  // set visible comment of vote
  const renderComment = () => {
    if (context !== 'superVote' && context !== 'results') {
      setSelectedDot(idx);
    }
  };

  // close comment of vote
  const closeComment = () => {
    setSelectedDot('');
  };

  const tooltipTitle = (
    <Typography variant="body2" onClick={closeComment}>
      {comment === '' ? 'n/a' : comment}
    </Typography>
  );

  return (
    <Box>
      <LightTooltip
        className={dotsClasses.tooltip}
        title={tooltipTitle}
        open={isSelect}
        placement="top"
        interactive
        arrow
      >
        <div
          className={
            context === 'superVote' || context === 'results'
              ? dotsClasses.completePollDot
              : cx(dotsClasses.dot, isSelect && dotsClasses.dotSelect)
          }
          onClick={renderComment}
        />
      </LightTooltip>
    </Box>
  );
};

ParticipantsVotes.propTypes = {
  context: PropTypes.oneOf([
    'strawPoll',
    'strawPollResults',
    'strawPollResultsAdmin',
    'superVote',
    'results',
  ]),
  idx: PropTypes.string.isRequired, // participant vote id
  comment: PropTypes.string.isRequired, // participant vote comment
  isSelected: PropTypes.bool, // participant vote select
  setSelectedDot: PropTypes.func, // function for changing participant select vote
};

export default ParticipantsVotes;
