import { each, filter, map, sortBy, values, join, find, isEmpty } from 'lodash';

/**
 * Creates the output array for a User Journey Moments actvity
 * @param {Object} journeys
 * @returns {Array<{type: String, text: String, votes: Number}>}
 */
export const createOutput = (journeys, superVoteTypes) => {
  if (isEmpty(journeys) || isEmpty(superVoteTypes)) {
    return [];
  }
  const isActorSelect = find(superVoteTypes, voteType => voteType.type === 'actor');
  const isMomentSelect = find(superVoteTypes, voteType => voteType.type !== 'actor');

  const actor = find(journeys, journey => journey.participantId === isActorSelect?.id);
  const moment = find(journeys, journey => journey.participantId === isMomentSelect?.id);

  const outputActor = actor?.values.actor
    ? {
        type: 'actor',
        value: actor.values.actor,
      }
    : {};

  const outputMoment = moment?.values[isMomentSelect?.type]
    ? {
        type: 'moment',
        value: moment.values[isMomentSelect.type],
      }
    : {};

  // output value
  const output = [outputActor, outputMoment];

  return output;
};

/**
 * Creates the results for a User Journey Moments actvity
 * @param {Object} journeys
 * @param {Object} superVoteMoment
 * @returns {Array<{type: String, text: String, votes: Number, id: String, superVote: Boolean}>}
 */
export const createKeyMomentsResults = (journeys, superVoteMoment) => {
  // get votes for key moments
  const keyMoments = {};
  each(journeys, journey => {
    if (journey.keyMoment && journey.values?.actor) {
      const momentVotes = filter(
        journey.votedParticipants,
        vote => vote.moment && vote.moment.isVoted,
      );
      keyMoments[journey.participantId] = {
        id: journey.participantId,
        type: 'moment',
        text: journey.values[journey.keyMoment],
        votes: momentVotes.length,
        superVote: superVoteMoment?.participantId === journey.participantId,
      };
    }
  });

  return keyMoments;
};

/**
 * Creates the results for a User Journey Moments actvity
 * @param {Object} journeys
 * @param {Object} superVoteActor
 * @returns {Array<{type: String, text: String, votes: Number, id: String, superVote: Boolean}>}
 */
export const createActorsResults = (journeys, superVoteActor) => {
  // get votes for actors
  const actors = {};
  each(journeys, journey => {
    if (journey.keyMoment && journey.values?.actor) {
      const actorVotes = filter(
        journey.votedParticipants,
        vote => vote.actor && vote.actor.isVoted,
      );
      actors[journey.participantId] = {
        id: journey.participantId,
        type: 'actor',
        text: journey.values.actor,
        votes: actorVotes.length,
        superVote: superVoteActor?.participantId === journey.participantId,
      };
    }
  });

  return actors;
};

/**
 * Creates the array for Report Header
 *
 */
export const createUserJourneyReportHeader = () => [
  { label: 'Super Vote', key: 'superVote' },
  { label: 'Votes', key: 'votes' },
  { label: 'Type', key: 'type' },
  { label: 'Text', key: 'text' },
];

/**
 * Creates the data for Report
 *
 */
export const createUserJourneyReportData = (sortedJourneys, superVoteTypes) => {
  const isActorSelect = find(superVoteTypes, voteType => voteType.type === 'actor');
  const isMomentSelect = find(superVoteTypes, voteType => voteType.type !== 'actor');

  const superVoteActor = find(
    sortedJourneys,
    journey => journey.participantId === isActorSelect?.id,
  );
  const superVoteMoment = find(
    sortedJourneys,
    journey => journey.participantId === isMomentSelect?.id,
  );

  const actors = createActorsResults(sortedJourneys, superVoteActor);
  const keyMoments = createKeyMomentsResults(sortedJourneys, superVoteMoment);
  const results = sortBy([...values(actors), ...values(keyMoments)], ['id', 'type']);
  return map(results, result => ({
    superVote: result.superVote ? 1 : 0,
    votes: result.votes,
    type: result.type,
    text: result.text,
  }));
};

export const createUserJourneyReport = activityData => {
  const participantData = activityData?.participantData;
  const sortedJourneys = sortBy(participantData.journeys, 'participantId');

  const headers = createUserJourneyReportHeader();
  const data = createUserJourneyReportData(sortedJourneys, participantData.superVoteTypes);

  return { headers, data };
};
