import React from 'react';
import { useSelector } from 'react-redux';
import { includes, keys } from 'lodash';
import { useFirestore } from 'react-redux-firebase';
// material ui
import { Typography, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
// cr components
import BrainwritingParticipantWriteForm from './BrainwritingParticipantWriteForm';
import BrainwritingParticipantEdit from './BrainwritingParticipantEdit';
import GrayBox from '../../GrayBox/GrayBox';
// selectors
import { useBrainwritingSelector } from 'redux/selectors/brainwritingSelectors';
// utils
import { renderHtmlStringSimple } from 'utils/renderHtmlString';
import { localStorageSetObject, localStorageGetObject } from '../../../utils/localStorageUtils';
// styles
import './write.scss';

const BrainwritingParticipantWrite = () => {
  const participantId = useSelector(state => state.firebase.auth.uid);
  const db = useFirestore();
  const { currentRound, ideas, rounds, topic, id } = useBrainwritingSelector();
  const localStorageKey = `brainWriting_${id}_currentSubmission`;
  const [isEditing, setIsEditing] = React.useState(false);
  const isFirstSubmission = currentRound === 1 || !includes(keys(ideas), participantId);
  const currentAssignment = rounds[currentRound].assignments[participantId];
  const noAvailbleIdeas = !isFirstSubmission && !currentAssignment;

  const currentAssignmentText = currentAssignment ? ideas[currentAssignment.idea].text : '';

  const currentStoredSubmission = localStorageGetObject(localStorageKey) ?? {
    currentRound,
    currentAssignmentText,
    currentSubmission: '',
  };

  React.useEffect(() => {
    if (currentRound !== currentStoredSubmission?.currentRound) {
      localStorageSetObject(localStorageKey, {
        currentRound,
        currentAssignmentText,
        currentSubmission: '',
      });
    }
  }, [currentRound]);

  if (noAvailbleIdeas) {
    return (
      <>
        <GrayBox className="mb-4">
          <Typography className="text-center">{topic}</Typography>
        </GrayBox>
        <Typography>
          No available ideas for you to contribute to on this round. Sit tight!
        </Typography>
      </>
    );
  }

  const handleEditClick = isEditingText => {
    setIsEditing(isEditingText);
    db.doc(`brainwriting/${id}`)
      .set(
        {
          rounds: {
            [currentRound]: {
              assignments: {
                [participantId]: {
                  submitted: isEditingText ? false : true,
                },
              },
            },
          },
        },
        { merge: true },
      )
      .then(() => {
        console.log('success');
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <>
      <GrayBox className="mb-8">
        <Typography className="text-center">{topic}</Typography>
      </GrayBox>
      {currentAssignmentText && (
        <GrayBox className="mb-4 w-100 current-assignment-text">
          <div className="ideaWrapper">
            <Typography
              style={{ whiteSpace: 'nowrap' }}
              className="font-italic text-left white-space-pre-wrap ck-content"
              dangerouslySetInnerHTML={renderHtmlStringSimple(
                isEditing ? currentStoredSubmission.currentAssignmentText : currentAssignmentText,
              )}
            />
            {currentStoredSubmission.currentSubmission != '' && !isEditing && (
              <IconButton onClick={() => handleEditClick(true)} style={{ alignSelf: 'start' }}>
                <EditIcon />
              </IconButton>
            )}
          </div>
        </GrayBox>
      )}
      {isEditing && (
        <BrainwritingParticipantEdit
          setIsEditing={setIsEditing}
          handleEditClick={handleEditClick}
        />
      )}
      {!isEditing && <BrainwritingParticipantWriteForm />}
    </>
  );
};

export default BrainwritingParticipantWrite;
