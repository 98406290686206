import { enqueueErrorSnackbar } from 'redux/actions/notifierActions';
import { getSessionData } from 'utils/getSessionData';

/**
 * Add participant Feedback sentiment
 *
 * @param {String} sessionId
 * @param {String} participantId
 * @param {String} sentiment
 */
export const updateParticipantSentiment =
  (sessionId, participantId, sentiment, sendNotifications) =>
  async (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;
    let currentFeedback = getState()?.firestore?.data?.session?.feedback;
    if (!currentFeedback) {
      currentFeedback = await getSessionData(sessionId);
    }
    const notificationsAlreadySent = currentFeedback[participantId]?.isSendNotification;

    db()
      .doc(`sessions/${sessionId}`)
      .set(
        {
          feedback: {
            [participantId]: {
              participantId,
              sentiment: sentiment ? sentiment : '',
            },
          },
        },
        { merge: true },
      )
      .then(() => {
        if (!notificationsAlreadySent) {
          sendNotifications(sentiment);
        }
      })
      .catch(err => {
        dispatch(enqueueErrorSnackbar('Error update Feedback sentiment'));
        console.error(err);
      });
  };

/**
 * Add participant Feedback
 *
 * @param {String} sessionId
 * @param {String} participantId
 * @param {String} typeFeedback
 * @param {String} value
 */
export const updateParticipantFeedback =
  (sessionId, participantId, typeFeedback, value) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;
    db()
      .doc(`sessions/${sessionId}`)
      .set(
        {
          feedback: {
            [participantId]: {
              participantId,
              [typeFeedback]: value,
            },
          },
        },
        { merge: true },
      )
      .catch(err => {
        dispatch(enqueueErrorSnackbar('Error update Feedback'));
        console.error(err);
      });
  };

/**
 * Sends email notifications to facilitators when sentiment is set
 *
 * @param {string} participantName
 * @param {string} meetingId
 * @param {string} meetingName
 * @param {string} feedbackId
 * @param {string} sentiment
 * @param {string} meetingLink
 * @param {array} facilitatorEmails
 */
export const sendFacilitatorNotifications =
  (
    participantId,
    participantName,
    meetingId,
    meetingName,
    feedbackId,
    sentiment,
    meetingLink,
    facilitatorEmails,
  ) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;
    const batch = db().batch();

    facilitatorEmails.forEach(facilitatorEmail => {
      const emailNotifRef = db().collection('emailNotifications').doc();

      batch.set(emailNotifRef, {
        createdAt: db.FieldValue.serverTimestamp(),
        participantName,
        meetingId,
        meetingName,
        feedbackId,
        sentiment,
        meetingLink,
        to: facilitatorEmail,
        type: 'feedbackSentiment',
      });
    });

    batch.set(
      db().doc(`sessions/${meetingId}`),
      {
        feedback: {
          [participantId]: {
            isSendNotification: true,
          },
        },
      },
      { merge: true },
    );

    batch.commit().catch(err => {
      console.error(err);
    });
  };
