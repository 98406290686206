import React from 'react';
import { includes, map, sortBy, filter, find } from 'lodash';
import { useSelector } from 'react-redux';
// library components
import { Box, makeStyles } from '@material-ui/core';
// cr components
import JourneyVote from 'components/UserJourneyMoments/Participant/JourneyVote';
import CSVReportDownloadButton from 'components/CSVReportDownloadButton';
import AdminActivityAuxView from 'components/AdminActivityAuxView';
// selectors
import {
  useUserJourneyMomentsSelector,
  getSuperVotes,
} from 'redux/selectors/userJourneyMomentsSelectors';
// utils
import {
  createUserJourneyReportHeader,
  createUserJourneyReportData,
} from 'utils/userJourneyMoments';

const useStyles = makeStyles(theme => ({
  cardSuperVoted: {
    background: '#F0FEE5',
    border: `3px solid ${theme.palette.success.main}`,
  },
}));

/**
 * Renders journeys actor and key moment for the 'vote', 'review' and 'results' phases on the Facilitator side
 */
const JourneyVoteReview = () => {
  const classes = useStyles();
  const userJourneyMoments = useUserJourneyMomentsSelector();
  const { participantData, state, name } = userJourneyMoments;

  const session = useSelector(state => state.firestore.data.session);
  const superVoteTypes = useSelector(getSuperVotes());

  if (!state?.activeView || !includes(['vote', 'review', 'results'], state.activeView)) {
    return null;
  }

  const sortedJourneys = sortBy(participantData.journeys, 'participantId');

  // render headers for csv report
  const headers = createUserJourneyReportHeader();

  // render data for csv report
  const csvReport = createUserJourneyReportData(sortedJourneys, superVoteTypes);

  const superVote = {};
  const isActorSelect = find(superVoteTypes, voteType => voteType.type === 'actor');
  const isMomentSelect = find(superVoteTypes, voteType => voteType.type !== 'actor');

  if (state.activeView === 'results') {
    const actor = find(sortedJourneys, journey => journey.participantId === isActorSelect?.id);
    const moment = find(sortedJourneys, journey => journey.participantId === isMomentSelect?.id);
    superVote['participantId'] = actor?.participantId;
    superVote['actor'] = actor?.values.actor;
    superVote['moment'] = moment?.values[isMomentSelect.type];
  }

  const renderTitleText = () => {
    switch (state.activeView) {
      case 'vote':
        return 'Journey Review';
      case 'review':
        return 'Journey Voting Results';
      case 'results':
        return 'Decider Voting Results';
      default:
        return '';
    }
  };

  return (
    <AdminActivityAuxView
      titleText={renderTitleText()}
      allowPrint={state.activeView === 'results'}
      activityName={name}
      hasDownloadButton={state.activeView === 'results'}
      renderDownloadButton={() => (
        <CSVReportDownloadButton
          headers={headers}
          csvReport={csvReport}
          buttonText="Download Results"
          filename="Results.csv"
          topic={session.topic}
          activity="UserJourneyMoments"
          activityName={name}
          withoutLabel={true}
        />
      )}
    >
      <Box
        className="w-100 mb-2 mt-3 ml-3"
        display="flex"
        alignItems="center"
        justifyContent={state.activeView === 'results' ? 'center' : 'start'}
        flexWrap="wrap"
      >
        {state.activeView === 'results' ? (
          <JourneyVote
            journeyId={superVote.participantId}
            keyMoment={isMomentSelect?.type}
            keyMomentTitle={superVote.moment}
            actor={superVote.actor}
            type="actor"
            context={state.activeView}
            isFinished={true}
            momentVotesCount={isMomentSelect ? 1 : 0}
            actorVotesCount={isActorSelect ? 1 : 0}
            superVoteTypes={superVoteTypes}
            superVotedClassName={classes.cardSuperVoted}
          />
        ) : (
          map(sortedJourneys, (journey, idx) => {
            const momentVotes = filter(
              journey.votedParticipants,
              vote => vote.moment && vote.moment.isVoted,
            );
            const actorVotes = filter(
              journey.votedParticipants,
              vote => vote.actor && vote.actor.isVoted,
            );
            if (journey.keyMoment && journey.values?.actor) {
              return (
                <JourneyVote
                  key={`journey-${idx}`}
                  journeyId={journey.participantId}
                  keyMoment={journey.keyMoment}
                  keyMomentTitle={journey.values ? journey.values[journey.keyMoment] : ''}
                  actor={journey.values?.actor}
                  type="actor"
                  context={state.activeView}
                  isFinished={true}
                  momentVotesCount={state.activeView === 'review' ? momentVotes.length : 0}
                  actorVotesCount={state.activeView === 'review' ? actorVotes.length : 0}
                />
              );
            }
          })
        )}
      </Box>
    </AdminActivityAuxView>
  );
};

export default JourneyVoteReview;
