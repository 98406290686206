import React from 'react';
import { filter, map, sortBy, size, find } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
// library components
import { Box, makeStyles, useTheme, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
// cr components
import JourneyVote from 'components/UserJourneyMoments/Participant/JourneyVote';
import DottedProgress from 'components/DottedProgress';
// selectors
import {
  useUserJourneyMomentsSelector,
  getSuperVotes,
} from 'redux/selectors/userJourneyMomentsSelectors';
// actions
import { updateSuperVote } from 'redux/actions/userJourneyMomentsActions';

const useStyles = makeStyles(theme => ({
  cardSuperVoted: {
    background: '#F0FEE5',
    border: `3px solid ${theme.palette.success.main}`,
  },
  success: {
    color: theme.palette.success.main,
    fontSize: 14,
  },
  disabled: {
    color: grey[500],
    fontSize: 14,
  },
}));

/**
 * User Journey Moments view for the 'superVote' phase on the Participant side
 */
const UserJourneyMomentsParticipantSuperVote = () => {
  const maxSuperVotes = 2;
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const {
    id,
    participantData: { journeys },
    state: { activeView, superVoteParticipantId },
  } = useUserJourneyMomentsSelector();
  // sorted participants journey by participant id
  const sortedJourneys = sortBy(journeys, 'participantId');
  const superVoteTypes = useSelector(getSuperVotes());
  const superVoteTypesCount = size(superVoteTypes);

  const isActorSelect = find(superVoteTypes, voteType => voteType.type === 'actor');
  const isMomentSelect = find(superVoteTypes, voteType => voteType.type !== 'actor');

  const participantId = useSelector(state => state.firebase.auth.uid);

  const handleVote = (journeyId, type, isSuperVoted) => {
    if (
      isSuperVoted &&
      (maxSuperVotes === superVoteTypesCount ||
        (isActorSelect && type === 'actor') ||
        (isMomentSelect && type !== 'actor'))
    ) {
      return;
    }
    if (participantId === superVoteParticipantId) {
      dispatch(updateSuperVote(id, journeyId, type, isSuperVoted));
    }
  };

  // renders participants votes
  const rendersJourneyVote = () =>
    map(sortedJourneys, (journey, idx) => {
      // get votes for moment for current journey
      const momentVotes = filter(
        journey.votedParticipants,
        vote => vote.moment && vote.moment.isVoted,
      );
      // get votes for actor for current journey
      const actorVotes = filter(
        journey.votedParticipants,
        vote => vote.actor && vote.actor.isVoted,
      );
      const superVotes = filter(superVoteTypes, vote => vote.id === journey.participantId);
      if (journey.keyMoment && journey.values?.actor) {
        return (
          <JourneyVote
            key={`journey-${idx}`}
            journeyId={journey.participantId}
            keyMoment={journey.keyMoment}
            keyMomentTitle={journey.values?.[journey.keyMoment]}
            actor={journey.values?.actor}
            type="actor"
            context={activeView}
            isFinished={false}
            momentVotesCount={momentVotes.length}
            actorVotesCount={actorVotes.length}
            handleVote={handleVote}
            superVotedClassName={classes.cardSuperVoted}
            superVoteTypes={superVoteParticipantId === participantId ? superVotes : []}
          />
        );
      }
    });

  return (
    <>
      <Box
        className="w-100 mb-8"
        display="flex"
        alignItems="center"
        justifyContent="start"
        flexWrap="wrap"
      >
        {rendersJourneyVote()}
      </Box>
      {superVoteParticipantId === participantId && (
        <Box className="d-flex align-items-center flex-column">
          <DottedProgress
            className="ml-1"
            activeColor={theme.palette.success.main}
            totalCount={maxSuperVotes}
            activeCount={superVoteTypesCount}
          />
          <Typography
            className={cx(
              maxSuperVotes === superVoteTypesCount ? classes.success : classes.disabled,
            )}
          >
            {maxSuperVotes === superVoteTypesCount
              ? 'You’ve voted on Actor and Target Moment'
              : `the Actor ${isActorSelect ? 'was' : "wasn't"} voted, the Moment ${isMomentSelect ? 'was' : "wasn't"} voted`}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default UserJourneyMomentsParticipantSuperVote;
