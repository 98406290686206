import React from 'react';
import { useDispatch } from 'react-redux';
import cx from 'classnames';
// library components
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// cr components
import ActivityParticipantDetails from 'components/ActivityParticipantDetails';
import ActivityPromptAdmin from 'components/ActivityPromptAdmin';
import ActivityActionButton from 'components/CustomButtons/ActivityActionButton';
// selectors
import { useHeatMappingSelector } from 'redux/selectors/heatMappingSelectors';
// actions
import { updateActivityView } from 'redux/actions/heatMappingActions';
// config
import { HEAT_MAPPING_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';

const useStyles = makeStyles(theme => ({
  promptAdmin: {
    marginBottom: theme.spacing(5),
  },
  container: {
    minHeight: 220,
  },
}));

/**
 * Heat-Mapping view for the 'strawPollResults' phase on the Facilitator side
 */
const HeatMappingAdminStrawPollResults = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const heatMapping = useHeatMappingSelector();
  const { id, prompt } = heatMapping;

  const onUpdateActivityView = () => {
    dispatch(updateActivityView('superVote', id));
  };

  return (
    <Box className={cx(classes.container, 'd-flex flex-column justify-content-between')}>
      <ActivityPromptAdmin
        prompt={prompt}
        collection={CONFIG.collection}
        activityId={id}
        className={classes.promptAdmin}
      />
      <ActivityParticipantDetails noDetails headerText="Reviewing straw poll results." />
      <ActivityActionButton
        text="Start Super vote"
        className="mb-2 mt-1"
        onClick={onUpdateActivityView}
        delayReady
      />
    </Box>
  );
};

export default HeatMappingAdminStrawPollResults;
