import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { each, filter, head, sortBy } from 'lodash';
// library components
import { Box } from '@material-ui/core';
// cr components
import ActivityParticipantDetails from 'components/ActivityParticipantDetails';
import ActivityActionButton from 'components/CustomButtons/ActivityActionButton';
// selectors
import {
  useLightningDemoSelector,
  getParticipantsCompletedUpload,
} from 'redux/selectors/lightningDemoSelectors';
// actions
import {
  updateActivityView,
  addCurrentParticipantId,
  updateParticipantsCompletedReview,
  addCurrentDemoId,
  deleteLightningDemo,
} from 'redux/actions/lightningDemoActions';
// utils
import { makeParticipantDetailsHeaderText } from 'utils/makeParticipantDetailsHeaderText';

/**
 * Lightning Demo view for the 'upload' phase on the Facilitator side
 */
const LightningDemoAdminUpload = () => {
  const dispatch = useDispatch();
  const lightningDemo = useLightningDemoSelector();
  const {
    id,
    participantData: { participants, demos },
  } = lightningDemo;
  const participantsHaveUploaded = useSelector(getParticipantsCompletedUpload());
  const participantsReadyReview = filter(participantsHaveUploaded, { completed: true });

  const participantsUploadedCountText = makeParticipantDetailsHeaderText(
    participantsReadyReview.length,
    participantsHaveUploaded.length,
    'added demos',
  );

  const removeAllEmptyDemos = () => {
    each(demos, demo => {
      if (!demo.imageId) {
        dispatch(deleteLightningDemo(id, demo.demoId));
      }
    });
  };

  const onUpdateActivityView = () => {
    // get participant id for review
    const currentParticipantId = participants ? head(participantsReadyReview)?.id : '';
    const participantDemos = filter(demos, demo => demo.participantId === currentParticipantId);
    // get participant demo id for review
    const currentParticipantDemo = head(sortBy(participantDemos, 'order'));
    if (currentParticipantId) {
      dispatch(addCurrentParticipantId(id, currentParticipantId));
      dispatch(updateParticipantsCompletedReview(id, currentParticipantId));
      const demoId = currentParticipantDemo ? currentParticipantDemo.demoId : '';
      dispatch(addCurrentDemoId(id, demoId));
    }
    dispatch(updateActivityView('review', id, removeAllEmptyDemos));
  };

  return (
    <Box className="d-flex flex-column justify-content-center mt-8 mb-5">
      <ActivityParticipantDetails
        headerText={participantsUploadedCountText}
        hasCompletedLabel="Added Demo"
        hasntCompletedLabel="Hasn’t Added Demo"
        completedCountMax={4} // There are a total of 4 demos allowed, so that's the max they can add. This should probably be a constant somewhere.
        participantsCompletedStatus={participantsHaveUploaded}
      />
      <ActivityActionButton text="Start Review" className="mb-2" onClick={onUpdateActivityView} />
    </Box>
  );
};

export default LightningDemoAdminUpload;
