import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// library components
import { Paper, Box, Typography, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
// cr components
import ButtonSpinner from 'components/ButtonSpinner/ButtonSpinner';

const useImageStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: ({ isSmallScreen }) => (isSmallScreen ? '100%' : 340),
    height: 340,
    padding: ({ isSmallScreen }) => (isSmallScreen ? 10 : 0),
    backgroundColor: '#EDF5F8',
    border: '1px dashed #C0CFDC',
    marginRight: ({ isSmallScreen }) => (isSmallScreen ? 0 : 20),
    marginBottom: 30,
  },
  title: {
    width: '100%',
    lineHeight: '40px',
    paddingLeft: 30,
  },
  image: {
    width: '100%',
    height: '100%',
    maxWidth: 715,
    maxHeight: 550,
    objectFit: 'contain',
  },
  imageBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: ({ isSmallScreen }) => (isSmallScreen ? '100%' : 290),
    height: 235,
    minHeight: 150,
    marginTop: theme.spacing(2),
    background: 'rgba(183, 194, 200, 0.3)',
  },
}));

/**
 * Image box view on the Participant side
 */
const Image = ({ src, name, handleDeleteImage, isLoading }) => {
  const isSmallScreen = useMediaQuery('(max-width: 767px)');
  const imageClasses = useImageStyles({ isSmallScreen });

  return (
    <Paper className={imageClasses.container} variant="outlined">
      <Box display="flex" alignItems="center" justifyContent="center" className="w-100">
        <Typography className={imageClasses.title} align="center" color="primary">
          {name}
        </Typography>
        <IconButton aria-label="delete" color="primary" onClick={() => handleDeleteImage(src)}>
          <DeleteIcon />
        </IconButton>
      </Box>
      <Box className={imageClasses.imageBox}>
        {isLoading ? (
          <ButtonSpinner />
        ) : (
          <img className={imageClasses.image} src={src} alt="heat-mapping" />
        )}
      </Box>
    </Paper>
  );
};

Image.propTypes = {
  src: PropTypes.string, // image url
  name: PropTypes.string, // image name
  handleDeleteImage: PropTypes.func, // function for delete image
};

export default Image;
