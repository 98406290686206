import React from 'react';
import { sortBy, map, reduce, each } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
// library components
import { Typography, Box } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
// cr components
import Flow from 'components/NoteAndMap/Participant/Flow';
// selectors
import {
  useNoteAndMapSelector,
  getParticipantVotes,
  getParticipantsFlows,
} from 'redux/selectors/noteAndMapSelectors';
// actions
import { updateParticipantVote } from 'redux/actions/noteAndMapActions';

const useStyles = makeStyles(theme => ({
  success: {
    color: theme.palette.success.main,
    fontSize: 14,
  },
  disabled: {
    color: grey[500],
    fontSize: 14,
  },
}));

/**
 * Note And Map view for the 'vote' phase on the Participant side
 */
const NoteAndMapParticipantVote = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    id,
    config: { maxVotes },
    state: { activeView },
  } = useNoteAndMapSelector();
  const participantId = useSelector(state => state.firebase.auth.uid);

  const participantsFlows = useSelector(getParticipantsFlows());
  const participantVotedMoments = useSelector(getParticipantVotes(participantId));
  const sortedFlows = sortBy(participantsFlows, 'participantId');

  const countSelectedMoments = reduce(
    participantVotedMoments,
    (acc, moment) => {
      each(moment, value => {
        if (value) {
          acc += 1;
        }
      });
      return acc;
    },
    0,
  );

  // select key moment
  const handleSelectField = (journeyId, type, isVoted) => {
    const fieldValue = participantsFlows[journeyId].types[type]?.value;
    const isEmpty = !fieldValue || fieldValue === '';
    if ((isVoted && maxVotes === countSelectedMoments) || isEmpty) {
      return;
    }
    if (type !== 'actor') {
      dispatch(updateParticipantVote(id, participantId, journeyId, type, isVoted));
    }
  };

  const renderFlow = (flow, idx, flowParticipantId) => (
    <Flow
      key={`moment-${idx}`}
      participantFlow={flow}
      context={activeView}
      disabled={true}
      handleVote={handleSelectField}
      createdFlowParticipantId={flowParticipantId}
    />
  );

  return (
    <>
      {map(sortedFlows, (flow, idx) => renderFlow(flow.types, idx, flow.participantId))}
      <Box className="d-flex align-items-center flex-column">
        <Typography className={countSelectedMoments > 0 ? classes.success : classes.disabled}>
          {countSelectedMoments > 0
            ? 'You’ve voted on moments. Vote on any that are essential.'
            : `Click on a moment to add or remove a vote.`}
        </Typography>
      </Box>
    </>
  );
};

export default NoteAndMapParticipantVote;
