import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { map, keys, sortBy } from 'lodash';
// library components
import { Typography, Box, makeStyles } from '@material-ui/core';
// cr components
import Question from 'components/Scorecard/Participant/Question';
import Note from 'components/Scorecard/Participant/Note';
import NewNote from 'components/Scorecard/Participant/NewNote';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
// selectors
import {
  useScorecardSelector,
  getParticipantAnswersSelector,
  getParticipantNotesSelector,
  getParticipantCommentsSelector,
} from 'redux/selectors/scorecardSelectors';
// actions
import {
  addParticipantAnswer,
  addParticipantNoteAction,
  removeParticipantAnswer,
  removeParticipantNoteAction,
  addParticipantComment,
  removeParticipantComment,
} from 'redux/actions/scorecardActions';

const useStyles = makeStyles({
  questionTitle: {
    fontSize: 11,
  },
  testetTitle: {
    textAlign: 'center',
    fontWeight: 300,
    fontSize: 20,
  },
  grid: {
    width: 'calc(100% + 20px)',
    margin: '0 -10px',
  },
  gridItem: {
    padding: `0 10px !important`,
  },
});

/**
 * Scorecard view for the 'interview' phase on the Participant side
 */
const ScorecardParticipantInterview = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const participantId = useSelector(state => state.firebase.auth.uid);
  const {
    id,
    config: { sprintQuestions, prototypeQuestions, testers },
    state: { currentTester },
  } = useScorecardSelector();
  // sort sprint questions by value and id
  const sortedSprintQuestions = sortBy(sprintQuestions, ['order', 'value']);
  // sort prototype questions by value and id
  const sortedPrototypeQuestions = sortBy(prototypeQuestions, ['order', 'value']);
  // get the participant answers for current tester
  const participantAnswers = useSelector(
    getParticipantAnswersSelector(currentTester, participantId),
  );
  // get the sort participant notes for current tester
  const participantNotes = useSelector(getParticipantNotesSelector(participantId, currentTester));
  // get the participant comments for current tester
  const participantComments = useSelector(
    getParticipantCommentsSelector(currentTester, participantId),
  );

  // the participant select answer
  const handleParticipantSelectAnswer = (questionId, value) => {
    dispatch(addParticipantAnswer(id, currentTester, participantId, questionId, value));
  };

  // the participant select answer, which was selected
  const handleParticipantRemoveAnswer = questionId => {
    dispatch(removeParticipantAnswer(id, currentTester, participantId, questionId));
  };

  // the participant select action for note
  const handleSelectNoteAction = (noteId, action) => {
    dispatch(addParticipantNoteAction(id, participantId, noteId, action));
  };

  // the participant select action for note, which was selected
  const handleRemoveNoteAction = noteId => {
    dispatch(removeParticipantNoteAction(id, participantId, noteId));
  };

  // the participant add comment
  const handleParticipantAddComment = (questionId, value) => {
    dispatch(addParticipantComment(id, currentTester, participantId, questionId, value));
  };

  // remove the participant comment
  const handleParticipantRemoveComment = questionId => {
    dispatch(removeParticipantComment(id, currentTester, participantId, questionId));
  };

  // renders questions
  const renderQuestions = sortedQuestions => {
    const questionIds = keys(sortedQuestions);
    return map(questionIds, (sprintQuestion, idx) => {
      const question = sortedQuestions[sprintQuestion];
      const answer = participantAnswers?.[question.id];
      const comment = participantComments?.[question.id];
      return (
        <Question
          key={`question-${idx}`}
          number={idx + 1}
          question={question.value}
          participantAnswer={answer}
          questionId={question.id}
          handleParticipantSelectAnswer={handleParticipantSelectAnswer}
          handleParticipantRemoveAnswer={handleParticipantRemoveAnswer}
          handleParticipantAddComment={handleParticipantAddComment}
          handleParticipantRemoveComment={handleParticipantRemoveComment}
          comment={comment}
        />
      );
    });
  };

  // renders notes
  const renderNotes = () =>
    map(participantNotes, (note, idx) => (
      <GridItem
        xs={12}
        sm={6}
        md={3}
        lg={3}
        xl={3}
        key={`note-${idx}`}
        className={classes.gridItem}
      >
        <Note
          note={note}
          action={note.participantsActions?.[participantId]?.action}
          handleSelectNoteAction={handleSelectNoteAction}
          handleRemoveNoteAction={handleRemoveNoteAction}
        />
      </GridItem>
    ));

  return (
    <>
      <Box display="flex" flexDirection="column" className="w-100 mb-5">
        <Typography className={classes.testetTitle}>{testers[currentTester]?.value}</Typography>
        <Typography className={classes.questionTitle} variant="overline" color="textSecondary">
          Sprint Questions
        </Typography>
        {renderQuestions(sortedSprintQuestions)}
      </Box>
      <Box display="flex" flexDirection="column" className="w-100 mb-5">
        <Typography className={classes.questionTitle} variant="overline" color="textSecondary">
          Prototype Questions
        </Typography>
        {renderQuestions(sortedPrototypeQuestions)}
      </Box>
      <Box display="flex" flexDirection="column" className="w-100">
        <Typography className={classes.questionTitle} variant="overline" color="textSecondary">
          Insights
        </Typography>
        <GridContainer direction="row" spacing={3} className={classes.grid}>
          <GridItem xs={12} sm={6} md={3} lg={3} xl={3} className={classes.gridItem}>
            <NewNote order={participantNotes.length} />
          </GridItem>
          {renderNotes()}
        </GridContainer>
      </Box>
    </>
  );
};

export default ScorecardParticipantInterview;
