import React from 'react';
import { useDispatch } from 'react-redux';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

import { toggleAdminImageReorder } from 'redux/actions/heatMappingActions';

// selectors
import { useHeatMappingSelector } from 'redux/selectors/heatMappingSelectors';

const useStyles = makeStyles(theme => ({
  reorder: {
    color: '#093B75',
    fontSize: '14px',
    fontWeight: 'normal',
    textTransform: 'initial !important',
  },
}));

export default function ToggleImageReorder({ id }) {
  const heatMapping = useHeatMappingSelector();
  const dispatch = useDispatch();
  const isReordering = heatMapping?.state?.isReordering || false;
  const classes = useStyles();

  // Manage visibiility of admin reorder images
  const toggleReorder = () => {
    dispatch(toggleAdminImageReorder(id));
  };

  return (
    <Button onClick={toggleReorder}>
      <span className={classes.reorder}>{isReordering ? '< Exit Reorder' : 'Reorder Images'}</span>
    </Button>
  );
}
