import { range, flatMap, isEmpty, random } from 'lodash';

export function assignIdeas({ ideas, voteAssignments }) {
  return range(1, 6).reduce((rounds, currentRound) => {
    let assignments = [];

    let count = 0;
    // it can happen that a participant is left with no idea
    // Ex.:
    // participants: 1 2 3 4 5 6
    //                x x x x
    // ideas:        1 2 3 4 5 6
    // in this case participant 6 can't take the idea because he can't take his own, and all others are occupied.
    while (assignments.length !== ideas.length && count < 5) {
      assignments = ideaAssignments(ideas, currentRound, rounds);
      count++;
    }

    return {
      ...rounds,
      [currentRound]: rounds[currentRound]
        ? [...rounds[currentRound], ...assignments]
        : [...assignments],
    };
  }, voteAssignments);
}

function ideaAssignments(ideas, currentRound, voteAssignments) {
  const currentRoundAssignments = {};
  const assignments = ideas.map(idea => {
    const participantId = idea.id;
    // TODO join conditions below
    // remove own idea from vote pool.
    const withoutOwn = ideas.filter(idea => idea.id !== participantId);

    // remove ideas that were already assigned to someone in this round.
    const withoutCurrentRoundAssignments = isEmpty(currentRoundAssignments)
      ? withoutOwn
      : withoutOwn.filter(idea => {
          return !currentRoundAssignments[idea.id];
        });

    // remove ideas that were assigned to current participant in other rounds.
    const withoutAlreadyAssigned = isEmpty(voteAssignments)
      ? withoutCurrentRoundAssignments
      : withoutCurrentRoundAssignments.filter(idea => {
          const existingAssignments = flatMap(Object.values(voteAssignments));
          return !existingAssignments.find(
            assignments =>
              assignments.ideaId === idea.id && assignments.participantId === participantId,
          );
        });

    // this means that there are no more ideas to choose from for current participant.
    if (!withoutAlreadyAssigned.length) {
      return null;
    }

    const ideaIdx = getRandomIdeaIndex(withoutAlreadyAssigned);
    const current = withoutAlreadyAssigned[ideaIdx];
    currentRoundAssignments[current.id] = true;

    return {
      participantId,
      ideaId: current.id,
      vote: 0,
    };
  });
  return assignments.filter(Boolean);
}

function getRandomIdeaIndex(ideaPool) {
  return random(0, ideaPool.length - 1);
}
