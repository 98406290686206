import { enqueueSnackbar, enqueueErrorSnackbar } from '../actions/notifierActions';

export const setParticipantRoomAssignments = (
  breakoutId,
  participantRoomAssignments,
  successCb,
  errCb,
) => {
  return (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore();

    db.doc(`breakouts/${breakoutId}`)
      .set(
        {
          participantRoomAssignments,
        },
        { merge: true },
      )
      .then(() => {
        if (successCb) successCb();
      })
      .catch(err => {
        if (errCb) errCb();
        enqueueErrorSnackbar('Error');
        console.log(err);
      });
  };
};

export const updateBreakout = (breakoutId, update, successCb, errCb) => {
  return (dispatch, getState, { getFirebase }) => {
    console.log('updateBreakout', { ...update });
    const db = getFirebase().firestore();

    db.doc(`breakouts/${breakoutId}`)
      .set(
        {
          ...update,
        },
        { merge: true },
      )
      .then(() => {
        if (successCb) successCb();
      })
      .catch(err => {
        if (errCb) errCb();
        enqueueErrorSnackbar('Error');
        console.log(err);
      });
  };
};
