import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import Box from '@material-ui/core/Box';
import { getFirebase } from 'react-redux-firebase';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import LoginForm from '../form';
import FormDialog from '../../../components/Dialogs/FormDialog';
import { saveLoginEmail, clearLoginEmail } from '../../../utils/login';
import ControlRoomLogo from './logo';

import './index.css';

const queryString = require('query-string');

const SignIn = ({ whitelist }) => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(null);
  const [urlEmail, setUrlEmail] = useState(null);
  const [showWhitelistError, setShowWhitelistError] = useState(false);
  const firebase = getFirebase();
  const db = firebase.firestore();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const params = queryString.parse(window.location.search);

  // Automatically sign the user in if they are using the 1-time login
  // link from registration
  useEffect(() => {
    const urlParamsEmail = params.email ? decodeURIComponent(params.email) : params.email;

    if (Boolean(urlParamsEmail)) {
      setUrlEmail(urlParamsEmail);
    }

    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      if (urlParamsEmail) {
        firebase
          .auth()
          .signInWithEmailLink(urlParamsEmail, window.location.href)
          .then(() => clearLoginEmail())
          .catch(e => {
            setUrlEmail(null);
            console.error(e);
            Sentry.captureException(e);

            let message = e.message;

            if (e.code === 'auth/network-request-failed') {
              message +=
                ' If your internet connection is currently active, your network administrator may be blocking requests to googleapis.com/identitytoolkit';
            }

            enqueueSnackbar(message, {
              variant: 'error',
              autoHideDuration: 10000,
            });

            history.push('/auth/login-page/');
          });
      }
    }
  }, []);

  const checkWhitelist = async email => {
    console.log('email', email);

    const snapshot = await db.collection('whitelist').where('email', '==', email).get();
    if (snapshot.docs && snapshot.docs.length) {
      return initiateSignIn(email);
    } else {
      setLoading(false);
      setShowWhitelistError(true);
    }
  };

  const redirectToSignUp = () => history.push('/auth/signup');

  const initiateSignIn = async email => {
    try {
      await firebase.functions().httpsCallable('users-loginWithEmail')({
        email,
        origin: window.location.origin,
        query: window.location.search,
      });

      saveLoginEmail(email);
      setLoading(false);
      setEmail(email);
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);

      let message = e.message;

      if (e.code === 'auth/network-request-failed') {
        message +=
          ' If your internet connection is currently active, your network administrator may be blocking requests to googleapis.com/identitytoolkit';
      }

      enqueueSnackbar(message, { variant: 'error', autoHideDuration: 10000 });
      setLoading(false);
    }
  };

  // 1-time login link from registration email
  if (Boolean(urlEmail) && firebase.auth().isSignInWithEmailLink(window.location.href)) {
    return (
      <div className="login-header">
        <h6 className="text-center signInHeader">Logging in...</h6>
      </div>
    );
  }

  return (
    <div>
      <div className="logoWrapper">
        <ControlRoomLogo />
      </div>
      <div className="login-header">
        <h6 className="signInHeader mb-0">
          {email ? 'Sign-in email sent' : 'Request a Login Link'}
        </h6>
        {!email && (
          <Typography className="text-center" style={{ maxWidth: 300, margin: '0 auto' }}>
            Enter your email address and we'll send you a login link
          </Typography>
        )}
      </div>
      <div>
        {!Boolean(email) ? (
          <>
            <LoginForm
              loading={loading}
              loginButtonLabel="Submit"
              onSubmit={values => {
                setLoading(true);
                if (whitelist) {
                  return checkWhitelist(values.email);
                }
                return initiateSignIn(values.email);
              }}
            />
            {/* <Box className="text-center mt-2">
              <div className="signInContainer">
                <p className="signUpTxt">
                  Don’t have an account?
                  <Link to="/auth/signup" className="signUpBtn">
                    Sign Up
                  </Link>
                </p>
              </div>
            </Box> */}
          </>
        ) : (
          <div>
            <div>
              <Typography variant="body1">
                A Login Link with additional instructions was emailed to <strong>{email}</strong>.
                Be sure to check your promotions or spam folders.
                <br />
                <br />
                If you don't receive the email within a few minutes, contact{' '}
                <a href="mailto:hello@voltagecontrol.com">hello@voltagecontrol.com</a> for further
                instructions.
              </Typography>
            </div>
            <div className="login-back-container">
              <Button variant="contained" color="primary" onClick={() => setEmail(null)} fullWidth>
                Back
              </Button>
            </div>
          </div>
        )}
      </div>
      <FormDialog
        title=""
        isOpen={showWhitelistError}
        handleClose={() => setShowWhitelistError(false)}
      >
        <div style={{ textAlign: 'center' }}>
          <div style={{ marginBottom: 15 }}>
            <Typography className="login-sorry">Not Found</Typography>
          </div>
          <Typography variant="body1">
            You don't have an account. Sign ups are currently closed.
          </Typography>
          {/* <div style={{ marginTop: 50, marginBottom: 20 }}>
            <Hidden xsDown>
              <Button
                variant="contained"
                color="primary"
                onClick={redirectToSignUp}
                fullWidth
              >
                SIGN UP
              </Button>
            </Hidden>
            <Hidden smUp>
              <Button
                variant="contained"
                color="primary"
                onClick={redirectToSignUp}
                size="small"
                fullWidth
              >
                SIGN UP
              </Button>
            </Hidden>
          </div> */}
        </div>
      </FormDialog>
    </div>
  );
};

SignIn.propTypes = {
  // Whether to check the whitelist before initiating login
  whitelist: PropTypes.bool.isRequired,
};

SignIn.defaultProps = {
  whitelist: false,
};

export default SignIn;
