import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import { Draggable } from 'react-beautiful-dnd';
import { round } from 'lodash';

const pointRadius = 10;

const useStyles = makeStyles(theme => ({
  dot: {
    width: pointRadius * 2,
    height: pointRadius * 2,
    borderRadius: '50%',
    cursor: 'pointer',
    '&:hover': {
      background: 'rgba(36, 111, 196, 0.3)',
    },
  },
  centerDot: {
    position: 'absolute',
    top: 5,
    left: 5,
    width: pointRadius,
    height: pointRadius,
    borderRadius: '50%',
    backgroundColor: theme.palette.indigo.main,
    cursor: 'pointer',
    border: '1px solid white',
  },
  centerDotDisable: {
    cursor: 'default',
  },
  dotDisable: {
    cursor: 'default',
    '&:hover': {
      background: 'none',
    },
  },
}));

/**
 * Renders dragging point
 */
const DraggablePoint = ({
  id,
  disable,
  point,
  className,
  getElementId,
  coeff,
  coeffHeight,
  idx,
}) => {
  const classes = useStyles();

  const getStyle = (style, snapshot) => {
    if (snapshot.isDragging && !snapshot.dropAnimation) {
      return {
        ...style,
        borderRadius: '50%',
        backgroundColor: 'rgba(36, 111, 196, 0.3)',
      };
    }
    if (!snapshot.isDropAnimating) {
      return {
        ...style,
        position: 'absolute',
        top: round(point.top / coeffHeight),
        left: round(point.left / coeff),
      };
    }
    return {
      ...style,
      transitionDuration: `0.001s`,
    };
  };

  return (
    <Draggable
      isDragDisabled={disable}
      draggableId={`${point.top + point.left}`}
      index={idx}
      className={cx(classes.box, className)}
    >
      {(provided, snapshot) => (
        <div
          id={`point-${point.top}-${point.left}`}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getStyle(provided.draggableProps.style, snapshot)}
        >
          <div
            id={`point-${point.top}-${point.left}`}
            className={cx(classes.dot, disable && classes.dotDisable)}
            onMouseDown={() => {
              getElementId(id);
            }}
          >
            <div className={cx(classes.centerDot, disable && classes.centerDotDisable)} />
          </div>
        </div>
      )}
    </Draggable>
  );
};

DraggablePoint.propTypes = {
  id: PropTypes.string.isRequired, // draggable element id
  disable: PropTypes.bool,
  point: PropTypes.shape({
    top: PropTypes.number, // point top position
    left: PropTypes.number, // point left position
  }),
  className: PropTypes.string,
  getElementId: PropTypes.func, // function for getting id of draggable element
  coeff: PropTypes.number, // coefficient for heat-mapping dots
};

export default DraggablePoint;
