import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { map, find, sortBy, filter, includes, has, head, size } from 'lodash';
import { DragDropContext } from 'react-beautiful-dnd';
import { makeStyles } from '@material-ui/core/styles';
// library components
import { Box } from '@material-ui/core';
// cr components
import Category from './Category';
// actions
import {
  addParticipantVote,
  removeParticipantVote,
  swapParticipantVote,
} from 'redux/actions/noteAndCategorizeAction';
// selectors
import {
  useNoteAndCategorizeSelector,
  getParticipantVotes,
  getCategorizedParticipantVotes,
} from 'redux/selectors/noteAndCategorizeSelectors';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    marginBottom: theme.spacing(2),
  },
}));

/**
 * Note & Categorize view for the 'vote' phase on the Participant side
 */
const NoteAndCategorizeParticipantVote = () => {
  const classes = useStyles();
  const participantId = useSelector(state => state.firebase.auth.uid);
  const {
    id,
    config: { categories, votesPerCategory, format, roundRobinShare },
    participantData: { notes },
    state: { serialRoundRobin },
  } = useNoteAndCategorizeSelector();
  const participantVoteNotes = useSelector(getParticipantVotes(participantId));
  const participantVotesByCategory = useSelector(getCategorizedParticipantVotes(participantId));
  const dispatch = useDispatch();

  let sortField = 'value';
  const sortedCategories = sortBy(
    map(categories, category => {
      sortField = has(category, 'order') ? 'order' : 'value';
      return category;
    }),
    sortField,
  );

  const currentCategories =
    roundRobinShare === 'on' && format === 'Serial'
      ? filter(sortedCategories, category => category.id === serialRoundRobin)
      : sortedCategories;

  const completedCategory = map(categories, category => ({
    id: category.id,
    voteCompleted: false,
  }));
  const allNotes = sortBy(
    map(notes, note => notes[note.noteId]),
    ['order', 'noteId'],
  );

  /**
   * Create all categories.
   */
  const renderCategories = () => {
    return map(currentCategories, (category, idx) => {
      const votedNotesForCategory = filter(allNotes, note => {
        if (note.categoryId === category.id && includes(note.votedFor, participantId)) {
          return note;
        }
      });
      const notesCount = filter(
        notes,
        note => note.categoryId === category.id && !note.isCombine,
      ).length;
      const votesCount = notesCount < votesPerCategory ? notesCount : +votesPerCategory;
      const currentCategory = find(completedCategory, { id: category.id });
      currentCategory.voteCompleted = votedNotesForCategory.length >= votesCount;
      return (
        <Category
          key={`${category.value}-${idx}`}
          id={category.id}
          droppableId={`${idx}`}
          value={category.value}
          notes={allNotes}
          participantVoteNotes={participantVoteNotes}
          context="vote"
          handleParticipantVote={handleParticipantVote}
          votesCount={votesCount}
          votesPerCategory={votesPerCategory}
          countVotedNotesForCategory={votedNotesForCategory.length}
          noteSize="small"
        />
      );
    });
  };

  /**
   * Submit one participant vote.
   */
  const handleParticipantVote = (noteId, isSelected) => {
    const noteCategory = notes[noteId].categoryId;

    if (
      votesPerCategory === '1' &&
      participantVoteNotes.length &&
      participantVotesByCategory[noteCategory].length
    ) {
      // dispatch(
      //   removeParticipantVote(id, , participantId)
      // );
      // dispatch(
      //   addParticipantVote(id, noteId, participantId)
      // );

      dispatch(
        swapParticipantVote(
          id,
          participantId,
          noteId,
          head(participantVotesByCategory[noteCategory]).noteId,
        ),
      );
    }

    if (isSelected) {
      // remove vote
      dispatch(removeParticipantVote(id, noteId, participantId));
    } else {
      // add vote
      if (
        find(
          completedCategory,
          category => category.id === notes[noteId].categoryId && category.voteCompleted === true,
        )
      ) {
        return;
      }
      dispatch(addParticipantVote(id, noteId, participantId));
    }
  };

  return (
    <DragDropContext>
      <Box classes={classes}>{renderCategories()}</Box>
    </DragDropContext>
  );
};

export default NoteAndCategorizeParticipantVote;
