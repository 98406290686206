/**
 * Returns a facilitator session type based on the url
 */
export const getFacilitatorSessionType = () => {
  if (window.location.pathname.split('/').includes('past-sessions')) {
    return 'past-sessions';
  }

  if (window.location.pathname.split('/').includes('shared-sessions')) {
    return 'shared-sessions';
  }

  return 'sessions';
};
