import React, { useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { includes } from 'lodash';
// library components
import { Dialog, Box, Typography } from '@material-ui/core';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { grey } from '@material-ui/core/colors';
// cr components
import DialogHeader from '../DialogHeader';
import DialogAction from '../DialogAction';
import UploadImage from './UploadImage';
// utils
import { maybeConvertFromHeic } from 'utils/maybeConvertFromHeic';

const useStyles = makeStyles(theme => ({
  paperWidthSm: {
    maxWidth: 970,
  },
  dialogTitle: {
    maxWidth: 970,
    width: 970,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttonSpinnerBox: {
    position: 'absolute',
    top: '45%',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  buttonSpinner: {
    color: theme.palette.primary.main,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 890,
    width: '100%',
    height: 500,
    padding: '40px 110px 10px',
    marginBottom: theme.spacing(4),
    borderRadius: 4,
    background: grey[200],
  },
  imageToRotate: {
    margin: 'auto',
    objectFit: 'contain',
    maxHeight: '45vh',
  },
  textPrompt: {
    display: 'flex',
    paddingTop: 10,
    color: grey[500],
    fontSize: 13,
    textAlign: 'center',
  },
  helpTagText: {
    fontSize: '13px', //Reduce the Font size to accommodate the long help text
  },
  newImageText: {
    fontSize: '13px', //Reduced the Font size to match the Help Tag
    paddingLeft: theme.spacing(1),
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
}));

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(5),
    paddingTop: theme.spacing(2),
  },
}))(MuiDialogContent);

const UploadImageDialog = ({ handleClose, isOpen, uploadCardImage }) => {
  const classes = useStyles();

  const imgRef = useRef(null);

  const [fileUrl, setFileUrl] = useState();

  //Keeps Track of when the user is done cropping and starts Rotating the Image...
  const [startsRotating, setStartsRotating] = useState(false);

  const [crop, setCrop] = useState({
    unit: '%',
    aspect: 7 / 8,
    width: 70,
  });

  // Participant upload new image. Read image file and return first file
  const onDrop = useCallback(async acceptedFiles => {
    if (includes(acceptedFiles[0].type, 'image')) {
      if (acceptedFiles && acceptedFiles.length > 0) {
        let imageBlob = await maybeConvertFromHeic(acceptedFiles[0]);

        if (!(imageBlob instanceof Blob)) {
          return;
        }

        const reader = new FileReader();
        reader.addEventListener('load', () => setFileUrl(reader.result));
        reader.readAsDataURL(imageBlob);
      }
      setStartsRotating(false); //When a new Image is dropped, back to the Crop section
    }
  }, []);

  // Passes the image DOM element
  const onImageLoaded = image => {
    imgRef.current = image;
  };

  // Set new crop if he changed
  const onCropChange = newCrop => {
    setCrop(newCrop);
  };

  /**
   * Get croped image in blob format
   *
   * @param {HTMLImageElement} image - Image File Object
   * @param {Object} crop - crop Object
   * @param {String} fileName - Name of the returned file in Promise
   */
  const getCroppedImg = async (image, crop, fileName) => {
    const canvas = document.createElement('canvas');

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const pixelRatio = 2;
    canvas.width = (crop.width ? crop.width : image.width) * pixelRatio;
    canvas.height = (crop.height ? crop.height : image.height) * pixelRatio;
    const ctx = canvas.getContext('2d');

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);

    ctx.drawImage(
      image,
      (crop.width ? crop.x : 0) * scaleX,
      (crop.height ? crop.y : 0) * scaleY,
      (crop.width ? crop.width : image.width) * scaleX,
      (crop.height ? crop.height : image.height) * scaleY,
      0,
      0,
      crop.width ? crop.width : image.width,
      crop.height ? crop.height : image.height,
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        blob.name = fileName;
        resolve(URL.createObjectURL(blob)); //creates a URL to display the Cropped Image in the Rotate Section
      }, 'image/jpeg');
    });
  };

  const handleRotateUploadedImage = () => {
    if (!startsRotating) {
      return;
    }

    const TO_RADIANS = Math.PI / 180;
    const DEGREES_TO_ROTATE = 90;

    //Create an Image Object
    var img = new Image();

    //when the Image is loaded, rotate the Image...
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      //Sets the Canvas Dimensions to the Image Dimensions
      canvas.height = img.naturalWidth;
      canvas.width = img.naturalHeight;

      ctx.translate(canvas.width, 0);

      // Rotate the Canvas by converting from Degrees to Radians
      ctx.rotate(DEGREES_TO_ROTATE * TO_RADIANS);

      //Place the Image on the Rotated Canvas
      ctx.drawImage(img, 0, 0);

      canvas.toBlob(blob => {
        setFileUrl(URL.createObjectURL(blob)); //sets the Image Source to the Rotated Image
      });
    };
    img.src = fileUrl; //Set the Image Source to the FileUrl
  };

  // Close dialog. Update crop and image URL
  const handleCloseUploadImage = () => {
    handleClose();
    setFileUrl('');
    setCrop({
      unit: '%',
      aspect: 7 / 8,
      width: 70,
    });
  };

  //When in Crop Section, get the Cropped Image, and go to Rotate Section
  //When in Rotate Section, get the Blob from the FileURL to upload
  const handleConfirm = async () => {
    if (!fileUrl) {
      return;
    }

    if (!startsRotating) {
      const croppedImg = await getCroppedImg(imgRef.current, crop);
      setFileUrl(croppedImg);
      setStartsRotating(true);
    } else {
      let blob = await fetch(fileUrl).then(r => r.blob());
      uploadCardImage(blob);
      handleCloseUploadImage();
    }
  };

  const ImageEditor = !startsRotating ? (
    <ReactCrop
      src={fileUrl}
      crop={crop}
      onImageLoaded={onImageLoaded}
      onChange={onCropChange}
      imageStyle={{
        objectFit: 'contain',
        maxHeight: '45vh',
        backgroundColor: 'rgba(183, 194, 200, 0.3)',
      }}
      crossorigin="anonymous"
    />
  ) : (
    <img src={fileUrl} alt="" className={classes.imageToRotate} />
  );

  const ImageEditorHelpTag = !startsRotating
    ? 'Press and drag image to change crop area. Tap “Rotate” when done.'
    : 'Press the Rotate button to rotate the image. Tap “Save” when done.';

  return (
    <Box>
      <Dialog
        open={isOpen}
        classes={{
          paperWidthSm: classes.paperWidthSm,
        }}
      >
        <DialogHeader
          title="Edit Card Sets: Add Image"
          handleRotate={startsRotating && handleRotateUploadedImage} //Only Display the Rotate Icon when in Rotate Section
          handleClose={handleCloseUploadImage}
        />
        <DialogContent classes={classes.root} dividers>
          <Box className={classes.container}>
            {!fileUrl ? <UploadImage onDrop={onDrop} /> : ImageEditor}
            <Box className={classes.textPrompt}>
              <Typography className={classes.helpTagText}>
                {!fileUrl ? '.jpg or .png minimum size: 340w x 400h' : ImageEditorHelpTag}
              </Typography>
              {fileUrl && (
                <Typography
                  className={classes.newImageText}
                  onClick={() => {
                    setFileUrl('');
                  }}
                >
                  Select a different image
                </Typography>
              )}
            </Box>
          </Box>
          <DialogAction
            handleClose={handleCloseUploadImage}
            handleConfirm={handleConfirm}
            handleRotate={startsRotating && handleRotateUploadedImage} //Only Display the Rotate button when in Rotate Section
            confirmText={startsRotating ? 'Save' : 'Rotate'}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

UploadImageDialog.propTypes = {
  handleClose: PropTypes.func.isRequired, // close dialog
  isOpen: PropTypes.bool.isRequired, // is dialog open ?
  uploadCardImage: PropTypes.func.isRequired, // on submit form
};

export default UploadImageDialog;
