import React from 'react';
import Overlay from '../Overlay';
import Spinner from '../Spinner';

const OverlaySpinner = ({ fullscreen }) => {
  return (
    <Overlay fullscreen={fullscreen}>
      <Spinner />
    </Overlay>
  );
};

export default OverlaySpinner;
