import React from 'react';
import { useSelector } from 'react-redux';
import { map, reduce, find } from 'lodash';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
// library components
import { Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
// cr components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import NoteCard from './NoteCard';
import DottedProgress from 'components/DottedProgress';
// selectors
import {
  getNotCombinedNotes,
  useNoteAndVoteSelector,
  getParticipantVotes,
} from 'redux/selectors/noteAndVoteSelectors';

const useStyles = makeStyles(theme => ({
  successColor: {
    color: theme.palette.indigo.main,
  },
  grid: {
    width: 'calc(100% + 20px)',
    marginBottom: 0,
  },
  card: {
    padding: '0 10px 20px !important',
  },
}));

/**
 * Note & Vote view for the 'vote' phase on the Participant side
 */
const NoteAndVoteParticipantVote = () => {
  const participantId = useSelector(state => state.firebase.auth.uid);
  const {
    config: { voteLimit },
  } = useNoteAndVoteSelector();
  const submittedNotes = useSelector(getNotCombinedNotes());
  const participantVotes = useSelector(getParticipantVotes(participantId));
  const submittedVotesCount = participantVotes.length;
  const theme = useTheme();
  const classes = useStyles();
  const remainingVotes = voteLimit - submittedVotesCount;
  const remainingVotesText = remainingVotes === 1 ? 'vote' : 'votes';

  const renderSubmittedNotes = () =>
    map(submittedNotes, note => (
      <GridItem key={note.noteId} className={classes.card} xs={12} sm={6} md={3} lg={3} xl={3}>
        <NoteCard
          note={note}
          context="vote"
          numberCombineNotes={reduce(
            note.combineNotesId,
            (acc, noteId) => {
              const duplicateNote = find(
                submittedNotes,
                duplicateNote => duplicateNote.noteId === noteId,
              );
              acc += duplicateNote?.combineNotesId?.length
                ? duplicateNote.combineNotesId.length
                : 1;
              return acc;
            },
            0,
          )}
        />
      </GridItem>
    ));

  return (
    <>
      <GridContainer direction="row" className={classes.grid}>
        {renderSubmittedNotes()}
      </GridContainer>
      {!remainingVotes && <CheckCircleIcon className={cx(classes.successColor, 'mb-1')} />}
      <DottedProgress
        className="ml-1"
        activeColor={theme.palette.indigo.main}
        totalCount={voteLimit}
        activeCount={participantVotes.length}
      />
      <Typography
        className={cx(!remainingVotes && classes.successColor, 'mb-3')}
        color="textSecondary"
        variant="body2"
      >
        {remainingVotes
          ? `You have ${remainingVotes} ${remainingVotesText} left`
          : 'You’ve finished voting!'}
      </Typography>
    </>
  );
};

export default NoteAndVoteParticipantVote;
