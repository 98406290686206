import React from 'react';
import { useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';
import { map, includes, size, each, find, reduce, startCase } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
// library components
import { Box, Divider, Typography } from '@material-ui/core';
import Moment from 'components/Moment';
import { Droppable, Draggable } from 'react-beautiful-dnd';
// cr components
import DottedProgress from 'components/DottedProgress';
// selectors
import {
  useNoteAndMapSelector,
  getAllTypes,
  getParticipant,
} from 'redux/selectors/noteAndMapSelectors';

const useStyles = makeStyles(theme => ({
  flow: {
    width: '100%',
    position: 'relative',
    marginBottom: 20,
  },
  smallJourney: {
    flexDirection: 'column',
  },
  moment: {
    maxWidth: ({ smallScreen }) => (smallScreen ? '100%' : 170),
  },
  divider: {
    height: 170,
    marginLeft: 1,
  },
  votedMoment: {
    background: '#E5F6FE',
    border: `3px solid ${theme.palette.indigo.main}`,
  },
  removedMoment: {
    color: '#bdbdbd',
    background: '#f0f0f0',
    border: `3px solid #a8a8a8`,
  },
  dots: {
    position: 'absolute',
    top: -13,
    left: 7,
  },
  superVoted: {
    border: `3px solid ${theme.palette.success.main}`,
    backgroundColor: theme.palette.success.lightest,
  },
  typeBox: {
    flex: '1 1 170px',
    position: 'relative',
    display: 'flex',
  },
  author: {
    position: 'absolute',
    top: -18,
    left: 7,
  },
  gridItem: {
    padding: '0 5px !important',
    position: 'relative',
  },
}));

const getStyle = (style, snapshot) => {
  if (!snapshot.isDragging) return {};
  if (!snapshot.isDropAnimating) {
    return style;
  }

  return {
    ...style,
    transitionDuration: `0.001s`,
  };
};

const Flow = ({
  createdFlowParticipantId,
  participantFlow,
  handleCreateFlowField,
  handleChangeFlowField,
  context,
  disabled,
  handleVote,
  handleOpenCombineList,
  combineMoment,
  superVoteTypes,
  hasCombineList,
  isRemoveMoment,
}) => {
  const theme = useTheme();
  const smallScreen = useMediaQuery('(max-width: 760px)');
  const classes = useStyles({ smallScreen });

  const moments = Array.from({ length: 4 }, (_, i) => `Moment ${i + 1}`);

  const allTypes = useSelector(getAllTypes());

  const {
    participantData: { flows },
  } = useNoteAndMapSelector();

  const participantId = useSelector(state => state.firebase.auth.uid);

  const createdFlowParticipant = useSelector(getParticipant(createdFlowParticipantId));

  const labelDisabled = !includes(['vote', 'create', 'review'], context);

  // render notes for drag
  const renderDraggableMoments = (
    title,
    placeholder,
    type,
    fieldType,
    idx,
    isOpenCombineMoments,
  ) => {
    const momentId = type?.id;
    return (
      <Droppable
        droppableId={momentId}
        isCombineEnabled
        type="moments"
        key={fieldType}
        isDropDisabled={type?.isRemoved}
      >
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className={classes.typeBox}
            style={
              snapshot.draggingFromThisWith
                ? { height: 170, border: '1px dashed #CFCFCF', borderRadius: 4 }
                : {}
            }
          >
            <Draggable
              key={type?.id}
              draggableId={momentId}
              index={idx}
              isDragDisabled={type?.isRemoved}
            >
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  className="w-100"
                  style={getStyle(provided.draggableProps.style, snapshot)}
                >
                  {renderMoments(title, placeholder, type, fieldType, isOpenCombineMoments)}
                  {combineMoment?.id === momentId && renderCombineMoments()}
                </div>
              )}
            </Draggable>
            <div style={{ display: 'none' }}>{provided.placeholder}</div>
          </div>
        )}
      </Droppable>
    );
  };

  // render all moments
  const renderMoments = (title, placeholder, type, fieldType, isOpenCombineMoments) => {
    let isVoted =
      context === 'vote' ? includes(type?.votedFor || [], participantId) : size(type?.votedFor);
    const isMomentRemoved = type?.isRemoved;
    const isCombine = !!type?.parentMomentId;
    const momentId = type?.id;
    const isSuperVote = !!find(superVoteTypes, ({ id }) => id === momentId);
    const validValue = value =>
      isOpenCombineMoments
        ? value
        : (labelDisabled && !isVoted && fieldType !== 'actor') || isCombine
          ? ''
          : value;
    if (type?.combineMomentsId?.length) {
      isVoted = reduce(
        type.combineMomentsId,
        (acc, momentId) => {
          each(flows, flow =>
            each(flow?.types, (typeCombine, idx) => {
              if (typeCombine?.id === momentId) {
                acc += typeCombine?.votedFor ? typeCombine.votedFor.length : 0;
              }
            }),
          );
          return acc;
        },
        isVoted,
      );
    }

    // remove votes if moment is removed
    if (!isRemoveMoment && isMomentRemoved) {
      isVoted = false;
    }

    return (
      <Box className={classes.typeBox} key={fieldType}>
        {context !== 'vote' && !!isVoted && !isCombine && (
          <DottedProgress
            className={classes.dots}
            activeColor={isMomentRemoved ? '#a8a8a8' : theme.palette.indigo.main}
            totalCount={isVoted}
            activeCount={isVoted}
          />
        )}
        <Moment
          className={classes.moment}
          title={validValue(title)}
          placeholder={validValue(placeholder)}
          value={validValue(type?.value)}
          type={fieldType}
          handleCreateJourneyField={handleCreateFlowField}
          handleChangeJourneyField={handleChangeFlowField}
          context={context}
          isSmallScreen={smallScreen}
          disabled={disabled}
          handleVote={handleVote}
          journeyId={createdFlowParticipantId}
          isVoted={isCombine ? false : !!isVoted}
          votedClassName={
            isRemoveMoment && isMomentRemoved ? classes.removedMoment : classes.votedMoment
          }
          isCombine={!!type?.combineMomentsId?.length}
          combineCount={type?.combineMomentsId?.length + 1}
          handleOpenCombineList={handleOpenCombineList}
          isSuperVoted={isSuperVote}
          superVotedClassName={classes.superVoted}
          hasCombineList={hasCombineList}
          isRemoveMoment={isRemoveMoment}
          isRemoved={isMomentRemoved}
        />
        {fieldType === 'actor' && !smallScreen && (
          <Divider orientation="vertical" classes={{ root: classes.divider }} />
        )}
      </Box>
    );
  };

  // render moments in stack
  const renderCombineMoments = () => {
    return map(combineMoment.combineMomentsId, (combineMomentId, index) => {
      const moment = find(allTypes, types => types.id === combineMomentId);
      const momentType = moment?.type;
      return renderDraggableMoments(startCase(momentType), '', moment, momentType, index, true);
    });
  };

  return (
    <Box className={classes.flow}>
      {context !== 'create' && (
        <Typography className={classes.author}>
          {createdFlowParticipant?.name || 'anonym'}
        </Typography>
      )}
      <Box className="mb-6 d-flex" flexDirection={smallScreen ? 'column' : 'row'}>
        {renderMoments('Actor', 'Add Role', participantFlow?.actor, 'actor')}
        {map(moments, (moment, idx) => {
          const momentType = `moment-${idx + 1}`;
          if (!isRemoveMoment && participantFlow?.[momentType]?.isRemoved) {
            return renderMoments(moment, '', participantFlow?.[momentType], momentType, false);
          }
          if (context === 'voteResults' && participantFlow?.[momentType]?.votedFor?.length) {
            return renderDraggableMoments(
              moment,
              '',
              participantFlow?.[momentType],
              momentType,
              idx,
            );
          }
          return renderMoments(moment, '', participantFlow?.[momentType], momentType, false);
        })}
        {context === 'voteResults' && participantFlow?.end?.votedFor?.length
          ? !isRemoveMoment && participantFlow?.end?.isRemoved
            ? renderMoments('Outcome', 'Add Ending', participantFlow?.end, 'end')
            : renderDraggableMoments('Outcome', 'Add Ending', participantFlow?.end, 'end', 7)
          : renderMoments('Outcome', 'Add Ending', participantFlow?.end, 'end')}
      </Box>
    </Box>
  );
};

Flow.propTypes = {
  createdFlowParticipantId: PropTypes.string, // the participant id, who created current flow
  participantFlow: PropTypes.shape({
    actor: PropTypes.shape({
      value: PropTypes.string,
    }), // value of actor field
    'moment-1': PropTypes.object,
    'moment-2': PropTypes.object,
    'moment-3': PropTypes.object,
    'moment-4': PropTypes.object,
    end: PropTypes.object, // value of outcome field
  }),
  handleCreateFlowField: PropTypes.func, // function for creating values of flow fields
  handleChangeFlowField: PropTypes.func, // function for editing values of flow fields
  context: PropTypes.string.isRequired, // the active phase of state
  disabled: PropTypes.bool,
  handleVote: PropTypes.func, // function for voting
  isRemoveMoment: PropTypes.bool, // if true - users can remove moments
};

export default Flow;
