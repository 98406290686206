import React from 'react';
import PropTypes from 'prop-types';
import { useFirestore } from 'react-redux-firebase';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { merge } from 'lodash';
// library components
import { Formik, Form } from 'formik';
import Button from '@material-ui/core/Button';
// cr components
import ButtonSpinner from 'components/ButtonSpinner/ButtonSpinner';
import TextArea from 'components/CustomFormComponents/TextArea';
import TextInput from 'components/CustomFormComponents/TextInput';
// actions
import { enqueueErrorSnackbar } from 'redux/actions/notifierActions';
// config
import { FEEDBACK_CONFIG } from '@voltage-control/control-room-activities-config';

const formSchema = Yup.object().shape({
  likedLabel: Yup.string(),
  likedPlaceholder: Yup.string(),
  didntLikeLabel: Yup.string(),
  didntLikePlaceholder: Yup.string(),
  opportunityLabel: Yup.string(),
  opportunityPlaceholder: Yup.string(),
  outcomeLabel: Yup.string(),
  outcomePlaceholder: Yup.string(),
});

const FeedbackSettingsForm = ({ closeDialog }) => {
  const db = useFirestore();
  const dispatch = useDispatch();
  const session = useSelector(state => state.firestore.data.session);
  const feedbackConfig = merge(FEEDBACK_CONFIG.fields, session.feedbackConfig);

  return (
    <Formik
      initialValues={feedbackConfig}
      validationSchema={formSchema}
      onSubmit={async values => {
        return db
          .doc(`sessions/${session.id}`)
          .update({
            feedbackConfig: values,
          })
          .then(() => {
            closeDialog();
          })
          .catch(err => {
            dispatch(enqueueErrorSnackbar('Error saving Feedback settings'));
            console.error(err);
          });
      }}
    >
      {({ isSubmitting, submitForm }) => (
        <Form style={{ paddingBottom: 20 }}>
          <div className="mb-6">
            <TextArea name="likedLabel" rows={2} label="1st Question" required />
            <TextInput
              name="likedPlaceholder"
              type="text"
              label="1st Question - Answer Placeholder"
              required
            />
          </div>
          <div className="mb-6">
            <TextArea name="didntLikeLabel" rows={2} label="2nd Question" required />
            <TextInput
              name="didntLikePlaceholder"
              type="text"
              label="2nd Question - Answer Placeholder"
              required
            />
          </div>
          <div className="mb-6">
            <TextArea name="opportunityLabel" rows={2} label="3rd Question" required />
            <TextInput
              name="opportunityPlaceholder"
              type="text"
              label="3rd Question - Answer Placeholder"
              required
            />
          </div>
          <div>
            <TextArea name="outcomeLabel" rows={2} label="4th Question" required />
            <TextInput
              name="outcomePlaceholder"
              type="text"
              label="4th Question - Answer Placeholder"
              required
            />
          </div>

          <div className="form-dialog-buttons">
            <Button
              variant="outlined"
              color="primary"
              disabled={isSubmitting}
              onClick={closeDialog}
            >
              CANCEL
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={submitForm}
            >
              SAVE
              {isSubmitting && <ButtonSpinner />}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

FeedbackSettingsForm.propTypes = {
  closeDialog: PropTypes.func.isRequired,
};

export default FeedbackSettingsForm;
