import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const FeedbackAnswersPrint = ({ title, answers }) => {
  const renderAnswers = () =>
    map(answers, (answer, idx) => (
      <Box pt={1} key={`answer-print-${idx}`}>
        <Typography style={{ fontSize: 14 }}>
          <span style={{ fontSize: 16, fontWeight: 'bold' }}>&ldquo;</span>
          {answer}
          <span style={{ fontSize: 16, fontWeight: 'bold' }}>&ldquo;</span>
        </Typography>
      </Box>
    ));

  return (
    <Box pb={2}>
      <Typography style={{ fontWeight: '500', fontSize: 16 }}>{title}</Typography>
      {renderAnswers()}
    </Box>
  );
};

FeedbackAnswersPrint.defaultProps = {
  title: '',
};

FeedbackAnswersPrint.propTypes = {
  answers: PropTypes.array.isRequired,
  title: PropTypes.string,
};

export default FeedbackAnswersPrint;
