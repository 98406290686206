import { map, orderBy, max, each, join } from 'lodash';

/**
 * Creates the output array for a Polling actvity
 * @param {Object} notes
 * @returns {Array<{text: String, votes: Number}>}
 */
export const createOutput = notes => {
  // create initial output array
  const output = map(notes, note => ({
    text: note.value,
    votes: note.votedFor?.length || 0,
    winner: false,
  }));

  // create array of votes
  const votes = map(output, 'votes');

  // determine hights vote count
  const highVote = max(votes);

  // tag notes with the highest votes as 'winner'
  each(output, (note, idx) => {
    if (note.votes === highVote) {
      output[idx].winner = true;
    }
  });

  // sort array and return
  return orderBy(output, ['votes', 'text'], ['desc', 'asc']);
};

/**
 * Creates the array for Report Header
 *
 */
export const createPollingReportHeader = () => [
  { label: 'Options', key: 'options' },
  { label: 'Votes', key: 'votes' },
];

/**
 * Creates the data for Report
 *
 */
export const createPollingReportData = output => {
  const sortedOptions = orderBy(output, ['votes'], ['desc']);
  return map(sortedOptions, sortedOption => {
    return {
      votes: sortedOption.votes,
      option: sortedOption.text,
    };
  });
};

export const createPollingReport = activityData => {
  const headers = createPollingReportHeader();
  const data = createPollingReportData(activityData?.output);
  return { headers, data };
};
