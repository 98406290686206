import { enqueueErrorSnackbar } from 'redux/actions/notifierActions';
import { includes } from 'lodash';

import { generateRandomId } from 'utils/generateRandomId';
import { NOTE_AND_MAP_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';
import { createOutput } from 'utils/noteAndMap';

/**
 * Update the active view for the activity
 * @param {String} view
 * @param {String} noteAndMapId
 */
export const updateActivityView =
  (view, noteAndMapId) =>
  (dispatch, getState, { getFirebase }) => {
    if (!includes(CONFIG.views, view)) {
      console.error(`View '${view}' does not exist in the activity config`);
      return;
    }

    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${noteAndMapId}`)
      .set(
        {
          state: {
            activeView: view,
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error changing activity view'));
      });
  };

/**
 * Create participant moment/actor/outcome
 *
 * @param {String} noteAndMapId
 * @param {String} participantId
 * @param {String} fieldName
 * @param {String} value
 * @param {String} typeId
 */

export const createMoment =
  (noteAndMapId, participantId, fieldName, value) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;
    const id = generateRandomId();

    db()
      .doc(`${CONFIG.collection}/${noteAndMapId}`)
      .set(
        {
          participantData: {
            flows: {
              [participantId]: {
                participantId: participantId,
                types: {
                  [fieldName]: { value, id: id, votedFor: [] },
                },
              },
            },
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error create moment'));
      });
  };

export const updateMomentValue =
  (noteAndMapId, participantId, fieldName, value) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${noteAndMapId}`)
      .set(
        {
          participantData: {
            flows: {
              [participantId]: {
                participantId: participantId,
                types: {
                  [fieldName]: { value },
                },
              },
            },
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error update moment value'));
      });
  };

/**
 * Update participant vote moment/outcome
 *
 * @param {String} noteAndMapId
 * @param {String} participantId
 * @param {String} flowId
 * @param {String} type
 * @param {Boolean} isVoted
 */
export const updateParticipantVote =
  (noteAndMapId, participantId, flowId, type, isVoted) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;
    const votedFor = isVoted
      ? db.FieldValue.arrayUnion(participantId)
      : db.FieldValue.arrayRemove(participantId);

    db()
      .doc(`${CONFIG.collection}/${noteAndMapId}`)
      .set(
        {
          participantData: {
            flows: {
              [flowId]: {
                types: {
                  [type]: {
                    votedFor,
                  },
                },
              },
            },
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error update participant vote'));
      });
  };

/**
 * Update combine momentes
 * @param {String} noteAndMapId
 * @param {String} flowId
 * @param {String} combineFlowId
 * @param {String} combineNoteId
 * @param {String} type
 * @param {String} combineType
 * @param {String} momentId
 * @param {String} combineMomentId
 * @param {Boolean} isCombine
 */
export const updateCombineMoments =
  (noteAndMapId, flowId, combineFlowId, type, combineType, momentId, combineMomentId, isCombine) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;
    const combineMomentsId = isCombine
      ? db.FieldValue.arrayUnion(combineMomentId)
      : db.FieldValue.arrayRemove(combineMomentId);

    db()
      .doc(`${CONFIG.collection}/${noteAndMapId}`)
      .set(
        {
          participantData: {
            flows: {
              [`${flowId}`]: {
                types: {
                  [type]: { combineMomentsId },
                },
              },
              [`${combineFlowId}`]: {
                types: {
                  [combineType]: {
                    parentMomentId: isCombine ? momentId : '',
                  },
                },
              },
            },
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error combine moments'));
      });
  };

/**
 * Update super vote participant id
 *
 * @param {String} heatMappingId
 * @param {String} participantId
 */
export const updateSuperVoteParticipant =
  (noteAndMapId, participantId) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${noteAndMapId}`)
      .set(
        {
          state: {
            superVoteParticipantId: participantId,
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error update super vote note and map participant'));
      });
  };

/**
 * Update participant super vote actor/moment/outcome
 *
 * @param {String} noteAndMapId
 * @param {String} type
 * @param {String} typeId
 * @param {Boolean} isVoted
 */
export const updateSuperVote =
  (noteAndMapId, typeId, type, isVoted) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;
    const superVoteTypes = isVoted
      ? db.FieldValue.arrayUnion({ id: typeId, type })
      : db.FieldValue.arrayRemove({ id: typeId, type });
    db()
      .doc(`${CONFIG.collection}/${noteAndMapId}`)
      .set(
        {
          participantData: {
            superVoteTypes,
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error update participant vote'));
      });
  };

/**
 * Update combine momentes
 * @param {String} noteAndMapId
 * @param {String} flowId
 * @param {String} combineNoteId
 * @param {String} type
 * @param {String} combineType
 * @param {String} momentId
 * @param {String} combineMomentId
 * @param {Boolean} isCombine
 */
export const updateCombineMomentsForOneFlow =
  (noteAndMapId, flowId, type, combineType, momentId, combineMomentId, isCombine) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;
    const combineMomentsId = isCombine
      ? db.FieldValue.arrayUnion(combineMomentId)
      : db.FieldValue.arrayRemove(combineMomentId);

    db()
      .doc(`${CONFIG.collection}/${noteAndMapId}`)
      .set(
        {
          participantData: {
            flows: {
              [`${flowId}`]: {
                types: {
                  [type]: { combineMomentsId },
                  [combineType]: { parentMomentId: isCombine ? momentId : '' },
                },
              },
            },
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error combine moments for one flow'));
      });
  };

/**
 * Create the activity output and update the view to 'results'
 * @param {String} noteAndMapId
 */
export const revealResults =
  noteAndMapId =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;
    const state = getState();
    const { flows, superVoteTypes } = state.firestore.data.noteAndMap.participantData;
    const output = createOutput(flows, superVoteTypes);

    db()
      .doc(`${CONFIG.collection}/${noteAndMapId}`)
      .set(
        {
          output,
        },
        { merge: true },
      )
      .then(() => {
        dispatch(updateActivityView('results', noteAndMapId));
      })
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error generating results'));
      });
  };

/**
 * Remove moment/outcome
 *
 * @param {String} noteAndMapId
 * @param {String} flowId
 * @param {String} type
 * @param {Boolean} isRemoved
 */
export const removeMoment =
  (noteAndMapId, flowId, type, isRemoved) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${noteAndMapId}`)
      .set(
        {
          participantData: {
            flows: {
              [flowId]: {
                types: {
                  [type]: {
                    isRemoved,
                  },
                },
              },
            },
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error remove moment'));
      });
  };
