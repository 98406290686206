import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import { map, keys } from 'lodash';
import * as Sentry from '@sentry/react';
// library components
import { Typography, Paper, Box, TextField } from '@material-ui/core';
// cr components
import Slider from 'components/Slider';
import ParticipantsVotes from './ParticipantsVotes';
import HeatMappingDots from 'components/HeatMapping/Participant/HeatMappingDots';
import { useHeatMappingSelector } from 'redux/selectors/heatMappingSelectors';

const useImageStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
    width: 340,
    height: ({ context }) => (context === 'results' ? '100%' : 380),
    paddingBottom: theme.spacing(1),
    backgroundColor: '#EDF5F8',
    border: '1px dashed #C0CFDC',
    marginRight: 20,
    marginBottom: theme.spacing(3),
    cursor: 'pointer',
  },
  containerSelected: {
    position: 'relative',
    border: `3px solid ${theme.palette.indigo.main}`,
    backgroundColor: theme.palette.indigo.lightest,
  },
  containerSelectedSuperVote: {
    position: 'relative',
    border: `3px solid ${theme.palette.success.main}`,
    backgroundColor: theme.palette.success.lightest,
  },
  title: {
    lineHeight: '40px',
    minWidth: 100,
  },
  dotsContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '40%',
  },
  dotStrawPoll: {
    width: 12,
    height: 12,
    marginLeft: theme.spacing(1),
    borderRadius: '50%',
    background: theme.palette.indigo.main,
  },
  superVoteDot: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    marginRight: theme.spacing(1),
    borderRadius: '50%',
    backgroundColor: '#8FD41E',
  },
  superVotesContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '37%',
  },
  titleSelectedSuperVote: {
    color: theme.palette.success.main,
  },
  cursorDefault: {
    cursor: 'default',
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  textField: {
    position: 'absolute',
    left: 17,
    top: '43%',
    width: 300,
    background: '#fff',
    zIndex: 10,
    '& .MuiFormLabel-root': {
      padding: `0px ${theme.spacing(1)}px`,
      fontSize: 14,
    },
    '& .MuiInputBase-inputMultiline': {
      padding: `${theme.spacing(1)}px ${theme.spacing(1)}px 0px`,
      fontSize: 14,
    },
  },
  selectedColor: {
    opacity: ({ isSelected, context }) => (isSelected && context === 'strawPoll' ? 0.4 : 1),
  },
  buttonSpinnerBox: {
    position: 'absolute',
    top: '45%',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  buttonSpinner: {
    color: theme.palette.primary.main,
  },
  sliderText: {
    paddingTop: theme.spacing(3),
    color: 'rgba(86, 86, 86, 0.5)',
    order: 3,
  },
}));

// Renders participants votes if context is 'strawPollResults'
const Dots = ({ isCorrectSelected, comments, context, selectedDot, setSelectedDot }) => {
  useEffect(() => {
    if (isCorrectSelected && selectedDot === '') {
      setSelectedDot(keys(comments)[0]);
    }
  }, []);

  return map(comments, (comment, idx) => {
    return (
      <ParticipantsVotes
        key={`vote-${idx}`}
        comment={comment}
        context={context}
        idx={idx}
        isSelect={idx === selectedDot}
        setSelectedDot={setSelectedDot}
      />
    );
  });
};

Dots.propTypes = {
  isCorrectSelected: PropTypes.bool.isRequired,
  comments: PropTypes.object, // participant comments for voting
  context: PropTypes.oneOf([
    'strawPoll',
    'strawPollResults',
    'strawPollResultsAdmin',
    'superVote',
    'results',
  ]), // state phase
  selectedDot: PropTypes.string, // id of selected participant dot
  setSelectedDot: PropTypes.func, // function for changing participant select dot
};

/**
 * Renders sketch box for voting
 */
const ImagePoll = ({
  sketch,
  idx,
  createdBy,
  handleVote,
  isVoting,
  isFinishedVote,
  context,
  comments,
  participantComment,
  superVotePoints,
  heatMappingPoints,
  isSelected,
  handleChangeParticipantComment,
  handleFinishParticipantComment,
  selectedDot,
  setSelectedDot,
}) => {
  const imageClasses = useImageStyles({ isSelected, context });
  const session = useSelector(state => state.firestore.data.session);
  const [isSelectedSuperVote, setIsSelectedSuperVote] = useState(!!superVotePoints);
  const [votePoints, setVotePoints] = useState(superVotePoints ? superVotePoints : 0);
  const heatMapping = useHeatMappingSelector();
  const isPointsHidden = heatMapping?.state?.isPointsHidden || false;
  const name = `Sketch #${idx + 1}`;
  const isCorrectSelected =
    isSelected &&
    (context === 'strawPoll' ||
      context === 'strawPollResults' ||
      context === 'strawPollResultsAdmin');

  // Selects sketch that the participant voted for
  const selectSketch = () => {
    if (!isFinishedVote && context === 'strawPoll') {
      handleVote(createdBy, !isSelected);
    }
  };

  // Renders images for slider
  const slides = () =>
    map(sketch, (image, index) => {
      return (
        <HeatMappingDots
          key={`image-${index}`}
          image={image}
          heatMappingPoints={heatMappingPoints}
          heatMappingImageId={`points-image-${idx}-${index}`}
          isPointsHidden={isPointsHidden}
        />
      );
    });

  // Add super vote participant point
  const addVote = event => {
    if (
      context === 'superVote' &&
      !isFinishedVote &&
      !isVoting &&
      event.target.id !== 'slider-button' &&
      event.target.id !== 'super-vote-point' &&
      event.target.tagName !== 'path'
    ) {
      setIsSelectedSuperVote(true);
      setVotePoints(votePoints + 1);
      handleVote(createdBy, votePoints + 1);
    } else if (
      context === 'strawPoll' &&
      event.target.id !== 'slider-button' &&
      event.target.id !== 'because-textarea' &&
      event.target.tagName !== 'path'
    ) {
      selectSketch();
    }
  };

  // Remove super vote participant point
  const handleRemoveSuperVote = () => {
    if (context === 'superVote' && !isFinishedVote) {
      const countVotesPoints = votePoints - 1;
      setIsSelectedSuperVote(!!countVotesPoints);
      setVotePoints(countVotesPoints);
      handleVote(createdBy, countVotesPoints);
    }
  };

  // Renders super vote participant point
  const renderSuperVotePoints = () => {
    let i = votePoints;
    const result = [];
    while (i > 0) {
      result.push(
        <div
          id="super-vote-point"
          key={`point-${i}`}
          className={cx(
            imageClasses.superVoteDot,
            isFinishedVote ? imageClasses.cursorDefault : imageClasses.cursorPointer,
          )}
          onClick={handleRemoveSuperVote}
        />,
      );
      i = i - 1;
    }
    return result;
  };

  if (slides().length > 0) {
    return (
      <Paper
        className={cx(
          imageClasses.container,
          isCorrectSelected && imageClasses.containerSelected,
          isSelectedSuperVote &&
            ((context === 'superVote' && !isFinishedVote) || context === 'results') &&
            imageClasses.containerSelectedSuperVote,
          isFinishedVote ? imageClasses.cursorDefault : imageClasses.cursorPointer,
        )}
        variant="outlined"
        onClick={addVote}
      >
        <Box display="flex">
          <Box className={imageClasses.dotsContainer}>
            {isSelected && context === 'strawPoll' && (
              <div className={imageClasses.dotStrawPoll} onClick={selectSketch} />
            )}
            {context !== 'strawPoll' && (
              <Dots
                isCorrectSelected={isCorrectSelected}
                comments={comments}
                context={context}
                selectedDot={selectedDot}
                setSelectedDot={setSelectedDot}
              />
            )}
          </Box>
          <Typography
            className={cx(
              imageClasses.title,
              isSelectedSuperVote &&
                ((context === 'superVote' && !isFinishedVote) || context === 'results') &&
                imageClasses.titleSelectedSuperVote,
              imageClasses.selectedColor,
            )}
            align="center"
            color="primary"
          >
            {name}
          </Typography>
          <Box className={imageClasses.superVotesContainer}>
            {((context === 'superVote' && !isFinishedVote) || context === 'results') &&
              renderSuperVotePoints()}
          </Box>
        </Box>
        {context === 'results' ? (
          slides()
        ) : (
          <Slider
            className={imageClasses.selectedColor}
            slides={slides()}
            size="small"
            textClassName={imageClasses.sliderText}
          />
        )}
        {isSelected && context === 'strawPoll' && (
          <TextField
            classes={{
              root: imageClasses.textField,
            }}
            multiline
            rows={4}
            id="because-textarea"
            placeholder="Tell us why you voted on this sketch"
            onChange={handleChangeParticipantComment}
            onBlur={handleFinishParticipantComment}
            value={participantComment}
          />
        )}
      </Paper>
    );
  } else {
    Sentry.captureException({
      errorMessage: 'Heatmapping Error: No slides returned when there should have been',
      meetingId: session.id,
      facilitatorId: session.facilitatorId,
    });

    return <></>;
  }
};

ImagePoll.propTypes = {
  context: PropTypes.oneOf([
    'strawPoll',
    'strawPollResults',
    'strawPollResultsAdmin',
    'superVote',
    'results',
  ]), // state phase
  sketch: PropTypes.arrayOf(PropTypes.string), // array of images url for sketch
  idx: PropTypes.number, // index of image
  createdBy: PropTypes.string, // id of participant, which create sketch
  handleVote: PropTypes.func, // function for voting
  isVoting: PropTypes.bool, // participant select sketch for voting
  isFinishedVote: PropTypes.bool, // paricipant finished voting
  comments: PropTypes.object, // participant comments for voting
  superVotePoints: PropTypes.number, // participant super vote points
  heatMappingPoints: PropTypes.object, // participant heat-mapping points
  isSelected: PropTypes.bool, // participant voted sketch in strawPoll
  participantComment: PropTypes.string, // value of participant comment
  handleChangeParticipantComment: PropTypes.func, // function for changing participant comment
  handleFinishParticipantComment: PropTypes.func, // function for saving participant comment
};

export default ImagePoll;
