import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
// CR components
import CustomSelect from 'components/CustomFormComponents/CustomSelect';
// constants
import { VARIABLE_QUESTION_TYPES_SELECT_OPTIONS } from './QuestionTypes/Facilitator/constants';

const QuestionTypeSelect = ({ onChange, setQuestionType, ...otherProps }) => {
  const [field, meta, helpers] = useField('questionType');

  const handleChange = (event, value) => {
    setQuestionType(value.props.value);
    helpers.setValue(value.props.value);
  };

  return (
    <CustomSelect
      name="questionType"
      label="Question Type"
      required
      {...otherProps}
      customOnChange={handleChange}
      selectOptions={VARIABLE_QUESTION_TYPES_SELECT_OPTIONS}
    />
  );
};

QuestionTypeSelect.propTypes = {
  onChange: PropTypes.func, // the default onChange from Formik, not used
  setQuestionType: PropTypes.func, // set state function from the parent
};

export default QuestionTypeSelect;
