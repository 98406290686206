import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
// library components
import { Card, CardContent } from '@material-ui/core';
// cr components
import SmallLabel from 'components/SmallLabel';
import NoteForm from './NoteForm';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    minHeight: 140,
    border: `1px dashed ${grey[400]}`,
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
}));

/**
 * Renders view for creating new note on the Participant side.
 */
const NewNote = ({ onCreateNewNote }) => {
  const classes = useStyles();
  const [isEditing, setIsEditing] = useState(false);

  const onCancelEditing = () => {
    setIsEditing(false);
  };

  return (
    <Card classes={classes} variant="outlined" elevation={0}>
      <CardContent
        className="w-100"
        style={{ paddingBottom: 16 }}
        onClick={() => {
          setIsEditing(true);
        }}
      >
        {isEditing ? (
          <NoteForm cancelEdit={onCancelEditing} type="create" onCreateNewNote={onCreateNewNote} />
        ) : (
          <SmallLabel text="Create new (max 90 characters)" uppercase={false} fontSize={14} />
        )}
      </CardContent>
    </Card>
  );
};

NewNote.propTypes = {
  onCreateNewNote: PropTypes.func,
};

export default NewNote;
