import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty, isLoaded } from 'react-redux-firebase';
import { grey } from '@material-ui/core/colors';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  getBreakoutsTopic,
  getParticipantRoom,
} from '../../../redux/selectors/Breakouts/rootBreakoutsSelector';

import { Typography, Paper, CircularProgress } from '@material-ui/core';

import DelayedRender from '../../DelayedRender/DelayedRender';

import { useMeetingRoomSelector } from '../../../redux/selectors/meetingRoomSelectors';
import { getCurrentSidebarMeetingForUser } from '../../../redux/selectors/sidebarMeetingsSelectors';

import { joinMeetingRoom } from '../../../redux/actions/meetingRoomActions';

// cr utils
import renderHtmlString from '../../../utils/renderHtmlString';
import renderlinkFormat from '../../../utils/renderlinkFormat';
import {
  createBreakoutMeetRoomId,
  createHallwayMeetRoomId,
} from '../../../utils/createMeetRoomIds';
import MuralCanvas from 'components/MuralCanvas';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  topic: {
    marginBottom: '30px',
    textAlign: 'center',
    fontSize: '30px',
    fontWeight: '300',
  },
  description: {
    backgroundColor: grey[100],
    textAlign: 'center',
    padding: '30px',
    marginBottom: '20px',
  },
  startsSoon: {
    fontSize: '36px',
  },
});

const BreakoutParticipantRoom = ({ participantId, breakoutsSession }) => {
  const classes = useStyles();
  const topic = useSelector(getBreakoutsTopic);
  const session = useSelector(state => state.firestore.data.session);
  const participantRoom = useSelector(getParticipantRoom(participantId));
  const authUser = useSelector(state => state.firebase.auth);
  const { roomId, roomIsSidebar } = useMeetingRoomSelector();
  const dispatch = useDispatch();
  const avEnabled = session.avEnabled;
  const sessionId = session.id;
  const participantRoomId = participantRoom?.id;
  const currentSidebar = useSelector(getCurrentSidebarMeetingForUser(authUser.uid));
  const sidebarMeetings = useSelector(state => state.firestore.data.sidebarMeetings);
  const sidebarMeetingsIsLoaded = isLoaded(sidebarMeetings);

  // set the jitsi meeting room id
  useEffect(() => {
    if (!sidebarMeetingsIsLoaded) return;

    const breakoutMeetRoomId = createBreakoutMeetRoomId(sessionId, participantRoomId);

    if (participantRoomId && roomId !== breakoutMeetRoomId && avEnabled && !currentSidebar) {
      dispatch(
        joinMeetingRoom(
          {
            roomId: breakoutMeetRoomId,
            roomName: participantRoom.title,
            roomStatus: 'routing',
          },
          `Joining breakout room: ${participantRoom.title}`,
        ),
      );
    }

    // user was in hallway when sidebar started so they have no room id to auto-load...
    if (!participantRoomId && avEnabled && !currentSidebar) {
      dispatch(
        joinMeetingRoom(
          {
            roomId: createHallwayMeetRoomId(session.id),
            roomName: 'Hallway',
            roomStatus: 'routing',
          },
          'Joining room: hallway',
        ),
      );
    }
  }, [
    participantRoom,
    participantRoomId,
    avEnabled,
    sessionId,
    currentSidebar,
    sidebarMeetingsIsLoaded,
  ]);

  if (isEmpty(participantRoom)) {
    return breakoutsSession?.breakoutStarted ? (
      <DelayedRender>
        <div className="mt-8">
          <CircularProgress size={35} />
        </div>
      </DelayedRender>
    ) : (
      <div className={classes.startsSoon}>Your breakout will start soon...</div>
    );
  }

  return (
    <div className={classes.root}>
      <Typography
        style={{ marginTop: '-10px' }}
        align="center"
        className="mb-4"
        dangerouslySetInnerHTML={renderHtmlString(topic.description, {
          format: function (value, type) {
            return renderlinkFormat(
              value,
              type,
              '<svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeInherit" style="width: 14px; position: relative; top: 2px" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation" fill="#0066cc"><path d="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"></path></svg>',
            );
          },
        })}
      />
      <Typography className={classes.topic}>{participantRoom.title}</Typography>

      {participantRoom.description && (
        <Paper className={classes.description} elevation={0}>
          <Typography
            dangerouslySetInnerHTML={renderHtmlString(
              participantRoom.description.replace('|', '<hr class="divider" />'),
              {
                format: function () {
                  return '<svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeInherit" style="width: 14px; position: relative; top: 2px" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation" fill="#0066cc"><path d="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"></path></svg>';
                },
              },
            )}
          />
        </Paper>
      )}
      {breakoutsSession.muralEnabled && participantRoom.muralURL && (
        <MuralCanvas muralURL={participantRoom.muralURL} />
      )}
    </div>
  );
};

export default BreakoutParticipantRoom;
