import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import firebase from 'firebase/app';
import 'firebase/storage';
import imageCompression from 'browser-image-compression';
import cx from 'classnames';
// library components
import { Paper, Typography, InputBase, Box, IconButton, Tooltip, Button } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import LinkIcon from '@material-ui/icons/Link';
import SearchIcon from '@material-ui/icons/Search';
import grey from '@material-ui/core/colors/grey';
// cr components
import CropImageForm from 'components/CropImageForm';
import ButtonSpinner from 'components/ButtonSpinner/ButtonSpinner';
import FormDialog from 'components/Dialogs/FormDialog';
import ImageSearchForm from 'components/LightningDemo/Participant/ImageSearchForm';
// actions
import {
  updateLightningDemo,
  updateLightningDemoBigIdeas,
  updateLightningDemoSourceTitle,
  updateLightningDemoUrl,
} from 'redux/actions/lightningDemoActions';
// utils
import { generateRandomId } from 'utils/generateRandomId';
import { maybeConvertFromHeic } from 'utils/maybeConvertFromHeic';

const useStyles = makeStyles(theme => ({
  lightningBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: ({ context, isSmallScreen }) => (context === 'review' || isSmallScreen ? '100%' : '47%'),
    margin: ({ context }) => {
      if (context === 'review') return 0;
      if (context === 'results') return 10;
      return '10px 0';
    },
    padding: ({ context }) => (context === 'review' ? '0px 30px' : 0),
    border: ({ isNotReadyDemo }) =>
      isNotReadyDemo ? `1px solid ${theme.palette.dissatisfied.main}` : `1px dashed #C0CFDC`,
    background: '#EDF5F8',
  },
  dropZone: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: ({ context }) => (context === 'review' ? '100%' : '90%'),
    height: ({ context, isSmallScreen }) =>
      context === 'review' || isSmallScreen ? '100%' : '19vw',
    minHeight: 150,
    borderRadius: 3,
    background: 'rgba(183, 194, 200, 0.5)',
  },
  dragActive: {
    border: `2px dashed #C0CFDC`,
  },
  image: {
    width: '100%',
    height: '100%',
    maxWidth: 715,
    maxHeight: 550,
    objectFit: 'contain',
  },
  label: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '3%',
    cursor: 'pointer',
  },
  input: {
    display: 'none',
  },
  inputSubTitle: {
    marginTop: '2vw',
    marginBottom: '8%',
    fontSize: 13,
  },
  urlButton: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '100%',
    justifyContent: 'center',
  },
  inputBox: {
    width: '100%',
    padding: ({ context }) => (context === 'review' ? 0 : '0px 5%'),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  buttonSpinnerBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 200,
    paddingRight: theme.spacing(5),
  },
  buttonSpinner: {
    color: theme.palette.primary.main,
  },
  addCircle: {
    marginTop: '4vw',
    marginBottom: '8%',
  },
  searchButton: {
    marginLeft: 20,
    textTransform: 'none',
    fontWeight: 400,
    fontSize: 15,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
}));

const dropzoneSelectors = [
  'ImageDropzone',
  'dropzoneTxt',
  'dropzoneTxt2',
  'dropzoneTxt3',
  'dropzoneInputIcon',
  'dropzoneInputLabel',
];

const useInputStyles = makeStyles(theme => ({
  root: {
    width: ({ imageId }) => (imageId ? '90%' : '67%'),
    paddingRight: ({ imageId }) => (imageId ? 0 : theme.spacing(5)),
    paddingLeft: ({ imageId, context }) => (imageId && context === 'upload' ? theme.spacing(3) : 0),
    marginLeft: ({ imageId }) => (imageId ? 'auto' : 0),
    marginRight: 'auto',
    color: ({ isNotReadyDemo, bigIdeas }) =>
      isNotReadyDemo && !bigIdeas ? theme.palette.dissatisfied.main : theme.palette.primary.main,
  },
  input: {
    textAlign: 'center',
    '&::placeholder': {
      opacity: 1,
    },
  },
  rootSource: {
    paddingRight: 5,
    fontWeight: 300,
    color: ({ isNotReadyDemo, sourceTitle }) =>
      isNotReadyDemo && !sourceTitle ? theme.palette.dissatisfied.main : grey[700],
  },
  inputSource: {
    textAlign: 'center',
    paddingBottom: 0,
    '&::placeholder': {
      opacity: 1,
    },
  },
  rootUrl: {
    width: ({ url }) => (url ? url.length * 8 : '100px'),
    minWidth: '102px',
    fontWeight: 300,
    color: theme.palette.primary.main,
  },
  inputUrl: {
    '&::placeholder': {
      opacity: 1,
    },
  },
  inputError: {
    color: theme.palette.dissatisfied.main,
  },
  focused: {
    color: `${theme.palette.primary.main} !important`,
  },
  focusedSource: {
    color: `${grey[800]} !important`,
  },
}));

const useDialogStyles = makeStyles(theme => ({
  paperWidthSm: {
    maxWidth: 700,
  },
  contentRoot: {
    padding: ({ isSmallScreen }) => (isSmallScreen ? '0 20px' : '0 50px'),
  },
  titleRoot: {
    height: 80,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: '30px',
  },
}));

/**
 * Renders a lightning demo
 */
const LightningDemo = ({
  lightningDemoId,
  demoId,
  participantId,
  imageId,
  handleDeleteLightningDemo,
  bigIdeas,
  source,
  demoUrl,
  isNotReadyDemo,
  isFinished,
  context,
}) => {
  const isSmallScreen = useMediaQuery('(max-width: 767px)');

  const dispatch = useDispatch();
  const classes = useStyles({ isNotReadyDemo, context, isSmallScreen });
  const [isOpen, setIsOpen] = useState(false);
  const [fileUrl, setFileUrl] = useState();
  const [lightningDemoUrl, setLightningDemoUrl] = useState();
  const [title, setTitle] = useState(bigIdeas);
  const [sourceTitle, setSourceTitle] = useState(source);
  const [url, setUrl] = useState(demoUrl);
  const [errorUrl, setErrorUrl] = useState(false);
  const [isUploadNewImage, setIsUploadNewImage] = useState(false);
  const [isSearchFormOpen, setIsSearchFormOpen] = useState(false);

  const titleInputClasses = useInputStyles({
    isNotReadyDemo,
    bigIdeas,
    sourceTitle,
    isFinished,
    url,
    imageId,
    context,
  });
  const dialogClasses = useDialogStyles({ isSmallScreen });

  const isLoading = !lightningDemoUrl;

  // Upload new image to firebase
  const uploadNewImage = async (file, demoId) => {
    setIsUploadNewImage(true);
    const options = {
      maxWidthOrHeight: 1920,
    };

    try {
      const imageId = generateRandomId();
      const compressedFile = await imageCompression(file, options);
      const storageRef = firebase
        .storage()
        .ref(`lightningDemo/${lightningDemoId}/${participantId}/${imageId}`);

      await storageRef.put(compressedFile);
      const url = await storageRef.getDownloadURL();
      setLightningDemoUrl(url);

      dispatch(updateLightningDemo(lightningDemoId, demoId, imageId, url));
      setIsUploadNewImage(false);
    } catch (err) {
      console.log('Error upload image to firebase', err);
      setIsUploadNewImage(false);
    }
  };

  // Get image url from frirebase storage for demo
  const getSketchImageUrls = useCallback(
    (participantId, imageId) => {
      const storageRef = firebase
        .storage()
        .ref(`lightningDemo/${lightningDemoId}/${participantId}/${imageId}`);

      storageRef
        .getDownloadURL()
        .then(url => {
          setLightningDemoUrl(url);
        })
        .catch(err => console.log('Error get image url', err));
    },
    [lightningDemoId],
  );

  useEffect(() => {
    if (imageId) {
      getSketchImageUrls(participantId, imageId);
      setTitle(bigIdeas);
      setSourceTitle(source);
      setUrl(demoUrl);
    } else if (!bigIdeas && !source && !demoUrl && !imageId) {
      setTitle(bigIdeas);
      setSourceTitle(source);
      setUrl(demoUrl);
    }
  }, [participantId, imageId, bigIdeas, source, demoUrl, getSketchImageUrls]);

  useEffect(() => {
    // Prevent default browser behavior (green plus icon and only allow it if the dropzone is hovered)
    window.addEventListener(
      'dragenter',
      function (e) {
        if (!dropzoneSelectors.includes(e.target.id)) {
          e.preventDefault();
          e.dataTransfer.dropEffect = 'none';
        }
      },
      false,
    );

    window.addEventListener('dragover', function (e) {
      if (!dropzoneSelectors.includes(e.target.id)) {
        e.preventDefault();
        e.dataTransfer.dropEffect = 'none';
      }
    });

    window.addEventListener('drop', function (e) {
      if (!dropzoneSelectors.includes(e.target.id)) {
        e.preventDefault();
        e.dataTransfer.dropEffect = 'none';
      }
    });
  }, []);

  // on close crop image dialog
  const closeDialog = () => {
    setIsOpen(false);
  };

  // Participant upload new image. Read image file and return first file
  const onDrop = useCallback(async acceptedFiles => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      let imageBlob = await maybeConvertFromHeic(acceptedFiles[0]);

      if (!(imageBlob instanceof Blob)) {
        return setIsOpen(false);
      }

      const reader = new FileReader();
      reader.addEventListener('load', () => setFileUrl(reader.result));
      reader.readAsDataURL(imageBlob);
    }
    return setIsOpen(true);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  // delete lightning demo
  const onDeleteLightningDemo = () => {
    handleDeleteLightningDemo(demoId, imageId);
  };

  // change big ideas
  const onChangeTitle = event => {
    setTitle(event.target.value);
  };

  // change source title
  const onChangeSourceTitle = event => {
    setSourceTitle(event.target.value);
  };

  // change url
  const onChangeUrl = event => {
    setUrl(event.target.value);
  };

  // finish change big ideas
  const onCompleteEditTitle = event => {
    dispatch(updateLightningDemoBigIdeas(lightningDemoId, demoId, title));
    if (!title) {
      event.target.placeholder = 'Big Ideas';
    }
  };

  // finish change source title
  const onCompleteEditSourceTitle = event => {
    dispatch(updateLightningDemoSourceTitle(lightningDemoId, demoId, sourceTitle));
    if (!sourceTitle) {
      event.target.placeholder = 'Source Title';
    }
  };

  // finish change url
  const onCompleteEditUrl = event => {
    const regExp =
      /https?:\/\/w{0,3}\w*?\.(\w*?\.)?\w{2,3}\S*|www\.(\w*?\.)?\w*?\.\w{2,3}\S*|(\w*?\.)?\w*?\.\w{2,3}[?]\S*/;
    if (url) {
      if (regExp.test(url)) {
        setErrorUrl(false);
        dispatch(updateLightningDemoUrl(lightningDemoId, demoId, url));
      } else {
        setErrorUrl(true);
      }
    } else {
      dispatch(updateLightningDemoUrl(lightningDemoId, demoId, url));
      event.target.placeholder = 'URL (Optional)';
    }
  };

  const handleOpenSearchDialog = () => {
    setIsSearchFormOpen(true);
  };

  const handleSelectSearchImage = url => {
    setFileUrl(url);
    setIsOpen(true);
  };

  return (
    <Paper className={classes.lightningBox} elevation={0}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent={isFinished ? 'center' : 'start'}
        className="w-100 mb-1 mt-1"
      >
        {!imageId && (
          <Button
            className={classes.searchButton}
            size="small"
            startIcon={<SearchIcon />}
            color="primary"
            onClick={handleOpenSearchDialog}
          >
            Google
          </Button>
        )}
        <InputBase
          classes={{
            root: titleInputClasses.root,
            input: titleInputClasses.input,
            focused: titleInputClasses.focused,
          }}
          value={title}
          placeholder={title ? '' : 'Big Ideas'}
          inputProps={{ 'aria-label': 'naked' }}
          onChange={onChangeTitle}
          onBlur={onCompleteEditTitle}
          onFocus={e => (e.target.placeholder = '')}
          readOnly={isFinished}
        />
        {!isFinished && (
          <IconButton className="ml-auto" aria-label="delete" onClick={onDeleteLightningDemo}>
            <DeleteIcon fontSize="small" color="primary" />
          </IconButton>
        )}
      </Box>
      {imageId || isUploadNewImage ? (
        <div className={classes.dropZone}>
          {isLoading || isUploadNewImage ? (
            <Box className={classes.buttonSpinnerBox}>
              <ButtonSpinner className={classes.buttonSpinner} />
            </Box>
          ) : (
            <img className={classes.image} src={lightningDemoUrl} alt="lightning-demo" />
          )}
        </div>
      ) : (
        <div
          className={cx(classes.dropZone, isDragActive && classes.dragActive)}
          style={isDragActive ? { background: 'rgba(0,0,0,.3)' } : {}}
          {...getRootProps()}
          id="ImageDropzone"
        >
          <input {...getInputProps()} id="dropzoneInput" />
          <label className={classes.label} htmlFor="contained-button-file" id="dropzoneInputLabel">
            <AddCircleIcon
              className={classes.addCircle}
              fontSize="large"
              color="primary"
              id="dropzoneInputIcon"
            />
            <Typography align="center" color="primary" id="dropzoneTxt">
              Select Photo/Image to add new demo
            </Typography>
            <Typography align="center" color="textSecondary" id="dropzoneTxt2">
              (Or drop in this area)
            </Typography>
            <Typography
              className={classes.inputSubTitle}
              align="center"
              color="textSecondary"
              id="dropzoneTxt3"
            >
              .jpg or .png minimum size: 300w x 400h
            </Typography>
          </label>
        </div>
      )}
      <Box display="flex" alignItems="center" flexDirection="column" className={classes.inputBox}>
        <InputBase
          classes={{
            root: titleInputClasses.rootSource,
            input: titleInputClasses.inputSource,
            focused: titleInputClasses.focusedSource,
          }}
          value={sourceTitle}
          placeholder={sourceTitle ? '' : 'Source Title'}
          inputProps={{ 'aria-label': 'naked' }}
          onChange={onChangeSourceTitle}
          onBlur={onCompleteEditSourceTitle}
          onFocus={e => (e.target.placeholder = '')}
          readOnly={isFinished}
          fullWidth
        />
        <Tooltip open={errorUrl} title="URL is not correct" arrow>
          <div className={classes.urlButton}>
            <LinkIcon fontSize="small" color="primary" className="mr-1" />
            <InputBase
              classes={{
                root: titleInputClasses.rootUrl,
                input: titleInputClasses.inputUrl,
                error: titleInputClasses.inputError,
                focused: titleInputClasses.focused,
              }}
              value={url}
              placeholder={url ? '' : 'URL (Optional)'}
              inputProps={{ 'aria-label': 'naked' }}
              onChange={onChangeUrl}
              onBlur={onCompleteEditUrl}
              onFocus={e => {
                if (context === 'review' || context === 'results') {
                } else {
                  e.target.placeholder = '';
                }
              }}
              readOnly={isFinished}
              error={errorUrl}
              fullWidth
            />
          </div>
        </Tooltip>
      </Box>
      <CropImageForm
        closeDialog={closeDialog}
        isOpen={isOpen}
        fileUrl={fileUrl}
        id={demoId}
        uploadNewImage={uploadNewImage}
        cropParam={{
          unit: '%',
          width: 100,
          height: 100,
        }}
      />
      <FormDialog
        handleClose={() => setIsSearchFormOpen(false)}
        isOpen={isSearchFormOpen}
        title={'Image Search'}
        classes={{
          paperWidthSm: dialogClasses.paperWidthSm,
        }}
        contentClasses={{
          root: dialogClasses.contentRoot,
        }}
        titleClasses={{
          root: dialogClasses.titleRoot,
        }}
      >
        <ImageSearchForm
          closeDialog={() => setIsSearchFormOpen(false)}
          handleSelectSearchImage={handleSelectSearchImage}
        />
      </FormDialog>
    </Paper>
  );
};

LightningDemo.propTypes = {
  lightningDemoId: PropTypes.string.isRequired, // the id of the acticity
  demoId: PropTypes.string.isRequired, // the id of the lightning demo
  participantId: PropTypes.string.isRequired, // the id of participant
  imageId: PropTypes.string.isRequired, // the id of image
  handleDeleteLightningDemo: PropTypes.func, // function for delete lightning demo
  bigIdeas: PropTypes.string.isRequired, // the title (big ideas) of the lightning demo
  source: PropTypes.string.isRequired, // the source title of the lightning demo
  demoUrl: PropTypes.string.isRequired, // the url of the lightning demo
  isNotReadyDemo: PropTypes.bool, // the demo without Big Ideas or Source Title
  isFinished: PropTypes.bool.isRequired, // the participant finished uploading demo
  context: PropTypes.string, // active phase of state
};

export default LightningDemo;
