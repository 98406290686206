import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';

// material components
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';

// actions
import { setSessionSlideNumber } from 'redux/actions/slidesSessionActions';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
  },
}));

const useSelectStyles = makeStyles({
  select: {
    paddingLeft: 5,
  },
});

const useInputStyles = makeStyles({
  root: {
    background: '#f2f2f2',
    fontSize: 12,
  },
});

export default function SlideSelect({ slidesSession, slidesSessionId }) {
  const [currentSlide, setCurrentSlide] = useState(slidesSession.currentSlide);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const selectClasses = useSelectStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    setCurrentSlide(slidesSession.currentSlide);
  }, [slidesSession.currentSlide]);

  const renderOptions = () => {
    return slidesSession.imageUrls.map((slide, idx) => (
      <MenuItem value={idx} key={`slide_${idx}`}>
        {idx + 1}
      </MenuItem>
    ));
  };

  const handleChange = e => {
    setCurrentSlide(e.target.value);
    dispatch(setSessionSlideNumber(slidesSessionId, e.target.value, enqueueSnackbar));
  };

  return (
    <Fragment>
      <FormControl variant="outlined" className={classes.formControl}>
        <Select
          value={currentSlide}
          onChange={handleChange}
          classes={selectClasses}
          input={<Input disableUnderline classes={useInputStyles()} />}
        >
          {renderOptions()}
        </Select>
      </FormControl>
    </Fragment>
  );
}

SlideSelect.propTypes = {
  slidesSession: PropTypes.object.isRequired,
  slidesSessionId: PropTypes.string.isRequired,
};
