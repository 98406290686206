import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { map, includes, find, filter } from 'lodash';
import cx from 'classnames';
import { Droppable, Draggable } from 'react-beautiful-dnd';
// library components
import { IconButton, Typography, Box, InputBase, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// cr components
import Idea from './Idea';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

const useStyles = makeStyles(theme => ({
  group: {
    flex: '1 1 550px',
    minHeight: 155,
    border: `1px dashed #C0CFDC`,
    borderRadius: 4,
    background: '#EDF5F8',
  },
  title: {
    width: '100%',
    paddingLeft: theme.spacing(6),
    textAlign: 'center',
  },
  spaceTitle: {
    padding: theme.spacing(2),
  },
  inputTitle: {
    display: 'flex',
    justifyContent: 'center',
    width: '90%',
    paddingLeft: theme.spacing(14),
  },
  settings: {
    marginLeft: 'auto',
  },
  settingsContainer: {
    width: 115,
  },
  settingsMenuItem: {
    padding: '4px 12px',
    fontSize: 15,
  },
  droppableIdeas: {
    top: 10,
  },
  grid: {
    width: 'calc(100% + 10px)',
  },
  gridItem: {
    padding: `0 5px !important`,
    marginBottom: 10,
    /**
     * So the cards don't start running over
     * each other.  The cards themselves have
     * a minWidth of 170
     */
    minWidth: '180px',
  },
  opacity0: {
    opacity: '0',
  },
}));

const getStyle = (style, snapshot) => {
  if (!snapshot.isDragging) return {};
  if (!snapshot.isDropAnimating) {
    return style;
  }

  return {
    ...style,
    transitionDuration: `0.001s`,
  };
};

/**
 * Renders ideas group view
 */
const Group = ({
  className,
  groupId,
  droppableId,
  participantId,
  title,
  context,
  ideas,
  settingsDisabled,
  onDeleteGroup,
  onEditGroupName,
  handleVote,
  isFinished,
  tooltipText,
  isComplete,
  countGroups,
  handleOpenDuplicateCards,
  isOpenCombineList,
  combineIdea,
  isDragStart,
}) => {
  const smallScreen = useMediaQuery('(max-width: 500px)');
  const classes = useStyles();

  const [isEditGroupName, setIsEditGroupName] = useState(false);
  const [groupName, setGroupName] = useState(title);

  const groupIdea = find(ideas, idea => idea.groupId === groupId);
  const deletingDisabled = countGroups <= 1 || !!groupIdea;

  useEffect(() => {
    setGroupName(title);
  }, [title]);

  // render draggable idea
  const renderIdea = (idea, idx) => (
    <Droppable key={idea.ideaId} droppableId={idea.ideaId} isCombineEnabled type="hmws">
      {(provided, dropSnapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.droppableProps}
          style={
            (isDragStart ? { height: 135, width: 200 } : {},
            dropSnapshot.draggingFromThisWith
              ? { height: '135px', width: '200px', border: '1px dashed #CFCFCF', borderRadius: 4 }
              : {})
          }
        >
          <Draggable
            key={idea.ideaId}
            id={idea.ideaId}
            isDragDisabled={context !== 'group' && context !== 'groupAdmin'}
            draggableId={idea.ideaId}
            index={idx}
          >
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={getStyle(provided.draggableProps.style, snapshot)}
              >
                <Idea
                  idea={idea}
                  context={context}
                  handleSelect={handleVote}
                  isSelected={includes(idea.votedParticipants, participantId)}
                  isFinished={isFinished}
                  isComplete={isComplete}
                  handleOpenDuplicateCards={handleOpenDuplicateCards}
                  isDraggingOver={dropSnapshot.isDraggingOver}
                />
              </div>
            )}
          </Draggable>
          <div style={{ display: 'none' }}>{provided.placeholder}</div>
        </div>
      )}
    </Droppable>
  );

  // Renders participant ideas
  const renderIdeas = () => {
    const notDuplicatesIdeas = filter(ideas, idea => !idea.isCombine);
    return map(notDuplicatesIdeas, (idea, idx) => {
      if (idea.groupId === groupId) {
        return (
          <GridItem
            key={idea.ideaId}
            className={cx(classes.gridItem)}
            xs={12}
            sm={6}
            md={3}
            lg={3}
            xl={3}
          >
            {renderIdea(idea, idx)}
            {isOpenCombineList &&
              combineIdea?.ideaId === idea.ideaId &&
              map(combineIdea.combineIdeasId, (ideaId, index) => {
                const duplicateIdea = find(ideas, duplicateIdea => duplicateIdea.ideaId === ideaId);
                return renderIdea(duplicateIdea, idx + index, classes.disableCard, true);
              })}
          </GridItem>
        );
      }
    });
  };

  // function on delete current group
  const handleDeleteGroup = () => {
    onDeleteGroup(groupId);
  };

  // function on edit current group name
  const handleEditGroupName = () => {
    if (groupName === 'Untitled') {
      setGroupName('');
    }
    setIsEditGroupName(true);
  };

  // if group name change, set group name in state
  const onChangeName = event => {
    setGroupName(event.target.value);
  };

  // function on finished participant edit group name
  const onCompleteEditGroupName = () => {
    onEditGroupName(groupId, groupName);
    if (groupName === '') {
      setGroupName('Untitled');
      onEditGroupName(groupId, 'Untitled');
    }
    setIsEditGroupName(false);
  };

  // function on finished participant edit group name and press 'Enter' button
  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      onEditGroupName(groupId, groupName);
      setIsEditGroupName(false);
    }
  };

  const getCategoryDropStyle = isDraggingOver => ({
    background: isDraggingOver ? '#D7E6EF' : '#EDF5F8',
  });

  return (
    <Droppable droppableId={droppableId} type="hmws" direction="horizontal">
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.droppableProps}
          className={cx(classes.group, className)}
          style={getCategoryDropStyle(snapshot.isDraggingOver)}
        >
          <Box className="w-100" display="flex" alignItems="center">
            {isEditGroupName ? (
              <div className={classes.inputTitle}>
                <InputBase
                  autoFocus
                  color="primary"
                  value={groupName}
                  inputProps={{ 'aria-label': 'naked' }}
                  onChange={onChangeName}
                  onBlur={onCompleteEditGroupName}
                  onKeyDown={handleKeyDown}
                />
              </div>
            ) : (
              <Tooltip
                disableFocusListener
                disableTouchListener
                disableHoverListener={context !== 'groupAdmin' || !tooltipText}
                title={tooltipText || ''}
              >
                <Typography
                  className={cx(classes.title, settingsDisabled && classes.spaceTitle)}
                  color={groupId ? 'primary' : 'textSecondary'}
                >
                  {groupName}
                  <IconButton onClick={handleEditGroupName} size="small" className="ml-1">
                    <EditIcon color="primary" fontSize="inherit" />
                  </IconButton>
                </Typography>
              </Tooltip>
            )}
            {!settingsDisabled && (
              <IconButton
                onClick={handleDeleteGroup}
                className={cx('ml-1', classes.settings, {
                  [classes.opacity0]: deletingDisabled,
                })}
                disabled={deletingDisabled}
              >
                <DeleteIcon
                  color="primary"
                  color={deletingDisabled ? 'disabled' : 'primary'}
                  fontSize="inherit"
                />
              </IconButton>
            )}
          </Box>
          <Box
            className="px-4 pb-2"
            display="flex"
            flexWrap="wrap"
            justifyContent={smallScreen ? 'center' : 'normal'}
          >
            <GridContainer direction={smallScreen ? 'column' : 'row'} className={classes.grid}>
              {renderIdeas()}
            </GridContainer>
          </Box>
          <div style={{ display: 'none' }}>{provided.placeholder}</div>
        </div>
      )}
    </Droppable>
  );
};

Group.propTypes = {
  className: PropTypes.string, // name of group container style
  groupId: PropTypes.string, // current group id
  droppableId: PropTypes.string, // droppable element id
  participantId: PropTypes.string, // participant id
  title: PropTypes.string, // the name of group
  context: PropTypes.string, // active view of state
  ideas: PropTypes.array, // all shared ideas, which sorted by order
  settingsDisabled: PropTypes.bool, // group without settings button
  onDeleteGroup: PropTypes.func, // function for delete current group
  onEditGroupName: PropTypes.func, // function for edit current group name
  handleVote: PropTypes.func, // function for set participant vote
  isFinished: PropTypes.bool, // the participant finished with active phase - true
  isComplete: PropTypes.bool, // the participant has fulfilled the conditions - true
  tooltipText: PropTypes.string, // tooltip title
  countGroups: PropTypes.number, // number of groups
  handleOpenDuplicateCards: PropTypes.func, // function on open drop down duplicate cards
  isOpenCombineList: PropTypes.bool, // drop down duplicate cards open
  combineIdea: PropTypes.object, // duplicate card
  isDragStart: PropTypes.bool, // is drag card
};

export default Group;
