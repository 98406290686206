import { filter, find, reduce, sortBy, each, map, join, orderBy } from 'lodash';

/**
 * Creates the output array for a Story Flow actvity
 * @param {Object} flows
 * @param {Object} participantsSuperVoteFlow
 * @returns {Array<{actor: String, end: String, moment-1: String, moment-2: String, moment-3: String, moment-4: String}>}
 */
export const createOutput = (flows, participantsSuperVoteFlow) => {
  // get super vote flow id
  const superVoteFlow = find(
    participantsSuperVoteFlow,
    participant => !!participant.superVoteFlowId,
  );
  // get super vote flow
  const votedFlow = filter(flows, flow => flow.participantId === superVoteFlow?.superVoteFlowId)[0];

  const output = votedFlow
    ? [
        votedFlow.values?.actor,
        votedFlow.values?.['moment-1'],
        votedFlow.values?.['moment-2'],
        votedFlow.values?.['moment-3'],
        votedFlow.values?.['moment-4'],
        votedFlow.values?.end,
      ]
    : [];

  return output;
};

export const getCountSelectedFlows = (participants, participantsSelectedFlow) => {
  return reduce(
    participants,
    (acc, participant) => {
      const participantsSelectedFlows = participantsSelectedFlow;
      if (participantsSelectedFlows?.[participant]) {
        const selectedFlowId = participantsSelectedFlows[participant].selectedFlowId;
        const selectedFlowForParticipant = acc[selectedFlowId] ? acc[selectedFlowId] : [];
        acc[selectedFlowId] = [...selectedFlowForParticipant, participant];
      }
      return acc;
    },
    {},
  );
};

/**
 * Creates the array for Report Header
 *
 */
export const createStoryFlowReportHeader = () => [
  { label: 'Super Vote', key: 'superVote' },
  { label: 'Votes', key: 'votes' },
  { label: 'Opening', key: 'actor' },
  { label: 'Step 1', key: 'moment-1' },
  { label: 'Step 2', key: 'moment-2' },
  { label: 'Step 3', key: 'moment-3' },
  { label: 'Step 4', key: 'moment-4' },
  { label: 'End', key: 'end' },
];

/**
 * Creates the data for Report
 *
 */
export const createStoryFlowReportData = (sortedFlows, countSelectedFlows, superVoteFlow) =>
  orderBy(
    map(sortedFlows, flow => {
      return {
        superVote: superVoteFlow?.superVoteFlowId === flow.participantId ? 1 : 0,
        votes: countSelectedFlows[flow.participantId]?.length || 0,
        actor: flow.values?.actor,
        'moment-1': flow.values?.['moment-1'],
        'moment-2': flow.values?.['moment-2'],
        'moment-3': flow.values?.['moment-3'],
        'moment-4': flow.values?.['moment-4'],
        end: flow.values?.end,
      };
    }),
    ['superVote', 'votes'],
    ['desc', 'desc'],
  );

// createStoryFlowReport(activityData?.participantData);
export const createStoryFlowReport = activityData => {
  const participantData = activityData?.participantData;
  const sortedFlows = sortBy(participantData?.flows, 'participantId');
  const countSelectedFlows = getCountSelectedFlows(
    participantData.participants,
    participantData?.participantsSelectedFlow,
  );
  const superVoteFlow = find(
    participantData?.participantsSuperVoteFlow,
    participant => !!participant.superVoteFlowId,
  );

  const headers = createStoryFlowReportHeader();
  const data = createStoryFlowReportData(sortedFlows, countSelectedFlows, superVoteFlow);

  return { headers, data };
};
