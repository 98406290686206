import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'react-redux-firebase';
import cx from 'classnames';
// library components
import { Paper, Typography, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
// cr components
import LiveScribing from './LiveScribing';
// selectors
import { useLiveScribingSelector } from 'redux/selectors/liveScribingSelectors';
// actions
import { updateEditLock } from 'redux/actions/liveScribingActions';
// utils
import { linkifyHtmlString } from 'utils/renderHtmlString';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    margin: theme.spacing(2),
    padding: 30,
    position: 'relative',
  },
  content: {
    minHeight: 238,
    marginBottom: theme.spacing(2),
  },
  title: {
    left: '50%',
    transform: 'translate(-50%, 0px)',
    position: 'absolute',
    fontSize: 16,
    fontWeight: 200,
    textAlign: 'center',
  },
  headContainer: {
    minHeight: 24,
  },
}));

/**
 * Renders live scribing write form on the Facilitator side
 */
const LiveScribingWrite = () => {
  const classes = useStyles();
  const liveScribing = useLiveScribingSelector();
  const dispatch = useDispatch();
  const { id, name, state } = liveScribing;
  const session = useSelector(state => state.firestore.data.session);
  const userId = useSelector(state => state.firebase.auth.uid);

  if (!state?.activeView || state.activeView !== 'write' || isEmpty(session) || !userId) {
    return null;
  }

  const editLockedByOtherUser = Boolean(state.editUserId !== '' && state.editUserId !== userId);
  const currentUserEditing = state.editUserId === userId;

  return (
    <Paper className={classes.container} elevation={3}>
      <div className={cx('d-flex justify-content-between', classes.headContainer)}>
        <div />
        <Typography className={classes.title}> {`${name} Live Scribe Review`}</Typography>
        {!currentUserEditing && !editLockedByOtherUser ? (
          <IconButton
            onClick={() => {
              dispatch(updateEditLock(id, userId));
            }}
            size="small"
            className="align-self-start"
          >
            <EditIcon fontSize="inherit" />
          </IconButton>
        ) : (
          <div />
        )}
        {currentUserEditing && (
          <IconButton
            onClick={() => {
              dispatch(updateEditLock(id, ''));
            }}
            size="small"
            className="align-self-start"
          >
            <DoneIcon fontSize="inherit" />
          </IconButton>
        )}
      </div>
      {currentUserEditing ? (
        <LiveScribing
          id={id}
          sessionId={session.id}
          liveScribingText={state.liveScribingText}
          isTabDisabled={currentUserEditing && !document.hasFocus()}
          userId={userId}
        />
      ) : (
        <div className={cx('d-flex justify-content-between mt-1', classes.content)}>
          <div
            className="ck-content w-100 border p-1"
            dangerouslySetInnerHTML={{ __html: linkifyHtmlString(state.liveScribingText) }}
          />
        </div>
      )}
    </Paper>
  );
};

export default LiveScribingWrite;
