import { getFirebase } from 'react-redux-firebase';

/**
 * Fetches data for a given sessionId
 *
 * @param {string} sessionId - firestore document id
 * @returns {object} the firestore document
 */
export const getSessionData = async sessionId => {
  const firebase = getFirebase();
  const db = firebase.firestore();
  const snapshot = await db.collection('sessions').doc(`${sessionId}`).get();

  return snapshot.data();
};
