import React from 'react';
import PropTypes from 'prop-types';
import { useFirestore } from 'react-redux-firebase';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';

import { Formik, Form } from 'formik';
import Button from '@material-ui/core/Button';

import ButtonSpinner from 'components/ButtonSpinner/ButtonSpinner';
import TextInput from 'components/CustomFormComponents/TextInput';

import { enqueueSnackbar } from '../../redux/actions/notifierActions';

const formSchema = Yup.object().shape({
  email: Yup.string().email().required('Required'),
});

const AddCoFacilitatorForm = ({ closeDialog }) => {
  const db = useFirestore();
  const user = useSelector(state => state.firebase.auth);
  const session = useSelector(state => state.firestore.data.session);
  const dispatch = useDispatch();

  return (
    <Formik
      initialValues={{
        email: '',
      }}
      validationSchema={formSchema}
      onSubmit={(values, { resetForm, setSubmitting, setFieldError }) => {
        if (values.email === user.email) {
          setFieldError('email', "You can't add yourself as a co-faciltator");
          setSubmitting(false);
          return;
        }

        db.collection('users')
          .where('email', '==', values.email)
          .get()
          .then(snapshot => {
            if (!snapshot.size) {
              setFieldError('email', 'No Control Room user found with this email');
              setSubmitting(false);
            } else {
              snapshot.forEach(doc => {
                const coFacilitator = doc.data();

                db.doc(`sessions/${session.id}`)
                  .set(
                    {
                      coFacilitators: db.FieldValue.arrayUnion(coFacilitator.uid),
                    },
                    { merge: true },
                  )
                  .then(async () => {
                    dispatch(
                      enqueueSnackbar({
                        message: 'Co-Facilitator Added',
                        options: { variant: 'success' },
                      }),
                    );

                    await db.collection('emailNotifications').doc().set({
                      createdAt: db.FieldValue.serverTimestamp(),
                      meetingId: session.id,
                      meetingTopic: session.topic,
                      sharedBy: user.displayName,
                      to: values.email,
                      type: 'sharedMeeting',
                    });

                    setSubmitting(false);
                    resetForm();
                    closeDialog();
                  });
              });
            }
          });
      }}
    >
      {({ isSubmitting, submitForm }) => (
        <Form style={{ paddingBottom: 20 }}>
          <TextInput
            name="email"
            type="email"
            label="Co-Facilitator Email"
            placeholder="email address your co-facilitator uses to log into Control Room"
            required
          />

          <div className="form-dialog-buttons">
            <Button
              variant="outlined"
              color="primary"
              disabled={isSubmitting}
              onClick={closeDialog}
            >
              CANCEL
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={submitForm}
            >
              ADD CO-FACILITATOR
              {isSubmitting && <ButtonSpinner />}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

AddCoFacilitatorForm.propTypes = {
  closeDialog: PropTypes.func.isRequired,
};

export default AddCoFacilitatorForm;
