import React from 'react';
import { useSelector } from 'react-redux';
// library components
import { Box, makeStyles } from '@material-ui/core';
// cr components
import OptionCloud from 'components/OptionCloud';
// selectors
import { getQuestions } from 'redux/selectors/surveySelectors';

const useStyles = makeStyles({
  optionContainer: {
    width: '100%',
  },
});

/**
 * Render an OptionCloud for the survey questions
 */
const SurveyQuestions = () => {
  const classes = useStyles();
  const questions = useSelector(getQuestions());

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mt={2}>
      <OptionCloud
        readonly
        options={questions}
        allowNew={false}
        className={classes.optionContainer}
      />
    </Box>
  );
};

export default SurveyQuestions;
