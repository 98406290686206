import React from 'react';
import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';

import './pageContent.scss';

const PageContent = ({ children, className }) => {
  return (
    <Paper variant="elevation" className={`page-content-paper ${className}`} elevation={3}>
      {children}
    </Paper>
  );
};

PageContent.defaultProps = {
  className: '',
};

PageContent.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default PageContent;
