import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
// library components
import { makeStyles, Button, Typography, Box } from '@material-ui/core';
import { Form, Formik } from 'formik';
// cr components
import TextArea from 'components/CustomFormComponents/TextArea';
import { CheckCircle } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  textarea: {
    padding: `10px 12px`,
    fontSize: 14,
  },
  label: {
    fontSize: 12,
    fontWeight: 300,
  },
  button: {
    height: 45,
    width: '100%',
    marginTop: 20,
    background: theme.palette.success.main,
    color: '#fff',
    '&:hover': {
      background: theme.palette.success.main,
    },
  },
  successIcon: {
    fontSize: 24,
    color: theme.palette.success.main,
  },
  successText: {
    fontSize: 14,
    color: theme.palette.success.main,
  },
}));

/**
 * Renders form for submit
 */
const ParticipantForm = ({
  participantDefaultName,
  participantDefaultEmail,
  participantId,
  handleSubmit,
  isParticipantSubmitted,
}) => {
  const classes = useStyles();

  const formSchema = Yup.object().shape({
    participantName: Yup.string().required('Required'),
    participantEmail: Yup.string().email().required('Required'),
  });

  return (
    <Formik
      initialValues={{
        participantId: participantId,
        participantName: participantDefaultName || '',
        participantEmail: participantDefaultEmail || '',
      }}
      validationSchema={formSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ isSubmitting, submitForm, values }) =>
        isSubmitting || isParticipantSubmitted ? (
          <Box className="w-100 d-flex flex-column align-items-center pt-2">
            <CheckCircle className={classes.successIcon} />
            <Typography className={classes.successText}>You’ve been entered!</Typography>
          </Box>
        ) : (
          <Form className="w-100">
            <TextArea
              name="participantName"
              additionalStyles={{
                input: classes.textarea,
                label: classes.label,
              }}
              placeholder="Your name"
              label="Your name"
              multiline
              fullWidth
              rows={1}
              variant="filled"
            />
            <TextArea
              name="participantEmail"
              additionalStyles={{
                input: classes.textarea,
                label: classes.label,
              }}
              placeholder="Your email"
              label="Your email"
              multiline
              fullWidth
              rows={1}
              variant="filled"
            />
            <Button className={classes.button} onClick={submitForm}>
              Enter to win
            </Button>
          </Form>
        )
      }
    </Formik>
  );
};

ParticipantForm.propTypes = {
  participantDefaultEmail: PropTypes.string,
  participantDefaultName: PropTypes.string,
  participantId: PropTypes.string.isRequired,
  isParticipantSubmitted: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
};

export default ParticipantForm;
