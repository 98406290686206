import React from 'react';
import PropTypes from 'prop-types';
// cr components
import SingleChoice from './SingleChoice';
import ShortAnswer from './ShortAnswer';
import MultipleChoice from './MultipleChoice';
import LinearScale from './LinearScale';
import Sentiment from './Sentiment';
// constants
import { VARIABLE_QUESTION_TYPES } from '../constants';

const VariableQuestionsParticipantAutoField = ({ questionType, name, choices, ...rest }) => {
  switch (questionType) {
    case VARIABLE_QUESTION_TYPES.SHORT_ANSWER:
      return <ShortAnswer name={name} />;
    case VARIABLE_QUESTION_TYPES.SINGLE_CHOICE:
      return <SingleChoice name={name} choices={choices} {...rest} />;
    case VARIABLE_QUESTION_TYPES.MULTIPLE_CHOICE:
      return <MultipleChoice name={name} choices={choices} {...rest} />;
    case VARIABLE_QUESTION_TYPES.LINEAR_SCALE:
      return <LinearScale name={name} choices={choices} {...rest} />;
    case VARIABLE_QUESTION_TYPES.SENTIMENT:
      return <Sentiment name={name} />;
    default:
      return <div />;
  }
};

VariableQuestionsParticipantAutoField.propTypes = {
  questionType: PropTypes.oneOf([...Object.values(VARIABLE_QUESTION_TYPES)]).isRequired,
  name: PropTypes.string.isRequired,
  choices: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
};

export default VariableQuestionsParticipantAutoField;
