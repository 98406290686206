import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { keys, includes } from 'lodash';
import cx from 'classnames';
// library components
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Typography } from '@material-ui/core';
// cr components
import StoryFlow from 'components/StoryFlow/Participant/StoryFlow';
// selectors
import { useStoryFlowSelector } from 'redux/selectors/storyFlowSelectors';
// actions
import { createParticipantFlow } from 'redux/actions/storyFlowActions';

const useStyles = makeStyles(theme => ({
  successColor: {
    color: theme.palette.success.main,
  },
}));

/**
 * Story Flow view for the 'create' phase on the Participant side
 */
const StoryFlowParticipantCreate = () => {
  const flowFieldsCount = 6;
  const dispatch = useDispatch();
  const classes = useStyles();
  const participantId = useSelector(state => state.firebase.auth.uid);

  const {
    id,
    participantData: { flows },
    state: { activeView },
  } = useStoryFlowSelector();
  const [participantFlow, setParticipantFlow] = useState(flows[participantId]?.values);
  const isFlowComplete =
    keys(participantFlow).length >= flowFieldsCount && !includes(participantFlow, '');

  // change flow field value
  const handleChangeFlowField = (fieldName, value) => {
    setParticipantFlow({ ...participantFlow, [fieldName]: value });
  };

  // create new flow field
  const handleCreateFlowField = (fieldName, value) => {
    dispatch(createParticipantFlow(id, participantId, fieldName, value));
  };

  return (
    <>
      <StoryFlow
        createdFlowParticipantId={participantId}
        participantFlow={participantFlow}
        handleCreateFlowField={handleCreateFlowField}
        handleChangeFlowField={handleChangeFlowField}
        context={activeView}
      />
      {isFlowComplete && (
        <>
          <CheckCircleIcon className={classes.successColor} />
          <Typography className={cx(classes.successColor, 'mb-3')} variant="body2">
            You’ve finished all the steps
          </Typography>
        </>
      )}
    </>
  );
};

export default StoryFlowParticipantCreate;
