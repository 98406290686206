import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { map, orderBy } from 'lodash';
// cr components
import CustomSelect from './CustomSelect';
import EditCardSetsButton from './EditCardSetsButton';
// selectors
import { getFacilitatorCardSets } from 'redux/selectors/cardSetsSelectors';

const CardSetSelect = ({ field, disabled, ...rest }) => {
  const facilitatorId = useSelector(state => state.firestore.data.session?.facilitatorId);

  const [cardSets, setCardSets] = useState();
  const [isUpdate, setIsUpdate] = useState(false);

  const handleConfirm = () => {
    setIsUpdate(isUpdate => !isUpdate);
  };

  const getSets = useCallback(async () => {
    const sets = await getFacilitatorCardSets(facilitatorId);
    const sortedSets = orderBy(sets, 'order', 'desc');
    setCardSets(
      map(sortedSets, set => ({
        value: set.setId,
        name: set.name || 'New Set',
      })),
    );
  }, [facilitatorId]);

  useEffect(() => {
    getSets();
  }, [getSets, isUpdate]);

  return (
    <>
      <CustomSelect
        {...{ ...field, selectOptions: cardSets ? cardSets : [] }}
        disabled={disabled}
        {...rest}
      />
      <EditCardSetsButton handleConfirm={handleConfirm} disabled={disabled} />
    </>
  );
};

export default CardSetSelect;
