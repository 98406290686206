import React from 'react';
import { Form, Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { useFirestore, useFirebase } from 'react-redux-firebase';
import * as Yup from 'yup';
import * as Sentry from '@sentry/react';
import { darken, makeStyles } from '@material-ui/core/styles';
// 3rd party components
import Button from '@material-ui/core/Button';
// cr components
import TextInput from '../CustomFormComponents/TextInput';
import ButtonSpinner from '../ButtonSpinner/ButtonSpinner';
// actions
import { enqueueErrorSnackbar } from 'redux/actions/notifierActions';
// redux types
import { PARTICIPANT_UPDATING, PARTICIPANT_UPDATED } from 'redux/types/participantSessionTypes';

const useStyles = makeStyles({
  root: {
    width: '100%',
    margin: '0 auto',
    textAlign: 'center',
  },
  button: {
    marginTop: '8px',
    backgroundColor: '#093B75',
    color: 'white',

    '&:hover': {
      backgroundColor: darken('#093B75', 0.2),
    },
  },
});

const ParticipantNameForm = ({ sessionId }) => {
  const classes = useStyles();
  const authUser = useSelector(state => state.firebase.auth);
  const firebase = useFirebase();
  const db = useFirestore();
  const dispatch = useDispatch();

  const handleSubmit = (values, { setSubmitting }) => {
    const { name } = values;

    /**
     * This would only get called if the participant has a facilitator
     * account, is logged in, and has not set a displayName yet.
     */
    const updateParticipant = () => {
      const currentUser = firebase.auth().currentUser;
      const batch = db.batch();

      dispatch({ type: PARTICIPANT_UPDATING, payload: { updating: true } });

      batch.set(
        db.collection('sessions').doc(sessionId),
        {
          numParticipants: db.FieldValue.increment(1),
        },
        { merge: true },
      );

      batch.set(
        db.doc(`participants/${sessionId}`),
        {
          [currentUser.uid]: {
            id: currentUser.uid,
            active: true,
            name,
            heartbeat: db.FieldValue.serverTimestamp(),
          },
        },
        { merge: true },
      );

      batch
        .commit()
        .then(async () => {
          localStorage.setItem(`session_${sessionId}_participant_${currentUser.uid}`, true);

          await firebase.updateAuth(
            {
              displayName: name,
            },
            !currentUser.isAnonymous,
          );

          dispatch({ type: PARTICIPANT_UPDATED, payload: { updating: false } });
        })
        .catch(err => {
          console.log('batch write error', err);
        });
    };

    if (!authUser.isEmpty) {
      updateParticipant();
    } else {
      firebase
        .auth()
        .signInAnonymously()
        .then(async () => {
          updateParticipant();
        })
        .catch(e => {
          setSubmitting(false);
          console.error(e);
          Sentry.captureException(e);

          let message = e.message;

          if (e.code === 'auth/network-request-failed') {
            message +=
              ' If your internet connection is currently active, your network administrator may be blocking requests to googleapis.com/identitytoolkit';
          }

          dispatch(enqueueErrorSnackbar(message, { autoHideDuration: 10000 }));
        });
    }
  };

  const formSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
  });

  return (
    <Formik
      initialValues={{
        name: '',
      }}
      validationSchema={formSchema}
      onSubmit={handleSubmit}
    >
      {({ submitForm, isSubmitting }) => (
        <Form className={classes.root}>
          <TextInput name="name" placeholder={`Enter your name`} />
          <Button variant="contained" color="primary" disabled={isSubmitting} onClick={submitForm}>
            Get Started
            {isSubmitting && <ButtonSpinner />}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default ParticipantNameForm;
