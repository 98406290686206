export default function renderlinkFormat(value, type, children) {
  if (type === 'email') {
    return value;
  }

  const domain =
    type === 'url' ? value.replace('http://', '').replace('https://', '').split(/[/?#]/)[0] : '';

  return `${domain}${domain && ' '}${children}`;
}
