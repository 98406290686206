import React from 'react';
import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';
import { map } from 'lodash';
// library components
import { makeStyles } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// cr components
import Idea from './Idea';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
// selectors
import { getVoteIdeas, getArchivedIdeas } from 'redux/selectors/howMightWeSelectors';

const useStyles = makeStyles({
  grid: {
    width: 'calc(100% + 10px)',
    margin: '0 -5px',
    marginTop: 10,
  },
  gridItem: {
    padding: `0 5px !important`,
    // display: 'flex',
    flexDirection: 'column',
  },
});

/**
 * Results view
 */
const Results = ({ context, type }) => {
  const smallScreen = useMediaQuery('(max-width: 425px)');
  const classes = useStyles();
  const votedIdeas = useSelector(getVoteIdeas());
  const archivedIdeas = useSelector(getArchivedIdeas());
  const sortedIdeas = type === 'archived' ? archivedIdeas : votedIdeas;

  const renderIdeas = () =>
    map(sortedIdeas, (idea, idx) => (
      <GridItem key={idea.ideaId} className={classes.gridItem} xs={12} sm={6} md={6} lg={3} xl={3}>
        <Idea
          votesCount={type === 'archived' ? 0 : idea.votedParticipants.length}
          key={`idea-${idx}`}
          idea={idea}
          draggableId={idx}
          isFinished={true}
          context={context}
        />
      </GridItem>
    ));

  return (
    <GridContainer direction={smallScreen ? 'column' : 'row'} spacing={3} className={classes.grid}>
      {renderIdeas()}
    </GridContainer>
  );
};

Results.propTypes = {
  context: PropTypes.string, // active view of state
  type: PropTypes.oneOf(['archived', 'voted']), // active tab
};

export default Results;
