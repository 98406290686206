import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useReactToPrint } from 'react-to-print';
// 3rd part components
import { Card, CardContent, Typography, Box, Divider, IconButton, Hidden } from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';
// CR components
import CrLogo from 'components/CrLogo';

const useStyles = makeStyles(theme => ({
  card: {
    margin: theme.spacing(2),
    width: '100%',
    padding: 30,
    paddingTop: 15,
  },
  titleText: {
    fontWeight: '300',
    textAlign: 'center',
  },
  headerDivider: {
    marginBottom: 30,
    [theme.breakpoints.down('sm')]: {
      marginBottom: ({ hasTabs }) => (hasTabs ? 0 : 30),
    },
  },
  mobileTabsContainer: {
    backgroundColor: '#f7f7f7',
  },
  downloadButtonContainer: {
    // paddingLeft: theme.spacing(1),
    // [theme.breakpoints.down('sm')]: {
    //   paddingLeft: '0',
    // },
  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  reorder: {
    position: 'absolute',
    top: '5px',
    color: '#093B75',
    fontSize: '14px',
    fontStyle: 'normal',
    fontFamily: 'Roboto',
    fontWeight: 'normal',
    lineHeight: '24px',
    cursor: 'pointer',
  },
}));

/**
 * Renders the facilitor 'aux' view (shows below the main card for the activity and the meeting broadcast card) for an activity.
 * If the view needs tabs, the tab controls should be passed into this component via a render function (renderTabs), not
 * included in the 'children' prop. If the view needs download button, the button should be passed into this component
 * via a render function (renderDownloadButton), not included in the 'children' prop.  In the jsx that is passed into the 'children' prop, you can use the Material UI - Box
 * component to wrap elements that you want to show or hide for printing and set the 'display' and 'displayPrint' props on the Box
 * components.
 */
const AdminActivityAuxView = ({
  // TODO: refactor to use just renderActionsLeft and renderActionsRight props
  //        (move download button into renderActionsRight wherever it is used)
  activityName,
  allowPrint,
  children,
  hasDownloadButton,
  hasTabs,
  printTitleLine1,
  printTitleLine2,
  renderActionsLeft = () => <Box />,
  renderActionsRight = () => <Box />,
  renderDownloadButton = () => <Box />,
  renderTabs = () => <Box />,
  titleText,
}) => {
  const classes = useStyles({ hasTabs });
  const printRef = useRef();
  const session = useSelector(state => state.firestore.data.session);
  const defaultPrintTitleLine1 = `${session.topic} - ${activityName}`;
  const defaultPrintTitleLine2 = 'Results';
  const renderedPrintTitleLine1 = printTitleLine1 ?? defaultPrintTitleLine1;
  const renderedPrintTitleLine2 = printTitleLine2 ?? defaultPrintTitleLine2;

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  return (
    <Card className={classes.card}>
      <CardContent className="p-0">
        <Box>
          <Typography className={classes.titleText}>{titleText}</Typography>
        </Box>
        <Box
          className="position-relative d-flex justify-content-between"
          style={{ minHeight: '40px' }}
        >
          <Box className={classes.actionsContainer}>
            <Hidden smDown>{renderTabs()}</Hidden>
            {renderActionsLeft()}
          </Box>
          <Box className={classes.actionsContainer}>
            {renderActionsRight()}
            {hasDownloadButton ? renderDownloadButton() : <Box />}
            {allowPrint && (
              <IconButton onClick={handlePrint}>
                <PrintIcon color="primary" />
              </IconButton>
            )}
          </Box>
        </Box>
        <Divider className={classes.headerDivider} />
        <Hidden mdUp>
          <Box className={classes.mobileTabsContainer}>{hasTabs ? renderTabs() : <Box />}</Box>
        </Hidden>
        <Box ref={printRef}>
          <Box display="none" displayPrint="block">
            <Box mb={4} className="text-center">
              <div className="mb-2">
                <CrLogo />
              </div>
              <Typography variant="h6" className="mb-1 text-center text-dark">
                {renderedPrintTitleLine1}
              </Typography>
              <Typography className="mb-1 text-center text-dark">
                {renderedPrintTitleLine2}
              </Typography>
            </Box>
          </Box>
          {children}
        </Box>
      </CardContent>
    </Card>
  );
};

AdminActivityAuxView.defaultProps = {
  allowPrint: false,
};

AdminActivityAuxView.propTypes = {
  children: PropTypes.node.isRequired, // jsx content of the Aux view
  titleText: PropTypes.string.isRequired, // header text of this component
  noPrint: PropTypes.bool, // override the 'printAuxView' behavior to disable printing
  printTitleLine1: PropTypes.string, // 1st line of the title in print view (can be automatically generated if activityName is passed in)
  printTitleLine2: PropTypes.string, // 2nd line of the title in print view (defaults to 'Results' if nothing is passed)
  renderActionsLeft: PropTypes.func, // render function to render any additional action buttons on the left of the header
  renderActionsRight: PropTypes.func, // render function to render any additional action buttons on the right of the header
  activityName: function (props) {
    // 'name' as it's stored in Firestore for the activity
    const currentActivityType = window.location.pathname.split('/')[4];

    if (!props['printTitleLine1'] && !props['activityName'] && props['allowPrint']) {
      return new Error(
        'You must provide an activityName when printTitleLine1 is not specified and allowPrint is true',
      );
    }
  },
  hasTabs: PropTypes.bool, // should we render tab controls?
  renderTabs: function (props) {
    // render function for tab controls if 'hasTabs' is true
    if (props['hasTabs'] && typeof props['renderTabs'] !== 'function') {
      return new Error('You must provide a renderTabs function prop when hasTabs is true');
    }
  },
  hasDownloadButton: PropTypes.bool, // should render download button?
  renderDownloadButton: function (props) {
    // render function for Download Button if 'hasDownloadButton' is true
    if (props['hasDownloadButton'] && typeof props['renderDownloadButton'] !== 'function') {
      return new Error(
        'You must provide a renderDownloadButton function prop when hasDownloadButton is true',
      );
    }
  },
};

export default AdminActivityAuxView;
