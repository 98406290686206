import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import { useTheme } from '@material-ui/core/styles';

import FeedbackIcon from 'components/FeedbackIcon/FeedbackIcon';

import './sessionFeedbackSummary.scss';

const SessionFeedbackSummary = ({ filteredFeedback }) => {
  const theme = useTheme();

  const renderSummaries = () => {
    return map(filteredFeedback, (fb, key) => (
      <div className={`${key}-summary summary`} key={`${key}-summary`}>
        <FeedbackIcon type={key} width={60} active />
        <div className="stats" style={{ color: theme.palette[key].main }}>
          <span className="count">{fb.length}</span>
          <span className="percentage">{fb.percentage}%</span>
        </div>
      </div>
    ));
  };

  return <div className="session-feedback-summary">{renderSummaries()}</div>;
};

SessionFeedbackSummary.propTypes = {
  filteredFeedback: PropTypes.object.isRequired,
};

export default SessionFeedbackSummary;
