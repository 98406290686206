import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFirestore } from 'react-redux-firebase';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { map } from 'lodash';
// material ui components
import { Typography, Tooltip } from '@material-ui/core';
// cr components
import shuffleIdeas from '../../../utils/brainwriting/shuffleIdeas';
// import CustomButton from '../../CustomButton/CustomButton';
import GrayBox from '../../GrayBox/GrayBox';
// import ButtonSpinner from '../../ButtonSpinner/ButtonSpinner';
import EditableTextWrapper from '../../EditableTextWrapper';
import ActivityParticipantDetails from 'components/ActivityParticipantDetails';
import ActivityActionButton from 'components/CustomButtons/ActivityActionButton';
// selectors
import {
  useBrainwritingSelector,
  getIsShuffleReady,
  getRoundSubmissionsComplete,
  getCanShuffle,
} from 'redux/selectors/brainwritingSelectors';
import { getSubmissionRatioThisRound } from 'redux/selectors/brainwritingSelectors';
// utils
import { makeParticipantDetailsHeaderText } from 'utils/makeParticipantDetailsHeaderText';

const useStyles = makeStyles(theme => ({
  ratioText: {
    fontSize: '18px',
    fontWeight: '300',
    marginBottom: '20px',
  },
  ratio: {
    color: '#093B75',
  },
  topic: {
    fontSize: '18px',
  },
  lastRoundMessage: {
    borderLeft: `4px solid ${theme.palette.error.main}`,
    paddingLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const BrainwritingAdminWrite = () => {
  const classes = useStyles();
  const db = useFirestore();
  const { id, currentRound, ideas, participants, topic, rounds } = useBrainwritingSelector();
  const { submitCount, participantsCount } = useSelector(getSubmissionRatioThisRound);
  const isShuffleReady = useSelector(getIsShuffleReady);
  const roundSubmissionsComplete = useSelector(getRoundSubmissionsComplete);
  const [isShuffling, setIsShuffling] = useState(false);
  const [gameIsEnding, setGameIsEnding] = useState(false);
  const activityRef = db.doc(`brainwriting/${id}`);
  const nextRound = currentRound + 1;
  const canShuffleAgain = useSelector(getCanShuffle);
  const roundIsEnded = rounds[currentRound].finished;
  const headerText = makeParticipantDetailsHeaderText(submitCount, participantsCount, 'submitted');

  useEffect(() => {
    if (roundIsEnded && isShuffling) {
      shuffleParticipants();
    }

    if (roundIsEnded && gameIsEnding) {
      const output = map(ideas, idea => idea.text);
      activityRef.set(
        {
          activeView: 'results',
          isTransitioning: false,
          output: output,
        },
        { merge: true },
      );
    }
  }, [roundIsEnded, roundSubmissionsComplete, isShuffling, gameIsEnding]);

  const endRound = () => {
    return activityRef.set(
      {
        isTransitioning: true,
        rounds: {
          [currentRound]: {
            finished: true,
          },
        },
      },
      { merge: true },
    );
  };

  const shuffleParticipants = async () => {
    const nextAssignments = shuffleIdeas(ideas, rounds);

    activityRef
      .set(
        {
          currentRound: nextRound,
          isTransitioning: false,
          rounds: {
            [nextRound]: {
              assignments: nextAssignments,
              finished: false,
            },
          },
        },
        { merge: true },
      )
      .then(() => {
        setIsShuffling(false);
      });
  };

  const handleShuffle = async () => {
    setIsShuffling(true);
    endRound();
  };

  const handleFinish = () => {
    setGameIsEnding(true);

    activityRef.set(
      {
        isTransitioning: true,
        rounds: {
          [currentRound]: {
            finished: true,
          },
        },
      },
      { merge: true },
    );
  };

  return (
    <>
      <EditableTextWrapper
        initialValue={topic}
        fieldType="textarea"
        fieldName="topic"
        firestoreCollection="brainwriting"
        documentId={id}
      >
        <GrayBox className="mb-4">
          <Typography className={classes.topic}>{topic}</Typography>
        </GrayBox>
      </EditableTextWrapper>
      <Typography className={classes.ratioText} style={{ fontSize: 20, textAlign: 'center' }}>
        Round:
        <span className={classes.ratio}> {currentRound}</span>
      </Typography>

      <ActivityParticipantDetails
        headerText={headerText}
        hasCompletedLabel="Submitted"
        hasntCompletedLabel="Hasn't Submitted"
        participantsCompletedStatus={map(participants, pId => {
          const assignments = rounds[currentRound].assignments;
          return {
            id: pId,
            completed: assignments[pId]?.submitted ? true : false,
            //completedCount not needed - will only ever be 1.
          };
        })}
      />

      <div className="d-flex flex-column justify-content-center align-items-center">
        <Tooltip
          title="Not enough submissions to shuffle"
          arrow
          placement="left"
          enterDelay={500}
          disableFocusListener={!isShuffling && isShuffleReady && canShuffleAgain}
          disableHoverListener={!isShuffling && isShuffleReady && canShuffleAgain}
          disableTouchListener={!isShuffling && isShuffleReady && canShuffleAgain}
        >
          <div>
            <ActivityActionButton
              text="Shuffle"
              onClick={handleShuffle}
              className="mb-3 mt-1"
              disabled={isShuffling || !isShuffleReady || !canShuffleAgain}
              showSpinner={isShuffling}
            />
          </div>
        </Tooltip>
        {currentRound !== 1 && (
          <ActivityActionButton
            text="End Activity"
            onClick={handleFinish}
            className="mb-3 mt-1"
            disabled={gameIsEnding}
            showSpinner={gameIsEnding}
          />
        )}
      </div>
    </>
  );
};

export default BrainwritingAdminWrite;
