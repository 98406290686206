import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CrLogo from 'components/CrLogo/CrLogo';

import { LEARN_MORE_LINK } from 'constants/general';
import { SIGNUP_LINK } from 'constants/general';

const buttonLinkStyles = makeStyles(theme => {
  return {
    outlinedPrimary: {
      '&:hover': {
        color: theme.palette.primary.main,
      },
      '&:focus': {
        color: theme.palette.primary.main,
      },
    },
  };
});

const AdvertFooter = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <CrLogo />
      <Typography
        style={{
          maxWidth: 300,
          fontSize: 20,
          lineHeight: '30px',
          fontWeight: '300',
          margin: `40px 0 40px 0`,
          textAlign: 'center',
        }}
      >
        Power up your meetings
      </Typography>
      <Button
        variant="outlined"
        color="primary"
        href={SIGNUP_LINK}
        target="_blank"
        classes={buttonLinkStyles()}
      >
        Sign Up
      </Button>
    </div>
  );
};

export default AdvertFooter;
