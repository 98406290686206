import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import cx from 'classnames';
// library components
import { Paper, Typography, InputBase, Box } from '@material-ui/core';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
// cr components
import DottedProgress from 'components/DottedProgress';

const useStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 140px',
    minWidth: 120,
    height: 154,
    margin: 5,
    padding: '12px 20px',
    border: ({ isFieldCreated }) =>
      isFieldCreated ? `1px solid ${grey[300]}` : `1px dashed ${grey[300]}`,
    boxShadow: ({ isFieldCreated }) =>
      isFieldCreated ? '0px 8px 18px rgba(0, 0, 0, 0.12)' : 'none',
    cursor: ({ context, isFinished }) => context === 'keyMoment' && !isFinished && 'pointer',
  },
  cardShadow: {
    boxShadow: '0px 8px 18px rgba(0, 0, 0, 0.12)',
  },
  cardSelected: {
    padding: ({ isSelected, context }) => isSelected && context === 'keyMoment' && '10px 18px',
    border: ({ isSelected, context }) =>
      isSelected &&
      (context === 'keyMoment'
        ? `3px solid ${theme.palette.secondary.light}`
        : `1px solid ${theme.palette.secondary.light}`),
    backgroundColor: ({ isSelected, context }) =>
      context === 'keyMoment' && isSelected && '#FFE7EF',
  },
  actorCardFilled: {
    border: ({ type, isFieldCreated }) =>
      type === 'actor' && isFieldCreated && `1px solid ${theme.palette.indigo.main}`,
  },
  endCardFilled: {
    border: ({ type, isFieldCreated }) =>
      type === 'end' && isFieldCreated && `1px solid ${theme.palette.satisfied.main}`,
  },
  actorCardVoted: {
    padding: '10px 18px',
    border: ({ isVoted, votesCount }) =>
      (isVoted || votesCount > 0) && `3px solid ${theme.palette.indigo.main}`,
    background: ({ context, type, isVoted, votesCount }) =>
      (((context === 'results' || context === 'review' || context === 'superVote') && votesCount) ||
        isVoted) &&
      type === 'actor' &&
      '#E5F6FE',
  },
  momentCardVoted: {
    padding: '10px 18px',
    border: ({ isVoted, votesCount }) =>
      (isVoted || votesCount > 0) && `3px solid ${theme.palette.secondary.light}`,
    background: ({ context, type, isVoted, votesCount }) =>
      (((context === 'results' || context === 'review' || context === 'superVote') && votesCount) ||
        isVoted) &&
      type !== 'actor' &&
      '#FFE7EF',
  },
  title: {
    marginBottom: 5,
    fontWeight: 500,
    color: ({ isSelected, isSuperVoted }) =>
      isSelected && !isSuperVoted
        ? theme.palette.secondary.light
        : isSuperVoted
          ? theme.palette.success.main
          : grey[400],
  },
  actorColor: {
    color: ({ type, isSuperVoted }) =>
      type === 'actor' && (isSuperVoted ? theme.palette.success.main : theme.palette.indigo.main),
  },
  endColor: {
    color: ({ type }) => type === 'end' && theme.palette.satisfied.main,
  },
  input: {
    maxHeight: 90,
    color: grey[800],
    cursor: ({ isFinished }) => (isFinished ? 'default' : 'pointer'),
    paddingRight: 10,
    paddingBottom: ({ context }) => context !== 'results' && 10,
  },
  text: {
    width: 'calc(100% + 18px)',
    padding: 0,
    fontSize: 15,
  },
  combineBox: {
    position: 'relative',
    top: -5,
    left: 10,
    display: 'flex',
    marginLeft: 'auto',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  combineTitle: {
    marginLeft: theme.spacing(1),
    marginRight: 4,
    fontSize: 12,
  },
  filterIcon: {
    color: '#5E5E5E',
    fontSize: 14,
  },
  removedMomentColor: {
    color: '#bdbdbd !important',
  },
}));

/**
 * Reneders the journey field (the field types: actor, moment, outcome)
 */
const Moment = ({
  journeyId,
  title,
  value,
  placeholder,
  type,
  handleCreateJourneyField,
  handleChangeJourneyField,
  handleSelectJourneyField,
  disabled,
  context,
  isSelected,
  isFinished,
  handleVote,
  isVoted,
  votesCount,
  isSmallScreen,
  className,
  votedClassName,
  isCombine,
  combineCount,
  hasCombineList,
  handleOpenCombineList,
  isSuperVoted,
  superVotedClassName,
  isRemoveMoment,
  isRemoved,
}) => {
  const theme = useTheme();

  const [fieldValue, setFieldValue] = useState(value);
  const isFieldCreated = !!fieldValue;

  const classes = useStyles({
    context,
    isSelected,
    type,
    isFieldCreated,
    isFinished,
    isVoted,
    votesCount,
    isSuperVoted,
  });

  // function for change journey field value
  const handleChangeValue = event => {
    if (context === 'create') {
      setFieldValue(event.target.value);
      handleChangeJourneyField(type, event.target.value);
    }
  };

  // function for create new journey field
  const handleCreateField = () => {
    if (context === 'create') {
      handleCreateJourneyField(type, fieldValue ? fieldValue : '');
    }
  };

  // function for select key moment and vote
  const handleSelect = event => {
    if (context === 'keyMoment' && type !== 'actor' && type !== 'end' && !isFinished) {
      handleSelectJourneyField(type, !isSelected);
    }
    if (context === 'vote' && !isFinished) {
      handleVote(journeyId, type, !isVoted);
    }
    if (context === 'superVote' && !isFinished) {
      handleVote(journeyId, type, !isSuperVoted, isVoted);
    }
    if (isRemoveMoment) {
      // remove moment from flow
      handleVote(journeyId, type, !isRemoved, event);
    }
  };

  const onOpenCombineList = () => {
    if (hasCombineList) {
      handleOpenCombineList(type, journeyId);
    }
  };

  const renderCombine = combineCount => (
    <Box className={classes.combineBox} onClick={onOpenCombineList} id="combine-list">
      <Typography className={classes.combineTitle} id="combine-list">
        {combineCount}
      </Typography>
      <FilterNoneIcon className={classes.filterIcon} id="combine-list" />
    </Box>
  );

  return (
    <Paper
      className={cx(
        classes.card,
        classes.cardSelected,
        classes.actorCardFilled,
        classes.endCardFilled,
        className,
        isVoted && votedClassName,
        isSuperVoted && superVotedClassName,
        {
          [classes.momentCardVoted]:
            type !== 'actor' &&
            ((context === 'vote' && isVoted) ||
              ((context === 'results' || context === 'review' || context === 'superVote') &&
                votesCount > 0)),
        },
        {
          [classes.actorCardVoted]:
            type === 'actor' &&
            ((context === 'vote' && isVoted) ||
              ((context === 'results' || context === 'review' || context === 'superVote') &&
                votesCount > 0 &&
                type === 'actor')),
        },
      )}
      onClick={handleSelect}
    >
      <Box display="flex">
        <Typography
          className={cx(
            classes.title,
            classes.actorColor,
            classes.endColor,
            isRemoved && classes.removedMomentColor,
          )}
          variant="body2"
        >
          {title}
        </Typography>
        {isCombine && !isRemoved && renderCombine(combineCount)}
      </Box>
      {context === 'create' ? (
        <InputBase
          className={classes.text}
          classes={{
            input: classes.input,
          }}
          placeholder={placeholder}
          value={fieldValue}
          inputProps={{ 'aria-label': 'naked' }}
          rows={context === 'results' ? (isSmallScreen ? 1 : 5) : isSmallScreen ? 1 : 6}
          multiline
          fullWidth
          onChange={handleChangeValue}
          onBlur={handleCreateField}
          readOnly={disabled || context !== 'create'}
        />
      ) : (
        <Typography className={classes.text}>{fieldValue}</Typography>
      )}
      {(context === 'results' || context === 'review') && (
        <DottedProgress
          className="mx-0 mb-1 mt-auto"
          activeColor={
            context === 'results' && isSuperVoted
              ? theme.palette.success.main
              : type === 'actor'
                ? theme.palette.indigo.main
                : theme.palette.secondary.light
          }
          activeCount={votesCount ? votesCount : 0}
          totalCount={votesCount ? votesCount : 0}
        />
      )}
    </Paper>
  );
};

Moment.propTypes = {
  journeyId: PropTypes.string, // the journey id
  title: PropTypes.string.isRequired, // the title of journey field
  value: PropTypes.string, // a value of journey field
  placeholder: PropTypes.string, // journey field placeholder
  type: PropTypes.string.isRequired, // type of field: 'actor', 'end', 'moment'
  isSelected: PropTypes.bool, // a key moment is selected
  context: PropTypes.string.isRequired, // the active phase of state
  disabled: PropTypes.bool, // journey fields disabled for editing
  isFinished: PropTypes.bool, // participant submitted your data
  handleCreateJourneyField: PropTypes.func, // function for creating values of journey fields
  handleChangeJourneyField: PropTypes.func, // function for editing values of journey fields
  handleSelectJourneyField: PropTypes.func, // function for selecting key moment
  handleVote: PropTypes.func, // function for voting key moment or actor
  isVoted: PropTypes.bool, // current journey field voted
  votesCount: PropTypes.number, // count of participants which voted for this journey field
  className: PropTypes.string, // name of moment card style
  superVotedClassName: PropTypes.string, // name of super vote card style
  isRemoveMoment: PropTypes.bool, // if true - users can remove moments
  isRemoved: PropTypes.bool, // if true - moment is removed from flow
};

export default Moment;
