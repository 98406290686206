import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { split, last } from 'lodash';
import cx from 'classnames';
// library components
import { makeStyles, Box, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import TimerIcon from '@material-ui/icons/Timer';
import ScheduleIcon from '@material-ui/icons/Schedule';
// utils
import { getActivityActiveView } from 'utils/getActivityConfig';

const useStyles = makeStyles(theme => ({
  activity: {
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
    height: 35,
    margin: 2,
    padding: '0 12px',
    borderRadius: 5,
    background: '#EEEEEE',
    color: '#898989',
    fontSize: 14,
  },
  active: {
    background: '#D8D8D8',
    color: '#333333',
    fontSize: 14,
  },
  icon: {
    color: '#898989',
    fontSize: 18,
  },
  completeIcon: {
    color: theme.palette.success.main,
  },
  activeIcon: {
    color: '#333333',
  },
}));

/**
 * Renders activity with Name and Status
 */
const Activity = ({ activityName, activityStatus, activityType, activityId }) => {
  const classes = useStyles();

  const [activityActiveView, setActivityActiveView] = useState();
  const [didMount, setDidMount] = useState(false);

  const name = last(split(activityName, ':'));

  // get activity status from firebase
  const getData = useCallback(async () => {
    if (didMount) {
      const data = await getActivityActiveView(activityType, activityId);
      setActivityActiveView(data);
    }
  }, [activityId, activityType, didMount]);

  useEffect(() => {
    setDidMount(true);
    getData();
    return () => setDidMount(false);
  }, [getData]);

  // render activity status icon
  const renderActivityStatus = () => {
    switch (activityActiveView) {
      case 'results':
        return <CheckCircleIcon className={cx(classes.icon, classes.completeIcon)} />;
      case 'pending':
        return <ScheduleIcon className={classes.icon} />;
      default:
        return (
          <TimerIcon
            className={cx(classes.icon, activityStatus === 'active' && classes.activeIcon)}
          />
        );
    }
  };

  return (
    <Box className={cx(classes.activity, activityStatus === 'active' && classes.active)}>
      <Typography className="pr-1">{name}</Typography>
    </Box>
  );
};

Activity.propTypes = {
  activityName: PropTypes.string,
  activityStatus: PropTypes.string,
  activityType: PropTypes.string.isRequired,
  activityId: PropTypes.string.isRequired,
};

export default Activity;
