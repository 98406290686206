import React from 'react';
// library components
import { Box, Typography } from '@material-ui/core';
// styles
import { useAssessmentStyles } from '../Shared/useAssessmentStyles';

/**
 * Assessment view for the 'start' phase on the Participant side
 */
const AssessmentParticipantStart = () => {
  const commonClasses = useAssessmentStyles();

  return (
    <div className="position-relative d-flex flex-column align-items-center w-100">
      <Box className="mb-2 mb-sm-5" mt={{ xs: -5, sm: -3 }}>
        <Typography className={commonClasses.questionText}>
          Your facilitator will start the assessment soon
        </Typography>
      </Box>
    </div>
  );
};

export default AssessmentParticipantStart;
