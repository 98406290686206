import React from 'react';
import CheckIcon from '@material-ui/icons/Check';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { filter, map, size, uniq, orderBy, indexOf } from 'lodash';

import { makeStyles } from '@material-ui/styles';

import { useDispatch, useSelector } from 'react-redux';
import { openStaffingChecklistModal } from 'redux/actions/staffingActions';
import { selectProject } from 'redux/selectors/staffing/getProject';

import moment from 'moment';

import './checklist.scss';
import { PROJECTS_CHECKLISTS_SEEDS } from 'constants/staffing';

const useStyles = makeStyles(theme => ({
  tickIcon: {
    fontSize: 15,
    color: '#02A05C',
  },
  chevronLeft: {
    fontSize: 15,
    color: '#898989',
    marginLeft: 5,
  },
}));

const ChecklistsSection = ({ id }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const getProject = selectProject();
  const project = useSelector(state => getProject(state, id));

  //get all checklists categories in the project, and sort it by the order of the checklists seed object
  const projectChecklistsCategory = orderBy(
    uniq(map(Object.values(project?.checklists || {}), checklist => checklist.category)),
    m => {
      return indexOf(Object.keys(PROJECTS_CHECKLISTS_SEEDS), m);
    },
  );

  if (!project) {
    return null;
  }

  return (
    <div className="checklistCard">
      {map(projectChecklistsCategory, (item, index) => {
        const numberOfAllTasks = size(
          filter(Object.values(project?.checklists || {}), m => m.category === item),
        );
        const numberOfCompletedTasks = size(
          filter(Object.values(project?.checklists || {}), m => m.category === item && m.completed),
        );
        const numberOfOverDueTasks = size(
          filter(
            Object.values(project?.checklists || {}),
            m =>
              m.category === item && !m.completed && moment(new Date()).isAfter(moment(m.dueDate)),
          ),
        );

        const areAllTasksCompleted = numberOfAllTasks === numberOfCompletedTasks;

        return (
          <div
            onClick={() => {
              dispatch(openStaffingChecklistModal(true, project.id, item));
            }}
            className={
              index + 1 === size(projectChecklistsCategory) ? 'checklistRowLast' : 'checklistRow'
            }
            key={index.toString()}
          >
            <p className="checklistTitle">{item}</p>
            <div className="d-flex align-items-center">
              {numberOfOverDueTasks > 0 && (
                <span className="checklistCount overdueLabel">
                  {numberOfOverDueTasks} overdue tasks!
                </span>
              )}

              {areAllTasksCompleted && <CheckIcon className={classes.tickIcon} />}

              <p
                className={`checklistCount mb-0 ${areAllTasksCompleted ? 'completedChecklist' : ''}`}
              >
                {numberOfCompletedTasks}/{numberOfAllTasks}
              </p>

              <ChevronRightIcon className={classes.chevronLeft} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ChecklistsSection;
