import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { filter, sortBy, size, each, isEmpty, find } from 'lodash';
// library components
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// cr components
import ActivityParticipantDetails from 'components/ActivityParticipantDetails';
import ActivityPromptAdmin from 'components/ActivityPromptAdmin';
import ActivityActionButton from 'components/CustomButtons/ActivityActionButton';
import CategoriesAdmin from 'components/Categories/CategoriesAdmin';
import ActiveCardSetAdmin from './ActiveCardSetAdmin';
// selectors
import {
  useCardSortingSelector,
  getParticipantsCompletedCategorize,
} from 'redux/selectors/cardSortingSelectors';
import { getActiveSet } from 'redux/selectors/cardSetsSelectors';
// actions
import { updateActivityView, createCard, deleteCards } from 'redux/actions/cardSortingActions';
// config
import { CARD_SORTING_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';
// utils
import { makeParticipantDetailsHeaderText } from 'utils/makeParticipantDetailsHeaderText';

const useStyles = makeStyles(theme => ({
  promptAdmin: {
    marginBottom: theme.spacing(1),
  },
}));

/**
 * Card Sorting view for the 'categorize' phase on the Facilitator side
 */
const CardSortingAdminCategorize = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const cardSorting = useCardSortingSelector();
  const {
    id,
    prompt,
    config: { categories, primaryCategory, cardSet },
    participantData: { cards },
  } = cardSorting;

  const [activeCardSet, setActiveCardSet] = useState(cards);

  const isSetChanged = !isEmpty(cards) && !find(cards, card => card.cardSetId === cardSet);

  // get cards for active set
  const getCards = useCallback(async () => {
    const set = await getActiveSet(cardSet);
    each(set.cards, card => {
      dispatch(
        createCard(id, cardSet, card.order, card.icon || '', card.text || '', card.imageUrl || ''),
      );
    });
    setActiveCardSet(sortBy(set.cards, ['order', 'cardId']));
  }, [cardSet]);

  useEffect(() => {
    if (isEmpty(cards)) {
      getCards();
    }
    if (isSetChanged) {
      dispatch(
        deleteCards(id, () => {
          setActiveCardSet({});
        }),
      );
    }
  }, [getCards, isSetChanged]);

  const sortedCategories = sortBy(categories, ['order', 'value']);

  const participantsHaveCategorizedData = useSelector(
    getParticipantsCompletedCategorize(size(activeCardSet)),
  );
  // sorting categorized items
  const participantsCategorizedCount = filter(participantsHaveCategorizedData, {
    completed: true,
  }).length;
  const participantsCategorizedCountText = makeParticipantDetailsHeaderText(
    participantsCategorizedCount,
    participantsHaveCategorizedData.length,
    'sorting round 1 cards',
  );

  const onUpdateActivityView = () => {
    dispatch(updateActivityView(id, 'review'));
  };

  return (
    <Box className="d-flex flex-column justify-content-center">
      <ActivityPromptAdmin
        prompt={prompt}
        collection={CONFIG.collection}
        activityId={id}
        className={classes.promptAdmin}
      />
      <ActiveCardSetAdmin cardSetId={cardSet} />
      <CategoriesAdmin categories={sortedCategories} primaryCategoryId={primaryCategory} />
      <ActivityParticipantDetails
        headerText={participantsCategorizedCountText}
        hasCompletedLabel="Sorted Round 1 Cards"
        hasntCompletedLabel="Hasn't Sorted Round 1 Cards"
        completedCountMax={size(activeCardSet)}
        participantsCompletedStatus={participantsHaveCategorizedData}
      />
      <ActivityActionButton text="Review Results" onClick={onUpdateActivityView} delayReady />
    </Box>
  );
};

export default CardSortingAdminCategorize;
