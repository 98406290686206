import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import ButtonSpinner from '../../../components/ButtonSpinner/ButtonSpinner';
import TextInput from '../../../components/CustomFormComponents/TextInput';
import { PRIVACY_POLICY_LINK } from 'constants/general';

import './index.css';

const queryString = require('query-string');

const LoginForm = ({ loading, onSubmit, loginButtonLabel, helperText }) => {
  const urlParams = queryString.parse(window.location.search);
  const email = urlParams.loginEmail ? decodeURIComponent(urlParams.loginEmail) : '';
  const submitRef = useRef();

  useEffect(() => {
    if (email) {
      submitRef.current.click();
    }
  }, [email]);

  return (
    <Formik
      initialValues={{ email }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email('Please enter a valid email').required(),
      })}
      onSubmit={onSubmit}
    >
      <Form>
        {helperText && (
          <div style={{ marginBottom: 10 }}>
            <Typography variant="body1">{helperText}</Typography>
          </div>
        )}
        <div>
          <TextInput name="email" placeholder="your.email@gmail.com" fullWidth />
        </div>
        <div>
          <Button
            ref={submitRef}
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
            fullWidth
          >
            {loginButtonLabel}
            {loading && <ButtonSpinner />}
          </Button>
        </div>
        <div className="login-disclaimer">
          <p className="disclaimerText">
            By Signing In you agree to our{' '}
            <a href={PRIVACY_POLICY_LINK} target="_blank">
              Privacy Policy
            </a>
          </p>
        </div>
      </Form>
    </Formik>
  );
};

LoginForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loginButtonLabel: PropTypes.string,
  helperText: PropTypes.string,
};

LoginForm.defaultProps = {
  loginButtonLabel: 'Sign In',
};

export default LoginForm;
