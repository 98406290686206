import { map, orderBy, max, forEach, join, each } from 'lodash';

/**
 * Creates the output array for a Heat-Mapping actvity
 * @param {Object} sketches
 * @param {Object} images
 * @returns {Array<{images: Array, superVotes: Number, winner: Boolean}>}
 */
export const createOutput = (sketches, images) => {
  // create initial output array
  const output = map(sketches, sketch => ({
    images: map(sketch.images, imageId => images[imageId].url),
    superVotes: sketch.superVotePoints || 0,
    votes: sketch.votedFor?.length || 0,
    winner: false,
    bigIdeas: sketch.adminComments?.bigIdeas || '',
    concerns: sketch.adminComments?.concerns || '',
  }));

  // create array of votes
  const votes = map(output, 'superVotes');

  // determine hights vote count
  const highVote = max(votes);

  // tag notes with the highest votes as 'winner'
  forEach(output, (sketch, idx) => {
    if (sketch.superVotes === highVote) {
      output[idx].winner = true;
    }
  });

  // sort array and return
  return orderBy(output, ['superVotes'], ['desc', 'asc']);
};

/**
 * Creates the array for Report Header
 *
 */
export const createHeatMappingReportHeader = () => {
  return [
    { label: 'Sketch', key: 'sketchId' },
    { label: 'Super Votes', key: 'superVotes' },
    { label: 'Votes', key: 'votes' },
    { label: 'Big Ideas', key: 'bigIdeas' },
    { label: 'Concerns', key: 'concerns' },
    { label: 'Image', key: 'imageNum' },
    { label: 'Image URL', key: 'imageURL' },
  ];
};

/**
 * Creates the data for Report
 *
 */
export const createHeatMappingReportData = (sketches, images) => {
  const data = [];
  //TODO: @reggie is there a way to do this without delcaring the aray?
  forEach(sketches, (sketch, sIndex) => {
    forEach(sketch.images, (imageId, iIndex) => {
      data.push({
        // TODO: Lookup participant Name instead of having id here..
        sketchId: sIndex,
        imageNum: iIndex + 1,
        superVotes: sketch.superVotePoints || 0,
        votes: sketch.votedFor?.length || 0,
        imageURL: images[imageId].url,
        bigIdeas: sketch.adminComments?.bigIdeas || '',
        concerns: sketch.adminComments?.concerns || '',
      });
    });
  });

  return orderBy(
    data,
    ['superVotes', 'votes', 'sketchNum', 'imageNum'],
    ['desc', 'desc', 'asc', 'asc'],
  );
};

export const createHeatMappingReport = activityData => {
  const headers = createHeatMappingReportHeader();
  const data = createHeatMappingReportData(
    activityData?.participantData?.sketches,
    activityData?.participantData?.images,
  );
  return { headers, data };
};
