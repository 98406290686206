import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isEmpty, isLoaded, useFirestoreConnect } from 'react-redux-firebase';

import NoteAndCategorizeAdminCategorize from 'components/NoteAndCategorize/Admin/NoteAndCategorizeAdminCategorize';
import NoteAndCategorizeAdminRoundRobin from 'components/NoteAndCategorize/Admin/NoteAndCategorizeAdminRoundRobin';
import NoteAndCategorizeAdminReview from 'components/NoteAndCategorize/Admin/NoteAndCategorizeAdminReview';
import NoteAndCategorizeAdminVote from 'components/NoteAndCategorize/Admin/NoteAndCategorizeAdminVote';
import NoteAndCategorizeAdminResults from 'components/NoteAndCategorize/Admin/NoteAndCategorizeAdminResults';
import AdminActivityTemplate from 'components/AdminActivityTemplate';

import { useNoteAndCategorizeSelector } from 'redux/selectors/noteAndCategorizeSelectors';

import { NOTE_AND_CATEGORIZE_DERIVATIVE_CONFIG_LIST } from '@voltage-control/control-room-activities-config';

const views = {
  categorize: NoteAndCategorizeAdminCategorize,
  roundRobin: NoteAndCategorizeAdminRoundRobin,
  review: NoteAndCategorizeAdminReview,
  vote: NoteAndCategorizeAdminVote,
  results: NoteAndCategorizeAdminResults,
};

const AdminNoteAndCategorize = () => {
  const routeParams = useParams();
  const noteAndCategorize = useNoteAndCategorizeSelector();
  const session = useSelector(state => state.firestore.data.session);
  const meetingParticipants = useSelector(state => state.firestore.data.participants);

  // hooks up a firestore listener to Redux
  useFirestoreConnect(() => [
    {
      collection: 'noteAndCategorize',
      doc: `${routeParams.activityId}`,
      storeAs: 'noteAndCategorize',
    },
  ]);

  // ensure all of the data we need is loaded
  if (
    !isLoaded(session) ||
    isEmpty(session) ||
    !isLoaded(noteAndCategorize) ||
    isEmpty(noteAndCategorize) ||
    !isLoaded(meetingParticipants) ||
    routeParams.activityId !== noteAndCategorize?.id
  ) {
    return null;
  }
  // get current view
  const CurrentView = views[noteAndCategorize.state.activeView];
  // get current derived noteAndCategorize
  const config =
    NOTE_AND_CATEGORIZE_DERIVATIVE_CONFIG_LIST[noteAndCategorize.config?.derivative || 'general'];

  return (
    <AdminActivityTemplate
      activityConfig={config}
      activityData={noteAndCategorize}
      session={session}
      CurrentView={CurrentView}
    />
  );
};

export default AdminNoteAndCategorize;
