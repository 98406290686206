import React from 'react';
import PropTypes from 'prop-types';
import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';

import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    color: grey[600],
    fontSize: props => props.fontSize || 11,
    textTransform: ({ uppercase }) => (uppercase ? 'uppercase' : 'initial'),
    marginBottom: theme.spacing(1) / 2,
  },
}));

const SmallLabel = ({ text, className, uppercase, fontSize }) => {
  const classes = useStyles({ fontSize, uppercase });

  return (
    <Typography classes={classes} className={className}>
      {text}
    </Typography>
  );
};

SmallLabel.defaultProps = {
  className: '',
  uppercase: true,
};

SmallLabel.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default SmallLabel;
