import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
// library components
import { Formik, Form } from 'formik';
import { Button } from '@material-ui/core';
// cr components
import QuestionTypeSelect from './QuestionTypeSelect';
import TextArea from '../TextArea';
// constants
import { VARIABLE_QUESTION_TYPES } from './QuestionTypes/constants';
import {
  VARIABLE_QUESTIONS_FIELDS_MAP,
  VARIABLE_QUESTIONS_FIELDS_SCHEMA_MAP,
  VARIABLE_QUESTIONS_INITIAL_VALUES_MAP,
} from './QuestionTypes/Facilitator/constants';

const QuestionForm = ({
  questionId,
  isNew,
  closeDialog,
  onAddQuestion,
  onEditQuestion,
  initialValues = {},
}) => {
  const initialFormConfigState = {
    questionType: VARIABLE_QUESTION_TYPES.SHORT_ANSWER,
    initialValues: {
      questionType: VARIABLE_QUESTION_TYPES.SHORT_ANSWER,
      question: '',
    },
    formSchema: {
      questionType: Yup.string().required('Required'),
      question: Yup.string().required('Required'),
    },
  };

  const formConfigReducer = (state, action) => {
    switch (action.type) {
      case 'reset': {
        return { ...initialFormConfigState };
      }
      case 'questionType': {
        return {
          questionType: action.payload,
          formSchema: {
            ...initialFormConfigState.formSchema,
            ...VARIABLE_QUESTIONS_FIELDS_SCHEMA_MAP[action.payload],
          },
          initialValues: {
            ...initialFormConfigState.initialValues,
            questionType: action.payload,
            ...VARIABLE_QUESTIONS_INITIAL_VALUES_MAP[action.payload],
          },
        };
      }
      case 'setInitialValues': {
        return {
          questionType: action.payload.questionType,
          formSchema: {
            ...initialFormConfigState.formSchema,
            ...VARIABLE_QUESTIONS_FIELDS_SCHEMA_MAP[action.payload.questionType],
          },
          initialValues: { ...action.payload },
        };
      }
    }
  };

  const [formConfigState, dispatchFormConfig] = useReducer(formConfigReducer, {
    ...initialFormConfigState,
  });

  useEffect(() => {
    if (!isNew && initialValues.questionType) {
      dispatchFormConfig({
        type: 'setInitialValues',
        payload: { ...initialValues },
      });
    }
  }, [isNew, initialValues.questionType]);

  const setQuestionType = questionType => {
    dispatchFormConfig({
      type: 'questionType',
      payload: questionType,
    });
  };

  const renderQuestionFields = () => VARIABLE_QUESTIONS_FIELDS_MAP[formConfigState.questionType];

  return (
    <Formik
      initialValues={formConfigState.initialValues}
      validationSchema={Yup.object().shape(formConfigState.formSchema)}
      onSubmit={(values, { resetForm, setSubmitting }) => {
        const { questionType, question, ...otherValues } = values;
        let data = { questionType, question };

        data = { ...data, ...otherValues };

        isNew ? onAddQuestion(data) : onEditQuestion(questionId, data);

        setSubmitting(false);
        closeDialog();
        resetForm();
      }}
      enableReinitialize
    >
      {({ submitForm, resetForm }) => (
        <Form style={{ paddingBottom: 20 }}>
          <QuestionTypeSelect setQuestionType={setQuestionType} />
          <TextArea name="question" label="Question" rows={1} required />
          {renderQuestionFields()}
          <div className="form-dialog-buttons">
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                closeDialog();
                resetForm();
              }}
            >
              CANCEL
            </Button>
            <Button variant="contained" color="primary" onClick={submitForm}>
              {isNew ? 'ADD' : 'SAVE'}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

QuestionForm.propTypes = {
  isNew: PropTypes.bool,
  closeDialog: PropTypes.func.isRequired,
  onAddQuestion: PropTypes.func,
  onEditQuestion: PropTypes.func,
};

export default QuestionForm;
