import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useFirestore } from 'react-redux-firebase';
import { useSelector, useDispatch } from 'react-redux';
import { map, keys } from 'lodash';
import moment from 'moment';
import * as Yup from 'yup';
// 3rd party components
import { Formik, Form } from 'formik';
import { Button, Box } from '@material-ui/core';
// CR components
import ButtonSpinner from 'components/ButtonSpinner/ButtonSpinner';
import TextInput from 'components/CustomFormComponents/TextInput';
import MaterialDateTime from 'components/CustomFormComponents/MaterialDateTime';
import MeetingTemplatesSelect from 'components/CustomFormComponents/MeetingTemplatesSelect';
import TextOptionsCluster from 'components/CustomFormComponents/TextOptionsCluster';
// selectors
import {
  facilitatorSubscriptionSelector,
  useFacilitatorSessionSelector,
} from 'redux/selectors/facilitatorSessionSelectors';
// actions
import { openPremiumSubscriptionDialog } from 'redux/actions/facilitatorSessionActions';
import { openMuralAuthDialog } from 'redux/actions/facilitatorSessionActions';

const formSchema = Yup.object().shape({
  topic: Yup.string().required('Required'),
  shortDesc: Yup.string(),
  startTime: Yup.string().required('Required'),
});

const CreateSessionForm = ({
  closeDialog,
  setIncludedActivities,
  setSessionId,
  setSelectedMeetingTemplate,
  resetCreateSessionDialogState,
}) => {
  const db = useFirestore();
  const user = useSelector(state => state.firebase.profile);
  const [hasShownMuralDialog, setHasShownMuralDialog] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { isSubscriber } = useSelector(facilitatorSubscriptionSelector());
  const { isInternalUser, isOpenMuralAuthDialog } = useFacilitatorSessionSelector();
  const isMuralAuthorized = user.isMuralAuthorized;

  const parseTemplateActivities = templateActivities => {
    // split activity.id on '-' to get the activity type after the '-'
    return map(templateActivities, activity => activity.id.split('-')[1]);
  };

  const checkMuralAuth = (values, resetForm) => {
    if (!keys(values.templateActivities).length) return;

    const activities = parseTemplateActivities(values.templateActivities);

    if (
      activities.includes('mural') &&
      !isMuralAuthorized &&
      !isOpenMuralAuthDialog &&
      !hasShownMuralDialog
    ) {
      dispatch(openMuralAuthDialog(true));
      setHasShownMuralDialog(true);
    }
  };

  return (
    <Formik
      initialValues={{
        topic: '',
        startTime: moment().format(),
        meetingTemplate: '',
        templateActivities: {},
      }}
      validationSchema={formSchema}
      onSubmit={async (values, { resetForm, setSubmitting }) => {
        const newSessionRef = db.collection('sessions').doc();

        db.collection('sessions')
          .doc(newSessionRef.id)
          .set({
            ...values,
            activeRoute: 'welcome',
            activities: [],
            highlight: '',
            coFacilitators: [],
            createdAt: db.FieldValue.serverTimestamp(),
            facilitatorId: user.uid,
            feedback: [],
            id: newSessionRef.id,
            numParticipants: 0,
            welcomeMessage: '<p>Welcome! Your facilitator will start the session soon.</p>',
          })
          .then(() => {
            if (values.meetingTemplate && keys(values.templateActivities).length) {
              setSelectedMeetingTemplate(values.meetingTemplate);
              setSubmitting(false);
              resetForm();
              setSessionId(newSessionRef.id);
              setIncludedActivities(parseTemplateActivities(values.templateActivities));
            } else {
              setSubmitting(false);
              resetCreateSessionDialogState();
              resetForm();
              closeDialog();
              history.push('/admin/sessions');
            }
          })
          .catch(err => {
            console.error('firestore write error', err);
            setSubmitting(false);
          });
      }}
    >
      {({ isSubmitting, submitForm, values, resetForm }) => (
        <Form style={{ paddingBottom: 20 }} onChange={checkMuralAuth(values, resetForm)}>
          <TextInput
            name="topic"
            type="text"
            label="Title/Topic"
            placeholder="My Awesome Meeting Topic"
            required
            autoFocus={true}
          />
          <MaterialDateTime name="startTime" label="Date/Time" />
          <Box
            onClick={() => {
              if (!isSubscriber) dispatch(openPremiumSubscriptionDialog(true));
            }}
          >
            <MeetingTemplatesSelect
              name="meetingTemplate"
              label={'Meeting Template ' + (isSubscriber ? '(Optional)' : '(Premium)')}
              disabled={!isSubscriber}
              isInternalUser={isInternalUser}
            />
          </Box>

          {values.meetingTemplate && (
            <TextOptionsCluster
              name="templateActivities"
              canAddNew={false}
              label="Activities included"
            />
          )}

          <div className="form-dialog-buttons justify-content-between">
            <Button
              variant="outlined"
              color="primary"
              disabled={isSubmitting}
              onClick={closeDialog}
            >
              CANCEL
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={submitForm}
            >
              {values.meetingTemplate && keys(values.templateActivities).length
                ? 'NEXT'
                : 'CREATE MEETING'}
              {isSubmitting && <ButtonSpinner />}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

CreateSessionForm.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  /**
   * Following functions are only used when a meeting
   * template has been selected. They set state on the
   * CreateSessionDialog component so that it can pass that state
   * down to the AddActivityForm components that will be rendered
   * to allow settings to be set on each activity included in the template
   */
  setIncludedActivities: PropTypes.func,
  setSessionId: PropTypes.func,
  setSelectedMeetingTemplate: PropTypes.func,
  resetCreateSessionDialogState: PropTypes.func,
};

export default CreateSessionForm;
