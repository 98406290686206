import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'react-redux-firebase';
// librrary components
import { IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
// cr components
import WelcomeMessageForm from './WelcomeMessageForm';
// utils
import { linkifyHtmlString } from 'utils/renderHtmlString';

const SessionWelcomeMessageAdmin = () => {
  const [isEditing, setIsEditing] = useState(false);
  const session = useSelector(state => state.firestore.data.session);

  if (isEmpty(session)) return null;

  return (
    <>
      {isEditing || !session.welcomeMessage ? (
        <WelcomeMessageForm
          initialValues={{ welcomeMessage: session.welcomeMessage || '' }}
          setIsEditing={setIsEditing}
          sessionId={session.id}
        />
      ) : (
        <div className="d-flex justify-content-between">
          <div
            className="ck-content w-100"
            style={{ fontSize: 18 }}
            dangerouslySetInnerHTML={{ __html: linkifyHtmlString(session.welcomeMessage) }}
          />
          <IconButton
            onClick={() => {
              setIsEditing(true);
            }}
            size="small"
            className="align-self-start"
          >
            <EditIcon fontSize="inherit" />
          </IconButton>
        </div>
      )}
    </>
  );
};

export default SessionWelcomeMessageAdmin;
