import React from 'react';
import { useDispatch } from 'react-redux';

// library components
import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

//cr components
import ProjectRoleActionForm from 'components/Forms/Staffing/ProjectRoleActionForm';

//actions & selectors
import { openProjectRoleActionDialog } from 'redux/actions/staffingActions';
import { useStaffingSelector } from 'redux/selectors/staffing/staffingSelectors';

const useStyles = makeStyles(theme => ({
  paperWidthSm: {
    maxWidth: 760,
  },
  contentRoot: {
    padding: theme.spacing(5),
  },
  dialogHeader: {
    position: 'relative',
    maxWidth: 760,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dialogHeaderRoot: {
    padding: '24px 8px',
  },
  dialogHeaderTitle: {
    fontSize: 16,
    fontWeight: 500,
  },
  cancelButton: {
    position: 'absolute',
    right: theme.spacing(1),
  },
}));

const OpenProjectRoleActionDialog = () => {
  const { isOpenProjectRoleActionDialog, projectRoleActionDialogType } = useStaffingSelector();

  const dispatch = useDispatch();
  const classes = useStyles();

  // Handle Close Dialog
  const closeDialog = () => dispatch(openProjectRoleActionDialog(false));

  // Render Dialog title
  const renderTitle = () => {
    if (projectRoleActionDialogType === 'accept') return 'Accept Project Role';
    if (projectRoleActionDialogType === 'reject') return 'Reject Project Role';

    return '';
  };

  return (
    <div>
      <Dialog
        open={isOpenProjectRoleActionDialog}
        onClose={closeDialog}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        classes={{
          paperWidthSm: classes.paperWidthSm,
        }}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle
          disableTypography
          className={classes.dialogHeader}
          classes={{ root: classes.dialogHeaderRoot }}
        >
          <Typography className={classes.dialogHeaderTitle}>{renderTitle()}</Typography>
          <IconButton
            className={classes.cancelButton}
            aria-label="close"
            onClick={() => closeDialog('')}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers className={classes.contentRoot}>
          <ProjectRoleActionForm closeDialog={closeDialog} />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default OpenProjectRoleActionDialog;
