import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/storage';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { filter, find, map, sortBy, size, last } from 'lodash';
import cx from 'classnames';
// library components
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Typography, Box, Button } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
// cr components
import LightningDemo from 'components/LightningDemo/Participant/LightningDemo';
import InfoDialog from 'components/Dialogs/InfoDialog';
// selectors
import {
  useLightningDemoSelector,
  getParticipantDemos,
} from 'redux/selectors/lightningDemoSelectors';
// actions
import { createLightningDemo, deleteLightningDemo } from 'redux/actions/lightningDemoActions';
// constants
import { LIGHTNING_DEMO_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';

const useStyles = makeStyles(theme => ({
  title: {
    paddingRight: ({ smallScreen }) => !smallScreen && theme.spacing(12),
    marginLeft: 'auto',
    marginRight: 'auto',
    fontSize: 16,
    fontWeight: 200,
    textAlign: 'center',
  },
  completeTitle: {
    margin: 'auto',
    fontSize: 16,
    fontWeight: 200,
    textAlign: 'center',
  },
  errorTextColor: {
    color: theme.palette.dissatisfied.main,
  },
  successColorText: {
    color: theme.palette.success.main,
  },
}));

/**
 * Lightning Demo view for the 'upload' phase on the Participant side
 */
const LightningDemoParticipantUpload = () => {
  // max number of demos for participant
  const maxDemosCount = 4;
  const dispatch = useDispatch();

  const smallScreen = useMediaQuery('(max-width: 750px)');
  const classes = useStyles({ smallScreen });

  const participantId = useSelector(state => state.firebase.auth.uid);
  const lightningDemo = useLightningDemoSelector();
  const {
    id,
    state: { activeView },
  } = lightningDemo;

  // get participant help from config
  const { participantHelp } = CONFIG;
  const participantHelpData = participantHelp[activeView];

  // get participant demos
  const demos = useSelector(getParticipantDemos(participantId));

  const [isModalOpen, setIsModalOpen] = useState(false);

  // on open Info dialog
  const openDialog = () => {
    setIsModalOpen(true);
  };

  // on close Info dialog
  const closeDialog = () => {
    setIsModalOpen(false);
  };

  // demos include image, big ideas, source title
  const completedDemos = filter(
    demos,
    demo => demo.imageId && demo.bigIdeas !== '' && demo.source !== '',
  );
  // demos include image, but not include big ideas or source title
  const notReadyDemos = filter(
    demos,
    demo => demo.imageId && (demo.bigIdeas === '' || demo.source === ''),
  );
  // the number of demos which include image, big ideas, source title
  const completedDemosCount = size(completedDemos);

  // the number of demos
  const demosCount = size(demos);
  // sort demos by order
  const sortedDemos = sortBy(demos, 'order');

  useEffect(() => {
    if (completedDemosCount === demosCount && demosCount !== maxDemosCount) {
      const order = demosCount ? last(sortedDemos).order + 1 : 0;
      dispatch(createLightningDemo(id, participantId, order));
    }
  }, [completedDemosCount, demosCount]);

  // Delete lightning demo from firestore
  const handleDeleteLightningDemo = (demoId, imageId) => {
    if (imageId) {
      // Create a reference to the file to delete
      const deletedRef = firebase.storage().ref(`lightningDemo/${id}/${participantId}/${imageId}`);

      // Delete the file
      deletedRef
        .delete()
        .then(() => {
          // File deleted successfully
          dispatch(deleteLightningDemo(id, demoId));
        })
        .catch(err => {
          console.log('Error delete image', err);
        });
    } else {
      dispatch(deleteLightningDemo(id, demoId));
    }
  };

  // renders lightning demos
  const renderDemos = () =>
    map(sortedDemos, (demo, idx) => {
      const isNotReadyDemo = find(
        notReadyDemos,
        notReadyDemo => demo.demoId === notReadyDemo.demoId,
      );
      return (
        <LightningDemo
          key={`demo-${idx}`}
          demoId={demo.demoId}
          handleDeleteLightningDemo={handleDeleteLightningDemo}
          lightningDemoId={id}
          participantId={participantId}
          imageId={demo.imageId}
          bigIdeas={demo.bigIdeas}
          source={demo.source}
          demoUrl={demo.url}
          isNotReadyDemo={!!isNotReadyDemo}
          isFinished={false}
          context={activeView}
        />
      );
    });

  return (
    <>
      <Box className="w-100 mb-2" display="flex" alignItems="center" flexWrap="wrap">
        <Button
          color="primary"
          size="small"
          startIcon={<HelpIcon fontSize="small" color="primary" />}
          onClick={openDialog}
        >
          Activity Help
        </Button>
        <InfoDialog
          handleClose={closeDialog}
          isOpen={isModalOpen}
          title={participantHelpData.helpTitle}
        >
          {participantHelpData.helpText}
        </InfoDialog>
        <Typography
          className={cx(demosCount === maxDemosCount ? classes.completeTitle : classes.title)}
        >
          Create up to 4 demos, along with title, big idea, and optional URL.
        </Typography>
      </Box>
      <Box
        className="w-100"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        {renderDemos()}
      </Box>
      {!!notReadyDemos.length && (
        <Typography variant="body2" className={cx('mt-2 mb-1', classes.errorTextColor)}>
          Make sure you add the Big Idea and Source Title to all demos
        </Typography>
      )}
      {!notReadyDemos.length && !!completedDemosCount && (
        <>
          <CheckCircleIcon className={cx('mt-2 mb-1', classes.successColorText)} />
          <Typography className={cx('mb-2', classes.successColorText)} variant="body2">
            Added {completedDemosCount} {completedDemosCount > 1 ? 'demos' : 'demo'}
          </Typography>
        </>
      )}
    </>
  );
};

export default LightningDemoParticipantUpload;
