import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { split, last, find } from 'lodash';
import { grey } from '@material-ui/core/colors';
// library components
import { Box } from '@material-ui/core';
// cr components
import Moment from 'components/Moment';

const useStyles = makeStyles({
  line: {
    width: 0,
    height: 190,
    border: `1px solid ${grey[400]}`,
  },
});

/**
 * Renders actor and key moments fields of journey
 */
const JourneyVote = ({
  journeyId,
  keyMoment,
  keyMomentTitle,
  actor,
  context,
  handleVote,
  isActorVote,
  isMomentVote,
  actorVotesCount,
  momentVotesCount,
  isFinished,
  superVotedClassName,
  superVoteTypes,
}) => {
  const classes = useStyles();

  if (!actor || !keyMoment) {
    return null;
  }

  return (
    actor &&
    keyMoment && (
      <Box className="mr-5" display="flex" alignItems="center">
        <Moment
          title="Actor"
          value={actor}
          context={context}
          type="actor"
          handleVote={handleVote}
          journeyId={journeyId}
          isVoted={isActorVote}
          isFinished={isFinished}
          votesCount={actorVotesCount}
          superVotedClassName={superVotedClassName}
          isSuperVoted={!!find(superVoteTypes, vote => vote.type === 'actor')}
        />
        <div className={classes.line} />
        <Moment
          title={`Moment ${last(split(keyMoment, ''))}`}
          value={keyMomentTitle}
          isSelected={true}
          type={`${keyMoment}`}
          context={context}
          handleVote={handleVote}
          journeyId={journeyId}
          isVoted={isMomentVote}
          isFinished={isFinished}
          votesCount={momentVotesCount}
          superVotedClassName={superVotedClassName}
          isSuperVoted={!!find(superVoteTypes, vote => vote.type === keyMoment)}
        />
      </Box>
    )
  );
};

JourneyVote.propTypes = {
  journeyId: PropTypes.string, // the journey id
  keyMoment: PropTypes.string, // a key moment for journey
  keyMomentTitle: PropTypes.string, // a value of key moment for journey
  actor: PropTypes.string, // a value of actor journey field
  context: PropTypes.string.isRequired, // the active phase of state
  handleVote: PropTypes.func, // function for voting key moment or actor
  isActorVoteComplete: PropTypes.bool, // the participant select actor
  isMomentVoteComplete: PropTypes.bool, // the participant select moment
  actorVotesCount: PropTypes.number, // count of participants which voted for this actor
  momentVotesCount: PropTypes.number, // count of participants which voted for this moment
  isFinished: PropTypes.bool, // participant submitted your data
  superVotedClassName: PropTypes.string,
};

export default JourneyVote;
