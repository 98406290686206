import React from 'react';
import { map } from 'lodash';
import { makeStyles } from '@material-ui/styles';
// cr components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import NoteCard from './NoteCard';
// selectors
import { useNoteAndVoteSelector } from 'redux/selectors/noteAndVoteSelectors';

const useStyles = makeStyles(theme => ({
  container: {
    padding: '0 10px 20px !important',
  },
  grid: {
    width: 'calc(100% + 20px)',
    marginBottom: 0,
  },
}));

/**
 * Note & Vote view for the 'results' phase on the Participant side
 */
const NoteAndVoteParticipantResults = () => {
  const classes = useStyles();
  const { output } = useNoteAndVoteSelector();

  const renderResults = () =>
    map(output, (note, idx) => (
      <GridItem
        key={`results-${idx}`}
        className={classes.container}
        xs={12}
        sm={6}
        md={3}
        lg={3}
        xl={3}
      >
        <NoteCard note={note} context="results" />
      </GridItem>
    ));

  return (
    <GridContainer className={classes.grid} direction="row">
      {renderResults()}
    </GridContainer>
  );
};

export default NoteAndVoteParticipantResults;
