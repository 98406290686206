import { SURVEY_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';
import { enqueueErrorSnackbar } from 'redux/actions/notifierActions';
import { updateActivityView } from 'redux/actions/activityActions';
import { createOutput } from 'utils/survey';

/**
 * Add participant answer
 *
 * @param {String} surveyId
 * @param {String} participantId
 * @param {String} questionId
 * @param {String} value
 */
export const updateParticipantSurvey =
  (surveyId, participantId, questionId, value) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${surveyId}`)
      .set(
        {
          participantData: {
            answers: {
              [participantId]: {
                [questionId]: value,
              },
            },
          },
        },
        { merge: true },
      )
      .then(() => {
        dispatch(updateOutput());
      })
      .catch(err => {
        dispatch(enqueueErrorSnackbar('Error updating answers'));
        console.error(err);
      });
  };

export const updateOutput =
  () =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;
    const {
      id,
      config: { questions },
      participantData: { answers },
    } = getState().firestore.data[CONFIG.collection];

    const output = createOutput(questions, answers);

    db()
      .doc(`${CONFIG.collection}/${id}`)
      .update({ output })
      .catch(err => {
        dispatch(enqueueErrorSnackbar('Error updating output'));
        console.error(err);
      });
  };

/**
 * Create the activity output and update the view to 'results'
 * @param {String} surveyId
 */
export const endSurvey =
  surveyId =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    const {
      config: { questions },
      participantData: { answers },
    } = getState().firestore.data[CONFIG.collection];

    const output = createOutput(questions, answers);

    db()
      .doc(`${CONFIG.collection}/${surveyId}`)
      .set(
        {
          output,
        },
        { merge: true },
      )
      .then(() => {
        dispatch(updateActivityView(CONFIG, surveyId, 'results'));
      })
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error generating results'));
      });
  };
