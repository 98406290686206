import React from 'react';
import { useSelector } from 'react-redux';
import { darken, makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
// CR components
import Participant2510IdeaForm from './Participant2510IdeaForm';
// selectors
import { session2510Selector } from 'redux/selectors/25:10/root2510Selectors';

const useStyles = makeStyles(theme => ({
  topic: {
    backgroundColor: grey[100],
    textAlign: 'center',
    padding: '30px',
    marginBottom: '20px',
  },
  textarea: {
    marginTop: '30px',
    borderColor: grey[500],
  },
  action: {
    margin: '40px 0',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  button: {
    backgroundColor: theme.palette.success.main,
    color: 'white',

    '&:hover': {
      backgroundColor: darken('#02A05C', 0.2),
    },
  },
  submittedText: {
    color: '#333333',
    '& h4': {
      marginBottom: '0',
    },
  },
}));

const Participant2510Idea = ({ activityId }) => {
  const classes = useStyles();
  const activity = useSelector(session2510Selector);

  return <Participant2510IdeaForm activityId={activityId} activity={activity} classes={classes} />;
};

export default Participant2510Idea;
