/**
 * Creates Participant header text
 *
 * @param {Number}    count            participants completed stage
 * @param {Number}    total            participants count
 * @param {String}    suffix           type activity
 * @returns {String} participant header text
 */
export const makeParticipantDetailsHeaderText = (count, total, suffix, noMainText) => {
  const mainText = noMainText ? suffix : `participants have ${suffix}`;
  if (count === total) {
    if (count === 0) {
      return `No ${mainText}`;
    }
    return `All ${mainText}`;
  }
  return `${count} of ${total} ${mainText}.`;
};
