import React from 'react';
import { useSelector } from 'react-redux';
import { map, sortBy, each } from 'lodash';
// cr components
import StoryFlow from 'components/StoryFlow/Participant/StoryFlow';
// selectors
import { useStoryFlowSelector, getCountSelectedFlows } from 'redux/selectors/storyFlowSelectors';

/**
 * Story Flow view for the 'selectResults' phase on the Participant side
 */
const StoryFlowParticipantSelectResults = () => {
  const {
    participantData: { flows },
    state: { activeView },
  } = useStoryFlowSelector();

  const countSelectedFlows = useSelector(getCountSelectedFlows());

  let numberWinnerVotes = 0;

  each(countSelectedFlows, flow => {
    if (flow.length > numberWinnerVotes) {
      numberWinnerVotes = flow.length;
    }
  });

  const sortedFlows = sortBy(flows, 'participantId');

  // renders all participants flows with 'select' results
  const renderParticipantsFlows = () =>
    map(sortedFlows, (flow, idx) => {
      const selectedFlow = countSelectedFlows[flow.participantId];
      return (
        <StoryFlow
          key={`participant-flow-${idx}`}
          createdFlowParticipantId={flow.participantId}
          participantFlow={flow?.values}
          votesCount={selectedFlow ? selectedFlow.length : 0}
          disabled={true}
          context={activeView}
          isFinished={true}
          selectedFlowId={
            numberWinnerVotes && selectedFlow?.length === numberWinnerVotes
              ? flow.participantId
              : ''
          }
        />
      );
    });

  return renderParticipantsFlows();
};

export default StoryFlowParticipantSelectResults;
