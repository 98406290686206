import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import cx from 'classnames';
import copy from 'copy-to-clipboard';
import { Link } from 'react-router-dom';
import { enqueueSuccessSnackbar } from 'redux/actions/notifierActions';
import { Box, Tooltip, useTheme } from '@material-ui/core';
import Spinner from 'components/Spinner';

const useStyles = makeStyles(theme => ({
  pillBtn: {
    display: 'inline-flex',
    padding: '3px 10px',
    backgroundColor: '#D8E1E7',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 99,
    marginRight: 5,
    marginBottom: 10,
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  pillBtnDisabled: {
    display: 'inline-flex',
    padding: '3px 10px',
    backgroundColor: '#EBEBEB',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 99,
    marginRight: 5,
    marginBottom: 10,
    color: 'rgba(0, 0, 0, 0.54)',
    cursor: 'default',
  },
  pillBtnHasError: {
    backgroundColor: theme.palette.error.main,
  },
  pillBtnNoLnk: {
    cursor: 'default',
  },
  pillBtnText: {
    fontSize: 13,
    fontWeight: '500',
    marginBottom: 0,
    userSelect: 'none',
  },
  pillBtnTextDisabled: {
    fontSize: 13,
    fontWeight: '500',
    marginBottom: 0,
  },
  pillBtnIcon: {
    fontSize: 13,
    marginRight: 4,
    display: 'flex',
    alignItems: 'center',
  },
}));

const PillButtonTooltipWrapper = ({ tooltipText, children }) => {
  if (tooltipText) {
    return (
      <Tooltip arrow title={tooltipText} placement="top">
        {children}
      </Tooltip>
    );
  }
  return <>{children}</>;
};

const PillButton = ({
  type,
  clickToCopyContent,
  externalUrl,
  appRoute,
  text,
  Icon,
  className,
  disabled,
  loading,
  tooltipText,
  hasError,
}) => {
  const classes = useStyles();
  const extraClasses = className ?? '';
  const dispatch = useDispatch();
  const theme = useTheme();

  const renderContent = () => (
    <>
      {Icon && (
        <span className={classes.pillBtnIcon}>
          <Icon fontSize="inherit" color="inherit" />
        </span>
      )}
      <span
        className={cx(classes.pillBtnText, {
          [`${classes.pillBtnTextDisabled}`]: disabled,
        })}
      >
        <Box
          style={{ display: 'flex', alignItems: 'center', color: hasError ? 'white' : 'inherit' }}
        >
          {text}
          {loading && (
            <Box ml={1} style={{ position: 'relative', top: 2 }}>
              <Spinner size={12} color="primary" />
            </Box>
          )}
        </Box>
      </span>
    </>
  );

  const handleClickToCopy = () => {
    copy(clickToCopyContent, {
      onCopy: () => {
        dispatch(enqueueSuccessSnackbar('Calendar Event ID Copied'));
      },
      format: 'text/plain',
    });
  };

  return (
    <PillButtonTooltipWrapper tooltipText={tooltipText}>
      <div>
        {type === 'externalUrl' && (
          <a
            href={externalUrl}
            className={cx(classes.pillBtn, extraClasses, {
              [`${classes.pillBtnDisabled}`]: disabled,
            })}
            target="_blank"
            rel="noopener noreferrer"
          >
            {renderContent()}
          </a>
        )}
        {type === 'appRoute' && (
          <Link
            to={appRoute}
            className={cx(classes.pillBtn, extraClasses, {
              [`${classes.pillBtnDisabled}`]: disabled,
            })}
          >
            {renderContent()}
          </Link>
        )}
        {type === 'clickToCopy' && (
          <span
            onClick={handleClickToCopy}
            className={cx(classes.pillBtn, extraClasses, {
              [`${classes.pillBtnDisabled}`]: disabled,
            })}
          >
            {renderContent()}
          </span>
        )}
        {type === 'plainText' && (
          <span
            className={cx(classes.pillBtn, classes.pillBtnNoLnk, extraClasses, {
              [`${classes.pillBtnDisabled}`]: disabled,
              [`${classes.pillBtnHasError}`]: hasError,
            })}
            style={{ opacity: hasError ? 0.8 : 1 }}
          >
            {renderContent()}
          </span>
        )}
      </div>
    </PillButtonTooltipWrapper>
  );
};

PillButton.propTypes = {
  type: PropTypes.oneOf(['appRoute', 'externalUrl', 'clickToCopy', 'plainText']).isRequired,
  clickToCopyContent: PropTypes.string,
  externalUrl: PropTypes.string,
  appRoute: PropTypes.string,
  text: PropTypes.string.isRequired,
  Icon: PropTypes.elementType,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  tooltipText: PropTypes.string,
};

export default PillButton;
