import { forEach, isTypedArray, map } from 'lodash';
import { ideasWithScore } from 'utils/25:10/report';

/**
 * Creates the array for Report Header
 *
 */
export const createBrainWritingHeader = () => [
  { label: 'Participant', key: 'participant' },
  { label: 'Writing Cleaned', key: 'writing_cleaned' },
  { label: 'Writing', key: 'writing' },
];

/**
 * Creates the data for Report
 *
 */
export const createBrainWritingData = (ideas, meetingParticipants) => {
  // render data for csv report
  return map(ideas, idea => ({
    participant: meetingParticipants[idea.id] ? meetingParticipants[idea.id].name : '',
    writing: idea.text,
    writing_cleaned: idea.text
      .replaceAll('<p>', '\n')
      .replaceAll('&nbsp;', ' ')
      .replace(/<\/?[^>]+(>|$)/g, ''),
  }));
};

export const createBrainWritingReport = (activityData, participantMap) => {
  const headers = createBrainWritingHeader();
  const data = createBrainWritingData(activityData?.ideas, participantMap);
  // or just return .output???
  return { headers, data };
};
