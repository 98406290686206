import React from 'react';
import { SessionActivation } from '@muraldevkit/mural-integrations-mural-canvas';
import { muralApiClient } from 'utils/muralApiClient';

/**
 * Auth component for the Mural canvas integration
 */
const MuralAdminSessionActivation = () => <SessionActivation apiClient={muralApiClient} />;

export default MuralAdminSessionActivation;
