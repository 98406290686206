import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { filter } from 'lodash';
// library components
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// cr components
import ActivityParticipantDetails from 'components/ActivityParticipantDetails';
import ActivityPromptAdmin from 'components/ActivityPromptAdmin';
import ActivityActionButton from 'components/CustomButtons/ActivityActionButton';
// selectors
import {
  useUserJourneyMomentsSelector,
  getParticipantsHaveCreated,
} from 'redux/selectors/userJourneyMomentsSelectors';
// actions
import { updateActivityView } from 'redux/actions/userJourneyMomentsActions';
// config
import { USER_JOURNEY_MOMENTS_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';
// utils
import { makeParticipantDetailsHeaderText } from 'utils/makeParticipantDetailsHeaderText';

const useStyles = makeStyles(theme => ({
  promptAdmin: {
    marginBottom: theme.spacing(5),
  },
}));

/**
 * User Journey Moments view for the 'create' phase on the Facilitator side
 */
const UserJourneyMomentsAdminCreate = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userJourneyMoments = useUserJourneyMomentsSelector();
  const { id, prompt } = userJourneyMoments;

  const participantsHaveCreated = useSelector(getParticipantsHaveCreated());
  const participantsHaveCreatedCount = filter(participantsHaveCreated, { completed: true }).length;
  const participantsHaveCreatedCountText = makeParticipantDetailsHeaderText(
    participantsHaveCreatedCount,
    participantsHaveCreated.length,
    'added user journey moments',
  );

  const onUpdateActivityView = () => {
    dispatch(updateActivityView('keyMoment', id));
  };

  return (
    <Box className="d-flex flex-column justify-content-center">
      <ActivityPromptAdmin
        prompt={prompt}
        collection={CONFIG.collection}
        activityId={id}
        className={classes.promptAdmin}
      />
      <ActivityParticipantDetails
        headerText={participantsHaveCreatedCountText}
        hasCompletedLabel="Added"
        hasntCompletedLabel="Hasn’t Added"
        completedCountMax={6} // There are a total of 6 steps, so that's the max they can add. This should probably be a constant somewhere.
        participantsCompletedStatus={participantsHaveCreated}
      />
      <ActivityActionButton
        text="Start selecting key moment"
        onClick={onUpdateActivityView}
        className="mb-2 mt-1"
        delayReady
      />
    </Box>
  );
};

export default UserJourneyMomentsAdminCreate;
