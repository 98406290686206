import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useFirestore } from 'react-redux-firebase';
import moment from 'moment';
import { merge } from 'lodash';
import { useConfirm } from 'material-ui-confirm';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
// library components
import { Formik, Form } from 'formik';
import { Button, Box, LinearProgress, Typography } from '@material-ui/core';

// cr components
import TextArea from 'components/CustomFormComponents/TextArea';
import Sentiment from 'components/CustomFormComponents/Sentiment';
import * as Yup from 'yup';
// actions
import {
  updateParticipantSentiment,
  updateParticipantFeedback,
} from 'redux/actions/feedbackActions';
import { enqueueErrorSnackbar } from 'redux/actions/notifierActions';
// selectors
import { getParticipantsMap } from 'redux/selectors/participantsSelectors';
// config
import { FEEDBACK_CONFIG } from '@voltage-control/control-room-activities-config';
// utils
import { getUser } from 'utils/getUsers';
import { sendFacilitatorNotifications } from 'redux/actions/feedbackActions';

const queryString = require('query-string');

const formSchema = Yup.object().shape({
  sentiment: Yup.string().required('Required'),
  liked: Yup.string().required('Required'),
  didntLike: Yup.string().required('Required'),
  opportunity: Yup.string().required('Required'),
  outcome: Yup.string().required('Required'),
});

const useStyles = makeStyles({
  messageHeader: {
    paddingBottom: 20,
  },
  message: {
    fontSize: 20,
    lineHeight: '30px',
    fontWeight: '300',
    margin: `0 0 72px 0`,
    textAlign: 'center',
  },
});

const SessionFeedbackForm = ({ session, participant }) => {
  const classes = useStyles();
  const db = useFirestore();
  const dispatch = useDispatch();
  const history = useHistory();
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [facilitatorEmails, setFacilitatorEmails] = useState([]);
  const participantId = useSelector(state => state.firebase.auth.uid);
  const sessionParticipantFeedback = session.feedback?.[participantId];
  const participantData = useSelector(getParticipantsMap);
  const feedbackConfig = merge(FEEDBACK_CONFIG.fields, session.feedbackConfig);
  const location = useLocation();
  const urlParams = queryString.parse(location.search);
  const sentimentParam = urlParams.sentiment;
  const storedSentiment = sessionParticipantFeedback?.sentiment;
  const hasFacilitatorEmails = Boolean(facilitatorEmails.length);

  const confirm = useConfirm();

  /**
   * Fetch and set the facilitator emails to use when
   * sending notifications
   */
  useEffect(() => {
    if (!hasFacilitatorEmails) {
      const getFacilitatorEmails = async () => {
        const coFacilitatorIds = session?.coFacilitators || [];
        const facilitatorsIds = [...coFacilitatorIds, session.facilitatorId];

        const emailRequests = facilitatorsIds.map(async facilitatorId => {
          const { email } = (await getUser(facilitatorId)) || '';
          return Promise.resolve(email);
        });

        const emails = await Promise.all(emailRequests);

        setFacilitatorEmails(emails);
      };

      getFacilitatorEmails();
    }
  }, [hasFacilitatorEmails]);

  /**
   * Set meeting sentiment from URL params
   */
  useEffect(() => {
    if (!hasFacilitatorEmails) return;

    if (sentimentParam) {
      handleSaveSentiment(sentimentParam);
    }
  }, [storedSentiment, sentimentParam, hasFacilitatorEmails]);

  const sendNotification = async sentiment => {
    if (!sentiment) return;

    const participantName =
      participantData?.[participantId]?.name || participant?.name || 'Anonymous';

    dispatch(
      sendFacilitatorNotifications(
        participantId,
        participantName,
        session.id,
        session.topic,
        session.id,
        sentiment,
        location?.pathname,
        facilitatorEmails,
      ),
    );
  };

  const handleSaveSentiment = async value => {
    dispatch(updateParticipantSentiment(session.id, participantId, value, sendNotification));
  };

  const handleSaveFeedbackAnswer = e => {
    if (e.target.name) {
      dispatch(updateParticipantFeedback(session.id, participantId, e.target.name, e.target.value));
    }
  };

  /**
   * Protect against submitting new feedback on a meeting with the old array
   * data structure
   */
  if (Array.isArray(session.feedback) && session.feedback.length) {
    return (
      <>
        <Typography variant="h4" className={cx('form-header', classes.messageHeader)}>
          Feedback Closed
        </Typography>
        <Typography className={classes.message}>
          This meeting can no longer accept feedback.
        </Typography>
      </>
    );
  }

  if (submitSuccess) {
    return (
      <>
        <Typography variant="h4" className={cx('form-header', classes.messageHeader)}>
          Thank you!
        </Typography>
        <Typography className={classes.message}>Your feedback is greatly appreciated.</Typography>
      </>
    );
  }

  return (
    <Formik
      initialValues={{
        sentiment: sentimentParam
          ? sentimentParam
          : sessionParticipantFeedback?.sentiment
            ? sessionParticipantFeedback.sentiment
            : null,
        liked: sessionParticipantFeedback?.liked ? sessionParticipantFeedback.liked : '',
        didntLike: sessionParticipantFeedback?.didntLike
          ? sessionParticipantFeedback.didntLike
          : '',
        opportunity: sessionParticipantFeedback?.opportunity
          ? sessionParticipantFeedback.opportunity
          : '',
        outcome: sessionParticipantFeedback?.outcome ? sessionParticipantFeedback.outcome : '',
      }}
      enableReinitialize
      validationSchema={formSchema}
      onSubmit={(values, { setSubmitting }) => {
        db.doc(`sessions/${session.id}`)
          .set(
            {
              feedback: {
                id: moment().valueOf(),
                ...values,
              },
            },
            { merge: true },
          )
          .then(() => {
            localStorage.setItem(`submittedFeedbackFor_${session.id}`, true);
            setSubmitting(false);
            setSubmitSuccess(true);

            if (session.slidesSessionIds?.[0]) {
              confirm({
                title: 'Feedback Received',
                description: 'Thanks for submitting feedback!',
                cancellationButtonProps: {
                  className: 'd-none',
                },
              }).then(() => {
                history.push(`/session/${session.id}/slides/${session.slidesSessionIds[0]}`);
              });
            }
          })
          .catch(err => {
            dispatch(enqueueErrorSnackbar('Error submitting Feedback'));
            console.error(err);
          });
      }}
    >
      {({ submitForm, isSubmitting, dirty }) => (
        <Form style={{ paddingBottom: 72 }} onBlur={handleSaveFeedbackAnswer}>
          <Typography variant="h5" className="form-header">
            Help your meeting host improve by giving them feedback.
          </Typography>
          <Sentiment
            name="sentiment"
            label="How was the meeting?"
            isSaveSentiment={true}
            handleSaveSentiment={handleSaveSentiment}
            required
          />
          <Box mb={8}>
            <TextArea
              name="liked"
              type="email"
              label={feedbackConfig.likedLabel}
              placeholder={feedbackConfig.likedPlaceholder}
              required
            />
          </Box>
          <Box mb={8}>
            <TextArea
              name="didntLike"
              type="text"
              label={feedbackConfig.didntLikeLabel}
              placeholder={feedbackConfig.didntLikePlaceholder}
              required
            />
          </Box>
          <Box mb={8}>
            <TextArea
              name="opportunity"
              type="text"
              label={feedbackConfig.opportunityLabel}
              placeholder={feedbackConfig.opportunityPlaceholder}
              required
            />
          </Box>
          <Box mb={8}>
            <TextArea
              name="outcome"
              type="text"
              label={feedbackConfig.outcomeLabel}
              placeholder={feedbackConfig.outcomePlaceholder}
              required
            />
          </Box>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {isSubmitting ? (
              <LinearProgress />
            ) : (
              <Button
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                onClick={submitForm}
              >
                SHARE FEEDBACK
              </Button>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SessionFeedbackForm;
