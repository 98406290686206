import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import { map, isEmpty, filter, keyBy, keys } from 'lodash';
// library components
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Tooltip,
  Box,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
// redux selectors
import { getParticipantsMap } from 'redux/selectors/participantsSelectors';
import { impersonateUser } from 'utils/impersonateUser';

/**
 * A dialog that shows the list of session participants.  Disconnected participants
 * will be in the bottoms list.  Inactive participants will be greyed out.
 */
const SessionParticipantsDialog = ({ closeDialog, open }) => {
  const theme = useTheme();
  // const classes = useStyles();
  const sessionParticipants = useSelector(getParticipantsMap);
  const session = useSelector(state => state.firestore.data.session);

  const getParticipantStyle = active => ({
    background: 'white',
    border: '1px solid gray',
    borderRadius: 20,
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    opacity: active ? '1' : '.33',
    padding: theme.spacing(1),
    cursor: 'pointer',
  });

  const handleParticipantClick = id => {
    impersonateUser.impersonate(session, id);
  };

  const participantsConnected = isEmpty(sessionParticipants)
    ? []
    : keyBy(filter(sessionParticipants, { connected: true }), 'id');

  const renderParticipantsConnected = () => {
    return map(participantsConnected, (p, idx) => (
      <Tooltip title="Impersonate User" arrow enterDelay={250} key={`cp-${idx}`}>
        <span
          className="shadow-sm"
          key={idx}
          style={getParticipantStyle(p.active)}
          onClick={() => handleParticipantClick(p.id)}
        >
          {sessionParticipants[p.id]?.name}
        </span>
      </Tooltip>
    ));
  };

  const participantsNotConnected = isEmpty(sessionParticipants)
    ? []
    : keyBy(filter(sessionParticipants, { connected: false }), 'id');

  const renderParticipantsNotConnected = () => {
    return map(participantsNotConnected, (p, idx) => (
      <Tooltip title="Impersonate User" arrow enterDelay={250} key={`tooltip-${idx}`}>
        <span
          className="shadow-sm"
          key={idx}
          style={getParticipantStyle(p.active)}
          onClick={() => handleParticipantClick(p.id)}
        >
          {sessionParticipants[p.id]?.name}
        </span>
      </Tooltip>
    ));
  };

  return (
    <div>
      <Dialog open={open} maxWidth="md">
        <DialogTitle disableTypography className="d-flex justify-content-between">
          <Box>
            <Typography variant="h6" style={{ textTransform: 'initial' }}>
              Meeting Participants
            </Typography>
            <Typography style={{ textTransform: 'initial' }}>
              Click on a participant name to remotely assist them
            </Typography>
          </Box>
          <IconButton
            aria-label="close"
            className="position-relative align-self-start"
            onClick={closeDialog}
            style={{
              top: `-${theme.spacing(1)}px`,
              right: `-${theme.spacing(2)}px`,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="pb-3 pt-0">
          <Typography className="font-weight-bold">Connected Participants</Typography>
          <Typography className="mb-1" style={{ fontSize: 14 }}>
            Participants in gray don't currently have the Control Room tab/window focused in their
            browser.
          </Typography>
          <div className="d-flex flex-wrap mb-3">
            {keys(participantsConnected).length > 0 ? (
              renderParticipantsConnected()
            ) : (
              <span className="ml-1">None</span>
            )}
          </div>

          <Typography className="mb-1 font-weight-bold">Disconnected Participants</Typography>
          <div className="d-flex flex-wrap mb-3">
            {keys(participantsNotConnected).length > 0 ? (
              renderParticipantsNotConnected()
            ) : (
              <span className="ml-1">None</span>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

SessionParticipantsDialog.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default SessionParticipantsDialog;
