import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
// library components
import CloseIcon from '@material-ui/icons/Close';
import { TextareaAutosize, Box, IconButton, Typography } from '@material-ui/core';

const useCardStyles = makeStyles(theme => ({
  root: {
    minHeight: 40,
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    borderRadius: 4,
    padding: theme.spacing(1),
    backgroundColor: '#EEEEEE',
  },
  cancelBtn: {
    position: 'relative',
    right: `-${theme.spacing(0.5)}px`,
    fontSize: 18,
    alignSelf: 'center',
  },
  title: {
    fontSize: 15,
    marginLeft: 10,
    background: 'none',
  },
  textArea: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    background: 'transparent',
    resize: 'none',
    border: '0 none',
    outline: 'none',
    padding: 0,
    display: 'inline-block',
  },
}));

/**
 * Renders the new custom field whith name and delete property.
 */
const NewField = ({
  fieldId,
  fieldValue,
  fieldOrder,
  inputProps,
  handleChangeFieldValue,
  handleDeleteField,
  setFocus,
  disabled,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const classes = useCardStyles();
  const field = useRef(null);

  const toggleIsEditing = () => {
    setIsEditing(!isEditing);
  };

  const handleEditValue = event => {
    handleChangeFieldValue(fieldId, event.target.value, fieldOrder);
  };

  const handleDelete = () => {
    handleDeleteField(fieldId);
  };

  return (
    <Box
      className={classes.root}
      display="inline-flex"
      alignItems="flex-start"
      alignSelf="flex-start"
    >
      <TextareaAutosize
        ref={field}
        id={fieldId}
        className={cx(classes.textArea, {
          'd-none': !isEditing,
        })}
        value={fieldValue}
        onChange={handleEditValue}
        autoFocus
        onBlur={() => {
          if (!fieldValue) {
            handleDelete();
          } else {
            toggleIsEditing();
          }
        }}
        {...inputProps}
      />
      <Typography
        component="span"
        className={cx(classes.textArea, {
          'd-none': isEditing,
        })}
        onMouseDown={event => {
          if (!disabled) {
            toggleIsEditing();
            setFocus(event, field.current);
          }
        }}
      >
        {fieldValue}
      </Typography>
      {!disabled && !isEditing && (
        <IconButton size="small" className={classes.cancelBtn} onClick={handleDelete}>
          <CloseIcon fontSize="inherit" />
        </IconButton>
      )}
    </Box>
  );
};

NewField.propTypes = {
  // The field id
  fieldId: PropTypes.string,
  // The field value
  fieldValue: PropTypes.string.isRequired,
  // The field order
  fieldOrder: PropTypes.number,
  // The params for input component
  inputProps: PropTypes.object,
  // Function for delete field
  handleDeleteField: PropTypes.func.isRequired,
  // Function for changing field value
  handleChangeFieldValue: PropTypes.func,
  // Function for set focus
  setFocus: PropTypes.func,
  disabled: PropTypes.bool,
};

export default NewField;
