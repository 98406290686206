import { createSelector } from 'reselect';
import { filter, includes, map, size, reduce, each, isEmpty, sortBy, keys, keyBy } from 'lodash';

import { useShallowEqualSelector } from '../utils';

const noteAndCategorizeSelector = state => state.firestore.data.noteAndCategorize || {};

export const useNoteAndCategorizeSelector = () => {
  return useShallowEqualSelector(noteAndCategorizeSelector);
};

/**
 * Get all notes for a participant
 *
 * @param {String} participantId
 */
export const getParticipantNotes = participantId =>
  createSelector([noteAndCategorizeSelector], ({ participantData: { notes } }) => {
    return filter(notes, { participantId: participantId });
  });

/**
 * Get all voted participant notes
 *
 * @param {String} participantId
 */
export const getParticipantVotes = participantId =>
  createSelector([noteAndCategorizeSelector], ({ participantData: { notes } }) => {
    return filter(notes, note => note.votedFor.includes(participantId));
  });

/**
 * Get all voted participant notes mapped by categoryId
 *
 * @param {String} participantId
 */
export const getCategorizedParticipantVotes = participantId =>
  createSelector(
    [noteAndCategorizeSelector, getParticipantVotes(participantId)],
    ({ config: { categories } }, participantVotedNotes) => {
      const categoryIds = keys(categories);
      const categoryMap = {};

      each(categoryIds, categoryId => {
        categoryMap[categoryId] = filter(
          participantVotedNotes,
          note => categoryId === note.categoryId,
        );
      });

      return categoryMap;
    },
  );

/**
 * Get data on which participants have completed categorization,
 * formatted for the ActivityParticipantDetail component
 *
 * @param {String} categoryId
 * @returns {Array.<{id: String, completed: Boolean}>}
 */
export const getParticipantsCompletedCategorizes = categoryId =>
  createSelector([useNoteAndCategorizeSelector], ({ participantData: { participants, notes } }) => {
    return map(participants, participantId => {
      const participantNotesWithCategory = size(
        filter(
          notes,
          note =>
            (categoryId ? note.categoryId === categoryId : note.categoryId !== '') &&
            note.participantId === participantId,
        ),
      );

      return {
        id: participantId,
        categoryId: categoryId,
        completed: participantNotesWithCategory > 0,
        completedCount: participantNotesWithCategory,
      };
    });
  });

/**
 * Get data on which participants have completed vote,
 * formatted for the ActivityParticipantDetail component
 *
 * @returns {Array.<{id: String, completed: Boolean}>}
 */
export const getParticipantsCompletedVotes = () =>
  createSelector(
    [noteAndCategorizeSelector],
    ({
      config: { votesPerCategory, categories, format, roundRobinShare },
      participantData: { participants, notes },
      state: { serialRoundRobin },
    }) => {
      const currentCategories =
        roundRobinShare === 'on' && format === 'Serial'
          ? filter(categories, category => category.id === serialRoundRobin)
          : categories;

      const categoryVotesCount = reduce(
        currentCategories,
        (accCategories, category) => {
          const notesCount = reduce(
            notes,
            (acc, note) => {
              if (note.categoryId === category.id) {
                acc = acc + 1;
              }
              return acc;
            },
            0,
          );

          let participantVotes = [];
          each(participants, participantId => {
            each(notes, note => {
              if (note.categoryId === category.id && includes(note.votedFor, participantId)) {
                participantVotes = [...participantVotes, participantId];
              }
            });
          });
          return {
            ...accCategories,
            [category.id]: {
              votesPerCategory: notesCount > votesPerCategory ? +votesPerCategory : notesCount,
              participantVotes,
            },
          };
        },
        {},
      );

      const notEmptyCategory = filter(categoryVotesCount, category => !!category.votesPerCategory);

      return map(participants, participantId => {
        const participantVotesCount = reduce(
          notEmptyCategory,
          (vacc, votes) => {
            vacc =
              vacc +
              reduce(
                votes.participantVotes,
                (acc, participant) => {
                  if (participant === participantId) {
                    acc = acc + 1;
                  }
                  return acc;
                },
                0,
              );
            return vacc;
          },
          0,
        );

        return {
          id: participantId,
          completed: participantVotesCount > 0,
          completedCount: participantVotesCount,
        };
      });
    },
  );

/**
 * Get data on which participants have completed round robin,
 * formatted for the ActivityParticipantDetail component
 *
 * @returns {Array.<{id: String, completed: Boolean}>}
 */
export const getParticipantsCompletedRoundRobin = () =>
  createSelector(
    [noteAndCategorizeSelector],
    ({ participantData: { participants, participantsCompletedRoundRobin } }) =>
      map(participants, participantId => ({
        id: participantId,
        completed: includes(participantsCompletedRoundRobin, participantId),
      })),
  );

/**
 * Get results data
 *
 */
export const getResults = () =>
  createSelector([noteAndCategorizeSelector], ({ voteOutput, state }) => {
    if (isEmpty(state?.results)) {
      return voteOutput;
    }
    return state?.results;
  });
