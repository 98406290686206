import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { filter, size, round } from 'lodash';
// library components
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// cr components
import ActivityParticipantDetails from 'components/ActivityParticipantDetails';
import ActivityPromptAdmin from 'components/ActivityPromptAdmin';
import ActivityActionButton from 'components/CustomButtons/ActivityActionButton';
import CategoriesAdmin from 'components/Categories/CategoriesAdmin';
import ActiveCardSetAdmin from './ActiveCardSetAdmin';
import SelectOptionForm from './SelectOptionForm';
import FormDialog from 'components/Dialogs/FormDialog';
// selectors
import {
  useCardSortingSelector,
  getPrimaryCategorizedCards,
  getParticipantsCompletedRound,
} from 'redux/selectors/cardSortingSelectors';
// actions
import { updateActivityView, updateCardsCount } from 'redux/actions/cardSortingActions';
// config
import { CARD_SORTING_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';
// utils
import { makeParticipantDetailsHeaderText } from 'utils/makeParticipantDetailsHeaderText';

const useStyles = makeStyles(theme => ({
  promptAdmin: {
    marginBottom: theme.spacing(1),
  },
}));

/**
 * Card Sorting view for the 'primaryCategorize' phase on the Facilitator side
 */
const CardSortingAdminPrimaryCategorize = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const cardSorting = useCardSortingSelector();
  const {
    id,
    prompt,
    config: { categories, primaryCategory, cardSet },
    state: { cardsCount },
    state,
  } = cardSorting;

  const currentRound = state.round;

  const [isModalOpen, setIsModalOpen] = useState(!cardsCount);

  const sortedCategories = filter(categories, { id: primaryCategory });

  const cards = useSelector(getPrimaryCategorizedCards(`round${currentRound - 1}`));

  const participantsHaveCategorizedData = useSelector(
    getParticipantsCompletedRound(`round${currentRound}`),
  );
  // sorting categorized items
  const participantsCategorizedCount = filter(participantsHaveCategorizedData, {
    completed: true,
  }).length;
  const participantsCategorizedCountText = makeParticipantDetailsHeaderText(
    participantsCategorizedCount,
    participantsHaveCategorizedData.length,
    `sorting round ${currentRound} cards`,
  );

  const onUpdateActivityView = () => {
    dispatch(updateActivityView(id, 'results'));
  };

  // Close modal form
  const closeDialog = () => {
    setIsModalOpen(false);
  };

  const submitOptions = count => {
    dispatch(updateCardsCount(id, count));
    closeDialog();
  };

  const options = Array.from({ length: size(cards) }, (_, idx) => idx + 1);

  return (
    <>
      <Box className="d-flex flex-column justify-content-center">
        <ActivityPromptAdmin
          prompt={prompt}
          collection={CONFIG.collection}
          activityId={id}
          className={classes.promptAdmin}
        />
        <ActiveCardSetAdmin cardSetId={cardSet} />
        <CategoriesAdmin categories={sortedCategories} primaryCategoryId={primaryCategory} />
        <ActivityParticipantDetails
          headerText={participantsCategorizedCountText}
          hasCompletedLabel={`Sorted Round ${currentRound} Cards`}
          hasntCompletedLabel={`Hasn't Sorted Round ${currentRound} Cards`}
          completedCountMax={cardsCount}
          participantsCompletedStatus={participantsHaveCategorizedData}
        />
        <ActivityActionButton
          className="mt-2"
          text="Review Results"
          onClick={onUpdateActivityView}
          delayReady
        />
      </Box>
      <FormDialog
        handleClose={() => setIsModalOpen(true)}
        isOpen={isModalOpen}
        title="Select Option"
      >
        <SelectOptionForm
          options={options}
          submitOptions={submitOptions}
          defaultValue={round(size(cards) / 2)}
        />
      </FormDialog>
    </>
  );
};

export default CardSortingAdminPrimaryCategorize;
