import { map, orderBy, filter, find, reduce, keys, findIndex, sortBy, size } from 'lodash';

/**
 * Creates the output array for a Note & Map activity
 * @param {Object} flows
 * @param {Array<{type: String, id: String}>} superVoteTypes
 * @returns {Array<{text: String, votes: Number}>}
 */
export const createOutput = (flows, superVoteTypes) => {
  // get all types
  const allTypes = reduce(
    flows,
    (acc, { types, participantId }) => {
      const typeNames = keys(types);
      const validTypes = map(typeNames, name => {
        const type = types[name];
        return {
          ...type,
          type: name,
        };
      });
      return [...acc, ...validTypes];
    },
    [],
  );
  //find super vote type
  let output = filter(allTypes, ({ id }) => find(superVoteTypes, type => type.id === id));

  // create initial output array
  output = map(output, type => ({
    type: type.type.split('-')[0],
    text: type.value,
  }));

  return orderBy(output, ['votes', 'text'], ['desc', 'asc']);
};

/**
 * Creates the array for Report Header
 *
 */
export const createNoteAndMapReportHeader = () => [
  { label: 'Created By', key: 'flowCreator' },
  { label: 'Super Votes', key: 'haveSuperVotes' },
  { label: 'Votes', key: 'votes' },
  { label: 'Type', key: 'type' },
  { label: 'Value', key: 'value' },
  { label: 'Duplicate', key: 'duplicate' },
];

/**
 * Creates the data for Report
 *
 */
export const createNoteAndMapReportData = allTypes =>
  map(allTypes, ({ type, value, parentMomentId, flowCreator, votes, haveSuperVotes }) => {
    const duplicate = parentMomentId
      ? findIndex(allTypes, type => parentMomentId === type.id) + 1
      : '';
    return {
      flowCreator,
      votes,
      type,
      value,
      duplicate,
      target: !!haveSuperVotes,
    };
  });

export const reportData = (participantData, participants) => {
  const participantsFlows = sortBy(participantData?.flows, 'participantId');
  const superVoteTypes = participantData?.superVoteTypes || [];
  return reduce(
    participantsFlows,
    (acc, { types, participantId }) => {
      const typeNames = ['actor', 'moment-1', 'moment-2', 'moment-3', 'moment-4', 'end'];
      const validTypes = map(typeNames, name => {
        const type = types[name];
        if (!type) return { type: name };
        const { id, votedFor, value, parentMomentId, combineMomentsId } = type;
        return {
          id,
          value,
          type: name,
          parentMomentId,
          votedFor,
          flowCreator: find(participants, ({ id }) => id === participantId)?.name,
          flowId: participantId,
          votes: size(votedFor),
          haveSuperVotes: find(superVoteTypes, type => id === type.id) ? 1 : 0,
          combineMomentsId,
        };
      });
      return [...acc, ...validTypes];
    },
    [],
  );
};

export const createNoteAndMapReport = (activityData, participantMap) => {
  const headers = createNoteAndMapReportHeader();

  const data = createNoteAndMapReportData(
    reportData(activityData?.participantData, participantMap),
  );

  return { headers, data };
};
