import React from 'react';
import { useSelector } from 'react-redux';
import { includes, find, map, sortBy, each } from 'lodash';
// library components
import { Box } from '@material-ui/core';
// cr components
import LightningDemo from 'components/LightningDemo/Participant/LightningDemo';
import LightningDemoReview from 'components/LightningDemo/Participant/LightningDemoReview';
import AdminActivityAuxView from 'components/AdminActivityAuxView';
import CSVReportDownloadButton from 'components/CSVReportDownloadButton';
// selectors
import { useLightningDemoSelector } from 'redux/selectors/lightningDemoSelectors';
// utils
import {
  createLightningDemoReportHeader,
  createLightningDemoReportData,
} from 'utils/lightningDemo';

/**
 * Renders lightning demo for review on the Facilitator side
 */
const LightningDemoViews = () => {
  const lightningDemo = useLightningDemoSelector();
  const { id, participantData, state, name, output } = lightningDemo;

  const session = useSelector(state => state.firestore.data.session);

  if (!state?.activeView || !includes(['review', 'results'], state.activeView)) {
    return null;
  }

  // get current demo
  const currentDemo = find(participantData.demos, demo => demo.demoId === state.currentDemoId);
  // sort demos by participantId and order
  const sortedDemos = sortBy(participantData.demos, ['participantId', 'order']);

  // get upload demos participants
  const participantsUploadDemos = [];
  each(participantData.participants, participant => {
    const participantDemo = find(participantData.demos, demo => demo.participantId === participant);
    if (participantDemo) {
      participantsUploadDemos.push(participant);
    }
  });

  // render headers for csv report
  const headers = createLightningDemoReportHeader();

  // render data for csv report
  const csvReport = createLightningDemoReportData(output);

  return (
    <AdminActivityAuxView
      titleText={
        state.activeView === 'results'
          ? `Demo Review`
          : `Reviewing demos from participant 
        ${state.participantsCompletedReview ? state.participantsCompletedReview.length : 1} 
        of ${participantsUploadDemos.length}`
      }
      allowPrint={state.activeView === 'results'}
      activityName={name}
      hasDownloadButton={state.activeView === 'results'}
      renderDownloadButton={() => (
        <CSVReportDownloadButton
          headers={headers}
          csvReport={csvReport}
          buttonText="Download Lightning Demos"
          filename="Results.csv"
          topic={session.topic}
          activity="LightningDemo"
          activityName={name}
          withoutLabel={true}
        />
      )}
    >
      <Box
        className="w-100 pt-3 pb-4 px-4"
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        flexWrap="wrap"
      >
        {state.activeView === 'results' ? (
          map(sortedDemos, (demo, idx) => {
            if (demo.demoId) {
              return (
                <LightningDemo
                  key={`demo-${idx}`}
                  context={state.activeView}
                  demoId={demo.demoId}
                  lightningDemoId={id}
                  participantId={demo.participantId}
                  imageId={demo.imageId}
                  bigIdeas={demo.bigIdeas}
                  source={demo.source}
                  demoUrl={demo.url}
                  isFinished={true}
                />
              );
            }
          })
        ) : (
          <LightningDemoReview
            lightningDemoId={id}
            activeView={state.activeView}
            currentDemo={currentDemo}
          />
        )}
      </Box>
    </AdminActivityAuxView>
  );
};

export default LightningDemoViews;
