import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'react-redux-firebase';
import { map } from 'lodash';

import { makeStyles } from '@material-ui/styles';

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Typography from '@material-ui/core/Typography';
import Button from 'components/CustomButtons/Button.js';

//cr components
import PageContent from 'components/PageContent/PageContent';
import PageContentHeader from 'components/PageContent/PageContentHeader';
import PageContentBody from 'components/PageContent/PageContentBody';

//staffing components
import ProjectCard from 'components/Staffing/Projects/ProjectCard/';
import ProjectsFilter from 'components/Staffing/Projects/ProjectsFilter/';
import AddProjectToDealDialog from 'components/Dialogs/Staffing/AddProjectToDealDialog';

//actions & selectors
import { selectProjects } from 'redux/selectors/staffing/getProject';
import { openAddProjectToDealDialog } from 'redux/actions/staffingActions';

const useStyles = makeStyles({
  pageHeader: {},
  gridItem: {
    padding: '0 10px !important',
  },
  createProjectButton: {
    height: 36,
    marginBottom: 3,
  },
  tab: {
    minWidth: 80,
    fontSize: 12,
    boxShadow: 'inset 0px -2px 0px #666666',
    marginBlock: 3,
  },
});

const ProjectsList = () => {
  const classes = useStyles();

  const getAllProject = selectProjects();
  const { projects, isLoaded } = useSelector(state => getAllProject(state));

  const dispatch = useDispatch();

  if (!isLoaded) {
    return null;
  }

  return (
    <div>
      <GridContainer direction="row" justify="center" alignItems="center">
        <GridItem xs={12}>
          <PageContent>
            <PageContentHeader className={classes.pageHeader}>
              <Typography variant="h5" className="page-content-title">
                Projects
              </Typography>
              <div className="right-col">
                <Button
                  color="primary"
                  onClick={() => {
                    dispatch(openAddProjectToDealDialog(true));
                  }}
                  size="sm"
                  className={classes.createProjectButton}
                >
                  New Project
                </Button>
              </div>
            </PageContentHeader>
            <PageContentBody>
              <ProjectsFilter />

              <GridContainer className="w-100 m-0">
                {isEmpty(projects) ? (
                  <Typography style={{ fontSize: 18 }} className="w-100 text-center ml-2 mt-4">
                    No Projects
                  </Typography>
                ) : null}

                {map(projects, (project, idx) => {
                  if (!project || !project.id) {
                    return null;
                  }
                  return (
                    <GridItem
                      xs={12}
                      sm={6}
                      md={6}
                      lg={6}
                      key={`project-card-${idx}`}
                      style={{ marginBottom: 30 }}
                      className={classes.gridItem}
                    >
                      <ProjectCard id={project.id} />
                    </GridItem>
                  );
                })}
              </GridContainer>
            </PageContentBody>
          </PageContent>
        </GridItem>
      </GridContainer>

      <AddProjectToDealDialog />
    </div>
  );
};

export default ProjectsList;
