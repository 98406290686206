import React from 'react';
import { keys } from 'lodash';
// selectors
import { useAssessmentSelector } from 'redux/selectors/assessmentSelectors';
// styles
import AssessmentParticipantAllAnswered from './AssessmentParticipantAllAnswered';
import AssessmentFinalResults from '../Shared/AssessmentFinalResults';

/**
 * Renders final results on the participant side
 */
const AssessmentParticipantFinalResults = () => {
  const {
    config: { reviewResults, questions },
  } = useAssessmentSelector();

  return (
    <>
      {reviewResults ? (
        <AssessmentFinalResults />
      ) : (
        <AssessmentParticipantAllAnswered questionCount={keys(questions).length} />
      )}
    </>
  );
};

export default AssessmentParticipantFinalResults;
