import React, { useState } from 'react';
import { includes, map, sortBy, find, each } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
// library components
import { Box, Tabs, Tab } from '@material-ui/core';
// cr components
import StoryFlow from 'components/StoryFlow/Participant/StoryFlow';
import AdminActivityAuxView from 'components/AdminActivityAuxView';
import CSVReportDownloadButton from 'components/CSVReportDownloadButton';
// selectors
import { useStoryFlowSelector } from 'redux/selectors/storyFlowSelectors';
// utils
import {
  getCountSelectedFlows,
  createStoryFlowReportHeader,
  createStoryFlowReportData,
} from 'utils/storyFlow';

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 300,
    opacity: 0.9,
  },
  tab: {
    minWidth: 80,
    fontSize: 12,
  },
  tabsTitle: {
    width: '60%',
    fontWeight: 300,
  },
  container: {
    width: '100%',
    padding: 30,
    margin: theme.spacing(2),
  },
}));

/**
 *  Renders Story Flows for 'selectResults', 'superVote', 'results' phases on the Facilitator side
 */
const StoryFlowViews = () => {
  const classes = useStyles();
  const storyFlow = useStoryFlowSelector();
  const { participantData, state, name } = storyFlow;
  const session = useSelector(state => state.firestore.data.session);

  const [activeTab, setActiveTab] = useState(0);

  if (
    !state?.activeView ||
    !includes(['selectResults', 'superVote', 'results'], state.activeView)
  ) {
    return null;
  }

  const sortedFlows = sortBy(participantData.flows, 'participantId');

  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
  };

  const countSelectedFlows = getCountSelectedFlows(
    participantData.participants,
    participantData.participantsSelectedFlow,
  );

  let numberWinnerVotes = 0;

  each(countSelectedFlows, flow => {
    if (flow.length > numberWinnerVotes) {
      numberWinnerVotes = flow.length;
    }
  });

  let superVoteFlow;
  let votedFlow;

  if (state.activeView === 'results') {
    superVoteFlow = find(
      participantData.participantsSuperVoteFlow,
      participant => !!participant.superVoteFlowId,
    );
    votedFlow = find(
      participantData.flows,
      flow => flow.participantId === superVoteFlow?.superVoteFlowId,
    );
  }

  // render headers for csv report
  const headers = createStoryFlowReportHeader();

  // render data for csv report
  const csvReport = createStoryFlowReportData(sortedFlows, countSelectedFlows, superVoteFlow);

  const renderActiveTabFlows = () => {
    return (
      <Box
        className="w-100 mt-4"
        display="flex"
        alignItems="center"
        justifyContent="start"
        flexWrap="wrap"
      >
        {activeTab
          ? map(sortedFlows, (flow, idx) => {
              const selectedFlow = countSelectedFlows[flow.participantId];
              return (
                <StoryFlow
                  key={`participant-flow-${idx}`}
                  selectedFlowId={superVoteFlow?.superVoteFlowId}
                  createdFlowParticipantId={flow?.participantId}
                  participantFlow={flow?.values}
                  votesCount={selectedFlow ? selectedFlow.length : 0}
                  disabled={true}
                  context={state.activeView}
                  isFinished={true}
                />
              );
            })
          : !!votedFlow && (
              <StoryFlow
                createdFlowParticipantId={superVoteFlow?.participantId}
                participantFlow={votedFlow?.values}
                disabled={true}
                context={state.activeView}
                votesCount={0}
                isFinished={true}
                isSelected={true}
              />
            )}
      </Box>
    );
  };

  return (
    <AdminActivityAuxView
      titleText={state.activeView === 'results' ? 'Voting Results' : 'Review Results'}
      activityName={name}
      printTitleLine2={state.activeView === 'results' ? 'Voting Results' : 'Review Results'}
      hasTabs={state.activeView === 'results'}
      renderTabs={() => (
        <Box display="flex" alignItems="center">
          <Tabs
            className="pr-2"
            value={activeTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChangeTab}
          >
            <Tab disableRipple className={classes.tab} label="Voted" />
            <Tab disableRipple className={classes.tab} label="Archived" />
          </Tabs>
        </Box>
      )}
      allowPrint
      hasDownloadButton={state.activeView === 'results'}
      renderDownloadButton={() => (
        <CSVReportDownloadButton
          headers={headers}
          csvReport={csvReport}
          filename="Results.csv"
          buttonText="Download Story Flows"
          topic={session.topic}
          activity="StoryFlow"
          activityName={name}
          withoutLabel={true}
        />
      )}
    >
      {state.activeView === 'results' ? (
        <>{renderActiveTabFlows()}</>
      ) : (
        <>
          <Box
            className="w-100 mt-2"
            display="flex"
            alignItems="center"
            justifyContent="start"
            flexWrap="wrap"
          >
            {map(sortedFlows, (flow, idx) => {
              const selectedFlow = countSelectedFlows[flow.participantId];
              return (
                <StoryFlow
                  key={`participant-flow-${idx}`}
                  createdFlowParticipantId={flow.participantId}
                  participantFlow={flow?.values}
                  votesCount={selectedFlow ? selectedFlow.length : 0}
                  disabled={true}
                  context={state.activeView === 'superVote' ? 'selectResults' : state.activeView}
                  isFinished={true}
                  selectedFlowId={
                    numberWinnerVotes && selectedFlow?.length === numberWinnerVotes
                      ? flow.participantId
                      : ''
                  }
                />
              );
            })}
          </Box>
        </>
      )}
    </AdminActivityAuxView>
  );
};

export default StoryFlowViews;
