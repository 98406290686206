import React from 'react';
import {
  includes,
  map,
  sortBy,
  isEmpty,
  split,
  head,
  find,
  each,
  filter,
  orderBy,
  size,
} from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
// library components
import { makeStyles, Box, Typography } from '@material-ui/core';
import { DragDropContext } from 'react-beautiful-dnd';
// cr components
import AdminActivityAuxView from 'components/AdminActivityAuxView';
import Category from 'components/CardSorting/Participant/Category';
import CSVReportDownloadButton from 'components/CSVReportDownloadButton';
// selectors
import {
  useCardSortingSelector,
  getCategorizedCards,
  getReviewRoundCards,
  getCardsReport,
  getAllParticipantsCards,
} from 'redux/selectors/cardSortingSelectors';
// actions
import {
  deleteCardFromCategory,
  updateCardNextRound,
  updateCardCategory,
} from 'redux/actions/cardSortingActions';
// utils
import { createCardSortingReportHeader, createCardSortingReportData } from 'utils/cardSorting';

const useStyles = makeStyles({
  categoriesContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: 'calc(100% + 20px)',
  },
  errorTitle: {
    marginBottom: 30,
    color: '#DD380C',
    fontSize: 13,
    fontWeight: 300,
    textAlign: 'center',
  },
});

/**
 *  Renders Card Sorting for 'review' phases on the Facilitator side
 */
const CardSortingAdminViews = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const cardSorting = useCardSortingSelector();
  const categorizedCards = useSelector(getCategorizedCards());
  const allParticipantCards = useSelector(getAllParticipantsCards());
  const numberRound = cardSorting?.state?.round;
  const primaryCards = useSelector(getReviewRoundCards(`round${numberRound}`));
  const cardsReportData = useSelector(getCardsReport());

  const session = useSelector(state => state.firestore.data.session);

  if (
    isEmpty(cardSorting) ||
    isEmpty(session) ||
    !cardSorting?.state?.activeView ||
    !includes(['review', 'results'], cardSorting.state.activeView)
  ) {
    return null;
  }

  const {
    state: { activeView, round, isActivityFinished },
    name,
    prompt,
    config: { categories, primaryCategory },
    id,
    participantData,
  } = cardSorting;

  const allCards = [];
  each(categorizedCards, category => {
    each(category, card => {
      allCards.push({ ...card });
    });
  });

  const hasDuplicateCard = !!find(allCards, card => card.isDuplicate);
  const sortedCategories =
    activeView === 'review'
      ? sortBy(categories, ['order', 'value'])
      : filter(categories, { id: primaryCategory });

  const handleRemoveCard = (cardId, isIncludeToNextRound) => {
    const notIncludeCards = filter(primaryCards, { isNotIncludeNextRound: false });
    const currentRound = `round${round}`;
    if (isIncludeToNextRound && size(notIncludeCards) <= 1) {
      return;
    }
    each(participantData?.[currentRound], (participants, participantId) => {
      each(participants, card => {
        if (card.cardId === cardId) {
          dispatch(
            updateCardNextRound(id, currentRound, participantId, cardId, isIncludeToNextRound),
          );
        }
      });
    });
  };

  // render categories
  const renderCategories = () =>
    map(sortedCategories, (category, idx) => {
      const categoryCards =
        activeView === 'review'
          ? categorizedCards?.[category.id]
          : isActivityFinished
            ? filter(cardsReportData, { finalRound: true })
            : primaryCards;
      const sortedCards = sortBy(categoryCards, ['votesCount', 'order', 'cardId']);
      return (
        <Category
          key={`${category.value}-${idx}`}
          id={category.id}
          droppableId={`${idx}`}
          categoryName={category.value}
          cards={sortedCards}
          context={`${activeView}Admin`}
          isPrimaryCategory={primaryCategory === category.id}
          handleRemoveCard={handleRemoveCard}
        />
      );
    });

  const renderTitle = activeView => {
    switch (activeView) {
      case 'review':
        return `${prompt}: Round 1 card review`;
      case 'results':
        if (isActivityFinished) {
          return `Final Results`;
        } else {
          return `Review round ${round} cards`;
        }
      default:
        return '';
    }
  };

  const onDragEnd = result => {
    const { source, destination, draggableId } = result;

    if (!destination) {
      return;
    }

    const startIndex = source.droppableId;
    const finishIndex = destination.droppableId;

    if (startIndex === finishIndex) {
      return;
    }

    const cardId = head(split(draggableId, '-'));

    const startCategoryId = sortedCategories[startIndex] ? sortedCategories[startIndex].id : '';
    const finishCategoryId = sortedCategories[finishIndex] ? sortedCategories[finishIndex].id : '';
    const draggableCard = find(categorizedCards?.[startCategoryId], card => card.cardId === cardId);
    const duplicateCard = find(
      categorizedCards?.[finishCategoryId],
      card => card.cardId === cardId,
    );

    if (finishCategoryId && draggableCard.isDuplicate && duplicateCard) {
      dispatch(
        deleteCardFromCategory(id, draggableCard.participantId, draggableCard.categoryId, cardId),
      );
      return;
    }

    if (finishCategoryId && !draggableCard.isDuplicate) {
      const cardFromAllParticipants = filter(allParticipantCards, { cardId: cardId });
      each(cardFromAllParticipants, card => {
        dispatch(deleteCardFromCategory(id, card.participantId, card.categoryId, cardId));
      });
      dispatch(
        updateCardCategory(
          id,
          draggableCard.participantId,
          finishCategoryId,
          cardId,
          draggableCard.order || 0,
        ),
      );
    }
  };

  // render headers for csv report
  const headers = createCardSortingReportHeader();

  // render data for csv report
  const csvReport = createCardSortingReportData(cardsReportData);

  const renderCategoriesBox = () => (
    <DragDropContext onDragEnd={onDragEnd}>
      <Box className={classes.categoriesContainer}>{renderCategories()}</Box>
    </DragDropContext>
  );

  return (
    <AdminActivityAuxView
      titleText={renderTitle(activeView)}
      activityName={name}
      printTitleLine2="Final Results"
      allowPrint={activeView === 'results'}
      hasDownloadButton={isActivityFinished}
      renderDownloadButton={() => (
        <CSVReportDownloadButton
          headers={headers}
          csvReport={csvReport}
          buttonText="Download Results"
          filename="Results.csv"
          topic={session.topic}
          activity="CardSorting"
          activityName={name}
          withoutLabel={true}
        />
      )}
    >
      <Box displayPrint="none">
        {(hasDuplicateCard || activeView === 'results') && !isActivityFinished && (
          <Typography className={classes.errorTitle}>
            {activeView === 'review'
              ? 'Resolve duplicate (red) cards to continue to next round.'
              : size(primaryCards) > 1 &&
                'Click any of the less popular cards to remove them from the next step.'}
          </Typography>
        )}
        {renderCategoriesBox()}
      </Box>
      <Box displayPrint="flex" display="none" flexDirection="column" flexWrap="wrap">
        {renderCategoriesBox()}
      </Box>
    </AdminActivityAuxView>
  );
};

export default CardSortingAdminViews;
