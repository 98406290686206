import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isEmpty, isLoaded, useFirestoreConnect } from 'react-redux-firebase';

import AdminActivityTemplate from 'components/AdminActivityTemplate';
import MuralAdminMural from 'components/Mural/Admin/MuralAdminMural';

import { useMuralSelector } from 'redux/selectors/muralSelectors';

import { MURAL_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';

const views = {
  mural: MuralAdminMural,
};

const MuralAdmin = () => {
  const routeParams = useParams();
  const mural = useMuralSelector();
  const session = useSelector(state => state.firestore.data.session);
  const meetingParticipants = useSelector(state => state.firestore.data.participants);

  // hooks up a firestore listener to Redux
  useFirestoreConnect(() => [
    {
      collection: 'mural',
      doc: `${routeParams.activityId}`,
      storeAs: 'mural',
    },
  ]);

  // ensure all of the data we need is loaded
  if (
    !isLoaded(session) ||
    isEmpty(session) ||
    !isLoaded(mural) ||
    isEmpty(mural) ||
    !isLoaded(meetingParticipants) ||
    routeParams.activityId !== mural?.id
  ) {
    return null;
  }

  const CurrentView = views[mural.state.activeView];

  return (
    <AdminActivityTemplate
      activityConfig={CONFIG}
      activityData={mural}
      session={session}
      CurrentView={CurrentView}
    />
  );
};

export default MuralAdmin;
