import React from 'react';
import { useDispatch } from 'react-redux';

//cr components
import AddBlockedTimeForm from 'components/Forms/Staffing/AddBlockedTimeForm';

//actions & selectors
import { openCreateBlockedTimeDialog } from 'redux/actions/staffingActions';
import { useStaffingSelector } from 'redux/selectors/staffing/staffingSelectors';
import FormDialog from 'components/Dialogs/FormDialog';

const CreateBlockedTimeDialog = () => {
  const { isOpenCreateBlockedTimeDialog } = useStaffingSelector();
  const dispatch = useDispatch();

  const closeDialog = () => {
    dispatch(openCreateBlockedTimeDialog(false));
  };

  return (
    <FormDialog
      handleClose={closeDialog}
      isOpen={isOpenCreateBlockedTimeDialog}
      title="Blocked Time"
      isTitleHasCloseIcon
    >
      <AddBlockedTimeForm closeDialog={closeDialog} />
    </FormDialog>
  );
};

export default CreateBlockedTimeDialog;
