import _ from 'lodash';
import { createSelector } from 'reselect';
import { ideasWithScore } from 'utils/25:10/report';

export const session2510Selector = state => state.firestore.data['25:10'] || {};

export const getTopic = createSelector([session2510Selector], session => session.topic);

export const getName = createSelector([session2510Selector], session => session.name);

export const getActiveView = createSelector([session2510Selector], session => session.activeView);

export const getIdeas = createSelector([session2510Selector], session => session.ideas);

export const getCurrentRound = createSelector(
  [session2510Selector],
  session => session.currentRound,
);

export const getAssignedIdea = participantId =>
  createSelector([session2510Selector, getIdeas], (session, ideas) => {
    const { currentRound, voteAssignments } = session;
    const assignedIdea = voteAssignments[currentRound].find(
      assignment => assignment.participantId === participantId,
    );
    return !assignedIdea ? null : ideas.find(idea => idea.id === assignedIdea.ideaId);
  });

export const getVoteAssignments = createSelector(
  [session2510Selector],
  session => session.voteAssignments,
);

export const getResults = createSelector(
  [getIdeas, getVoteAssignments],
  (ideas, voteAssignments) => {
    if (voteAssignments) {
      const voteAssignmentsValues = Object.values(voteAssignments);
      const ideasWithScoreOutput = ideasWithScore(ideas, voteAssignmentsValues);

      return _.orderBy(ideasWithScoreOutput, ['score'], ['desc']);
    }
  },
);

export const getNumVotes = createSelector(getIdeas, ideas => ideas.length);

export const getIdeaById = id =>
  createSelector([getIdeas], ideas => ideas.find(idea => idea.id === id));

export const getVotesForCurrentRound = createSelector([session2510Selector], session => {
  const { currentRound, voteAssignments } = session;
  return voteAssignments[currentRound].filter(assignment => assignment.vote).length;
});

export const getNumOfParticipants = createSelector([session2510Selector], session => {
  return 'participants' in session ? session.participants.length : session.numParticipants || 0;
});

export const getVotesToParticipantsRatio = createSelector(
  [getVotesForCurrentRound, getNumVotes],
  (numVotes, numParticipants) => {
    return {
      numVotes,
      numParticipants,
    };
  },
);

export const getIdeasToParticipantsRatio = createSelector(
  [getNumVotes, getNumOfParticipants],
  (numVotes, numParticipants) => {
    return {
      numVotes,
      numParticipants,
    };
  },
);

export const getSessionVote = createSelector(
  [getVotesToParticipantsRatio],
  ({ numVotes, numParticipants }) => {
    const remainingVotes = numParticipants - numVotes;

    return {
      numVotes,
      numParticipants,
      remainingVotes,
    };
  },
);

export const getParticipantsVote = participantId =>
  createSelector([session2510Selector], session => {
    const { currentRound, voteAssignments } = session;
    const assignment = voteAssignments[currentRound].find(
      assignment => assignment.participantId === participantId,
    );
    return !assignment ? null : assignment.vote;
  });
