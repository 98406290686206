import React from 'react';
import { useSelector } from 'react-redux';
// cr components
import ResultsView from 'components/Scorecard/Participant/ResultsView';
import AdminActivityAuxView from 'components/AdminActivityAuxView';
import CSVReportDownloadButton from 'components/CSVReportDownloadButton';
// selectors
import { getParticipantsMap } from 'redux/selectors/participantsSelectors';
import { useScorecardSelector, getParticipantsNotes } from 'redux/selectors/scorecardSelectors';
// utils
import { createScorecardReportHeader, createScorecardReportData } from 'utils/scorecard';

/**
 *  Renders Scorecard for 'results' phase on the Facilitator side
 */
const ScorecardResults = () => {
  const scorecard = useScorecardSelector();
  const { config, state, name, participantData } = scorecard;

  const session = useSelector(state => state.firestore.data.session);

  const participantsData = useSelector(getParticipantsMap);
  const notes = useSelector(getParticipantsNotes());

  if (!state?.activeView || state.activeView !== 'results') {
    return null;
  }

  const { answers, comments } = participantData;

  // render headers for csv report
  const headers = createScorecardReportHeader();

  // render data for csv report
  const csvReport = createScorecardReportData(notes, participantsData, config, answers, comments);

  return (
    <AdminActivityAuxView
      titleText="Results"
      allowPrint={state.activeView === 'results'}
      activityName={name}
      hasDownloadButton={state.activeView === 'results'}
      renderDownloadButton={() => (
        <CSVReportDownloadButton
          headers={headers}
          csvReport={csvReport}
          buttonText="Download Insights"
          filename="Insights.csv"
          topic={session.topic}
          activity="Scorecard"
          activityName={name}
          withoutLabel={true}
        />
      )}
    >
      <ResultsView
        sprintQuestions={config.sprintQuestions}
        prototypeQuestions={config.prototypeQuestions}
        testers={config.testers}
        scorecardName={name}
        context={`${state.activeView}Admin`}
      />
    </AdminActivityAuxView>
  );
};

export default ScorecardResults;
