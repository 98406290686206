import React from 'react';
// cr components
import ActivityPromptAdmin from 'components/ActivityPromptAdmin';
// selectors
import { useNoteAndVoteSelector } from 'redux/selectors/noteAndVoteSelectors';
// config
import { NOTE_AND_VOTE_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';

/**
 * Note & Vote view for the 'results' phase on the Facilitator side
 */
const NoteAndVoteAdminVote = () => {
  const { id, prompt } = useNoteAndVoteSelector();

  return <ActivityPromptAdmin prompt={prompt} collection={CONFIG.collection} activityId={id} />;
};

export default NoteAndVoteAdminVote;
