import React from 'react';
import { sortBy, map, size, find } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
// library components
import { Typography, Box } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
// cr components
import Flow from 'components/NoteAndMap/Participant/Flow';
import DottedProgress from 'components/DottedProgress';
// selectors
import {
  useNoteAndMapSelector,
  getSuperVotes,
  getParticipantsFlows,
} from 'redux/selectors/noteAndMapSelectors';
import { getParticipantsMap } from 'redux/selectors/participantsSelectors';
// actions
import { updateSuperVote } from 'redux/actions/noteAndMapActions';

const useStyles = makeStyles(theme => ({
  success: {
    color: theme.palette.success.main,
    fontSize: 14,
  },
  disabled: {
    color: grey[500],
    fontSize: 14,
  },
}));

/**
 * Note And Map view for the 'vote' phase on the Participant side
 */
const NoteAndMapParticipantVote = () => {
  const maxSuperVotes = 2;
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const {
    id,
    state: { activeView, superVoteParticipantId },
  } = useNoteAndMapSelector();
  const participantsData = useSelector(getParticipantsMap);
  const participantId = useSelector(state => state.firebase.auth.uid);

  const participantsFlows = useSelector(getParticipantsFlows());
  const superVoteTypes = useSelector(getSuperVotes());
  const superVoteTypesCount = size(superVoteTypes);
  const sortedFlows = sortBy(participantsFlows, 'participantId');

  const isActorSelect = find(superVoteTypes, voteType => voteType.type === 'actor');
  const isMomentSelect = find(superVoteTypes, voteType => voteType.type !== 'actor');

  // select key moment
  const handleSelectField = (journeyId, type, isSuperVoted, isVoted) => {
    if (
      isSuperVoted &&
      (maxSuperVotes === superVoteTypesCount ||
        (isActorSelect && type === 'actor') ||
        (isMomentSelect && type !== 'actor'))
    ) {
      return;
    }
    if (isVoted || type === 'actor') {
      const typeId = participantsFlows[journeyId].types[type]?.id;
      dispatch(updateSuperVote(id, typeId, type, isSuperVoted));
    }
  };

  const renderFlow = (flow, idx, flowParticipantId) => (
    <Flow
      key={`moment-${idx}`}
      participantFlow={flow}
      context={activeView}
      disabled={true}
      superVoteTypes={superVoteParticipantId === participantId ? superVoteTypes : []}
      handleVote={superVoteParticipantId === participantId ? handleSelectField : () => {}}
      createdFlowParticipantId={flowParticipantId}
    />
  );

  return (
    <>
      {map(sortedFlows, (flow, idx) => renderFlow(flow.types, idx, flow.participantId))}
      {superVoteParticipantId === participantId ? (
        <Box className="d-flex align-items-center flex-column">
          <DottedProgress
            className="ml-1"
            activeColor={theme.palette.success.main}
            totalCount={maxSuperVotes}
            activeCount={superVoteTypesCount}
          />
          <Typography
            className={cx(
              maxSuperVotes === superVoteTypesCount ? classes.success : classes.disabled,
            )}
          >
            {maxSuperVotes === superVoteTypesCount
              ? 'You’ve voted on Actor and Target Moment'
              : `the Actor ${isActorSelect ? 'was' : "wasn't"} voted, the Moment ${isMomentSelect ? 'was' : "wasn't"} voted`}
          </Typography>
        </Box>
      ) : (
        <Box className="d-flex align-items-center flex-column">
          <Typography className="pb-3 pt-2" color="textSecondary" variant="body2">
            {participantsData[superVoteParticipantId]
              ? participantsData[superVoteParticipantId].name
              : 'Unknown'}{' '}
            is deciding.
          </Typography>
        </Box>
      )}
    </>
  );
};

export default NoteAndMapParticipantVote;
