import React from 'react';
import PropTypes from 'prop-types';
import { useFirestore } from 'react-redux-firebase';
import { useDispatch, useSelector } from 'react-redux';
import { each, find, map, includes, filter, keys, findIndex, forEach } from 'lodash';
import * as Yup from 'yup';
// library components
import { Formik, Form } from 'formik';
import { Box, Button, Tooltip } from '@material-ui/core';
// cr components
import ButtonSpinner from 'components/ButtonSpinner/ButtonSpinner';
import AutoField from 'components/CustomFormComponents/AutoField';
import FieldsGroup from 'components/CustomFormComponents/FieldsGroup';
// actions
import { enqueueSuccessSnackbar, enqueueErrorSnackbar } from 'redux/actions/notifierActions';
// utils
import { uploadImage, deleteImage } from 'utils/uploadImage';

const ActivitySettingsForm = ({ closeDialog, activityConfig, activityData }) => {
  const db = useFirestore();
  const config = find(activityConfig.fields, { key: 'config' });
  const session = useSelector(state => state.firestore.data.session);
  const dispatch = useDispatch();

  const getFormSchema = () => {
    const schema = {};

    each(config.fields, field => {
      schema[field.key] = field.validator;
    });

    each(activityConfig.fields, field => {
      if (field.isSetupField) {
        schema[field.key] = field.validator;
      }
    });

    return schema;
  };

  const getInitialValues = () => {
    return {
      ...activityData.config,
      name: activityData.name,
      prompt: activityData.prompt,
    };
  };

  const renderConfigFields = () => {
    const groups = {};
    const fields = map(config.fields, (field, idx) => {
      if (!field.isSetupField && !field.isHardCodeSettings) {
        return;
      }

      const disabled =
        !includes(field.canEditDuring, activityData.state.activeView) || field.isHardCodeSettings;

      if (field.parent) {
        return;
      }

      if (field.child) {
        const child = find(config.fields, childFields => childFields.key === field.child);
        field.formField.child = child;
      }

      if (field.groupName) {
        groups[field.groupName] = groups[field.groupName] || {};
        groups[field.groupName].values = groups[field.groupName].values || [];
        groups[field.groupName].values.push(field);

        if (field.child) {
          const child = find(config.fields, childFields => childFields.key === field.child);
          each(groups[field.groupName].values, (parentFields, idx) => {
            if (parentFields.key === child?.parent) {
              groups[field.groupName].values[idx].child = child;
            }
          });
        }

        if (!groups[field.groupName].indexGroup) {
          groups[field.groupName].indexGroup = idx;
        }

        return;
      }

      return (
        <Tooltip
          key={`${field.formField.name}-${idx}`}
          disableFocusListener={!disabled}
          disableHoverListener={!disabled}
          disableTouchListener={!disabled}
          placement="top"
          arrow
          title={
            field.customDisabledMessage ??
            `Can't edit ${field.formField.label} during this phase of the activity`
          }
        >
          <Box>
            <AutoField
              key={`${field.formField.type}-${idx}`}
              field={field.formField}
              disabled={disabled || field.isHardCodeSettings}
            />
          </Box>
        </Tooltip>
      );
    });

    if (keys(groups).length !== 0) {
      each(groups, (group, idx) => {
        const disabledFields = {};
        const tooltipTitles = {};
        each(group.values, field => {
          const disabled =
            !includes(field.canEditDuring, activityData.state.activeView) ||
            field.isHardCodeSettings;
          disabledFields[field.key] = {
            fieldName: field.formField.name,
            disabled: disabled,
          };
          tooltipTitles[field.key] = {
            fieldName: field.formField.name,
            tooltipTitle:
              field.customDisabledMessage ??
              `Can't edit ${field.formField.label} during this phase of the activity`,
          };
        });
        fields[group.indexGroup] = (
          <FieldsGroup
            key={`fields-group-${idx}`}
            group={group.values}
            disabledFields={disabledFields}
            tooltipTitleFields={tooltipTitles}
          />
        );
      });
    }

    return fields;
  };

  const renderCommonFields = () =>
    map(filter(activityConfig.fields, { isSetupField: true }), (field, idx) => (
      <AutoField key={`${field.formField.name}-${idx}`} field={field.formField} />
    ));

  const settingActivityConfigSubmit = async (fields, values) => {
    const submit = {};

    for (const field of fields) {
      // if field type image upload then upload image to storage and get image Url
      if (field?.formField?.type === 'uploadImage') {
        if (typeof values[field.key] === 'object') {
          const collection = activityConfig.collection;
          const url = await uploadImage(values[field.key], collection, activityData.id);
          submit[field.key] = url || activityData.config?.[field.key];

          // remove from storage image, if field has image url
          if (activityData.config?.[field.key] && url) {
            const removeImageUrl = activityData.config?.[field.key];
            await deleteImage(removeImageUrl);
          }
        } else if (!values[field.key]) {
          // remove from storage image, if field has image url
          if (activityData.config?.[field.key]) {
            const removeImageUrl = activityData.config?.[field.key];
            await deleteImage(removeImageUrl);
          }
          submit[field.key] = values[field.key];
        } else {
          submit[field.key] = values[field.key];
        }
      } else {
        submit[field.key] = values[field.key];
      }
    }

    return submit;
  };

  return (
    <Formik
      initialValues={getInitialValues()}
      validationSchema={Yup.object().shape(getFormSchema())}
      onSubmit={async (values, { setSubmitting }) => {
        const batch = db.batch();

        /**
         * Update the activity name in the session object as well if it
         * has been changed
         */
        if (values.name !== getInitialValues().name) {
          let sessionActivities = [...session.activities];
          const sessionActivityIdx = findIndex(sessionActivities, ['id', activityData.id]);

          sessionActivities[sessionActivityIdx] = {
            ...sessionActivities[sessionActivityIdx],
            name: `${activityConfig.niceName}: ${values.name}`,
          };

          batch.set(
            db.doc(`sessions/${session.id}`),
            {
              activities: sessionActivities,
            },
            { merge: true },
          );
        }

        const data = {
          config: await settingActivityConfigSubmit(config.fields, values),
          name: values.name,
        };

        if (values.prompt) {
          data.prompt = values.prompt;
        }

        batch.update(db.doc(`${activityConfig.collection}/${activityData.id}`), { ...data });

        batch
          .commit()
          .then(() => {
            setSubmitting(false);
            closeDialog();
            dispatch(enqueueSuccessSnackbar('Saved'));
          })
          .catch(err => {
            console.log(err);
            setSubmitting(false);
            closeDialog();
            dispatch(enqueueErrorSnackbar('Error saving activity settings'));
          });
      }}
    >
      {({ isSubmitting, submitForm }) => (
        <Form className="pb-3">
          {renderCommonFields()}
          {renderConfigFields()}

          <div className="form-dialog-buttons">
            <Button
              variant="outlined"
              color="primary"
              disabled={isSubmitting}
              onClick={closeDialog}
            >
              CANCEL
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={submitForm}
            >
              SAVE
              {isSubmitting && <ButtonSpinner />}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

ActivitySettingsForm.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  activityConfig: PropTypes.object.isRequired,
  activityData: PropTypes.object.isRequired,
};

export default ActivitySettingsForm;
