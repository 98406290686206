import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sortBy, each, find } from 'lodash';
// library components
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// cr components
import ActivityPromptAdmin from 'components/ActivityPromptAdmin';
import ActivityActionButton from 'components/CustomButtons/ActivityActionButton';
import CategoriesAdmin from 'components/Categories/CategoriesAdmin';
import ActiveCardSetAdmin from './ActiveCardSetAdmin';
// selectors
import { useCardSortingSelector, getCategorizedCards } from 'redux/selectors/cardSortingSelectors';
// actions
import { updateActivityView, updateRound } from 'redux/actions/cardSortingActions';
// config
import { CARD_SORTING_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';

const useStyles = makeStyles(theme => ({
  promptAdmin: {
    marginBottom: theme.spacing(1),
  },
}));

/**
 * Card Sorting view for the 'review' phase on the Facilitator side
 */
const CardSortingAdminReview = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const cardSorting = useCardSortingSelector();
  const {
    id,
    prompt,
    config: { categories, primaryCategory, cardSet },
  } = cardSorting;

  const cards = useSelector(getCategorizedCards());

  const sortedCategories = sortBy(categories, ['order', 'value']);

  const onUpdateActivityView = () => {
    dispatch(updateRound(id, 2));
    dispatch(updateActivityView(id, 'primaryCategorize'));
  };

  const allCards = [];
  each(cards, category => {
    each(category, card => {
      allCards.push({ ...card });
    });
  });

  const hasDuplicateCard = !!find(allCards, card => card.isDuplicate);

  return (
    <Box className="d-flex flex-column justify-content-center">
      <ActivityPromptAdmin
        prompt={prompt}
        collection={CONFIG.collection}
        activityId={id}
        className={classes.promptAdmin}
      />
      <ActiveCardSetAdmin cardSetId={cardSet} />
      <CategoriesAdmin categories={sortedCategories} primaryCategoryId={primaryCategory} />
      <ActivityActionButton
        className="mt-2"
        text="Start Round 2"
        onClick={onUpdateActivityView}
        delayReady
        disabled={hasDuplicateCard}
      />
    </Box>
  );
};

export default CardSortingAdminReview;
