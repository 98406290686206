import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FacilitatorAccountSettingsForm from 'components/Forms/FacilitatorAccountSettingsForm';

import { openFacilitatorAccountSettingsDialog } from 'redux/actions/facilitatorSessionActions';
import { useFacilitatorSessionSelector } from 'redux/selectors/facilitatorSessionSelectors';

import './formDialog.scss';

export default function FacilitatorAccountSettingsDialog() {
  const { isOpenFacilitatorAccountSettingsDialog } = useFacilitatorSessionSelector();
  const dispatch = useDispatch();

  const closeDialog = () => {
    dispatch(openFacilitatorAccountSettingsDialog(false));
  };

  return (
    <div>
      <Dialog
        open={isOpenFacilitatorAccountSettingsDialog}
        onClose={closeDialog}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="sm"
        className="form-dialog"
      >
        <DialogTitle>Account Settings</DialogTitle>
        <DialogContent>
          <FacilitatorAccountSettingsForm closeDialog={closeDialog} />
        </DialogContent>
      </Dialog>
    </div>
  );
}
