import { createSelector } from 'reselect';
import { map, filter, size, each, sortBy, orderBy, find } from 'lodash';
import { useShallowEqualSelector } from '../utils';

const scorecardSelector = state => state.firestore.data.scorecard || {};

export const useScorecardSelector = () => {
  return useShallowEqualSelector(scorecardSelector);
};

/**
 * Get testers id
 *
 * @returns {Array.<{id: String, completed: Boolean}>}
 */
export const getTestersId = () =>
  createSelector([useScorecardSelector], ({ config: { testers }, state: { testersCompleted } }) => {
    const sortedTesters = sortBy(testers, 'order');
    return map(sortedTesters, tester => ({
      id: tester.id,
      completed: !!testersCompleted?.[tester.id],
    }));
  });

/**
 * Get data on which participants have completed interview,
 * formatted for the ActivityParticipantDetail component
 *
 * @returns {Array.<{id: String, completed: Boolean}>}
 */
export const getParticipantsCompletedInterview = testerId =>
  createSelector([useScorecardSelector], ({ participantData: { participants, answers } }) => {
    return map(participants, participantId => ({
      id: participantId,
      completed: size(answers?.[testerId]?.[participantId]) > 0,
      completedCount: size(answers?.[testerId]?.[participantId]),
    }));
  });

/**
 * Get participant answers
 */
export const getParticipantAnswersSelector = (currentTester, participantId) =>
  createSelector([useScorecardSelector], ({ participantData: { answers } }) => {
    return answers?.[currentTester]?.[participantId];
  });

/**
 * Get participant notes
 */
export const getParticipantNotesSelector = (participantId, testerId) =>
  createSelector([useScorecardSelector], ({ participantData: { notes } }) => {
    return orderBy(
      filter(notes, note => note.participantId === participantId && note.testerId === testerId),
      ['order'],
      ['desc'],
    );
  });

/**
 * Get questions with answers
 *
 * @returns {Array.<{id: String, answers: Array.<String>}>}
 */
export const getAnswersSelector = () =>
  createSelector(
    [useScorecardSelector],
    ({ config: { sprintQuestions, prototypeQuestions }, participantData: { answers } }) => {
      const questions = { ...sprintQuestions, ...prototypeQuestions };
      const participantAnswers = map(questions, question => ({
        id: question.id,
        answers: [],
      }));
      each(answers, answer =>
        each(answer, participants =>
          each(participantAnswers, participantAnswer => {
            const questionId = participantAnswer.id;
            if (participants?.[questionId]) {
              participantAnswer.answers.push(participants?.[questionId]);
            }
          }),
        ),
      );
      return participantAnswers;
    },
  );

/**
 * Get notes action and action time
 *
 * @returns {Array.<{id: String, answers: Array.<String>}>}
 */
export const getParticipantsNotes = () =>
  createSelector([useScorecardSelector], ({ participantData }) => {
    const participantsNotes = [];
    each(participantData?.notes, note => {
      if (size(note.participantsActions)) {
        each(note.participantsActions, action =>
          participantsNotes.push({ ...note, action, timeCreated: action.time }),
        );
      }
      participantsNotes.push(note);
    });
    return participantsNotes;
  });

/**
 * Get participant comments
 */
export const getParticipantCommentsSelector = (currentTester, participantId) =>
  createSelector([useScorecardSelector], ({ participantData: { comments } }) => {
    return comments?.[currentTester]?.[participantId];
  });

/**
 * Get questions with participant answers and comments
 *
 * @returns {Array.<{id: String, comments: Object}>}
 */
export const getCommentsSelector = () =>
  createSelector(
    [useScorecardSelector],
    ({
      config: { sprintQuestions, prototypeQuestions, testers },
      participantData: { answers, comments },
    }) => {
      const questions = { ...sprintQuestions, ...prototypeQuestions };
      const participantAnswers = map(questions, question => ({
        id: question.id,
        comments: {},
      }));
      each(comments, (tester, testerId) =>
        each(tester, (participants, idx) =>
          each(participantAnswers, participantAnswer => {
            const questionId = participantAnswer.id;
            if (participants?.[questionId]) {
              const tester = find(testers, tester => tester.id === testerId);
              participantAnswer.comments = {
                ...participantAnswer.comments,
                [testerId]: {
                  ...participantAnswer.comments?.[testerId],
                  [idx]: {
                    testerName: tester ? tester.value : '',
                    comment: participants?.[questionId],
                    answer: answers?.[testerId]?.[idx]?.[questionId],
                  },
                },
              };
            }
          }),
        ),
      );
      return participantAnswers;
    },
  );
