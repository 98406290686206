import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useFirestore } from 'react-redux-firebase';
import { useConfirm } from 'material-ui-confirm';
import copy from 'copy-to-clipboard';
import cx from 'classnames';

import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Tooltip from '@material-ui/core/Tooltip';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import FormDialog from 'components/Dialogs/FormDialog';
import CloneSessionForm from 'components/Forms/CloneSessionForm';
import RenameSessionForm from 'components/Forms/RenameSessionForm';
import RecapDeck from 'components/Dialogs/RecapDeck';
import QRDialog from 'components/Dialogs/QRDialog';

import { enqueueSnackbar, enqueueSuccessSnackbar } from '../../redux/actions/notifierActions';
import { exportMeetingReports } from 'utils/exportMeetingReports';
import { downloadMeetingTemplate } from 'utils/downloadMeetingTemplate';
import { useFacilitatorSessionSelector } from 'redux/selectors/facilitatorSessionSelectors';
import { showAppOverlaySpinner } from 'redux/actions/facilitatorSessionActions';

import { generateParticipantURL } from 'utils/generateParticipantURL';

export default function MenuListComposition({
  sessionId,
  activities,
  sessionTopic,
  recapDeck,
  isViewingShared,
  className,
}) {
  const participantLink = generateParticipantURL(sessionId, sessionTopic);

  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [isCloneFormOpen, setIsCloneFormOpen] = useState(false);
  const [isRenameFormOpen, setIsRenameFormOpen] = useState(false);
  const [isRecapDeckOpen, setIsRecapDeckOpen] = useState(false);
  const [isQRDialogOpen, setIsQRDialogOpen] = useState(false);
  const { isInternalUser } = useFacilitatorSessionSelector();
  const db = useFirestore();
  const confirm = useConfirm();
  const enableRecapDeck = Boolean(activities?.length);

  const toggleQRDialog = () => {
    setIsQRDialogOpen(!isQRDialogOpen);
  };

  const toggleCloneDialog = () => {
    setIsCloneFormOpen(!isCloneFormOpen);
  };

  const toggleRenameDialog = () => {
    setIsRenameFormOpen(!isRenameFormOpen);
  };

  const toggleRecapDeckDialog = () => {
    setOpen(false);
    setIsRecapDeckOpen(prev => !prev);
  };

  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleSessionLinkClick = () => {
    copy(participantLink, {
      onCopy: () => {
        dispatch(enqueueSuccessSnackbar('Participant Link Copied'));
      },
      format: 'text/plain',
    });
    setOpen(false);
  };

  const handleDelete = () => {
    confirm({ description: 'This action is permanent!' }).then(() => {
      db.doc(`/sessions/${sessionId}`).delete();
    });
  };

  const handleDownloadMeetingTemplate = () => {
    setOpen(false);
    downloadMeetingTemplate(sessionId);
  };

  const handleCopyRatingEmbed = () => {
    const sesionUrl = `${window.location.protocol}//${window.location.host}/session/${sessionId}/?sentiment=`;
    const ratingEmbed = `<table style="padding: 5"><tr>
                            <td><a href="${sesionUrl}satisfied"><img width="150" src="http://cdn.mcauto-images-production.sendgrid.net/ed4b6bc376101813/e8b8ae2e-b5e6-483d-933f-2004eb5223aa/401x401.png"/></a></td>
                            <td><a href="${sesionUrl}neutral"><img width="150" src="http://cdn.mcauto-images-production.sendgrid.net/ed4b6bc376101813/6b59244a-09fd-4d4e-93ce-3cadb54d1b88/401x401.png"/></a></td>
                            <td><a href="${sesionUrl}dissatisfied"><img width="150" src="http://cdn.mcauto-images-production.sendgrid.net/ed4b6bc376101813/c54b7bdb-8fad-429b-b864-c66f43c29fe3/401x401.png"/></a></td>
                         </tr></table>`;

    const copied = copy(ratingEmbed, {
      debug: true,
      format: 'text/html',
    });

    setOpen(false);
    dispatch(enqueueSnackbar({ message: 'Copied to clipboard', options: { variant: 'success' } }));
    //TODO: error checking and snack see copyActivityOutput.js
  };

  const handleExportMeeting = () => {
    dispatch(showAppOverlaySpinner(true));
    setTimeout(async () => {
      await exportMeetingReports(activities, sessionTopic, sessionId);
      dispatch(showAppOverlaySpinner(false));
    }, 500);
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <div className="session-card-menu">
        <Button
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          className={cx('session-card-menu-button', className)}
        >
          <div style={{ color: '#747474' }}>
            <MoreHorizIcon style={{ position: 'relative', top: '3px' }} />
          </div>
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          disablePortal
          transition
          placement="bottom-start"
          style={{ zIndex: 1 }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper elevation={6}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    // autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem onClick={handleSessionLinkClick}>Copy Participant Link</MenuItem>
                    <MenuItem onClick={toggleQRDialog}>Display Participant QR Code</MenuItem>
                    <MenuItem onClick={handleCopyRatingEmbed}>Copy Rating Embed</MenuItem>
                    {!isViewingShared && <MenuItem onClick={toggleRenameDialog}>Rename</MenuItem>}
                    <MenuItem onClick={toggleCloneDialog}>Clone</MenuItem>
                    {!isViewingShared && <MenuItem onClick={handleDelete}>Delete</MenuItem>}
                    <MenuItem onClick={handleExportMeeting}>Export Meeting</MenuItem>
                    {isInternalUser && (
                      <div>
                        <Tooltip
                          title="Add activities to enable Recap Deck"
                          arrow
                          placement="left"
                          enterDelay={500}
                          disableFocusListener={enableRecapDeck}
                          disableHoverListener={enableRecapDeck}
                          disableTouchListener={enableRecapDeck}
                        >
                          <div>
                            <MenuItem onClick={toggleRecapDeckDialog} disabled={!enableRecapDeck}>
                              Recap Deck
                            </MenuItem>
                          </div>
                        </Tooltip>
                        <MenuItem onClick={handleDownloadMeetingTemplate}>
                          Download Meeting JSON
                        </MenuItem>
                      </div>
                    )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
      <FormDialog handleClose={toggleCloneDialog} isOpen={isCloneFormOpen} title="Clone Meeting">
        <CloneSessionForm closeDialog={toggleCloneDialog} baseSessionId={sessionId} />
      </FormDialog>
      <FormDialog isOpen={isRenameFormOpen} handleClose={toggleRenameDialog} title="Rename Meeting">
        <RenameSessionForm closeDialog={toggleRenameDialog} sessionId={sessionId} />
      </FormDialog>
      <RecapDeck
        isOpen={isRecapDeckOpen}
        handleClose={toggleRecapDeckDialog}
        sessionId={sessionId}
        activities={activities}
        recapDeck={recapDeck}
      />
      <QRDialog
        handleClose={toggleQRDialog}
        isOpen={isQRDialogOpen}
        title="Participant QR Code"
        qrContent={participantLink}
      />
    </>
  );
}

MenuListComposition.propTypes = {
  sessionId: PropTypes.string.isRequired,
  activities: PropTypes.array,
  sessionTopic: PropTypes.string,
  isViewingShared: PropTypes.bool, // viewing as co-facilitator
  recapDeck: PropTypes.shape({
    activities: PropTypes.object, // recap deck images for all activities
    nextSteps: PropTypes.arrayOf(PropTypes.string), // recap deck next steps for all activities
    testerQuotes: PropTypes.arrayOf(PropTypes.string), // recap tester quotes steps for all activities
    userInsights: PropTypes.arrayOf(PropTypes.string), // recap deck user insights for all activities
  }),
  className: PropTypes.string,
};
