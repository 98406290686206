import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
// library components
import { makeStyles, Box, Typography, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 40,
    marginBottom: 4,
    borderRadius: 5,
    background: '#EEEEEE',
    color: '#898989',
    fontSize: 16,
  },
  activityName: {
    marginLeft: theme.spacing(1),
    color: '#000000',
    fontSize: 15,
  },
  iconButton: {
    padding: 5,
  },
  close: {
    marginLeft: 'auto',
  },
  drag: {
    cursor: 'grab',
  },
}));

/**
 * Renders co-facilitatetor name and ability for remove co-facilitator
 */
const CoFacilitator = ({
  facilitatorName,
  facilitatorId,
  facilitatorEmail,
  onDeleteFacilitator,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.itemContainer}>
      <Typography className={classes.activityName}>
        {facilitatorName === 'Unknown' ? `Unknown (${facilitatorEmail})` : facilitatorName}
      </Typography>
      <IconButton
        className={cx(classes.iconButton, classes.close)}
        onClick={() => onDeleteFacilitator(facilitatorId)}
      >
        <CloseIcon />
      </IconButton>
    </Box>
  );
};

CoFacilitator.defaultProps = {
  facilitatorName: 'Unknown',
  facilitatorEmail: '',
};

CoFacilitator.propTypes = {
  facilitatorName: PropTypes.string, // co-facilitator name
  facilitatorId: PropTypes.string.isRequired, // co-facilitator uid
  facilitatorEmail: PropTypes.string, // co-facilitator email
  onDeleteFacilitator: PropTypes.func.isRequired, // handle remove co-facilitator
};

export default CoFacilitator;
