import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import { useSelector } from 'react-redux';
import { uniq, compact } from 'lodash';
// cr components
import WordCloud from 'components/WordCloud/WordCloud';
// selectors
import { getQuestion } from 'redux/selectors/assessmentSelectors';

const ShortAnswerQuestionResultsSummary = ({ questionId, fontSizes }) => {
  const [words, setWords] = useState([]);
  const { answers } = useSelector(getQuestion(questionId));
  const uniqAnswers = uniq(answers);
  const answerCount = uniqAnswers.length;

  useEffect(() => {
    if (answerCount > 0) {
      const theWords = [];

      for (let answer of uniqAnswers) {
        for (let word of answer.split(' ')) {
          theWords.push(word.trim().toLowerCase());
        }
      }

      setWords(compact(theWords));
    }
  }, [answerCount]);

  if (!words.length) return null;

  const wordCloudProps = { words };

  if (fontSizes) {
    wordCloudProps.fontSizes = fontSizes;
  }

  return <WordCloud {...wordCloudProps} />;
};

ShortAnswerQuestionResultsSummary.propTypes = {
  questionId: PropTypes.string.isRequired,
  wordCloudProps: PropTypes.object,
  height: PropTypes.string,
};

export default React.memo(ShortAnswerQuestionResultsSummary, isEqual);
