import React from 'react';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
// cr components
import ActivityTemplate from 'components/ActivityTemplate/ActivityTemplate';
import PollingParticipantVote from 'components/Polling/Participant/PollingParticipantVote';
import PollingParticipantResults from 'components/Polling/Participant/PollingParticipantResults';
import ParticipantActivityContent from 'components/ParticipantActivityContent';
// selectors
import { usePollingSelector } from 'redux/selectors/pollingSelectors';
// constants
import { POLLING_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';

const views = {
  vote: PollingParticipantVote,
  results: PollingParticipantResults,
};

/**
 * Entry point for Polling on the Participant side.
 * Loads the Firestore object for the activity, ensures
 * the data is loaded before rendering the game, and determines
 * which view to show
 */
const PollingParticipant = ({
  match: {
    params: { activityId },
  },
}) => {
  const polling = usePollingSelector();

  // hooks up a firestore listener to Redux
  useFirestoreConnect(() => [
    {
      collection: 'polling',
      doc: `${activityId}`,
      storeAs: 'polling',
    },
  ]);

  // ensure all of the data we need is loaded
  if (!isLoaded(polling) || activityId !== polling?.id) {
    return null;
  }

  const Component = views[polling.state.activeView];

  return (
    <ActivityTemplate type="Polling" name={polling.name}>
      <ParticipantActivityContent
        activityData={polling}
        viewLabel={CONFIG.participantViewLabels[polling.state.activeView]}
        activityConfig={CONFIG}
      >
        <Component />
      </ParticipantActivityContent>
    </ActivityTemplate>
  );
};

export default PollingParticipant;
