import { enqueueErrorSnackbar } from 'redux/actions/notifierActions';
import { includes } from 'lodash';
import { createOutput } from 'utils/storyFlow';

import { STORY_FLOW_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';

/**
 * Update the active view for the activity
 * @param {String} view
 * @param {String} storyFlowId
 */
export const updateActivityView =
  (view, storyFlowId) =>
  (dispatch, getState, { getFirebase }) => {
    if (!includes(CONFIG.views, view)) {
      console.error(`View '${view}' does not exist in the activity config`);
      return;
    }

    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${storyFlowId}`)
      .set(
        {
          state: {
            activeView: view,
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error changing activity view'));
      });
  };

/**
 * Create participant story field
 *
 * @param {String} storyFlowId
 * @param {String} participantId
 */
export const createParticipantFlow =
  (storyFlowId, participantId, fieldName, value) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${storyFlowId}`)
      .set(
        {
          participantData: {
            flows: {
              [participantId]: {
                participantId: participantId,
                values: {
                  [fieldName]: value,
                },
              },
            },
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error create participant story field'));
      });
  };

/**
 * Update participant flow select
 *
 * @param {String} storyFlowId
 * @param {String} flowId
 * @param {String} participantId
 */
export const updateParticipantSelect =
  (storyFlowId, flowId, participantId) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${storyFlowId}`)
      .set(
        {
          participantData: {
            participantsSelectedFlow: {
              [participantId]: {
                selectedFlowId: flowId,
              },
            },
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error update participant flow select'));
      });
  };

/**
 * Remove participant flow select
 *
 * @param {String} storyFlowId
 * @param {String} flowId
 * @param {String} participantId
 */
export const removeParticipantSelect =
  (storyFlowId, participantId) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;
    const path = `participantData.participantsSelectedFlow.${participantId}`;

    db()
      .doc(`${CONFIG.collection}/${storyFlowId}`)
      .update({
        [path]: db.FieldValue.delete(),
      })
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error remove participant flow select'));
      });
  };

/**
 * Update super vote participant id
 *
 * @param {String} storyFlowId
 * @param {String} participantId
 */
export const updateSuperVoteParticipant =
  (storyFlowId, participantId) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${storyFlowId}`)
      .set(
        {
          state: {
            superVoteParticipantId: participantId,
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error update super vote participant id'));
      });
  };

/**
 * Update participant super vote flow
 *
 * @param {String} storyFlowId
 * @param {String} flowId
 * @param {String} participantId
 */
export const updateParticipantSuperVote =
  (storyFlowId, flowId, participantId) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`${CONFIG.collection}/${storyFlowId}`)
      .set(
        {
          participantData: {
            participantsSuperVoteFlow: {
              [participantId]: {
                superVoteFlowId: flowId,
              },
            },
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error update participant super vote flow'));
      });
  };

/**
 * Remove participant super vote flow
 *
 * @param {String} storyFlowId
 * @param {String} flowId
 * @param {String} participantId
 */
export const removeParticipantSuperVote =
  (storyFlowId, participantId) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;
    const path = `participantData.participantsSuperVoteFlow.${participantId}`;

    db()
      .doc(`${CONFIG.collection}/${storyFlowId}`)
      .update({
        [path]: db.FieldValue.delete(),
      })
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error update participant super vote flow'));
      });
  };

/**
 * Create the activity output and update the view to 'results'
 * @param {String} storyFlowId
 */
export const revealResults =
  storyFlowId =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;
    const state = getState();
    const { flows, participantsSuperVoteFlow } = state.firestore.data.storyFlow.participantData;
    const output = createOutput(flows, participantsSuperVoteFlow);

    db()
      .doc(`${CONFIG.collection}/${storyFlowId}`)
      .set(
        {
          output,
        },
        { merge: true },
      )
      .then(() => {
        dispatch(updateActivityView('results', storyFlowId));
      })
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error generating results'));
      });
  };
