import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { includes } from 'lodash';
// library components
import { Formik, Form } from 'formik';
import { Box } from '@material-ui/core';
// cr components
import TextArea from 'components/CustomFormComponents/TextArea';
import RadioButtonGroup from 'components/CustomFormComponents/RadioButtonGroup';
// actions
import { updateParticipantSurvey } from 'redux/actions/surveyActions';
// selectors
import {
  useSurveySelector,
  getParticipantAnswers,
  getQuestions,
} from 'redux/selectors/surveySelectors';

const SurveyParticipantForm = ({ context }) => {
  const dispatch = useDispatch();
  const participantId = useSelector(state => state.firebase.auth.uid);
  const { id } = useSurveySelector();
  const participantAnswers = useSelector(getParticipantAnswers(participantId));
  const questions = useSelector(getQuestions());

  // textarea components will be saved on blur
  const handleOnBlur = e => {
    if (e.target.name && e.target.type !== 'radio') {
      dispatch(updateParticipantSurvey(id, participantId, e.target.name, e.target.value));
    }
  };

  // radio components will be saved on change
  const handleOnChange = e => {
    if (e.target.name && e.target.type === 'radio') {
      console.dir(e.target);
      dispatch(updateParticipantSurvey(id, participantId, e.target.name, e.target.value));
    }
  };

  const getInitialValues = () => {
    const theInitialValues = {};

    if (!questions.length) return theInitialValues;

    questions.forEach(question => {
      theInitialValues[question.id] = participantAnswers[question.id] || '';
    });

    return theInitialValues;
  };

  const renderQuestions = () => {
    if (questions.length) {
      return questions.map(question => {
        const options = question.options;

        return (
          <Box mb={4} key={question.id}>
            {options ? (
              <RadioButtonGroup
                name={question.id}
                label={question.value}
                values={options}
                defaultValue=""
                stacked
                disabled={context === 'results'}
              />
            ) : (
              <TextArea
                name={question.id}
                type="text"
                label={question.value}
                rows={4}
                disabled={context === 'results'}
              />
            )}
          </Box>
        );
      });
    }
  };

  return (
    <Formik initialValues={getInitialValues()} enableReinitialize>
      {() => (
        <Form
          className="w-100"
          style={{ maxWidth: 600 }}
          onBlur={handleOnBlur}
          onChange={handleOnChange}
        >
          {renderQuestions()}
        </Form>
      )}
    </Formik>
  );
};

export default SurveyParticipantForm;
