import React from 'react';
// cr components
import ResultsView from 'components/Scorecard/Participant/ResultsView';
// selectors
import { useScorecardSelector } from 'redux/selectors/scorecardSelectors';

/**
 * Scorecard view for the 'results' phase on the Participant side
 */
const ScorecardParticipantResults = () => {
  const {
    config: { sprintQuestions, prototypeQuestions, testers },
    name,
    state: { activeView },
  } = useScorecardSelector();

  return (
    <ResultsView
      sprintQuestions={sprintQuestions}
      prototypeQuestions={prototypeQuestions}
      testers={testers}
      scorecardName={name}
      context={activeView}
    />
  );
};

export default ScorecardParticipantResults;
