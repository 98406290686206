import {
  DEAL_STATUS_STATES,
  DEAL_ERROR_STATUS_STATES,
  PROJECT_ERROR_STATUS_STATES,
  PROJECT_STATUS_STATES,
} from 'constants/staffing';

export const getDealStatusStyles = itemStatus => {
  switch (itemStatus) {
    case DEAL_STATUS_STATES.INITIALIZING:
      return {
        title: 'Initializing',
        containerClassname: 'deal-card-body-status-pending',
        textClassname: 'deal-card-body-day-text-pending',
      };
    case DEAL_ERROR_STATUS_STATES.INITIALIZING:
      return {
        title: 'Error Initializing',
        containerClassname:
          'deal-card-body-day-text-pending bg-danger deal-card-body-status-danger',
      };
    case DEAL_STATUS_STATES.PENDING:
      return {
        title: 'Pending',
        containerClassname: 'deal-card-body-status-pending',
        textClassname: 'deal-card-body-day-text-pending',
      };
    case DEAL_STATUS_STATES.CONTRACT_SENT:
      return {
        title: 'Contract sent',
        containerClassname: 'deal-card-body-status-pending',
        textClassname: 'deal-card-body-day-text-pending',
      };
    case DEAL_STATUS_STATES.READY_TO_INVOICE:
      return {
        title: 'Ready to Invoice',
        containerClassname: 'deal-card-body-status-pending',
        textClassname: 'deal-card-body-day-text-pending',
      };
    case DEAL_ERROR_STATUS_STATES.READY_TO_INVOICE:
      return {
        title: 'Error Invoicing',
        containerClassname:
          'deal-card-body-day-text-pending bg-danger deal-card-body-status-danger',
      };
    case DEAL_STATUS_STATES.DEPOSIT_INVOICED:
      return {
        title: 'Deposit Invoiced',
        containerClassname: 'deal-card-body-status-pending',
        textClassname: 'deal-card-body-day-text-pending',
      };
    case DEAL_STATUS_STATES.IN_PROGRESS:
      return {
        title: 'In Progress',
        containerClassname: 'deal-card-body-status-in-progress',
        textClassname: 'deal-card-body-day-text-in-progress',
      };
    case DEAL_STATUS_STATES.COMPLETED:
      return {
        title: 'Completed',
        containerClassname: 'deal-card-body-status-in-progress',
        textClassname: 'deal-card-body-day-text-in-progress',
      };
    case DEAL_STATUS_STATES.CLOSED:
    default:
      return {
        title: 'Closed',
        containerClassname: 'deal-card-body-status-closed',
        textClassname: 'deal-card-body-day-text-closed',
      };
  }
};

export const getProjectStatusStyles = itemStatus => {
  switch (itemStatus) {
    case PROJECT_STATUS_STATES.INITIALIZING:
      return {
        title: 'Initializing',
        containerClassname: 'project-card-body-status-pending',
      };
    case PROJECT_ERROR_STATUS_STATES.INITIALIZING:
      return {
        title: 'Error Initializing',
        containerClassname:
          'project-card-body-status-pending bg-danger project-card-body-status-danger',
      };
    case PROJECT_STATUS_STATES.ADDING_CALENDAR:
      return {
        title: 'Adding Calendar',
        containerClassname: 'project-card-body-status-pending',
      };
    case PROJECT_STATUS_STATES.SCHEDULED:
      return {
        title: 'Scheduled',
        containerClassname: 'project-card-body-status-pending',
      };
    case PROJECT_ERROR_STATUS_STATES.ADDING_CALENDAR:
      return {
        title: 'Error Adding to Calendar',
        containerClassname:
          'project-card-body-status-pending bg-danger project-card-body-status-danger',
      };
    case PROJECT_STATUS_STATES.CREATING_ASSETS:
      return {
        title: 'Creating Assets',
        containerClassname: 'project-card-body-status-pending',
      };
    case PROJECT_ERROR_STATUS_STATES.CREATING_ASSETS:
      return {
        title: 'Error Creating Assets',
        containerClassname:
          'project-card-body-status-pending bg-danger project-card-body-status-danger',
      };
    case PROJECT_STATUS_STATES.IN_PROGRESS:
      return {
        title: 'In-Progress',
        containerClassname: 'project-card-body-status-in-progress',
      };
    case PROJECT_STATUS_STATES.READY_TO_INVOICE:
      return {
        title: 'Ready to Invoice',
        containerClassname: 'project-card-body-status-in-progress',
      };
    case PROJECT_ERROR_STATUS_STATES.READY_TO_INVOICE:
      return {
        title: 'Error readying Invoice',
        containerClassname:
          'project-card-body-status-pending bg-danger project-card-body-status-danger',
      };
    case PROJECT_STATUS_STATES.INVOICING:
      return {
        title: 'Invoicing',
        containerClassname: 'project-card-body-status-in-progress',
      };
    case PROJECT_STATUS_STATES.CLOSED:
      return {
        title: 'Closed',
        containerClassname: 'project-card-body-status-closed',
      };
    case PROJECT_STATUS_STATES.PENDING:
    default:
      return {
        title: 'Pending',
        containerClassname: 'project-card-body-status-pending',
      };
  }
};
