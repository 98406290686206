import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { filter, map } from 'lodash';
import { useFirestore } from 'react-redux-firebase';
import { useDispatch } from 'react-redux';
// library components
import { makeStyles, Box, Typography, Button } from '@material-ui/core';
import { Formik, Form } from 'formik';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
// cr components
import CoFacilitator from './CoFacilitator';
import ButtonSpinner from 'components/ButtonSpinner/ButtonSpinner';
import FormDialog from 'components/Dialogs/FormDialog';
import AddCoFacilitatorForm from 'components/Forms/AddCoFacilitatorForm';
// actions
import { enqueueSuccessSnackbar, enqueueErrorSnackbar } from 'redux/actions/notifierActions';

const useStyles = makeStyles(theme => ({
  addButton: {
    width: '100%',
    height: 40,
    marginBottom: theme.spacing(3),
    background: theme.palette.primary.main,
    color: '#fff !important',
    fontSize: 14,
    fontWeight: '500 !important',
    textTransform: 'uppercase !important',
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24)',
    '&:hover': {
      background: theme.palette.primary.main,
    },
  },
  activitiesContainer: {
    marginTop: 30,
  },
  activitiesTitle: {
    marginBottom: theme.spacing(2),
    fontSize: 12,
    fontWeight: 300,
    color: '#5e5e5e',
  },
  cancelButton: {
    width: 105,
    height: 40,
    color: '#fff',
    background: '#9E9E9E',
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24)',
    '&:hover': {
      background: '#9E9E9E',
    },
  },
  saveButton: {
    width: 105,
    height: 40,
    color: '#fff',
    background: theme.palette.primary.main,
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24)',
    '&:hover': {
      background: theme.palette.primary.main,
    },
  },
  spinner: {
    marginLeft: '0 !important',
    color: '#fff',
  },
}));

/**
 *
 */
const RemoveCoFacilitatorsForm = ({ sessionId, closeDialog, coFacilitators }) => {
  const classes = useStyles();
  const db = useFirestore();
  const dispatch = useDispatch();

  const [facilitators, setFacilitators] = useState(coFacilitators);
  const [isCoFacilitatorFormOpen, setIsCoFacilitatorFormOpen] = useState(false);

  useEffect(() => {
    setFacilitators(coFacilitators);
  }, [coFacilitators]);

  // remove co-facilitator from state
  const onDeletefacilitator = facilitatorId => {
    const newFacilitatorList = filter(
      facilitators,
      facilitator => facilitator.uid !== facilitatorId,
    );
    setFacilitators(newFacilitatorList);
  };

  // renders all co-facilitators for current session
  const renderSessionCoFacilitators = () =>
    map(facilitators, (facilitator, idx) => (
      <CoFacilitator
        key={`facilitator-${idx}`}
        facilitatorName={facilitator.displayName}
        facilitatorEmail={facilitator.email}
        facilitatorId={facilitator.uid}
        onDeleteFacilitator={onDeletefacilitator}
      />
    ));

  // open/close add co-facilitator form
  const toggleCoFacilitatorForm = () => {
    setIsCoFacilitatorFormOpen(!isCoFacilitatorFormOpen);
  };

  return (
    <>
      <Formik
        initialValues={facilitators}
        enableReinitialize
        onSubmit={(values, { setSubmitting }) => {
          const data = {
            coFacilitators: map(values, value => value.uid),
          };

          db.doc(`sessions/${sessionId}`)
            .update({
              ...data,
            })
            .then(() => {
              setSubmitting(false);
              closeDialog();
              dispatch(enqueueSuccessSnackbar('Saved'));
            })
            .catch(err => {
              setSubmitting(false);
              closeDialog();
              dispatch(enqueueErrorSnackbar('Error editing co-facilitators'));
            });
        }}
      >
        {({ isSubmitting, submitForm }) => (
          <Form>
            <Button
              onClick={toggleCoFacilitatorForm}
              className={classes.addButton}
              startIcon={<GroupAddIcon />}
              color="primary"
            >
              Add Co-facilitator
            </Button>
            <Box>
              <Typography className={classes.activitiesTitle}>Current Co-Facilitators</Typography>
              {renderSessionCoFacilitators()}
            </Box>
            <div className={cx('form-dialog-buttons', 'justify-content-between')}>
              <Button
                className={classes.cancelButton}
                disabled={isSubmitting}
                onClick={closeDialog}
              >
                CANCEL
              </Button>
              <Button className={classes.saveButton} disabled={isSubmitting} onClick={submitForm}>
                {isSubmitting ? <ButtonSpinner className={classes.spinner} /> : 'SAVE'}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      <FormDialog
        handleClose={toggleCoFacilitatorForm}
        isOpen={isCoFacilitatorFormOpen}
        title="Add Co-Facilitator"
      >
        <AddCoFacilitatorForm closeDialog={toggleCoFacilitatorForm} />
      </FormDialog>
    </>
  );
};

RemoveCoFacilitatorsForm.propTypes = {
  sessionId: PropTypes.string.isRequired, // the current session id
  closeDialog: PropTypes.func.isRequired, // function close Form dialog
  coFacilitators: PropTypes.shape({
    displayName: PropTypes.string, // the name of the co-facilitators
    uid: PropTypes.string, // the id of the co-facilitators
  }),
};

export default RemoveCoFacilitatorsForm;
