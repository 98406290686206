import React from 'react';
import TextArea from '../../CustomFormComponents/TextArea';
import TextInput from '../../CustomFormComponents/TextInput';
import { Typography } from '@material-ui/core';
import Topic from 'components/25:10/Admin/Topic';

const BreakoutTopicAdmin = ({ classes }) => {
  return (
    <div className={classes.session}>
      {/* keep this but hide it because other code depends on it being in the form submission */}
      <TextInput
        name="topic"
        placeholder="Enter breakouts name here..."
        additionalClassNames={classes.topic}
        hidden
      />

      <TextArea
        name="description"
        placeholder="Insert description here:"
        multiline
        fullWidth
        rows={4}
        variant="filled"
        label="Description"
      />
    </div>
  );
};

export default BreakoutTopicAdmin;
