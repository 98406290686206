import React, { useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useDropzone } from 'react-dropzone';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import cx from 'classnames';
import { includes } from 'lodash';
import { useDispatch } from 'react-redux';
// library components
import { Paper, Box, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CropImageForm from 'components/CropImageForm';
// cr components
import ButtonSpinner from 'components/ButtonSpinner/ButtonSpinner';
// actions
import { showAppOverlaySpinner } from 'redux/actions/participantSessionActions';
import { enqueueErrorSnackbar } from 'redux/actions/notifierActions';
// utils
// import heic2any from "heic2any";
import { maybeConvertFromHeic } from 'utils/maybeConvertFromHeic';

const useStyles = makeStyles(() => ({
  input: {
    display: 'none',
  },
}));

const useImageStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: ({ isSmallScreen }) => (isSmallScreen ? '100%' : 340),
    height: 340,
    border: '1px dashed #C0CFDC',
    marginBottom: 16,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  addButton: {
    width: 40,
    height: 40,
    borderRadius: '50%',
    color: 'white',
  },
  addButtonIcon: {
    position: 'relative',
    top: 2,
    left: 2,
    fontSize: 36,
  },
  title: {
    width: '100%',
    marginTop: 5,
    fontSize: 18,
    lineHeight: '35px',
  },
  subTitle: {
    marginTop: 25,
    fontSize: 15,
  },
  dragActive: {
    border: `2px dashed #C0CFDC !important`,
    background: 'rgba(0,0,0,.1)',
  },
  buttonSpinnerBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 200,
    paddingRight: theme.spacing(5),
  },
  buttonSpinner: {
    color: theme.palette.primary.main,
  },
}));

/**
 * Renders add image view on the Participant side
 */
const NewImage = ({ uploadNewImage, isLoading }) => {
  const inputRef = useRef();
  const classes = useStyles();
  const isSmallScreen = useMediaQuery('(max-width: 767px)');
  const newImageClasses = useImageStyles({ isSmallScreen });
  const [isOpen, setIsOpen] = useState(false);
  const [fileUrl, setFileUrl] = useState();
  const dispatch = useDispatch();

  const closeDialog = () => {
    inputRef.current.value = '';
    setIsOpen(false);
  };

  // Participant upload new image. Read image file and return first file
  const onDrop = useCallback(async acceptedFiles => {
    if (includes(acceptedFiles[0].type, 'image')) {
      if (acceptedFiles && acceptedFiles.length > 0) {
        let imageBlob = await maybeConvertFromHeic(acceptedFiles[0]);

        if (!(imageBlob instanceof Blob)) {
          return setIsOpen(false);
        }

        const reader = new FileReader();
        reader.addEventListener('load', () => setFileUrl(reader.result));
        reader.readAsDataURL(imageBlob);
      }
      return setIsOpen(true);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <>
      <CropImageForm
        closeDialog={closeDialog}
        isOpen={isOpen}
        fileUrl={fileUrl}
        uploadNewImage={uploadNewImage}
        cropParam={{
          unit: '%',
          width: 100,
          height: 100,
        }}
      />
      <div {...getRootProps()}>
        <input
          {...getInputProps()}
          accept="image/*"
          className={classes.input}
          id="contained-button-file"
          type="file"
          ref={inputRef}
        />
        <label htmlFor="contained-button-file">
          <Paper
            className={cx(newImageClasses.container, isDragActive && newImageClasses.dragActive)}
            variant="outlined"
            id="ImageDropzone"
          >
            {isLoading ? (
              <Box className={newImageClasses.buttonSpinnerBox}>
                <ButtonSpinner className={newImageClasses.buttonSpinner} />
              </Box>
            ) : (
              <>
                <Box bgcolor="primary.main" className={newImageClasses.addButton} id="DropAddBtn">
                  <AddIcon
                    fontSize="large"
                    className={newImageClasses.addButtonIcon}
                    id="DropIcon"
                  />
                </Box>
                <Typography
                  className={newImageClasses.title}
                  align="center"
                  color="primary"
                  id="DropAddTxt"
                >
                  Add Photo or Image
                </Typography>
                <Typography align="center" color="textSecondary" variant="body2" id="DropAddTxt2">
                  (Or drop in this area)
                </Typography>
                <Typography
                  className={newImageClasses.subTitle}
                  align="center"
                  color="textSecondary"
                  id="DropAddTxt3"
                >
                  Photo, image instructions go here.
                </Typography>
              </>
            )}
          </Paper>
        </label>
      </div>
    </>
  );
};

NewImage.propTypes = {
  uploadNewImage: PropTypes.func, // upload new participant image
  isLoading: PropTypes.bool, // loading new image
};

export default NewImage;
