import React, { useState } from 'react';
import { sortBy, map, find, filter, size, head, split } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
// library components
import { makeStyles, Box, Typography } from '@material-ui/core';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
// cr components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Category from './Category';
import Card from './Card';
// actions
import { updateCardRound, deleteCardFromRound } from 'redux/actions/cardSortingActions';
// selectors
import {
  useCardSortingSelector,
  getPrimaryCategorizedCards,
  getParticipantRoundCards,
} from 'redux/selectors/cardSortingSelectors';

const useStyles = makeStyles(theme => ({
  categoriesContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: 'calc(100% + 20px)',
  },
  gridContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: 'calc(100% + 10px)',
    margin: '0 -5px',
  },
  gridItem: {
    padding: `0 5px !important`,
  },
  successColor: {
    color: theme.palette.success.main,
  },
  categorizedCard: {
    width: 95,
    height: 115,
    border: '1px dashed #CFCFCF',
    borderRadius: 4,
    background: '#F9F9F9',
    marginBottom: 10,
  },
  categorizedCardDrag: {
    background: '#E8E8E8',
  },
}));

/**
 * Card Sorting view for the 'primaryCategorize' (next rounds) phase on the Participant side
 */
const CardSortingParticipantPrimaryCategorize = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    id,
    config: { categories, primaryCategory },
    state: { activeView, cardsCount, round },
  } = useCardSortingSelector();

  const currentRound = `round${round}`;
  const previousRound = `round${round - 1}`;

  const participantId = useSelector(state => state.firebase.auth.uid);

  // get participant cards from primary category
  const participantCards = useSelector(getParticipantRoundCards(currentRound, participantId));
  const cards = useSelector(getPrimaryCategorizedCards(previousRound));

  console.log('cards', cards);

  const [dragCardId, setDragCardId] = useState('');

  const sortedCategories = filter(categories, { id: primaryCategory });

  const isCategorizeCompleted = size(participantCards) === cardsCount;

  // render categories
  const renderCategories = () =>
    map(sortedCategories, (category, idx) => {
      const sortedCards = sortBy(participantCards, ['order', 'cardId']);
      return (
        <Category
          key={`${category.value}-${idx}`}
          id={category.id}
          droppableId={`${idx}`}
          categoryName={category.value}
          cards={sortedCards}
          context={activeView}
          isPrimaryCategory={primaryCategory === category.id}
        />
      );
    });

  // render cards
  const renderCards = isDraggingOver =>
    map(cards, (card, idx) => {
      const cardCategorized = find(
        participantCards,
        categorizedCard => categorizedCard.cardId === card.cardId,
      );
      return (
        <GridItem className={classes.gridItem} key={`card-${idx}`}>
          {cardCategorized ? (
            <Box
              key={`card-${idx}`}
              className={cx(
                classes.categorizedCard,
                dragCardId === card.cardId && isDraggingOver && classes.categorizedCardDrag,
              )}
            />
          ) : (
            <Card card={card} draggableId={idx} context={activeView} />
          )}
          {dragCardId === card.cardId && !cardCategorized && (
            <Box
              className={cx(classes.categorizedCard, isDraggingOver && classes.categorizedCardDrag)}
            />
          )}
        </GridItem>
      );
    });

  const onDragEnd = result => {
    setDragCardId('');
    const { source, destination, draggableId } = result;

    if (!destination) {
      return;
    }

    const startIndex = source.droppableId;
    const finishIndex = destination.droppableId;

    if (startIndex === finishIndex) {
      return;
    }

    const cardId = head(split(draggableId, '-'));

    const finishCategoryId = sortedCategories[finishIndex] ? sortedCategories[finishIndex].id : '';
    const draggableCard = find(cards, card => card.cardId === cardId);

    if (finishCategoryId && isCategorizeCompleted) {
      return;
    }

    if (!finishCategoryId) {
      dispatch(deleteCardFromRound(id, participantId, currentRound, cardId));
      return;
    }

    dispatch(
      updateCardRound(
        id,
        participantId,
        currentRound,
        draggableCard.cardId,
        size(participantCards) || 0,
        draggableCard.icon || '',
        draggableCard.text || '',
        draggableCard.imageUrl || '',
      ),
    );
  };

  const onDragStart = start => {
    const cardId = head(split(start.draggableId, '-'));
    setDragCardId(cardId);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
      <Box className={classes.categoriesContainer}>{renderCategories()}</Box>
      <Droppable droppableId={`${sortedCategories.length}`} type="cards">
        {(provided, snapshot) => (
          <div ref={provided.innerRef} {...provided.droppableProps} className="w-100">
            <GridContainer className={classes.gridContainer} direction="row" spacing={3}>
              {renderCards(snapshot.isDraggingOver)}
            </GridContainer>
            <div style={{ display: 'none' }}>{provided.placeholder}</div>
          </div>
        )}
      </Droppable>
      <>
        {isCategorizeCompleted && <CheckCircleIcon className={classes.successColor} />}
        <Typography
          className={cx(isCategorizeCompleted && classes.successColor, 'mb-2')}
          color="textSecondary"
          variant="body2"
        >
          {`You’ve added ${size(participantCards)} of ${cardsCount} cards`}
        </Typography>
      </>
    </DragDropContext>
  );
};

export default CardSortingParticipantPrimaryCategorize;
