import heic2any from 'heic2any';
import { store } from '../index.js';
import { showAppOverlaySpinner as showParticipantSpinner } from 'redux/actions/participantSessionActions';
import { showAppOverlaySpinner as showFacilitatorSpinner } from 'redux/actions/facilitatorSessionActions';
import { enqueueErrorSnackbar } from 'redux/actions/notifierActions.js';

/**
 * Accepts a file blob, checks if it is an heic image
 * and converts it to a png blob if so
 *
 * @param {Blob} blob
 * @reutrns {Blob}
 */
export const maybeConvertFromHeic = async blob => {
  if (blob.type === 'image/heic') {
    _showSpinners(true);

    let newBlob = blob;
    let err = false;

    try {
      newBlob = await heic2any({ blob: newBlob });
    } catch (error) {
      store.dispatch(enqueueErrorSnackbar('Error converting from HEIC image format'));
      console.error(error);
      err = error;
    }

    _showSpinners(false);
    return err ? err : newBlob;
  }

  return blob;
};

const _showSpinners = showSpinner => {
  store.dispatch(showParticipantSpinner(showSpinner));
  store.dispatch(showFacilitatorSpinner(showSpinner));
};
