import React, { useRef, useEffect, useState, useCallback } from 'react';
import { map, size } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

import PageContentSectionHeader from 'components/PageContent/PageContentSectionHeader';
import ReactToPrint from 'react-to-print';
import { merge } from 'lodash';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import PrintIcon from '@material-ui/icons/Print';
import IconButton from '@material-ui/core/IconButton';
import ChartistGraph from 'react-chartist';

import SessionFeedbackSummary from 'views/Admin/SessionFeedbackDetail/SessionFeedbackSummary';
import FeedbackAnswersCollapse from 'views/Admin/SessionFeedbackDetail/FeedbackAnswersCollapse';
import FeedbackAnswersPrint from './FeedbackAnswersPrint';
import EllipsisMenu from 'components/EllipsisMenu';
import FormDialog from 'components/Dialogs/FormDialog';
import FeedbackSettingsForm from 'components/Forms/FeedbackSettingsForm';
import AdminSendToParticipantsActivity from 'components/AdminActivityTemplate/AdminSendToParticipantsActivity';

import { ACTIVITIES } from 'constants/activities';
import { FEEDBACK_CONFIG } from '@voltage-control/control-room-activities-config';

// utils
import { renderHtmlStringSimple } from 'utils/renderHtmlString';
import { getFacilitatorVideoInstructions } from 'utils/getActivityConfig';

import './sessionFeedbackDetail.scss';
const useStyles = makeStyles(theme => {
  return {
    root: {
      position: 'absolute',
      left: '50%',
      top: '38%',
      transform: 'translate(-60%, -55%)',
      color: props => theme.palette[props.sentiment]?.main,
      fontSize: 32,
      fontWeight: 400,
    },
  };
});

const useTitleStyles = makeStyles({
  title: {
    color: '#093B75',
    fontSize: 18,
    lineHeight: '30px',
  },
});

const useVideoStyles = makeStyles(theme => ({
  videoBox: {
    position: 'relative',
    width: '100%',
    height: 0,
    paddingBottom: '62.5%',
    marginTop: theme.spacing(3),
  },
  video: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
}));

const SessionFeedbackDetail = ({ session }) => {
  const [instructionsDialogOpen, setInstructionsDialogOpen] = useState(false);
  const [feedbackSettingsOpen, setFeedbackSettingsOpen] = useState(false);
  const printRef = useRef();
  const { filteredFeedback, filteredAnswersByQuestion, feedbackCount, totalSentimentCount } =
    session;
  const totalFeedbackStyles = useStyles(session);
  const classes = useTitleStyles();
  const videoClasses = useVideoStyles();
  const [graphConfig, setGraphConfig] = useState({
    data: {},
    options: {},
  });

  const fields = merge(FEEDBACK_CONFIG.fields, session.feedbackConfig);

  const { selectedFacilitatorRoute } = useSelector(state => state.facilitatorSession);
  const activeParticipantRoute = session?.activeRoute;

  console.log('filteredFeedback', filteredFeedback);

  useEffect(() => {
    setGraphConfig({
      data: {
        labels: map(filteredFeedback, (fb, key) => key),
        series: map(filteredFeedback, (fb, key) => ({
          value: fb.length,
          className: key,
        })),
      },
      options: {
        height: '165px',
        donut: true,
        donutWidth: 33,
      },
    });
  }, [totalSentimentCount, filteredFeedback]);

  const toggleInstructionsDialog = () => {
    setInstructionsDialogOpen(!instructionsDialogOpen);
  };

  const toggleFeedbackSettingsDialog = () => {
    setFeedbackSettingsOpen(!feedbackSettingsOpen);
  };

  const [videoInstruction, setVideoInstruction] = useState(
    ACTIVITIES.feedback?.facilitatorInstructionsVideo,
  );

  const activityHasInstructionsVideo = !!size(videoInstruction);

  /**
   * if the activity config doesn't contain video instructions,
   * then the video is loaded from the firebase storage. path - 'facilitatorInctructions/feedback'
   */
  const getVideoInstructions = useCallback(async () => {
    const result = await getFacilitatorVideoInstructions('feedback');
    if (size(result)) {
      setVideoInstruction(result);
    }
  }, []);

  useEffect(() => {
    if (!activityHasInstructionsVideo) {
      getVideoInstructions();
    }
  }, [getVideoInstructions, activityHasInstructionsVideo]);

  const renderTotalFeedback = () => {
    return <span className={totalFeedbackStyles.root}>{totalSentimentCount}</span>;
  };

  const getEllipsisMenuItems = () => {
    const menuItems = [
      { function: toggleInstructionsDialog, name: 'Instructions' },
      { function: toggleFeedbackSettingsDialog, name: 'Settings' },
    ];

    return map(menuItems, (item, idx) => (
      <MenuItem key={`menu-item-${idx}`} onClick={item.function}>
        {item.name}
      </MenuItem>
    ));
  };

  const renderHeader = () => (
    <>
      <div className="d-flex justify-content-between">
        <Typography className={classes.title}>Feedback</Typography>
        <EllipsisMenu menuItems={getEllipsisMenuItems()} />
      </div>

      {/* Instructions Dialog */}
      <FormDialog
        isOpen={instructionsDialogOpen}
        handleClose={toggleInstructionsDialog}
        isTitleHasCloseIcon={true}
        title="Feedback Instructions"
      >
        <Box className="mb-3">
          <Typography
            dangerouslySetInnerHTML={renderHtmlStringSimple(
              ACTIVITIES.feedback.facilitatorInstructions,
            )}
          />
          {activityHasInstructionsVideo && (
            <Box className={videoClasses.videoBox}>
              <iframe
                title="facilitator-instructions"
                className={videoClasses.video}
                src={videoInstruction}
                frameBorder="0"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allowFullScreen
              />
            </Box>
          )}
        </Box>
      </FormDialog>
      <Divider className="mb-2" />
    </>
  );

  return (
    <>
      {!feedbackCount && (
        <>
          {renderHeader()}
          <div className="feedback-detail">
            <PageContentSectionHeader
              title="No Feedback Received"
              centered
              style={{ marginBottom: 36 }}
            />
            <Typography align="center">
              Once your meeting receives some feedback it will show up here.
            </Typography>
          </div>
        </>
      )}
      {feedbackCount > 0 && (
        <>
          {renderHeader()}
          <div className="feedback-detail position-relative" ref={printRef}>
            {totalSentimentCount > 0 && (
              <>
                <PageContentSectionHeader
                  title="Meeting Sentiment"
                  centered
                  className="mb-5"
                  style={{ borderBottom: 'none' }}
                />
                <Box position="absolute" top="-3px" right="0" display="block" displayPrint="none">
                  <ReactToPrint
                    trigger={() => (
                      <IconButton>
                        <PrintIcon />
                      </IconButton>
                    )}
                    content={() => printRef.current}
                  />
                </Box>
                <Box display="flex" justifyContent="center" position="relative">
                  <ChartistGraph data={graphConfig.data} type="Pie" options={graphConfig.options} />
                  {renderTotalFeedback()}
                </Box>
                <SessionFeedbackSummary filteredFeedback={filteredFeedback} />
              </>
            )}

            <PageContentSectionHeader title="Responses" style={{ marginBottom: 10 }} />
            <Divider className="d-print-none" />
            <Divider className="d-none d-print-block mb-3" />
            <Box display="block" displayPrint="none">
              <FeedbackAnswersCollapse
                title={fields.likedLabel}
                answers={filteredAnswersByQuestion.liked}
              />
            </Box>
            <Box display="none" displayPrint="block">
              <FeedbackAnswersPrint
                title={fields.likedLabel}
                answers={filteredAnswersByQuestion.liked}
              />
            </Box>
            <Divider className="d-print-none" />
            <Box display="block" displayPrint="none">
              <FeedbackAnswersCollapse
                title={fields.didntLikeLabel}
                answers={filteredAnswersByQuestion.didntLike}
              />
            </Box>
            <Box display="none" displayPrint="block">
              <FeedbackAnswersPrint
                title={fields.didntLikeLabel}
                answers={filteredAnswersByQuestion.didntLike}
              />
            </Box>
            <Divider className="d-print-none" />
            <Box display="block" displayPrint="none">
              <FeedbackAnswersCollapse
                title={fields.opportunityLabel}
                answers={filteredAnswersByQuestion.opportunity}
              />
            </Box>
            <Box display="none" displayPrint="block" mb={3}>
              <FeedbackAnswersPrint
                title={fields.opportunityLabel}
                answers={filteredAnswersByQuestion.opportunity}
              />
            </Box>
            <Divider className="d-print-none" />
            <Box display="block" displayPrint="none">
              <FeedbackAnswersCollapse
                title={fields.outcomeLabel}
                answers={filteredAnswersByQuestion.outcome}
              />
            </Box>
            <Box display="none" displayPrint="block" mb={3}>
              <FeedbackAnswersPrint
                title={fields.outcomeLabel}
                answers={filteredAnswersByQuestion.outcome}
              />
            </Box>
            <Divider style={{ marginBottom: 20 }} className="d-print-none" />
          </div>
        </>
      )}
      <FormDialog
        handleClose={toggleFeedbackSettingsDialog}
        isOpen={feedbackSettingsOpen}
        title="Session Feedback Settings"
      >
        <FeedbackSettingsForm closeDialog={toggleFeedbackSettingsDialog} />
      </FormDialog>
      {activeParticipantRoute !== selectedFacilitatorRoute && (
        <AdminSendToParticipantsActivity
          className="mt-auto"
          session={session}
          activeFacilitatorRoute={selectedFacilitatorRoute}
        />
      )}
    </>
  );
};

export default SessionFeedbackDetail;
