import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
// library components
import { Button, DialogActions } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Rotate90DegreesCcwIcon from '@material-ui/icons/Rotate90DegreesCcw';

const useStyles = makeStyles(theme => ({
  cancelButton: {
    width: 105,
    height: 40,
    marginRight: 'auto',
    background: '#9E9E9E',
    color: '#fff',
    fontSize: 14,
    '&:hover': {
      background: '#9E9E9E',
      opacity: 0.9,
    },
  },
  rotateButton: {
    height: 40,
    minWidth: 40,
    marginRight: 'auto',
    borderRadius: '50%',
    background: theme.palette.primary.main,
    color: '#fff',
    boxShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
    fontSize: 14,
    '&:hover': {
      background: theme.palette.primary.main,
      opacity: 0.9,
    },
  },

  saveButton: {
    width: 105,
    height: 40,
    background: theme.palette.primary.main,
    color: '#fff',
    fontSize: 14,
    '&:hover': {
      background: theme.palette.primary.main,
      opacity: 0.9,
    },
  },
}));

const DialogAction = ({ handleClose, handleConfirm, handleRotate, confirmText, className }) => {
  const classes = useStyles();

  return (
    <DialogActions className={cx(className, 'p-0')}>
      <Button onClick={handleClose} className={classes.cancelButton}>
        CANCEL
      </Button>

      {handleRotate && (
        <Button onClick={handleRotate} className={classes.rotateButton}>
          <Rotate90DegreesCcwIcon />
        </Button>
      )}

      <Button className={classes.saveButton} onClick={handleConfirm}>
        {confirmText || 'SAVE'}
      </Button>
    </DialogActions>
  );
};

DialogAction.propTypes = {
  handleClose: PropTypes.func.isRequired, // close dialog
  handleConfirm: PropTypes.func.isRequired, // open dialog
  handleRotate: PropTypes.func, //rotate Image function -- Only display the Rotate Btn when this is passed
  confirmText: PropTypes.string, // text for submit button
  className: PropTypes.string, // styles for buttons container
};

export default DialogAction;
