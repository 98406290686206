import { useShallowEqualSelector } from '../utils';
import { includes } from 'lodash';

export const useMeetingRoomSelector = () => {
  return useShallowEqualSelector(
    ({
      meetingRoom: {
        roomStatus,
        roomId,
        roomName,
        videoMuted,
        audioMuted,
        jitsiDevices,
        previousRoom,
        facilitatorIsBroadcasting,
      },
    }) => {
      return {
        roomStatus,
        roomId,
        roomName,
        videoMuted,
        audioMuted,
        jitsiDevices,
        previousRoom,
        roomIsHallway: includes(roomId, 'hallway'),
        roomIsMain: includes(roomId, 'main'),
        roomIsBreakout: includes(roomId, 'breakout'),
        roomIsSidebar: includes(roomId, 'sidebar'),
        facilitatorIsBroadcasting,
      };
    },
  );
};
