import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { keys, includes } from 'lodash';
import { makeStyles } from '@material-ui/styles';
import cx from 'classnames';
// library components
import { Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
// cr components
import Journey from 'components/UserJourneyMoments/Participant/Journey';
// selectors
import { useUserJourneyMomentsSelector } from 'redux/selectors/userJourneyMomentsSelectors';
// actions
import { createParticipantJourney } from 'redux/actions/userJourneyMomentsActions';

const useStyles = makeStyles(theme => ({
  successColor: {
    color: theme.palette.success.main,
  },
}));

/**
 * User Journey Moments view for the 'create' phase on the Participant side
 */
const UserJourneyMomentsParticipantCreate = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const participantId = useSelector(state => state.firebase.auth.uid);
  const journeyFieldsCount = 6;

  const {
    id,
    participantData: { journeys },
    state: { activeView },
  } = useUserJourneyMomentsSelector();
  const [participantJourney, setParticipantJourney] = useState(journeys[participantId]?.values);

  const isJourneyComplete =
    keys(participantJourney).length >= journeyFieldsCount && !includes(participantJourney, '');

  // change journey field value
  const handleChangeJourneyField = (fieldName, value) => {
    setParticipantJourney({ ...participantJourney, [fieldName]: value });
  };

  // create new journey field
  const handleCreateJourneyField = (fieldName, value) => {
    dispatch(createParticipantJourney(id, participantId, fieldName, value));
  };

  return (
    <>
      <Journey
        handleCreateJourneyField={handleCreateJourneyField}
        handleChangeJourneyField={handleChangeJourneyField}
        participantJourney={participantJourney}
        activeView={activeView}
      />
      {isJourneyComplete && (
        <>
          <CheckCircleIcon className={classes.successColor} />
          <Typography className={cx(classes.successColor, 'mb-2')} variant="body2">
            All steps added!
          </Typography>
        </>
      )}
    </>
  );
};

export default UserJourneyMomentsParticipantCreate;
