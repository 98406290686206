import React from 'react';
import PropTypes from 'prop-types';
import { useFirestore, getFirebase } from 'react-redux-firebase';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';

import { Formik, Form } from 'formik';
import Button from '@material-ui/core/Button';

import ButtonSpinner from 'components/ButtonSpinner/ButtonSpinner';
import TextInput from 'components/CustomFormComponents/TextInput';

import { enqueueSnackbar } from '../../redux/actions/notifierActions';

const formSchema = Yup.object().shape({
  displayName: Yup.string().required('Required'),
});

const UpdateUserDisplayNameForm = ({ closeDialog }) => {
  const firebase = getFirebase();

  return (
    <Formik
      initialValues={{
        displayName: '',
      }}
      validationSchema={formSchema}
      onSubmit={(values, { resetForm, setSubmitting, setFieldError }) => {
        firebase.updateAuth({ displayName: values.displayName }, true).then(() => {
          setSubmitting(false);
          closeDialog();
        });
      }}
    >
      {({ isSubmitting, submitForm }) => (
        <Form style={{ paddingBottom: 20 }}>
          <TextInput
            name="displayName"
            type="text"
            label="Display Name"
            placeholder="your name as you'd like it to be shown in the app"
            required
          />

          <div className="form-dialog-buttons">
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={submitForm}
            >
              UPDATE DISPLAY NAME
              {isSubmitting && <ButtonSpinner />}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

UpdateUserDisplayNameForm.propTypes = {
  closeDialog: PropTypes.func.isRequired,
};

export default UpdateUserDisplayNameForm;
