import React from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { isEmpty } from 'react-redux-firebase';
import { map } from 'lodash';
// library components
import {
  Box,
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  makeStyles,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// cr components
import AdminActivityAuxView from 'components/AdminActivityAuxView';
import CSVReportDownloadButton from 'components/CSVReportDownloadButton';
import GrayBox from 'components/GrayBox/GrayBox';
import ButtonSpinner from 'components/ButtonSpinner/ButtonSpinner';
// selectors
import { useBrainwritingSelector } from 'redux/selectors/brainwritingSelectors';
import { getParticipantsMap } from 'redux/selectors/participantsSelectors';
// utils
import { renderHtmlStringSimple } from 'utils/renderHtmlString';
import { createBrainWritingHeader, createBrainWritingData } from 'utils/brainwriting/report';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: '500',
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 20,
  },
  buttonSpinnerBox: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    paddingRight: 20,
  },
  buttonSpinner: {
    color: theme.palette.primary.main,
  },
}));

/**
 * Renders ideas for the 'results' phases on the Facilitator side
 */
const BrainwritingAdminReview = () => {
  const classes = useStyles();
  const brainwriting = useBrainwritingSelector();
  const meetingParticipants = useSelector(getParticipantsMap);

  const session = useSelector(state => state.firestore.data.session);

  if (brainwriting?.activeView !== 'results' || isEmpty(session)) {
    return null;
  }

  const { ideas, name, activeView } = brainwriting;

  // render headers for csv report
  const headers = createBrainWritingHeader();

  // render data for csv report
  const csvReport = createBrainWritingData(ideas, meetingParticipants);

  const renderIdeas = () =>
    map(ideas, (idea, idx) => (
      <ExpansionPanel key={`idea_${idx}`}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>
            {meetingParticipants[idea.id]
              ? meetingParticipants[idea.id].name
              : 'Participant Unknown'}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography className="font-italic white-space-pre-wrap">
            <span dangerouslySetInnerHTML={renderHtmlStringSimple(idea.text)} />
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    ));

  const renderIdeasForPrint = () =>
    map(ideas, (idea, idx) => (
      <div className="mb-4" key={`idea_print_${idx}`}>
        <Typography className="mb-1">
          {meetingParticipants[idea.id] ? meetingParticipants[idea.id].name : 'Participant Unknown'}
        </Typography>
        <GrayBox className="text-left">
          <Typography className="white-space-pre-wrap">{idea.text}</Typography>
        </GrayBox>
      </div>
    ));

  return (
    <AdminActivityAuxView
      titleText="Results"
      allowPrint={activeView === 'results'}
      activityName={name}
      hasDownloadButton={activeView === 'results'}
      renderDownloadButton={() => (
        <CSVReportDownloadButton
          headers={headers}
          csvReport={csvReport}
          buttonText="Download Results"
          filename="Results.csv"
          topic={session.topic}
          activity="Brainwriting"
          activityName={name}
          withoutLabel={true}
        />
      )}
    >
      {_.isEmpty(meetingParticipants) ? (
        <Box className={classes.buttonSpinnerBox}>
          <ButtonSpinner className={classes.buttonSpinner} />
        </Box>
      ) : (
        <Box displayPrint="none">{renderIdeas()}</Box>
      )}
      <Box displayPrint="flex" display="none" justifyContent="start" flexWrap="wrap">
        {renderIdeasForPrint()}
      </Box>
    </AdminActivityAuxView>
  );
};

export default BrainwritingAdminReview;
