import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useConfirm } from 'material-ui-confirm';
// selectors
import { useAssessmentSelector } from 'redux/selectors/assessmentSelectors';
// library components
import { Box, Typography } from '@material-ui/core';
// cr components
import ActivityPromptAdmin from 'components/ActivityPromptAdmin';
import ActivityActionButton from 'components/CustomButtons/ActivityActionButton';
// selectors
import { getFacilitatorPromptSubText } from 'redux/selectors/assessmentSelectors';
// actions
import { startAssessment } from 'redux/actions/assessmentActions';
// config
import { ASSESSMENT_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';
// utils
import GrayBox from 'components/GrayBox';

/**
 * Assessment view for the 'write' phase on the Facilitator side
 */
const AssessmentAdminStart = () => {
  const confirm = useConfirm();
  const dispatch = useDispatch();
  const { id, prompt } = useAssessmentSelector();
  const promptText = useSelector(getFacilitatorPromptSubText());

  const onStart = () => {
    confirm({
      description: 'Questions/settings cannot be edited after this activity is started.',
    }).then(() => {
      dispatch(startAssessment(id));
    });
  };

  return (
    <Box className="d-flex flex-column justify-content-center">
      <ActivityPromptAdmin
        prompt={prompt}
        collection={CONFIG.collection}
        activityId={id}
        className="mb-1"
      />
      <GrayBox className="mb-5 p-2">
        <Typography
          className="d-flex flex-column align-items-start justify-content-start"
          dangerouslySetInnerHTML={{ __html: promptText }}
        />
      </GrayBox>
      <Box display="flex" justifyContent="center" flexWrap="wrap">
        <ActivityActionButton
          text="START"
          onClick={onStart}
          className="mx-1 mb-2"
          disabled={false}
        />
      </Box>
    </Box>
  );
};

export default AssessmentAdminStart;
