import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { filter, find, size, sortBy } from 'lodash';
// library components
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// cr components
import ActivityParticipantDetails from 'components/ActivityParticipantDetails';
import ActivityActionButton from 'components/CustomButtons/ActivityActionButton';
import GrayBox from 'components/GrayBox';
// selectors
import {
  useScorecardSelector,
  getParticipantsCompletedInterview,
  getTestersId,
} from 'redux/selectors/scorecardSelectors';
// actions
import {
  revealResults,
  updateCurrentTester,
  updateTestersComplete,
} from 'redux/actions/scorecardActions';
// utils
import { makeParticipantDetailsHeaderText } from 'utils/makeParticipantDetailsHeaderText';

const useStyles = makeStyles(theme => ({
  promptAdmin: {
    marginBottom: theme.spacing(1),
  },
  box: {
    marginBottom: theme.spacing(4),
  },
  boxTitle: {
    fontSize: 18,
  },
  testerCount: {
    fontWeight: 300,
    fontSize: 12,
    marginBottom: theme.spacing(1),
  },
}));

/**
 * Scorecard view for the 'interview' phase on the Facilitator side
 */
const ScorecardAdminInterview = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const scorecard = useScorecardSelector();

  const {
    id,
    config: { testers, prototypeQuestions, sprintQuestions },
    state: { currentTester, testersCompleted },
  } = scorecard;

  const allTestersId = useSelector(getTestersId());

  const sortedTesters = sortBy(testers, 'order');

  const tester = currentTester
    ? find(sortedTesters, tester => tester.id === currentTester)
    : find(allTestersId, tester => !tester.completed);
  if (!currentTester) {
    const order = size(testersCompleted);
    dispatch(updateCurrentTester(id, tester.id));
    dispatch(updateTestersComplete(id, tester.id, order));
  }

  // participants, who answered on all quections
  const participantsHaveFinished = useSelector(getParticipantsCompletedInterview(tester.id));
  const participantsHaveFinishedCount = filter(participantsHaveFinished, {
    completed: true,
  }).length;

  const participantsFinishedCountText = makeParticipantDetailsHeaderText(
    participantsHaveFinishedCount,
    participantsHaveFinished.length,
    'scored the questions',
  );
  const onUpdateActivityView = () => {
    dispatch(revealResults(id));
  };

  // the facilitator click on previous participant
  const onPreviousTester = () => {
    const currentTesterComplete = find(testersCompleted, tester => tester.id === currentTester);
    const previousTester = find(
      testersCompleted,
      tester => tester.order === currentTesterComplete.order - 1,
    );
    dispatch(updateCurrentTester(id, previousTester.id));
  };

  // the facilitator click on next participant
  const onNextTester = () => {
    const currentTesterOrder = testersCompleted?.[currentTester]?.order;
    let nextTester = find(testersCompleted, tester => tester.order === currentTesterOrder + 1);
    if (nextTester) {
      // if next tester was completed
      dispatch(updateCurrentTester(id, nextTester.id));
    } else {
      // get next tester order
      const order = size(testersCompleted);
      // find tester, who not completed
      nextTester = find(allTestersId, tester => !tester.completed);
      // update current tester
      dispatch(updateCurrentTester(id, nextTester.id));
      // set tester as completed
      dispatch(updateTestersComplete(id, nextTester.id, order));
    }
  };

  const testerOrder = testersCompleted?.[currentTester]
    ? testersCompleted[currentTester].order
    : size(testersCompleted);

  return (
    <Box className="d-flex flex-column justify-content-center">
      <Box>
        <Typography className={classes.testerCount} color="textSecondary">
          {`Tester ${testerOrder + 1} of ${size(testers)}`}
        </Typography>
        <GrayBox className={classes.box}>
          <Typography className={classes.boxTitle}>{tester.value}</Typography>
        </GrayBox>
      </Box>
      <ActivityParticipantDetails
        headerText={participantsFinishedCountText}
        hasCompletedLabel="Scored Questions"
        hasntCompletedLabel="Hasn’t Scored Questions"
        completedCountMax={size(testers) + size(prototypeQuestions) + size(sprintQuestions)}
        participantsCompletedStatus={participantsHaveFinished}
      />
      <Box display="flex" alignItems="center" justifyContent="center">
        <Box className="d-flex flex-wrap justify-content-center">
          <ActivityActionButton
            text="Previous"
            className="mx-1 mb-2"
            onClick={onPreviousTester}
            disabled={!testerOrder}
          />
          {size(testersCompleted) === size(testers) && tester.order === size(testers) ? (
            <ActivityActionButton
              text="Review Results"
              className="mx-1 mb-2"
              onClick={onUpdateActivityView}
            />
          ) : (
            <ActivityActionButton text="Next" className="mx-1 mb-2" onClick={onNextTester} />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ScorecardAdminInterview;
