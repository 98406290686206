import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import cx from 'classnames';
import { map } from 'lodash';

import { Box, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import CreateSessionForm from 'components/Forms/CreateSessionForm';
import AddActivityForm from 'components/Forms/AddActivityForm';

import { openCreateSessionDialog } from 'redux/actions/facilitatorSessionActions';
import { useFacilitatorSessionSelector } from 'redux/selectors/facilitatorSessionSelectors';

import { ACTIVITIES } from 'constants/activities';
import { MEETING_TEMPLATES } from 'constants/meetingTemplates';

import './formDialog.scss';

export default function CreateSessionDialog() {
  const { isOpenCreateSessionDialog } = useFacilitatorSessionSelector();
  /**
   * set by AddActivityForm on completion of settings for one of the
   * activiites in an activity template
   */
  const [activityIdx, setActivityIdx] = useState(0);
  /**
   * Set by CreateSessionForm if an meeting template is selected
   */
  const [sessionId, setSessionId] = useState(null);
  const [selectedMeetingTemplate, setSelectedMeetingTemplate] = useState('');
  const [includedActivities, setIncludedActivities] = useState([]);
  const dispatch = useDispatch();

  const closeDialog = () => {
    dispatch(openCreateSessionDialog(false));
  };

  const resetState = () => {
    setActivityIdx(0);
    setSessionId(null);
    setIncludedActivities([]);
    setSelectedMeetingTemplate('');
  };

  // const getActivityTemplateInitialValues = () => {
  //   if (MEETING_TEMPLATES[selectedMeetingTemplate]?.includedActivities[idx].collection === '25:10') {

  //   } else if (MEETING_TEMPLATES[selectedMeetingTemplate]?.includedActivities[idx].config) {
  //     return MEETING_TEMPLATES[selectedMeetingTemplate]?.includedActivities[idx].config;
  //   } else {
  //     return {};
  //   }
  // }

  const renderActivitySettingForms = () => {
    return map(includedActivities, (activity, idx) => (
      <Box
        className={cx({
          'd-none': idx !== activityIdx,
        })}
        key={`new-activity-${idx}`}
      >
        <AddActivityForm
          isActivityTemplate
          isLastActivityInTemplate={includedActivities.length === idx + 1}
          activityTemplateActivity={activity}
          sessionId={sessionId}
          closeDialog={closeDialog}
          activityIdx={activityIdx}
          setActivityIdx={setActivityIdx}
          resetCreateSessionDialogState={resetState}
          activityTemplateInitalValues={
            MEETING_TEMPLATES[selectedMeetingTemplate]?.includedActivities[idx].config ?? {}
          }
        />
      </Box>
    ));
  };

  const renderDialogTitle = () => {
    if (!includedActivities.length) {
      return 'Create Meeting';
    }

    const count = `${activityIdx + 1}/${includedActivities.length}`;
    return `Activity Settings ${count}: ${ACTIVITIES[includedActivities[activityIdx]].niceName}`;
  };

  return (
    <div>
      <Dialog
        open={isOpenCreateSessionDialog}
        onClose={closeDialog}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="sm"
        className="form-dialog"
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle>{renderDialogTitle()}</DialogTitle>
        <DialogContent>
          {!includedActivities.length && (
            <CreateSessionForm
              closeDialog={closeDialog}
              setIncludedActivities={setIncludedActivities}
              setSessionId={setSessionId}
              setSelectedMeetingTemplate={setSelectedMeetingTemplate}
              resetCreateSessionDialogState={resetState}
            />
          )}
          {includedActivities.length > 0 && renderActivitySettingForms()}
        </DialogContent>
      </Dialog>
    </div>
  );
}
