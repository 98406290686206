import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { grey } from '@material-ui/core/colors';
import clsx from 'clsx';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  topic: {
    backgroundColor: grey[100],
    textAlign: 'center',
    padding: '30px',
    marginBottom: theme.spacing(4),
    fontSize: 16,
  },
}));

const Topic = props => {
  const classes = useStyles();

  return (
    <Paper elevation={0} className={clsx(classes.topic, props.classes)}>
      <Typography>{props.topic}</Typography>
    </Paper>
  );
};

export default Topic;
