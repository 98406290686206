import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useFirebase } from 'react-redux-firebase';
import { makeStyles } from '@material-ui/core/styles';
import * as Sentry from '@sentry/react';
// library components
import {
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
  Button,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
// CR components
import ButtonSpinner from 'components/ButtonSpinner';

// action
import { enqueueErrorSnackbar } from 'redux/actions/notifierActions';

const useStyles = makeStyles(theme => ({
  closeIcon: {
    position: 'relative',
    right: `-${theme.spacing(2)}px`,
    top: `-${theme.spacing(1)}px`,
  },
}));

/**
 * Renders a Dialog to kick off Mural auth
 */
export default function MuralAuthDialog({ handleClose, isOpen }) {
  const classes = useStyles();
  const firebase = useFirebase();
  const dispatch = useDispatch();
  const [showButtonSpinner, setShowButtonSpinner] = useState(false);

  const getAuthUrl = () => {
    setShowButtonSpinner(true);

    firebase
      .functions()
      .httpsCallable('mural-getAuthUrl')({ redirectUrl: window.location.href })
      .then(res => {
        window.location.replace(res.data);
      })
      .catch(err => {
        dispatch(enqueueErrorSnackbar('Error getting Mural auth url'));
        Sentry.captureException(err);
        console.error(err);
      });
  };

  return (
    <div>
      <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle disableTypography className="pb-0">
          <div className="d-flex justify-content-between">
            <Typography variant="h6">Authorization Required</Typography>
            <IconButton aria-label="close" onClick={handleClose} className={classes.closeIcon}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent className="pb-4">
          <Box display="flex" justifyContent="center" flexDirection="column">
            <Typography className="mb-3 text-center mx-auto" style={{ maxWidth: 500 }}>
              You must authorize your Mural account for use with Control Room before using the Mural
              for Control Room activity.
            </Typography>
            <Button
              disabled={showButtonSpinner}
              variant="contained"
              color="primary"
              className="align-self-center"
              onClick={getAuthUrl}
            >
              {!showButtonSpinner && 'Authorize Mural Account'}
              {showButtonSpinner && <ButtonSpinner className="ml-0" />}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

MuralAuthDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};
