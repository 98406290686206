import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
// library components
import { makeStyles, Typography, Box, InputBase } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles(() => ({
  text: {
    fontSize: 13,
    color: grey[400],
    textAlign: 'center',
  },
  input: {
    color: grey[900],
    fontSize: 18,
    textAlign: 'center',
    padding: '0 10px',
  },
}));

const NewCardText = ({ handleCloseCreateText, createCardText, cardId, context, initialValue }) => {
  const classes = useStyles();
  const inputRef = useRef();
  const [value, setValue] = useState(context === 'edit' ? initialValue : '');

  // focus the input on load
  useEffect(() => {
    // needed a slight delay to work  ¯\_(ツ)_/¯
    setTimeout(function () {
      inputRef.current.focus();
    }, 100);
  }, []);

  const handleCreateField = event => {
    if (event.key === 'Enter') {
      createCardText(event.target.value, cardId);
      handleCloseCreateText();
    }
  };

  const handleChange = e => {
    setValue(e.target.value);
  };

  return (
    <Box className="position-relative">
      <InputBase
        inputRef={inputRef}
        classes={{
          input: classes.input,
        }}
        inputProps={{ 'aria-label': 'naked' }}
        rows={5}
        multiline
        fullWidth
        onKeyDown={handleCreateField}
        onBlur={handleCloseCreateText}
        onChange={handleChange}
        value={value}
      />
      <Typography className={classes.text}>Press enter to save</Typography>
    </Box>
  );
};

NewCardText.propTypes = {
  handleCloseCreateText: PropTypes.func.isRequired, // the function close the text redactor without saving
  createCardText: PropTypes.func.isRequired, // the function saves the text of the edited card
  cardId: PropTypes.string, // id of the card to be edited
  context: PropTypes.oneOf(['create', 'edit']).isRequired,
  initialValue: PropTypes.string, // initil value for the field if the context is 'edit'
};

export default NewCardText;
