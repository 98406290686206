import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import cx from 'classnames';
// library components
import { Box, Card, CardContent, IconButton, Typography } from '@material-ui/core';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import ThumbDownAltIcon from '@material-ui/icons/ThumbDownAlt';
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';
// cr components
import NoteForm from './NoteForm';
import EditCardButton from 'components/EditCardButton/EditCardButton';

const useStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minWidth: 160,
    height: 140,
    border: `1px solid ${grey[300]}`,
    marginBottom: 20,
    boxShadow: `0px 8px 18px rgba(0, 0, 0, 0.12)`,
  },
  cardContent: {
    display: 'flex',
    alignItems: 'start',
    width: '100%',
    height: '100%',
    padding: 20,
    overflow: 'hidden',
    '&:hover': {
      overflowY: 'auto',
    },
  },
  cardTxt: {
    width: '90%',
    color: theme.palette.text.primary,
  },
  cardSettings: {
    padding: 4,
  },
  cardButtons: {
    padding: theme.spacing(1),
    top: 4,
    color: grey[400],
  },
  cardLike: {
    color: ({ isLike }) => isLike && theme.palette.success.main,
  },
  cardUnlike: {
    color: ({ isUnlike }) => isUnlike && theme.palette.error.main,
  },
  cardQuote: {
    color: ({ isQuote }) => isQuote && theme.palette.indigo.main,
  },
  editButton: {
    width: 'initial',
    paddingLeft: theme.spacing(2),
  },
}));

/**
 * Renders view for note on the Participant side.
 */
const Note = ({ note, action, handleSelectNoteAction, handleRemoveNoteAction }) => {
  const [isEditing, setIsEditing] = useState(false);
  const isLike = action === 'like';
  const isUnlike = action === 'unlike';
  const isQuote = action === 'quote';
  const classes = useStyles({ isLike, isUnlike, isQuote });

  // Cancel edit idea
  const onCancelEditing = () => {
    setIsEditing(false);
  };

  // Edit idea
  const onEdit = () => {
    setIsEditing(true);
  };

  const onLike = () => {
    if (isLike) {
      handleRemoveNoteAction(note.noteId);
    } else {
      handleSelectNoteAction(note.noteId, 'like');
    }
  };

  const onUnlike = () => {
    if (isUnlike) {
      handleRemoveNoteAction(note.noteId);
    } else {
      handleSelectNoteAction(note.noteId, 'unlike');
    }
  };

  const onQuote = () => {
    if (isQuote) {
      handleRemoveNoteAction(note.noteId);
    } else {
      handleSelectNoteAction(note.noteId, 'quote');
    }
  };

  return (
    <Card className={classes.card}>
      <CardContent
        className={classes.cardContent}
        style={isEditing ? { paddingBottom: 12 } : {}}
        onClick={onEdit}
      >
        {isEditing ? (
          <NoteForm cancelEdit={onCancelEditing} type="edit" note={note} />
        ) : (
          <Typography className={classes.cardTxt} variant="body2">
            {note.note}
          </Typography>
        )}
      </CardContent>
      {!isEditing && (
        <Box className="pb-1 pr-1" display="flex" position="relative">
          <EditCardButton isVisible={true} onEdit={onEdit} className={classes.editButton} />
          <Box className="ml-auto">
            <IconButton
              className={cx(classes.cardButtons, classes.cardLike)}
              aria-label="ThumbUp"
              onClick={onLike}
            >
              <ThumbUpAltIcon fontSize="small" />
            </IconButton>
            <IconButton
              className={cx(classes.cardButtons, classes.cardUnlike)}
              aria-label="ThumbDown"
              onClick={onUnlike}
            >
              <ThumbDownAltIcon fontSize="small" />
            </IconButton>
            <IconButton
              className={cx(classes.cardButtons, classes.cardQuote)}
              aria-label="Quote"
              onClick={onQuote}
            >
              <FormatQuoteIcon fontSize="small" />
            </IconButton>
          </Box>
        </Box>
      )}
    </Card>
  );
};

Note.propTypes = {
  note: PropTypes.shape({
    note: PropTypes.string.isRequired, // the note text value
    noteId: PropTypes.string.isRequired, // the note id
  }),
  action: PropTypes.string, // the participant action for note
  handleSelectNoteAction: PropTypes.func, // handle select note action
  handleRemoveNoteAction: PropTypes.func, // handle remove note action
};

export default Note;
