import React from 'react';
import { useSelector } from 'react-redux';
import { map, sortBy } from 'lodash';
import { useTheme } from '@material-ui/core/styles';
// library components
import { Typography } from '@material-ui/core';
// cr components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import NoteCard from './NoteCard';
import DottedProgress from 'components/DottedProgress';
// selectors
import {
  usePollingSelector,
  getNotesWithVotes,
  getParticipantVotes,
} from 'redux/selectors/pollingSelectors';

/**
 * Polling view for the 'vote' phase on the Participant side
 */
const PollingParticipantVote = () => {
  const participantId = useSelector(state => state.firebase.auth.uid);
  const {
    config: { voteLimit },
  } = usePollingSelector();
  const notes = useSelector(getNotesWithVotes());
  const participantVotes = useSelector(getParticipantVotes(participantId));
  const submittedVotesCount = participantVotes.length;
  const theme = useTheme();
  const remainingVotes = voteLimit - submittedVotesCount;
  const remainingVotesText = remainingVotes === 1 ? 'vote' : 'votes';

  const sortedNotes = sortBy(notes, ['value', 'id']);

  const renderSubmittedNotes = () =>
    map(sortedNotes, note => (
      <GridItem key={note.id} xs={12} sm={6} md={4} lg={4} xl={4}>
        <NoteCard note={note} context="vote" />
      </GridItem>
    ));

  return (
    <>
      <GridContainer direction="row" className="mb-4">
        {renderSubmittedNotes()}
      </GridContainer>
      <DottedProgress
        activeColor={theme.palette.indigo.main}
        totalCount={voteLimit}
        activeCount={participantVotes.length}
      />
      <Typography className="mb-3">
        You have {remainingVotes} {remainingVotesText} left
      </Typography>
    </>
  );
};

export default PollingParticipantVote;
