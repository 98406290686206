import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isEmpty, isLoaded, useFirestoreConnect } from 'react-redux-firebase';

import AdminActivityTemplate from 'components/AdminActivityTemplate';
import HowMightWeAdminCreate from 'components/HowMightWe/Admin/HowMightWeAdminCreate';
import HowMightWeAdminShare from 'components/HowMightWe/Admin/HowMightWeAdminShare';
import HowMightWeAdminGroup from 'components/HowMightWe/Admin/HowMightWeAdminGroup';
import HowMightWeAdminVote from 'components/HowMightWe/Admin/HowMightWeAdminVote';
import HowMightWeAdminResults from 'components/HowMightWe/Admin/HowMightWeAdminResults';

import { useHowMightWeSelector } from 'redux/selectors/howMightWeSelectors';

import { HOW_MIGHT_WE_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';

const views = {
  create: HowMightWeAdminCreate,
  share: HowMightWeAdminShare,
  group: HowMightWeAdminGroup,
  vote: HowMightWeAdminVote,
  results: HowMightWeAdminResults,
};

const AdminHowMightWe = () => {
  const routeParams = useParams();
  const howMightWe = useHowMightWeSelector();
  const session = useSelector(state => state.firestore.data.session);
  const meetingParticipants = useSelector(state => state.firestore.data.participants);

  // hooks up a firestore listener to Redux
  useFirestoreConnect(() => [
    {
      collection: 'howMightWe',
      doc: `${routeParams.activityId}`,
      storeAs: 'howMightWe',
    },
  ]);

  // ensure all of the data we need is loaded
  if (
    !isLoaded(session) ||
    isEmpty(session) ||
    !isLoaded(howMightWe) ||
    isEmpty(howMightWe) ||
    !isLoaded(meetingParticipants) ||
    routeParams.activityId !== howMightWe?.id
  ) {
    return null;
  }

  const CurrentView = views[howMightWe.state.activeView];

  return (
    <AdminActivityTemplate
      activityConfig={CONFIG}
      activityData={howMightWe}
      session={session}
      CurrentView={CurrentView}
    />
  );
};

export default AdminHowMightWe;
