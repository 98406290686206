import React from 'react';
import PropTypes from 'prop-types';

const CrIcon = ({ size, color, className }) => {
  const renderLogo = () => (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
    >
      <path
        d="M34.7742 30.3369H31.19V16.3011H34.7742V21.5269H48V0H0V21.5269H12.7384V16.3011H16.3226V30.3369H12.7384V25.1111H0V48H48V25.1111H34.7742V30.3369ZM22.4731 26.9462H18.8889V19.6989H22.4731V26.9462ZM28.4301 33.7348H24.8459V12.9104H28.4301V33.7348Z"
        fill={color === '' ? 'currentColor' : color}
      />
    </svg>
  );

  return renderLogo();
};

CrIcon.defaultProps = {
  size: 21,
  color: 'currentColor',
  className: '',
};

CrIcon.propTypes = {
  width: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
};

export default CrIcon;
