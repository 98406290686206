import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { startCase, join, split, map, sortBy } from 'lodash';
import cx from 'classnames';
// library components
import { CSVLink } from 'react-csv';
import { makeStyles, Button } from '@material-ui/core';
// selectors
import { getGiveawayResults } from 'redux/selectors/giveawaySelectors';
//utils
import { createGiveawayReportHeader, createGiveawayReportData } from 'utils/giveaway';

const useClasses = makeStyles(theme => ({
  downloadButton: {
    width: 235,
    height: 45,
    marginLeft: 'auto',
    marginRight: 'auto',
    color: '#FFFFFF',
    fontSize: 14,
    fontWeight: 400,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  link: {
    color: '#FFFFFF',
    fontWeight: 500,
  },
}));

const GiveawayDownloadEntires = ({ topic, activity, activityName, filename, buttonText }) => {
  const classes = useClasses();

  const sessionTopicStartCase = startCase(topic);
  const sessionTopic = join(split(sessionTopicStartCase, ' '), '-');

  const nameStartCase = startCase(activityName);
  const name = join(split(nameStartCase, ' '), '-');

  const results = useSelector(getGiveawayResults());

  return (
    <Button className={classes.downloadButton}>
      <CSVLink
        data={createGiveawayReportData(results)}
        headers={createGiveawayReportHeader()}
        filename={`${sessionTopic}-${activity}-${name}-${filename}`}
        className={cx('btn', classes.link)}
        target="_blank"
      >
        {buttonText}
      </CSVLink>
    </Button>
  );
};

GiveawayDownloadEntires.propTypes = {
  topic: PropTypes.string.isRequired, // meeting topic
  activity: PropTypes.string.isRequired, // activity type
  activityName: PropTypes.string.isRequired, // activity name
  filename: PropTypes.string.isRequired, // end of file name (Result.csv)
  buttonText: PropTypes.string.isRequired, // button text
};

export default GiveawayDownloadEntires;
