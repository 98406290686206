import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';

import OpenJobList from './ContractorDashboard/OpenJobList';
import MyInfo from 'views/Admin/Staffing/ContractorDashboard/MyInfo';
import MyAvailabilityList from 'views/Admin/Staffing/ContractorDashboard/MyAvailabilityList';

const ContractorDashboard = () => {
  const user = useSelector(state => state.firebase.profile);

  useFirestoreConnect(() => [
    {
      collection: 'contractors',
      where: [['email', '==', user.email]],
      storeAs: 'currentContractor',
    },
    // TODO: is this dangerous?
    // Should we protect against save on another contracto's date?
    // Using this to be able to pull cities/countries for the autosuggest..
    {
      collection: 'contractors',
      storeAs: 'contractors',
    },
    {
      collection: 'projects',
      storeAs: 'projects',
    },
    {
      collection: 'projectMethodologies',
      storeAs: 'projectMethodologies',
    },
    {
      collection: 'deals',
      storeAs: 'deals',
    },
    {
      collection: 'companies',
      storeAs: 'companies',
    },
    {
      collection: 'staffBlockedTimes',
      where: [['userId', '==', user.uid]],
      storeAs: 'currentContractorBlockedTimes',
    },
  ]);

  return (
    <>
      <Switch>
        <Route
          exact
          path="/admin/contractor/"
          render={routeProps => <OpenJobList {...routeProps} />}
        />
        <Route
          exact
          path="/admin/contractor-info/"
          render={routeProps => <MyInfo {...routeProps} />}
        />
        <Route
          exact
          path="/admin/contractor-availability/"
          render={routeProps => <MyAvailabilityList {...routeProps} />}
        />
      </Switch>
    </>
  );
};

export default ContractorDashboard;
