import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { map } from 'lodash';
// library components
import { Box, Tabs, Tab } from '@material-ui/core';
// cr components
import Grouping from 'components/HowMightWe/Participant/Grouping';
import Results from 'components/HowMightWe/Participant/Results';
import AdminActivityAuxView from 'components/AdminActivityAuxView';
import CSVReportDownloadButton from 'components/CSVReportDownloadButton';
import Idea from '../Participant/Idea';
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
// selectors
import {
  useHowMightWeSelector,
  getVoteIdeas,
  getArchivedIdeas,
} from 'redux/selectors/howMightWeSelectors';
// utils
import { createHMWReportHeader, createHMWReportData } from 'utils/howMightWe';

const useStyles = makeStyles({
  tab: {
    minWidth: 80,
    fontSize: 12,
  },
  title: {
    width: '60%',
    fontWeight: 300,
  },
  grid: {
    width: 'calc(100% + 10px)',
    margin: '0 -5px',
    marginTop: 10,
  },
  gridItem: {
    padding: `0 5px !important`,
  },
});

/**
 * Renders groups and ideas on the Facilitator side
 */
const GroupsView = () => {
  const classes = useStyles();
  const howMightWe = useHowMightWeSelector();
  const { id, participantData, state, name } = howMightWe;

  const votedIdeas = useSelector(getVoteIdeas());
  const archivedIdeas = useSelector(getArchivedIdeas());

  const session = useSelector(state => state.firestore.data.session);

  const [activeTab, setActiveTab] = useState(0);

  if (!state?.activeView || (state.activeView !== 'group' && state.activeView !== 'results')) {
    return null;
  }

  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
  };

  // render headers for csv report
  const headers = createHMWReportHeader();

  // render data for csv report
  const csvReport = createHMWReportData(archivedIdeas, votedIdeas);

  const printResults = () => {
    const sortedIdeas = activeTab ? archivedIdeas : votedIdeas;

    return map(sortedIdeas, (idea, idx) => (
      <GridItem key={idea.ideaId} className={classes.gridItem} xs={12} sm={6} md={3} lg={3} xl={3}>
        <Idea
          votesCount={activeTab ? 0 : idea.votedParticipants.length}
          key={`idea-${idx}`}
          idea={idea}
          draggableId={idx}
          isFinished={true}
          context={state.activeView}
        />
      </GridItem>
    ));
  };

  return (
    <AdminActivityAuxView
      titleText={
        state.activeView === 'results'
          ? `How Might We Results`
          : `Drag into groups. Drag duplicates onto best version.`
      }
      allowPrint={state.activeView === 'results'}
      activityName={name}
      hasDownloadButton={state.activeView === 'results'}
      renderDownloadButton={() => (
        <CSVReportDownloadButton
          headers={headers}
          csvReport={csvReport}
          buttonText="Download All HMWs"
          filename="Results.csv"
          topic={session.topic}
          activity="HMW"
          activityName={name}
          withoutLabel={true}
        />
      )}
      hasTabs={state.activeView === 'results'}
      renderTabs={() => (
        <Box display="flex" alignItems="center">
          <Tabs
            value={activeTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChangeTab}
          >
            <Tab disableRipple className={classes.tab} label="Voted" />
            <Tab disableRipple className={classes.tab} label="Archived" />
          </Tabs>
        </Box>
      )}
    >
      <Box displayPrint="none">
        {state.activeView === 'group' ? (
          <Grouping
            howMightWeId={id}
            groups={participantData.groups}
            context={`${state.activeView}Admin`}
            title=""
          />
        ) : (
          <Results context={state.activeView} type={activeTab ? 'archived' : 'voted'} />
        )}
      </Box>
      <Box displayPrint="flex" display="none" justifyContent="start" flexWrap="wrap">
        <GridContainer className={classes.grid}>{printResults()}</GridContainer>
      </Box>
    </AdminActivityAuxView>
  );
};

export default GroupsView;
