import { enqueueErrorSnackbar } from 'redux/actions/notifierActions';

/**
 * Update live scribing text
 *
 * @param {String} liveScribingId
 * @param {String} liveScribingText
 * @param {Func} successCb
 */
export const updateLiveScribingText =
  (liveScribingId, liveScribingText, successCb) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`liveScribing/${liveScribingId}`)
      .set(
        {
          state: {
            liveScribingText,
          },
        },
        { merge: true },
      )
      .then(() => {
        if (successCb) {
          successCb();
        }
      })
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error update live scribing text'));
      });
  };

/**
 * Update edit user id
 *
 * @param {String} liveScribingId
 * @param {String} editUserId
 */
export const updateEditLock =
  (liveScribingId, editUserId) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;
    const docRef = db().doc(`liveScribing/${liveScribingId}`);
    const { uid } = getState().firebase.auth;

    db()
      .runTransaction(transaction => {
        return transaction.get(docRef).then(document => {
          if (document.data().state.editUserId !== '' && document.data().state.editUserId !== uid)
            return;

          transaction.set(
            docRef,
            {
              state: { editUserId },
            },
            { merge: true },
          );
        });
      })
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error updating edit lock'));
      });
  };
