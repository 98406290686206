import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import cx from 'classnames';
// library components
import { Box, Card, CardContent, CardActions, Typography } from '@material-ui/core';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
// cr components
import IdeaForm from './IdeaForm';
import DottedProgress from 'components/DottedProgress';
import EditCardButton from 'components/EditCardButton/EditCardButton';

const useStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minWidth: 160,
    minHeight: 150,
    border: `1px solid ${grey[300]}`,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px  0 !important`,
    marginBottom: theme.spacing(2),
    boxShadow: '0px 8px 18px rgba(0, 0, 0, 0.12)',
  },
  cardShare: {
    // flex: '1',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minWidth: 170,
    minHeight: 135,
    border: `1px solid ${grey[300]}`,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px  0 !important`,
    marginRight: ({ context }) => (context === 'groupAdmin' || context === 'group' ? 0 : 10),
    marginBottom: ({ context }) => (context === 'groupAdmin' || context === 'group' ? 0 : 10),
    cursor: 'pointer',
    boxShadow: '0px 8px 18px rgba(0, 0, 0, 0.12)',
    backgroundColor: ({ isDraggingOver }) => isDraggingOver && '#F4F4F4',
  },
  cardSelect: {
    padding: `6px 14px  0 !important`,
    border: `3px solid ${theme.palette.neutral.main}`,
    background: theme.palette.neutral.lightest,
  },
  cardDisabled: {
    border: `2px solid ${grey[300]}`,
    background: '#FDFDFD',
    cursor: 'default',
  },
  cardComplete: {
    cursor: 'default',
  },
  title: {
    fontWeight: 600,
    color: ({ isDisabled, context }) =>
      isDisabled && context !== 'results' ? grey[400] : theme.palette.neutral.main,
    userSelect: 'none',
  },
  cardContent: {
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    padding: `0 !important`,
  },
  cardTxt: {
    lineHeight: '20px',
    // height: '80px',
    color: ({ isDisabled, context }) =>
      isDisabled && context !== 'results'
        ? theme.palette.text.disabled
        : theme.palette.text.primary,
    // overflowX: 'hidden',
    overflowWrap: 'break-word',
    whiteSpace: 'pre-line',
  },
  textContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  dot: {
    marginBottom: 0,
  },
  combineBox: {
    position: 'relative',
    left: 5,
    display: 'flex',
    marginLeft: 'auto',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  combineTitle: {
    marginLeft: theme.spacing(1),
    marginRight: 4,
    fontSize: 12,
  },
  filterIcon: {
    color: '#5E5E5E',
    fontSize: 14,
  },
  actionContainer: {
    position: 'relative',
    // top: ({ context }) => (context === 'create' ? '24px' : 0),
    // left: ({ context }) => (context === 'create' ? '-14px' : 0),
    paddingLeft: 0,
  },
}));

/**
 * Renders view for note on the Participant side.
 */
const Idea = ({
  idea,
  isSelected,
  isFinished,
  isComplete,
  context,
  handleSelect,
  votesCount,
  handleOpenDuplicateCards,
  isDraggingOver,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isSelect, setIsSelect] = useState(isSelected);
  const isDisabled = idea.isCombine;
  const classes = useStyles({ isDisabled, context, isDraggingOver });

  // Cancel edit idea
  const onCancelEditing = () => {
    setIsEditing(false);
  };

  // Select idea
  const handleSelectIdea = () => {
    if (context === 'create') {
      setIsEditing(true);
    }

    if ((context === 'share' || context === 'vote') && !isFinished) {
      if (isComplete && !isSelect) {
        return;
      }
      const isIdeaSelected = !isSelect;
      setIsSelect(isIdeaSelected);
      handleSelect(idea.ideaId, isIdeaSelected);
    }
  };

  const onOpenDuplicateCards = () => {
    if (context === 'group' || context === 'groupAdmin') {
      handleOpenDuplicateCards(idea.ideaId);
    }
  };

  return (
    <Card
      className={cx(
        context === 'create' ? classes.card : classes.cardShare,
        (isSelect || (context === 'results' && votesCount)) && classes.cardSelect,
        isFinished && classes.cardComplete,
        isDisabled && context !== 'results' && classes.cardDisabled,
      )}
      onClick={handleSelectIdea}
    >
      <CardContent
        className={cx(classes.cardContent)}
        style={isEditing ? { paddingBottom: 45 } : {}}
      >
        <Box display="flex" alignItems="center">
          <Typography className={classes.title} variant="body2">
            HMW
          </Typography>
          {(idea.combineIdeasId?.length || idea.isCombine) && context !== 'results' && (
            <Box className={classes.combineBox} onClick={onOpenDuplicateCards}>
              <Typography className={cx(classes.combineTitle)}>
                {idea.combineIdeasId?.length ? idea.combineIdeasId.length + 1 : ''}
              </Typography>
              <FilterNoneIcon className={cx(classes.filterIcon)} />
            </Box>
          )}
        </Box>
        {isEditing && !isFinished ? (
          <IdeaForm cancelEdit={onCancelEditing} type="edit" idea={idea} />
        ) : (
          <Box className={classes.textContainer}>
            <Typography className={classes.cardTxt} variant="body2">
              {idea.idea}
            </Typography>
          </Box>
        )}
      </CardContent>
      <CardActions className={classes.actionContainer}>
        <EditCardButton isVisible={context === 'create' && !isEditing} onEdit={handleSelectIdea} />
        {context === 'results' && (
          <DottedProgress
            className={classes.dot}
            activeColor="#E59A05"
            totalCount={votesCount}
            activeCount={votesCount}
          />
        )}
      </CardActions>
    </Card>
  );
};

Idea.propTypes = {
  idea: PropTypes.shape({
    idea: PropTypes.string.isRequired, // idea text value
    ideaId: PropTypes.string.isRequired, // idea id
  }),
  context: PropTypes.oneOf(['create', 'share', 'group', 'groupAdmin', 'vote', 'results']), // active phase of state
  isFinished: PropTypes.bool, // if the participant finished with active phase, then - true
  isSelected: PropTypes.bool, // is idea selected
  isComplete: PropTypes.bool, // if the participant has chosen the required amount, then - true
  handleSelect: PropTypes.func, // get id and isSelected property of idea
  votesCount: PropTypes.number, // voted participants count for current idea
  onDisabledIdea: PropTypes.func, // function for set disabled idea
  isDraggingOver: PropTypes.bool, // dragged card over current card
};

export default Idea;
