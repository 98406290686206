import React, { useState, useEffect, useRef } from 'react';
import { useFirestore } from 'react-redux-firebase';

import { Button, Checkbox, FormControlLabel, Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import FormDialog from './FormDialog';
import { useFormik } from 'formik';
import { callUploadAttendeesToHS } from 'redux/actions/staffingActions';
import { enqueueSuccessSnackbar, enqueueErrorSnackbar } from 'redux/actions/notifierActions';
import { showAppOverlaySpinner } from 'redux/actions/facilitatorSessionActions';
import { useDispatch } from 'react-redux';
import { useConfirm } from 'material-ui-confirm';

const useStyles = makeStyles(theme => ({
  uploadInstructions: {
    marginBottom: theme.spacing(2),
    fontSize: '14px',
    color: theme.palette.text.secondary,
  },
  attendeesFolderLink: {
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  formField: {
    marginRight: theme.spacing(2),
  },
  checkboxField: {
    marginTop: theme.spacing(2),
  },
  formButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3),
    '& > button': {
      marginLeft: theme.spacing(2),
    },
  },
  cancelButton: {
    width: 105,
    height: 40,
    marginRight: 'auto',
    background: '#9E9E9E',
    color: '#fff',
    fontSize: 14,
    '&:hover': {
      background: '#9E9E9E',
      opacity: 0.9,
    },
  },
  uploadLogsContainer: {
    height: '300px',
    overflowY: 'auto',
    marginBottom: theme.spacing(2),
    border: '1px solid #e0e0e0',
    padding: theme.spacing(1),
    '& p': {
      marginBottom: '0.25em',
      lineHeight: 1.2,
    },
  },
}));

export default function UploadAttendeesDialog({ isOpen, handleClose, project }) {
  const dispatch = useDispatch();
  const confirm = useConfirm();

  const [autoScroll, setAutoScroll] = useState(true);
  const [showUploadForm, setShowUploadForm] = useState(true);
  const [uploadLogs, setUploadLogs] = useState([]);
  const [isUploadCompleted, setIsUploadCompleted] = useState(false);
  const [uploadSessionId, setUploadSessionId] = useState(null);

  const db = useFirestore();
  const logsEndRef = useRef(null);
  const projectId = project.id;

  const formik = useFormik({
    initialValues: {
      skipRows: 0,
      maxRows: 100,
      dryRun: true,
    },
    onSubmit: (values, { setSubmitting }) => {
      setUploadLogs([]);
      setIsUploadCompleted(false);
      setShowUploadForm(false);

      dispatch(
        callUploadAttendeesToHS(
          projectId,
          values.dryRun,
          values.skipRows,
          values.maxRows,
          sessionId => {
            // Success callback
            console.log('Received session ID:', sessionId); // Debug log
            setUploadSessionId(sessionId);
            dispatch(showAppOverlaySpinner(false));
            dispatch(enqueueSuccessSnackbar());
            setSubmitting(false);
          },
          error => {
            // Error callback
            console.error('Upload failed:', error);
            dispatch(showAppOverlaySpinner(false));
            dispatch(enqueueErrorSnackbar('Upload failed'));
            setSubmitting(false);
            setShowUploadForm(true);
          },
        ),
      );
    },
  });

  useEffect(() => {
    let unsubscribe;
    if (uploadSessionId) {
      console.log('Setting up listener for session ID:', uploadSessionId);
      unsubscribe = db
        .collection('hsAttendeeUpload')
        .doc(uploadSessionId)
        .onSnapshot(
          docSnapshot => {
            console.log('Snapshot received:', docSnapshot);

            if (docSnapshot.exists) {
              const data = docSnapshot.data();
              console.log('Document data:', data);

              if (data && Array.isArray(data.logs) && data.logs.length > 0) {
                const logMessages = data.logs.map(log => log.message);
                console.log('Setting upload logs:', logMessages);

                setUploadLogs(logMessages);

                if (data.completed) {
                  console.log('Upload completed');
                  setIsUploadCompleted(true);
                  unsubscribe();
                }

                if (data.aborted) {
                  console.log('Upload aborted');
                  setIsUploadCompleted(true);
                  unsubscribe();
                }
              } else {
                console.log('Document exists but has no logs or invalid data');
              }
            } else {
              console.log('No document found for session ID:', uploadSessionId);
            }
          },
          error => {
            console.error('Error listening to Firestore:', error);
          },
        );
    }
    return () => {
      if (unsubscribe) {
        console.log('Unsubscribing from Firestore listener');
        unsubscribe();
      }
    };
  }, [uploadSessionId, db]);

  useEffect(() => {
    if (autoScroll && logsEndRef.current) {
      logsEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [autoScroll, uploadLogs]);

  const classes = useStyles();

  const handleDialogClose = () => {
    if (!isUploadCompleted && uploadSessionId) {
      abortAndCloseUploadDialog();
    } else {
      handleClose();
      resetUploadForm();
    }
  };

  const abortUpload = async () => {
    if (!isUploadCompleted && uploadSessionId) {
      try {
        db.doc(`hsAttendeeUpload/${uploadSessionId}`).set({ aborted: true }, { merge: true });
        console.log('uploadSessionId abort True', uploadSessionId);
        console.log('Upload aborted successfully');
        dispatch(enqueueSuccessSnackbar('Upload aborted successfully'));
      } catch (error) {
        console.error('Error aborting upload:', error);
        dispatch(enqueueErrorSnackbar('Failed to abort upload'));
      }
    }
  };

  const abortAndCloseUploadDialog = async () => {
    if (!isUploadCompleted && uploadSessionId) {
      confirm({
        description: 'Are you sure you want to abort the upload?',
      })
        .then(async () => {
          await abortUpload();
          resetUploadForm();
          handleClose();
        })
        .catch(() => {
          // User cancelled the abort action
        });
    } else {
      resetUploadForm();
      handleClose();
    }
  };

  const resetUploadForm = () => {
    formik.resetForm();
    setUploadSessionId(null);
    setUploadLogs([]);
    setShowUploadForm(true);
    setIsUploadCompleted(false);
  };

  return (
    <FormDialog
      isOpen={isOpen}
      handleClose={handleDialogClose}
      isTitleHasCloseIcon={true}
      title="Upload Attendees to HubSpot"
    >
      {showUploadForm ? (
        <form onSubmit={formik.handleSubmit}>
          <div className={classes.uploadInstructions}>
            <p>
              Upload the file from WordPress to the{' '}
              {project.attendeesFolder?.webViewLink ? (
                <a
                  href={project.attendeesFolder.webViewLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.attendeesFolderLink}
                >
                  attendees folder
                </a>
              ) : (
                'attendees folder'
              )}{' '}
              and name it <strong>attendee-hubspot-upload.csv</strong> before proceeding. Switch to
              json upload.
            </p>
          </div>

          <Grid container spacing={2} alignItems="center">
            {/* Form fields */}
            <Grid item xs={4}>
              <TextField
                fullWidth
                id="skipRows"
                name="skipRows"
                label="Skip Rows"
                type="number"
                value={formik.values.skipRows}
                onChange={formik.handleChange}
                error={formik.touched.skipRows && Boolean(formik.errors.skipRows)}
                helperText={formik.touched.skipRows && formik.errors.skipRows}
                margin="normal"
                className={classes.formField}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                id="maxRows"
                name="maxRows"
                label="Max Rows"
                type="number"
                value={formik.values.maxRows}
                onChange={formik.handleChange}
                error={formik.touched.maxRows && Boolean(formik.errors.maxRows)}
                helperText={formik.touched.maxRows && formik.errors.maxRows}
                margin="normal"
                className={classes.formField}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    id="dryRun"
                    name="dryRun"
                    checked={formik.values.dryRun}
                    onChange={formik.handleChange}
                    color="primary"
                  />
                }
                label="Dry Run"
                className={classes.checkboxField}
              />
            </Grid>
          </Grid>

          <div className={classes.formButtonContainer}>
            <Button
              className={classes.cancelButton}
              variant="contained"
              onClick={handleClose}
              disabled={formik.isSubmitting}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              disabled={formik.isSubmitting}
            >
              Submit
            </Button>
          </div>
        </form>
      ) : (
        <div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <h4>Upload Progress</h4>
            <FormControlLabel
              control={
                <Checkbox
                  checked={autoScroll}
                  onChange={e => setAutoScroll(e.target.checked)}
                  color="primary"
                />
              }
              label="Auto-scroll to new logs"
            />
          </div>
          <p>Session ID: {uploadSessionId}</p>
          <div className={classes.uploadLogsContainer}>
            {uploadLogs.length > 0 ? (
              uploadLogs.map((log, index) => (
                <p key={index} style={{ fontWeight: log.startsWith('***') ? 'bold' : 'normal' }}>
                  {log}
                </p>
              ))
            ) : (
              <p>Waiting for logs...</p>
            )}
            <div ref={logsEndRef} />
          </div>
          <div className={classes.formButtonContainer}>
            <Button
              className={classes.cancelButton}
              variant="contained"
              onClick={abortAndCloseUploadDialog}
            >
              {isUploadCompleted ? 'Close' : 'Abort'}
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={resetUploadForm}
              disabled={!isUploadCompleted}
            >
              Upload Again
            </Button>
          </div>
        </div>
      )}
    </FormDialog>
  );
}
