import React from 'react';
import PropTypes from 'prop-types';

import { Button, makeStyles } from '@material-ui/core';
import { darken } from '@material-ui/core/styles';

const bgColorMap = {
  green: '#02A05C',
};

const textColorMap = {
  green: 'white',
};

const useStyles = makeStyles({
  root: {
    color: props => textColorMap[props.color],
    backgroundColor: props => bgColorMap[props.color],
    '&:hover': {
      backgroundColor: props => darken(bgColorMap[props.color], 0.3),
    },
  },
});

const CustomButton = props => {
  const { children, color, variant, ...rest } = props;
  const classes = useStyles(props);
  return (
    <Button variant={variant} {...rest} classes={classes}>
      {children}
    </Button>
  );
};

CustomButton.defaultProps = {
  variant: 'contained',
  color: 'green',
};

CustomButton.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.oneOf(['green']).isRequired,
  variant: PropTypes.oneOf(['contained', 'outlined']),
};

export default CustomButton;
