import { UPDATE_PARTICIPANTS } from 'redux/types/participantsTypes';

const initialState = {};

export default function participantReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_PARTICIPANTS:
      return action.payload;
    default:
      return state;
  }
}
