import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { filter } from 'lodash';
// library components
import { Box } from '@material-ui/core';
// cr components
import ActivityParticipantDetails from 'components/ActivityParticipantDetails';
import ActivityPromptAdmin from 'components/ActivityPromptAdmin';
import ActivityActionButton from 'components/CustomButtons/ActivityActionButton';
import PollingOptions from './PollingOptions';
// selectors
import {
  usePollingSelector,
  getParticipantsHaveVoted,
  getNotesWithVotes,
} from 'redux/selectors/pollingSelectors';
// actions
import { revealResults } from 'redux/actions/pollingActions';
// config
import { POLLING_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';
// utils
import { makeParticipantDetailsHeaderText } from 'utils/makeParticipantDetailsHeaderText';

/**
 * Polling view for the 'vote' phase on the Facilitator side
 */
const PollingAdminVote = () => {
  const dispatch = useDispatch();
  const {
    id,
    prompt,
    participantData: { participants },
    config,
  } = usePollingSelector();
  const notes = useSelector(getNotesWithVotes());
  const participantsHaveVotedData = useSelector(getParticipantsHaveVoted());
  const participantsVotedCount = filter(participantsHaveVotedData, { completed: true }).length;

  const headerText = makeParticipantDetailsHeaderText(
    participantsVotedCount,
    participants.length,
    'voted',
  );

  const onRevealResults = () => {
    dispatch(revealResults(notes, id));
  };

  return (
    <Box className="d-flex flex-column justify-content-center">
      <ActivityPromptAdmin
        prompt={prompt}
        collection={CONFIG.collection}
        activityId={id}
        className="mb-1"
      />
      <Box mb={2}>
        <PollingOptions context="vote" />
      </Box>
      <ActivityParticipantDetails
        headerText={headerText}
        hasCompletedLabel="Voted"
        hasntCompletedLabel="Hasn't Voted"
        completedCountMax={config.voteLimit}
        participantsCompletedStatus={participantsHaveVotedData}
      />
      <ActivityActionButton text="END POLL" onClick={onRevealResults} className="mb-2" />
    </Box>
  );
};

export default PollingAdminVote;
