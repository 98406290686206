import React from 'react';
// cr components
import ImagesBox from './ImagesBox';
// selectors
import { useHeatMappingSelector } from 'redux/selectors/heatMappingSelectors';

/**
 * Heat-Mapping view for the 'strawPollResults' phase on the Participant side
 */
const HeatMappingParticipantStrawPollResults = () => {
  const {
    participantData: { sketches },
    state,
  } = useHeatMappingSelector();

  return <ImagesBox context={state.activeView} comments={sketches} />;
};

export default HeatMappingParticipantStrawPollResults;
