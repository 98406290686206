import { useShallowEqualSelector } from '../utils';
import { createSelector } from 'reselect';
import { includes } from 'lodash';

const profileSelector = state => state.firebase.profile;

export const useFacilitatorSessionSelector = () => {
  return useShallowEqualSelector(
    ({
      facilitatorSession: {
        showVideo,
        selectedFacilitatorRoute,
        isOpenPremiumDialog,
        isOpenFacilitatorAccountSettingsDialog,
        isOpenCreateSessionDialog,
        isOpenMuralAuthDialog,
        showAppOverlaySpinner,
        isOpenInfoDialog,
        infoDialogContent,
      },
      firebase: { profile },
    }) => {
      return {
        showVideo,
        selectedFacilitatorRoute,
        isOpenPremiumDialog,
        isOpenFacilitatorAccountSettingsDialog,
        isOpenCreateSessionDialog,
        showAppOverlaySpinner,
        isOpenMuralAuthDialog,
        isOpenInfoDialog,
        infoDialogContent,
        isInternalUser: isInternalUser(profile),
        isSubscriber: isSubscriber(profile),
        muralWorkspaceOptions: profile.muralWorkspaces
          ? profile.muralWorkspaces.map(({ name, id }) => ({ name, value: id }))
          : [],
      };
    },
  );
};

export const facilitatorSubscriptionSelector = () =>
  createSelector([profileSelector], profile => {
    return {
      hasStripeSubscription: profile?.token?.claims?.stripeRole === 'subscriber',
      // hasFreeSubscription: profile.hasFreeSubscription || profile.isInternalUser || includes(profile.email, '@voltagecontrol.com'),
      /**
       * Everyone that is not a subscriber is considered to have a free subscription now.
       * If they are currently a paid subscriber, nothing will change for them. If they are
       * not a paid subscriber, they will be considered to have a free subscription and see the
       * "You have a complimentary subscirption" message when they click the "Premium Subscription"
       * item in the left nav.
       */
      hasFreeSubscription: profile?.token?.claims?.stripeRole !== 'subscriber',
      isSubscriber: isSubscriber(profile),
      isStaffingAdmin: profile.staffingAdmin,
      isVCContractor: profile.vcContractor,
      isInternalUser: isInternalUser(profile),
    };
  });

const isInternalUser = profile =>
  profile.isInternalUser || includes(profile.email, '@voltagecontrol.com');

const isSubscriber = profile => true;
// profile.hasFreeSubscription
// || profile.isInternalUser
// || includes(profile.email, '@voltagecontrol.com')
// || profile?.token?.claims?.stripeRole === 'subscriber';
