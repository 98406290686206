import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { map, sortBy, has, filter, size, each, orderBy, findIndex } from 'lodash';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
// library components
import { Box, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
// cr components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import NoteCard from './NoteCard';
import NewNote from './NewNote';
import Category from './Category';
// actions
import { updateNote } from 'redux/actions/noteAndCategorizeAction';
// selectors
import {
  getParticipantNotes,
  useNoteAndCategorizeSelector,
} from 'redux/selectors/noteAndCategorizeSelectors';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: ({ format, isShowPrevCategory }) =>
      format === 'Serial' && isShowPrevCategory ? 'column' : 'row',
    flexWrap: 'wrap',
    width: '100%',
    marginBottom: theme.spacing(2),
  },
}));

const useDropContainerStyles = makeStyles(theme => ({
  dropContainer: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 30,
  },
  buttonText: {
    width: 310,
    marginBottom: theme.spacing(2),
  },
  gridItem: {
    padding: `0 10px !important`,
  },
  successColor: {
    color: theme.palette.success.main,
  },
  previousCategory: {
    flex: 1,
  },
}));

/**
 * Note & Categorize view for the 'write note and categorize' phase on the Participant side
 */
const NoteAndCategorizeParticipantWrite = () => {
  const participantId = useSelector(state => state.firebase.auth.uid);
  const participantNotes = useSelector(getParticipantNotes(participantId));
  const sortedParticipantNotes = orderBy(participantNotes, ['order'], ['desc']);
  const {
    id,
    config: { categories, format, ideasPerCategory, isShowPrevCategory },
    participantData: { currentCategoryId, previousCategoryId },
    participantData,
  } = useNoteAndCategorizeSelector();

  const classes = useStyles({ format, isShowPrevCategory });
  const dropContainerClasses = useDropContainerStyles();

  const [notes, setNotes] = useState([...sortedParticipantNotes]);
  const allNotes = orderBy(participantData?.notes, ['order', 'noteId'], ['desc']);
  const dispatch = useDispatch();
  let sortField = 'value';
  const sortedCategories = sortBy(
    map(categories, category => {
      sortField = has(category, 'order') ? 'order' : 'value';
      return category;
    }),
    sortField,
  );

  const [isCreated, setIsCreated] = useState(false);
  const [editNoteId, setEditNoteId] = useState();
  const [categoryId, setCategoryId] = useState(currentCategoryId);

  let isCategorizeCompleted = true;
  if (format === 'Parallel') {
    each(categories, category => {
      const countCategorizedNotes = size(filter(notes, { categoryId: category.id }));
      isCategorizeCompleted = countCategorizedNotes >= ideasPerCategory && isCategorizeCompleted;
    });
  } else {
    const countCategorizedNotes = size(filter(notes, { categoryId: currentCategoryId }));
    isCategorizeCompleted = countCategorizedNotes >= ideasPerCategory;
  }

  useEffect(() => {
    // if participant added new note
    const oldParticipantNotes = notes;
    if (oldParticipantNotes.length !== participantNotes.length && isCreated) {
      setNotes(sortedParticipantNotes);
    }
    // if participant edited note
    if (editNoteId) {
      setNotes(sortedParticipantNotes);
      setEditNoteId();
    }
    const oldCategoryId = categoryId;
    if (currentCategoryId !== oldCategoryId) {
      setNotes(participantNotes);
      setCategoryId(currentCategoryId);
    }
  }, [currentCategoryId, sortedParticipantNotes, editNoteId, notes, isCreated, categoryId]);

  const onCreateNewNote = () => {
    setIsCreated(true);
  };

  const onEditNote = noteId => {
    setEditNoteId(noteId);
  };

  /**
   * Apply changes that has resulted from the drag.
   */
  const onDragEnd = result => {
    setIsCreated(false);
    const { source, destination, draggableId } = result;

    if (!destination) {
      return;
    }

    const startIndex = source.droppableId;
    const finishIndex = destination.droppableId;
    const finishCategoryId = sortedCategories[finishIndex] ? sortedCategories[finishIndex].id : '';
    const draggableNoteIndex = source.index;

    if (format === 'Serial' && isShowPrevCategory && previousCategoryId === finishCategoryId) {
      return;
    }

    // Moving in one category
    if (startIndex === finishIndex) {
      const draggableNote = notes[draggableNoteIndex];
      const changeNote = notes[destination.index];
      const newParticipantNotes = { ...notes };
      newParticipantNotes[destination.index] = draggableNote;
      newParticipantNotes[draggableNoteIndex] = changeNote;
      setNotes(newParticipantNotes);
      return;
    }

    // get number of notes for droppable category
    const countNotesForCategory = size(filter(notes, note => note.categoryId === finishCategoryId));

    // if the required number of notes exists in the category, then you cannot drag another note
    if (countNotesForCategory >= ideasPerCategory && finishCategoryId !== '') {
      return;
    }

    const finalIndex = findIndex(sortedParticipantNotes, note => note.noteId === draggableId);

    // Moving from one category to another
    const draggableNote = finishCategoryId
      ? { ...notes[finalIndex], categoryId: finishCategoryId }
      : { ...notes[finalIndex], categoryId: '' };
    const newParticipantNotes = { ...notes };

    newParticipantNotes[finalIndex] = draggableNote;
    setNotes(newParticipantNotes);

    dispatch(updateNote(draggableNote, draggableNote.noteId, id));
  };

  /**
   * Create all categories.
   */
  const renderCategories = () => {
    return format === 'Serial'
      ? map(sortedCategories, (category, idx) => {
          if (
            category.id === currentCategoryId ||
            (category.id === previousCategoryId && isShowPrevCategory)
          ) {
            const isPrevCategory = category.id === previousCategoryId && isShowPrevCategory;
            return (
              <Category
                key={`${category.value}-${idx}`}
                id={category.id}
                droppableId={`${idx}`}
                value={category.value}
                notes={isPrevCategory ? allNotes : notes}
                context="write"
                noteSize="large"
                isPrevCategory={isPrevCategory}
                className={isShowPrevCategory ? dropContainerClasses.previousCategory : ''}
              />
            );
          }
        })
      : map(sortedCategories, (category, idx) => (
          <Category
            key={`${category.value}-${idx}`}
            id={category.id}
            droppableId={`${idx}`}
            value={category.value}
            notes={notes}
            context="write"
            noteSize={sortedCategories.length > 1 ? 'small' : 'large'}
          />
        ));
  };

  /**
   * Renders all participant notes that not categorized.
   */
  const renderParticipantNotes = () =>
    map(notes, (note, idx) => {
      if (note.categoryId === '') {
        return (
          <GridItem
            className={dropContainerClasses.gridItem}
            key={`grid-item-${idx}`}
            xs={12}
            sm={6}
            md={3}
            lg={3}
            xl={3}
          >
            <NoteCard
              key={note.noteId}
              note={note}
              context="write"
              draggableId={`${idx}`}
              onEditNote={onEditNote}
            />
          </GridItem>
        );
      }
    });

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Box classes={classes}>{renderCategories()}</Box>
      <Droppable droppableId={`${sortedCategories.length}`} type="notes">
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className={dropContainerClasses.dropContainer}
          >
            <GridContainer className="m-0" direction="row" spacing={3}>
              <GridItem
                className={dropContainerClasses.gridItem}
                xs={12}
                sm={6}
                md={3}
                lg={3}
                xl={3}
              >
                <NewNote onCreateNewNote={onCreateNewNote} />
              </GridItem>
              {renderParticipantNotes()}
            </GridContainer>
            <div style={{ display: 'none' }}>{provided.placeholder}</div>
          </div>
        )}
      </Droppable>
      {isCategorizeCompleted ? (
        <>
          <CheckCircleIcon className={dropContainerClasses.successColor} />
          <Typography className={cx(dropContainerClasses.successColor, 'mb-2')} variant="body2">
            You’ve categorized the maximum number of notes.
          </Typography>
        </>
      ) : (
        <Typography className="mb-2" color="textSecondary" variant="body2">
          {`Create notes, then drag up to ${ideasPerCategory} notes to each category above.`}
        </Typography>
      )}
    </DragDropContext>
  );
};

export default NoteAndCategorizeParticipantWrite;
