import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useFirestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { grey } from '@material-ui/core/colors';
import cx from 'classnames';
import { delay } from 'lodash';

import { Typography, Box, IconButton } from '@material-ui/core';
import NextIcon from '@material-ui/icons/SkipNext';
import PreviousIcon from '@material-ui/icons/SkipPrevious';

import PageContent from 'components/PageContent/PageContent';
import PageContentHeader from 'components/PageContent/PageContentHeader';
import PageContentBody from 'components/PageContent/PageContentBody';
import AdvertFooter from 'components/AdvertFooter/AdvertFooter';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Spinner from 'components/Spinner';
import DelayedRender from 'components/DelayedRender';
import CrLogo from 'components/CrLogo/CrLogo';

import { updateLocalSlidesSessionSlide } from 'redux/actions/participantSessionActions';

import './index.css';

const useStyles = makeStyles({
  noSlides: {
    border: '2px dashed',
    backgroundColor: grey[100],
    textAlign: 'center',
    padding: '0 15px',
    width: '100%',
    borderRadius: 4,
    height: 0,
    paddingTop: '28.125%',
    paddingBottom: '28.125%',
  },
});

const Slides = () => {
  const classes = useStyles();
  let { slidesSessionId } = useParams();
  const [imgLoaded, setImgLoaded] = useState(false);
  const [slideHeight, setSlideHeight] = useState(null);
  const dispatch = useDispatch();
  const session = useSelector(state => state.firestore.data.session);
  // if the id isn't in the url, check the session object
  slidesSessionId = slidesSessionId || session?.slidesSessionIds?.[0];
  const slidesSession = useSelector(state => state.firestore.data.slidesSession);
  const localSlidesSession = useSelector(
    state => state.participantSession.slidesSessions[slidesSessionId],
  );
  const slidesLoaded = isLoaded(slidesSession);
  const slidesEmpty = isEmpty(slidesSession);

  const currentSlide = slidesSession?.participantsControlSlides
    ? localSlidesSession?.currentSlide
    : slidesSession?.currentSlide;

  const participantsControlSlides = slidesSession?.participantsControlSlides;

  useFirestoreConnect(() => [
    {
      collection: 'remoteSlidesSessions',
      doc: `${slidesSessionId}`,
      storeAs: 'slidesSession',
    },
  ]);

  // reset imgLoaded when the slide changes
  useEffect(() => {
    setImgLoaded(false);
  }, [currentSlide]);

  const setSlideContainerHeight = () => {
    const height = document.getElementById('slide-img').offsetHeight;
    setSlideHeight(height);
  };

  /**
   * Listen to resize events to dynamically set the slide container
   * height
   */
  useEffect(() => {
    if (!slidesLoaded || (slidesLoaded && slidesEmpty)) return;

    let resizeTimer;

    const handleResize = () => {
      clearTimeout(resizeTimer);

      resizeTimer = setTimeout(() => {
        const height = document.getElementById('slide-img').offsetHeight;
        setSlideHeight(height);
      }, 250);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [slidesLoaded, slidesEmpty]);

  useEffect(() => {
    if (participantsControlSlides && currentSlide === undefined) {
      dispatch(updateLocalSlidesSessionSlide(slidesSessionId, slidesSession.currentSlide));
    }
  }, [participantsControlSlides, currentSlide]);

  const onNext = () => {
    dispatch(updateLocalSlidesSessionSlide(slidesSessionId, currentSlide + 1));
  };

  const onPrevious = () => {
    dispatch(updateLocalSlidesSessionSlide(slidesSessionId, currentSlide - 1));
  };

  const onImageLoaded = e => {
    if (!slideHeight) {
      delay(setSlideContainerHeight, 50);
    }

    setImgLoaded(true);
  };

  return (
    <GridContainer
      direction="row"
      justify="center"
      alignItems="center"
      className="session-feedback"
    >
      <GridItem xs={12} sm={12} md={12} lg={12} xl={8}>
        <PageContent>
          <PageContentHeader className="with-logo">
            <div className="left-col">
              <CrLogo width={100} className="mt-1" withLink session={session} />
            </div>
            <Typography
              variant="h5"
              style={{ fontWeight: 'bold', textTransform: 'uppercase' }}
              className="page-content-title"
            >
              {session.topic}: Slides
            </Typography>
          </PageContentHeader>
          <PageContentBody contentCenter>
            <Box className="w-100 d-flex justify-content-center align-items-start mb-2">
              {(slidesLoaded && slidesEmpty) || slidesSessionId === 'undefined' ? (
                <div
                  className={cx(
                    classes.noSlides,
                    'd-flex',
                    'justify-content-center',
                    'align-items-center',
                  )}
                >
                  <p style={{ fontSize: 20 }}>No slides added yet</p>
                </div>
              ) : (
                <>
                  {slidesSession?.imageUrls && (
                    <div className="w-100" id="slideContainer" style={{ minHeight: slideHeight }}>
                      {!imgLoaded && (
                        <DelayedRender delay={250}>
                          <div
                            className="w-100 h-100 d-flex justify-content-center align-items-center"
                            style={{ minHeight: slideHeight }}
                          >
                            <Spinner />
                          </div>
                        </DelayedRender>
                      )}
                      <img
                        src={slidesSession.imageUrls[currentSlide]}
                        onLoad={onImageLoaded}
                        alt="slide preview"
                        id="slide-img"
                        className={cx('watch-demo-slide border w-100', {
                          'd-none': !imgLoaded,
                        })}
                      />
                    </div>
                  )}
                </>
              )}
            </Box>
            {participantsControlSlides ? (
              <Box className="d-flex justify-content-between">
                <div>
                  <IconButton onClick={onPrevious} disabled={currentSlide < 1}>
                    <PreviousIcon />
                  </IconButton>
                </div>
                <div className="mx-2 d-flex align-items-center">
                  <Typography>
                    {currentSlide + 1} / {slidesSession?.imageUrls.length}
                  </Typography>
                </div>
                <div className="d-flex align-items-center">
                  {currentSlide < slidesSession.imageUrls.length - 1 && (
                    <IconButton onClick={onNext}>
                      <NextIcon />
                    </IconButton>
                  )}
                </div>
              </Box>
            ) : (
              <Typography className="font-italic" color="textSecondary">
                Facilitator is currently controlling Slides
              </Typography>
            )}
            <Box className="my-8">
              <AdvertFooter />
            </Box>
          </PageContentBody>
        </PageContent>
      </GridItem>
    </GridContainer>
  );
};

export default Slides;
