import firebase from 'firebase/app';
import 'firebase/storage';

/**
 * Uploads a file to Firebase storage at the specified path with optional metadata.
 *
 * @param {File} file - The file to be uploaded.
 * @param {string} storagePath - The path in Firebase storage where the file will be stored.
 * @param {Object} [metadata={}] - Optional metadata for the file being uploaded.
 * @returns {Promise<firebase.storage.Reference>} A promise that resolves with the storage reference of the uploaded file.
 * @throws {Error} If no file is provided or if there is an error during the upload.
 */
export const uploadFile = async (file, storagePath, customMetadata = {}) => {
  if (!file) {
    throw new Error('No file provided');
  }
  const metadata = { customMetadata };
  const storageRef = firebase.storage().ref(`${storagePath}/${file.name}`);
  await storageRef.put(file, metadata);
  return storageRef;
};
