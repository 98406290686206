import React from 'react';
import { useSelector } from 'react-redux';
import { has, map, sortBy, filter } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { DragDropContext } from 'react-beautiful-dnd';
// library components
import { Box } from '@material-ui/core';
// cr components
import Category from './Category';
// selectors
import {
  getParticipantNotes,
  useNoteAndCategorizeSelector,
} from 'redux/selectors/noteAndCategorizeSelectors';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    marginBottom: theme.spacing(2),
  },
}));

/**
 * Note & Categorize view for the 'roundRobin' phase on the Participant side
 */
const NoteAndCategorizeParticipantRoundRobin = () => {
  const classes = useStyles();
  const {
    config: { categories, roundRobinShare, format },
    participantData: { participantRoundRobin },
    state: { serialRoundRobin },
  } = useNoteAndCategorizeSelector();
  const participantNotes = useSelector(
    getParticipantNotes(participantRoundRobin ? participantRoundRobin.id : ''),
  );

  let sortField = 'value';
  const sortedCategories = sortBy(
    map(categories, category => {
      sortField = has(category, 'order') ? 'order' : 'value';
      return category;
    }),
    sortField,
  );

  const currentCategories =
    roundRobinShare === 'on' && format === 'Serial'
      ? filter(sortedCategories, category => category.id === serialRoundRobin)
      : sortedCategories;

  /**
   * Create all categories.
   */
  const renderCategories = () => {
    return map(currentCategories, (category, idx) => (
      <Category
        key={`${category.value}-${idx}`}
        id={category.id}
        droppableId={`${idx}`}
        value={category.value}
        notes={participantNotes}
        context="roundRobin"
        noteSize="small"
      />
    ));
  };

  return (
    <DragDropContext>
      <Box classes={classes}>{renderCategories()}</Box>
    </DragDropContext>
  );
};

export default NoteAndCategorizeParticipantRoundRobin;
