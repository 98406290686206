import React from 'react';
// cr components
import CustomSelect from './CustomSelect';
// selectors
import { useFacilitatorSessionSelector } from 'redux/selectors/facilitatorSessionSelectors';

const MuralWorkspaceSelect = ({ field, disabled, ...rest }) => {
  const { muralWorkspaceOptions } = useFacilitatorSessionSelector();
  console.log('muralWorkspaceOptions', muralWorkspaceOptions);
  console.log('rest', { ...rest });
  console.log('field', { ...field });

  return (
    <CustomSelect
      {...{
        ...field,
        selectOptions: muralWorkspaceOptions,
        helperText: 'Mural Workspaces can be refreshed in your account settings',
      }}
      disabled={disabled}
      {...rest}
    />
  );
};

export default MuralWorkspaceSelect;
