import React from 'react';
import { map, slice } from 'lodash';
import { makeStyles } from '@material-ui/styles';
import cx from 'classnames';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// library components
import { Box } from '@material-ui/core';
// cr components
import Moment from 'components/Moment';

const useStyles = makeStyles({
  smallJourney: {
    flexDirection: 'column',
  },
  moment: {
    width: '100%',
    height: '100%',
    minHeight: 65,
    flex: 0,
  },
});

/**
 * User Journey Moments view
 */
const Journey = ({
  handleCreateJourneyField,
  handleChangeJourneyField,
  handleSelectJourneyField,
  participantJourney,
  activeView,
  keyMoment,
}) => {
  const classes = useStyles();
  const desktopScreen = useMediaQuery('(min-width: 960px)');
  const tabletScreen = useMediaQuery('(min-width: 480px)');
  const smallScreen = useMediaQuery('(max-width: 480px)');
  const moments = Array.from({ length: 4 }, (_, i) => `Moment ${i + 1}`);

  const renderActor = () => (
    <Moment
      title="Actor"
      placeholder="Add Role"
      value={
        activeView === 'create' ? participantJourney?.actor : participantJourney?.values?.actor
      }
      type="actor"
      handleCreateJourneyField={handleCreateJourneyField}
      handleChangeJourneyField={handleChangeJourneyField}
      handleSelectJourneyField={handleSelectJourneyField}
      disabled={false}
      context={activeView}
      isSmallScreen={smallScreen}
      className={cx(smallScreen && classes.moment)}
    />
  );

  const renderOutcome = () => (
    <Moment
      title="Outcome"
      placeholder="Add Ending"
      value={activeView === 'create' ? participantJourney?.end : participantJourney?.values?.end}
      type="end"
      handleCreateJourneyField={handleCreateJourneyField}
      handleChangeJourneyField={handleChangeJourneyField}
      handleSelectJourneyField={handleSelectJourneyField}
      disabled={false}
      context={activeView}
      isSmallScreen={smallScreen}
      className={cx(smallScreen && classes.moment)}
    />
  );

  const renderMoment = (moment, idx) => {
    const momentType = `moment-${idx + 1}`;
    return (
      <Moment
        key={`moment-${idx}`}
        title={moment}
        value={
          activeView === 'create'
            ? participantJourney?.[`moment-${idx + 1}`]
            : participantJourney?.values?.[momentType]
        }
        type={`moment-${idx + 1}`}
        handleCreateJourneyField={handleCreateJourneyField}
        handleChangeJourneyField={handleChangeJourneyField}
        handleSelectJourneyField={handleSelectJourneyField}
        disabled={false}
        context={activeView}
        isSelected={keyMoment === momentType}
        isSmallScreen={smallScreen}
        className={cx(smallScreen && classes.moment)}
      />
    );
  };

  return tabletScreen && !desktopScreen ? (
    <Box className="mb-5">
      <Box display="flex" alignItems="center" justifyContent="start">
        {renderActor()}
        {map(slice(moments, 0, 2), (moment, idx) => renderMoment(moment, idx))}
      </Box>
      <Box display="flex" alignItems="center" justifyContent="start">
        {map(slice(moments, 2), (moment, idx) => renderMoment(moment, idx + 2))}
        {renderOutcome()}
      </Box>
    </Box>
  ) : (
    <Box
      className={cx(smallScreen && classes.smallJourney, 'w-100 mb-5')}
      display="flex"
      alignItems="center"
      justifyContent="start"
    >
      {renderActor()}
      {map(moments, (moment, idx) => renderMoment(moment, idx))}
      {renderOutcome()}
    </Box>
  );
};

export default Journey;
