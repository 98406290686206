import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useFirestore } from 'react-redux-firebase';
import { useRouteMatch } from 'react-router-dom';
import { concat } from 'lodash';
import mixpanel from 'mixpanel-browser';
import { sessionRoutes } from 'routes';
import cx from 'classnames';
// library components
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Tooltip, MenuItem, FormControl, Select, Input } from '@material-ui/core';
// constants
import { ACTIVITIES } from 'constants/activities';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: ({ mediumScreen, tabletScreen }) =>
      mediumScreen && !tabletScreen ? '23vw' : tabletScreen ? '100%' : '30vw',
    maxWidth: 300,
    minWidth: 160,
  },
  formSmallScreen: {
    width: `300px !important`,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const useSelectStyles = makeStyles({
  select: {
    paddingLeft: 12,
    paddingRight: 16,
    fontWeight: 400,
    color: 'rgba(0, 0, 0, 0.6)',
  },
});

const useInputStyles = makeStyles({
  root: {
    background: 'rgba(0, 0, 0, 0.04)',
    width: '100%',
  },
});

export default function ParticipantViewSelect({ session }) {
  const [selectedRoute, setSelectedRoute] = useState(session.activeRoute);
  const smallScreen = useMediaQuery('(max-width: 760px)');
  const tabletScreen = useMediaQuery('(max-width: 959px)');
  const mediumScreen = useMediaQuery('(max-width: 1100px)');
  const classes = useStyles({ mediumScreen, tabletScreen });
  const selectClasses = useSelectStyles();
  const db = useFirestore();
  const route = useRouteMatch();

  // update the dropdown if the activeRoute changes in redux
  useEffect(() => {
    setSelectedRoute(session.activeRoute);
  }, [session.activeRoute]);

  const renderOptions = () => {
    const avilableRoutes = [
      {
        name: 'Welcome',
        value: 'welcome',
        tooltip: ACTIVITIES.welcome.tooltip,
      },
      {
        name: 'Slides',
        value: `slides`,
        tooltip: ACTIVITIES.slides.tooltip,
      },
    ];

    const feedbackRoute = [
      {
        name: 'Feedback',
        value: `feedback`,
        tooltip: ACTIVITIES.feedback.tooltip,
      },
    ];

    if (session.activities?.length) {
      session.activities.forEach(activity => {
        avilableRoutes.push({
          name: activity.name,
          value: activity.route,
          tooltip: ACTIVITIES[activity.activity]?.tooltip,
        });
      });
    }

    const allRoutes = concat(avilableRoutes, feedbackRoute);

    return allRoutes.map((option, idx) => (
      <MenuItem value={option.value} key={idx}>
        {option.tooltip ? (
          <Tooltip
            title={option.tooltip}
            arrow
            placement="left"
            enterDelay={500}
            enterNextDelay={500}
          >
            <span className="w-100">{option.name}</span>
          </Tooltip>
        ) : (
          option.name
        )}
      </MenuItem>
    ));
  };

  const handleChange = (e, child) => {
    setSelectedRoute(e.target.value);
    db.collection('sessions')
      .doc(`${route.params.sessionId}`)
      .update({
        activeRoute: e.target.value,
      })
      .then(() => {
        if (process.env.REACT_APP_HOSTING_ENV === 'prod') {
          let activityName = child?.props?.children.props?.children?.props?.children;

          if (activityName) {
            activityName = activityName.includes('25:10') ? '25:10' : activityName.split(':')[0];
          }

          mixpanel.track('Set Activity', { activity: activityName });
        }
      });
  };

  return (
    <FormControl
      variant="outlined"
      className={cx(classes.formControl, smallScreen && classes.formSmallScreen)}
    >
      <span style={{ fontWeight: '500', paddingBottom: 8 }}>Participant View:</span>
      <Select
        id="participant-view-select"
        value={selectedRoute}
        onChange={handleChange}
        label="Participant View"
        classes={selectClasses}
        input={<Input disableUnderline classes={useInputStyles()} />}
      >
        {renderOptions()}
      </Select>
    </FormControl>
  );
}
