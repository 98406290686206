import React from 'react';
import { FormControl, InputLabel, Input, makeStyles } from '@material-ui/core';

// styles
const useStyles = makeStyles(theme => ({
  input: {
    backgroundColor: '#EEEEEE',
    paddingLeft: 10,
  },
  inputLabel: {
    paddingLeft: 10,
    zIndex: 2,
  },
}));

const StaffingInputFilter = ({ onChange, label, name, type, inputProps }) => {
  const classes = useStyles();

  return (
    <FormControl style={{ margin: 8, minWidth: 120, width: '100%' }}>
      <InputLabel htmlFor={name} className={classes.inputLabel} classes={{ shrink: 'pl-0' }}>
        {label}
      </InputLabel>
      <Input
        id={name}
        classes={{
          root: classes.input,
        }}
        type={type}
        onChange={onChange}
        inputProps={inputProps}
      />
    </FormControl>
  );
};

export default StaffingInputFilter;
