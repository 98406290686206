import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isEmpty, isLoaded, useFirestoreConnect } from 'react-redux-firebase';

import AdminActivityTemplate from 'components/AdminActivityTemplate';
import GiveawayAdminWrite from 'components/Giveaway/Admin/GiveawayAdminWrite';
import GiveawayAdminPickWinner from 'components/Giveaway/Admin/GiveawayAdminPickWinner';
import GiveawayAdminResults from 'components/Giveaway/Admin/GiveawayAdminResults';

import { useGiveawaySelector } from 'redux/selectors/giveawaySelectors';

import { GIVEAWAY_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';

const views = {
  write: GiveawayAdminWrite,
  pickWinner: GiveawayAdminPickWinner,
  results: GiveawayAdminResults,
};

const AdminGiveaway = () => {
  const routeParams = useParams();
  const giveaway = useGiveawaySelector();
  const session = useSelector(state => state.firestore.data.session);
  const meetingParticipants = useSelector(state => state.firestore.data.participants);

  // hooks up a firestore listener to Redux
  useFirestoreConnect(() => [
    {
      collection: 'giveaway',
      doc: `${routeParams.activityId}`,
      storeAs: 'giveaway',
    },
  ]);

  // ensure all of the data we need is loaded
  if (
    !isLoaded(session) ||
    isEmpty(session) ||
    !isLoaded(giveaway) ||
    isEmpty(giveaway) ||
    !isLoaded(meetingParticipants) ||
    routeParams.activityId !== giveaway?.id
  ) {
    return null;
  }

  const CurrentView = views[giveaway.state.activeView];

  return (
    <AdminActivityTemplate
      activityConfig={CONFIG}
      activityData={giveaway}
      session={session}
      CurrentView={CurrentView}
    />
  );
};

export default AdminGiveaway;
