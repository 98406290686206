import React from 'react';
import PropTypes from 'prop-types';
// library components
import { Select, Input, FormControl, MenuItem, makeStyles } from '@material-ui/core';
import { map } from 'lodash';

const useStyles = makeStyles({
  label: {
    fontSize: 12,
    fontWeight: 300,
    lineHeight: '30px',
    color: '#5E5E5E',
  },
  formControl: {
    width: '100%',
    marginBottom: 10,
  },
  select: {
    paddingLeft: 12,
    paddingRight: 16,
    fontSize: 14,
    fontWeight: 400,
    color: '#5C5C5C',
  },
  input: {
    background: '#EEEEEE',
    width: '100%',
    height: 40,
  },
});

const SelectDeck = ({ title, placeholder, selectedValue, handleChange, options }) => {
  const classes = useStyles();

  // renders options for select
  const renderOptions = () =>
    map(options, option => (
      <MenuItem key={`option-${option.id}`} value={option.id}>
        {option.name}
      </MenuItem>
    ));

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <span className={classes.label}>{title}</span>
      <Select
        id="select-recap-deck-notes"
        value={selectedValue || ''}
        displayEmpty
        onChange={handleChange}
        classes={{
          root: classes.select,
        }}
        input={
          <Input
            disableUnderline
            classes={{
              root: classes.input,
            }}
          />
        }
      >
        {placeholder && (
          <MenuItem value="" disabled>
            <em>{placeholder}</em>
          </MenuItem>
        )}
        {renderOptions()}
      </Select>
    </FormControl>
  );
};

SelectDeck.propTypes = {
  title: PropTypes.string, // select label
  placeholder: PropTypes.string, // select placeholder
  selectedValue: PropTypes.string, // selected value id
  handleChange: PropTypes.func, //on change select
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string, // value id
      name: PropTypes.string, // value name
    }),
  ),
};

export default SelectDeck;
