import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
// library components
import { Box, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
// cr components
import SurveyParticipantForm from './SurveyParticipantForm';
// selectors
import { getParticipantAnsweredAllQuestions } from 'redux/selectors/surveySelectors';

const useStyles = makeStyles(theme => ({
  successColorText: {
    color: theme.palette.success.main,
  },
  successText: {
    fontSize: 14,
  },
  unfinishedText: {
    fontSize: 14,
    textAlign: 'center',
  },
}));

/**
 * Survey view for the 'write' phase on the Participant side
 */
const SurveyParticipantWrite = ({ context }) => {
  const classes = useStyles();
  const participantId = useSelector(state => state.firebase.auth.uid);
  const allAnswered = useSelector(getParticipantAnsweredAllQuestions(participantId));

  return (
    <>
      <SurveyParticipantForm context={context} />
      <Box className="mb-8">
        {allAnswered ? (
          <Box className="d-flex flex-column justify-content-center align-items-center mt-2">
            <CheckCircleIcon className={cx('mb-1', classes.successColorText, classes.checkIcon)} />
            <Typography className={cx(classes.successText, classes.successColorText)}>
              You're Done
            </Typography>
          </Box>
        ) : (
          <Typography className={classes.unfinishedText}>
            {context === 'results'
              ? 'The survey has ended'
              : 'Answer the questions to complete the survey'}
          </Typography>
        )}
      </Box>
    </>
  );
};

export default SurveyParticipantWrite;
