import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { isEmpty, map } from 'lodash';

import Button from 'components/CustomButtons/Button.js';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import PageContent from 'components/PageContent/PageContent';
import PageContentBody from 'components/PageContent/PageContentBody';
import PageContentHeader from 'components/PageContent/PageContentHeader';
import DealCard from 'components/Staffing/Deals/DealCard';
import DealsFilter from 'components/Staffing/Deals/DealsFilter';
import AddDealDialog from 'components/Dialogs/Staffing/AddDealDialog';
import AddProjectToDealDialog from 'components/Dialogs/Staffing/AddProjectToDealDialog';
import DealInsightsDialog from 'components/Dialogs/Staffing/DealInsightsDialog';

import { openCreateDealDialog } from 'redux/actions/staffingActions';
import { selectDeals } from 'redux/selectors/staffing/getDeals';
import { useSelector } from 'react-redux';

const useStyles = makeStyles({
  pageHeader: {
    // paddingBottom: "0px !important",
  },
  gridItem: {
    padding: '0 10px !important',
  },
  createDealButton: {
    height: 36,
  },
  tab: {
    minWidth: 80,
    fontSize: 12,
    boxShadow: 'inset 0px -2px 0px #666666',
    marginBlock: 3,
  },
});

const DealsList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const getAllDeals = selectDeals();
  const { deals, isLoaded } = useSelector(state => getAllDeals(state));

  const openCreateDeal = () => dispatch(openCreateDealDialog(true));

  if (!isLoaded) {
    return null;
  }

  return (
    <div>
      <GridContainer direction="row" justify="center" alignItems="center">
        <GridItem xs={12}>
          <PageContent>
            <PageContentHeader className={classes.pageHeader}>
              <Typography variant="h5" className="page-content-title">
                Deals
              </Typography>
              <div className="right-col">
                <Button
                  color="primary"
                  onClick={openCreateDeal}
                  size="sm"
                  className={classes.createDealButton}
                >
                  New Deal
                </Button>
              </div>
            </PageContentHeader>
            <PageContentBody>
              <DealsFilter />
              <GridContainer className="w-100 m-0">
                {isEmpty(deals) ? (
                  <Typography style={{ fontSize: 18 }} className="w-100 text-center ml-2 mt-4">
                    No deals yet
                  </Typography>
                ) : null}

                {map(deals, (deal, idx) => {
                  return (
                    <GridItem
                      xs={12}
                      sm={4}
                      md={4}
                      lg={4}
                      key={`deal-card-${idx}`}
                      style={{ marginBottom: 30 }}
                      className={classes.gridItem}
                    >
                      <DealCard id={deal.id} />
                    </GridItem>
                  );
                })}
              </GridContainer>
            </PageContentBody>
          </PageContent>
        </GridItem>
      </GridContainer>

      <AddDealDialog />
      <AddProjectToDealDialog />
      <DealInsightsDialog />
    </div>
  );
};

export default DealsList;
