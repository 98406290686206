import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/functions';
import { reject, find } from 'lodash';
import { store } from '../index.js';
import {
  setSelectedFacilitatorRoute,
  showAppOverlaySpinner,
} from 'redux/actions/facilitatorSessionActions';
import { ACTIVITIES } from '../constants/activities';
import * as Sentry from '@sentry/react';

/**
 * Delete and activiy from a session and remove the activity document
 *
 * @param string    sessionId         id of session to delete activity from
 * @param string    activityType      name of activity collection in firestore
 * @param string    activityId        id of the activity document
 * @param object    history           history object from react-router-dom
 * @param function  enqueueSnackbar   function from Notistack for enqueueing notifications
 * @param string    newRoute          route to change participant to if the session's current activeRoute belongs
 *                                    to the activity being deleted
 */
export async function deleteActivity(
  sessionId,
  activityType,
  activityId,
  history,
  enqueueSnackbar,
  newRoute = 'slides',
) {
  const db = firebase.firestore();
  const functions = firebase.functions();
  const sessionRef = db.doc(`/sessions/${sessionId}`);
  const activityCollection = ACTIVITIES[activityType]?.collection
    ? ACTIVITIES[activityType].collection
    : activityType;
  const activityRef = db.doc(`/${activityCollection}/${activityId}`);

  store.dispatch(showAppOverlaySpinner(true));

  /**
   * Note: When deleting, the storage must be cleared first because the 'activities-clearStorage'
   * cloud function relies on checking the firestore document
   */
  await functions
    .httpsCallable('activities-clearStorage')({ activityType, activityId })
    .catch(err => {
      Sentry.captureException(err);
      console.error('activities-clearStorage error', err);
    });

  return db
    .runTransaction(function (transaction) {
      return transaction.get(sessionRef).then(function (sessionDoc) {
        if (!sessionDoc.exists) {
          throw 'Document does not exist!';
        }

        const session = sessionDoc.data();
        const currentActiveRoute = find(session.activities, { id: activityId }).route;
        const activities = reject(session.activities, { id: activityId });

        const update = {
          activities,
          activeFacilitatorRoute: newRoute,
        };

        if (session.activeRoute === currentActiveRoute) {
          update.activeRoute = newRoute;
        }

        transaction.set(sessionRef, update, { merge: true });
        transaction.delete(activityRef);
      });
    })
    .then(() => {
      store.dispatch(showAppOverlaySpinner(false));
      history.push(`/admin/sessions/${sessionId}/${newRoute}`);
      store.dispatch(setSelectedFacilitatorRoute(newRoute, sessionId));
    })
    .catch(function (error) {
      store.dispatch(showAppOverlaySpinner(false));
      enqueueSnackbar('error deleting activity', { variant: 'error' });
      console.log('Transaction failed: ', error);
    });
}
