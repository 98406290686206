import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import ButtonSpinner from '../../../components/ButtonSpinner/ButtonSpinner';
import TextInput from '../../../components/CustomFormComponents/TextInput';
import TextArea from '../../../components/CustomFormComponents/TextArea';
import { PRIVACY_POLICY_LINK } from 'constants/general';

import './index.css';

const SignUpForm = ({ loading, onSubmit, loginButtonLabel, helperText }) => {
  return (
    <Formik
      initialValues={{ email: '' }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('Please enter your name'),
        company: Yup.string().required('Please enter your company'),
        email: Yup.string().email('Please enter a valid email').required(),
      })}
      onSubmit={onSubmit}
    >
      <Form>
        {helperText && (
          <div style={{ marginBottom: 10 }}>
            <Typography variant="body1">{helperText}</Typography>
          </div>
        )}
        <div className="signup-txtInput">
          <TextInput name="name" placeholder="Name" fullWidth />
        </div>
        <div className="signup-txtInput">
          <TextInput name="email" placeholder="Email (used for login)" fullWidth />
        </div>
        <div className="signup-txtInput">
          <TextInput name="company" placeholder="Company" fullWidth />
        </div>
        <div className="signup-txtInput">
          <TextArea
            name="engage"
            placeholder="What are some of your favorite ways to engage participants?"
            fullWidth
          />
        </div>
        <div>
          <Button variant="contained" color="primary" type="submit" disabled={loading} fullWidth>
            {loginButtonLabel}
            {loading && <ButtonSpinner />}
          </Button>
        </div>
        <div className="login-disclaimer">
          <p className="disclaimerText">
            By Signing In you agree to our{' '}
            <a href={PRIVACY_POLICY_LINK} target="_blank">
              Privacy Policy
            </a>
          </p>
        </div>
      </Form>
    </Formik>
  );
};

SignUpForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loginButtonLabel: PropTypes.string,
  helperText: PropTypes.string,
};

SignUpForm.defaultProps = {
  loginButtonLabel: 'Log In',
};

export default SignUpForm;
