import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { truncate } from 'lodash';
// library components
import { makeStyles, Box, Typography, IconButton } from '@material-ui/core';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import { Draggable } from 'react-beautiful-dnd';
// cr components
import ActivitySettingsForm from 'components/Forms/ActivitySettingsForm';
import FormDialog from 'components/Dialogs/FormDialog';
// utils
import { getConfig, getActivityData } from 'utils/getActivityConfig';

const useStyles = makeStyles(theme => ({
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 40,
    marginBottom: 4,
    borderRadius: 5,
    background: '#EEEEEE',
    color: '#898989',
    fontSize: 16,
  },
  activityName: {
    marginLeft: theme.spacing(1),
    color: '#000000',
    fontSize: 15,
  },
  iconButton: {
    padding: 5,
  },
  close: {
    marginLeft: 'auto',
  },
  drag: {
    cursor: 'grab',
  },
}));

/**
 * An activity to the current session
 */
const ActivityItem = ({ activityName, activityId, activityType, idx, onDeleteActivity }) => {
  const classes = useStyles();
  const maxNameLength = 60;
  const config = getConfig(activityType);

  const [settingsDialogOpen, setSettingsDialogOpen] = useState(false);
  const [activityData, setActivityData] = useState({});

  let name = config && activityData ? `${config.niceName}: ${activityData.name}` : activityName;

  // truncate activity name
  name = truncate(name, {
    length: maxNameLength,
    separator: ' ',
  });

  // get activity data for settings
  const getData = useCallback(async () => {
    if (config) {
      const data = await getActivityData(config.collection, activityId);
      setActivityData(data);
    }
  }, [activityId, config]);

  useEffect(() => {
    getData();
  }, [getData, activityId, settingsDialogOpen]);

  // function open/close settings for activity
  const toggleSettingsDialog = () => {
    setSettingsDialogOpen(!settingsDialogOpen);
  };

  return (
    <>
      <Draggable draggableId={activityId} index={idx}>
        {(provided, snapshot) => (
          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            <Box className={classes.itemContainer}>
              <IconButton className={cx(classes.iconButton, classes.drag)}>
                <DragIndicatorIcon />
              </IconButton>
              {config && (
                <IconButton className={classes.iconButton} onClick={toggleSettingsDialog}>
                  <EditIcon />
                </IconButton>
              )}
              <Typography className={classes.activityName}>{name}</Typography>
              <IconButton
                className={cx(classes.iconButton, classes.close)}
                onClick={() => onDeleteActivity(activityId, config?.collection || activityType)}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </div>
        )}
      </Draggable>
      {config && (
        <FormDialog
          handleClose={toggleSettingsDialog}
          isOpen={settingsDialogOpen}
          title={`${config.niceName} Settings`}
        >
          <ActivitySettingsForm
            activityConfig={config}
            activityData={activityData}
            closeDialog={toggleSettingsDialog}
          />
        </FormDialog>
      )}
    </>
  );
};

ActivityItem.propTypes = {
  activityName: PropTypes.string.isRequired, // activity name
  activityId: PropTypes.string.isRequired, // activity id
  activityType: PropTypes.string.isRequired, // activity type (noteAndCategorize, giveaway, liveScribing...)
  idx: PropTypes.number.isRequired, // activity index in array
  onDeleteActivity: PropTypes.func.isRequired, // on delete activity from session
};

export default ActivityItem;
