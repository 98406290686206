import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { map } from 'lodash';
import cx from 'classnames';
// library components
import { Typography, Card, CardContent } from '@material-ui/core';
// cr components
import ShortAnswerQuestionResultsSummary from '../Shared/QuestionResults/ShortAnswerQuestionResultsSummary';
import SingleChoiceQuestionResultsSummary from '../Shared/QuestionResults/SingleChoiceQuestionResultsSummary';
import MultipleChoiceQuestionResultsSummary from '../Shared/QuestionResults/MultipleChoiceQuestionResultsSummary';
import LinearScaleQuestionResultsSummary from '../Shared/QuestionResults/LinearScaleQuestionResultsSummary';
import SentimentQuestionResultsSummary from '../Shared/QuestionResults/SentimentQuestionResultsSummary';
// selectors
import { useAssessmentSelector, getSortedQuestions } from 'redux/selectors/assessmentSelectors';
// constants
import { VARIABLE_QUESTION_TYPES } from 'components/CustomFormComponents/VariableQuestions/QuestionTypes/constants';
// styles
import { useAssessmentStyles } from '../Shared/useAssessmentStyles';

const AssessmentFinalResults = () => {
  const commonClasses = useAssessmentStyles();
  const location = useLocation();
  const {
    config: { questions },
  } = useAssessmentSelector();
  const sortedQuestions = useSelector(getSortedQuestions());

  const renderQuestionResult = questionId => {
    switch (questions[questionId].data.questionType) {
      case VARIABLE_QUESTION_TYPES.SHORT_ANSWER:
        return <ShortAnswerQuestionResultsSummary questionId={questionId} fontSizes={[12, 24]} />;
      case VARIABLE_QUESTION_TYPES.SINGLE_CHOICE:
        return <SingleChoiceQuestionResultsSummary questionId={questionId} />;
      case VARIABLE_QUESTION_TYPES.MULTIPLE_CHOICE:
        return <MultipleChoiceQuestionResultsSummary questionId={questionId} />;
      case VARIABLE_QUESTION_TYPES.LINEAR_SCALE:
        return <LinearScaleQuestionResultsSummary questionId={questionId} />;
      case VARIABLE_QUESTION_TYPES.SENTIMENT:
        return <SentimentQuestionResultsSummary questionId={questionId} />;
      default:
        return null;
    }
  };

  const renderResultCard = (question, questionNumber) => {
    return (
      <Link to={`${location.pathname}/q/${question.id}`} className="d-flex flex-column flex-1">
        <Card
          className={cx('flex-1 d-flex flex-column', commonClasses.card)}
          style={{ minHeight: 265 }}
        >
          <CardContent className="d-flex flex-column flex-1">
            <Typography className={cx('fw-300 mb-3')} style={{ fontSize: 18 }}>
              {questionNumber}. {question.data.question}
            </Typography>
            <div className="flex-1 d-flex flex-column justify-content-center">
              {renderQuestionResult(question.id)}
            </div>
          </CardContent>
        </Card>
      </Link>
    );
  };

  return (
    <div className="container-fluid mx-0 px-0">
      <div className="row">
        {map(sortedQuestions, (q, idx) => (
          <div className="col-12 col-md-6 mb-3 d-flex flex-column" key={`q-result-${idx}`}>
            {renderResultCard(q, idx + 1)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AssessmentFinalResults;
