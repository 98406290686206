import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { map } from 'lodash';
// library components
import { Box } from '@material-ui/core';
// cr components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import NoteCard from 'components/NoteAndVote/Participant/NoteCard';
import NotesReview from 'components/NoteAndVote/Participant/NotesReview';
import AdminActivityAuxView from 'components/AdminActivityAuxView';
import CSVReportDownloadButton from 'components/CSVReportDownloadButton';
// selectors
import { getSubmittedNotes, useNoteAndVoteSelector } from 'redux/selectors/noteAndVoteSelectors';
// utils
import { createNoteAndVoteReportHeader, createNoteAndVoteReportData } from 'utils/noteAndVote';

const useStyles = makeStyles(theme => ({
  title: {
    paddingBottom: theme.spacing(3),
    fontWeight: 300,
    fontSize: 16,
    opacity: 0.9,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    padding: 30,
    margin: theme.spacing(2),
    marginBottom: 0,
  },
  grid: {
    width: 'calc(100% + 20px)',
    marginBottom: 0,
  },
  noteContainer: {
    padding: '0 10px 20px !important',
  },
}));

/**
 * Renders notes for the 'review' and 'results' phases on the Facilitator side
 */
const NoteAndVoteReview = () => {
  const classes = useStyles();
  const noteAndVote = useNoteAndVoteSelector();
  const sortedParticipantNotes = useSelector(getSubmittedNotes());
  const { participantData, output, state, id, name } = noteAndVote;

  const session = useSelector(state => state.firestore.data.session);

  if (!state?.activeView || (state.activeView !== 'results' && state.activeView !== 'review')) {
    return null;
  }

  // render headers for csv report
  const headers = createNoteAndVoteReportHeader();

  // render data for csv report
  const csvReport = createNoteAndVoteReportData(sortedParticipantNotes);

  const renderReviewNotes = () => <NotesReview notes={sortedParticipantNotes} noteAndVoteId={id} />;

  const renderResults = () =>
    map(output, (note, idx) => (
      <GridItem
        key={`results-${idx}`}
        className={classes.noteContainer}
        xs={12}
        sm={6}
        md={3}
        lg={3}
        xl={3}
      >
        <NoteCard note={note} context={state.activeView} />
      </GridItem>
    ));

  const renderPrintResults = () =>
    map(output, (note, idx) => (
      <GridItem key={`print-results-${idx}`} className="p-0" xs={4}>
        <NoteCard note={note} context={state.activeView} />
      </GridItem>
    ));

  return (
    <AdminActivityAuxView
      titleText={
        state.activeView === 'results'
          ? `Voting results from ${participantData.participants.length} participants`
          : `Review notes. Drag duplicate notes onto best version.`
      }
      allowPrint={state.activeView === 'results'}
      activityName={name}
      hasDownloadButton={state.activeView === 'results'}
      renderDownloadButton={() => (
        <CSVReportDownloadButton
          headers={headers}
          csvReport={csvReport}
          buttonText="Download Notes"
          filename="Results.csv"
          topic={session.topic}
          activity="NoteAndVote"
          activityName={name}
          withoutLabel={true}
        />
      )}
    >
      <Box displayPrint="none">
        {state.activeView === 'results' ? (
          <GridContainer direction="row" className={classes.grid}>
            {renderResults()}
          </GridContainer>
        ) : (
          renderReviewNotes()
        )}
      </Box>
      <Box displayPrint="flex" display="none" justifyContent="start" flexWrap="wrap">
        {state.activeView === 'results' && renderPrintResults()}
      </Box>
    </AdminActivityAuxView>
  );
};

export default NoteAndVoteReview;
