import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect, useFirestore } from 'react-redux-firebase';
import { withRouter } from 'react-router-dom';
import { useParticipants } from 'hooks/useParticipants';

import { getCurrentActivityType } from 'redux/selectors/participantSessionSelectors';

const ParticipantDetail = ({
  match: {
    params: { sessionId },
  },
  children,
}) => {
  const db = useFirestore();
  const currentActivityType = useSelector(getCurrentActivityType);
  const authUser = useSelector(state => state.firebase.auth);

  useFirestoreConnect(() => [
    {
      collection: 'participants',
      doc: `${sessionId}`,
      storeAs: 'participants',
    },
    {
      collection: 'sidebarMeetings',
      where: ['sessionId', '==', `${sessionId}`],
    },
  ]);

  useParticipants();

  /**
   * Send a heartbeat every 30 seconds so the facilitator
   * can see who is connected
   */
  useEffect(() => {
    if (!authUser.uid) return;

    const sendHeartbeat = () => {
      if (currentActivityType !== 'feedback') {
        console.log('sending heartbeat');

        db.doc(`participants/${sessionId}`).set(
          {
            [`${authUser.uid}`]: {
              heartbeat: db.FieldValue.serverTimestamp(),
            },
          },
          { merge: true },
        );
      }
    };

    sendHeartbeat();
    const interval = setInterval(() => sendHeartbeat(), 30000);

    return () => {
      clearInterval(interval);
    };
  }, [currentActivityType, authUser.uid]);

  return <div>{children}</div>;
};

export default withRouter(ParticipantDetail);
