import React, { useEffect, useState } from 'react';
import { map } from 'lodash';
import PropTypes from 'prop-types';
// library components
import { grey } from '@material-ui/core/colors';
import { makeStyles, Box, Typography, Button } from '@material-ui/core';
// cr components
import Answer from 'components/Scorecard/Participant/Answer';
import QuestionComment from './QuestionComment';

const useStyles = makeStyles(theme => ({
  question: {
    marginTop: theme.spacing(1),
    fontWeight: 300,
    opacity: 0.87,
  },
  line: {
    width: '100%',
    border: `1px solid ${grey[100]}`,
    marginTop: theme.spacing(1),
  },
  addCommentButton: {
    padding: 0,
    marginTop: theme.spacing(1),
    fontSize: 14,
    fontWeight: 400,
    textTransform: 'none',
    color: theme.palette.primary.main,
    '&:hover': {
      background: 'unset',
    },
  },
}));

/**
 * Renders question with answer
 */
const Question = ({
  number,
  participantAnswer,
  question,
  questionId,
  handleParticipantSelectAnswer,
  handleParticipantRemoveAnswer,
  handleParticipantAddComment,
  handleParticipantRemoveComment,
  comment,
}) => {
  const classes = useStyles();

  const answers = ['Yes', 'No', 'Unclear', 'N/A'];

  const [isEditingComment, setIsEditingComment] = useState(false);

  useEffect(() => {
    if (!comment) {
      setIsEditingComment(false);
    }
  }, [comment]);

  // renders answers
  const renderAnswers = () =>
    map(answers, (answer, idx) => (
      <Answer
        key={`answer-${idx}`}
        isAnswerSelected={answer === participantAnswer}
        questionId={questionId}
        value={answer}
        handleParticipantSelectAnswer={handleParticipantSelectAnswer}
        handleParticipantRemoveAnswer={handleParticipantRemoveAnswer}
      />
    ));

  const toggleQuestionComment = () => {
    setIsEditingComment(prev => !prev);
  };

  return (
    <Box>
      <Typography className={classes.question}>{`${number}. ${question}`}</Typography>
      <Box className="mt-2" display="flex">
        {renderAnswers()}
      </Box>
      {isEditingComment || comment ? (
        <QuestionComment
          comment={comment}
          questionId={questionId}
          handleParticipantAddComment={handleParticipantAddComment}
          handleParticipantRemoveComment={questionId => {
            handleParticipantRemoveComment(questionId);
            setIsEditingComment(false);
          }}
        />
      ) : (
        <Button className={classes.addCommentButton} onClick={toggleQuestionComment}>
          Add Comment
        </Button>
      )}
      <div className={classes.line} />
    </Box>
  );
};

Question.propTypes = {
  number: PropTypes.number.isRequired, // the number of question
  participantAnswer: PropTypes.string, // the participant answer
  question: PropTypes.string.isRequired, // the question text
  questionId: PropTypes.string.isRequired, // the question id
  handleParticipantSelectAnswer: PropTypes.func, // the participant click on the answer, which not selected
  handleParticipantRemoveAnswer: PropTypes.func, // the participant click on the answer, which selected
  handleParticipantAddComment: PropTypes.func, // the participant add comment to answer/question
  handleParticipantRemoveComment: PropTypes.func, // the participant add empty comment
  comment: PropTypes.string, // the participant comment to answer/question
};

export default Question;
