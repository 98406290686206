import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { map, includes, slice } from 'lodash';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import cx from 'classnames';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// library components
import { Box } from '@material-ui/core';
import Moment from 'components/Moment';
// cr components
import DottedProgress from 'components/DottedProgress';

const useStyles = makeStyles(theme => ({
  flowSelected: {
    padding: '10px 18px',
    border: ({ context }) =>
      context === 'select' || context === 'selectResults'
        ? `3px solid ${theme.palette.indigo.main}`
        : `3px solid ${theme.palette.success.main}`,
    background: ({ context }) =>
      context === 'select' || context === 'selectResults'
        ? theme.palette.indigo.lightest
        : theme.palette.success.lightest,
  },
  smallJourney: {
    flexDirection: 'column',
  },
  moment: {
    width: '100%',
    height: '100%',
    minHeight: 65,
    flex: 0,
  },
  dotsContainer: {
    marginRight: 'auto',
    marginBottom: 2,
    paddingLeft: 5,
  },
}));

/**
 * Story Flow view
 */
const StoryFlow = ({
  selectedFlowId,
  isSuperVoteParticipant,
  createdFlowParticipantId,
  participantFlow,
  handleCreateFlowField,
  handleChangeFlowField,
  handleSelectFlow,
  isFinished,
  context,
  votesCount,
}) => {
  const theme = useTheme();
  const classes = useStyles({ context });
  const desktopScreen = useMediaQuery('(min-width: 960px)');
  const tabletScreen = useMediaQuery('(min-width: 480px)');
  const smallScreen = useMediaQuery('(max-width: 480px)');
  const moments = Array.from({ length: 4 }, (_, i) => `Step ${i + 1}`);

  const [isFlowSelected, setIsFlowSelected] = useState(selectedFlowId === createdFlowParticipantId);

  useEffect(() => {
    setIsFlowSelected(selectedFlowId === createdFlowParticipantId);
  }, [selectedFlowId, createdFlowParticipantId]);

  const onSelectFlow = () => {
    if (context === 'select' || (context === 'superVote' && isSuperVoteParticipant)) {
      if (!isFinished) {
        handleSelectFlow(createdFlowParticipantId, !isFlowSelected);
        setIsFlowSelected(!isFlowSelected);
      }
    }
  };

  const renderMoment = (moment, idx) => (
    <Moment
      key={`moment-${idx}`}
      className={cx(smallScreen && classes.moment, isFlowSelected ? classes.flowSelected : '')}
      title={moment}
      placeholder={context === 'create' ? 'Add' : ''}
      value={participantFlow?.[`moment-${idx + 1}`]}
      type={`moment-${idx + 1}`}
      handleCreateJourneyField={handleCreateFlowField}
      handleChangeJourneyField={handleChangeFlowField}
      disabled={isFinished}
      context={context}
      isSmallScreen={smallScreen}
    />
  );

  const renderOpening = () => (
    <Moment
      className={cx(smallScreen && classes.moment, isFlowSelected ? classes.flowSelected : '')}
      title="Opening"
      placeholder="Add Opening"
      value={participantFlow?.actor}
      type="actor"
      handleCreateJourneyField={handleCreateFlowField}
      handleChangeJourneyField={handleChangeFlowField}
      disabled={isFinished}
      context={context}
      isSmallScreen={smallScreen}
    />
  );

  const renderEnd = () => (
    <Moment
      className={cx(smallScreen && classes.moment, isFlowSelected ? classes.flowSelected : '')}
      title="End"
      placeholder="Add Ending"
      value={participantFlow?.end}
      type="end"
      handleCreateJourneyField={handleCreateFlowField}
      handleChangeJourneyField={handleChangeFlowField}
      disabled={isFinished}
      context={context}
      isSmallScreen={smallScreen}
    />
  );

  return (
    <Box
      className={cx((desktopScreen || smallScreen) && 'w-100')}
      display="flex"
      flexDirection="column"
      alignItems={tabletScreen && !desktopScreen ? 'center' : 'start'}
    >
      {includes(['selectResults', 'superVote', 'results'], context) && (
        <DottedProgress
          className={classes.dotsContainer}
          totalCount={votesCount}
          activeCount={votesCount}
          activeColor={theme.palette.indigo.main}
        />
      )}
      {tabletScreen && !desktopScreen ? (
        <Box className="mb-5" onClick={onSelectFlow}>
          <Box display="flex" alignItems="center">
            {renderOpening()}
            {map(slice(moments, 0, 2), (moment, idx) => renderMoment(moment, idx))}
          </Box>
          <Box display="flex" alignItems="center" justifyContent="start">
            {map(slice(moments, 2), (moment, idx) => renderMoment(moment, idx + 2))}
            {renderEnd()}
          </Box>
        </Box>
      ) : (
        <Box
          onClick={onSelectFlow}
          className={cx(smallScreen && classes.smallJourney, 'w-100 mb-5 d-flex justify-start')}
        >
          {renderOpening()}
          {map(moments, (moment, idx) => renderMoment(moment, idx))}
          {renderEnd()}
        </Box>
      )}
    </Box>
  );
};

StoryFlow.propTypes = {
  isSelected: PropTypes.bool, // the participant select current flow
  isSelectionComplete: PropTypes.bool, // the participant has fulfilled the conditions for selection flow
  isSuperVoteParticipant: PropTypes.bool, // the facilitator select current participant for super vote
  createdFlowParticipantId: PropTypes.string, // the participant id, who created current flow
  participantFlow: PropTypes.shape({
    actor: PropTypes.string, // value of actor field
    'moment-1': PropTypes.string,
    'moment-2': PropTypes.string,
    'moment-3': PropTypes.string,
    'moment-4': PropTypes.string,
    end: PropTypes.string, // value of outcome field
  }),
  handleCreateFlowField: PropTypes.func, // function for creating values of flow fields
  handleChangeFlowField: PropTypes.func, // function for editing values of flow fields
  handleSelectFlow: PropTypes.func, // function for selecting key moment
  isFinished: PropTypes.bool, // participant submitted data
  context: PropTypes.string.isRequired, // the active phase of state
  votesCount: PropTypes.number, // count of participants which voted for this flow
};

export default StoryFlow;
