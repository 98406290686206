import React from 'react';
import cx from 'classnames';
// library components
import { Box, makeStyles } from '@material-ui/core';
// cr components
import wheel from 'assets/img/wheel.svg';
import arrow from 'assets/img/arrow.svg';
import './spinnerStyles.scss';

const useStyles = makeStyles(theme => ({
  box: {
    position: 'absolute',
    top: '28%',
    left: '50%',
    transform: 'translate(-50%, 0)',
  },
  spinnerBox: {
    width: 165,
    height: 165,
    borderRadius: '50%',
    border: `3px solid ${theme.palette.success.main}`,
    boxShadow: '0px 8px 13px rgba(0, 0, 0, 0.15)',
    backgroundImage: `url(${wheel})`,
    backgroundSize: 185,
    backgroundPositionX: -13,
    backgroundPositionY: -6,
    backgroundColor: '#DDF1D3',
  },
  spinnerArrow: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

/**
 * Renders Spinner
 */
const Spinner = () => {
  const classes = useStyles();

  return (
    <Box className={classes.box}>
      <Box className={cx(classes.spinnerBox, 'loader')} />
      <img src={arrow} alt="spinner-arrow" className={classes.spinnerArrow} />
    </Box>
  );
};

export default Spinner;
