import { saveAs } from 'file-saver';
import { store } from '../index.js';
import { showAppOverlaySpinner } from 'redux/actions/facilitatorSessionActions';
import { getActivityData } from './getActivityData.js';
import { getSessionData } from './getSessionData.js';
import { enqueueErrorSnackbar } from 'redux/actions/notifierActions.js';

/**
 * Generates an object that can be used for creating a
 * meeting template. That object will need to be added
 * to the @voltage-control/control-room-activities-config
 * package
 *
 * @param {string} sessionId id of session
 * @returns {object} object formatted as a meeting template
 */
export async function downloadMeetingTemplate(sessionId) {
  const meetingTemplate = {
    title: 'REPLACE ME',
    internalUserOnly: true,
    includedActivities: [],
  };
  store.dispatch(showAppOverlaySpinner(true));

  const session = await getSessionData(sessionId);

  if (session.activities.length) {
    try {
      await Promise.all(
        session.activities.map(async activity => {
          // exclude legacy activities
          if (['brainwriting', 'breakouts'].includes(activity.activity)) return;

          const activityData = await getActivityData(activity.activity, activity.id);

          if (activity.activity === '25:10') {
            meetingTemplate.includedActivities.push({
              [`...ACTIVITIES['${activity.activity}']`]: 'remove', // just temporary, will reformat below
              config: {
                name: activityData.name,
                topic: activityData.topic,
              },
            });
          } else {
            meetingTemplate.includedActivities.push({
              [`...ACTIVITIES.${activity.activity}`]: 'remove', // just temporary, will reformat below
              config: {
                name: activityData.name,
                prompt: activityData.prompt,
                ...activityData.config,
              },
            });
          }

          return;
        }),
      );

      if (meetingTemplate.includedActivities.length) {
        let jsonString = JSON.stringify(meetingTemplate, null, 2);
        jsonString = jsonString.replaceAll(`": "remove"`, '');
        jsonString = jsonString.replaceAll(`"...`, '...');

        const blob = new Blob([jsonString], { type: 'text/plain;charset=utf-8' });
        saveAs(blob, 'meeting-template.json');
      } else {
        store.dispatch(enqueueErrorSnackbar('No supported activities to export'));
      }

      store.dispatch(showAppOverlaySpinner(false));
    } catch (error) {
      store.dispatch(showAppOverlaySpinner(false));
      enqueueErrorSnackbar('error generating meetin JSON');
      console.error('error generating meetin JSON: ', error);
    }
  } else {
    store.dispatch(showAppOverlaySpinner(false));
    store.dispatch(enqueueErrorSnackbar('No activities to export'));
  }

  return;
}
