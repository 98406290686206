import { enqueueErrorSnackbar } from 'redux/actions/notifierActions';

/**
 * Create cards set
 *
 * @param {String} facilitatorId
 * @param {String} setId
 * @param {Number} order
 */
export const createCardsSet =
  (facilitatorId, setId, order) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`cardSets/${setId}`)
      .set(
        {
          setId,
          name: '',
          facilitatorId,
          cards: {},
          isEdit: true,
          order,
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error create set'));
      });
  };

/**
 * Update edit set
 *
 * @param {String} setId
 * @param {Boolean} isEdit
 * @param {Boolean} hasCb
 * @param {Function} successCb
 */
export const updateEditSet =
  (setId, isEdit, hasCb, successCb) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`cardSets/${setId}`)
      .set(
        {
          isEdit,
        },
        { merge: true },
      )
      .then(() => {
        if (hasCb) {
          successCb();
        }
      })
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error update edit set'));
      });
  };

/**
 * Update set name
 *
 * @param {String} setId
 * @param {String} name
 */
export const updateSetName =
  (setId, name) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`cardSets/${setId}`)
      .set(
        {
          name,
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error update set name'));
      });
  };

/**
 * Delete set
 *
 * @param {String} setId
 */
export const deleteSet =
  setId =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .collection('cardSets')
      .doc(`${setId}`)
      .delete()
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error delete set'));
      });
  };

/**
 * Duplicate set
 *
 * @param {String} setId
 * @param {Object} set
 */
export const duplicateSet =
  (setId, duplicateSet) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`cardSets/${setId}`)
      .set(duplicateSet, { merge: true })
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error duplicate set'));
      });
  };

/**
 * Create card
 *
 * @param {String} setId
 * @param {String} cardId
 * @param {String} text
 * @param {String} imageUrl
 * @param {String} icon
 * @param {Number} order
 */
export const createCard =
  (setId, cardId, text, imageUrl, icon, order) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`cardSets/${setId}`)
      .set(
        {
          cards: {
            [cardId]: {
              cardId,
              order,
              text: text || '',
              icon: icon || '',
              imageUrl: imageUrl || '',
            },
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error create card'));
      });
  };

/**
 * Delete card
 *
 * @param {String} setId
 * @param {String} cardId
 * @param {Function} successCb
 */
export const deleteCard =
  (setId, cardId, successCb) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;
    const path = `cards.${cardId}`;

    db()
      .doc(`cardSets/${setId}`)
      .update({
        [path]: db.FieldValue.delete(),
      })
      .then(() => successCb())
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error delete card'));
      });
  };

/**
 * Edit card image
 *
 * @param {String} setId
 * @param {String} cardId
 * @param {String} imageUrl
 */
export const editCardImage =
  (setId, cardId, imageUrl) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`cardSets/${setId}`)
      .set(
        {
          cards: {
            [cardId]: {
              imageUrl: imageUrl,
            },
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error edit card image'));
      });
  };

/**
 * Edit card icon
 *
 * @param {String} setId
 * @param {String} cardId
 * @param {String} icon
 */
export const editCardIcon =
  (setId, cardId, icon) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`cardSets/${setId}`)
      .set(
        {
          cards: {
            [cardId]: {
              icon: icon,
            },
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error edit card icon'));
      });
  };

/**
 * Edit card text
 *
 * @param {String} setId
 * @param {String} cardId
 * @param {String} text
 */
export const editCardText =
  (setId, cardId, text) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`cardSets/${setId}`)
      .set(
        {
          cards: {
            [cardId]: {
              text: text,
            },
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error edit card text'));
      });
  };
