import React, { useState } from 'react';
import PropTypes from 'prop-types';
// library components
import { makeStyles, Paper, Typography, MenuItem, Menu, Box } from '@material-ui/core';
// cr components
import NewCardText from './NewCardText';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 170,
    height: 200,
    marginBottom: 10,
    border: '1px dashed #CFCFCF',
    cursor: 'pointer',
  },
  text: {
    fontSize: 18,
    color: '#9E9E9E',
  },
}));

const NewCard = ({ createCardText, handleUploadImage, handleUploadIcon }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isCreateText, setIsCreateText] = useState(false);

  const handleOpen = event => {
    if (!isCreateText) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCreateText = () => {
    handleClose();
    setIsCreateText(true);
  };

  const handleCloseCreateText = () => {
    setIsCreateText(false);
  };

  const handleCreateImage = () => {
    handleClose();
    handleUploadImage();
  };

  const handleCreateIcon = () => {
    handleClose();
    handleUploadIcon();
  };

  return (
    <Box className="position-relative">
      <Paper
        className={classes.container}
        elevation={0}
        onClick={handleOpen}
        aria-controls="cards-menu"
        aria-haspopup="true"
      >
        {isCreateText ? (
          <NewCardText
            handleCloseCreateText={handleCloseCreateText}
            createCardText={createCardText}
            context="create"
          />
        ) : (
          <Typography className={classes.text}>New Card</Typography>
        )}
      </Paper>
      <Menu
        id="cards-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleCreateText}>Add Text</MenuItem>
        <MenuItem onClick={handleCreateIcon}>Add Icon</MenuItem>
        <MenuItem onClick={handleCreateImage}>Add Image</MenuItem>
      </Menu>
    </Box>
  );
};

NewCard.propTypes = {
  createCardText: PropTypes.func.isRequired, // the function saves the text of the edited card
  handleUploadImage: PropTypes.func.isRequired, // the function saves the icon card
  handleUploadIcon: PropTypes.func.isRequired, // the function saves the image card
};

export default NewCard;
