import React from 'react';
import { filter, map, sortBy } from 'lodash';
// library components
import { Box } from '@material-ui/core';
// cr components
import JourneyVote from 'components/UserJourneyMoments/Participant/JourneyVote';
// selectors
import { useUserJourneyMomentsSelector } from 'redux/selectors/userJourneyMomentsSelectors';

/**
 * User Journey Moments view for the 'review' phase on the Participant side
 */
const UserJourneyMomentsParticipantReview = () => {
  const {
    participantData: { journeys },
    state: { activeView },
  } = useUserJourneyMomentsSelector();
  // sorted participants journey by participant id
  const sortedJourneys = sortBy(journeys, 'participantId');

  // renders participants votes
  const rendersJourneyVote = () =>
    map(sortedJourneys, (journey, idx) => {
      // get votes for moment for current journey
      const momentVotes = filter(
        journey.votedParticipants,
        vote => vote.moment && vote.moment.isVoted,
      );
      // get votes for actor for current journey
      const actorVotes = filter(
        journey.votedParticipants,
        vote => vote.actor && vote.actor.isVoted,
      );
      if (journey.keyMoment && journey.values?.actor) {
        return (
          <JourneyVote
            key={`journey-${idx}`}
            journeyId={journey.participantId}
            keyMoment={journey.keyMoment}
            keyMomentTitle={journey.values?.[journey.keyMoment]}
            actor={journey.values?.actor}
            type="actor"
            context={activeView}
            isFinished={true}
            momentVotesCount={momentVotes.length}
            actorVotesCount={actorVotes.length}
          />
        );
      }
    });

  return (
    <>
      <Box
        className="w-100 mb-8"
        display="flex"
        alignItems="center"
        justifyContent="start"
        flexWrap="wrap"
      >
        {rendersJourneyVote()}
      </Box>
    </>
  );
};

export default UserJourneyMomentsParticipantReview;
