import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { filter, size } from 'lodash';
// library components
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// cr components
import ActivityParticipantDetails from 'components/ActivityParticipantDetails';
import ActivityPromptAdmin from 'components/ActivityPromptAdmin';
import ActivityActionButton from 'components/CustomButtons/ActivityActionButton';
import FormDialog from 'components/Dialogs/FormDialog';
import SelectSuperVoteParticipantForm from 'components/Forms/SelectSuperVoteParticipantForm';
// selectors
import {
  useUserJourneyMomentsSelector,
  getParticipantsHaveVoted,
} from 'redux/selectors/userJourneyMomentsSelectors';
import { getParticipantsMap } from 'redux/selectors/participantsSelectors';
// actions
import { revealResults, updateSuperVoteParticipant } from 'redux/actions/userJourneyMomentsActions';
// config
import { USER_JOURNEY_MOMENTS_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';

const useStyles = makeStyles(theme => ({
  promptAdmin: {
    marginBottom: theme.spacing(5),
  },
}));

/**
 * User Journey Moments view for the 'superVote' phase on the Facilitator side
 */
const UserJourneyMomentsAdminSuperVote = () => {
  const maxSuperVote = 2;
  const classes = useStyles();
  const dispatch = useDispatch();
  const userJourneyMoments = useUserJourneyMomentsSelector();
  const {
    id,
    prompt,
    participantData: { participants, superVoteTypes },
    state: { superVoteParticipantId },
  } = userJourneyMoments;

  const [isModalOpen, setIsModalOpen] = useState(!superVoteParticipantId);

  const participantsData = useSelector(getParticipantsMap);

  const participantsHaveVoted = useSelector(getParticipantsHaveVoted());
  const isSuperVoteComplete = size(superVoteTypes) === maxSuperVote;
  const participantsSuperVoteText = isSuperVoteComplete
    ? 'Decider finished voting'
    : 'Decider is voting';

  // Close modal form
  const closeDialog = () => {
    setIsModalOpen(false);
  };

  // Add super vote participant id
  const submitSuperVoteParticipantId = participantId => {
    dispatch(updateSuperVoteParticipant(id, participantId));
    closeDialog();
  };

  const onUpdateActivityView = () => {
    dispatch(revealResults(id));
  };

  return (
    <Box className="d-flex flex-column justify-content-center">
      <ActivityPromptAdmin
        prompt={prompt}
        collection={CONFIG.collection}
        activityId={id}
        className={classes.promptAdmin}
      />
      <ActivityParticipantDetails
        headerText={participantsSuperVoteText}
        hasCompletedLabel="Voted"
        hasntCompletedLabel="Hasn’t Voted"
        participantsCompletedStatus={participantsHaveVoted}
      />
      <ActivityActionButton
        text="Review Final Results"
        onClick={onUpdateActivityView}
        className="mb-2 mt-1"
        delayReady
      />
      <FormDialog
        handleClose={() => setIsModalOpen(true)}
        isOpen={isModalOpen}
        title={'Start Super Vote'}
      >
        <SelectSuperVoteParticipantForm
          participantsData={participantsData}
          participants={participants}
          submitSuperVoteParticipantId={submitSuperVoteParticipantId}
        />
      </FormDialog>
    </Box>
  );
};

export default UserJourneyMomentsAdminSuperVote;
