import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { filter, head, has, includes, map, sortBy, each, size } from 'lodash';
// library components
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// cr components
import ActivityParticipantDetails from 'components/ActivityParticipantDetails';
import ActivityPromptAdmin from 'components/ActivityPromptAdmin';
import ActivityActionButton from 'components/CustomButtons/ActivityActionButton';
import CategoriesAdmin from 'components/Categories/CategoriesAdmin';
// selectors
import {
  useNoteAndCategorizeSelector,
  getParticipantsCompletedCategorizes,
} from 'redux/selectors/noteAndCategorizeSelectors';
// actions
import {
  updateCategoryStatus,
  updateCurrentCategory,
  updatePreviousCategory,
  revealResults,
  removeNote,
  updateSerialRoundRobinStatus,
} from 'redux/actions/noteAndCategorizeAction';
// config
import { NOTE_AND_CATEGORIZE_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';
// utils
import { makeParticipantDetailsHeaderText } from 'utils/makeParticipantDetailsHeaderText';

const useStyles = makeStyles(theme => ({
  promptAdmin: {
    marginBottom: theme.spacing(1),
  },
}));

/**
 * Note & Categorize view for the 'categorize' phase on the Facilitator side
 */
const NoteAndCategorizeAdminCategorize = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const noteAndCategorize = useNoteAndCategorizeSelector();
  const {
    id,
    prompt,
    state: { activeView, isCategorized },
    config: { categories, format, roundRobinShare, isShowPrevCategory, ideasPerCategory },
    participantData: { notes, currentCategoryId },
  } = noteAndCategorize;

  let currentCategory = null; // always 'null' for Parallel
  let isLastCategory = true; // always 'last' for Parallel
  let sortField = 'value';
  let nextView = 'review';
  let finishButtonText = 'Remove Duplicates';

  const sortedCategories = sortBy(
    map(categories, category => {
      sortField = has(category, 'order') ? 'order' : 'value';
      return category;
    }),
    sortField,
  );

  if (roundRobinShare === 'on') {
    nextView = 'roundRobin';
    finishButtonText = 'review results';
  }

  if (format === 'Serial') {
    // sorting not categorized items
    const currentCategories = filter(
      sortedCategories,
      category => !includes(isCategorized, category.id),
    );
    currentCategory = head(currentCategories);

    if (currentCategories.length > 1) {
      isLastCategory = false;
    }

    // pass current category id for participant view
    dispatch(updateCurrentCategory(id, currentCategory?.id));
  }

  if (!isLastCategory && !(roundRobinShare === 'on' && format === 'Serial')) {
    finishButtonText = 'next category';
  }

  const participantsHaveCategorizedData = useSelector(
    getParticipantsCompletedCategorizes(currentCategory?.id),
  );
  // sorting categorized items
  const participantsCategorizedCount = filter(participantsHaveCategorizedData, {
    completed: true,
  }).length;
  const participantsCategorizedCountText = makeParticipantDetailsHeaderText(
    participantsCategorizedCount,
    participantsHaveCategorizedData.length,
    'categorized notes',
  );

  const onUpdateActivityView = () => {
    if (currentCategory) {
      // remove notes without category
      each(notes, note => {
        if (!note.categoryId) {
          dispatch(removeNote(id, note.noteId));
        }
      });
      if (isShowPrevCategory && format === 'Serial') {
        dispatch(updatePreviousCategory(id, currentCategoryId));
      }
      if (roundRobinShare === 'on' && format === 'Serial') {
        dispatch(updateSerialRoundRobinStatus(id, currentCategory.id, 'roundRobin'));
      }
      dispatch(updateCategoryStatus(id, currentCategory.id));
    }
    if (isLastCategory) {
      if (roundRobinShare === 'on' && format === 'Serial') {
        dispatch(updateSerialRoundRobinStatus(id, currentCategory.id, 'roundRobin'));
      }
      // create result and switch view
      dispatch(revealResults(id, nextView, false));
    }
  };

  return (
    <Box className="d-flex flex-column justify-content-center">
      <ActivityPromptAdmin
        prompt={prompt}
        collection={CONFIG.collection}
        activityId={id}
        className={classes.promptAdmin}
      />
      <CategoriesAdmin
        categories={sortedCategories}
        view={activeView}
        currentCategory={currentCategory}
      />
      <ActivityParticipantDetails
        headerText={participantsCategorizedCountText}
        hasCompletedLabel="Categorized"
        hasntCompletedLabel="Hasn't Categorized"
        // TODO: @reggie, why is this not a number????
        completedCountMax={currentCategory ? ideasPerCategory : ideasPerCategory * size(categories)}
        participantsCompletedStatus={participantsHaveCategorizedData}
      />
      <ActivityActionButton
        text={finishButtonText}
        onClick={onUpdateActivityView}
        delayReady
        key={isLastCategory ? 'revealresult' : 'nextcategory'}
      />
    </Box>
  );
};

export default NoteAndCategorizeAdminCategorize;
