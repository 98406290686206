import React from 'react';
import { useSelector } from 'react-redux';
import { isLoaded } from 'react-redux-firebase';
import { makeStyles } from '@material-ui/core/styles';
import range from 'lodash/range';
// library components
import Typography from '@material-ui/core/Typography';
// CR components
import Participant2510VoteForm from './Participant2510VoteForm';
// selectors
import {
  getAssignedIdea,
  getCurrentRound,
  getParticipantsVote,
  getTopic,
} from 'redux/selectors/25:10/root2510Selectors';

const useStyles = makeStyles({
  roundsComplete: {
    fontSize: '30px',
    fontWeight: '300',
    color: '#02A05C',
  },
  waitingResults: {
    fontSize: '20px',
    fontWeight: '300',
    color: '#333333',
  },
  round: {
    marginBottom: '30px',
    textAlign: 'center',
    fontSize: '30px',
    fontWeight: '300',
  },
});

const votes = range(1, 6);
const Participant2510Vote = ({ activityId }) => {
  const classes = useStyles();
  const participantId = useSelector(state => state.firebase.auth.uid);
  const assignedIdeaVote = useSelector(getAssignedIdea(participantId));
  const participantsVote = useSelector(getParticipantsVote(participantId));
  const currentRound = useSelector(getCurrentRound);
  const topic = useSelector(getTopic);

  if (!assignedIdeaVote && !isLoaded(assignedIdeaVote)) {
    return null;
  }

  if (!assignedIdeaVote) {
    return (
      <>
        <Typography>Voting started before you submitted an idea.</Typography>
        <Typography>Please wait for results.</Typography>
        <Typography className={classes.round}>Round {currentRound}</Typography>
      </>
    );
  }

  if (currentRound === 5 && participantsVote) {
    return (
      <>
        <Typography className={classes.roundsComplete}>All rounds completed!</Typography>
        <Typography className={classes.waitingResults}>Waiting for results.</Typography>
      </>
    );
  }

  return (
    <Participant2510VoteForm
      activityId={activityId}
      votes={votes}
      idea={assignedIdeaVote}
      currentRound={currentRound}
      participantsVote={participantsVote}
      topic={topic}
    />
  );
};

export default Participant2510Vote;
