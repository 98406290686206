import React from 'react';
// cr components
import Grouping from './Grouping';
// selectors
import { useHowMightWeSelector } from 'redux/selectors/howMightWeSelectors';

/**
 * HMW view for the 'group' phase on the Participant side
 */
const HowMightWeParticipantGroup = () => {
  const {
    id,
    participantData: { groups },
    state: { activeView },
  } = useHowMightWeSelector();

  return <Grouping howMightWeId={id} groups={groups} context={activeView} />;
};

export default HowMightWeParticipantGroup;
