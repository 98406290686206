import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
// library components
import { Typography } from '@material-ui/core';
// cr components
import GrayBox from 'components/GrayBox';

const useStyles = makeStyles(theme => ({
  box: {
    marginBottom: theme.spacing(6),
    padding: '10px 15px',
  },
  header: {
    fontSize: 18,
  },
  description: {
    fontWeight: 300,
    fontSize: 16,
  },
}));

/**
 * Renders the item name and description
 */
const ItemDescription = ({ itemName, itemDescription }) => {
  const classes = useStyles();

  return (
    <GrayBox className={classes.box}>
      <Typography className={classes.header}>{itemName}</Typography>
      <Typography className={classes.description}>{itemDescription}</Typography>
    </GrayBox>
  );
};

ItemDescription.propTypes = {
  itemName: PropTypes.string.isRequired,
  itemDescription: PropTypes.string.isRequired,
};

export default ItemDescription;
