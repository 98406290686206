import React from 'react';
import { useSelector } from 'react-redux';
import { map, sortBy } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
// library components
import { Typography, Box } from '@material-ui/core';
// cr components
import LightningDemo from 'components/LightningDemo/Participant/LightningDemo';
// selectors
import { useLightningDemoSelector } from 'redux/selectors/lightningDemoSelectors';
import {
  createLightningDemoReportHeader,
  createLightningDemoReportData,
} from 'utils/lightningDemo';

const useStyles = makeStyles({
  title: {
    marginLeft: 'auto',
    paddingLeft: '10%',
    fontSize: 16,
    fontWeight: 200,
    textAlign: 'center',
  },
  completeTitle: {
    margin: 'auto',
    fontSize: 16,
    fontWeight: 200,
    textAlign: 'center',
  },
  newDemoButton: {
    marginLeft: 'auto',
    fontSize: 15,
    fontWeight: 400,
  },
});

/**
 * Lightning Demo view for the 'results' phase on the Participant side
 */
const LightningDemoParticipantResults = () => {
  const classes = useStyles();
  const lightningDemo = useLightningDemoSelector();
  const {
    id,
    participantData: { demos },
    state: { activeView },
    output,
    name,
  } = lightningDemo;

  const session = useSelector(state => state.firestore.data.session);

  // sort demos by participant id and order
  const sortedDemos = sortBy(demos, ['participantId', 'order']);

  // render headers for csv report
  const headers = createLightningDemoReportHeader();

  // render data for csv report
  const csvReport = createLightningDemoReportData(output);

  return (
    <Box>
      <Box className="w-100 mb-2" display="flex" alignItems="center">
        <Typography className={classes.completeTitle}>Demo Review</Typography>
      </Box>
      <Box
        className="w-100 pl-3"
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        flexWrap="wrap"
      >
        {map(sortedDemos, (demo, idx) => {
          if (demo.demoId) {
            return (
              <LightningDemo
                key={`demo-${idx}`}
                context={activeView}
                demoId={demo.demoId}
                lightningDemoId={id}
                participantId={demo.participantId}
                imageId={demo.imageId}
                bigIdeas={demo.bigIdeas}
                source={demo.source}
                demoUrl={demo.url}
                isFinished={true}
              />
            );
          }
        })}
      </Box>
    </Box>
  );
};

export default LightningDemoParticipantResults;
