import React from 'react';
import { useDispatch } from 'react-redux';

import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';

import { useStaffingSelector } from 'redux/selectors/staffing/staffingSelectors';
import { openAddProjectToDealDialog } from 'redux/actions/staffingActions';
import AddProjectToDealForm from 'components/Forms/Staffing/AddProjectToDealForm';

const useStyles = makeStyles(theme => ({
  paperWidthSm: {
    maxWidth: 760,
  },
  contentRoot: {
    padding: theme.spacing(5),
  },
  dialogHeader: {
    position: 'relative',
    maxWidth: 760,
    width: 760,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dialogHeaderRoot: {
    padding: '24px 8px',
  },
  dialogHeaderTitle: {
    fontSize: 16,
    fontWeight: 500,
  },
  cancelButton: {
    position: 'absolute',
    right: theme.spacing(1),
  },
}));

const AddProjectToDealDialog = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { isOpenAddProjectToDealDialog, projectToUpdateId } = useStaffingSelector();

  const closeDialog = () => {
    dispatch(openAddProjectToDealDialog(false, null));
  };

  return (
    <div>
      <Dialog
        open={isOpenAddProjectToDealDialog}
        onClose={closeDialog}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        classes={{
          paperWidthSm: classes.paperWidthSm,
        }}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle
          disableTypography
          className={classes.dialogHeader}
          classes={{ root: classes.dialogHeaderRoot }}
        >
          <Typography className={classes.dialogHeaderTitle}>
            {projectToUpdateId ? 'Edit' : 'Add'} Project
          </Typography>
          <IconButton className={classes.cancelButton} aria-label="close" onClick={closeDialog}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers className={classes.contentRoot}>
          <AddProjectToDealForm closeDialog={closeDialog} />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddProjectToDealDialog;
