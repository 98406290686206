import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getFirebase } from 'react-redux-firebase';
import { useParams } from 'react-router';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import SessionFeedbackForm from 'components/Forms/SessionFeedbackForm';
import PageContent from 'components/PageContent/PageContent';
import PageContentHeader from 'components/PageContent/PageContentHeader';
import PageContentBody from 'components/PageContent/PageContentBody';
import AdvertFooter from 'components/AdvertFooter/AdvertFooter';
import CrLogo from 'components/CrLogo/CrLogo';

import { getSessionData } from 'utils/getSessionData';

import './feedback.scss';

const Feedback = () => {
  const { sessionId } = useParams();
  const session = useSelector(state => state.firestore.data.session);
  const alreadySubmitted = localStorage.getItem(`submittedFeedbackFor_${sessionId}`);
  const firebase = getFirebase();
  const db = firebase.firestore();
  const participantId = useSelector(state => state.firebase.auth.uid);
  const [sessionData, setSessionData] = useState();
  const [sessionParticipants, setSessionParticipants] = useState();

  useEffect(() => {
    async function getSessionInfo() {
      const response = await getSessionData(sessionId);
      setSessionData(response);
    }
    async function getParticipants() {
      const response = await db.collection('participants').doc(`${sessionId}`).get();
      setSessionParticipants(response.data());
    }
    getSessionInfo();
    getParticipants();
  }, [sessionId]);

  if (!sessionData || !sessionParticipants) {
    return null;
  }

  return (
    <GridContainer
      direction="row"
      justify="center"
      alignItems="center"
      className="session-feedback"
    >
      <GridItem sm={12} md={12} lg={12} xl={8}>
        <PageContent>
          <PageContentHeader className="with-logo">
            <div className="left-col">
              <CrLogo width={100} className="mt-1" withLink session={session || sessionData} />
            </div>
            <Typography
              variant="h5"
              style={{ fontWeight: 'bold', textTransform: 'uppercase' }}
              className="page-content-title"
            >
              {(session && session.topic) || sessionData.topic}: Feedback
            </Typography>
            <div />
          </PageContentHeader>
          <PageContentBody contentCenter>
            {alreadySubmitted && (
              <Typography variant="h5" className="form-header">
                You already submitted feedback for this meeting.
              </Typography>
            )}
            <SessionFeedbackForm
              session={sessionData}
              participant={sessionParticipants[participantId]}
            />
            <Box style={{ marginBottom: 72 }}>
              <AdvertFooter />
            </Box>
          </PageContentBody>
        </PageContent>
      </GridItem>
    </GridContainer>
  );
};

export default Feedback;
