import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { has, map, sortBy, reverse, find, remove, includes, each, filter, size } from 'lodash';
import { DragDropContext } from 'react-beautiful-dnd';
import { makeStyles } from '@material-ui/core/styles';
// library components
import { Box } from '@material-ui/core';
// cr components
import Category from './Category';
// selectors
import { useNoteAndCategorizeSelector } from 'redux/selectors/noteAndCategorizeSelectors';
// actions
import { updateCombineNotes } from 'redux/actions/noteAndCategorizeAction';
// utils
import { createCategorizeOutput } from 'utils/noteAndCategorize';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    marginBottom: theme.spacing(2),
  },
}));

const notesUseStyles = makeStyles({
  note: {
    minHeight: 75,
  },
});

/**
 * Note & Categorize view for the 'review' phase on the Participant side.
 */
const NoteAndCategorizeReview = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const notesClasses = notesUseStyles();
  const {
    id,
    config: { votesPerCategory, categories, roundRobinShare, format },
    participantData: { notes },
    state: { isCategorized, activeView, serialRoundRobin },
  } = useNoteAndCategorizeSelector();
  const results = createCategorizeOutput(notes);
  const participantsNotes = reverse(sortBy(notes, ['order', 'noteId']));
  let sortField = 'value';
  const sortedCategories = sortBy(
    map(categories, category => {
      sortField = has(category, 'order') ? 'order' : 'value';
      return category;
    }),
    sortField,
  );

  const [isDragStart, setIsDragStart] = useState(false);
  const [combineNote, setCombineNote] = useState({});
  const [isOpenCombineList, setIsOpenCombineList] = useState(false);
  const [draggableCategory, setDraggableCategory] = useState('');

  // open drop down duplicate cards
  const handleOpenDuplicateCards = noteId => {
    const currentCombineNote = find(notes, note => note.noteId === noteId);
    if (combineNote.noteId === noteId) {
      setIsOpenCombineList(false);
      setCombineNote({});
    } else {
      setIsOpenCombineList(true);
      setCombineNote(currentCombineNote);
    }
  };

  // add duplicate card to stack
  const addDuplicateCardToStack = (combineNoteId, draggableId, draggableNote) => {
    if (combineNoteId === draggableId) {
      return;
    }

    const combineNote = find(notes, note => note.noteId === combineNoteId);
    const draggableNoteCategory = draggableNote.categoryId;

    if (draggableNoteCategory !== combineNote.categoryId) {
      // if note combine with note from other category
      return;
    }

    if (size(draggableNote.combineNotesId)) {
      each(draggableNote.combineNotesId, duplicateNoteId => {
        dispatch(updateCombineNotes(id, draggableId, duplicateNoteId, false));
        dispatch(updateCombineNotes(id, combineNoteId, duplicateNoteId, true));
      });
    }

    if (isOpenCombineList && combineNote.combineNotesId?.length > 1) {
      const newcombineNote = {
        ...combineNote,
        combineNotesId: [...combineNote.combineNotesId, draggableId],
      };
      setCombineNote(newcombineNote);
    }

    dispatch(updateCombineNotes(id, combineNoteId, draggableId, true));
  };

  // function on drag end
  const onDragEnd = result => {
    setIsDragStart(false);
    setDraggableCategory('');
    const { destination, draggableId, combine } = result;

    const draggableNote = find(notes, note => note.noteId === draggableId);
    const draggableNoteCategory = draggableNote.categoryId;

    const finishIndex = destination?.droppableId;

    const duplicateCard = find(notes, note => note.noteId === finishIndex);
    if (duplicateCard) {
      if (draggableNote.isCombine) {
        const combineNote = find(notes, note => includes(note.combineNotesId, draggableId));

        if (draggableNoteCategory !== duplicateCard.categoryId) {
          // if note combine with note from other category
          return;
        }

        if (finishIndex === draggableId) {
          return;
        }

        dispatch(updateCombineNotes(id, combineNote.noteId, draggableId, false));
        const newCombineNote = {
          ...combineNote,
          combineNotesId: remove(combineNote.combineNotesId, draggableId),
        };
        setCombineNote(newCombineNote);

        dispatch(updateCombineNotes(id, finishIndex, draggableId, true));
      } else {
        addDuplicateCardToStack(finishIndex, draggableId, draggableNote);
      }
      return;
    }

    // remove duplicate card from stack
    if (draggableNote.isCombine) {
      if (!finishIndex) {
        return;
      }
      const combineNote = find(notes, note => includes(note.combineNotesId, draggableId));

      if (combineNote.noteId === draggableId) {
        return;
      }

      dispatch(updateCombineNotes(id, combineNote.noteId, draggableId, false));
      const newcombineNote = {
        ...combineNote,
        combineNotesId: remove(combineNote.combineNotesId, draggableId),
      };
      setCombineNote(newcombineNote);

      // add card to other stack
      if (combine) {
        const combineNoteId = combine.draggableId;
        dispatch(updateCombineNotes(id, combineNoteId, draggableId, true));
      }

      return;
    }

    // add duplicate card to stack
    if (combine) {
      const combineNoteId = combine.draggableId;
      addDuplicateCardToStack(combineNoteId, draggableId, draggableNote);
      return;
    }

    if (!destination) {
      return;
    }
  };

  const onDragStart = result => {
    setIsDragStart(true);
    const draggableId = result.draggableId;
    const note = find(notes, note => note.noteId === draggableId);
    setDraggableCategory(note?.categoryId || '');
  };

  /**
   * Create all categories.
   */
  const renderCategories = categories => {
    return map(categories, (category, idx) => (
      <Category
        key={`${category.value}-${idx}`}
        id={category.id}
        droppableId={`${idx}`}
        value={category.value}
        notes={participantsNotes}
        context="review"
        votesPerCategory={votesPerCategory}
        noteSize="small"
        results={results?.[category.id]}
        noteClassName={notesClasses.note}
        handleOpenDuplicateCards={handleOpenDuplicateCards}
        combineNote={combineNote}
        isOpenCombineList={isOpenCombineList}
        isDragStart={isDragStart}
        isDraggableCategory={draggableCategory === category.id}
      />
    ));
  };

  const currentCategories =
    roundRobinShare === 'on' &&
    format === 'Serial' &&
    size(isCategorized) <= size(sortedCategories) &&
    activeView !== 'results'
      ? filter(sortedCategories, category => category.id === serialRoundRobin)
      : sortedCategories;

  return (
    <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
      <Box classes={classes}>{renderCategories(currentCategories)}</Box>
    </DragDropContext>
  );
};

export default NoteAndCategorizeReview;
