import React from 'react';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
// cr components
import ActivityTemplate from 'components/ActivityTemplate/ActivityTemplate';
import ParticipantActivityContent from 'components/ParticipantActivityContent';
import CardSortingParticipantCategorize from 'components/CardSorting/Participant/CardSortingParticipantCategorize';
import CardSortingParticipantReview from 'components/CardSorting/Participant/CardSortingParticipantReview';
import CardSortingParticipantPrimaryCategorize from 'components/CardSorting/Participant/CardSortingParticipantPrimaryCategorize';
import CardSortingParticipantResults from 'components/CardSorting/Participant/CardSortingParticipantResults';
// selectors
import { useCardSortingSelector } from 'redux/selectors/cardSortingSelectors';
// constants
import { CARD_SORTING_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';

//add new view for all action
const views = {
  categorize: CardSortingParticipantCategorize,
  review: CardSortingParticipantReview,
  primaryCategorize: CardSortingParticipantPrimaryCategorize,
  results: CardSortingParticipantResults,
};

const CardSortingParticipant = ({
  match: {
    params: { activityId },
  },
}) => {
  const cardSorting = useCardSortingSelector();

  // hooks up a firestore listener to Redux
  useFirestoreConnect(() => [
    {
      collection: 'cardSorting',
      doc: `${activityId}`,
      storeAs: 'cardSorting',
    },
  ]);

  // ensure all of the data we need is loaded
  if (!isLoaded(cardSorting) || activityId !== cardSorting?.id) {
    return null;
  }

  const Component = views[cardSorting.state.activeView];

  const renderViewLabel = () => {
    switch (cardSorting.state.activeView) {
      case 'results':
        if (cardSorting.state.isActivityFinished) {
          return `Final Results`;
        } else {
          return `Review round ${cardSorting.state.round} cards`;
        }
      default:
        return CONFIG.participantViewLabels[cardSorting.state.activeView];
    }
  };

  return (
    <ActivityTemplate type="Card Sorting" name={cardSorting.name}>
      <ParticipantActivityContent
        activityData={cardSorting}
        viewLabel={renderViewLabel()}
        activityConfig={CONFIG}
      >
        <Component />
      </ParticipantActivityContent>
    </ActivityTemplate>
  );
};

export default CardSortingParticipant;
