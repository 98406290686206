import React from 'react';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
// cr components
import ActivityTemplate from 'components/ActivityTemplate/ActivityTemplate';
import AssessmentParticipantStart from 'components/Assessment/Participant/AssessmentParticipantStart';
import AssessmentParticipantWrite from 'components/Assessment/Participant/AssessmentParticipantWrite';
import ParticipantActivityContent from 'components/ParticipantActivityContent';
import AssessmentParticipantFinalResults from 'components/Assessment/Participant/AssessmentParticipantFinalResults';
// selectors
import { useAssessmentSelector } from 'redux/selectors/assessmentSelectors';
// constants
import { ASSESSMENT_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';

const views = {
  start: AssessmentParticipantStart,
  write: AssessmentParticipantWrite,
  results: AssessmentParticipantFinalResults,
};

/**
 * Entry point for Assessment on the Participant side.
 * Loads the Firestore object for the activity, ensures
 * the data is loaded before rendering the game, and determines
 * which view to show
 */
const AssessmentParticipant = ({
  match: {
    params: { activityId },
  },
}) => {
  const assessment = useAssessmentSelector();

  // hooks up a firestore listener to Redux
  useFirestoreConnect(() => [
    {
      collection: 'assessment',
      doc: `${activityId}`,
      storeAs: 'assessment',
    },
  ]);

  // ensure all of the data we need is loaded
  if (!isLoaded(assessment) || activityId !== assessment?.id) {
    return null;
  }

  const Component = views[assessment.state.activeView];

  return (
    <ActivityTemplate type="Assessment" name={assessment.name}>
      <ParticipantActivityContent
        activityData={assessment}
        viewLabel=""
        activityConfig={CONFIG}
        promptOverride={assessment.state.activeView === 'results' ? 'Assessment Results' : ''}
      >
        <Component context={assessment.state.activeView} />
      </ParticipantActivityContent>
    </ActivityTemplate>
  );
};

export default AssessmentParticipant;
