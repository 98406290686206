import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
// library components
import { Button, Typography } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
// cr components

const useStyles = makeStyles(theme => ({
  formActions: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    width: '100%',
    padding: 0,
    // bottom: `-8px`,
    paddingTop: 8,
    left: '-8px',
    // right: 0,
  },
  editBtn: {
    display: 'flex',
    alignItems: 'center',
    color: '#898989',
    cursor: 'pointer',
  },
  editText: {
    fontSize: '12px',
  },
}));

/**
 * Renders Edit icon for note, idea and etc.
 */
const EditCardButton = ({ isVisible, onEdit, className = '' }) => {
  const classes = useStyles();
  if (!isVisible) return null;
  return (
    <div className={cx(classes.formActions, className)}>
      <Button size="small" className={classes.editBtn} onClick={onEdit}>
        <CreateIcon className="mr-1" color="inherit" />
        <Typography className={classes.editText} color="inherit">
          Edit
        </Typography>
      </Button>
    </div>
  );
};

EditCardButton.propTypes = {
  isVisible: PropTypes.bool, //  if the participant can see the edit label, then - true
  onEdit: PropTypes.func, // edit card handler
  className: PropTypes.string,
};

export default EditCardButton;
