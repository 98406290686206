import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { isLoaded, isEmpty } from 'react-redux-firebase';
import { map, filter, sortBy } from 'lodash';
import { makeStyles } from '@material-ui/styles';

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Typography from '@material-ui/core/Typography';
import PageContent from 'components/PageContent/PageContent';
import PageContentHeader from 'components/PageContent/PageContentHeader';
import PageContentBody from 'components/PageContent/PageContentBody';
import Button from 'components/CustomButtons/Button.js';
import VideocamIcon from '@material-ui/icons/Videocam';

// custom components
import SessionCard from 'components/SessionCard/SessionCard';
import CreateSessionDialog from 'components/Dialogs/CreateSessionDialog';

// actions
import {
  openPremiumSubscriptionDialog,
  openCreateSessionDialog,
} from 'redux/actions/facilitatorSessionActions';

// selectors
import {
  facilitatorSubscriptionSelector,
  useFacilitatorSessionSelector,
} from 'redux/selectors/facilitatorSessionSelectors';

const useStyles = makeStyles(theme => ({
  gridItem: {
    padding: '0 10px !important',
  },
  createMeetingButton: {
    height: 36,
  },
  buttonSpinnerBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 40,
    paddingRight: theme.spacing(5),
  },
  buttonSpinner: {
    color: theme.palette.primary.main,
  },
  loadMoreContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
}));

export default function SessionsList({
  isViewingShared,
  sessionsType,
  handleLoadSessions,
  hasMoreSessions,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const sessions = useSelector(state => state.firestore.data[sessionsType]);
  const { isOpenCreateSessionDialog } = useFacilitatorSessionSelector();
  const meetingMaxSize = 5;
  const { isSubscriber } = useSelector(facilitatorSubscriptionSelector());

  // get active sessions
  const activeSessions = filter(sessions, session => !!session);
  // determines the account limit depending on the user's role
  const isUserAccountLimit =
    isSubscriber || (activeSessions.length < meetingMaxSize && !isSubscriber);

  const toggleCreateDialog = () => {
    isUserAccountLimit
      ? dispatch(openCreateSessionDialog(!isOpenCreateSessionDialog))
      : dispatch(openPremiumSubscriptionDialog(true));
  };

  const renderSessions = () => {
    if (isLoaded(sessions)) {
      let actualSessions = filter(sessions, session => {
        return session !== null;
      });

      actualSessions = sortBy(actualSessions, ['startTime']);

      if (['past-sessions', 'shared-sessions'].includes(sessionsType)) {
        actualSessions = actualSessions.reverse();
      }

      return map(actualSessions, (session, idx) => {
        return (
          <GridItem
            xs={12}
            sm={6}
            md={6}
            lg={4}
            key={`session-card-${idx}`}
            style={{ marginBottom: 30 }}
            className={classes.gridItem}
          >
            <SessionCard
              id={session.id}
              isViewingShared={isViewingShared}
              sessionsType={sessionsType}
            />
          </GridItem>
        );
      });
    }
  };

  let title = sessionsType === 'sessions' ? 'Upcoming Meetings' : 'Past Meetings';

  if (isViewingShared) {
    title = 'Shared Meetings';
  }

  return (
    <div>
      <GridContainer direction="row" justify="center" alignItems="center">
        {isLoaded(sessions) && (
          <GridItem xs={12}>
            <PageContent>
              <PageContentHeader>
                <div className="left-col" />
                <Typography variant="h5" className="page-content-title">
                  {title}
                </Typography>
                <div className="right-col">
                  {!isViewingShared && (
                    <Button
                      color="primary"
                      onClick={toggleCreateDialog}
                      size="sm"
                      className={classes.createMeetingButton}
                    >
                      CREATE MEETING
                    </Button>
                  )}
                </div>
              </PageContentHeader>
              <PageContentBody>
                <GridContainer className="w-100 m-0">
                  {isEmpty(sessions) ? (
                    <Typography style={{ fontSize: 18 }} className="w-100 text-center ml-2 mt-4">
                      {isViewingShared ? 'No Shared Meetings' : 'No Upcoming Meetings'}
                    </Typography>
                  ) : (
                    <>
                      {renderSessions()}
                      {hasMoreSessions && (
                        <div className={classes.loadMoreContainer}>
                          <Button color="primary" onClick={handleLoadSessions}>
                            Load More
                          </Button>
                        </div>
                      )}
                    </>
                  )}
                </GridContainer>
              </PageContentBody>
            </PageContent>
          </GridItem>
        )}
      </GridContainer>
      <CreateSessionDialog />
    </div>
  );
}

SessionsList.propTypes = {
  isViewingShared: PropTypes.bool,
  sessionsType: PropTypes.oneOf(['sessions', 'shared-sessions', 'past-sessions']),
};
