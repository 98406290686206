import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import cx from 'classnames';
import { map } from 'lodash';
// library components
import { ButtonBase, Typography, Box } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
// cr components
import Option from './Option';

const useStyles = makeStyles(theme => ({
  option: {
    background: grey[200],
    borderRadius: theme.shape.borderRadius,
    padding: `${theme.spacing(1)}px ${theme.spacing(1.5)}px`,
    fontSize: 15,
    display: 'flex',
    alignSelf: 'start',
    minHeight: 40,
  },
  addOptionBtn: {
    background: theme.palette.primary.lightest,
    color: theme.palette.primary.main,
    alignSelf: 'start',
  },
  optionCloud: {
    display: 'flex',
    justifyContent: ({ center }) => (center ? 'center' : 'flex-start'),
    flexWrap: 'wrap',
    marginBottom: theme.spacing(1),
  },
}));

/**
 * Renders and options 'cloud' where options can be added, edited, and delted
 */
const OptionCloud = ({
  onCreate,
  onEdit,
  onDelete,
  allowNew,
  options,
  readonly,
  center,
  className,
}) => {
  const classes = useStyles({ center });
  const [isAdding, setIsAdding] = useState(false);

  const renderOptions = () =>
    map(options, option => (
      <Option
        key={option.id}
        option={option}
        className={classes.option}
        onEdit={onEdit}
        onDelete={onDelete}
        readonly={readonly}
      />
    ));

  return (
    <Box className={cx(classes.optionCloud, className)}>
      {renderOptions()}
      {isAdding ? (
        <Option
          isNew
          onCreate={onCreate}
          onCancelAdding={() => {
            setIsAdding(false);
          }}
          className={classes.option}
        />
      ) : (
        <>
          {allowNew && !readonly && (
            <ButtonBase
              className={cx(classes.option, classes.addOptionBtn)}
              onClick={() => {
                setIsAdding(true);
              }}
            >
              <Typography color="inherit" className="mr-1">
                Add
              </Typography>
              <AddCircleIcon color="inherit" style={{ fontSize: 16 }} />
            </ButtonBase>
          )}
        </>
      )}
    </Box>
  );
};

OptionCloud.defaultProps = {
  allowNew: true,
  onCreate: () => {
    alert('No onCreate function was passed');
  },
  onEdit: () => {
    alert('No onEdit function was passed');
  },
  onDelete: () => {
    alert('No onDelete function was passed');
  },
};

OptionCloud.propTypes = {
  // determines if new options can be added
  allowNew: PropTypes.bool,
  // apply justifyContent: 'center' to the options
  center: PropTypes.bool,
  onCreate: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  // the array of options to render
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  // determines if the options can be edited or added to
  readonly: PropTypes.bool,
};

export default OptionCloud;
