import React, { useState } from 'react';
import { reduce, map } from 'lodash';
import PropTypes from 'prop-types';
// library components
import { grey } from '@material-ui/core/colors';
import { makeStyles, Box, Typography, Divider, IconButton } from '@material-ui/core';
import ChartistGraph from 'react-chartist';
import Chartist from 'chartist';
import 'chartist-plugin-legend';
import CommentIcon from '@material-ui/icons/Comment';
// cr components
import FormDialog from 'components/Dialogs/FormDialog';
import CommentsModal from './CommentsModal';

const useStyles = makeStyles(theme => ({
  question: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  questionText: {
    width: '90%',
    lineHeight: '16px',
    fontSize: 14,
    fontWeight: 300,
    opacity: 0.9,
  },
  number: {
    paddingRight: theme.spacing(1),
    color: theme.palette.primary.main,
    lineHeight: '16px',
    fontSize: 14,
    fontWeight: 500,
  },
  line: {
    width: '96%',
    margin: `${theme.spacing(2)}px auto`,
    border: `1px solid ${grey[100]}`,
  },
  chartBox: {
    position: 'relative',
    left: 15,
    width: '60%',
    height: 47,
    paddingTop: theme.spacing(1),
  },
  ctLegend: {
    bottom: -5,
  },
  questionBox: {
    display: 'flex',
    alignItems: 'center',
    height: 47,
  },
  commentIcon: {
    color: theme.palette.primary.main,
    fontSize: 20,
  },
  iconButton: {
    visibility: ({ questionComments }) => !questionComments && 'hidden',
  },
}));

/**
 * Renders questions and results (graphs) for question
 */
const QuestionResults = ({
  number,
  question,
  participantAnswers,
  showLegend,
  context,
  questionComments,
}) => {
  const classes = useStyles({ questionComments });
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);

  // the type of answers
  const answers = ['Yes', 'Unclear', 'No', 'N/A'];

  // number of answers of a certain type
  const answersCount = map(answers, answer => ({
    [answer]: reduce(
      participantAnswers,
      (acc, participantAnswer) => {
        if (answer === participantAnswer) {
          acc++;
        }
        return acc;
      },
      0,
    ),
  }));

  const allAnswersData = map(answersCount, answerCount => {
    return map(answerCount, answer => answer);
  });

  // optiions for chart
  let options = {
    horizontalBars: true,
    stackBars: true,
    height: '36px',
    fullWidth: true,
    showGridBackground: false,
    axisY: {
      showLabel: false,
      showGrid: false,
    },
    axisX: {
      showLabel: false,
      showGrid: false,
    },
  };

  // the legend for the graph is displayed for the first question
  if (number === 1 && showLegend) {
    options = {
      ...options,
      plugins: [
        Chartist.plugins.legend({
          legendNames: answers,
        }),
      ],
    };
  }

  // chart data
  const data = {
    labels: ['answers'],
    series: allAnswersData,
  };

  const toggleCommentModal = () => {
    setIsCommentModalOpen(prev => !prev);
  };

  return (
    <>
      <Box display="flex" flexDirection="column">
        <Box className={classes.questionBox}>
          <Typography className={classes.number}>{`#${number} `}</Typography>
          {(context === 'resultsAdmin' || context === 'results') && (
            <IconButton className={classes.iconButton} onClick={toggleCommentModal}>
              <CommentIcon className={classes.commentIcon} />
            </IconButton>
          )}
          <Divider orientation="vertical" flexItem className="mr-1 ml-1" />
          <Typography className={classes.questionText}>{`${question}`}</Typography>
          <Box
            className={classes.chartBox}
            display="flex"
            alignItems="start"
            justifyContent="flex-end"
          >
            <ChartistGraph className={'ct-tester-graph'} data={data} options={options} type="Bar" />
          </Box>
        </Box>
        <Divider />
      </Box>
      {questionComments && (
        <FormDialog
          handleClose={toggleCommentModal}
          isOpen={isCommentModalOpen}
          title="Question Comments"
        >
          <CommentsModal question={question} comments={questionComments.comments} />
        </FormDialog>
      )}
    </>
  );
};

QuestionResults.propTypes = {
  number: PropTypes.number.isRequired, // the number of question
  question: PropTypes.string.isRequired, // the question text
  participantAnswers: PropTypes.arrayOf(PropTypes.string), // all participants answers
  showLegend: PropTypes.bool, // true - the legend for the graph is displayed
  context: PropTypes.string, // the current activity active view
  questionComments: PropTypes.object, // comments for all questions
};

export default QuestionResults;
