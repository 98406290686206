import { random, keyBy, take, takeRight } from 'lodash';
import { generateRandomId } from '../generateRandomId';

export function assignParticipantsToRooms({ participants, rooms, assignments, isShuffle }) {
  let currentAssignments = isShuffle ? [] : [...assignments];
  const previousAssignments = [...assignments]; // only used when shuffling

  for (let i = 0; i < participants.length; i++) {
    // number of participants in each room by room index
    const roomNumParticipants = rooms.map(
      room => currentAssignments.filter(assignment => assignment.roomId === room.id).length,
    );
    // lowest number of participants in any room
    const minParticipants = Math.min.apply(null, roomNumParticipants);
    // room pool to place the next participant in based on how many participants are in each room
    const roomPool = rooms.filter(
      room =>
        currentAssignments.filter(assignment => assignment.roomId === room.id).length ===
        minParticipants,
    );

    roomPool.forEach(room => {
      const participantPool = participants.filter(participant => {
        const isAssigned = currentAssignments.find(assignment => {
          return assignment.participantId === participant.id;
        });
        return !isAssigned;
      });

      if (!participantPool.length) {
        return currentAssignments;
      }

      const participantIndex = getRandomParticipantIndex(participantPool);
      const randomParticipant = participantPool[participantIndex];

      if (isShuffle) {
        const participantPreviouslyAssignedToRoom = !!previousAssignments.find(prevAssignment => {
          return (
            prevAssignment.roomId === room.id &&
            prevAssignment.participantId === randomParticipant.id
          );
        });

        if (participantPreviouslyAssignedToRoom) return;
      }

      currentAssignments.push({
        participantId: randomParticipant.id,
        roomId: room.id,
      });
    });
  }

  return currentAssignments;
}

function getRandomParticipantIndex(participantPool) {
  return random(0, participantPool.length - 1);
}

/**
 * Split breakout rooms x2. Participants are divided into the new
 * rooms but are kept with participants from the room they were in
 *
 * @param {object} breakout
 * @param {object} roomMap
 */
export const splitBreakouts = (breakout, roomMap) => {
  const currentRooms = breakout.rooms;
  const updatedRooms = [];
  const updatedParticipantRoomAssignments = [];

  currentRooms.forEach(room => {
    const mappedRoom = roomMap[room.id];
    const extraParticipants = mappedRoom.participants.length % 2;
    const participantsPerRoom = Math.floor(mappedRoom.participants.length / 2);
    const newRoomId = generateRandomId();

    const originalRoomParticipants = extraParticipants
      ? take(mappedRoom.participants, participantsPerRoom + 1)
      : take(mappedRoom.participants, participantsPerRoom);

    originalRoomParticipants.forEach(participantId => {
      updatedParticipantRoomAssignments.push({ participantId, roomId: room.id });
    });

    const newRoomParticipants = takeRight(mappedRoom.participants, participantsPerRoom);

    newRoomParticipants.forEach(participantId => {
      updatedParticipantRoomAssignments.push({ participantId, roomId: newRoomId });
    });

    // separate titles if they include the pipe character
    const titles = room.title.includes(' | ') ? room.title.split(' | ') : [room.title, room.title];

    // separate descriptions if they include the pipe character
    const descriptions = room.description.includes(' | ')
      ? room.description.split(' | ')
      : [room.description, room.description];

    updatedRooms.push({
      id: room.id,
      title: titles[0],
      description: descriptions[0],
    });

    updatedRooms.push({
      id: newRoomId,
      title: titles[1],
      description: descriptions[1],
    });
  });

  return {
    rooms: updatedRooms,
    participantRoomAssignments: updatedParticipantRoomAssignments,
  };
};

/**
 * Merge rooms into half the number of current rooms.  Participants will
 * be kept together while merging
 *
 * @param {object} breakout
 * @param {object} roomMap
 */
export const mergeBreakouts = (breakout, roomMap) => {
  const currentRooms = breakout.rooms;
  const updatedRooms = [];
  const updatedParticipantRoomAssignments = [];

  currentRooms.forEach((room, idx) => {
    const mappedRoom = roomMap[room.id];

    if (!mappedRoom.participants) return;

    const nextRoom = currentRooms[idx + 1];
    const isLast = currentRooms.length - 1 === idx;

    /**
     * Figure out if the idx is even or 0 because we are going
     * to merge the odd idx rooms into the even ones
     */
    const idxIsEven = idx % 2 === 0;

    /**
     * This room keeps it's participants.  The next odd idx room will
     * merge into it
     */
    if (idxIsEven && !isLast) {
      let updatedRoom = { ...room };

      if (room.title !== nextRoom.title) {
        updatedRoom.title = `${room.title} | ${nextRoom.title}`;
      }

      if (room.description !== nextRoom.description) {
        updatedRoom.description = `${room.description} | ${nextRoom.description}`;
      }

      updatedRooms.push(updatedRoom);

      mappedRoom.participants.forEach(participantId => {
        updatedParticipantRoomAssignments.push({ participantId, roomId: room.id });
      });

      return;
    }

    /**
     * This is the odd room out. Merge this with the last room in the
     * updatedRooms array
     */
    if (idxIsEven && isLast) {
      const roomToMergeTo = updatedRooms[updatedRooms.length - 1];

      roomToMergeTo.description = `${roomToMergeTo.description} | ${room.description}`;

      roomToMergeTo.title = `${roomToMergeTo.title} | ${room.title}`;

      mappedRoom.participants.forEach(participantId => {
        updatedParticipantRoomAssignments.push({ participantId, roomId: roomToMergeTo.id });
      });

      return;
    }

    /**
     * if the idx is an odd number, and this isn't the last of the current rooms,
     * just put the participants in the room with the previous even idx
     */
    mappedRoom.participants.forEach(participantId => {
      updatedParticipantRoomAssignments.push({ participantId, roomId: currentRooms[idx - 1].id });
    });
  });

  return {
    rooms: updatedRooms,
    participantRoomAssignments: updatedParticipantRoomAssignments,
  };
};
