import React from 'react';
import { useSelector } from 'react-redux';

// cr components
import ActivityTemplate from '../../../components/ActivityTemplate/ActivityTemplate';

// utils
import { linkifyHtmlString } from 'utils/renderHtmlString';

const ParticipantWelcome = () => {
  const session = useSelector(state => state.firestore.data.session);

  return (
    <ActivityTemplate type="Welcome" name="" contentCenter={false}>
      {session.welcomeMessage ? (
        <div
          className="ck-content clearfix"
          dangerouslySetInnerHTML={{ __html: linkifyHtmlString(session.welcomeMessage) }}
        />
      ) : (
        <p style={{ fontSize: 18 }}>Welcome! Your facilitator will start the session soon.</p>
      )}
    </ActivityTemplate>
  );
};

export default ParticipantWelcome;
