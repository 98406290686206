import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
// library components
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { useDropzone } from 'react-dropzone';

const useStyles = makeStyles(theme => ({
  uploadContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: 650,
    height: 400,
    border: '1px dashed #CFCFCF',
    borderRadius: 4,
    cursor: 'pointer',
  },
  uploadContainerDrag: {
    border: `2px dashed #CFCFCF !important`,
    background: 'rgba(0,0,0,.1)',
  },
  icon: {
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
    fontSize: 40,
  },
  text: {
    color: theme.palette.primary.main,
    fontSize: 18,
  },
  textDisabled: {
    color: grey[500],
    fontSize: 18,
  },
}));

const UploadImage = ({ onDrop }) => {
  const classes = useStyles();
  const inputRef = useRef();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div {...getRootProps()}>
      <input
        {...getInputProps()}
        accept="image/*"
        className={classes.input}
        id="contained-button-file"
        type="file"
        ref={inputRef}
      />
      <label htmlFor="contained-button-file">
        <Box className={cx(classes.uploadContainer, isDragActive && classes.uploadContainerDrag)}>
          <AddCircleIcon className={classes.icon} />
          <Typography className={classes.text}>Select Photo/Image</Typography>
          <Typography className={classes.textDisabled}>(Or drop in this area)</Typography>
        </Box>
      </label>
    </div>
  );
};

UploadImage.propTypes = {
  onDrop: PropTypes.func.isRequired, // drop image to container to upload
};

export default UploadImage;
