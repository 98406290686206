import React from 'react';
import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';
import { map } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
// 3rd party components
import {
  Checkbox,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormGroup,
  Box,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  label: {
    marginBottom: 4,
  },
  root: {
    marginBottom: theme.spacing(2),
  },
  checkboxGroup: ({ stacked }) => ({
    display: 'flex',
    flexDirection: stacked ? 'column' : 'row',
    flexWrap: 'wrap',
  }),
  checkboxLabel: {
    color: '#000000',
  },
}));

/**
 * renders a checkbox group that stores the checked values as an array
 */
const MultiSelectCheckboxGroup = ({ name, label, values, disabled, stacked, required }) => {
  const [field, meta] = useField(name);
  const { setFieldValue, submitCount } = useFormikContext();
  const classes = useStyles({ stacked });
  const { error, touched } = meta;
  const hasError = (error && touched) || (submitCount && error) ? true : false;

  const checkboxes = map(values, (value, index) => {
    let optionValue, optionLabel;

    // check if value is an object with a label key
    if (value.label) {
      optionLabel = value.label;
      optionValue = value.value;
    } else {
      optionLabel = value;
      optionValue = value;
    }

    const handleChange = event => {
      const arr = [...field.value];
      if (event.target.checked) {
        arr.push(value);
      } else {
        arr.splice(arr.indexOf(value), 1);
      }
      setFieldValue(name, arr);
    };

    const checked = field.value.includes(value);

    return (
      <div key={`checkbox-${index}`}>
        <FormControlLabel
          className={classes.checkboxLabel}
          control={
            <Checkbox
              name={optionValue}
              checked={checked}
              onChange={handleChange}
              disabled={disabled}
              color="primary"
            />
          }
          label={optionLabel}
        />
      </div>
    );
  });

  return (
    <div>
      <FormControl required={required} error={hasError} component="fieldset">
        {label && (
          <FormLabel component="legend" className={classes.label}>
            {label}
          </FormLabel>
        )}
        <FormGroup className={classes.checkboxGroup}>{checkboxes}</FormGroup>
        {hasError ? (
          <Box component="span" color="error.main">
            {error}
          </Box>
        ) : null}
      </FormControl>
    </div>
  );
};

MultiSelectCheckboxGroup.propTypes = {
  // The name of field.
  name: PropTypes.string.isRequired,
  // The title of field.
  label: PropTypes.string.isRequired,
  // Contains radio button values.
  values: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ]),
  ).isRequired,
  // The field is disabled
  disabled: PropTypes.bool,
  // Render options vertically
  stacked: PropTypes.bool,
};

export default MultiSelectCheckboxGroup;
