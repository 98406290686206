import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { throttle } from 'lodash';
import { useDispatch } from 'react-redux';
// library components
import { Formik, Form } from 'formik';
import { Box, makeStyles } from '@material-ui/core';
// cr components
import TextEditor from 'components/CustomFormComponents/TextEditor';
import Lock from 'components/LiveScribing/Admin/Lock';
// actions
import { updateLiveScribingText } from 'redux/actions/liveScribingActions';

const useStyles = makeStyles({
  liveScibing: {
    position: 'relative',
  },
  lock: {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, 0)',
  },
  lockBox: {
    position: 'absolute',
    top: 0,
    minHeight: '239px',
    height: '100%',
    width: '100%',
    opacity: 0.5,
    background: '#e3e3e3',
    zIndex: 5,
  },
});

const LiveScribing = ({ id, sessionId, liveScribingText, isTabDisabled }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const update = useCallback(
    throttle(submitText => {
      console.log('submit', `submit ${Math.floor(Date.now() / 1000)}`);
      dispatch(updateLiveScribingText(id, submitText));
    }, 5000),
    [],
  );

  return (
    <Box className={classes.liveScibing}>
      <Formik
        initialValues={{ liveScribingText }}
        enableReinitialize
        onSubmit={values => {
          if (values.liveScribingText !== liveScribingText) {
            update(values.liveScribingText);
          }
        }}
      >
        {({ submitForm }) => (
          <Form onKeyUp={submitForm} onBlur={submitForm}>
            <TextEditor name="liveScribingText" label="" className="mb-2" sessionId={sessionId} />
          </Form>
        )}
      </Formik>
      {isTabDisabled && (
        <>
          <Box className={classes.lockBox} />
          <Lock className={classes.lock} />
        </>
      )}
    </Box>
  );
};

LiveScribing.propTypes = {
  id: PropTypes.string.isRequired, // id of liveScribing session
  sessionId: PropTypes.string.isRequired, // id of session
  liveScribingText: PropTypes.string, // live scribing text
  isTabDisabled: PropTypes.bool, // disabled live scribing field for other windows
  userId: PropTypes.string.isRequired, // user id
};

export default LiveScribing;
