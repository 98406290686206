import React from 'react';
import PropTypes from 'prop-types';
import { useFirestore } from 'react-redux-firebase';

import { Formik, Form } from 'formik';
import { Button } from '@material-ui/core';

import ButtonSpinner from 'components/ButtonSpinner/ButtonSpinner';
import TextArea from 'components/CustomFormComponents/TextArea';
import TextEditor from 'components/CustomFormComponents/TextEditor';
import * as Yup from 'yup';

const formSchema = Yup.object().shape({
  welcomeMessage: Yup.string().required('Required'),
});

const WelcomeMessageForm = ({ initialValues, setIsEditing, sessionId }) => {
  const db = useFirestore();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={formSchema}
      onSubmit={values => {
        db.doc(`/sessions/${sessionId}`)
          .update({
            welcomeMessage: values.welcomeMessage,
          })
          .then(data => {
            console.log('res', data);
            setIsEditing(false);
          })
          .catch(err => {
            console.log(err);
          });
      }}
    >
      {({ isSubmitting, submitForm, values, resetForm }) => (
        <Form style={{ paddingBottom: 20 }}>
          <TextEditor
            name="welcomeMessage"
            label="Message"
            // placeholder="Write a message to show your participants when joing the session. Your message can include links and line breaks"
            className="mb-2"
            sessionId={sessionId}
          />

          <div className="form-dialog-buttons">
            <Button
              className="mr-2"
              variant="contained"
              color="primary"
              size="small"
              disabled={isSubmitting}
              onClick={submitForm}
            >
              Save
              {isSubmitting && <ButtonSpinner size="small" />}
            </Button>
            <Button
              size="small"
              variant="outlined"
              color="primary"
              disabled={isSubmitting}
              onClick={() => {
                resetForm();
                setIsEditing(false);
              }}
            >
              CANCEL
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

WelcomeMessageForm.propTypes = {
  initialValues: PropTypes.shape({
    welcomeMessage: PropTypes.string,
  }),
  setIsEditing: PropTypes.func.isRequired,
  sessionId: PropTypes.string.isRequired,
};

export default WelcomeMessageForm;
