import React from 'react';
import PropTypes from 'prop-types';
// library components
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles, Button } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  button: {
    color: theme.palette.error.main,
    fontSize: 15,
    fontWeight: 400,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
}));

const DeleteSetButton = ({ handleDeleteSet }) => {
  const classes = useStyles();

  return (
    <Button className={classes.button} startIcon={<DeleteIcon />} onClick={handleDeleteSet}>
      Delete Set
    </Button>
  );
};

DeleteSetButton.propTypes = {
  handleDeleteSet: PropTypes.func.isRequired, // the function processes pressing the "Delete set" button and deletes the set and the cards
};

export default DeleteSetButton;
