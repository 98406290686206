import React, { useEffect, useRef } from 'react';
import { useFirestore } from 'react-redux-firebase';
import { Form, Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { includes, keys } from 'lodash';
import cx from 'classnames';
// library components
import { Button } from '@material-ui/core';
// cr components;
import TextEditor from 'components/CustomFormComponents/TextEditor';
import CustomButton from 'components/CustomButton/CustomButton';
import ButtonSpinner from 'components/ButtonSpinner';
// selectors
import { useBrainwritingSelector } from 'redux/selectors/brainwritingSelectors';
import { localStorageSetObject, localStorageGetObject } from '../../../utils/localStorageUtils';
// actions
import { enqueueErrorSnackbar } from 'redux/actions/notifierActions';

const BrainwritingParticipantEdit = ({ setIsEditing, handleEditClick }) => {
  const db = useFirestore();
  const formRef = useRef();
  const dispatch = useDispatch();
  const { id, currentRound, ideas, rounds, participants, isTransitioning } =
    useBrainwritingSelector();
  const localStorageKey = `brainWriting_${id}_currentSubmission`;
  const participantsCount = participants.length;
  const participantId = useSelector(state => state.firebase.auth.uid);
  const activityRef = db.doc(`brainwriting/${id}`);
  const isFirstSubmission = currentRound === 1 || !includes(keys(ideas), participantId);
  const alreadySubmittedThisRound = rounds[currentRound].assignments[participantId]?.submitted;
  const session = useSelector(state => state.firestore.data.session);
  let previousSubmission = localStorageGetObject(localStorageKey) ?? {
    currentRound: '',
    currentAssignmentText: '',
    currentSubmission: '',
  };

  // add participant to activity
  useEffect(() => {
    if (!includes(participants, participantId)) {
      activityRef
        .set(
          {
            participants: db.FieldValue.arrayUnion(participantId),
          },
          { merge: true },
        )
        .catch(err => {
          console.log(err);
        });
    }
  }, [participantsCount]);

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    const currentSubmissionUpdated = values.text;
    setSubmitting(true);
    localStorageSetObject(localStorageKey, {
      ...previousSubmission,
      currentSubmission: currentSubmissionUpdated,
    });
    const ideaId = rounds[currentRound].assignments[participantId].idea;
    const newText = `${previousSubmission.currentAssignmentText ?? ''}\n─ Round ${currentRound} ─\n${currentSubmissionUpdated}`;

    let update = {
      ...ideas[ideaId],
      text: newText,
      contributors: db.FieldValue.arrayUnion(participantId),
    };

    activityRef
      .set(
        {
          ideas: {
            [ideaId]: { ...update },
          },
          rounds: {
            [currentRound]: {
              assignments: {
                [participantId]: {
                  submitted: true,
                },
              },
            },
          },
        },
        { merge: true },
      )
      .then(() => {
        setSubmitting(false);
        setIsEditing(false);
        resetForm();
      })
      .catch(err => {
        console.log(err);
        dispatch(enqueueErrorSnackbar('Error submitting'));
        setSubmitting(false);
      });
  };

  const cancelEdit = () => handleEditClick(false);

  const renderLabel = () => {
    if (alreadySubmittedThisRound) return '';

    if (isFirstSubmission) {
      return 'Based on the prompt above, type in your thoughts/ideas below. When done hit the submit button.';
    }

    return 'Read the thoughts/ideas shared from others in the area above. Type in your contributions in the text box below. Remember to “yes, and” what you read above. When you are done hit the submit button.';
  };

  return (
    <Formik
      initialValues={{
        text: previousSubmission.currentSubmission,
      }}
      onSubmit={handleSubmit}
      validationSchema={Yup.object().shape({
        text: Yup.string(),
      })}
      innerRef={formRef}
    >
      {({ values, submitForm, isSubmitting }) => (
        <>
          <Form className={cx('w-100', { 'd-none': isTransitioning })} style={{ minHeight: 400 }}>
            <TextEditor
              name="text"
              label={renderLabel()}
              sessionId={session.id}
              toolbar={[
                '|',
                'alignment',
                'fontColor',
                'fontBackgroundColor',
                'fontSize',
                'imageUpload',
                'bulletedList',
                'numberedList',
                'bold',
                'underline',
                'italic',
                'undo',
                'redo',
              ]}
            />
            <div className="my-4 text-center buttonContainer">
              <Button variant="contained" onClick={cancelEdit}>
                Cancel
              </Button>
              <CustomButton onClick={submitForm} disabled={!values.text || isSubmitting}>
                Submit
                {isSubmitting && <ButtonSpinner />}
              </CustomButton>
            </div>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default BrainwritingParticipantEdit;
