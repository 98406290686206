import React from 'react';
import { useSelector } from 'react-redux';
import { map, isEqual } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
// 3rd party components
import { Card, CardContent, Typography } from '@material-ui/core';
// selectors
import { getQuestion } from 'redux/selectors/assessmentSelectors';

const useStyles = makeStyles(theme => ({
  masonryContainer: {
    columnCount: '3',
    gridColumnGap: '1.5rem',
    columnGap: '1.5rem',
    orphans: 1,
    widows: 1,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      columnCount: '2',
    },
    [theme.breakpoints.down('xs')]: {
      columnCount: '1',
      gridColumnGap: '1.25rem',
      columnGap: '1.25rem',
    },
    [theme.breakpoints.up('xl')]: {
      columnCount: '4',
    },
  },
  card: {
    border: '1px solid #D6D6D6',
    marginBottom: '1.5rem',
    display: 'inline-block',
    width: '100%',
    // boxShadow: '0px 5px 10px 0px rgba(0,0,0,0.10)',
    [theme.breakpoints.down('xs')]: {
      columnCount: '1',
      marginBottom: '1.25rem',
    },
  },
  cardContent: {
    padding: 12,
    paddingBottom: '12px !important',
  },
}));

const ShortAnswerQuestionResultsDetail = ({ questionId }) => {
  const classes = useStyles();
  const { answerCounts } = useSelector(getQuestion(questionId));

  return (
    <div className={classes.masonryContainer}>
      {map(answerCounts, (answer, idx) => (
        <Card className={classes.card} key={`answer-${idx}`}>
          <CardContent className="p-2">
            <Typography>{answer.answer}</Typography>
            <Typography color="textSecondary" className="text-right">
              - {answer.participantNames.join(', ')}
            </Typography>
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

export default React.memo(ShortAnswerQuestionResultsDetail, isEqual);
