import React from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import { grey } from '@material-ui/core/colors';
import cx from 'classnames';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
  root: {
    backgroundColor: grey[200],
    textAlign: 'center',
    padding: 30,
  },
});

const GreyBox = ({ children, className }) => {
  const classes = useStyles();
  return (
    <Paper elevation={0} className={cx(classes.root, className)}>
      {children}
    </Paper>
  );
};

export default GreyBox;
