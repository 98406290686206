import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { filter } from 'lodash';
// library components
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// cr components
import ActivityPromptAdmin from 'components/ActivityPromptAdmin';
import ActivityActionButton from 'components/CustomButtons/ActivityActionButton';
import CategoriesAdmin from 'components/Categories/CategoriesAdmin';
import ActiveCardSetAdmin from './ActiveCardSetAdmin';
// selectors
import { useNarrowingSelector, getCardsReport } from 'redux/selectors/narrowingSelectors';
// actions
import {
  updateActivityView,
  updateRound,
  updateFinishedActivity,
  updateCardsCount,
} from 'redux/actions/narrowingActions';
// config
import { NARROWING_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';

const useStyles = makeStyles(theme => ({
  promptAdmin: {
    marginBottom: theme.spacing(1),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
  },
}));

/**
 * Card Sorting view for the 'results' phase on the Facilitator side
 */
const NarrowingAdminResults = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const narrowing = useNarrowingSelector();
  const cardsReportData = useSelector(getCardsReport());
  const {
    id,
    prompt,
    config: { categories, primaryCategory, cardSet },
    state: { round, isActivityFinished },
  } = narrowing;

  const sortedCategories = filter(categories, { id: primaryCategory });

  const onUpdateActivityView = () => {
    dispatch(updateRound(id, round + 1));
    dispatch(updateCardsCount(id, 0));
    dispatch(updateActivityView(id, 'primaryCategorize'));
  };

  const onFinishedActivityView = () => {
    // reveal results
    dispatch(updateFinishedActivity(id, true, cardsReportData));
  };

  return (
    <Box className="d-flex flex-column justify-content-center">
      <ActivityPromptAdmin
        prompt={prompt}
        collection={CONFIG.collection}
        activityId={id}
        className={classes.promptAdmin}
      />
      <ActiveCardSetAdmin cardSetId={cardSet} />
      {/* <CategoriesAdmin
        categories={sortedCategories}
        primaryCategoryId={primaryCategory}
      /> */}
      {!isActivityFinished && (
        <Box className={classes.buttons}>
          <ActivityActionButton
            color="green"
            text="Done"
            onClick={onFinishedActivityView}
            delayReady
          />
          <ActivityActionButton
            text={`Start Round ${round + 1}`}
            onClick={onUpdateActivityView}
            delayReady
          />
        </Box>
      )}
    </Box>
  );
};

export default NarrowingAdminResults;
