import React, { useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { Typography, Button } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';

import ParticipantsHaveCompletedDialog from 'components/Dialogs/ParticipantsHaveCompleted';

const useStyles = makeStyles(theme => ({
  headerText: {
    fontSize: 18,
    fontWeight: '300',
  },
  button: {
    color: theme.palette.primary.main,
  },
  buttonText: {
    color: '#1976D2',
  },
  icon: {
    marginRight: theme.spacing(1) / 2,
  },
}));

/**
 * Renders the text about how many participants have completed
 * a particiular phase of the activity.  Also provides the modal
 * that opens when the View Details button is clicked.
 */
const ActivityParticipantDetails = ({
  headerText,
  hasCompletedLabel,
  hasntCompletedLabel,
  completedCountMax,
  participantsCompletedStatus,
  noDetails,
}) => {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <>
      <div className="d-flex flex-column justify-content-center align-items-center mb-5">
        <Typography className={classes.headerText}>{headerText}</Typography>
        {!noDetails && (
          <Button
            size="small"
            className={classes.button}
            onClick={() => {
              setDialogOpen(true);
            }}
          >
            <VisibilityIcon className={classes.icon} />
            <span className={classes.buttonText}>VIEW DETAILS</span>
          </Button>
        )}
      </div>
      {!noDetails && (
        <ParticipantsHaveCompletedDialog
          hasCompletedLabel={hasCompletedLabel}
          hasntCompletedLabel={hasntCompletedLabel}
          completedCountMax={completedCountMax}
          open={dialogOpen}
          closeDialog={() => {
            setDialogOpen(false);
          }}
          participantsCompletedStatus={participantsCompletedStatus}
        />
      )}
    </>
  );
};

ActivityParticipantDetails.propTypes = {
  headerText: PropTypes.string.isRequired,
  hasCompletedLabel: PropTypes.string,
  hasntCompletedLabel: PropTypes.string,
  completedCountMax: PropTypes.number,
  participantsCompletedStatus: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired, // participant id
      completed: PropTypes.bool.isRequired, // participant has completed the task that is being reported on
      completedCount: PropTypes.number, // total count of actions
    }),
  ),
  noDetails: PropTypes.bool, // only render headerText if this is true
};

export default ActivityParticipantDetails;
