import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import cx from 'classnames';
// library components
import { Typography, Paper, Box, Button } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// cr components
import InfoDialog from 'components/Dialogs/InfoDialog';
import HelpIcon from '@material-ui/icons/Help';
// selectors
import { useLiveScribingSelector } from 'redux/selectors/liveScribingSelectors';
// utils
import renderHtmlString from 'utils/renderHtmlString';
// constants
import { LIVE_SCRIBING_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';

const useStyles = makeStyles({
  title: {
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingRight: ({ smallScreen }) => !smallScreen && 100,
    fontSize: 16,
    fontWeight: 300,
  },
  liveScribingContainer: {
    width: '100%',
    minHeight: 500,
    margin: '6px 0',
    padding: 20,
    border: '1px dashed #CFCFCF',
  },
  liveScribingText: {
    fontWeight: 300,
    overflowWrap: 'break-word',
    whiteSpace: 'pre-line',
  },
});

/**
 * Live Scribing view for the 'write' phase on the Partcicipant side
 */
const LiveScribingParticipantWrite = () => {
  const smallScreen = useMediaQuery('(max-width: 600px)');
  const classes = useStyles({ smallScreen });
  const liveScribing = useLiveScribingSelector();
  const {
    state: { liveScribingText, activeView },
  } = liveScribing;

  const [isModalOpen, setIsModalOpen] = useState(false);

  // get participant help from config
  const { participantHelp } = CONFIG;
  const participantHelpData = participantHelp[activeView];

  // on open Info dialog
  const openDialog = () => {
    setIsModalOpen(true);
  };

  // on close Info dialog
  const closeDialog = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Box
        className="w-100 mb-2"
        display="flex"
        flexDirection={smallScreen ? 'column' : 'row'}
        alignItems="center"
        justifyContent="center"
        flexWrap="wrap"
      >
        <Button
          color="primary"
          size="small"
          startIcon={<HelpIcon fontSize="small" color="primary" />}
          onClick={openDialog}
        >
          Activity Help
        </Button>
        <InfoDialog
          handleClose={closeDialog}
          isOpen={isModalOpen}
          title={participantHelpData.helpTitle}
        >
          {participantHelpData.helpText}
        </InfoDialog>
        <Typography className={classes.title} align="center">
          Facilitator is live scribing
        </Typography>
      </Box>
      <Paper className={classes.liveScribingContainer} elevation={0}>
        <>
          {/* <Typography
            className={classes.liveScribingText}
            dangerouslySetInnerHTML={(renderHtmlString(liveScribingText))}
          /> */}
          <div
            className="ck-content clearfix"
            dangerouslySetInnerHTML={{ __html: liveScribingText }}
          />
        </>
      </Paper>
    </>
  );
};

export default LiveScribingParticipantWrite;
