/**
 * Sets an object to localStorage
 * @param {string} key
 * @param {object} data
 */
export const localStorageSetObject = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};

/**
 * Get and parse object from local storage
 * @param {string} key
 * @return {object|null} object if exists, otherwise null
 */
export const localStorageGetObject = key => {
  const value = localStorage.getItem(key);
  return value && JSON.parse(value);
};

/**
 * Get the local storage key where the selected facilitator route
 * for a session is stored
 * @param {string} sessionId
 */
export const getStoredFacilitatorRouteKey = sessionId => {
  return `session_${sessionId}_selectedFacilitatorRoute`;
};
