import React from 'react';
import { grey } from '@material-ui/core/colors';
import PropTypes from 'prop-types';
import cx from 'classnames';
// library components
import { makeStyles, Paper, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  answer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 124,
    height: 40,
    marginRight: theme.spacing(1),
    backgroundColor: grey[200],
    textAlign: 'center',
    cursor: 'pointer',
  },
  answerYes: {
    border: ({ isAnswerSelected, value }) =>
      isAnswerSelected && value === 'Yes' && `1px solid ${theme.palette.success.main}`,
    background: ({ isAnswerSelected, value }) => isAnswerSelected && value === 'Yes' && '#BFE9D7',
  },
  answerNo: {
    border: ({ isAnswerSelected, value }) =>
      isAnswerSelected && value === 'No' && `1px solid ${theme.palette.error.main}`,
    background: ({ isAnswerSelected, value }) => isAnswerSelected && value === 'No' && '#FFCBC5',
  },
  answerUnclear: {
    border: ({ isAnswerSelected, value }) =>
      isAnswerSelected && value === 'Unclear' && `1px solid #FDC300`,
    background: ({ isAnswerSelected, value }) =>
      isAnswerSelected && value === 'Unclear' && '#FFECAE',
  },
  answerNA: {
    border: ({ isAnswerSelected, value }) =>
      isAnswerSelected && value === 'N/A' && `1px solid ${grey[800]}`,
    background: ({ isAnswerSelected, value }) => isAnswerSelected && value === 'N/A' && grey[400],
  },
  value: {
    fontSize: 15,
  },
}));

/**
 * Renders answer to the question
 */
const Answer = ({
  questionId,
  isAnswerSelected,
  value,
  handleParticipantSelectAnswer,
  handleParticipantRemoveAnswer,
}) => {
  const classes = useStyles({ isAnswerSelected, value });

  const onSelectAnswer = () => {
    if (isAnswerSelected) {
      // if answer selected
      handleParticipantRemoveAnswer(questionId);
    } else {
      // if answer not selected
      handleParticipantSelectAnswer(questionId, value);
    }
  };

  return (
    <Paper
      elevation={0}
      className={cx(
        classes.answer,
        classes.answerYes,
        classes.answerNo,
        classes.answerUnclear,
        classes.answerNA,
      )}
      onClick={onSelectAnswer}
    >
      <Typography className={classes.value} align="center">
        {value}
      </Typography>
    </Paper>
  );
};

Answer.propTypes = {
  questionId: PropTypes.string.isRequired, // the question id
  isAnswerSelected: PropTypes.bool.isRequired, // the participant selected answer for current question
  value: PropTypes.string, // answer value
  handleParticipantSelectAnswer: PropTypes.func, // if the participant click on answer, which not selected
  handleParticipantRemoveAnswer: PropTypes.func, // if the participant click on answer, which selected
};

export default Answer;
