import React from 'react';
import PropTypes from 'prop-types';
// library components
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { makeStyles, Button } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  button: {
    color: theme.palette.primary.main,
    fontSize: 15,
    fontWeight: 400,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
}));

const DuplicateSetButton = ({ handleDuplicateSet }) => {
  const classes = useStyles();

  return (
    <Button className={classes.button} startIcon={<FileCopyIcon />} onClick={handleDuplicateSet}>
      Duplicate Set
    </Button>
  );
};

DuplicateSetButton.propTypes = {
  handleDuplicateSet: PropTypes.func.isRequired, // the function processes the "Duplicate set" button and create new set with duplicate cards and name
};

export default DuplicateSetButton;
