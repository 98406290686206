import React from 'react';
import { Typography } from '@material-ui/core';
import {
  FACILITATOR_SHOW_VIDEO,
  SET_SELECTED_FACILITATOR_ROUTE,
  IS_OPEN_PREMIUM_SUBSCRIPTION_DIALOG,
  IS_OPEN_FACILITATOR_ACCOUNT_SETTINGS_DIALOG,
  IS_OPEN_CREATE_MEETING_DIALOG,
  IS_OPEN_MURAL_AUTH_DIALOG,
  SHOW_APP_OVERLAY_SPINNER,
  IS_OPEN_INFO_DIALOG,
  SET_INFO_DIALOG_CONTENT,
  CLOSE_INFO_DIALOG,
} from '../types/facilitatorSessionTypes';
import { ACTIVITIES } from 'constants/activities';
import { getStoredFacilitatorRouteKey } from 'utils/localStorageUtils';
import { enqueueErrorSnackbar } from './notifierActions';

/**
 * Shows or hides the facilitator meeting video
 * @param {bool} showVideo
 */
export const updateShowVideo = showVideo => {
  return {
    type: FACILITATOR_SHOW_VIDEO,
    payload: showVideo,
  };
};

/**
 * Set the selected facilitator route
 * @param {string} selectedRoute
 * @param {string} sessionId
 */
export const setSelectedFacilitatorRoute = (selectedRoute, sessionId) => (dispatch, getState) => {
  localStorage.setItem(getStoredFacilitatorRouteKey(sessionId), selectedRoute);

  dispatch({
    type: SET_SELECTED_FACILITATOR_ROUTE,
    payload: selectedRoute,
  });
};

/**
 * Set premium subscription dialog is open or close
 * @param {boolean} isOpenPremiumDialog
 */
export const openPremiumSubscriptionDialog = isOpenPremiumDialog => {
  return {
    type: IS_OPEN_PREMIUM_SUBSCRIPTION_DIALOG,
    payload: isOpenPremiumDialog,
  };
};

/**
 * Set global info dialog open or closed
 * @param {boolean} isOpenInfoDialog
 */
export const openInfoDialog = isOpenInfoDialog => {
  return {
    type: IS_OPEN_INFO_DIALOG,
    payload: isOpenInfoDialog,
  };
};

/**
 * Close and clear the info dialog
 */
export const closeInfoDialog = () => {
  return {
    type: CLOSE_INFO_DIALOG,
  };
};

/**
 * Set global info dialog content
 * @param {string} title
 * @param {string} content // html string
 */
export const setInfoDialogContent = ({ title, content }) => {
  return {
    type: SET_INFO_DIALOG_CONTENT,
    payload: { title, content },
  };
};

/**
 * Set app overlay spinner to show or not
 * @param {boolean} showAppOverlaySpinner
 */
export const showAppOverlaySpinner = showAppOverlaySpinner => {
  return {
    type: SHOW_APP_OVERLAY_SPINNER,
    payload: showAppOverlaySpinner,
  };
};

/**
 * Set account settings dialog open or closed
 * @param {boolean} isOpenAccountSettingsDialog
 */
export const openFacilitatorAccountSettingsDialog = isOpenAccountSettingsDialog => {
  return {
    type: IS_OPEN_FACILITATOR_ACCOUNT_SETTINGS_DIALOG,
    payload: isOpenAccountSettingsDialog,
  };
};

/**
 * Set account settings dialog open or closed
 * @param {boolean} isOpenCreateSessionDialog
 */
export const openMuralAuthDialog = isOpenMuralAuthDialog => {
  return {
    type: IS_OPEN_MURAL_AUTH_DIALOG,
    payload: isOpenMuralAuthDialog,
  };
};

/**
 * Set account settings dialog open or closed
 * @param {boolean} isOpenCreateSessionDialog
 */
export const openCreateSessionDialog = isOpenCreateSessionDialog => {
  return {
    type: IS_OPEN_CREATE_MEETING_DIALOG,
    payload: isOpenCreateSessionDialog,
  };
};

/**
 * Export a recap deck of the meeting to Google Slides
 *
 * @param {string} sessionId
 */
export const exportRecapDeck =
  sessionId =>
  async (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    dispatch(showAppOverlaySpinner(true));

    try {
      const request = await firebase.functions().httpsCallable('sessions-exportToGoogleSlides')({
        sessionId,
        ACTIVITIES,
      });

      dispatch(
        setInfoDialogContent({
          title: 'Export Complete',
          content: () => (
            <Typography>
              <a
                href={request.data}
                class="dont-break-out"
                rel="noopener noreferrer"
                target="_blank"
              >
                {request.data}
              </a>
            </Typography>
          ),
        }),
      );

      dispatch(openInfoDialog(true));
    } catch (error) {
      console.error(error);
      dispatch(enqueueErrorSnackbar('Error running export'));
    }

    dispatch(showAppOverlaySpinner(false));
  };
