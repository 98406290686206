import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { filter, head, keys, sortBy } from 'lodash';
// library components
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// cr components
import ActivityParticipantDetails from 'components/ActivityParticipantDetails';
import ActivityPromptAdmin from 'components/ActivityPromptAdmin';
import ActivityActionButton from 'components/CustomButtons/ActivityActionButton';
// selectors
import {
  useHeatMappingSelector,
  getParticipantsCompletedReview,
} from 'redux/selectors/heatMappingSelectors';
// actions
import {
  updateActivityView,
  updateSketchCompletedHeatMapping,
  updateCurrentSketch,
} from 'redux/actions/heatMappingActions';
// config
import { HEAT_MAPPING_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';

const useStyles = makeStyles(theme => ({
  promptAdmin: {
    marginBottom: theme.spacing(5),
  },
}));

/**
 * Heat-Mapping view for the 'review' phase on the Facilitator side
 */
const HeatMappingAdminReview = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [firstSketch, setFirstSketch] = useState();
  const heatMapping = useHeatMappingSelector();
  const {
    id,
    prompt,
    participantData: { sketches },
    state: { currentSketch },
  } = heatMapping;

  let noHeatMappingSketches = sortBy(filter(sketches, { completed: false }, ['participantId']));
  const isLastSketch = noHeatMappingSketches.length === 1 || !noHeatMappingSketches.length;

  const participantsCompletedReview = useSelector(getParticipantsCompletedReview());

  const sketchesLength = keys(sketches).length;
  const participantsUploadedCountText =
    ' Reviewing sketch ' +
    `${sketchesLength - noHeatMappingSketches.length + 1} of ${sketchesLength}`;

  const onUpdateActivityView = () => {
    if (isLastSketch) {
      if (firstSketch) {
        dispatch(updateCurrentSketch(id, firstSketch.id));
      }
      dispatch(updateActivityView('strawPoll', id));
    } else {
      dispatch(updateSketchCompletedHeatMapping(id, currentSketch, true));
      noHeatMappingSketches = sortBy(
        filter(noHeatMappingSketches, sketch => sketch.id !== currentSketch),
        ['participantId'],
      );

      const nextSketch = head(noHeatMappingSketches);
      dispatch(updateCurrentSketch(id, nextSketch.id));
    }
  };

  useEffect(() => {
    setFirstSketch(head(noHeatMappingSketches));
  }, []);

  return (
    <Box className="d-flex flex-column justify-content-center">
      <ActivityPromptAdmin
        prompt={prompt}
        collection={CONFIG.collection}
        activityId={id}
        className={classes.promptAdmin}
      />
      <ActivityParticipantDetails
        headerText={participantsUploadedCountText}
        hasCompletedLabel="Reviewed"
        hasntCompletedLabel="Hasn't Reviewed"
        participantsCompletedStatus={participantsCompletedReview}
      />
      <ActivityActionButton
        text={isLastSketch ? 'start straw poll' : 'next sketch'}
        className="mb-2 mt-1"
        onClick={onUpdateActivityView}
        delayReady
        key={currentSketch}
      />
    </Box>
  );
};

export default HeatMappingAdminReview;
