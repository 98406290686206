import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useField, useFormikContext } from 'formik';

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import './textArea.scss';
import cx from 'classnames';

const useInputStyles = makeStyles({
  root: {
    background: 'rgba(0, 0, 0, 0.04)',
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    marginTop: '0 !important',
    paddingLeft: 5,
    paddingRight: 5,
  },
});

const useLabelStyles = makeStyles({
  root: {
    display: 'inline-block',
  },
});

const useStyles = makeStyles(theme => ({
  helperText: {
    fontSize: 12,
    color: theme.palette.text.hint,
    fontStyle: 'italic',
  },
}));

const TextArea = ({
  name,
  type,
  placeholder,
  rows,
  required,
  label,
  disabled,
  className,
  transparent,
  additionalStyles = {},
  inputProps = {},
  helperText,
}) => {
  const classes = useStyles();
  const inputStyles = useInputStyles();
  const labelStyles = useLabelStyles();
  const [field, meta] = useField(name);
  const { error, touched } = meta;
  const { submitCount } = useFormikContext();

  const hasError = (error && touched) || (submitCount && error) ? true : false;

  return (
    <div className={cx('text-area', className)}>
      {label && (
        <Box className="mb-1">
          <InputLabel
            variant="standard"
            disableAnimation
            shrink={false}
            className={additionalStyles.label}
            classes={labelStyles}
            error={hasError}
            required={required}
            htmlFor={field.name}
          >
            {label}
          </InputLabel>
          {helperText && <Typography className={classes.helperText}> {helperText}</Typography>}
        </Box>
      )}
      <Input
        {...field}
        type={type}
        placeholder={placeholder}
        error={hasError}
        classes={inputStyles}
        fullWidth
        multiline
        rows={rows}
        id={field.name}
        disabled={disabled}
        className={cx(additionalStyles.input, {
          'transparent-text-area': transparent,
        })}
        inputProps={inputProps}
        onBlur={field.onBlur}
      />
      {hasError ? (
        <Box component="span" color="error.main">
          {error}
        </Box>
      ) : null}
    </div>
  );
};

TextArea.defaultProps = {
  rows: 6,
};

TextArea.propTypes = {
  rows: PropTypes.number,
  additionalStyles: PropTypes.shape({
    label: PropTypes.string,
    input: PropTypes.string,
  }),
};

export default TextArea;
