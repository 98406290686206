import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

function ProjectRedirect() {
  const { projectId } = useParams();
  const history = useHistory();
  const user = useSelector(state => state.firebase.profile);

  console.log('***PROJECT REDIRECT*** projectId', projectId);

  useEffect(() => {
    if (user.staffingAdmin) {
      history.push(`/admin/staffing/projects/detail/${projectId}`);
    } else {
      history.push(`/admin/contractor?projectId=${projectId}`);
    }
  }, [user, projectId, history]);

  return null;
}

export default ProjectRedirect;
