import React from 'react';
import { useSelector } from 'react-redux';
import { isEmpty, isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { useParams } from 'react-router-dom';
// cr components
import AssessmentAdminStart from 'components/Assessment/Admin/AssessmentAdminStart';
import AssessmentAdminWrite from 'components/Assessment/Admin/AssessmentAdminWrite';
import AssessmentAdminResults from 'components/Assessment/Admin/AssessmentAdminResults';
import AdminActivityTemplate from 'components/AdminActivityTemplate';
// selectors
import { useAssessmentSelector } from 'redux/selectors/assessmentSelectors';
// config
import { ASSESSMENT_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';

const views = {
  start: AssessmentAdminStart,
  write: AssessmentAdminWrite,
  results: AssessmentAdminResults,
};

/**
 * Entry point for Assessment on the Admin side.
 * Loads the Firestore object for the activity, ensures
 * the data is loaded before rendering the game, and determines
 * which view to show
 */
const AssessmentAdmin = () => {
  const routeParams = useParams();
  const assessment = useAssessmentSelector();
  const session = useSelector(state => state.firestore.data.session);
  const meetingParticipants = useSelector(state => state.firestore.data.participants);

  // hooks up a firestore listener to Redux
  useFirestoreConnect(() => [
    {
      collection: 'assessment',
      doc: `${routeParams.activityId}`,
      storeAs: 'assessment',
    },
  ]);

  // ensure all of the data we need is loaded
  if (
    !isLoaded(session) ||
    isEmpty(session) ||
    !isLoaded(assessment) ||
    isEmpty(assessment) ||
    !isLoaded(meetingParticipants) ||
    /**
     * Ensure the loaded Assessment data actually matches the id in the URL.
     * Since we use only store one instance of any activiy in Redux at a time,
     * if you switch to a different Assessment in the same meeting/session it is
     * possible to momentarily have data for the wrong instance of the activity while
     * useFirestoreConnect loads the new data
     */
    routeParams.activityId !== assessment?.id
  ) {
    return null;
  }

  // console.log("assessment", assessment)

  const CurrentView = views[assessment.state.activeView];

  return (
    <AdminActivityTemplate
      activityConfig={CONFIG}
      activityData={assessment}
      session={session}
      CurrentView={CurrentView}
    />
  );
};

export default AssessmentAdmin;
