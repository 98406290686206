import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { map } from 'lodash';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
  table: {
    minWidth: '100%',
  },
  paper: {
    border: '1px solid #DEDEDE',
  },
});

export default function BasicTable({ headers, rows }) {
  const classes = useStyles();

  // console.log("headers", headers);
  // console.log('rows', rows);

  return (
    <TableContainer component={Paper} className={classes.paper}>
      <Table className={classes.table} aria-label="basic table">
        <TableHead>
          <TableRow>
            {map(headers, (header, idx) => (
              <TableCell align={idx === 0 ? 'left' : 'right'} key={`header-${idx}`}>
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {map(rows, (row, idx) => (
            <TableRow key={`row-${idx}`}>
              {map(row, (item, itemIdx) => (
                <TableCell align={itemIdx === 0 ? 'left' : 'right'} key={`item-${idx}-${itemIdx}`}>
                  {item}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

BasicTable.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  rows: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
};
