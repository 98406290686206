import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { Typography, Tooltip } from '@material-ui/core';

import './dealStatusView.scss';
import { getDealStatusStyles } from 'utils/staffing/status';
import { includes } from 'lodash';
import { DEAL_ERROR_STATUS_STATES } from 'constants/staffing';

const DealStatusErrorWrapper = props => {
  if (props.statusError && includes(Object.values(DEAL_ERROR_STATUS_STATES), props.status)) {
    return (
      <Tooltip arrow title={props.statusError}>
        {props.children}
      </Tooltip>
    );
  }
  return <React.Fragment>{props.children}</React.Fragment>;
};

const DealStatusView = props => {
  const { status, time, statusError } = props;

  return (
    <div className="d-flex align-items-center flex-wrap dealStatusView">
      <DealStatusErrorWrapper status={status} statusError={statusError}>
        <div>
          <div
            className={cx(
              'mr-1',
              'deal-card-body-status',
              getDealStatusStyles(status).containerClassname,
            )}
          >
            <Typography className="deal-card-body-status-text">
              {getDealStatusStyles(status).title}
            </Typography>
          </div>
        </div>
      </DealStatusErrorWrapper>
      <Typography
        className={cx('deal-card-body-day-text', getDealStatusStyles(status).textClassname)}
      >
        {time}
      </Typography>
    </div>
  );
};

DealStatusView.propTypes = {
  status: PropTypes.string,
};

export default DealStatusView;
