import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import cx from 'classnames';
// library components
import { Typography, Divider, Button, IconButton } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import makeStyles from '@material-ui/core/styles/makeStyles';
import VisibilityIcon from '@material-ui/icons/Visibility';
// cr components
import ParticipantTimer from '../ParticipantTimer/ParticipantTimer';
import FormDialog from 'components/Dialogs/FormDialog';
import HighlightsDialog from 'components/Dialogs/HighlightsDialog';
import ParticipantRenameForm from 'components/Forms/ParticipantRenameForm';
// selectors
import { getParticipantCanControlView } from '.../../redux/selectors/participantSessionSelectors';
// utils
import { linkifyHtmlString } from 'utils/renderHtmlString';
// styles
import './participantSidebar.scss';

const useStyles = makeStyles(theme => ({
  highlightsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    textTransform: 'none',
    marginBottom: theme.spacing(1),
  },
  icon: {
    marginRight: theme.spacing(1) / 2,
  },
  mwebIcon: {
    marginRight: theme.spacing(1) / 2,
    position: 'fixed',
    right: '6px',
    marginTop: '-8px',
    color: theme.palette.text.primary,
  },
  highlightsText: {
    color: '#333333',
    marginTop: '1rem',
    textTransform: 'none',
  },
  buttonText: {
    marginTop: '.5rem',
  },
  displayName: {
    fontSize: 11,
    fontWeight: 500,
    lineHeight: '13px',
    color: 'rgba(0, 0, 0, 0.6)',
  },
}));

const ParticipantSidebar = ({ session }) => {
  const [renameFormOpen, setRenameFormOpen] = useState(false);
  const [isHighlightsModalOpen, setIsHighlightsModalOpen] = useState(false);
  const sessionBroadcast = session.highlight || '';
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const participantCanControlView = useSelector(getParticipantCanControlView);
  const authUser = useSelector(state => state.firebase.auth);
  const classes = useStyles();

  const toggleRenameForm = () => {
    setRenameFormOpen(!renameFormOpen);
  };

  // on open Info dialog
  const openHighlightsDialog = () => {
    setIsHighlightsModalOpen(true);
  };

  // on close Info dialog
  const closeHighlightsDialog = () => {
    setIsHighlightsModalOpen(false);
  };

  return (
    <>
      <div
        className={cx('participant-sidebar flex-md-column p-1 p-md-0 border-top shadow', {
          'd-none': !participantCanControlView,
          'd-flex': participantCanControlView,
        })}
      >
        {!smallScreen ? (
          <>
            <div className="mt-1 d-flex justify-content-center align-items-center">
              <Button size="small" onClick={toggleRenameForm} className="auth-user-button">
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <AccountCircleIcon style={{ marginBottom: 4 }} />
                  <Typography>{authUser.displayName}</Typography>
                </div>
              </Button>
            </div>
            <div className="mb-6 mt-2">
              <ParticipantTimer session={session} />
            </div>
            <div className="mb-1 px-3">
              <Button
                size="small"
                className={classes.highlightsContainer}
                onClick={openHighlightsDialog}
              >
                <VisibilityIcon className={classes.icon} />
                <Typography
                  className={`text-dark text-align-center my-0 fw-500 ${classes.buttonText}`}
                >
                  Highlights
                </Typography>
              </Button>
              <Divider />
            </div>
            <div id="broadcast-history" className="broadcast-history px-3 flex-1">
              <div
                className="ck-content clearfix"
                dangerouslySetInnerHTML={{ __html: linkifyHtmlString(sessionBroadcast) }}
              />
            </div>
          </>
        ) : (
          <>
            <div className="d-flex flex-column justify-content-center align-items-center pt-1 px-2">
              <ParticipantTimer session={session} />
              <div className="d-flex justify-content-center align-items-center">
                <Button size="small" onClick={toggleRenameForm}>
                  <Typography className={classes.displayName}>{authUser.displayName}</Typography>
                </Button>
              </div>
            </div>
            <Divider orientation="vertical" flexItem />
            <div className="pt-1 pl-2 flex-1 scrollbar-fix" style={{ overflowY: 'scroll' }}>
              <IconButton size="small" onClick={openHighlightsDialog} className={classes.mwebIcon}>
                <VisibilityIcon />
              </IconButton>
              <div id="broadcast-history" className="broadcast-history flex-1">
                <div
                  className={`ck-content clearfix`}
                  dangerouslySetInnerHTML={{ __html: linkifyHtmlString(sessionBroadcast) }}
                />
              </div>
            </div>
          </>
        )}
      </div>
      <FormDialog
        isOpen={renameFormOpen}
        handleClose={toggleRenameForm}
        title="Change Display Name"
      >
        <ParticipantRenameForm closeDialog={toggleRenameForm} />
      </FormDialog>
      <HighlightsDialog
        handleClose={closeHighlightsDialog}
        isOpen={isHighlightsModalOpen}
        title="Highlights"
      >
        {sessionBroadcast}
      </HighlightsDialog>
    </>
  );
};

ParticipantSidebar.propTypes = {
  session: PropTypes.object.isRequired,
};

export default ParticipantSidebar;
