import React, { useState } from 'react';
import SendProjectAttendeeMessageForm from 'components/Forms/SendProjectAttendeeMesageForm';
import FormDialog from './FormDialog';
import { useFirestore } from 'react-redux-firebase';
import { map } from 'lodash';
import { useDeepCompareEffect } from 'react-use';
import Spinner from 'components/Spinner';
import { sleep } from 'utils/sleep';
import { Box } from '@material-ui/core';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { enqueueErrorSnackbar } from 'redux/actions/notifierActions';
import { PROJECT_ATTENDEE_MESSAGE_DESTINATIONS } from 'constants/staffing';

const SendAttendeeMessageDialog = ({ handleClose, isOpen, projectAttendeeMessages, projectId }) => {
  const dispatch = useDispatch();
  const [isFetchingAttendees, setIsFetchingAttendees] = useState(false);
  const [attendeeOptions, setAttendeeOptions] = useState([]);
  const [messageOptions, setMessageOptions] = useState([]);
  const db = useFirestore();

  useEffect(() => {
    if (!isOpen) {
      setTimeout(() => {
        setAttendeeOptions([]);
        setMessageOptions([]);
      }, 1000);
    }
  }, [isOpen]);

  useDeepCompareEffect(() => {
    (async () => {
      if (isFetchingAttendees || attendeeOptions.length || !isOpen) return;

      setIsFetchingAttendees(true);

      await sleep(500);

      const doc = await db.collection('projectAttendees').doc(projectId).get();

      if (doc.exists) {
        const attendees = doc.data().attendees;
        const options = map(attendees, (attendee, id) => {
          return {
            value: id,
            name: attendee.email,
          };
        });

        if (options.length) {
          options.unshift({
            value: 'all',
            name: 'Send to All',
          });

          setAttendeeOptions(options);
        }
      } else {
        handleClose();
        dispatch(enqueueErrorSnackbar('Error fetching project attendees'));
      }

      setIsFetchingAttendees(false);
    })();
  }, [isFetchingAttendees, attendeeOptions, isOpen]);

  useDeepCompareEffect(() => {
    if (!projectAttendeeMessages.length || !isOpen) return;

    const attendeeMessageDestinations = [
      PROJECT_ATTENDEE_MESSAGE_DESTINATIONS.Email,
      PROJECT_ATTENDEE_MESSAGE_DESTINATIONS.CircleDM,
    ];

    setMessageOptions(
      projectAttendeeMessages
        .filter(msg => attendeeMessageDestinations.includes(msg.destination))
        .map(msg => ({
          value: msg.id,
          name: msg.name,
        })),
    );
  }, [projectAttendeeMessages, isOpen]);

  return (
    <FormDialog
      isOpen={isOpen}
      handleClose={handleClose}
      title="Re-Send Attendee Message"
      isTitleHasCloseIcon
    >
      <Box
        style={{ minHeight: 200 }}
        display="flex"
        flexDirection="column"
        className="flex-1 justify-content-center align-items-center"
      >
        {isFetchingAttendees ? (
          <Box display="flex" flexDirection="column" className="flex-1 mt-8">
            <Spinner />
          </Box>
        ) : (
          <SendProjectAttendeeMessageForm
            attendeeOptions={attendeeOptions}
            closeDialog={handleClose}
            messageOptions={messageOptions}
            projectId={projectId}
          />
        )}
      </Box>
    </FormDialog>
  );
};

export default SendAttendeeMessageDialog;
