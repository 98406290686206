import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { map, sortBy, find } from 'lodash';
import { makeStyles } from '@material-ui/styles';
import cx from 'classnames';
// library components
import { Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
// cr components
import StoryFlow from 'components/StoryFlow/Participant/StoryFlow';
// selectors
import { useStoryFlowSelector, getCountSelectedFlows } from 'redux/selectors/storyFlowSelectors';
// actions
import {
  updateParticipantSuperVote,
  removeParticipantSuperVote,
} from 'redux/actions/storyFlowActions';

const useStyles = makeStyles(theme => ({
  successColor: {
    color: theme.palette.success.main,
  },
}));

/**
 * Story Flow view for the 'selectResults' phase on the Participant side
 */
const StoryFlowParticipantSuperVote = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const participantId = useSelector(state => state.firebase.auth.uid);
  const countSelectedFlows = useSelector(getCountSelectedFlows());
  const {
    id,
    participantData: { flows, participantsSuperVoteFlow },
    state: { activeView, superVoteParticipantId },
  } = useStoryFlowSelector();
  const sortedFlows = sortBy(flows, 'participantId');
  const participantSelectedFlow = find(
    participantsSuperVoteFlow,
    participant => !!participant.selectedFlowId,
  );
  const isSuperVoteParticipant = superVoteParticipantId === participantId;

  const [selectedFlowId, setSelectedFlowId] = useState(participantSelectedFlow?.selectedFlowId);
  const [isSelected, setIsSelected] = useState(!!participantSelectedFlow);

  // participant select flow for super vote
  const handleSelectFlow = (createdFlowParticipantId, selected) => {
    setIsSelected(selected);
    if (selected) {
      setSelectedFlowId(createdFlowParticipantId);
      dispatch(updateParticipantSuperVote(id, createdFlowParticipantId, participantId));
    } else {
      dispatch(removeParticipantSuperVote(id, participantId));
    }
  };

  // renders participants flows
  const renderParticipantsFlows = () =>
    map(sortedFlows, (flow, idx) => {
      const selectedFlow = countSelectedFlows[flow.participantId];
      return (
        <StoryFlow
          key={`participant-flow-${idx}`}
          isSuperVoteParticipant={isSuperVoteParticipant}
          handleSelectFlow={handleSelectFlow}
          selectedFlowId={selectedFlowId}
          createdFlowParticipantId={flow.participantId}
          participantFlow={flow?.values}
          votesCount={selectedFlow ? selectedFlow.length : 0}
          disabled={true}
          context={activeView}
        />
      );
    });

  return (
    <>
      {renderParticipantsFlows()}
      {isSelected && isSuperVoteParticipant && (
        <>
          <CheckCircleIcon className={classes.successColor} />
          <Typography className={cx(classes.successColor, 'mb-3')} variant="body2">
            You’ve selected a story flow!
          </Typography>
        </>
      )}
    </>
  );
};

export default StoryFlowParticipantSuperVote;
