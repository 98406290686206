import { map, sortBy } from 'lodash';
import { VARIABLE_QUESTION_TYPES } from 'components/CustomFormComponents/VariableQuestions/QuestionTypes/constants';

/**
 * Creates the output array for a Assessment actvity
 * @param {Object} questions
 * @param {Object} answers
 * @returns {Array<{participantName: String, answers: []}>}
 */
export const createOutput = (questions, answers, participants) => {
  const sortedQuestions = sortBy(questions, 'order');
  const sortedParticipants = sortBy(participants, 'name');
  const output = [];

  sortedParticipants.forEach(p => {
    const data = {
      participantName: p.name,
      answers: [],
    };

    const participantAnswers = answers[p.id];

    sortedQuestions.forEach(q => {
      const answer = {
        question: q.data.question,
        questionId: q.id,
      };

      if (participantAnswers && q.id in participantAnswers) {
        if (q.data.questionType === VARIABLE_QUESTION_TYPES.MULTIPLE_CHOICE) {
          answer.answer = participantAnswers[q.id].join(', ');
        } else if (q.data.questionType === VARIABLE_QUESTION_TYPES.LINEAR_SCALE) {
          answer.answer = q.data.choices[Number(participantAnswers[q.id])];
        } else {
          answer.answer = participantAnswers[q.id];
        }
      } else {
        answer.answer = '';
      }

      data.answers.push(answer);
    });

    output.push(data);
  });

  return output;
};

/**
 * Creates the array for Report Header
 *
 */
export const createAssessmentReportHeader = questions => {
  const headers = [{ label: 'Participant', key: 'participant' }];

  const sortedQuestions = sortBy(questions, 'order');

  sortedQuestions.forEach(q => {
    headers.push({
      label: q.data.question,
      key: q.id,
    });
  });

  return headers;
};

export const createAssessmentReportData = output => {
  return map(output, row => {
    const data = {};

    data.participant = row.participantName;

    row.answers.forEach(answer => {
      data[answer.questionId] = answer.answer;
    });

    return data;
  });
};
