import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
// library components
import { Box, Typography, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
// cr components
import OptionForm from './OptionForm';

const useStyles = makeStyles(theme => ({
  cancelBtn: {
    position: 'relative',
    right: `-${theme.spacing(1)}px`,
    fontSize: 18,
    alignSelf: 'center',
  },
}));

/**
 * Renders a single option in an OptionCloud
 */
const Option = ({
  option,
  isNew,
  className,
  onCreate,
  onEdit,
  onDelete,
  onCancelAdding,
  readonly,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const classes = useStyles();

  return (
    <Box mr={1} mb={1} className={cx(className)}>
      {isNew && <OptionForm type="create" onCreate={onCreate} cancelCreate={onCancelAdding} />}
      {!isNew && (
        <Box>
          {isEditing ? (
            <OptionForm
              option={option}
              type="edit"
              onEdit={onEdit}
              cancelEdit={() => {
                setIsEditing(false);
              }}
            />
          ) : (
            <Typography
              color="inherit"
              onClick={() => {
                if (!readonly) {
                  setIsEditing(true);
                }
              }}
            >
              {option?.value}
            </Typography>
          )}
        </Box>
      )}
      {!isEditing && !isNew && !readonly && (
        <IconButton
          size="small"
          className={classes.cancelBtn}
          onClick={() => {
            onDelete(option);
          }}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      )}
    </Box>
  );
};

Option.defauleProps = {
  className: '',
};

Option.propTypes = {
  option: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }),
  isNew: PropTypes.bool,
  className: PropTypes.string,
  onCreate: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onCancelAdding: PropTypes.func,
  readonly: PropTypes.bool,
};

export default Option;
