import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { map, remove, cloneDeep, includes } from 'lodash';
import { makeStyles } from '@material-ui/styles';
import cx from 'classnames';
// library components
import { Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// cr components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import DottedProgress from 'components/DottedProgress';
import Idea from './Idea';
// selectors
import {
  useHowMightWeSelector,
  getParticipantIdeas,
  getSharedParticipantIdeas,
} from 'redux/selectors/howMightWeSelectors';
// actions
import { updateSharedIdea } from 'redux/actions/howMightWeActions';

const useStyles = makeStyles(theme => ({
  successColorText: {
    color: theme.palette.neutral.main,
  },
  checkIcon: {
    marginTop: theme.spacing(3),
  },
  grid: {
    width: 'calc(100% + 10px)',
  },
  gridItem: {
    padding: `0 5px !important`,
  },
}));

/**
 * HMW view for the 'share' phase on the Participant side
 */
const HowMightWeParticipantShare = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const smallScreen = useMediaQuery('(max-width: 425px)');
  const participantId = useSelector(state => state.firebase.auth.uid);
  const participantIdeas = useSelector(getParticipantIdeas(participantId));
  const sharedParticipantIdeas = useSelector(getSharedParticipantIdeas(participantId));
  const {
    id,
    config: { share },
    state: { activeView },
  } = useHowMightWeSelector();

  const [shareIdeasId, setShareIdeasId] = useState(
    map(sharedParticipantIdeas, idea => idea.ideaId),
  );

  const ideasCount = shareIdeasId.length;

  // Update isShared property of idea
  const handleShare = (ideaId, isIdeaSelected) => {
    if (isIdeaSelected) {
      setShareIdeasId([...shareIdeasId, ideaId]);
    } else {
      const newShareIdeasId = cloneDeep(shareIdeasId);
      remove(newShareIdeasId, idea => idea === ideaId);
      setShareIdeasId([...newShareIdeasId]);
    }
    dispatch(updateSharedIdea(id, ideaId, isIdeaSelected));
  };

  // Renders participant ideas
  const renderIdeas = () =>
    map(participantIdeas, idea => (
      <GridItem key={idea.ideaId} className={classes.gridItem} xs={12} sm={6} md={3} lg={3} xl={3}>
        <Idea
          key={idea.ideaId}
          idea={idea}
          isSelected={includes(shareIdeasId, idea.ideaId)}
          context={activeView}
          handleSelect={handleShare}
          isComplete={ideasCount >= share}
        />
      </GridItem>
    ));

  return (
    <>
      <GridContainer direction={smallScreen ? 'column' : 'row'} className={classes.grid}>
        {renderIdeas()}
      </GridContainer>
      {ideasCount >= share && (
        <CheckCircleIcon className={cx(classes.successColorText, classes.checkIcon)} />
      )}
      <DottedProgress
        className={cx('mt-1 ml-1', ideasCount < share && 'mt-7')}
        activeColor="#E59A05"
        totalCount={+share}
        activeCount={ideasCount}
      />
      <Typography
        color="textSecondary"
        className={cx(ideasCount === share && classes.successColorText)}
        variant="body2"
      >
        {ideasCount} of {share} HMW Selected
      </Typography>
    </>
  );
};

export default HowMightWeParticipantShare;
