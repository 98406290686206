import {
  PARTICIPANT_SESSION_VIEW,
  PARTICIPANT_SLIDES_VIEW,
  SHOW_PARTICIPANT_APP_OVERLAY_SPINNER,
} from '../types/participantSessionTypes';

/**
 * Changes the participant view between
 * @param {string} view one of ['activity', 'video', 'slides']
 */
export const updateParticipantSessionView = selectedView => {
  return {
    type: PARTICIPANT_SESSION_VIEW,
    payload: selectedView,
  };
};

/**
 * Changes the participant view between
 * @param {string} view one of ['activity', 'video', 'slides']
 */
export const updateLocalSlidesSessionSlide = (slidesId, slideIdx) => {
  return {
    type: PARTICIPANT_SLIDES_VIEW,
    payload: { slidesId, slideIdx },
  };
};

/**
 * Set app overlay spinner to show or not
 * @param {boolean} showAppOverlaySpinner
 */
export const showAppOverlaySpinner = showAppOverlaySpinner => {
  return {
    type: SHOW_PARTICIPANT_APP_OVERLAY_SPINNER,
    payload: showAppOverlaySpinner,
  };
};

// async setRemoteSlidesSessionSlideNumber(sessionId, slideNumber) {
//   const session = await this.getRemoteSlidesSession(sessionId);
//   if (slideNumber > -1) {
//       if (session && session.imageUrls) {
//           session.currentSlide = Math.min(slideNumber, session.imageUrls.length - 1);
//           return this.firestoreHelper.writeObjectToFirestore(models.remoteSlidesSession, session);
//       }
//   }
//   return session;
// }
