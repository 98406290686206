import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty, filter, head, orderBy } from 'lodash';
// library components
import { Dialog, Box } from '@material-ui/core';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
// cr components
import DialogHeader from './DialogHeader';
import DialogAction from './DialogAction';
import CardSets from './CardSets';
import EditSet from './EditSet';
import DuplicateSetButton from './Buttons/DuplicateSetButton';
import DeleteSetButton from './Buttons/DeleteSetButton';
import Cards from './Cards';
import ButtonSpinner from 'components/ButtonSpinner/ButtonSpinner';
// selectors
import { getFacilitatorSet, getFacilitatorCardSets } from 'redux/selectors/cardSetsSelectors';
// actions
import {
  updateEditSet,
  deleteSet,
  createCardsSet,
  duplicateSet,
} from 'redux/actions/cardSetsActions';
// utils
import { generateRandomId } from 'utils/generateRandomId';

const useStyles = makeStyles(theme => ({
  paperWidthSm: {
    maxWidth: 970,
  },
  dialogTitle: {
    maxWidth: 970,
    width: 970,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  editSet: {
    position: 'relative',
    maxWidth: 780,
    width: '100%',
    height: 500,
    paddingLeft: 30,
  },
  buttonSpinnerBox: {
    position: 'absolute',
    top: '45%',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  buttonSpinner: {
    color: theme.palette.primary.main,
  },
  dialogActions: {
    marginTop: theme.spacing(2),
  },
}));

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(5),
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(4),
  },
}))(MuiDialogContent);

const EditCardSetsDialog = ({ handleClose, handleConfirm, isOpen }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const facilitatorId = useSelector(state => state.firestore.data.session?.facilitatorId);

  const [cardSet, setCardSet] = useState();
  const [cardSets, setCardSets] = useState();
  const [editId, setEditId] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const getSets = useCallback(async () => {
    const set = await getFacilitatorSet(facilitatorId);
    const sets = await getFacilitatorCardSets(facilitatorId);
    if (!editId) {
      setEditId(set?.setId);
    }
    setCardSets(sets);
    setCardSet(set);
    setIsLoading(false);
  }, [editId, facilitatorId]);

  useEffect(() => {
    setIsLoading(true);
    getSets();
  }, [editId, getSets]);

  // create new set
  const handleCreateSet = () => {
    const setId = generateRandomId();
    const order = cardSets?.length + 1 || 0;
    if (editId) {
      dispatch(updateEditSet(editId, false));
    }
    dispatch(createCardsSet(facilitatorId, setId, order));
    setEditId(setId);
  };

  // select set for editing
  const handleEditSet = setId => {
    if (editId) {
      dispatch(
        updateEditSet(editId, false, true, () => {
          dispatch(updateEditSet(setId, true));
          setEditId(setId);
        }),
      );
    } else {
      dispatch(updateEditSet(setId, true));
      setEditId(setId);
    }
  };

  // delete set
  const handleDeleteSet = () => {
    if (!cardSet) {
      return;
    }
    dispatch(deleteSet(cardSet?.setId));
    const sets = filter(cardSets, set => set.setId !== cardSet?.setId);
    const nextSet = head(orderBy(sets, 'order', 'desc'));
    if (nextSet) {
      dispatch(updateEditSet(nextSet.setId, true));
      setEditId(nextSet.setId);
    } else {
      setEditId('');
    }
  };

  // duplicate current set
  const handleDuplicateSet = () => {
    if (!cardSet) {
      return;
    }
    if (editId) {
      dispatch(updateEditSet(editId, false));
    }
    const duplicateSetId = generateRandomId();
    dispatch(
      duplicateSet(duplicateSetId, {
        ...cardSet,
        order: cardSets?.length + 1,
        setId: duplicateSetId,
      }),
    );
    setEditId(duplicateSetId);
  };

  return (
    <Box>
      <Dialog
        open={isOpen}
        classes={{
          paperWidthSm: classes.paperWidthSm,
        }}
      >
        <DialogHeader title="Edit Card Sets" handleClose={handleClose} />
        <DialogContent classes={classes.root} dividers>
          <Box className="d-flex">
            <CardSets
              handleEditSet={handleEditSet}
              handleCreateSet={handleCreateSet}
              cardSets={cardSets}
            />
            {isLoading ? (
              <Box className={classes.buttonSpinnerBox}>
                <ButtonSpinner className={classes.buttonSpinner} />
              </Box>
            ) : (
              !isEmpty(cardSet) && (
                <Box className={classes.editSet}>
                  <Box className="d-flex flex-wrap align-items-center">
                    <EditSet setId={cardSet?.setId} name={cardSet?.name} />
                    <Box>
                      <DuplicateSetButton handleDuplicateSet={handleDuplicateSet} />
                      <DeleteSetButton handleDeleteSet={handleDeleteSet} />
                    </Box>
                  </Box>
                  <Cards setId={editId} />
                </Box>
              )
            )}
          </Box>
          <DialogAction
            handleClose={handleClose}
            handleConfirm={() => {
              handleConfirm();
              handleClose();
            }}
            className={classes.dialogActions}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

EditCardSetsDialog.propTypes = {
  handleClose: PropTypes.func.isRequired, // close dialog
  handleConfirm: PropTypes.func.isRequired, // save data from dialog
  isOpen: PropTypes.bool.isRequired, // dialog open ?
};

export default EditCardSetsDialog;
