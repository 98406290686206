import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { map, find } from 'lodash';
import * as Yup from 'yup';
// library components
import { Formik, Form } from 'formik';
import { Button } from '@material-ui/core';
// cr components
import ButtonSpinner from 'components/ButtonSpinner/ButtonSpinner';
import CustomSelect from 'components/CustomFormComponents/CustomSelect';
// utils
import { sendResultsToLiveScribing } from 'utils/sendResultsToLiveScribing';
// constants
import { ACTIVITIES } from 'constants/activities';

/**
 * Form for choosing which Live Scribing activity in a meeting to send results output from
 * another activity to when there are more than 1 available
 */
const SendToLiveScribingForm = ({ closeDialog, activityOutput, liveScribeActivities }) => {
  const auth = useSelector(state => state.firebase.auth);

  const formSchema = Yup.object().shape({
    liveScribingId: Yup.string().required('Required'),
  });

  return (
    <Formik
      initialValues={{
        liveScribingId: liveScribeActivities[0]?.id,
      }}
      validationSchema={formSchema}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        const liveScibingActivity = find(liveScribeActivities, { id: values.liveScribingId });

        await sendResultsToLiveScribing(liveScibingActivity, auth.uid, activityOutput);

        setSubmitting(false);
        closeDialog();
        resetForm();
      }}
    >
      {({ isSubmitting, submitForm, resetForm }) => (
        <Form style={{ paddingBottom: 20 }}>
          <CustomSelect
            name="liveScribingId"
            label=""
            selectOptions={map(liveScribeActivities, lsActivity => ({
              value: lsActivity.id,
              name: lsActivity.name.split(`${ACTIVITIES.liveScribing.niceName}:`)[1],
            }))}
          />

          <div className="form-dialog-buttons">
            <Button
              variant="outlined"
              color="primary"
              disabled={isSubmitting}
              onClick={() => {
                closeDialog();
                resetForm();
              }}
            >
              CANCEL
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={submitForm}
            >
              SEND IT!
              {isSubmitting && <ButtonSpinner />}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

SendToLiveScribingForm.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  // output of the activity being copied to Live Scribing
  activityOutput: PropTypes.string,
  // array of Live Scribing activities as they are stored in the activities array of a meeting
  liveScribeActivities: PropTypes.arrayOf(PropTypes.object),
};

export default SendToLiveScribingForm;
