import { enqueueErrorSnackbar } from 'redux/actions/notifierActions';
import { includes } from 'lodash';
import { generateRandomId } from 'utils/generateRandomId';
import { createOutput } from 'utils/lightningDemo';

const views = ['upload', 'review', 'results'];

/**
 * Update the active view for the activity
 * @param {String} view
 * @param {String} lightningDemoId
 */
export const updateActivityView =
  (view, lightningDemoId, successCb) =>
  (dispatch, getState, { getFirebase }) => {
    if (!includes(views, view)) {
      console.error(`View '${view}' does not exist in the activity config`);
      return;
    }

    const db = getFirebase().firestore;

    db()
      .doc(`lightningDemo/${lightningDemoId}`)
      .set(
        {
          state: {
            activeView: view,
          },
        },
        { merge: true },
      )
      .then(() => {
        if (successCb) {
          successCb();
        }
      })
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error changing activity view'));
      });
  };

/**
 * Update participants
 *
 * @param {String} lightningDemoId
 * @param {String} participantId
 */
export const updateParicipants =
  (lightningDemoId, participantId) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`lightningDemo/${lightningDemoId}`)
      .set(
        {
          participantData: {
            participants: db.FieldValue.arrayUnion(participantId),
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error update participants'));
      });
  };

/**
 * Update participant if from active participants for current lightning demo
 *
 * @param {String} lightningDemoId
 * @param {String} participantId
 */
export const removeParicipants =
  (lightningDemoId, participantId) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`lightningDemo/${lightningDemoId}`)
      .set(
        {
          participantData: {
            participants: db.FieldValue.arrayRemove(participantId),
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error removing participants'));
      });
  };

/**
 * Create a lightning demo
 *
 * @param {String} participantId
 * @param {String} lightningDemoId
 */
export const createLightningDemo =
  (lightningDemoId, participantId, order) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`lightningDemo/${lightningDemoId}`)
      .set(
        {
          participantData: {
            demos: {
              [participantId + order]: {
                participantId,
                demoId: participantId + order,
                imageId: '',
                url: '',
                bigIdeas: '',
                source: '',
                order,
              },
            },
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error creating demo'));
      });
  };

/**
 * Delete lightning demo in participantData
 *
 * @param {String} lightningDemoId
 * @param {String} demoId
 */
export const deleteLightningDemo =
  (lightningDemoId, demoId) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;
    const path = `participantData.demos.${demoId}`;

    db()
      .doc(`lightningDemo/${lightningDemoId}`)
      .update({
        [path]: db.FieldValue.delete(),
      })
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error delete lightning demo'));
      });
  };

/**
 * Update a lightning demo
 *
 * @param {String} lightningDemoId
 * @param {String} demoId
 * @param {String} imageId
 * @param {String} imageUrl
 */
export const updateLightningDemo =
  (lightningDemoId, demoId, imageId, imageUrl) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`lightningDemo/${lightningDemoId}`)
      .set(
        {
          participantData: {
            demos: {
              [`${demoId}`]: {
                imageId: imageId,
                imageUrl,
              },
            },
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error update demo'));
      });
  };

/**
 * Update a lightning demo title
 *
 * @param {String} lightningDemoId
 * @param {String} demoId
 * @param {String} title
 */
export const updateLightningDemoBigIdeas =
  (lightningDemoId, demoId, title) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`lightningDemo/${lightningDemoId}`)
      .set(
        {
          participantData: {
            demos: {
              [`${demoId}`]: {
                bigIdeas: title,
              },
            },
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error update demo title'));
      });
  };

/**
 * Update a lightning demo source title
 *
 * @param {String} lightningDemoId
 * @param {String} demoId
 * @param {String} sourceTitle
 */
export const updateLightningDemoSourceTitle =
  (lightningDemoId, demoId, sourceTitle) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`lightningDemo/${lightningDemoId}`)
      .set(
        {
          participantData: {
            demos: {
              [`${demoId}`]: {
                source: sourceTitle,
              },
            },
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error update demo source title'));
      });
  };

/**
 * Update a lightning demo url
 *
 * @param {String} lightningDemoId
 * @param {String} demoId
 * @param {String} url
 */
export const updateLightningDemoUrl =
  (lightningDemoId, demoId, url) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`lightningDemo/${lightningDemoId}`)
      .set(
        {
          participantData: {
            demos: {
              [`${demoId}`]: {
                url: url,
              },
            },
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error update demo url'));
      });
  };

/**
 * Update current participant id
 *
 * @param {String} lightningDemoId
 * @param {String} participantId
 */
export const addCurrentParticipantId =
  (lightningDemoId, participantId) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`lightningDemo/${lightningDemoId}`)
      .set(
        {
          state: {
            currentParticipantId: participantId,
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error update current participant id'));
      });
  };

/**
 * Update participants completed review
 *
 * @param {String} lightningDemoId
 * @param {String} participantId
 */
export const updateParticipantsCompletedReview =
  (lightningDemoId, participantId) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`lightningDemo/${lightningDemoId}`)
      .set(
        {
          state: {
            participantsCompletedReview: db.FieldValue.arrayUnion(participantId),
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error update update participants completed review'));
      });
  };

/**
 * Update current demo id
 *
 * @param {String} lightningDemoId
 * @param {String} participantId
 */
export const addCurrentDemoId =
  (lightningDemoId, demoId) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`lightningDemo/${lightningDemoId}`)
      .set(
        {
          state: {
            currentDemoId: demoId,
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error update current demo id'));
      });
  };

/**
 * Update a lightning demo completed review
 *
 * @param {String} lightningDemoId
 * @param {String} demoId
 */
export const updateCompletedDemo =
  (lightningDemoId, demoId) =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;

    db()
      .doc(`lightningDemo/${lightningDemoId}`)
      .set(
        {
          participantData: {
            demos: {
              [`${demoId}`]: {
                isCompleted: true,
              },
            },
          },
        },
        { merge: true },
      )
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error update a lightning demo completed review'));
      });
  };

/**
 * Create the activity output and update the view to 'results'
 * @param {String} lightningDemoId
 */
export const revealResults =
  lightningDemoId =>
  (dispatch, getState, { getFirebase }) => {
    const db = getFirebase().firestore;
    const state = getState();
    const { demos } = state.firestore.data.lightningDemo.participantData;
    const output = createOutput(demos);

    db()
      .doc(`lightningDemo/${lightningDemoId}`)
      .set(
        {
          output,
        },
        { merge: true },
      )
      .then(() => {
        dispatch(updateActivityView('results', lightningDemoId));
      })
      .catch(err => {
        console.error(err);
        dispatch(enqueueErrorSnackbar('Error generating results'));
      });
  };
