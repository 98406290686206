import buildApiClient, { buildClientConfig } from '@muraldevkit/mural-integrations-mural-client';

const SERVICES = {
  auth: process.env.REACT_APP_API_URL,
  mural: process.env.REACT_APP_MURAL_DOMAIN,
};

// --- Configuration ---
const tokenHandlerConfig = {
  authorizeUri: new URL('/mural-authUrlEndpoint', `http://${SERVICES.auth}`).href,
  requestTokenUri: new URL('/mural-tokenEnpoint', `http://${SERVICES.auth}`).href,
  refreshTokenUri: new URL('/mural-refreshTokenEndpoint', `http://${SERVICES.auth}`).href,
};

// --- MURAL API client ---
const clientConfig = buildClientConfig({
  appId: process.env.REACT_APP_MURAL_CLIENT_ID,
  muralHost: SERVICES.mural,
  ...tokenHandlerConfig,
});

export const muralApiClient = buildApiClient(clientConfig);

export const parseMuralURL = muralURL => {
  const parts = muralURL.split('/');
  const workspaceId = parts[4];
  const muralId = parts[parts.length - 2];
  const state = parts[parts.length - 1];

  return {
    id: `${workspaceId}.${muralId}`,
    workspaceId,
    state,
  };
};
