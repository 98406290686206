import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { map } from 'lodash';
import cx from 'classnames';
// library components
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
// cr components
import GrayBox from 'components/GrayBox';

const useStyles = makeStyles(theme => ({
  container: {
    margin: '0 -5px',
  },
  header: {
    fontSize: 15,
  },
  boxItem: {
    flex: '1 1 150px',
    margin: '0 5px',
    marginBottom: theme.spacing(2),
    padding: '15px 10px',
  },
  activeBoxItem: {
    flex: '1 1 150px',
    padding: '15px 10px',
    margin: '0 5px',
    marginBottom: theme.spacing(2),
    background: '#ecf4f8',
    color: '#093870',
  },
  primaryCategory: {
    background: '#D4EAE1',
    color: '#008437',
  },
}));

/**
 * Renders the category boxes
 */
const CategoriesAdmin = ({ categories, currentCategory, primaryCategoryId }) => {
  const classes = useStyles();
  const isParallel = !currentCategory;

  return (
    <>
      <Box className={cx('d-flex flex-wrap', classes.container)}>
        {map(categories, category => (
          <GrayBox
            key={`category_${category.id}`}
            className={cx(
              isParallel || currentCategory.id === category.id
                ? classes.activeBoxItem
                : classes.boxItem,
              category.id === primaryCategoryId && classes.primaryCategory,
            )}
          >
            <Typography className={classes.header}>{category.value}</Typography>
          </GrayBox>
        ))}
      </Box>
    </>
  );
};

CategoriesAdmin.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  currentCategory: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }),
  currentParticipantId: PropTypes.string,
  output: PropTypes.object,
  primaryCategoryId: PropTypes.string,
};

export default CategoriesAdmin;
