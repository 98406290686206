import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// library components
import { makeStyles, Paper, Box, Typography } from '@material-ui/core';
// cr components
import GiftImage from 'assets/img/default_gift_image.svg';
import Spinner from './Spinner';
import WinnerConfetti from './WinnerConfetti';

const useStyles = makeStyles(theme => ({
  giftBox: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: 440,
    minHeight: 360,
    marginTop: theme.spacing(6),
    marginBottom: 20,
    padding: 20,
    border: '1px solid #E3E3E3',
    boxShadow: '0px 8px 18px rgba(0, 0, 0, 0.12)',
    background: '#FDFDFD',
    zIndex: 5,
  },
  smallBox: {
    maxWidth: 440,
    width: '100%',
  },
  image: {
    marginTop: 'auto',
    marginBottom: 'auto',
    width: 230,
  },
  imagePlaceholder: {
    opacity: 0.3,
  },
  container: {
    position: 'relative',
  },
  text: {
    width: '100%',
    fontSize: 16,
    fontWeight: 300,
    lineHeight: '22px',
    textAlign: 'center',
    overflowWrap: 'break-word',
  },
  itemText: {
    marginTop: 'auto',
    width: '100%',
    fontSize: 20,
    fontWeight: 300,
    lineHeight: '40px',
    textAlign: 'center',
    overflowWrap: 'break-word',
  },
}));

const Gift = ({ image, isSelecting, isWinner, itemName, itemDescription }) => {
  const smallScreen = useMediaQuery('(max-width: 500px)');
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Paper elevation={0} className={cx(smallScreen && classes.smallBox, classes.giftBox)}>
        <Box className={cx(isSelecting && classes.imagePlaceholder)}>
          <img className={classes.image} src={image || GiftImage} alt="default-gift" />
        </Box>
        {isSelecting && <Spinner />}
        <Typography className={classes.itemText}>{itemName}</Typography>
        <Typography className={classes.text}>{itemDescription}</Typography>
      </Paper>
      {isWinner && <WinnerConfetti />}
    </Box>
  );
};

Gift.propTypes = {
  image: PropTypes.string, // the gift image url
  isSelecting: PropTypes.bool, // the winner is now being chosen
  isWinner: PropTypes.bool, // the winner is chosen
};

export default Gift;
