import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { find } from 'lodash';
// library components
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// cr components
import ActivityParticipantDetails from 'components/ActivityParticipantDetails';
import ActivityPromptAdmin from 'components/ActivityPromptAdmin';
import ActivityActionButton from 'components/CustomButtons/ActivityActionButton';
import FormDialog from 'components/Dialogs/FormDialog';
import SelectSuperVoteParticipantForm from 'components/Forms/SelectSuperVoteParticipantForm';
// selectors
import {
  useStoryFlowSelector,
  getParticipantsCompletedSuperVote,
} from 'redux/selectors/storyFlowSelectors';
import { getParticipantsMap } from 'redux/selectors/participantsSelectors';
// actions
import { revealResults, updateSuperVoteParticipant } from 'redux/actions/storyFlowActions';
// config
import { STORY_FLOW_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';

const useStyles = makeStyles(theme => ({
  promptAdmin: {
    marginBottom: theme.spacing(5),
  },
}));

/**
 * Story Flow view for the 'superVote' phase on the Facilitator side
 */
const StoryFlowAdminSuperVote = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const participantsData = useSelector(getParticipantsMap);
  const storyFlow = useStoryFlowSelector();
  const {
    id,
    prompt,
    participantData: { participants },
    state: { superVoteParticipantId },
  } = storyFlow;
  const participantsHaveSuperVoteComplete = useSelector(getParticipantsCompletedSuperVote());
  const isSuperVoteComplete = find(
    participantsHaveSuperVoteComplete,
    votes => votes.id === superVoteParticipantId && votes.completed,
  );

  const [isModalOpen, setIsModalOpen] = useState(!superVoteParticipantId);

  const participantsSuperVoteText = isSuperVoteComplete
    ? 'Decider selected a flow'
    : 'Decider is selecting a flow';

  const onUpdateActivityView = () => {
    dispatch(revealResults(id));
  };

  // Close modal form
  const closeDialog = () => {
    setIsModalOpen(false);
  };

  // Add super vote participant id
  const submitSuperVoteParticipantId = participantId => {
    dispatch(updateSuperVoteParticipant(id, participantId));
    closeDialog();
  };

  return (
    <Box className="d-flex flex-column justify-content-center">
      <ActivityPromptAdmin
        prompt={prompt}
        collection={CONFIG.collection}
        activityId={id}
        className={classes.promptAdmin}
      />
      <ActivityParticipantDetails
        headerText={participantsSuperVoteText}
        hasCompletedLabel="Selected"
        hasntCompletedLabel="Hasn’t Selected"
        participantsCompletedStatus={participantsHaveSuperVoteComplete}
      />
      <ActivityActionButton
        text="Review Results"
        className="mb-2 mt-1"
        onClick={onUpdateActivityView}
        delayReady
      />
      <FormDialog handleClose={closeDialog} isOpen={isModalOpen} title={'Start Super Vote'}>
        <SelectSuperVoteParticipantForm
          closeDialog={closeDialog}
          participantsData={participantsData}
          participants={participants}
          submitSuperVoteParticipantId={submitSuperVoteParticipantId}
        />
      </FormDialog>
    </Box>
  );
};

export default StoryFlowAdminSuperVote;
