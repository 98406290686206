import React, { useState, useEffect } from 'react';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector, useDispatch } from 'react-redux';
import { filter } from 'lodash';
import { isEmpty, isLoaded, useFirestore } from 'react-redux-firebase';

import { Typography, Button, CircularProgress } from '@material-ui/core';

import BreakoutsParticipantTemplate from 'components/Breakouts/Participant/Layout/BreakoutsParticipantTemplate';
import BreakoutParticipantRoom from 'components/Breakouts/Participant/BreakoutParticipantRoom';

import { getParticipantRoom } from '../../../redux/selectors/Breakouts/rootBreakoutsSelector';
import { useMeetingRoomSelector } from '../../../redux/selectors/meetingRoomSelectors';
import { getCurrentSidebarMeetingForUser } from '../../../redux/selectors/sidebarMeetingsSelectors';

import {
  joinMainRoom,
  joinMeetingRoom,
  leaveSidebarMeeting,
} from '../../../redux/actions/meetingRoomActions';

import { createHallwayMeetRoomId } from '../../../utils/createMeetRoomIds';

const BreakoutsDetailParticipant = ({
  match: {
    params: { breakoutId },
  },
}) => {
  const session = useSelector(state => state.firestore.data.session);
  const authUser = useSelector(state => state.firebase.auth);
  const breakoutsSession = useSelector(state => state.firestore.data.breakouts);
  const sidebarMeetings = useSelector(state => state.firestore.data.sidebarMeetings);
  const db = useFirestore();
  const [joiningRoom, setJoiningRoom] = useState(false);
  const participantRoom = useSelector(getParticipantRoom(authUser.uid));
  const dispatch = useDispatch();
  const avEnabled = session?.avEnabled;
  const { roomIsHallway, roomIsMain, roomIsSidebar, roomId } = useMeetingRoomSelector();
  const currentSidebar = useSelector(getCurrentSidebarMeetingForUser(authUser.uid));
  const sessionIsLoaded = isLoaded(session);
  const sidebarMeetingsIsLoaded = isLoaded(sidebarMeetings);
  const breakoutsSessionIsLoaded = isLoaded(breakoutsSession);

  useFirestoreConnect(() => [
    {
      collection: 'breakouts',
      doc: `${breakoutId}`,
      storeAs: 'breakouts',
    },
  ]);

  useEffect(() => {
    if (!sessionIsLoaded || !sidebarMeetingsIsLoaded || !breakoutsSessionIsLoaded) return;

    if (
      breakoutsSession.participantsChooseRooms &&
      !participantRoom?.id &&
      avEnabled &&
      !roomIsHallway &&
      !roomIsMain &&
      !currentSidebar
    ) {
      dispatch(joinMainRoom(session.id, {}));
    }
  }, [
    participantRoom,
    breakoutsSession,
    avEnabled,
    currentSidebar,
    sessionIsLoaded,
    sidebarMeetingsIsLoaded,
    breakoutsSessionIsLoaded,
  ]);

  if (
    !isLoaded(breakoutsSession) ||
    isEmpty(breakoutsSession) ||
    breakoutId !== breakoutsSession?.id
  ) {
    return null;
  }

  const {
    breakoutStarted,
    participantsChooseRooms,
    rooms,
    maxRoomSize,
    participantRoomAssignments,
  } = breakoutsSession;

  const roomIsFull = (roomId, roomAssignments) => {
    const roomParticipants = filter(roomAssignments, { roomId: roomId });
    return roomParticipants.length == maxRoomSize;
  };

  const renderRoomButtons = () =>
    rooms.map(room => {
      return (
        <div className="d-flex flex-column" key={room.title}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              joinRoom(room.id);
            }}
            className="mb-3"
            disabled={roomIsFull(room.id, participantRoomAssignments) || joiningRoom}
          >
            {room.title}
            {roomIsFull(room.id, participantRoomAssignments) && ' - FULL'}
          </Button>
        </div>
      );
    });

  const joinRoom = roomId => {
    setJoiningRoom(true);
    var docRef = db.doc(`breakouts/${breakoutId}`);

    return db
      .runTransaction(function (transaction) {
        return transaction.get(docRef).then(function (doc) {
          const room = doc.data();

          if (!roomIsFull(roomId, room.participantRoomAssignments)) {
            transaction.update(docRef, {
              participantRoomAssignments: db.FieldValue.arrayUnion({
                participantId: authUser.uid,
                roomId: roomId,
              }),
            });
          } else {
            throw 'Room is already full';
          }
        });
      })
      .then(() => {
        if (currentSidebar && avEnabled) {
          dispatch(leaveSidebarMeeting(roomId, authUser.uid, true));
        }
        // delay this so we don't get a flash of the buttons
        // again while before the route changes
        setTimeout(() => {
          setJoiningRoom(false);
        }, 2000);
      })
      .catch(function (error) {
        console.log('Transaction failed: ', error);
      });
  };

  const leaveRoom = () => {
    if (avEnabled) {
      if (currentSidebar) {
        dispatch(leaveSidebarMeeting(roomId, authUser.uid, true));
      }

      dispatch(
        joinMeetingRoom(
          {
            roomId: createHallwayMeetRoomId(session.id),
            roomName: 'Hallway',
            roomStatus: 'routing',
          },
          'Joining room: hallway',
        ),
      );
    }

    db.doc(`breakouts/${breakoutsSession.id}`).update({
      participantRoomAssignments: db.FieldValue.arrayRemove({
        participantId: authUser.uid,
        roomId: participantRoom.id,
      }),
    });
  };

  return (
    <BreakoutsParticipantTemplate
      sessionName={`${session.topic}: Breakouts - ${breakoutsSession.topic.name}`}
      actionBtn={
        breakoutsSession.participantsChooseRooms &&
        participantRoom && (
          <Button
            onClick={leaveRoom}
            color="primary"
            variant="contained"
            size="small"
            className="mt-1"
          >
            CHANGE ROOM
          </Button>
        )
      }
    >
      {breakoutStarted && participantsChooseRooms && isEmpty(participantRoom) ? (
        <div>
          <Typography variant="h6" className="mb-4" align="center">
            Choose a room
          </Typography>
          <div>
            {joiningRoom ? (
              <div className="d-flex justify-content-center">
                <CircularProgress />
              </div>
            ) : (
              renderRoomButtons()
            )}
          </div>
        </div>
      ) : (
        <BreakoutParticipantRoom participantId={authUser.uid} breakoutsSession={breakoutsSession} />
      )}
    </BreakoutsParticipantTemplate>
  );
};

export default BreakoutsDetailParticipant;
