import { getFirebase } from 'react-redux-firebase';
import { size } from 'lodash';
import firebase from 'firebase/app';
import 'firebase/storage';
// configs
import {
  ASSESSMENT_CONFIG,
  CARD_SORTING_CONFIG,
  NARROWING_CONFIG,
  GIVEAWAY_CONFIG,
  HEAT_MAPPING_CONFIG,
  HOW_MIGHT_WE_CONFIG,
  LIGHTNING_DEMO_CONFIG,
  LIVE_SCRIBING_CONFIG,
  NOTE_AND_CATEGORIZE_DERIVATIVE_CONFIG_LIST,
  NOTE_AND_MAP_CONFIG,
  NOTE_AND_VOTE_CONFIG,
  POLLING_CONFIG,
  SCORECARD_CONFIG,
  STORY_FLOW_CONFIG,
  SURVEY_CONFIG,
  USER_JOURNEY_MOMENTS_CONFIG,
} from '@voltage-control/control-room-activities-config';

import { ACTIVITIES } from 'constants/activities';

const CONFIG = {
  cardSorting: CARD_SORTING_CONFIG,
  narrowing: NARROWING_CONFIG,
  giveaway: GIVEAWAY_CONFIG,
  heatMapping: HEAT_MAPPING_CONFIG,
  howMightWe: HOW_MIGHT_WE_CONFIG,
  lightningDemo: LIGHTNING_DEMO_CONFIG,
  liveScribing: LIVE_SCRIBING_CONFIG,
  noteAndMap: NOTE_AND_MAP_CONFIG,
  noteAndVote: NOTE_AND_VOTE_CONFIG,
  polling: POLLING_CONFIG,
  scorecard: SCORECARD_CONFIG,
  storyFlow: STORY_FLOW_CONFIG,
  survey: SURVEY_CONFIG, // Assessment V1
  assessment: ASSESSMENT_CONFIG, // Assessment V2
  userJourneyMoments: USER_JOURNEY_MOMENTS_CONFIG,
};

export const getConfig = activity => {
  const noteAndCategorizeDerivatives = activity === 'noteAndCategorize' ? 'general' : activity;
  const config =
    NOTE_AND_CATEGORIZE_DERIVATIVE_CONFIG_LIST[noteAndCategorizeDerivatives] || CONFIG[activity];
  return config;
};

export const getActivityData = async (activity, id) => {
  const firebase = getFirebase();
  const db = firebase.firestore();
  const snapshot = await db.collection(`${activity}`).doc(`${id}`).get();
  const activityData = snapshot.data();
  return activityData;
};

export const getActivityActiveView = async (activity, id) => {
  const activityConfig = getConfig(activity);
  const collection = activityConfig ? activityConfig.collection : activity;
  const activityData = await getActivityData(collection, id);

  const { participantData, config, state } = activityData;

  // get participants
  let participants;
  if (activity === '25:10' || activity === 'brainwriting') {
    participants = activityData.participants;
  } else if (activity === 'breakouts') {
    participants = activityData.participantRoomAssignments;
  } else {
    participants = participantData.participants;
  }

  const participantsCount = size(participants);

  if (!participantsCount) {
    return 'pending';
  }

  if (activity === '25:10' || activity === 'brainwriting') {
    return activityData.activeView;
  } else if (activity === 'breakouts' || activity === 'liveScribing') {
    return 'results';
  } else if (activity === 'giveaway') {
    if (config?.ofGiveaways === state?.numberWinner) {
      return 'results';
    } else {
      return state?.activeView;
    }
  } else {
    return state?.activeView;
  }
};

// Get the references of all videos
const getVideoInstructions = async activityName => {
  const storage = firebase.storage();
  const storageRef = storage.ref();
  const ref = storageRef.child(`facilitatorInstructions/${activityName}/instructions.mp4`);

  return ref
    .getDownloadURL()
    .then(url => {
      return url;
    })
    .catch(err => {
      console.log('Error: get facilitator instructions video', err);
    });
};

/**
 * Get facilitator video instructions from firabase storage
 * @param {String} activity
 */
export const getFacilitatorVideoInstructions = async activity => {
  const activityConfig = ACTIVITIES?.[activity];
  if (!activityConfig?.facilitatorInstructionsVideo) {
    const videoUrl = await getVideoInstructions(activity);
    return videoUrl;
  }
};

export const getActivitiesData = async activities => {
  const data = [];
  for (const activity of activities) {
    const activityData = await getActivityData(activity.activity, activity.id);
    data.push(activityData);
  }
  return data;
};
