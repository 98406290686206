import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import makeStyles from '@material-ui/core/styles/makeStyles';
import cx from 'classnames';
import { size } from 'lodash';
import { getCorrectString } from 'utils/getCorrectString';
// library components
import { Formik, Form } from 'formik';
import { Button, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
// cr components
import TextArea from 'components/CustomFormComponents/TextArea';
import Spinner from 'components/Spinner';
// selectors
import { useNoteAndVoteSelector, getParticipantNotes } from 'redux/selectors/noteAndVoteSelectors';
// actions
import { createNote, editNote } from 'redux/actions/noteAndVoteActions';

const green = '#02A084';

const useStyles = makeStyles(theme => ({
  saveIcon: {
    fontSize: 20,
  },
  saveBtn: {
    color: green,
    position: 'relative',
    left: `-${theme.spacing(1)}px`,
  },
  saveBtnText: {
    fontSize: 12,
    textTransform: 'initial',
  },
  cancelBtn: {
    position: 'relative',
  },
  cancelBtnText: {
    fontSize: 12,
    color: '#ABABAB',
    textTransform: 'initial',
  },
  textArea: {
    // fontSize: 18,
    // lineHeight: '26px',
    padding: 0,
  },
  formActions: {
    padding: 0,
    bottom: `${theme.spacing(1)}px`,
    left: 0,
    right: 0,
  },
}));

const NoteForm = ({ note, type, cancelEdit }) => {
  const user = useSelector(state => state.firebase.auth);
  const { id } = useNoteAndVoteSelector();
  const dispatch = useDispatch();
  const classes = useStyles({ type });
  const participantId = useSelector(state => state.firebase.auth.uid);
  const participantNotes = useSelector(getParticipantNotes(participantId));

  /**
   * Autofocus the field when the form is rendered so that
   * the user knows that they can type since the form field
   * is essentially invisible
   */
  useEffect(() => {
    focusNoteField();
  }, []);

  const focusNoteField = () => {
    document.getElementById('note').focus();
  };

  const formSchema = Yup.object().shape({
    note: Yup.string(),
  });

  const initialValues = { note: '' };

  if (note) {
    initialValues.note = note.note;
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={formSchema}
      onSubmit={(values, { resetForm, setSubmitting }) => {
        if (!values.note) {
          setSubmitting(false);
          cancelEdit();
          return;
        }
        const noteText = getCorrectString(values.note);

        if (type === 'create') {
          dispatch(
            createNote(noteText, user.uid, id, size(participantNotes), () => {
              setSubmitting(false);
              resetForm();
              focusNoteField();
            }),
          );
        }

        if (type === 'edit') {
          dispatch(
            editNote(noteText, note.noteId, id, () => {
              setSubmitting(false);
              resetForm();
              cancelEdit();
            }),
          );
        }
      }}
    >
      {({ isSubmitting, submitForm, resetForm }) => (
        <Form className="w-100 h-100 position-relative">
          <TextArea
            name="note"
            rows={5}
            required
            transparent
            inputProps={{ maxLength: 140 }}
            additionalStyles={{
              input: classes.textArea,
            }}
          />

          <div
            className={cx(
              'd-flex justify-content-between position-absolute w-100',
              classes.formActions,
            )}
          >
            {isSubmitting ? (
              <Spinner size={24} />
            ) : (
              <Button className={classes.saveBtn} onClick={submitForm} size="small">
                <CheckCircleIcon color="inherit" className="mr-1" />
                <Typography color="inherit" className={classes.saveBtnText}>
                  Save
                </Typography>
              </Button>
            )}
            <Button
              onClick={() => {
                /**
                 * This is hacky work around.  For some reason calling cancelEdit()
                 * here doesn't work, but it does work in the submit function, so we are
                 * resetting the form and then calling submitForm() which calls cancelEdit() if
                 * the field is empty. ¯\_(ツ)_/¯
                 */
                resetForm();
                submitForm();
              }}
              size="small"
              className={classes.cancelBtn}
            >
              <Typography className={classes.cancelBtnText}>Cancel</Typography>
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

NoteForm.propTypes = {
  note: PropTypes.shape({
    note: PropTypes.string.isRequired,
    noteId: PropTypes.string.isRequired,
  }),
  cancelEdit: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['create', 'edit']),
};

export default NoteForm;
