import React from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

// core components
import { makeStyles } from '@material-ui/core/styles';
import { Checkbox, FormControlLabel } from '@material-ui/core';

import PickerSelect from 'components/Staffing/PickerSelect/PickerSelect';
import { DEAL_STATUS_SELECT_OPTIONS } from 'constants/staffing';
import { DEAL_STATUS_PRICE_OPTIONS } from 'constants/staffing';

import { selectCompanies } from 'redux/selectors/staffing/getDeals';
import { setDealsFilterPayload } from 'redux/actions/staffingActions';
import { useStaffingSelector } from 'redux/selectors/staffing/staffingSelectors';

import './dealsFilter.scss';

// useStyles
const useStyles = makeStyles(theme => ({
  main: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 25,
    gap: '15px',
  },
  formControl: {
    margin: theme.spacing(1),
    marginRight: -5,
    flexGrow: 1,
  },
  input: {
    width: 143,
    backgroundColor: '#EEEEEE',
  },
  inputLabel: {
    paddingLeft: 10,
    zIndex: 2,
  },
  selectedValue: {
    '& .MuiSelect-select': {
      paddingLeft: '10px',
    },
  },
}));

const DealsFilter = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleSelect = (value, filterParam) => {
    dispatch(
      setDealsFilterPayload({
        [filterParam]: value,
      }),
    );
  };

  const getAllCompanies = selectCompanies();
  const { companies } = useSelector(state => getAllCompanies(state));

  return (
    <div className={cx(classes.main)}>
      <PickerSelect
        label="Company"
        classNames={cx('deals-filter-select-input', classes.selectedValue)}
        options={companies}
        onChange={value => {
          handleSelect(value, 'dealsCompany');
        }}
      />

      <PickerSelect
        label="Price range"
        classNames={cx('deals-filter-select-input', classes.selectedValue)}
        options={DEAL_STATUS_PRICE_OPTIONS}
        onChange={value => {
          handleSelect(value, 'dealsPrice');
        }}
      />

      <PickerSelect
        label="Status"
        classNames={cx('deals-filter-select-input', classes.selectedValue)}
        options={DEAL_STATUS_SELECT_OPTIONS}
        onChange={value => {
          handleSelect(value, 'dealsStatus');
        }}
      />
    </div>
  );
};

export default DealsFilter;
