import React, { useState } from 'react';
import PropTypes from 'prop-types';
// import ReactWordcloud from 'react-wordcloud';
import { TagCloud } from 'react-tagcloud';
import { useDeepCompareEffect } from 'react-use';
import { stripWordsFromArray, wordExclusions } from 'utils/words';

import './word-cloud.scss';
import { isNumericString } from 'utils/isNumericString';
import { isNumber } from 'lodash';

const WordCloud = ({ words, fontSizes, height, minCount }) => {
  const [theWords, setTheWords] = useState([]);

  useDeepCompareEffect(() => {
    if (words.length) {
      const parsedWords = [];
      const alreadyCounted = [];

      // strip blacklisted words
      let filteredWords = stripWordsFromArray(words, wordExclusions);

      // strip punctuation
      if (filteredWords.length) {
        filteredWords = filteredWords.map(word =>
          word
            .replaceAll('.', '')
            .replaceAll('?', '')
            .replaceAll('!', '')
            .replaceAll(',', '')
            .replaceAll('"', ''),
        );
      }

      // filter out contractions
      if (filteredWords.length) {
        filteredWords = filteredWords.filter(x => !x.includes(`'`) && !x.includes('’'));
      }

      if (filteredWords.length) {
        filteredWords = filteredWords.filter(x => !isNumericString(x) && !isNumber(x));
      }

      if (filteredWords.length) {
        filteredWords = filteredWords.filter(x => x.length > 3);
      }

      if (filteredWords.length) {
        for (let word of filteredWords) {
          if (alreadyCounted.includes(word)) continue;

          const count = filteredWords.filter(x => x === word).length;

          alreadyCounted.push(word);

          if (count >= minCount) {
            parsedWords.push({
              value: word,
              count,
            });
          }
        }
      }

      if (parsedWords.length) {
        setTheWords(parsedWords);
      }
    }
  }, [words]);

  if (!theWords.length) return null;

  // console.log('theWords', theWords);

  let theProps = {
    minSize: 18,
    maxSize: 32,
    tags: theWords,
    className: 'cr-word-cloud',
    colorOptions: {
      luminosity: 'dark',
    },
  };

  if (fontSizes) {
    theProps.minSize = fontSizes[0];
    theProps.maxSize = fontSizes[1];
  }

  return (
    <>
      {height ? (
        <div style={{ height: `${height}px` }}>
          <TagCloud {...theProps} />
        </div>
      ) : (
        <TagCloud {...theProps} />
      )}
    </>
  );
};

WordCloud.defaultProps = {
  words: [],
  minCount: 2,
};

WordCloud.propTypes = {
  words: PropTypes.arrayOf(PropTypes.string).isRequired,
  height: PropTypes.number,
  fontSizes: PropTypes.arrayOf(PropTypes.number.isRequired),
};

export default WordCloud;
