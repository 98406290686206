import { createSelector } from 'reselect';
import { useShallowEqualSelector } from '../utils';
import { map, includes, filter, orderBy, sortBy } from 'lodash';

const howMightWeSelector = state => state.firestore.data.howMightWe || {};

export const useHowMightWeSelector = () => {
  return useShallowEqualSelector(howMightWeSelector);
};

/**
 * Get data on which participants have completed create ideas,
 * formatted for the ActivityParticipantDetail component
 *
 * @returns {Array.<{id: String, completed: Boolean}>}
 */
export const getParticipantsCompletedCreate = () =>
  createSelector(
    [useHowMightWeSelector],
    ({ config: { share }, participantData: { ideas, participants } }) => {
      return map(participants, participantId => {
        const participantIdeas = orderBy(
          filter(ideas, { participantId: participantId }),
          ['order'],
          ['desc'],
        );
        return {
          id: participantId,
          completed: !!participantIdeas.length,
          completedCount: participantIdeas.length,
        };
      });
    },
  );

export const getParticipantIdeas = participantId =>
  createSelector([useHowMightWeSelector], ({ participantData: { ideas } }) => {
    return orderBy(filter(ideas, { participantId: participantId }), ['order'], ['desc']);
  });

/**
 * Get data on which participants have completed share ideas,
 * formatted for the ActivityParticipantDetail component
 *
 * @returns {Array.<{id: String, completed: Boolean}>}
 */
export const getParticipantsCompletedShare = () =>
  createSelector([useHowMightWeSelector], ({ participantData: { ideas, participants } }) => {
    return map(participants, participantId => {
      const participantShareIdeas = orderBy(
        filter(ideas, { participantId: participantId, isShared: true }),
        ['order'],
        ['desc'],
      );
      return {
        id: participantId,
        completed: !!participantShareIdeas.length,
        completedCount: participantShareIdeas.length,
      };
    });
  });

/**
 * Get shared participant ideas
 *
 */
export const getSharedParticipantIdeas = participantId =>
  createSelector([useHowMightWeSelector], ({ participantData: { ideas } }) => {
    return orderBy(
      filter(ideas, idea => idea.participantId === participantId && idea.isShared),
      ['order'],
      ['desc'],
    );
  });

/**
 * Get data on which participants have completed organize ideas,
 * formatted for the ActivityParticipantDetail component
 *
 * @returns {Array.<{id: String, completed: Boolean}>}
 */
export const getParticipantsCompletedGroup = () =>
  createSelector([useHowMightWeSelector], ({ participantData: { participants } }) => {
    return map(participants, participantId => ({
      id: participantId,
      completed: true,
    }));
  });

/**
 * Get all shared participants ideas
 *
 */
export const getSharedIdeas = context =>
  createSelector([useHowMightWeSelector], ({ participantData: { ideas } }) => {
    // sorts ideas by creation in phase 'create', in phases 'vote'/'group' - by idea
    return orderBy(
      filter(ideas, 'isShared'),
      context === 'vote' || context === 'group' ? ['idea', 'ideaId'] : ['order'],
      ['desc'],
    );
  });

/**
 * Get data on which participants have completed vote,
 * formatted for the ActivityParticipantDetail component
 *
 * @returns {Array.<{id: String, completed: Boolean}>}
 */
export const getParticipantsCompletedVote = () =>
  createSelector(
    [useHowMightWeSelector],
    ({ config: { votes }, participantData: { ideas, participants } }) => {
      return map(participants, participantId => {
        const participantVotedIdeas = filter(ideas, idea =>
          includes(idea.votedParticipants, participantId),
        );
        return {
          id: participantId,
          completed: !!participantVotedIdeas.length,
          completedCount: participantVotedIdeas.length,
        };
      });
    },
  );

/**
 * Get voted ideas for participant
 *
 */
export const getVotedParticipantIdeas = participantId =>
  createSelector([useHowMightWeSelector], ({ participantData: { ideas } }) => {
    const participantVotesIdeas = filter(ideas, idea =>
      includes(idea.votedParticipants, participantId),
    );
    return map(participantVotesIdeas, idea => idea.ideaId);
  });

/**
 * Get voted ideas
 *
 */
export const getVoteIdeas = () =>
  createSelector([useHowMightWeSelector], ({ participantData }) => {
    return orderBy(
      filter(
        participantData?.ideas,
        idea => idea.votedParticipants && idea.votedParticipants?.length !== 0,
      ),
      [idea => idea.votedParticipants?.length, 'order'],
      ['desc', 'desc'],
    );
  });

/**
 * Get archived ideas
 *
 */
export const getArchivedIdeas = () =>
  createSelector([useHowMightWeSelector], ({ participantData }) => {
    return sortBy(
      filter(
        participantData?.ideas,
        idea => !idea.votedParticipants || idea.votedParticipants?.length === 0,
      ),
      ['order', 'idea'],
    );
  });
