import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { filter } from 'lodash';
// library components
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// cr components
import ActivityParticipantDetails from 'components/ActivityParticipantDetails';
import ActivityPromptAdmin from 'components/ActivityPromptAdmin';
import ActivityActionButton from 'components/CustomButtons/ActivityActionButton';
// selectors
import {
  useStoryFlowSelector,
  getParticipantsCompletedCreate,
} from 'redux/selectors/storyFlowSelectors';
// actions
import { updateActivityView } from 'redux/actions/storyFlowActions';
// config
import { STORY_FLOW_CONFIG as CONFIG } from '@voltage-control/control-room-activities-config';
// utils
import { makeParticipantDetailsHeaderText } from 'utils/makeParticipantDetailsHeaderText';

const useStyles = makeStyles(theme => ({
  promptAdmin: {
    marginBottom: theme.spacing(5),
  },
}));

/**
 * Story Flow view for the 'create' phase on the Facilitator side
 */
const StoryFlowAdminCreate = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const storyFlow = useStoryFlowSelector();
  const { id, prompt } = storyFlow;

  const participantsHaveCreated = useSelector(getParticipantsCompletedCreate());
  const participantsHaveCreatedCount = filter(participantsHaveCreated, { completed: true }).length;

  const participantsHaveCreatedCountText = makeParticipantDetailsHeaderText(
    participantsHaveCreatedCount,
    participantsHaveCreated.length,
    'add a user flow',
  );

  const onUpdateActivityView = () => {
    dispatch(updateActivityView('select', id));
  };

  return (
    <Box className="d-flex flex-column justify-content-center">
      <ActivityPromptAdmin
        prompt={prompt}
        collection={CONFIG.collection}
        activityId={id}
        className={classes.promptAdmin}
      />
      <ActivityParticipantDetails
        headerText={participantsHaveCreatedCountText}
        hasCompletedLabel="Added User Flow"
        hasntCompletedLabel="Hasn’t Added User Flow"
        completedCountMax={6} // There are a total of 6 steps, so that's the max they can add. This should probably be a constant somewhere.
        participantsCompletedStatus={participantsHaveCreated}
      />
      <ActivityActionButton
        text="Start Voting"
        className="mb-2 mt-1"
        onClick={onUpdateActivityView}
      />
    </Box>
  );
};

export default StoryFlowAdminCreate;
