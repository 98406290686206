import React from 'react';
import PropTypes from 'prop-types';
// library components
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import Icon from '@material-ui/core/Icon';

const useStyles = makeStyles({
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 124.8,
    height: 105,
    background: ({ isSelected }) => (isSelected ? '#fff' : grey[200]),
    border: '1px solid #C9C9C9',
    cursor: 'pointer',
    boxShadow: ({ isSelected }) => isSelected && '0px 6px 15px rgba(0, 0, 0, 0.15)',
  },
  icon: {
    fontSize: 60,
    color: ({ isSelected }) => (isSelected ? grey[900] : grey[500]),
  },
});

const IconCard = ({ icon, handleSelectIcon, isSelected }) => {
  const classes = useStyles({ isSelected });

  return (
    <Box className={classes.iconContainer} onClick={() => handleSelectIcon(icon)}>
      <Icon className={classes.icon}>{icon}</Icon>
    </Box>
  );
};

IconCard.propTypes = {
  icon: PropTypes.string.isRequired, // type icon
  handleSelectIcon: PropTypes.func, // function by which the icon is selected
  isSelected: PropTypes.bool, // is icon selected ?
};

export default IconCard;
