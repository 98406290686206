import { map, orderBy, max, each, filter, findIndex, includes, join } from 'lodash';

/**
 * Creates the output array for a Note & Vote actvity
 * @param {Object} notes
 * @returns {Array<{text: String, votes: Number}>}
 */
export const createOutput = notes => {
  // filter to notes that were shared
  let output = filter(notes, { shared: true });

  // create initial output array
  output = map(output, note => ({
    text: note.note,
    votes: note.votedFor?.length || 0,
    winner: false,
  }));

  // create array of votes
  const votes = map(output, 'votes');

  // determine hights vote count
  const highVote = max(votes);

  // tag notes with the highest votes as 'winner'
  each(output, (note, idx) => {
    if (note.votes === highVote) {
      output[idx].winner = true;
    }
  });

  // sort array and return
  return orderBy(output, ['votes', 'text'], ['desc', 'asc']);
};

/**
 * Creates the array for Report Header
 *
 */
export const createNoteAndVoteReportHeader = () => [
  { label: 'Votes', key: 'votes' },
  { label: 'Note', key: 'note' },
  { label: 'Duplicate', key: 'duplicate' },
];

/**
 * Creates the data for Report
 *
 */
export const createNoteAndVoteReportData = sortedParticipantNotes => {
  const sortedNotes = orderBy(sortedParticipantNotes, note => note.votedFor?.length, ['desc']);
  return map(sortedNotes, note => {
    const duplicateNumber = findIndex(sortedNotes, sortedNote =>
      includes(sortedNote.combineNotesId, note.noteId),
    );
    return {
      votes: note.votedFor?.length || 0,
      note: note.note,
      duplicate: note.combine ? `${duplicateNumber + 1}` : '',
    };
  });
};

export const createNoteAndVoteReport = activityData => {
  const sortedParticipantNotes = orderBy(
    filter(activityData?.participantData?.notes, note => note.shared),
    ['order', 'noteId'],
    ['desc'],
  );

  const headers = createNoteAndVoteReportHeader();
  const data = createNoteAndVoteReportData(sortedParticipantNotes);
  return { headers, data };
};
