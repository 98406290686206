import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { filter, size, forEach, last, split } from 'lodash';
// library components
import { Dialog, Box, Tabs, Tab } from '@material-ui/core';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
// cr components
import DialogHeader from './DialogHeader';
import DialogAction from './DialogAction';
import ImagesContent from './ImagesContent';
import NotesContent from './NotesContent';
// actions
import { exportRecapDeck } from 'redux/actions/facilitatorSessionActions';
// utils
import { getActivitiesData } from 'utils/getActivityConfig';

const useStyles = makeStyles(theme => ({
  paperWidthSm: {
    maxWidth: 760,
  },
  dialogTitle: {
    maxWidth: 760,
    width: 760,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dialogActions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(4),
  },
  tab: {
    minWidth: 80,
    height: 50,
    fontSize: 12,
  },
}));

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(5),
    paddingTop: 4,
    paddingRight: theme.spacing(4),
  },
}))(MuiDialogContent);

/**
 * Renders Recap Deck Modal dialog
 */
const RecapDeck = ({
  isOpen,
  handleClose,
  sessionId,
  activities,
  recapDeck,
  onlyImages,
  sessionActivity,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  // active tab 'images' or 'notes'
  const [activeTab, setActiveTab] = useState(0);
  const [notes, setNotes] = useState({});
  const [testers, setTesters] = useState({});

  // render header
  const dialogHeader = onlyImages
    ? `Activity Images: ${last(split(sessionActivity?.name, ': '))}`
    : 'Recap Deck';

  const handleConfirm = () => {
    dispatch(exportRecapDeck(sessionId));
  };

  // get scorecards notes
  const getScorecardActivity = useCallback(async activities => {
    const activityData = await getActivitiesData(activities);
    forEach(activityData, activity => {
      const data = activity?.participantData?.notes;
      const testersData = activity?.config?.testers;
      if (data) {
        setNotes(prev => ({ ...prev, ...data }));
      }
      if (testersData) {
        setTesters(prev => ({ ...prev, ...testersData }));
      }
    });
  }, []);

  useEffect(() => {
    const scoreActivities = filter(activities, { activity: 'scorecard' });
    if (size(scoreActivities)) {
      getScorecardActivity(scoreActivities);
    }
  }, [getScorecardActivity, activities]);

  // change tab 'images' or 'notes'
  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Dialog
      open={isOpen}
      classes={{
        paperWidthSm: classes.paperWidthSm,
      }}
    >
      <DialogHeader title={dialogHeader} handleClose={handleClose} />
      <DialogContent classes={classes.root} dividers>
        {onlyImages ? (
          <ImagesContent
            activities={activities}
            sessionId={sessionId}
            recapDeckActivities={recapDeck?.activities}
            onlyImages={onlyImages}
            sessionActivity={sessionActivity}
          />
        ) : (
          <>
            <Box className="d-flex justify-content-center">
              <Tabs
                value={activeTab}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleChangeTab}
              >
                <Tab disableRipple className={classes.tab} label="IMAGES" />
                <Tab disableRipple className={classes.tab} label="NOTES" />
              </Tabs>
            </Box>
            {activeTab ? (
              <NotesContent
                sessionId={sessionId}
                initialValues={{
                  testerQuotes: recapDeck?.testerQuotes,
                  userInsights: recapDeck?.userInsights,
                  nextSteps: recapDeck?.nextSteps,
                }}
                notes={notes}
                testers={testers}
              />
            ) : (
              <ImagesContent
                activities={activities}
                sessionId={sessionId}
                recapDeckActivities={recapDeck?.activities}
              />
            )}
          </>
        )}
        {!onlyImages && (
          <DialogAction
            handleClose={handleClose}
            handleConfirm={() => {
              handleConfirm();
              handleClose();
            }}
            className={classes.dialogActions}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

RecapDeck.propTypes = {
  isOpen: PropTypes.bool, // true - recap deck dialog open
  activities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired, // activity id
      name: PropTypes.string.isRequired, // activity name
    }),
  ),
  sessionId: PropTypes.string.isRequired, // session id,
  recapDeck: PropTypes.shape({
    activities: PropTypes.object, // recap deck images for all activities
    nextSteps: PropTypes.arrayOf(PropTypes.string), // recap deck next steps for all activities
    testerQuotes: PropTypes.arrayOf(PropTypes.string), // recap tester quotes steps for all activities
    userInsights: PropTypes.arrayOf(PropTypes.string), // recap deck user insights for all activities
  }),
  sessionActivity: PropTypes.shape({
    id: PropTypes.string.isRequired, // current activity id
    name: PropTypes.string.isRequired, // current activity name
  }),
  onlyImages: PropTypes.bool, // true - recap deck only for images
  handleClose: PropTypes.func, // function for open Recap Deck Dialog
};

export default RecapDeck;
