import React from 'react';
import { useSelector } from 'react-redux';
import { has, map, sortBy, orderBy, size } from 'lodash';
import { DragDropContext } from 'react-beautiful-dnd';
import { makeStyles } from '@material-ui/core/styles';
// library components
import { Box } from '@material-ui/core';
// cr components
import Category from './Category';
// selectors
import {
  useNoteAndCategorizeSelector,
  getResults,
} from 'redux/selectors/noteAndCategorizeSelectors';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    marginBottom: theme.spacing(2),
  },
}));

const notesUseStyles = makeStyles({
  note: {
    minHeight: 75,
  },
});

/**
 * Note & Categorize view for the 'results' phase on the Participant side.
 */
const NoteAndCategorizeParticipantResults = () => {
  const classes = useStyles();
  const notesClasses = notesUseStyles();
  const {
    config: { votesPerCategory, categories },
    participantData: { notes },
  } = useNoteAndCategorizeSelector();
  const results = useSelector(getResults());
  const participantsNotes = orderBy(
    notes,
    [note => size(note.votedFor), 'order', 'noteId'],
    ['desc', 'asc', 'asc'],
  );
  let sortField = 'value';
  const sortedCategories = sortBy(
    map(categories, category => {
      sortField = has(category, 'order') ? 'order' : 'value';
      return category;
    }),
    sortField,
  );

  /**
   * Create all categories.
   */
  const renderCategories = () => {
    return map(sortedCategories, (category, idx) => (
      <Category
        key={`${category.value}-${idx}`}
        id={category.id}
        droppableId={`${idx}`}
        value={category.value}
        notes={participantsNotes}
        context="results"
        votesPerCategory={votesPerCategory}
        noteSize="small"
        results={results?.[category.id]}
        noteClassName={notesClasses.note}
      />
    ));
  };

  return (
    <DragDropContext>
      <Box classes={classes}>{renderCategories()}</Box>
    </DragDropContext>
  );
};

export default NoteAndCategorizeParticipantResults;
