import { useShallowEqualSelector } from '../utils';
import { createSelector } from 'reselect';
import { filter, size, map, includes, cloneDeep, each } from 'lodash';

const pollingSelector = state => state.firestore.data.polling || {};

export const usePollingSelector = () => {
  return useShallowEqualSelector(pollingSelector);
};

/**
 * Get all votes with the votes added as an array of
 * participant ids
 */
export const getNotesWithVotes = () =>
  createSelector([pollingSelector], ({ participantData: { votes }, config: { notes } }) => {
    const notesCopy = cloneDeep(notes);

    each(notesCopy, note => {
      notesCopy[note.id].votedFor = votes[note.id];
    });

    return notesCopy;
  });

/**
 * Get all notes a participant has voted for
 *
 * @param {String} participantId
 */
export const getParticipantVotedNotes = participantId =>
  createSelector([getNotesWithVotes()], notes =>
    filter(notes, note => includes(note.votedFor, participantId)),
  );

/**
 * Get all note ids that were voted for by a participant
 *
 * @param {String} participantId
 */
export const getParticipantVotes = participantId =>
  createSelector([getParticipantVotedNotes(participantId)], notes => map(notes, 'id'));

/**
 * Get data on which participants have submitted thier votes,
 * formatted for the ActivityParticipantDetail component
 *
 * @returns {Array.<{id: String, completed: Boolean}>}
 */
export const getParticipantsHaveVoted = () =>
  createSelector(
    [getNotesWithVotes(), pollingSelector],
    (notes, { participantData: { participants } }) => {
      return map(participants, pId => {
        const notesVotedFor = size(filter(notes, note => includes(note?.votedFor, pId)));
        return {
          id: pId,
          completed: notesVotedFor > 0,
          completedCount: notesVotedFor,
        };
      });
    },
  );
