import firebase from 'firebase/app';
import 'firebase/firestore';
import { store } from '../index.js';
import { each } from 'lodash';
import copy from 'copy-to-clipboard';
// actions
import { enqueueSuccessSnackbar, enqueueErrorSnackbar } from 'redux/actions/notifierActions';
// constants
import { ACTIVITIES } from 'constants/activities';

/**
 * Copies the output of an activity according to it's config
 * @param {String} activityType the firestore collection for the activity
 * @param {String} activityId
 * @param {Boolean} returnOutput if true, returns the output instead of copying to the clipboard
 * @param {function} outputFormater optional function for activities without a config
 */
export const copyActivityOutput = async (activityType, activityId, returnOuput, outputFormater) => {
  const dispatch = store.dispatch;
  const db = firebase.firestore();
  const activityCollection = ACTIVITIES[activityType]?.collection
    ? ACTIVITIES[activityType].collection
    : activityType;
  const activityRef = db.doc(`/${activityCollection}/${activityId}`);
  const customFormatter = outputFormater || ACTIVITIES[activityType].config.copyOutputFormatter;
  const query = await activityRef.get();
  const activityData = query.data();

  let copied = null;

  if (customFormatter) {
    const output = await customFormatter(activityData);

    if (returnOuput) {
      return output;
    } else {
      copied = copy(output, {
        debug: true,
        format: 'text/html',
      });
    }
  } else {
    let outputString = `${activityData.name}\n\n`;

    if (!activityData.output) {
      dispatch(enqueueErrorSnackbar('Error copying results to clipboard.'));
      console.error('There is no ouput array for this activity');
      console.error(activityData);
      outputString += 'No Output\n';
    } else if (!activityData.output.length) {
      dispatch(enqueueErrorSnackbar('Error copying results to clipboard'));
      console.error('The output array for this activity is empty');
      console.error(activityData);
      outputString += 'Empty Output\n';
    } else {
      each(activityData.output, item => {
        each(item, (data, key) => {
          outputString += `${key}: ${data}<br/>`;
        });

        outputString += '<br/>';
      });
    }

    if (returnOuput) {
      return outputString;
    } else {
      copied = copy(outputString, {
        debug: true,
        format: 'text/html',
      });
    }
  }

  if (copied && !returnOuput) {
    dispatch(enqueueSuccessSnackbar('Results copied to clipboard'));
  } else {
    dispatch(enqueueErrorSnackbar('Error copying results to clipboard'));
  }
};
